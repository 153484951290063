import { fnApiFetchAppAccess } from "../api/api-applications";

function groupBy(data, key) {
  const result = {};
  let jsonArr = data;
  let array = [];
  // (manually) convert JSON Array to Array Object
  for (var i in jsonArr) {
    array.push(jsonArr[i]);
  }
  array.forEach((item) => {
    if (item["enabled"] == true) {
      if (!result[item[key]]) {
        result[item[key]] = [];
      }
      result[item[key]].push(item);
    }
  });
  return result;
}

const getDefaultState = () => {
  return {
    stAppAccess: null,
  };
};
const state = getDefaultState();
const getters = {
  appAccess(state) {
    return state.stAppAccess;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  updateAppAccess(state, data) {
    state.stAppAccess = data;
  },
};
const actions = {
  getApplicationAccess({ commit }, params = null) {
    fnApiFetchAppAccess(params)
      .then((rsp) => {
        let items = rsp.data.payload.app_list;
        // Populate app_list item to nested JSON (grouped by app_group)
        let lookup = {};
        let result = [];
        for (let i = 0; i < items.length; i++) {
          let item = items[i];
          let name = item.group;
          if (!(name in lookup)) {
            lookup[name] = 1;
            result.push(name);
          }
        }
        let newData = groupBy(rsp.data.payload.app_list, "group");

        commit("updateAppAccess", newData);
      })
      .catch((err) => {
        Promise.reject(err);
      });
  },

  // // Get List UserBBI
  // getVersionApp({commit}, filter) {
  //   return new Promise((resolve, reject) => {
  //       fnApiGetVersionApp(filter).then( rsp => {
  //       commit('StGetVersionApp', rsp.data.payload)
  //       if(rsp.data.payload.length == 0) {
  //           resolve('No Data')
  //       }
  //       resolve(rsp.data)
  //   }).catch(err => {
  //       reject(err)
  //       })
  //   })
  // },
  clearState({ commit }) {
    commit("resetState");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
