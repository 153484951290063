<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item active>Account</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">Account</span></h2>

    <b-form @submit.prevent="submitChangePassword">
      <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
        {{errorMessage}}
      </b-alert>
      <b-form-group
        id="input-group-1"
        label="User ID :"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.userid"
          readonly
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="Name :" label-for="input-2">
        <b-form-input
          id="input-2"
          v-model="form.name"
          readonly
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="Role :" label-for="input-3">
        <b-form-input
          id="input-32"
          v-model="form.role"
          readonly
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="Department :" label-for="input-3">
        <b-form-input
          id="input-32"
          v-model="form.deptname"
          readonly
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-5" label="Old Password :" label-for="input-4" v-if="canChangePassword">
        <b-form-input
          id="input-4"
          v-model="form.oldPassword"
          type="password"
          ref="oldPassword"
          required
          placeholder="Enter password"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-6" label="New Password :" label-for="input-5" v-if="canChangePassword">
        <b-form-input
          id="input-5"
          v-model="form.newPassword"
          type="password"
          ref="newPassword"
          required
          placeholder="Enter new password"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-7" label="Confirm New Password :" label-for="input-6" v-if="canChangePassword">
        <b-form-input
          id="input-6"
          v-model="form.confirmPassword"
          type="password"
          ref="confirmPassword"
          required
          placeholder="Enter new password once more"
        ></b-form-input>
      </b-form-group>
      <div>
        <b-row>
          <b-col cols="8">
            <b-button v-if="canChangePassword" class='btn-spacing' type="submit" variant="primary">Change Password</b-button>
          </b-col>
          <b-col cols="4" class="text-right">
            <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToDashboard()">Cancel</b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import apiAccount from '@/store/account.js';

export default {
  name: 'AccountPage',
  data() {
    return {
      errorMessage: null,
      form: {
          userid: null,
          name: null,
          role: null,
          deptname: null,
          oldPassword: null,
          newPassword:null,
          confirmPassword:null,
      },
      show: true
    }
  },
  computed: {
    ...mapGetters('session', ['userData']),
    canChangePassword() {
      let usrBU = this.userData.business_unit
      if(usrBU == 'BBI1' || usrBU == 'BBI') return true;
      return false
    }
  },
  methods: {
      ...mapActions('session', ['showNotification']),
    getAccountInfo() {
      this.$set(this.form, 'userid', this.userData.username)
      this.$set(this.form, 'name', this.userData.fullname)
      let roles = ''
      if (this.userData.roles.length > 0) {
        for (let a=0 ; a<this.userData.roles.length; a++) {
          if (a==0) {
            roles  += this.userData.roles[a].app
          } else {
            roles  += ', '+this.userData.roles[a].app
          }
        }
      }
      this.$set(this.form, 'role', roles)
      this.$set(this.form, 'deptname', this.userData.deptname)
      // this.form.userid  = userData.username
      // this.form.name    = userData.fullname
      // this.form.role    = userData.role
      // this.resetForm()
      if(this.userData.is_first_login) {
        this.errorMessage = 'You are using admin provided password, please change your password'
      }
    },
    resetForm() {
      this.$set(this.form, 'oldPassword', null)
      this.$set(this.form, 'newPassword', null)
      this.$set(this.form, 'confirmPassword', null)
      this.form.oldPassword = null
      this.form.newPassword = null
      this.form.confirmPassword = null
    },
    submitChangePassword() {
      this.errorMessage = null;
      if (this.form.newPassword != this.form.confirmPassword) {
        this.errorMessage = 'New Password & Confirmation Password Mismatch. Please check your input'
        this.resetForm()
      }
      else {
        apiAccount.dispatch('changePassword', {
          username: this.form.userid,
          old_password: this.form.oldPassword,
          new_password: this.form.newPassword
        })
          .then(response => {
            this.updateUserData()
            this.$router.push({name:"DashboardPage"})
          })
          .catch(err => {
          if (err.response.status === 400) {
            this.errorMessage = err.response.data.message;
          }
        })
      }
    },
    goToDashboard(){
      this.$router.push({name:"DashboardPage"})
    },
    updateUserData() {
      this.errorMessage = null;
      apiAccount.dispatch('updateAcctDetail')
      .then(response => {
        this.showNotification({type:'success', message:'Password updated'})
      })
      .catch(err => {
        if (err.response.status === 400) {
          this.errorMessage = err.response.data.message;
        }
      })
    },
  },
  mounted: function(){
    this.getAccountInfo()
  },
}
</script>

<style src="./Account.scss" lang="scss" scoped />