<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <h2 class="page-title"> <span style="font-size:16px;" class="fw-semi-bold fontbbi ">Dashboard Delivery</span></h2>
      <!-- <b-breadcrumb-item><span class='fontbbi space2 text-secondary'>YOU ARE HERE</span></b-breadcrumb-item>
      <b-breadcrumb-item active><span class='fontbbi space2'>Applications</span></b-breadcrumb-item> -->
    </b-breadcrumb>
    <!-- <h2 class="page-title"> <span class="fw-semi-bold fontbbi ">Dashboard PPCM</span></h2> -->
    <!-- <b-row align-h="center">
      <b-col sm="auto">
          <div class="overflow-auto">
              <b-col sm="4">
              <b-input-group size="sm">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
              </b-col>
              <b-col sm="12" style="padding-top:10px;">
                <b-table striped hover style="text-align:center;"
                  id="my-table"
                  :items="stAllPPM"
                  :per-page="perPage"
                  :fields="fields"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"


                    responsive="sm"
                  >

                <template #cell(io)="row" >
                  <div><span @click="IOPPCM(row.value)" style="color:#5497f3; text-decoration: underline; cursor: pointer;" class="fw-semi-bold fontbbi space3">{{row.value}}</span>
                  </div>
                </template>

                 <template #cell(total_plan)="row" >
                  <span :class="TotalPlan(row.index, row.value, stAllPPM[row.index].qty_plan, stAllPPM[row.index].cutting)">
                  {{total_plan[row.index]}}</span>
                </template>
                <template #cell(total_actual)="row" >
                  <span :class="TotalPlan(row.index, row.value, stAllPPM[row.index].qty_plan, stAllPPM[row.index].cutting)">
                  {{total_plan[row.index]}}</span>
                </template>

                </b-table>
              </b-col>
                <b-col sm="2" md="3" class="my-0">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="mt-2"
                      pills
                    ></b-pagination>
                  </b-col>
                </div>
              </b-col>
      </b-row> -->
   <b-row>
      <b-col xs="12"> Search
        <input type="text"
         placeholder=""
         v-model="filter">
          <div style="padding-top:20px;" class="table-responsive">
          <table class="table table-lg mb-0">
              <thead>
                <tr style="text-align:center;" class="text-muted">
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('io')">IO</th>
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('cutting_subcon')">SubCon</th>
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('due_date')">Due Date</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Article</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Sample</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Qty Plan</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Qty Packing</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Type</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Partial</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">User</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                v-for="row in filteredRows"
                :key="row.id"
                style="text-align:center;"
                >
                  <td style="border-right:1px solid #CCC; border-left:1px solid #CCC; border-bottom:1px solid #CCC;" :class="chkDateOnSchedule(row.due_date, today, row.status)">
                    <span v-html="highlightMatches(row.io)" @click="IOPPCM(row.io)" style="color:#5497f3; text-decoration: underline; cursor: pointer;" class="fw-semi-bold fontbbi">{{row.io}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.subcon}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span v-if="row.due_date !== null" v-html="FormatDate(row.due_date)" class="fw-semi-bold fontbbi">{{row.due_date}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.artikel}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.sample}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.qty_plan}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.qty_packing}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.tipe}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.visit}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.user}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.status}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
  <button @click="prevPage" :disabled="cantGoBack">Previous</button> <span style="padding-left: 5px;"></span>
  <button @click="nextPage" :disabled="cantGoNext">Next</button>
  </p>
          </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Widget from '@/components/Widget/Widget';
// import appsStore from '@/store/apiApps.js';

export default {
        name: 'DashboardDelivery',
        components: { Widget },

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                errorMessage: null,
                today:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                currentSort:'status',
                currentSortDir:'desc',
                oneDay:oneDay,
                pageSize:10,
                totalData:0,
                currentPage:1,
                filter: '',
                filterOn: [],
                form : {
                    fullname:null,
                    role:null,
                },

            }
        },
  computed: {
    ...mapState('application', ['stAppAccess']),
    ...mapGetters('session', ['userData']),
    ...mapState('ppm', ['stPPCMDelData']),
    cantGoBack() {
      return this.currentPage === 1;
    },
    filteredRows() {
      if (this.stPPCMDelData !== null) {
    return this.stPPCMDelData.filter(row => {
      const status = row.status.toLowerCase();
      const io = row.io.toString().toLowerCase();
      const artikel = row.artikel.toString().toLowerCase();
      const due_date = row.due_date.toString().toLowerCase();
      const searchTerm = this.filter.toLowerCase();

      return status.includes(searchTerm) ||
        io.includes(searchTerm) ||
        artikel.includes(searchTerm) ||
        due_date.includes(searchTerm);
    }).sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
      }
  },
  },
  methods: {
    ...mapActions('session', ['showNotification']),
    ...mapActions('application', ['clearState', 'getApplicationAccess']),
    ...mapActions('ppm', ['clearStatePpm','getAllDelivery']),
    highlightMatches(text) {
    const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
    if (!matchExists) return text;
    const re = new RegExp(this.filter, 'ig');
    return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);

    },
    Loading(value) {
        if (value == 'start') {
        this.loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            color: '#f89830',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
            });
        }
        if (value == 'stop') {
        // Set delay 1 second
        setTimeout(() => {
            this.loader.hide()
        }, 1000);
        }
    },
    Setdata_IO() {
        let userData     = this.userData
        this.$set(this.form, 'fullname', userData.fullname)
        // this.$set(this.form, 'role', userData.role)
        if (userData.roles) {
          for (let a=0; a<userData.roles.length; a++) {
            if (userData.roles[a].app == 'PPCM') {
              this.form.role = userData.roles[a].role_list
            }
          }
        }
        if (this.form.role.includes('subcon')) {
          this.$router.push({name:"ApplicationPage"})
          this.showNotification({type:'error', message:"you don't have authorization to view this page"})
        }
    },
    cantGoNext() {
      if ((this.currentPage*this.pageSize) > this.totalData) {
        return true
      } else {
        return false
      }
    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
          return ' '
      } else {
      let date = new Date(value)
      let tgl = date.toISOString().slice(8,10)
      let bulan = this.monthNames[date.getMonth()]
      let tahun = date.toISOString().slice(0,4)
      date = tgl+' '+bulan+' '+tahun
      return date
      }
    },
    chkDateOnSchedule(param1, param2, param3){
        let duedate   = (param1);
        let today     = (param2);
        let status    = param3.toLowerCase();
        // console.log(duedate);
        // console.log(today);
        // console.log(status);

        if (status == 'new' || status == 'pending') {
          return 'new'
        }
         if (duedate < today) {
          return 'red'
        }
        if (duedate >= today) {
          return 'green'
        }
      },
    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.stPPCMDelData.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        totalPage() {
         this.totalRows = this.stPPCMDelData.length
        },

    populateGroupTitle(title) {
      this.curGroup = title
      return "<h5><span class='fw-semi-bold'>"+ title +"</span></h5>"
    },
    IOPPCM(data) {
      // this.$router.push({ name: 'PPCMInputDelivery', params: { data_io: data } })
      let routeData = this.$router.resolve({ name: 'PPCMInputDelivery', query: { io: data }})
      // console.log(routeData.href)
      // window.open(routeData.href, '_blank')
      window.open(routeData.href, '_self')
    },
    chkUrlTarget(formUrl){
      let chkUrlString = formUrl.substring(0, 3)

      if(chkUrlString === '/#/') {
        return "_self"
      }
      else {
        return "_blank"
      }
    },
  },
  mounted() {

  },
  created() {
    this.Loading('start')
    this.getAllDelivery()
    .then(rsp=> {
      console.log(rsp)
      if (this.rsp != 'No Data') {
        this.totalData = rsp.length
      }
      this.Setdata_IO()

    })
    .catch(err => {
      if(err.response.status !== 401) {
        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
      }
    })
    .finally(()=>{
      this.Loading('stop')
    })
    this.clearState()
    this.clearStatePpm()
  }
};
</script>
<style src="../InputPPM/DashboardPPCM.scss" lang="scss" scoped />
