// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import VueTouch from 'vue-touch';
import Toasted from 'vue-toasted';
import VuePivottable from 'vue-pivottable/dist/vue-pivottable.css';

import store from './store';
import router from './Routes';
import App from './App';
import VueDatePicker from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import VueGtag from "vue-gtag";
import vSelect from "vue-select"
import VCalendar from 'v-calendar';

// Use v-calendar & v-date-picker components
Vue.use(VCalendar)

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.component('v-select', vSelect)

// ApexChart
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(VueTouch);
Vue.use(Toasted, {duration: 10000});
Vue.use(VuePivottable)
Vue.use(VueDatePicker, {
  lang: 'en'
});
const gAnalyticID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID || '-'
if(process.env.VUE_APP_GOOGLE_ANALYTICS_ENABLED === 'true') {
  Vue.use(VueGtag, {
    config: { id: gAnalyticID}
  }, router)
}

//import vee-validate
import {
  ValidationObserver,
  ValidationProvider,
  extend
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
});
