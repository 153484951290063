import { render, staticRenderFns } from "./HealthDeclarationReportV2.vue?vue&type=template&id=24ab7680&scoped=true&"
import script from "./HealthDeclarationReportV2.vue?vue&type=script&lang=js&"
export * from "./HealthDeclarationReportV2.vue?vue&type=script&lang=js&"
import style0 from "./HealthDeclarationReport.scss?vue&type=style&index=0&id=24ab7680&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ab7680",
  null
  
)

export default component.exports