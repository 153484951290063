import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchFormMasterSubcon,
    fnApiFetchFormMasterSubconDetail,
    fnApiFetchFormInputPPM,
    fnApiFetchFormInputPPMDetail,
    fnApiFetchApproveInputPPM,
    fnApiFetchApproveInputPPMDetail,
    fnApiFetchMonitoringPPM,
    fnApiFetchFormSubconPPM,
    fnApiFetchFormSubconCompPPM,
    fnApiFetchUpdateInputPPM,
    fnApiFetchFormInspectionPPCM,
    fnApiFetchUpdateInspectionPPCM,
    fnApiFetchAddFormInspectionPPCM,
    fnApiFetchFormDeliveryPPCM,
    fnApiFetchUpdateDeliveryPPCM,
    fnApiFetchAddFormDeliveryPPCM,
    fnApiFetchFormReceivePPCM,
    fnApiFetchAddFormReceivePPCM,
    fnApiFetchUpdateReceivePPCM,
    fnApiFetchUploadFile,
    fnApiFetchUpdateUploadFile,
    fnApiFetchUploadImage,
    fnApiFetchUpdateUploadImage,
// PPCM Adjustment
    fnApiFetchCreateAdjustment ,
    fnApiFetchUpdateAdjustment ,

// PPCM Order Reason
    fnApiFetchCreateOrderReason ,

// API_V2
    // Save Subcon Daily
    fnApiFetchCreateUpdateSubconDailyV2 ,
    // Save PPM
    fnApiFetchCreateUpdateInputOrderPPMV2,
    // Save Delivery
    fnApiFetchUpdateCompletedDeliveryV2,
    // Save Receive
    fnApiFetchUpdateCompletedReceiveV2,
} from '../../api/api-applications-ppm.js'

Vue.use(Vuex)


const actions = {
        putFormMasterSubcon(context, formDaily) {
            console.log(formDaily)
        return new Promise((resolve, reject) => {
            fnApiFetchFormMasterSubcon(formDaily)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        postFormMasterSubcon(context, formDaily) {
            console.log(formDaily)
        return new Promise((resolve, reject) => {
            fnApiFetchFormMasterSubconDetail(formDaily)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        PostSubconSave(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchFormSubconPPM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

        PutInputPPM(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateInputPPM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        PostSubconComplete(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchFormSubconCompPPM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        putInputOrderPPM(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchFormInputPPM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        postInputOrderPPMDetail(context, formPPM) {
            console.log(formPPM)
        return new Promise((resolve, reject) => {
            fnApiFetchFormInputPPMDetail(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        putApprovePPCM(context, formPPM) {
            console.log(formPPM)
        return new Promise((resolve, reject) => {
            fnApiFetchApproveInputPPM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        postApprovePPCMDetail(context, formPPM) {
            console.log(formPPM)
        return new Promise((resolve, reject) => {
            fnApiFetchApproveInputPPMDetail(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        putMonitoringPPM(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchMonitoringPPM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        PostInspection(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchFormInspectionPPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        UpdateFormInspection(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateInspectionPPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        AddFormInspection(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchAddFormInspectionPPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

        PostDelivery(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchFormDeliveryPPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        AddDelivery(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchAddFormDeliveryPPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        UpdateFormDelivery(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateDeliveryPPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        PostReceive(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchFormReceivePPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        AddReceive(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchAddFormReceivePPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        UpdateFormReceive(context, formPPM) {
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateReceivePPCM(formPPM)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        UploadFile(context, Upload) {
            // console.log(Upload)
        return new Promise((resolve, reject) => {
            fnApiFetchUploadFile(Upload)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        UpdateUploadFile(context, Upload) {
            // console.log(Upload)
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateUploadFile(Upload)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        UploadImage(context, Upload) {
            // console.log(Upload)
        return new Promise((resolve, reject) => {
            fnApiFetchUploadImage(Upload)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        UpdateUploadImage(context, Upload) {
            // console.log(Upload)
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateUploadImage(Upload)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
// PPCM Adjustment
        PostCreateAdjustment(context, formData) {
            // console.log(formData)
        return new Promise((resolve, reject) => {
            fnApiFetchCreateAdjustment(formData)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

        PutCreateAdjustment(context, formData) {
            // console.log(formData)
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateAdjustment(formData)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

//  Order Reason
        PostOrderReason(context, formData) {
            // console.log(formData)
        return new Promise((resolve, reject) => {
            fnApiFetchCreateOrderReason(formData)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

// API_V2
        // Save SubconDaily
        ApiSubconDaily_V2(context, formData) {
        return new Promise((resolve, reject) => {
            fnApiFetchCreateUpdateSubconDailyV2(formData)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },
        // Save Input Order PPM
        ApiInput_Order_PPM_V2(context, formData) {
        return new Promise((resolve, reject) => {
            fnApiFetchCreateUpdateInputOrderPPMV2(formData)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

        // Save And Completed Delivery
        ApiUpdate_Delivery_V2(context, formData) {
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateCompletedDeliveryV2(formData)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

        // Save And Completed Receive
        ApiUpdate_Receive_V2(context, formData) {
        return new Promise((resolve, reject) => {
            fnApiFetchUpdateCompletedReceiveV2(formData)
                .then(response => {

                    resolve(response)
                })
                .catch(error => {

                    reject(error)
                })
        })
        },

}

export default new Vuex.Store({
    namespaced: true,
    actions,
})