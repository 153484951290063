import { getAPI } from './api-base'

const apiPath = {
    // *API PPCM
    formmastersubcon        : 'v1/api/bbi-ppcm/edit-form-master-subcon',
    formInputPPM            : 'v1/api/bbi-ppcm/input-order-ppm',
    formInputPPMDetail      : 'v1/api/bbi-ppcm/input-order-ppm-detail',
    approveppcm             : '/v1/api/bbi-ppcm/approve-ppm',
    monitoringppm           : '/v1/api/bbi-ppcm/monitoring-ppm',
    FormSaveSubcon          : '/v1/api/bbi-ppcm/input-subcon-daily',
    FormUpdateInputPPM      : '/v1/api/bbi-ppcm/update-input-ppm',
    inspectionPPCM          : '/v1/api/bbi-ppcm/ppcm-inspection',
    AddinspectionPPCM       : '/v1/api/bbi-ppcm/add-ppcm-inspection',
    PostNPutPPCMDelivery    : '/v1/api/bbi-ppcm/post-ppcm-delivery',
    AddPPCMDelivery         : '/v1/api/bbi-ppcm/add-ppcm-delivery',
    PostNPutPPCMReceive     : '/v1/api/bbi-ppcm/post-ppcm-receive',
    AddPPCMReceive          : '/v1/api/bbi-ppcm/add-ppcm-receive',
    UploadFile              : 'v1/api/bbi-ppcm/uploadfileppcm',
    UploadImage             : 'v1/api/bbi-ppcm/uploadimageppcm',
    // PPCM Adjustment
    createupdateadjustment  : 'v1/api/bbi-ppcm/inputupdateadjustment',

    // Order Reason
    CreateUpdateOrderReason : 'v1/api/bbi-ppcm/create-input-order-reason',

    // API_V2
        // SubconDaily
    Update_and_Create_SubconDaily_v2   : 'v1/api/bbi-ppcm/create_and_update_subcon_daily_v2',
        // PPM
    Update_and_Create_InputOrderPPM_v2 : 'v1/api/bbi-ppcm/create_and_update_input_order_ppm_v2',
        // Delivery
    Update_and_Completed_Delivery_v2 : 'v1/api/bbi-ppcm/update_completed_delivery_v2',
        // Receive
    Update_and_Completed_Receive_v2 : 'v1/api/bbi-ppcm/update_completed_receive_v2',
}

// PPCM
export function fnApiFetchFormMasterSubcon(formDaily) {
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.formmastersubcon, formDaily)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchFormMasterSubconDetail(formDaily) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.formmastersubcon, formDaily)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchFormSubconPPM(formPPM) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.FormSaveSubcon, formPPM)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchFormSubconCompPPM(formPPM) {
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.FormSaveSubcon, formPPM)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchFormInputPPM(formPPM) {
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.formInputPPM, formPPM)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchFormInputPPMDetail(formPPM) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.formInputPPMDetail, formPPM)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchUpdateInputPPM(formPPM) {
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.FormUpdateInputPPM, formPPM)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchApproveInputPPM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.approveppcm, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchApproveInputPPMDetail(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.approveppcm, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchMonitoringPPM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.monitoringppm, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchAddFormInspectionPPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.AddinspectionPPCM, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchFormInspectionPPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.inspectionPPCM, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchUpdateInspectionPPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.inspectionPPCM, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchFormDeliveryPPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.PostNPutPPCMDelivery, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchAddFormDeliveryPPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.AddPPCMDelivery, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchUpdateDeliveryPPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.PostNPutPPCMDelivery, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchFormReceivePPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.PostNPutPPCMReceive, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchAddFormReceivePPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.AddPPCMReceive, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchUpdateReceivePPCM(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.PostNPutPPCMReceive, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiFetchUploadFile(Upload) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.UploadFile, Upload)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchUpdateUploadFile(Upload) {
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.UploadFile, Upload)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchUploadImage(Upload) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.UploadImage, Upload)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiFetchUpdateUploadImage(Upload) {
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.UploadImage, Upload)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
// PPCM Adjustment
export function fnApiFetchCreateAdjustment(formData) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.createupdateadjustment, formData)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
// PPCM Update Adjustment Approve
export function fnApiFetchUpdateAdjustment(formData) {
    return new Promise((resolve, reject) => {
        getAPI.put(apiPath.createupdateadjustment, formData)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}

// Post Order Reason
export function fnApiFetchCreateOrderReason(formData) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.CreateUpdateOrderReason, formData)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}


// Api V2

    // Save, Receive, Complete, And Close SubconDaily
export function fnApiFetchCreateUpdateSubconDailyV2(formData) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.Update_and_Create_SubconDaily_v2, formData)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
    // Save InputOrderPPM
export function fnApiFetchCreateUpdateInputOrderPPMV2(formData) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.Update_and_Create_InputOrderPPM_v2, formData)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
    // Update Completed Delivery
export function fnApiFetchUpdateCompletedDeliveryV2(formData) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.Update_and_Completed_Delivery_v2, formData)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
    // Update Completed Receive
export function fnApiFetchUpdateCompletedReceiveV2(formData) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.Update_and_Completed_Receive_v2, formData)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}