<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-approval-activity-weekly">Approval Weekly Activity</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Approval Weekly Activity Plan" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3>Approval Weekly Activity Plan</h3>
                <router-link tag="b" to="/app/sfc-approval-activity-weekly" style="position: absolute; right: 15px;">
                  <b-button size="md" type='button' variant="info">
                    <b-icon icon="arrow-left"></b-icon>
                    Back
                  </b-button>
                </router-link>
              </div>

            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="NIK"
                      label-for="Employee_ID"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="Employee_ID"
                        :disabled="true"
                        v-model="form.employee_nik"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Sales Person"
                      label-for="EmployeeName"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="EmployeeName"
                        :disabled="true"
                        v-model="form.employee_name"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Plan ID"
                      label-for="ID_Transaction"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="ID_Transaction"
                        :disabled="true"
                        v-model="form.transaction_id"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-col sm='4'>
                  <b-form-group
                    label="Period"
                    label-for="Period"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <VueDatePicker
                      class="vd__div"
                      v-model="form.period"
                      no-header
                      :disabled="true"
                      type="month"
                      format="MMM YYYY"
                      :min-date="minMonthDate"
                      placeholder="Choose date"
                      />
                    </b-form-group>
                </b-col>
              </b-list-group-item>
            </b-list-group>
            <b-table
              :no-border-collapse="true"
              striped
              stacked="md"
              class="header-background max_width_fit"
              hover
              small
              :tbody-tr-class="rowClass"
              show-empty
              responsive
              :fields="fields"
              :items="dataTable"
            >
              <!-- table empty -->
              <template #empty="scope">
                  <p class="text-center">{{ scope.emptyText }}</p>
              </template>

              <!-- Plan Date -->
              <template #cell(plan_date)="row">
                  <span v-if="row.value !== null" v-html="FormatDate(row.value)"></span>
              </template>

              <!-- Activity -->
              <template #cell(activity_name)="row">
                  <span v-if="row.value !== null" v-html="ActivityReplace(row.value)"></span>
              </template>

              <!-- Status Approval -->
              <template #cell(approval_status)="row">
                  <span v-if="row.value !== null" v-html="FormatStatus(row.value)"></span>
              </template>

              <!-- <template v-slot:cell(actions)="{item}"> -->
              <template #cell(action)="row">
                <b-form-group>
                  <b-form-radio-group
                    v-model="selectedApprove[row.index]"
                    :options="optionsAction"
                    :required='true'
                    @change="onChange(row.item , row.item.customer, row.index)"
                    value-field="value"
                    :disabled="row.item.approval_status == 'approved'"
                    :checked="isChecked"
                    text-field="name"
                  >
                  </b-form-radio-group>
                  <span :id="'Check-'+row.index" class="display-hidden text-very-small text-danger">"Wajib di Isi"</span>
                </b-form-group>
              </template>
            </b-table>
          </b-card>

          <!-- Reject Not Ok -->
          <template>
            <b-modal
                v-model="showModal"
                ok-variant="success"
                cancel-variant="warning"
                header-bg-variant="secondary"
                hide-footer
                hide-header-close
                hide-backdrop
                size="md"
                no-close-on-backdrop
                title="Are you Sure Reject ?"

            >
                <div id="modal">
                  <validation-observer ref="observer" v-slot="{handleSubmit}">
                    <b-form @submit.prevent="handleSubmit(submitReject)" >
                      <b-row>
                        <b-col cols="12">
                            <b-form-group
                              label               ="Reason"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-textarea
                              id="textarea-no-resize"
                              placeholder="Enter Your Reason"
                              v-model="ReasonReject"
                              rows="3"
                              ref="reasonModal"
                              no-resize
                            ></b-form-textarea>
                            <span id="ReasonWarrning" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                            </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row align-h="end">
                        <b-col sm='4'>
                          <b-button block size="md" @click="submitReject()" type='submit' variant="success">
                            Submit
                          </b-button>
                        </b-col>
                        <b-col sm='4'>
                          <b-button block size="md" @click="cancel()" type='button' variant="warning">
                            Cancel
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
            </b-modal>
          </template>

          <!-- Reject Reason -->
          <template>
            <b-modal
                v-model="showModalReject"
                ok-variant="success"
                cancel-variant="warning"
                header-bg-variant="secondary"
                hide-footer
                hide-header-close
                hide-backdrop
                size="md"
                no-close-on-backdrop
                title="Are you Sure Reject ?"

            >
                <div id="modal">
                  <validation-observer ref="observer">
                    <b-form>
                      <b-row>
                        <b-col cols="12">
                            <b-form-group
                              label               ="Reason"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-textarea
                              id="textarea-no-resize"
                              placeholder="Enter Your Reason"
                              v-model="Remark"
                              rows="3"
                              ref="reasonModal"
                              no-resize
                            ></b-form-textarea>
                            <span id="RemarkWarrning" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                            </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row align-h="end">
                        <b-col sm='4'>
                          <b-button block size="md" @click="SubmitRejected()" type='submit' variant="success">
                            Submit
                          </b-button>
                        </b-col>
                        <b-col sm='4'>
                          <b-button block size="md" @click="cancelRejected()" type='button' variant="warning">
                            Cancel
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
            </b-modal>
          </template>

          <br />
          <b-row align-h="center">
              <b-col md='3'>
                <b-button block v-if="buttonSubmit" size="md" @click="Confirm('approved')" type='submit' variant="success">
                  Approve
                </b-button>
              </b-col>
              <b-col md='3'>
                <b-button block v-if="buttonSubmit" size="md" @click="Rejected()" type='submit' variant="danger">
                  Reject
                </b-button>
              </b-col>
          </b-row>
        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import apiWeeklyApproval from '@/store/salesforce-form/weekly-approval.js';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";

export default {
  components: {
    ModelListSelect,
    Loader,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'short'};
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "Approval Weekly Activity Plan",
      minMonthDate:null,
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      showModal:false,
      showModalReject:false,
      today:today,
      isChecked:false,
      headerTitle:null,
      disabledForm:false,
      buttonSubmit:null,
      showHidden:true,
      today_days:days,
      today_month:month,
      StrMonth:Strmonth,
      selectedApprove:[],
      previousSelected:[],
      today_year:year,
      optionsAction:[
        { name: 'OK', value: 'ok' },
        { name: 'NOT OK', value: 'not_ok' },
      ],
      fields:[
          {key:'action', label:'Action', class:"display-hidden", sortable:false },
          {key:'approval_status', label:'Approval Status', class:"display-hidden", sortable:false },
          {key:'project_id', label:'project id', sortable:true },
          {key:'customer_name', label:'customer_name', sortable:false },
          {key:'customer_id' ,class: 'display-hidden'},
          {key:'plan_date', label:'Plan Date', sortable:false },
          {key:'activity_name', label:'Plan', sortable:false },
          {key:'remark', label:'Remark' , sortable:false },
          {key:'reason', label:'Reason' , sortable:false },
      ],
      dataTable:[],
      ReasonReject:null,
      ModalID:null,
      idDataModal:null,
      NameActivity:null,
      ModalPlanDate:null,
      WeeklyApproval:null,
      WeeklyPlanning:null,
      RemarkModel:null,
      ProjectID:null,
      Remark:null,
      employee_nik:null,
      employee_name:null,
      dataTableApproval:[],
      form :{
        employee_nik:null,
        employee_name:null,
        period:null,
        expired_date:null,
        transaction_id:null,
      },

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState('sales_force', ['stWeekApp','stWeekAppCount','stDtWeekApp','stDtWeekAppCount']),

  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions('general', ['fetchCityList']),
    ...mapActions('sales_force', ['getDashboardWeeklyApproval','getDataWeeklyApproval']),

    setData() {
      this.employee_nik               = this.userData.nik
      this.employee_name              = this.userData.fullname
      this.form.employee_nik          = this.userData.nik

      let expired_date    = new Date(this.today)
      let resultExpired   = expired_date.setDate(expired_date.getMonth() + 6 );
      this.form.expired_date = new Date(resultExpired).toISOString().slice(0,10)

      this.form.employee_name         = this.userData.fullname
      let idData                      = this.$route.params.idData
      let statusRoute                 = this.$route.params.page
      if (this.$route.params.page == 'view' || this.$route.params.page == 'edit') {
        if (this.$route.params.page == 'edit') {
          this.fields[0].class = ''
          this.fields[7].class = 'display-hidden'
          this.buttonSubmit = true
        }
        if (this.$route.params.page == 'view') {
          this.fields[1].class = ''
          this.buttonSubmit = false
        }
      } else {
        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
        this.$router.push({name:"SFCApprovalWeekly"})
        return
      }
      if (statusRoute == undefined || statusRoute =='' ) {
        statusRoute = null
      }

      // start loading
      this.isLoadingData = true

      this.getDashboardWeeklyApproval({
      id:idData,
      })
      .then(rsp => {
          if(this.stWeekAppCount == 0) {
              this.showNotification({type:'info', message:'Data Not Found'})
          }
          this.getDataWeeklyApproval({
            idData:idData,
            })
            .then(rsp => {
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);

                if(this.stDtWeekAppCount == 0) {
                    this.showNotification({type:'info', message:'Data Detail Not Found'})
                }
                this.setDataForm()
            })
            .catch(err => {
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);

                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                    this.$router.push({name:"SFCApprovalWeekly"})
                }
            })
      })
      .catch(err => {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
          if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
              this.$router.push({name:"SFCApprovalWeekly"})
          }
      })
    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
        return ' '
      } else {
        let date = new Date(value)
        let day = date.getDate()
        if (String(day).length == 1) {
          day = "0"+String(day)
        }
        let year = date.getFullYear()
        let bulan = this.monthNames[date.getMonth()]
        date = day+' '+bulan+' '+year
        return date
      }
    },
    ActivityReplace(value) {
      let string = value
      if (value !== null) {
        string = value.replace(/[^a-zA-Z0-9,()/& ]/g, "");
      }
      return string
    },
    setDataForm() {
      let FormData = this.stWeekApp
      let FormDataDetail = this.stDtWeekApp
      this.form.period = FormData[0].period_date
      this.form.employee_nik = FormData[0].created_by
      this.form.transaction_id = FormData[0].transaction_id
      this.form.employee_name = FormData[0].sales_name
      this.WeeklyPlanning = FormData[0].weekly_planning
      this.WeeklyApproval = this.$route.params.idData
      this.dataTable = FormDataDetail
    },
    rowClass(item, type) {
      let trClass= null
      if (item !== null) {
        if (item.approval_status == 'rejected' && this.$route.params.page == 'edit') {
          trClass = 'display-hidden'
        }
        // if (item.approval_status == 'rejected' && this.$route.params.page == 'view') {
        //   trClass = 'row-danger'
        // }
      }
      return trClass

    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    FormatStatus(value) {
        if (value.length > 0) {
            value = value.toLowerCase()
            if (value.includes('waiting')) {
                value = "Waiting Approval"
            }
            if (value.length > 1) {
                value = value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
        return value
    },
    cancel() {
      this.selectedApprove[this.ModalID] = null
      this.showModal = false
      this.isChecked = true
      this.ReasonReject = null
      this.NameActivity = null
      this.idDataModal = null
      this.RemarkModel = null
      this.ProjectID = null
      this.ModalPlanDate = null
    },
    cancelRejected() {
      this.showModalReject = false
    },
    onChange (item, customer_id, index) {
      this.isChecked = false
      this.ModalID = index
      this.idDataModal = item.id
      this.RemarkModel = item.remark
      this.ProjectID = item.project_id
      this.idCustomerModal = customer_id
      this.NameActivity = item.activity_name
      this.ModalPlanDate = item.plan_date
      if (this.selectedApprove[index] == 'not_ok') {
        this.ReasonReject = null
        this.showModal = true
      } else {
        this.dataTableApproval[this.ModalID] = ({
          reason:null,
          approval_status:'approved',
          project_id:this.ProjectID,
          approval_id:this.idDataModal,
          remark:this.RemarkModel,
          customer_id:this.idCustomerModal,
          plan_date:this.ModalPlanDate,
          activity_name:this.NameActivity,
        })
      }
    },
    Rejected() {
      this.Remark = null
      this.showModalReject = true
    },
    SubmitRejected() {
      if (this.Remark == null || this.Remark == undefined || this.Remark.substring(0, 1) == ' ' || this.Remark.substring(0, 1) == '' ) {
        document.getElementById("RemarkWarrning").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("RemarkWarrning").classList.add("display-hidden");
          }, 5000);
          return false
      }
      this.Confirm('rejected')
    },
    submitReject() {
      if (this.ReasonReject == null || this.ReasonReject == undefined || this.ReasonReject.substring(0, 1) == ' ' || this.ReasonReject.substring(0, 1) == '' ) {
        document.getElementById("ReasonWarrning").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("ReasonWarrning").classList.add("display-hidden");
          }, 5000);
          return false
      }
      this.dataTableApproval[this.ModalID] = ({
        reason:this.ReasonReject,
        approval_status:'rejected',
        approval_id:this.idDataModal,
        plan_date:this.ModalPlanDate,
        remark:this.RemarkModel,
        project_id:this.ProjectID,
        activity_name:this.NameActivity,
        customer_id:this.idCustomerModal,
      })
      this.showModal = false
    },
    CheckFormChoice() {
      let process = true
      let len = this.dataTable.length
      for (let a=0; a<=len-1; a++) {
        if (this.dataTable[a].approval_status == 'waiting_approval') {
          if (this.selectedApprove[a] == null || this.selectedApprove[a] == undefined) {
            document.getElementById("Check-"+a).classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("Check-"+a).classList.add("display-hidden");
            }, 5000);
            process = false
          }
        }
      }
      return process
    },
    Confirm(choice, index) {
      let varOk = 'danger'
      if(choice !== 'delete') {
        varOk = 'success'
      }
      let Boxtrue = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to '+choice+' ? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: varOk,
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          Boxtrue = value
          if (Boxtrue == true) {
            if (this.CheckFormChoice()) {
              this.SaveApproval(choice)
            }
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    SaveApproval(choice) {
      // start loading
      this.isLoadingData = true

      apiWeeklyApproval.dispatch('WeeklyApproval',{
        weekly_approval:this.$route.params.idData,
        weekly_planning:this.WeeklyPlanning,
        approval_status:choice,
        remark:this.Remark,
        sales_nik:this.employee_nik,
        sales_name:this.employee_name,
        expired_date:this.form.expired_date,
        data:this.dataTableApproval,
      })
      .then(response => {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCApprovalWeekly"})
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
  },

  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />