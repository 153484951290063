<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">{{formName}}</span> </h2>
    <b-row> 
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          <b-row class="form">
            <!-- datepicker From -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">From*</label>
                <b-form class="datepicker">
                  <VueDatePicker
                  v-model="selectedDate"
                  class="vd__div"
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="max_date"
                  placeholder="Choose report date start"
                />
                </b-form>
                </div>              
            </b-col>
            <!-- end datepicker From -->

            <!-- Filter Dept Code -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
              <label>Department*</label>
                <b-form-select ref="opt_dept" class="form-custom" v-model="selectedDept" placeholder="Choose Department Code"> 
                  <!-- option choose dept value none -->
                  <option :value="null">-- Choose Department Code --</option>
                  <!-- end option choose dept value none -->
                  <option v-for="(row,index) in stDeptList" :key="index" :value="row.dept_code" :selected="selectedDept">
                       {{row.dept_code}}      
                  </option>                        
                </b-form-select>
            <div class="border-bottom-select"></div>
            </div>
            </b-col>
            <!-- end Filter Dept Code --> 
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button class='btn-spacing' type="button" variant="primary" v-on:click="populateReport()">Load Data</b-button>
                <download-excel
                    :class   = "exportButtonClass()"
                    :data   = "rptData"
                    :fields = "excel_fields"
                    :title   = "excel_title"
                    :name    = "excel_name">
                    Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToApplication()">Cancel</b-button>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Processed at</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{processedDateTime}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Total Data</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{rptDataCount}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Late/Early submission</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumDateNotMatch}}</b-col>
            </b-row>
             <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Resiko Tinggi</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumHighRisk}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Resiko Sedang</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumMedRisk}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Resiko Rendah</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumLowRisk}}</b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col xs="12">
        <div class="table-responsive">
          <table class="table table-lg mb-0">
            <thead>
              <tr style="text-align:center;" class="text-muted">
                <th>FORM VERIFIKASI</th>
                <th>TANGGAL SIMPAN</th>
                <th>TANGGAL</th>
                <th>NAMA</th>
                <th>NIK</th>
                <th>DEPARTMENT</th>
                <!-- <th>SUHU > 37.2</th> -->
                <th>KONDISI KESEHATAN</th>
                <th>NILAI RESIKO</th>
                <th>PROFIL RESIKO</th>
                <th>BEPERGIAN</th>
                <!-- <th>TUJUAN</th>    -->
                <th>TEMPAT KERJA</th>
                <th>TRANSPORTASI</th>
                <th>LAYANAN KESEHATAN</th>
                <th>NAMA LAYANAN KESEHATAN</th>
                <th>BERBELANJA</th>
                <th>LOKASI BERBELANJA</th>
                <th>SWALAYAN</th>
                <th>KEGIATAN KEAGAMAAN</th>
                <th>PESTA</th>
                <th>CLUB OLAHRAGA</th>
                <th>MAKAN DI RESTORAN</th>
                <th>PERUSAHAAN LAIN</th>
                <th>TEMPAT HIBURAN</th>
                <th>KELUAR KOTA</th>
                <th>TUJUAN KELUAR KOTA</th>
                <th>KELUARGA SAKIT</th>
                <th>KEKERABATAN</th>
                <th>PENYAKIT KERABAT</th>
                <th>KUNJUNGAN TAMU</th>
                <!-- <th>NAMA TAMU</th> -->
                <th>RIWAYAT KONTAK 14 HARI</th>
              </tr>
            </thead>
            <tbody>            
              <tr style="text-align:center;"
                v-for="(row, i) in stRptData"
                :key="row.id"
                :id="'rowData_'+row.nik"
              >
                <validation-observer :ref="'observer_'+ row.nik" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(() => onSubmit(i))">
                    <td>
                    <b-button type="submit" v-on:click="doApproval('APPROVED', row.nik, row.tanggal_harian, validation)" variant="primary" 
                    style="margin-bottom: 10px; width: -webkit-fill-available;">                
                    Approved</b-button>

                    <b-button type="submit" :class="chkCanReject(row.nik)" v-on:click="doApproval('REJECTED', row.nik, row.tanggal_harian)" 
                    style="width: -webkit-fill-available;"
                    variant="warning">
                    Rejected</b-button>
                    </td>
                    <td>
                      <validation-provider
                      name="keterangan"
                      :rules="checkMandatoryRemark(row.nik)"
                      v-slot="validationContext">
                        <b-form-group cols="12" label-for="inputKeterangan" aria-describedby="input-keterangan-feedback">
                          <b-form-textarea style="width: auto;"
                            size="md"
                            :state="getValidationState(validationContext)" 
                            :id="'inputKeterangan_'+ row.nik" 
                            :name="'inputKeterangan_'+ row.nik" 
                            :vid="'inputKeterangan_'+ row.nik" 
                            placeholder="Masukkan keterangan"
                            v-model="keterangan['_'+row.nik]"
                            rows="3"
                            no-resize
                            trim>
                          </b-form-textarea>
                          <b-form-invalid-feedback  :state="getValidationState(validationContext)"  id="input-keterangan-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </td>
                  </form>
                </validation-observer>                
                <td :class="chkDateNotMatch(row.tanggal_simpan_char, row.tanggal_harian_char)">{{row.tanggal_simpan_char}}</td>
                <td>{{row.tanggal_harian_char}}</td>                
                <td>{{row.nama_karyawan}}</td>
                <td>{{row.nik}}</td>
                <td>{{row.department}}</td>
                <!-- <td>{{row.suhu}}</td> -->
                <td :class="chkSickCounter(row.count_lama_sakit)">{{row.kondisi_kesehatan}}</td>
                <td :class="chkNilaiResiko(row.profil_resiko)">{{row.nilai_resiko}}</td>
                <td :class="chkProfilResiko(row.profil_resiko)">{{row.profil_resiko}}</td>
                <td>{{row.bepergian}}</td>
                <!-- <td>{{row.tempat_tujuan}}</td> -->
                <td>{{row.tempat_kerja}}</td>
                <td>{{row.transportasi}}</td>
                <td>{{row.layanan_kesehatan}}</td>
                <td>{{row.nama_layanan_kesehatan}}</td>
                <td>{{row.berbelanja}}</td>
                <td>{{row.lokasi_berbelanja}}</td>
                <td>{{row.warung_tradisional}}</td>
                <td>{{row.kegiatan_keagamaan}}</td>
                <td>{{row.pesta}}</td>
                <td>{{row.club_olahraga}}</td>
                <td>{{row.makan_direstoran}}</td>
                <td>{{row.perusahaan_lain}}</td>
                <td>{{row.tempat_hiburan}}</td>
                <td>{{row.keluar_kota}}</td>
                <td>{{row.tujuan_keluar_kota}}</td>
                <td>{{row.keluarga_sakit}}</td>
                <td>{{row.nama_keluarga_sakit}}</td>
                <td>{{row.kondisi_keluarga_sakit}}</td>
                <td>{{row.tamu}}</td>
                <!-- <td>{{row.nama_tamu}}</td> -->
                <td>{{row.riwayat_covid}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue'
import apiDailyV2 from '@/store/health-form/health-daily.js';
import JsonExcel from 'vue-json-excel'
// import vue loading
import Loading from 'vue-loading-overlay';
// Import vue loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

Vue.component('download-excel', JsonExcel)

export default {
    name: 'DailyHealthReport',
    
    data() {
      const now = new Date()
      const today = now.toISOString().slice(0,10)
      return {
        formName: 'Verifikasi Kesehatan Harian',
        form:[],
        keterangan:[],
        isMandatory:[],
        max_date:today,
        activeRow:0,
        approvalStatus:null,
        rptVersion:'v1.0.1',
        processedDateTime:"",
        selectedDate: null,
        selectedDept: null,
        errorMessage: null,
        countDateNotMatch: 0,
        countHighRisk: 0,
        countMedRisk: 0,
        countLowRisk: 0,
        maxDate: null,
        excel_title: '',
        excel_name: '',
        excel_fields: {
          'TANGGAL SIMPAN'    : 'tanggal_simpan_char',
          'TANGGAL'           : 'tanggal_harian',          
          'NAMA'              : 'nama_karyawan',
          'NIK'               : 'nik',
          'DEPARTMENT'        : 'department',
          // 'SUHU'              : 'suhu',
          'KONDISI KESEHATAN' : 'kondisi_kesehatan',
          'NILAI RESIKO'      : 'nilai_resiko',
          'PROFIL RESIKO'     : 'profil_resiko',
          'BEPERGIAN'         : 'bepergian',
          // 'TUJUAN'            : 'tempat_tujuan',
          'TEMPAT KERJA'      : 'tempat_kerja',
          'TRANSPORTASI'      : 'transportasi',
          'LAYANAN KESEHATAN' : 'layanan_kesehatan',
          'NAMA LAYANAN KESEHATAN': 'nama_layanan_kesehatan',
          'BERBELANJA'        : 'berbelanja',
          'LOKASI BERBELANJA' : 'lokasi_berbelanja',
          'SWALAYAN'          : 'warung_tradisional',
          'KEGIATAN KEAGAMAAN' : 'kegiatan_keagamaan',
          'PESTA'             : 'pesta',
          'CLUB OLAHRAGA'     : 'club_olahraga',
          'MAKAN DI RESTORAN' : 'makan_direstoran',
          'PERUSAHAAN LAIN'   : 'perusahaan_lain',
          'TEMPAT HIBURAN'    : 'tempat_hiburan',
          'KELUAR KOTA'       : 'keluar_kota',
          'TUJUAN KELUAR KOTA': 'tujuan_keluar_kota',
          'KELUARGA SAKIT'    : 'keluarga_sakit',
          'KEKERABATAN'       : 'nama_keluarga_sakit',
          'PENYAKIT KERABAT'  : 'kondisi_keluarga_sakit',
          'KUNJUNGAN TAMU'    : 'tamu',
          // 'NAMA TAMU'         : 'nama_tamu',
          'RIWAYAT KONTAK 14 HARI'  : 'riwayat_covid',
        },
      }
    },
    computed: {
      ...mapState('report', ['stDeptList', 'stRptData', 'stRptDataCount', 'stActiveReport']),
      ...mapGetters('report', ['rptDataCount', 'rptData', 'hasData']),
      ...mapGetters('session', ['userData', 'localDate']),
      sumHighRisk() {
        let percentage    = 0
        if(this.countHighRisk > 0) {
          percentage    = parseFloat(this.countHighRisk) * 100.00 / this.rptDataCount
        }
        return this.countHighRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumMedRisk() {
        let percentage    = 0
        if(this.countMedRisk > 0) {
          percentage    = parseFloat(this.countMedRisk) * 100.00 / this.rptDataCount
        }
        return this.countMedRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumLowRisk() {
        let percentage    = 0
        if(this.countLowRisk > 0) {
          percentage    = parseFloat(this.countLowRisk) * 100.00 / this.rptDataCount
        }
        return this.countLowRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumDateNotMatch() {
        let percentage    = 0
        if(this.countDateNotMatch > 0) {
          percentage    = parseFloat(this.countDateNotMatch) * 100.00 / this.rptDataCount
        }
        return this.countDateNotMatch + " ( " + percentage.toFixed(2) + "%)"
      },
    },
    methods: {
      ...mapActions('report', ['initializeReport', 'getListDepartment', 'getDataDailyHealthVerification', 'getCurrentDatetime','generateTitleXLS']),
      ...mapActions('session', ['showNotification']),
      populateReport() {
        this.resetReportSummary()
        if (this.selectedDate === null || this.selectedDept === null) {
          this.errorMessage = "Please select Date and Department filter"
          this.$refs.opt_dept.$el.classList.value = this.$refs.opt_dept.$el.classList.value + ' animation-shake'
          setTimeout(() => {
          this.$refs.opt_dept.$el.classList.remove('animation-shake');
          },1500)
        }
        else {
          let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  color: '#f89830',
                  loader: 'dots',
                  width: 64,
                  height: 64,
                  backgroundColor: '#ffffff',
                  opacity: 0.5,
                  zIndex: 999,
                  
                });
                // show report after timeout min 1 second
              setTimeout(() => {  
          this.getDataDailyHealthVerification({
            date: this.selectedDate,
            dateto: this.selectedDate,
            dept: this.selectedDept,
            businessunit: this.userData.business_unit
          })
          .then(rsp => {
            loader.hide() 
            this.postProcessReport(rsp) 
          })
          .catch(err => {
            loader.hide() 
            if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Failed loading report'})
              console.log(err)
            }
          })
           },1000)
        }
      },
      getValidationState(ctx) {
        return ctx.dirty || ctx.validated ? ctx.valid : null;
      },
      checkMandatoryRemark(nik){
        let rules = ''
        let isMandatory = false
        if(!this.isMandatory['_' + nik]) {
          isMandatory = this.isMandatory['_' + nik]
        }
        console.log('mandatory? ' + isMandatory)
        if(isMandatory) {
          rules = 'required'
        }

        return rules
      },
      doApproval(status, nik, tanggal_harian) {
        let vObserver = this.$refs['observer_' + nik][0]
        let remark = this.keterangan['_'+nik]
        
        // Validate form
        vObserver.validate().then(result => {
          if(result == true) {
            let verifyData = this.createFormData({tanggal_harian, nik, status, remark})
            this.form.push(verifyData)
          }
        })
        this.isMandatory['_' + nik] = false
      },
      chkCanReject(nik) {
        let cls     = ''
        let remark  = this.keterangan['_'+nik]
        if(remark == null || remark == '') {
          cls = 'btn-disabled'
        }
        return cls
      },
      createFormData({tanggal_harian=null, nik=null, status=null, remark=null, isMandatory=true}) {
        let data = {
            tanggal_harian:tanggal_harian,
            nik:nik,
            status:status,
            review_by:this.userData.username,
            review_by_name:this.userData.fullname,
            remark:remark,
            mandatory:isMandatory,
          }
        return data
      },
      postProcessReport(apiArg) {
        // console.log(apiArg)
        let cntDateNotMatch     = document.getElementsByClassName('bg-datenotmatch').length
        let cntHighRisk         = document.getElementsByClassName('bg-riskprofile-high').length
        let cntMediumRisk       = document.getElementsByClassName('bg-riskprofile-med').length
        let cntLowRisk          = document.getElementsByClassName('bg-riskprofile-low').length
        this.countDateNotMatch  = cntDateNotMatch
        this.countHighRisk      = cntHighRisk
        this.countMedRisk       = cntMediumRisk
        this.countLowRisk       = cntLowRisk
        
        // Deploy toast dialog
        this.getCurrentDatetime().then(rsp => {
          this.processedDateTime  = rsp;
          this.showNotification({type:'success', message:'Report Loaded'})
          
          this.initExcelTitle()
        })
        .catch(ex => {
          this.showNotification({type:'error', message:'Failed loading report'})
          console.log(ex)
        })
      },
      goToApplication(){
        this.$router.push({ name:"ApplicationPage" })
      },
      chkSickCounter(param){
        if(param > 0  && param < 2) {
          return 'bg-sick-yellow'
        }
        else if (param >= 2 ) {
          return 'bg-sick-red'
        }
        return ''
      },
      chkProfilResiko(val){
        if(val.toLowerCase().trim() == 'resiko tinggi') {
          return 'bg-riskprofile-high bg-sick-red'
        }
        else if(val.toLowerCase().trim() == 'resiko sedang') {
          return 'bg-riskprofile-med bg-sick-yellow'
        }
        else if(val.toLowerCase().trim() == 'resiko rendah') {
          return 'bg-riskprofile-low'
        }
        return ''
      },
      chkNilaiResiko(val){
        if(val.toLowerCase().trim() == 'resiko tinggi') {
          return 'bg-sick-red'
        }
        else if(val.toLowerCase().trim() == 'resiko sedang') {
          return 'bg-sick-yellow'
        }
        return ''
      },
      chkDateNotMatch(param1, param2){
        let varSubmitDate   = param1.toLowerCase().trim().slice(0,16)
        let varFormDate     = param2.toLowerCase().trim().slice(0,16)
        if(varSubmitDate !== varFormDate) {
          return 'bg-datenotmatch' 
        }
        
        return ''
      },
      resetReportSummary() {
        this.errorMessage = null
        this.processedDateTime = ""
      },
      exportButtonClass() {
        let classNames = 'btn btn-info'
        if(!this.hasData) {
          classNames += ' hide'
        }
        return classNames
      },
      initExcelTitle() {
        this.generateTitleXLS({
            rptName           : this.stActiveReport, 
            rptVersion        : this.rptVersion, 
            processedDateTime : this.processedDateTime, 
            processedBy       : (this.userData.username + ' - ' + this.userData.fullname)
          }).then(rsp =>{
            this.excel_name   = rsp[0]
            this.excel_title  = rsp[1]
          })
      },
        
      onSubmit(idx) {
        // do Nothing if no data is submitted
        if(this.form.length == 0) {
          return
        }
        apiDailyV2.dispatch('postDailyVerification', {
          data:this.form
        })
        .then(response => {
          console.log(response)
          this.$delete(this.stRptData, idx)
          this.showNotification({type:'success', message:'Successfully Submitted'})
        })
        .catch(err => {
          if (err.response.status === 400) {
            console.log(err.response.data.message)
            this.showNotification({type:'error', message:err.response.data.message})                  
          }
        })
        .finally(()=>{
          this.form = []
        })
      }
    },
    created() {
      this.initializeReport()    
      this.getListDepartment(this.userData.business_unit)
      this.selectedDate = this.localDate
      this.maxDate = this.localDate
    }
};
</script>


<style src="./DailyHealthVerification.scss" lang="scss" scoped />
