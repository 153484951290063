<template>
  <div class="tables-basic dahsbordAdjustment">
    <b-breadcrumb>
      <h2 class="page-title"> <span style="font-size:16px;" class="fw-semi-bold fontbbi ">Dashboard Monitoring Detail</span></h2>
    </b-breadcrumb>
   <!-- User Interface controls -->

      <b-col sm="12">
        <!-- <b-row>
          <b-col sm="4">
            <b-form-group
            label="Filter: "
            label-for="filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm">
            <b-input-group size="sm">
              <b-form-select
              id="filter"
              v-model="filter"
              size="sm"
              :options="optionsFilter"
              class="w-75"
              ></b-form-select>
            </b-input-group>
            </b-form-group>
          </b-col>
        </b-row> -->

        <b-row>
          <!-- Filter subcon -->
          <b-col sm="4">
            <b-form-group
            label="Subcon"
            label-for="subcon"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <!-- <b-form-select v-model="form.filter_subcon" :options="opt_subcon" placeholder="Choose Type" style="top: 1px; font-size:12px;">
            </b-form-select> -->
            <v-select
                label="text"
                :reduce="(data) => data.value"
                :options="opt_subcon"
                v-model="form.filter_subcon"
                :clearable=false
                ref="opt_finishing"
                style="top: 1px; font-size:12px;"
            ></v-select>
            </b-form-group>
          </b-col>

          <!-- Filter Process -->
          <b-col sm="4">
            <b-form-group
            label="Status"
            label-for="status"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
              <b-form-select v-model="form.filter_process" :change="Change_Filter()" :options="opt_process" placeholder="Choose" style="top: 1px;">
            </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Filter io -->
          <b-col sm="4">
            <b-form-group
            label="IO"
            label-for="io"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
              <b-form-input style="height: calc(1.5em + 0.75rem + 2px);"
                placeholder="Enter your IO"
                v-model="form.filter_io">
              </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>


        <b-row>
          <!-- Filter Month -->
          <b-col sm="4">
            <b-form-group
            label="Month"
            label-for="month"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <b-form-select :disabled="disabled_month" :change="Change_Filter()" v-model="form.filter_month" :options="opt_month" style="top: 1px;">
            </b-form-select>
            </b-form-group>
          </b-col>

          <!-- Filter Last Process -->
          <b-col sm="4">
            <b-form-group
            label="Process"
            label-for="process"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <b-form-select :disabled="disabled_last_process" v-model="form.filter_lastprocess" :options="opt_lastprocess" style="top: 1px;">
            </b-form-select>
            </b-form-group>
          </b-col>

          <!-- Filter Artikel -->
          <b-col sm="4">
            <b-form-group
            label="Article"
            label-for="artikel"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
              <b-form-input style="height: calc(1.5em + 0.75rem + 2px);"
                placeholder="Enter your Artikel"
                v-model="form.filter_artikel">
              </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Filter Year -->
          <b-col sm="4">
            <b-form-group
            label="Year"
            label-for="year"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
            <b-form-select :disabled="disabled_year" v-model="form.filter_year" :options="opt_year" style="top: 1px;">
            </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Filter Brand -->
          <b-col sm="4">
            <b-form-group
            label="Brand"
            label-for="brand"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
            <b-form-select v-model="form.filter_brand" :options="opt_brand" style="top: 1px;">
            </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Filter Start Date -->
          <b-col sm="4">
            <b-form-group
            label="Start Date"
            label-for="start"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
            <VueDatePicker
            class="vd__div"
            v-model="form.filter_start_date"
            no-header
            clearable
            :disabled="disabled_start_date"
            format="DD MMM YYYY"
            min-date="1900-01-01"
            placeholder="Choose Start Date"
            />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Filter On Schedule -->
          <b-col sm="4">
            <b-form-group
            label="On Schedule"
            label-for="schedule"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
            <b-form-select v-model="form.filter_schedule" :options="opt_schedule" style="top: 1px;">
            </b-form-select>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Space -->
          <b-col sm="4">
          </b-col>

          <!-- Filter End Date -->
          <b-col sm="4">
            <b-form-group
            label="End Date"
            label-for="end"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="sm"
            >
            <b-input-group size="sm">
            <VueDatePicker
            class="vd__div"
            v-model="form.filter_end_date"
            no-header
            clearable
            :disabled="disabled_end_date"
            format="DD MMM YYYY"
            :min-date="form.filter_start_date"
            placeholder="Choose End Date"
            />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row align-h="end" style="padding-bottom:15px !important; padding-top:15px !important;">
          <b-col sm="auto">
              <b-button variant="primary"
                style="border-radius:5px; font-size: 12px; !important;"
                @click="searchFilter">
                <b-icon icon="search"></b-icon>
              Search
              </b-button>
          </b-col>
          <b-col sm="auto">
              <b-button variant="danger"
                style="border-radius:5px; font-size: 12px; !important;"
                @click="ClearFilter">
                <b-icon icon="arrow-repeat" font-scale="1.4"></b-icon>
              Clear
              </b-button>
          </b-col>
        </b-row>

    <!-- Main table element -->
    <b-table
      :items="dataTable"
      :fields="fields"
      responsive
      :current-page="currentPage"
      :per-page="perPage"
      :hover="true"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      label-sort-asc
      :sort-direction="sortDirection"
      stacked="md"
      @filtered="onFiltered"
      class='my-table'
    >
      <!-- IO -->
      <template #cell(io)="row">
        <div @click="IOPPCM(row.value)" style="color:#5497f3; text-decoration: underline; cursor: pointer;">{{row.value}}</div>
      </template>

      <!-- Due Date -->
      <template #cell(due_date)="row">
        <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
      </template>

      <!-- Status -->
      <template #cell(status)="row">
        <span v-if="row.value !== null" v-html="statusHidden(row.value)"></span>
      </template>

      <!-- Total Plan Days-->
      <template #cell(total_plan)="row">
        <span v-if="row.item.total_qty !== null && row.item.cutting_target !== null"
        v-html="TotalPlanDays(
          row.item.total_qty,
          row.item.cutting_target,
          row.item.sewing_target,
          row.item.finishing_target,
          row.item.packing_target,
          row.item.printing_target,
          row.item.embroidery_target,
          row.item.emblem_target,
          row.item.washing_target,
          row.item.delivery_days)" >
        </span>
      </template>

      <!-- Total Actual Days -->
      <template #cell(total_actual_days)="row">
        <span v-if="row.cutting_date !== null">{{row.value}}</span>
      </template>

      <!-- QTY Final -->
      <template #cell(total_final)="row">
        <span v-if="row.item.packing_progress !== null">
          {{row.item.packing_progress}} ({{((row.item.packing_progress / row.item.total_qty) * 100).toFixed(1)}}%)</span>
      </template>

      <!-- Pic IO -->
      <template #cell(pic_io_image)="row">
        <div v-if="row.item.image_io != null">
        <img :src="url_data+'/media/ppm/image/'+row.item.image_io" class='image_dashboard'>
        </div>
      </template>

      <!-- Cut % -->
      <template #cell(cutting_progress_calc)="row">
        <!-- Calc Percentage ProgressProcess -->
        {{TotalQtyPercent(row.item.id,
           row.item.cutting_progress, row.item.sewing_progress, row.item.finishing_progress,
           row.item.packing_progress, row.item.printing_progress, row.item.embroidery_progress,
           row.item.emblem_progress, row.item.washing_progress,

           row.item.total_qty,
           row.item.order_process)}}
         <span v-if="row.item.cutting_progress !== null">
           {{cutting_percent[row.item.id]}}%</span>
      </template>

      <!-- Sew % -->
      <template #cell(sewing_progress_calc)="row">
         <span v-if="row.item.sewing_progress !== null">
           {{sewing_percent[row.item.id]}}%</span>
      </template>

      <!-- Fin % -->
      <template #cell(finishing_progress_calc)="row">
         <span v-if="row.item.finishing_progress !== null" >
           {{finishing_percent[row.item.id]}}%</span>
      </template>

      <!-- Pack % -->
      <template #cell(packing_progress_calc)="row">
         <span v-if="row.item.packing_progress !== null">
           {{packing_percent[row.item.id]}}%</span>
      </template>

      <!-- Print % -->
      <template #cell(printing_progress_calc)="row">
         <span v-if="row.item.printing_progress !== null">
           {{printing_percent[row.item.id]}}%</span>
      </template>

      <!-- Embro % -->
      <template #cell(embroidery_progress_calc)="row">
         <span v-if="row.item.embroidery_progress !== null">
           {{embroidery_percent[row.item.id]}}%</span>
      </template>

      <!-- Emblem % -->
      <template #cell(emblem_progress_calc)="row">
         <span v-if="row.item.emblem_progress !== null">
           {{emblem_percent[row.item.id]}}%</span>
      </template>

      <!-- Washing % -->
      <template #cell(washing_progress_calc)="row">
         <span v-if="row.item.washing_progress !== null">
           {{washing_percent[row.item.id]}}%</span>
      </template>

      <!-- Qc final Date -->
      <template #cell(qc_final_date)="row">
         <span v-if="row.item.qc_final !== null" v-html="FormatDate(row.item.qc_final)">{{row.item.qc_final}}</span>
      </template>

      <!-- Receive % -->
      <template #cell(receive_calc)="row">
         <span v-if="row.item.receive !== null">{{((row.item.receive / row.item.packing_progress) * 100).toFixed(1)}}%</span>
      </template>

    </b-table>
  <b-row>
    <b-col sm="4">
      Showing {{showPerPage}} of {{totalData}} entries
    </b-col>
    <b-col sm="4">

    </b-col>
    <b-col sm="4" align-self="end" class="my-1">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalData"
        :per-page="perPage"
        align="fill"
        size="sm"
      >
      <template #first-text><span class="text-danger">First</span></template>
      <template #prev-text><span class="">Prev</span></template>
      <template #next-text><span class="">Next</span></template>
      <template #last-text><span class="text-danger">Last</span></template>
      <template #page="{ page, active }">
        <b v-if="active">{{Pagination(page)}} {{ page }}</b>
        <i v-else>{{ page }}</i>
      </template>
    </b-pagination>
    </b-col>
  </b-row>
  </b-col>
</div>

</template>

<script>
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex';
import Widget from '@/components/Widget/Widget';
import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
// import vue loading
import Loading from 'vue-loading-overlay';
// Import vue loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

export default {
    name: 'DashboardAdjustment',
    components: {
      Widget,
      vSelect
    },
    data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('id-ID', optyear)
    const yearplus = new Date().getFullYear()
    const yearplus_1 = yearplus+1
    const yearplus_2 = yearplus+2
    return {
      today:today,
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      oneDay:oneDay,
      year:year,
      loader:null,
      url_data:'',
      disabled_last_process:true,
      disabled_end_date:true,
      disabled_start_date:false,
      disabled_month:false,
      disabled_year:false,
      filter: 'all',
      showPerPage:0,
      dataTable:[],
      totalData:0,
      optionsFilter: [
        { value: 'all', text: 'All' },
        { value: 'io', text: 'IO' },
        { value: 'brand', text: 'Brand' },
        { value: 'artikel', text: 'Artikel' },
        { value: 'status', text: 'Status' },
      ],
      fields: [
        { key: 'id', label: 'ID', class:"hidden" },
        { key: 'io', label: 'IO', sortable: true, thClass: 'header-table', tdClass: 'chkDateOnSchedule' },
        { key: 'cutting_subcon', label: 'Sub Con', sortable: true, class: 'header-table' },
        { key: 'due_date', label: 'due date', sortable: true, sortDirection: 'desc', class: 'header-table' },
        { key: 'artikel', label: 'artikel', class: 'header-table' },
        { key: 'brand', label: 'brand', class: 'header-table' },
        { key: 'sample', label: 'sample', class: 'header-table' },
        { key: 'status', label: 'status', sortable: true, sortDirection: 'desc', class: 'header-table' },
        { key: 'total_plan', label: 'Total plan', sortable: true, class: 'header-table' },
        { key: 'total_actual_days', label: 'Total actual', sortable: true, class: 'header-table' },
        { key: 'total_qty', label: 'Qty Plan', sortable: true, class: 'header-table' },
        { key: 'total_final', label: 'Qty Final', sortable: true, class: 'header-table' },
        { key: 'pic_io_image', label: 'Pic IO', class: 'header-table' },
        { key: 'cutting_progress_calc', label: 'Cut', class: 'header-table' },
        { key: 'sewing_progress_calc', label: 'Sew', class: 'header-table' },
        { key: 'finishing_progress_calc', label: 'Finish', class: 'header-table' },
        { key: 'packing_progress_calc', label: 'Pack', class: 'header-table' },
        { key: 'printing_progress_calc', label: 'Print', class: 'header-table' },
        { key: 'embroidery_progress_calc', label: 'Embro', class: 'header-table' },
        { key: 'emblem_progress_calc', label: 'Emblem', class: 'header-table' },
        { key: 'washing_progress_calc', label: 'Wash', class: 'header-table' },
        { key: 'qc_final_date', label: 'Qc Final', class: 'header-table' },
        { key: 'qty_shortship', label: 'Qty Shortship', class: 'header-table' },
        { key: 'receive_calc', label: 'Receive', class: 'header-table' },
      ],
      currentPage: 1,
      PageNow:1,
      perPage: 10,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      form_filter:[],
      actual_days:[],
      actual_cutting:[],
      actual_sewing:[],
      actual_finishing:[],
      actual_packing:[],
      actual_printing:[],
      actual_embroidery:[],
      actual_emblem:[],
      actual_washing:[],
      totalQty:[],
      progress_cutting:[],
      progress_sewing:[],
      progress_finishing:[],
      progress_packing:[],
      progress_printing:[],
      progress_embroidery:[],
      progress_emblem:[],
      progress_washing:[],
      order_process:[],
      cutting_percent:[],
      sewing_percent:[],
      finishing_percent:[],
      packing_percent:[],
      printing_percent:[],
      embroidery_percent:[],
      emblem_percent:[],
      washing_percent:[],
      sewing_total:[],
      finishing_total:[],
      packing_total:[],
      printing_total:[],
      embroidery_total:[],
      emblem_total:[],
      washing_total:[],
      opt_process: [
          { value: 'all', text: 'All' ,disabled: false },
          { value: 'new', text: 'New' ,disabled: false },
          { value: 'approval', text: 'Approval' ,disabled: false },
          { value: 'ongoing', text: 'Process' ,disabled: false },
          { value: 'completed', text: 'Completed' ,disabled: false },
      ],
      opt_lastprocess: [
          { value: 'all', text: 'All' ,disabled: false },
          { value: 'cutting', text: 'Cutting' ,disabled: false },
          { value: 'sewing', text: 'Sewing' ,disabled: false },
          { value: 'finishing', text: 'Finishing' ,disabled: false },
          { value: 'packing', text: 'Packing' ,disabled: false },
          { value: 'printing', text: 'Printing' ,disabled: false },
          { value: 'embroidery', text: 'Embroidery' ,disabled: false },
          { value: 'emblem', text: 'Emblem' ,disabled: false },
          { value: 'washing', text: 'Washing' ,disabled: false },
          { value: 'delivery', text: 'Delivery' ,disabled: false },
          { value: 'receive', text: 'Receive' ,disabled: false },
          { value: 'closeio', text: 'CloseIO' ,disabled: false },
      ],
      opt_subcon: [
          { value: 'all', text: 'All' ,disabled: false },
      ],
      opt_schedule: [
          { value: 'all', text: 'All' ,disabled: false },
          { value: 'yes', text: 'Yes' ,disabled: false },
          { value: 'no', text: 'No' ,disabled: false },
      ],
      opt_year: [
          { value: 'all', text: 'All', disabled: false },
          { value: year-1, text: year-1, disabled: false },
          { value: year, text: year, disabled: false },
          { value: yearplus_1, text: yearplus_1, disabled: false },
          { value: yearplus_2, text: yearplus_2, disabled: false },
      ],
      opt_brand: [
          { value: 'all', text: 'All', disabled: false },
      ],
      opt_month: [
          { value: 'all', text: 'All' ,disabled: false },
          { value: '01', text: 'January' ,disabled: false },
          { value: '02', text: 'February' ,disabled: false },
          { value: '03', text: 'March' ,disabled: false },
          { value: '04', text: 'April' ,disabled: false },
          { value: '05', text: 'May' ,disabled: false },
          { value: '06', text: 'June' ,disabled: false },
          { value: '07', text: 'July' ,disabled: false },
          { value: '08', text: 'August' ,disabled: false },
          { value: '09', text: 'September' ,disabled: false },
          { value: '10', text: 'October' ,disabled: false },
          { value: '11', text: 'November' ,disabled: false },
          { value: '12', text: 'Desember' ,disabled: false },
      ],
      form : {
            fullname:null,
            data:null,
            role:null,
            filter:'all',
            start_limit:0,
            end_limit:10,
            filter_all:'yes',
            filter_io:null,
            filter_artikel:null,
            filter_subcon:'all',
            filter_schedule:'all',
            filter_month:'all',
            filter_year:'all',
            filter_process:'all',
            filter_lastprocess:'all',
            filter_brand:'all',
            filter_start_date:null,
            filter_end_date:null,
        },
      }
  },
  computed: {
      ...mapState('application', ['stAppAccess']),
      ...mapGetters('session', ['userData']),
      ...mapState('ppm', ['stAllDtail','stAllDtailCount','stMstSub','stMstSubCount','StMonitoringCount']),

    },
    methods: {
      ...mapActions('session', ['showNotification']),
      ...mapActions('application', ['clearState', 'getApplicationAccess']),
      ...mapActions('ppm', ['clearStatePpm','getMonitoringDetailData','getDataFormMasterSubcon','getMonitoringDetailCount']),

    setDataFilter() {
      if (this.form.filter_io !== null) {
        if (this.form.filter_io == '' || this.form.filter_io.substring(0, 1) == ' ' ) {
          this.form.filter_io = null
        }
      }
      if (this.form.filter_artikel !== null) {
        if (this.form.filter_artikel == '' || this.form.filter_artikel.substring(0, 1) == ' ' ) {
          this.form.filter_artikel = null
        }
      }
      console.log(this.form.filter_start_date)
      let filter = {
        all:this.form.filter_all,
        io:this.form.filter_io,
        subcon:this.form.filter_subcon,
        artikel: this.form.filter_artikel,
        month: this.form.filter_month,
        year: this.form.filter_year,
        status: this.form.filter_process,
        last_process: this.form.filter_lastprocess,
        brand: this.form.filter_brand,
        on_schedule: this.form.filter_schedule,
        start_date: this.form.filter_start_date,
        end_date: this.form.filter_end_date,
      }
      return filter
    },
    Setdata_IO() {
        let filter_data = this.setDataFilter()
        this.form_filter.push(filter_data)
        let userData     = this.userData
        this.$set(this.form, 'fullname', userData.fullname)
        this.$set(this.form, 'role', userData.role)
        if (userData.roles) {
          for (let a=0; a<userData.roles.length; a++) {
            if (userData.roles[a].app == 'PPCM') {
              this.form.role = userData.roles[a].role_list
            }
          }
        }
        // this.form.role = this.form.role.toLowerCase();
        this.url_data = process.env.VUE_APP_BASE_URL
        if (this.url_data == undefined) {
          let url = window.location.hostname
          this.url_data = 'http://'+url+':8000'
        }
        this.LoadData()
          if (this.form.role.includes('subcon')) {
            this.$router.push({name:"ApplicationPage"})
            this.showNotification({type:'error', message:"You don't have authorization to view this page"})
          }
      },

    Change_Filter() {
      // enable/disable last_process
      if (this.form.filter_process == 'ongoing') {
        this.disabled_last_process = false;
      } else {
        this.disabled_last_process = true;
        this.form.filter_lastprocess = 'all'
      }

      // Start Date and End Date
      if (this.form.filter_start_date != null) {
        this.disabled_month = true;
        this.disabled_year = true;
        this.form.filter_month = 'all'
        this.form.filter_year = 'all'
        this.disabled_end_date = false;
      } else {
        this.disabled_month = false;
        this.disabled_year = false;
        this.disabled_end_date = true;
        this.form.filter_end_date = null;
      }

      // Month and Year
      if (this.form.filter_month != 'all' || this.form.filter_year != 'all') {
          this.disabled_start_date = true;
          this.disabled_end_date = true;
          this.form.filter_start_date = null;
          this.form.filter_end_date = null;
      }
      if (this.form.filter_month == 'all' && this.form.filter_year == 'all') {
          this.disabled_start_date = false;
      }

    },
    Pagination(value) {
      if (value !== this.PageNow) {
        if (value > this.PageNow) {
          this.PageNow = value
          this.nextPage()
          this.showPerPage = this.perPage * this.PageNow
          if (this.showPerPage >= this.totalData) {
            this.showPerPage = this.totalData
          }
        } else {
          this.PageNow = value
          this.showPerPage = this.showPerPage - (this.showPerPage - (this.perPage * this.PageNow))
          if (this.showPerPage <= this.perPage) {
            this.showPerPage = this.perPage
          }
        }
      }
    },
    IOPPCM(data) {
      // this.$router.push({ name: 'PPCInputMonitoring', params: { data_io: data } })
      let routeData = this.$router.resolve({ name: 'PPCInputMonitoring', query: { io: data }})
      // console.log(routeData.href)
      window.open(routeData.href, '_blank')
    },
      FormatDate(value) {
        if(value == null || value == undefined || value == '') {
            return ' '
        } else {
        let date = new Date(value)
        let tgl = date.toISOString().slice(8,10)
        let bulan = this.monthNames[date.getMonth()]
        let tahun = date.toISOString().slice(0,4)
        date = tgl+' '+bulan+' '+tahun
        return date
        }
      },
      CalcTotalPlan(totalQty,target) {
      let calc = (((totalQty/target) - Math.floor(totalQty/target))*10)
      if (calc >= 3) {
        calc = Math.ceil(totalQty/target)
      } else {
        calc = Math.floor(totalQty/target)
        if (calc == 0) {
          calc = 1
        }
      }
      return calc
      },
      TotalPlanDays(totalqty,cutting,sewing,finishing,packing,printing,embroidery,emblem,washing,delivery) {
      let Plancutting= 0
      let Plansewing = 0
      let Planfinishing = 0
      let Planpacking = 0
      let Planprinting = 0
      let Planembroidery = 0
      let Planemblem = 0
      let Planwashing = 0
      let PlanQc_Final = 2
      let PlanDelivery = 0

      if (cutting !== null) {
        Plancutting = this.CalcTotalPlan(totalqty,cutting)
      }
      if (sewing !== null) {
        Plansewing = this.CalcTotalPlan(totalqty,sewing)
      }
      if (finishing !== null) {
        Planfinishing = this.CalcTotalPlan(totalqty,finishing)
      }
      if (packing !== null) {
        Planpacking = this.CalcTotalPlan(totalqty,packing)
      }
      if (printing !== null) {
        Planprinting = this.CalcTotalPlan(totalqty,printing)
      }
      if (embroidery !== null) {
        Planembroidery = this.CalcTotalPlan(totalqty,embroidery)
      }
      if (emblem !== null) {
        Planemblem = this.CalcTotalPlan(totalqty,emblem)
      }
      if (washing !== null) {
        Planwashing = this.CalcTotalPlan(totalqty,washing)
      }
      if (delivery !== null) {
        PlanDelivery = delivery
      }
      let TotalPlan = Plancutting + Plansewing + Planfinishing + Planpacking + Planprinting + Planembroidery + Planemblem + Planwashing + PlanQc_Final + PlanDelivery
      return TotalPlan
      },
      chkDateOnSchedule(value, key, item){
        let duedate   = item.due_date;
        let today     = this.today;
        let status    = item.status;
        let endDate   = item.end_date_receive;
       if (status == 'New' || status == 'New(Pending)' || status == 'Pending(Approval)')  {
         return 'table-io new'
       } else {
        if (endDate == null || endDate == '' || endDate == undefined) {
          if (duedate < today) {
           return 'table-io red'
          }
          if (duedate >= today) {
            return 'table-io green'
          }
        } else {
          if (duedate < endDate) {
           return 'table-io red'
          }
          if (duedate >= endDate) {
            return 'table-io green'
          }
        }
       }
      },
      Loading(value) {
        if (value == 'start') {
        this.loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            color: '#f89830',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
            });
        }
        if (value == 'stop') {
          // Set delay 1 second
          setTimeout(() => {
            this.loader.hide()
          }, 1000);
        }
      },
      calcProcessPercent(index, process, progress) {
        if (process == 'b') {
          this.sewing_total[index] = progress
        }
        if (process == 'c') {
          this.finishing_total[index] = progress
        }
        if (process == 'd') {
          this.packing_total[index] = progress
        }
        if (process == 'e') {
          this.printing_total[index] = progress
        }
        if (process == 'f') {
          this.embroidery_total[index] = progress
        }
        if (process == 'g') {
          this.emblem_total[index] = progress
        }
        if (process == 'h') {
          this.washing_total[index] = progress
        }
      },
      TotalQtyPercent(index, cut,sew,fin,pack,print,embro,emblem,wash, total, order_process){
      if (order_process == null || order_process == '' || order_process == undefined) {
        // nothing
      } else {
        let len = Math.abs(order_process.length / 2)
        let param = order_process
        let d = 0
        this.totalQty[index] = total
        for (let a=0;a<=len;a++) {
          // console.log('index: '+index)
          // console.log('param: '+param)
          // console.log('progress: '+progress)
          // console.log('total: '+total)
          // console.log('process : '+a)
          // console.log('order_process: '+order_process)
          if (param[d] == 'a') {
            this.calcProcessPercent(index, param[d+2], cut)
            this.progress_cutting[index] = cut
            this.cutting_percent[index] = (((this.progress_cutting[index] / this.totalQty[index]) * 100).toFixed(1))
            // console.log('this.progress_cutting[index]:  '+this.progress_cutting[index])
            // console.log('this.totalQty[index]:  '+this.totalQty[index])
            // console.log('this.cutting_percent[index]:  '+this.cutting_percent[index])
          }
          if (param[d] == 'b') {
            this.calcProcessPercent(index, param[d+2], sew)
            this.progress_sewing[index] = sew
            this.sewing_percent[index] = (((this.progress_sewing[index] / this.sewing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'c') {
            this.calcProcessPercent(index, param[d+2], fin)
            this.progress_finishing[index] = fin
            this.finishing_percent[index] = (((this.progress_finishing[index] / this.finishing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'd') {
            this.progress_packing[index] = pack
            this.packing_percent[index] = (((this.progress_packing[index] / this.packing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'e') {
            this.calcProcessPercent(index, param[d+2], print)
            this.progress_printing[index] = print
            this.printing_percent[index] = (((this.progress_printing[index] / this.printing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'f') {
            this.calcProcessPercent(index, param[d+2], embro)
            this.progress_embroidery[index] = embro
            this.embroidery_percent[index] = (((this.progress_embroidery[index] / this.embroidery_total[index]) * 100).toFixed(1))
            // console.log('this.progress_embroidery[index]:  '+this.progress_embroidery[index])
            // console.log('this.embroidery_total[index]:  '+this.embroidery_total[index])
            // console.log('this.embroidery_percent[index]:  '+this.embroidery_percent[index])
          }
          if (param[d] == 'g') {
            this.calcProcessPercent(index, param[d+2], emblem)
            this.progress_emblem[index] = emblem
            this.emblem_percent[index] = (((this.progress_emblem[index] / this.emblem_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'h') {
            this.calcProcessPercent(index, param[d+2], wash)
            this.progress_washing[index] = wash
            this.washing_percent[index] = (((this.progress_washing[index] / this.washing_total[index]) * 100).toFixed(1))
          }
          d = d + 2
        }
      }

    },
      statusHidden(value) {
        let conOng = value.includes("ONGOING")
        let conPen = value.includes("Approval")
        let status = value
        if (conOng) {
          status = ((value.substring(8,20)).split(")")[0]);
        }
        if (conPen) {
          status = ((value.substring(8,20)).split(")")[0]);
        }
        return status
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalData = filteredItems.length
        this.currentPage = 1
      },
      ClearFilter() {
        this.currentPage = 1;
        this.PageNow = 1;
        this.form_filter = []
        this.form.filter_all = 'yes'
        this.form.filter_io = null
        this.form.filter_artikel = null
        this.form.filter_subcon = 'all'
        this.form.filter_schedule = 'all'
        this.form.filter_month = 'all'
        this.form.filter_year = 'all'
        this.form.filter_process = 'all'
        this.form.filter_lastprocess = 'all'
        this.form.filter_brand = 'all'
        this.form.filter_start_date = null
        this.form.filter_end_date = null
        let filter_data = this.setDataFilter()
        this.form_filter.push(filter_data)
        this.form.start_limit = 0
        this.LoadData()
      },
      searchFilter() {
        if (this.form.filter_start_date != null && this.form.filter_end_date == null) {
          this.showNotification({type:'info', message:'Please Input End Date Field'})
          return
        }
        this.currentPage = 1;
        this.PageNow = 1;
        this.form_filter = []
        this.form.filter_all = 'no'
        let filter_data = this.setDataFilter()
        this.form_filter.push(filter_data)
        this.form.start_limit = 0
        this.form.end_limit = this.perPage
        this.LoadData()
      },
      nextPage:function() {
          if (this.totalData > this.dataTable.length) {
          this.Loading('start')
          // this.form.start_limit = this.perPage * ( this.PageNow -1 )
          this.form.start_limit = this.dataTable.length
          this.form.end_limit = this.perPage * this.PageNow
          this.getMonitoringDetailData({
            role:this.form.role,
            filter:this.form_filter,
            start_limit:this.form.start_limit,
            end_limit:this.form.end_limit,
          })
          .then(rsp => {
            this.Loading('stop')
            this.dataTable = this.dataTable.concat(this.stAllDtail) // merge two arrays
              let foo = new Map();
              for(const tag of this.dataTable) {
                foo.set(tag.id, tag);
              }
              this.dataTable = [...foo.values()]
          })

        }
      },

      LoadData() {
        this.Loading('start')
        this.getMonitoringDetailCount({
          role:this.form.role,
          filter:this.form_filter,
          start_limit:0,
        }).then(rsp => {

              this.getMonitoringDetailData({
                role:this.form.role,
                filter:this.form_filter,
                start_limit:this.form.start_limit,
                end_limit:this.form.end_limit,
              })
              .then(rsp => {
                  this.dataTable = this.stAllDtail
                  this.totalData = this.StMonitoringCount
                  this.showPerPage = this.perPage
                  if (this.totalData < this.perPage) {
                    this.showPerPage = this.totalData
                  }
                  this.Loading('stop')
                  if(this.stAllDtailCount == 0) {
                    this.showNotification({type:'info', message:'Data Not Found'})
                  }
              })
              .catch(err => {
                this.Loading('stop')
                if(err.response.status !== 401) {
                  this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
              })

        })
        .catch(err => {
            this.Loading('stop')
            if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
            }
          })
      },

    },
    mounted() {
      this.getDataFormMasterSubcon().then(rsp=> {
        for (let r=0; r<=this.stMstSubCount-1; r++) {
            this.opt_subcon.push(
                { value: this.stMstSub[r].id, text: this.stMstSub[r].nama_subcon, disabled: false },
            );
        }
        this.Setdata_IO()
        this.clearStatePpm()
      }).catch(err => {
          if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
              this.$router.push({name:"Application"})
          }
      })
    },
  }
</script>
<style scoped>
  td.header-table {
    font-size:10px !important;
  }
</style>
<style src="./DashboardMonitoring.scss" lang="scss" scoped />
