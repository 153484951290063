<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto" md="6">
                        
                        <!-- Code Acc -->
                        <validation-provider
                            name="codeAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Code
                                </template>
                                    <!-- <b-form-select id="inputCodeAcc" v-model="form.codeAcc"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                    <v-select append-to-body
                                        :reduce="ref => ref.code" 
                                        v-model="form.codeAcc"
                                        @input="setSelected" 
                                        :options="codeOpt" 
                                        placeholder="Type your code number"
                                        label="ref">
                                        <template #list-header>
                                            <b-input-group-append>
                                                <b-button block variant="light" @click="showMaster()">
                                                    <!-- <b-icon icon="eye-fill" aria-label="Help"></b-icon> -->
                                                    Lihat Semua
                                                </b-button>
                                            </b-input-group-append>
                                        </template>
                                        <template #option="{ code, description }">
                                            <p style="margin: 0">{{ code }}</p>
                                            <em class="text-muted">{{ description }}</em>
                                        </template>
                                    </v-select>
                                <b-form-invalid-feedback id="inputCodeAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Type Acc -->
                        <validation-provider
                            name="TypeAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4"  
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Type
                                </template>
                            
                                <b-form-input readonly id="inputTypeAcc" v-model="form.typeAcc"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputTypeAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Description -->
                        <validation-provider
                            name="DescriptionAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Description
                                </template>
                            
                                <b-form-input readonly id="DescriptionAcc" v-model="form.descriptionAcc"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="DescriptionAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Detail Acc -->
                        <validation-provider
                            name="DetailAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Detail
                                </template>
                            
                                <b-form-textarea id="DetailAcc" v-model="form.detailAcc"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                <b-form-invalid-feedback id="DetailAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        
                        
                    </b-col>
                    <b-col cols="auto" md="6">
                        <!-- UsedAcc -->
                        <!-- <validation-provider
                            name="UsedAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Used As
                                </template>
                                <b-form-select id="inputUsedAcc" v-model="form.usedAcc"  :options="usedOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                <b-form-invalid-feedback id="inputUsedAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->

                        <!-- Images Acc -->
                        <!-- <validation-provider
                            name="imagesAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images Accessories
                                </template>
                                <b-form-file
                                    multiple
                                    ref="fileInput"
                                    id="inputImagesAcc"
                                    v-model="form.imagesAcc"
                                    no-drop
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose file here..."
                                    accept=".jpg, .png,"
                                    :file-name-formatter="formatNames"
                                    v-on:change="onFileChange"
                                ></b-form-file>
                                    <div v-for="img in imagesUrl" :key="img.name">
                                        <p class="small">
                                            <a :href="img.src">{{img.fileName}}</a>
                                            <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                            </b-badge>
                                        </p>
                                    </div>
                                <b-form-invalid-feedback id="inputImagesBotton">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->
                        <b-form-group
                            label-cols          ="4" 
                            label-cols-lg       ="4" 
                            label-size          ="sm"
                        >
                            <template v-slot:label>
                                Images
                            </template>
                            <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesAcc"></b-img>

                        </b-form-group>
                        <div>
                            <b-button block  variant="success" type="submit">Save</b-button>
                        </div>
                    </b-col>
                    
                    
                    
                </b-row>
                    
            </b-form>
        </validation-observer>
       
        <div>
            <b-table
                responsive
                striped 
                hover
                :items="loadProductMaterial"
                :fields="fields"
            >
                
                
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="data">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                        
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
         
         <b-modal
            hide-backdrop
            hide-footer
            v-model="modalMaster"
            size="xl"
            ref="modal"
            scrollable 
            title="Master Material">
            <master-accesories/>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import MasterAccesories from '../Master/MasterAccesories.vue';
export default {
  components: { MasterAccesories },
    name:'FormAcc',
    data(){
        return{
            mainProps: {width: 150, class: 'm1' },
            formName: 'Acc',
            modulName: null,
            tabIndex:1,
            isValid:false,
            isSelect:'TBD',
            fields:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                // 'used_as'
            ],
            
            imagesUrl:[],
            form:{
                indexId:null,
                codeAcc:null,
                typeAcc:null,
                imagesAcc:[],
                detailAcc:null,
                usedAcc:null,
                descriptionAcc:null,
                accId:null,
                category:'accessories',
            },
            codeOpt:[
                {code:'Code A', ref:'Code A'},
                {code:'Code B', ref:'Code B'},
                {code:'Code C', ref:'Code C'},
                {code:'Code D', ref:'Code D'},
            ],
            usedOpt:[
                {value:'main', text:'Main'},
                {value:'collar', text:'Collar'},
                {value:'pocket', text:'Pocket'},
            ],
            accOpt: [
                { item: 'TBD', name: 'TBD' },
                { item: 'Yes', name: 'Yes' },
                { item: 'No', name: 'No'},
                
            ],
            modalMaster:false,
            
        }
    },
    methods:{
        ...mapActions('productDesign',['add','delete','update','get']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),

        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            if(this.form.indexId == null){
                this.add({
                    formname: this.formName,
                    category: this.form.category,
                    material_id: this.form.accId,
                    code:this.form.codeAcc,
                    type:this.form.typeAcc,
                    detail:this.form.detailAcc,
                    images:this.imagesUrl,
                    used_as:this.form.usedAcc,
                    description: this.form.descriptionAcc,
                    isProccess:this.isSelect,
                    
                })
            }else{
                this.update({
                    formname: this.formName,
                    id:this.form.indexId,
                    acc_id: this.form.accId,
                    code:this.form.codeAcc,
                    type:this.form.typeAcc,
                    detail:this.form.detailAcc,
                    images:this.imagesUrl,
                    used_as:this.form.usedAcc,
                    description: this.form.descriptionAcc,
                    isProccess:this.isSelect,
                    
                })
            }
            
            this.clearForm()
        
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeAcc = null
            this.form.typeAcc = null
            this.form.imagesAcc = []
            this.imagesUrl = []
            this.form.detailAcc = null
            this.form.usedAcc = null
            this.form.descriptionAcc = null
            this.$refs.observer.reset()
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                // this.form.imagesAcc.push(file[i])
                // this.imagesUrl = URL.createObjectURL(file[i])
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
            return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        deleteData(id){
            
            this.delete({id:id,formname:this.formName})
        },
        editData(index){
            
            this.get({id:index,formname:this.formName})
            this.form.indexId   = index
            this.form.accId     = this.accDetail.id
            this.form.codeAcc   = this.accDetail.code
            this.form.typeAcc   = this.accDetail.type
            this.form.detailAcc = this.accDetail.detail
            this.imagesUrl      = this.accDetail.images
            this.form.usedAcc   = this.accDetail.used_as
            this.form.descriptionAcc = this.accDetail.description
            this.isProccess     = this.accDetail.accessories_process
        },
        
        showMaster(){
            this.modalMaster = true
        },
        setSelected(value){
            
            if(value != null){
                let dataAcc = this.MASTER_MATERIAL.filter(x => x.code === value)
                this.form.accId             = dataAcc[0].id
                this.form.codeAcc           = dataAcc[0].code
                this.form.descriptionAcc    = dataAcc[0].description
                this.form.typeAcc           = dataAcc[0].type
                this.form.descriptionAcc    = dataAcc[0].description
                this.form.imagesAcc         = dataAcc[0].img_src
            }else{
                console.log('data tidak ada');
            }
        },
        getAccMaster(){
            this.GET_MASTER_MATERIAL().then(response => {
                
                this.codeOpt = []
                let objAcc = response.data.payload.filter(x=>x.category ==='accessories')
                for(var i=0;i<objAcc.length;i++){
                    let arrAcc = {
                            ref: objAcc[i].code,
                            code: objAcc[i].code,
                            description: objAcc[i].description

                            
                        }
                        this.codeOpt.push(arrAcc)
                }
            }).catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        
    },

    mounted(){
        if(this.form.indexId != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        
        this.getAccMaster()

    },
    computed:{
    ...mapGetters('productDesign',['PRODUCT_MATERIAL']),
    ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        loadProductMaterial(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='accessories')
        }
    }
}
</script>