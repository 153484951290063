<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="sm">
      <b-row align-h="center">
        <b-col cols="12" sm="auto">
          <b-card header="Form Harian Baru" header-tag="header">
            <template v-slot:header>
              <div class="text-center">
                <div class="banner-container">
                  <div class="bbi-banner"></div>
                </div>
                <h3>Laporan Kesehatan Harian</h3>
                <p>
                  Harap diisi dengan JUJUR dan sesuai kondisi yg ada demi
                  kebaikan bersama
                </p>
              </div>
            </template>

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <b-row align-h="center">
                  <b-col sm="auto">
                    <!-- input tanggal -->
                    <validation-provider name="tanggal">
                      <b-form-group>
                        <label for="tanggal">Tanggal Laporan Hari Ini</label>
                        <b-input-group
                          class="sm-3"
                          aria-describedby="input-tanggal-feedback"
                        >
                          <b-form-input
                            id="tanggal"
                            v-model="form.tanggal_harian"
                            type="text"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append> </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input tanggal -->

                    <!-- input nama karyawan -->
                    <b-form-group label="Nama Karyawan" label-for="inputNama">
                      <b-form-input
                        readonly
                        id="inputName"
                        v-model="form.nama_karyawan"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input nama karyawan -->
                  </b-col>
                  <b-col sm="auto">
                    <!-- input nik -->
                    <b-form-group
                      label="Nomor Induk Karyawan"
                      label-for="inputNik"
                    >
                      <b-form-input
                        readonly
                        id="inputNik"
                        v-model="form.nik"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input nik -->

                    <!-- input department -->
                    <b-form-group label="Department" label-for="inputDept">
                      <b-form-input
                        readonly
                        id="inputDept"
                        v-model="form.department"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input department -->
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <b-col sm="6">
                    <!-- options kondisi kesehatan -->
                    <validation-provider
                      name="kondisi_kesehatan"
                      vid="kondisi_kesehatan"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group label="Kondisi Kesehatan ?">
                        <b-form-radio-group
                          v-model="form.kondisi_kesehatan"
                          name="kondisi_kesehatan"
                          ref="opt_kesehatan"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-kondisi_kesehatan-feedback"
                          @change="checkKesehatan($event)"
                        >
                          <b-radio class="radio" value="sehat">Sehat</b-radio>
                          <b-radio value="sakit">Sakit</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-kondisi_kesehatan-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- input kondisi kesehatan -->
                    <transition-group name="fade">
                      <b-col
                        cols="11.5"
                        offset="1"
                        key="1"
                        v-if="checkKondisiKesehatan"
                      >
                        <validation-provider
                          name="keterangan_kondisi_kesehatan"
                          :rules="{ required: checkKondisiKesehatan }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label="Sakit yang dialami"
                            ref="opt_ket_kesehatan"
                          >
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-keterangan_kondisi_kesehatan-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="batuk, radang, pilek, demam, pegal, hilang, rasa/bau"
                                v-model="form.sakit"
                              />
                              Gejala mirip Covid-19 (batuk, radang, pilek,
                              demam, pegal, hilang, rasa/bau)
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="lainnya"
                                v-model="form.sakit"
                              />
                              Lainnya
                            </b-form-checkbox-group>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-kondisi-sakit-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                        <!-- input kondisi lainnya sakit -->
                        <validation-provider
                          key="1"
                          v-if="kondisiSakitLainnya"
                          name="kondisi_sakit_lainnya"
                          :rules="{ required: kondisiSakitLainnya }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            cols="6"
                            ref="opt_kondisi_lainnya"
                            label-for="inputKondisiLainnya"
                            aria-describedby="input-sakit-lainnya-feedback"
                          >
                            <b-form-input
                              class="form--sakit-lainnya"
                              name="kondisi__sakit_lainnya"
                              size="sm"
                              ref="opt_kondisi__lainnya_input"
                              :state="getValidationState(validationContext)"
                              id="inputKondisiLainnya"
                              v-model="form.sakit_kondisi_lainnya"
                              trim
                            >
                            </b-form-input>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-sakit-lainnya-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </transition-group>
                    <!-- end options kondisi kesehatan -->
                    <!-- input bepergian -->
                    <validation-provider
                      name="bepergian"
                      vid="pergi"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group label="Apakah Anda Kemarin Bepergian">
                        <b-form-radio-group
                          v-model="pergi"
                          name="bepergian"
                          ref="opt_bepergian"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-bepergian-feedback"
                          @change="checkPergi($event)"
                        >
                          <b-radio class="radio" value="ya">Ya</b-radio>
                          <b-radio value="tidak">Tidak</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-bepergian-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>

                    <b-col cols="11.5" offset="1" v-if="checkBepergian()">
                      <!-- input tempat kerja -->
                      <validation-provider
                        name="tempat_kerja"
                        vid="tempat_kerja"
                        :rules="{ required: checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Tempat Kerja">
                          <b-form-radio-group
                            v-model="tempat_kerja"
                            name="tempat_kerja"
                            ref="opt_tempat_kerja"
                            aria-describedby="input-tempat_kerja-feedback"
                            @change="checkTempatKerja($event)"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-tempat_kerja-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input tempat kerja -->
                      <!-- input dinas luar kota -->
                      <validation-provider
                        name="dinas_luar_kota"
                        vid="dinas_luar_kota"
                        :rules="{ required: checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Dinas / Perjalanan Pribadi Luar Kota"
                        >
                          <b-form-radio-group
                            v-model="dinas_luar_kota"
                            name="dinas_luar_kota"
                            ref="opt_dinas_luar_kota"
                            aria-describedby="input-dinas_luar_kota-feedback"
                            @change="checkDinasLuarKota($event)"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-dinas_luar_kota-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        key="1"
                        v-if="LuarKotaKeperluanTujuan"
                        name="luar_kota_keperluan_tujuan"
                        :rules="{ required: LuarKotaKeperluanTujuan }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          cols="6"
                          ref="opt_luar_kota_keperluan_tujuan"
                          label-for="inputLuarKotaKeperluanTujuan"
                           label="Keperluan Dan Tujuan"
                          aria-describedby="input-luar-kota-keperluan-tujuan-feedback"
                        >
                          <b-form-input
                            class="form-luar-kota-keperluan-tujuan"
                            name="luar_kota_keperluan_tujuan"
                            size="sm"
                            ref="opt_luar_kota_keperluan_tujuan"
                            :state="getValidationState(validationContext)"
                            id="inputLuarKotaKeperluanTujuan"
                            v-model="form.luar_kota_keperluan_tujuan"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-luar-kota-keperluan-tujuan-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!--  input dinas luar kota -->
                      <!-- input dinas luar negri -->
                      <validation-provider
                        name="dinas_luar_negeri"
                        vid="dinas_luar_negeri"
                        :rules="{ required: checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          :label="paramDinasKeluarNegeri"
                        >
                          <b-form-radio-group
                            v-model="dinas_luar_negeri"
                            ref="opt_dinas_luar_negeri"
                            aria-describedby="input-dinas_luar_negeri-feedback"
                            @change="checkDinasLuarNegeri($event)"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-dinas_luar_negeri-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        key="1"
                        v-if="LuarNegeriKeperluanTujuan"
                        name="luar_negeri_keperluan_tujuan"
                        :rules="{ required: LuarNegeriKeperluanTujuan }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          cols="6"
                          ref="opt_luar_negeri_keperluan_tujuan"
                          label-for="inputLuarNegeriKeperluanTujuan"
                          label="Keperluan Dan Tujuan"
                          aria-describedby="input-luar-negeri-keperluan-tujuan-feedback"
                        >
                          <b-form-input
                            class="form-luar-negeri-keperluan-tujuan"
                            name="luar_negeri_keperluan_tujuan"
                            size="sm"
                            ref="opt_luar_negeri_keperluan_tujuan"
                            :state="getValidationState(validationContext)"
                            id="inputLuarNegeriKeperluanTujuan"
                            v-model="form.luar_negeri_keperluan_tujuan"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-luar-negeri-keperluan-tujuan-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!--  input dinas luar negeri -->
                      <!-- input fasilitas umum -->
                      <validation-provider
                        name="fasilitas_umum"
                        vid="fasilitas_umum"
                        :rules="{ required: checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Fasilitas Umum">
                          <b-form-radio-group
                            v-model="fasilitas_umum"
                            ref="opt_fasilitas_umum"
                            aria-describedby="input-fasilitas_umum-feedback"
                            @change="checkFasilitasUmum($event)"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-fasilitas_umum-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        key="1"
                        v-if="FasiltasUmumLainnya"
                        name="fasiltas_umum_lainnya"
                        :rules="{ required: FasiltasUmumLainnya }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          cols="6"
                          ref="opt_fasiltas_umum_lainnya"
                          label-for="inputFasilitasUmumLainnya"
                          aria-describedby="input-fasilitas-umum-lainnya-feedback"
                        >
                          <b-form-input
                            class="form-fasilitas-umum-lainnya"
                            name="kondisi_fasilitas_umum_lainnya"
                            size="sm"
                            ref="opt_fasilitas_umum_lainnya_input"
                            :state="getValidationState(validationContext)"
                            id="inputFasilitasUmumLainnya"
                            v-model="form.fasilitas_umum_lainnya"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-fasilitas-umum-lainnya-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!--  input fasilitas umum -->
                    </b-col>
                    <!-- /input bepergian -->
                    <!-- input covid_19 -->
                    <validation-provider
                      name="covid_19"
                      vid="covid_19"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        description="Kontak erat: berjabat tangan, berbicara, berada dalam seruangan dengan jarak < 1 meter"
                      >
                        <label for="input-covid_19-feedback" v-html="labelCovid19"></label>
                        <b-form-radio-group
                          v-model="covid_19"
                          name="covid_19"
                          ref="opt_covid_19"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-covid_19-feedback"
                          @change="covid19($event)"
                        >
                          <b-radio class="radio" value="ya">Ya</b-radio>
                          <b-radio value="tidak">Tidak</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-covid_19-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input covid_19 -->
                  </b-col>
                  <b-col cols="12" sm="7">
                    <!-- input checkbox jawaban -->
                    <validation-provider
                      name="checkbox_jawaban"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Saya bertanggung jawab terhadap setiap jawaban yang saya berikan pada form ini untuk dapat dipergunakan sesuai dengan kebutuhannya."
                      >
                        <b-form-checkbox
                          v-model="form.checkbox_jawaban"
                          name="checkbox_jawaban"
                          ref="opt_jawab"
                          value="ya"
                          stacked
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-checkbox-jawaban-feedback"
                        >
                          Ya
                        </b-form-checkbox>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-checkbox-jawaban-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input checkbox jawaban -->

                    <b-button
                      block
                      @click="FocusWarning()"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import apiDailyV2 from "@/store/health-form/health-daily.js";
import { extend } from "vee-validate";
import Vue from "vue";
import loginStore from "@/store/login.js";
// import vue loading
import Loading from "vue-loading-overlay";
// Import vue loading stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Import api-method fbPOST
import { fnGET, fnPOST, fnPOSTRpt, fnGETRpt } from "../../../../api/api-methods";
// Import api-path HR Daily Health V2 dan Site List
import { APIPATH_HR, APIPATH_BBIAPPS } from "../../../../api/api-paths";
import vSelect from "vue-select";
// import vue sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// Init plugin
Vue.use(Loading);
Vue.use(VueSweetalert2);
export default {
  name: "HealthDaily",
  components: {
    vSelect,
  },
  data() {
    const now = new Date();
    return {
      formName: "Form Kesehatan Harian",
      errorMessage: null,
      form: {
        nama_karyawan: null,
        nik: null,
        department: null,
        alt_department:null,
        tanggal_harian: null,
        kondisi_kesehatan: null,
        sakit_kondisi_lainnya: null,
        sakit_gejala_covid: null,
        fasilitas_umum_lainnya: null,
        checkbox_jawaban: null,
        luar_kota_keperluan_tujuan:null,
        luar_negeri_keperluan_tujuan:null,
        sakit:[],
        lokasi:null,
      },
      check_resiko: [],
      indexGejalaCovid: null,
      indexGejalaLainnya: null,
      indexTempatKerja: null,
      indexDinasLuarKota: null,
      indexFasilitasUmum: null,
      indexCovid19: null,
      indexSakitGejalaCovid:null,
      indexSakitGejalaLainnya:null,
      kondisiSakitLainnya: false,
      tempat_kerja: null,
      dinas_luar_kota: null,
      dinas_luar_negeri: null,
      fasilitas_umum: null,
      FasiltasUmumLainnya: false,
      pergi: null,
      covid_19: null,
      level_resiko:null,
      LuarKotaKeperluanTujuan: false,
      LuarNegeriKeperluanTujuan: false,
      profil_resiko:null,
      checkKondisiKesehatan: false,
      kondisiKesehatanArr:[],
      paramDinasKeluarNegeri:null,
      labelCovid19:`Apakah anda pernah melakukan kontak erat dengan keluarga / kerabat / orang yang memiliki Gejala/ Suspek Covid-19 atau terkonfirmasi Positif Covid-19 dalam <b>5 hari</b> terakhir ?`,
      FinalChecked:[],
    };
  },
  computed: {
    ...mapGetters("session", [
      "userData",
      "localDate",
      "serverDate",
      "businessUnit",
    ]),
  },

  methods: {
    ...mapActions("session", ["showNotification"]),
    chckResiko() {
      
      for(let i = 0; i < this.check_resiko.length; i++){
        this.FinalChecked.push(this.check_resiko[i].value);
      }

      if (this.FinalChecked.includes("BERESIKO TINGGI")) {
        this.level_resiko = "BERESIKO TINGGI"
      } else if (
        this.FinalChecked.includes("BERESIKO RENDAH") &&
        !this.FinalChecked.includes("BERESIKO TINGGI")
      ) {
        this.level_resiko = "BERESIKO RENDAH"
      } else if (
        !this.FinalChecked.includes("BERESIKO RENDAH") &&
        !this.FinalChecked.includes("BERESIKO TINGGI")
      ) {
         this.level_resiko = "TIDAK BERESIKO"
      }
      // console.log(this.level_resiko);
      // console.log(this.FinalChecked)
    },

    checkTempatKerja(e) {
      if (this.tempat_kerja === "ya") {
        this.check_resiko.push({'key':"tempat_kerja",'value':"BERESIKO RENDAH"});
        this.indexTempatKerja = "tempat_kerja"
        // this.indexTempatKerja = this.check_resiko.slice(-1)[0];
      } else {
        // if (this.indexTempatKerja != null) {
        //   this.check_resiko.splice(this.indexTempatKerja, 1);
        // }
        const filterTempatKerja = this.check_resiko.filter((i)=>i.key !== 'tempat_kerja');
        this.check_resiko = [];
        this.check_resiko = filterTempatKerja;
        this.indexTempatKerja = "tempat_kerja"
      }
      // this.chckResiko();
      //  console.log(this.check_resiko)
    },

    checkDinasLuarKota(e) {
      if (this.dinas_luar_kota === "ya") {
        // this.check_resiko.push("BERESIKO RENDAH");
        // this.indexDinasLuarKota = this.check_resiko.slice(-1)[0];
        this.indexDinasLuarKota = "dinas_luar_kota"
        this.check_resiko.push({'key':"dinas_luar_kota",'value':"BERESIKO RENDAH"});
        this.LuarKotaKeperluanTujuan = true;
      } else {
          this.LuarKotaKeperluanTujuan = false;
          // if (this.indexDinasLuarKota != null) {
          //   this.check_resiko.splice(this.indexDinasLuarKota, 1);
          // }
          const dinasLuarKota = this.check_resiko.filter((i)=>i.key !== 'dinas_luar_kota');
          this.check_resiko = [];
          this.check_resiko = dinasLuarKota;
          this.indexDinasLuarKota = "dinas_luar_kota"
      }
      // this.chckResiko();
      // console.log(this.check_resiko)
    },

    checkDinasLuarNegeri(e) {
      if (this.dinas_luar_negeri === "ya") {
        // this.check_resiko.push("BERESIKO TINGGI");
        // this.indexDinasLuarNegeri = this.check_resiko.slice(-1)[0];
        this.indexDinasLuarNegeri = "dinas_luar_negeri"
        this.check_resiko.push({'key':"dinas_luar_negeri",'value':"BERESIKO TINGGI"});
        this.LuarNegeriKeperluanTujuan = true;
      } else {
        this.LuarNegeriKeperluanTujuan = false;
        // if (this.indexDinasLuarNegeri != null) {
        //     this.check_resiko.splice(this.indexDinasLuarNegeri, 1);
        // }
        const DinasLuarNegeri = this.check_resiko.filter((i)=>i.key !== 'dinas_luar_negeri');
        this.check_resiko = [];
        this.check_resiko = DinasLuarNegeri;
        this.indexDinasLuarNegeri = "dinas_luar_negeri"
      }
      // this.chckResiko();
      // console.log(this.check_resiko)
    },

    checkFasilitasUmum(e) {
      if (this.fasilitas_umum === "ya") {
        // this.check_resiko.push("BERESIKO RENDAH");
        // this.indexFasilitasUmum = this.check_resiko.slice(-1)[0];
        this.indexFasilitasUmum = "fasilitas_umum"
        this.check_resiko.push({'key':"fasilitas_umum",'value':"BERESIKO RENDAH"});
        this.FasiltasUmumLainnya = true;
      } else {
        this.FasiltasUmumLainnya = false;
        // if (this.indexFasilitasUmum != null) {
        //     this.check_resiko.splice(this.indexFasilitasUmum, 1);
        // }
        const FasilitasUmum = this.check_resiko.filter((i)=>i.key !== 'fasilitas_umum');
        this.check_resiko = [];
        this.check_resiko = FasilitasUmum;
        this.indexFasilitasUmum = "fasilitas_umum"
        
      }
      // this.chckResiko();
      // console.log(this.check_resiko)
    },

    covid19(e) {
      if (this.covid_19 === "ya") {
        // this.check_resiko.push("BERESIKO TINGGI");
        // this.indexCovid19 = this.check_resiko.slice(-1)[0];
        this.indexCovid19 = "riwayat_covid"
        this.check_resiko.push({'key':"riwayat_covid",'value':"BERESIKO TINGGI"});
      } else {
        // if (this.indexFasilitasUmum != null) {
        //   this.check_resiko.splice(this.indexFasilitasUmum, 1);
        // }
        const RiwayatCovid = this.check_resiko.filter((i)=>i.key !== 'riwayat_covid');
        this.check_resiko = [];
        this.check_resiko = RiwayatCovid;
        this.indexCovid19 = "riwayat_covid"
      }
      // this.chckResiko();
      // console.log(this.check_resiko)
    },

    checkKesehatan(e) {
      if (this.form.kondisi_kesehatan == "sehat") {
        // this.check_resiko.splice(this.indexGejalaCovid, 1);
        // this.check_resiko.splice(this.indexGejalaLainnya, 1);
        this.form.kondisi_kesehatan = "sehat";
        this.checkKondisiKesehatan = false;
        this.form.sakit = [];
        this.form.sakit_kondisi_lainnya = null;
        // this.form.sakit.splice(this.indexSakitGejalaCovid, 1);
        // this.form.sakit.splice(this.indexSakitGejalaLainnya, 1);

        const GejalaLainnya = this.check_resiko.filter((i)=>i.key !== 'gejala_lainnya');
        this.check_resiko = [];
        this.check_resiko = GejalaLainnya;

        const GejalaCovid = this.check_resiko.filter((i)=>i.key !== 'gejala_covid');
        this.check_resiko = [];
        this.check_resiko = GejalaCovid;
      }else{
        this.checkKondisiKesehatan = true;
      }
      // this.chckResiko();
      // console.log(this.check_resiko)
    },

    checkPergi(e) {
      if (this.pergi == "tidak") {
        if (this.indexTempatKerja != null) {
          // this.check_resiko.splice(this.indexTempatKerja, 1);
          const filterTempatKerja = this.check_resiko.filter((i)=>i.key !== 'tempat_kerja');
          this.check_resiko = [];
          this.check_resiko = filterTempatKerja;
        }

        if (this.indexDinasLuarKota != null) {
          // this.check_resiko.splice(this.indexDinasLuarKota, 1);
          const dinasLuarKota = this.check_resiko.filter((i)=>i.key !== 'dinas_luar_kota');
          this.check_resiko = [];
          this.check_resiko = dinasLuarKota;
        }

        if (this.indexDinasLuarNegeri != null) {
          // this.check_resiko.splice(this.indexDinasLuarNegeri, 1);
          const DinasLuarNegeri = this.check_resiko.filter((i)=>i.key !== 'dinas_luar_negeri');
          this.check_resiko = [];
          this.check_resiko = DinasLuarNegeri;
        }

        if (this.indexFasilitasUmum != null) {
          // this.check_resiko.splice(this.indexFasilitasUmum, 1);
          const FasilitasUmum = this.check_resiko.filter((i)=>i.key !== 'fasilitas_umum');
          this.check_resiko = [];
          this.check_resiko = FasilitasUmum;
        }

        // this.chckResiko();
        // console.log(this.check_resiko)
      }
    },

    // Get account info
    getAccountInfo() {
      let userData = this.userData;
      this.$set(this.form, "nama_karyawan", userData.fullname);
      this.$set(this.form, "nik", userData.nik);
      if (userData.business_unit != "BBI1") {
        this.$set(this.form, "department", userData.subdept);
      } else {
        this.$set(this.form, "department", userData.dept);
      }
      this.$set(this.form, "alt_department", userData.alt_dept);
      this.$set(this.form, "lokasi", userData.business_unit);

      this.$set(this.form, "tanggal_harian", this.serverDate);
    },
    
    // Get validation
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    checkBepergian() {
      if (this.pergi == "ya") {
        return true;
      } else {
        this.dinas_luar_kota = null;
        this.dinas_luar_negeri = null;
        this.fasilitas_umum = null;
        this.tempat_kerja = null;
      }
      return false;
    },

    check: function (e) {
      if (
        e.target.value ===
        "batuk, radang, pilek, demam, pegal, hilang, rasa/bau"
      ) {
        if (e.target.checked) {
          // this.check_resiko.push("BERESIKO TINGGI");
          // this.indexGejalaCovid = this.check_resiko.slice(-1)[0];
          // this.indexSakitGejalaCovid = this.form.sakit.slice(-1)[0];
          this.form.sakit_gejala_covid = e.target.value;
          this.check_resiko.push({'key':"gejala_covid",'value':"BERESIKO TINGGI"});
          this.indexGejalaCovid = "gejala_covid"
        } else {
          // this.check_resiko.splice(this.indexGejalaCovid, 1);
          // this.form.sakit.splice(this.indexSakitGejalaCovid, 1);
          const GejalaCovid = this.check_resiko.filter((i)=>i.key !== 'gejala_covid');
          this.check_resiko = [];
          this.check_resiko = GejalaCovid;
          this.form.sakit_gejala_covid = null;
        }
      } else if (e.target.value === "lainnya") {
        if (e.target.checked) {
          // this.check_resiko.push("BERESIKO RENDAH");
          // this.indexGejalaLainnya = this.check_resiko.slice(-1)[0];
          this.kondisiSakitLainnya = true;
          this.check_resiko.push({'key':"gejala_lainnya",'value':"BERESIKO RENDAH"});
          this.indexGejalaLainnya = "gejala_lainnya"
        } else {
          // this.check_resiko.splice(this.indexGejalaLainnya, 1);
          this.kondisiSakitLainnya = false;
          // this.form.sakit.splice(this.indexSakitGejalaCovid, 1);
          //  this.form.sakit_kondisi_lainnya = null;
          const GejalaLainnya = this.check_resiko.filter((i)=>i.key !== 'gejala_lainnya');
          this.check_resiko = [];
          this.check_resiko = GejalaLainnya;
        }
      }
      // this.chckResiko();
      // console.log(this.check_resiko)  
    },
    // animation shake and autoscroll
    FocusWarning() {
      if (
        this.form.kondisi_kesehatan == null ||
        this.form.kondisi_kesehatan == ""
      ) {
        this.$refs.opt_kesehatan.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_kesehatan.$el.classList.value =
          this.$refs.opt_kesehatan.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_kesehatan.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }

      if (this.pergi == null || this.pergi == "") {
        this.$refs.opt_bepergian.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_bepergian.$el.classList.value =
          this.$refs.opt_bepergian.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_bepergian.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }

      if (this.pergi == "ya") {
        if (this.tempat_kerja == null || this.tempat_kerja == "") {
          this.$refs.opt_tempat_kerja.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_tempat_kerja.$el.classList.value =
            this.$refs.opt_tempat_kerja.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_tempat_kerja.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }

        if (this.dinas_luar_kota == null || this.dinas_luar_kota == "") {
          this.$refs.opt_dinas_luar_kota.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_dinas_luar_kota.$el.classList.value =
            this.$refs.opt_dinas_luar_kota.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_dinas_luar_kota.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }

        if (this.dinas_luar_negeri == null || this.dinas_luar_negeri == "") {
          this.$refs.opt_dinas_luar_negeri.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_dinas_luar_negeri.$el.classList.value =
            this.$refs.opt_dinas_luar_negeri.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_dinas_luar_negeri.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        
        if (this.fasilitas_umum == null || this.fasilitas_umum == "") {
          this.$refs.opt_fasilitas_umum.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_fasilitas_umum.$el.classList.value =
            this.$refs.opt_fasilitas_umum.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_fasilitas_umum.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
      }

      if (this.covid_19 == null || this.covid_19 == "") {
          this.$refs.opt_covid_19.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_covid_19.$el.classList.value =
            this.$refs.opt_covid_19.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_covid_19.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        
        if (
          this.form.checkbox_jawaban == null ||
          this.form.checkbox_jawaban == ""
        ) {
          this.$refs.opt_jawab.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_jawab.$el.classList.value =
            this.$refs.opt_jawab.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_jawab.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
    },

    onSubmit(){
      this.chckResiko();
      if (this.level_resiko == 'TIDAK BERESIKO') {
        this.profil_resiko = "Tidak Berisiko";
        this.messages =
          "<br>Profil resiko anda: Tidak beresiko.<br>Silahkan beraktivitas\ndan tetap lakukan protokol pencegahan Covid-19.\n#Disiplin5M";
      } else if (this.level_resiko == 'BERESIKO RENDAH') {
        this.profil_resiko = "Resiko Rendah";
        this.messages =
          "<br>Profil resiko anda: Rendah.<br>Laporkan kondisi kesehatan ke atasan anda dan beraktivitas.\nTetap lakukan protokol pencegahan Covid-19.\n#Disiplin5M";
      } else if (this.level_resiko == 'BERESIKO TINGGI') {
        this.profil_resiko = "Resiko Tinggi";
        this.messages =
          "<br>Profil resiko anda: Tinggi.<br>Laporkan kondisi anda ke atasan dan atau Personalia.\nJika anda sakit/memiliki gejala Covid-19, lakukan pemeriksaan ke Faskes/Klinik Terdekat\nTetap lakukan protokol pencegahan Covid-19.\n#Disiplin5M";
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: "#f89830",
        loader: "dots",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
      });
      
      if (this.form.kondisi_kesehatan == "sehat") {
        this.form.sakit = 'Sehat';
      }else if(this.form.kondisi_kesehatan == "sakit"){

        if(this.form.sakit_gejala_covid != null){
          this.form.sakit.push(this.form.sakit_gejala_covid);
        }

        if(this.form.sakit_kondisi_lainnya != null){
          this.form.sakit.push(this.form.sakit_kondisi_lainnya);
        }

      }

      // show report after timeout min 1 second
      setTimeout(() => {
         fnPOSTRpt(APIPATH_HR.healthDailyV4, {
          tanggal_harian: this.form.tanggal_harian,
          nama_karyawan: this.form.nama_karyawan,
          nik: this.form.nik,
          department: this.form.department,
          alt_department: this.form.alt_department,
          profil_resiko: this.profil_resiko,
          lokasi_kerja: null,
          lokasi: this.form.lokasi,
          kondisi_kesehatan: this.form.sakit.toString().replace('lainnya,', ''),
          bepergian: this.pergi,
          tempat_kerja: this.tempat_kerja,
          keluar_kota: this.dinas_luar_kota,
          tujuan_keluar_kota: this.form.luar_kota_keperluan_tujuan,
          keluar_negeri: this.dinas_luar_negeri,
          tujuan_keluar_negeri: this.form.luar_negeri_keperluan_tujuan,
          fasilitas_umum: this.fasilitas_umum,
          nama_fasilitas_umum: this.form.fasilitas_umum_lainnya,
          riwayat_kontak_covid: this.covid_19,
          checkbox_jawaban: this.form.checkbox_jawaban,
        })
          .then((response) => {
            loader.hide();
            let postSubmitInfo = `Nama : ${response.data.payload.nama} <br>NIK : ${response.data.payload.nik} <br>Tgl Lapor : ${response.data.payload.timestamp} WIB<br>`;
            postSubmitInfo += this.messages;
            Vue.swal.fire({
              icon: "info",
              title: "Laporan Berhasil Disimpan",
              html: postSubmitInfo,
            });
            // alert(postSubmitInfo);
            this.$router.push({ name: "DashboardPage" });
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });
          })
          .catch((err) => {
            loader.hide();
            if (err.response.status === 400) {
              console.log(err.response.data.message);
              this.showNotification({
                type: "error",
                message: err.response.data.message,
              });
            }
          });
      },100);
    },
    getParamDailyHealth(){
      fnGETRpt(APIPATH_HR.paramHealthDailyV4)
      .then((rsp)=>{
        const rspFilter = rsp.data.payload.filter((i)=> i.form_name === 'Daily Health v4');
        this.paramDinasKeluarNegeri = "Dinas / Perjalanan Pribadi Luar Negeri yang memiliki kasus harian Covid-19 tinggi " + " " +rspFilter[0].parameter_1;
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  },
  mounted: function () {
    this.getAccountInfo();
    this.getParamDailyHealth();
  },
};
</script>
<style src="./DailyHealthV4.scss" lang="scss" scoped />
