<template>
    <div :class="{ 'theme-helper': true, 'theme-helper-opened': opened }">
        <section class="widget theme-helper-content">
            <header class="theme-helper-header d-flex p-0">
                <div class="theme-helper-toggler" @click="toggle">
                    <div class="theme-helper-spinner bg-bbi_red text-white">
                        <i class="la la-cog"></i>
                        <i class="la la-cog fs-smaller"></i>
                    </div>
                </div>
                <!-- widget change themes sidebar -->
                <h6 class='fontbbi space2'>Choose Theme</h6>
            </header>
            <div class="widget-body mt-3">
                <div class="mt-4">                    
                    <a href="#"
                    v-on:click='ColorWhite()'
                    role="button"
                    class="btn btn-white btn-rounded-f btn-block fs-mini fontbbi space2" style='border: 1px solid #999'>
                        White
                    </a>
                    <a href="#"
                    v-on:click='ColorOrange()'
                    role="button"
                    class="btn btn-bbi_yellow btn-rounded-f btn-block fs-mini text-white fontbbi space2" style='border: 1px solid #999'>
                        Orange
                    </a>
                </div>
                <!-- end widget change themes sidebar -->


                <!-- <div class="d-flex justify-content-between mt-lg">
                    <a role="button"
                    target="_blank"
                    href="https://flatlogic.com/contact"
                    class="btn btn-outline-default btn-rounded-f fs-mini text-muted d-flex align-items-center">
                        <i class="glyphicon glyphicon-headphones mr-xs"></i>
                        Support
                    </a>
                    <a role="button"
                    target="_blank"
                    href="https://github.com/flatlogic/sing-app"
                    class="btn btn-outline-default btn-rounded-f fs-mini text-muted d-flex align-items-center">
                        <i class="fa fa-github mr-xs"></i>
                        Github
                    </a>
                </div>
                <div class="mt-lg d-flex flex-column align-items-center theme-sharing">
                    <span class="fs-sm">
                        Thank you for sharing!
                    </span>
                    <div class="d-flexjustify-content-center text-light mt-2">
                        <a target="_blank"
                        href="https://twitter.com/intent/tweet?text=Admin%20dashboard%20template%20built%20with%20NodeJS,%20React%20and%20Bootstrap%204!&url=https://demo.flatlogic.com/sing-app/html-bs4/&via=flatlogic">
                            <i class="fa fa-twitter pr-1"></i>
                        </a>
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https://demo.flatlogic.com/sing-app/html-bs4/"
                        target="_blank">
                            <i class="fa fa-facebook pl-1"></i>
                        </a>
                    </div>
                </div> -->
            </div>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'Helper',
    data() {
        return {
            opened: false,
        }
    },
    methods: {
        // ...mapActions('layout', ['SidebarColorOrange','SidebarColorWhite', 'SetThemeOrange', 'SetThemeWhite']),
        ...mapActions('layout', ['SetThemeOrange', 'SetThemeWhite']),
        toggle() {
            this.opened = !this.opened;
        },
        // script change state store layout 
        ColorOrange() {       
            this.SetThemeOrange();     
            // this.SidebarColorOrange(true);
            // this.SidebarColorWhite(false);
        },
        ColorWhite() {   
            this.SetThemeWhite();   
        //    this.SidebarColorOrange(false);
        //     this.SidebarColorWhite(true);
        },
        // end script change state store layout 
    }
};
</script>

<style src="./Helper.scss" lang="scss" scoped />
