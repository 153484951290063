<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link tag="a" to="/app/appsplm-material-development"> Material Development</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item  active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container fluid>
            <b-card>
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Material Development Form</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <b-button title="Back" size="sm" variant="warning" v-on:click="backTo()">
                                    <b-icon font-scale="2" icon="arrow-left" variant="dark"></b-icon>
                                    Back 
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                    <b-col cols>
                    <validation-observer ref="observer" v-slot="{handleSubmit}">
                        <b-form @submit.prevent="handleSubmit(onSubmit)">
                            <b-row>
                                <b-col cols="auto" md="4">
                                    <!-- Category -->
                                        <validation-provider
                                            name="category-material"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Category Material <code>*</code>
                                                </template>
                                                <b-form-select disabled id="input-category-material" v-model="form.category" :options="categoryOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                <b-form-invalid-feedback id="input-category-material">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    <!-- Used  -->
                                        <validation-provider
                                            name="used"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Used As <code>*</code>
                                                </template>
                                                <b-form-input readonly id="input-used" v-model="form.used" :options="usedOpt" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                <b-form-invalid-feedback id="input-used">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    <!-- Code -->
                                        <validation-provider
                                            name="code"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Code <code>*</code>
                                                </template>
                                                <b-form-input readonly id="input-code" v-model="form.code" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                <b-form-invalid-feedback id="input-code">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    <!-- Type -->
                                        <validation-provider
                                            name="type"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Type <code>*</code>
                                                </template>
                                                <b-form-input readonly id="input-type" v-model="form.type" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                <b-form-invalid-feedback id="input-type">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    
                                </b-col>
                                <b-col cols="auto" md="4">
                                    <!-- Description -->
                                        <validation-provider
                                            name="description"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Description <code>*</code>
                                                </template>
                                                <b-form-input readonly id="input-description" v-model="form.description" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                <b-form-invalid-feedback id="input-description">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    <!-- Images -->
                                        <!-- <validation-provider
                                            name="images"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Images <code>*</code>
                                                </template>
                                                <b-form-file disabled id="input-images" v-model="form.images" :state="getValidationState(validationContext)"  trim></b-form-file>
                                                <b-form-invalid-feedback id="input-images">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider> -->
                                    <!-- Reference -->
                                        <validation-provider
                                            name="reference"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Reference <code>*</code>
                                                </template>
                                                <b-form-select id="input-reference" v-model="form.reference" :options="refOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                <b-form-invalid-feedback id="input-reference">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                    <!-- Supplier  -->
                                        <validation-provider
                                            name="supplier"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Supplier <code>*</code>
                                                </template>
                                                <b-form-input value-field="value"
                                                            text-field="value" id="inputSupplier" readonly v-model="form.supplier"   :state="getValidationState(validationContext)"  trim></b-form-input>
                                                <b-form-invalid-feedback id="input-supplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                </b-col>
                                <b-col cols="auto" md="4">
                                    <b-form-group
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Images <code>*</code>
                                        </template>
                                        
                                        <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesUrl" :alt="form.imagesName"></b-img>

                                    </b-form-group>
                                </b-col>
                            </b-row>
                                    <b-overlay :show="busy" rounded="lg" opacity="0.85">
                                        <template #overlay>
                                            <div class="text-center">
                                                <b-icon icon="arrow-clockwise" font-scale="4" variant="primary" animation="spin"></b-icon>
                                                <p id="cancel-label">Please wait...</p>
                                                
                                            </div>
                                        </template>
                                    <b-card>
                                    <!-- Activity -->
                                        <validation-provider
                                            name="activity"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Activity <code>*</code>
                                                </template>
                                                <b-form-select disabled id="input-activity" v-model="form.activity" :options="activityOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                <b-form-invalid-feedback id="input-activity">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- <template v-for="">

                                        </template> -->
                                    <!-- Update Images -->
                                        <validation-provider
                                            name="upload-images"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                            >
                                                <template v-slot:label>
                                                    Upload Images<code>*</code>
                                                </template>
                                                <b-form-file
                                                    ref="fileInput"
                                                    id="activity-images"
                                                    v-model="form.activityFile"
                                                    :state="getValidationState(validationContext)"
                                                    placeholder="Choose file here..."
                                                    no-drop
                                                    v-on:change="uploadFile"
                                                    :file-name-formatter="formatNames"
                                                ></b-form-file>
                                                <div v-for="img in imagesUrl" :key="img.name">
                                                    <p class="small">
                                                        <a :href="img.src">{{img.fileName}}</a>
                                                        <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                            <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                        </b-badge>
                                                    </p>
                                                </div>
                                                <b-form-invalid-feedback id="inputUploadImages">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>

                                    <!-- Designer Comment -->
                                        <template v-if="designerComment">
                                            <validation-provider
                                                name="designer-comment-design"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Design
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.design"
                                                        :options="options"
                                                        name="radio-comment-design"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.design === 'not ok'">
                                                        <b-form-input  id="designer-comment-design" v-model="comment.design" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-color"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Color
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.color"
                                                        :options="options"
                                                        name="radio-comment-color"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.color === 'not ok'">
                                                        <b-form-input  id="designer-comment-color" v-model="comment.color" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-quality"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Quality
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.quality"
                                                        :options="options"
                                                        name="radio-comment-quality"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.quality === 'not ok'">
                                                        <b-form-input  id="designer-comment-quality" v-model="comment.quality" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                        </template>
                                        <!-- <template v-if="designerComment">
                                            <validation-provider
                                                name="designer-comment-yarnsize"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Yarn Size
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.yarnSize"
                                                        :options="options"
                                                        name="radio-comment-yarnsize"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.yarnSize === 'not ok'">
                                                        <b-form-input  id="designer-comment-yarnsize" v-model="comment.yarnSize" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-texture"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Texture
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.texture"
                                                        :options="options"
                                                        name="radio-comment-texture"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.texture === 'not ok'">
                                                        <b-form-input  id="designer-comment-texture" v-model="comment.texture" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-type"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Type
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.type"
                                                        :options="options"
                                                        name="radio-comment-type"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.type === 'not ok'">
                                                        <b-form-input  id="designer-comment-type" v-model="comment.type" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-width"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Width
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.width"
                                                        :options="options"
                                                        name="radio-comment-width"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.width === 'not ok'">
                                                        <b-form-input  id="designer-comment-width" v-model="comment.width" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-finishing"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Fiinishing
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.finishing"
                                                        :options="options"
                                                        name="radio-comment-finishing"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.finishing === 'not ok'">
                                                        <b-form-input  id="designer-comment-finishing" v-model="comment.finishing" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-weight"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Weight
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.weight"
                                                        :options="options"
                                                        name="radio-comment-weight"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.weight === 'not ok'">
                                                        <b-form-input  id="designer-comment-weight" v-model="comment.weight" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-weaving"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Weaving
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.weaving"
                                                        :options="options"
                                                        name="radio-comment-weaving"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.weaving === 'not ok'">
                                                        <b-form-input  id="designer-comment-weaving" v-model="comment.weaving" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-composition"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Composition
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.composition"
                                                        :options="options"
                                                        name="radio-comment-composition"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.composition === 'not ok'">
                                                        <b-form-input  id="designer-comment-composition" v-model="comment.composition" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-atribute1"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Atribute 1
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.atribute1"
                                                        :options="options"
                                                        name="radio-comment-atribute1"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.atribute1 === 'not ok'">
                                                        <b-form-input  id="designer-comment-atribute1" v-model="comment.atribute1" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-atribute2"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Atribute 1
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.atribute2"
                                                        :options="options"
                                                        name="radio-comment-atribute2"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.atribute2 === 'not ok'">
                                                        <b-form-input  id="designer-comment-atribute2" v-model="comment.atribute2" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-construction"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Construction
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.construction"
                                                        :options="options"
                                                        name="radio-comment-construction"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.construction === 'not ok'">
                                                        <b-form-input  id="designer-comment-weight" v-model="comment.construction" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-supplier"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Supplier
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.supplier"
                                                        :options="options"
                                                        name="radio-comment-supplier"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.supplier === 'not ok'">
                                                        <b-form-input  id="designer-comment-supplier" v-model="comment.supplier" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                            <validation-provider
                                                name="designer-comment-color"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        color
                                                    </template>
                                                    <b-form-radio-group
                                                        v-model="commentOpt.color"
                                                        :options="options"
                                                        name="radio-comment-color"
                                                        :state="getValidationState(validationContext)" 
                                                    >
                                                    </b-form-radio-group>
                                                    <template v-if="commentOpt.color === 'not ok'">
                                                        <b-form-input  id="designer-comment-color" v-model="comment.color" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                </b-form-group>
                                            </validation-provider>
                                        </template>  -->
                                    <!-- /Designer Comment -->
                                    <!-- Finish -->
                                        <template  v-if="finish">
                                            <b-card>
                                            <b-row>
                                                <b-col sm="auto" md="6">
                                                    <!-- Brand -->
                                                        <validation-provider
                                                            name="brand"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Brand
                                                                </template>
                                                                <b-form-input readonly id="inputBrand" v-model="form.finish.brand"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Brand -->

                                                    <!-- YarnSize -->
                                                        <validation-provider
                                                            name="yarnSize"
                                                            :rules="{ required: false }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary"> Yarn Size</p>
                                                                </template>
                                                                <b-form-select 
                                                                v-on:change="generateCode()"
                                                                value-field="value"
                                                                text-field="value" id="inputYarnSize" v-model="form.finish.yarnSize" :options="yarnOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                <b-form-invalid-feedback id="inputYarnSize">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /YarnSize -->

                                                    <!-- Texture -->
                                                        <validation-provider
                                                            name="Texture"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary"> Texture </p>
                                                                </template>
                                                                <b-form-select  v-on:change="generateCode()" value-field="value"
                                                            text-field="value" id="inputTexture" v-model="form.finish.texture" :options="textureOpt"   :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                <b-form-invalid-feedback id="inputTexture">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Texture -->

                                                    <!-- Month -->
                                                        <validation-provider
                                                            name="Month"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Month<code>*</code>
                                                                </template>
                                                                <b-form-input readonly value-field="value"
                                                            text-field="value" id="inputMonth" v-model="form.finish.month"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Month -->

                                                    <!-- Year -->
                                                        <validation-provider
                                                            name="Year"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Year
                                                                </template>
                                                                <b-form-input readonly value-field="value"
                                                            text-field="value" id="inputYear" v-model="form.finish.year"   :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputYear">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Year -->

                                                    <!-- Code -->
                                                        <validation-provider
                                                            name="Code"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Code
                                                                </template>
                                                                <b-form-input readonly id="inputCode" v-model="form.finish.code"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Code -->

                                                    <!-- Type -->
                                                        <validation-provider
                                                            name="Type"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Type<code>*</code>
                                                                </template>
                                                                <b-form-input readonly
                                                                value-field="value" text-field="value" id="inputType" v-model="form.finish.type"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputType">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Type -->
                                                    
                                                    <!-- Width -->
                                                        <validation-provider
                                                            name="Width"
                                                            :rules="{ required: false }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary">Width</p>
                                                                </template>
                                                                <b-form-select v-on:change="generateCode()" value-field="value"
                                                            text-field="value" id="inputWidth" v-model="form.finish.width" :options="widthOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                <b-form-invalid-feedback id="inputWidth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Width -->

                                                    <!-- ChemicalFinishing -->
                                                        <validation-provider
                                                            name="ChemicalFinishing"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary">Chemical Finishing </p>
                                                                </template>
                                                                <b-form-select value-field="value"
                                                            text-field="value" id="inputChemicalFinishing" v-model="form.finish.chemicalFinishing"  :options="chemicalFinishingOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                <b-form-invalid-feedback id="inputChemicalFinishing">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /ChemicalFinishing -->

                                                    <!-- RunningNumber -->
                                                        <validation-provider
                                                            name="RunningNumber"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Running Number
                                                                </template>
                                                                <b-form-input readonly  id="inputRunningNumber" v-model="form.finish.runningNumber"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputRunningNumber">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /RunningNumber -->
                                                    
                                                </b-col>
                                                <b-col sm="auto" md="6">

                                                    <!-- Description -->
                                                        <validation-provider
                                                            name="Description"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Description
                                                                </template>
                                                                <b-form-input readonly id="inputDescription" v-model="form.finish.description"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputDescription">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Description -->

                                                    <!-- Weight -->
                                                        <validation-provider
                                                            name="Weight"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    Weight
                                                                </template>
                                                                <b-form-input readonly id="inputWeight" v-model="form.finish.weight"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputWeight">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Weight -->

                                                    <!-- Weaving Type -->
                                                        <validation-provider
                                                            name="WeavingType"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                  Weaving Type
                                                                </template>
                                                                <!-- <b-form-select value-field="value"
                                                                    text-field="value" id="inputWeavingType" v-model="form.finish.weavingType" :options="weavingOpt"   :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                                                    <b-form-input readonly  id="inputWeavingType" v-model="form.finish.weavingType" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputWeavingType">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Weaving Type -->

                                                    <!-- Composition -->
                                                        <validation-provider
                                                            name="Composition"
                                                            :rules="{ required: false }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                
                                                                    <template v-slot:label>
                                                                        <p class="text-primary">Composition</p>
                                                                    </template>
                                                                <b-input-group>
                                                                    <b-form-select v-on:change="generateCode()"  value-field="value"
                                                            text-field="value" id="inputComposition" v-model="form.finish.composition" :options="compositionOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                    
                                                                    <template #append>
                                                                        <b-form-select id="inputCompositionValue" v-model="form.finish.compositionValue" :options="valueOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                    </template>
                                                                </b-input-group>
                                                                    <b-form-invalid-feedback id="inputComposition">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    <b-form-invalid-feedback id="inputCompositionValue">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Composition -->

                                                    <!-- Atribute1 -->
                                                        <validation-provider
                                                            name="Atribute1"
                                                            :rules="{ required: false }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                
                                                                    <template v-slot:label>
                                                                        <p class="text-primary">Atribute 1</p>
                                                                    </template>
                                                                <b-input-group>
                                                                    <b-form-select value-field="value"
                                                            text-field="value" id="inputAtribute1" v-model="form.finish.atribute1" :options="compositionOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                    
                                                                    <template #append>
                                                                        <b-form-select id="inputAtribute1Value" v-model="form.finish.atribute1Value" :options="valueOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                    </template>
                                                                </b-input-group>
                                                                    <b-form-invalid-feedback id="inputAtribute1">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    <b-form-invalid-feedback id="inputAtribute1Value">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Atribute1 -->
                                                    
                                                    <!-- Atribute2 -->
                                                        <validation-provider
                                                            name="Atribute2"
                                                            :rules="{ required: false }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                
                                                                    <template v-slot:label>
                                                                        <p class="text-primary">Atribute 2</p>
                                                                    </template>
                                                                <b-input-group>
                                                                    <b-form-select value-field="value"
                                                            text-field="value" id="inputAtribute2" v-model="form.finish.atribute2" :options="compositionOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                    
                                                                    <template #append>
                                                                        <b-form-select id="inputAtribute2Value" v-model="form.finish.atribute2Value" :options="valueOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                    </template>
                                                                </b-input-group>
                                                                    <b-form-invalid-feedback id="inputAtribute2">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                                    <b-form-invalid-feedback id="inputAtribute2Value">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Atribute2 -->
                                                        
                                                    <!-- Construction -->
                                                        <validation-provider
                                                            name="Construction"
                                                            :rules="{ required: false }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary">Construction</p>
                                                                </template>
                                                                <b-form-select v-on:change="generateCode()" value-field="value"
                                                            text-field="value" id="inputConstruction" v-model="form.finish.construction" :options="constructionOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                <b-form-invalid-feedback id="inputConstruction">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Construction -->

                                                    <!-- Code Handloom -->
                                                        <validation-provider
                                                            name="CodeHandloom"
                                                            :rules="{ required: false }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary">Code Handloom</p>
                                                                </template>
                                                                <b-form-input id="inputCodeHandloom" v-model="form.finish.codeHandloom"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                                <b-form-invalid-feedback id="inputCodeHandloom">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Code Handloom -->

                                                    <!-- Supplier -->
                                                        <!-- <validation-provider
                                                            name="Supplier"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary">Supplier</p>
                                                                </template>
                                                                <b-form-select value-field="value"
                                                            text-field="value" id="inputSupplier"  v-model="form.finish.supplier" :options="supplierOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                <b-form-invalid-feedback id="inputSupplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider> -->
                                                    <!-- /Supplier -->

                                                    <!-- Color -->
                                                        <validation-provider
                                                            name="Color"
                                                            :rules="{ required: true }"
                                                            v-slot="validationContext"
                                                        >
                                                            <b-form-group 
                                                                label-cols          ="3" 
                                                                label-cols-lg       ="3" 
                                                                label-size          ="sm"
                                                            >
                                                                <template v-slot:label>
                                                                    <p class="text-primary">Color</p>
                                                                </template>
                                                                <b-form-select value-field="value"
                                                            text-field="value" id="inputColor" v-model="form.finish.color" :options="colorOpt"   :state="getValidationState(validationContext)"  trim></b-form-select>
                                                                <b-form-invalid-feedback id="inputColor">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-form-group>
                                                        </validation-provider>
                                                    <!-- /Color -->
                                                    
                                                </b-col>
                                            </b-row>
                                            </b-card>
                                            
                                        </template>
                                    <!-- /Finish -->
                                    <!-- Remark -->
                                        <validation-provider
                                            name="remark"
                                            :rules="{ required: false }"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group 
                                                label-cols          ="3" 
                                                label-cols-lg       ="3" 
                                                label-size          ="sm"
                                                
                                            >
                                                <template v-slot:label>
                                                    Remark<code>*</code>
                                                </template>
                                                <b-form-textarea 
                                                    
                                                    id="input-remark" 
                                                    v-model="form.remark"   
                                                    :state="getValidationState(validationContext)"  
                                                    trim></b-form-textarea>
                                                <b-form-invalid-feedback id="input-remark">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <template v-if="roles">
                                            <template v-if="!finishButton">
                                            <b-row>
                                                <b-col sm="auto" md="4">
                                                    <div>
                                                        <b-button block variant="success" type="submit">Approve</b-button>
                                                    </div>
                                                </b-col>
                                                <b-col sm="auto" md="4">
                                                    <div>
                                                        <b-button block variant="warning" type="submit" >Reject</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            </template>
                                            <template v-else>
                                                <b-row>
                                                <b-col sm="auto" md="4">
                                                    <div>
                                                        <b-button block variant="success" type="submit">Submit</b-button>
                                                    </div>
                                                </b-col>
                                                <b-col sm="auto" md="4">
                                                    <div>
                                                        <b-button block variant="warning" v-on:click="backMenu()" >Cancel</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                            </template>
                                        </template>
                                    </b-card>
                                    </b-overlay>
                                    <b-card>
                                        <b-table
                                            striped 
                                            hover
                                            show-empty
                                            small
                                            responsive
                                            :items="loadActivity"
                                            :fields="fields"
                                        >
                                            <template #cell(comment)="data">
                                                <!-- <p> {{data.value}} </p> -->
                                                <p v-for="item in data.value" :key="item">
                                                    {{item}}
                                                </p>
                                            </template>
                                            <template #cell(date)='{item}'>
                                                {{new Date(item.date).toLocaleString("id-ID")}}
                                            </template>
                                        </b-table>
                                    </b-card>
                                
                        </b-form>
                    </validation-observer>
                    </b-col>
                    <b-col cols="12" sm="6" xl="3" lg="12">
                     <b-card
                        header="Disscusion Activity"
                        header-tag="header"
                        class="scroll"
                      >
                        <template>
                            <template v-for="item in dataMessage" >
                                <div :key="item.id" class="mt-4" >
                                <div class="left-chat">
                                <p v-if="item.username !== form.username" class="h5">
                                    <b-icon icon="check2-all"></b-icon> {{ item.message }}
                                    <i style="font-size:10px; display:block; padding-top:5px;">{{ item.fullname }} - {{ new Date (item.date_time).toDateString() }}</i>
                                </p>
                                </div>
                                <div class="right-chat">
                                <p v-if="item.username === form.username" class="h5">
                                    <b-icon icon="check2-all"></b-icon> {{ item.message }}
                                    <i style="font-size:10px; display:block; padding-top:5px;">You - {{ new Date (item.date_time).toDateString() }}</i>
                                </p>
                                </div>
                                </div> 
                            </template>
                            <template>
                                <div style="margin-top:30px; padding-bottom:30px;">
                                    <b-form inline @submit.prevent="sendMessage">
                                    <b-form-input
                                    v-model="dataMessageAct" 
                                    id="inline-form-input-message"
                                    class="mb-2 mb-sm-0"
                                    style="max-width:70% !important"
                                    placeholder="Write Message....."
                                    ></b-form-input>
                                    <b-button variant="primary" type="submit" style="max-width:30% !important;">Send</b-button>
                                     </b-form>
                                </div>
                            </template>
                        </template>
                     </b-card>
                    </b-col>
                    </b-row>
                </template>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import {mapActions,mapGetters} from 'vuex'
import apiProduct from '@/store/product-form/master-param.js'
import { APIPATH_PLM } from "../../../../api/api-paths";
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../api/api-methods";
export default {
    // props:['material_id','mdev_id','supplier_name'],
    data(){
        const now = new Date()
        const today = now.toISOString().slice(0,16)
        return{
            material_id:this.$route.query.material_id,
            mdev_id:this.$route.query.mdev_id,
            supplier_name:this.$route.query.supplier_name,
            dataMessageAct:null,
            messageAll: [],
            modulName:'Activity',
            formName:'Material Design',
            mainProps: {width: 200, height: 200, class: 'm1' },
            designerComment:false,
            finishing:false,
            finishButton:true,
            finish:false,
            isSupplier:false,
            materialMasterId:null,
            busy:false,
            form:{
                product_material_id:null,
                supplier_id:null,
                supplier_name:null,
                supplier_code:null,
                status:null,
                id:null,
                username:null,
                role:null,
                category:null,
                used:null,
                type:null,
                description:null,
                code:null,
                supplier:null,
                reference:null,
                images:null,
                activity:null,
                remark:null,
                date: today,
                nama_karyawan: null,
                nik:null,
                department:null,
                lokasi:null,
                comment:null,
                isComplete:null,
                imagesUrl:null,
                imagesName:null,
                activityFile:null,
                finish:{
                    brand:null,
                    yarnSize:null,
                    texture:null,
                    month:null,
                    code:null,
                    type:null,
                    width:null,
                    chemicalFinishing:null,
                    runningNumber:null,
                    description:null,
                    composition:null,
                    compositionValue:null,
                    construction:null,
                    year:null,
                    // images:null,
                    // date:null,
                    weavingType: null,
                    weight:null,
                    // createdBy:null,
                    codeTemp:null,
                    status:null,
                    color:null,
                    atribute1:null,
                    atribute1Value:null,
                    atribute2:null,
                    atribute2Value:null,
                    codeHandloom:null,
                }
            },
            comment:{
                // yarnSize:null,
                // texture:null,
                // type:null,
                // width:null,
                // finishing:null,
                // weight:null,
                // weaving:null,
                // composition:null,
                // atribute1:null,
                // atribute2:null,
                // construction:null,
                // supplier:null, 
                color:null,
                quality:null,
                design:null
            },
            commentOpt:{
                // yarnSize:null,
                // texture:null,
                // type:null,
                // width:null,
                // finishing:null,
                // weight:null,
                // weaving:null,
                // composition:null,
                // atribute1:null,
                // atribute2:null,
                // construction:null,
                // supplier:null, 
                color:null,
                quality:null,
                design:null
            },
            materialValue:null,
            options:[
                {value:'ok', text:'APPROVE'},
                {value:'not ok', text:'REJECT'}
            ],
            dataMaterialDev:[],
            datMaterialDevAct:[],
            fields:[
                {key:'actions', label:'#'},
                'date',
                'activity',
                'images',
                'user',
                'remark',
                {key:'comment',label:'COMMENT',formatter:this.formatComment},
            ],
            categoryOpt:[
                {value:'fabric', text:'fabric'},
                {value:'accessories', text:'accessories'},
                {value:'thread', text:'thread'},
                {value:'artwork', text:'artwork'},
                {value:'interlining', text:'interlining'},
            ],
            usedOpt:[
                {value:'main', text:'Main'},
                {value:'collar', text:'Collar'},
                {value:'pocket', text:'Pocket'},
            ],
            supplierOpt:[
                {value:'PT. XYZ', text:'PT. XYZ',},
                {value:'PT. ABC', text:'PT. ABC',},
                {value:'PT. JKL', text:'PT. JKL',},
                {value:'PT. OPQ', text:'PT. OPQ',},
            ],
            refOpt:[
                {value:'REF 1', text:'REF 1'},
                {value:'REF 2', text:'REF 2'},
                {value:'REF 3', text:'REF 3'},
                {value:'REF 4', text:'REF 4'},
            ],
            activityOpt:[
                
            ],
            weavingOpt:[
                {value:'Weaving A',text:'Weaving A'},
                {value:'Weaving B',text:'Weaving B'},
                {value:'Weaving C',text:'Weaving C'},
            ],
            brandOpt:[],
            typeOpt:[],
            textureOpt:[],
            chemicalFinishingOpt:[],
            yearOpt:[],
            monthOpt:[],
            yarnOpt:[],
            widthOpt:[],
            compositionOpt:[],
            atribute1Opt:[],
            atribute2Opt:[],
            valueOpt:[
                {value:'100',text:'100'},
                {value:'97',text:'97'},
                {value:'80',text:'80'},
                {value:'60',text:'60'},
                {value:'55',text:'55'},
                {value:'45',text:'45'},
                {value:'40',text:'40'},
                {value:'20',text:'20'},
                {value:'3',text:'3'},
            ],
            constructionOpt:[],
            colorOpt:[],
            role:['Super User','MD'],
            rols:['Super User'],
            roleActivity:[
                {
                    ids:['0'],
                    role:['Super User']
                },
                {
                    ids:['1','2','3','4','5','6','8','9','10','11'],
                    role:['Super User','MD']
                },
                {
                    ids:['7'],
                    role:['Super User','Designer']
                }
            ],
            imagesUrl:[]
            

            
        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('productDesign',['add','get','addActivity','getMaterialDev', 'postMaterialDev','GET_PRODUCT_MATERIAL_DEV']),
        ...mapActions('materialDev',['GET_MATERIALDEV_DASHBOARD','GET_MATERIALDEV_DETAIL','POST_MATERIALDEV_DETAIL','GET_MATERIALDEV_ACTIVITY','POST_MATERIALDEV_ACTIVITY','POST_ACTIVITY_FILES']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL','POST_MASTER_MATERIAL','PUT_MASTER_MATERIAL','POST_MATERIAL_IMAGES','addMaster','editMaster','updateMaster','deleteMaster']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getData(){
            this.GET_MATERIALDEV_DASHBOARD().then(response => {
                console.log('material_id',this.material_id)
                console.log('mdev_id',this.mdev_id)
                console.log('supplier_name',this.supplier_name)

                console.log('act data',response.data.payload);
                let dataMaterial = response.data.payload.filter(x=>x.product_master_id == this.material_id)
                
                this.form.category = dataMaterial[0].category
                this.form.type= dataMaterial[0].type
                this.form.description= dataMaterial[0].description
                this.form.code= dataMaterial[0].code
                // this.form.supplier= dataMaterial[0].supplier
                this.form.reference= dataMaterial[0].reference
                this.form.images= dataMaterial[0].images
                this.form.used= dataMaterial[0].used_as
                this.form.imagesUrl= dataMaterial[0].img_src
                this.form.imagesName= dataMaterial[0].img_name

                this.materialMasterId = dataMaterial[0].material_id
                
                
            })
        },
        setSupplier(){
            // console.log('set supplier',this.supplier_name);
            // if(typeof this.supplier_name !== undefined ){
            //     this.form.supplier = this.supplier_name
            //     console.log('set',this.form.supplier);
            //     this.isSupplier = true
            // }
            // if(typeof this.form.supplier === undefined){
            //     this.isSupplier = false
            //     console.log('not set', this.isSupplier);
            // }
            this.form.supplier = this.supplier_name
        },
        
        onSubmit(){
            this.busy = true
            var opt = this.commentOpt
                if(
                    // opt.yarnSize === 'not ok'||
                    // opt.texture === 'not ok'||
                    // opt.type === 'not ok'||
                    // opt.width === 'not ok' ||
                    // opt.finishing === 'not ok'||
                    // opt.weight === 'not ok'||
                    // opt.weaving === 'not ok'||
                    // opt.composition === 'not ok'||
                    // opt.atribute1 === 'not ok'||
                    // opt.atribute2 === 'not ok'||
                    // opt.construction === 'not ok'||
                    // opt.supplier === 'not ok'||
                    opt.color === 'not ok'||
                    opt.quality === 'not ok'||
                    opt.design === 'not ok'
                    ){
                        this.form.isComplete = 2
                        
                    }else{
                        this.form.isComplete = this.form.activity+1
                    }
            let comments = {
                commentOpt : this.commentOpt,
                commentValue : this.comment
            }
            if(this.comment.color != null||this.comment.design != null||this.comment.quality != null){
                this.form.comment = JSON.stringify(comments)
            }else{
                this.form.comment = null
            }
            this.generateCode()
            console.log(this.form.finish.code);
            const actId = this.activityOpt.filter(x => x.value == this.form.activity)
            // console.log('actId',this.activityOpt.filter(xz => xz.value == this.form.activity));
            this.POST_MATERIALDEV_ACTIVITY({
                formName: 'activity',
                date: this.form.date,
                activity_id: actId[0].id,
                material_dev_id: this.mdev_id,
                user: this.form.nama_karyawan,
                remark:this.form.remark,
                comment:this.form.comment,
                material_value:JSON.stringify(this.form.finish),
                next_value: this.form.isComplete
                
            }).then(response => {
                    if(response.status === 200){
                        this.finish = false
                    }
                    // if(this.form.activity === 10){
                    //     this.generateCode()
                    //     this.postFinish()
                    // }
                    // console.log('activity success',response.data.payload);
                    if(this.imagesUrl.length > 0){
                        this.submitImages(response.data.payload.activity_id)
                    }
                    this.busy = false
                    this.showNotification({type:'success', message:'Successfully'})
                    this.imagesUrl = []
                    this.form.activityFile = null
                    this.getActivity()
            })
            this.clearForm()

        },
        postFinish(){
            this.busy = true
            this.PUT_MASTER_MATERIAL({
                material_id:this.masterMaterialId,
                category:this.form.category,
                formname:this.formName,
                code:this.form.finish.code,
                brand:this.form.finish.brand,
                type:this.form.finish.type,
                composition:this.form.finish.composition,
                composition_value:this.form.finish.compositionValue,
                yarn_size:this.form.finish.yarnSize,
                width:this.form.finish.width,
                construction:this.form.finish.construction,
                texture:this.form.finish.texture,
                chemical_finishing:this.form.finish.chemicalFinishing,
                year:this.form.finish.year,
                month:this.form.finish.month,
                running_number:this.form.finish.runningNumber,
                weaving_type: this.form.finish.weavingType,
                description:this.form.finish.description,
                weight: this.form.finish.weight,
                supplier:this.form.finish.supplier,
                date:this.form.finish.date,
                createdBy:this.form.finish.createdBy,
                status:'Finish',
                color:this.form.finish.color,
                atribute1:this.form.finish.atribute1,
                atribute1_value:this.form.finish.atribute1Value,
                atribute2:this.form.finish.atribute2,
                atribute2_value:this.form.finish.atribute2Value,
                code_handloom:this.form.finish.codeHandloom
            }).then(response =>{
                if(response.status === 200){
                    this.finish = false
                    this.busy = false
                }
            }).catch(error => {
                return error.response.data.message
            })
        },
        getActivity(){
            this.busy = true
            this.GET_MATERIALDEV_ACTIVITY()
            .then(response => {
                let id = this.mdev_id
                let dataActivity = response.data.payload
                let objActivity = dataActivity.filter(i=>i.material_dev_id == id )
                this.dataMaterialDevAct = objActivity

                // console.log('data material',this.dataMaterialDev);
                // for (let i = 0; i < this.dataMaterialDev.length; i++) {
                    //     let dats = this.dataMaterialDev[i].comment;
                    
                //     console.log('dats',JSON.parse(dats).commentValue);
                // }
                // let lastActivity = objActivity[objActivity.length - 1].activity
                // last activity tergantung order_by dari backend
                // kalo desc = activity[0] & asc activity[activity.length - 1]
                    console.log('mdev act', this.dataMaterialDevAct[0]);
                
                if(objActivity.length > 0){
                    let lastActivity = objActivity[0].next_value
                    if(objActivity[0].activity == 'Finish'){
                        this.ChangeStatusSupplier()
                    } 
                    console.log('last activity id',objActivity[0].activity);
                    return this.setActivity2(lastActivity)
                }else{
                    let lastActivity = null
                    return this.setActivity2(lastActivity)
                }
                
            })
            this.busy = false
        },
        formatComment(value){
            // for (let i = 0; i < value.length; i++) {
            //         let dats = this.value[i];
            //         let commentVal = JSON.parse(dats).commentValue

                    
            //         console.log('dats',JSON.parse(dats));

            //         return commentVal
            //     }
            if (value != null) {
                const commentVal = JSON.parse(value).commentValue
                const entries = Object.entries(commentVal)
                const data = entries.filter(([key,val])=> val !== '' && val !== null)
                // const filt = commentVal.filter(([key,val])=> val !== null)
                const comment = []
                for (let i = 0; i < data.length; i++) {
                    const filt = data[i].join(' : ')
                        comment.push(filt)
                }

                return comment
            }
            // return JSON.parse(value)
        },
        setActivity2(lastActivity){
            console.log('activity',lastActivity);
            
            if(lastActivity === null){
                this.form.activity = 1
            }else if(lastActivity === 7){
                this.form.activity = lastActivity
                this.designerComment = true
            }else if(lastActivity === 9){
                this.setFinish()
                this.form.activity = lastActivity
                this.finish = true
            }
            else{
                this.form.activity = lastActivity
            }
        },
        getMatdevDetail(){
            this.GET_MATERIALDEV_DETAIL().then(response => {
               const dataMatdevDetail = response.data.payload.filter(x => x.product_material_id == this.material_id && x.id == this.mdev_id)
               this.form.product_material_id = dataMatdevDetail[0].product_material_id
               this.form.supplier_id = dataMatdevDetail[0].supplier_id
               this.form.supplier_name = dataMatdevDetail[0].supplier_name
               this.form.supplier_code = dataMatdevDetail[0].supplier_code
               this.form.status = dataMatdevDetail[0].status
               this.form.id = dataMatdevDetail[0].id

            })
        },
        ChangeStatusSupplier(){
            this.POST_MATERIALDEV_DETAIL({
                id: this.form.id,
                product_material:this.form.product_material_id,
                supplier_id:this.form.supplier_id,
                supplier_name:this.form.supplier_name,
                supplier_code:this.form.supplier_code,
                status:"Done"

            }).catch(error => {
                return error
            })

            this.clearForm()
        },
        clearForm(){
            this.designerComment = false
            this.form.images = null,
            this.form.remark = null,
            this.comment.yarnSize = null,
            this.comment.texture = null,
            this.comment.type = null,
            this.comment.width = null,
            this.comment.finishing = null,
            this.comment.weight = null,
            this.comment.weaving = null,
            this.comment.composition = null,
            this.comment.atribute1 = null,
            this.comment.atribute2 = null,
            this.comment.construction = null,
            this.comment.supplier = null,
            this.comment.color = null,
            this.comment.design = null,
            this.comment.quality = null,
            this.commentOpt.yarnSize = null,
            this.commentOpt.texture = null,
            this.commentOpt.type = null,
            this.commentOpt.width = null,
            this.commentOpt.finishing = null,
            this.commentOpt.weight = null,
            this.commentOpt.weaving = null,
            this.commentOpt.composition = null,
            this.commentOpt.atribute1 = null,
            this.commentOpt.atribute2 = null,
            this.commentOpt.construction = null,
            this.commentOpt.supplier = null,
            this.commentOpt.color = null,
            this.commentOpt.design = null,
            this.commentOpt.quality = null
        },
        getAccountInfo() {
                let userData      = JSON.parse(localStorage.userData)
                this.$set(this.form, 'nama_karyawan', userData.fullname)
                this.$set(this.form, 'nik', userData.nik)
                this.$set(this.form, 'department', userData.dept)
                this.$set(this.form, 'lokasi', userData.business_unit)
                this.$set(this.form, 'username', userData.username)
                this.$set(this.form, 'role', userData.role)
                
            },
        getDataParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                
                    
                    let activity = response.data.payload.filter(d=>d.category ==='Activity')
                    
                    for(var i=0;i<activity.length;i++){

                        let act = {
                            value: activity[i].value,
                            text: activity[i].name,
                            id: activity[i].id
                        }
                        this.activityOpt.push(act)
                    }
                    console.log(this.activityOpt);
                    this.brandOpt = response.data.payload.filter(d=>d.category ==='Brand')
                    this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')
                    this.yearOpt = response.data.payload.filter(d=>d.category ==='Year')
                    this.yarnOpt = response.data.payload.filter(d=>d.category ==='Yarn Size')
                    this.textureOpt = response.data.payload.filter(d=>d.category ==='Texture')
                    this.typeOpt = response.data.payload.filter(d=>d.category ==='Type of Fabric')
                    this.widthOpt = response.data.payload.filter(d=>d.category ==='Width')
                    this.chemicalFinishingOpt = response.data.payload.filter(d=>d.category ==='Finishing')
                    this.compositionOpt = response.data.payload.filter(d=>d.category ==='Composition')
                    // let compVal = response.data.payload.filter(d=>d.category ==='Composition')
                    this.atribute1Opt = response.data.payload.filter(d=>d.category ==='Composition')
                    this.atribute2Opt = response.data.payload.filter(d=>d.category ==='Composition')
                    this.constructionOpt = response.data.payload.filter(d=>d.category ==='Construction')
                    this.colorOpt = response.data.payload.filter(d=>d.category ==='Color')
                    this.weavingOpt = response.data.payload.filter(d=>d.category ==='Weaving Type')
                    // this.supplierOpt = response.data.payload.filter(d=>d.category ==='Supplier')
                    // this.colorOpt = response.data.payload.filter(d=>d.category ==='Color')

                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        setDesignerComment(){
            
            if(this.form.activity === 'Designer Comment'){
                this.designerComment = true
            }else{
                this.designerComment = false
            }
        },
        setFinish(){
            this.GET_MASTER_MATERIAL().then(response => {
                
                    let dataMaster  = response.data.payload.filter(mm => mm.material_id === this.materialMasterId)
                    // console.log('datamaster',dataMaster)
                    this.form.finish.brand= dataMaster[0].brand
                    this.form.finish.yarnSize= dataMaster[0].yarn_size
                    this.form.finish.texture= dataMaster[0].texture
                    this.form.finish.month= dataMaster[0].month
                    this.form.finish.code= dataMaster[0].code
                    this.form.finish.type= dataMaster[0].type
                    this.form.finish.width= dataMaster[0].width
                    this.form.finish.chemicalFinishing= dataMaster[0].chemical_finishing
                    this.form.finish.runningNumber= dataMaster[0].running_number
                    this.form.finish.description= dataMaster[0].description
                    this.form.finish.composition= dataMaster[0].composition
                    this.form.finish.compositionValue= dataMaster[0].compostion_value
                    this.form.finish.construction= dataMaster[0].construction
                    this.form.finish.year= dataMaster[0].year
                    this.form.finish.images= dataMaster[0].images
                    this.form.finish.supplier= dataMaster[0].supplier
                    this.form.finish.date= dataMaster[0].date
                    this.form.finish.weavingType=  dataMaster[0].weaving_type
                    this.form.finish.weight= dataMaster[0].weight
                    this.form.finish.createdBy= dataMaster[0].created_by
                    this.form.finish.codeTemp= dataMaster[0].code_temp
                    this.form.finish.status= dataMaster[0].status
                    this.form.finish.color= dataMaster[0].color
                    this.form.finish.atribute1= dataMaster[0].atribute1
                    this.form.finish.atribute1Value= dataMaster[0].atribute1_value
                    this.form.finish.atribute2= dataMaster[0].atribute2
                    this.form.finish.atribute2Value= dataMaster[0].atribute2_value
                    this.form.finish.codeHandloom= dataMaster[0].code_handloom
                
            })
        },
        generateCode(){
            // console.log('finish',this.form.finish);
            if((this.form.finish.brand && 
                this.form.finish.type && 
                this.form.finish.composition &&
                this.form.finish.yarnSize &&
                this.form.finish.width &&
                this.form.finish.construction &&
                this.form.finish.texture && 
                this.form.finish.chemicalFinishing && 
                this.form.finish.year && 
                this.form.finish.month) != null){
                    
                let brand = this.brandOpt.filter(x=>x.value === this.form.finish.brand)
                let type = this.typeOpt.filter(x=>x.value === this.form.finish.type)
                let texture = this.textureOpt.filter(x=>x.value === this.form.finish.texture)
                let finishing = this.chemicalFinishingOpt.filter(x=>x.value === this.form.finish.chemicalFinishing)
                let year = this.yearOpt.filter(x=>x.value === this.form.finish.year)
                let month = this.monthOpt.filter(x=>x.value === this.form.finish.month)
                let yarn = this.yarnOpt.filter(x=>x.value === this.form.finish.yarnSize)
                let composition = this.compositionOpt.filter(x=>x.value === this.form.finish.composition)
                let width = this.widthOpt.filter(x=>x.value === this.form.finish.width)
                let construction = this.constructionOpt.filter(x=>x.value === this.form.finish.construction)

                this.form.finish.code = brand[0].name+'-'+type[0].name+'-'+composition[0].name+yarn[0].name+'-'+width[0].name+construction[0].name+texture[0].name+'-'+finishing[0].name+year[0].name+month[0].name

                console.log(this.form.finish.code);
            }

        },
        cancel(){
            this.$router.push({name:'AppsMaterialDevPage'})
        },
        backMenu(){
            this.$router.push({
                name:'AppsMaterialDevDetailPage',
                params:{
                    material_id:this.material_id,
                }
            })
        },
        backTo(){
            // console.log('prod_id',this.productId);
            this.$router.push({
                name:'AppsMaterialDevDetailPage',
                params:{material_id:this.material_id}
            })
        },
        uploadFile(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                    images:file[i],
                    img_name:file[i].name,
                    img_code:file[i].name+'-'+[i]
                }
                this.imagesUrl.push(img)
                
            }

            
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
            return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        submitImages(activityID){
            
            const fd = new FormData()
            fd.append('activity_id', activityID)
            fd.append('file_src',this.imagesUrl[0].images)

            this.POST_ACTIVITY_FILES(fd)
            .then(response => {
                if(response.data.status === 200){
                    console.log('gambar berhasil disimpan');
                    
                }
            }).catch(err => {
                return err.response.data.message
            })

            return this.clearForm()
        },
        getChatAcivity(){
            fnPOST(APIPATH_PLM.getMaterialChatActivityAll,{
                material_development : this.mdev_id
            })
            .then(response => {
                this.messageAll = response.data.payload
                // console.log('data material chat',this.messageAll)
                if(this.mdev_id != null){
                    setTimeout(this.getChatAcivity, 15000);
                }
            }).catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        sendMessage(){
            fnPOST(APIPATH_PLM.postMaterialChatActivity,{
                material_development : this.mdev_id,
                username     : this.form.username,
                fullname     : this.form.nama_karyawan,
                role         : this.form.role,
                message      : this.dataMessageAct
                
            }).then(response => {
                    if(response.status === 200){
                        this.showNotification({type:'success', message:'Successfully'})
                        this.getChatAcivity()
                        this.dataMessageAct = null
                    }
            }).catch(error => {
                return error.response.data.message
            })
        },
        


    },
    mounted(){
        this.getDataParam()
        this.getData()
        this.getAccountInfo() 
        this.getChatAcivity()
    },
    created(){
        this.setSupplier()
        this.getActivity()
        this.getMatdevDetail()
    },
    computed:{
        ...mapGetters('productDesign',['fabricList','accList','threadList','artworkList','interliningList','activityList']),
        ...mapGetters('materialDev',['MATERIALDEV_ACTIVITY']),
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        ...mapGetters('session', ['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')
            let valueAct = []
                valueAct.push(this.form.activity)
            let roleString = roleList[0].role_list[0]
            
            let rolesAct = this.roleActivity.filter(i=>i.role.includes(roleString))
            
            if(rolesAct[0].ids.some(i=>valueAct.toString().includes(i))){
                return true
            }
            if(rolesAct[0].ids.includes('0')) {
                return true
            }
            else{
                return false
            }
        },
        loadActivity(){
            const dataActivity = this.MATERIALDEV_ACTIVITY.filter(x => x.material_dev_id == this.mdev_id)
            console.log('dataactivity',dataActivity);
            return dataActivity
        },
        dataMessage(){
            // let id_activity_chat = this.mdev_id
            // let dataM = this.messageAll.filter(x => x.garment_material == id_activity_chat)
            let dataM = this.messageAll
            return dataM 
        }

        
    }
}
</script>
<style scoped>
.scroll {
    max-height: 565px;
    overflow-y: auto;
}
.right-chat{
    display: flex;
    justify-content: flex-end;
}
.right-chat p{
    background-color:#d9fdd3;
    padding:9px;
    border-radius:11px;
    font-size:15px;
    width: 80%;
    color:black;
    margin-top: -6px;
    margin-bottom: -6px;
}
.left-chat{
    display: flex;
    justify-content: flex-start;
}
.left-chat p{
    background-color:#f0ebe3;
    padding:9px;
    border-radius:11px;
    font-size:15px;
    right:0px;
    color: black;
    width: 80%;
    margin-top: -6px;
    margin-bottom: -6px;
}
</style>