<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-list-project">List Project ID</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid>
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="List Actual Project ID" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3> List Actual Project ID</h3>
                <router-link tag="b" to="/app/sfc-list-project" style="position: absolute; right: 15px;">
                  <b-button size="md" type='button' variant="info">
                    <b-icon icon="arrow-left"></b-icon>
                    Back
                  </b-button>
                </router-link>
              </div>

            </template>

            <b-list-group flush>
              <b-list-group-item>
                <b-row align-v="center">
                  <b-col sm="6">
                    <b-form-group
                      v-if="customerName"
                      label="Customer Name"
                      label-for="Customer"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                    <b-form-input
                        id="CustomerName"
                        :readonly="true"
                        :value="customerName"
                        trim
                    ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>


            <br />

            <b-table
              :no-border-collapse="true"
              striped
              stacked="md"
              class="header-background max_width_fit"
              hover
              small
              outlined
              :per-page="paging.perPage"
              :current-page="paging.currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
              responsive
              sort-icon-left
              :fields="fields"
              :items="dataTable"
            >
              <!-- table empty -->
              <template #empty="scope">
                  <p class="text-center">{{ scope.emptyText }}</p>
              </template>

              <!-- Plan Date -->
              <template #cell(plan_date)="row">
                  <span v-if="row.value !== null" v-html="FormatDate(row.value)"></span>
              </template>

              <!-- Actual Date -->
              <template #cell(date)="row">
                  <span v-if="row.value !== null" v-html="FormatDate(row.value)"></span>
              </template>

              <!-- Sub Activity -->
              <template #cell(sub_activity_name)="row">
                  <span v-if="row.value !== null" v-html="ActivityReplace(row.value)"></span>
              </template>

              <!-- Activity -->
              <template #cell(activity_name)="row">
                  <span v-if="row.value !== null" v-html="ActivityReplace(row.value)"></span>
              </template>

            </b-table>
            <b-row>
              <b-col sm="4">
                Showing {{showPerPage}} of {{totalData}} entries
              </b-col>
              <b-col sm="4">
                <b-form-group
                  label="Per Page"
                  label-for="PerPage"
                  label-cols-sm="6"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-input
                  style="max-width:80px;"
                    id="PerPage"
                    v-model="paging.perPage"
                    @input="Pagging()"
                    type="number"
                    min="1"
                    max=999
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4" align-self="end" class="my-1">
                  <b-pagination
                      v-model="paging.currentPage"
                      :total-rows="rows"
                      :per-page="paging.perPage"
                      align="right"
                      size="sm"
                  >
                  <template #first-text><span class="">First</span></template>
                  <template #prev-text><span class="">Prev</span></template>
                  <template #next-text><span class="">Next</span></template>
                  <template #last-text><span class="">Last</span></template>
                  <template #page="{ page, active }">
                      <b v-if="active">{{Pagination(page)}} {{ page }}</b>
                      <i v-else>{{ page }}</i>
                  </template>

                  </b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";
import { fnPOST } from "../../../../api/api-methods";
import { APIPATH_SALESFORCE } from "../../../../api/api-paths";

export default {
  components: {
    ModelListSelect,
    Loader,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'short'};
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "List Actual Project ID",
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      today:today,
      isChecked:false,
      disabledForm:false,
      buttonSubmit:null,
      today_days:days,
      today_month:month,
      minDateStart:null,
      StrMonth:Strmonth,
      previousSelected:[],
      today_year:year,
      fields:[
          {key:'action', label:'Action', class:"display-hidden", sortable:false },
          {key:'project_id', label:'Project ID', class:"min-10vw", sortable:true },
          {key:'date', label:'Date', class:"min-10vw", sortable:true },
          {key:'sales_nik', label:'Sales Person', class:"display-hidden", sortable:false },
          {key:'sales_name', label:'Sales Person', class:"min-10vw", sortable:false },
          {key:'activity_name', label:'activity name', class:"min-10vw", sortable:true },
          {key:'sub_activity_name', label:'Sub Activity', class:"min-10vw", sortable:false },
          {key:'remark', label:'Remark', class:"min-10vw", sortable:false },
      ],
      dataTable:[],
      sortBy: '',
      sortDesc: false,
      showPerPage:0,
      totalData:0,
      PageNow:1,
      paging:{
          perPage:10,
          currentPage:1,
      },

      form :{
        employee_nik:null,
        employee_name:null,
        sales:'ALL',
        end_date:null,
        start_date:null,
      },
      projectID:null,
      customerName:null,

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    rows(){
        if(this.dataTable != null){
            return this.dataTable.length
        }
    }

  },
  methods: {
    ...mapActions("session", ["showNotification"]),

    setData() {
      this.form.employee_nik    = this.userData.nik
      this.form.employee_name   = this.userData.fullname
      let statusRoute           = this.$route.params.page
      this.projectID            = this.$route.params.idData
      this.customerName         = this.$route.params.customer
      if (statusRoute == undefined || statusRoute =='' ) {
        statusRoute = null
      }
      this.LoadData()
    },

    Pagging() {
        if ( this.showPerPage > this.paging.perPage) {
           this.showPerPage = this.paging.perPage
        }
        if ( this.showPerPage < this.paging.perPage) {
           this.showPerPage = this.paging.perPage
        }
        if ( this.showPerPage >= this.totalData) {
           this.showPerPage = this.totalData
        }
    },
    Pagination(value) {
        if (value !== this.PageNow) {
            if (value > this.PageNow) {
                this.PageNow = value
                // this.nextPage()
                this.showPerPage = this.paging.perPage * this.PageNow
            if (this.showPerPage >= this.totalData) {
                this.showPerPage = this.totalData
            }
            } else {
                this.PageNow = value
                this.showPerPage = this.showPerPage - (this.showPerPage - (this.paging.perPage * this.PageNow))
            if (this.showPerPage <= this.paging.perPage) {
                this.showPerPage = this.paging.perPage
            }
            }
        }
    },

    LoadData() {

      // start loading
      this.isLoadingData = true

      fnPOST(APIPATH_SALESFORCE.getListActualProject, {
        filter:this.projectID,
      })
      .then(rsp => {
        if (rsp.data.payload == null || rsp.data.payload == undefined) {
          this.dataTable = []
          this.totalData = 0
          this.showNotification({type:'info', message:'Data Not Found'})
        } else {
          this.dataTable = rsp.data.payload
          this.totalData = rsp.data.payload.length
        }
        this.showPerPage = this.paging.perPage
        if (this.totalData < this.paging.perPage) {
            this.showPerPage = this.totalData
        }
      })
      .catch(err => {
        if(err.response.status !== 401) {
            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
        }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
        return ' '
      } else {
        let date = new Date(value)
        let nameDay = this.dayNames[date.getDay()];
        let day = date.getDate()
        if (String(day).length == 1) {
          day = "0"+String(day)
        }
        let year = date.getFullYear()
        let bulan = this.monthNames[date.getMonth()]
        date = nameDay+' '+day+' '+bulan+' '+year
        return date
      }
    },
    ActivityReplace(value) {
      let string = value
      if (value !== null) {
        string = value.replace(/[^a-zA-Z0-9,()/& ]/g, "");
      }
      return string
    },
  },
  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />