import Toast from "vue-toasted";
import Vue from "vue";
import { fnApiFetchServerTime } from "../api/api-general";

Vue.use(Toast);

const getDefaultState = () => {
  return {
    stToken: localStorage.getItem("access_token") || null,
    stUserData: localStorage.getItem("userData") || null,
    stToast: null,
    stError: null,
    stInfo: null,
    stSuccess: null,
  };
};
const state = getDefaultState();
const getters = {
  token(state) {
    return state.stToken;
  },
  userData(state) {
    return state.stUserData;
  },
  localDate() {
    let curDateTime = new Date();
    // format to yyyy-MM-DD
    let dateString =
      curDateTime.getFullYear() +
      "-" +
      (curDateTime.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      curDateTime
        .getDate()
        .toString()
        .padStart(2, "0");

    return dateString;
  },
  serverDate() {
    /// Source : https://stackoverflow.com/questions/20269657/right-way-to-get-web-server-time-and-display-it-on-web-pages
    var xmlHttp;
    try {
      //FF, Opera, Safari, Chrome
      xmlHttp = new XMLHttpRequest();
    } catch (err1) {
      //IE
      try {
        xmlHttp = new window.ActiveXObject("Msxml2.XMLHTTP");
      } catch (err2) {
        try {
          xmlHttp = new window.ActiveXObject("Microsoft.XMLHTTP");
        } catch (eerr3) {
          //AJAX not supported, use CPU time.
          alert("AJAX not supported");
        }
      }
    }
    xmlHttp.open("HEAD", window.location.href.toString(), false);
    xmlHttp.setRequestHeader("Content-Type", "text/html");
    xmlHttp.send("");

    var st = xmlHttp.getResponseHeader("Date");
    var serverDate = new Date(st);

    let dateString =
      serverDate.getFullYear() +
      "-" +
      (serverDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      serverDate
        .getDate()
        .toString()
        .padStart(2, "0");

    return dateString;
  },
};
const mutations = {
  resetSessionState(state) {
    Object.assign(state, getDefaultState());
  },
  updateToken(state, data) {
    state.stToken = data;
  },
  updateUserData(state, data) {
    state.stUserData = data;
  },
  setError(state, data) {
    state.stError = data;
  },
  setInfo(state, data) {
    state.stInfo = data;
  },
  setSuccess(state, data) {
    state.stSuccess = data;
  },
};
const actions = {
  clearState({ commit }) {
    commit("resetSessionState");
  },
  setNotification({ state }, param) {
    if (param.type == "info") {
      state.stInfo = param.message;
    } else if (param.type == "error") {
      state.stError = param.message;
    } else if (param.type == "success") {
      state.stSuccess = param.message;
    }
  },
  triggerNotification({ commit, state }) {
    if (state.stInfo != null) {
      Vue.toasted.show(state.stInfo, {
        duration: 10000,
        type: "info",
        theme: "bubble",
        position: "top-center",
        keepOnHover: true,
        action: [
          {
            text: "OK",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
      commit("setInfo", null);
    }
    if (state.stError != null) {
      Vue.toasted.show(state.stError, {
        duration: 10000,
        type: "error",
        theme: "bubble",
        position: "top-center",
        keepOnHover: true,
        action: [
          {
            text: "OK",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        ],
      });
      commit("setError", null);
    }
    if (state.stSuccess != null) {
      Vue.toasted.success(state.stSuccess, {
        duration: 10000,
        position: "top-center",
        keepOnHover: true,
        type: "success",
        theme: "bubble",
        action: [
          {
            text: "OK",
            onClick: (e, toastObject) => {
              toastObject.goAway(100);
            },
          },
        ],
      });
      commit("setSuccess", null);
    }
  },
  showNotification({ state }, param) {
    this.dispatch("session/setNotification", param);
    this.dispatch("session/triggerNotification");
  },
  fetchBackendServerTime() {
    let dateTime = "";
    fnApiFetchServerTime().then((rsp) => {
      dateTime = rsp.data.payload;
    });

    return dateTime;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
