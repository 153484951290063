import { getAPI } from './api-base'
import { resolve, reject } from 'core-js/fn/promise'

const apiPath = {
// *API SFC
    //  TERM OF PAYMENT
 GetTermOfPayment               : '/v1/api/bbi-sales-force/get-term-of-payment/',

    //  MASTER CUSTOMER
 DashboardMasterCustomer        : '/v1/api/bbi-sales-force/dashboard-master-customer/',
 GetDataMasterCustomer          : '/v1/api/bbi-sales-force/get-master-customer/',
 GetDataMasterCustomerLocation  : '/v1/api/bbi-sales-force/get-master-customer-location/',
 GetDataMasterCustomerContact   : '/v1/api/bbi-sales-force/get-master-customer-contact/',

    // LIST CUSTOMER
 GetListCustomer                : '/v1/api/bbi-sales-force/get-list-customer/',
 GetListCustomerWeekly          : '/v1/api/bbi-sales-force/get-list-customer-weekly/',

    // MONTHLY PLANNING
 DashboardMonthlyPlanning       : '/v1/api/bbi-sales-force/dashboard-monthly-planning/',
 GetDataMonthlyPlanning         : '/v1/api/bbi-sales-force/get-monthly-planning/',
 GetDataMonthlyPlanningDetail   : '/v1/api/bbi-sales-force/get-monthly-planning-detail/',

    // MONTHLY APPROVAL
 GetDashboardMonthlyApproval    : '/v1/api/bbi-sales-force/dashboard-approval-monthly/',
 GetDataMonthlyApproval         : '/v1/api/bbi-sales-force/get-approval-monthly/',

    // WEEKLY PLANNING
 GetDashboardWeeklyPlanning     : '/v1/api/bbi-sales-force/dashboard-weekly-planning/',
 GetDataWeeklyPlanning          : '/v1/api/bbi-sales-force/get-weekly-planning/',
 GetDataWeeklyPlanningDetail    : '/v1/api/bbi-sales-force/get-weekly-planning-detail/',
 GetDataLastMonthlyPlanning     : '/v1/api/bbi-sales-force/last-monthly-planning/',

    // ACTIVITY REASON
 GetActivityAndReason           : '/v1/api/bbi-sales-force/get-activity-reason/',

    // SUB ACTIVITY
 ListSubActivity                : 'v1/api/bbi-sales-force/get-sub-activity-reason/',

    // WEEKLY APPROVAL
GetDashboardWeeklyApproval      : '/v1/api/bbi-sales-force/dashboard-approval-weekly/',
GetDataWeeklyApproval           : '/v1/api/bbi-sales-force/get-approval-weekly/',

    // CUSTOMER DISTRIBUTION
GetDashboardCustomerDistribution: '/v1/api/bbi-sales-force/customer-distribution/',
GetCustomerDistributionDetail   : '/v1/api/bbi-sales-force/customer-distribution-detail/',
GetCustomerDistributionList     : '/v1/api/bbi-sales-force/customer-distribution-list/',
GetSalesGroup                   : '/v1/api/bbi-sales-force/sales-group/',

    // REPORT
GetReviewActualActivity         : '/v1/api/bbi-sales-force/review-actual-activity/',
GetReviewCustomer               : '/v1/api/bbi-sales-force/review-customer/',
GetListSalesDetail              : '/v1/api/bbi-sales-force/list-sales-detail/',

GetListUserBBI                  : '/v1/api/bbi-sales-force/list-user-bbi/',

// ACTUAL ACTIVITY
DataActualActivity              : 'v1/api/bbi-sales-force/actual-activity/',


// Master And Parameter
GetSectorAndIndustry            : 'v1/api/bbi-sales-force/get-sector-and-industry/',
GetParameter                    : 'v1/api/bbi-sales-force/get-parameter/',

// DOWNLOAD APP
GetVersionApp                   : '/v1/api/bbi-sales-force/get-version-app/',
}

// *SFC
    // TERM OF PAYMENT
export function fnApiGetTermOfPayment(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetTermOfPayment, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
    // MASTER CUSTOMER
// Dahsboard Master Customer
export function fnApiGetDahsboardMasterCustomer(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.DashboardMasterCustomer, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Master Customer
export function fnApiGetDataMasterCustomer(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataMasterCustomer, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Master Customer Location
export function fnApiGetDataMasterCustomerLocation(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataMasterCustomerLocation, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Master Customer Contact
export function fnApiGetDataMasterCustomerContact(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataMasterCustomerContact, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data List Customer
export function fnApiGetListCustomer(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetListCustomer, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data List Customer Weekly
export function fnApiGetListCustomerWeekly(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetListCustomerWeekly, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

    // MONTHLY PLANNING
// Dashboard Monthly Planning
export function fnApiGetDahsboardMonthly(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.DashboardMonthlyPlanning, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Get Data Monthly Planning
export function fnApiGetDataMonthlyPlanning(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataMonthlyPlanning, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Get Data Monthly Planning Detail
export function fnApiGetDataMonthlyPlanningDetail(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataMonthlyPlanningDetail, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

    // MONTHLY APPROVAL
// Dashboard Monthly Approval
export function fnApiGetDashboardMonthlyApproval(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDashboardMonthlyApproval, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Data Monthly Approval
export function fnApiGetDataMonthlyApproval(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataMonthlyApproval, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

    // WEEKLY PLANNING
// Dashboard Weekly Planning
export function fnApiGetDashboardWeeklyPlanning(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDashboardWeeklyPlanning, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Weekly Planning
export function fnApiGetDataWeeklyPlanning(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataWeeklyPlanning, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Data Weekly Planning Detail
export function fnApiGetDataWeeklyDetailPlanning(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataWeeklyPlanningDetail, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Last Monthly Planning
export function fnApiGetLastMonthlyPlanning(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataLastMonthlyPlanning, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

    // ACTIVITY REASON
// Get Activity And Reason
export function fnApiGetActivity(filters){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.GetActivityAndReason, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiGetReason(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetActivityAndReason, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

    // WEEKLY APPROVAL
// Dashboard Weekly Approval
export function fnApiGetDashboardWeeklyApproval(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDashboardWeeklyApproval, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Weekly Approval
export function fnApiGetDataWeeklyApproval(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataWeeklyApproval, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

    // CUSTOMER DISTRIBUTION
// Dashboard Customer Distribution
export function fnApiGetDashboardCustomerDistribution(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDashboardCustomerDistribution, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Customer Distribution
export function fnApiGetDataCustomerDistribution(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDashboardCustomerDistribution, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Customer Distribution Detail
export function fnApiGetDataCustomerDistributionDetail(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetCustomerDistributionDetail, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
// Data Customer Distribution List
export function fnApiGetDataCustomerDistributionList(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetCustomerDistributionList, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Data Sales Group
export function fnApiGetDataSalesGroup(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetSalesGroup, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Review Actual Activity
export function fnApiGetReviewActualActivity(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetReviewActualActivity, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Review Customer
export function fnApiGetReviewCustomer(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetReviewCustomer, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// List Sales Detail
export function fnApiGetListSalesDetail(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetListSalesDetail, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// List User BBI
export function fnApiGetListUserBBI(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetListUserBBI, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// -- Get Dashboard Actual Activity
export function fnApiFetchDashboardActualActivity(filter){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.DataActualActivity,filter)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Get Data Actual Activity
export function fnApiFetchDataActualActivity(filter){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.DataActualActivity,filter)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Get Master Sub Activity
export function fnApiFetchMasterSubActivity(filter){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.ListSubActivity,filter)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
// -- Get Master Sector
export function fnApiFetchGetMasterSector(filter){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.GetSectorAndIndustry,filter)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Get Master Industry
export function fnApiFetchGetMasterIndustry(filter){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.GetSectorAndIndustry,filter)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
// -- Get Master Parameter
export function fnApiFetchGetMasterParameter(filter){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.GetParameter,filter)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// Version App
export function fnApiGetVersionApp(filters){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.GetVersionApp, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}