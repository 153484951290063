<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-ppcm"> Dashboard PPM</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">

                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Close IO Form</h3>
                                </div>
                            </div>
                        </template>


                                <b-row align-h="center">
                        <!-- data IO -->
<center>
                                <table class="table table-lg">
                                    <tbody>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Date Cutting</span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_date}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Due Date </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.due_date}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">IO </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.data_io}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Subcon </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_subcon}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Article </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.artikel}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Sample </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.sample}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Plan </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.qty_plan}}</span>
                                            </td>
                                        </tr>

                                            <tr id="table-info">
                                                <td v-if="typedeliv == 'Partial'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Partial</span>
                                                </td>
                                                <td v-if="typedeliv == 'Partial'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: Yes</span>
                                                </td>

                                                <td v-if="typedeliv == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Full</span>
                                                </td>
                                                <td v-if="typedeliv == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: Yes</span>
                                                </td>
                                            </tr>

                                            <tr  id="table-info">
                                                <td v-if="typedeliv == 'Partial' " style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Partial</span>
                                                </td>
                                                <td v-if="typedeliv == 'Partial' " style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: {{visitdeliv}}</span>
                                                </td>

                                                <td v-if="typedeliv == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Full</span>
                                                </td>
                                                <td v-if="typedeliv == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: {{visitdeliv}}</span>
                                                </td>
                                            </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Status DC </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{statusdeliv}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td id="table-td">

                                            </td>
                                            <td id="table-td">

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
</center>
                                </b-row>

                            <br />

                            <b-row align-h="center">
                                <b-col xs="12">
                                    <div class="table-responsive">
                                        <table class="table table-lg mb-0">
                                            <thead>
                                                <tr v-if="stPPCMLogReceiveDtCount !== 0" style="text-align:center; " class=text-table-th>
                                                    <th>
                                                        Date
                                                    </th>
                                                    <th>
                                                        Partial
                                                    </th>
                                                    <th>
                                                        Qty (Pcs)
                                                    </th>
                                                    <th>
                                                        Qty (Box)
                                                    </th>
                                                    <th>
                                                        Difference (Pcs)
                                                    </th>
                                                    <th>
                                                        Difference (Box)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="text-align:center;"
                                                    v-for="row in stPPCMLogReceiveDt"
                                                    :key="row.id"
                                                    >
                                                    <td v-if="row.qty_pcs_receive !== 0" v-html="FormatDate(row.date)">
                                                        {{row.date}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.visit}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_pcs_receive}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_box_receive}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_difference_pcs}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_difference_box}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                            <br />
                            <br />
                               <b-row  align-h="center">
                                    <b-col sm="3">
                                        <b-button block :disabled="alldisabled" @click="Completed()" ref="completed" type="button" variant="success">Close IO</b-button>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block :disabled="alldisabled" @click="Cancel()" ref="cancel" type="button" variant="danger">Cancel</b-button>
                                    </b-col>
                                </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import PostLogReceive from '@/store/ppm-form/ppm.js';
    import UpdateReceive from '@/store/ppm-form/ppm.js';
    import UpdateInputPPM from '@/store/ppm-form/ppm.js';
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);

    export default {
       name: 'PPCMInputReceive',

        data() {
            const now = new Date()

            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

            // create hour:min:sec
            const time = now.toString().slice(16,24)
            // const opthour = { hour: '2-digit'};
            // const optmin = { minute: '2-digit'};
            // const optsec = { second: '2-digit'};
            // const hour = now.toLocaleString('id-ID', opthour ,{ hour12: false })
            // const min = now.toLocaleString('id-ID', optmin ,{ hour12: false })
            // const sec = now.toLocaleString('id-ID', optsec ,{ hour12: false })
            // const time = hour+':'+min+':'+sec
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                formName: 'Close IO Form',
                errorMessage: null,
                max_date:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                today:today,
                oneDay:oneDay,
                time:time,
                qty_pcs_actual:0,
                qty_box_actual:0,
                alldisabled:false,
                    form : {
                    data_io:null,
                    employee:null,
                    user:null,
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    manufacture_date:null,
                    total_qty:null,
                    due_date:null,
                    cutting_subcon :null,
                    cutting_progress:null,
                    qty_packing:null,
                    qty_shortship:null,
                    qty_pcs:0,
                    qty_box:0,
                    qty_pcs_receive:null,
                    qty_box_receive:null,
                    tipe:null,
                    visit_delivery:null,
                    visit_receive:null,
                    status:null,
                    datenow:today,
                    remaining_pcs:0,
                    remaining_box:0,
                    },
                    statusdeliv:null,
                    typedeliv:null,
                    visitdeliv:null,
                    statusPPM:null,




            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['clearStatePpm','stPPCMDelvrData','stPPCMReceiveData','stPPCMReceiveDataCount','stPPCMLogReceiveDt','stPPCMLogReceiveDtCount','stIoPPM']),

             qty_remaining_pcs() {
                 let remaining = 0
                 remaining = this.form.qty_pcs - this.qty_pcs_actual
                //  console.log(remaining)
                return remaining;

             },
             qty_remaining_box() {
                 let remaining_box = 0
                 remaining_box = this.form.qty_box - this.qty_box_actual
                //  console.log(remaining)
                return remaining_box;

             },

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getPPCMDeliveryFormData','getPPCMReceiveFormData','GetDataPPCMReceiveyLog','getInputIOPPM']),
           FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 500);
                }
            },
           Setdata_IO() {
                // let data_IO      = this.$route.params.data_io
                let data_IO      = this.$route.query.io
                let userData     = this.userData
                this.$set(this.form, 'user', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardPPCM'})
                }
                if (this.form.data_io != null) {
                    this.getPPCMReceiveFormData(this.form.data_io);
                    this.Loading('start')
                    this.getPPCMDeliveryFormData(this.form.data_io);
                    this.getInputIOPPM(this.form.data_io).then(this.GetdataForm()).catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardPPCM"})
                        }
                    })
                    .finally(()=>{
                        this.Loading('stop')
                    })
                }
            },
            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stPPCMReceiveDataCount == 0 || this.stPPCMReceiveDataCount == null || this.stIoPPM == null) {
                    this.DelayGetData()
                    return
                }
                // Set Data v-model
                let formdata = this.stPPCMReceiveData
                this.form.artikel = formdata[0].artikel
                this.form.sample = formdata[0].sample
                this.form.employee = formdata[0].user
                this.form.brand = formdata[0].brand
                if (formdata[0].date !== null) {
                    this.form.cutting_date = this.FormatDate(formdata[0].date)
                }
                if (formdata[0].due_date !== null) {
                    this.form.due_date = this.FormatDate(formdata[0].due_date)
                }
                this.form.cutting_subcon = formdata[0].subcon
                this.form.qty_plan = formdata[0].qty_plan
                this.form.qty_pcs = formdata[0].qty_pcs;
                this.form.qty_box = formdata[0].qty_box;
                this.form.qty_pcs_receive = formdata[0].qty_pcs_receive;
                this.form.qty_box_receive = formdata[0].qty_box_receive;

                if (this.form.qty_pcs_receive == null || this.form.qty_pcs_receive == 0) {
                    this.form.qty_pcs = parseInt(this.form.qty_pcs)
                } else {
                    this.form.qty_pcs = parseInt(this.form.qty_pcs) - parseInt(this.form.qty_pcs_receive)
                }
                if (this.form.qty_box_receive == null || this.form.qty_box_receive == 0) {
                    this.form.qty_box = parseInt(this.form.qty_box)
                } else {
                    this.form.qty_box = parseInt(this.form.qty_box) - parseInt(this.form.qty_box_receive)
                }
                this.form.tipe = formdata[0].tipe;
                this.form.visit_delivery = formdata[0].visit_delivery;
                this.form.visit_receive = formdata[0].visit_receive;
                this.form.status = formdata[0].status;
                this.form.datenow = formdata[0].datenow;

                this.GetDataPPCMReceiveyLog(this.form.data_io);

                if (this.status == 'Done') {
                    this.alldisabled = true;
                }
                this.statusPPM = this.stIoPPM[0].status

                let dataDeliv = this.stPPCMDelvrData
                this.statusdeliv = dataDeliv[0].status
                this.typedeliv = dataDeliv[0].type
                this.visitdeliv = dataDeliv[0].visit

                if (this.statusPPM == 'COMPLETED') {
                    this.alldisabled = true;
                }
            },
            Cancel() {
                this.$router.push({name:"DashboardPPCM"})
            },

            Completed() {
                if (this.statusPPM !== 'ONGOING(CloseIO)') {
                    this.showNotification({type:'error', message:'Pastikan Untuk Men-Completed Process Sebelumnya'})
                    return
                }
                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you want to complete the process?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value1 => {
                    this.boxTwo = String(value1)
                    if (this.boxTwo == 'true') {

                let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  color: '#f89830',
                  loader: 'spinner',
                  width: 64,
                  height: 64,
                  backgroundColor: '#ffffff',
                  opacity: 0.5,
                  zIndex: 999,
                });
                // show report after timeout min 1 second


                setTimeout(() => {
                UpdateInputPPM.dispatch('PutInputPPM', {
                io:this.form.data_io,
                status:'COMPLETED',
                date_close_io:this.today+'_'+this.time,
                user_close_io:this.form.user,
                })
                .then(response => {
                    loader.hide()
                this.$router.push({name:"DashboardPPCM"})
                this.showNotification({type:'success', message:'Successfully Completed'})
                // console.log(response)
                })
                .catch(err => {
                    loader.hide()
                if (err.response === 400) {
                    console.log(err.response.data.message)
                    this.showNotification({type:'error', message:err.response.data.message})
                }
                })
                .finally(()=>{
                loader.hide()
                })
                },3000)

                 }
                })
                .catch(err => {
                    // An error occurred
                })



            },

            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
        },
        created() {
            this.Setdata_IO()
        },

    }
</script>
<style src="../Inspection/PPCMInputInspection.scss" lang="scss" scoped />
