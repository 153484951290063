import { getAPI } from './api-base'

const apiPath = {
    appAccess : '/core/api/app-list/',
    appAccessSmg : '/core/api/app-list-smg/',
}


export function fnApiFetchAppAccess (params) {
    if(params == null || params.businessunit == 'BBI1') {
        return new Promise((resolve, reject) => {
            getAPI.get(apiPath.appAccess)
            .then(rsp => { resolve(rsp) })
            .catch(err =>{ reject(err) })
        })
    }
    else {
        return new Promise((resolve, reject) => {
            getAPI.post(apiPath.appAccessSmg, params)
            .then(rsp => { resolve(rsp) })
            .catch(err =>{ reject(err) })
        })
    }
}
