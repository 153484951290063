import { render, staticRenderFns } from "./HealthHistoryReportV2.vue?vue&type=template&id=df38f316&scoped=true&"
import script from "./HealthHistoryReportV2.vue?vue&type=script&lang=js&"
export * from "./HealthHistoryReportV2.vue?vue&type=script&lang=js&"
import style0 from "./HealthHistoryReport.scss?vue&type=style&index=0&id=df38f316&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df38f316",
  null
  
)

export default component.exports