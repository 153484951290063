<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{stActiveReport}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">{{stActiveReport}}</span>
    <span class="fw-normal fs-larger"> {{rptVersion}} </span></h2>
    <b-row> 
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          <b-row class="form"> 
            <!-- datepicker From -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">From*</label>
                <b-form class="datepicker">
                  <VueDatePicker
                  v-model="selectedDate"
                  class="vd__div"
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="selectedDateTo"
                  placeholder="Choose report date start"
                />
                </b-form>
                </div>
            </b-col>
            <!-- end datepicker From -->

            <!-- datepicker To -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
              <label for="example-datepicker">To*</label>
                 <VueDatePicker
                 class="vd__div"
                  v-model="selectedDateTo"                   
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="max_date"
                  placeholder="Choose report date start"
                />
                </div>
            </b-col>
            <!-- end datepicker To -->

            <!-- Filter Dept Code -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
              <label>Department*</label>
                <b-form-select class="form-custom" v-model="selectedDept" ref="opt_dept" placeholder="Choose Department Code"> 
                  <!-- option choose dept value none -->
                  <option :value="null">-- Choose Department Code --</option>
                  <!-- end option choose dept value none -->
                  <option v-for="(row,index) in stDeptList" :key="index" :value="row.dept_code" :selected="selectedDept">
                       {{row.dept_code}}      
                  </option>                        
                </b-form-select>
            <div class="border-bottom-select"></div>
            </div>
            </b-col>
            <!-- end Filter Dept Code -->             
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button class='btn-spacing' type="button" variant="primary" :disabled="isLoadingData" v-on:click="populateReport()">Load Report</b-button>
                <download-excel
                    :class   = "exportButtonClass()"
                    :data   = "rptData"
                    :fields = "excel_fields"
                    :title   = "excel_title"
                    :name    = "excel_name">
                    Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToReport()">Cancel</b-button>
              </b-col>
            </b-row>
          </div>
          <!-- <div class='col-xs-3'>
            <b-button class='btn-spacing' type="button" variant="primary" v-on:click="populateReport()">Load Report</b-button>
            <router-link class='btn-spacing' tag="b-button" to="/app/reports"> Cancel</router-link>
          </div> -->
          <div>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Processed at</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{processedDateTime}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Total Data</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{rptDataCount}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Late/Early submission</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumDateNotMatch}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Resiko Besar</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumHighRisk}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Resiko Sedang</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumMedRisk}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Resiko Kecil</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumLowRisk}}</b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col xs="12">
        <div class="table-responsive">
          <table class="table table-lg mb-0">
            <thead>
              <tr class="text-muted">
                <th>TANGGAL SIMPAN</th>
                <th>TANGGAL</th>
                <th>NAMA</th>
                <th>NIK</th>
                <th>DEPARTMENT</th>
                <th>NILAI RESIKO</th>
                <th>PROFIL RESIKO</th>
                <th>NO. KTP</th>
                <th>BATUK/PILEK/NYERI TENGGOROKAN</th>
                <th>LEMAS/PEGAL/TIDAK BERTENAGA</th>
                <th>DEMAM / SUHU >37.3 C</th>
                <th>SESAK NAFAS</th>
                <th>KONTAK DENGAN PENDERITA COVID-19</th>
                <th>KE LUAR KOTA 7 HARI TERAKHIR</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="row in stRptData"
                :key="row.id" >
                <td :class="chkDateNotMatch(row.tanggal_simpan_char, row.tanggal_harian_char)">{{row.tanggal_simpan_char}}</td>
                <td>{{row.tanggal_harian_char}}</td>
                <td>{{row.nama_karyawan}}</td>
                <td>{{row.nik}}</td>
                <td>{{row.department}}</td>
                <td :class="chkRiskScore(row.nilai_resiko)">{{row.nilai_resiko}}</td>
                <td :class="chkRiskProfile(row.profil_resiko)">{{row.profil_resiko}}</td>
                <td>{{row.no_ktp}}</td>
                <td>{{row.batuk_pilek_nyeri}}</td>
                <td>{{row.lemas_pegal_tidak_bertenaga}}</td>
                <td>{{row.demam}}</td>
                <td>{{row.sesak_nafas}}</td>
                <td>{{row.riwayat_covid}}</td>
                <td>{{row.keluar_kota}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment'
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
// import vue loading
import Loading from 'vue-loading-overlay';
// Import vue loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

Vue.component('download-excel', JsonExcel)

export default {
    name: 'HealthDeclarationReport',
    data() {
      const now = new Date()
      const today = now.toISOString().slice(0,10)
      return {
        rptVersion:'v2.0',
        processedDateTime:"",
        max_date:today,
        selectedDate: today,
        selectedDateTo: today,
        selectedDept: null,
        errorMessage: null,
        isLoadingData:false,
        countDateNotMatch: 0,
        countHighRisk: 0,
        countMedRisk: 0,
        countLowRisk: 0,
        excel_title: '',
        excel_name: '',
        excel_fields: {
          'TANGGAL SIMPAN'                      : 'tanggal_simpan_char',
          'TANGGAL'                             : 'tanggal_harian',
          'NAMA'                                : 'nama_karyawan',
          'NIK'                                 : 'nik',
          'DEPARTMENT'                          : 'department',
          'NILAI RESIKO'                        : 'nilai_resiko',
          'PROFIL RESIKO'                       : 'profil_resiko',
          'NO. KTP'                             : 'no_ktp',
          'BATUK/PILEK/NYERI TENGGOROKAN'       : 'batuk_pilek_nyeri',
          'LEMAS/PEGAL/TIDAK BERTENAGA'         : 'lemas_pegal_tidak_bertenaga',
          'DEMAM / SUHU >37.3 C'                : 'demam',
          'SESAK NAFAS'                         : 'sesak_nafas',
          'KONTAK DENGAN PENDERITA COVID-19'    : 'riwayat_covid',
          'KE LUAR KOTA 7 HARI TERAKHIR'        : 'keluar_kota',
        },
      }
    },
    computed: {
      ...mapState('report', ['stDeptList', 'stRptData', 'stRptDataCount', 'stActiveReport']),
      ...mapGetters('report', ['rptDataCount', 'rptData', 'hasData']),
      ...mapGetters('session', ['userData']),
      sumDateNotMatch() {
        let percentage    = 0
        if(this.countDateNotMatch > 0) {
          percentage    = parseFloat(this.countDateNotMatch) * 100.00 / this.rptDataCount
        }
        return this.countDateNotMatch + " ( " + percentage.toFixed(2) + "%)"
      },
      sumHighRisk() {
        let percentage    = 0
        if(this.countHighRisk > 0) {
          percentage    = parseFloat(this.countHighRisk) * 100.00 / this.rptDataCount
        }
        return this.countHighRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumMedRisk() {
        let percentage    = 0
        if(this.countMedRisk > 0) {
          percentage    = parseFloat(this.countMedRisk) * 100.00 / this.rptDataCount
        }
        return this.countMedRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumLowRisk() {
        let percentage    = 0
        if(this.countLowRisk > 0) {
          percentage    = parseFloat(this.countLowRisk) * 100.00 / this.rptDataCount
        }
        return this.countLowRisk + " ( " + percentage.toFixed(2) + "%)"
      },
    },
    methods: {
      ...mapActions('report', ['initializeReport', 'getListDepartment', 'getDataHealthDeclarationV2', 'getCurrentDatetime', 'generateTitleXLS']),
      ...mapActions('session', ['showNotification']),
      populateReport() {
        this.processedDateTime = ""
        this.errorMessage = null
        if (this.selectedDate === null || this.selectedDept === null)
        {
          this.errorMessage = "Please select Date and Department filter"
          this.$refs.opt_dept.$el.classList.value = this.$refs.opt_dept.$el.classList.value + ' animation-shake'
          setTimeout(() => {
          this.$refs.opt_dept.$el.classList.remove('animation-shake');
          },1500)
        }
        else {
          let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  color: '#f89830',
                  loader: 'dots',
                  width: 64,
                  height: 64,
                  backgroundColor: '#ffffff',
                  opacity: 0.5,
                  zIndex: 999,
                  
                });
                // simulate AJAX timeout min 1 second
              setTimeout(() => {                  
          this.isLoadingData = true
          this.getDataHealthDeclarationV2({
            date: this.selectedDate,
            dateto: this.selectedDateTo,
            dept: this.selectedDept,
            businessunit: this.userData.business_unit
          })
          .then(rsp => {
            loader.hide() 
            this.postProcessReport(rsp) 
          })
          .catch(err => {
            loader.hide() 
            if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Failed loading report'})
              console.log(err)
            }
          })
          .finally(()=>{
            loader.hide() 
            this.isLoadingData = false
          })
          },1000)
        }
      },
      goToReport(){
        this.$router.push({name:"ReportPage"})
      },
      postProcessReport(apiArg) {
        // console.log(apiArg)
        let cntDateNotMatch     = document.getElementsByClassName('bg-datenotmatch').length
        let cntHighRisk         = document.getElementsByClassName('bg-riskprofile-high').length
        let cntMediumRisk       = document.getElementsByClassName('bg-riskprofile-med').length
        let cntLowRisk          = document.getElementsByClassName('bg-riskprofile-low').length
        this.countDateNotMatch  = cntDateNotMatch
        this.countHighRisk      = cntHighRisk
        this.countMedRisk       = cntMediumRisk
        this.countLowRisk       = cntLowRisk

        this.getCurrentDatetime().then(rsp => {
          this.processedDateTime = rsp;
          this.showNotification({type:'success', message:'Report Loaded'})
          
          this.initExcelTitle()
        })
        .catch(() => {
          this.showNotification({type:'success', message:'Failed loading report'})
        })
      },
      convertToReadableDateTime(param) {
          return moment(String(param)).format('DD-MM-YYYY hh:mm:ss')
      },
      chkRiskProfile(param){
        if(param.toLowerCase().trim() == 'resiko besar') {
          return 'bg-riskprofile-high'
        }
        else if(param.toLowerCase().trim() == 'resiko sedang') {
          return 'bg-riskprofile-med'
        }
        return 'bg-riskprofile-low'
      },
      chkDateNotMatch(param1, param2){
        let varSubmitDate   = param1.toLowerCase().trim().slice(0,16)
        let varFormDate     = param2.toLowerCase().trim().slice(0,16)
        if(varSubmitDate !== varFormDate) {
          return 'bg-datenotmatch' 
        }
        
        return ''
      },
      chkRiskScore(param){
        if(param >= 4) {
          return 'bg-riskscore-high'
        }
        else if (param > 1 && param < 4) {
          return 'bg-riskscore-med'
        }

        return ''
      },
      exportButtonClass() {
        let classNames = 'btn btn-info'
        if(!this.hasData) {
          classNames += ' hide'
        }
        if(this.isLoadingData){
          classNames += ' btn-disabled'
        }
        return classNames
      },
      initExcelTitle() {
        this.generateTitleXLS({
            rptName           : this.stActiveReport, 
            rptVersion        : this.rptVersion, 
            processedDateTime : this.processedDateTime, 
            processedBy       : (this.userData.username + ' - ' + this.userData.fullname)
          }).then(rsp =>{
            this.excel_name   = rsp[0]
            this.excel_title  = rsp[1]
          })
      },
    },
    mounted() {
      this.initializeReport()
      this.getListDepartment(this.userData.business_unit)
    }
};
</script>


<style src="./HealthDeclarationReport.scss" lang="scss" scoped />
