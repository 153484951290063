<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item class="fw-semi-bold">{{formName}}</b-breadcrumb-item>

        </b-breadcrumb>

        <b-container fluid>

            <b-card>
                <b-row align-h="start">
                    <b-col lg="4">
                        <b-form-group
                        label="Search"
                        label-for="Search-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="Search-input"
                            v-model="search"
                            type="search"
                            placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!search" @click="search = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                 <template v-slot:header >
                    <b-row align-v="center">
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Approval Monthly Activity</h4>
                            </div>
                        </b-col>
                    </b-row>
                </template>
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                <b-table
                    :no-border-collapse="true"
                    striped
                    class="header-background "
                    hover
                    outlined
                    show-empty
                    responsive
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    @filtered="onFiltered"
                    :filter="search"
                    :fields="fields"
                    :items="dataTable"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>

                    <!-- Period Date -->
                    <template #cell(period_date)="row">
                        <span v-if="row.value !== null" v-html="FormatDate(row.value)"></span>
                    </template>

                    <!-- Status Approval -->
                    <template #cell(approval_status)="row">
                        <span v-if="row.value !== null" v-html="FormatStatus(row.value)"></span>
                    </template>


                    <!-- <template v-slot:cell(actions)="{item}"> -->
                    <template #cell(action)="row">
                        <b-button title="View" size="xs" variant="outline-info" v-on:click="viewData(row.item.id)" style='margin-right:5px;'>
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>


                        </b-button>
                        <b-button title="Edit" size="xs" v-if=" ( roles_list.includes('dept-head') || roles_list.includes('superuser') || roles_list.includes('div-head')) && row.item.approval_status =='waiting_approval' " variant="outline-warning" v-on:click="editData(row.item.id)">
                            <b-icon icon="pencil-fill" aria-label="Edit"></b-icon>


                        </b-button>
                    </template>
                </b-table>
                <b-row>
                <b-col sm="4">
                Showing {{showPerPage}} of {{totalData}} entries
                </b-col>
                <b-col sm="4">

                </b-col>
                <b-col sm="4" align-self="end" class="my-1">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="right"
                        size="sm"
                    >
                    <template #first-text><span class="">First</span></template>
                    <template #prev-text><span class="">Prev</span></template>
                    <template #next-text><span class="">Next</span></template>
                    <template #last-text><span class="">Last</span></template>
                    <template #page="{ page, active }">
                        <b v-if="active">{{ page }}</b>
                        <!-- <b v-if="active">{{Pagination(page)}} {{ page }}</b> -->
                        <i v-else>{{ page }}</i>
                    </template>

                    </b-pagination>
                </b-col>
                </b-row>
                <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import Loader from "@/components/Loader/Loader";
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    components: {
        Loader,
    },
    data(){
        return{
            isLoadingData:false,
            formName:'Approval Monthly Activity',
            sortDesc: false,
            monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            sortBy: 'id',
            showPerPage:0,
            totalData:0,
            search:null,
            PageNow:1,
            roles_list:[],
            dataTable:[],
            fields:[
                {key:'action', label:'Action', sortable:false},
                {key:'id', label:'ID', class: 'display-hidden', sortable:false},
                {key:'transaction_id', label:'Plan Id',  sortable:false},
                {key:'period_date', label:'Period', sortable:true},
                {key:'created_by', label:'NIK', sortable:true},
                {key:'sales_name', label:'Sales Person', sortable:true},
                {key:'approval_status', label:'Status', sortable:true},
                {key:'remark', label:'Reason', sortable:false},

            ],
            paging:{
                perPage:10,
                currentPage:1,
            },
            isLoading:false,
            form_filter:null,
            form:{
                role:null,
                start_limit:null,
                end_limit:null,
                user_nik:null,
                username:null,
            },
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['stMnthApp','stMnthAppCount']),
        rows(){
            if(this.totalData != null){
                return this.totalData
            }
        }
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['getDashboardMonthlyApproval']),
        setData(){
            let roles = this.userData.roles
            if (roles.length > 0) {
                for (let data in roles) {
                if (roles[data].app == 'SFC') {
                    let lower = roles[data].role_list.map(string => {
                    return string.toLowerCase();
                    });
                    this.roles_list = lower
                }
                }
            }
            this.form.user_nik   = this.userData.nik
            this.form.username   = this.userData.fullname
            this.LoadData()
        },
        LoadData() {
            // start loading
            this.isLoadingData = true
            this.getDashboardMonthlyApproval({
                sales:this.form.user_nik,
                role:this.roles_list,
                filter:this.form_filter,
                start_limit:this.form.start_limit,
                end_limit:this.form.end_limit,
            })
            .then(rsp => {
                this.dataTable = this.stMnthApp
                this.totalData = this.stMnthAppCount
                if(this.stMnthAppCount == 0) {
                    this.showNotification({type:'info', message:'Data Not Found'})
                }
                if (this.totalData < this.paging.perPage) {
                    this.showPerPage = this.totalData
                }
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            }).finally(()=>{
                // stop Loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalData = filteredItems.length
            this.paging.currentPage = 1
            this.showPerPage = filteredItems.length
            if (filteredItems.length >= this.paging.perPage) {
                this.showPerPage = this.paging.perPage
            }
        },
        Pagination(value) {
            if (value !== this.PageNow) {
                if (value > this.PageNow) {
                    this.PageNow = value
                    // this.nextPage()
                    this.showPerPage = this.paging.perPage * this.PageNow
                if (this.showPerPage >= this.totalData) {
                    this.showPerPage = this.totalData
                }
                } else {
                    this.PageNow = value
                    this.showPerPage = this.showPerPage - (this.showPerPage - (this.paging.perPage * this.PageNow))
                if (this.showPerPage <= this.paging.perPage) {
                    this.showPerPage = this.paging.perPage
                }
                }
            }
        },
        FormatStatus(value) {
            if (value.length > 0) {
                value = value.toLowerCase()
                if (value.includes('waiting')) {
                    value = "Waiting Approval"
                }
                if (value.length > 1) {
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                }
            }
            return value
        },
        FormatDate(value) {
            if(value == null || value == undefined || value == '') {
                return ' '
            } else {
            let date = new Date(value)
            let tgl = date.toISOString().slice(8,10)
            let bulan = this.monthNames[date.getMonth()]
            let tahun = date.toISOString().slice(0,4)
            date = bulan+' '+tahun
            return date
            }
        },
        viewData(data) {
            this.$router.push({ name: 'SFCApprovalMonthlyForm', params: { page:'view', idData:data } })
        },
        editData(data) {
            this.$router.push({ name: 'SFCApprovalMonthlyForm', params: { page:'edit', idData:data } })
        },
    },
    created(){
        this.setData()
    },
}
</script>
<style src="../SalesForce.scss" lang="scss" scoped />