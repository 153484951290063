<template>
  <div class="tables-basic">
    <div>
      <router-link to="wbs">
        <div class="floating-container">
          <div class="floating-button">
            <br />
            <b>#Berani Lapor</b>
          </div>
        </div></router-link
      >
    </div>
    <b-breadcrumb>
      <b-breadcrumb-item
        ><span class="fontbbi space2 text-secondary"
          >YOU ARE HERE</span
        ></b-breadcrumb-item
      >
      <b-breadcrumb-item active
        ><span class="fontbbi space2">Applications</span></b-breadcrumb-item
      >
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold fontbbi space3">Applications</span>
    </h2>
    <b-row v-for="(data, group) in listApp" :key="group">
      <b-col>
        <Widget v-bind:title="populateGroupTitle(group)" customHeader>
          <div class="table-resposive overflow">
            <table class="table">
              <tbody>
                <tr align="left">
                  <td
                    align="center"
                    class="div-menu"
                    v-for="(row, index) in data"
                    :key="index"
                  >
                    <a
                      class="fontbbi space1 text-inverse"
                      v-if="row.enabled"
                      :href="replaceAppUrl(row.app_url, row.target)"
                      :target="chkUrlTarget(row.target)"
                    >
                      <div class="div-fill-all">
                        <img
                          class="img-rounded"
                          height="50"
                          :src="row.img_url"
                        />
                        <br />
                        <!-- Hanya muncul salah satu. Jika bbi1 -> app_name. Jika non bbi1 -> name -->
                        {{ row.app_name }}
                        {{ row.name }}
                      </div>
                    </a>
                    <span v-else>
                      <img
                        class="img-rounded img-grayed"
                        height="50"
                        :src="row.img_url"
                      />
                      <br />
                      {{ row.app_name }}
                      {{ row.name }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import { fnPOST,fnPOSTCore } from "../../api/api-methods";
import { APIPATH_BBIAPPS } from "../../api/api-paths";
// import appsStore from '@/store/apiApps.js';

export default {
  name: "Applications",
  components: { Widget },
  data() {
    return {
      listApp: null,
    };
  },
  computed: {
    ...mapState("application", ["stAppAccess"]),
    ...mapGetters("session", ["userData"]),
  },
  methods: {
    ...mapActions("application", ["clearState", "getApplicationAccess"]),
    populateGroupTitle(title) {
      this.curGroup = title;
      return "<h5><span class='fw-semi-bold'>" + title + "</span></h5>";
    },
    replaceAppUrl(formUrl, targetUrl) {
      let userData = JSON.parse(localStorage.userData);
      let nik = userData.nik;
      let name = userData.fullname;
      let dept = userData.dept;
      let deptName = userData.deptname;
      let role = userData.role;
      let loginId = userData.username;
      let bunit = userData.business_unit;
      let url = formUrl
        .replace("_NIK_", nik)
        .replace("_FULLNAME_", name)
        .replace("_DEPARTMENT_", dept)
        .replace("_DEPARTMENTNAME_", deptName)
        .replace("_ROLE_", role)
        .replace("_LOGINID_", loginId)
        .replace("_BUSINESSUNIT_", bunit);

      let chkUrlString = formUrl.substring(0, 3)
      let curlUrl = new URL(window.location.href)
      if (targetUrl == "NEW_TAB" || targetUrl == 'NEW_WINDOW') {
        return url
      } else {
        return curlUrl.origin + url;
      }
    },
    chkUrlTarget(formUrl) {
      // console.log(formUrl)
      if (formUrl == "NEW_TAB" || formUrl == 'NEW_WINDOW') {
        return "_blank";
      } else {
        return "_self";
      }
    },
    processAppPayload(payload) {
      let items = payload;
      let lookup = {};
      let result = [];
      for (let i = 0; i < items.length; i++) {
        let tempAppList = items[i];
        let name = tempAppList.group;
        if (!(name in lookup)) {
          lookup[name] = 1;
          result.push(name);
        }
      }
      let newData = this.groupBy(
        payload,
        "group"
      );
      this.$set(this, "listApp", newData);
    },
    groupBy(data, key) {
      const result = {};
      let jsonArr = data;
      let array = [];

      for (let i = 0; i < jsonArr.length; i++) {
        let tempApp = jsonArr[i];

        array.push(tempApp);
      }
      array.forEach((item) => {
        if (item["enabled"] == true) {
          if (!result[item[key]]) {
            result[item[key]] = [];
          }
          result[item[key]].push(item);
        }
      });
      return result;
    },
  },
  created() {
    let usrBU = this.userData.business_unit;
    let dept = this.userData.deptname;
    let subdept = this.userData.subdeptname;
    let role = this.userData.role;
    let roles_app = this.userData.roles;
    this.clearState();

    // if(usrBU == 'BBI1' || usrBU == 'BBI') {
      fnPOSTCore(APIPATH_BBIAPPS.appRoleList, {
        roles_app: roles_app,
      }).then((rsp) => {
        this.processAppPayload(rsp.data.payload.app_list)
      });
    // }
    // else {
    //  fnPOSTCore(APIPATH_BBIAPPS.appAccessSmg, {
    //     businessunit: usrBU,
    //     role: role,
    //     dept: dept,
    //     subdept: subdept,
    //     roles_app: roles_app,
    //  }).then(rsp => {
    //     this.processAppPayload(rsp.data.payload.app_list)
    //   })

      // this.getApplicationAccess({
      //   businessunit: usrBU,
      //   role: role,
      //   dept: dept,
      //   subdept: subdept,
      // })
      // this.$set(this, "listApp", this.stAppAccess);
    // }
  },
};
</script>

<style src="./Applications.scss" lang="scss" scoped />
