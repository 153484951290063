<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item class="fw-semi-bold">{{formName}}</b-breadcrumb-item>

        </b-breadcrumb>

        <b-container fluid>

            <b-card>
                 <template v-slot:header >
                    <b-row align-v="center">
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>List Review Actual Activity</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <!-- <b-button title="Add" v-on:click="AddNewCustomer()" size="sm" variant="outline-success" style='margin-right:5px;' >
                                    <b-icon icon="journal-plus" aria-label="Add"></b-icon>
                                    Add New Customer
                                </b-button> -->
                                <!-- <b-button title="Add" size="sm" variant="success" v-on:click="AddNewCustomer()">
                                    <span>Add New Customer</span>
                                </b-button> -->
                            </div>
                        </b-col>

                    </b-row>
                </template>
                <b-row align-h="start">
                    <b-col lg="4">
                        <b-form-group
                        label="Search"
                        label-for="Search-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="Search-input"
                            v-model="search"
                            type="search"
                            placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!search" @click="search = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                <b-table
                    :no-border-collapse="true"
                    striped
                    class="header-background"
                    hover
                    outlined
                    show-empty
                    responsive
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    @filtered="onFiltered"
                    :filter="search"
                    :fields="fields"
                    :items="dataTable"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>

                    <template #cell(action)="row">
                        <!-- <b-button title="View" size="xs" variant="outline-info" v-on:click="viewData(row.item.id)" style='margin-right:5px;'>
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>

                        </b-button> -->
                        <b-button title="Edit" size="xs" variant="outline-warning" v-on:click="editData(row.item.sales_nik, row.item.sales_name)">
                            <b-icon icon="pencil-fill" aria-label="Edit"></b-icon>

                        </b-button>
                    </template>
                </b-table>
                <b-row>
                <b-col sm="4">
                Showing {{showPerPage}} of {{totalData}} entries
                </b-col>
                <b-col sm="4">

                </b-col>
                <b-col sm="4" align-self="end" class="my-1">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="right"
                        size="sm"
                    >
                    <template #first-text><span class="">First</span></template>
                    <template #prev-text><span class="">Prev</span></template>
                    <template #next-text><span class="">Next</span></template>
                    <template #last-text><span class="">Last</span></template>
                    <template #page="{ page, active }">
                        <b v-if="active">{{Pagination(page)}} {{ page }}</b>
                        <i v-else>{{ page }}</i>
                    </template>

                    </b-pagination>
                </b-col>
                </b-row>
                <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import Loader from "@/components/Loader/Loader";
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    components: {
        Loader,
    },
    data(){
        return{
            isLoadingData:false,
            formName:'List Review Actual Activity',
            sortBy: 'id',
            sortDesc: true,
            roles_list:[],
            dataTable:[],
            fields:[
                {key:'action', label:'Action', sortable:false},
                {key:'sales_nik', label:'Sales Nik', sortable:false},
                {key:'sales_name', label:'Sales Name', sortable:false},
            ],
            showPerPage:0,
            totalData:0,
            PageNow:1,
            paging:{
                perPage:10,
                currentPage:1,
            },
            isLoading:false,
            search:null,
            form_filter:null,
            form:{
                role:null,
                start_limit:null,
                end_limit:null,
                user_nik:null,
                username:null,
            },
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['stLrac','stLracCount']),
        rows(){
            if(this.dataTable != null){
                return this.dataTable.length
            }
        }
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['getListReviewActualActivity']),

        setData(){
            let roles = this.userData.roles
            if (roles.length > 0) {
                for (let data in roles) {
                if (roles[data].app == 'SFC') {
                    let lower = roles[data].role_list.map(string => {
                    return string.toLowerCase();
                    });
                    this.roles_list = lower
                }
                }
            }
            this.LoadData()
        },

        LoadData() {
            // start loading
            this.isLoadingData = true
            this.getListReviewActualActivity({
                sales:this.userData.nik,
                role:this.roles_list,
                filter:this.form_filter,
                start_limit:this.form.start_limit,
                end_limit:this.form.end_limit,
            })
            .then(rsp => {
                this.dataTable = this.stLrac
                this.totalData = this.stLracCount
                this.showPerPage = this.paging.perPage
                if (this.totalData < this.paging.perPage) {
                    this.showPerPage = this.totalData
                }
                if(this.stLracCount == 0) {
                    this.showNotification({type:'info', message:'Data Not Found'})
                }
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            }).finally(()=>{
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
            })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalData = filteredItems.length
            this.paging.currentPage = 1
        },
        Pagination(value) {
            if (value !== this.PageNow) {
                if (value > this.PageNow) {
                    this.PageNow = value
                    // this.nextPage()
                    this.showPerPage = this.paging.perPage * this.PageNow
                if (this.showPerPage >= this.totalData) {
                    this.showPerPage = this.totalData
                }
                } else {
                    this.PageNow = value
                    this.showPerPage = this.showPerPage - (this.showPerPage - (this.paging.perPage * this.PageNow))
                if (this.showPerPage <= this.paging.perPage) {
                    this.showPerPage = this.paging.perPage
                }
                }
            }
        },
        FormatCommaNumber (param) {
            let format = param
            if (param == null || param == undefined || param == '') {
                format = null
            }
            else {
                // format = param.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                format = param.toString()
            }
            return format
        },
        editData(data, name) {
            // let routeData
            // routeData = this.$router.resolve({ name: 'SFCMasterCustomerForm', params: { page:'edit', idData:data }})
            this.$router.push({ name: 'SFCReviewActualActivity', params: { data:name, idData:data } })
            // console.log(routeData.href)
            // window.open(routeData.href, '_blank')
            // window.open(routeData.href, '_self')
        },
    },
    created(){
        this.setData()
    },
}
</script>
<style src="../SalesForce.scss" lang="scss" scoped />