<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{stActiveReport}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">{{stActiveReport}}</span>
    <span class="fw-normal fs-larger"> {{rptVersion}} </span></h2>
    <b-row> 
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          
          <b-row class="form">
            <!-- datepicker From -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">From*</label>
                <b-form class="datepicker">
                  <VueDatePicker
                  v-model="selectedDate"
                  class="vd__div"
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="selectedDateTo"
                  placeholder="Choose report date start"
                />
                </b-form>
                </div>              
            </b-col>
            <!-- end datepicker From -->

            <!-- datepicker To -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
              <label for="example-datepicker">To*</label>
                 <VueDatePicker
                 class="vd__div"
                  v-model="selectedDateTo"                   
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="max_date"
                  placeholder="Choose report date start"
                />
                </div>
            </b-col>
            <!-- end datepicker To -->

            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
              <label>Business Unit*</label>
                <b-form-select class="form-custom" v-model="selectedBU" placeholder="Choose Business Unit" @change="fetchDept()">
                 <option v-for="(row,index) in BUList" :key="index" :value="row.code" :selected="selectedBU">
                      {{row.code}} - {{row.name}}
                  </option>
                </b-form-select>
              <div class="border-bottom-select"></div>
              </div>
            </b-col>
            <!-- Filter Dept Code -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
              <label>Department*</label>
                <b-form-select class="form-custom" v-model="selectedDept" ref="opt_dept" placeholder="Choose Department Code"> 
                  <!-- option choose dept value none -->
                  <option :value="null">-- Choose Department Code --</option>
                  <!-- end option choose dept value none -->
                  <option v-for="(row,index) in stDeptList" :key="index" :value="row.dept_code" :selected="selectedDept">
                       {{row.dept_code}}      
                  </option>                        
                </b-form-select>
            <div class="border-bottom-select"></div>
            </div>
            </b-col>
            <!-- end Filter Dept Code --> 
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button class='btn-spacing' type="button" variant="primary" v-on:click="populateReport()">Load Report</b-button>
                <download-excel
                    :class   = "exportButtonClass()"
                    :data   = "rptData"
                    :fields = "excel_fields"
                    :title   = "excel_title"
                    :name    = "excel_name">
                    Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToReport()">Cancel</b-button>
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Processed at</b-col>
                  <b-col xs="7" md="8" lg="10">: {{processedDateTime}}</b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Total Data</b-col>
                  <b-col xs="7" md="8" lg="10">: {{rptDataCount}}</b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col xs="4" md="3" lg="2">Resiko</b-col>
                  <b-col xs="8" md="9" lg="10">: 
                    <span>Tinggi {{sumHighRisk}} </span> | 
                    <span>Sedang {{sumMedRisk}} </span> | 
                    <span>Rendah {{sumLowRisk}}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            
          </div>
        </div>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col xs="12">
        <div class="table-responsive">
          <table class="table table-lg mb-0">
            <thead>
              <tr style="text-align:center;" class="text-muted">
                <th>TANGGAL SIMPAN</th>
                <th>TANGGAL</th>
                <th>NAMA</th>
                <th>NIK</th>
                <th>DEPARTMENT</th>
                <!-- <th>SUHU > 37.2</th> -->
                <th>KONDISI KESEHATAN</th>
                <th>NILAI RESIKO</th>
                <th>PROFIL RESIKO</th>                
                <th>BEPERGIAN</th>

                <th>TUJUAN</th>
                <th>TEMPAT KERJA</th>
                <th>TRANSPORTASI</th>
                <th>LAYANAN KESEHATAN</th>
                <th>NAMA LAYANAN KESEHATAN</th>
                <th>BERBELANJA</th>
                <th>LOKASI BERBELANJA</th>
                <th>SWALAYAN</th>
                <th>KEGIATAN KEAGAMAAN</th>
                <th>PESTA</th>
                <th>CLUB OLAHRAGA</th>
                <th>MAKAN DI RESTORAN</th>
                <th>PERUSAHAAN LAIN</th>
                <th>TEMPAT HIBURAN</th>
                <th>KELUAR KOTA</th>
                <th>TUJUAN KELUAR KOTA</th>
                
                <th>KELUARGA SAKIT</th>
                <th>KEKERABATAN</th>
                <th>PENYAKIT KERABAT</th>
                <th>KUNJUNGAN TAMU</th>
                <!-- <th>NAMA TAMU</th> -->
                <th>RIWAYAT KONTAK 14 HARI</th>
                <th>STATUS VERIFIKASI</th>
                <th>TGL VERIFIKASI</th>
                <th>NIK VERIFIKATOR</th>
                <th>NAMA VERIFIKATOR</th>
                <th>KET. VERIFIKASI</th>
              </tr>
            </thead>
            <tbody>
              <tr style="text-align:center;"
                v-for="row in stRptData"
                :key="row.id"
              >
                <!-- <td>{{convertToReadableDateTime(row.tanggal_simpan)}}</td> -->
                <td :class="chkDateNotMatch(row.tanggal_simpan_char, row.tanggal_harian_char)">{{row.tanggal_simpan_char}}</td>
                <td>{{row.tanggal_harian_char}}</td>
                <td>{{row.nama_karyawan}}</td>
                <td>{{row.nik}}</td>
                <td>{{row.department}}</td>
                <!-- <td>{{row.suhu}}</td> -->
                <!-- <td :class="{'bg-sick':isSick(row.kondisi_kesehatan) }">{{row.kondisi_kesehatan}}</td> -->
                <td :class="chkSickCounter(row.count_lama_sakit)">{{row.kondisi_kesehatan}}</td>
                <td :class="chkNilaiResiko(row.profil_resiko)">{{row.nilai_resiko}}</td>
                <td :class="chkProfilResiko(row.profil_resiko)">{{row.profil_resiko}}</td>
                <td>{{row.bepergian}}</td>
                <!-- <td>{{row.tempat_tujuan}}</td> -->
                <td>{{row.tempat_kerja}}</td>
                <td>{{row.transportasi}}</td>
                <td>{{row.layanan_kesehatan}}</td>
                <td>{{row.nama_layanan_kesehatan}}</td>
                <td>{{row.berbelanja}}</td>
                <td>{{row.lokasi_berbelanja}}</td>
                <td>{{row.warung_tradisional}}</td>
                <td>{{row.kegiatan_keagamaan}}</td>
                <td>{{row.pesta}}</td>
                <td>{{row.club_olahraga}}</td>
                <td>{{row.makan_direstoran}}</td>
                <td>{{row.perusahaan_lain}}</td>
                <td>{{row.tempat_hiburan}}</td>
                <td>{{row.keluar_kota}}</td>
                <td>{{row.tujuan_keluar_kota}}</td>
                <td>{{row.keluarga_sakit}}</td>
                <td>{{row.nama_keluarga_sakit}}</td>
                <td>{{row.kondisi_keluarga_sakit}}</td>
                <td>{{row.tamu}}</td>
                <!-- <td>{{row.nama_tamu}}</td> -->
                <td>{{row.riwayat_covid}}</td>
                <td>{{row.status}}</td>
                <td>{{row.review_date_char}}</td>
                <td>{{row.review_by}}</td>
                <td>{{row.remark}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
import loginStore from '@/store/login.js';
// import vue loading
import Loading from 'vue-loading-overlay';
// Import vue loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

Vue.component('download-excel', JsonExcel)

export default {
    name: 'DailyHealthReportAllBUV2',
    data() {
      const now = new Date()
      const today = now.toISOString().slice(0,10)
      return {
        rptVersion:'v1.0.2',
        processedDateTime:"",
        max_date:today,
        BUList:'',
        selectedDate: null,
        selectedDateTo: null,
        selectedDept: null,
        selectedBU: '',
        errorMessage: null,
        countDateNotMatch: 0,
        countHighRisk: 0,
        countMedRisk: 0,
        countLowRisk: 0,
        excel_title: '',
        excel_name: '',
        excel_fields: {
          'TANGGAL SIMPAN'    : 'tanggal_simpan_char',
          'TANGGAL'           : 'tanggal_harian',
          'NAMA'              : 'nama_karyawan',
          'NIK'               : 'nik',
          'DEPARTMENT'        : 'department',
          // 'SUHU > 37.2'       : 'suhu',
          'KONDISI KESEHATAN' : 'kondisi_kesehatan',
          'NILAI RESIKO'      : 'nilai_resiko',
          'PROFIL RESIKO'     : 'profil_resiko',
          'BEPERGIAN'         : 'bepergian',
          // 'TUJUAN'            : 'tempat_tujuan',
          'TEMPAT KERJA'      : 'tempat_kerja',
          'TRANSPORTASI'      : 'transportasi',
          'LAYANAN KESEHATAN' : 'layanan_kesehatan',
          'NAMA LAYANAN KESEHATAN': 'nama_layanan_kesehatan',
          'BERBELANJA'        : 'berbelanja',
          'LOKASI BERBELANJA' : 'lokasi_berbelanja',
          'SWALAYAN'          : 'warung_tradisional',
          'KEGIATAN KEAGAMAAN' : 'kegiatan_keagamaan',
          'PESTA'             : 'pesta',
          'CLUB OLAHRAGA'     : 'club_olahraga',
          'MAKAN DI RESTORAN' : 'makan_direstoran',
          'PERUSAHAAN LAIN'   : 'perusahaan_lain',
          'TEMPAT HIBURAN'    : 'tempat_hiburan',        
          'KELUAR KOTA'       : 'keluar_kota',
          'TUJUAN KELUAR KOTA': 'tujuan_keluar_kota',        
          'KELUARGA SAKIT'    : 'keluarga_sakit',
          'KEKERABATAN'       : 'nama_keluarga_sakit',
          'PENYAKIT KERABAT'  : 'kondisi_keluarga_sakit',
          'KUNJUNGAN TAMU'    : 'tamu',
          // 'NAMA TAMU'         : 'nama_tamu',
          'RIWAYAT KONTAK 14 HARI'  : 'riwayat_covid',
          'STATUS VERIFIKASI' : 'status',
          'TGL VERIFIKASI'   : 'review_date_char',
          'NIK VERIFIKATOR'   : 'review_by',
          'NAMA VERIFIKATOR'  : 'review_by_name',
          'KET. VERIFIKASI'   : 'remark',
        },
      }
    },
    computed: {
      ...mapState('report', ['stDeptList', 'stRptData', 'stRptDataCount', 'stActiveReport']),
      ...mapGetters('report', ['rptDataCount', 'rptData', 'hasData']),
      ...mapGetters('session', ['userData', 'localDate']),
      sumHighRisk() {
        let percentage    = 0
        if(this.countHighRisk > 0) {
          percentage    = parseFloat(this.countHighRisk) * 100.00 / this.rptDataCount
        }
        return this.countHighRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumMedRisk() {
        let percentage    = 0
        if(this.countMedRisk > 0) {
          percentage    = parseFloat(this.countMedRisk) * 100.00 / this.rptDataCount
        }
        return this.countMedRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumLowRisk() {
        let percentage    = 0
        if(this.countLowRisk > 0) {
          percentage    = parseFloat(this.countLowRisk) * 100.00 / this.rptDataCount
        }
        return this.countLowRisk + " ( " + percentage.toFixed(2) + "%)"
      },
      sumDateNotMatch() {
        let percentage    = 0
        if(this.countDateNotMatch > 0) {
          percentage    = parseFloat(this.countDateNotMatch) * 100.00 / this.rptDataCount
        }
        return this.countDateNotMatch + " ( " + percentage.toFixed(2) + "%)"
      },
    },
    methods: {
      ...mapActions('report', ['initializeReport', 'getListDepartment', 'getDataDailyHealthV2', 'getCurrentDatetime','generateTitleXLS']),
      ...mapActions('session', ['showNotification']),
      populateReport() {
        this.resetReportSummary()
        if (this.selectedDate === null || this.selectedDept === null) {
          this.errorMessage = "Please select Date and Department filter"
          this.$refs.opt_dept.$el.classList.value = this.$refs.opt_dept.$el.classList.value + ' animation-shake'
          setTimeout(() => {
          this.$refs.opt_dept.$el.classList.remove('animation-shake');
          },1500)
        }
        else {
          let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  color: '#f89830',
                  loader: 'dots',
                  width: 64,
                  height: 64,
                  backgroundColor: '#ffffff',
                  opacity: 0.5,
                  zIndex: 999,
                  
                });
                // show report after timeout min 1 second
              setTimeout(() => {                  
          this.getDataDailyHealthV2({
            date: this.selectedDate,
            dateto: this.selectedDateTo,
            dept: this.selectedDept,
            businessunit: this.selectedBU
          })
          .then(rsp => {
            loader.hide() 
            this.postProcessReport(rsp) 
          })
          .catch(err => {
            loader.hide() 
            if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Failed loading report'})
              console.log(err)
            }
          })
          },1000)
        }
      },
      postProcessReport(apiArg) {
        // console.log(apiArg)
        let cntDateNotMatch     = document.getElementsByClassName('bg-datenotmatch').length
        let cntHighRisk         = document.getElementsByClassName('bg-riskprofile-high').length
        let cntMediumRisk       = document.getElementsByClassName('bg-riskprofile-med').length
        let cntLowRisk          = document.getElementsByClassName('bg-riskprofile-low').length
        this.countDateNotMatch  = cntDateNotMatch
        this.countHighRisk      = cntHighRisk
        this.countMedRisk       = cntMediumRisk
        this.countLowRisk       = cntLowRisk
        
        // Deploy toast dialog
        this.getCurrentDatetime().then(rsp => {
          this.processedDateTime  = rsp;
          this.showNotification({type:'success', message:'Report Loaded'})
          
          this.initExcelTitle()
        })
        .catch(ex => {
          this.showNotification({type:'error', message:'Failed loading report'})
          console.log(ex)
        })
      },
      goToReport(){
        this.$router.push({ name:"ReportPage" })
      },
      chkSickCounter(param){
        if(param > 0  && param < 2) {
          return 'bg-sick-yellow'
        }
        else if (param >= 2 ) {
          return 'bg-sick-red'
        }
        return ''
      },
      fetchDept() {
        this.initializeReport()
        this.selectedDept = null
        this.getListDepartment(this.selectedBU) 
        this.countHighRisk = 0
        this.countMedRisk = 0
        this.countLowRisk = 0
        this.countDateNotMatch = 0       
      },
      chkProfilResiko(val){
        if(val.toLowerCase().trim() == 'resiko tinggi') {
          return 'bg-riskprofile-high bg-sick-red'
        }
        else if(val.toLowerCase().trim() == 'resiko sedang') {
          return 'bg-riskprofile-med bg-sick-yellow'
        }
        else if(val.toLowerCase().trim() == 'resiko rendah') {
          return 'bg-riskprofile-low'
        }
        return ''
      },
      chkNilaiResiko(val){
        if(val.toLowerCase().trim() == 'resiko tinggi') {
          return 'bg-sick-red'
        }
        else if(val.toLowerCase().trim() == 'resiko sedang') {
          return 'bg-sick-yellow'
        }
        return ''
      },
      chkDateNotMatch(param1, param2){
        let varSubmitDate   = param1.toLowerCase().trim().slice(0,16)
        let varFormDate     = param2.toLowerCase().trim().slice(0,16)
        if(varSubmitDate !== varFormDate) {
          return 'bg-datenotmatch' 
        }
        
        return ''
      },
      resetReportSummary() {
        this.errorMessage = null
        this.processedDateTime = ""
      },
      exportButtonClass() {
        let classNames = 'btn btn-info'
        if(!this.hasData) {
          classNames += ' hide'
        }
        return classNames
      },
      initExcelTitle() {
        // let excelFullName = this.stActiveReport + ' ' + this.rptVersion
        // excelFullName += '<br />'
        // excelFullName += ' \n(Processed at: ' + this.processedDateTime + ')'

        // this.excel_title = excelFullName
        // this.excel_name = this.stActiveReport + ' ' + this.processedDateTime + '.xls'
        this.generateTitleXLS({
            rptName           : this.stActiveReport, 
            rptVersion        : this.rptVersion, 
            processedDateTime : this.processedDateTime, 
            processedBy       : (this.userData.username + ' - ' + this.userData.fullname)
          }).then(rsp =>{
            this.excel_name   = rsp[0]
            this.excel_title  = rsp[1]
          })
      }
    },
    created() {
      this.initializeReport()
      loginStore.dispatch('getListBU').then(() => {
        this.BUList = loginStore.getters.BUList
        this.selectedBU = this.userData.business_unit
        this.getListDepartment(this.selectedBU)
        this.selectedDate = this.localDate
        this.selectedDateTo = this.localDate
      })
    }
};
</script>


<style src="./DailyHealthReport.scss" lang="scss" scoped />
