<template>
 <router-link style="text-decoration: none; color: inherit;" :to="url">
  <b-card no-body class="overflow-hidden" style="border-radius: 15px; box-shadow: 0 10px 20px rgb(0 0 0 / 14%), 0 6px 6px rgb(0 0 0 / 23%);">
    <b-row no-gutters class="row-wf">
      <b-col md="6" class="px-10 icon-wrap-wf">
        <div class="text-center" id="icon-position-wf">
          <i
            :class="fullIconName"
            id="icon-wf"
          ></i>
        </div>
      </b-col>
      <b-col md="6" class="text-wrap-wf">
        <div class="text-right wrap-count-text">
          <p class="h5 card-text-wf" style="color: #9a9a9a; font-weight: 400;">{{ title }}</p>
          <p class="h1 card-text-count-wf" style="margin-top: -5px; font-weight: 500;">{{ count }}</p>
          <div
          class="d-flex justify-content-center mb-3"
          style="position: relative; top: 5px; left:30px; z-index: 10"
        >
          <b-spinner
            variant="primary"
            v-show="isLoading"
            label="Loading..."
          ></b-spinner>
        </div>
        </div>
      </b-col>
    </b-row>
    <!-- <b-col md="12" class="hr-wf">
      <hr/>
      <div style="margin-top: -5px; color: #9a9a9a; font-weight: 500; padding:2px;" v-if="last_updated">
        <p class="h6 text-last-update-wf"><i class="fa fa-refresh" style="color:#0f4d19;"></i> Last Updated: {{ last_updated }}</p>
      </div>
      <div v-else class="card-close-wf"><br/></div>
    </b-col> -->
  </b-card>
 </router-link>
</template>

<script>
export default {
    props:['icon','title','count', 'last_updated', 'url', 'iconColor', 'isLoading'],
    data(){
        return{
        }
    },
    computed:{
        fullIconName() {
            return `fi ${this.icon}`;
        },
    }
};
</script>
<style>
#icon-position-wf{
    /* text-align: left !important;
    width: 10%; */
    padding: 20px;
}
.wrap-count-text{
    padding-top: 30px; 
    padding-right: 65px;
}
#icon-wf{
    font-size: 60px; 
    /* color: #ff9500 !important */
    color: v-bind('iconColor') !important;
}
.row-main-wf {
    padding-left: 16px;
    padding-right: 53px;
    margin-top: 10px;
    width: 1250px;
}
.card-text-wf{
  font-size: 25px !important;
}  
.card-text-count-wf{
  font-size: 40px !important;
}   
@media only screen and (max-width: 600px) {
    .card-text-wf{
        font-size: 13px !important;
    }
    .card-text-count-wf{
      font-size: 20px !important;
    }   
    .card-wf {
        max-width: 33% !important;
        padding: 5px !important;
    }
    .row-wf{
        height: 50px !important;
    }
    #icon-wf{
        font-size: 30px; 
        color: v-bind('iconColor') !important;
    }
    #icon-position-wf{
        text-align: left !important;
        width: 10%;
    }
    .hr-wf{
      /* display: none !important; */
      margin-top: -27px !important;
      height: 57px !important;
    }
    .text-last-update-wf{
      font-size: 9px !important;
      margin-top: -7px !important;
    }
    .no-gutters {
      height: 77px !important;
    }
    .icon-wrap-wf{
      width: 25% !important;
    }
    .text-wrap-wf{
      width:75% !important;
    }
    .row-main-wf{
      padding: 10px;
      margin-bottom: 20px !important;
      width: 100% !important;
    }
    .card-close-wf{
      height: 20px!important;
    }
    .wrap-count-text {
      padding-top: 12px;
      padding-right: 15px;
    }
}
</style>