<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{rptName}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">{{rptName}}</span>
    <span class="fw-normal fs-larger"> {{rptVersion}} </span></h2>
    <b-row> 
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          <b-row class="form">
            <!-- datepicker From -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">From*</label>
                <b-form class="datepicker">
                  <VueDatePicker
                  v-model="selectedDate"
                  class="vd__div"
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="selectedDateTo"
                  placeholder="Choose report date start"
                />
                </b-form>
                </div>              
            </b-col>
            <!-- end datepicker From -->

            <!-- datepicker To -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
              <label for="example-datepicker">To*</label>
                 <VueDatePicker
                 class="vd__div"
                  v-model="selectedDateTo"                   
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="max_date"
                  placeholder="Choose report date start"
                />
                </div>
            </b-col>
            <!-- end datepicker To -->
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button class='btn-spacing' type="button" variant="primary" :disabled="isLoadingData" v-on:click="populateReport()">Load Report</b-button>
                <b-button type="button" :class="exportButtonClass()" v-on:click="exportToExcel()">Export to Excel</b-button>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToReport()">Cancel</b-button>
              </b-col>
            </b-row>
            <a href="#" download="your-foo.txt"></a>
          </div>
        </div>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col xs="12" class="overflow-auto">
        <div class='custom-pivot-class'>
            <vue-pivottable-ui
                :data="pivotData"
                :aggregator-name="aggregatorName"
                :renderer-name="rendererName"
                :rows="rows"
                :cols="cols"
                :vals="vals"
                :row-total="true"
                :col-total="true"
            >
            </vue-pivottable-ui>
        </div>
        <div>
          <iframe id="txtArea1" style="display:none"></iframe>
        </div>
      </b-col>
    </b-row>
    
  </div>
  
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
// import vue loading
import Loading from 'vue-loading-overlay';
// Import vue loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

Vue.component('download-excel', JsonExcel)

export default {
    name: 'DailyHealthReport',
    data() {
      const now = new Date()
      const today = now.toISOString().slice(0,10)
      return {
        rptVersion:'v1.0.0',
        rptName:'Daily Sales Report zz',   //Default Report Name. Will replaced by API's value (store: getters reports/activeReport)
        processedDateTime:"",
        selectedDate: today,
        max_date:today,
        selectedDateTo: today,
        errorMessage: null,
        isLoadingData:false,
        pivotData:[],
        objParameter:[],
        aggregatorName: 'Sum',
        rendererName: 'Table',
        hasData: false,
        excel_title: '',
        excel_name: '',
        rows: [],
        cols: [],
        vals: [],
        disabledFromDragDrop: [],
        hiddenFromDragDrop: [],
      }
    },
    computed: {
      ...mapState('report', ['stRptData', 'stRptDataCount']),
      ...mapGetters('report', ['rptDataCount', 'rptData', 'activeReport']),
      ...mapGetters('session', ['userData', 'localDate']),
    },
    methods: {
      ...mapActions('report', ['initializeReport', 'getPivotDataDailySales', 'getCurrentDatetime', 'generateTitleXLS', 'exportHTMLToXls', 'manipulatePivotUI']),
      ...mapActions('session', ['showNotification']),
      populateReport() {
        this.resetReportSummary()
        if (this.selectedDate === null) {
          this.errorMessage = "Please select Date filter"
        }
        else {
          let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  color: '#f89830',
                  loader: 'dots',
                  width: 64,
                  height: 64,
                  backgroundColor: '#ffffff',
                  opacity: 0.5,
                  zIndex: 999,
                  
                });
                // show report after timeout min 1 second
              setTimeout(() => {
          this.isLoadingData = true          
          this.getPivotDataDailySales({
            date: this.selectedDate,
            dateto: this.selectedDateTo,
          })
          .then(rsp => {
            this.pivotData = this.rptData.pivotData
            this.vals = this.rptData.vals
            this.rows = this.rptData.rows
            this.cols = this.rptData.cols
            this.aggregatorName = this.rptData.aggregatorName
            this.rendererName = this.rptData.rendererName
            this.disabledFromDragDrop = this.rptData.disabledFromDragDrop
            this.hiddenFromDragDrop = this.rptData.hiddenFromDragDrop
            if(this.pivotData.length > 0) {
              this.hasData = true
            }
            else { this.hasData = false }
            loader.hide()
            this.postProcessReport(rsp)
          })
          .catch(err => {
            loader.hide() 
            if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Failed loading report'})
              console.log(err)
            }
          })
          .finally(()=>{
            loader.hide() 
            this.isLoadingData = false
          })
          },1000)
        }
      },
      postProcessReport(apiArg) {        
        // Deploy toast dialog
        this.getCurrentDatetime().then(rsp => {
          this.processedDateTime  = rsp;
          this.showNotification({type:'success', message:'Report Loaded'})

          this.initExcelTitle()
          // this.manipulatePivotUI(document.getElementsByClassName('pvtVals')[0])
        })
        .catch(ex => {
          this.showNotification({type:'error', message:'Failed loading report'})
          console.log(ex)
        })
      },
      goToReport(){
        this.$router.push({ name:"ReportPage" })
      },
      toggle: function(todo){
        todo.done = !todo.done
      },
      initExcelTitle() {
        this.generateTitleXLS({
            rptName           : this.rptName, 
            rptVersion        : this.rptVersion, 
            processedDateTime : this.processedDateTime, 
            processedBy       : (this.userData.username + ' - ' + this.userData.fullname)
          }).then(rsp =>{
            this.excel_name   = rsp[0]
            this.excel_title  = rsp[1]
          })
      },
      exportToExcel(){
        // class 'pvtTable' found using inspect element on pivotTable result
        let tblElement    = document.getElementsByClassName('pvtTable')[0]
        let fileName      = this.excel_name
        let strHeader     = this.excel_title
        
        this.exportHTMLToXls({tblElement, fileName, strHeader})
      },
      resetReportSummary() {
        this.errorMessage = null
      },
      exportButtonClass() {
        let classNames = 'btn btn-info'
        if(!this.hasData) {
          classNames += ' hide'
        }
        if(this.isLoadingData){
          classNames += ' btn-disabled'
        }
        return classNames
      },
    },
    created() {
      this.initializeReport()
      this.rptName = this.activeReport
      this.selectedDateTo = this.localDate
    }
};
</script>


<style src="./DailySalesReport.scss" lang="scss" />