<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item class="fw-semi-bold">{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>

    <b-container fluid>
      <b-card>
        <template v-slot:header>
          <b-row align-v="center">
            <b-col sm="auto" md="9">
              <div class="text-left">
                <h4>Report Sales Projection</h4>
              </div>
            </b-col>
            <b-col sm="auto" md="3">
              <div class="text-right">
                <!-- <b-button title="Add" v-on:click="AddNewCustomer()" size="sm" variant="outline-success" style='margin-right:5px;' >
                                    <b-icon icon="journal-plus" aria-label="Add"></b-icon>
                                    Add New Customer
                                </b-button> -->
                <!-- <b-button title="Add" size="sm" variant="success" v-on:click="AddNewCustomer()">
                                    <span>Add New Customer</span>
                                </b-button> -->
              </div>
            </b-col>
          </b-row>
        </template>
        <b-row align-h="start">
          <b-col sm="4">
            <b-form-group
              label="Period"
              label-for="Period"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <VueDatePicker
                class="vd__div"
                @input="ChangePeriod()"
                v-model="period"
                no-header
                ref="period"
                type="month"
                placeholder="Choose Period"
              />
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-form-group
              label="Sales Group"
              label-for="Employee_ID"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <v-select
                v-model="salesGroup"
                :clearable="true"
                @input="ChangeSalesGroup()"
                :reduce="(data) => data.leader_nik"
                :required="true"
                class="vd__div"
                prepend-icon="edit"
                label="name"
                placeholder="Choose Sales Group"
                :options="options.sales_group"
                ref="OptSalesGroup"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <div id="wdr-component">
          <Pivot :report="dataTable" toolbar></Pivot>
        </div>
        <Loader
          v-if="isLoadingData"
          fullPage
          useOverlay
          loader="spinner"
        ></Loader>
      </b-card>
    </b-container>
  </div>
</template>
<script src="https://cdn.webdatarocks.com/latest/webdatarocks.toolbar.min.js"></script>
<script src="https://cdn.webdatarocks.com/latest/webdatarocks.js"></script>

<script>
import Loader from "@/components/Loader/Loader";
import { mapActions, mapGetters, mapState } from "vuex";
import { fnPOST } from "../../../../api/api-methods";
import { APIPATH_SALESFORCE } from "../../../../api/api-paths";
import Pivot from "../../../../components/Pivot/Pivot.vue";
import "webdatarocks/webdatarocks.css";
export default {
  name: "app",
  components: {
    Loader,
    Pivot,
  },
  data() {
    const now = new Date();
    const optyear = { year: "numeric" };
    const today = now.toISOString().slice(0, 10);
    const year = now.toLocaleString("id-ID", optyear);
    return {
      isLoadingData: false,
      formName: "Report Sales Projection",
      roles_list: [],
      today:today,
      dataTable: {
        formats: [
        {
            name: "",
            decimalPlaces: 2,
        }
    ],
        dataSource: {
          dataSourceType: "json",
          data: [],
        },
        slice: {
          rows: [
            {
              uniqueName: "month",
            },
          ],
          columns: [
            {
              uniqueName: "Measures",
            },
          ],

          measures: [
            {
              uniqueName: "qty",
              aggregation: "sum",
            },
          ],
          flatOrder: [
            "month",
            "item",
            "pic_sales",
            "status_customer",
            "qty",
            "est_price",
            "price_total",
            "cogs",
            "cogs_total",
            "gp_value",
            "gp_percent",
            "status"
          ],
        },
        options: {
          grid: {
            type: "flat",
          },
        },
      },
      period: null,
      period_date: null,
      data: [],
      division: null,
      salesGroup: null,
      options: {
        sales_group: [],
      },
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
  },
  methods: {
    ...mapActions("session", ["showNotification"]),

    setData() {
      let roles = this.userData.roles;
      if (roles.length > 0) {
        for (let data in roles) {
          if (roles[data].app == "SFC") {
            let lower = roles[data].role_list.map((string) => {
              return string.toLowerCase();
            });
            this.roles_list = lower;
          }
        }
      }
      this.period = this.today.substring(0,7)
      this.LoadDataReport();
    },
    LoadDataReport() {
      this.data = []
      if (this.period) {
        this.period_date = this.period + "-01";
      }
      // start loading
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.getReportSalesProjection, {
        period: this.period_date,
        division: this.division,
        sales_group: this.salesGroup,
      })
        .then((rsp) => {
          // console.log(rsp);
          if (rsp.data.payload.length > 0) {
            this.data = rsp.data.payload;
            webdatarocks.updateData({
              data: {},
            });
            webdatarocks.updateData({
              data: this.getDataJson(),
            });
            webdatarocks.setOptions({
              grid: {
                showHeaders: false,
              },
            });
            webdatarocks.setFormat({
              name: "",
              thousandsSeparator:',',
            });
            webdatarocks.refresh();
          } else {
            webdatarocks.updateData({
              data: {},
            });
            this.showNotification({ type: "info", message: "Data Not Found" });
          }
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({ type: "error", message: err });
          }
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    getDataJson() {
      var months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let result = [];
      let data = this.data;
      if (this.data.length > 0) {
        for (let a = 0; a < data.length; a++) {
          let year = data[a].est_shipment_month.substring(0, 4);
          let monthnum = data[a].est_shipment_month.substring(5, 7);
          let month = months[parseFloat(monthnum - 1)];
          let pivot = {
            no: "  " + (a + 1) + " . ",
            customer:data[a].customer_name,
            // month: month + " " + year,
            item: data[a].category,
            "pic sales": data[a].sales_name,
            "status customer": data[a].status,
            "qty": data[a].total_qty,
            "harga dpp": parseFloat(data[a].total_est_item_price),
            "jumlah dpp": data[a].total_est_item_price * data[a].total_qty,
            cogm: data[a].total_est_cogs,
            "jumlah cogm": data[a].total_est_cogs * data[a].total_qty,
            "gp": data[a].gp_value,
            "%": data[a].gp_percent,
            status: data[a].status_customer,
          };

          result.push(pivot);
        }
      } else {
        let pivot = {
            no: "-",
            customer:"-",
            // month: month + " " + year,
            item: "-",
            "pic sales": "-",
            "status customer":"-",
            "qty": "-",
            "harga dpp": "-",
            "jumlah dpp": "-",
            cogm: "-",
            "jumlah cogm": "-",
            "gp": "-",
            "%": "-",
            status: "-",
          };

          result.push(pivot);
      }
      return result;
    },
    LoadSalesGroup() {
      // start loading
      this.options.sales_group = []
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.getSalesGroup, {})
        .then((rsp) => {
          if (rsp.data.payload) {
            this.options.sales_group = rsp.data.payload
          }
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({ type: "error", message: err });
          }
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    ChangePeriod() {
      if (
        this.period == null ||
        this.period == undefined
      ) {
        // this.LoadData();
      } else {
        this.LoadDataReport();
      }
    },
    ChangeSalesGroup() {
      if (
        this.period == null ||
        this.period == undefined
      ) {
        // this.LoadData();
      } else {
        this.LoadDataReport();
      }
    },
  },
  created() {
    this.setData();
    this.LoadSalesGroup();
  },
};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
