<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{stActiveReport}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">{{stActiveReport}}</span>
    <span class="fw-normal fs-larger"> {{rptVersion}} </span></h2>
    <b-row> 
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          <b-row class="form">
          <!-- Filter Dept Code -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
              <label>Department*</label>
                  <v-select label="dept_code" :reduce="data => data.dept_code" :options="this.optionsDept" v-model="selectedDept" class="form-custom custom-select" ref='opt_dept'></v-select>
              </div>
            </b-col>
            <!-- end Filter Dept Code --> 
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button class='btn-spacing' type="button" variant="primary" :disabled="isLoadingData" v-on:click="populateReport()">Load Report</b-button>
                <download-excel
                    :class   = "exportButtonClass()"
                    :data   = "rptData"
                    :fields = "excel_fields"
                    :title   = "excel_title"
                    :name    = "excel_name">
                    Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToReport()">Cancel</b-button>
              </b-col>
            </b-row>
          </div>
          <!-- <div class='col-xs-3'>
            <b-button class='btn-spacing' type="button" variant="primary" v-on:click="populateReport()">Load Report</b-button>
            <router-link class='btn-spacing' tag="b-button" to="/app/reports"> Cancel</router-link>
          </div> -->
          <div>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Processed at</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{processedDateTime}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Total Data</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{rptDataCount}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Total Has Sick History</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumSickHistory}}</b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col xs="12">
        <div class="table-responsive">
          <table class="table table-lg mb-0">
            <thead>
              <tr class="text-muted">
                <th>TANGGAL SIMPAN</th>
                <th>NAMA</th>
                <th>NIK</th>
                <th>DEPARTMENT</th>
                <th>TEMPAT LAHIR</th>
                <th>TANGGAL LAHIR</th>
                <th>ALAMAT</th>
                <th>NOMOR HP PRIBADI</th>
                <th>RIWAYAT SAKIT KERAS</th>
                <th>PROVINSI</th>
                <th>KOTA</th>
                <th>KECAMATAN</th>
                <th>KELURAHAN</th>
                <th>KODEPOS</th>
                <th>NAMA ANGGOTA KELUARGA</th>
                <th>NOMOR ANGGOTA KELUARGA</th>
                <th>PROFESI TENAGA KESEHATAN</th>
                <th>NAMA FASILITAS KESEHATAN</th>
                <th>PROFESI MARKETING / KURIR</th>
                <th>PROFESI LAYANAN MASYARAKAT</th>
                <th>PROFESI PENGEMUDI</th>
                <th>PROFESI PEDAGANG / PENGUSAHA</th>
                <th>LOKASI USAHA</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="row in stRptData"
                :key="row.id"
              >
                <td>{{row.tanggal_simpan_char}}</td>
                <td>{{row.nama_karyawan}}</td>
                <td>{{row.nik}}</td>
                <td>{{row.department}}</td>
                <td>{{row.tempat_lahir}}</td>
                <td>{{row.tanggal_lahir_char}}</td>
                <td>{{row.alamat}}</td>
                <td>{{row.nomor_hp}}</td>
                <td :class="chkSickProfile(row.count_jumlah_sakit)">{{row.riwayat_sakit_keras}}</td>
                <td>{{row.provinsi}}</td>
                <td>{{row.kota}}</td>
                <td>{{row.kecamatan}}</td>
                <td>{{row.kelurahan}}</td>
                <td>{{row.kodepos}}</td>
                <td>{{row.nama_anggota_keluarga}}</td>
                <td>{{row.nomor_anggota_keluarga}}</td>
                <td>{{row.profesi_faskes}}</td>
                <td>{{row.nama_faskes}}</td>
                <td>{{row.profesi_sales}}</td>
                <td>{{row.profesi_layanan_masyarakat}}</td>
                <td>{{row.profesi_pengemudi}}</td>
                <td>{{row.profesi_pedagang}}</td>
                <td>{{row.lokasi_berdagang}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <Loader v-if="isLoadingData" fullPage useOverlay></Loader>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css';

import Loader from '@/components/Loader/Loader';
import {setFocus, showToastOK} from '@/core/pluginHelper'
import {fnApiFetchListDepartment} from '@/api/api-reports.js'

Vue.component('download-excel', JsonExcel)

export default {
    name: 'HealthHistoryReportV2',
    components: {
        vSelect, Loader,
    },     
    data() {
      return {
        rptVersion:'v2.0',
        processedDateTime:"",
        selectedDate: null,
        selectedDateTo: null,
        selectedDept: null,
        errorMessage: null,
        isLoadingData:false,
        countSickHistory: 0,
        excel_title: '',
        excel_name: '',
        excel_fields: {  
          'TANGGAL SIMPAN'              : 'tanggal_simpan_char',
          'NAMA'                        : 'nama_karyawan',
          'NIK'                         : 'nik',
          'DEPARTMENT'                  : 'department',
          'TEMPAT LAHIR'                : 'tempat_lahir',
          'TANGGAL LAHIR'               : 'tanggal_lahir_char',
          'ALAMAT'                      : 'alamat',
          'NOMOR HP PRIBADI'            : 'nomor_hp',
          'RIWAYAT SAKIT KERAS'         : 'riwayat_sakit_keras',
          'PROVINSI'                    : 'provinsi',
          'KOTA'                        : 'kota',
          'KECAMATAN'                   : 'kecamatan',
          'KELURAHAN'                   : 'kelurahan',
          'KODEPOS'                     : 'kodepos',
          'NAMA ANGGOTA KELUARGA'       : 'nama_anggota_keluarga',
          'NOMOR HP ANGGOTA KELUARGA'   : 'nomor_anggota_keluarga',
          'PROFESI TENAGA KESEHATAN'    : 'profesi_faskes',
          'NAMA FASILITAS KESEHATAN'    : 'nama_faskes',
          'PROFESI MARKETING / KURIR'   : 'profesi_sales',
          'PROFESI LAYANAN MASYARAKAT'  : 'profesi_layanan_masyarakat',
          'PROFESI PENGEMUDI'           : 'profesi_pengemudi',
          'PROFESI PEDAGANG / PENGUSAHA': 'profesi_pedagang',
          'LOKASI USAHA'                : 'lokasi_berdagang',
        },
        optionsDept:[],
      }
    },
    computed: {
      ...mapState('report', ['stDeptList', 'stRptData', 'stRptDataCount', 'stActiveReport']),
      ...mapGetters('report', ['rptDataCount', 'rptData', 'hasData', 'deptList']),
      ...mapGetters('session', ['userData']),
      sumSickHistory() {
        let percentage    = 0
        if(this.countSickHistory > 0) {
          percentage    = parseFloat(this.countSickHistory) * 100.00 / this.rptDataCount
        }
        return this.countSickHistory + " ( " + percentage.toFixed(2) + "%)"
      },
      getStateDeptList() {
        return this.deptList
      },
    },
    methods: {
      ...mapActions('report', ['initializeReport', 'getListDepartment', 'getDataHealthHistoryV2', 'getCurrentDatetime','generateTitleXLS']),
      populateReport() {
        this.processedDateTime = ""
        this.errorMessage = null
        if (this.selectedDept === null)
        {
          this.errorMessage = "Please select Department filter"
          setFocus(this.$refs.opt_dept)
        }
        else {
            // show report after timeout min 1 second
          this.isLoadingData = true
          this.getDataHealthHistoryV2({
            dept: this.selectedDept,
            businessunit: this.userData.business_unit
          })
          .then(rsp => {
            this.postProcessReport(rsp) 
          })
          .catch(err => {
            if(err.response.status !== 401) {
              showToastOK({type:'error', message:'Failed loading report'})
            }
          })
          .finally(()=>{
            this.isLoadingData = false
          })
        }
      },
      goToReport(){
        this.$router.push({name:"ReportPage"})
      },
      chkSickProfile(param){
        if(param > 0) {
          return 'bg-yellow'
        }
        
        return ''
      },
      postProcessReport(apiArg) {
        // console.log(apiArg)
        let cntSick             = document.getElementsByClassName('bg-yellow').length
        this.countSickHistory   = cntSick
        
        // Deploy toast dialog
        this.getCurrentDatetime().then(rsp => {
          this.processedDateTime = rsp;
          showToastOK({type:'success', message:'Report Loaded'})          
          this.initExcelTitle()
        })
        .catch(() => {
          showToastOK({type:'success', message:'Failed loading report'})
        })
      },
      exportButtonClass() {
        let classNames = 'btn btn-info'
        if(!this.hasData) {
          classNames += ' hide'
        }
        if(this.isLoadingData){
          classNames += ' btn-disabled'
        }
        return classNames
      },
      initExcelTitle() {
        this.generateTitleXLS({
            rptName           : this.stActiveReport, 
            rptVersion        : this.rptVersion, 
            processedDateTime : this.processedDateTime,
            processedBy       : (this.userData.username + ' - ' + this.userData.fullname)
          }).then(rsp =>{
            this.excel_name   = rsp[0]
            this.excel_title  = rsp[1]
          })
      },
    },
    created() {
      this.initializeReport()
      fnApiFetchListDepartment(this.userData.business_unit).then(rsp=>{
        this.optionsDept = rsp.data.payload
      })
      // this.getListDepartment(this.userData.business_unit)
    },
    toggleButtons(isEnabled) {

    },
};
</script>


<style src="./HealthHistoryReport.scss" lang="scss" scoped />
