<template>  
    <div>
        <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item >
            <router-link tag="a" to="/app/appsplm-product-design2">Product Design</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Edit</b-breadcrumb-item>
        </b-breadcrumb>
        <b-overlay :show="busy" rounded="sm" >
            <template #overlay>
                <div class="text-center">
                    <b-icon icon="arrow-clockwise" font-scale="4" variant="primary" animation="spin"></b-icon>
                    <p id="cancel-label">Please wait...</p>
                    
                </div>
            </template>
        <b-card title="Edit Images">
            <b-form-group
                label-cols          ="3" 
                label-cols-lg       ="3" 
                label-size          ="sm"
            >
                <template v-slot:label>
                    Design
                </template>
                    <div v-for="img in form.imgDesign" :key="img.img_src">
                        
                            <p class="small">
                                    <a :href="img.img_src" target="_blank">{{img.img_name}}</a>
                                <b-badge href="#" v-on:click="changeImage(img.img_id,img.img_label,'Design')" variant="light">
                                    <b-icon  icon="pencil-fill" font-scale="1" variant="warning"></b-icon>
                                    Edit
                                </b-badge>
                                <b-badge href="#" v-on:click="deleteImage(img.img_id)" variant="light">
                                    <b-icon  icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                    Delete
                                </b-badge>
                            </p>
                    </div>
                    <template v-if="form.imgDesign.length<1">
                        <b-button size="xs" variant="success" v-on:click="addImage('design')">Add Images</b-button>
                    </template>
            </b-form-group>
            <b-form-group
                label-cols          ="3" 
                label-cols-lg       ="3" 
                label-size          ="sm"
            >
                <template v-slot:label>
                    Technical Drawing
                </template>
                    <div v-for="img in form.imgTechnicalDrawing" :key="img.img_src">
                        <p class="small">
                                <a :href="img.img_src" target="_blank">{{img.img_name}}</a>
                            <b-badge href="#" v-on:click="changeImage(img.img_id,img.img_label,'TechnicalDrawing')" variant="light">
                                <b-icon icon="pencil-fill" font-scale="1" variant="warning"></b-icon>
                                Edit
                            </b-badge>
                            <b-badge href="#" v-on:click="deleteImage(img.img_id)" variant="light">
                                    <b-icon  icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                    Delete
                                </b-badge>
                        </p>
                    </div>
                    <template v-if="form.imgTechnicalDrawing.length<2">
                        <b-button size="xs" variant="success" v-on:click="addImage('technical-drawing')">Add Images</b-button>
                    </template>
            </b-form-group>
            <b-form-group
                label-cols          ="3" 
                label-cols-lg       ="3" 
                label-size          ="sm"
            >
                <template v-slot:label>
                    Code Main Label
                </template>
                    <div v-for="img in form.imgCodeMainLabel" :key="img.img_src">
                        <p class="small">
                                <a :href="img.img_src" target="_blank">{{img.img_name}}</a>
                            <b-badge href="#" v-on:click="changeImage(img.img_id,img.img_label,'CodeMainLabel')" variant="light">
                                <b-icon icon="pencil-fill" font-scale="1" variant="warning"></b-icon>
                                Edit
                            </b-badge>
                            <b-badge href="#" v-on:click="deleteImage(img.img_id)" variant="light">
                                    <b-icon  icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                    Delete
                                </b-badge>
                        </p>
                    </div>
                     <template v-if="form.imgCodeMainLabel.length<1">
                        <b-button size="xs" variant="success" v-on:click="addImage('code-main-label')">Add Images</b-button>
                    </template>
            </b-form-group>
            <b-form-group
                label-cols          ="3" 
                label-cols-lg       ="3" 
                label-size          ="sm"
            >
                <template v-slot:label>
                    Code Streamer Label
                </template>
                    <div v-for="img in form.imgCodeStreamerLabel" :key="img.img_src">
                        <p class="small">
                                <a :href="img.img_src" target="_blank">{{img.img_name}}</a>
                            <b-badge href="#" v-on:click="changeImage(img.img_id,img.img_label,'CodeStreamerLabel')" variant="light">
                                <b-icon icon="pencil-fill" font-scale="1" variant="warning"></b-icon>
                                Edit
                            </b-badge>
                            <b-badge href="#" v-on:click="deleteImage(img.img_id)" variant="light">
                                    <b-icon  icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                    Delete
                                </b-badge>
                        </p>
                    </div>
                     <template v-if="form.imgCodeStreamerLabel.length<1">
                        <b-button size="xs" variant="success" v-on:click="addImage('code-streamer-label')">Add Images</b-button>
                    </template>
            </b-form-group>
            <b-form-group
                label-cols          ="3" 
                label-cols-lg       ="3" 
                label-size          ="sm"
            >
                <template v-slot:label>
                    Code Size Label
                </template>
                    <div v-for="img in form.imgCodeSizeLabel" :key="img.img_src">
                        <p class="small">
                                <a :href="img.img_src" target="_blank">{{img.img_name}}</a>
                            <b-badge href="#" v-on:click="changeImage(img.img_id,img.img_label,'CodeSizeLabel')" variant="light">
                                <b-icon icon="pencil-fill" font-scale="1" variant="warning"></b-icon>
                                Edit
                            </b-badge>
                            <b-badge href="#" v-on:click="deleteImage(img.img_id)" variant="light">
                                    <b-icon  icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                    Delete
                                </b-badge>
                        </p>
                    </div>
                     <template v-if="form.imgCodeSizeLabel.length<1">
                        <b-button size="xs" variant="success" v-on:click="addImage('code-size-label')">Add Images</b-button>
                    </template>
            </b-form-group>
            <b-form-group
                label-cols          ="3" 
                label-cols-lg       ="3" 
                label-size          ="sm"
            >
                <template v-slot:label>
                    Code Hang Tag
                </template>
                    <div v-for="img in form.imgCodeHangtag" :key="img.img_src">
                        <p class="small">
                                <a :href="img.img_src" target="_blank">{{img.img_name}}</a>
                            <b-badge href="#" v-on:click="changeImage(img.img_id,img.img_label,'CodeHangTag')" variant="light">
                                <b-icon icon="pencil-fill" font-scale="1" variant="warning"></b-icon>
                                Edit
                            </b-badge>
                            <b-badge href="#" v-on:click="deleteImage(img.img_id)" variant="light">
                                    <b-icon  icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                    Delete
                                </b-badge>
                        </p>
                    </div>
                     <template v-if="form.imgCodeHangtag.length<1">
                        <b-button size="xs" variant="success" v-on:click="addImage('code-hang-tag')">Add Images</b-button>
                    </template>
            </b-form-group>

        </b-card>
        <template>
            <b-modal
                hide-backdrop
                hide-footer
                v-model="show"
                size="sm"
                ref="modal"
                title="Edit Images">
                <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                    <b-form @submit.prevent="handleSubmit(onSubmit)">
                        <validation-provider
                            name="UploadImages"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                           <b-form-group>
                               <template v-slot:label>
                                   {{title}}
                               </template>
                                <b-form-file
                                    ref="fileRef"
                                    :id="id"
                                    :name="label"
                                    v-model="images"
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose file here..."
                                    accept=".jpg, .png,"
                                    no-drop
                                    v-on:change="onFileUpload"
                                ></b-form-file>
                           </b-form-group>
                        </validation-provider>
                        <b-button variant="warning" v-on:click="hideModal()" block size="sm">Cancel</b-button>
                        <b-button variant="success" block size="sm" type="submit">Save</b-button>
                    </b-form>
                </validation-observer>
            </b-modal>
        </template>
        <template>
            <b-modal
                hide-backdrop
                hide-footer
                v-model="showAdd"
                size="sm"
                ref="modalAdd"
                title="Add Images">
                <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                    <b-form @submit.prevent="handleSubmit(onSubmitAdd)">
                        <validation-provider
                            name="UploadImages"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                           <b-form-group>
                               <template v-slot:label>
                                   {{title}}
                               </template>
                                <b-form-file
                                    ref="fileRef"
                                    :id="id"
                                    :name="label"
                                    v-model="images"
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose file here..."
                                    accept=".jpg, .png,"
                                    no-drop
                                    v-on:change="onFileUpload"
                                ></b-form-file>
                           </b-form-group>
                        </validation-provider>
                        <b-button variant="warning" v-on:click="hideModalAdd()" block size="sm">Cancel</b-button>
                        <b-button variant="success" block size="sm" type="submit">Save</b-button>
                    </b-form>
                </validation-observer>
            </b-modal>
        </template>
        </b-overlay>
    </div>
</template>
<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    props:['productId'],
    data(){
        return{
            show:false,
            showAdd:false,
            title:null,
            imagesId:null,
            label:null,
            id:null,
            name:null,
            images:[],
            form:{
                imgUpload:[],
                design:null,
                imgDesign:[],
                technicalDrawing:null,
                imgTechnicalDrawing:[],
                codeMainLabel:null,
                codeStreamerLabel:null,
                codeSizeLabel:null,
                codeHangtag:null,
                imgCodeMainLabel:[],
                imgCodeStreamerLabel:[],
                imgCodeSizeLabel:[],
                imgCodeHangtag:[],
            },
            busy:false,
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_IMAGES','POST_PRODUCT_IMAGES','PUT_PRODUCT_IMAGES','DELETE_PRODUCT_IMAGES']),
        ...mapActions('session',['showNotification']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onFileUpload(e){
        var file = e.target.files
        var idField = e.target.id
        var pathName = e.target.name
        this.form['img'+idField] = []
        this.form.imgUpload = []
        for (var i=0; i < file.length; i++){
            let img = {
                img_id:this.imagesId,
                src: URL.createObjectURL(file[i]),
                fileName: file[i].name,
                images: file[i],
                img_name:file[i].name,
                img_code:file[i].name+'-'+[i],
                img_label:pathName
            }
            this.form['img'+idField].push(img)
            this.form.imgUpload.push(img)

        
        }
        
        },
        getProductImg(){
            this.clearForm()
            this.GET_PRODUCT_IMAGES({product_id:this.productId})
            .then(rsp=>{
                let data = rsp.data.payload
                for (let i = 0; i < data.length; i++) {
                    let img = {
                        img_id:data[i].id,
                        img_code:data[i].img_code,
                        img_name:data[i].img_name,
                        img_src:data[i].img_src,
                        img_label:data[i].img_label,

                    }
                    if(data[i].img_label === 'design'){
                        this.form.imgDesign.push(img)
                    }if(data[i].img_label === 'technical-drawing'){
                        this.form.imgTechnicalDrawing.push(img)
                    }if(data[i].img_label === 'code-main-label'){
                        this.form.imgCodeMainLabel.push(img)
                    }if(data[i].img_label === 'code-streamer-label'){
                        this.form.imgCodeStreamerLabel.push(img)
                    }if(data[i].img_label === 'code-size-label'){
                        this.form.imgCodeSizeLabel.push(img)
                    }if(data[i].img_label === 'code-hang-tag'){
                        this.form.imgCodeHangtag.push(img)
                    }
                    
                }
                
            })
        },
        onSubmit(){
            Vue.set(this,'busy',true)
            const fd = new FormData()
            fd.append('images_id', this.form.imgUpload[0].img_id)
            fd.append('design',this.form.imgUpload[0].images)
            fd.append('img_label',this.form.imgUpload[0].img_label)
            // fd.append('technical_drawing',this.product.technical[0].images)
            

            this.PUT_PRODUCT_IMAGES(fd)
            .then(response =>{
                this.form.imgDesign = []
                this.form.imgTechnicalDrawing = []
                this.form.imgCodeMainLabel = []
                this.form.imgCodeStreamerLabel = []
                this.form.imgCodeSizeLabel = []
                this.form.imgCodeHangtag = []
                // console.log('gambar berhasil disimpan');
                Vue.set(this,'busy',false)
                this.showNotification({type:'success', message:'Successfully Submitted'})

                this.getProductImg()
            })
            .catch(err=>{
                
                    Vue.set(this,'busy',false)
                    return err.response.data.message
                
            })
            
            this.hideModal()
        },
        onSubmitAdd(){
            Vue.set(this,'busy',true)
            const fd = new FormData()
            fd.append('product_id', this.productId)
            fd.append('design',this.form.imgUpload[0].images)
            fd.append('img_label',this.form.imgUpload[0].img_label)
            // fd.append('technical_drawing',this.product.technical[0].images)
            

            this.POST_PRODUCT_IMAGES(fd)
            .then(response =>{
                
                // console.log('gambar berhasil disimpan');
                Vue.set(this,'busy',false)
                this.showNotification({type:'success', message:'Successfully Submitted'})

                this.getProductImg()
            })
            .catch(err=>{
                
                    Vue.set(this,'busy',false)
                    return err.response.data.message
                
            })
            
            this.hideModalAdd()
        },
        changeImage(id,label,name){
            this.show = true
            this.$set(this,'imagesId',id)
            this.$set(this,'id',name)
            this.$set(this,'label',label)
            this.$set(this,'name',name)
        },
        hideModal(){
            this.show = false
            this.$set(this,'imagesId',null)
            this.$set(this,'id',null)
            this.$set(this,'label',null)
            this.$set(this,'name',null)
        },
        hideModalAdd(){
            this.showAdd = false
            this.$set(this,'imagesId',null)
            this.$set(this,'id',null)
            this.$set(this,'label',null)
            this.$set(this,'name',null)
        },
        addImage(name){
            this.showAdd = true
            this.$set(this,'imagesId',this.productId)
            this.$set(this,'label',name)
            this.$set(this,'name',name)
        },
        deleteImage(id){
            Vue.set(this,'busy',true)
            this.DELETE_PRODUCT_IMAGES({images_id:id})
            .then(rsp=>{
                Vue.set(this,'busy',false)
                this.showNotification({type:'success', message:'Successfully Delete'})
                this.getProductImg()
            }).catch(err=>{
                Vue.set(this,'busy',false)
                return err
            })
            
        },
        clearForm(){
            this.form.imgDesign =[]
            this.form.imgTechnicalDrawing =[]
            this.form.imgCodeMainLabel =[]
            this.form.imgCodeStreamerLabel =[]
            this.form.imgCodeSizeLabel =[]
            this.form.imgCodeHangtag =[]
        }
        
    },
    created(){
        this.getProductImg()
    }
}
</script>