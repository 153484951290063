<template>

    <div id="master-paramater-list">
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
            <b-breadcrumb-item>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col sm="auto" lg="12">
                    <b-card
                        header="Master Parameter List"
                        header-tag="header"
                    >
                        <template v-slot:header >
                            <b-row>
                                <b-col sm="auto">
                                    <div class="text-left">
                                        <h3>Master Paramater</h3>
                                    </div>
                                </b-col>
                                <b-col sm="auto" md="3" >
                                    <div class="text-left">
                                        <template v-if="roles">
                                            <b-button title="Tambah" size="sm" variant="success" v-on:click="goToCreated()">
                                                
                                                <b-icon icon="plus-circle-fill" aria-label="Help"></b-icon>
                                                Create
                                                
                                            </b-button>
                                        </template>
                                    </div>
                                </b-col>
                                <b-col sm="auto" >
                                    <b-form-group
                                        label="Filter"
                                        label-for="filter-input"
                                        label-cols-sm="3"
                                        label-align-sm="right"
                                        label-size="sm"
                                        class="mb-0"
                                        >
                                        <b-input-group size="sm">
                                            <b-form-input
                                            id="filter-input"
                                            v-model="filter"
                                            type="search"
                                            placeholder="Type to Search"
                                            ></b-form-input>

                                            <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                        </b-form-group>
                                </b-col>
                            </b-row>
                            
                        </template>
                        <div ref="pdf">
                            <b-table 
                                id="table-test"
                                responsive 
                                stickyColumn 
                                striped 
                                hover 
                                show-empty
                                @filtered="onFiltered"
                                :filter="filter"
                                :per-page="paging.perPage"
                                :current-page="paging.currentPage"
                                :items="items" 
                                :fields="fields">

                                <template v-if="roles" v-slot:cell(actions)="{item}">
                                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(item.id,item.name,item.category,item.value,item.description)">
                                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                                    </b-button>
                                    <b-button  title="Ubah" size="xs" variant="warning" v-on:click="goEditData(item.id,item.name,item.category,item.value,item.description,item.parent)">
                                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                        <div class="mt-3">
                            <b-pagination 
                                v-model="paging.currentPage" 
                                :total-rows="rows" 
                                :per-page="paging.perPage"
                                align="center"></b-pagination>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import apiProduct from '@/store/product-form/master-param.js'
export default {
    name: 'AppsMasterParamListPage',
    data(){
        return {
            formName: 'Master Parameter',
            modulName: 'List',
            isLoadingData:false,
            errorMessage:null,
            fields:[
                {key:'actions', label:'#'},
                'name','category','value','description',
                ],
            items: [],
            pdfItems:[],
            id:'',
            name:null,
            catergory:null,
            value:null,
            description:null,
            msgConfirm:'',
            paging: {
                perPage: 10,
                currentPage: 1,
                totalRows:1,
            },
            filter: null,

            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User']
        }
    },
    computed: {
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        rows() {
            // console.log(this.items);
            // this.paging.totalRows = this.items.length
            return this.items.length
        }
    },
    methods: {
        ...mapActions('session', ['showNotification']),
        onFiltered(filteredItems) {
            // console.log(filteredItems.length);
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.currentPage = 1
            return filteredItems.length
        },
        getPaging(){
            this.$set(this.paging,'totalRows',this.items.length)
        },
        getData(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    this.items = response.data.payload
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
            
        },
        goToCreated(){
            this.$router.push({name:"AppsMasterParamPage"})
        },
        goEditData(id,name,category,value,description,parent){
            var parentCat = this.items.filter(i=>i.id == parent)
            this.$router.push({
                name:"AppsMasterParamPage",
                params: {
                    master_id:id.toString(),
                    name:name,
                    category:category,
                    value:value,
                    description:description,
                    parent:parent,
                    parentCategory:parentCat[0].category
                }
            })
        },
        deleteData(id,name,category,value,description){
            apiProduct.dispatch('deleteMasterParam', {
                master_id:id.toString(),
                name:name,
                category:category,
                value:value,
                description:description,
            })
            .then(response => {
                
                this.showNotification({type:'success',message:'Successfully Deleted'})
                this.getData()
            })
            .catch(err => {
                if (err.response.status === 400) {
                    
                    this.errorMessage = err.response.data.message
                }
            })
        },
        
        
    },
    mounted() {
        
        this.getData()
        
    },
}
</script>