<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-subcon"> Dashboard Subcon</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Form Master Subcon" header-tag="header">
                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Input Subcon Daily</h3>
                                </div>
                            </div>
                        </template>


                            <form>
                            <!-- <b-row align-h="start">
                            <b-col sm="12">
                            <div class="text-left">
                                <h6 style=''>IO         :{{form.data_io}}</h6>
                                <h6 style=''>Date       :{{form.data_io}}</h6>
                            </div>
                            </b-col>
                            </b-row> -->

                            <div id="tab-button" class="tab">
                                <input type="button" id="process0-button" :disabled="arraydisabled[0]" class="tablinks hidden label " v-on:click="openProcess('process0')" value="Cutting" />
                                <!-- <button id="process0-button" :disabled="arraydisabled[0]" class="tablinks hidden" v-on:click="openProcess('process0')">Cutting</button> -->
                                <input type="button" id="process1-button" :disabled="arraydisabled[1]" class="tablinks hidden label " v-on:click="openProcess('process1')" value="Sewing" />
                                <input type="button" id="process2-button" :disabled="arraydisabled[2]" class="tablinks hidden label " v-on:click="openProcess('process2')" value="Finishing" />
                                <input type="button" id="process3-button" :disabled="arraydisabled[3]" class="tablinks hidden label " v-on:click="openProcess('process3')" value="Packing" />
                                <input type="button" id="process4-button" :disabled="arraydisabled[4]" class="tablinks hidden label " v-on:click="openProcess('process4')" value="Print" />
                                <input type="button" id="process5-button" :disabled="arraydisabled[5]" class="tablinks hidden label " v-on:click="openProcess('process5')" value="Embroidery" />
                                <input type="button" id="process6-button" :disabled="arraydisabled[6]" class="tablinks hidden label " v-on:click="openProcess('process6')" value="Emblem" />
                                <input type="button" id="process7-button" :disabled="arraydisabled[7]" class="tablinks hidden label " v-on:click="openProcess('process7')" value="Washing" />
                            </div>


                                <b-row style="padding-top:15px;" align-h="center">
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="IO"
                                                label-for           ="inputIO">
                                                <b-form-input readonly id="inputIO" v-model="form.data_io"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Date"
                                                label-for           ="inputDate">
                                                <b-form-input readonly id="inputDate" v-model="date_today"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Due Date"
                                                label-for           ="inputDue_date">
                                                <b-form-input readonly id="inputDue_date" v-model="form.due_date"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                </b-row>
                                <b-row align-h="center">
                                         <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Artikel"
                                                label-for           ="inputArtikel">
                                                <b-form-input readonly id="inputArtikel" v-model="form.artikel"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                         <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Sample"
                                                label-for           ="inputSample">
                                                <b-form-input readonly id="inputSample" v-model="form.sample"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                         <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Total Qty"
                                                label-for           ="inputTotal_Qty">
                                                <b-form-input readonly id="inputTotal_Qty" v-model="form.total_qty"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                </b-row>

                        <div id="process0" class="tabcontent">
                            Cutting
                                <b-row  style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Size</th>
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="rule_size_1 != 0">
                                                <td style="background-color:#DDD">{{name_size_1}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_1)"></td>
                                                <td id="size_1">
                                                    <validation-provider
                                                    name="size_1"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_1" v-model="size_1"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_1, rule_size_1,size_1)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_2 != 0">
                                                <td style="background-color:#DDD">{{name_size_2}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_2)"></td>
                                                <td id="size_2">
                                                    <validation-provider
                                                    name="size_2"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_2" v-model="size_2"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_2, rule_size_2,size_2)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_3 != 0">
                                                <td style="background-color:#DDD">{{name_size_3}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_3)"></td>
                                                <td id="size_3">
                                                    <validation-provider
                                                    name="size_3"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_3" v-model="size_3"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_3, rule_size_3,size_3)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_4 != 0">
                                                <td style="background-color:#DDD">{{name_size_4}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_4)"></td>
                                                <td id="size_4">
                                                    <validation-provider
                                                    name="size_4"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_4" v-model="size_4"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_4, rule_size_4,size_4)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_5 != 0">
                                                <td style="background-color:#DDD">{{name_size_5}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_5)"></td>
                                                <td id="size_5">
                                                    <validation-provider
                                                    name="size_5"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_5" v-model="size_5"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_5, rule_size_5,size_5)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_6 != 0">
                                                <td style="background-color:#DDD">{{name_size_6}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_6)"></td>
                                                <td id="size_6">
                                                    <validation-provider
                                                    name="size_6"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_6" v-model="size_6"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_6, rule_size_6,size_6)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_7 != 0">
                                                <td style="background-color:#DDD">{{name_size_7}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_7)"></td>
                                                <td id="size_7">
                                                    <validation-provider
                                                    name="size_7"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_7" v-model="size_7"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_7, rule_size_7,size_7)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_8 != 0">
                                                <td style="background-color:#DDD">{{name_size_8}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_8)"></td>
                                                <td id="size_8">
                                                    <validation-provider
                                                    name="size_8"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_8" v-model="size_8"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_8, rule_size_8,size_8)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_9 != 0">
                                                <td style="background-color:#DDD">{{name_size_9}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_9)"></td>
                                                <td id="size_9">
                                                    <validation-provider
                                                    name="size_9"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_9" v-model="size_9"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_9, rule_size_9,size_9)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_10 != 0">
                                                <td style="background-color:#DDD">{{name_size_10}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_10)"></td>
                                                <td id="size_10">
                                                    <validation-provider
                                                    name="size_10"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_10" v-model="size_10"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_10, rule_size_10,size_10)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_11 != 0">
                                                <td style="background-color:#DDD">{{name_size_11}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_11)"></td>
                                                <td id="size_11">
                                                    <validation-provider
                                                    name="size_11"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_11" v-model="size_11"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_11, rule_size_11,size_11)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_12 != 0">
                                                <td style="background-color:#DDD">{{name_size_12}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_12)"></td>
                                                <td id="size_12">
                                                    <validation-provider
                                                    name="size_12"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_12" v-model="size_12"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_12, rule_size_12,size_12)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_13 != 0">
                                                <td style="background-color:#DDD">{{name_size_13}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_13)"></td>
                                                <td id="size_13">
                                                    <validation-provider
                                                    name="size_13"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_13" v-model="size_13"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_13, rule_size_13,size_13)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_14 != 0">
                                                <td style="background-color:#DDD">{{name_size_14}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_14)"></td>
                                                <td id="size_14">
                                                    <validation-provider
                                                    name="size_14"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_14" v-model="size_14"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_14, rule_size_14, size_14)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_15 != 0">
                                                <td style="background-color:#DDD">{{name_size_15}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_15)"></td>
                                                <td id="size_15">
                                                    <validation-provider
                                                    name="size_15"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_15" v-model="size_15"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_15, rule_size_15, size_15)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_16 != 0">
                                                <td style="background-color:#DDD">{{name_size_16}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_16)"></td>
                                                <td id="size_16">
                                                    <validation-provider
                                                    name="size_16"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_16" v-model="size_16"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_16, rule_size_16, size_16)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_17 != 0">
                                                <td style="background-color:#DDD">{{name_size_17}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_17)"></td>
                                                <td id="size_17">
                                                    <validation-provider
                                                    name="size_17"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_17" v-model="size_17"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_17, rule_size_17, size_17)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_18 != 0">
                                                <td style="background-color:#DDD">{{name_size_18}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_18)"></td>
                                                <td id="size_18">
                                                    <validation-provider
                                                    name="size_18"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_18" v-model="size_18"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_18, rule_size_18, size_18)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_19 != 0">
                                                <td style="background-color:#DDD">{{name_size_19}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_19)"></td>
                                                <td id="size_19">
                                                    <validation-provider
                                                    name="size_19"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_19" v-model="size_19"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_19, rule_size_19, size_19)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_20 != 0">
                                                <td style="background-color:#DDD">{{name_size_20}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_20)"></td>
                                                <td id="size_20">
                                                    <validation-provider
                                                    name="size_20"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_20" v-model="size_20"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_20, rule_size_20, size_20)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_21 != 0">
                                                <td style="background-color:#DDD">{{name_size_21}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_21)"></td>
                                                <td id="size_21">
                                                    <validation-provider
                                                    name="size_21"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_21" v-model="size_21"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_21, rule_size_21, size_21)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_22 != 0">
                                                <td style="background-color:#DDD">{{name_size_22}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_22)"></td>
                                                <td id="size_22">
                                                    <validation-provider
                                                    name="size_22"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_22" v-model="size_22"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_22, rule_size_22, size_22)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_23 != 0">
                                                <td style="background-color:#DDD">{{name_size_23}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_23)"></td>
                                                <td id="size_23">
                                                    <validation-provider
                                                    name="size_23"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_23" v-model="size_23"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_23, rule_size_23, size_23)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_24 != 0">
                                                <td style="background-color:#DDD">{{name_size_24}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_24)"></td>
                                                <td id="size_24">
                                                    <validation-provider
                                                    name="size_24"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_24" v-model="size_24"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_24, rule_size_24, size_24)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_25 != 0">
                                                <td style="background-color:#DDD">{{name_size_25}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_25)"></td>
                                                <td id="size_25">
                                                    <validation-provider
                                                    name="size_25"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_25" v-model="size_25"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_25, rule_size_25, size_25)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_26 != 0">
                                                <td style="background-color:#DDD">{{name_size_26}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_26)"></td>
                                                <td id="size_26">
                                                    <validation-provider
                                                    name="size_26"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_26" v-model="size_26"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_26, rule_size_26, size_26)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_27 != 0">
                                                <td style="background-color:#DDD">{{name_size_27}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_27)"></td>
                                                <td id="size_27">
                                                    <validation-provider
                                                    name="size_27"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_27" v-model="size_27"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_27, rule_size_27, size_27)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_28 != 0">
                                                <td style="background-color:#DDD">{{name_size_28}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_28)"></td>
                                                <td id="size_28">
                                                    <validation-provider
                                                    name="size_28"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_28" v-model="size_28"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_28, rule_size_28, size_28)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_29 != 0">
                                                <td style="background-color:#DDD">{{name_size_29}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_29)"></td>
                                                <td id="size_29">
                                                    <validation-provider
                                                    name="size_29"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_29" v-model="size_29"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_29, rule_size_29, size_29)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_30 != 0">
                                                <td style="background-color:#DDD">{{name_size_30}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_30)"></td>
                                                <td id="size_30">
                                                    <validation-provider
                                                    name="size_30"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_30" v-model="size_30"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_30, rule_size_30, size_30)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_31 != 0">
                                                <td style="background-color:#DDD">{{name_size_31}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_31)"></td>
                                                <td id="size_31">
                                                    <validation-provider
                                                    name="size_31"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_31" v-model="size_31"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_31, rule_size_31, size_31)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_32 != 0">
                                                <td style="background-color:#DDD">{{name_size_32}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_32)"></td>
                                                <td id="size_32">
                                                    <validation-provider
                                                    name="size_32"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_32" v-model="size_32"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_32, rule_size_32, size_32)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_33 != 0">
                                                <td style="background-color:#DDD">{{name_size_33}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_33)"></td>
                                                <td id="size_33">
                                                    <validation-provider
                                                    name="size_33"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_33" v-model="size_33"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_33, rule_size_33, size_33)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_34 != 0">
                                                <td style="background-color:#DDD">{{name_size_34}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_34)"></td>
                                                <td id="size_34">
                                                    <validation-provider
                                                    name="size_34"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_34" v-model="size_34"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_34, rule_size_34, size_34)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr v-if="rule_size_35 != 0">
                                                <td style="background-color:#DDD">{{name_size_35}}</td>
                                                <td style="background-color:#BBB" v-html="SizeRemaining(b_size_35)"></td>
                                                <td id="size_35">
                                                    <validation-provider
                                                    name="size_35"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_35" v-model="size_35"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="background-color:#BBB" v-html="CalcSizeShow(b_size_35, rule_size_35, size_35)"></td>
                                                <td style="background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(a_remaining)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcCuttingSize(size_1,size_2,size_3,size_4,size_5,size_6,size_7,size_8,size_9,size_10,size_11,size_12,size_13,size_14,size_15,size_16,size_17,size_18,size_19,size_20,size_21,size_22,size_23,size_24,size_25,size_26,size_27,size_28,size_29,size_30,size_31,size_32,size_33,size_34,size_35)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcessCutting(cutting_progress,a_remaining,form.total_qty)" ></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center;" >Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">{{a_next_process}}</td>
                                                <td style="text-align:center; background-color:#68a8ff; font-weight:bold;">{{a_next_subcon}}</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="a_ket" :options="opt_reason_cutting" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_a" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="a_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>


                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Process</th>
                                                <th style="border:1px solid #888;">Start Date</th>
                                                <th style="border:1px solid #888;">Plan (Days)</th>
                                                <th style="border:1px solid #888;">Actual (Days)</th>
                                                <th style="border:1px solid #888;">End Date</th>
                                                <th style="border:1px solid #888;">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Cutting</td>
                                                <td v-html="FormatDate(form.cutting_date)" style="background-color:#CCC;"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.cutting_target !== null">{{CalcTotalPlan(form.total_qty ,form.cutting_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.cutting_end_date !== null">
                                                    {{cutting_actual_days}}</span>
                                                    <span v-if="form.cutting_end_date == null && form.cutting_date !== null ">
                                                    {{cutting_actual_days}}</span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.cutting_end_date !== null" v-html="FormatDate(form.cutting_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                       <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[0]" @click="CompleteProcess('a')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[0]" @click="Confirm('a')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[0]" @click="CloseProcess('a')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>
                        </div>
                        <div id="process1" class="tabcontent">
                                <b-row  align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Send Date</th>
                                                <th style="border:1px solid #888;">Send Qty</th>
                                                <th style="border:1px solid #888;">Send From</th>
                                                <th style="border:1px solid #888;">Date Rec</th>
                                                <th style="border:1px solid #888;">Rec Qyt</th>
                                                <th style="border:1px solid #888;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'A'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.send_qty !== null && row.next_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.send_date)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_qty}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_to}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.date_rec)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'b' && row.io == form.data_io && row.status_rec == 'Open'" >
                                                    <validation-provider
                                                    name="size_xxxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" v-model="b_receive[index]"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'b' && (row.status_rec == 'Receive' || row.status_rec == 'Partial Receive') && row.io == form.data_io " style="background-color:#CCC;">
                                                    {{row.rec_qty}}
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'b' && row.io == form.data_io " v-html="checkStatReceive(row.status_rec, 1)" style="background-color:#CCC;">{{row.status_rec}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="end">
                                    <b-col sm="3">
                                        <b-button block :disabled="inputDisabled[1]" @click="Receive('b')" ref="save" type="button" variant="warning">Receive</b-button>
                                    </b-col>
                                </b-row>
                                Sewing
                                <b-row  style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center; background-color:#BBB">{{b_remaining}}</td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="b_actual"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(sewing_progress,0, b_actual)"></td>
                                                <td style="text-align:center; background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center; background-color:#CCC" v-html="ProcessRemaining(b_remaining, b_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(b_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcess(sewing_progress,b_remaining,form.total_qty)" ></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">{{b_next_process}}</td>
                                                <td style="background-color:#68a8ff; font-weight:bold;">{{b_next_subcon}}</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="b_ket" :options="opt_reason_sewing" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_b" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="b_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Process</th>
                                                <th style="border:1px solid #888; ">Start Date</th>
                                                <th style="border:1px solid #888; ">Plan (Days)</th>
                                                <th style="border:1px solid #888; ">Actual (Days)</th>
                                                <th style="border:1px solid #888; ">End Date</th>
                                                <th style="border:1px solid #888; ">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'C'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;">Cutting</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;" v-html="FormatDate(form.cutting_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.cutting_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io && form.cutting_end_date !== null" style="background-color:#CCC;">
                                                        {{cutting_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.cutting_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'DA'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">Sewing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io && form.sewing_end_date !== null" style="background-color:#CCC;">
                                                         {{sewing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'D'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">Finishing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io && form.finishing_end_date !== null" style="background-color:#CCC;">
                                                        {{finishing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'E'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">Packing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io && form.packing_end_date !== null" style="background-color:#CCC;">
                                                        {{packing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'F'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">Print</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io && form.printing_end_date !== null" style="background-color:#CCC;">
                                                        {{printing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'G'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">Embroidery</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io && form.embroidery_end_date !== null" style="background-color:#CCC;">
                                                        {{embroidery_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'H'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">Emblem</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io && form.emblem_end_date !== null" style="background-color:#CCC;">
                                                        {{emblem_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'I'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">Washing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io && form.washing_end_date !== null" style="background-color:#CCC;">
                                                        {{washing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>


                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Sewing</td>
                                                <td style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.sewing_target !== null">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.sewing_end_date !== null">
                                                        {{sewing_actual_days}}
                                                    </span>
                                                    <span v-if="form.sewing_end_date == null && form.sewing_date !== null">
                                                        {{sewing_actual_days}}
                                                    </span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.sewing_end_date !== null" v-html="FormatDate(form.sewing_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                       <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[1]" @click="CompleteProcess('b')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[1]" @click="Confirm('b')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[1]" @click="CloseProcess('b')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>

                        </div>

                            <div id="process2" class="tabcontent">
                                <b-row  align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Send Date</th>
                                                <th style="border:1px solid #888;">Send Qty</th>
                                                <th style="border:1px solid #888;">Send From</th>
                                                <th style="border:1px solid #888;">Date Rec</th>
                                                <th style="border:1px solid #888;">Rec Qyt</th>
                                                <th style="border:1px solid #888;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'J'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.send_qty !== null && row.next_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.send_date)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_qty}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_to}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.date_rec)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'c' && row.io == form.data_io && row.status_rec == 'Open' " >
                                                    <validation-provider
                                                    name="size_xxxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" v-model="b_receive[index]"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'c' && (row.status_rec == 'Receive' || row.status_rec == 'Partial Receive') && row.io == form.data_io " style="background-color:#CCC;">
                                                    {{row.rec_qty}}
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'c' && row.io == form.data_io " style="background-color:#CCC;" v-html="checkStatReceive(row.status_rec, 2)" ></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="end">
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[2]" @click="Receive('c')" ref="save" type="button" variant="warning">Receive</b-button>
                                    </b-col>
                                </b-row>

                                Finishing
                                <b-row  style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center; background-color:#BBB">{{c_remaining}}</td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="c_actual"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(finishing_progress, 0, c_actual)"></td>
                                                <td style="text-align:center; background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center; background-color:#CCC" v-html="ProcessRemaining(c_remaining, c_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(c_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcess(finishing_progress,c_remaining,form.total_qty)" ></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">{{c_next_process}}</td>
                                                <td style="background-color:#68a8ff; font-weight:bold;">{{c_next_subcon}}</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="c_ket" :options="opt_reason_finishing" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_c" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="c_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Process</th>
                                                <th style="border:1px solid #888; ">Start Date</th>
                                                <th style="border:1px solid #888; ">Plan (Days)</th>
                                                <th style="border:1px solid #888; ">Actual (Days)</th>
                                                <th style="border:1px solid #888; ">End Date</th>
                                                <th style="border:1px solid #888; ">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'K'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;">Cutting</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;" v-html="FormatDate(form.cutting_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.cutting_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io && form.cutting_end_date !== null" style="background-color:#CCC;">
                                                       {{cutting_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.cutting_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'LA'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">Sewing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io && form.sewing_end_date !== null" style="background-color:#CCC;">
                                                       {{sewing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'L'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">Finishing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io && form.finishing_end_date !== null" style="background-color:#CCC;">
                                                        {{finishing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'M'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">Packing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io && form.packing_end_date !== null" style="background-color:#CCC;">
                                                        {{packing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'N'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">Print</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io && form.printing_end_date !== null" style="background-color:#CCC;">
                                                        {{printing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'O'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">Embroidery</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io && form.embroidery_end_date !== null" style="background-color:#CCC;">
                                                        {{embroidery_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'P'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">Emblem</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io && form.emblem_end_date !== null" style="background-color:#CCC;">
                                                        {{emblem_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'Q'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">Washing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io && form.washing_end_date !== null" style="background-color:#CCC;">
                                                      {{washing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>


                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Finishing</td>
                                                <td style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.finishing_target !== null">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.finishing_end_date !== null">
                                                        {{finishing_actual_days}}
                                                    </span>
                                                    <span v-if="form.finishing_end_date == null && form.finishing_date !== null">
                                                        {{finishing_actual_days}}
                                                    </span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.finishing_end_date !== null" v-html="FormatDate(form.finishing_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                    <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[2]" @click="CompleteProcess('c')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[2]" @click="Confirm('c')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[2]" @click="CloseProcess('c')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>

                            </div>


                            <div id="process3" class="tabcontent">
                                <b-row  align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Send Date</th>
                                                <th style="border:1px solid #888;">Send Qty</th>
                                                <th style="border:1px solid #888;">Send From</th>
                                                <th style="border:1px solid #888;">Date Rec</th>
                                                <th style="border:1px solid #888;">Rec Qyt</th>
                                                <th style="border:1px solid #888;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'R'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.send_qty !== null && row.next_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.send_date)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_qty}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_to}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.date_rec)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'd' && row.io == form.data_io && row.status_rec == 'Open' " >
                                                    <validation-provider
                                                    name="size_xxxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" v-model="b_receive[index]"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'd' && (row.status_rec == 'Receive' || row.status_rec == 'Partial Receive') && row.io == form.data_io " style="background-color:#CCC;">
                                                    {{row.rec_qty}}
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'd' && row.io == form.data_io " style="background-color:#CCC;" v-html="checkStatReceive(row.status_rec, 3)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="end">
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[3]" @click="Receive('d')" ref="save" type="button" variant="warning">Receive</b-button>
                                    </b-col>
                                </b-row>
                                Packing
                                <b-row   style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual(Pcs)</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">Actual(Box)</th>
                                                <th style="border:1px solid #888; ">Total(Box)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center; background-color:#BBB">{{d_remaining}}</td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="d_actual"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(packing_progress, 0, d_actual)"></td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="form.packing_box"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(packingBox, 0, form.packing_box)"></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center; background-color:#CCC" v-html="ProcessRemaining(d_remaining, d_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(d_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcess(packing_progress,d_remaining,form.total_qty)" ></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(form.packing_box)"></td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(packingBox, 0, form.packing_box)"></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">Delivery</td>
                                                <td style="background-color:#68a8ff; font-weight:bold;">BBI</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="d_ket" :options="opt_reason_packing" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_d" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="d_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Process</th>
                                                <th style="border:1px solid #888; ">Start Date</th>
                                                <th style="border:1px solid #888; ">Plan (Days)</th>
                                                <th style="border:1px solid #888; ">Actual (Days)</th>
                                                <th style="border:1px solid #888; ">End Date</th>
                                                <th style="border:1px solid #888; ">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'S'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;">Cutting</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;" v-html="FormatDate(form.cutting_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.cutting_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io && form.cutting_end_date !== null" style="background-color:#CCC;">
                                                        {{cutting_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.cutting_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'T'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">Sewing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io && form.sewing_end_date !== null" style="background-color:#CCC;">
                                                        {{sewing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'TA'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">Finishing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io && form.finishing_end_date !== null" style="background-color:#CCC;">
                                                        {{finishing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'U'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">Packing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io && form.packing_end_date !== null" style="background-color:#CCC;">
                                                        {{packing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'V'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">Print</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io && form.printing_end_date !== null" style="background-color:#CCC;">
                                                        {{printing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'W'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">Embroidery</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io && form.embroidery_end_date !== null" style="background-color:#CCC;">
                                                        {{embroidery_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'X'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">Emblem</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io && form.emblem_end_date !== null" style="background-color:#CCC;">
                                                        {{emblem_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'Y'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">Washing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io && form.washing_end_date !== null" style="background-color:#CCC;">
                                                        {{washing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>


                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Packing</td>
                                                <td style="background-color:#CCC;" v-html="FormatDate(form.packing_date)"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.packing_target !== null">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.packing_end_date !== null">
                                                        {{packing_actual_days}}
                                                    </span>
                                                    <span v-if="form.packing_end_date == null && form.packing_date !== null">
                                                        {{packing_actual_days}}
                                                    </span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.packing_end_date !== null" v-html="FormatDate(form.packing_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                    <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[3]" @click="CompleteProcess('d')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[3]" @click="Confirm('d')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[3]" @click="CloseProcess('d')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>

                            </div>


                            <div id="process4" class="tabcontent">
                                <b-row  align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Send Date</th>
                                                <th style="border:1px solid #888;">Send Qty</th>
                                                <th style="border:1px solid #888;">Send From</th>
                                                <th style="border:1px solid #888;">Date Rec</th>
                                                <th style="border:1px solid #888;">Rec Qyt</th>
                                                <th style="border:1px solid #888;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZA'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.send_qty !== null && row.next_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.send_date)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_qty}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_to}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.date_rec)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'e' && row.io == form.data_io && row.status_rec == 'Open' " >
                                                    <validation-provider
                                                    name="size_xxxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" v-model="b_receive[index]"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'e' && (row.status_rec == 'Receive' || row.status_rec == 'Partial Receive') && row.io == form.data_io " style="background-color:#CCC;">
                                                    {{row.rec_qty}}
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'e' && row.io == form.data_io " style="background-color:#CCC;" v-html="checkStatReceive(row.status_rec, 4)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="end">
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[4]" @click="Receive('e')" ref="save" type="button" variant="warning">Receive</b-button>
                                    </b-col>
                                </b-row>

                                Printing
                                <b-row  style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center; background-color:#BBB">{{e_remaining}}</td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="e_actual"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(printing_progress, 0, e_actual)"></td>
                                                <td style="text-align:center; background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center; background-color:#CCC" v-html="ProcessRemaining(e_remaining, e_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(e_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcess(printing_progress,e_remaining,form.total_qty)" ></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">{{e_next_process}}</td>
                                                <td style="background-color:#68a8ff; font-weight:bold;">{{e_next_subcon}}</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="e_ket" :options="opt_reason_print" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_e" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="e_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Process</th>
                                                <th style="border:1px solid #888; ">Start Date</th>
                                                <th style="border:1px solid #888; ">Plan (Days)</th>
                                                <th style="border:1px solid #888; ">Actual (Days)</th>
                                                <th style="border:1px solid #888; ">End Date</th>
                                                <th style="border:1px solid #888; ">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZB'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;">Cutting</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;" v-html="FormatDate(form.cutting_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.cutting_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io && form.cutting_end_date !== null" style="background-color:#CCC;">
                                                        {{cutting_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.cutting_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZC'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">Sewing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io && form.sewing_end_date !== null" style="background-color:#CCC;">
                                                       {{sewing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZD'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">Finishing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io && form.finishing_end_date !== null" style="background-color:#CCC;">
                                                       {{finishing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZE'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">Packing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io && form.packing_end_date !== null" style="background-color:#CCC;">
                                                        {{packing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZF'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">Print</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io && form.printing_end_date !== null" style="background-color:#CCC;">
                                                        {{printing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZG'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">Embroidery</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io && form.embroidery_end_date !== null" style="background-color:#CCC;">
                                                        {{embroidery_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZGG'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">Emblem</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io && form.emblem_end_date !== null" style="background-color:#CCC;">
                                                        {{emblem_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZI'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">Washing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io && form.washing_end_date !== null" style="background-color:#CCC;">
                                                        {{washing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>


                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Print</td>
                                                <td style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.printing_target !== null">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.printing_end_date !== null">
                                                        {{printing_actual_days}}
                                                    </span>
                                                    <span v-if="form.printing_end_date == null && form.printing_date !== null">
                                                        {{printing_actual_days}}
                                                    </span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.printing_end_date !== null" v-html="FormatDate(form.printing_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                    <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[4]" @click="CompleteProcess('e')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                               <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[4]" @click="Confirm('e')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[4]" @click="CloseProcess('e')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>

                            </div>

                            <div id="process5" class="tabcontent">
                                <b-row  align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Send Date</th>
                                                <th style="border:1px solid #888;">Send Qty</th>
                                                <th style="border:1px solid #888;">Send From</th>
                                                <th style="border:1px solid #888;">Date Rec</th>
                                                <th style="border:1px solid #888;">Rec Qyt</th>
                                                <th style="border:1px solid #888;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZJ'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.send_qty !== null && row.next_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.send_date)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_qty}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_to}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.date_rec)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'f' && row.io == form.data_io && row.status_rec == 'Open' " >
                                                    <validation-provider
                                                    name="size_xxxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" v-model="b_receive[index]"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'f' && (row.status_rec == 'Receive' || row.status_rec == 'Partial Receive') && row.io == form.data_io " style="background-color:#CCC;">
                                                    {{row.rec_qty}}
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'f' && row.io == form.data_io " style="background-color:#CCC;" v-html="checkStatReceive(row.status_rec, 5)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="end">
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[5]" @click="Receive('f')" ref="save" type="button" variant="warning">Receive</b-button>
                                    </b-col>
                                </b-row>
                                Embroidery
                                <b-row  style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center; background-color:#BBB">{{f_remaining}}</td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="f_actual"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(embroidery_progress, 0, f_actual)"></td>
                                                <td style="text-align:center; background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center; background-color:#CCC" v-html="ProcessRemaining(f_remaining, f_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(f_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcess(embroidery_progress,f_remaining,form.total_qty)" ></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">{{f_next_process}}</td>
                                                <td style="background-color:#68a8ff; font-weight:bold;">{{f_next_subcon}}</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="f_ket" :options="opt_reason_embroidery" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_f" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="f_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Process</th>
                                                <th style="border:1px solid #888; ">Start Date</th>
                                                <th style="border:1px solid #888; ">Plan (Days)</th>
                                                <th style="border:1px solid #888; ">Actual (Days)</th>
                                                <th style="border:1px solid #888; ">End Date</th>
                                                <th style="border:1px solid #888; ">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZK'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;">Cutting</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;" v-html="FormatDate(form.cutting_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.cutting_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io && form.cutting_end_date !== null" style="background-color:#CCC;">
                                                        {{cutting_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.cutting_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZL'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">Sewing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io && form.sewing_end_date !== null" style="background-color:#CCC;">
                                                        {{sewing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZM'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">Finishing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io && form.finishing_end_date !== null" style="background-color:#CCC;">
                                                        {{finishing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZN'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">Packing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io && form.packing_end_date !== null" style="background-color:#CCC;">
                                                        {{packing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZO'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">Print</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io && form.printing_end_date !== null" style="background-color:#CCC;">
                                                        {{printing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZP'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">Embroidery</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io && form.embroidery_end_date !== null" style="background-color:#CCC;">
                                                        {{embroidery_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZQ'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">Emblem</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io && form.emblem_end_date !== null" style="background-color:#CCC;">
                                                        {{emblem_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZR'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">Washing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io && form.washing_end_date !== null" style="background-color:#CCC;">
                                                        {{washing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Embroidery</td>
                                                <td style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.embroidery_target !== null">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.embroidery_end_date !== null">
                                                        {{embroidery_actual_days}}
                                                    </span>
                                                    <span v-if="form.embroidery_end_date == null && form.embroidery_date !== null">
                                                        {{embroidery_actual_days}}
                                                    </span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.embroidery_end_date !== null" v-html="FormatDate(form.embroidery_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                    <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[5]" @click="CompleteProcess('f')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                               <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[5]" @click="Confirm('f')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[5]" @click="CloseProcess('f')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>


                            </div>

                            <div id="process6" class="tabcontent">
                                <b-row  align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Send Date</th>
                                                <th style="border:1px solid #888;">Send Qty</th>
                                                <th style="border:1px solid #888;">Send From</th>
                                                <th style="border:1px solid #888;">Date Rec</th>
                                                <th style="border:1px solid #888;">Rec Qyt</th>
                                                <th style="border:1px solid #888;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZS'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.send_qty !== null && row.next_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.send_date)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_qty}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_to}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.date_rec)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'g' && row.io == form.data_io && row.status_rec == 'Open' " >
                                                    <validation-provider
                                                    name="size_xxxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" v-model="b_receive[index]"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'g' && (row.status_rec == 'Receive' || row.status_rec == 'Partial Receive') && row.io == form.data_io " style="background-color:#CCC;">
                                                    {{row.rec_qty}}
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'g' && row.io == form.data_io " style="background-color:#CCC;" v-html="checkStatReceive(row.status_rec, 6)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="end">
                                    <b-col sm="3">
                                        <b-button block :disabled="inputDisabled[6]" @click="Receive('g')" ref="save" type="button" variant="warning">Receive</b-button>
                                    </b-col>
                                </b-row>
                                Emblem
                                <b-row  style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center; background-color:#BBB">{{g_remaining}}</td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="g_actual"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(emblem_progress, 0, g_actual)"></td>
                                                <td style="text-align:center; background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center; background-color:#CCC" v-html="ProcessRemaining(g_remaining, g_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(g_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcess(emblem_progress,g_remaining,form.total_qty)" ></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">{{g_next_process}}</td>
                                                <td style="background-color:#68a8ff; font-weight:bold;">{{g_next_subcon}}</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="g_ket" :options="opt_reason_emblem" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_g" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="g_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Process</th>
                                                <th style="border:1px solid #888; ">Start Date</th>
                                                <th style="border:1px solid #888; ">Plan (Days)</th>
                                                <th style="border:1px solid #888; ">Actual (Days)</th>
                                                <th style="border:1px solid #888; ">End Date</th>
                                                <th style="border:1px solid #888; ">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZT'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;">Cutting</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;" v-html="FormatDate(form.cutting_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.cutting_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io && form.cutting_end_date !== null" style="background-color:#CCC;">
                                                        {{cutting_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.cutting_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZU'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">Sewing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io && form.sewing_end_date !== null" style="background-color:#CCC;">
                                                        {{sewing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZV'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">Finishing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io && form.finishing_end_date !== null" style="background-color:#CCC;">
                                                        {{finishing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZW'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">Packing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io && form.packing_end_date !== null" style="background-color:#CCC;">
                                                        {{packing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZX'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">Print</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io && form.printing_end_date !== null" style="background-color:#CCC;">
                                                        {{printing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZY'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">Embroidery</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io && form.embroidery_end_date !== null" style="background-color:#CCC;">
                                                        {{embroidery_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZZ'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">Emblem</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io && form.emblem_end_date !== null" style="background-color:#CCC;">
                                                        {{emblem_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAA'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">Washing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io && form.washing_end_date !== null" style="background-color:#CCC;">
                                                        {{washing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Emblem</td>
                                                <td style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.emblem_target !== null">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.emblem_end_date !== null">
                                                        {{emblem_actual_days}}
                                                    </span>
                                                    <span v-if="form.emblem_end_date == null && form.emblem_date !== null">
                                                        {{emblem_actual_days}}
                                                    </span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.emblem_end_date !== null" v-html="FormatDate(form.emblem_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                    <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[6]" @click="CompleteProcess('g')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[6]" @click="Confirm('g')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[6]" @click="CloseProcess('g')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>

                            </div>

                            <div id="process7" class="tabcontent">
                                <b-row  align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888;">Send Date</th>
                                                <th style="border:1px solid #888;">Send Qty</th>
                                                <th style="border:1px solid #888;">Send From</th>
                                                <th style="border:1px solid #888;">Date Rec</th>
                                                <th style="border:1px solid #888;">Rec Qyt</th>
                                                <th style="border:1px solid #888;">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'ZS'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.send_qty !== null && row.next_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.send_date)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_qty}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{row.send_to}}</td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(row.date_rec)"></td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'h' && row.io == form.data_io && row.status_rec == 'Open' " >
                                                    <validation-provider
                                                    name="size_xxxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" v-model="b_receive[index]"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'h' && (row.status_rec == 'Receive' || row.status_rec == 'Partial Receive') && row.io == form.data_io " style="background-color:#CCC;">
                                                    {{row.rec_qty}}
                                                </td>
                                                <td v-if="row.send_qty !== null && row.next_process == 'h' && row.io == form.data_io " style="background-color:#CCC;" v-html="checkStatReceive(row.status_rec, 7)"></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="end">
                                    <b-col sm="3">
                                        <b-button block :disabled="inputDisabled[7]" @click="Receive('h')" ref="save" type="button" variant="warning">Receive</b-button>
                                    </b-col>
                                </b-row>
                                Washing
                                <b-row  style="padding-top:10px;" align-h="start">
                                    <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Remaining</th>
                                                <th style="border:1px solid #888; ">Actual</th>
                                                <th style="border:1px solid #888; ">Total</th>
                                                <th style="border:1px solid #888; ">UOM</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style="text-align:center; background-color:#BBB">{{h_remaining}}</td>
                                                <td>
                                                    <validation-provider
                                                    name="size_xxl"
                                                    >
                                                    <b-form-input class="select-custom" type="number" id="size_xxl" v-model="h_actual"   trim></b-form-input>
                                                    </validation-provider>
                                                </td>
                                                <td style="text-align:center; background-color:#BBB" v-html="CalcSizeShow(washing_progress, 0, h_actual)"></td>
                                                <td style="text-align:center; background-color:#BBB">PCS</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td style="text-align:center; background-color:#CCC" v-html="ProcessRemaining(h_remaining, h_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="CalcShow(h_actual)"></td>
                                                <td style="text-align:center; background-color:#CCC" v-html="TotalQtyProcess(washing_progress,h_remaining,form.total_qty)" ></td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Next Process</td>
                                                <td style="text-align:center; font-weight:bold;">{{h_next_process}}</td>
                                                <td style="background-color:#68a8ff; font-weight:bold;">{{h_next_subcon}}</td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Reason</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-select class="form-custom" v-model="h_ket" :options="opt_reason_washing" placeholder="Choose Type" style="top: 1px;">
                                                    </b-form-select>
                                                    <span id="warning_h" class="hidden" style="color:red; font-size:10px">Wajib Di Isi</span>
                                                </td>
                                            </tr>
                                            <tr style="border:0px;">
                                                <td>Description</td>
                                                <td colspan="2" style="font-weight:bold;">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="h_ket_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row v-if="form.role !== 'subcon'" align-h="start">
                                <div class="table-responsive">
                                        <table class="table">
                                        <thead>
                                            <tr style="border:1px solid #888; background-color:#ffc247; text-align:center;" class="text-muted">
                                                <th style="border:1px solid #888; ">Process</th>
                                                <th style="border:1px solid #888; ">Start Date</th>
                                                <th style="border:1px solid #888; ">Plan (Days)</th>
                                                <th style="border:1px solid #888; ">Actual (Days)</th>
                                                <th style="border:1px solid #888; ">End Date</th>
                                                <th style="border:1px solid #888; ">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAB'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;">Cutting</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io " style="background-color:#CCC;" v-html="FormatDate(form.cutting_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.cutting_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io && form.cutting_end_date !== null" style="background-color:#CCC;">
                                                        {{cutting_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.cutting_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'a' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAC'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">Sewing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.sewing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io && form.sewing_end_date !== null" style="background-color:#CCC;">
                                                        {{sewing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.sewing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'b' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAD'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">Finishing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.finishing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io && form.finishing_end_date !== null" style="background-color:#CCC;">
                                                        {{finishing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.finishing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'c' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAE'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">Packing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.packing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io && form.packing_end_date !== null" style="background-color:#CCC;">
                                                        {{packing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.packing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'd' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAF'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">Print</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.printing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io && form.printing_end_date !== null" style="background-color:#CCC;">
                                                        {{printing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.printing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'e' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAG'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">Embroidery</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.embroidery_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io && form.embroidery_end_date !== null" style="background-color:#CCC;">
                                                        {{embroidery_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.embroidery_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'f' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAH'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">Emblem</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.emblem_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io && form.emblem_end_date !== null" style="background-color:#CCC;">
                                                        {{emblem_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.emblem_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'g' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr
                                            v-for="(row,index) in stIoSubconDaily"
                                            :key="'AAI'+index"
                                            style="text-align:center;"
                                            >
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">Washing</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io && form.washing_end_date !== null" style="background-color:#CCC;">
                                                        {{washing_actual_days}}
                                                </td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#CCC;" v-html="FormatDate(form.washing_end_date)"></td>
                                                <td v-if="row.status == 'Complete' && row.order_process == 'h' && row.io == form.data_io" style="background-color:#AAA;">
                                                       Complete
                                                </td>

                                            </tr>

                                            <tr style="text-align:center;">
                                                <td style="background-color:#CCC;">Washing</td>
                                                <td style="background-color:#CCC;" v-html="FormatDate(form.washing_date)"></td>
                                                <td style="background-color:#CCC;" v-if="form.total_qty !== null && form.washing_target !== null">{{CalcTotalPlan(form.total_qty , form.washing_target)}}</td>
                                                <td style="background-color:#CCC;">
                                                    <span v-if="form.washing_end_date !== null">
                                                        {{washing_actual_days}}
                                                    </span>
                                                    <span v-if="form.washing_end_date == null && form.washing_date !== null">
                                                        {{washing_actual_days}}
                                                    </span>
                                                </td>
                                                <td style="background-color:#CCC;"><span v-if="form.washing_end_date !== null" v-html="FormatDate(form.washing_end_date)"></span></td>
                                                <td style="background-color:#ffde9b;">
                                                    <input type="button" style="cursor:pointer; background: #ffc247; border: 1px solid #AAA; border-radius:8px; opacity: 1; width: -webkit-fill-available;" value="Complete" :disabled="CompleteDisabled[7]" @click="CompleteProcess('h')" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </b-row>

                                <b-row  align-h="center">
                                    <b-col sm="4">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="inputDisabled[7]" @click="Confirm('h')" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  :disabled="CloseDisabled[7]" @click="CloseProcess('h')" ref="Close" type="button" variant="secondary">Close</b-button>
                                    </b-col>
                                </b-row>

                            </div>

                            </form>

                     </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import PostSubcon from '@/store/ppm-form/ppm.js';
    import PostCompleteSubcon from '@/store/ppm-form/ppm.js';
    import PostOrderReason from '@/store/ppm-form/ppm.js';
    import UpdateInputPPM from '@/store/ppm-form/ppm.js';
    import UpdateDelivery from '@/store/ppm-form/ppm.js';
    // import vue loading
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);
    import axios from 'axios'

    export default {
       name: 'InputSubconDaily',

        data() {
            const now = new Date()

            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

           // create hour:min:sec
            const time = now.toString().slice(16,24)
            // const opthour = { hour: '2-digit'};
            // const optmin = { minute: '2-digit'};
            // const optsec = { second: '2-digit'};
            // const hour = now.toLocaleString('id-ID', opthour ,{ hour12: false })
            // const min = now.toLocaleString('id-ID', optmin ,{ hour12: false })
            // const sec = now.toLocaleString('id-ID', optsec ,{ hour12: false })
            // const time = hour+':'+min+':'+sec
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                formName: 'Input Daily Subcon',
                errorMessage: null,
                today:today,
                time:time,
                date_today:today,
                oneDay:oneDay,
                code_process:null,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                rule_complete:80,
                tabIndex: 0,
                data_reason:['cutting','sewing','finishing','packing','print','embroidery','emblem','washing'],
                opt_reason_cutting: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                opt_reason_sewing: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                opt_reason_finishing: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                opt_reason_packing: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                opt_reason_print: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                opt_reason_embroidery: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                opt_reason_emblem: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                opt_reason_washing: [
                    { value: null, text: '--- Please Choice ---' , disabled: false },
                ],
                closeProcess:null,
                process_ket:null,
                comp:[],
                cls:[],
                    form : {
                    data_io:null,
                    Employee:null,
                    status:null,
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    manufacture_date:null,
                    description:null,
                    style:null,
                    texture:null,
                    material:null,
                    category:null,
                    sleeve:null,
                    qty_order:null,
                    total_qty:null,
                    due_date:null,
                    package_size:null,
                    basic:null,
                    addtional:null,
                    marker:today,
                    acc:today,
                    fabric:today,
                    fabric_inspection:today,
                    ppm:today,
                    myfile:null,
                    cutting_id :null,
                    cutting_subcon :null,
                    cutting_target :null,
                    cutting_target_dummy :null,
                    cutting_date:null,
                    cutting_end_date:null,
                    embroidery_id:null,
                    embroidery_subcon :null,
                    embroidery_target :null,
                    embroidery_target_dummy :null,
                    embroidery_date :null,
                    embroidery_end_date :null,
                    printing_id :null,
                    printing_subcon :null,
                    printing_target :null,
                    printing_target_dummy :null,
                    printing_date :null,
                    printing_end_date :null,
                    emblem_id :null,
                    emblem_subcon :null,
                    emblem_target :null,
                    emblem_target_dummy :null,
                    emblem_date :null,
                    emblem_end_date :null,
                    sewing_id :null,
                    sewing_subcon :null,
                    sewing_target :null,
                    sewing_target_dummy :null,
                    sewing_date :null,
                    sewing_end_date :null,
                    washing_id :null,
                    washing_subcon :null,
                    washing_target :null,
                    washing_target_dummy :null,
                    washing_date :null,
                    washing_end_date :null,
                    finishing_id :null,
                    finishing_subcon :null,
                    finishing_target :null,
                    finishing_target_dummy :null,
                    finishing_date :null,
                    finishing_end_date :null,
                    packing_id :null,
                    packing_subcon :null,
                    packing_target :null,
                    packing_target_dummy :null,
                    packing_date :null,
                    packing_end_date :null,
                    packing_box:0,
                    process_order:[],
                    role:[],
                    visit_inline:null,
                    visit_final:null,

                    },
                packingBox:null,
                selectedDept:null,
                document_io:[],
                ttl_qty:0,
                form_api:[],
                read_id: null,
                cat_id_cut:null,
                dis_id_cut:null,
                cat_id_sew:null,
                dis_id_sew:null,
                cat_id_embr:null,
                dis_id_embr:null,
                cat_id_pri:null,
                dis_id_pri:null,
                cat_id_embl:null,
                dis_id_embl:null,
                cat_id_wash:null,
                dis_id_wash:null,
                cat_id_fin:null,
                dis_id_fin:null,
                cat_id_pack:null,
                dis_id_pack:null,
                isActive:true,
                readmore:null,
                append_process:0,
                order_process:[],
                arraydisabled:[],
                button_add:false,
                process_order:null,
                arr_process:0,
                err_process:0,
                qty_process:10,
                count_size:0,
                size_1:0,
                name_size_1:null,
                b_size_1:null,
                rule_size_1:0,
                size_2:0,
                name_size_2:null,
                b_size_2:null,
                rule_size_2:0,
                size_3:0,
                name_size_3:null,
                b_size_3:null,
                rule_size_3:0,
                size_4:0,
                name_size_4:null,
                b_size_4:null,
                rule_size_4:0,
                size_5:0,
                name_size_5:null,
                b_size_5:null,
                rule_size_5:0,
                size_6:0,
                name_size_6:null,
                b_size_6:null,
                rule_size_6:0,
                size_7:0,
                name_size_7:null,
                b_size_7:null,
                rule_size_7:0,
                size_8:0,
                name_size_8:null,
                b_size_8:null,
                rule_size_8:0,
                size_9:0,
                name_size_9:null,
                b_size_9:null,
                rule_size_9:0,
                size_10:0,
                name_size_10:null,
                b_size_10:null,
                rule_size_10:0,
                size_11:0,
                name_size_11:null,
                b_size_11:null,
                rule_size_11:0,
                size_12:0,
                name_size_12:null,
                b_size_12:null,
                rule_size_12:0,
                size_13:0,
                name_size_13:null,
                b_size_13:null,
                rule_size_13:0,
                size_14:0,
                name_size_14:null,
                b_size_14:null,
                rule_size_14:0,
                size_15:0,
                name_size_15:null,
                b_size_15:null,
                rule_size_15:0,
                size_16:0,
                name_size_16:null,
                b_size_16:null,
                rule_size_16:0,
                size_17:0,
                name_size_17:null,
                b_size_17:null,
                rule_size_17:0,
                size_18:0,
                name_size_18:null,
                b_size_18:null,
                rule_size_18:0,
                size_19:0,
                name_size_19:null,
                b_size_19:null,
                rule_size_19:0,
                size_20:0,
                name_size_20:null,
                b_size_20:null,
                rule_size_20:0,
                size_21:0,
                name_size_21:null,
                b_size_21:null,
                rule_size_21:0,
                size_22:0,
                name_size_22:null,
                b_size_22:null,
                rule_size_22:0,
                size_23:0,
                name_size_23:null,
                b_size_23:null,
                rule_size_23:0,
                size_24:0,
                name_size_24:null,
                b_size_24:null,
                rule_size_24:0,
                size_25:0,
                name_size_25:null,
                b_size_25:null,
                rule_size_25:0,
                size_26:0,
                name_size_26:null,
                b_size_26:null,
                rule_size_26:0,
                size_27:0,
                name_size_27:null,
                b_size_27:null,
                rule_size_27:0,
                size_28:0,
                name_size_28:null,
                b_size_28:null,
                rule_size_28:0,
                size_29:0,
                name_size_29:null,
                b_size_29:null,
                rule_size_29:0,
                size_30:0,
                name_size_30:null,
                b_size_30:null,
                rule_size_30:0,
                size_31:0,
                name_size_31:null,
                b_size_31:null,
                rule_size_31:0,
                size_32:0,
                name_size_32:null,
                b_size_32:null,
                rule_size_32:0,
                size_33:0,
                name_size_33:null,
                b_size_33:null,
                rule_size_33:0,
                size_34:0,
                name_size_34:null,
                b_size_34:null,
                rule_size_34:0,
                size_35:0,
                name_size_35:null,
                b_size_35:null,
                rule_size_35:0,
                b_actual:0,
                c_actual:0,
                d_actual:0,
                e_actual:0,
                f_actual:0,
                g_actual:0,
                h_actual:0,
                total_actual:0,
                a_total_actual:0,
                b_total_actual:0,
                c_total_actual:0,
                d_total_actual:0,
                e_total_actual:0,
                f_total_actual:0,
                g_total_actual:0,
                h_total_actual:0,
                remaining:0,
                a_remaining:0,
                b_remaining:0,
                c_remaining:0,
                d_remaining:0,
                e_remaining:0,
                f_remaining:0,
                g_remaining:0,
                h_remaining:0,
                a_process:null,
                b_process:null,
                c_process:null,
                d_process:null,
                e_process:null,
                f_process:null,
                g_process:null,
                h_process:null,
                a_next_process:null,
                b_next_process:null,
                c_next_process:null,
                d_next_process:null,
                e_next_process:null,
                f_next_process:null,
                g_next_process:null,
                h_next_process:null,
                a_next_subcon:null,
                b_next_subcon:null,
                c_next_subcon:null,
                d_next_subcon:null,
                e_next_subcon:null,
                f_next_subcon:null,
                g_next_subcon:null,
                h_next_subcon:null,
                a_code_process:null,
                b_code_process:null,
                c_code_process:null,
                d_code_process:null,
                e_code_process:null,
                f_code_process:null,
                g_code_process:null,
                h_code_process:null,
                total_receive:0,
                b_receive:[],
                d_receive:[],
                c_receive:[],
                e_receive:[],
                f_receive:[],
                g_receive:[],
                h_receive:[],
                process:null,
                send_date:null,
                send_qty:null,
                send_to:null,
                date_rec:null,
                rec_qty:null,
                end_date:null,
                start_date:null,
                subcon:null,
                status_rec:null,
                ord_process:null,
                ord_qty:null,
                cutting_progress:0,
                sewing_progress:0,
                finishing_progress:0,
                packing_progress:0,
                printing_progress:0,
                embroidery_progress:0,
                emblem_progress:0,
                washing_progress:0,
                boxTwo:'',
                inputDisabled:[],
                CloseDisabled:[],
                CompleteDisabled:[],
                re_cutting:null,
                a_ket:null,
                a_ket_detail:null,
                a_ket_last:null,
                a_ket_detail_last:null,

                b_ket:null,
                b_ket_detail:null,
                b_ket_last:null,
                b_ket_detail_last:null,

                c_ket:null,
                c_ket_detail:null,
                c_ket_last:null,
                c_ket_detail_last:null,

                d_ket:null,
                d_ket_detail:null,
                d_ket_last:null,
                d_ket_detail_last:null,

                e_ket:null,
                e_ket_detail:null,
                e_ket_last:null,
                e_ket_detail_last:null,

                f_ket:null,
                f_ket_detail:null,
                f_ket_last:null,
                f_ket_detail_last:null,

                g_ket:null,
                g_ket_detail:null,
                g_ket_last:null,
                g_ket_detail_last:null,

                h_ket:null,
                h_ket_detail:null,
                h_ket_last:null,
                h_ket_detail_last:null,

                keterangan:null,
                keterangan_detail:null,
                keterangan_last:null,
                keterangan_detail_last:null,
                cutting_actual_days:null,
                sewing_actual_days:null,
                finishing_actual_days:null,
                packing_actual_days:null,
                printing_actual_days:null,
                embroidery_actual_days:null,
                emblem_actual_days:null,
                washing_actual_days:null,
                completed_actual_days:null,
                close_process:null,
                close_date:null,
            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stIoPPM','stIoPPMCount','stMstSub','stIoSubconDaily','stPPCMInsDataCount','stPPCMInsData','StDataReasonCount','StDataReason']),

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getInputIOPPM','getSubconDailyData','getPPCMInspectionFormData','getDataReason']),
            ...mapActions('master_calender', ['GetDateCalculation']),
            FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 500);
                }
            },
            Setdata_IO() {
                let data_IO      = this.$route.query.io
                let userData     = this.userData
                this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'role', userData.role)
                if (userData.roles) {
                    for (let a=0; a<userData.roles.length; a++) {
                        if (userData.roles[a].app == 'PPCM') {
                            this.form.role = userData.roles[a].role_list
                        }
                    }
                }
                // this.form.role == this.form.role.toLowerCase()
                // console.log(this.form.role);
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardSubcon'})
                }
                if (this.form.data_io != null) {
                    this.getSubconDailyData(this.form.data_io).then().catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardSubcon"})
                        }
                    })
                    this.getPPCMInspectionFormData(this.form.data_io).then().catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardSubcon"})
                        }
                    })
                    this.Loading('start')
                    this.getInputIOPPM(this.form.data_io).then(
                        this.GetdataForm()
                    ).catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardSubcon"})
                        }
                    })
                    .finally(()=>{
                        this.Loading('stop')
                    })
                }

            },
            CalcActualDays(end, start, shift, param1){
                shift = 1
            this.GetDateCalculation({
                is_iterate: true,
                shift_id:shift,
                params:[{
                    calc_type: "duration",
                    start_date: start,
                    end_date: end,
            }]
            })
            .then(rsp => {
                console.log('param:  '+param1)
                console.log('start:  '+start)
                console.log('end:  '+end)
                console.log(rsp[0].duration)
                if (param1 == 'cutting') {
                    this.cutting_actual_days = rsp[0].duration
                }
                if (param1 == 'sewing') {
                    this.sewing_actual_days = rsp[0].duration
                }
                if (param1 == 'finishing') {
                    this.finishing_actual_days = rsp[0].duration
                }
                if (param1 == 'packing') {
                    this.packing_actual_days = rsp[0].duration
                }
                if (param1 == 'printing') {
                    this.printing_actual_days = rsp[0].duration
                }
                if (param1 == 'embroidery') {
                    this.embroidery_actual_days = rsp[0].duration
                }
                if (param1 == 'emblem') {
                    this.emblem_actual_days = rsp[0].duration
                }
                if (param1 == 'washing') {
                    this.washing_actual_days = rsp[0].duration
                }
            })
            .catch(err => {
                if(err.response.status !== 401) {
                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            })
            },
            SizeRemaining(value) {
                if (value < 0) {
                    return '0';
                } else {
                    return value
                }
            },
            ProcessRemaining(remaining, value) {
                let rem = parseInt(remaining)
                let val = parseInt(value)
                if (val >= 0) {
                    // val = val
                } else {
                    val = 0
                }
                let total = rem - val
                if (total <=0 ) {
                    total = 0;
                }
                return total
            },
            CalcSizeShow(last_value, total_value, value) {
                let last_val = parseInt(last_value)
                let total_val = parseInt(total_value)
                let val = parseInt(value)
                if (val >= 0) {
                    // val = val
                } else {
                    val = 0
                }
                let calc = (Math.abs(last_val - total_val))+val
                return calc
            },
            CalcShow(value) {
                if (value <= 0) {
                    value = 0
                }
                return value
            },
            CalcCuttingSize(val_1,val_2,val_3,val_4,val_5,val_6,val_7,val_8,val_9,val_10,val_11,val_12,val_13,val_14,val_15,val_16,val_17,val_18,val_19,val_20,val_21,val_22,val_23,val_24,val_25,val_26,val_27,val_28,val_29,val_30) {

                if (val_1 > 0) { val_1 = parseInt(val_1); } else { val_1 = 0; }
                if (val_2 > 0) { val_2 = parseInt(val_2); } else { val_2 = 0; }
                if (val_3 > 0) { val_3 = parseInt(val_3); } else { val_3 = 0; }
                if (val_4 > 0) { val_4 = parseInt(val_4); } else { val_4 = 0; }
                if (val_5 > 0) { val_5 = parseInt(val_5); } else { val_5 = 0; }
                if (val_6 > 0) { val_6 = parseInt(val_6); } else { val_6 = 0; }
                if (val_7 > 0) { val_7 = parseInt(val_7); } else { val_7 = 0; }
                if (val_8 > 0) { val_8 = parseInt(val_8); } else { val_8 = 0; }
                if (val_9 > 0) { val_9 = parseInt(val_9); } else { val_9 = 0; }
                if (val_10 > 0) { val_10 = parseInt(val_10); } else { val_10 = 0; }
                if (val_11 > 0) { val_11 = parseInt(val_11); } else { val_11 = 0; }
                if (val_12 > 0) { val_12 = parseInt(val_12); } else { val_12 = 0; }
                if (val_13 > 0) { val_13 = parseInt(val_13); } else { val_13 = 0; }
                if (val_14 > 0) { val_14 = parseInt(val_14); } else { val_14 = 0; }
                if (val_15 > 0) { val_15 = parseInt(val_15); } else { val_15 = 0; }
                if (val_16 > 0) { val_16 = parseInt(val_16); } else { val_16 = 0; }
                if (val_17 > 0) { val_17 = parseInt(val_17); } else { val_17 = 0; }
                if (val_18 > 0) { val_18 = parseInt(val_18); } else { val_18 = 0; }
                if (val_19 > 0) { val_19 = parseInt(val_19); } else { val_19 = 0; }
                if (val_20 > 0) { val_20 = parseInt(val_20); } else { val_20 = 0; }
                if (val_21 > 0) { val_21 = parseInt(val_21); } else { val_21 = 0; }
                if (val_22 > 0) { val_22 = parseInt(val_22); } else { val_22 = 0; }
                if (val_23 > 0) { val_23 = parseInt(val_23); } else { val_23 = 0; }
                if (val_24 > 0) { val_24 = parseInt(val_24); } else { val_24 = 0; }
                if (val_25 > 0) { val_25 = parseInt(val_25); } else { val_25 = 0; }
                if (val_26 > 0) { val_26 = parseInt(val_26); } else { val_26 = 0; }
                if (val_27 > 0) { val_27 = parseInt(val_27); } else { val_27 = 0; }
                if (val_28 > 0) { val_28 = parseInt(val_28); } else { val_28 = 0; }
                if (val_29 > 0) { val_29 = parseInt(val_29); } else { val_29 = 0; }
                if (val_30 > 0) { val_30 = parseInt(val_30); } else { val_30 = 0; }

                let total = val_1+val_2+val_3+val_4+val_5+val_6+val_7+val_8+val_9+val_10+val_11+val_12+val_13+val_14+val_15+val_16+val_17+val_18+val_19+val_20+val_21+val_22+val_23+val_24+val_25+val_26+val_27+val_28+val_29+val_30
                this.totalInputCutting = total
                return total;
            },
            TotalQtyProcess(prog, rem, total) {
                let remaining  = ''
                let progress = prog;
                if (rem < 0) {
                    remaining = '(OVER '+Math.abs(rem)+')';
                }
                if (rem > 0) {
                    remaining = '(UNDER '+Math.abs(rem)+')';
                }
                let totalProcess = progress+' '+remaining
                return totalProcess
            },
            TotalQtyProcessCutting(prog, rem, total) {
                let remaining  = ''
                let progress = this.totalInputCutting
                let left_rem = rem - this.totalInputCutting
                if (left_rem <= 0) {
                    if (left_rem < 0) {
                        remaining = '(OVER '+Math.abs(left_rem)+')';
                        this.re_cutting = 'OVER '+Math.abs(left_rem);
                        progress = 0
                    }
                    if (left_rem == 0) {
                        progress = 0
                    }
                }
                if (left_rem > 0) {
                    remaining = '(UNDER '+Math.abs(left_rem)+')';
                    this.re_cutting = 'UNDER '+Math.abs(left_rem);
                }
                if (progress > rem) {
                    progress = rem
                }
                if (prog > total) {
                    rem = prog - total
                    left_rem = rem + this.totalInputCutting
                    remaining = '(OVER '+Math.abs(left_rem)+')';
                    this.re_cutting = 'OVER '+Math.abs(left_rem);
                    progress = 0
                }
                let totalProcess = progress+' '+remaining
                return totalProcess
            },
            checkStatReceive(param, value) {
                // console.log('param: '+param)
                // console.log('value: '+value)
                if (param == 'Open') {
                    this.CloseDisabled[value] = true
                    this.CompleteDisabled[value] = true
                }
                return param;
            },
            TotalQty(param) {
                let count = 0;
                let char = [];
                let ord_size = [];
                if (param == null || param == '' || param == undefined) {
                    this.form.total_qty = this.stIoPPM[0].total_qty;
                } else {
                for (let size = 0;size<param.length; size++) {
                    if (param.charAt(size) == '(') {
                        char[count] = ((param.substring(size + 1,size+6)).split(")")[0]);
                    }
                    if (param.charAt(size) == ')') {
                        ord_size[count] = ((param.substring(size + 2,size+6)).split(/[|¦]+/)[0]);
                        count++;
                        this.count_size = count
                    }
                }
                // console.log('param: '+char);
                // console.log('count: '+count);
                // console.log('ord_size: '+ord_size);
                if (count>=1) {
                    this.b_size_1 = ord_size[0];
                    this.name_size_1 = char[0];
                }
                if (count>=2) {
                    this.b_size_2 = ord_size[1];
                    this.name_size_2 = char[1];
                }
                if (count>=3) {
                    this.b_size_3 = ord_size[2];
                    this.name_size_3 = char[2];
                }
                if (count>=4) {
                    this.b_size_4 = ord_size[3];
                    this.name_size_4 = char[3];
                }
                if (count>=5) {
                    this.b_size_5 = ord_size[4];
                    this.name_size_5 = char[4];
                }
                if (count>=6) {
                    this.b_size_6 = ord_size[5];
                    this.name_size_6 = char[5];
                }
                if (count>=7) {
                    this.b_size_7 = ord_size[6];
                    this.name_size_7 = char[6];
                }
                if (count>=8) {
                    this.b_size_8 = ord_size[7];
                    this.name_size_8 = char[7];
                }
                if (count>=9) {
                    this.b_size_9 = ord_size[8];
                    this.name_size_9 = char[8];
                }
                if (count>=10) {
                    this.b_size_10 = ord_size[9];
                    this.name_size_10 = char[9];
                }
                if (count>=11) {
                    this.b_size_11 = ord_size[10];
                    this.name_size_11 = char[10];
                }
                if (count>=12) {
                    this.b_size_12 = ord_size[11];
                    this.name_size_12 = char[11];
                }
                if (count>=13) {
                    this.b_size_13 = ord_size[12];
                    this.name_size_13 = char[12];
                }
                if (count>=14) {
                    this.b_size_14 = ord_size[13];
                    this.name_size_14 = char[13];
                }
                if (count>=15) {
                    this.b_size_15 = ord_size[14];
                    this.name_size_15 = char[14];
                }
                if (count>=16) {
                    this.b_size_16 = ord_size[15];
                    this.name_size_16 = char[15];
                }
                if (count>=17) {
                    this.b_size_17 = ord_size[16];
                    this.name_size_17 = char[16];
                }
                if (count>=18) {
                    this.b_size_18 = ord_size[17];
                    this.name_size_18 = char[17];
                }
                if (count>=19) {
                    this.b_size_19 = ord_size[18];
                    this.name_size_19 = char[18];
                }
                if (count>=20) {
                    this.b_size_20 = ord_size[19];
                    this.name_size_20 = char[19];
                }
                if (count>=21) {
                    this.b_size_21 = ord_size[20];
                    this.name_size_21 = char[20];
                }
                if (count>=22) {
                    this.b_size_22 = ord_size[21];
                    this.name_size_22 = char[21];
                }
                if (count>=23) {
                    this.b_size_23 = ord_size[22];
                    this.name_size_23 = char[22];
                }
                if (count>=24) {
                    this.b_size_24 = ord_size[23];
                    this.name_size_24 = char[23];
                }
                if (count>=25) {
                    this.b_size_25 = ord_size[24];
                    this.name_size_25 = char[24];
                }
                if (count>=26) {
                    this.b_size_26 = ord_size[25];
                    this.name_size_26 = char[25];
                }
                if (count>=27) {
                    this.b_size_27 = ord_size[26];
                    this.name_size_27 = char[26];
                }
                if (count>=28) {
                    this.b_size_28 = ord_size[27];
                    this.name_size_28 = char[27];
                }
                if (count>=29) {
                    this.b_size_29 = ord_size[28];
                    this.name_size_29 = char[28];
                }
                if (count>=30) {
                    this.b_size_30 = ord_size[29];
                    this.name_size_30 = char[29];
                }
                if (count>=31) {
                    this.b_size_31 = ord_size[30];
                    this.name_size_31 = char[30];
                }
                if (count>=32) {
                    this.b_size_32 = ord_size[31];
                    this.name_size_32 = char[31];
                }
                if (count>=33) {
                    this.b_size_33 = ord_size[32];
                    this.name_size_33 = char[32];
                }
                if (count>=34) {
                    this.b_size_34 = ord_size[33];
                    this.name_size_34 = char[33];
                }
                if (count>=35) {
                    this.b_size_35 = ord_size[34];
                    this.name_size_35 = char[34];
                }

                }
            },
            TotalQtySize(param) {
                let count = 0;
                let char = [];
                let ord_size = [];
                if (param == null || param == '' || param == undefined) {
                    // this.form.total_qty = this.stIoPPM[0].total_qty;
                } else {
                for (let size = 0;size<param.length; size++) {
                    if (param.charAt(size) == '(') {
                        char[count] = ((param.substring(size + 1,size+6)).split(")")[0]);
                    }
                    if (param.charAt(size) == ')') {
                        ord_size[count] = ((param.substring(size + 2,size+6)).split(/[|¦]+/)[0]);
                        count++;
                    }
                }
                // console.log('param: '+char);
                // console.log('count: '+count);
                // console.log('ord_size: '+ord_size);
                if (count>=1) {
                    this.rule_size_1 = ord_size[0];
                }
                if (count>=2) {
                    this.rule_size_2 = ord_size[1];
                }
                if (count>=3) {
                    this.rule_size_3 = ord_size[2];
                }
                if (count>=4) {
                    this.rule_size_4 = ord_size[3];
                }
                if (count>=5) {
                    this.rule_size_5 = ord_size[4];
                }
                if (count>=6) {
                    this.rule_size_6 = ord_size[5];
                }
                if (count>=7) {
                    this.rule_size_7 = ord_size[6];
                }
                if (count>=8) {
                    this.rule_size_8 = ord_size[7];
                }
                if (count>=9) {
                    this.rule_size_9 = ord_size[8];
                }
                if (count>=10) {
                    this.rule_size_10 = ord_size[9];
                }
                if (count>=11) {
                    this.rule_size_11 = ord_size[10];
                }
                if (count>=12) {
                    this.rule_size_12 = ord_size[11];
                }
                if (count>=13) {
                    this.rule_size_13 = ord_size[12];
                }
                if (count>=14) {
                    this.rule_size_14 = ord_size[13];
                }
                if (count>=15) {
                    this.rule_size_15 = ord_size[14];
                }
                if (count>=16) {
                    this.rule_size_16 = ord_size[15];
                }
                if (count>=17) {
                    this.rule_size_17 = ord_size[16];
                }
                if (count>=18) {
                    this.rule_size_18 = ord_size[17];
                }
                if (count>=19) {
                    this.rule_size_19 = ord_size[18];
                }
                if (count>=20) {
                    this.rule_size_20 = ord_size[19];
                }
                if (count>=21) {
                    this.rule_size_21 = ord_size[20];
                }
                if (count>=22) {
                    this.rule_size_22 = ord_size[21];
                }
                if (count>=23) {
                    this.rule_size_23 = ord_size[22];
                }
                if (count>=24) {
                    this.rule_size_24 = ord_size[23];
                }
                if (count>=25) {
                    this.rule_size_25 = ord_size[24];
                }
                if (count>=26) {
                    this.rule_size_26 = ord_size[25];
                }
                if (count>=27) {
                    this.rule_size_27 = ord_size[26];
                }
                if (count>=28) {
                    this.rule_size_28 = ord_size[27];
                }
                if (count>=29) {
                    this.rule_size_29 = ord_size[28];
                }
                if (count>=30) {
                    this.rule_size_30 = ord_size[29];
                }
                if (count>=31) {
                    this.rule_size_31 = ord_size[30];
                }
                if (count>=32) {
                    this.rule_size_32 = ord_size[31];
                }
                if (count>=33) {
                    this.rule_size_33 = ord_size[32];
                }
                if (count>=34) {
                    this.rule_size_34 = ord_size[33];
                }
                if (count>=35) {
                    this.rule_size_35 = ord_size[34];
                }

                }

            },
            CalcTotalPlan(totalQty,target) {
                let calc = (((totalQty/target) - Math.floor(totalQty/target))*10)
                if (calc >= 3) {
                    calc = Math.ceil(totalQty/target)
                } else {
                    calc = Math.floor(totalQty/target)
                    if (calc == 0) {
                    calc = 1
                    }
                }
                return calc
            },
            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stIoPPMCount == 0 || this.stIoPPMCount == null) {
                    this.DelayGetData()
                    return
                }
                if (this.stPPCMInsDataCount == 0 || this.stPPCMInsDataCount == null) {
                    this.DelayGetData()
                    return
                }
                // console.log(this.CloseDisabled)
                // console.log(this.CompleteDisabled)
                let formdata = this.stIoPPM
                let forminsData = this.stPPCMInsData

                this.date_today = this.FormatDate(this.today)
                this.form.visit_inline = forminsData[0].visit_inline;

                this.form.visit_final = forminsData[0].visit_final;

                this.form.status = formdata[0].status
                this.form.artikel = formdata[0].artikel
                this.form.sample = formdata[0].sample
                this.form.brand = formdata[0].brand
                this.form.subbrand = formdata[0].sub_brand
                if (formdata[0].manufacture_date !== null) {
                    this.form.manufacture_date = this.FormatDate(formdata[0].manufacture_date)
                }
                this.form.cutting_date = formdata[0].cutting_date
                if (formdata[0].due_date !== null) {
                    this.form.due_date = this.FormatDate(formdata[0].due_date)
                }
                this.form.cutting_id = formdata[0].cutting_id
                this.form.cutting_subcon = formdata[0].cutting_subcon
                this.form.cutting_target = formdata[0].cutting_target
                this.form.cutting_date = formdata[0].cutting_date
                this.form.cutting_end_date = formdata[0].cutting_end_date



                this.cutting_progress = formdata[0].cutting_progress
                if (this.cutting_progress == null) {
                    this.cutting_progress = 0;
                }
                this.form.embroidery_id = formdata[0].embroidery_id
                this.form.embroidery_subcon = formdata[0].embroidery_subcon
                this.form.embroidery_target = formdata[0].embroidery_target

                this.form.embroidery_date = formdata[0].embroidery_date
                this.form.embroidery_end_date = formdata[0].embroidery_end_date

                this.embroidery_progress = formdata[0].embroidery_progress
                if (this.embroidery_progress == null) {
                    this.embroidery_progress = 0;
                }
                this.form.printing_id = formdata[0].printing_id
                this.form.printing_subcon = formdata[0].printing_subcon
                this.form.printing_target = formdata[0].printing_target

                this.form.printing_date = formdata[0].printing_date
                this.form.printing_end_date = formdata[0].printing_end_date

                this.printing_progress = formdata[0].printing_progress
                if (this.printing_progress == null) {
                    this.printing_progress = 0;
                }

                this.form.emblem_id = formdata[0].emblem_id
                this.form.emblem_subcon = formdata[0].emblem_subcon
                this.form.emblem_target = formdata[0].emblem_target
                this.form.emblem_date = formdata[0].emblem_date
                this.form.emblem_end_date = formdata[0].emblem_end_date

                this.emblem_progress = formdata[0].emblem_progress
                if (this.emblem_progress == null) {
                    this.emblem_progress = 0;
                }

                this.form.sewing_id = formdata[0].sewing_id
                this.form.sewing_subcon = formdata[0].sewing_subcon
                this.form.sewing_target = formdata[0].sewing_target
                this.form.sewing_date = formdata[0].sewing_date
                this.form.sewing_end_date = formdata[0].sewing_end_date

                this.sewing_progress = formdata[0].sewing_progress
                if (this.sewing_progress == null) {
                    this.sewing_progress = 0;
                }

                this.form.washing_id = formdata[0].washing_id
                this.form.washing_subcon = formdata[0].washing_subcon
                this.form.washing_target = formdata[0].washing_target
                this.form.washing_date = formdata[0].washing_date
                this.form.washing_end_date = formdata[0].washing_end_date

                this.washing_progress = formdata[0].washing_progress
                if (this.washing_progress == null) {
                    this.washing_progress = 0;
                }

                this.form.packing_id = formdata[0].packing_id
                this.form.packing_subcon = formdata[0].packing_subcon
                this.form.packing_target = formdata[0].packing_target
                this.form.packing_date = formdata[0].packing_date
                this.form.packing_end_date = formdata[0].packing_end_date

                this.packing_progress = formdata[0].packing_progress
                this.packingBox = formdata[0].packing_box
                if (this.packing_progress == null) {
                    this.packing_progress = 0;
                }
                if (this.packingBox == null) {
                    this.packingBox = 0;
                }
                this.form.finishing_id = formdata[0].finishing_id
                this.form.finishing_subcon = formdata[0].finishing_subcon
                this.form.finishing_target = formdata[0].finishing_target
                this.form.finishing_date = formdata[0].finishing_date
                this.form.finishing_end_date = formdata[0].finishing_end_date

                this.finishing_progress = formdata[0].finishing_progress
                if (this.finishing_progress == null) {
                    this.finishing_progress = 0;
                }

                this.process_order = formdata[0].order_process;
                //console.log(this.process_order);
                this.dataOrderProcess();
                if (this.form.cutting_end_date == null && this.form.cutting_date != null) {
                    this.CalcActualDays(this.today, this.form.cutting_date, this.form.cutting_id, 'cutting')
                }
                if (this.form.cutting_end_date != null) {
                    console.log('cutting end date')
                    this.CalcActualDays(this.form.cutting_end_date, this.form.cutting_date, this.form.cutting_id, 'cutting')
                }
                if (this.form.sewing_end_date == null && this.form.sewing_date != null) {
                    this.CalcActualDays(this.today, this.form.sewing_date, this.form.sewing_id, 'sewing')
                }
                if (this.form.sewing_end_date != null) {
                    this.CalcActualDays(this.form.sewing_end_date, this.form.sewing_date, this.form.sewing_id, 'sewing')
                }
                if (this.form.finishing_end_date == null && this.form.finishing_date != null) {
                    this.CalcActualDays(this.today, this.form.finishing_date, this.form.finishing_id, 'finishing')
                }
                if (this.form.finishing_end_date != null) {
                    this.CalcActualDays(this.form.finishing_end_date, this.form.finishing_date, this.form.finishing_id, 'finishing')
                }
                if (this.form.packing_end_date == null && this.form.packing_date != null) {
                    this.CalcActualDays(this.today, this.form.packing_date, this.form.packing_id, 'packing')
                }
                if (this.form.packing_end_date != null) {
                    this.CalcActualDays(this.form.packing_end_date, this.form.packing_date, this.form.packing_id, 'packing')
                }
                if (this.form.printing_end_date == null && this.form.printing_date != null) {
                    this.CalcActualDays(this.today, this.form.printing_date, this.form.printing_id, 'printing')
                }
                if (this.form.printing_end_date != null) {
                    this.CalcActualDays(this.form.printing_end_date, this.form.printing_date, this.form.printing_id, 'printing')
                }
                if (this.form.embroidery_end_date == null && this.form.embroidery_date != null) {
                    this.CalcActualDays(this.today, this.form.embroidery_date, this.form.embroidery_id, 'embroidery')
                }
                if (this.form.embroidery_end_date != null) {
                    this.CalcActualDays(this.form.embroidery_end_date, this.form.embroidery_date, this.form.embroidery_id, 'embroidery')
                }
                if (this.form.emblem_end_date == null && this.form.emblem_date != null) {
                    this.CalcActualDays(this.today, this.form.emblem_date, this.form.emblem_id, 'emblem')
                }
                if (this.form.emblem_end_date != null) {
                    this.CalcActualDays(this.form.emblem_end_date, this.form.emblem_date, this.form.emblem_id, 'emblem')
                }
                if (this.form.washing_end_date == null && this.form.washing_date != null) {
                    this.CalcActualDays(this.today, this.form.washing_date, this.form.washing_id, 'washing')
                }
                if (this.form.washing_end_date != null) {
                    this.CalcActualDays(this.form.washing_end_date, this.form.washing_date, this.form.washing_id, 'washing')
                }
                let datasubcondaily = this.stIoSubconDaily
                this.form.total_qty = this.stIoPPM[0].total_qty;
                // console.log('total_qty:'+this.form.total_qty)

                if ((this.form.role.includes('subcon') && this.form.cutting_subcon !== this.form.Employee)) {
                    this.arraydisabled[0] = true;
                    let order_process = document.getElementById("process0-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[0] = false;
                }
                if ((this.form.role.includes('subcon') && this.form.sewing_subcon !== this.form.Employee)) {
                    this.arraydisabled[1] = true;
                    let order_process = document.getElementById("process1-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[1] = false;
                }
                if ((this.form.role.includes('subcon') && this.form.finishing_subcon !== this.form.Employee)) {
                    this.arraydisabled[2] = true;
                    let order_process = document.getElementById("process2-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[2] = false;
                }
                if ((this.form.role.includes('subcon') && this.form.packing_subcon !== this.form.Employee)) {
                    this.arraydisabled[3] = true;
                    let order_process = document.getElementById("process3-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[3] = false;
                }
                if ((this.form.role.includes('subcon') && this.form.printing_subcon !== this.form.Employee)) {
                    this.arraydisabled[4] = true;
                    let order_process = document.getElementById("process4-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[4] = false;
                }
                if ((this.form.role.includes('subcon') && this.form.embroidery_subcon !== this.form.Employee)) {
                    this.arraydisabled[5] = true;
                    let order_process = document.getElementById("process5-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[5] = false;
                }
                if ((this.form.role.includes('subcon') && this.form.emblem_subcon !== this.form.Employee)) {
                    this.arraydisabled[6] = true;
                    let order_process = document.getElementById("process6-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[6] = false;
                }
                if ((this.form.role.includes('subcon') && this.form.washing_subcon !== this.form.Employee)) {
                    this.arraydisabled[7] = true;
                    let order_process = document.getElementById("process7-button");
                    order_process.classList.add("other");
                } else {
                    this.arraydisabled[7] = false;
                }

                let a = 0;
                let length = datasubcondaily.length-1;

                if (this.process_order == '' || this.process_order == null) {
                    //console.log('process: '+this.process_order)
                } else {
                    for (a=0;a<=length;a++) {
                        if (datasubcondaily[a].order_process == 'a') {
                            this.a_process = 'Cutting';
                            this.a_code_process = datasubcondaily[a].code_process;
                            if (datasubcondaily[a].ket !== null && datasubcondaily[a].status == 'Close') {
                                this.a_ket = datasubcondaily[a].ket
                                this.a_ket_last = datasubcondaily[a].ket
                                this.a_ket_detail = datasubcondaily[a].ket_detail
                                this.a_ket_detail_last = datasubcondaily[a].ket_detail
                            }

                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[0] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            } else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                    // console.log('a'+a+this.a_code_process)
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[0] = true;
                                this.CloseDisabled[0] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                            }

                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                                if (datasubcondaily[a].order_qty != null) {
                                    this.a_remaining = datasubcondaily[a].remaining
                                    if (this.a_remaining < 0) {
                                        this.a_remaining ='0'
                                    }
                                    this.a_total_actual = datasubcondaily[a].total_qty
                                }
                            }

                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.a_next_process = 'Cutting'
                                this.a_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.a_next_process = 'Sewing'
                                this.a_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.a_next_process = 'Finishing'
                                this.a_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.a_next_process = 'Packing'
                                this.a_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.a_next_process = 'Print'
                                this.a_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.a_next_process = 'Embroidery'
                                this.a_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.a_next_process = 'Emblem'
                                this.a_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.a_next_process = 'Washing'
                                this.a_next_subcon = this.form.washing_subcon
                            }
                            let one_qty_order = datasubcondaily[a].order_qty
                            let two_qty_order = formdata[0].qty_order
                            this.TotalQtySize(two_qty_order)
                            if (one_qty_order == null || one_qty_order == '' || one_qty_order == 0 || one_qty_order == undefined) {
                                // console.log('statys'+datasubcondaily[a].status);
                                if (datasubcondaily[a].status == null || datasubcondaily[a].status == 'Close') {
                                    // console.log('two_qty_order'+two_qty_order)
                                } else {
                                    this.TotalQty(two_qty_order)
                                    // console.log('two_qty_order'+two_qty_order)
                                }
                            } else {
                                this.TotalQty(one_qty_order)
                                // console.log('one_qty_order'+one_qty_order)
                            }
                        }
                        if (datasubcondaily[a].order_process == 'b') {
                            this.b_process = 'Sewing';
                            this.b_code_process = datasubcondaily[a].code_process;
                            if (datasubcondaily[a].ket !== null && datasubcondaily[a].status == 'Close') {
                                this.b_ket = datasubcondaily[a].ket
                                this.b_ket_last = datasubcondaily[a].ket
                                this.b_ket_detail = datasubcondaily[a].ket_detail
                                this.b_ket_detail_last = datasubcondaily[a].ket_detail
                            }
                    // console.log('b'+this.b_code_process)
                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[1] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            }else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[1] = true;
                                this.CloseDisabled[1] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                            this.b_remaining = datasubcondaily[a].remaining
                            this.b_total_actual =datasubcondaily[a].total_qty
                            }
                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.b_next_process = 'Cutting'
                                this.b_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.b_next_process = 'Sewing'
                                this.b_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.b_next_process = 'Finishing'
                                this.b_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.b_next_process = 'Packing'
                                this.b_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.b_next_process = 'Print'
                                this.b_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.b_next_process = 'Embroidery'
                                this.b_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.b_next_process = 'Emblem'
                                this.b_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.b_next_process = 'Washing'
                                this.b_next_subcon = this.form.washing_subcon
                            }

                        }
                        if (datasubcondaily[a].order_process == 'c') {
                            this.c_process = 'Finishing';
                            this.c_code_process = datasubcondaily[a].code_process;
                            if (datasubcondaily[a].ket !== null && datasubcondaily[a].status == 'Close') {
                                this.c_ket = datasubcondaily[a].ket
                                this.c_ket_last = datasubcondaily[a].ket
                                this.c_ket_detail = datasubcondaily[a].ket_detail
                                this.c_ket_detail_last = datasubcondaily[a].ket_detail
                            }
                    // console.log('c'+this.c_code_process)
                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[2] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            } else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[2] = true;
                                this.CloseDisabled[2] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                            this.c_remaining = datasubcondaily[a].remaining
                            this.c_total_actual =datasubcondaily[a].total_qty
                            }
                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.c_next_process = 'Cutting'
                                this.c_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.c_next_process = 'Sewing'
                                this.c_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.c_next_process = 'Finishing'
                                this.c_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.c_next_process = 'Packing'
                                this.c_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.c_next_process = 'Print'
                                this.c_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.c_next_process = 'Embroidery'
                                this.c_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.c_next_process = 'Emblem'
                                this.c_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.c_next_process = 'Washing'
                                this.c_next_subcon = this.form.washing_subcon
                            }
                        }
                        if (datasubcondaily[a].order_process == 'd') {
                            this.d_process = 'Packing';
                            this.d_code_process = datasubcondaily[a].code_process;
                            // if (datasubcondaily[a].ket == '' || datasubcondaily[a].ket == null) {
                            //     this.d_ket = null
                            //     this.d_ket_last = null
                            //     this.d_ket_detail = null
                            //     this.d_ket_detail_last = null
                            // } else {
                            //     this.d_ket = datasubcondaily[a].ket
                            //     this.d_ket_last = datasubcondaily[a].ket
                            //     this.d_ket_detail = datasubcondaily[a].ket_detail
                            //     this.d_ket_detail_last = datasubcondaily[a].ket_detail
                            // }
                    // console.log('d'+this.d_code_process)
                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[3] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            } else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[3] = true;
                                this.CloseDisabled[3] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                                this.d_ket = datasubcondaily[a].ket
                                this.d_ket_last = datasubcondaily[a].ket
                                this.d_ket_detail = datasubcondaily[a].ket_detail
                                this.d_ket_detail_last = datasubcondaily[a].ket_detail
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                            this.d_remaining = datasubcondaily[a].remaining
                            this.d_total_actual =datasubcondaily[a].total_qty
                            }
                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.d_next_process = 'Cutting'
                                this.d_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.d_next_process = 'Sewing'
                                this.d_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.d_next_process = 'Finishing'
                                this.d_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.d_next_process = 'Packing'
                                this.d_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.d_next_process = 'Print'
                                this.d_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.d_next_process = 'Embroidery'
                                this.d_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.d_next_process = 'Emblem'
                                this.d_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.d_next_process = 'Washing'
                                this.d_next_subcon = this.form.washing_subcon
                            }
                        }
                        if (datasubcondaily[a].order_process == 'e') {
                            this.e_process = 'Print';
                            this.e_code_process = datasubcondaily[a].code_process;
                            // if (datasubcondaily[a].ket == '' || datasubcondaily[a].ket == null) {
                            //     this.e_ket = null
                            //     this.e_ket_last = null
                            //     this.e_ket_detail = null
                            //     this.e_ket_detail_last = null
                            // } else {
                            //     this.e_ket = datasubcondaily[a].ket
                            //     this.e_ket_detail = datasubcondaily[a].ket_detail
                            //     this.e_ket_last = datasubcondaily[a].ket
                            //     this.e_ket_detail_last = datasubcondaily[a].ket_detail
                            // }
                    // console.log('e'+this.e_code_process)
                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[4] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            } else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[4] = true;
                                this.CloseDisabled[4] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                                this.e_ket = datasubcondaily[a].ket
                                this.e_ket_last = datasubcondaily[a].ket
                                this.e_ket_detail = datasubcondaily[a].ket_detail
                                this.e_ket_detail_last = datasubcondaily[a].ket_detail
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                            this.e_remaining = datasubcondaily[a].remaining
                            this.e_total_actual =datasubcondaily[a].total_qty
                            }
                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.e_next_process = 'Cutting'
                                this.e_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.e_next_process = 'Sewing'
                                this.e_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.e_next_process = 'Finishing'
                                this.e_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.e_next_process = 'Packing'
                                this.e_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.e_next_process = 'Print'
                                this.e_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.e_next_process = 'Embroidery'
                                this.e_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.e_next_process = 'Emblem'
                                this.e_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.e_next_process = 'Washing'
                                this.e_next_subcon = this.form.washing_subcon
                            }
                        }
                        if (datasubcondaily[a].order_process == 'f') {
                            this.f_process = 'Embroidery';
                            this.f_code_process = datasubcondaily[a].code_process;
                            // if (datasubcondaily[a].ket == '' || datasubcondaily[a].ket == null) {
                            //     this.f_ket = null
                            //     this.f_ket_detail = null
                            //     this.f_ket_last = null
                            //     this.f_ket_detail_last = null
                            // } else {
                            //     this.f_ket = datasubcondaily[a].ket
                            //     this.f_ket_detail = datasubcondaily[a].ket_detail
                            //     this.f_ket_last = datasubcondaily[a].ket
                            //     this.f_ket_detail_last = datasubcondaily[a].ket_detail
                            // }
                    // console.log('f'+this.f_code_process)
                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[5] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            }else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[5] = true;
                                this.CloseDisabled[5] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                                this.f_ket = datasubcondaily[a].ket
                                this.f_ket_last = datasubcondaily[a].ket
                                this.f_ket_detail = datasubcondaily[a].ket_detail
                                this.f_ket_detail_last = datasubcondaily[a].ket_detail
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                            this.f_remaining = datasubcondaily[a].remaining
                            this.f_total_actual =datasubcondaily[a].total_qty
                            }
                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.f_next_process = 'Cutting'
                                this.f_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.f_next_process = 'Sewing'
                                this.f_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.f_next_process = 'Finishing'
                                this.f_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.f_next_process = 'Packing'
                                this.f_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.f_next_process = 'Print'
                                this.f_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.f_next_process = 'Embroidery'
                                this.f_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.f_next_process = 'Emblem'
                                this.f_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.f_next_process = 'Washing'
                                this.f_next_subcon = this.form.washing_subcon
                            }
                        }
                        if (datasubcondaily[a].order_process == 'g') {
                            this.g_process = 'Emblem';
                            this.g_code_process = datasubcondaily[a].code_process;
                            // if (datasubcondaily[a].ket == '' || datasubcondaily[a].ket == null) {
                            //     this.g_ket = null
                            //     this.g_ket_detail = null
                            //     this.g_ket_last = null
                            //     this.g_ket_detail_last = null
                            // } else {
                            //     this.g_ket = datasubcondaily[a].ket
                            //     this.g_ket_detail = datasubcondaily[a].ket_detail
                            //     this.g_ket_last = datasubcondaily[a].ket
                            //     this.g_ket_detail_last = datasubcondaily[a].ket_detail
                            // }
                    // console.log('g'+this.g_code_process)
                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[6] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            } else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[6] = true;
                                this.CloseDisabled[6] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                                this.g_ket = datasubcondaily[a].ket
                                this.g_ket_last = datasubcondaily[a].ket
                                this.g_ket_detail = datasubcondaily[a].ket_detail
                                this.g_ket_detail_last = datasubcondaily[a].ket_detail
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                            this.g_remaining = datasubcondaily[a].remaining
                            this.g_total_actual =datasubcondaily[a].total_qty
                            }
                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.g_next_process = 'Cutting'
                                this.g_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.g_next_process = 'Sewing'
                                this.g_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.g_next_process = 'Finishing'
                                this.g_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.g_next_process = 'Packing'
                                this.g_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.g_next_process = 'Print'
                                this.g_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.g_next_process = 'Embroidery'
                                this.g_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.g_next_process = 'Emblem'
                                this.g_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.g_next_process = 'Washing'
                                this.g_next_subcon = this.form.washing_subcon
                            }
                        }
                        if (datasubcondaily[a].order_process == 'h') {
                            this.h_process = 'Washing';
                            this.h_code_process = datasubcondaily[a].code_process;
                            // if (datasubcondaily[a].ket == '' || datasubcondaily[a].ket == null) {
                            //     this.h_ket = null
                            //     this.h_ket_detail = null
                            //     this.h_ket_last = null
                            //     this.h_ket_detail_last = null
                            // } else {
                            //     this.h_ket = datasubcondaily[a].ket
                            //     this.h_ket_detail = datasubcondaily[a].ket_detail
                            //     this.h_ket_last = datasubcondaily[a].ket
                            //     this.h_ket_detail_last = datasubcondaily[a].ket_detail
                            // }
                    // console.log('h'+this.h_code_process)
                            if (datasubcondaily[a].status == 'Complete') {
                                this.arraydisabled[7] = true;
                                this.comp[datasubcondaily[a].process] = 'Complete'
                            } else {
                                if (datasubcondaily[a].status != null) {
                                    this.comp[datasubcondaily[a].process] = 'Open'
                                }
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Complete') {
                                this.inputDisabled[7] = true;
                                this.CloseDisabled[7] = true;
                                this.cls[datasubcondaily[a].process] = 'Close'
                                this.h_ket = datasubcondaily[a].ket
                                this.h_ket_last = datasubcondaily[a].ket
                                this.h_ket_detail = datasubcondaily[a].ket_detail
                                this.h_ket_detail_last = datasubcondaily[a].ket_detail
                            }
                            if (datasubcondaily[a].status == 'Close' || datasubcondaily[a].status == 'Open') {
                            this.h_remaining = datasubcondaily[a].remaining
                            this.h_total_actual =datasubcondaily[a].total_qty
                            }
                            let next_process = datasubcondaily[a].next_process
                            if (next_process == 'a') {
                                this.h_next_process = 'Cutting'
                                this.h_next_subcon = this.form.cutting_subcon
                            }
                            if (next_process == 'b') {
                                this.h_next_process = 'Sewing'
                                this.h_next_subcon = this.form.sewing_subcon
                            }
                            if (next_process == 'c') {
                                this.h_next_process = 'Finishing'
                                this.h_next_subcon = this.form.finishing_subcon
                            }
                            if (next_process == 'd') {
                                this.h_next_process = 'Packing'
                                this.h_next_subcon = this.form.packing_subcon
                            }
                            if (next_process == 'e') {
                                this.h_next_process = 'Print'
                                this.h_next_subcon = this.form.printing_subcon
                            }
                            if (next_process == 'f') {
                                this.h_next_process = 'Embroidery'
                                this.h_next_subcon = this.form.embroidery_subcon
                            }
                            if (next_process == 'g') {
                                this.h_next_process = 'Emblem'
                                this.h_next_subcon = this.form.emblem_subcon
                            }
                            if (next_process == 'h') {
                                this.h_next_process = 'Washing'
                                this.h_next_subcon = this.form.washing_subcon
                            }
                        }
                    }
                }
                console.log(this.comp)
                console.log(this.CloseDisabled)
                console.log(this.CompleteDisabled)

            },

            dataOrderProcess() {
                let b = 1;
                let a = 0;
                let r = 0;
                if (this.process_order == '' || this.process_order == null) {
                    //console.log('process: '+this.process_order)
                } else {
                for (a=0;a<=7;a++) {
                if (this.process_order.slice(r, b) == 'a') {
                    this.form.process_order[a] = 'a';
                        this.AddProcess(0);
                }
                if (this.process_order.slice(r, b) == 'b') {
                    this.form.process_order[a] = 'b';
                        this.AddProcess(1);
                }
                if (this.process_order.slice(r, b) == 'c') {
                    this.form.process_order[a] = 'c';
                        this.AddProcess(2);
                }
                if (this.process_order.slice(r, b) == 'd') {
                    this.form.process_order[a] = 'd';
                        this.AddProcess(3);
                }
                if (this.process_order.slice(r, b) == 'e') {
                    this.form.process_order[a] = 'e';
                        this.AddProcess(4);
                }
                if (this.process_order.slice(r, b) == 'f') {
                    this.form.process_order[a] = 'f';
                        this.AddProcess(5);
                }
                if (this.process_order.slice(r, b) == 'g') {
                    this.form.process_order[a] = 'g';
                        this.AddProcess(6);
                }
                if (this.process_order.slice(r, b) == 'h') {
                    this.form.process_order[a] = 'h';
                        this.AddProcess(7);
                }
                b += 2;
                r += 2;
                //console.log('r'+r);
                //console.log('b'+b);
                //console.log(this.form.process_order);
                }
                }
            },
            AddProcess(value) {

                let order_process = document.getElementById("process"+value+"-button");
                let tab_button = document.getElementById("tab-button");
                if (order_process !== null) {
                    tab_button.appendChild(order_process);
                    order_process.classList.remove("hidden");
                }
            },
            openProcess(value) {
                var i, tabcontent, tablinks;
                tabcontent = document.getElementsByClassName("tabcontent");
                for (i = 0; i < tabcontent.length; i++) {
                    tabcontent[i].style.display = "none";
                }
                tablinks = document.getElementsByClassName("tablinks");
                for (i = 0; i < tablinks.length; i++) {
                    tablinks[i].className = tablinks[i].className.replace(" active", "");
                }
                document.getElementById(value).style.display = "block";
                document.getElementById(value+"-button").classList.add("active");


            },
            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
            Confirm(value) {
                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value1 => {
                    this.boxTwo = String(value1)
                    if (this.boxTwo == 'true') {
                        this.Save(value)
                    }
                    if (this.boxTwo == 'false' || this.boxTwo == 'null') {
                        this.closeProcess == null
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },
            Save(value) {
                let process = value;
                if (process == 'a') {
                    let total_qty = parseInt(this.a_total_actual) -
                                    parseInt(this.size_1) - parseInt(this.size_2) - parseInt(this.size_3) -
                                    parseInt(this.size_4) - parseInt(this.size_5) - parseInt(this.size_6) -
                                    parseInt(this.size_7) - parseInt(this.size_8) - parseInt(this.size_9) -
                                    parseInt(this.size_10) - parseInt(this.size_11) - parseInt(this.size_12) -
                                    parseInt(this.size_13) - parseInt(this.size_14) - parseInt(this.size_15) -
                                    parseInt(this.size_16) - parseInt(this.size_17) - parseInt(this.size_18) -
                                    parseInt(this.size_19) - parseInt(this.size_20) - parseInt(this.size_21) -
                                    parseInt(this.size_22) - parseInt(this.size_23) - parseInt(this.size_24) -
                                    parseInt(this.size_25) - parseInt(this.size_26) - parseInt(this.size_27) -
                                    parseInt(this.size_28) - parseInt(this.size_29) - parseInt(this.size_30) -
                                    parseInt(this.size_31) - parseInt(this.size_32) - parseInt(this.size_33) -
                                    parseInt(this.size_34) - parseInt(this.size_35)
                    this.total_actual = total_qty;
                    this.ttl_qty = this.stIoPPM[0].total_qty
                    this.status = 'Open';
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.size_1) + parseInt(this.size_2) + parseInt(this.size_3) +
                                    parseInt(this.size_4) + parseInt(this.size_5) + parseInt(this.size_6) +
                                    parseInt(this.size_7) + parseInt(this.size_8) + parseInt(this.size_9) +
                                    parseInt(this.size_10) + parseInt(this.size_11) + parseInt(this.size_12) +
                                    parseInt(this.size_13) + parseInt(this.size_14) + parseInt(this.size_15) +
                                    parseInt(this.size_16) + parseInt(this.size_17) + parseInt(this.size_18) +
                                    parseInt(this.size_19) + parseInt(this.size_20) + parseInt(this.size_21) +
                                    parseInt(this.size_22) + parseInt(this.size_23) + parseInt(this.size_24) +
                                    parseInt(this.size_25) + parseInt(this.size_26) + parseInt(this.size_27) +
                                    parseInt(this.size_28) + parseInt(this.size_29) + parseInt(this.size_30) +
                                    parseInt(this.size_31) + parseInt(this.size_32) + parseInt(this.size_33) +
                                    parseInt(this.size_34) + parseInt(this.size_35)
                    if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty) ) {
                        this.showNotification({type:'error', message:'The Quantity is still 0 or empty'})
                        return
                    }
                    console.log('this.send_qty::  '+this.send_qty)

                    if (parseInt(this.size_1) < 0 || parseInt(this.size_2) < 0  || parseInt(this.size_3) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_4) < 0 || parseInt(this.size_5) < 0  || parseInt(this.size_6) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_7) < 0 || parseInt(this.size_8) < 0  || parseInt(this.size_9) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_10) < 0 || parseInt(this.size_11) < 0  || parseInt(this.size_12) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_13) < 0 || parseInt(this.size_14) < 0  || parseInt(this.size_15) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_16) < 0 || parseInt(this.size_17) < 0  || parseInt(this.size_18) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_19) < 0 || parseInt(this.size_20) < 0  || parseInt(this.size_21) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_22) < 0 || parseInt(this.size_23) < 0  || parseInt(this.size_24) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_25) < 0 || parseInt(this.size_26) < 0  || parseInt(this.size_27) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_28) < 0 || parseInt(this.size_29) < 0  || parseInt(this.size_30) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_31) < 0 || parseInt(this.size_32) < 0  || parseInt(this.size_33) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    if (parseInt(this.size_34) < 0 || parseInt(this.size_35) < 0) {
                        this.showNotification({type:'error', message:'Please fill quantity size with correctly'})
                        return
                    }
                    this.remaining = parseInt(this.a_remaining) - parseInt(this.send_qty)
                    this.a_remaining = this.remaining
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'a') {
                            this.send_to = this.form.cutting_subcon
                            this.process = a;
                            this.process_ket = 'cutting'
                            this.keterangan = this.a_ket
                            this.keterangan_detail = this.a_ket_detail
                            this.keterangan_last = this.a_ket_last
                            this.keterangan_detail_last = this.a_ket_detail_last
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'b') {
                                    this.subcon = this.form.sewing_id;
                                }
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.b_size_1 = this.b_size_1 - this.size_1;
                            this.b_size_2 = this.b_size_2 - this.size_2;
                            this.b_size_3 = this.b_size_3 - this.size_3;
                            this.b_size_4 = this.b_size_4 - this.size_4;
                            this.b_size_5 = this.b_size_5 - this.size_5;
                            this.b_size_6 = this.b_size_6 - this.size_6;
                            this.b_size_7 = this.b_size_7 - this.size_7;
                            this.b_size_8 = this.b_size_8 - this.size_8;
                            this.b_size_9 = this.b_size_9 - this.size_9;
                            this.b_size_10 = this.b_size_10 - this.size_10;
                            this.b_size_11 = this.b_size_11 - this.size_11;
                            this.b_size_12 = this.b_size_12 - this.size_12;
                            this.b_size_13 = this.b_size_13 - this.size_13;
                            this.b_size_14 = this.b_size_14 - this.size_14;
                            this.b_size_15 = this.b_size_15 - this.size_15;
                            this.b_size_16 = this.b_size_16 - this.size_16;
                            this.b_size_17 = this.b_size_17 - this.size_17;
                            this.b_size_18 = this.b_size_18 - this.size_18;
                            this.b_size_19 = this.b_size_19 - this.size_19;
                            this.b_size_20 = this.b_size_20 - this.size_20;
                            this.b_size_21 = this.b_size_21 - this.size_21;
                            this.b_size_22 = this.b_size_22 - this.size_22;
                            this.b_size_23 = this.b_size_23 - this.size_23;
                            this.b_size_24 = this.b_size_24 - this.size_24;
                            this.b_size_25 = this.b_size_25 - this.size_25;
                            this.b_size_26 = this.b_size_26 - this.size_26;
                            this.b_size_27 = this.b_size_27 - this.size_27;
                            this.b_size_28 = this.b_size_28 - this.size_28;
                            this.b_size_29 = this.b_size_29 - this.size_29;
                            this.b_size_30 = this.b_size_30 - this.size_30;
                            this.b_size_31 = this.b_size_31 - this.size_31;
                            this.b_size_32 = this.b_size_32 - this.size_32;
                            this.b_size_33 = this.b_size_33 - this.size_33;
                            this.b_size_34 = this.b_size_34 - this.size_34;
                            this.b_size_35 = this.b_size_35 - this.size_35;
                            this.ord_qty ='';
                            if (this.count_size >= 1) {
                                this.ord_qty += '('+this.name_size_1+'):'+this.b_size_1
                            }
                            if (this.count_size >= 2) {
                                this.ord_qty += '|('+this.name_size_2+'):'+this.b_size_2
                            }
                            if (this.count_size >= 3) {
                                this.ord_qty += '|('+this.name_size_3+'):'+this.b_size_3
                            }
                            if (this.count_size >= 4) {
                                this.ord_qty += '|('+this.name_size_4+'):'+this.b_size_4
                            }
                            if (this.count_size >= 5) {
                                this.ord_qty += '|('+this.name_size_5+'):'+this.b_size_5
                            }
                            if (this.count_size >= 6) {
                                this.ord_qty += '|('+this.name_size_6+'):'+this.b_size_6
                            }
                            if (this.count_size >= 7) {
                                this.ord_qty += '|('+this.name_size_7+'):'+this.b_size_7
                            }
                            if (this.count_size >= 8) {
                                this.ord_qty += '|('+this.name_size_8+'):'+this.b_size_8
                            }
                            if (this.count_size >= 9) {
                                this.ord_qty += '|('+this.name_size_9+'):'+this.b_size_9
                            }
                            if (this.count_size >= 10) {
                                this.ord_qty += '|('+this.name_size_10+'):'+this.b_size_10
                            }
                            if (this.count_size >= 11) {
                                this.ord_qty += '|('+this.name_size_11+'):'+this.b_size_11
                            }
                            if (this.count_size >= 12) {
                                this.ord_qty += '|('+this.name_size_12+'):'+this.b_size_12
                            }
                            if (this.count_size >= 13) {
                                this.ord_qty += '|('+this.name_size_13+'):'+this.b_size_13
                            }
                            if (this.count_size >= 14) {
                                this.ord_qty += '|('+this.name_size_14+'):'+this.b_size_14
                            }
                            if (this.count_size >= 15) {
                                this.ord_qty += '|('+this.name_size_15+'):'+this.b_size_15
                            }
                            if (this.count_size >= 16) {
                                this.ord_qty += '|('+this.name_size_16+'):'+this.b_size_16
                            }
                            if (this.count_size >= 17) {
                                this.ord_qty += '|('+this.name_size_17+'):'+this.b_size_17
                            }
                            if (this.count_size >= 18) {
                                this.ord_qty += '|('+this.name_size_18+'):'+this.b_size_18
                            }
                            if (this.count_size >= 19) {
                                this.ord_qty += '|('+this.name_size_19+'):'+this.b_size_19
                            }
                            if (this.count_size >= 20) {
                                this.ord_qty += '|('+this.name_size_20+'):'+this.b_size_20
                            }
                            if (this.count_size >= 21) {
                                this.ord_qty += '|('+this.name_size_21+'):'+this.b_size_21
                            }
                            if (this.count_size >= 22) {
                                this.ord_qty += '|('+this.name_size_22+'):'+this.b_size_22
                            }
                            if (this.count_size >= 23) {
                                this.ord_qty += '|('+this.name_size_23+'):'+this.b_size_23
                            }
                            if (this.count_size >= 24) {
                                this.ord_qty += '|('+this.name_size_24+'):'+this.b_size_24
                            }
                            if (this.count_size >= 25) {
                                this.ord_qty += '|('+this.name_size_25+'):'+this.b_size_25
                            }
                            if (this.count_size >= 26) {
                                this.ord_qty += '|('+this.name_size_26+'):'+this.b_size_26
                            }
                            if (this.count_size >= 27) {
                                this.ord_qty += '|('+this.name_size_27+'):'+this.b_size_27
                            }
                            if (this.count_size >= 28) {
                                this.ord_qty += '|('+this.name_size_28+'):'+this.b_size_28
                            }
                            if (this.count_size >= 29) {
                                this.ord_qty += '|('+this.name_size_29+'):'+this.b_size_29
                            }
                            if (this.count_size >= 30) {
                                this.ord_qty += '|('+this.name_size_30+'):'+this.b_size_30
                            }
                            if (this.count_size >= 31) {
                                this.ord_qty += '|('+this.name_size_31+'):'+this.b_size_31
                            }
                            if (this.count_size >= 32) {
                                this.ord_qty += '|('+this.name_size_32+'):'+this.b_size_32
                            }
                            if (this.count_size >= 33) {
                                this.ord_qty += '|('+this.name_size_33+'):'+this.b_size_33
                            }
                            if (this.count_size >= 34) {
                                this.ord_qty += '|('+this.name_size_34+'):'+this.b_size_34
                            }
                            if (this.count_size >= 35) {
                                this.ord_qty += '|('+this.name_size_35+'):'+this.b_size_35
                            }
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)
                            // console.log(this.code_process);
                            this.SubmitSave();
                        }
                    }
                }
                if (process == 'b') {
                    this.process_ket = 'sewing'
                    let total_qty = parseInt(this.b_actual)
                    this.total_actual = total_qty;
                    this.ttl_qty = total_qty;
                    this.status = 'Open';
                    if (parseInt(this.b_remaining) < total_qty) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (total_qty < 0) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    this.remaining = parseInt(this.b_remaining) - parseInt(this.b_actual)
                    this.b_remaining = this.remaining
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.b_actual)
                     if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'b') {
                            this.send_to = this.form.sewing_subcon
                            this.process = a;
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)

                            this.keterangan = this.b_ket
                            this.keterangan_detail = this.b_ket_detail
                            this.keterangan_last = this.b_ket_last
                            this.keterangan_detail_last = this.b_ket_detail_last

                            this.SubmitSave();

                        }
                    }
                }
                if (process == 'c') {
                    this.process_ket = 'finishing'
                    let total_qty = parseInt(this.c_actual)
                    this.total_actual = total_qty;
                    this.ttl_qty = total_qty;
                    this.status = 'Open';
                    if (parseInt(this.c_remaining) < total_qty) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (total_qty < 0) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    this.remaining = parseInt(this.c_remaining) - parseInt(this.c_actual)
                    this.c_remaining = this.remaining
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.c_actual)
                     if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'c') {
                            this.send_to = this.form.finishing_subcon
                            this.process = a;
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)

                            this.keterangan = this.c_ket
                            this.keterangan_detail = this.c_ket_detail
                            this.keterangan_last = this.c_ket_last
                            this.keterangan_detail_last = this.c_ket_detail_last
                            this.SubmitSave();

                        }
                    }
                }
                if (process == 'd') {
                    this.process_ket = 'packing'
                    let total_qty = parseInt(this.d_actual)
                    this.total_actual = total_qty;
                    this.ttl_qty = total_qty;
                    this.status = 'Open';
                    if (parseInt(this.d_remaining) < total_qty) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (total_qty < 0) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (this.form.packing_box < 1 || this.form.packing_box == null || isNaN(this.form.packing_box)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    this.remaining = parseInt(this.d_remaining) - parseInt(this.d_actual)
                    this.d_remaining = this.remaining
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.d_actual)
                     if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'd') {
                            this.send_to = this.form.packing_subcon
                            this.process = a;
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)

                            this.keterangan = this.d_ket
                            this.keterangan_detail = this.d_ket_detail
                            this.keterangan_last = this.d_ket_last
                            this.keterangan_detail_last = this.d_ket_detail_last
                            this.SubmitSave();

                        }
                    }
                }
                if (process == 'e') {
                    this.process_ket = 'print'
                    let total_qty = parseInt(this.e_actual)
                    this.total_actual = total_qty;
                    this.ttl_qty = total_qty;
                    this.status = 'Open';
                    if (parseInt(this.e_remaining) < total_qty) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (total_qty < 0) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    this.remaining = parseInt(this.e_remaining) - parseInt(this.e_actual)
                    this.e_remaining = this.remaining
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.e_actual)
                     if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'e') {
                            this.send_to = this.form.printing_subcon
                            this.process = a;
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'b') {
                                    this.subcon = this.form.sewing_id;
                                }
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)

                            this.keterangan = this.e_ket
                            this.keterangan_detail = this.e_ket_detail
                            this.keterangan_last = this.e_ket_last
                            this.keterangan_detail_last = this.e_ket_detail_last
                            this.SubmitSave();

                        }
                    }
                }
                if (process == 'f') {
                    this.process_ket = 'embroidery'
                    let total_qty = parseInt(this.f_actual)
                    this.total_actual = total_qty;
                    this.ttl_qty = total_qty;
                    this.status = 'Open';
                    if (parseInt(this.f_remaining) < total_qty) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (total_qty < 0) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    this.remaining = parseInt(this.f_remaining) - parseInt(this.f_actual)
                    this.f_remaining = this.remaining
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.f_actual)
                     if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'f') {
                            this.send_to = this.form.embroidery_subcon;
                            this.process = a;
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'b') {
                                    this.subcon = this.form.sewing_id;
                                }
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)

                            this.keterangan = this.f_ket
                            this.keterangan_detail = this.f_ket_detail
                            this.keterangan_last = this.f_ket_last
                            this.keterangan_detail_last = this.f_ket_detail_last
                            this.SubmitSave();

                        }
                    }
                }
                if (process == 'g') {
                    this.process_ket = 'emblem'
                    let total_qty = parseInt(this.g_actual)
                    this.total_actual = total_qty;
                    this.ttl_qty = total_qty;
                    this.status = 'Open';
                    if (parseInt(this.g_remaining) < total_qty) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (total_qty < 0) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    this.remaining = parseInt(this.g_remaining) - parseInt(this.g_actual)
                    this.g_remaining = this.remaining
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.g_actual)
                     if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'g') {
                            this.send_to = this.form.emblem_subcon;
                            this.process = a;
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'b') {
                                    this.subcon = this.form.sewing_id;
                                }
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)

                            this.keterangan = this.g_ket
                            this.keterangan_detail = this.g_ket_detail
                            this.keterangan_last = this.g_ket_last
                            this.keterangan_detail_last = this.g_ket_detail_last
                            this.SubmitSave();

                        }
                    }
                }
                if (process == 'h') {
                    this.process_ket = 'washing'
                    let total_qty = parseInt(this.h_actual)
                    this.total_actual = total_qty;
                    this.ttl_qty = total_qty;
                    this.status = 'Open';
                    if (parseInt(this.h_remaining) < total_qty) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    if (total_qty < 0) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    this.remaining = parseInt(this.h_remaining) - parseInt(this.h_actual)
                    this.h_remaining = this.remaining
                    this.send_date = this.today;
                    this.send_qty = parseInt(this.h_actual)
                     if (this.send_qty == 0 || this.send_qty == null || this.send_qty == undefined || isNaN(this.send_qty)) {
                        this.showNotification({type:'error', message:'Error The Quantity'})
                        return
                    }
                    let a = 0;
                    let b = 0;
                    for (a=0;a<=7;a++) {
                        if (this.form.process_order[a] == 'h') {
                            this.send_to = this.form.washing_subcon;
                            this.process = a;
                            for (b=a+1;b<=a+1;b++) {
                                this.next_process = this.form.process_order[b];
                                if (this.form.process_order[b] == 'b') {
                                    this.subcon = this.form.sewing_id;
                                }
                                if (this.form.process_order[b] == 'c') {
                                    this.subcon = this.form.finishing_id;
                                }
                                if (this.form.process_order[b] == 'd') {
                                    this.subcon = this.form.packing_id;
                                }
                                if (this.form.process_order[b] == 'e') {
                                    this.subcon = this.form.printing_id;
                                }
                                if (this.form.process_order[b] == 'f') {
                                    this.subcon = this.form.embroidery_id;
                                }
                                if (this.form.process_order[b] == 'g') {
                                    this.subcon = this.form.emblem_id;
                                }
                                if (this.form.process_order[b] == 'h') {
                                    this.subcon = this.form.washing_id;
                                }
                            }
                            this.start_date = this.today;
                            this.ord_process = process;
                            this.status_rec = 'Open';
                            this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)

                            this.keterangan = this.h_ket
                            this.keterangan_detail = this.h_ket_detail
                            this.keterangan_last = this.h_ket_last
                            this.keterangan_detail_last = this.h_ket_detail_last
                            this.SubmitSave();

                        }
                    }
                }
            },
            Receive(value) {
                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value2 => {
                    this.boxTwo = String(value2)
                    if (this.boxTwo == 'true') {
                        this.ReceiveSubmit(value)
                    }
                })
                .catch(err => {
                    // An error occurred
                })

            },
            ReceiveSubmit(value) {
                let loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: false,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                    // show report after timeout min 1 second
                setTimeout(() => {
                let z = 0;
                let a = 0;
                let process_now = 0;
                let order_process_now = null;
                let next_process_now = null;

                let order_process = value;
                this.code_process = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)
                for (a=0;a<=7;a++) {
                if (this.form.process_order[a] == value) {
                    if (this.form.process_order[a] == 'b') {
                        this.subcon = this.form.sewing_id
                        this.process = a;
                        this.total_actual = this.b_remaining
                        if (a < 7) {
                        this.next_process = this.form.process_order[a+1]
                        }
                        order_process_now = this.form.process_order[a-1]
                        next_process_now = this.form.process_order[a]
                        process_now = a-1
                    }
                    if (this.form.process_order[a] == 'c') {
                        this.subcon = this.form.finishing_id
                        this.process = a;
                        this.total_actual = this.c_remaining
                        if (a < 7) {
                        this.next_process = this.form.process_order[a+1]
                        }
                        order_process_now = this.form.process_order[a-1]
                        next_process_now = this.form.process_order[a]
                        process_now = a-1
                    }
                    if (this.form.process_order[a] == 'd') {
                        this.subcon = this.form.packing_id
                        this.process = a;
                        this.total_actual = this.d_remaining
                        console.log('this.total_actual: '+this.total_actual)
                        this.next_process = null
                        order_process_now = this.form.process_order[a-1]
                        next_process_now = this.form.process_order[a]
                        process_now = a-1
                    }
                    if (this.form.process_order[a] == 'e') {
                        this.subcon = this.form.printing_id
                        this.process = a;
                        this.total_actual = this.e_remaining
                        if (a < 7) {
                        this.next_process = this.form.process_order[a+1]
                        }
                        order_process_now = this.form.process_order[a-1]
                        next_process_now = this.form.process_order[a]
                        process_now = a-1
                    }
                    if (this.form.process_order[a] == 'f') {
                        this.subcon = this.form.embroidery_id
                        this.process = a;
                        this.total_actual = this.f_remaining
                        if (a < 7) {
                        this.next_process = this.form.process_order[a+1]
                        }
                        order_process_now = this.form.process_order[a-1]
                        next_process_now = this.form.process_order[a]
                        process_now = a-1
                    }
                    if (this.form.process_order[a] == 'g') {
                        this.subcon = this.form.emblem_id
                        this.process = a;
                        this.total_actual = this.g_remaining
                        if (a < 7) {
                        this.next_process = this.form.process_order[a+1]
                        }
                        order_process_now = this.form.process_order[a-1]
                        next_process_now = this.form.process_order[a]
                        process_now = a-1
                    }
                    if (this.form.process_order[a] == 'h') {
                        this.subcon = this.form.washing_id
                        this.process = a;
                        this.total_actual = this.h_remaining
                        if (a < 7) {
                        this.next_process = this.form.process_order[a+1]
                        }
                        order_process_now = this.form.process_order[a-1]
                        next_process_now = this.form.process_order[a]
                        process_now = a-1
                    }
                }
                }

                let status_process = 'Open';
                let length = this.stIoSubconDaily.length-1
                let loop = 0
                    for (z=0;z<=length;z++) {
                       //console.log(this.b_receive[z]);
                        if (this.stIoSubconDaily[z].next_process == value) {
                            if (this.stIoSubconDaily[z].status_rec !== 'Receive' || this.stIoSubconDaily[z].status_rec !== 'Partial Receive' || this.stIoSubconDaily[z].status_rec !== null) {
                                if (this.b_receive[z] != null || this.b_receive[z] != undefined ) {
                                    loop = loop + 1;
                                    if (loop > 1) {
                                        this.showNotification({type:'error', message:'Pastikan Hanya 1 Field yang di Receive'})
                                        loader.hide()
                                        return
                                    }
                                    let zero = 0
                                    let total_receive = parseInt(this.b_receive[z]);
                                    this.total_actual = parseInt(this.total_actual) + total_receive
                                    let kode = this.stIoSubconDaily[z].code_process
                                    let data_receive = this.stIoSubconDaily[z].send_qty
                                    let send_to = this.stIoSubconDaily[z].send_to
                                    // console.log('total_receive: '+total_receive)

                                    let kode_2 = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)
                                    let kode_3 = this.form.data_io+''+this.today+'_'+this.time+''+Math.floor(Math.random() * 999999999)
                                    let status = 'Receive'
                                    let status_partial = 'Partial Receive'
                                    if (total_receive == null || total_receive == undefined || total_receive == 0) {
                                        this.showNotification({type:'error', message:'Error Receive Quantity'})
                                        loader.hide()
                                        return
                                    }
                                    let date_rec = this.today;

                                    if (data_receive < total_receive) {
                                        this.showNotification({type:'error', message:'Error Receive Quantity'})
                                        loader.hide()
                                        return
                                    }
                                    if (data_receive > total_receive) {
                                        let total_rec  = data_receive - total_receive

                                    PostCompleteSubcon.dispatch('ApiSubconDaily_V2', {
                                    condition_process:'partial_receive',
                                    code_process:kode,
                                    io:this.form.data_io,
                                    employee:this.form.Employee,
                                    status_rec:status_partial,
                                    rec_qty:total_receive,
                                    date_rec:date_rec,

                                    total_qty:total_rec,
                                    process:process_now,
                                    status:'Open',
                                    remaining:zero,
                                    send_qty:total_rec,
                                    send_date:this.today,
                                    subcon:this.subcon,
                                    send_to:send_to,
                                    order_process:order_process_now,
                                    next_process:next_process_now,
                                    code_process_partial:kode_3,

                                    total_qty_rec:this.total_actual,
                                    process_rec:this.process,
                                    status_rec_partial:status_process,
                                    remaining_rec:this.total_actual,
                                    order_process_rec:order_process,
                                    next_process_rec:this.next_process,
                                    code_process_rec:kode_2,
                                    })
                                    .then(response => {
                                    // console.log(response)
                                        loader.hide()
                                        this.$router.push({name:"DashboardSubcon"})
                                        this.showNotification({type:'success', message:'Successfully Receive'})

                                    })
                                    .catch(err => {
                                        loader.hide()
                                    if (err.response === 400) {
                                        console.log(err.response.data.message)
                                        this.showNotification({type:'error', message:err.response.data.message})
                                    }
                                    })
                                    }
                                    // console.log('kode receive : '+kode)
                                    // console.log('data_receive : '+data_receive)
                                    // console.log('total_receive : '+total_receive)

                                //console.log(this.kode);
                                    if (data_receive == total_receive) {

                                    PostCompleteSubcon.dispatch('ApiSubconDaily_V2', {
                                    condition_process:'receive',
                                    code_process:kode,
                                    io:this.form.data_io,
                                    status_rec:status,
                                    rec_qty:total_receive,
                                    date_rec:date_rec,

                                    employee:this.form.Employee,
                                    total_qty:this.total_actual,
                                    process:this.process,
                                    status:status_process,
                                    remaining:this.total_actual,
                                    subcon:this.subcon,
                                    order_process:order_process,
                                    next_process:this.next_process,
                                    code_process_rec:kode_2


                                    })
                                    .then(response => {
                                    // console.log(response)
                                        loader.hide()
                                        this.$router.push({name:"DashboardSubcon"})
                                        this.showNotification({type:'success', message:'Successfully Receive'})

                                    })
                                    .catch(err => {
                                        loader.hide()
                                    if (err.response === 400) {
                                        console.log(err.response.data.message)
                                        this.showNotification({type:'error', message:err.response.data.message})
                                    }
                                    })
                                    }

                                }
                            }
                        }
                    }
                },1000)

            },
            CloseProcess(value) {
                // console.log(this.closeProcess)
                if (value == 'a') {
                    if (this.a_remaining !== 0) {
                        if (this.a_ket == null || this.a_ket == ' ' || this.a_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.re_cutting+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_a").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.a_ket
                    this.keterangan_detail = this.a_ket_detail
                    this.keterangan_last = this.a_ket_last
                    this.keterangan_detail_last = this.a_ket_detail_last
                }
                if (value == 'b') {
                    if (this.b_remaining > 0) {
                        if (this.b_ket == null || this.b_ket == ' ' || this.b_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.b_remaining+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_b").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.b_ket
                    this.keterangan_detail = this.b_ket_detail
                    this.keterangan_last = this.b_ket_last
                    this.keterangan_detail_last = this.b_ket_detail_last
                }
                if (value == 'c') {
                    if (this.c_remaining > 0) {
                        if (this.c_ket == null || this.c_ket == ' ' || this.c_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.c_remaining+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_c").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.c_ket
                    this.keterangan_detail = this.c_ket_detail
                    this.keterangan_last = this.c_ket_last
                    this.keterangan_detail_last = this.c_ket_detail_last
                }
                if (value == 'd') {
                    if (this.d_remaining > 0) {
                        if (this.d_ket == null || this.d_ket == ' ' || this.d_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.d_remaining+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_d").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.d_ket
                    this.keterangan_detail = this.d_ket_detail
                    this.keterangan_last = this.d_ket_last
                    this.keterangan_detail_last = this.d_ket_detail_last
                }
                if (value == 'e') {
                    if (this.e_remaining > 0) {
                        if (this.e_ket == null || this.e_ket == ' ' || this.e_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.e_remaining+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_e").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.e_ket
                    this.keterangan_detail = this.e_ket_detail
                    this.keterangan_last = this.e_ket_last
                    this.keterangan_detail_last = this.e_ket_detail_last
                }
                if (value == 'f') {
                    if (this.f_remaining > 0) {
                        if (this.f_ket == null || this.f_ket == ' ' || this.f_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.f_remaining+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_f").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.f_ket
                    this.keterangan_detail = this.f_ket_detail
                    this.keterangan_last = this.f_ket_last
                    this.keterangan_detail_last = this.f_ket_detail_last
                }
                if (value == 'g') {
                    if (this.g_remaining > 0) {
                        if (this.g_ket == null || this.g_ket == ' ' || this.g_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.g_remaining+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_g").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.g_ket
                    this.keterangan_detail = this.g_ket_detail
                    this.keterangan_last = this.g_ket_last
                    this.keterangan_detail_last = this.g_ket_detail_last
                }
                if (value == 'h') {
                    if (this.h_remaining > 0) {
                        if (this.h_ket == null || this.h_ket == ' ' || this.h_ket == '') {
                            this.closeProcess = null
                            this.showNotification({type:'error', message:'Quantity Remaining :'+this.h_remaining+', Harap Untuk Menuliskan Keterangan'})
                            document.getElementById("warning_h").classList.remove("hidden");
                            return
                        }
                    }
                    this.keterangan = this.h_ket
                    this.keterangan_detail = this.h_ket_detail
                    this.keterangan_last = this.h_ket_last
                    this.keterangan_detail_last = this.h_ket_detail_last
                }
                this.closeProcess = 'CloseProcess'
                this.Complete(value)

            },
            CompleteProcess(value) {
                this.closeProcess = null
                this.Complete(value)
            },
            Complete(value) {
            this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you want to Complete/Close the process?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value1 => {
                    this.boxTwo = String(value1)
                    if (this.boxTwo == 'true') {

                let a = 0
                for (a=0;a<=7;a++) {
                    if (this.form.process_order[a] == value) {
                        this.process = a;
                        if (this.form.process_order[a] == 'a') {
                            this.process_ket = 'cutting'
                            this.code_process = this.a_code_process
                            this.completed_actual_days = this.cutting_actual_days
                            let cutting_end_date = this.form.cutting_end_date
                            if (this.form.cutting_end_date == null) {
                                cutting_end_date = this.today
                            }
                            this.close_process = 'a'
                            this.close_date = cutting_end_date
                            this.keterangan = this.a_ket
                            this.keterangan_detail = this.a_ket_detail

                            this.keterangan_last = this.a_ket_last
                            this.keterangan_detail_last = this.a_ket_detail_last
                        if (this.closeProcess == null) {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // cutting_end_date:cutting_end_date,
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                        }

                        }
                        if (this.form.process_order[a] == 'b') {
                            this.process_ket = 'sewing'
                            this.code_process = this.b_code_process
                            this.completed_actual_days = this.sewing_actual_days
                            let sewing_end_date = this.form.sewing_end_date
                            if (this.form.sewing_end_date == null) {
                                sewing_end_date = this.today
                            }
                            this.close_process = 'b'
                            this.close_date = sewing_end_date
                            this.keterangan = this.b_ket
                            this.keterangan_detail = this.b_ket_detail

                            this.keterangan_last = this.b_ket_last
                            this.keterangan_detail_last = this.b_ket_detail_last
                        if (this.closeProcess == null) {
                            if (this.form.visit_inline == null) {
                                this.showNotification({type:'error', message:'Instpection Inline Harap Di Isi'})
                            }
                            if (this.comp[a-1] == 'Complete') {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // sewing_end_date:sewing_end_date,
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                            } else {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Completed Terlebih Dahulu'})
                                return
                            }
                        } else {
                            if (this.cls[a-1] !== 'Close') {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Close Terlebih Dahulu'})
                                return
                            }
                        }
                        }
                        if (this.form.process_order[a] == 'c') {
                            this.process_ket = 'finishing'
                            this.code_process = this.c_code_process
                            this.completed_actual_days = this.finishing_actual_days
                            let finishing_end_date = this.form.finishing_end_date
                            if (this.form.finishing_end_date == null) {
                                finishing_end_date = this.today
                            }
                            this.close_process = 'c'
                            this.close_date = finishing_end_date
                            this.keterangan = this.c_ket
                            this.keterangan_detail = this.c_ket_detail

                            this.keterangan_last = this.c_ket_last
                            this.keterangan_detail_last = this.c_ket_detail_last
                            // console.log(a)
                        if (this.closeProcess == null) {

                            if (this.comp[a-1] == 'Complete') {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // finishing_end_date:finishing_end_date
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                            } else {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Completed Terlebih Dahulu'})
                                return
                            }
                        } else {
                            if (this.cls[a-1] !== 'Close') {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Close Terlebih Dahulu'})
                                return
                            }
                        }
                        }
                        if (this.form.process_order[a] == 'd') {
                            this.process_ket = 'packing'
                            this.code_process = this.d_code_process
                            this.completed_actual_days = this.packing_actual_days
                            let packing_end_date = this.form.packing_end_date
                            if (this.form.packing_end_date == null) {
                                packing_end_date = this.today
                            }
                            this.close_process = 'd'
                            this.close_date = packing_end_date
                            this.keterangan = this.d_ket
                            this.keterangan_detail = this.d_ket_detail

                            this.keterangan_last = this.d_ket_last
                            this.keterangan_detail_last = this.d_ket_detail_last
                        if (this.closeProcess == null) {
                            if (this.form.visit_final == null || this.form.visit_final == 0) {
                                this.showNotification({type:'error', message:'Instpection Final Harap Di Isi'})
                            }
                            if (this.comp[a-1] == 'Complete') {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // packing_end_date:packing_end_date,
                            // status:'ONGOING(Delivery)',
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                            } else {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Completed Terlebih Dahulu'})
                                return
                            }
                        } else {
                            if (this.cls[a-1] !== 'Close') {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Close Terlebih Dahulu'})
                                return
                            }
                        }
                        }
                        if (this.form.process_order[a] == 'e') {
                            this.process_ket = 'print'
                            this.code_process = this.e_code_process
                            this.completed_actual_days = this.printing_actual_days
                            let printing_end_date = this.form.printing_end_date
                            if (this.form.printing_end_date == null) {
                                printing_end_date = this.today
                            }
                            this.close_process = 'e'
                            this.close_date = printing_end_date
                            this.keterangan = this.e_ket
                            this.keterangan_detail = this.e_ket_detail

                            this.keterangan_last = this.e_ket_last
                            this.keterangan_detail_last = this.e_ket_detail_last
                        if (this.closeProcess == null) {

                            if (this.comp[a-1] == 'Complete') {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // printing_end_date:printing_end_date,
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                            } else {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Completed Terlebih Dahulu'})
                                return
                            }
                        } else {
                            if (this.cls[a-1] !== 'Close') {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Close Terlebih Dahulu'})
                                return
                            }
                        }
                        }
                        if (this.form.process_order[a] == 'f') {
                            this.process_ket = 'embroidery'
                            this.code_process = this.f_code_process
                            this.completed_actual_days = this.embroidery_actual_days
                            let embroidery_end_date = this.form.embroidery_end_date
                            if (this.form.embroidery_end_date == null) {
                                embroidery_end_date = this.today
                            }
                            this.close_process = 'f'
                            this.close_date = embroidery_end_date
                            this.keterangan = this.f_ket
                            this.keterangan_detail = this.f_ket_detail

                            this.keterangan_last = this.f_ket_last
                            this.keterangan_detail_last = this.f_ket_detail_last
                        if (this.closeProcess == null) {

                            if (this.comp[a-1] == 'Complete') {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // embroidery_end_date:embroidery_end_date,
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                            } else {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Completed Terlebih Dahulu'})
                                return
                            }
                        } else {
                            if (this.cls[a-1] !== 'Close') {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Close Terlebih Dahulu'})
                                return
                            }
                        }
                        }
                        if (this.form.process_order[a] == 'g') {
                            this.process_ket = 'emblem'
                            this.code_process = this.g_code_process
                            this.completed_actual_days = this.emblem_actual_days
                            let emblem_end_date = this.form.emblem_end_date
                            if (this.form.emblem_end_date == null) {
                                emblem_end_date = this.today
                            }
                            this.close_process = 'g'
                            this.close_date = emblem_end_date
                            this.keterangan = this.g_ket
                            this.keterangan_detail = this.g_ket_detail

                            this.keterangan_last = this.g_ket_last
                            this.keterangan_detail_last = this.g_ket_detail_last
                        if (this.closeProcess == null) {

                            if (this.comp[a-1] == 'Complete') {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // emblem_end_date:emblem_end_date,
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                            } else {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Completed Terlebih Dahulu'})
                                return
                            }
                        } else {
                            if (this.cls[a-1] !== 'Close') {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Close Terlebih Dahulu'})
                                return
                            }
                        }
                        }
                        if (this.form.process_order[a] == 'h') {
                            this.process_ket = 'washing'
                            this.code_process = this.h_code_process
                            this.completed_actual_days = this.washing_actual_days
                            let washing_end_date = this.form.washing_end_date
                            if (this.form.washing_end_date == null) {
                                washing_end_date = this.today
                            }
                            this.close_process = 'h'
                            this.close_date = washing_end_date
                            this.keterangan = this.h_ket
                            this.keterangan_detail = this.h_ket_detail

                            this.keterangan_last = this.h_ket_last
                            this.keterangan_detail_last = this.h_ket_detail_last
                        if (this.closeProcess == null) {

                            if (this.comp[a-1] == 'Complete') {

                            // UpdateInputPPM.dispatch('PutInputPPM', {
                            // io:this.form.data_io,
                            // washing_end_date:washing_end_date,
                            // })
                            // .then(response => {
                            // // console.log(response)
                            // })
                            // .catch(err => {
                            // if (err.response === 400) {
                            //     console.log(err.response.data.message)
                            //     this.showNotification({type:'error', message:err.response.data.message})
                            // }
                            // })
                            } else {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Completed Terlebih Dahulu'})
                                return
                            }
                        } else {
                            if (this.cls[a-1] !== 'Close') {
                                this.showNotification({type:'error', message:'Process Sebelumnya Harap Di Close Terlebih Dahulu'})
                                return
                            }
                        }
                        }
                    }
                }
                if (this.closeProcess == null || this.closeProcess =='') {
                    this.status = "Complete";
                    this.end_date = this.today;
                    this.SubmitComplete();
                } else {
                    this.status = "Close";
                    this.end_date = this.today;
                    this.SubmitCloseProcess();
                }

                }
                    if (this.boxTwo == 'false' || this.boxTwo == 'null') {
                        this.closeProcess == null
                    }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            SubmitCloseProcess() {
                if (this.keterangan_detail !== null) {
                    if (this.keterangan_detail.substring(0, 1) == ' '  || this.keterangan_detail == '') {
                        this.keterangan_detail = null
                    }
                }
               let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  color: '#f89830',
                  loader: 'spinner',
                  width: 64,
                  height: 64,
                  backgroundColor: '#ffffff',
                  opacity: 0.5,
                  zIndex: 999,
                });
                // show report after timeout min 1 second
              setTimeout(() => {
                // apiUpload.dispatch('UploadFile', {
                //     myfile: this.document_io
                // })
                PostCompleteSubcon.dispatch('ApiSubconDaily_V2', {
                    condition_process:'close',
                    io:this.form.data_io,
                    code_process:this.code_process,
                    status:this.status,
                    ket:this.keterangan,
                    ket_detail:this.keterangan_detail,

                    process_ket:this.process_ket,
                    date_time:this.today+'_'+this.time,

                    order_process:this.close_process,
                    process_date:this.close_date,
                })
                .then(response => {
                loader.hide()
                //console.log(response)
                this.$router.push({name:"DashboardSubcon"})
                this.showNotification({type:'success', message:'Successfully Submitted'})
                })
                .catch(err => {
                loader.hide()
                if (err.response === 400) {
                    console.log(err.response.data.message)
                    this.showNotification({type:'error', message:err.response.data.message})
                }
                })
                .finally(()=>{
                this.form_api = []
                })
                },1000)

                    // PostOrderReason.dispatch('PostOrderReason', {
                    // io:this.form.data_io,
                    // process:this.process_ket,
                    // reason_category:this.keterangan,
                    // reason_detail:this.keterangan_detail,
                    // date_time:this.today+'_'+this.time,
                    // })
                    // .then(response => {
                    // //console.log(response)
                    // })
                    // .catch(err => {
                    // if (err.response === 400) {
                    //     console.log(err.response.data.message)
                    //     this.showNotification({type:'error', message:err.response.data.message})
                    // }
                    // })

            },

            SubmitComplete() {
                if (this.keterangan_detail !== null) {
                    if (this.keterangan_detail.substring(0, 1) == ' '  || this.keterangan_detail == '') {
                        this.keterangan_detail = null
                    }
                }
               let loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  canCancel: false,
                  color: '#f89830',
                  loader: 'spinner',
                  width: 64,
                  height: 64,
                  backgroundColor: '#ffffff',
                  opacity: 0.5,
                  zIndex: 999,
                });
                // show report after timeout min 1 second
              setTimeout(() => {
                // apiUpload.dispatch('UploadFile', {
                //     myfile: this.document_io
                // })
                PostCompleteSubcon.dispatch('ApiSubconDaily_V2', {
                    condition_process:'completed',
                    io:this.form.data_io,
                    code_process:this.code_process,
                    status:this.status,
                    end_date:this.end_date,
                    actual_days:this.completed_actual_days,
                    ket:this.keterangan,
                    ket_detail:this.keterangan_detail,

                    process_ket:this.process_ket,
                    date_time:this.today+'_'+this.time,

                    order_process:this.close_process,
                    process_date:this.close_date,
                })
                .then(response => {
                loader.hide()
                //console.log(response)
                this.$router.push({name:"DashboardSubcon"})
                this.showNotification({type:'success', message:'Successfully Submitted'})
                })
                .catch(err => {
                loader.hide()
                if (err.response === 400) {
                    console.log(err.response.data.message)
                    this.showNotification({type:'error', message:err.response.data.message})
                }
                })
                .finally(()=>{
                })
                },1000)

                // PostOrderReason.dispatch('PostOrderReason', {
                // io:this.form.data_io,
                // process:this.process_ket,
                // reason_category:this.keterangan,
                // reason_detail:this.keterangan_detail,
                // date_time:this.today+'_'+this.time,
                // })
                // .then(response => {
                // //console.log(response)
                // })
                // .catch(err => {
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })

            },
            SubmitSave() {
                // console.log('SubmitSave')
                if (this.keterangan_detail !== null) {
                    if (this.keterangan_detail.substring(0, 1) == ' '  || this.keterangan_detail == '') {
                        this.keterangan_detail = null
                    }
                }
                let cutting_date = this.form.cutting_date
                if (this.form.cutting_date == null) {
                    cutting_date = this.today;
                }
                let sewing_date = this.form.sewing_date
                if (this.form.sewing_date == null) {
                    sewing_date = this.today;
                }
                let finishing_date = this.form.finishing_date
                if (this.form.finishing_date == null) {
                    finishing_date = this.today;
                }
                let packing_date = this.form.packing_date
                if (this.form.packing_date == null) {
                    packing_date = this.today;
                }
                let printing_date = this.form.printing_date
                if (this.form.printing_date == null) {
                    printing_date = this.today;
                }
                let embroidery_date = this.form.embroidery_date
                if (this.form.embroidery_date == null) {
                    embroidery_date = this.today;
                }
                let emblem_date = this.form.emblem_date
                if (this.form.emblem_date == null) {
                    emblem_date = this.today;
                }
                let washing_date = this.form.washing_date
                if (this.form.washing_date == null) {
                    washing_date = this.today;
                }
                let progress_cutting = this.cutting_progress + this.send_qty
                let progress_sewing = this.sewing_progress + this.send_qty
                let progress_finishing = this.finishing_progress + this.send_qty
                let progress_packing = this.packing_progress + this.send_qty
                let progress_box = this.packingBox + parseInt(this.form.packing_box)
                let progress_printing = this.printing_progress + this.send_qty
                let progress_embroidery = this.embroidery_progress + this.send_qty
                let progress_emblem = this.emblem_progress + this.send_qty
                let progress_washing = this.washing_progress + this.send_qty

                let process_progress = null
                let process_date = null
                let process_progress_box = null

                if (this.ord_process == 'a') {
                    process_progress = progress_cutting
                    process_date     = cutting_date
                }
                if (this.ord_process == 'b') {
                    process_progress = progress_sewing
                    process_date     = sewing_date
                }
                if (this.ord_process == 'c') {
                    process_progress = progress_finishing
                    process_date     = finishing_date
                }
                if (this.ord_process == 'd') {
                    process_progress = progress_packing
                    process_progress_box = progress_box
                    process_date     = packing_date
                }
                if (this.ord_process == 'e') {
                    process_progress = progress_printing
                    process_date     = printing_date
                }
                if (this.ord_process == 'f') {
                    process_progress = progress_embroidery
                    process_date     = embroidery_date
                }
                if (this.ord_process == 'g') {
                    process_progress = progress_emblem
                    process_date     = emblem_date
                }
                if (this.ord_process == 'h') {
                    process_progress = progress_washing
                    process_date     = washing_date
                }
                this.Loading('start')
                // PostSubcon.dispatch('PostSubconSave', {
                PostSubcon.dispatch('ApiSubconDaily_V2', {
                    condition_process:'save',
                    io:this.form.data_io,
                    employee:this.form.Employee,
                    total_qty:this.total_actual,
                    process:this.process,
                    status:this.status,
                    remaining:this.remaining,
                    send_date:this.send_date,
                    send_qty:this.send_qty,
                    send_to:this.send_to,
                    start_date:this.start_date,
                    subcon:this.subcon,
                    order_process:this.ord_process,
                    status_rec:this.status_rec,
                    order_qty:this.ord_qty,
                    next_process:this.next_process,
                    code_process:this.code_process,
                    ket:this.keterangan,
                    ket_detail:this.keterangan_detail,
                    process_ket:this.process_ket,
                    date_time:this.today+'_'+this.time,
                    process_progress:process_progress,
                    process_box:process_progress_box,
                    process_date:process_date,
                    })
                .then(response => {
                    this.$router.push({name:"DashboardSubcon"})
                    this.showNotification({type:'success', message:'Successfully Submitted'})
                })
                .catch(err => {
                    if (err.response === 400) {
                        console.log(err.response.data.message)
                        this.showNotification({type:'error', message:err.response.data.message})
                    }
                })
                .finally(()=>{
                this.Loading('stop')
                })
            },

            LoadDataReason() {
            for(let rf=0; rf<=this.data_reason.length; rf++ ) {
                this.getDataReason(this.data_reason[rf]).then(rsp=>{
                    for (let r=0; r<=this.StDataReasonCount-1; r++) {
                        if (this.data_reason[rf] == 'cutting') {
                            this.opt_reason_cutting.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                        if (this.data_reason[rf] == 'sewing') {
                            this.opt_reason_sewing.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                        if (this.data_reason[rf] == 'finishing') {
                            this.opt_reason_finishing.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                        if (this.data_reason[rf] == 'packing') {
                            this.opt_reason_packing.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                        if (this.data_reason[rf] == 'print') {
                            this.opt_reason_print.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                        if (this.data_reason[rf] == 'embroidery') {
                            this.opt_reason_embroidery.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                        if (this.data_reason[rf] == 'emblem') {
                            this.opt_reason_emblem.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                        if (this.data_reason[rf] == 'washing') {
                            this.opt_reason_washing.push(
                                { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                            );
                        }
                    }
                }).catch(err=>{
                    if (err.response !== 401) {
                        this.showNotification({type:'error', message:'Data Gagal Di Load, Harap Di Ulang Kembali'})
                    }
                })
            }
            },

        },
        mounted() {
            this.LoadDataReason()
            this.Setdata_IO()
        },

    }
</script>
<style>
#tab-button .active {
  background-color: #ffc629 !important;
}
</style>
<style src="./InputSubconDaily.scss" lang="scss" scoped />
