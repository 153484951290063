<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="sm">
      <b-row align-h="center">
        <b-col cols="12" sm="auto">
          <b-card header="Form Harian Baru" header-tag="header">
            <template v-slot:header>
              <div class="text-center">
                <div class="banner-container">
                  <div class="bbi-banner"></div>
                </div>
                <h3>Form Riwayat Vaksin</h3>
                <p>
                  Harap diisi dengan JUJUR dan sesuai kondisi yg ada demi
                  kebaikan bersama
                </p>
              </div>
            </template>

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(addRiwayatVaksin)">
                <b-row align-h="center">
                  <b-col sm="6">
                    <!-- input nik -->
                    <b-form-group
                      label="Nomor Induk Karyawan"
                      label-for="inputNik"
                    >
                      <b-form-input
                        readonly
                        id="inputNik"
                        v-model="form.nik"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input nik -->

                    <!-- input nama karyawan -->
                    <b-form-group label="Nama Karyawan" label-for="inputNama">
                      <b-form-input
                        readonly
                        id="inputName"
                        v-model="form.nama_karyawan"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input nama karyawan -->
                  </b-col>
                  <b-col sm="6">
                    <!-- input department -->
                    <b-form-group label="Department" label-for="inputDept">
                      <b-form-input
                        readonly
                        id="inputDept"
                        v-model="form.department"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input department -->
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <b-col sm="6">
                    <!-- input penerima vaksin -->
                    <validation-provider
                      name="penerima_vaksin"
                      vid="penerima_vaksin"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Penerima Vaksin"
                        label-for="inputPenerima"
                      >
                        <v-select
                          label="penerima_vaksin"
                          :reduce="(data) => data.penerima_vaksin"
                          :options="penerimaVaksin"
                          v-model="selectedPenerima"
                          @input="assignAnggotaKeluargaKe($event)"
                          class="form-custom custom-select"
                          ref="opt_penerima"
                        ></v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-selectedPenerima-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input penerima vaksin -->

                    <!-- input jumlah anggota keluarga -->
                    <validation-provider
                      name="jumlah_keluarga"
                      vid="jumlah_keluarga"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Jumlah Anggota Keluarga Serumah yang Berumur Di Atas 6 Tahun"
                        label-for="inputJmlAnggota"
                      >
                        <v-select
                          label="jumlah_keluarga"
                          :reduce="(data) => data.jumlah_keluarga"
                          :options="anggotaKeluarga"
                          v-model="selectedJmlAnggota"
                          class="form-custom custom-select"
                          ref="opt_jml_anggota"
                        ></v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-selectedJmlAnggota-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input jumlah anggota keluarga -->

                    <!-- input tanggal vaksin -->
                    <validation-provider
                      name="tanggal_vaksin"
                      vid="tanggal_vaksin"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Tanggal Vaksin"
                        label-for="inputTanggal"
                      >
                        <b-form class="datepicker">
                          <VueDatePicker
                            v-model="selectedDate"
                            class="vd__div"
                            no-header
                            format="DD MMM YYYY"
                            min-date="1900-01-01"
                            :max-date="maxDate"
                            placeholder="Choose vaccine date"
                            ref="opt_tanggal_vaksin"
                          />
                        </b-form>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-selectedDate-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input tanggal vaksin -->
                  </b-col>
                  <b-col sm="6">
                    <!-- input anggota keluarga ke -->
                    <validation-provider
                      name="anggota_keluarga_ke"
                      vid="anggota_keluarga_ke"
                      :rules="{ required: !checkPenerimaVaksin() }"
                      v-slot="validationContext"
                      v-if="!checkPenerimaVaksin()"
                    >
                      <b-form-group
                        label="Anggota Keluarga Ke-"
                        label-for="inputAnggotaKeluargaKe"
                      >
                        <v-select
                          label="keluargaKe"
                          :reduce="(data) => data.keluargaKe"
                          :options="nomorAnggotaKeluarga"
                          v-model="selectedKeluargaKe"
                          @input="assignVaksinAnggotaKeluargaKe($event)"
                          class="form-custom custom-select"
                          ref="opt_keluarga_ke"
                        ></v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-selectedKeluargaKe-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input anggota keluarga ke -->

                    <!-- input vaksin ke -->
                    <validation-provider name="vaksin_ke" vid="vaksin_ke">
                      <b-form-group
                        label="Vaksin Ke-"
                        label-for="inputVaksinKe"
                      >
                        <b-form-input
                          readonly
                          id="inputVaksinKe"
                          v-model="nomorVaksin"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input vaksin ke -->

                    <!-- input jenis vaksin -->
                    <validation-provider
                      name="jenis_vaksin"
                      vid="jenis_vaksin"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group label="Vaksin" label-for="inputJenis">
                        <v-select
                          label="jenis_vaksin"
                          :reduce="(data) => data.jenis_vaksin"
                          :options="optJenisVaksin"
                          v-model="selectedVaksin"
                          class="form-custom custom-select"
                          ref="opt_jenis_vaksin"
                          :disabled="checkJenisVaksin()"
                        ></v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-selectedVaksin-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input jenis vaksin -->
                  </b-col>
                  <b-col sm="12">
                    <validation-provider
                      v-if="!checkSertifikatVaksin()"
                      name="idsertifkatvaksin"
                      vid="sertifikat_vaksin"
                      :rules="{ required: !checkSertifikatVaksin(), minId }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        ref="opt_sertifikat_vaksin"
                        label="No Id Sertifikat Vaksin (24 Digit)"
                        label-for="inputSertifikatVaksin"
                        aria-describedby="input-sertifikat-vaksin-feedback"
                      >
                        <b-form-input
                          id="inputSertifikatVaksin"
                          v-model="sertifikatVaksin"
                          name="sertifikat_vaksin"
                          class="form-sertifikat-vaksin"
                          ref="opt_sertifikat_vaksin"
                          trim
                          @keypress="isAlphaNumeric($event)"
                          :disabled="checkSertifikatVaksin()"
                          maxlength="24"
                          placeholder="Contoh : 60f13rT56Yb567Drt5g61Yt7"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-sertifikat-vaksin-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col sm="6">
                    <b-button
                      block
                      @click="FocusWarning()"
                      type="submit"
                      variant="success"
                      >Add</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(saveRiwayatVaksin)">
                <br />
                <b-row align-h="center">
                  <b-col xs="12">
                    <b-form-group
                      label="Riwayat Vaksinasi"
                      label-for="inputRiwayatVaksin"
                    >
                    </b-form-group>
                    <div class="table-responsive">
                      <table class="table table-lg mb-0">
                        <thead>
                          <tr style="text-align: center">
                            <th>Penerima Vaksin</th>
                            <th>Vaksinasi Ke-</th>
                            <th>Tanggal Vaksin</th>
                            <th>Vaksin</th>
                            <th>No Id Sertifkat Vaksin</th>
                            <!-- <th>Action</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            style="text-align: center"
                            v-for="row in riwayatVaksin"
                            :key="row.id"
                          >
                            <td>{{ row.penerima_vaksin }}</td>
                            <td>{{ row.vaksin_ke }}</td>
                            <td>{{ row.tanggal_vaksin }}</td>
                            <td>{{ row.jenis_vaksin }}</td>
                            <td>{{ row.sertifikat_vaksin }}</td>
                            <!-- <td>
                              <b-button type="button" variant="warning"
                                >Edit</b-button
                              >
                              &nbsp;
                              <b-button type="button" variant="danger"
                                >Delete</b-button
                              >
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <br />
                  <b-col sm="6">
                    <b-button block type="submit" variant="primary"
                      >Submit</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
// import vue loading
import Loading from "vue-loading-overlay";
// import vue loading stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import vSelect from "vue-select";
// import penerima vaksin
import { fnGET, fnPOST } from "../../../../api/api-methods";
// import jenis vaksin
import { APIPATH_BBIAPPS, APIPATH_HR } from "../../../../api/api-paths";
// import vue sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { extend } from "vee-validate";

// init plugin
Vue.use(Loading);
Vue.use(VueSweetalert2);

export default {
  name: "VaccineHistory",
  components: {
    vSelect,
  },
  data() {
    return {
      formName: "Form Riwayat Vaksin",
      date: new Date(),
      currentDate: new Date(),
      form: {
        nama_karyawan: null,
        nik: null,
        department: null,
        lokasi: null,
      },
      selectedPenerima: null,
      selectedKeluargaKe: null,
      penerimaVaksin: [],
      nomorAnggotaKeluarga: [],
      selectedNoVaksin: null,
      nomorVaksin: "0",
      selectedDate: null,
      selectedVaksin: null,
      optJenisVaksin: [],
      sertifikatVaksin: null,
      riwayatVaksin: [],
      messages: null,
      minId: null,
      selectedJmlAnggota: null,
      anggotaKeluarga: [],
      lengthArray: null,
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    maxDate() {
      return new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    getAccountInfo() {
      let userData = this.userData;
      this.$set(this.form, "nama_karyawan", userData.fullname);
      this.$set(this.form, "nik", userData.nik);
      if (userData.business_unit != "BBI1") {
        this.$set(this.form, "department", userData.subdept);
      } else {
        this.$set(this.form, "department", userData.dept);
      }
      this.$set(this.form, "lokasi", userData.business_unit);
    },
    getListPenerimaVaksin() {
      fnGET(APIPATH_BBIAPPS.listPenerimaVaksin).then((rsp) => {
        this.penerimaVaksin = rsp.data;
      });
    },
    getListJenisVaksin() {
      fnGET(APIPATH_BBIAPPS.listJenisVaksin).then((rsp) => {
        this.optJenisVaksin = rsp.data;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getVaccineHistoryData() {
      let userData = this.userData;
      fnPOST(APIPATH_HR.getVaccineHistory, {
        nik: userData.nik,
      }).then((rsp) => {
        // console.log(rsp.data.payload);
        for (let x = 0; x < rsp.data.payload.length; x++) {
          const tmpVaccineHistory = rsp.data.payload[x];
          if (
            tmpVaccineHistory.jumlah_anggota_keluarga == null ||
            tmpVaccineHistory.jumlah_anggota_keluarga == ""
          ) {
            tmpVaccineHistory.jumlah_anggota_keluarga = 0;
          }
          this.riwayatVaksin.push({
            penerima_vaksin: tmpVaccineHistory.penerima_vaksin,
            vaksin_ke: tmpVaccineHistory.vaksin_ke,
            tanggal_vaksin: tmpVaccineHistory.tanggal_vaksin,
            jenis_vaksin: tmpVaccineHistory.jenis_vaksin,
            sertifikat_vaksin: tmpVaccineHistory.sertifikat_vaksin,
            jumlah_anggota_keluarga: tmpVaccineHistory.jumlah_anggota_keluarga,
          });
        }
        this.lengthArray = this.riwayatVaksin.length;
      });
    },
    getJmlAnggotaKeluarga() {
      for (let x = 0; x <= 10; x++) {
        this.anggotaKeluarga.push({
          id: x,
          jumlah_keluarga: x,
        });
      }
    },

    assignAnggotaKeluargaKe(e) {
      if (e != "" || e != null) {
        if (this.riwayatVaksin.length != 0) {
          let tmpVaccineHistoryGet = this.riwayatVaksin
            .sort()
            .find((post, index) => {
              if (post.penerima_vaksin == this.selectedPenerima) {
                return true;
              }
            });

          let lastVaccineHistoryGet = this.riwayatVaksin
            .sort()
            .find((post, index) => {
              return true;
            });
          console.log(this.riwayatVaksin);
          if (this.selectedPenerima != null) {
            let jmlAnggotaKeluarga =
              lastVaccineHistoryGet.jumlah_anggota_keluarga;
            this.selectedJmlAnggota = jmlAnggotaKeluarga;
          } else {
            this.selectedJmlAnggota = null;
          }

          if (tmpVaccineHistoryGet != null) {
            if (tmpVaccineHistoryGet.penerima_vaksin == this.selectedPenerima) {
              let VaksinKe = parseInt(tmpVaccineHistoryGet.vaksin_ke);
              let VaksinName = tmpVaccineHistoryGet.jenis_vaksin;
              let SertifikatVaksin = tmpVaccineHistoryGet.sertifikat_vaksin;
              VaksinKe += 1;
              this.nomorVaksin = VaksinKe.toString();
              this.selectedVaksin = VaksinName;
              this.sertifikatVaksin = SertifikatVaksin;
            }
          } else {
            this.nomorVaksin = "0";
            this.selectedVaksin = null;
            this.sertifikatVaksin = null;
            let VaksinKe = parseInt(this.nomorVaksin);
            VaksinKe += 1;
            this.nomorVaksin = VaksinKe.toString();
          }
        } else {
          this.nomorVaksin = "0";
          this.selectedVaksin = null;
          this.sertifikatVaksin = null;
          let VaksinKe = parseInt(this.nomorVaksin);
          VaksinKe += 1;
          this.nomorVaksin = VaksinKe.toString();
        }
      }
      if (e == "Anak" || e == "Saudara Kandung" || e == "Lainnya") {
        this.nomorAnggotaKeluarga = [];
        this.selectedKeluargaKe = "";
        for (let x = 1; x <= 5; x++) {
          this.nomorAnggotaKeluarga.push({
            id: x,
            keluargaKe: this.selectedPenerima + " Ke-" + x,
          });
        }
      } else {
        this.nomorAnggotaKeluarga = [];
      }
    },

    assignVaksinAnggotaKeluargaKe(e) {
      if (e != "" || e != null) {
        if (this.riwayatVaksin.length != 0) {
          let tmpVaccineHistoryGet = this.riwayatVaksin
            .sort()
            .find((post, index) => {
              if (post.penerima_vaksin == this.selectedKeluargaKe) {
                return true;
              }
            });

          let lastVaccineHistoryGet = this.riwayatVaksin
            .sort()
            .find((post, index) => {
              return true;
            });

          if (this.selectedPenerima != null) {
            let jmlAnggotaKeluarga =
              lastVaccineHistoryGet.jumlah_anggota_keluarga;
            this.selectedJmlAnggota = jmlAnggotaKeluarga;
          } else {
            this.selectedJmlAnggota = null;
          }

          if (tmpVaccineHistoryGet != null) {
            if (
              tmpVaccineHistoryGet.penerima_vaksin == this.selectedKeluargaKe
            ) {
              let VaksinKe = parseInt(tmpVaccineHistoryGet.vaksin_ke);
              let VaksinName = tmpVaccineHistoryGet.jenis_vaksin;
              let SertifikatVaksin = tmpVaccineHistoryGet.sertifikat_vaksin;
              VaksinKe += 1;
              this.nomorVaksin = VaksinKe.toString();
              this.selectedVaksin = VaksinName;
              this.sertifikatVaksin = SertifikatVaksin;
            }
          } else {
            this.nomorVaksin = "0";
            this.selectedVaksin = null;
            this.sertifikatVaksin = null;
            let VaksinKe = parseInt(this.nomorVaksin);
            VaksinKe += 1;
            this.nomorVaksin = VaksinKe.toString();
          }
        } else {
          this.nomorVaksin = "0";
          this.selectedVaksin = null;
          this.sertifikatVaksin = null;
          let VaksinKe = parseInt(this.nomorVaksin);
          VaksinKe += 1;
          this.nomorVaksin = VaksinKe.toString();
        }
      }
    },

    checkJenisVaksin() {
      if (this.nomorVaksin > 2 || this.nomorVaksin < 2) {
        return false;
      }
      return true;
    },

    isAlphaNumeric(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z0-9]+$/.test(char)) {
        return true;
      } else {
        e.preventDefault();
      }
    },

    checkPenerimaVaksin() {
      if (
        this.selectedPenerima == "Anak" ||
        this.selectedPenerima == "Saudara Kandung" ||
        this.selectedPenerima == "Lainnya"
      ) {
        return false;
      }
      this.selectedPenerimaKe == "";
      return true;
    },

    checkSertifikatVaksin() {
      if (this.selectedPenerima == "Pribadi") {
        return false;
      }
      this.sertifikatVaksin == "-";
      return true;
    },

    FocusWarning() {
      if (this.selectedPenerima == null || this.selectedPenerima == "") {
        this.$refs.opt_penerima.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_penerima.$el.classList.value =
          this.$refs.opt_penerima.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_penerima.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (
        this.selectedPenerima == "Anak" ||
        this.selectedPenerima == "Kakak" ||
        this.selectedPenerima == "Adik" ||
        this.selectedPenerima == "Paman" ||
        this.selectedPenerima == "Bibi" ||
        this.selectedPenerima == "Sepupu" ||
        this.selectedPenerima == "Keponakan"
      ) {
        if (this.selectedPenerimaKe == null || this.selectedPenerimaKe == "") {
          this.$refs.opt_keluarga_ke.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_keluarga_ke.$el.classList.value =
            this.$refs.opt_keluarga_ke.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_keluarga_ke.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
      }
      if (this.selectedDate == null || this.selectedDate == "") {
        this.$refs.opt_tanggal_vaksin.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_tanggal_vaksin.$el.classList.value =
          this.$refs.opt_tanggal_vaksin.$el.classList.value +
          " animation-shake";
        setTimeout(() => {
          this.$refs.opt_tanggal_vaksin.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.selectedVaksin == null || this.selectedVaksin == "") {
        this.$refs.opt_jenis_vaksin.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_jenis_vaksin.$el.classList.value =
          this.$refs.opt_jenis_vaksin.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_jenis_vaksin.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.selectedJmlAnggota == null || this.selectedJmlAnggota == "") {
        this.$refs.opt_jml_anggota.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_jml_anggota.$el.classList.value =
          this.$refs.opt_jml_anggota.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_jml_anggota.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.selectedPenerima == "Pribadi") {
        if (this.sertifikatVaksin == null || this.sertifikatVaksin == "") {
          this.$refs.opt_sertifikat_vaksin.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_sertifikat_vaksin.$el.classList.value =
            this.$refs.opt_sertifikat_vaksin.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_sertifikat_vaksin.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
      }
    },

    addRiwayatVaksin() {
      if (
        this.selectedPenerima == "Anak" ||
        this.selectedPenerima == "Saudara Kandung" ||
        this.selectedPenerima == "Lainnya"
      ) {
        this.selectedPenerima = this.selectedKeluargaKe;
      }
      if (
        (this.selectedPenerima != "Pribadi" && this.sertifikatVaksin == "") ||
        this.sertifikatVaksin == null
      ) {
        this.sertifikatVaksin = "-";
      }
      if (this.selectedJmlAnggota == null || this.selectedJmlAnggota == "") {
        this.selectedJmlAnggota = 0;
      }

      this.riwayatVaksin.unshift({
        penerima_vaksin: this.selectedPenerima,
        vaksin_ke: this.nomorVaksin,
        tanggal_vaksin: this.selectedDate,
        jenis_vaksin: this.selectedVaksin,
        sertifikat_vaksin: this.sertifikatVaksin,
        jumlah_anggota_keluarga: this.selectedJmlAnggota,
      });
      this.selectedPenerima = null;
      this.nomorVaksin = "0";
      this.selectedDate = null;
      this.selectedVaksin = null;
      this.sertifikatVaksin = null;
      this.selectedJmlAnggota = null;
      this.showNotification({
        type: "success",
        message: "Successfully Added",
      });
    },
    saveRiwayatVaksin() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: "#f89830",
        loader: "dots",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
      });

      // let json = JSON.stringify(this.riwayatVaksin);
      // let post_riwayat = { data: json };
      // console.log(post_riwayat);

      if (this.riwayatVaksin.length > this.lengthArray) {
        this.messages = "Laporan riwayat vaksin berhasil disimpan.<br>";

        let temp = {
          nama_karyawan: this.form.nama_karyawan,
          nik: this.form.nik,
          department: this.form.department,
          lokasi: this.form.lokasi,
          detail: this.riwayatVaksin,
        };

        setTimeout(() => {
          fnPOST(APIPATH_HR.vaccineHistory, temp)
            .then((response) => {
              loader.hide();
              let postSubmitInfo = `Nama : ${response.data.payload.nama} <br>NIK : ${response.data.payload.nik} <br>Tgl Lapor : ${response.data.payload.timestamp} WIB<br>`;
              this.messages += postSubmitInfo;
              // alert(this.messages);
              Vue.swal.fire({
                icon: "info",
                title: "Laporan Vaksin Berhasil Disimpan",
                html: this.messages,
              });
              this.$router.push({ name: "DashboardPage" });
              this.showNotification({
                type: "success",
                message: "Successfully Submitted",
              });
            })
            .catch((err) => {
              loader.hide();
              if (err.response.status === 400) {
                console.log(err.response.data.message);
                Vue.swal.fire({
                  icon: "error",
                  title: "Laporan Vaksin Gagal Disimpan",
                  html: err.response.data.message,
                });
                this.showNotification({
                  type: "error",
                  message: err.response.data.message,
                });
              }
            });
        }, 1000);
      } else {
        loader.hide();
        this.messages =
          "Laporan riwayat vaksin gagal disimpan.<br>Anda melakukan submit tanpa data baru, silahkan mengisi data riwayat vaksin terlebih dahulu";

        Vue.swal.fire({
          icon: "error",
          title: "Laporan Vaksin Gagal Disimpan",
          html: this.messages,
        });
      }
    },
  },
  created() {
    this.getListPenerimaVaksin();
    this.getListJenisVaksin();
    this.getVaccineHistoryData();
    this.getJmlAnggotaKeluarga();
    extend("minId", {
      getMessage: (idsertifkatvaksin) => idsertifkatvaksin + "Minimal 24 digit",
      validate: (value) => {
        if (value.length >= 24) {
          return true;
        }
        return false;
      },
    });
  },
  mounted() {
    this.getAccountInfo();
  },
};
</script>

<style src="./VaccineHistory.scss" lang="scss" scoped />
