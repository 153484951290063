import Vue from "vue";
import Router from "vue-router";
import authStore from "@/store/login";

import Layout from "@/components/Layout/Layout";
import Login from "@/pages/Login/Login";
import ErrorPage from "@/pages/Error/Error";

// Main
import DashboardPage from "@/pages/Dashboard/Dashboard";

// Account
import AccountPage from "@/pages/Account/Account";
import ForgotPasswordPage from "@/pages/ForgotPassword/ForgotPassword";
import FormComplaintPage from "@/pages/FormComplaint/FormComplaint";
import FormComplaintPageLocal from "@/pages/FormComplaint/FormComplaintlocal";
import ContactComplaint from "@/pages/FormComplaint/ContactComplaint";

/*****  BBI Apps  *****/
import ApplicationPage from "@/pages/Applications/Applications";

// Workflow
import WorkflowPageInbox from "@/pages/Applications/WorkFlow/WorkflowInbox";
import WorkflowPageOutbox from "@/pages/Applications/WorkFlow/WorkflowOutbox";
import WorkflowPageClose from "@/pages/Applications/WorkFlow/WorkflowClose";

// SCC
import AppsSCCDMSDocument from "@/pages/Applications/SCC/DMSDocument/DMSDocument";
// DMS LEGAL
import AppsDMSLegalPermit from "@/pages/Applications/SCC/DMSLEGAL/PermitType/DashboardPermitType";
import AppsDMSLegalInstitution from "@/pages/Applications/SCC/DMSLEGAL/Institution/DashboardInstitution";
import AppsDMSLegalLevel from "@/pages/Applications/SCC/DMSLEGAL/Level/DashboardLevel";
import AppsDMSLegalLocation from "@/pages/Applications/SCC/DMSLEGAL/Location/DashboardLocation";
import AppsDMSLegalReminder from "@/pages/Applications/SCC/DMSLEGAL/Reminder/DashboardReminder";
import AppsDMSLegalCategory from "@/pages/Applications/SCC/DMSLEGAL/Category/DashboardCategory";
import AppsDMSLegalDocument from "@/pages/Applications/SCC/DMSLEGAL/DocumentLegal/DashboardDocument";
import AppsDMSLegalDocumentReminder from "@/pages/Applications/SCC/DMSLEGAL/DocumentReminder/DashboardDocumentReminder"
// HR

import AppsHRHealthDeclarationPage from "@/pages/Applications/HR/HealthDeclaration/HealthDeclaration";
import AppsHRHealthPemantauanPage from "@/pages/Applications/HR/HealthPemantauan/HealthPemantauan";
import AppsHRHealthSelfDeclarationPage from "@/pages/Applications/HR/HealthSelfDeclaration/HealthSelfDeclaration";
import AppsHRHealthDailyPageV3 from "@/pages/Applications/HR/DailyHealth/DailyHealthV3";
import AppsHRHealthDailyPageV4 from "@/pages/Applications/HR/DailyHealth/DailyHealthV4";
import AppsHRVaccine from "@/pages/Applications/HR/VaccineHistory/VaccineHistory";
import AppsHRDailyHealthVerificationPage from "@/pages/Applications/HR/DailyHealth/DailyHealthVerification";
import AppsHRHealthHistoryPageV2 from "@/pages/Applications/HR/HealthHistory/HealthHistoryV2";
//PLM
// *** Master Param ***
import AppsMasterParamPage from "@/pages/Applications/PLM/MasterParameter";
import AppsMasterParamListPage from "@/pages/Applications/PLM/MasterParameterList";
// *** Master PLM ***
import AppsMasterPLMListPage from "@/pages/Applications/PLM/Master/MasterList";
// *** Product Design ***
import AppsProductDesignPage from "@/pages/Applications/PLM/ProductDesign/ProductDesign";
import AppsProductDesignListPage from "@/pages/Applications/PLM/ProductDesign/ProductDesignList";
import AppsProductDesignFormPage from "@/pages/Applications/PLM/ProductDesign/ProductDesignForm";

// *** Approve Design ***
import AppsApproveDesignListPage from "@/pages/Applications/PLM/ApprovalDesign/ApprovalDesignList";
import AppsApproveDesignFormPage from "@/pages/Applications/PLM/ApprovalDesign/ApprovalDesignForm";
// *** MD Product Design ***
import AppsProductDesignMDPage from "@/pages/Applications/PLM/MD/MDList";

//PLM2
// *** Product Design v2 ***
import AppsProductDesign2Page from "@/pages/Applications/PLM/ProductDesignV2/ProductDesign2";
import AppsProductDesign2FormPage from "@/pages/Applications/PLM/ProductDesignV2/ProductDesign2Form";
import AppsProductDesign2InputPage from "@/pages/Applications/PLM/ProductDesignV2/ProductDesignFormInput2";
import AppsProductDesignFormEditPage from "@/pages/Applications/PLM/ProductDesignV2/ProductDesignFormEdit";
import AppsProductDesign2ViewArticlePage from "@/pages/Applications/PLM/ProductDesignV2/ProductDesign2ViewArticle";
import AppsProductDesignFormEditImages from "@/pages/Applications/PLM/ProductDesignV2/ProductDesignFormEditImages";
// *** Material Development ***
import AppsMaterialDevPage from "@/pages/Applications/PLM/MaterialDevelopment/MaterialDevDashboard";
import AppsMaterialDevDetailPage from "@/pages/Applications/PLM/MaterialDevelopment/MaterialDevDetail";
import AppsMaterialDevFormPage from "@/pages/Applications/PLM/MaterialDevelopment/MaterialDevForm";
// *** Garment Development ***
import AppsGarmentDevPage from "@/pages/Applications/PLM/GarmentDevelopment/GarmentDevDashboard";
import AppsGarmentDevDetailPage from "@/pages/Applications/PLM/GarmentDevelopment/GarmentDevDetail";
import AppsGarmentDevFormPage from "@/pages/Applications/PLM/GarmentDevelopment/GarmentDevForm";
// *** Supplier Selection ***
import AppsSupplierSelectionDashboardPage from "@/pages/Applications/PLM/SupplierSelection/SupplierSelection";
import AppsSupplierSelectionFormPage from "@/pages/Applications/PLM/SupplierSelection/SupplierSelectionForm";
// *** Costing ***
import AppsCostingDashboardPage from "@/pages/Applications/PLM/Costing/Costing";
import AppsCostingFormPage from "@/pages/Applications/PLM/Costing/CostingForm";
// *** Size Spec ***
import AppsSizeSpecDashboardPage from "@/pages/Applications/PLM/SizeSpec/SizeSpec";
import AppsSizeSpecDetailPage from "@/pages/Applications/PLM/SizeSpec/SizeSpecDetail";
// *** Tech Pack ***
import AppsTechPack from "@/pages/Applications/PLM/ProductDesignV2/ProductDesign2TechPack";

// *** Master Supplier ***
import AppsMasterSupplierDashboard from "@/pages/Applications/PLM/MasterSupplier/MasterSupplierDashboard";

/*****  BBI Report *****/
import ReportPage from "@/pages/Reports/Reports";
// HR

import RptHRHealthDeclarationPageV2 from "@/pages/Reports/HR/HealthDeclarationReport/HealthDeclarationReportV2";
import RptHRHealthDeclarationPageV2AllBU from "@/pages/Reports/HR/HealthDeclarationReport/HealthDeclarationReportV2AllBU";
import RptHRRapidTestPage from "@/pages/Reports/HR/RapidTestReport/RapidTestReport";

import RptHRHealthHistoryPageV2 from "@/pages/Reports/HR/HealthHistoryReport/HealthHistoryReportV2";
import RptHRHealthDailyPageV3 from "@/pages/Reports/HR/DailyHealthReport/DailyHealthReportV3";
import RptHRHealthDailyPageV4 from "@/pages/Reports/HR/DailyHealthReport/DailyHealthReportV4";
import RptHRHealthDailyPageV5 from "@/pages/Reports/HR/DailyHealthReport/DailyHealthReportV5";
import RptHRHealthDailyPageAllBuV4 from "@/pages/Reports/HR/DailyHealthReport/DailyHealthReportAllBuV4";
import RptHRHealthDailyPageV3Location from "@/pages/Reports/HR/DailyHealthReport/DailyHealthReportV3Location";
import RptHRHealthDailyPageAllBUV2 from "@/pages/Reports/HR/DailyHealthReport/DailyHealthReportAllBUV2";
import RptHRVaccineHistory from "@/pages/Reports/HR/VaccineHistoryReport/VaccineHistoryReport";

import RptHRVaccineHistory2V2 from "@/pages/Reports/HR/VaccineHistoryReport/VaccineHistoryReport2V2";
// Sales
import RptSalesDailySalesPage from "@/pages/Reports/Sales/DailySalesReport/DailySalesReport";
import RptProductivityPage from "@/pages/Reports/Sales/Productivity/ProductivityReport";
import RptBrandProductivityPage from "@/pages/Reports/Sales/Brand/BrandProductivityReport";

// --- PPCM --- //
// Master Subcon
import PPCMMasterSubcon from "@/pages/Applications/PPCM/MasterSubcon/MasterSubcon";
// Input Order PPM
import DashboardPPCM from "@/pages/Applications/PPCM/InputPPM/DashboardPPCM";
import PPCMInputPPM from "@/pages/Applications/PPCM/InputPPM/InputPPM";
// Approve
import PPCInputApproval from "@/pages/Applications/PPCM/ApprovePPM/PPCInputApproval";
import DashboardApproval from "@/pages/Applications/PPCM/ApprovePPM/DashboardApproval";
// SubconDaily
import PPCInputSubcon from "@/pages/Applications/PPCM/SubconDaily/InputSubconDaily";
import DashboardSubcon from "@/pages/Applications/PPCM/SubconDaily/DashboardSubcon";
// Monitoring Detail
import PPCInputMonitoring from "@/pages/Applications/PPCM/MonitoringDetail/PPCInputMonitoring";
import DashboardMonitoring from "@/pages/Applications/PPCM/MonitoringDetail/DashboardMonitoring";
// Inspection
import DashboardInspection from "@/pages/Applications/PPCM/Inspection/DashboardInspection";
import PPCMFormInspection from "@/pages/Applications/PPCM/Inspection/PPCMInputInspection";
// Delivery
import DashboardDelivery from "@/pages/Applications/PPCM/Delivery/DashboardDelivery";
import PPCMInputDelivery from "@/pages/Applications/PPCM/Delivery/PPCMInputDelivery";
// Receive
import DashboardReceive from "@/pages/Applications/PPCM/Receive/DashboardReceive";
import PPCMInputReceive from "@/pages/Applications/PPCM/Receive/PPCMInputReceive";
// CloseIO
import CloseIO from "@/pages/Applications/PPCM/CloseIO/PPCMInputCloseIO";

// PPCM Adjustment
import PPCMDashboardAdjustment from "@/pages/Applications/PPCM/ProcessAdjustment/DashboardAdjustment";
import PPCMInputAdjustment from "@/pages/Applications/PPCM/ProcessAdjustment/InputAdjustment";
import PPCMDashboardApproveAdjustment from "@/pages/Applications/PPCM/ProcessAdjustment/DashboardApproveAdjustment";
import PPCMApproveAdjustment from "@/pages/Applications/PPCM/ProcessAdjustment/ApproveAdjustment";

// // SALES FORCE
import SFCMasterCustomer from "@/pages/Applications/SalesForce/MasterCustomer/MasterCustomer";
import SFCMasterCustomerApproval from "@/pages/Applications/SalesForce/MasterCustomer/MasterCustomerApprove";
import SFCMasterCustomerForm from "@/pages/Applications/SalesForce/MasterCustomer/MasterCustomerForm";

import SFCMonthlyActivityPlan from "@/pages/Applications/SalesForce/MonthlyActivityPlan/MonthlyActivityPlan";
import SFCMonthlyActivityPlanForm from "@/pages/Applications/SalesForce/MonthlyActivityPlan/MonthlyActivityPlanForm";

import SFCApprovalMonthly from "@/pages/Applications/SalesForce/ApprovalMonthly/ApprovalMonthly";
import SFCApprovalMonthlyForm from "@/pages/Applications/SalesForce/ApprovalMonthly/ApprovalMonthlyForm";

import SFCWeeklyActivityPlan from "@/pages/Applications/SalesForce/WeeklyActivityPlan/WeeklyActivityPlan";
import SFCWeeklyActivityPlanForm from "@/pages/Applications/SalesForce/WeeklyActivityPlan/WeeklyActivityPlanForm";

import SFCApprovalWeekly from "@/pages/Applications/SalesForce/ApprovalWeekly/ApprovalWeekly";
import SFCApprovalWeeklyForm from "@/pages/Applications/SalesForce/ApprovalWeekly/ApprovalWeeklyForm";

import SFCCustomerDistribution from "@/pages/Applications/SalesForce/CustomerDepHead/CustomerDepHead";
import SFCCustomerDistributionForm from "@/pages/Applications/SalesForce/CustomerDepHead/CustomerDepHeadForm";

import SFCSalesGroup from "@/pages/Applications/SalesForce/SalesGroup/SalesGroupForm";

import SFCActualActivity from "@/pages/Applications/SalesForce/ActualActivity/ActualActivity";
import SFCActualActivityForm from "@/pages/Applications/SalesForce/ActualActivity/ActualActivityForm";

import SFCListProject from "@/pages/Applications/SalesForce/ListProject/ListProject";
import SFCListActualProject from "@/pages/Applications/SalesForce/ListProject/ListActualProject";

import AppRelease from "@/pages/Applications/SalesForce/AppRelease/AppRelease";
import MobileAppRelease from "@/pages/Applications/General/MobileApps/AppRelease";

// REPORT
import SFCListReviewActualActivity from "@/pages/Applications/SalesForce/Report/ListReviewActualActivity";
import SFCReviewActualActivity from "@/pages/Applications/SalesForce/Report/ReviewActualActivity";
import SFCListReviewCustomer from "@/pages/Applications/SalesForce/Report/ListReviewCustomer";
import SFCReviewCustomer from "@/pages/Applications/SalesForce/Report/ReviewCustomer";
import SFCSalesRecapitulation from "@/pages/Applications/SalesForce/Report/SalesRecapitulation";
import SFCSalesProjection from "@/pages/Applications/SalesForce/Report/SalesProjection";

// Assigned SPG
import AssignSpg from "@/pages/Applications/SFA/AssignSpg"



Vue.use(Router);

function chkLogin(to, from, next) {
  if (authStore.getters.loggedIn) {
    next(); // allow to enter route
  } else {
    next("/login"); // go to '/login';
  }
}

export default new Router({
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/forgotpassword",
      name: "ForgotPasswordPage",
      component: ForgotPasswordPage,
    },
    {
      path: "/wbs",
      name: "FormComplaintPage",
      component: FormComplaintPage
    },
    {
      path: "wbs/contact",
      name: "ContactComplaint",
      component: ContactComplaint
    },
    {
      path: "/error",
      name: "Error",
      component: ErrorPage,
    },
    {
      path: "/sfc-app-release",
      name: "AppRelease",
      // component: () => import('@/pages/Applications/SalesForce/AppRelease/AppRelease'),
      component: AppRelease,
    },
    {
      path: "/app-release",
      name: "MobileAppRelease",
      // component: () => import('@/pages/Applications/General/MobileApps/AppRelease'),
      component: MobileAppRelease,
    },
    {
      path: "/app",
      beforeEnter: chkLogin,
      name: "Layout",
      component: Layout,
      children: [
        {
          path: "dashboard",
          name: "DashboardPage",
          component: DashboardPage,
        },
        {
          path: "workflow-inbox",
          name: "WorkflowPageInbox",
          component: WorkflowPageInbox,
        },
        {
          path: "workflow-outbox",
          name: "WorkflowPageOutbox",
          component: WorkflowPageOutbox,
        },
        {
          path: "workflow-close",
          name: "WorkflowPageClose",
          component: WorkflowPageClose,
        },
        {
          path: "account",
          name: "AccountPage",
          component: AccountPage,
        },
        {
          path: "wbs",
          name: "FormComplaintPageLocal",
          component: FormComplaintPageLocal
        },
        // APPLICATIONS
        {
          path: "applications",
          name: "ApplicationPage",
          component: ApplicationPage,
        },

        {
          path: "appshr-health-declaration",
          name: "AppsHRHealthDeclarationPage",
          component: AppsHRHealthDeclarationPage,
        },
        {
          path: "appshr-health-pemantauan-khusus",
          name: "AppsHRHealthPemantauanPage",
          component: AppsHRHealthPemantauanPage,
        },
        {
          path: "appshr-health-history-v2",
          name: "AppsHRHealthHistoryPageV2",
          component: AppsHRHealthHistoryPageV2,
        },
        {
          path: "appshr-health-self-declaration",
          name: "AppsHRHealthSelfDeclarationPage",
          component: AppsHRHealthSelfDeclarationPage,
        },
        // {
        //   path: "appshr-daily-health-v3",
        //   name: "AppsHRHealthDailyPageV3",
        //   component: AppsHRHealthDailyPageV3,
        // },
        {
          path: "appshr-daily-health-v4",
          name: "AppsHRHealthDailyPageV4",
          component: AppsHRHealthDailyPageV4,
        },
        {
          path: "appshr-vaccine",
          name: "AppsHRVaccine",
          component: AppsHRVaccine,
        },
        {
          path: "appshr-daily-health-verif",
          name: "AppsHRDailyHealthVerificationPage",
          component: AppsHRDailyHealthVerificationPage,
        },
        // PPC
        {
          path: "appsppcm-master-subcon",
          name: "PPCMMasterSubcon",
          component: PPCMMasterSubcon,
        },
        {
          path: "appsppcm-input-ppm",
          name: "PPCMInputPPM",
          component: PPCMInputPPM,
        },
        {
          path: "appsppcm-dashboard-ppcm",
          name: "DashboardPPCM",
          component: DashboardPPCM,
        },
        {
          path: "appsppcm-dashboard-subcon",
          name: "DashboardSubcon",
          component: DashboardSubcon,
        },
        {
          path: "appsppcm-dashboard-approval",
          name: "DashboardApproval",
          component: DashboardApproval,
        },
        {
          path: "appsppcm-input-approval",
          name: "PPCInputApproval",
          component: PPCInputApproval,
        },
        {
          path: "appsppcm-dashboard-monitoring",
          name: "DashboardMonitoring",
          component: DashboardMonitoring,
        },
        {
          path: "appsppcm-input-monitoring",
          name: "PPCInputMonitoring",
          component: PPCInputMonitoring,
        },
        {
          path: "appsppcm-input-subcon",
          name: "PPCInputSubcon",
          component: PPCInputSubcon,
        },
        {
          path: "appsppcm-dashboard-inspection",
          name: "DashboardInspection",
          component: DashboardInspection,
        },
        {
          path: "appsppcm-input-inspection",
          name: "PPCMFormInspection",
          component: PPCMFormInspection,
        },
        {
          path: "appsppcm-dashboard-delivery",
          name: "DashboardDelivery",
          component: DashboardDelivery,
        },
        {
          path: "appsppcm-input-delivery",
          name: "PPCMInputDelivery",
          component: PPCMInputDelivery,
        },
        {
          path: "appsppcm-dashboard-receive",
          name: "DashboardReceive",
          component: DashboardReceive,
        },
        {
          path: "appsppcm-input-receive",
          name: "PPCMInputReceive",
          component: PPCMInputReceive,
        },
        {
          path: "appsppcm-close-io",
          name: "CloseIO",
          component: CloseIO,
        },
        // PPCM Adjustment
        {
          path: "appsppcm-dashboard-adjustment",
          name: "DashboardAdjustment",
          component: PPCMDashboardAdjustment,
        },
        {
          path: "appsppcm-input-adjustment",
          name: "InputAdjustment",
          component: PPCMInputAdjustment,
        },
        {
          path: "appsppcm-dashboard-approval-adjustment",
          name: "DashboardApproveAdjustment",
          component: PPCMDashboardApproveAdjustment,
        },
        {
          path: "appsppcm-approve-adjustment",
          name: "ApproveAdjustment",
          component: PPCMApproveAdjustment,
        },
        // PLM
        // *** master param *** //
        {
          path: "appsplm-master-param",
          name: "AppsMasterParamPage",
          component: AppsMasterParamPage,
          props: true,
        },
        {
          path: "appsplm-master-param-list",
          name: "AppsMasterParamListPage",
          component: AppsMasterParamListPage,
        },
        // *** master PLM *** //
        {
          path: "appsplm-master-plm-list",
          name: "AppsMasterPLMListPage",
          component: AppsMasterPLMListPage,
        },
        // *** master Supplier ***
        {
          path: "appsplm-master-supplier",
          name: "AppsMasterSupplierDashboard",
          component: AppsMasterSupplierDashboard,
        },

        // *** product design *** //
        {
          path: "appsplm-product-design",
          name: "AppsProductDesignPage",
          component: AppsProductDesignPage,
        },
        {
          path: "appsplm-product-design-list",
          name: "AppsProductDesignListPage",
          component: AppsProductDesignListPage,
        },
        {
          path: "appsplm-product-design-form",
          name: "AppsProductDesignFormPage",
          component: AppsProductDesignFormPage,
          props: true,
        },
        {
          path: "appsplm-approve-design-list",
          name: "AppsApproveDesignListPage",
          component: AppsApproveDesignListPage,
          props: true,
        },
        {
          path: "appsplm-approve-design-form",
          name: "AppsApproveDesignFormPage",
          component: AppsApproveDesignFormPage,
          props: true,
        },
        {
          path: "appsplm-product-design2",
          name: "AppsProductDesign2Page",
          component: AppsProductDesign2Page,
          props: true,
        },
        {
          path: "appsplm-product-design2-form",
          name: "AppsProductDesign2FormPage",
          component: AppsProductDesign2FormPage,
          props: true,
        },
        {
          path: "appsplm-product-design-input",
          name: "AppsProductDesignFormInput2",
          component: AppsProductDesign2InputPage,
          props: true,
        },
        {
          path: "appsplm-product-design-edit",
          name: "AppsProductDesignFormEdit",
          component: AppsProductDesignFormEditPage,
          props: true,
        },
        {
          path: "appsplm-product-design2-article",
          name: "AppsProductDesign2ViewArticlePage",
          component: AppsProductDesign2ViewArticlePage,
          props: true,
        },
        {
          path: "appsplm-product-design-edit-images",
          name: "AppsProductDesignFormEditImages",
          component: AppsProductDesignFormEditImages,
          props: true,
        },
        {
          path: "appsplm-md",
          name: "AppsProductDesignMDPage",
          component: AppsProductDesignMDPage,
        },
        // Material Development
        {
          path: "appsplm-material-development",
          name: "AppsMaterialDevPage",
          component: AppsMaterialDevPage,
          props: true,
        },
        {
          path: "appsplm-material-development-detail",
          name: "AppsMaterialDevDetailPage",
          component: AppsMaterialDevDetailPage,
          props: true,
        },
        {
          path: "appsplm-material-development-form",
          name: "AppsMaterialDevFormPage",
          component: AppsMaterialDevFormPage,
          props: true,
        },
        //Garment Development
        {
          path: "appsplm-garment-development",
          name: "AppsGarmentDevPage",
          component: AppsGarmentDevPage,
          props: true,
        },
        {
          path: "appsplm-garment-development-detail",
          name: "AppsGarmentDevDetailPage",
          component: AppsGarmentDevDetailPage,
          props: true,
        },
        {
          path: "appsplm-garment-development-form",
          name: "AppsGarmentDevFormPage",
          component: AppsGarmentDevFormPage,
          props: true,
        },
        // Supplier Selection
        {
          path: "appsplm-supplier-selection",
          name: "AppsSupplierSelectionDashboardPage",
          component: AppsSupplierSelectionDashboardPage,
          props: true,
        },
        {
          path: "appsplm-supplier-selection-form",
          name: "AppsSupplierSelectionFormPage",
          component: AppsSupplierSelectionFormPage,
          props: true,
        },
        // Costring
        {
          path: "appsplm-costing",
          name: "AppsCostingDashboardPage",
          component: AppsCostingDashboardPage,
          props: true,
        },
        {
          path: "appsplm-costing-form",
          name: "AppsCostingFormPage",
          component: AppsCostingFormPage,
          props: true,
        },
        // Size Spec
        {
          path: "appsplm-size-spec",
          name: "AppsSizeSpecDashboardPage",
          component: AppsSizeSpecDashboardPage,
          props: true,
        },
        {
          path: "appsplm-size-spec-detail",
          name: "AppsSizeSpecDetailPage",
          component: AppsSizeSpecDetailPage,
          props: true,
        },
        // Tech Pack
        {
          path: "appsplm-tech-pack",
          name: "AppsTechPack",
          component: AppsTechPack,
          props: true,
        },
        // SCC DMS
        {
          path: "appsdms-document-v1",
          name: "AppsSCCDMSDocument",
          component: AppsSCCDMSDocument,
        },
        // DMS LEGAL
        {
          path:"dms/legal/permit-type",
          name:"AppsDMSLegalPermit",
          component: AppsDMSLegalPermit,
          props:true
        },
        {
          path:"dms/legal/institution",
          name:"AppsDMSLegalInstitution",
          component: AppsDMSLegalInstitution,
          props:true
        },
        {
          path:"dms/legal/level",
          name:"AppsDMSLegalLevel",
          component: AppsDMSLegalLevel,
          props:true
        },
        {
          path:"dms/legal/location",
          name:"AppsDMSLegalLocation",
          component:AppsDMSLegalLocation,
          props:true,
        },
        {
          path:"dms/legal/reminder",
          name:"AppsDMSLegalReminder",
          component:AppsDMSLegalReminder,
          props:true
        },
        {
          path:"dms/legal/document",
          name:"AppsDMSLegalDocument",
          component: AppsDMSLegalDocument,
          props:true
        },
        {
          path:"dms/legal/document-reminder",
          name:"AppsDMSLegalDocumentReminder",
          component:AppsDMSLegalDocumentReminder,
          props:true
        },
        {
          path:"dms/legal/category",
          name:"AppsDMSLegalCategory",
          component:AppsDMSLegalCategory,
        },
        {
          path: "dms/legal/department",
          name: "DepartmentMapping",
          component: () => import('@/pages/Applications/SCC/DMSLEGAL/DepartmentMapping/DashboardDepartment'),
        },
        {
          path: "dms/legal/department-pic",
          name: "DepartmentPIC",
          component: () => import('@/pages/Applications/SCC/DMSLEGAL/DepartmentMapping/DepartmentPIC'),
        },
        // REPORTS
        {
          path: "reports",
          name: "ReportPage",
          component: ReportPage,
        },
        // REPORT - HR

        {
          path: "rpthr-health-declaration-v2",
          name: "RptHRHealthDeclarationPageV2",
          component: RptHRHealthDeclarationPageV2,
        },
        {
          path: "rpthr-health-declaration-v2-allbu",
          name: "RptHRHealthDeclarationPageV2AllBU",
          component: RptHRHealthDeclarationPageV2AllBU,
        },
        {
          path: "rpthr-monitoring",
          name: "RptHRRapidTestPage",
          component: RptHRRapidTestPage,
        },
        {
          path: "rpthr-health-history-v2",
          name: "RptHRHealthHistoryPageV2",
          component: RptHRHealthHistoryPageV2,
        },
        {
          path: "rpthr-daily-health-v3",
          name: "RptHRHealthDailyPageV3",
          component: RptHRHealthDailyPageV3,
        },
        {
          path: "rpthr-daily-health-v4",
          name: "RptHRHealthDailyPageV4",
          component: RptHRHealthDailyPageV4,
        },
        {
          path: "rpthr-daily-health-v5",
          name: "RptHRHealthDailyPageV5",
          component: RptHRHealthDailyPageV5,
        },
        {
          path: "rpthr-daily-health-all-bu-v4",
          name: "RptHRHealthDailyPageAllBuV4",
          component: RptHRHealthDailyPageAllBuV4,
        },
        {
          path: "rpthr-daily-health-v3-location",
          name: "RptHRHealthDailyPageV3Location",
          component: RptHRHealthDailyPageV3Location,
        },
        {
          path: "rpthr-vaccine-history",
          name: "RptHRVaccineHistory",
          component: RptHRVaccineHistory,
        },
        {
          path: "rpthr-vaccine-history-2-v2",
          name: "RptHRVaccineHistory2V2",
          component: RptHRVaccineHistory2V2,
        },
        {
          path: "rpthr-daily-health-v2-allbu",
          name: "RptHRHealthDailyPageAllBUV2",
          component: RptHRHealthDailyPageAllBUV2,
        },
        // REPORT - SALES
        {
          path: "rptsales-daily-sales",
          name: "RptSalesDailySalesPage",
          component: RptSalesDailySalesPage,
        },
        {
          path: "rptsales-productivity",
          name: "RptProductivityPage",
          component: RptProductivityPage,
        },
        {
          path: "rptsales-brand",
          name: "RptBrandProductivityPage",
          component: RptBrandProductivityPage,
        },
        // SALES FORCE
        {
          path: "sfc-master-customer",
          name: "SFCMasterCustomer",
          component: SFCMasterCustomer,
        },
        {
          path: "sfc-master-customer-approval",
          name: "SFCMasterCustomerApproval",
          component: SFCMasterCustomerApproval,
        },
        {
          path: "sfc-master-customer-form",
          name: "SFCMasterCustomerForm",
          component: SFCMasterCustomerForm,
        },
        {
          path: "sfc-monthly-activity-plan",
          name: "SFCMonthlyActivityPlan",
          component: SFCMonthlyActivityPlan,
        },
        {
          path: "sfc-monthly-activity-plan-form",
          name: "SFCMonthlyActivityPlanForm",
          component: SFCMonthlyActivityPlanForm,
        },
        {
          path: "sfc-approval-activity-monthly",
          name: "SFCApprovalMonthly",
          component: SFCApprovalMonthly,
        },
        {
          path: "sfc-approval-activity-monthly-form",
          name: "SFCApprovalMonthlyForm",
          component: SFCApprovalMonthlyForm,
        },
        {
          path: "sfc-weekly-activity-plan",
          name: "SFCWeeklyActivityPlan",
          component: SFCWeeklyActivityPlan,
        },
        {
          path: "sfc-weekly-activity-plan-form",
          name: "SFCWeeklyActivityPlanForm",
          component: SFCWeeklyActivityPlanForm,
        },
        {
          path: "sfc-approval-activity-weekly",
          name: "SFCApprovalWeekly",
          component: SFCApprovalWeekly,
        },
        {
          path: "sfc-approval-activity-weekly-form",
          name: "SFCApprovalWeeklyForm",
          component: SFCApprovalWeeklyForm,
        },
        {
          path: "sfc-customer-distribution",
          name: "SFCCustomerDistribution",
          component: SFCCustomerDistribution,
        },
        {
          path: "sfc-customer-distribution-form",
          name: "SFCCustomerDistributionForm",
          component: SFCCustomerDistributionForm,
        },
        // REPORT
        {
          path: "sfc-list-review-actual-activity",
          name: "SFCListReviewActualActivity",
          component: SFCListReviewActualActivity,
        },
        {
          path: "sfc-review-actual-activity",
          name: "SFCReviewActualActivity",
          component: SFCReviewActualActivity,
        },
        {
          path: "sfc-list-review-customer",
          name: "SFCListReviewCustomer",
          component: SFCListReviewCustomer,
        },
        {
          path: "sfc-review-customer",
          name: "SFCReviewCustomer",
          component: SFCReviewCustomer,
        },
        {
          path: "rptpnl-summary",
          name: "RptPNLSummary",
          component: () => import('@/pages/Reports/PNL/PNLSummaryReport'),
        },
        {
          path: "rptpnl-detail",
          name: "RptPNLDetail",
          component: () => import('@/pages/Reports/PNL/PNLDetailReport'),
        },
        {
          path: "sfc-sales-group",
          name: "SFCSalesGroup",
          component: SFCSalesGroup,
        },
        {
          path: "sfc-actual-activity",
          name: "SFCActualActivity",
          component: SFCActualActivity,
        },
        {
          path: "sfc-actual-activity-form",
          name: "SFCActualActivityForm",
          component: SFCActualActivityForm,
        },
        {
          path: "sfc-list-project",
          name: "SFCListProject",
          component: SFCListProject,
        },
        {
          path: "sfc-list-actual-project",
          name: "SFCListActualProject",
          component: SFCListActualProject,
        },
        {
          path: "rptbi-chart",
          name: "RptBIChart",
          component: () => import('@/pages/Reports/BI/BI.vue'),
        },
        {
          path: "sfc-review-sales-recap",
          name: "SFCSalesRecapitulation",
          component: SFCSalesRecapitulation,
        },
        {
          path: "sfc-sales-projection",
          name: "SFCSalesProjection",
          component: SFCSalesProjection,
        },
        {
          path: "assigned-spg",
          name: "AssignSpg",
          component: AssignSpg
        }
      ],
    },
  ],
});
