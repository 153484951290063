import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiSizeSpec,
    fnApiPostSizeSpecHeader,
    fnApiPostSizeSpecDetail,
    fnApiPutSizeSpecHeader,
}from '../../api/api-aplications-product.js'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        ST_SIZESPEC_DASHBOARD:[],
        ST_SIZESPEC_DETAIL_DASHBOARD:[],
        ST_SIZESPEC_HEADER:[],
        ST_SIZESPEC_DETAIL:[],
    }
}

const state = getDefaultState()

const getters = {
    SIZESPEC_DASHBOARD(state){
        return state.ST_SIZESPEC_DASHBOARD
    },
    SIZESPEC_DETAIL_DASHBOARD(state){
        return state.ST_SIZESPEC_DETAIL_DASHBOARD
    },
    SIZESPEC_HEADER(state){
        return state.ST_SIZESPEC_HEADER
    },
    SIZESPEC_DETAIL(state){
        return state.ST_SIZESPEC_DETAIL
    }
}

const mutations = {
    RESET_STATE(state){
        state.ST_SIZESPEC_DETAIL_DASHBOARD = []
    },
    GET_SIZESPEC_DASHBOARD(state,item){
        state.ST_SIZESPEC_DASHBOARD = item
    },
    SET_SIZESPEC_HEADER(state,item){
        state.ST_SIZESPEC_HEADER = item
        console.log('mut',state.ST_SIZESPEC_HEADER);
    },
    SET_SIZESPEC_DETAIL(state,item){
        state.ST_SIZESPEC_DETAIL = item
    },
    SAVE_SIZESPEC_DETAIL_DASHBOARD(state,item){
        state.ST_SIZESPEC_DETAIL_DASHBOARD.push(item)
    },
    DELETE_SIZESPEC_DETAIL(state,id){
        state.ST_SIZESPEC_DETAIL_DASHBOARD.splice(id,1)
        console.log(state.ST_SIZESPEC_DETAIL_DASHBOARD);
    }
    
}

const actions = {
    RESET_STATE({commit}){
        commit('RESET_STATE')
    },
    SET_SIZESPEC_HEADER({commit},item){
        console.log('act',item);
        commit('SET_SIZESPEC_HEADER',item)
    },
    GET_SIZESPEC_DASHBOARD({commit}){
        return new Promise((resolve, reject)=>{
            fnApiSizeSpec()
            .then(response => {
                resolve(response)
                commit('GET_SIZESPEC_DASHBOARD', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_SIZESPEC_HEADER(context, sizeSpecHeader){
        return new Promise((resolve,reject)=>{
            fnApiPostSizeSpecHeader(sizeSpecHeader)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    PUT_SIZESPEC_HEADER(context, sizeSpecHeader){
        return new Promise((resolve,reject)=>{
            fnApiPutSizeSpecHeader(sizeSpecHeader)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_SIZESPEC_DETAIL(context, sizeSpecDetail){
        return new Promise((resolve, reject)=>{
            fnApiPostSizeSpecDetail(sizeSpecDetail)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    SAVE_SIZESPEC_DETAIL_DASHBOARD({commit},item){
        console.log('item',item);
        commit('SAVE_SIZESPEC_DETAIL_DASHBOARD',item)
    },
    DELETE_SIZESPEC_DETAIL({commit},id){
        console.log('actions',id);
        commit('DELETE_SIZESPEC_DETAIL',id)
    }
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}