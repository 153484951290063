<template>
  <div class="table-basic">
    <b-container fluid="sm">
      <b-row align-h="center">
        <b-col cols="12" sm="12" lg="7">
          <b-card header="Form Whistleblower" header-tag="header">
            <template v-slot:header>
              <div class="text-center">
                <div class="banner-container">
                  <b-row>
                    <b-col sm="12" md="12" lg="6">
                      <div class="bbi-banner-right"></div>
                    </b-col>
                    <!-- <b-col sm="12" md="12" lg="6">
                      <div class="aoi-banner-left"></div>
                    </b-col> -->
                    <b-col sm="12" md="12" lg="6">
                      <div class="aoi-banner-left"></div>
                    </b-col>
                  </b-row>
                </div>
                <h3 style="font-weight:600">WBS Form</h3>
                <!-- <h3>{{ countDown }}</h3> -->
                <p>
                  Harap diisi dengan JUJUR dan sesuai kondisi yg ada demi kebaikan bersama
                  <br/>
                  <i>Please fill in HONESTLY and according to existing conditions for the common good</i>
                </p>
              </div>
            </template>

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(onSubmit)"
                enctype="multipart/form-data"
              >
                <b-row align-h="center">
                  <!-- <b-col cols="12" sm="auto"> </b-col> -->
                  <b-col cols="12" sm="auto">
                     <!-- input kategori pelapor -->
                    <validation-provider
                      name="kategori_pelapor"
                      vid="kategori_pelapor_model"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label for="" v-html="editlabelKategoriPelapor"></label>
                        <hr />
                        <b-form-radio-group
                          v-model="kategori_pelapor_model"
                          ref="warning_kategori_pelapor"
                          :options="options_kategori_pelapor"
                          name="kategori_pelapor"
                          stacked
                          checked=""
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-kategori-pelapor"
                        >
                        </b-form-radio-group>

                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-kategori-pelapor"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input kategori pelapor -->
                     <!-- input nama perusahaan -->
                    <!-- <validation-provider
                      name="nama_perusahaan"
                      :rules="{ required: !checkKategoriLapor() }"
                      v-slot="validationContext"
                    > -->
                    <b-form-group
                      cols="6"
                      label-for="inputTempatTujuan"
                      aria-describedby="input-nama-perusahaan"
                    >
                      <label for="" v-html="editlabelNamaPerusahaan"></label>
                      <b-form-input
                        name="nama_perusahaan"
                        ref="warning_nama_perusahaan"
                        :disabled="checkKategoriLapor()"
                        id="inputTempatTujuan"
                        v-model="form.nama_perusahaan"
                        trim
                      >
                      </b-form-input>
                      <!-- <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-nama-perusahaan"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        > -->
                    </b-form-group>
                    <!-- </validation-provider> -->
                    <!-- /input nama perusahaan -->
                    <!-- input nama -->
                    <validation-provider
                      name="nama"
                      :rules="{
                        required: true,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-group cols="6" aria-describedby="input-nama">
                        <label for="" v-html="editlabelName"></label>
                        <b-form-input
                          id="inputNama"
                          v-model="form.nama"
                          ref="warning_nama"
                          :state="getValidationState(validationContext)"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-nama">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input nama -->
                    <!-- input alamat -->
                    <validation-provider
                      name="alamat"
                      :rules="{
                        required: true,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-group cols="6" aria-describedby="input-alamat">
                        <label for="" v-html="editlabelAlamat"></label>
                        <b-form-textarea
                          id="inputAlamat"
                          v-model="form.alamat"
                          ref="warning_alamat"
                          :state="getValidationState(validationContext)"
                          trim
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-alamat">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- input alamat -->
                    
                    <!-- input no hp -->
                    <validation-provider
                      name="number"
                      :rules="{
                        required: true,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        cols="6"
                        label="No Handphone"
                        label-for="inputNumber"
                        aria-describedby="input-nomor-handphone"
                      >
                        <b-form-input
                          id="inputNumber"
                          v-model="form.number"
                          ref="warning_number"
                          @keypress="isNumber($event)"
                          :state="getValidationState(validationContext)"
                          type="tel"
                          oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-nomor-handphone">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input no hp -->
                    <!-- input email -->
                    <validation-provider
                      name="email"
                      :rules="{
                        required: true,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-group cols="6" aria-describedby="input-email">
                        <label for="" v-html="editlabelEmail"></label>
                        <b-form-input
                          id="inputEmail"
                          v-model="form.email"
                          ref="warning_email"
                          type="email"
                          :state="getValidationState(validationContext)"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-email">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input email -->
                    <br />
                    <h3 style="font-weight:600" class="form-wbs">WHISTLEBLOWER STATUS</h3>
                    <br />

                    <!-- input anonim -->
                    <validation-provider
                      name="anonimity"
                      vid="anonim"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label
                          for=""
                          v-html="editlabelWhistleblowerStatus"
                        ></label>
                        <hr />
                        <b-form-radio-group
                          :options="options_anonims"
                          name="anonimity"
                          v-model="anonim_model"
                          stacked
                          ref="warning_anonimity"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-anonim"
                        >
                        </b-form-radio-group>

                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-anonim"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input anonim -->
                    <br />
                    <h3 style="font-weight:600" class="form-wbs">KATEGORI & KETERANGAN WHISTLEBLOWER</h3>
                    <h3 style="font-weight:600" class="form-wbs"><i>THE WHISTLEBLOWER CATEGORIZATION & INFORMATION</i></h3>
                    <br />

                    <!-- input anonim -->
                    <validation-provider
                      name="kat_kecurangan"
                      vid="anonim"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label for="" v-html="editlabelKategoriFraud"></label>
                        <b-form-radio-group
                          :options="options_kategori_kecurangan"
                          name="kat_kecurangan"
                          v-model="kat_kecurangan_model"
                          stacked
                          ref="warning_kat_kecurangan"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-anonim"
                        >
                        </b-form-radio-group>

                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-anonim"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input anonim -->
                    <!-- input anonim -->
                    <validation-provider
                      name="kat_kecurangan"
                      vid="anonim"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label
                          for=""
                          v-html="editlabelKategoriKodeEtik"
                        ></label>
                        <b-form-radio-group
                          :options="options_kategori_kodeetik"
                          name="kat_kecurangan"
                          v-model="kat_kecurangan_model"
                          stacked
                          ref="warning_kat_kecurangan"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-anonim"
                        >
                        </b-form-radio-group>

                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-anonim"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input anonim -->
                    <!-- input anonim -->
                    <validation-provider
                      name="kat_kecurangan"
                      vid="anonim"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label
                          for=""
                          v-html="editlabelKategoriKodeProsedur"
                        ></label>
                        <b-form-radio-group
                          :options="options_kategori_prosedur"
                          name="kat_kecurangan"
                          v-model="kat_kecurangan_model"
                          stacked
                          ref="warning_kat_kecurangan"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-anonim"
                        >
                        </b-form-radio-group>

                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-anonim"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input anonim -->

                    <!-- input keterangan whistleblower lainnya -->
                    <validation-provider
                      key="1"
                      v-if="!checkLainnya()"
                      name="keterangan_whistleblower_lainnya"
                      :rules="{ required: !checkLainnya() }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        cols="6"
                        ref="inpt_keterangan_whistleblower_lainnya"
                        label-for="inputKondisiLainnya"
                        aria-describedby="input-kondisi-lainnya-feedback"
                      >
                        <b-form-input
                          class="form-kondisi-lainnya"
                          name="keterangan_whistleblower_lainnya"
                          :disabled="checkLainnya()"
                          :state="getValidationState(validationContext)"
                          id="inputKondisiLainnya"
                          v-model="form.kondisiKetWbsLainnya"
                          ref="refkondisiKetWbsLainnya"
                          trim
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-kondisi-lainnya-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>

                    <!-- input tanggal -->
                    <validation-provider
                      name="tanggal"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label for="" v-html="editlabelDate"></label>
                        <b-input-group
                          class="sm-3"
                          aria-describedby="input-tanggal-feedback"
                        >
                          <b-form-input
                            id="tanggal"
                            v-model="form.tanggal"
                            ref="warning_tanggal"
                            type="text"
                            autocomplete="off"
                            :state="getValidationState(validationContext)"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <b-form-datepicker
                              v-model="form.tanggal"
                              button-only
                              right
                              aria-controls="tanggal"
                              :max="max"
                              :date-format-options="{
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                              }"
                              :state="getValidationState(validationContext)"
                            ></b-form-datepicker>
                          </b-input-group-append>
                          <b-form-invalid-feedback
                            id="input-tanggal-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input tanggal -->

                    <!-- input deskripsi -->
                    <validation-provider
                      name="deskripsi"
                      :rules="{
                        required: true,
                      }"
                      v-slot="validationContext"
                    >
                      <b-form-group cols="6" aria-describedby="input-deskripsi">
                        <label for="" v-html="editlabelDescription"></label>

                        <b-form-textarea
                          id="inputDeskripsi"
                          v-model="form.deskripsi"
                          ref="warning_deskripsi"
                          :state="getValidationState(validationContext)"
                          trim
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                        <b-form-invalid-feedback id="input-deskripsi">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- input desripsi -->
                    <br />
                    <validation-provider
                      name="inputImagesAccesories"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label for="" v-html="editlabelUpload"></label>

                        <b-form-file
                          multiple
                          ref="fileInput"
                          id="inputImagesAccesories"
                          v-model="form.files"
                          no-drop
                          placeholder="Choose file here..."
                          accept=".jpg, .png, .pdf,.mp4,.mp3"
                          v-on:change="onFileChange"
                        ></b-form-file>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-checkbox-jawaban2"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>

                    <br />
                    <div>
                      Semua informasi yang diberikan akan kami simpan dan
                      rahasiakan<br />
                      <i>
                        All information provided will be stored and treated as
                        strictly confidential.
                      </i>
                    </div>
                    <br />
                    <div>
                      Perusahaan menghargai laporan yang benar dan dapat
                      dipertanggungjawabkan.<br />
                      <i
                        >The company appreciate correct and accountable report.
                      </i>
                    </div>
                    <!-- input checkbox jawaban -->
                    <!-- <validation-provider
                      name="checkbox_jawaban1"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <label v-html="editlabelTermAndCond"></label>
                        <hr />
                        <b-form-checkbox
                          v-model="form.checkbox_jawaban1"
                          name="checkbox_jawaban1"
                          ref="warning_checkbox_jawaban1"
                          value="ya"
                          stacked
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-checkbox-jawaban1"
                        >
                          Semua informasi yang diberikan akan kami simpan dan
                          rahasiakan. / All provided information will be stored
                          and withheld.
                        </b-form-checkbox>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-checkbox-jawaban1"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider> -->
                    <!-- /input checkbox jawaban -->
                    <!-- input checkbox jawaban 2-->
                    <!-- <validation-provider
                      name="checkbox_jawaban2"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <b-form-checkbox
                          v-model="form.checkbox_jawaban2"
                          name="checkbox_jawaban2"
                          ref="warning_checkbox_jawaban2"
                          value="yas"
                          stacked
                          checked="true"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-checkbox-jawaban2"
                        >
                          Perusahaan menghargai laporan yang benar dan dapat
                          dipertanggungjawabkan / Company appreciate if you send
                          correct and accountable report.
                        </b-form-checkbox>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-checkbox-jawaban2"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider> -->
                    <!-- /input checkbox jawaban 2-->
                    <!-- <br /> -->

                    <!-- <div class="custom-file mb-3">
                      <input
                        multiple
                        type="file"
                        class="custom-file-input"
                        id="
                        "
                        ref="document_io"
                        accept=".doc, .docx, .ppt, .pptx, .pdf, .jpg, .mp4, .mp3"
                        v-on:change="onChangeFileUpload()"
                      />
                      <label class="custom-file-label" for="document_io">{{
                        file
                      }}</label>
                    </div>
                    <div style="color: red">
                      <strong>{{ loginForm.pleaseUploadFIle }}</strong>
                    </div> -->
                    <br />
                    <vue-recaptcha
                      @verify="markRecaptchaAsVerified"
                      sitekey="6LdsZ-EhAAAAAPnYCEewM6dsUIeSQTP-ncGR02F9"
                    >
                    </vue-recaptcha>

                    <div div style="color: red">
                      <strong>{{
                        loginForm.pleaseTickRecaptchaMessage
                      }}</strong>
                    </div>
                    <br />

                    <b-button
                      block
                      @click="FocusWarning()"
                      type="submit"
                      variant="primary"
                      id="submitbutton"
                      :disabled="buttonDisabled"
                      >Submit</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
      <Loader
        v-if="isLoadingData"
        fullPage
        useOverlay
        loader="spinner"
      ></Loader>
    </b-container>
  </div>
</template>

<script>
import { fnGETWBS, fnPOSTWBS } from "../../api/api-methods";
import Loader from "@/components/Loader/Loader";
import { APIPATH_WBS } from "../../api/api-paths";

import { extend } from "vee-validate";
import { uuid } from "vue-uuid";
import VueRecaptcha from "vue-recaptcha";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Vue from "vue";

export default {
  name: "FormComplaint",
  components: {
    VueRecaptcha,
    Loader,
  },

  data() {
    let titleInfo = `WHISTLEBLOWING SYSTEM`;
    //  let textInfo = `
    //         <br/>
    //         <p style="text-align:justify;text-justify:inter-word;"><i>Whistleblowing System</i> (WBS) merupakan sarana pelaporan bagi seluruh pihak terkait BBI Group (insan karyawan BBI Group, <i>buyer/customer & supplier</i>) untuk melaporkan adanya indikasi pelanggaran yang terjadi di lingkungan BBI Group. Whistleblowing System merupakan salah satu bagian pilar dari strategi <i>anti-fraud</i> yaitu pilar deteksi dalam mengidentifikasi kejadian <i>fraud</i> di Perusahaan.</p>
    //         <p style="text-align:justify;text-justify:inter-word;"><i>Whistleblowing System (WBS) is a reporting platform for all BBI Group stakeholders (BBI Group employees, buyer/customer & supplier) for violation indication within the BBI Group. Whistleblowing System is one of the anti-fraud strategies which is the pillar of fraud detection within the company.</i></p>
    //         <br/>
    //         <h3 style="color:red;padding-bottom:20px" align="left">Kebijakan Whistleblowing System/<br/><i>Whistleblowing System Policy :</i></h3>
    //         <table>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">1. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Pelapor whistleblowing system adalah seluruh Insan karyawan BBI Group dan seluruh pemangku kepentingan <i>(stakeholders)</i> Perusahaan/<br/><i>Whistleblower reporters are all of employees and stakeholders of BBI Group.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">2. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Perusahaan melindungi dan menjaga kerahasian pelapor/<br/><i>The company protects and ensure anonymity of the reporters.</i></td>
    //           </tr>
    //         </table>
    //         <br/>
    //         <br/>
    //         <h3 style="color:red;padding-bottom:20px;" align="left" >Kategori Indikasi Pelanggaran yang Dapat Dilaporkan Melalui Sarana <i>Whistleblowing System</i>/<br/><i>Violation Indication Category Reportable Through the Whistleblowing System :</i></h3>
    //         <h5 style="padding-bottom:10px;" align="left"> <b style="font-weight: 600;">Kecurangan</b>/<i>Fraud</i> </h5>
    //         <table>
    //           <!---<tr style='background:none'>
    //             <td style="min-width:30px">1. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Pencurian / Thievery.</td>
    //           </tr>-->
    //           <tr style='background:none'>
    //             <td style="min-width:30px">1. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Gratifikasi/Gratification.</td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">2. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Penyalahgunaan wewenang/Abuse of authority.</td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">3. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Kecurangan atas laporan keuangan/Fraud on Financial Report.</td>
    //           </tr>
    //         </table>
    //         <br/>
    //         <h5 style="padding-bottom:10px;" align="left"> <b style="font-weight: 600;">Pelanggaran Kode Etik Perusahaan/</b><br> <i>Company Code of Ethics Violations :</i> </h5>
    //         <table>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">1. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Penggunaan kekerasan/<i>Use of violence.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">2. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Pemerasan/<i>Extortion.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">3. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Penggunaan narkoba/<i>Drugs abuse.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">4. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Pelecehan, tindakan asusila/<i>Sexual harassment,immoral actions.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">5. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Menyembunyikan, menghilangkan data atau dokumen perusahaan/<i>Elimination, Concealment of data and corporate documents.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">6. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Menghancurkan dokumen asli perusahaan yang bersifat penting/<i>Destruction of important company records.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">7. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Membocorkan informasi perusahaan terhadap pihak di luar perusahaan/<i>Information leakage to external party.</i></td>
    //           </tr>
    //         </table>
    //         <br/>
    //         <h5 style="padding-bottom:10px;" align="left"> <b style="font-weight: 600;">Penyimpangan Aturan dan Prosedur/</b><br><i>Violation of rules & procedures : </i></h5>
    //         <table>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">1. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Pelanggaran kebijakan, peraturan perusahaan, SOP/<i>Violation of company policies, rules and SOPs.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">2. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Perbuatan yang membahayakan kesehatan & keselamatan kerja/<i>Unsafe actions that impact occupational health & safety.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">3. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Perbuatan yang membahayakan keamanan perusahaan/<i>Unsafe actions that jeopardize company viability.</i></td>
    //           </tr>
    //         </table>
    //         <br/>
    //         <h3 align="left" style="color:red;padding-bottom:20px;">Kerahasiaan Pelapor/<br/><i>Reporter’s Anonymity</i></h3>
    //         <p align="left">Sebagai wujud komitmen BBI Group untuk menjaga kerahasiaan data pelaporan dari pihak-pihak yang tidak berkepentingan, maka manajemen memberikan/<i>As a commitment of BBI Group to ensure the confidentiality of reporter’s information from unrelated parties, the management provides:</i></p>
    //         <table>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">1. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Perlindungan atas kerahasiaan identitas pelapor./<i>Protection of reporter’s identity confidentiality.</i></td>
    //           </tr>
    //           <tr style='background:none'>
    //             <td style="min-width:30px">2. </td>
    //             <td style="text-align:justify;text-justify:inter-word">Perlindungan atas kerahasiaan isi laporan yang disampaikan./<i>Protection of reported information confidentiality.</i></td>
    //           </tr>
    //         </table>
    //         <br/> `;

    // Vue.swal.fire({
    //   icon: "info",
    //   title: "WHISTLEBLOWING SYSTEM",
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   width: "800px",
    //   html: textInfo,
    // })

    // Vue.use(VueSweetalert2);

    const now = new Date();

    return {
      uuid: uuid.v4(),
      isLoadingData: false,
      editlabelWhistleblowerStatus: `
      Apakah anda ingin melaporkan ini secara anonim (tanpa membuka identitas anda)? /<br/>
      <i>Do you want to report this anonymously (without revealing identity)?.</i>
      `,
      editlabelName: `
      Nama Lengkap / <i>Fullname</i>
      `,
      editlabelEmail: `
      Email </i>
      `,
      editlabelAlamat: `
      Alamat Lengkap / <i>Full Address</i>
      `,
      editlabelKategoriPelapor: `
      Kategori Pelapor / <i>Informant category</i> </i>
      `,
      editlabelNamaPerusahaan: `
      Nama Perusahaan / <i>Company name</i> </i>
      `,
      editlabelDate: `
      Tanggal Kejadian / <i>Occurrence Date</i> </i>
      `,
      editlabelKategoriPelanggaran: `
      Kategori pelanggaran yang dilakukan. <br/>
      <i>The category of offense committed.</i>
      `,
      editlabelKategoriFraud: `
      <b>Kecurangan</b> / <i>Fraud</i>`,
      editlabelKategoriKodeEtik: `
      <b>Pelanggaran Kode Etik Perusahaan </b>/
      <i>Corporate Ethics Code violations</i>`,
      editlabelKategoriKodeProsedur: `
      <b>Penyimpangan aturan dan prosedur </b>/
      <i>Violation of rules & procedures</i>`,
      editlabelUpload: `
      Unggah File / <i>Upload Files</i> (Max. 10 MB)
      `,
      editlabelDescription: `
      Deskripsi / <i>Description</i>
      `,
      editlabelTermAndCond: `
      Syarat & Ketentuan / <i>Terms & Conditions.</i>
      `,
      options_keterangan_whistleblower: [
        {
          id: 1,
          value: "Korupsi",
          html: " Korupsi / <i>Corruption</i><br/><br/>",
        },
        {
          id: 2,
          value: "Kecurangan",
          html: " Kecurangan / <i>Fraud</i><br/><br/> ",
        },
        {
          id: 3,
          html: "Perbuatan melanggar hukum (termasuk pencurian,	penggunaan kekerasan terhadap karyawan atau pimpinan,	pemerasan, penggunaan narkoba, pelecehan, perbuatan	kriminal lainnya).<br><i>Unlawful acts (including theft, the use of violence against	employees or leaders, extortion, illegal drug use, abuse and	other crime act).</i><br><br>",
          value: "Perbuatan melanggar hukum ",
        },
        {
          id: 4,
          html: "Pelanggaran ketentuan perpajakan, atau peraturan	perundang-undangan lainnya (lingkungan hidup, mark-up,	under invoice, ketenagakerjaan, dll)<br><i>Taxes or other legislation violations (environmental, mark-up,	under invoice, labor, etc).</i><br><br>",
          value: "Pelanggaran ketentuan perpajakan",
        },
        {
          id: 5,
          html: "Pelanggaran Pedoman Etika Perusahaan atau pelanggaran norma-norma kesopanan pada umumnya.<br><i>Corporate Ethics Code or  decency norms  violations, in general.</i><br><br/>",
          value: "Pelanggaran Etika Perusahaan",
        },
        {
          id: 6,
          html: "Perbuatan yang membahayakan keselamatan & kesehatan kerja, atau membahayakan keamanan perusahaan.<br><i>Endanger acts that can impact to safety and occupational health or jeopardize the security of the company.</i><br/><br/> ",
          value: "Perbuatan yang membahayakan keselamatan & kesehatan kerja",
        },
        {
          id: 7,
          html: "Perbuatan yang dapat menimbulkan kerugian finansial atau non-finansial terhadap perusahaan atau merugikan	kepentingan perusahaan.<br><i>Actions that may cause financial or non-financial losses to	the company or detriment of the interests of company.</i><br/><br/>",
          value: "kerugian finansial atau non-finansial",
        },
        {
          id: 8,
          html: "Pelanggaran prosedur operasi standar (SOP) perusahaan,	terutama terkait dengan pengadaan barang dan jasa, pemberian manfaat dan remunerasi.<br><i>Company Standard Operating Procedures (SOP) violations, especially related to the procurement of goods and services, the provision of benefits and remunerations.</i><br/><br/>",
          value: "Pelanggaran (SOP)",
        },
        {
          id: 9,
          html: "Penyuapan / <i>Bribery</i><br/><br/>",
          value: "Penyuapan",
        },
        { id: 10, html: "Lainnya / <i>Other</i><br/><br/>", value: "lainnya" },
      ],
      filesUrl: [],
      errorMessage: null,
      loginForm: {
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: "",
        pleaseUploadFIle: "",
      },
      form: {
        nama: null,
        tanggal: null,
        kondisiKetWbsLainnya: "",
        kategori_keterangan_whistleblower: null,
        // checkbox_jawaban1: null,
        // checkbox_jawaban2: null,
        lokasi: null,

        kategori_pelapor: null,
        anonim: null,
        nama_perusahaan: null,
      },
      received_at: null,
      usingOtp: false,
      countDown: 10,
      max: now,
      buttonDisabled: false,
      keterangan_whistleblower_model: [],
      anonim_model: "",
      kat_kecurangan_model: "",
      kategori_pelapor_model: "Eksternal",
      posts: {},
      document_io: null,
      file: "Choose File",

      options_kategori_pelapor: [
        {
          html: "Pihak Eksternal Perusahaan / <i>Eksternal</i>  ",
          value: "Eksternal",
        },
        {
          html: "Pihak Internal Perusahaan / <i>Internal</i>  ",
          value: "Internal",
        },
      ],
      options_anonims: [
        { html: "Ya / <i>Yes</i>  ", value: "Ya" },
        { html: "Tidak / <i>No</i> ", value: "Tidak" },
      ],
      options_kategori_kecurangan: [
        // { html: "Pencurian / <i>Thievery</i>", value: "Pencurian" },
        // {
        //   html: "Korupsi, Gratifikasi / <i>Corruption, Gratification</i>  ",
        //   value: "Korupsi, Gratifikasi",
        // },
        {
          html: "Gratifikasi / <i>Gratification</i>  ",
          value: "Gratifikasi",
        },
        {
          html: "Penyalahgunaan wewenang / <i>Abuse of authority</i>  ",
          value: "Penyalahgunaan wewenang",
        },
        {
          html: "Kecurangan atas laporan keuangan / <i>Fraud on financial report</i>  ",
          value: "Kecurangan atas laporan keuangan ",
        },
      ],
      options_kategori_kodeetik: [
        {
          html: "Penggunaan kekerasan / <i>Use of violence</i>",
          value: "Penggunaan kekerasan",
        },
        {
          html: "Pemerasan / <i>Extortion</i>  ",
          value: "Pemerasan",
        },
        {
          html: "Penggunaan narkoba / <i>Drugs abuse</i>  ",
          value: "Penggunaan narkoba",
        },
        {
          html: "Pelecehan, tindakan asusila / <i>Sexual harassment, immoral actions </i>  ",
          value: "Pelecehan, tindakan asusila ",
        },
        {
          html: "Menyembunyikan, menghilangkan data atau dokumen perusahaan / <br/> <i>Elimination, concealment of data and corporate documents</i>  ",
          value: "Menyembunyikan, menghilangkan data atau dokumen perusahaan ",
        },
        {
          html: "Menghancurkan dokumen asli perusahaan yang bersifat penting / <br/> <i>Destruction of important company records</i>  ",
          value: "Menghancurkan dokumen asli perusahaan yang bersifat penting",
        },
        {
          html: "Membocorkan informasi perusahaan terhadap pihak di luar perusahaan / <br/> <i>Information leakage to external party</i>  ",
          value: "Pelecehan, tindakan asusila",
        },
      ],
      options_kategori_prosedur: [
        {
          html: "Pelanggaran kebijakan, peraturan perusahaan, SOP / <i>Violation of company policies, rules and SOPs</i>  ",
          value: "Pelanggaran kebijakan, peraturan perusahaan, SOP",
        },
        {
          html: "Perbuatan yang membahayakan kesehatan & keselamatan kerja / <i>Unsafe actions that impact occupational health & safety.</i>  ",
          value: "Perbuatan yang membahayakan kesehatan & keselamatan kerja",
        },
        {
          html: "Perbuatan yang membahayakan keamanan perusahaan / <i>Unsafe actions that jeopardize company viability</i>  ",
          value: "Perbuatan yang membahayakan keamanan perusahaan",
        },
        {
          html: "<b>Lainnya</b> / <i>Others</i>  ",
          value: "Lainnya",
        },
      ],
    };
  },
  methods: {
    isNumber: function(evt) {
      if (!/^[0-9]+$/.test(evt.key) || evt.key === '.') return evt.preventDefault();
      if(evt.keyCode === 46){
        evt.preventDefault();
      }
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true
      }
    },
    getUsingOTP() {
      fnGETWBS(APIPATH_WBS.getusingemailotp)
        .then((rsp) => {
          // Vue.set(this, "items", rsp.data.payload);
          if (rsp.data.payload[0].booleans == "No") {
            this.usingOtp = true;
          } else {
            this.usingOtp = true;
          }
        })
        .catch((err) => {
          return err;
        });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          document.getElementById(
            "submitbutton"
          ).innerText = `Submit kembali dalam ${this.countDown} detik`;
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else if (this.countDown == 0) {
        setTimeout(() => {
          document.getElementById(
            "submitbutton"
          ).innerText = `Submit kembali dalam 0 detik`;
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        document.getElementById("submitbutton").innerText = `Submit`;
        this.buttonDisabled = false;
      }
    },
    markRecaptchaAsVerified(response) {
      this.loginForm.pleaseTickRecaptchaMessage = "";
      this.loginForm.recaptchaVerified = true;
    },

    isDisabled() {
      if (this.buttonDisabled) {
        // return false;
        this.buttonDisabled = false;
      } else {
        this.buttonDisabled = true;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    checkLainnya() {
      if (this.kat_kecurangan_model == "Lainnya") {
        return false;
      }
      this.form.kondisiKetWbsLainnya = "";
      return true;
    },

    checkKategoriLapor() {
      if (this.kategori_pelapor_model == "Eksternal") {
        return false;
      }
      this.form.nama_perusahaan = "-";
      return true;
    },
    FocusWarning() {
      if (this.getNama == null || this.getNama == "") {
        this.$refs.warning_nama.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_nama.$el.classList.value =
          this.$refs.warning_nama.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.warning_nama.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (
        this.kategori_pelapor_model == null ||
        this.kategori_pelapor_model == ""
      ) {
        this.$refs.warning_kategori_pelapor.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_kategori_pelapor.$el.classList.value =
          this.$refs.warning_kategori_pelapor.$el.classList.value +
          " animation-shake";
        setTimeout(() => {
          this.$refs.warning_kategori_pelapor.$el.classList.remove(
            "animation-shake"
          );
        }, 1500);
        return false;
      }
      // if (
      //   this.kategori_pelapor_model == "Eksternal" &&
      //   this.form.nama_perusahaan == ""
      // ) {
      //   this.$refs.warning_nama_perusahaan.$el.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "nearest",
      //   });
      //   this.$refs.warning_nama_perusahaan.$el.classList.value =
      //     this.$refs.warning_nama_perusahaan.$el.classList.value +
      //     " animation-shake";
      //   setTimeout(() => {
      //     this.$refs.warning_nama_perusahaan.$el.classList.remove(
      //       "animation-shake"
      //     );
      //   }, 1500);
      //   return false;
      // }
      if (this.form.number == null || this.form.number == "") {
        this.$refs.warning_number.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_number.$el.classList.value =
          this.$refs.warning_number.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.warning_number.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.form.email == null || this.form.email == "") {
        this.$refs.warning_email.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_email.$el.classList.value =
          this.$refs.warning_email.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.warning_email.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.anonim_model == null || this.anonim_model == "") {
        this.$refs.warning_anonimity.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_anonimity.$el.classList.value =
          this.$refs.warning_anonimity.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.warning_anonimity.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (
        this.kat_kecurangan_model == null ||
        this.kat_kecurangan_model == ""
      ) {
        this.$refs.warning_kat_kecurangan.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_kat_kecurangan.$el.classList.value =
          this.$refs.warning_kat_kecurangan.$el.classList.value +
          " animation-shake";
        setTimeout(() => {
          this.$refs.warning_kat_kecurangan.$el.classList.remove(
            "animation-shake"
          );
        }, 1500);
        return false;
      }
      if (this.form.alamat == null || this.form.alamat == "") {
        this.$refs.warning_alamat.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_alamat.$el.classList.value =
          this.$refs.warning_alamat.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.warning_alamat.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      // if (
      //   this.keterangan_whistleblower_model == null ||
      //   this.keterangan_whistleblower_model == ""
      // ) {
      //   this.$refs.warning_keterangan_whistleblower.$el.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "nearest",
      //   });
      //   this.$refs.warning_keterangan_whistleblower.$el.classList.value =
      //     this.$refs.warning_keterangan_whistleblower.$el.classList.value +
      //     " animation-shake";
      //   setTimeout(() => {
      //     this.$refs.warning_keterangan_whistleblower.$el.classList.remove(
      //       "animation-shake"
      //     );
      //   }, 1500);
      //   return false;
      // }
      if (
        this.kat_kecurangan_model == "Lainnya" &&
        this.form.kondisiKetWbsLainnya == ""
      ) {
        this.$refs.refkondisiKetWbsLainnya.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.refkondisiKetWbsLainnya.$el.classList.value =
          this.$refs.refkondisiKetWbsLainnya.$el.classList.value +
          " animation-shake";
        setTimeout(() => {
          this.$refs.refkondisiKetWbsLainnya.$el.classList.remove(
            "animation-shake"
          );
        }, 1500);
        return false;
      }
      if (this.form.tanggal == null || this.form.tanggal == "") {
        this.$refs.warning_tanggal.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_tanggal.$el.classList.value =
          this.$refs.warning_tanggal.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.warning_tanggal.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.form.deskripsi == null || this.form.deskripsi == "") {
        this.$refs.warning_deskripsi.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.warning_deskripsi.$el.classList.value =
          this.$refs.warning_deskripsi.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.warning_deskripsi.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      // if (
      //   this.form.checkbox_jawaban1 == null ||
      //   this.form.checkbox_jawaban1 == ""
      // ) {
      //   this.$refs.warning_checkbox_jawaban1.$el.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "nearest",
      //   });
      //   this.$refs.warning_checkbox_jawaban1.$el.classList.value =
      //     this.$refs.warning_checkbox_jawaban1.$el.classList.value +
      //     " animation-shake";
      //   setTimeout(() => {
      //     this.$refs.warning_checkbox_jawaban1.$el.classList.remove(
      //       "animation-shake"
      //     );
      //   }, 1500);
      //   return false;
      // }
      // if (
      //   this.form.checkbox_jawaban2 == null ||
      //   this.form.checkbox_jawaban2 == ""
      // ) {
      //   this.$refs.warning_checkbox_jawaban2.$el.scrollIntoView({
      //     behavior: "smooth",
      //     block: "center",
      //     inline: "nearest",
      //   });
      //   this.$refs.warning_checkbox_jawaban2.$el.classList.value =
      //     this.$refs.warning_checkbox_jawaban2.$el.classList.value +
      //     " animation-shake";
      //   setTimeout(() => {
      //     this.$refs.warning_checkbox_jawaban2.$el.classList.remove(
      //       "animation-shake"
      //     );
      //   }, 1500);
      //   return false;
      // }
      if (this.form.files == null || this.form.files == "") {
        this.$refs.fileInput.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.fileInput.$el.classList.value =
          this.$refs.fileInput.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.fileInput.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
    },

    onSubmit() {
      if (
        this.form.nama_perusahaan == null ||
        this.form.nama_perusahaan == ""
      ) {
        this.form.nama_perusahaan = "-";
        // this.form.nama_perusahaan = "null";
      }
      if (!this.loginForm.recaptchaVerified) {
        this.loginForm.pleaseTickRecaptchaMessage = "Please tick recaptcha.";
        return false;
      }

      let objTemp = this.kat_kecurangan_model.toString();
      if (this.kat_kecurangan_model == "Lainnya") {
        if (this.$refs.refkondisiKetWbsLainnya.value != null) {
          objTemp = objTemp.replace("Lainnya", this.form.kondisiKetWbsLainnya);
        }
      }

      this.form.kat_kecurangan_model = objTemp;
      this.form.anonim = this.anonim_model;
      this.form.kategori_pelapor = this.kategori_pelapor_model;

      if (this.usingOtp) {
        fnPOSTWBS(APIPATH_WBS.generateotp, {
          uuid: this.uuid,
          email: this.form.email,
        });
        Vue.swal
          .fire({
            inputLabel: `Kode OTP dikimkan ke email anda (${this.form.email}), cek spam / junk apabila tidak ditemukan.`,
            input: "tel",
            showCancelButton: true,
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            customClass: {
              inputLabel: "inputlabelswalotp",
              input: "inputswalotp",
            },
            inputAttributes: { maxlength: 6 },
            inputPlaceholder: "kode otp (6 angka) ",
            preConfirm: (login) => {
              return (
                fnPOSTWBS(APIPATH_WBS.confirmotp, {
                  uuid: this.uuid,
                  otp: login,
                })
                  // return fetch(`https://api.github.com/users/${login}`)
                  .then((response) => {
                    console.log("masuk respon == ok");
                    console.log(response);
                    // return response.json();
                  })
                  .catch((error) => {
                    // Vue.swal.disableButtons();

                    console.log("masuk catch");
                    Vue.swal.showValidationMessage(
                      `Pesan Error : ${error.response.data.message}`
                    );
                    if (error.response.data.max_retry) {
                      Vue.swal.fire(
                        "Anda sudah 3x salah memasukkan OTP.",
                        "Silahkan submit kembali laporan anda dan masukkan OTP yang sesuai.",
                        "error"
                      );
                      this.buttonDisabled = true;
                      this.countDown = 10;
                      this.countDownTimer();
                    }
                  })
              );
            },
            allowOutsideClick: () => !Vue.swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              (this.isLoadingData = true),
                fnPOSTWBS(APIPATH_WBS.datawbs, {
                  nama: this.form.nama,
                  kategori_pelapor: this.form.kategori_pelapor,
                  perusahaan: this.form.nama_perusahaan,
                  no_hp: this.form.number,
                  email: this.form.email,
                  anonim: this.form.anonim,
                  alamat: this.form.alamat,
                  files: this.form.files,
                  kat_keterangan: this.form.kat_kecurangan_model,
                  // kat_keterangan: this.form.kategori_keterangan_whistleblower,
                  tanggal_kejadian: this.form.tanggal,
                  deskripsi: this.form.deskripsi,
                  formcode: this.form.tanggal,
                  nik: null,
                  dept: null,
                  bu: null,
                })
                  .then((response) => {
                    const responseawbid = response.data;
                    if(this.filesUrl != null){
                      this.submitFiles(responseawbid);
                    }else{
                      let path = null
                      this.sentEmail(responseawbid, path);
                    }
                  })
                  .catch((err) => {
                    if (err.response.status === 400) {
                      console.log(err.response.data.message);
                      this.errorMessage = err.response.data.message;
                    }
                    setTimeout(() => {
                      this.isLoadingData = false;
                    }, 500);
                  });
            }
          });
      } else {
        (this.isLoadingData = true),
          fnPOSTWBS(APIPATH_WBS.datawbs, {
            nama: this.form.nama,
            kategori_pelapor: this.form.kategori_pelapor,
            perusahaan: this.form.nama_perusahaan,
            no_hp: this.form.number,
            email: this.form.email,
            anonim: this.form.anonim,
            alamat: this.form.alamat,
            files: this.form.files,
            kat_keterangan: this.form.kat_kecurangan_model,
            // kat_keterangan: this.form.kategori_keterangan_whistleblower,
            tanggal_kejadian: this.form.tanggal,
            deskripsi: this.form.deskripsi,
            formcode: this.form.tanggal,
            nik: null,
            dept: null,
            bu: null,
          })
            .then((response) => {
              const responseawbid = response.data;
              if(this.filesUrl != null){
                this.submitFiles(responseawbid);
              }else{
                let path = null
                this.sentEmail(responseawbid, path);
              }
            })
            .catch((err) => {
              if (err.response.status === 400) {
                console.log(err.response.data.message);
                this.errorMessage = err.response.data.message;
              }
              setTimeout(() => {
                this.isLoadingData = false;
              }, 500);
            });
      }
    },
    onFileChange(e) {
      this.filesUrl = [];
      var file = e.target.files;
      console.log(file);
      for (var i = 0; i < file.length; i++) {
        let files = {
          src: URL.createObjectURL(file[i]),
          fileName: file[i].name,
          file_detail: file[i],
          files_name: file[i].name,
          files_code: file[i].name + "-" + [i],
        };
      }
      let name_files = [];
      let calculate_files = 0;
      for (var k = 0; k < file.length; k++) {
        let data = {
          files: file[k],
        };
        calculate_files+=data["files"].size
        name_files+=data["files"].name         
        this.filesUrl.push(data);
      }
      if (calculate_files > 2024 * 5024) {
        alert("File " + name_files + " lebih besar dari 10 MB, kirim tanpa lampiran file ? ");
        // this.$refs["fileInput"].reset();
        this.filesUrl = null
      }
      // console.log(this.filesUrl.length);
      // console.log('calculation size',name_files)
    },
    submitFiles(responseawbid) {
      // upload multiple file
      // let fd1 = [];
      let fd = new FormData();
      for (let index = 0; index < this.filesUrl.length; index++) {
        fd.append("files", this.filesUrl[index].files);
        fd.append("file_label", responseawbid);
        // fd1.push({
        //   image: fd,
        // });
        console.log(this.filesUrl[index].files);
        console.log(responseawbid);
      }
      fnPOSTWBS(APIPATH_WBS.uploadfilewbsmultiple, fd)
        .then((response) => {
          if (response.data.status === 200) {
            const path = [];
            for (let j = 0; j < response.data.message.length; j++) {
              path.push(response.data.message[j]);
            }
            console.log(path);
            // console.log(response.data.message[0]);
            this.sentEmail(responseawbid, path);
            // this.getDataAcc();
          }
        })
        .catch((err) => {
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
          return err.response.data.message;
        });
      // return this.clearForm();
    },
    sentEmail(responseawbid, path) {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
      var time = today.getHours()+':'+today.getMinutes()+':'+today.getSeconds();
      let send_at = date + ' '+ time;
      this.received_at = send_at;

      fnPOSTWBS(APIPATH_WBS.postdataemail, {
        nama: this.form.nama,
        kategori_pelapor: this.form.kategori_pelapor,
        perusahaan: this.form.nama_perusahaan,
        no_hp: this.form.number,
        email: this.form.email,
        anonim: this.form.anonim,
        alamat: this.form.alamat,
        kat_keterangan: this.form.kat_kecurangan_model,
        // kat_keterangan: this.form.kategori_keterangan_whistleblower,
        tanggal_kejadian: this.form.tanggal,
        deskripsi: this.form.deskripsi,
        content: this.form.deskripsi,
        to: this.form.email,
        id_wbs: responseawbid,
        retries: 0,
        is_sent: false,
        received_at: this.received_at,
        send_at: send_at,
        code: 1,
        template: 1,
        fromm: "wbs@triputra-group.com",
        file_dir: path,
      })
        .then((response) => {
          fnPOSTWBS(APIPATH_WBS.sentconfirmemail, {
            id_wbs: responseawbid,
            template: 2,
            email: this.form.email,
          });
          fnPOSTWBS(APIPATH_WBS.sentemail, {
            id_wbs: responseawbid,
            receiver_param: 1,
          })
            .then((response) => {
              setTimeout(() => {
                this.isLoadingData = false;
                Vue.swal.fire({
                  icon: "success",
                  title: "Laporan sudah kami terima.",
                  html: "Terima kasih atas keberanian anda, laporan anda akan kami tindaklanjuti.",
                });
                this.$router.push({
                  name: "Login",
                });
              }, 500);
            })
            .catch((err) => {
              setTimeout(() => {
                this.isLoadingData = false;
              }, 500);
            });
        })
        .catch((err) => {
          if (err.response.status === 400) {
            console.log(err.response.data.message);
            this.errorMessage = err.response.data.message;
          }
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
      // var posts = JSON.parse(JSON.stringify(this.form));
      // console.log(posts);
    },
  },
  mounted: function () {
    // this.getAccountInfo();
  },
  // computed() {
  //   this.countDownTimer();
  // },
  created() {
    this.getUsingOTP();
    // this.countDownTimer();
  },
};
</script>
<style >
.swal2-container1 {
  display: block !important;
  font-weight: 400;
  font-size: 15px;
}
.swal2-checkbox {
  margin-left: 20px;
  margin-bottom: 8px !important;
  font-weight: 600 !important;
}
#inputNumber::-webkit-inner-spin-button,
#inputNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.inputswalotp::-webkit-inner-spin-button,
.inputswalotp::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.inputswalotp {
  max-width: 100em !important;
}
#inputKondisiLainnya,
#inputNama,
#inputNumber,
#inputEmail,
#inputAlamat,
#inputDeskripsi,
#inputTempatTujuan {
  font-weight: 500 !important;
  color: black !important;
}
.inputlabelswalotp {
  text-align: center !important;
}
@media only screen and (max-width: 480px) {
h3.form-wbs{
  font-size: 1.30em;
}
}
</style>

