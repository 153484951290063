import Vue from 'vue'
import Toast from 'vue-toasted'

export function setFocus(refField){
  refField.$el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
  refField.$el.classList.value = refField.$el.classList.value + ' animation-shake'
  setTimeout(() => {                        
      refField.$el.classList.remove('animation-shake');
  },1500)
}

export function showToastOK({type, message, theme='bubble', position='top-center'}) {
  Vue.use(Toast)
  Vue.toasted.show(message, {
    duration: 10000,
    type: type,
    theme: theme,
    position: position,
    keepOnHover: true,
    action : [{
      text : 'OK',
      onClick : (e, toastObject) => {
        toastObject.goAway(0);
      }
    }]
  })
}

