<template>
    <div class="healt-declaration">
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card
                        header="Form Deklarasi"
                        header-tag="header"

                    >
                    <template v-slot:header >
                        <div class="text-center">
                            <!-- <img src="../../assets/bbi-logo.png" width="40%" alt="Logo BBI"> -->
                            <h3>Form Deklarasi</h3>
                            <p>
                                Demi kesehatan dan keselamatan bersama di tempat kerja, 
                                anda harus JUJUR dalam menjawab pertanyaan di bawah ini. 
                                Anda perlu menjawab apakah dalam 14 hari terakhir, 
                                anda pernah melakukan hal-hal berikut:
                            </p>
                        </div>
                    </template>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.stop.prevent="handleSubmit(onSubmit)" >
                                <b-row align-h="center">
                                    <b-col  sm="auto" >

                                        <!-- input nama karyawan -->
                                            <b-form-group 
                                                label               ="Nama Karyawan"
                                                label-for           ="inputNama"
                                            >
                                                <b-form-input readonly id="inputName" v-model="form.nama_karyawan"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nama karyawan -->

                                        <!-- input department -->
                                            <b-form-group 
                                                label               ="Department"
                                                label-for           ="inputDept"
                                            >
                                                <b-form-input readonly id="inputDept" v-model="form.department"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input department -->


                                    </b-col>
                                    <b-col sm="auto" >

                                        <!-- input nik -->
                                            <b-form-group 
                                                label               ="Nomor Induk Karyawan"
                                                label-for           ="inputNik"
                                            >
                                                <b-form-input readonly id="inputNik" v-model="form.nik"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nik -->

                                        <!-- input tanggal -->
                                            <validation-provider
                                                name="tanggal"
                                                :rules="{ required: true}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group>
                                                    <label for="tanggal">Tanggal Hari Ini</label>
                                                    <b-input-group class="sm-3" aria-describedby= "input-tanggal-feedback">
                                                        <b-form-input
                                                            id="tanggal"
                                                            v-model="form.tanggal_harian"
                                                            type="text"
                                                            autocomplete="off"
                                                            :state="getValidationState(validationContext)"
                                                            readonly
                                                        ></b-form-input>
                                                        <b-input-group-append>
                                                            <b-form-datepicker
                                                                v-model="form.tanggal_harian"
                                                                button-only
                                                                right
                                                                aria-controls="tanggal"
                                                                :max="max"
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                :state="getValidationState(validationContext)"
                                                            ></b-form-datepicker>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback id="input-tanggal-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                    
                                                </b-form-group> 
                                            </validation-provider>
                                        <!-- /input tanggal -->


                                    </b-col>
                                </b-row>
                                <b-row >
                                    <b-col sm="6" offset-sm="3">

                                        <!-- input alamat -->
                                            <validation-provider
                                                name="alamat"
                                                :rules="{ required: true, min: 3}"
                                                v-slot="validationContext">

                                                <b-form-group 
                                                    label               ="Alamat Lengkap"
                                                    label-for           ="inputAlamat"
                                                    aria-describedby    ="input-alamat-feedback"
                                                >
                                                    <b-form-input id="inputAlamat" v-model="form.alamat" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="input-alamat-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input alamat -->

                                        <!-- input bepergian -->
                                            <validation-provider
                                                name="bepergian"
                                                vid="pergi"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah anda pernah keluar rumah / tempat umum (pasar, fasilitas pelayanan kesehatan, rumah ibadah, kerumunan orang, dan lain-lain) ?">
                                                            <b-form-radio-group

                                                                v-model= "form.keluar_rumah"
                                                                :options= "options_bepergian"
                                                                name= "bepergian"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-bepergian-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-bepergian-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input bepergian -->

                                        <!-- input tempat tujuan -->
                                            <validation-provider
                                                name="tempat_tujuan"
                                                :rules="{required:tempatTujuan()}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group cols="6"
                                                    label               ="Tempat Tujuan"
                                                    label-for           ="inputTempatTujuan"
                                                    aria-describedby    ="input-tempat-tujuan-feedback"
                                                >
                                                    <b-form-input 
                                                        name="tempat_tujuan" 
                                                        size="sm" 
                                                        :disabled="checkBepergian()" 
                                                        :state="getValidationState(validationContext)" 
                                                        id="inputTempatTujuan" 
                                                        v-model="form.tempat_tujuan" 
                                                        
                                                        trim>
                                                    </b-form-input>
                                                    <b-form-invalid-feedback id="input-tempat-tujuan-feedback" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input tempat tujuan -->

                                        <!-- input sering keluar -->
                                            <validation-provider
                                                name="sering_keluar"
                                                vid="sering_keluar"
                                                :rules="{ required:  {allowFalse: requiredStatus()}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apabila anda menjawab ya dipertanyaan sebelumnya, sebutkan seberapa sering anda pergi kesana">
                                                            <b-form-radio-group

                                                                v-model= "form.sering_keluar"
                                                                :options= "options_sering_keluar"
                                                                name= "sering_keluar"
                                                                :disabled="checkBepergian()"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-sering-keluar-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-sering-keluar-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input sering keluar -->

                                        <!-- input penerapan covid -->
                                            <validation-provider
                                                name="penerapan_covid"
                                                vid="penerapan_covid"
                                                :rules="{ required: {allowFalse: requiredStatus()}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apabila anda menjawab ya dipertanyaan pertama, apakah tempat tujuan menerapkan upaya pencegahan covid-19">
                                                            <b-form-radio-group

                                                                v-model= "form.penerapan_covid"
                                                                :options= "options_all"
                                                                name= "penerapan_covid"
                                                                :disabled="checkBepergian()"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-penerapan-covid-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-penerapan-covid-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input penerapan covid -->

                                        <!-- input transportasi -->
                                            <validation-provider
                                                name="transportasi"
                                                vid="transportasi"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah anda pernah menggunakan transportasi umum (taxi / ojek online / bis / KRL / busway / dll)">
                                                            <b-form-radio-group

                                                                v-model= "form.transportasi"
                                                                :options= "options_all"
                                                                name= "transportasi"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-transportasi-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-transportasi-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input transportasi -->

                                        <!-- input nama transportasi -->
                                            <validation-provider
                                                name="nama_transportasi"
                                                :rules="{required:!checkTransportasi()}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group cols="6"
                                                    label               ="Apabila ya, sebutkan transportasi apa yang anda gunakan"
                                                    label-for           ="inputNamaTransportasi"
                                                    aria-describedby    ="input-nama-transportasi-feedback"
                                                >
                                                    <b-form-input 
                                                        name="nama_transportasi" 
                                                        size="sm" 
                                                        :disabled="checkTransportasi()" 
                                                        :state="getValidationState(validationContext)" 
                                                        id="inputNamaTransportasi" 
                                                        v-model="form.nama_transportasi" 
                                                        
                                                        trim>
                                                    </b-form-input>
                                                    <b-form-invalid-feedback id="input-nama-transportasi-feedback" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input nama transportasi -->

                                        <!-- input keluar kota -->
                                            <validation-provider
                                                name="keluar_kota"
                                                vid="keluar_kota"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah anda pernah bepergian keluar kota / internasional ?">
                                                            <b-form-radio-group

                                                                v-model= "form.keluar_kota"
                                                                :options= "options_all"
                                                                name= "keluar_kota"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-keluar-kota-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-keluar-kota-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input keluar kota -->

                                        <!-- input tujuan keluar kota -->
                                            <validation-provider
                                                name="tujuan_keluar_kota"
                                                :rules="{required:!checkKeluarKota()}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group cols="6"
                                                    label               ="Bila anda pernah bepergian ke luar kota / internasional, sebutkan destinasi dan kapan"
                                                    label-for           ="inputTujuanKeluarKota"
                                                    aria-describedby    ="input-tujuan-keluar-kota-feedback"
                                                >
                                                    <b-form-input 
                                                        name="tujuan_keluar_kota" 
                                                        size="sm" 
                                                        :disabled="checkKeluarKota()" 
                                                        :state="getValidationState(validationContext)" 
                                                        id="inputTujuanKeluarKota" 
                                                        v-model="form.tujuan_keluar_kota" 
                                                        
                                                        trim>
                                                    </b-form-input>
                                                    <b-form-invalid-feedback id="input-tujuan-keluar-kota-feedback" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input tujuan keluar kota -->

                                        <!-- input kegiatan -->
                                            <validation-provider
                                                name="kegiatan"
                                                vid="kegiatan"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah anda mengikuti kegiatan yang melibatkan orang banyak ?">
                                                            <b-form-radio-group

                                                                v-model= "form.kegiatan"
                                                                :options= "options_all"
                                                                name= "kegiatan"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-kegiatan-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-kegiatan-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input kegiatan -->

                                        <!-- input nama kegiatan -->
                                            <validation-provider
                                                name="nama_kegiatan"
                                                :rules="{required:!checkKegiatan()}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group cols="6"
                                                    label               ="Apabila anda pernah mengikuti kegiatan yang melibatkan banyak orang, sebutkan nama kegiatannya, serta tanggal berapa"
                                                    label-for           ="inputNamaKegiatan"
                                                    aria-describedby    ="input-nama-kegiatan-feedback"
                                                >
                                                    <b-form-input 
                                                        name="nama_kegiatan" 
                                                        size="sm" 
                                                        :disabled="checkKegiatan()" 
                                                        :state="getValidationState(validationContext)" 
                                                        id="inputNamaKegiatan" 
                                                        v-model="form.nama_kegiatan" 
                                                        
                                                        trim>
                                                    </b-form-input>
                                                    <b-form-invalid-feedback id="input-nama-kegiatan-feedback" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input nama kegiatan -->

                                        <!-- input riwayat_covid -->
                                            <validation-provider
                                                name="riwayat_covid"
                                                vid="riwayat_covid"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label="Apakah anda memiliki riwayat kontak erat 
                                                            dengan orang yang dinyatakan ODP,PDP atau 
                                                            konfirm COVID-19 (berjabat tangan, berbicara, 
                                                            berada dalam satu ruangan dengan jarak < 1m / satu rumah)">
                                                            <b-form-radio-group

                                                                v-model= "form.riwayat_covid"
                                                                :options= "options_all"
                                                                name= "riwayat_covid"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-riwayat-covid-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-riwayat-covid-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input riwayat_covid -->

                                        <!-- input sakit 14hari -->
                                            <validation-provider
                                                name="sakit_14hari"
                                                vid="sakit_14hari"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah pernah mengalami demam/ batuk/pilek/ sakit tenggorokan/sesak dalam 14 hari terakhir.">
                                                            <b-form-radio-group

                                                                v-model= "form.sakit_14hari"
                                                                :options= "options_all"
                                                                name= "sakit_14hari"
                                                                stacked
                                                                :state="getValidationState(validationContext)"
                                                                aria-describedby    ="input-sakit-14hari-feedback"

                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-sakit-14hari-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input sakit 14hari -->

                                        <!-- input nama sakit 14hari -->
                                            <validation-provider
                                                name="nama_sakit_14hari"
                                                :rules="{required:!checkSakit14()}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group cols="6"
                                                    label               ="Apabila ya, sakit apa dan sudah berapa lama ?"
                                                    label-for           ="inputNamaSakit14hari"
                                                    aria-describedby    ="input-nama-sakit-14hari-feedback"
                                                >
                                                    <b-form-input 
                                                        name="nama_sakit_14hari" 
                                                        size="sm" 
                                                        :disabled="checkSakit14()" 
                                                        :state="getValidationState(validationContext)" 
                                                        id="inputNamaSakit14hari" 
                                                        v-model="form.nama_sakit_14hari" 
                                                        
                                                        trim>
                                                    </b-form-input>
                                                    <b-form-invalid-feedback id="input-nama-sakit-14hari-feedback" >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input nama sakit 14hari -->



                                    </b-col>
                                </b-row>
                                <b-row class="justify-content-sm-center" >
                                    <b-col col sm="8">
                                        <!-- input checkbox jawaban -->
                                            <validation-provider
                                                name="jawaban_deklarasi"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Saya bertanggung jawab terhadap setiap jawaban yang saya berikan pada form ini untuk dapat dipergunakan sesuai dengan kebutuhannya.">
                                                    <b-form-checkbox

                                                        v-model= "form.jawaban_deklarasi"
                                                        name= "jawaban_deklarasi"
                                                        value="ya"
                                                        stacked
                                                        :state="getValidationState(validationContext)"
                                                        aria-describedby    ="input-jawaban-deklarasi-feedback"
                                                    >
                                                    Ya
                                                    </b-form-checkbox>
                                                    <b-form-invalid-feedback id="input-jawaban-deklarasi-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input checkbox jawaban -->
                                        <b-button block type="submit" variant="primary">Submit</b-button>
                                    </b-col>
                                </b-row>
                            </form>
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import apiDeclaration from '@/store/health-form/health-declaration.js';

    export default {
        name: 'HealthDeclaration',
        data() {
            const now = new Date()
            
            return {
                errorMessage: null,
                form: {
                    nama_karyawan       : null,
                    nik                 : null,
                    department          : null,
                    tanggal_harian      : null,
                    alamat              : null,
                    keluar_rumah        : null,
                    tempat_tujuan       : null,
                    sering_keluar       : null,     
                    penerapan_covid     : null,   
                    transportasi        : null,      
                    nama_transportasi   : null, 
                    keluar_kota         : null,       
                    tujuan_keluar_kota  : null,
                    kegiatan            : null,          
                    nama_kegiatan       : null,     
                    riwayat_covid       : null,     
                    sakit_14hari        : null,      
                    nama_sakit_14hari   : null, 
                    jawaban_deklarasi   : null, 
                    posting_date        : now      
                    
                },
                
                max: now,
                kondisi: [],
                pergi: '',
                posts:{},
                
                options_kondisi: [
                    { text: 'Batuk', value: 'batuk' },
                    { text: 'Pilek', value: 'pilek' },
                    { text: 'Sakit Tenggorokan', value: 'sakit tenggorokan' },
                    { text: 'Demam', value: 'demam' },
                    { text: 'Sehat', value: 'sehat'},
                    { text: 'Lainnya', value: 'lainnya' },
                ],

                options_bepergian: [
                    { text: 'Ya', value: 'ya' },
                    { text: 'Tidak', value: 'tidak' },
                ],

                options_sering_keluar: [
                    { text: '1x dalam seminggu', value: '1x dalam seminggu' },
                    { text: '2-3x dalam seminggu', value: '2-3x dalam seminggu' },
                    { text: '4x atau lebih dalam seminggu', value: '4x atau lebih dalam seminggu' },
                ],

                options_all: [
                    { text: 'Ya', value: 'ya' },
                    { text: 'Tidak', value: 'tidak' },
                ],

            }
        },
        
        methods: {
             ...mapActions('session', ['showNotification']),
            getAccountInfo() {
                let userData      = JSON.parse(localStorage.userData)
                this.$set(this.form, 'nama_karyawan', userData.fullname)
                this.$set(this.form, 'nik', userData.nik)
                if(userData.business_unit != 'BBI1') {
                    this.$set(this.form, 'department', userData.subdept)
                }
                else {
                    this.$set(this.form, 'department', userData.dept)
                }
                
            },
            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },

            checkBepergian(){
                if(this.form.keluar_rumah == 'ya'){
                    return false;
                }
                this.uncheckAll()
                return true
            },

            tempatTujuan(){
                if(this.form.keluar_rumah == 'ya' ){
                    return true;
                }
                this.form.tempat_tujuan = '';
                return false
            },

            checkTransportasi(){
                if(this.form.transportasi == 'ya'){
                    return false;
                }
                this.form.nama_transportasi = '';
                return true
            },

            checkKeluarKota(){
                if(this.form.keluar_kota == 'ya'){
                    return false;
                }
                this.form.tujuan_keluar_kota = '';
                return true
            },

            checkKegiatan(){
                if(this.form.kegiatan == 'ya'){
                    return false;
                }
                this.form.nama_kegiatan = '';
                return true
            },

            checkSakit14(){
                if(this.form.sakit_14hari == 'ya'){
                    return false;
                }
                this.form.nama_sakit_14hari = '';
                return true
            },

            requiredStatus(){
                if(this.form.keluar_rumah == 'ya' ){
                    return false;
                }else if(this.form.keluar_rumah == 'tidak' ){
                    return true;
                }
                return true
                
            },

            uncheckAll(){
                this.form.sering_keluar = false,
                this.form.penerapan_covid = false
            },

            onSubmit() {
                apiDeclaration.dispatch('postDeclaration', {
                    nama_karyawan       : this.form.nama_karyawan,
                    nik                 : this.form.nik,
                    department          : this.form.department,
                    tanggal_harian      : this.form.tanggal_harian,
                    alamat              : this.form.alamat,
                    keluar_rumah        : this.form.keluar_rumah,
                    tempat_tujuan       : this.form.tempat_tujuan,
                    sering_keluar       : this.form.sering_keluar,     
                    penerapan_covid     : this.form.penerapan_covid,   
                    transportasi        : this.form.transportasi,      
                    nama_transportasi   : this.form.nama_transportasi, 
                    keluar_kota         : this.form.keluar_kota,       
                    tujuan_keluar_kota  : this.form.tujuan_keluar_kota,
                    kegiatan            : this.form.kegiatan,          
                    nama_kegiatan       : this.form.nama_kegiatan,     
                    riwayat_covid       : this.form.riwayat_covid,     
                    sakit_14hari        : this.form.sakit_14hari,      
                    nama_sakit_14hari   : this.form.nama_sakit_14hari, 
                    jawaban_deklarasi   : this.form.jawaban_deklarasi, 
                    posting_date        : this.form.posting_date
                })
                    .then(response => {
                        console.log(response)
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                        this.$router.push({name:"DashboardPage"})
                    })
                    .catch(err => {
                        if (err.response.status === 400) {
                           
                            this.errorMessage = err.response.data.message;
                    }
                    })
            }
        },
        mounted: function(){
            this.getAccountInfo()
        },
    }
</script>