<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-delivery"> Dashboard Delivery</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">

                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Input Delivery Form</h3>
                                </div>
                            </div>
                        </template>


                                <b-row align-h="center">
                        <!-- data IO -->

                                <table class="table table-lg">
                                    <tbody>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Date Cutting</span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{formatToday}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Due Date </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.due_date}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">IO </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.data_io}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Subcon </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_subcon}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Article </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.artikel}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Sample </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.sample}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Plan </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.qty_plan}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Cutting </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_progress}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Shortship </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.qty_shortship}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Delivery </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.qty_packing}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Employee </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.Employee}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td id="table-td">

                                            </td>
                                            <td id="table-td">

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                </b-row>
                                <b-row>
                                <b-col xs="12">
                                    <div class="table-responsive">
                                        <center>
                                        <table class="table table-lg mb-0">
                                            <thead>
                                                <tr style="text-align:center; " class=text-table-th>
                                                    <th>
                                                        UOM
                                                    </th>
                                                    <th>
                                                        Qty
                                                    </th>
                                                    <th>
                                                        Delivery
                                                    </th>
                                                    <th>
                                                        Remaining
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="text-align:center; ">
                                                    <td style="background-color:#ffc247">Pcs</td>
                                                    <td style="background-color:#CCC">{{form.qty_pcs}}</td>
                                                    <td style="text-align:center;">
                                                        <validation-provider
                                                        name="qty_pcs"
                                                        >
                                                        <b-form-input class="select-custom" :disabled="alldisabled" type="number" id="qty_pcs" v-model="qty_pcs_actual"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>
                                                    <td style="background-color:#CCC">{{qty_remaining_pcs}}</td>
                                                </tr>

                                                <tr style="text-align:center; ">
                                                    <td style="background-color:#ffc247">Box</td>
                                                    <td style="background-color:#CCC">{{form.qty_box}}</td>
                                                    <td style="text-align:center;">
                                                        <validation-provider
                                                        name="qty_box"
                                                        >
                                                        <b-form-input class="select-custom" :disabled="alldisabled" type="number" id="qty_box" v-model="qty_box_actual"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>
                                                    <td style="background-color:#CCC">{{qty_remaining_box}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </center>
                                    </div>
                                </b-col>
                            </b-row>

                            <br />

                            <b-row  align-h="start">
                               <b-col sm="12">
                                   <table >
                                       <tbody>
                                   <td>
                                   Delivery to DC :
                                   </td>
                                   <td style="margin-left: 5px;position: relative;display: block; border: 1px solid rgb(136, 136, 136);border-collapse: inherit;border-left-width: thick;">
                                    <b-col sm="auto">
                                            <validation-provider
                                                name="deliver"
                                                vid="deliver"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="DateDeliver"
                                                label-for           ="DateDeliver">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.date_deliver"
                                                        no-header
                                                        :max-date="max_date"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        placeholder="Choose date"
                                                        />
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-deliver-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                </validation-provider>
                                      </b-col>
                                   </td>
                                        </tbody>
                                   </table>
                               </b-col>
                            </b-row>

                            <br />

                            <b-row>
                                <b-col xs="12">
                                    <div class="table-responsive">
                                        <table class="table table-lg mb-0">
                                            <thead>
                                                <tr v-if="stPPCMLogDeliveryDtCount !== 0" style="text-align:center; " class=text-table-th>
                                                    <th>
                                                        Date
                                                    </th>
                                                    <th>
                                                        Qty (Pcs)
                                                    </th>
                                                    <th>
                                                        Qty (Box)
                                                    </th>
                                                    <th>
                                                        Delivery To Dc
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="text-align:center;"
                                                    v-for="row in stPPCMLogDeliveryDt"
                                                    :key="row.id"
                                                    >
                                                    <td v-if="row.status !== 'Done'">
                                                        <span v-if="row.date_now !== null" v-html="FormatDate(row.date_now)">{{row.date_now}}</span>
                                                        <span v-if="row.date_now == null" v-html="FormatDate(row.date)">{{row.date}}</span>
                                                    </td>
                                                    <td v-if="row.status !== 'Done'">
                                                        {{row.qty_pcs_delivery}}
                                                    </td>
                                                    <td v-if="row.status !== 'Done'">
                                                        {{row.qty_box_delivery}}
                                                    </td>
                                                    <td v-if="row.status !== 'Done'" v-html="FormatDate(row.date)">
                                                        {{row.date}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                            <br />
                            <br />
                               <b-row  align-h="center">
                                   <b-col sm=3 class="text-center">
                                        <b-button block :disabled="alldisabled" @click="SubmitCompleted()" ref="completed" type="button" variant="warning">Completed</b-button>
                                    </b-col>
                                    <b-col sm=3 class="text-left">
                                    </b-col>
                                   <b-col sm=3 class="text-right">
                                        <b-button block :disabled="alldisabled" @click="Submit()" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm=3 class="text-right">
                                        <b-button block :disabled="alldisabled" @click="Cancel()" ref="cancel" type="button" variant="danger">Cancel</b-button>
                                    </b-col>
                                </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import PostLogDeliver from '@/store/ppm-form/ppm.js';
    import UpdateDelivery from '@/store/ppm-form/ppm.js';
    import UpdateReceive from '@/store/ppm-form/ppm.js';
    import UpdateInputPPM from '@/store/ppm-form/ppm.js';
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);

    export default {
       name: 'PPCMInputDelivery',

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                formName: 'Form Delivery PPCM',
                errorMessage: null,
                max_date:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                today:today,
                formatToday:null,
                oneDay:oneDay,
                qty_pcs_actual:0,
                qty_box_actual:0,
                statusPPM:null,
                alldisabled:false,
                    form : {
                    data_io:null,
                    Employee:null,
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    manufacture_date:null,
                    total_qty:null,
                    due_date:null,
                    cutting_subcon :null,
                    cutting_progress:null,
                    qty_packing:null,
                    qty_shortship:null,
                    qty_pcs:0,
                    qty_box:0,
                    qty_pcs_delivery:null,
                    qty_box_delivery:null,
                    tipe:null,
                    visit:null,
                    status:null,
                    datenow:today,
                    remaining_pcs:0,
                    remaining_box:0,
                    date_deliver:today,
                    start_date_delivery:null,
                    },
                    boxOne: '',
                    boxTwo: '',



            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stPPCMDelvrData','stPPCMDelvrDataCount','stPPCMLogDeliveryDt','stPPCMLogDeliveryDtCount','stIoPPM']),

             qty_remaining_pcs() {
                 let remaining = 0
                 remaining = this.form.qty_pcs - this.qty_pcs_actual
                //  console.log(remaining)
                return remaining;

             },
             qty_remaining_box() {
                 let remaining_box = 0
                 remaining_box = this.form.qty_box - this.qty_box_actual
                //  console.log(remaining)
                return remaining_box;

             },

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getPPCMDeliveryFormData','GetDataPPCMDeliveryLog','getInputIOPPM']),
           FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 500);
                }
            },
           Setdata_IO() {
                let data_IO      = this.$route.query.io
                // let userData     = this.userData
                // this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardDelivery'})
                }
                if (this.form.data_io != null) {
                    this.Loading('start')
                    this.getPPCMDeliveryFormData(this.form.data_io);
                    this.getInputIOPPM(this.form.data_io).then(this.GetdataForm()).catch(err => {
                        if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardDelivery"})
                        }
                    })
                    .finally(()=>{
                        this.Loading('stop')
                    })
                }
            },



            // OrderProcess() {
            //     let inline = document.getElementById("visit-inline");
            //     let final = document.getElementById("visit-final");
            //     let final_qty = document.getElementById("visit-final-qty");
            //     let final_qty2 = document.getElementById("visit-final-qty-2");
            //     if (this.form.type == 'inline') {
            //          inline.classList.remove("hidden");
            //          final.classList.add("hidden");
            //          final_qty.classList.add("hidden");
            //          final_qty2.classList.add("hidden");
            //     } else {
            //         inline.classList.add("hidden");
            //          final.classList.remove("hidden");
            //          final_qty.classList.remove("hidden");
            //          final_qty2.classList.remove("hidden");
            //     }
            // },
            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stPPCMDelvrDataCount == 0 || this.stPPCMDelvrDataCount == null || this.stIoPPM == null) {
                    this.DelayGetData()
                    return
                }
                let dataPPM = this.stIoPPM
                // Set Data v-model
                let formdata = this.stPPCMDelvrData
                this.form.artikel = formdata[0].artikel
                this.form.sample = formdata[0].sample
                this.form.Employee = formdata[0].user
                this.form.brand = formdata[0].brand
                this.form.start_date_delivery = formdata[0].datenow
                if (formdata[0].due_date !== null) {
                    this.form.due_date = this.FormatDate(formdata[0].due_date)
                }
                // console.log(this.form.start_date_delivery)
                this.formatToday = this.FormatDate(this.today)
                this.form.cutting_subcon = formdata[0].subcon
                this.form.cutting_progress = formdata[0].qty_cutting
                this.form.qty_plan = formdata[0].qty_plan
                this.form.qty_packing = formdata[0].qty_packing
                if (this.form.qty_packing == null) {
                    this.form.qty_packing = 0;
                }
                this.form.qty_shortship = formdata[0].qty_shortship
                if (this.form.qty_shortship == null) {
                    this.form.qty_shortship = 0;
                }
                this.form.qty_pcs = formdata[0].qty_pcs;
                this.form.qty_box = formdata[0].qty_box;
                this.form.qty_pcs_delivery = formdata[0].qty_pcs_delivery;
                this.form.qty_box_delivery = formdata[0].qty_box_delivery;

                if (this.form.qty_pcs_delivery == null || this.form.qty_pcs_delivery == 0) {
                    this.form.qty_pcs = this.form.qty_pcs
                } else {
                    this.form.qty_pcs = parseInt(this.form.qty_pcs) - parseInt(this.form.qty_pcs_delivery)
                }
                if (this.form.qty_box_delivery == null || this.form.qty_box_delivery == 0) {
                    this.form.qty_box = this.form.qty_box
                } else {
                    this.form.qty_box = parseInt(this.form.qty_box) - parseInt(this.form.qty_box_delivery)
                }
                this.form.tipe = formdata[0].tipe;
                this.form.visit = formdata[0].visit;
                this.form.status = formdata[0].status;

                this.GetDataPPCMDeliveryLog(this.form.data_io);

                if (this.form.status == 'Done') {
                    this.alldisabled = true;
                }

                this.statusPPM = this.stIoPPM[0].status

            },
            Cancel() {
                this.qty_pcs_actual = 0
                this.qty_box_actual = 0
            },
            SubmitCompleted() {
                 if (this.statusPPM !== 'ONGOING(Delivery)') {
                    this.showNotification({type:'error', message:'Pastikan Untuk Men-Completed Process Sebelumnya'})
                    return
                }
                this.boxTwo = ''
                    this.$bvModal.msgBoxConfirm('Are you want to complete the process?', {
                    title: 'Please Confirm',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    })
                    .then(value => {
                        this.boxTwo = String(value)
                        if (this.boxTwo == 'true') {
                            console.log('box: '+this.boxTwo)
                            this.Completed()
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })

            },

            Completed() {
                let qty_pcs = 0
                let qty_box = 0
                let qty_pcs_delivery = 0
                let qty_box_delivery = 0
                let tipe = null
                let visit = this.form.visit
                if (visit == null) {
                    visit = 0
                }
                let status = null
                let date = this.today

                qty_pcs = parseInt(this.form.qty_pcs) - parseInt(this.qty_pcs_actual)
                qty_box = parseInt(this.form.qty_box) - parseInt(this.qty_box_actual)
                qty_pcs_delivery = this.qty_pcs_actual
                qty_box_delivery = this.qty_box_actual
                tipe = 'Partial'
                status = 'Done'

                if (qty_pcs < 0 || isNaN(qty_pcs)) {
                    this.showNotification({type:'error', message:'Qty_Pcs Error'})
                    return
                }
                if (qty_box < 0 || isNaN(qty_box)) {
                    this.showNotification({type:'error', message:'Qty_Box Error'})
                    return
                }

                if (qty_pcs == 0 && visit == 0) {
                    tipe = 'Full'
                }

                visit = parseInt(visit) + 1;
                // show report after timeout min 1 second

                // PostLogDeliver.dispatch('PostDelivery', {
                // io:this.form.data_io,
                // user:this.form.employee,
                // qty_pcs:qty_pcs,
                // qty_box:qty_box,
                // qty_pcs_delivery:qty_pcs_delivery,
                // qty_box_delivery:qty_box_delivery,
                // tipe:tipe,
                // visit:visit,
                // status:status,
                // date:date,
                // })
                // .then(response => {
                // console.log(response)
                // })
                // .catch(err => {
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })


                let qty_pcs_deliveryput = this.form.qty_pcs_delivery
                let qty_box_deliveryput = this.form.qty_box_delivery

                if (qty_pcs_deliveryput == null) {
                    qty_pcs_deliveryput = 0
                }

                if (qty_box_deliveryput == null) {
                    qty_box_deliveryput = 0
                }

                let qty_pcs_deliveryupdate = parseInt(qty_pcs_deliveryput) + parseInt(this.qty_pcs_actual)
                let qty_box_deliveryupdate = parseInt(qty_box_deliveryput) + parseInt(this.qty_box_actual)
                let tipeupdate = tipe
                let visitupdate = visit
                let statusupdate = status
                let dateupdate_comp = this.today

                this.Loading('start')

                UpdateDelivery.dispatch('ApiUpdate_Delivery_V2', {
                    condition_process:'completed',
                    io:this.form.data_io,
                    status:statusupdate,
                    status_rec:'ONGOING(Receive)',
                    last_date:dateupdate_comp,
                })
                .then(response => {
                    // console.log(response)
                    this.$router.push({name:"DashboardDelivery"})
                    this.showNotification({type:'success', message:'Successfully Completed'})
                })
                .catch(err => {
                    if (err.response === 400) {
                        console.log(err.response.data.message)
                        this.showNotification({type:'error', message:err.response.data.message})
                    }
                })
                .finally(()=>{
                    this.Loading('stop')
                })

                // setTimeout(() => {
                // UpdateDelivery.dispatch('UpdateFormDelivery', {
                // io:this.form.data_io,
                // status:statusupdate,
                // last_date:dateupdate_comp,
                // })
                // .then(response => {
                //     loader.hide()
                // console.log(response)
                // this.$router.push({name:"DashboardDelivery"})
                // this.showNotification({type:'success', message:'Successfully Completed'})
                // })
                // .catch(err => {
                //     loader.hide()
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })
                // .finally(()=>{
                // loader.hide()
                // })
                // },3000)

                // UpdateInputPPM.dispatch('PutInputPPM', {
                // io:this.form.data_io,
                // status:'ONGOING(Receive)',
                // })
                // .then(response => {
                // // console.log(response)
                // })
                // .catch(err => {
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })

            },
            Submit() {
                if (this.qty_pcs_actual == null || this.qty_pcs_actual == 0 || this.qty_pcs_actual == '' || this.qty_pcs_actual == undefined) {
                    this.showNotification({type:'error', message:'Error Qty_Pcs'})
                    return
                }
                if (this.qty_box_actual == null || this.qty_box_actual == 0 || this.qty_box_actual == '' || this.qty_box_actual == undefined) {
                    this.showNotification({type:'error', message:'Error Qty_Box'})
                    return
                }
                let qty_pcs = 0
                let qty_box = 0
                let qty_pcs_delivery = 0
                let qty_box_delivery = 0
                let tipe = null
                let visit = this.form.visit
                if (visit == null) {
                    visit = 0
                }
                let status = null
                let date = this.form.date_deliver
                if (this.form.start_date_delivery == null) {
                    this.form.start_date_delivery = this.form.date_deliver
                }

                qty_pcs = parseInt(this.form.qty_pcs) - parseInt(this.qty_pcs_actual)
                qty_box = parseInt(this.form.qty_box) - parseInt(this.qty_box_actual)
                qty_pcs_delivery = this.qty_pcs_actual
                qty_box_delivery = this.qty_box_actual
                tipe = 'Partial'
                status = 'Ongoing'

                if (qty_pcs < 0 || isNaN(qty_pcs)) {
                    this.showNotification({type:'error', message:'Qty_Pcs Error'})
                    return
                }
                if (qty_box < 0 || isNaN(qty_box)) {
                    this.showNotification({type:'error', message:'Qty_Box Error'})
                    return
                }

                if (qty_pcs == 0 && visit == 0) {
                    tipe = 'Full'
                }

                visit = parseInt(visit) + 1;

                let qty_pcs_deliveryput = this.form.qty_pcs_delivery
                let qty_box_deliveryput = this.form.qty_box_delivery

                if (qty_pcs_deliveryput == null) {
                    qty_pcs_deliveryput = 0
                }

                if (qty_box_deliveryput == null) {
                    qty_box_deliveryput = 0
                }

                let qty_pcs_deliveryupdate = parseInt(qty_pcs_deliveryput) + parseInt(this.qty_pcs_actual)
                let qty_box_deliveryupdate = parseInt(qty_box_deliveryput) + parseInt(this.qty_box_actual)
                let tipeupdate = tipe
                let visitupdate = visit
                let statusupdate = status
                let dateupdate = this.form.date_deliver
                // console.log(qty_pcs_deliveryupdate)
                // console.log(qty_box_deliveryupdate)

                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value1 => {
                    this.boxTwo = String(value1)
                    if (this.boxTwo == 'true') {

                        this.Loading('start')

                        UpdateDelivery.dispatch('ApiUpdate_Delivery_V2', {
                            condition_process:'update',
                            io:this.form.data_io,
                            user:this.form.Employee,
                            qty_pcs:qty_pcs,
                            qty_box:qty_box,
                            qty_pcs_delivery:qty_pcs_deliveryupdate,
                            qty_box_delivery:qty_box_deliveryupdate,
                            qty_pcs_deliverylog:qty_pcs_delivery,
                            qty_box_deliverylog:qty_box_delivery,
                            tipe:tipe,
                            visit:visit,
                            status:status,
                            status_rec:status,
                            date:this.form.start_date_delivery,
                            last_date:date,
                            date_now:this.today,
                        })
                        .then(response => {
                            this.$router.push({name:"DashboardDelivery"})
                            this.showNotification({type:'success', message:'Successfully Save'})
                        })
                        .catch(err => {
                            if (err.response === 400) {
                                console.log(err.response.data.message)
                                this.showNotification({type:'error', message:err.response.data.message})
                            }
                        })
                        .finally(()=>{
                            this.Loading('stop')
                        })


            //   setTimeout(() => {
            //     PostLogDeliver.dispatch('PostDelivery', {
            //     io:this.form.data_io,
            //     user:this.form.Employee,
            //     qty_pcs:qty_pcs,
            //     qty_box:qty_box,
            //     qty_pcs_delivery:qty_pcs_delivery,
            //     qty_box_delivery:qty_box_delivery,
            //     tipe:tipe,
            //     visit:visit,
            //     status:status,
            //     date:this.form.start_date_delivery,
            //     last_date:date,
            //     date_now:this.today,
            //     })
            //     .then(response => {
            //     loader.hide()
            //     console.log(response)
            //     this.$router.push({name:"DashboardDelivery"})
            //     this.showNotification({type:'success', message:'Successfully Save'})
            //     })
            //     .catch(err => {
            //     loader.hide()
            //     if (err.response === 400) {
            //         console.log(err.response.data.message)
            //         this.showNotification({type:'error', message:err.response.data.message})
            //     }
            //     })
            //     .finally(()=>{
            //     loader.hide()
            //     })
            //     },3000)


            //     UpdateDelivery.dispatch('UpdateFormDelivery', {
            //     io:this.form.data_io,
            //     user:this.form.Employee,
            //     qty_pcs_delivery:qty_pcs_deliveryupdate,
            //     qty_box_delivery:qty_box_deliveryupdate,
            //     tipe:tipeupdate,
            //     visit:visitupdate,
            //     status:statusupdate,
            //     date:this.form.start_date_delivery,
            //     last_date:dateupdate,
            //     })
            //     .then(response => {
            //     console.log(response)
            //     })
            //     .catch(err => {
            //     if (err.response === 400) {
            //         console.log(err.response.data.message)
            //         this.showNotification({type:'error', message:err.response.data.message})
            //     }
            //     })

            //     UpdateReceive.dispatch('UpdateFormReceive', {
            //     io:this.form.data_io,
            //     qty_pcs:qty_pcs_deliveryupdate,
            //     qty_box:qty_box_deliveryupdate,
            //     status:status,
            //     })
            //     .then(response => {
            //     console.log(response)
            //     })
            //     .catch(err => {
            //     if (err.response === 400) {
            //         console.log(err.response.data.message)
            //         this.showNotification({type:'error', message:err.response.data.message})
            //     }
            //     })


                }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
        },
        created() {
            this.Setdata_IO()
        },

    }
</script>
<style src="../Inspection/PPCMInputInspection.scss" lang="scss" scoped />
