<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container fluid>
            <b-card no-body>
                <b-tabs align="center">
                    <b-tab title="Fabric" active lazy>
                        <master-fabric></master-fabric>
                    </b-tab>
                    <b-tab title="Accesories" lazy>
                        <master-accesories></master-accesories>
                    </b-tab>
                    <b-tab title="Thread" lazy>
                        <master-thread/>    
                    </b-tab>
                    <b-tab title="Artwork" lazy>
                        <master-artwork/>
                    </b-tab>
                    <b-tab title="Interlining" lazy>
                        <master-interlining/>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import MasterAccesories from './MasterAccesories.vue';
import MasterArtwork from './MasterArtwork.vue';
import MasterFabric from './MasterFabric.vue';
import FormMaster from './MasterForm';
import MasterThread from './MasterThread.vue';
import masterInterlining from './MasterInterlining';
import MasterInterlining from './MasterInterlining.vue';

export default {
    components:{
        FormMaster,
        MasterFabric,
        MasterAccesories,
        MasterThread,
        MasterArtwork,
        masterInterlining,
        MasterInterlining,
    },
    name:'MasterPLM',
    data(){
        return{
            formName:'Master PLM',
            errorMessage:null
            
        }
    },
    methods:{
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        submitForm(){
            console.log('oke')
        },
        editData(){
            this.$router.push({
                name:"AppsMasterPLMFormPage"
            })
        },
        getData(){
            if(this.masterPLM != null){
                this.dataMasterPLM.push(this.masterPLM)
            }
        },
        goToCreate(){
            this.show = true
        },
    },
    created(){
        // this.getDataMaster()
    },
}
</script>
<style>
.spacing-top{
    margin-top: 25px;
}
</style>