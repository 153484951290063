<template v-if="canFillForm">
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Form Riwayat V2" header-tag="header">
                        <template v-slot:header>
                            <div class="text-center">
                                <div class="banner-container">
                                    <div class="bbi-banner"></div>
                                </div>
                                <h3>Form Riwayat Kesehatan</h3>
                                <p>
                                    Harap diisi dengan JUJUR demi kebaikan bersama
                                </p>
                            </div>
                        </template>

                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <b-row align-h="center">
                                    <b-col sm="3">
                                        <!-- input nama karyawan -->
                                            <b-form-group 
                                                label               ="Nama"
                                                label-for           ="inputNama">
                                                <b-form-input readonly id="inputName" v-model="form.namaKaryawan"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nama karyawan -->
                                        
                                        <!-- input department -->
                                            <b-form-group 
                                                label               ="Department"
                                                label-for           ="inputDept"
                                            >
                                                <b-form-input readonly id="inputDept" v-model="form.department"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input department -->
                                    </b-col>
                                    <b-col sm="3">   
                                        <!-- input nik -->
                                            <b-form-group 
                                                label               ="NIK"
                                                label-for           ="inputNik">
                                                <b-form-input readonly id="inputNik" v-model="form.nik"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nik -->
                                        
                                        <!-- input tanggal -->
                                        <validation-provider
                                                name="tglLahir_valid"
                                                :rules="{ required: true}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group ref="opt_date">
                                                    <label for="inputTanggalLahir">Tanggal Lahir</label>
                                                    <b-input-group class="sm-3" aria-describedby= "input-tanggal-feedback">
                                                        <b-form-input
                                                            id="inputTanggalLahir"
                                                            v-model="form.tanggalLahir"
                                                            type="text"
                                                            autocomplete="off"
                                                            ref="inputTanggalLahir"
                                                            readonly
                                                        ></b-form-input>
                                                        <b-input-group-append>
                                                            <VueDatePicker  
                                                                no-header                                               
                                                                v-model="calendarDate"
                                                                ref="birthdayPicker"
                                                                :max-date="BirthdayMax"
                                                                min-date="1950-01-01"
                                                                @onOpen="menu=true"
                                                                @onClose="menu=false">
                                                                <template #activator="{  }">
                                                                    <div class="button-calendar">
                                                                    <b-icon ref="activator" animation="spin" icon="calendar3" font-scale="2" variant="secondary"></b-icon>                                              
                                                                    </div>
                                                                </template>
                                                            </VueDatePicker>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback id="input-tanggal-feedback" style="display:inline;">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </b-form-group> 
                                            </validation-provider>
                                        <!-- /input tanggal -->
                                    </b-col>
                                </b-row>
                                <b-row align-h="center">
                                    <b-col sm="6">
                                        <!-- input kota lahir -->
                                        <validation-provider
                                            name="kotaLahir_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group cols="6"
                                                ref                 ="opt_kotaLahir"
                                                label               ="Tempat Lahir"
                                                label-for           ="inputKotaLahir"
                                                aria-describedby    ="input-kotaLahir-feedback"
                                            >
                                                <!-- object value -->
                                                <model-list-select 
                                                    :list="options.kotaLahir"
                                                    v-model="form.tempatLahir"
                                                    option-value="name"
                                                    option-text="name"
                                                    ref="inputKotaLahir"
                                                    >
                                                </model-list-select>
                                                <b-form-invalid-feedback id="input-kotaLahir-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>

                                        <!-- If select OTHERS -->
                                        <transition-group>
                                            <validation-provider
                                                key=1 v-if="IsTempatLahirOthers"
                                                name="kotaLahirOthers_valid"
                                                :rules="{required: IsTempatLahirOthers}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group cols="6"
                                                    ref                 ="opt_kotaLahirOthers"
                                                    label               ="Nama Kota Tempat Lahir"
                                                    label-for           ="inputKotaLahir"
                                                    aria-describedby    ="input-kotaLahir-feedback"
                                                >
                                                    <b-form-input id="inputKotaLahirOthers" ref="inputKotaLahirOthers" v-model="form.tempatLahirOthers" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="input-kotaLahirOthers-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        </transition-group>
                                        <!-- /input kota lahir -->
                                        <!-- input Nomor HP Pribadi -->
                                        <validation-provider
                                            name="nomorHPPribadi_valid"
                                            :rules="{ required: true,rule, regex: /^\d*\.?\d*$/ }"
                                            v-slot="validationContext">
                                            <b-form-group cols="6"
                                                ref                 ="opt_nomorHPPribadi"
                                                label               ="Nomor HP pribadi yang bisa dihubungi"
                                                label-for           ="inputNomorHPPribadi"
                                                aria-describedby    ="input-nomorHPPribadi-feedback"
                                            >
                                                <b-form-input id="inputNomorHPPribadi" type="number" ref="inputNomorHPPribadi"  v-model="form.nomorHP" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                <b-form-invalid-feedback id="input-nomorHPPribadi-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- END input Nomor HP Pribadi -->
                                        <!-- input alamat -->
                                        <validation-provider
                                            name="alamat_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext">
                                            <b-form-group cols="6"
                                                ref                 ="opt_alamat"
                                                label               ="Alamat tempat tinggal (Domisili)"
                                                label-for           ="inputAlamat"
                                                aria-describedby    ="input-alamat-feedback"
                                            >
                                                <b-form-textarea id="inputAlamat" ref="inputAlamat" v-model="form.alamat" rows="3" max-rows="3" :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                                <b-form-invalid-feedback id="input-alamat-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input alamat -->

                                        <!-- input provinsi -->
                                        <validation-provider
                                            name="provinsi_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group cols="6"
                                                ref                 ="opt_provinsi"
                                                label               ="Provinsi"
                                                label-for           ="inputProvinsi"
                                                aria-describedby    ="input-provinsi-feedback"
                                            >
                                                <!-- object value -->
                                                <model-list-select 
                                                    :list="options.provinsi"
                                                    v-model="form.provinsi"
                                                    option-value="name"
                                                    option-text="name"
                                                    id="inputProvinsi"
                                                    ref="inputProvinsi"
                                                    >
                                                </model-list-select>
                                                <b-form-invalid-feedback id="input-provinsi-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input provinsi -->

                                        <!-- input kotamadya -->
                                        <validation-provider
                                            name="kota_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group cols="6"
                                                ref                 ="opt_kota"
                                                label               ="Kabupaten / Kotamadya"
                                                label-for           ="inputKota"
                                                aria-describedby    ="input-kota-feedback"
                                            >
                                                <template>
                                                <!-- object value -->
                                                    <model-list-select 
                                                        id="inputKota"
                                                        ref="inputKota"
                                                        :list="options.kota"
                                                        v-model="form.kota"
                                                        option-value="name"
                                                        :custom-text="generateCustomText">
                                                    </model-list-select>
                                                </template>
                                                <b-form-invalid-feedback id="input-kota-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input kotamadya -->

                                        <!-- input kecamatan -->
                                        <validation-provider
                                            name="kecamatan_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext">
                                            <b-form-group cols="6"
                                                ref                 ="opt_kecamatan"
                                                label               ="Kecamatan"
                                                label-for           ="inputKecamatan"
                                                aria-describedby    ="input-kecamatan-feedback">
                                                <template>
                                                <!-- object value -->
                                                    <model-list-select 
                                                        id="inputKecamatan"
                                                        ref="inputKecamatan"
                                                        :list="options.kecamatan"
                                                        v-model="form.kecamatan"
                                                        option-value="name"
                                                        :custom-text="generateCustomText">
                                                    </model-list-select>
                                                </template>
                                                <b-form-invalid-feedback id="input-kecamatan-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input kecamatan -->

                                        <!-- input kelurahan -->
                                        <validation-provider
                                            name="kelurahan_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext"
                                        >
                                            <b-form-group cols="6"
                                                ref                 ="opt_kelurahan"
                                                label               ="Kelurahan - Kode Pos"
                                                label-for           ="inputKelurahan"
                                                aria-describedby    ="input-kelurahan-feedback"
                                            >
                                                <template>
                                                <!-- object value -->
                                                    <model-list-select 
                                                        id="inputKelurahan"
                                                        ref="inputKelurahan"
                                                        :list="options.kelurahan"
                                                        v-model="selectedKelurahanKodepos"
                                                        option-value="name"
                                                        :custom-text="generateCustomText">
                                                    </model-list-select>
                                                </template>
                                                <b-form-invalid-feedback id="input-kelurahan-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input kelurahan -->

                                        <!-- options (pernah mengalami) Riwayat Penyakit -->
                                        <validation-provider
                                            name="adaRiwayat_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext">
                                            <b-form-group label="Riwayat penyakit bawaan (jika pernah ada riwayat):">
                                                <b-form-radio-group
                                                    id="inputKondisiKesehatan"
                                                    ref="inputKondisiKesehatan"
                                                    v-model= "form.kondisi_kesehatan"
                                                    name= "kondisi_kesehatan"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby    ="input-kondisi_kesehatan-feedback" >
                                                    <b-radio class="radio" value="ada">Ada</b-radio>
                                                    <b-radio value="tidak ada">Tidak Ada</b-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-kondisi_kesehatan-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- end options Riwayat Penyakit  -->

                                        <!-- input Riwayat Penyakit  -->
                                        <transition-group name="fade"> 
                                            <b-col cols="11.5" offset="1" key=1 v-if="checkAdaKondisiKesehatan">
                                                <validation-provider
                                                    name="jenisPenyakit_valid"
                                                    :rules="{ required: checkAdaKondisiKesehatan }"
                                                    v-slot="validationContext">
                                                    <b-form-group ref="opt_riwayat" label="Sakit yang dialami">
                                                        
                                                        <b-form-invalid-feedback  :state="getValidationState(validationContext)"  id="input-keterangan_kondisi_kesehatan-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="diabetes melitus" v-model="kondisi"  >
                                                            Diabetes Melitus
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="penyakit terkait geriatri/ gangguan kesehatan tertentu akibat penuaan" v-model="kondisi"  >
                                                            Penyakit terkait Geriatri/ gangguan kesehatan akibat penuaan
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="penyakit terkait autoimun/ kanker" v-model="kondisi"  >
                                                            Penyakit terkait Autoimun/ kanker
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="Penyakit Ginjal" v-model="kondisi"  >
                                                            Penyakit Ginjal
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="Penyakit Gangguan Jantung" v-model="kondisi"  >
                                                            Penyakit Gangguan Jantung
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="hipertensi" v-model="kondisi"  >
                                                            Hipertensi
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="penyakit paru obstruksi kronik (PPOK)" v-model="kondisi"  >
                                                            Penyakit Paru Obstruksi Kronik (PPOK)
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="tuberculosis" v-model="kondisi"  >
                                                            Tuberculosis
                                                        </b-form-checkbox-group>
                                                        <b-form-checkbox-group class="padding-top">
                                                            <input ref="chkboxKondisi" :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" value="lainnya" v-model="kondisi"  >
                                                            Penyakit Lainnya
                                                        </b-form-checkbox-group>
                                                    </b-form-group>
                                                </validation-provider>
                                            <!-- END input Riwayat Penyakit  -->

                                            <!-- chkbox Penyakit lainnya -->
                                                <validation-provider
                                                    key=1 v-if="checkKondisiLainnya"
                                                    name="inputKondisiLainnya_valid"                                            
                                                    :rules="{required:checkKondisiLainnya}"
                                                    v-slot="validationContext">
                                                    <b-form-group cols="6"
                                                        ref                 ="opt_riwayat_lainnya"
                                                        label-for           ="inputKondisiLainnya"
                                                        aria-describedby    ="input-kondisi-lainnya-feedback">
                                                        <b-form-input class="form-kondisi-lainnya"
                                                            name="kondisi_lainnya" 
                                                            size="sm" 
                                                            :state="getValidationState(validationContext)" 
                                                            id="inputKondisiLainnya" 
                                                            v-model="form.kondisiLainnya" 
                                                            ref="inputKondisiLainnya"
                                                            trim>
                                                        </b-form-input >
                                                        <b-form-invalid-feedback  :state="getValidationState(validationContext)"  id="input-kondisi-lainnya-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </b-col>
                                        </transition-group>
                                        <!-- END Penyakit lainnya -->
                                        
                                        <!-- input Nama Anggota Keluarga -->
                                        <validation-provider
                                            name="namaKeluarga_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext">
                                            <b-form-group cols="6"
                                                ref                 ="opt_nama_keluarga"
                                                label               ="Nama anggota keluarga yang bisa dihubungi dalam keadaan darurat"
                                                label-for           ="inputNamaKeluarga"
                                                aria-describedby    ="input-namakeluarga-feedback">
                                                <b-form-input id="inputNamaKeluarga" ref="inputNamaKeluarga" v-model="form.namaKeluarga" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                <b-form-invalid-feedback id="input-namakeluarga-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- END input Nama Anggota Keluarga -->

                                        <!-- input Nomor Anggota Keluarga -->
                                        <validation-provider
                                            name="nomorHPKeluarga_valid"
                                            :rules="{ required: true,rule, regex: /^\d*\.?\d*$/ }"
                                            v-slot="validationContext"
                                        >

                                            <b-form-group cols="6"
                                                ref                 ="opt_nomorhpkeluarga"
                                                label               ="Nomor HP keluarga yang bisa dihubungi"
                                                label-for           ="inputNomorHPKeluarga"
                                                aria-describedby    ="input-nomorhpkeluarga-feedback"
                                            >
                                                <b-form-input id="inputNomorHPKeluarga" type="number" ref="inputNomorHPKeluarga" v-model="form.nomorHPKeluarga" :state="getValidationState(validationContext)" trim></b-form-input>
                                                <b-form-invalid-feedback id="input-nomorhpkeluarga-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- END input Nomor Anggota Keluarga -->
                                        
                                        <!-- input Pekerjaan Keluarga -->
                                        <validation-provider
                                            name="statusProfesiKel_valid"
                                            ref="statusProfesiKel_valid"
                                            :rules="{required: true}"
                                            v-slot="validationContext">
                                            <b-form-group label="Apakah ada anggota keluarga dalam 1 rumah / tempat tinggal yang bekerja?">
                                                <b-form-radio-group
                                                    id="inputStatusBekerjaKeluarga"
                                                    ref="inputStatusBekerjaKeluarga"
                                                    v-model= "keluargaSerumahBekerja"
                                                    :state="getValidationState(validationContext)"
                                                    aria-describedby    ="input-kondisi_kesehatan-feedback" >
                                                    <b-radio class="radio" value="ya">Ya</b-radio>
                                                    <b-radio value="tidak">Tidak</b-radio>
                                                </b-form-radio-group>
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="feedback-status-bekerja-keluarga">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>

                                        <transition-group name="trnKeluargaBekerja"> 
                                            <b-col key=1 v-if="checkKeluargaBekerja">
                                                
                                                <b-form-group class="add-style">
                                                    <label>Jika ya, apakah ada yang berprofesi di bawah ini:</label>
                                                </b-form-group>
                                                <!-- options Profesi Faskes -->
                                                <validation-provider
                                                    name="profFaskes_valid"
                                                    ref="profFaskes_valid"
                                                    :rules="{required: checkKeluargaBekerja}"
                                                    v-slot="validationContext">
                                                    <b-form-group label="Bekerja  di tempat Fasilitas Kesehatan seperti Klinik, RS, Laboratorium, Puskesmas, Apotik (dokter, perawat, tenaga administrasi, farmasi dll)">
                                                        <b-form-radio-group
                                                            id="inputProfesiFaskes"
                                                            ref="inputProfesiFaskes"
                                                            v-model= "form.profesiFaskes"
                                                            :state="getValidationState(validationContext)"
                                                            aria-describedby    ="input-profesiFaskes-feedback" >
                                                            <b-radio class="radio" value="ya">Ya</b-radio>
                                                            <b-radio value="tidak">Tidak</b-radio>
                                                        </b-form-radio-group>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-profesiFaskes-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                            
                                                </validation-provider>
                                                <!-- end options Profesi Faskes  -->
                                                <!-- input Nama Faskes -->
                                                <b-col key=2 v-if="checkProfesiFaskes">
                                                    <validation-provider 
                                                        name="namaFaskes_valid"
                                                        :rules="{required: checkProfesiFaskes}"
                                                        v-slot="validationContext">
                                                        <b-form-group label="Nama Faskes">
                                                            <b-form-input id="inputNamaFaskes" ref="inputNamaFaskes" v-model="form.namaFaskes" :state="getValidationState(validationContext)" trim></b-form-input>
                                                            <b-form-invalid-feedback :state="getValidationState(validationContext)" id="feedback-nama-faskes">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <!-- END Nama Faskes -->
                                                
                                                <!-- options Profesi Marketing / Sales / Kurir -->
                                                <validation-provider
                                                    name="profMarketing_valid"
                                                    :rules="{required: checkKeluargaBekerja}"
                                                    v-slot="validationContext">
                                                    <b-form-group label="Marketing, Sales, Kurir">
                                                        <b-form-radio-group
                                                            id="inputProfesiMarketing"
                                                            ref="inputProfesiMarketing"
                                                            v-model= "form.profesiMarketing"
                                                            :state="getValidationState(validationContext)"
                                                            aria-describedby    ="input-profesiMarketing-feedback" >
                                                            <b-radio class="radio" value="ya">Ya</b-radio>
                                                            <b-radio value="tidak">Tidak</b-radio>
                                                        </b-form-radio-group>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-profesiMarketing-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                                <!-- end options Profesi Marketing / Sales / Kurir  -->
                                                <!-- options Layanan Profesi Masyarakat -->
                                                <validation-provider
                                                    name="profLayMasy_valid"
                                                    :rules="{required: checkKeluargaBekerja}"
                                                    v-slot="validationContext">
                                                    <b-form-group label="Layanan Masyarakat (Kecamatan, Kelurahan, RT/RW, KUA, Perizinan, Satpol PP, Security, Perbankan, Anggota TNI/ POLRI dll)">
                                                        <b-form-radio-group
                                                            id="inputProfesiLayMasy"
                                                            ref="inputProfesiLayMasy"
                                                            v-model= "form.profesiLayMasy"
                                                            :state="getValidationState(validationContext)"
                                                            aria-describedby    ="input-profesiLayMasy-feedback" >
                                                            <b-radio class="radio" value="ya">Ya</b-radio>
                                                            <b-radio value="tidak">Tidak</b-radio>
                                                        </b-form-radio-group>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-profesiLayMasy-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                                <!-- end options Profesi Layanan Masyarakat  -->
                                                <!-- options Profesi Pengemudi -->
                                                <validation-provider
                                                    name="profPengemudi_valid"
                                                    :rules="{required: checkKeluargaBekerja}"
                                                    v-slot="validationContext">
                                                    <b-form-group label="Pengemudi (berbasis aplikasi dan non aplikasi)">
                                                        <b-form-radio-group
                                                            id="inputProfesiPengemudi"
                                                            ref="inputProfesiPengemudi"
                                                            v-model= "form.profesiPengemudi"
                                                            :state="getValidationState(validationContext)"
                                                            aria-describedby    ="input-profesiPengemudi-feedback" >
                                                            <b-radio class="radio" value="ya">Ya</b-radio>
                                                            <b-radio value="tidak">Tidak</b-radio>
                                                        </b-form-radio-group>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-profesiPengemudi-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                                <!-- end options Profesi Pengemudi  -->
                                                <!-- options Profesi Pedagang -->
                                                <validation-provider
                                                    name="profPedagang_valid"
                                                    :rules="{required: checkKeluargaBekerja}"
                                                    v-slot="validationContext">
                                                    <b-form-group label="Pedagang/ Membuka usaha (warung makan, bengkel, salon, Jahit dll)">
                                                        <b-form-radio-group
                                                            id="inputProfesiPedagang"
                                                            ref="inputProfesiPedagang"
                                                            v-model= "form.profesiPedagang"
                                                            :state="getValidationState(validationContext)"
                                                            aria-describedby    ="input-profesiPedagang-feedback" >
                                                            <b-radio class="radio" value="ya">Ya</b-radio>
                                                            <b-radio value="tidak">Tidak</b-radio>
                                                        </b-form-radio-group>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-profesiPedagang-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                                <!-- end options Profesi Pedagang  --><!-- input Lokasi Usaha -->                
                                                <b-col key=3 v-if="checkProfesiPedagang">
                                                    <validation-provider
                                                        name="lokasiUsaha_valid"
                                                        :rules="{required: checkProfesiPedagang}"
                                                        v-slot="validationContext">
                                                        <b-form-group label="Lokasi Usaha">
                                                            <b-form-input id="inputLokasiUsaha" ref="inputLokasiUsaha" v-model="form.lokasiBerdagang" :state="getValidationState(validationContext)" trim></b-form-input>
                                                            <b-form-invalid-feedback :state="getValidationState(validationContext)" id="feedback-lokasi-usaha">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <!-- END Lokasi Usaha -->
                                            </b-col>

                                            
                                        </transition-group>
                                        <!-- END input Pekerjaan Keluarga -->
                                    </b-col>
                                    <b-col cols="12" sm="7">
                                        <!-- input checkbox jawaban -->
                                            <validation-provider
                                                name="checkbox_jawaban"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Saya bertanggung jawab terhadap setiap jawaban yang saya berikan pada form ini untuk dapat dipergunakan sesuai dengan kebutuhannya.">
                                                    <b-form-checkbox
                                                        v-model= "form.checkboxJawaban"
                                                        name= "checkbox_jawaban"
                                                        ref="checkbox_jawaban"
                                                        value="ya"
                                                        stacked
                                                        :state="getValidationState(validationContext)"
                                                        aria-describedby="input-checkbox-jawaban-feedback">
                                                    Ya
                                                    </b-form-checkbox>
                                                    <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-checkbox-jawaban-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                
                                            </validation-provider>
                                        <!-- /input checkbox jawaban -->

                                        <b-button block @click="focusInvalidField()" type="submit" variant="primary" :disabled="isLoadingData">Submit</b-button>
                                    </b-col>
                                </b-row>
                            </form>
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    <Loader v-if="isLoadingData" fullPage useOverlay></Loader>
    </div>
</template>

<script>
    import { ModelListSelect  } from 'vue-search-select'
    import Vue from 'vue'
    import 'vue-loading-overlay/dist/vue-loading.css';
    import 'vue-search-select/dist/VueSearchSelect.css';
    import  { APIPATH_HR } from '@/api/api-paths.js'
    import  { fnGET, fnPOST } from '@/api/api-methods.js'
    import { setFocus, showToastOK } from '@/core/pluginHelper'
    import Loader from '@/components/Loader/Loader';

    import apiHealthHistory from '@/store/health-form/health-history.js';
    import apiGeneral from '@/store/general.js';
    import { mapActions, mapGetters } from 'vuex';
    import { extend } from 'vee-validate';
    
    const KotaLahirOthersLiteral = 'OTHERS / LAINNYA'

    export default {
        name: 'HealthHistory',
        components: {
            ModelListSelect, Loader,
        },     
        data() {            
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            return{
                formName: 'Form Riwayat Kesehatan',
                errorMessage: null,
                isLoadingData: false,
                form: {
                    namaKaryawan: null,
                    nik: null,
                    department: null,
                    tempatLahir: null,
                    tempatLahirOthers: null,
                    tanggalLahir: null,
                    checkboxJawaban:null,
                    namaKeluarga:null,
                    nomorHPKeluarga:null,
                    lokasi: null,   
                    kondisi_kesehatan:null,     
                    kondisiLainnya:null,     
                    alamat: null,   
                    nomorHP: null, 
                    provinsi: null,
                    kota: null,
                    kecamatan: null,
                    kelurahan: null,
                    kodepos: null,
                    pekerjaanKeluargaSerumah: null,
                    profesiFaskes: null,
                    profesiMarketing: null,
                    profesiLayMasy: null,
                    profesiPengemudi: null,
                    profesiPedagang: null,
                    namaFaskes: null,
                    lokasiBerdagang: null,
                },
                max_date:today,
                kondisi: [],
                keluargaSerumahBekerja: null,
                selectedKelurahanKodepos: null,
                rule: null,
                messages: null,
                options: {
                    provinsi:[],
                    kota:[],
                    kecamatan:[],
                    kelurahan:[],
                    kotaLahir:[],
                },
                menu: false,
                calendarDate: new Date(),
                canFillForm: false,
            }
        },
        watch: {
            'form.provinsi':{
                handler: function(val, oldval) {
                    if(val) {
                        this.fetchCity('kota')
                    }
                },
                deep: true,
            },
            'form.kota':{
                handler: function(val, oldval) {
                    if(val) {
                        this.fetchCity('kecamatan')
                    }
                },
                deep: true,
            },
            'form.kecamatan':{
                handler: function(val, oldval) {
                    if(val) {
                        this.fetchCity('kelurahan')
                    }
                },
                deep: true,
            },
            menu (val) {
                val && setTimeout(() => (this.$refs.birthdayPicker.$refs.agenda.mode = 'year'));
            },
            calendarDate (val) {
                if(val < this.max_date) { 
                    this.$set(this.form, 'tanggalLahir', val)
                }
            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            BirthdayMax() {
                let d = new Date()
                let year = d.getFullYear();
                let month = d.getMonth();
                let day = d.getDate();
                let maxDate = new Date(year - 18, month, day).toISOString().substr(0, 10);
                return maxDate
            },
            IsTempatLahirOthers() {
                if(this.form.tempatLahir == KotaLahirOthersLiteral) {
                    return true
                }
                return false
            },
            
            checkKondisiLainnya(){
                if(this.kondisi.includes('lainnya')){
                    return true;
                }
                // this.form.kondisiLainnya = '';
                this.$set(this.form, 'kondisiLainnya', '')
                return false
            },

            checkKeluargaBekerja() {
                if(this.keluargaSerumahBekerja == null || this.keluargaSerumahBekerja == 'tidak'){
                    this.$set(this.form, 'profesiFaskes', null)
                    this.$set(this.form, 'profesiMarketing', null)
                    this.$set(this.form, 'profesiLayMasy', null)
                    this.$set(this.form, 'profesiPengemudi', null)
                    this.$set(this.form, 'profesiPedagang', null)
                    this.$set(this.form, 'namaFaskes', null)
                    this.$set(this.form, 'lokasiBerdagang', null)
                    return false;
                }
                return true
            },
            
            checkProfesiFaskes(){                
                if(this.form.profesiFaskes == 'ya'){
                    return true;
                }
                this.$set(this.form, 'namaFaskes', null)
                return false
            },
            
            checkProfesiPedagang(){
                if(this.form.profesiPedagang == 'ya'){
                    return true;
                }
                this.$set(this.form, 'lokasiBerdagang', null)
                return false
            },
           
            checkAdaKondisiKesehatan(){
                if(this.form.kondisi_kesehatan == 'ada'){                    
                    return true;
                }
                this.$set(this, 'kondisi', [])
                // this.form.kondisi_kesehatan = '';
                // this.kondisi = [];
                return false
            },      
        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('general', ['fetchCityList']),
            getAccountInfo() {
                let userData      = this.userData
                this.$set(this.form, 'namaKaryawan', userData.fullname)
                this.$set(this.form, 'nik', userData.nik)
                if(userData.business_unit != 'BBI1') {
                    this.$set(this.form, 'department', userData.subdept)
                }
                else {
                    this.$set(this.form, 'department', userData.dept)
                }
                this.$set(this.form, 'lokasi', userData.business_unit)
                
            },
            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },

            check: function(e) {
                if(e.target.value == 'tidak ada'){
                    if(e.target.checked){
                        this.kondisi = []
                        this.kondisi.push(e.target.value)
                    }
                }
                else if(e.target.value != 'tidak ada'){
                    if(e.target.checked){
                        if(this.kondisi.includes('tidak ada')) {
                            this.kondisi = this.kondisi.filter(a => a !== 'tidak ada')
                        }
                    }
                }
            },      
            focusInvalidField() {
                let isValid = this.$refs.observer.validate();
                if(isValid) {
                    let invalidObj = Object.keys(this.$refs.observer.errors)
                    if(this.$refs.observer.fields["tglLahir_valid"].invalid) {
                        // this.setFocus(this.$refs.inputTanggalLahir)
                        setFocus(this.$refs.inputTanggalLahir)
                        
                    }
                    else if(this.$refs.observer.fields["kotaLahir_valid"].invalid) {
                        this.setFocus(this.$refs.inputKotaLahir)
                    }
                    else if(this.IsTempatLahirOthers && this.$refs.observer.fields["kotaLahirOthers_valid"].invalid) {
                        this.setFocus(this.$refs.inputKotaLahirOthers)
                    }
                    else if(this.$refs.observer.fields["nomorHPPribadi_valid"].invalid) {
                        this.setFocus(this.$refs.inputNomorHPPribadi)
                    }
                    else if(this.$refs.observer.fields["alamat_valid"].invalid) {
                        this.setFocus(this.$refs.inputAlamat)
                    }
                    else if(this.$refs.observer.fields["provinsi_valid"].invalid) {
                        this.setFocus(this.$refs.inputProvinsi)
                    }
                    else if(this.$refs.observer.fields["kota_valid"].invalid) {
                        this.setFocus(this.$refs.inputKota)
                    }
                    else if(this.$refs.observer.fields["kecamatan_valid"].invalid) {
                        this.setFocus(this.$refs.inputKecamatan)
                    }
                    else if(this.$refs.observer.fields["kelurahan_valid"].invalid) {
                        this.setFocus(this.$refs.inputKelurahan)
                    }
                    else if(this.$refs.observer.fields["adaRiwayat_valid"].invalid) {
                        this.setFocus(this.$refs.inputKondisiKesehatan)
                    }
                    else if(this.form.kondisi_kesehatan == 'ada' && this.$refs.observer.fields["jenisPenyakit_valid"].invalid) {
                        this.setFocus(this.$refs.chkboxKondisi)
                    }
                    else if(this.checkKondisiLainnya && this.$refs.observer.fields["inputKondisiLainnya_valid"].invalid) {
                        this.setFocus(this.$refs.inputKondisiLainnya)
                    }
                    else if(this.$refs.observer.fields["namaKeluarga_valid"].invalid) {
                        this.setFocus(this.$refs.inputNamaKeluarga)
                    }
                    else if(this.$refs.observer.fields["nomorHPKeluarga_valid"].invalid) {
                        this.setFocus(this.$refs.inputNomorHPKeluarga)
                    }
                    else if(this.$refs.observer.fields["statusProfesiKel_valid"].invalid) {
                        this.setFocus(this.$refs.inputStatusBekerjaKeluarga)
                    }
                    else if(this.checkKeluargaBekerja)
                    {
                        if(this.$refs.observer.fields["profFaskes_valid"].invalid) {
                            this.setFocus(this.$refs.inputProfesiFaskes)
                        }
                        else if(this.checkProfesiFaskes && this.$refs.observer.fields["namaFaskes_valid"].invalid) {
                            this.setFocus(this.$refs.inputNamaFaskes)
                        }
                        else if(this.$refs.observer.fields["profMarketing_valid"].invalid) {
                            this.setFocus(this.$refs.inputProfesiMarketing)
                        }
                        else if(this.$refs.observer.fields["profLayMasy_valid"].invalid) {
                            this.setFocus(this.$refs.inputProfesiLayMasy)
                        }
                        else if(this.$refs.observer.fields["profPengemudi_valid"].invalid) {
                            this.setFocus(this.$refs.inputProfesiPengemudi)
                        }
                        else if(this.$refs.observer.fields["profPedagang_valid"].invalid) {
                            this.setFocus(this.$refs.inputProfesiPedagang)
                        }
                        else if(this.checkProfesiPedagang && this.$refs.observer.fields["lokasiUsaha_valid"].invalid) {
                            this.setFocus(this.$refs.inputLokasiUsaha)
                        }
                    }

                    
                    else if(this.$refs.observer.fields["checkbox_jawaban"].invalid) {
                        this.setFocus(this.$refs.checkbox_jawaban)
                    }
                    return false
                }
            },
            onSubmit() {
                //console.log(this.form.kondisi_kesehatan)
                let objTemp = this.form.kondisi_kesehatan;
                if (this.form.kondisi_kesehatan == 'ada') {
                    objTemp = this.kondisi.toString()
                    if(this.checkKondisiLainnya){
                        objTemp = objTemp.replace('lainnya',this.form.kondisiLainnya);
                    }
                }
                //Set Kelurahan & Kodepos
                this.$set(this.form, 'kelurahan', this.selectedKelurahanKodepos.name)
                this.$set(this.form, 'kodepos', this.selectedKelurahanKodepos.code)
                this.isLoadingData = true
                // show report after timeout min 1 second
                setTimeout(() => {
                    apiHealthHistory.dispatch('postHealthHistoryV2', {
                        nama_karyawan: this.form.namaKaryawan,
                        nik: this.form.nik,
                        department: this.form.department,
                        lokasi: this.form.lokasi,
                        tempat_lahir: (this.form.tempatLahir != KotaLahirOthersLiteral) ? this.form.tempatLahir : ('(OTHERS) ' + this.form.tempatLahirOthers),
                        tanggal_lahir: this.form.tanggalLahir,
                        riwayat_sakit_keras: objTemp,
                        checkbox_jawaban: this.form.checkboxJawaban,
                        nama_anggota_keluarga:this.form.namaKeluarga,
                        nomor_anggota_keluarga:this.form.nomorHPKeluarga,
                        
                        nomor_hp:this.form.nomorHP,
                        alamat:this.form.alamat,
                        provinsi:this.form.provinsi,
                        kota:this.form.kota,
                        kecamatan:this.form.kecamatan,
                        kelurahan:this.form.kelurahan,
                        kodepos:this.form.kodepos,

                        pekerjaan_keluarga_serumah:this.form.pekerjaanKeluargaSerumah,
                        
                        profesi_faskes: this.form.profesiFaskes,
                        profesi_sales: this.form.profesiMarketing,
                        profesi_layanan_masyarakat: this.form.profesiLayMasy,
                        profesi_pengemudi: this.form.profesiPengemudi,
                        profesi_pedagang: this.form.profesiPedagang,
                        nama_faskes:this.form.namaFaskes,
                        lokasi_berdagang: this.form.lokasiBerdagang
                    })
                    .then(response => {
                        //console.log(response) 
                        this.$router.push({name:"DashboardPage"})
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                    })
                    .catch(err => {
                        if (err.response.status === 400) {
                            this.showNotification({type:'error', message:err.response.data.message})   
                        }
                    })
                    .finally(()=>{
                        this.isLoadingData = false
                    })
                },1000)
            },
            fetchBirthPlace() {
                let paramFilter = {category: 'kota'}
                
                apiGeneral.dispatch('fetchCityList', paramFilter)
                .then(rsp => {
                    // Add "Others" into selection
                    let jsonTmptLahir  = rsp.data.payload
                    jsonTmptLahir.push({"name":KotaLahirOthersLiteral})
                    this.$set(this.options, 'kotaLahir', jsonTmptLahir)
                })
                .catch(err => {
                    if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Failed Fetching City Data'})
                    }
                })

            }
            , fetchCity(category) {
                let paramFilter = {}
                switch(category) {
                    case 'provinsi':
                        this.$set(this.form, "kota", null)
                        this.$set(this.form, "kecamatan", null)
                        this.$set(this.form, "kelurahan", null)
                        this.$set(this.form, "kodepos", null)
                        this.$set(this.options, "kota", [])
                        this.$set(this.options, "kecamatan", [])
                        this.$set(this.options, "kelurahan", [])
                        this.$set(this.options, "kodepos", [])
                        // this.form.kota = ''
                        // this.form.kecamatan = ''
                        // this.form.kelurahan = ''
                        // this.form.kodepos = ''

                        // this.options.kota = []
                        // this.options.kecamatan = []
                        // this.options.kelurahan = []
                        // this.options.kodepos = []

                        this.selectedKelurahanKodepos = {name:'', code:''}
                        paramFilter = {category: category}
                        break;
                    case 'kota':
                        this.$set(this.form, "kecamatan", '')
                        this.$set(this.form, "kelurahan", '')
                        this.$set(this.form, "kodepos", '')
                        this.$set(this.options, "kecamatan", [])
                        this.$set(this.options, "kelurahan", [])
                        this.$set(this.options, "kodepos", [])
                        this.selectedKelurahanKodepos = {name:'', code:''}
                        paramFilter = {category: category, provinsi:this.form.provinsi}
                        break;
                    case 'kecamatan':
                        this.$set(this.form, "kelurahan", '')
                        this.$set(this.form, "kodepos", '')
                        this.$set(this.options, "kelurahan", [])
                        this.$set(this.options, "kodepos", [])
                        this.selectedKelurahanKodepos = {name:'', code:''}
                        paramFilter = {category: category, provinsi:this.form.provinsi, kota:this.form.kota}
                        break;
                    case 'kelurahan':
                        paramFilter = {category: category, provinsi:this.form.provinsi, kota:this.form.kota, kecamatan:this.form.kecamatan}
                        break;
                } 
                apiGeneral.dispatch('fetchCityList', paramFilter)
                .then(rsp => {
                    this.$set(this.options, category, rsp.data.payload)
                })
                .catch(err => {
                    if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Failed Fetching City Data'})
                    }
                })
            },
            generateCustomText(e){
                if(e.code) {
                    return `${e.name} - ${e.code}`
                }
                return `${e.name}`
            },
            setFocus(refField){
                refField.$el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
                refField.$el.classList.value = refField.$el.classList.value + ' animation-shake'
                setTimeout(() => {                        
                    refField.$el.classList.remove('animation-shake');
                },1500)
            },

        },
        beforeCreate(){
            fnGET(APIPATH_HR.healthHistoryV2)
            .then(rsp => {
                this.canFillForm = rsp.data.payload.can_proceed
                if(!this.canFillForm) {
                    alert('NIK: ' + this.userData.username + '\nNama: ' + this.userData.fullname + '\nSudah pernah mengisi riwayat kesehatan \nTanggal: ' + rsp.data.payload.fill_date + '\nPengisian Riwayat Kesehatan cukup dilakukan 1x saja. \nJika ada perubahan/ kesalahan pengisian, silahkan hubungi bagian HRD.')
                }
            })
            .catch(err => {
                this.showNotification({type:'error', message:err.response.data.message})   
            })
            .finally(() => {
                if(!this.canFillForm) { 
                    this.$router.push({name:"ApplicationPage"})
                }
            })
        },
        created(){
            this.max = this.serverDate
            extend(
                'rule',{
                    getMessage: nomorhp => 'Hanya boleh diisi dengan angka',
                    validate: (value) => {
                        if(value == 0 || value >= 0) return true;
                        return false
                    }
                }
            );  
        },
        mounted: function(){
            this.getAccountInfo()
            this.fetchCity('provinsi')
            this.fetchBirthPlace()
        },
    }
</script>
<style src="./HealthHistory.scss" lang="scss" scoped />