<template>
    <div class="table-basic inputAdjustment">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-adjustment"> Dashboard Adjustment</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Form Master Subcon" header-tag="header">
                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:500;'>Create Adjustment</h3>
                                </div>
                            </div>
                        </template>
                        <b-row align-h="center">
                            <!-- INFO IO  -->
                            <b-col sm="auto" lg="4">
                                <b-form-group
                                    label               ="IO / PO"
                                    label-for           ="inputIO">
                                    <b-form-input readonly id="inputIO" v-model="form.data_io"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="4">
                                <b-form-group
                                    label               ="Status"
                                    label-for           ="inputStatus">
                                    <b-form-input readonly id="inputStatus" v-model="form.status"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="4">
                                <b-form-group
                                    label               ="Employee"
                                    label-for           ="inputEmployee">
                                    <b-form-input readonly id="inputEmployee" v-model="form.Employee"   trim></b-form-input>
                                </b-form-group>
                            </b-col>

                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="Artikel"
                                    label-for           ="inputArtikel">
                                    <b-form-input readonly id="inputArtikel" v-model="form.artikel"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="Brand"
                                    label-for           ="inputBrand">
                                    <b-form-input readonly id="inputBrand" v-model="form.brand"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="Total Qty"
                                    label-for           ="inputTotal_Qty">
                                    <b-form-input readonly id="inputTotal_Qty" v-model="form.total_qty"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="Due Date"
                                    label-for           ="inputDue_date">
                                    <b-form-input readonly id="inputDue_date" v-model="due_date"   trim></b-form-input>
                                </b-form-group>
                            </b-col>

                            <b-col align-self="center" class='col-process' lg="12" style="padding-top:15px;">
                            <b-card no-body>
                            <b-tabs content-class="mt-3" fill>
                                <b-tab active>
                                <template #title>
                                    <b-icon icon="tools"></b-icon> Reset IO
                                </template>
                                <b-row align-v="end">
                                    <b-col sm="auto" lg="8">
                                    <validation-provider
                                        name="inputKeterangan"
                                        vid="inputKeterangan">
                                        <b-form-group
                                            label               ="Keterangan Reset IO"
                                            label-for           ="inputKeteranganResetIO">
                                            <b-form-textarea
                                                id="textarea-state"
                                                v-model="form.ket_resetIO"
                                                placeholder="Enter Reason Reset IO"
                                                no-resize
                                                rows="3"
                                            ></b-form-textarea>
                                            </b-form-group>
                                    </validation-provider>
                                    </b-col>
                                    <b-col sm="auto" lg="3" style='bottom:13px;'>
                                        <b-button block  @click="doApproval('ResetIO')" ref="submit" type="submit" variant="success">Submit</b-button>
                                    </b-col>
                                </b-row>


                                </b-tab>

                                <b-tab>
                                <template #title>
                                    <b-icon icon="inbox-fill"></b-icon> SubconDaily
                                </template>
                                    <b-card no-body>
                                        <b-tabs pills card vertical nav-wrapper-class="w-40">
                                        <b-col sm="auto" lg="12">
                                            <!-- Render Tabs, supply a unique `key` to each tab -->
                                            <b-tab v-for="i in tabs" :key="'dyn-tab-' + i" :title="name_process[i]">
                                            <b-col sm="12" lg="6">
                                            <p> Options Request : </p>
                                            <span v-html='select_process[i] = name_process[i]' style='display:none;'></span>
                                            <b-form-select v-model="selected[i]" class="mb-3">
                                                <!-- These options will appear after the ones from 'options' prop -->
                                                <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                                                <b-form-select-option v-if="name_process[i] == 'Cutting' " value="qty_cutting">Change Input {{name_process[i] }}</b-form-select-option>
                                                <b-form-select-option value="reset_process">Reset Process {{name_process[i] }}</b-form-select-option>
                                                <b-form-select-option value="uncompleted">Uncompleted Process {{name_process[i] }}</b-form-select-option>
                                            </b-form-select>
                                                <!-- Render this if no tabs -->
                                                <template #empty>
                                                <div class="text-center text-muted">
                                                    There are no open tabs<br>
                                                    Please Check Again Or Contact Administrator.
                                                </div>
                                                </template>
                                            </b-col>

                                            <b-col sm="12" lg="12">
                                            <validation-provider
                                                name="inputKeterangan"
                                                vid="inputKeterangan">
                                                <b-form-group
                                                    label               ="Keterangan"
                                                    label-for           ="inputKeterangan">
                                                    <b-form-textarea
                                                        id="textarea-state"
                                                        v-model="ket_sub[i]"
                                                        no-resize
                                                        placeholder="Enter Your Reason"
                                                        rows="3"
                                                    ></b-form-textarea>
                                                    </b-form-group>
                                                </validation-provider>
                                                <b-col style='float:right; min-width:30%; padding-bottom:10px;' sm="auto" lg="6">
                                                    <b-button block  @click="doApproval(i)" ref="save" type="button" variant="success">Submit</b-button>
                                                </b-col>
                                            </b-col>

                                            </b-tab>
                                            <b-tab title="All Process" active>
                                                <p> Options Request : </p>
                                                <span v-html="all_process = 'true' " style='display:none;'></span>
                                                <b-form-select v-model="selected_all" class="mb-3">
                                                    <!-- These options will appear after the ones from 'options' prop -->
                                                    <b-form-select-option :value="null">-- Please select an option --</b-form-select-option>
                                                    <b-form-select-option value="reset_process">Reset All Process</b-form-select-option>
                                                    <b-form-select-option value="uncompleted">Uncompleted All Process</b-form-select-option>
                                                </b-form-select>
                                            </b-tab>
                                        </b-col>
                                        </b-tabs>
                                    </b-card>
                                </b-tab>

                                <b-tab disabled>
                                <template #title>
                                    <b-icon icon="search"></b-icon> Inspection
                                </template>
                                <p class="p-3">Tab contents 3</p>
                                </b-tab>

                                <b-tab disabled>
                                <template #title>
                                    <b-icon icon="truck"></b-icon> Delivery
                                </template>
                                <p class="p-3">Tab contents 4</p>
                                </b-tab>

                                <b-tab disabled>
                                <template #title>
                                    <b-icon icon="bag-check-fill"></b-icon> Receive
                                </template>
                                <p class="p-3">Tab contents 5</p>
                                </b-tab>

                            </b-tabs>
                            </b-card>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import apiPPCM from '@/store/ppm-form/ppm.js';
    import { extend } from 'vee-validate';
    // import vue loading
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);

    export default {
       name: 'InputAdjustment',

        data() {
           const now = new Date()
            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

            // create hour:min:sec
            const time = now.toString().slice(16,24)
            // const opthour = { hour: '2-digit'};
            // const optmin = { minute: '2-digit'};
            // const optsec = { second: '2-digit'};
            // const hour = now.toLocaleString('id-ID', opthour ,{ hour12: false })
            // const min = now.toLocaleString('id-ID', optmin ,{ hour12: false })
            // const sec = now.toLocaleString('id-ID', optsec ,{ hour12: false })
            // const time = hour+':'+min+':'+sec

            return{
                opt_process: [
                ],
                formName: 'Input Adjustment',
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                errorMessage: null,
                max_date:today,
                time:time,
                today:today,
                due_date:null,
                tabs: [],
                select_process:[null,null,null,null,null,null,null,null],
                selected_all:null,
                all_process:null,
                selected:[null,null,null,null,null,null,null,null],
                ket_sub:[null,null,null,null,null,null,null,null],
                tabCounter: 0,
                    form : {
                    data_io:null,
                    Employee:null,
                    status:null,
                    artikel:null,
                    brand:null,
                    total_qty:null,
                    due_date:null,
                    ket_resetIO:'',
                    keterangan_request:null,
                    detail_request:null,
                    request:null,
                    date_request:null,
                    cutting_id :null,
                    cutting_subcon :null,
                    cutting_target :null,
                    cutting_target_dummy :null,
                    cutting_date:null,
                    cutting_date_plan:null,
                    cutting_end_date:null,
                    cutting_end_date_plan:null,
                    embroidery_id:null,
                    embroidery_subcon :null,
                    embroidery_target :null,
                    embroidery_target_dummy :null,
                    embroidery_date :null,
                    embroidery_date_plan:null,
                    embroidery_end_date:null,
                    embroidery_end_date_plan:null,
                    printing_id :null,
                    printing_subcon :null,
                    printing_target :null,
                    printing_target_dummy :null,
                    printing_date :null,
                    printing_date_plan:null,
                    printing_end_date:null,
                    printing_end_date_plan:null,
                    emblem_id :null,
                    emblem_subcon :null,
                    emblem_target :null,
                    emblem_target_dummy :null,
                    emblem_date :null,
                    emblem_date_plan:null,
                    emblem_end_date:null,
                    emblem_end_date_plan:null,
                    sewing_id :null,
                    sewing_subcon :null,
                    sewing_target :null,
                    sewing_target_dummy :null,
                    sewing_date:null,
                    sewing_date_plan:null,
                    sewing_end_date:null,
                    sewing_end_date_plan:null,
                    washing_id :null,
                    washing_subcon :null,
                    washing_target :null,
                    washing_target_dummy :null,
                    washing_date :null,
                    washing_date_plan:null,
                    washing_end_date:null,
                    washing_end_date_plan:null,
                    finishing_id :null,
                    finishing_subcon :null,
                    finishing_target :null,
                    finishing_target_dummy :null,
                    finishing_date :null,
                    finishing_date_plan:null,
                    finishing_end_date:null,
                    finishing_end_date_plan:null,
                    packing_id :null,
                    packing_subcon :null,
                    packing_target :null,
                    packing_target_dummy :null,
                    packing_date :null,
                    packing_date_plan:null,
                    packing_end_date:null,
                    packing_end_date_plan:null,
                    calc_date:null,
                    process_order:[],
                    plan_date_delivery:null,
                    plan_end_date_delivery:null,
                    plan_date_qc_final:null,
                    plan_end_date_qc_final:null,
                    },
                process_order:[],
                name_process:[],
            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stIoPPM','stIoPPMCount']),

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getInputIOPPM']),
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Setdata_IO() {
                let data_IO      = this.$route.query.io
                let userData     = this.userData
                this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardAdjustment'})
                }
                this.getInputIOPPM(this.form.data_io)
                .then(rsp => {
                    this.GetdataForm()
                })
            },
            GetdataForm() {
                let formdata = this.stIoPPM
                this.form.status = formdata[0].status
                this.form.artikel = formdata[0].artikel
                this.form.brand = formdata[0].brand
                this.form.total_qty = formdata[0].total_qty
                this.form.due_date = formdata[0].due_date
                if (formdata[0].due_date !== null) {
                    this.due_date = this.FormatDate(formdata[0].due_date)
                }
                this.process_order = formdata[0].order_process;
                // console.log(this.process_order);

                this.dataOrderProcess();
            },
            dataOrderProcess() {
                let len = Math.ceil(this.process_order.length/2)
                // console.log(len);
                let b = 1;
                let a = 0;
                let r = 0;
                if (this.process_order == '' || this.process_order == null) {
                    this.showNotification({type:'error', message:'Data process order does not exist, please contact Administrator'})
                    this.$router.push({name:"DashboardAdjustment"})
                } else {
                for (a=0;a<=len-1;a++) {
                if (this.process_order.slice(r, b) == 'a') {
                    this.form.process_order[a] = 'a';
                    this.name_process[a] = 'Cutting'
                    this.newTab()
                }
                if (this.process_order.slice(r, b) == 'b') {
                    this.form.process_order[a] = 'b';
                    this.name_process[a] = 'Sewing'
                    this.newTab()
                }
                if (this.process_order.slice(r, b) == 'c') {
                    this.form.process_order[a] = 'c';
                    this.name_process[a] = 'Finishing'
                    this.newTab()
                }
                if (this.process_order.slice(r, b) == 'd') {
                    this.form.process_order[a] = 'd';
                    this.name_process[a] = 'Packing'
                    this.newTab()
                }
                if (this.process_order.slice(r, b) == 'e') {
                    this.form.process_order[a] = 'e';
                    this.name_process[a] = 'Print'
                    this.newTab()
                }
                if (this.process_order.slice(r, b) == 'f') {
                    this.form.process_order[a] = 'f';
                    this.name_process[a] = 'Embroidery'
                    this.newTab()
                }
                if (this.process_order.slice(r, b) == 'g') {
                    this.form.process_order[a] = 'g';
                    this.name_process[a] = 'Emblem'
                    this.newTab()
                }
                if (this.process_order.slice(r, b) == 'h') {
                    this.form.process_order[a] = 'h';
                    this.name_process[a] = 'Washing'
                    this.newTab()
                }
                b += 2;
                r += 2;
                //console.log('r'+r);
                //console.log('b'+b);
                }
                }
            },
            newTab() {
                this.tabs.push(this.tabCounter++)
                // console.log('add tab');
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 1000);
                }
            },
            doApproval(choice) {
                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm ',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                    this.boxTwo = String(value)
                    if (this.boxTwo == 'true') {
                        if (choice == 'ResetIO') {
                            this.form.request = 'reset_io'
                            this.form.detail_request = null
                            if (this.form.ket_resetIO == null || this.form.ket_resetIO == '' || this.form.ket_resetIO.substring(0, 1) == ' '|| this.form.ket_resetIO == undefined) {
                                this.showNotification({type:'info', message:'Keterangan Reset IO Harap Di Isi'})
                                return
                            }
                            this.form.keterangan_request = this.form.ket_resetIO
                            this.Submit()
                        }
                        if (choice >=0 ) {
                            let arr = choice
                            if (this.selected[arr] == null){
                                this.showNotification({type:'info', message:'Options Request is still empty'})
                                return
                            }
                            if (this.ket_sub[arr] == null || this.ket_sub[arr] == '' || this.ket_sub[arr].substring(0, 1) == ' '|| this.ket_sub[arr] == undefined) {
                                this.showNotification({type:'info', message:'Keterangan Process' +this.select_process[arr]+ ' Harap Di Isi'})
                                return
                            }
                            let process = this.select_process[arr].toLowerCase();
                            let type_request = this.selected[arr].toLowerCase();
                            this.form.request = 'process_subcon'
                            this.form.detail_request = type_request+' '+process
                            this.form.keterangan_request = this.ket_sub[arr]
                            this.Submit()
                        }
                    }
                })
                .catch(err => {
                    this.showNotification({type:'error', message:err})
                })

            },
            Submit() {
                this.form.date_request = this.today+' '+this.time;
                this.form.code_request = this.form.request+'_'+this.form.data_io+'_'+this.today+' '+this.time+'__'+Math.floor(Math.random() * 999999);
                this.Loading('start')
                apiPPCM.dispatch('PostCreateAdjustment', {
                        code_request:this.form.code_request,
                        io:this.form.data_io,
                        request:this.form.request,
                        detail_request:this.form.detail_request,
                        keterangan_request:this.form.keterangan_request,
                        user_request:this.form.Employee,
                        date_request:this.form.date_request,
                    })
                    .then(response => {
                        this.Loading('stop')
                        this.$router.push({name:"DashboardAdjustment"})
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                    })
                    .catch(err => {
                        this.Loading('stop')
                        console.log(err.response.data.message)
                        if (err.response === 400) {
                            console.log(err.response.data.message)
                            this.showNotification({type:'error', message:err.response.data.message})
                        }
                    })
            },
        },
        created() {
            this.Setdata_IO()
        },

    }
</script>
<style src="./InputAdjustment.scss" lang="scss" scoped />