<template>
  <div class="tables-basic">
    <b-breadcrumb>
    <h2 class="page-title"> <span style="font-size:16px;" class="fw-semi-bold fontbbi ">Dashboard Inspection</span></h2>
    </b-breadcrumb>
   <b-row>
     <b-col sm="12">
        <b-row>
          <b-col sm="auto">
            <b-form-group
            label="Filter: "
            label-for="filter"
            label-cols-sm="4"
            label-align-sm="left">
              <b-form-select id="filter" v-model="filter" :options="optionsFilter" ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="auto">
            <b-form-group
            label="Search: "
            label-for="filter"
            label-cols-sm="4"
            label-align-sm="left">
              <b-form-input style="height: calc(1.5em + 0.75rem + 2px); margin-left:-20px"
                placeholder="Enter your search keyword"
                v-model="search">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="auto">
              <b-button variant="primary"
                style="height: calc(1.5em + 0.75rem + 2px); margin-left:-40px"
                @click="searchFilter">
              Search
              </b-button>
          </b-col>
        </b-row>
          <div class="table-responsive">
          <table class="table table-lg mb-0">
              <thead>
                <tr style="text-align:center; border-top:1px solid #CCC;" class="text-muted">
                    <th style="border:1px solid #CCC; font-weight: 700;">IO</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Subcon</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Due Date</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Article</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Brand</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Sample</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Visit Inline</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Last Visit</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Status</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Qty Plan</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Visit Final</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Last Final</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                v-for="row in stPPCMInsV2"
                :key="row.id"
                style="text-align:center;"
                >

                  <td :class="chkDateOnSchedule(row.due_date, today, row.status , row.packing_end_date)" style="border-right:1px solid #CCC; border-left:1px solid #CCC; border-bottom:1px solid #CCC; cursor:pointer;">
                    <span class="fw-semi-bold fontbbi" @click="IOPPCM(row.io)">{{row.io}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.subcon}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; min-width:90px; border-bottom:1px solid #CCC;">
                    <span v-if="row.due_date !== null" v-html="FormatDate(row.due_date)" class="fw-semi-bold fontbbi">{{row.due_date}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.artikel}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.brand}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.sample}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.visit_inline}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; min-width:90px; border-bottom:1px solid #CCC;">
                    <span v-if="row.last_visit_inline !== null" class="fw-semi-bold fontbbi " v-html="FormatDate(row.last_visit_inline)"></span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.status_inline}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.qty_plan}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.visit_final}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; min-width:90px; border-bottom:1px solid #CCC;">
                    <span v-if="row.last_visit_final !== null" class="fw-semi-bold fontbbi " v-html="FormatDate(row.last_visit_final)"></span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.status_final}}</span>
                  </td>



                </tr>
              </tbody>
            </table>
            <br />
            <p>
  <button @click="prevPage" :disabled="cantGoBack">Previous</button> <span style="padding-left: 5px;"></span>
  <span style="margin-left:10px; padding-right:10px;"> Page : {{currentPage}} </span>
  <button @click="nextPage" :disabled="cantNext" >Next</button>
  </p>
          </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Widget from '@/components/Widget/Widget';
// import appsStore from '@/store/apiApps.js';

export default {
        name: 'DashboardSubcon',
        components: { Widget },

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                errorMessage: null,
                today:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                currentSort:'status',
                currentSort1:'due_date',
                currentSortDir:'desc',
                oneDay:oneDay,
                pageSize:10,
                totalData:0,
                currentPage:1,
                filter: 'all',
                search: null,
                url_data:'',
                optionsFilter: [
                  { value: 'all', text: 'All' },
                  { value: 'io', text: 'IO' },
                  { value: 'brand', text: 'Brand' },
                  { value: 'artikel', text: 'Artikel' },
                ],
                form : {
                    fullname:null,
                    role:null,
                    filter:'all',
                    search:null,
                    start_limit:0,
                    end_limit:10,
                },

            }
        },
  computed: {
    ...mapState('application', ['stAppAccess']),
    ...mapGetters('session', ['userData']),
    ...mapState('ppm', ['stPPCMInsV2','stPPCMInsCount','stPPCMInsCountV2']),
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantNext() {
      if (this.totalData == 0) {
        return this.currentPage === 1;
      }
        return this.currentPage === Math.ceil(this.totalData / this.pageSize)
    },
  },
  methods: {
    ...mapActions('session', ['showNotification']),
    ...mapActions('application', ['clearState', 'getApplicationAccess']),
    ...mapActions('ppm', ['clearStatePpm','getPPCMInspectionData','getDataPPCMInspectionV2','getDataCountPPCMInspectionV2']),
   Setdata_IO() {
        let userData     = this.userData
        this.$set(this.form, 'fullname', userData.fullname)
        this.$set(this.form, 'role', userData.role)
        if (userData.roles) {
          for (let a=0; a<userData.roles.length; a++) {
            if (userData.roles[a].app == 'PPCM') {
              this.form.role = userData.roles[a].role_list
            }
          }
        }
        // let role = this.form.role
        this.url_data = process.env.VUE_APP_BASE_URL
       if (this.form.role.includes('subcon')) {
        this.$router.push({name:"ApplicationPage"})
        this.showNotification({type:'error', message:"you don't have authorization to view this page"})
       }
       this.Loading('start')
        this.getDataPPCMInspectionV2({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        end_limit:this.form.end_limit,
      })
        this.getPPCMInspectionData().then(rsp => {
          this.totalData = rsp
          if(rsp == 0) {
              this.showNotification({type:'info', message:'Data Not Found'})
          }
          // console.log(this.stPPCMIns)
        })
        .finally(()=>{
          this.Loading('stop')
        })
   },
    Loading(value) {
        if (value == 'start') {
        this.loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            color: '#f89830',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
            });
        }
        if (value == 'stop') {
          // Set delay 1 second
          setTimeout(() => {
            this.loader.hide()
          }, 500);
        }
    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
          return ' '
      } else {
      let date = new Date(value)
      let tgl = date.toISOString().slice(8,10)
      let bulan = this.monthNames[date.getMonth()]
      let tahun = date.toISOString().slice(0,4)
      date = tgl+' '+bulan+' '+tahun
      return date
      }
    },
    chkDateOnSchedule(param1, param2, param3, param4){
        let duedate   = param1;
        let today     = param2;
        let status    = param3;
        let endDate    = param4;
        // console.log(duedate);
        // console.log(today);
        // console.log(status);

        if (status == 'new') {
          return 'new'
        }
        if (endDate == null || endDate == '' || endDate == undefined) {
          if (duedate < today) {
            return 'red'
          }
          if (duedate >= today) {
            return 'green'
          }
        } else {
          if (duedate < endDate) {
            return 'red'
          }
          if (duedate >= endDate) {
            return 'green'
          }
        }

      },
    searchFilter() {
      console.log('search: '+this.search)
      if (this.filter !== 'all') {
        if (this.search == null || this.search == '') {
          this.showNotification({type:'error', message:"Field Search Tidak Boleh Kosong"})
          return
        }
        if (this.search.substring(0, 1) == ' ' ) {
          this.showNotification({type:'error', message:"Field Search Terinput Belum Benar"})
          return
        }
      } else {
        this.search = null;
      }
      this.clearStatePpm()
      this.currentPage = 1;
      this.form.filter = this.filter
      this.form.search = this.search
      this.form.start_limit = 0
      this.form.end_limit = 10
      let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          color: '#f89830',
          loader: 'spinner',
          width: 64,
          height: 64,
          backgroundColor: '#ffffff',
          opacity: 0.5,
          zIndex: 999,
          });
          // show report after timeout min 1 second
      this.getDataCountPPCMInspectionV2({
        filter:this.form.filter,
        search:this.form.search,
      })
      setTimeout(() => {
      this.getDataPPCMInspectionV2({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        end_limit:this.form.end_limit,
      }).then(rsp=>{
        this.totalData = this.stPPCMInsCountV2
        if(this.stPPCMInsCountV2 == 0) {
          this.showNotification({type:'info', message:'Data Not Found'})
        }
      })
      .finally(()=>{
        loader.hide()
      })

      },2000);

    },
    nextPage:function() {
      this.clearStatePpm()
      this.form.start_limit = this.form.start_limit+10
      this.form.end_limit = this.form.end_limit+10
      this.getDataPPCMInspectionV2({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        end_limit:this.form.end_limit,
      })

        this.currentPage++;

    },
    prevPage:function() {
      this.clearStatePpm()
      this.form.start_limit = this.form.start_limit-10
      this.form.end_limit = this.form.end_limit-10
      this.getDataPPCMInspectionV2({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        end_limit:this.form.end_limit,
      })

        this.currentPage--;

    },

    IOPPCM(data) {
      // this.$router.push({ name: 'PPCMFormInspection', params: { data_io: data } })
      let routeData = this.$router.resolve({ name: 'PPCMFormInspection', query: { io: data }})
      // console.log(routeData.href)
      // window.open(routeData.href, '_blank')
      window.open(routeData.href, '_self')
    },

  },
  mounted() {
    this.Setdata_IO()

  },
  created() {
    this.clearStatePpm()
    this.clearState()

  }
};
</script>
<style src="../InputPPM/DashboardPPCM.scss" lang="scss" scoped />
