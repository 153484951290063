<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item >
                <router-link tag="a" to="/app/appsplm-product-design2"> {{formName}}</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-card title="View Process Article">
            <b-row>
                <b-col cols="12" sm="auto" md="4">
                    <b-form-group
                        label-cols-lg   ="3" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Brand<code>*</code>
                        </template>
                            <label> {{product.brand}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="3" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Article<code>*</code>
                        </template>
                            <label> {{product.article}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="3" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Coll. Month<code>*</code>
                        </template>
                            <label> {{product.coll_month}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="3" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Year<code>*</code>
                        </template>
                            <label> {{product.year}}</label>
                    </b-form-group>
                </b-col>
                <!-- <b-col cols="12" sm="auto" md="8">
                    <b-form-group
                        
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Design<code>*</code>
                        </template>
                            
                    </b-form-group>
                        <b-card-group deck >
                            <template v-for="designImg in product.design" >
                                <b-card :key="designImg.src"
                                    style="max-width: 120px;"
                                    img-height="80px"  
                                    :img-src="designImg.src" 
                                    img-alt="Image" 
                                    img-top>
                                <b-card-text>
                                    <small class="text-muted">{{designImg.fileName.substring(0,40)+"..."}}</small>
                                </b-card-text>
                                </b-card>
                            </template>
                        </b-card-group>
                    
                </b-col> -->
            </b-row>
        </b-card>
        <b-card>
            <b-table
                striped 
                hover
                show-empty
                small
                responsive
                :items="dataArticle"
                :fields="fields">
                <template #cell(plan)>
                    {{planDays}}
                </template>
                <template #cell(actual)>
                    {{actualDays}}
                </template>
            </b-table>
        </b-card>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props:['productId'],
    data(){
        return{
            formName:'Product Design List',
            modulName:'Process By Article',
            planDays:2,
            actualDays:1,
            article:[],
            fields:[
                // {key:'actions', label:'#'},
                {key:'process',label:'Process'},
                {key:'task',label:'Task'},
                {key:'start_date',label:'Start Date'},
                {label:'End Date',key:'end_date'},
                {label:'Estimated Date',key:'estimate_date'},
                {key:'plan',label:'Plan(days)'},
                {key:'actual',label:'Actual(days)'}
                // {key:'status',label:'Status'},
            ],
            dataArticle:[
                
            ],
            product:null,
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_PROCESS','GET_PRODUCT_DETAIL']),
        getData(){
            this.GET_PRODUCT_PROCESS({product_id:this.productId})
            .then(rsp =>{
                console.log(rsp.data.payload);
                this.$set(this,'dataArticle',rsp.data.payload)
            }).catch(err =>{
                return err
            })
        },
        getProduct(){
            
            this.GET_PRODUCT_DETAIL({
                product_id:this.productId
            })
            .then(rsp => {
                console.log(rsp.data.payload);
                this.$set(this,'product',rsp.data.payload)
            }).catch(err=>{
                return err
            })
        }
    },
    computed:{
        
    },
    created(){
        this.getProduct()
        this.getData()
    }
}
</script>