<template>
    <div>
        <!-- <b-form-radio-group
            v-model="isSelect"
            :options="fabricOpt"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
        ></b-form-radio-group> -->
        <template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <b-row >
                        <b-col cols="auto" md="6">
                            
                            <!-- Code Fabric -->
                            <validation-provider
                                name="codeFabric"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code
                                    </template>
                                    
                                        <!-- <b-form-select id="inputCodeFabric" v-model="form.codeFabric"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                        <v-select append-to-body
                                            :reduce="ref => ref.code" 
                                            v-model="form.codeFabric"
                                            @input="setSelected" 
                                            :options="codeOpt" 
                                            placeholder="Type your code number"
                                            label="ref">
                                            <template #list-header>
                                                <b-input-group-append>
                                                    <b-button block variant="light" @click="showMaster()">
                                                        <!-- <b-icon icon="eye-fill" aria-label="Help"></b-icon> -->
                                                        Lihat Semua
                                                    </b-button>
                                                </b-input-group-append>
                                            </template>
                                            <template #option="{ code, description }">
                                                <p style="margin: 0">{{ code }}</p>
                                                <em class="text-muted">{{ description }}</em>
                                            </template>
                                        </v-select>
                                        
                                    
                                    <b-form-invalid-feedback id="inputCodeFabric">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Type Fabric -->
                            <validation-provider
                                name="TypeFabric"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4"  
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Type
                                    </template>
                                
                                    <b-form-input readonly id="inputTypeFabric" v-model="form.typeFabric"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputTypeFabric">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Description -->
                            <validation-provider
                                name="DescriptionFabric"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Description
                                    </template>
                                
                                    <b-form-input readonly id="DescriptionFabric" v-model="form.descriptionFabric"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="DescriptionFabric">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Detail Fabric -->
                            <validation-provider
                                name="DetailFabric"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Detail
                                    </template>
                                
                                    <b-form-textarea id="DetailCodeFabric" v-model="form.detailFabric"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                    <b-form-invalid-feedback id="DetailCodeFabric">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            
                        </b-col>
                        <b-col cols="auto" md="6">
                            <!-- UsedFabric -->
                            <validation-provider
                                name="UsedFabric"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Used As
                                    </template>
                                    <b-form-select id="inputUsedFabric" v-model="form.usedFabric"  :options="usedOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputUsedFabric">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Images Fabric -->
                            <!-- <validation-provider
                                name="imagesFabric"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Images Fabric
                                    </template>
                                    <b-form-file
                                        multiple
                                        ref="fileInput"
                                        id="inputImagesFabric"
                                        v-model="form.imagesFabric"
                                        no-drop
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        :file-name-formatter="formatNames"
                                        v-on:change="onFileChange"
                                    ></b-form-file>
                                        <div v-for="img in imagesUrl" :key="img.name">
                                            <p class="small">
                                                <a :href="img.src">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="inputImagesBotton">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>

                                
                            </validation-provider> -->
                            <b-form-group
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images
                                </template>
                                    <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesFabric" ></b-img>

                            </b-form-group>
                            
                            <div>
                                <b-button block  variant="success" type="submit">Save</b-button>
                            </div>
                        </b-col>
                        
                        
                        
                    </b-row>
                        
                </b-form>
            </validation-observer>
                
                <b-table
                    responsive
                    striped 
                    hover
                    :items="loadProductMaterial"
                    :fields="fields"
                >
                    
                    
                    <template #cell(images)="{item}"   >
                        <p class="small" v-for="img in item.images" :key="img.src">
                            <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                        </p>
                    </template>
                    <template #cell(actions)="data">
                        <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                            <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                        </b-button>
                        <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                            
                            <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                        </b-button>
                        <b-button title="View" size="xs" variant="dark" v-on:click="modalFabricView = true">
                            
                            <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            
        </template>
        <b-modal
            hide-backdrop
            hide-footer
            v-model="modalMaster"
            size="xl"
            ref="modal"
            scrollable 
            title="Master Fabric">
            <master-fabric/>
        </b-modal>
        <b-modal
            hide-backdrop
            hide-footer
            v-model="modalFabricView"
            size="xl"
            ref="modal"
            scrollable 
            title="Fabric">
            <template>
                <b-card>
                    <b-row v-for="(value,key) in fabricList[0]" :key="key">
                        <b-col sm="auto" md="4">
                            <label>{{key.toUpperCase()}}</label>
                        </b-col>
                        <b-col sm="auto" md="4">
                            <label>{{value}}</label>
                        </b-col>
                    </b-row>
                </b-card>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';
import MasterFabric from '../Master/MasterFabric.vue';
export default {
  components: { MasterFabric },
    name:'FormFabric',
    data(){
        return{
            mainProps: {width: 150, class: 'm1' },
            formName: 'Fabric',
            modulName: null,
            tabIndex:1,
            isValid:false,
            
            fields:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                'used_as'
            ],
            imagesUrl:[],
            form:{
                indexId:null,
                codeFabric:null,
                typeFabric:null,
                imagesFabric:[],
                detailFabric:null,
                usedFabric:null,
                descriptionFabric:null,
                fabricId:null,
                category:'fabric',
                fabricProcess:'Yes',
            },
            codeOpt:[
                {code:'Code A', ref:'Code A'},
                {code:'Code B', ref:'Code B'},
                {code:'Code C', ref:'Code C'},
                {code:'Code D', ref:'Code D'},
                
            ],
            usedOpt:[
                {value:'Main', text:'Main'},
                {value:'Combo 1', text:'Combo 1'},
                {value:'Combo 2', text:'Combo 2'},
                {value:'Combo 3', text:'Combo 3'},
            ],
            fabricOpt: [
                { item: 'tbd', name: 'TBD', notEnabled: true },
                { item: 'yes', name: 'Yes' },
                { item: 'no', name: 'No', notEnabled: true},
                
            ],
            isSelect:'yes',
            modalMaster:false,
            modalFabricView:false,
        }
    },
    methods:{
        ...mapActions('productDesign',['add','delete','update','get']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            
            if(this.form.indexId == null){
                this.add({
                    formname: this.formName,
                    category: this.form.category,
                    material_id: this.form.fabricId,
                    code: this.form.codeFabric,
                    type: this.form.typeFabric,
                    images: this.imagesUrl,
                    used_as: this.form.usedFabric,
                    detail: this.form.detailFabric,
                    description: this.form.descriptionFabric,
                    isProccess:this.isSelect,
                    fabric_process:this.form.fabricProcess
                })
            }else{
                this.update({
                    formname: this.formName,
                    id:this.form.indexId,
                    fabric_id: this.form.fabricId,
                    detail:this.form.detailFabric,
                    used_as:this.form.usedFabric,
                    isProccess:this.isSelect,
                    fabric_process:this.form.fabricProcess
                })
            }
            
            this.clearForm()
        
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeFabric = null
            this.form.typeFabric = null
            this.form.imagesFabric = []
            this.imagesUrl = []
            this.form.detailFabric = null
            this.form.usedFabric = null
            this.form.fabricId = null
            this.form.descriptionFabric = null
            this.$refs.observer.reset()
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                // this.form.imagesFabric.push(file[i])
                // this.imagesUrl = URL.createObjectURL(file[i])
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
            return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        deleteData(id){
            
            this.delete({id:id,formname:this.formName})
        },
        editData(index){
            
            this.get({id:index,formname:this.formName})
            this.form.indexId   = index
            this.form.fabricId = this.fabricDetail.id
            this.form.codeFabric   = this.fabricDetail.code
            this.form.typeFabric   = this.fabricDetail.type
            this.form.detailFabric = this.fabricDetail.detail
            this.imagesUrl      = this.fabricDetail.images
            this.form.usedFabric    = this.fabricDetail.used_as
            this.form.descriptionFabric = this.fabricDetail.description
            
        },
        showMaster(){
            this.modalMaster = true
        },
        setSelected(value){
            
            if(value != null){
                let dataFabric = this.MASTER_MATERIAL.filter(x => x.code === value)
                
                this.form.fabricId= dataFabric[0].id
                this.form.codeFabric= dataFabric[0].code
                this.form.typeFabric= dataFabric[0].type
                this.form.descriptionFabric = dataFabric[0].description
                this.form.imagesFabric = dataFabric[0].img_src
            }else{
                console.log('belum ada data')
            }
        },
        getFabricMaster(){
            this.GET_MASTER_MATERIAL().then(response => {
                this.codeOpt = []
                    let objFabric = response.data.payload.filter(x=>x.category === 'fabric')
                    for(var i=0;i<objFabric.length;i++){
                        
                            let arrFabric = {
                                ref: objFabric[i].code,
                                code: objFabric[i].code,
                                description:objFabric[i].description
                            }
                            this.codeOpt.push(arrFabric)
                    }
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
    },

    mounted(){
        if(this.form.indexId != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        this.getFabricMaster()

    },
    computed:{
        ...mapGetters('productDesign',['fabricList','fabricDetail','PRODUCT_MATERIAL']),
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        loadProductMaterial(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='fabric')
        }
    }
}
</script>