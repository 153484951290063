<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto" md="6">
                        <!-- Duration -->
                            <validation-provider
                                name="Duration"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Duration <code>*</code>
                                    </template>
                                    <b-form-select value-field="value"
                                        text-field="value"  id="inputDuration" v-model="form.duration" :options="durationOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputDuration">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Duration -->

                        <!-- Brand -->
                            <validation-provider
                                name="brand"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Brand <code>*</code>
                                    </template>
                                    <b-form-select value-field="value"
                                        text-field="value" v-on:change="resetBrand()" id="inputBrand" v-model="form.brand" :options="brandOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Brand -->

                        <!-- Collection Name -->
                            <validation-provider
                                name="CollectionName"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Collection Name<code>*</code>
                                    </template>
                                    <b-form-input id="inputCollectionName" v-model="form.collectionName" :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputCollectionName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Collection Name -->

                        <!-- Quartal -->
                            <validation-provider
                                name="Quartal"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Quartal<code>*</code>
                                    </template>
                                    <!-- <b-form-input id="inputQuartal" v-model="form.quartal"  :state="getValidationState(validationContext)"  trim></b-form-input> -->
                                    <b-form-select
                                        id="inputQuartal"
                                        v-model="form.quartal"
                                        :options="quartalOpt"
                                        value-field="value"
                                        text-field="text"
                                        :state="getValidationState(validationContext)"
                                        trim></b-form-select>
                                    <b-form-invalid-feedback id="inputQuartal">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Quartal -->

                        <!-- Year -->
                            <validation-provider
                                name="Year"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Year<code>*</code>
                                    </template>
                                    <b-form-select
                                        id="inputYear"
                                        v-model="form.year"
                                        :options="yearOpt"
                                        value-field="value"
                                        text-field="text"
                                        v-on:change="generateArticle()"
                                        :state="getValidationState(validationContext)"
                                        trim>
                                        </b-form-select>
                                    <b-form-invalid-feedback id="inputYear">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Year -->

                        <!-- Prod. Month -->
                            <validation-provider
                                name="ProdMonth"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Prod. Month<code>*</code>
                                    </template>
                                    <b-form-select
                                        id="inputProdMonth"
                                        v-model="form.prodMonth"
                                        :options="monthOpt"
                                        value-field="value"
                                        text-field="value"
                                        :state="getValidationState(validationContext)"
                                        trim></b-form-select>
                                    <b-form-invalid-feedback id="inputProdMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Prod. Month -->

                        <!-- Coll. Month -->
                            <validation-provider
                                name="CollMonth"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Coll. Month<code>*</code>
                                    </template>
                                    <b-form-select
                                        value-field="value"
                                        v-on:change="generateArticle()"
                                        text-field="text" id="inputCollMonth" v-model="form.collMonth" :options="monthOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputCollMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Coll. Month -->

                        <!-- Sub Brand -->
                            <validation-provider
                                name="SubBrand"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Sub. Brand<code>*</code>
                                    </template>
                                    <b-form-select value-field="value"
                                        text-field="text" v-on:change="generateArticle()" id="inputSubBrand" v-model="form.subBrand" :options="setSubBrand"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputSubBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Sub Brand -->

                        <!-- Category -->
                            <validation-provider
                                name="Category"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Category<code>*</code>
                                    </template>
                                    <b-form-select value-field="value"
                                        text-field="text" v-on:change="generateArticle()" id="inputCategory" v-model="form.category" :options="setCategory"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputCategory">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Category -->
                        <!-- Note -->
                            <validation-provider
                                name="Note"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Note<code>*</code>
                                    </template>
                                    <b-form-textarea

                                        id="inputNote"
                                        v-model="form.note"
                                        :state="getValidationState(validationContext)"
                                        trim></b-form-textarea>
                                    <b-form-invalid-feedback id="inputNote">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Note -->
                        <!-- Color -->
                            <validation-provider
                                name="Color"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Color<code>*</code>
                                    </template>
                                    <b-form-select value-field="value"
                                        text-field="text" v-on:change="generateArticle()" id="inputColor" v-model="form.color" :options="colorOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputColor">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Color -->
                        <!-- Intensity -->
                            <validation-provider
                                name="Intensity"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Intensity<code>*</code>
                                    </template>
                                    <b-form-select value-field="value"
                                        text-field="label" v-on:change="generateArticle()" id="inputIntensity" v-model="form.intensity" :options="intensityOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputIntensity">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Intensity -->
                        <!-- Class Level -->
                        <!-- <validation-provider
                                name="Note"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Intensity<code>*</code>
                                    </template>
                                <b-form-radio-group
                                    v-model="isSelect"
                                    :options="classOpt"
                                    class="mb-3"
                                    value-field="value"
                                    text-field="text"
                                    disabled-field="notEnabled"
                                ></b-form-radio-group>
                                    <b-form-invalid-feedback id="inputNote">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider> -->
                        <!-- /Class Level -->
                    </b-col>
                    <b-col cols="auto" md="6">
                            <!-- Article -->
                            <validation-provider
                                name="Article"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Article<code>*</code>
                                    </template>
                                    <b-form-input readonly id="inputArticle" v-model="form.article"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputArticle">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Article -->

                        <!-- Design -->
                            <!-- <validation-provider
                                name="Design"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Design<code>*</code>
                                    </template>
                                    <b-form-file
                                        ref="filedesignImages"
                                        id="inputDesign"
                                        v-model="form.design"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        :file-name-formatter="formatNamesDesignImages"
                                        v-on:change="onDesignFileChange"
                                    ></b-form-file>
                                        <div v-for="img in designImages" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeImgDesign(img.fileName,designImages)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="inputDesign">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider> -->
                        <!-- /Design -->

                        <!-- Design -->
                            <validation-provider
                                name="Design"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Design
                                    </template>
                                    <b-form-file

                                        multiple
                                        ref="fileDesign"
                                        id="Design"
                                        name="design"
                                        v-model="form.design"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        v-on:change="onFileUpload"
                                    ></b-form-file>
                                        <div v-for="img in form.imgDesign" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src" target="_blank">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeFileUpload(img.fileName,form.imgDesign,'fileDesign')" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="Design">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Design -->

                        <!-- Repeat Sample -->
                            <validation-provider
                                name="RepeatSample"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Repeat Sample<code>*</code>
                                    </template>
                                    <b-form-radio-group
                                        id="inputRepatSample"
                                        v-model="form.repeatSample"
                                        :change="setRepeat()"
                                        name="repeat-sample"
                                    >
                                        <b-form-radio  value="yes">Yes</b-form-radio>
                                        <b-form-radio  value="no">No</b-form-radio>
                                    </b-form-radio-group>
                                    <b-form-invalid-feedback id="inputRepatSample">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Repeat Sample -->

                        <!-- No. Sample -->
                            <validation-provider
                                name="NoSample"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Sample Number<code>*</code>
                                    </template>
                                    <template v-if="readOnly === true">
                                        <b-form-input id="inputNoSample" v-model="form.noSample"   :state="getValidationState(validationContext)"  trim></b-form-input>
                                    </template>
                                    <template v-else>
                                        <v-select
                                            label="ref"
                                            v-model="form.noSample"
                                            :reduce="ref => ref.code"
                                            @input="setSelected"
                                            :options="refSampeOpt"
                                            :filter-by="myFilter"
                                            placeholder="Type your sample number"
                                            >
                                            <template #option="{ ref, old }">
                                                <p style="margin: 0">{{ ref }}</p>
                                                <em class="text-muted">{{ old }}</em>
                                            </template>
                                        </v-select>
                                    </template>
                                    <b-form-invalid-feedback id="inputNoSample">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /No. Sample -->
                        <!-- Old No. Sample -->
                            <validation-provider
                                name="OldSample"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Old Sample Number<code>*</code>
                                    </template>
                                    <b-form-input
                                        id="inputOldSample"
                                        v-model="form.oldSample"

                                        :state="getValidationState(validationContext)"
                                        trim></b-form-input>
                                    <b-form-invalid-feedback id="inputOldSample">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Old No. Sample -->

                        <!-- Guidance -->
                            <validation-provider
                                name="Guidance"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Guidance<code>*</code>
                                    </template>
                                    <b-form-select

                                        value-field="value"
                                        text-field="value"
                                        id="inputGuidance"
                                        v-model="form.guidance"
                                        :options="guidanceOpt"
                                        :state="getValidationState(validationContext)"
                                        trim></b-form-select>
                                    <b-form-invalid-feedback id="inputGuidance">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Guidance -->

                        <!-- SpecSize -->
                            <validation-provider
                                name="SpecSize"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Size Spec<code>*</code>
                                    </template>
                                    <b-form-select

                                        value-field="value"
                                        text-field="text"
                                        id="inputSpecSize"
                                        v-model="form.specSize"
                                        :options="setSizeSpec"
                                        :state="getValidationState(validationContext)"
                                        trim></b-form-select>
                                    <b-form-invalid-feedback id="inputSpecSize">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /SpecSize -->

                        <!-- Label -->
                            <!-- <validation-provider
                                name="Technical"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Technical Drawing
                                    </template>
                                    <b-form-file
                                        :disabled="!readOnly"
                                        multiple
                                        ref="filetechnicalImages"
                                        id="inputTechincal"
                                        v-model="form.technical"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        :file-name-formatter="formatNamesTechnicalImages"
                                        v-on:change="onTechnicalFileChange"
                                    ></b-form-file>
                                        <div v-for="img in technicalImages" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeImgTechnical(img.fileName,technicalImages)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="inputTechnical">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider> -->
                        <!-- /Label -->
                        <!-- Technical Drawing -->
                            <validation-provider
                                name="TechnicalDrawing"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Technical Drawing
                                    </template>
                                    <b-form-file
                                        multiple
                                        ref="fileTechnicalDrawing"
                                        id="TechnicalDrawing"
                                        name="technical-drawing"
                                        v-model="form.technicalDrawing"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        v-on:change="onFileUpload"
                                    ></b-form-file>
                                        <div v-for="img in form.imgTechnicalDrawing" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src" target="_blank">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeFileUpload(img.fileName,form.imgTechnicalDrawing,'fileTechnicalDrawing')" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="TechnicalDrawing">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Technical Drawing -->
                        <!-- Code Main Label -->
                            <validation-provider
                                name="CodeMainLabel"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code Main Label
                                    </template>
                                    <b-form-file

                                        multiple
                                        ref="fileCodeMainLabel"
                                        id="CodeMainLabel"
                                        name="code-main-label"
                                        v-model="form.codeMainLabel"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        v-on:change="onFileUpload"
                                    ></b-form-file>
                                        <div v-for="img in form.imgCodeMainLabel" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src" target="_blank">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeFileUpload(img.fileName,form.imgCodeMainLabel,'fileCodeMainLabel')" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="CodeMainLabel">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Code Main Label -->

                        <!-- Code Streamer Label -->
                            <validation-provider
                                name="CodeStreamerLabel"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code Streamer Label
                                    </template>
                                    <b-form-file

                                        multiple
                                        ref="fileCodeStreamerLabel"
                                        id="CodeStreamerLabel"
                                        name="code-streamer-label"
                                        v-model="form.codeStreamerLabel"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        v-on:change="onFileUpload"
                                    ></b-form-file>
                                        <div v-for="img in form.imgCodeStreamerLabel" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src" target="_blank">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeFileUpload(img.fileName,form.imgCodeStreamerLabel,'fileCodeStreamerLabel')" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="CodeStreamerLabel">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Code Streamer Label -->
                        <!-- Code Size Label -->
                            <validation-provider
                                name="CodeSizeLabel"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code Size Label
                                    </template>
                                    <b-form-file

                                        multiple
                                        ref="fileCodeSizeLabel"
                                        id="CodeSizeLabel"
                                        name="code-size-label"
                                        v-model="form.codeSizeLabel"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        v-on:change="onFileUpload"
                                    ></b-form-file>
                                        <div v-for="img in form.imgCodeSizeLabel" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src" target="_blank">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeFileUpload(img.fileName,imgCodeSizeLabel)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="inputTechnical">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Code Size Label -->
                        <!-- Code Hang Tag -->
                            <validation-provider
                                name="CodeHangtag"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3"
                                    label-cols-lg       ="3"
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code Hang Tag
                                    </template>
                                    <b-form-file

                                        multiple
                                        ref="fileCodeHangtag"
                                        id="CodeHangtag"
                                        name="code-hang-tag"
                                        v-model="form.codeHangtag"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        v-on:change="onFileUpload"
                                    ></b-form-file>
                                        <div v-for="img in form.imgCodeHangtag" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src" target="_blank">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeFileUpload(img.fileName,imgCodeHangtag)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="inputTechnical">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Code Hang Tag -->

                    </b-col>
                </b-row>
                <!-- <div>
                    <b-button block  variant="success" type="submit">Save</b-button>
                    <b-button block  variant="light" v-on:click="goToList()" >Cancel</b-button>
                </div>  -->

            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import apiProduct from '@/store/product-form/master-param.js'
import 'vue-select/dist/vue-select.css';
import Vue from 'vue';
export default {
    props:['productId','productMethods','modulCreate'],
    name:'FormProductDesign2',
    data(){
        const now = new Date()
        const today = now.toISOString().slice(0,10)
        return{
            formName: 'Product2',
            process:'Product Dev. Design',
            modulName: null,
            tabIndex:0,
            designImages:[],
            mainFabricImages:[],
            technicalImages:[],
            refSample:null,
            readOnly:true,
            imgTest:[],
            productDetail:null,
            form:{
                indexId:null,
                code:null,
                duration:null,
                brand:null,
                collectionName:null,
                year:null,
                quartal:null,
                prodMonth:null,
                collMonth:null,
                subBrand:null,
                category:null,
                color:null,
                intensity:null,
                article:null,
                design:null,
                imgDesign:[],
                status:'Waiting For Approval',
                noSample:null,
                guidance:null,
                specSize:null,
                technical:[],
                repeatSample:null,
                note:null,
                date:today,
                oldSample:null,
                sampleID:null,
                technicalDrawing:null,
                imgTechnicalDrawing:[],
                codeMainLabel:null,
                codeStreamerLabel:null,
                codeSizeLabel:null,
                codeHangtag:null,
                imgCodeMainLabel:[],
                imgCodeStreamerLabel:[],
                imgCodeSizeLabel:[],
                imgCodeHangtag:[],
                isProccess:null,

            },
            code:{
                brandCode:null,
                yearCode:null,
                monthCode:null,
                subbrandCode:null,
                categoryCode:null,
                colorCode:null,
                intensityCode:null
            },
            codeOpt:[
                {value:'1', text:'Code A'},
                {value:'2', text:'Code B'},
                {value:'3', text:'Code C'},
                {value:'4', text:'Code D'},
            ],
            brandOpt:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'ANTHM',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
            ],
            monthOpt:[
                {value:'1', text:'Januari'},
                {value:'2', text:'Febuari'},
                {value:'3', text:'Maret'},
                {value:'4', text:'April'},
            ],
            yearOpt:[

            ],
            subBrandOpt:[
                {value:'Subbrand A', text:'Sub Brand A'},
                {value:'Subbrand B', text:'Sub Brand B'},
                {value:'Subbrand C', text:'Sub Brand C'},
                {value:'Subbrand D', text:'Sub Brand D'},
            ],
            categoryOpt:[
                {value:'Category A', text:'Category A'},
                {value:'Category B', text:'Category B'},
                {value:'Category C', text:'Category C'},
                {value:'Category D', text:'Category D'},
            ],
            modelOpt:[
                {value:'1', text:'Model A'},
                {value:'2', text:'Model B'},
                {value:'3', text:'Model C'},
                {value:'4', text:'Model D'},
            ],
            noSampleOpt:[
                {value:'1', text:'No Sample A'},
                {value:'2', text:'No Sample B'},
                {value:'3', text:'No Sample C'},
                {value:'4', text:'No Sample D'},
            ],
            refSampeOpt:[
                {code:'1',ref:'Sample A'},
                {code:'2',ref:'Sample B'},
                {code:'3',ref:'Sample C'}
                // {code:'4',ref:'Sample D'}
            ],
            guidanceOpt:[
                {value:'1', text:'guidance A'},
                {value:'2', text:'guidance B'},
                {value:'3', text:'guidance C'},
                {value:'4', text:'guidance D'},
            ],
            specSizeOpt:[
                {value:'1', text:'SpecSize A'},
                {value:'2', text:'SpecSize B'},
                {value:'3', text:'SpecSize C'},
                {value:'4', text:'SpecSize D'},
            ],
            technicalOpt:[
                {value:'1', text:'TechImg1.jpg'},
                {value:'2', text:'TechImg2.jpg'},
                {value:'3', text:'TechImg3.jpg'},
                {value:'4', text:'TechImg4.jpg'},
            ],
            noteOpt:[
                {value:'1', text:'Note Sample 1'},
                {value:'2', text:'Note Sample 2'},
                {value:'3', text:'Note Sample 3'},
                {value:'4', text:'Note Sample 4'},
            ],
            quartalOpt:[
                {value:'1', text:'1'},
                {value:'2', text:'2'},
                {value:'3', text:'3'},
                {value:'4', text:'4'},
            ],
            durationOpt:[
                {value:'basic', text:'BASIC'},
                {value:'online', text:'ONLINE'},
                {value:'regular', text:'REGULAR'},
            ],
            classOpt:[
                {value:'A+', text:'A+'},
                {value:'A', text:'A'},
                {value:'B', text:'B'},
                {value:'C', text:'C'},
            ],
            intensityOpt:[
                {text:'DARK',value:'9',label:'DARK - 9'},
                {text:'LIGHT',value:'1',label:'LIGHT - 1'},
                {text:'MEDIUM',value:'5',label:'MEDIUM - 5'},
            ],
            colorOpt:[

            ],
            isSelect:null,
            myFilter: (option, label, search) => {
                let temp = search.toLowerCase();
                return option.ref.toLowerCase().indexOf(temp) > -1 ||
                option.old.toLowerCase().indexOf(temp) > -1
            }
        }
    },
    methods:{
        ...mapActions('productDesign',['addProduct','deleteProduct','updateProduct','GET_PRODUCT_SAMPLE','GET_PRODUCT_DETAIL','getProduct']),
        ...mapActions('sizeSpec',['GET_SIZESPEC_DASHBOARD']),
        ...mapActions('session',['showNotification']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getProductImg(){
            this.getProduct().then(response =>{
                let data = response.filter(x=>x.id === this.productId)

                // for (let i = 0; i < data.length; i++) {
                //     let img = {
                //         img_id:data[i].design_id,
                //         img_code:data[i].design_code,
                //         img_name:data[i].design_name,
                //         img_src:APIUrl + data[i].design_src,
                //         img_label:data[i].design_label,

                //     }

                //     this.productImg.push(img)

                // }

            })
        },
        resetBrand(){
            Vue.set(this.form,'subBrand',null)
            Vue.set(this.form,'category',null)
            Vue.set(this.form,'article',null)
            Vue.set(this.form,'noSample',null)

            this.generateArticle()

        },
        generateArticle(){

            if((this.form.brand && this.form.year && this.form.collMonth && this.form.subBrand && this.form.category ) != null){
                let brandId      = this.brandOpt.filter(i=>i.value === this.form.brand)

                let brandCode    = this.brandOpt.filter(x=>x.value === this.form.brand)
                let yearCode     = this.yearOpt.filter(x=>x.value === this.form.year)
                let monthCode    = this.monthOpt.filter(x=>x.value === this.form.collMonth)

                let subbrandCode = this.subBrandOpt.filter(x=>x.value === this.form.subBrand && x.parent === brandId[0].id )
                let categoryCode = this.categoryOpt.filter(x=>x.value === this.form.category && x.parent === brandId[0].id )


                let intensityCode   = this.intensityOpt.filter(x=>x.value === this.form.intensity)

                let colorCode       = this.colorOpt.filter(x=>x.value === this.form.color)


                this.form.article = brandCode[0].name+'-'+yearCode[0].name+monthCode[0].name+subbrandCode[0].name+categoryCode[0].name+'XX-'+colorCode[0].name+intensityCode[0].value
                Vue.set(this.code,'brandCode',brandCode[0].name)
                Vue.set(this.code,'yearCode',yearCode[0].name)
                Vue.set(this.code,'monthCode',monthCode[0].name)
                Vue.set(this.code,'subbrandCode',subbrandCode[0].name)
                Vue.set(this.code,'categoryCode',categoryCode[0].name)
                Vue.set(this.code,'intensityCode',intensityCode[0].value)
                Vue.set(this.code,'colorCode',colorCode[0].name)

                if(this.readOnly === false){
                    this.form.noSample = brandCode[0].name+'/'+yearCode[0].name+'/'+monthCode[0].name
                }
            }

        },
        getDataParam(){

            apiProduct.dispatch('getListMasterParam')
            .then(response => {

                    this.monthOpt = []
                    this.yearOpt = []
                    this.modelOpt = []
                    this.guidanceOpt = []
                    this.subBrandOpt = []
                    this.categoryOpt = []
                    this.color = []

                    for (let i = 0; i < response.data.payload.length; i++) {
                        const param = {
                            id:response.data.payload[i].id,
                            category:response.data.payload[i].category,
                            name:response.data.payload[i].name,
                            value:response.data.payload[i].value,
                            description:response.data.payload[i].description,
                            parent:response.data.payload[i].parent,
                            text:response.data.payload[i].value+' - '+response.data.payload[i].name
                        }

                        if(response.data.payload[i].category === 'Month'){
                            this.monthOpt.push(param)
                        }if(response.data.payload[i].category === 'Year'){
                            this.yearOpt.push(param)
                        }if(response.data.payload[i].category === 'Model'){
                            this.modelOpt.push(param)
                        }if(response.data.payload[i].category === 'Guidance'){
                            this.guidanceOpt.push(param)
                        }if(response.data.payload[i].category === 'Sub Brand'){
                            this.subBrandOpt.push(param)
                        }if(response.data.payload[i].category === 'Category'){
                            this.categoryOpt.push(param)
                        }if(response.data.payload[i].category === 'Color'){
                            this.colorOpt.push(param)
                        }

                    }

                    this.brandOpt = response.data.payload.filter(d=>d.category ==='Brand')
                    // this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')

                    // this.yearOpt = response.data.payload.filter(d=>d.category ==='Year')

                    // this.modelOpt = response.data.payload.filter(d=>d.category ==='Model')
                    // this.guidanceOpt = response.data.payload.filter(d=>d.category ==='Guidance')
                    // // this.specSizeOpt = response.data.payload.filter(d=>d.category ==='Fitting')
                    // this.subBrandOpt = response.data.payload.filter(d=>d.category ==='Sub Brand')
                    // this.categoryOpt = response.data.payload.filter(d=>d.category ==='Category')
                    // this.colorOpt = response.data.payload.filter(d=>d.category ==='Color')

            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })

        },
        getSizeSpec(){
            this.specSizeOpt = []
            this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
                let sizeSpec = JSON.parse(JSON.stringify( rsp.data.payload))
                for (let i = 0; i < sizeSpec.length; i++) {
                    // if(sizeSpec[i].size_detail !=null){
                        // if(sizeSpec[i].size_detail != '[]'){
                    const objSize = {
                        value: sizeSpec[i].id,
                        text: sizeSpec[i].category +' - '+ sizeSpec[i].description,
                        brand: sizeSpec[i].brand,
                        category: sizeSpec[i].category
                    }
                    this.specSizeOpt.push(objSize)
                    // }
                    // }
                }
            })
        },
        onSubmit(){
            this.$refs.observer.validate()
            .then(success => {

                if (success === true){
                    this.addProduct({
                        duration:this.form.duration,
                        brand:this.form.brand,
                        collectionName:this.form.collectionName,
                        year:this.form.year,
                        quartal:this.form.quartal,
                        prodMonth:this.form.prodMonth,
                        collMonth:this.form.collMonth,
                        subBrand:this.form.subBrand,
                        category:this.form.category,
                        article:this.form.article,
                        design:this.form.imgDesign,
                        repeatSample:this.form.repeatSample,
                        noSample:this.form.noSample,
                        oldSample:this.form.oldSample,
                        guidance:this.form.guidance,
                        specSize:this.form.specSize,
                        technical:this.form.imgTechnicalDrawing,
                        status:this.form.status,
                        note:this.form.note,
                        date:this.form.date,
                        process: this.process,
                        sample_id: this.form.sampleID,
                        mainLabel:this.form.imgCodeMainLabel,
                        streamerLabel:this.form.imgCodeStreamerLabel,
                        sizeLabel:this.form.imgCodeSizeLabel,
                        hangTag:this.form.imgCodeHangtag,
                        isProccess:this.form.isProccess,
                        color:this.form.color,
                        itensity:this.form.intensity,
                        brand_code:this.code.brandCode,
                        year_code:this.code.yearCode,
                        month_code:this.code.monthCode,
                        subbrand_code:this.code.subbrandCode,
                        category_code:this.code.categoryCode,
                        color_code:this.code.colorCode,
                        intensity_code:this.code.intensityCode,
                        fabric_process:'Yes',
                        accessories_process:'TBD',
                        thread_process:'TBD',
                        artwork_process:'TBD',
                        interlining_process:'TBD',
                    })

                }else{
                     alert('Isi yg bertanda **')
                }
            }).catch(error =>{
                console.log(error);
            })


        },
        goToList(){
            this.showNotification({type:'success', message:'Successfully'})
            this.$router.push({name:'AppsProductDesign2Page'})
        },
        onFileUpload(e){
            var file = e.target.files
            var idField = e.target.id
            var pathName = e.target.name
            this.form['img'+idField] = []

            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                    images: file[i],
                    img_name:file[i].name,
                    img_code:file[i].name+'-'+[i],
                    img_label:pathName
                }
                this.form['img'+idField].push(img)
            }

        },
        formatNamesUploadFiles(e) {
            var idField = e.target.id
            return this.form['img'+idField].length === 0 ? this.$refs['file'+idField].reset() : `${this.form['img'+idField].length} files selected`
        },
        removeFileUpload:function(filename,modelname,refname){

            modelname.splice(filename,1);
            return this.$refs[refname].reset()
        },
        onDesignFileChange(e){

            var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                    images: file[i],
                    img_name:file[i].name,
                    img_code:file[i].name+'-'+[i]
                }

                this.designImages.push(img)

            }






        },
        onMainFabricFileChange(e){
             var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.mainFabricImages.push(img)
            }
        },
        onTechnicalFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                    images: file[i],
                    img_name:file[i].name,
                    img_code:file[i].name+'-'+[i]
                }
                this.technicalImages.push(img)
            }
        },
        removeImgDesign:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNamesDesignImages()
        },
        formatNamesDesignImages() {
            return this.designImages.length === 0 ? this.$refs.filedesignImages.reset() : `${this.designImages.length} files selected`
        },
        removeImgTechnical:function(filename,modelname){

            modelname.splice(filename,1);
            return this.formatNamesTechnicalImages()
        },
        formatNamesTechnicalImages() {
            return this.technicalImages.length === 0 ? this.$refs.filetechnicalImages.reset() : `${this.technicalImages.length} files selected`
        },
        getProductData(){




            this.$set(this,'productDetail',this.GET_PRODUCT_DETAIL)

            this.form.brand = this.productDetail.brand,
            this.form.collectionName = this.productDetail.collection_name,
            this.form.year = this.productDetail.year,
            this.form.quartal = this.productDetail.quartal,
            this.form.prodMonth = this.productDetail.prod_month,
            this.form.collMonth = this.productDetail.coll_month,
            this.form.subBrand = this.productDetail.subbrand,
            this.form.category = this.productDetail.category,
            this.form.article = this.productDetail.article,
            this.designImages = this.productDetail.design,
            this.form.design = this.productDetail.design,
            this.form.repeatSample = this.productDetail.repeat_sample,
            this.form.noSample = this.productDetail.ref_sample,
            this.form.oldSample = this.productDetail.old_sample_no,
            this.form.guidance = this.productDetail.guidance,
            this.form.specSize = this.productDetail.size_spec,
            this.technicalImages = this.productDetail.technical,
            this.form.status = this.productDetail.status,
            this.form.note = this.productDetail.note,
            this.form.color = this.productDetail.color,
            this.form.intensity = this.productDetail.itensity,
            this.form.duration = this.productDetail.duration

        },
        setSelected(value){

            if (value === null){
                this.form.guidance = null
                this.form.specSize = null
                // this.form.technicalDrawing = null
                this.form.oldSample = null
                this.form.sampleID = null

            }else{

                let dataProductSample = this.PRODUCT_SAMPLE.filter(ps => ps.sample_id === value)

                this.form.sampleID = dataProductSample[0].sample_id
                this.form.oldSample =dataProductSample[0].old_sample_no
                this.form.guidance =dataProductSample[0].guidance
                this.form.specSize =dataProductSample[0].size_spec


            }
        },

        setSample(value){
            let dataProductSample = this.PRODUCT_SAMPLE.filter(ps => ps.sample_id === value)


            this.form.oldSample =dataProductSample[0].old_sample_no


            this.setSelected(value)

        },
        setOldSample(value){
            let dataProductSample = this.PRODUCT_SAMPLE.filter(ps => ps.sample_id === value)



            this.form.noSample = dataProductSample[0].ref_sample
            this.setSelected(value)
        },
        setRepeat(){
            if(this.form.repeatSample === "yes"){
                this.readOnly = false
            }if (this.form.repeatSample === "no") {
                this.readOnly = true
                if((this.form.brand && this.form.year && this.form.collMonth) != null){
                    let brandCode    = this.brandOpt.filter(x=>x.value === this.form.brand)
                    let yearCode     = this.yearOpt.filter(x=>x.value === this.form.year)
                    let monthCode    = this.monthOpt.filter(x=>x.value === this.form.collMonth)
                    this.form.noSample = brandCode[0].name+'/'+yearCode[0].value+'/'+ monthCode[0].value.substring(0,3)

                }
            }else{
                this.readOnly = false
            }
        },
        getProductSample(){
            this.GET_PRODUCT_SAMPLE().then(response =>{
                this.refSampeOpt = []

                let objSample   = response.data.payload
                for(var i=0;i<objSample.length;i++){
                    let arrSample = {
                        ref: objSample[i].ref_sample,
                        code: objSample[i].sample_id,
                        old: objSample[i].old_sample_no
                    }
                    this.refSampeOpt.push(arrSample)
                }
            })
        },
        clearForm(){
            this.form.indexId=null
            this.form.code=null
            this.form.duration=null
            this.form.brand=null
            this.form.collectionName=null
            this.form.year=null
            this.form.quartal=null
            this.form.prodMonth=null
            this.form.collMonth=null
            this.form.subBrand=null
            this.form.category=null
            this.form.color=null
            this.form.intensity=null
            this.form.article=null
            this.form.design=null
            this.form.imgDesign=[]
            this.form.status=null
            this.form.noSample=null
            this.form.guidance=null
            this.form.specSize=null
            this.form.technical=[]
            this.form.repeatSample=null
            this.form.note=null
            this.form.date=null
            this.form.oldSample=null
            this.form.sampleID=null
            this.form.technicalDrawing=null
            this.form.imgTechnicalDrawing=[]
            this.form.codeMainLabel=null
            this.form.codeStreamerLabel=null
            this.form.codeSizeLabel=null
            this.form.codeHangtag=null
            this.form.imgCodeMainLabel=[]
            this.form.imgCodeStreamerLabel=[]
            this.form.imgCodeSizeLabel=[]
            this.form.imgCodeHangtag=[]
            this.form.isProccess=null

        },
    },
    mounted(){
        this.getDataParam()
        this.getSizeSpec()

        this.getProductSample()
    },
    created(){
        if(this.modulCreate == 'create'){


            this.clearForm()
            this.modulName = 'Create'
        }

        else if(this.GET_PRODUCT_DETAIL){
            this.clearForm()

            this.modulName = 'Edit'

            this.getProductData()
            this.getProductImg()
        }

    },
    computed:{
        ...mapGetters('productDesign',['product','PRODUCT_SAMPLE','GET_PRODUCT_DETAIL','GET_PRODUCT_MATERIAL']),

        setSubBrand(){

            let brandId = this.brandOpt.filter(i=>i.value == this.form.brand)
            return this.subBrandOpt.filter(x=>x.parent == brandId[0].id)
        },
        setCategory(){

            let brandId = this.brandOpt.filter(i=>i.value == this.form.brand)

            return this.categoryOpt.filter(i=>i.parent == brandId[0].id)
        },
        setSizeSpec(){
            let brandName = this.brandOpt.filter(i =>i.value == this.form.brand )

            let categoryName = this.categoryOpt.filter(i => (i.value == this.form.category && i.parent == brandName[0].id))
            let sizeArr = JSON.parse(JSON.stringify(this.specSizeOpt))

            let sizeName = sizeArr.filter(i => (i.brand == brandName[0].value && i.category == categoryName[0].value))
            return sizeName
        }
    },
    watch:{
        productMethods:function(newVal, oldVal){

            this.onSubmit()
        }
    }
}
</script>
