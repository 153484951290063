import Vue from "vue";
import Vuex from "vuex";
import {
  fnApiFetchHealthDailyAccess,
  fnApiFetchHealthDailyAccessV2,
  fnApiFetchHealthDailyVerifAccess,
} from "../../api/api-applications-health.js";

Vue.use(Vuex);

const actions = {
  postDaily(context, formDaily) {
    // console.log(formDaily)
    return new Promise((resolve, reject) => {
      fnApiFetchHealthDailyAccess(formDaily)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postDailyV2(context, formDaily) {
    // console.log(formDaily)
    return new Promise((resolve, reject) => {
      fnApiFetchHealthDailyAccessV2(formDaily)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  postDailyVerification(context, formDaily) {
    // console.log(formDaily)
    return new Promise((resolve, reject) => {
      fnApiFetchHealthDailyVerifAccess(formDaily)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default new Vuex.Store({
  namespaced: true,
  actions,
});
