import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchMasterCustomer,
    fnApiFetchEditMasterCustomer,
}from '../../api/api-applications-salesforce.js'

Vue.use(Vuex)

const actions = {
    postMasterCustomer(context, formMasterCustomer){
        return new Promise((resolve, reject) => {
            fnApiFetchMasterCustomer(formMasterCustomer)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    EditMasterCustomer(context, formMasterCustomer){
        return new Promise((resolve, reject) => {
            fnApiFetchEditMasterCustomer(formMasterCustomer)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
}

export default new Vuex.Store({
    namespaced: true,
    actions
})