import { render, staticRenderFns } from "./DailyHealthReportV4.vue?vue&type=template&id=67c4660c&scoped=true&"
import script from "./DailyHealthReportV4.vue?vue&type=script&lang=js&"
export * from "./DailyHealthReportV4.vue?vue&type=script&lang=js&"
import style0 from "./DailyHealthReport.scss?vue&type=style&index=0&id=67c4660c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c4660c",
  null
  
)

/* custom blocks */
import block0 from "./DailyHealthReportV4.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Funpkg.com%2Fbootstrap-vue%40latest%2Fdist%2Fbootstrap-vue.min.css"
if (typeof block0 === 'function') block0(component)

export default component.exports