<template>
    <div class="tables-basic">
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>Aplications</b-breadcrumb-item>
            <b-breadcrumb-item active>Assigned SPG</b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid="lg">
            <b-row align-h="center">
                <b-col cols="12" lg>
                    <b-card header-tag="header" footer-tag="footer">
                        <template #header>
                            <h4 class="float-left mt-2">Assigned SPG</h4>
                            <b-button variant="outline-success btn-sm float-right" @click="showInputSpg()"><b-icon icon="journal-plus"> </b-icon> Add New</b-button>
                        </template>
                        <div>
                            <div class="float-left form-inline">
                                <select class="form-control mb-3" v-model="deptCode" @change="onChange()">
                                    <option value="">-- Assigned Dept --</option>
                                    <option v-for="option in options" :key="option.id" :value="option.department">
                                        {{ option.department }}
                                    </option>
                                </select>
                            </div>

                            <div class="float-right">
                                <b-input-group size="sm">
                                    <b-form-input
                                        id="filter-input"
                                        v-model="search"
                                        type="search"
                                        placeholder="Type to Search"
                                        @input="searchSpg()"
                                        >
                                    </b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!search" @click="search = '',getListSpg()">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>

                            <b-table
                                id="my-table"
                                responsive
                                show-empty
                                small
                                fixed 
                                :items="items"
                                :fields="fields"
                                :per-page="perPage"
                                :current-page="currentPage">
                                <template v-slot:cell(action)="{ item }">
                                    <span>
                                        <b-btn variant="outline-danger mr-2" size="xs"  @click="confirmDeleteSpg(item.nik,item.first_name)">
                                            <b-icon icon="trash"> </b-icon>
                                        </b-btn>
                                        <b-btn variant="outline-warning" size="xs" @click="showEditSpg(item.nik)">
                                            <b-icon icon="pencil"> </b-icon>
                                        </b-btn>

                                    </span>
                                </template>
                            </b-table>
                        </div>
                        <template #footer>
                            <b-container>
                                <b-row>
                                    <b-col sm="2" md="4" class="my-1">
                                        <label>Showing {{ perPage }} of {{rows }}</label>
                                    </b-col>
                                    <b-col sm="2" md="4" class="my-1">
                                        <b-form-group
                                            label="Per page"
                                            label-for="per-page-select"
                                            label-cols-sm="3"
                                            label-cols-md="3"
                                            label-cols-lg="3"
                                            label-align-sm="right"
                                            label-size="sm"
                                            class="mb-0"
                                            >
                                            <b-form-select
                                                id="per-page-select"
                                                v-model="perPage"
                                                :options="pageOptions"
                                                style="width: 100px;"
                                                class="text-center"
                                            ></b-form-select>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="2" md="4" class="my-1">
                                        <b-pagination
                                            v-model="currentPage"
                                            :total-rows="rows"
                                            :per-page="perPage"
                                            aria-controls="my-table"
                                            class="float-right"
                                            style="border: 1px;"
                                            page-class="customPage"
                                            >
                                        </b-pagination>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <b-modal id="bv-modal-spg" hide-footer modal-class="my-modal">
            <template #modal-title>
                Form Input User SPG
            </template>
            <b-form @submit.prevent="(postMethod ? saveDataSpg() : updateDataSpg())">
                <div class="d-block">
                    <b-container>
                        <b-row>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="NIK / Username:"
                                    label-for="input-1">
                                    <b-form-input
                                        id="input-1"
                                        type="text"
                                        placeholder="Enter NIK" 
                                        v-model="postData.nik"
                                        :class="{ 'is-invalid' : errors.nik }">
                                        >
                                    </b-form-input>
                                    <small class="text-danger" v-show="errors.nik">
                                        {{ (this.errors.nik ? this.errors.nik[0] : '') }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="Name:"
                                    label-for="input-1">
                                    <b-form-input
                                        id="input-1"
                                        type="text"
                                        placeholder="Enter name" 
                                        v-model="postData.name"
                                        :class="{ 'is-invalid' : errors.name }">
                                    </b-form-input>
                                    <small class="text-danger" v-show="errors.name">
                                        {{ (this.errors.name ? this.errors.name[0] : '') }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="Gender:"
                                    label-for="input-1">
                                    <select class="form-control" v-model="postData.gender" :class="{ 'is-invalid' : errors.gender }">
                                        <option value="">--Select Gender--</option>
                                        <option value="M">MALE</option>
                                        <option value="F">FEMALE</option>
                                    </select>
                                    <small class="text-danger" v-show="errors.gender">
                                        {{ (this.errors.gender ? this.errors.gender[0] : '') }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="Department:"
                                    label-for="input-1">
                                    <select class="form-control" 
                                        v-model="postData.organization"
                                        :class="{ 'is-invalid' : errors.organization }" @change="changeDept()">
                                        <option value="">--Select Department--</option>
                                        <option v-for="option in options" :key="option.id" :value="option.department">
                                            {{ option.department }}
                                        </option>
                                    </select>
                                    <small class="text-danger" v-show="errors.organization">
                                        {{ (this.errors.organization ? this.errors.organization[0] : '') }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="Position:"
                                    label-for="input-1">
                                    <b-form-input
                                        id="input-1"
                                        type="text"
                                        placeholder="Enter Position"
                                        readonly value="NSTR">
                                    </b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="Job:"
                                    label-for="input-1">
                                    <b-form-input
                                        id="input-1"
                                        type="text"
                                        placeholder="Enter Job" 
                                        v-model="postData.job"
                                        :class="{'is-invalid' : errors.job}" readonly>
                                    </b-form-input>
                                    <small class="text-danger" v-show="errors.job">
                                        {{ (this.errors.job ? this.errors.job[0] : '') }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="Area:"
                                    label-for="input-1"
                                    description="exp: AO-01">
                                    <b-form-input
                                        id="input-1"
                                        type="text"
                                        placeholder="Enter Area" 
                                        v-model="postData.area"
                                        :class="{'is-invalid' : errors.area}"
                                        style="text-transform:uppercase">
                                    </b-form-input>
                                    <small class="text-danger" v-show="errors.area">
                                        {{ (this.errors.area ? this.errors.area[0] : '') }}
                                    </small>
                                </b-form-group>
                            </b-col>
                            <b-col sm="12" md="6">
                                <b-form-group
                                    id="input-group-1"
                                    label="Sub Area:"
                                    label-for="input-1">
                                    <b-form-input
                                        id="input-1"
                                        type="text"
                                        placeholder="Enter Subarea" 
                                        v-model="postData.subarea"
                                        :class="{'is-invalid' : errors.subarea}"
                                        style="text-transform:uppercase">
                                    </b-form-input>
                                    <small class="text-danger" v-show="errors.subarea">
                                        {{ (this.errors.subarea ? this.errors.subarea[0] : '') }}
                                    </small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-container>
                </div>
                <div class="modal-footer">
                    <b-button type="submit" variant="primary">{{ (postMethod ? 'Submit' : 'Update') }}</b-button>
                    <b-button type="button" variant="danger" @click="resetForm()">Reset</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>
<style scoped lang="scss">
    ::v-deep{
        #select-dept{
            width: 40%;
        }
        .modal-body{
            background-color: #fff;
        }
        .customPage.page-item.active .page-link {
            background-color: rgb(65, 115, 176);
            border-color: rgb(61, 255, 174);
            color: #fff;
            font-weight: 600;
        }
        .customPage.page-item .page-link {
            background-color: rgb(230, 230, 230);
            border-color: gray;
    
        }
    
        table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        }
    
        table td, table th {
        border: 1px solid #ddd;
        padding: 8px;
        }
    
        table tr:nth-child(even){background-color: #f2f2f2;}
    
        table tr:hover {background-color: #ddd;}
    
        table th {
        padding-top: 15px;
        padding-bottom: 15px;
        text-align: left;
        background-color: gray;
        color: white;
        }
    }
</style>
<script>
    import axios from 'axios';
    import Vue from "vue";
    import moment from 'moment'
    import VueSweetalert2 from "vue-sweetalert2";
    import "sweetalert2/dist/sweetalert2.min.css";
    import { APIUrl, getAPICore } from '../../../api/api-base';
    import { mapGetters } from "vuex";
    Vue.use(VueSweetalert2);
    export default {
        data() {
        return {
            accessTokenData: localStorage.getItem("access_token"),
            perPage: 100,
            currentPage: 1,
            pageOptions: [10, 50, 100, 200],
            fields: [
                {
                    key: "action",
                    label: "ACTION",
                    thStyle: { width: "7%" },
                }, 
                {
                    key: "nik",
                    label: "NIK",
                    thStyle: { width: "10%" },
                },
                {
                    key: "first_name",
                    label: "NAME",
                },
                {
                    key: "department",
                    label: "DEPT. CODE",
                    thStyle: { width: "10%" },
                    tdClass: 'text-center',
                },
                {
                    key: "organization_name",
                    label: "DEPT. NAME",
                },
                {
                    key: "area",
                    label: "AREA",
                    thStyle: { width: "5%" },
                    tdClass: 'text-center',
                },
                {
                    key: "subarea",
                    label: "SUBAREA",
                    thStyle: { width: "8%" },
                    tdClass: 'text-center',
                },
                {
                    key: "date",
                    label: "LAST UPDATE",
                    thStyle: { width: "15%" },
                    tdClass: 'text-center',
                    formatter: "format_date"
                }, 
            ],
            items: [],
            options: [],
            search: null,
            deptCode: '',
            postData: {
                id: '',
                nik : '',
                name: '',
                gender: '',
                organization: '',
                job: '',
                area: '',
                subarea:''
            },
            errors: '',
            postMethod: true,
            idParam: ''
        }
        },
        computed: {
            ...mapGetters("session", ["userData"]),
            rows() {
                return this.items.length
            }
        },
        mounted(){
            this.getListSpg()
            this.getListDeptSpg()
        },
        methods:{
            getListDeptSpg(){
                getAPICore.get(APIUrl+'/api/sfa/list-dept/')
                .then(response =>
                    this.options = response.data.payload
                )
                .catch(function (error) {
                    console.log(error);
                })
            },
            getListSpg() {
                getAPICore.get(APIUrl+'/api/sfa/')
                .then(response =>
                    this.items = response.data.payload
                )
                .catch(function (error) {
                    console.log(error);
                })
            },
            confirmDeleteSpg(id,name) {
                this.$swal({
                    title: 'Are you sure?',
                    text: "Are you sure want to delete ( "+name+" )",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.deleteSpg(id)
                    }
                });
            },
            onChange(){
                if(this.deptCode){
                    getAPICore.get(APIUrl+'/api/sfa/?q='+this.deptCode)
                    .then(response =>
                        this.items = response.data.payload
                    )
                    .catch(function (error) {
                        this.$swal(
                            'Error 500',
                            'Somethink went wrong!',
                            'danger'
                        )
                    })
                }else{
                    this.getListSpg()
                }
            },
            searchSpg(){
                this.deptCode = ''
                getAPICore.get(APIUrl+'/api/sfa/?q='+this.search)
                .then(response =>
                    this.items = response.data.payload
                )
                .catch(function (error) {
                    console.log(error);
                })
            },
            saveDataSpg(){
                getAPICore.post(APIUrl+'/api/sfa/',this.postData)
                .then((res) =>{
                    this.getListSpg(),
                    this.$bvModal.hide('bv-modal-spg'),
                    this.$swal(
                        'Success',
                        'Data saved successfully.',
                        'success'
                    ),
                    this.createLog({
                        log_name : 'Create Data',
                        nik_spg : this.postData.nik,
                        is_success : true,
                        desc : res.data.message,
                    })
                })
                .catch(e => {
                    if(e.response.data.status == 400){
                        this.errors = e.response.data.message
                        setTimeout(()=>{
                            this.errors = ''
                        },5000);
                        
                    }else{
                        this.$swal(
                            'ERROR 500',
                            'Somethink went wrong!',
                            'danger'
                        )
                        this.createLog({
                            log_name : 'Create Data',
                            nik_spg : this.postData.nik,
                            is_success : false,
                            desc : e.response.data.message,
                        })
                    }
                });
            },
            updateDataSpg(){
                this.errors = ''
                getAPICore.put(APIUrl+'/api/sfa/',this.postData)
                .then((res) =>{
                    this.getListSpg(),
                    this.$bvModal.hide('bv-modal-spg'),
                    this.$swal(
                        'Success',
                        'Data updated successfully.',
                        'success'
                    ),
                    this.createLog({
                        log_name : 'Update Data',
                        nik_spg : this.postData.nik,
                        is_success : true,
                        desc : res.data.message,
                    })
                })
                .catch(e => {
                    if(e.response.data.status == 400){
                        this.errors = e.response.data.message
                        setTimeout(()=>{
                            this.errors = ''
                        },5000);
                    }else{
                        this.$swal(
                            'ERROR 500',
                            'Somethink went wrong!',
                            'danger'
                        )
                        this.createLog({
                            log_name : 'Update Data',
                            nik_spg : this.postData.nik,
                            is_success : false,
                            desc : e.response.data.message,
                        })
                    }
                });
            },
            resetForm(){ 
                this.postData.nik  = '',
                this.postData.name = '',
                this.postData.gender = '',
                this.postData.organization = '',
                this.postData.job = '',
                this.postData.area = '',
                this.postData.subarea =''
            },
            showInputSpg(){
                this.resetForm()
                this.$bvModal.show('bv-modal-spg')
                this.postMethod = true
            },
            showEditSpg(id){
                this.resetForm()
                getAPICore.post(APIUrl+'/api/sfa/detail/',{nik:id})
                .then(response =>{
                    const data =  response.data.payload
                    this.postData = {
                        id:id,
                        nik: data.nik,
                        name: data.first_name,
                        gender: data.gender,
                        organization: data.department,
                        job: data.job,
                        area: data.area,
                        subarea: data.subarea
                    },
                    this.$bvModal.show('bv-modal-spg'),
                    this.postMethod = false,
                    this.idParam = id
                })
                .catch(function (error) {
                    console.log(error);
                })
            },
            deleteSpg(id){
                getAPICore.post(APIUrl+'/api/sfa/delete/',{
                    headers: {
                        "Access-Control-Allow-Origin" : "*",
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.accessTokenData}`
                    },
                    nik:id,
                    credential: true

                })
                .then((res) => {
                    this.getListSpg(),
                    this.$swal(
                        'Deleted!',
                        'Your data has been deleted.',
                        'success'
                    ),
                    this.createLog({
                        log_name : 'Delete Data',
                        nik_spg : id,
                        is_success : true,
                        desc : res.data.message,
                    })
                })
                .catch((e) => {
                    this.$swal(
                        'Oops..!',
                        'Failed delete data.',
                        'info'
                    )
                    this.createLog({
                        log_name : 'Delete Data',
                        nik_spg : id,
                        is_success : false,
                        desc : e.response.data.message,
                    })
                })
            },
            createLog(log){
                getAPICore.post(APIUrl+'/api/sfa/log/',{
                    pic : this.userData.username,
                    log_name : log.log_name,
                    nik_spg : log.nik_spg,
                    is_success : log.is_success,
                    desc : log.desc,
                })
            },
            format_date(value){
                if (value) {
                return moment(String(value)).format('DD/MM/YYYY (HH:mm:ss)')
                }
            },
            changeDept(){
                const dept = this.postData.organization
                const strLength = dept.length
                const result = dept.slice(0,2) + "A" + dept.slice((strLength-(strLength+2))) + "NSTR"
                this.postData.job = result
            }
        }
    }
</script>