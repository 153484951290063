<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ stActiveReport }}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold">{{ stActiveReport }}</span>
      <span class="fw-normal fs-larger"> {{ rptVersion }} </span>
    </h2>
    <b-row>
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <b-row class="form">
            <!-- datepicker From -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">From*</label>
                <b-form class="datepicker">
                  <VueDatePicker
                    v-model="selectedDate"
                    class="vd__div"
                    no-header
                    format="DD MMM YYYY"
                    min-date="1900-01-01"
                    :max-date="selectedDateTo"
                    placeholder="Choose report date start"
                  />
                </b-form>
              </div>
              <!-- <b-form-group label="From*" >
                <b-form-datepicker placeholder="Choose report date start" v-model="selectedDate" 
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit' }" locale="id"></b-form-datepicker>
              </b-form-group> -->
            </b-col>
            <!-- end datepicker From -->

            <!-- datepicker To -->
            <b-col xs="12" md="3" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">To*</label>
                <VueDatePicker
                  class="vd__div"
                  v-model="selectedDateTo"
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="max_date"
                  placeholder="Choose report date start"
                />
              </div>
            </b-col>
            <!-- end datepicker To -->

            <!-- Filter Lokasi -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
                <label>Location*</label>
                <v-select
                  label="site_name"
                  :reduce="(data) => data.site_name"
                  :options="this.siteList"
                  v-model="selectedSite"
                  class="form-custom custom-select"
                  ref="opt_dept"
                ></v-select>
              </div>
            </b-col>
            <!-- end Filter Dept Code -->

            <!-- Filter NIK -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
                <label>Employee Id**</label>
                <input
                  class="form-control mb-3 bg-transparent"
                  type="text"
                  name="userid"
                  id="userid"
                  v-model="inputUserId"
                />
              </div>
            </b-col>
            <!-- end Filter NIK -->
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button
                  class="btn-spacing"
                  type="button"
                  variant="primary"
                  :disabled="isLoadingData"
                  v-on:click="populateReport()"
                  >Load Report</b-button
                >
                <download-excel
                  :class="exportButtonClass()"
                  :data="rptData"
                  :fields="excel_fields"
                  :title="excel_title"
                  :name="excel_name"
                >
                  Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button
                  class="btn-spacing-left"
                  type="button"
                  variant="secondary"
                  v-on:click="goToReport()"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Processed at</b-col>
                  <b-col xs="7" md="8" lg="10">: {{ processedDateTime }}</b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Total Data</b-col>
                  <b-col xs="7" md="8" lg="10">: {{ rptDataCount }}</b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col xs="4" md="3" lg="2">Resiko</b-col>
                  <b-col xs="8" md="9" lg="10"
                    >: <span>Tinggi {{ sumHighRisk }} </span> |
                    <span>Sedang {{ sumMedRisk }} </span> |
                    <span>Rendah {{ sumLowRisk }}</span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col xs="12">
        <div class="table-responsive">
          <table class="table table-lg mb-0">
            <thead>
              <tr style="text-align: center">
                <th>TANGGAL SIMPAN</th>
                <th>TANGGAL</th>
                <th>NAMA</th>
                <th>NIK</th>
                <th>DEPARTMENT</th>
                <!-- <th>SUHU > 37.2</th> -->
                <th>KONDISI KESEHATAN</th>
                <th>NILAI RESIKO</th>
                <th>PROFIL RESIKO</th>
                <!-- OPT POSITIF COVID 19 -->
                <th>POSITIF COVID-19</th>
                <th>TANGGAL TERPAPAR COVID-19</th>
                <th>
                  PENYAKIT BAWAAN (DIABETES/DARAH
                  TINGGI/ASMA/KOLESTROL/KANKER/GINJAL/PARU)
                </th>
                <th>TANGGAL LAHIR</th>
                <th>KELUHAN SEBELUM KARANTINA</th>
                <th>NOMOR HP/WA PRIBADI</th>
                <th>NOMOR HP/WA KELUARGA</th>
                <th>PENYEBAB TERPAPAR COVID-19</th>
                <th>ISOLASI HARI KE-</th>
                <th>OPTION POSITIF COVID-19 LAINNYA</th>
                <!-- /OPT POSITIF COVID 19 -->

                <!-- KONDISI KESEHATAN KELUARGA -->
                <th>KELUARGA SAKIT</th>
                <th>KEKERABATAN</th>
                <th>PENYAKIT KERABAT</th>
                <!-- /KONDISI KESEHATAN KELUARGA -->

                <th>BEPERGIAN</th>

                <!-- <th>TUJUAN</th> -->
                <th>TEMPAT KERJA</th>
                <th>LOKASI KERJA</th>
                <th>TRANSPORTASI</th>
                <th>LAYANAN KESEHATAN</th>
                <th>NAMA LAYANAN KESEHATAN</th>
                <th>BERBELANJA</th>
                <th>LOKASI BERBELANJA</th>
                <th>SWALAYAN</th>
                <th>KEGIATAN KEAGAMAAN</th>
                <th>PESTA</th>
                <th>CLUB OLAHRAGA</th>
                <th>MAKAN DI RESTORAN</th>
                <th>PERUSAHAAN LAIN</th>
                <th>TEMPAT HIBURAN</th>
                <th>KELUAR KOTA</th>
                <th>TUJUAN KELUAR KOTA</th>
                <th>ALASAN BEPERGIAN LAINNYA</th>

                <th>HARI LIBUR/OFF</th>
                <th>ISOLASI MANDIRI</th>
                <th>WORK FROM HOME</th>
                <th>CUTI/IZIN</th>
                <th>ALASAN TIDAK PERGI LAINNYA</th>

                <th>KUNJUNGAN TAMU</th>
                <th>KEPERLUAN BERTAMU</th>
                <!-- <th>NAMA TAMU</th> -->
                <th>RIWAYAT KONTAK 14 HARI</th>
                <th>STATUS VERIFIKASI</th>
                <th>TGL VERIFIKASI</th>
                <th>NIK VERIFIKATOR</th>
                <th>NAMA VERIFIKATOR</th>
                <th>KET. VERIFIKASI</th>
                <th>COORDINATES</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="text-align: center"
                v-for="(row, index) in rptData"
                :key="row.id"
                :class="setBackground(index)"
              >
                <!-- <td>{{convertToReadableDateTime(row.tanggal_simpan)}}</td> -->
                <td
                  :class="
                    chkDateNotMatch(
                      row.tanggal_simpan_char,
                      row.tanggal_harian_char
                    )
                  "
                >
                  {{ row.tanggal_simpan_char }}
                </td>
                <td>{{ row.tanggal_harian_char }}</td>
                <td>{{ row.nama_karyawan }}</td>
                <td>{{ row.nik }}</td>
                <td>{{ row.department }}</td>
                <!-- <td>{{row.suhu}}</td> -->
                <!-- <td :class="{'bg-sick':isSick(row.kondisi_kesehatan) }">{{row.kondisi_kesehatan}}</td> -->
                <td
                  :class="
                    chkSickCounter(row.count_lama_sakit, row.kondisi_kesehatan)
                  "
                >
                  {{ row.kondisi_kesehatan }}
                </td>
                <td :class="chkNilaiResiko(row.profil_resiko)">
                  {{ row.nilai_resiko }}
                </td>
                <td :class="chkProfilResiko(row.profil_resiko)">
                  {{ row.profil_resiko }}
                </td>
                <!-- OPTION POSITIF COVID-19 -->
                <td>{{ row.positif_covid }}</td>
                <td>{{ row.tgl_positif_covid }}</td>
                <td>{{ row.penyakit_bawaan }}</td>
                <td>{{ row.tanggal_lahir }}</td>
                <td>{{ row.keluhan_karantina }}</td>
                <td>{{ row.nomor_wa }}</td>
                <td>{{ row.nomor_wa_keluarga }}</td>
                <td>{{ row.penyebab_positif_covid }}</td>
                <td>{{ row.isolasi_hari_ke }}</td>
                <td>{{ row.opt_positif_lainnya }}</td>
                <!-- /OPTION POSITIF COVID-19 -->
                <!-- KONDISI KESEHATAN KELUARGA -->
                <td>{{ row.keluarga_sakit }}</td>
                <td>{{ row.nama_keluarga_sakit }}</td>
                <td>{{ row.kondisi_keluarga_sakit }}</td>
                <!-- /KONDISI KESEHATAN KELUARGA -->

                <td>{{ row.bepergian }}</td>
                <!-- <td>{{row.tempat_tujuan}}</td> -->
                <td>{{ row.tempat_kerja }}</td>
                <td>{{ row.lokasi_kerja }}</td>
                <td>{{ row.transportasi }}</td>
                <td>{{ row.layanan_kesehatan }}</td>
                <td>{{ row.nama_layanan_kesehatan }}</td>
                <td>{{ row.berbelanja }}</td>
                <td>{{ row.lokasi_berbelanja }}</td>
                <td>{{ row.warung_tradisional }}</td>
                <td>{{ row.kegiatan_keagamaan }}</td>
                <td>{{ row.pesta }}</td>
                <td>{{ row.club_olahraga }}</td>
                <td>{{ row.makan_direstoran }}</td>
                <td>{{ row.perusahaan_lain }}</td>
                <td>{{ row.tempat_hiburan }}</td>
                <td>{{ row.keluar_kota }}</td>
                <td>{{ row.tujuan_keluar_kota }}</td>
                <td>{{ row.bepergian_lainnya }}</td>

                <td>{{ row.hari_libur }}</td>
                <td>{{ row.isolasi_mandiri }}</td>
                <td>{{ row.work_from_home }}</td>
                <td>{{ row.cuti_izin }}</td>
                <td>{{ row.tidak_pergi_lainnya }}</td>

                <td>{{ row.tamu }}</td>
                <td>{{ row.keperluan_bertamu }}</td>
                <!-- <td>{{row.nama_tamu}}</td> -->

                <td>{{ row.riwayat_covid }}</td>
                <td>{{ row.status }}</td>
                <td>{{ row.review_date_char }}</td>
                <td>{{ row.review_by }}</td>
                <td>{{ row.review_by_name }}</td>
                <td>{{ row.remark }}</td>
                <td>{{ row.coordinates }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <Loader v-if="isLoadingData" fullPage useOverlay></Loader>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import vSelect from "vue-select";

import "vue-select/dist/vue-select.css";

import Loader from "@/components/Loader/Loader";
import { setFocus, showToastOK } from "@/core/pluginHelper";
import { fnApiFetchListDepartment } from "@/api/api-reports.js";
import { fnGET, fnPOST } from "../../../../api/api-methods";
import { APIPATH_HR, APIPATH_BBIAPPS } from "../../../../api/api-paths";

Vue.component("download-excel", JsonExcel);

export default {
  name: "DailyHealthReportV3",
  components: {
    vSelect,
    Loader,
  },
  data() {
    const now = new Date();
    const today = now.toISOString().slice(0, 10);
    return {
      rptVersion: "v3.1",
      rptData: null,
      rptDataCount: null,
      max_date: today,
      processedDateTime: "",
      selectedDate: null,
      selectedDateTo: null,
      selectedSite: null,
      inputUserId: "",
      errorMessage: null,
      isLoadingData: false,
      countDateNotMatch: 0,
      countHighRisk: 0,
      countMedRisk: 0,
      countLowRisk: 0,
      excel_title: "",
      excel_name: "",
      excel_fields: {
        "TANGGAL SIMPAN": "tanggal_simpan_char",
        TANGGAL: "tanggal_harian",
        NAMA: "nama_karyawan",
        NIK: "nik",
        DEPARTMENT: "department",
        // 'SUHU > 37.2'       : 'suhu',
        "KONDISI KESEHATAN": "kondisi_kesehatan",
        "NILAI RESIKO": "nilai_resiko",
        "PROFIL RESIKO": "profil_resiko",

        // OPTION POSITIF COVID-19
        "POSITIF COVID-19": "positif_covid",
        "TANGGAL TERPAPAR COVID-19": "tgl_positif_covid",
        "PENYAKIT BAWAAN (DIABETES/DARAH TINGGI/ASMA/KOLESTROL/KANKER/GINJAL/PARU)":
          "penyakit_bawaan",
        "TANGGAL LAHIR": "tanggal_lahir",
        "KELUHAN SEBELUM KARANTINA": "keluhan_karantina",
        "NOMOR HP/WA PRIBADI": "nomor_wa",
        "NOMOR HP/WA KELUARGA": "nomor_wa_keluarga",
        "PENYEBAB TERPAPR COVID-19": "penyebab_positif_covid",
        "ISOLASI HARI KE-": "isolasi_hari_ke",
        "OPTION POSITIF COVID-19 LAINNYA": "opt_positif_lainnya",

        "KELUARGA SAKIT": "keluarga_sakit",
        KEKERABATAN: "nama_keluarga_sakit",
        "PENYAKIT KERABAT": "kondisi_keluarga_sakit",

        BEPERGIAN: "bepergian",
        // 'TUJUAN'            : 'tempat_tujuan',
        "TUJUAN KELUAR KOTA": "tujuan_keluar_kota",
        "TEMPAT KERJA": "tempat_kerja",
        "LOKASI KERJA": "lokasi_kerja",
        TRANSPORTASI: "transportasi",
        "LAYANAN KESEHATAN": "layanan_kesehatan",
        "NAMA LAYANAN KESEHATAN": "nama_layanan_kesehatan",
        BERBELANJA: "berbelanja",
        "LOKASI BERBELANJA": "lokasi_berbelanja",
        SWALAYAN: "warung_tradisional",
        "KEGIATAN KEAGAMAAN": "kegiatan_keagamaan",
        PESTA: "pesta",
        "CLUB OLAHRAGA": "club_olahraga",
        "MAKAN DI RESTORAN": "makan_direstoran",
        "PERUSAHAAN LAIN": "perusahaan_lain",
        "TEMPAT HIBURAN": "tempat_hiburan",
        "ALASAN BEPERGIAN LAINNYA": "bepergian_lainnya",

        "HARI LIBUR": "hari_libur",
        "ISOLASI MANDIRI": "isolasi_mandiri",
        "POSITIF COVID": "positif_covid",
        "WORK FROM HOME": "work_from_home",
        "CUTI/IZIN": "cuti_izin",
        "ALASAN TIDAK PERGI LAINNYA": "tidak_pergi_lainnya",

        "KUNJUNGAN TAMU": "tamu",
        "KEPERLUAN BERTAMU": "keperluan_bertamu",
        // 'NAMA TAMU'         : 'nama_tamu',
        "KELUAR KOTA": "keluar_kota",
        "RIWAYAT KONTAK 14 HARI": "riwayat_covid",
        "STATUS VERIFIKASI": "status",
        "TGL VERIFIKASI": "review_date_char",
        "NIK VERIFIKATOR": "review_by",
        "NAMA VERIFIKATOR": "review_by_name",
        "KET. VERIFIKASI": "remark",
        COORDINATES: "coordinates",
      },
      siteList: [],
    };
  },
  computed: {
    ...mapState("report", [
      "stActiveReport",
    ]),
    ...mapGetters("report", ["rptDataCount", "rptData", "hasData"]),
    ...mapGetters("session", ["userData", "localDate"]),
    sumHighRisk() {
      let percentage = 0;
      if (this.countHighRisk > 0) {
        percentage =
          (parseFloat(this.countHighRisk) * 100.0) / this.rptDataCount;
      }
      return this.countHighRisk + " ( " + percentage.toFixed(2) + "%)";
    },
    sumMedRisk() {
      let percentage = 0;
      if (this.countMedRisk > 0) {
        percentage =
          (parseFloat(this.countMedRisk) * 100.0) / this.rptDataCount;
      }
      return this.countMedRisk + " ( " + percentage.toFixed(2) + "%)";
    },
    sumLowRisk() {
      let percentage = 0;
      if (this.countLowRisk > 0) {
        percentage =
          (parseFloat(this.countLowRisk) * 100.0) / this.rptDataCount;
      }
      return this.countLowRisk + " ( " + percentage.toFixed(2) + "%)";
    },
    sumDateNotMatch() {
      let percentage = 0;
      if (this.countDateNotMatch > 0) {
        percentage =
          (parseFloat(this.countDateNotMatch) * 100.0) / this.rptDataCount;
      }
      return this.countDateNotMatch + " ( " + percentage.toFixed(2) + "%)";
    },
  },
  methods: {
    ...mapActions("report", [
      "initializeReport",
      "getListDepartment",
      "getDataDailyHealthV2",
      "getDataDailyHealthV3",
      "getCurrentDatetime",
      "generateTitleXLS",
    ]),
    populateReport() {
      this.resetReportSummary();
      if (this.selectedDate === null || this.selectedSite === null) {
        this.errorMessage = "Please select Date and Location filter";
        setFocus(this.$refs.opt_dept);
      } else {
        this.isLoadingData = true;
        if (this.inputUserId == null || this.inputUserId == "") {
          this.inputUserId = "ALL";
        }
        
        fnPOST(APIPATH_HR.rptDataDailyHealthV3Location, {
          nik: this.inputUserId,
          date: this.selectedDate,
          dateto: this.selectedDateTo,
          location: this.selectedSite,
        })
        .then((rsp) => {
          // this.$set(this, "rptData", rsp.data.payload);
          // this.$set(this, "rptDataCount", rsp.data.payload);
          this.rptData = rsp.data.payload;
          this.rptDataCount = rsp.data.payload.length;
          this.postProcessReport(rsp);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            showToastOK({
              type: "error",
              message: "Failed loading report",
            });
          }
        })
        .finally(() => {
          this.isLoadingData = false;
        });
      }
    },
    postProcessReport(apiArg) {
      let cntDateNotMatch =
        document.getElementsByClassName("bg-datenotmatch").length;
      let cntHighRisk = document.getElementsByClassName(
        "bg-riskprofile-high"
      ).length;
      let cntMediumRisk =
        document.getElementsByClassName("bg-riskprofile-med").length;
      let cntLowRisk =
        document.getElementsByClassName("bg-riskprofile-low").length;
      this.countDateNotMatch = cntDateNotMatch;
      this.countHighRisk = cntHighRisk;
      this.countMedRisk = cntMediumRisk;
      this.countLowRisk = cntLowRisk;

      // Deploy toast dialog
      this.getCurrentDatetime()
        .then((rsp) => {
          this.processedDateTime = rsp;
          showToastOK({ type: "success", message: "Report Loaded" });

          this.initExcelTitle();
        })
        .catch((ex) => {
          showToastOK({ type: "error", message: "Failed loading report" });
        });
    },
    goToReport() {
      this.$router.push({ name: "ReportPage" });
    },
    getSiteList() {
      fnGET(APIPATH_BBIAPPS.listSiteName).then((rsp) => {
        let siteAll = {"id":0, "code_site":"ALL", "site_name":"ALL"}
        let tmpSiteList = rsp.data
        tmpSiteList.unshift(siteAll)
        this.$set(this, "siteList", tmpSiteList);
      });
    },
    chkSickCounter(param, chkCovid) {
      if (chkCovid.match("Positif Covid-19")) {
        return "bg-sick-purple";
      } else if (param > 0 && param < 2) {
        return "bg-sick-yellow";
      } else if (param >= 2) {
        return "bg-sick-red";
      }
      return "";
    },
    chkProfilResiko(val) {
      if (val.toLowerCase().trim() == "resiko tinggi") {
        return "bg-riskprofile-high bg-sick-red";
      } else if (val.toLowerCase().trim() == "resiko sedang") {
        return "bg-riskprofile-med bg-sick-yellow";
      } else if (val.toLowerCase().trim() == "resiko rendah") {
        return "bg-riskprofile-low";
      }
      return "";
    },
    chkNilaiResiko(val) {
      if (val.toLowerCase().trim() == "resiko tinggi") {
        return "bg-sick-red";
      } else if (val.toLowerCase().trim() == "resiko sedang") {
        return "bg-sick-yellow";
      }
      return "";
    },
    chkDateNotMatch(param1, param2) {
      let varSubmitDate = param1.toLowerCase().trim().slice(0, 16);
      let varFormDate = param2.toLowerCase().trim().slice(0, 16);
      if (varSubmitDate !== varFormDate) {
        return "bg-datenotmatch";
      }

      return "";
    },
    resetReportSummary() {
      this.errorMessage = null;
      this.processedDateTime = "";
    },
    exportButtonClass() {
      let classNames = "btn btn-info";
      if (this.rptDataCount < 1) {
        classNames += " hide";
      }
      if (this.isLoadingData) {
        classNames += " btn-disabled";
      }
      return classNames;
    },
    initExcelTitle() {
      this.generateTitleXLS({
        rptName: this.stActiveReport,
        rptVersion: this.rptVersion,
        processedDateTime: this.processedDateTime,
        processedBy: this.userData.username + " - " + this.userData.fullname,
      }).then((rsp) => {
        this.excel_name = rsp[0];
        this.excel_title = rsp[1];
      });
    },
    setBackground(idx) {
      if (parseInt(idx) % 2 == 0) {
        return "bg-greyed";
      }
      return "";
    },
  },
  created() {
    this.initializeReport();
    // this.getListDepartment(this.userData.business_unit)
    this.getSiteList();
    this.selectedDate = this.localDate;
    this.selectedDateTo = this.localDate;
  },
};
</script>


<style src="./DailyHealthReport.scss" lang="scss" scoped />

<link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css"/>
