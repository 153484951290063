<template>
    <div class="healt-rapid">
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Rapid Test" header-tag="header">
                        <template v-slot:header >
                            <div class="text-center">
                                <!-- <img src="../../assets/bbi-logo.png" width="40%" alt="Logo BBI"> -->
                                <h3>Form Pemantauan Khusus</h3>
                                <p>
                                    Form ini diisi khusus untuk karyawan dalam pemantauan khusus
                                </p>
                            </div>
                        </template>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onSubmit())">
                                <b-row align-h="center">
                                    <b-col cols="12" sm="auto">

                                        <!-- input nik -->
                                            <b-form-group 
                                                label               ="Nomor Induk Karyawan"
                                                label-for           ="inputNik"
                                            >
                                                <b-form-input readonly id="inputNik" v-model="form.nik"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nik -->

                                        <!-- input nama karyawan -->
                                            <b-form-group 
                                                label               ="Nama Karyawan"
                                                label-for           ="inputNama"
                                            >
                                                <b-form-input readonly id="inputName" v-model="form.nama_karyawan"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nama karyawan -->

                                        <!-- input department -->
                                            <b-form-group 
                                                label               ="Department"
                                                label-for           ="inputDept"
                                            >
                                                <b-form-input readonly id="inputDept" v-model="form.department"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input department -->

                                        <!-- input usia -->
                                            <validation-provider
                                                name="usia"
                                                :rules="{ required: true, numeric:true}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label               ="Usia"
                                                    label-for           ="inputUsia"
                                                    aria-describedby    ="input-usia-feedback"
                                                >
                                                    <b-form-input id="inputUsia" v-model="form.usia" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="input-usia-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input usia -->

                                        <!-- input alamat -->
                                            <validation-provider
                                                name="alamat"
                                                :rules="{ required: true, min: 3}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label               ="Alamat Lengkap"
                                                    label-for           ="inputAlamat"
                                                    aria-describedby    ="input-alamat-feedback"
                                                >
                                                    <b-form-input id="inputAlamat" v-model="form.alamat" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="input-alamat-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input alamat -->

                                        <!-- input hp keluarga -->
                                            <validation-provider
                                                name="hp"
                                                :rules="{ required: true, numeric: true, min:3, max:13}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label               ="Nomor Telp Keluarga yang bisa dihubungi"
                                                    label-for           ="inputHp"
                                                    aria-describedby    ="input-hp-feedback"
                                                >
                                                    <b-form-input id="inputHp" v-model="form.nomor_hp" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="input-hp-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>    
                                        <!-- /input hp keluarga -->

                                        <!-- input nama keluarga -->
                                            <validation-provider
                                                name="keluarga"
                                                :rules="{ required: true, min:2}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label               ="Nama Keluarga"
                                                    label-for           ="inputKeluarga"
                                                    aria-describedby    ="input-keluarga-feedback"
                                                >
                                                    <b-form-input id="inputKeluarga" v-model="form.nama_keluarga" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="input-keluarga-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>    
                                        <!-- /input nama keluarga -->


                                    </b-col>
                                    <b-col cols="12" sm="auto">
                                        <!-- input tanggal -->
                                            <validation-provider
                                                name="tanggal"
                                                :rules="{ required: true}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group>
                                                    <label for="tanggal">Tanggal Rapid</label>
                                                    <b-input-group class="sm-3" aria-describedby= "input-tanggal-feedback">
                                                        <b-form-input
                                                            id="tanggal"
                                                            v-model="form.tanggal_harian"
                                                            type="text"
                                                            autocomplete="off"
                                                            :state="getValidationState(validationContext)"
                                                            readonly
                                                        ></b-form-input>
                                                        <b-input-group-append>
                                                            <b-form-datepicker
                                                                v-model="form.tanggal_harian"
                                                                button-only
                                                                right
                                                                aria-controls="tanggal"
                                                                :max="max"
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                :state="getValidationState(validationContext)"
                                                            ></b-form-datepicker>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback id="input-tanggal-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                    
                                                </b-form-group> 
                                            </validation-provider>
                                        <!-- /input tanggal -->

                                        <!-- input suhu -->
                                            <validation-provider
                                                name="suhu"
                                                :rules="{ required: true,suhuRule, regex: /^\d*\.?\d*$/ }"
                                                v-slot="validationContext"
                                            >

                                                <b-form-group cols="6"
                                                    label               ="Suhu Tubuh"
                                                    label-for           ="inputSuhu"
                                                    aria-describedby    ="input-suhu-feedback"
                                                >
                                                    <b-form-input id="inputSuhu"  v-model="form.suhu" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="input-suhu-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input suhu -->

                                        <!-- input kondisi kesehatan -->
                                            <validation-provider
                                                name="kondisi_kesehatan"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Kondisi Kesehatan">
                                                    <b-form-checkbox-group v-for="optKondisi in options_kondisi" v-bind:key="optKondisi.id">
                                                        <input :state="getValidationState(validationContext)" type="checkbox" @change="check($event)" ref="cbKondisi" :value="optKondisi.value" id="optKondisi.id" v-model="kondisi"  >
                                                            {{ optKondisi.text }}
                                                    </b-form-checkbox-group>
                                                    <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-kondisi-kesehatan-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input kondisi kesehatan -->

                                        <!-- input kondisi lainnya -->
                                            <validation-provider
                                                name="kondisi_lainnya"
                                                :rules="{required:!checkLainnya()}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group cols="6"
                                                    
                                                    label-for           ="inputKondisiLainnya"
                                                    aria-describedby    ="input-kondisi-lainnya-feedback"
                                                >
                                                    <b-form-input 
                                                        name="kondisi_lainnya" 
                                                        size="sm" 
                                                        :disabled="checkLainnya()" 
                                                        :state="getValidationState(validationContext)" 
                                                        id="inputKondisiLainnya" 
                                                        v-model="form.kondisiLainnya" 
                                                        ref="test"
                                                        placeholder="Lainnya..."
                                                        trim>
                                                    </b-form-input>
                                                    <b-form-invalid-feedback  :state="getValidationState(validationContext)"  id="input-kondisi-lainnya-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input kondisi lainnya -->

                                        <!-- input sakit keras -->
                                            <b-form-group 
                                                label               ="Sakit keras yang pernah dialami"
                                                label-for           ="inputSakitKeras"
                                            >
                                                <b-form-input id="inputSakitKeras" v-model="form.sakit_keras"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input sakit keras -->

                                    </b-col>
                                </b-row>    
                                <b-row class="justify-content-sm-center" >
                                    <b-col col sm="8">
                                        <b-button block type="submit" variant="primary">Submit</b-button>
                                    </b-col>
                                </b-row>

                                
                            </form>
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import apiPemantauan from '@/store/health-form/health-pemantauan.js';
    import { extend } from 'vee-validate';

    export default {
        name: 'HealthPemantauan',
        data() {
            const now = new Date()

            return {
                form: {
                    nama_karyawan: null,
                    nik: null,
                    department: null,
                    tanggal_harian: null,
                    usia: null,
                    suhu: null,
                    alamat: null,
                    nomor_hp: null,
                    nama_keluarga: null,
                    kondisiLainnya:null,
                    sakit_keras:null,
                    kondisi_kesehatan:null,
                    lokasi: null,
                    
                },
                
                max: now,
                kondisi: [],
                
                options_kondisi: [
                    { id:1, text: 'Batuk', value: 'batuk'},
                    { id:2, text: 'Pilek', value: 'pilek'},
                    { id:3, text: 'Sakit Tenggorokan', value: 'sakit tenggorokan'},
                    { id:4, text: 'Demam', value: 'demam'},
                    { id:5, text: 'Hilangnya kemampuan mengecap rasa atau mencium bau', value: 'Hilangnya kemampuan mengecap rasa atau mencium bau'},
                    { id:6, text: 'Sehat', value: 'sehat'},
                    { id:7, text: 'Lainnya', value: 'lainnya'},
                ],
            }
        },
        methods: {
            ...mapActions('session', ['showNotification']),
            getAccountInfo() {
                let userData      = JSON.parse(localStorage.userData)
                this.$set(this.form, 'nama_karyawan', userData.fullname)
                this.$set(this.form, 'nik', userData.nik)
                this.$set(this.form, 'department', userData.dept)
                this.$set(this.form, 'lokasi', userData.business_unit)
                
            },
            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
            postRapid(){
                this.form.kondisi_kesehatan = this.kondisi.toString();
                console.log(this.form);
                
            },
            onSubmit() {
                
                let objTemp = this.kondisi.toString()
                if(this.$refs.test.value != null){
                    objTemp = objTemp.replace('lainnya',this.$refs.test.value);
                }

                this.form.kondisi_kesehatan = objTemp

                apiPemantauan.dispatch('postPemantauan', {
                    nama_karyawan: this.form.nama_karyawan,
                    nik: this.form.nik,
                    department: this.form.department,
                    tanggal_harian: this.form.tanggal_harian,
                    suhu: this.form.suhu,
                    usia: this.form.usia,
                    alamat: this.form.alamat,
                    nomor_hp: this.form.nomor_hp,
                    nama_keluarga: this.form.nama_keluarga,
                    sakit_keras: this.form.sakit_keras,
                    kondisi_kesehatan:this.form.kondisi_kesehatan,
                    lokasi: this.form.lokasi
                })
                    .then(response => {
                        console.log(response)
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                        this.$router.push({name:"DashboardPage"})
                    })
                    .catch(err => {
                        if (err.response.status === 400) {
                           this.errorMessage = err.response.data.message;
                        }
                    })
            },
            check: function(e) {
                if(e.target.value == 'sehat'){
                    if(e.target.checked){
                        this.kondisi = []
                        this.kondisi.push(e.target.value)
                    }
                }
                else if(e.target.value != 'sehat'){
                    if(e.target.checked){
                        if(this.kondisi.includes('sehat')) {
                            this.kondisi = this.kondisi.filter(a => a !== 'sehat')
                        }
                    }
                }
                    /* console.log(this.kondisi) */
            },
            checkLainnya(){
                if(this.kondisi.includes('lainnya')){
                    return false;
                }
                this.form.kondisiLainnya = '';
                return true
            },


        },
        mounted: function(){
            this.getAccountInfo()
        },
        created(){
            extend(
                'suhuRule',{
                    getMessage: suhu => suhu + 'Hanya boleh 0 atau 30 s/d 41.99',
                    validate: (value) => {
                        if(value == 0 || value >= 30 && value <= 41.99) return true;
                        return false
                    }
                }
            );  
        },
    }
</script>