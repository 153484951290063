<template>
  <div>Pivot</div>
</template>
<script>
import WebDataRocks from "webdatarocks";
export default {
  name: "PivotT",
  props: {
    afterchartdraw: Function,
    aftergriddraw: Function,
    beforegriddraw: Function,
    beforetoolbarcreated: Function,
    cellclick: Function,
    celldoubleclick: Function,
    componentFolder: String,
    customizeCell: Function,
    customizeContextMenu: Function,
    datachanged: Function,
    dataerror: Function,
    datafilecancelled: Function,
    dataloaded: Function,
    fieldslistclose: Function,
    fieldslistopen: Function,
    filterclose: Function,
    filteropen: Function,
    fullscreen: Function,
    global: Object,
    height: [String, Number],
    loadingdata: Function,
    loadinglocalization: Function,
    loadingolapstructure: Function,
    loadingreportfile: Function,
    localizationerror: Function,
    localizationloaded: Function,
    olapstructureerror: Function,
    olapstructureloaded: Function,
    openingreportfile: Function,
    querycomplete: Function,
    queryerror: Function,
    ready: Function,
    report: [String, Object],
    reportchange: Function,
    reportcomplete: Function,
    reportfilecancelled: Function,
    reportfileerror: Function,
    reportfileloaded: Function,
    runningquery: Function,
    toolbar: Boolean,
    update: Function,
    width: [Number, String],
  },
  mounted: function () {
    this.webdatarocks = new WebDataRocks({
      ...this.$props,
      container: this.$el,
    });
  },
  watch: {
    update: function (newVal, oldVal) {
      // console.log("Prop changed: ", newVal, " | was: ", oldVal);
      // this.webdatarocks = new WebDataRocks({
      //   ...this.$props,
      //   container: this.$el,
      // });
    },
  },
  beforeUpdate() {
    return true;
  },
};
</script>
<style>
@import "../../../node_modules/webdatarocks/webdatarocks.min.css";
/* @import "../../node_modules/webdatarocks/theme/dark/webdatarocks.min.css"; */
</style>
