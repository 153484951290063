<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-monitoring"> Dashboard Monitoring</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">

                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Input Monitoring Form</h3>
                                </div>
                            </div>
                        </template>


                                <b-row align-h="center">
                        <!-- data IO -->

                                <table class="table table-lg">
                                    <tbody>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">IO / PO </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.data_io}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Employee </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_subcon}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Due Date </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.due_date}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Brand </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.brand}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Article </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.artikel}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Sample </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.sample}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Subcon </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_subcon}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Order </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.total_qty}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Cutting Target / Day	</span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_target}}</span>
                                            </td>
                                        </tr>

                                        <tr v-if="form.sewing_id !== null" id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Sewing Target / Day </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.sewing_target}}</span>
                                            </td>
                                        </tr>

                                        <tr v-if="form.finishing_id !== null" id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Finishing Target / Day </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.finishing_target}}</span>
                                            </td>
                                        </tr>

                                        <tr v-if="form.packing_id !== null" id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Packing Target / Day </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.packing_target}}</span>
                                            </td>
                                        </tr>

                                        <tr v-if="form.printing_id !== null" id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Print Target / Day </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.printing_target}}</span>
                                            </td>
                                        </tr>

                                        <tr v-if="form.embroidery_id !== null" id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Embroidery Target / Day </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.embroidery_target}}</span>
                                            </td>
                                        </tr>

                                        <tr v-if="form.emblem_id !== null" id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Emblem Target / Day </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.emblem_target}}</span>
                                            </td>
                                        </tr>

                                        <tr v-if="form.washing_id !== null" id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Washing Target / Day </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.washing_target}}</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                </b-row>

                                <b-row align-h="center">
                                <table class="table table-lg">
                                    <tbody>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Preperation date</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Marker </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.marker}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Accessories </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.acc}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Fabric </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.fabric}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Inspection </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.fabric_inspection}}</span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">PPM </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.ppm}}</span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                </b-row>
                            <!-- END data IO -->

                                    </b-col>
                                </b-row>
                            </b-container>
                            <b-row>
                                <b-col xs="12">
                                    <div class="table-responsive">
                                        <table class="table table-lg mb-0">
                                            <thead>
                                                <tr style="text-align:center; " class=text-table-th>
                                                    <th>
                                                        Process
                                                    </th>
                                                    <th>
                                                        Subcon
                                                    </th>
                                                    <th>
                                                        Start Plan
                                                    </th>
                                                    <th>
                                                        Start Actual
                                                    </th>
                                                    <th>
                                                        Plan (Days)
                                                    </th>
                                                    <th>
                                                        Actual (Days)
                                                    </th>
                                                    <th>
                                                        End Date Plan
                                                    </th>
                                                    <th>
                                                        End Date Actual
                                                    </th>
                                                    <th>
                                                        Status
                                                    </th>
                                                    <th>
                                                        Qty Plan
                                                    </th>
                                                    <th>
                                                        Qty Actual
                                                    </th>
                                                    <th>
                                                        Qty Difference
                                                    </th>
                                                    <th>
                                                        Qty Difference (%)
                                                    </th>
                                                    <th>
                                                        Reason
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody id="tab-process-table">

                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                                <tr id="process0-table" class="hidden">
                                    <td :class='checkDateEndActual(form.cutting_end_date_plan,form.cutting_end_date)'>
                                        Cutting
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.cutting_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.cutting_date_plan !== null" v-html="FormatDate(form.cutting_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.cutting_date !== null" v-html="FormatDate(form.cutting_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.cutting_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.cutting_date !== null">
                                            {{cutting_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.cutting_end_date_plan !== null" v-html="FormatDate(form.cutting_end_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.cutting_end_date !== null" v-html="FormatDate(form.cutting_end_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.cutting_end_date !== null && form.cutting_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.cutting_end_date == null && form.cutting_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.cutting_end_date == null && form.cutting_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_cutting}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        <span v-if="form.cutting_progress !== null ">
                                        {{form.cutting_progress}}
                                        </span>
                                        <span v-if="form.cutting_progress == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        <span v-if="(form.total_qty_cutting - form.cutting_progress) >= 0 ">
                                        {{form.total_qty_cutting - form.cutting_progress}}
                                        </span>
                                        <span style="color:red;" v-if="(form.total_qty_cutting - form.cutting_progress) < 0 ">
                                        (Over {{Math.abs(form.total_qty_cutting - form.cutting_progress)}})
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span style="color:red;" v-if="form.cutting_progress !== null && (form.total_qty_cutting - form.cutting_progress) < 0">
                                        (Over {{Math.abs((((form.total_qty_cutting - form.cutting_progress) / form.total_qty_cutting) *100).toFixed(1))}})%
                                        </span>
                                        <span v-if="form.cutting_progress !== null && (form.total_qty_cutting - form.cutting_progress) >= 0">
                                        {{(((form.total_qty_cutting - form.cutting_progress) / form.total_qty_cutting) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.cutting_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.cutting_ket !== null ">
                                        {{form.cutting_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr id="process1-table" class="hidden">
                                    <td  :class='checkDateEndActual(form.sewing_end_date_plan,form.sewing_end_date)'>
                                        Sewing
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.sewing_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.sewing_date_plan !== null" v-html="FormatDate(form.sewing_date_plan)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.sewing_date !== null" v-html="FormatDate(form.sewing_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.sewing_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.sewing_date !== null">
                                            {{sewing_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.sewing_end_date_plan !== null" v-html="FormatDate(form.sewing_end_date_plan)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.sewing_end_date !== null" v-html="FormatDate(form.sewing_end_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.sewing_end_date !== null && form.sewing_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.sewing_end_date == null && form.sewing_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.sewing_end_date == null && form.sewing_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_sewing}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        <span v-if="form.sewing_progress !== null ">
                                        {{form.sewing_progress}}
                                        </span>
                                        <span v-if="form.sewing_progress == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_sewing - form.sewing_progress}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.sewing_progress !== null ">
                                        {{(((form.total_qty_sewing - form.sewing_progress) / form.total_qty_sewing) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.sewing_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.sewing_ket !== null ">
                                        {{form.sewing_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr style="background:#8ba5cc" id="process-final-table" class="hidden">
                                    <td :class='checkDateEndActual(form.end_plan_qc_final,form.qc_final)'>
                                        Qc Final
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                       PPC
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.plan_qc_final !== null" v-html="FormatDate(form.plan_qc_final)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.start_qc_final !== null" v-html="FormatDate(form.start_qc_final)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                       <span v-if="form.plan_days_delivery !== null">
                                           {{form.plan_days_delivery}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.start_qc_final !== null">
                                           {{qc_final_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.end_plan_qc_final !== null" v-html="FormatDate(form.end_plan_qc_final)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.qc_final !== null" v-html="FormatDate(form.qc_final)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.qc_final !== null">
                                            Done
                                        </span>
                                        <span v-if="form.qc_final == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                       <span v-if="form.total_qty_packing !== null ">
                                        {{form.total_qty_packing}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY Actual	 -->
                                        <span v-if="form.qty_shortship !== null ">
                                        {{form.total_qty_packing - form.qty_shortship}}
                                        </span>
                                        <span v-if="form.qty_shortship == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        <span v-if="form.qty_shortship !== null ">
                                        {{form.qty_shortship}}
                                        </span>
                                        <span v-if="form.qty_shortship == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.qty_shortship !== null && form.qty_shortship > 0">
                                        {{(((form.total_qty_packing - (form.total_qty_packing - form.qty_shortship)) / form.total_qty_packing) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.qty_shortship == null || form.qty_shortship <= 0">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.qc_final_ket !== null ">
                                        {{form.qc_final_ket}}
                                        </span>
                                    </td>
                                </tr>
                                <tr style="background:#f7c257" id="process-delivery-table" class="hidden">
                                    <td :class='checkDateEndActual(form.end_plan_delivery,form.end_date_delivery)'>
                                        Receive
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                       PPC
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.plan_delivery !== null" v-html="FormatDate(form.plan_delivery)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.date_delivery !== null" v-html="FormatDate(form.date_delivery)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                       {{form.plan_days_delivery}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.date_delivery !== null">
                                           {{delivery_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.end_plan_delivery !== null" v-html="FormatDate(form.end_plan_delivery)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.end_date_delivery !== null && form.status_receive == 'Done' " v-html="FormatDate(form.end_date_delivery)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.status_receive == 'New' || form.status_receive == null ">
                                            Open
                                        </span>
                                        <span v-if="form.status_receive == 'Ongoing' ">
                                            Ongoing
                                        </span>
                                        <span v-if="form.status_receive == 'Done' ">
                                            Done
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                       <span v-if="form.total_qty_packing !== null ">
                                        {{form.total_qty_packing}}
                                        </span>
                                        <span v-if="form.total_qty_packing == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY ACTUAL	 -->
                                        <span v-if="form.qty_delivery !== null ">
                                        {{form.qty_delivery}}
                                        </span>
                                        <span v-if="form.qty_delivery == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_packing - form.qty_delivery}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.qty_delivery !== null ">
                                        {{(((form.total_qty_packing - form.qty_delivery) / form.total_qty_packing) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.qty_delivery == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.receive_ket !== null ">
                                        {{form.receive_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr id="process2-table" class="hidden">
                                    <td :class='checkDateEndActual(form.finishing_end_date_plan,form.finishing_end_date)'>
                                        Finishing
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.finishing_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.finishing_date_plan !== null" v-html="FormatDate(form.finishing_date_plan)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.finishing_date !== null" v-html="FormatDate(form.finishing_date)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.finishing_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.finishing_date !== null">
                                            {{finishing_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.finishing_end_date_plan !== null" v-html="FormatDate(form.finishing_end_date_plan)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.finishing_end_date !== null" v-html="FormatDate(form.finishing_end_date)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.finishing_end_date !== null && form.finishing_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.finishing_end_date == null && form.finishing_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.finishing_end_date == null && form.finishing_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_finishing}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        <span v-if="form.finishing_progress !== null ">
                                        {{form.finishing_progress}}
                                        </span>
                                        <span v-if="form.finishing_progress == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_finishing - form.finishing_progress}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.finishing_progress !== null ">
                                        {{(((form.total_qty_finishing - form.finishing_progress) / form.total_qty_finishing) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.finishing_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.finishing_ket !== null ">
                                        {{form.finishing_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr id="process3-table" class="hidden">
                                    <td :class='checkDateEndActual(form.packing_end_date_plan,form.packing_end_date)'>
                                        Packing
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.packing_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.packing_date_plan !== null" v-html="FormatDate(form.packing_date_plan)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.packing_date !== null" v-html="FormatDate(form.packing_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.packing_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.packing_date !== null">
                                            {{packing_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.packing_end_date_plan !== null" v-html="FormatDate(form.packing_end_date_plan)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.packing_end_date !== null" v-html="FormatDate(form.packing_end_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.packing_end_date !== null && form.packing_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.packing_end_date == null && form.packing_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.packing_end_date == null && form.packing_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_packing}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        <span v-if="form.packing_progress !== null ">
                                        {{form.packing_progress}}
                                        </span>
                                        <span v-if="form.packing_progress == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_packing - form.packing_progress}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.packing_progress !== null ">
                                        {{(((form.total_qty_packing - form.packing_progress) / form.total_qty_packing) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.packing_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.packing_ket !== null ">
                                        {{form.packing_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr id="process4-table" class="hidden table-add">
                                    <td :class='checkDateEndActual(form.printing_end_date_plan,form.printing_end_date)'>
                                        Printing
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.printing_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.printing_date_plan !== null" v-html="FormatDate(form.printing_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.printing_date !== null" v-html="FormatDate(form.printing_date)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.printing_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.printing_date !== null">
                                            {{printing_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.printing_end_date_plan !== null" v-html="FormatDate(form.printing_end_date_plan)">
                                        </span>

                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.printing_end_date !== null" v-html="FormatDate(form.printing_end_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.printing_end_date !== null && form.printing_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.printing_end_date == null && form.printing_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.printing_end_date == null && form.printing_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_printing}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                         <span v-if="form.printing_progress !== null ">
                                        {{form.printing_progress}}
                                         </span>

                                        <span v-if="form.printing_progress == null ">
                                         0
                                        </span>

                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_printing - form.printing_progress}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.printing_progress !== null ">
                                        {{(((form.total_qty_printing - form.printing_progress) / form.total_qty_printing) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.printing_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.printing_ket !== null ">
                                        {{form.printing_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr id="process5-table" class="hidden table-add">
                                    <td :class='checkDateEndActual(form.embroidery_end_date_plan,form.embroidery_end_date)'>
                                        Embroidery
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.embroidery_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.embroidery_date_plan !== null" v-html="FormatDate(form.embroidery_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.embroidery_date !== null" v-html="FormatDate(form.embroidery_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.embroidery_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.embroidery_date !== null">
                                            {{embroidery_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.embroidery_end_date_plan !== null" v-html="FormatDate(form.embroidery_end_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.embroidery_end_date !== null" v-html="FormatDate(form.embroidery_end_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.embroidery_end_date !== null && form.embroidery_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.embroidery_end_date == null && form.embroidery_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.embroidery_end_date == null && form.embroidery_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_embroidery}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        <span v-if="form.embroidery_progress !== null ">
                                        {{form.embroidery_progress}}
                                        </span>

                                        <span v-if="form.embroidery_progress == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_embroidery - form.embroidery_progress}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.embroidery_progress !== null ">
                                        {{(((form.total_qty_embroidery - form.embroidery_progress ) / form.total_qty_embroidery) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.embroidery_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.embroidery_ket !== null ">
                                        {{form.embroidery_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr id="process6-table" class="hidden table-add">
                                    <td :class='checkDateEndActual(form.emblem_end_date_plan,form.emblem_end_date)'>
                                        Emblem
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.emblem_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.emblem_date_plan !== null" v-html="FormatDate(form.emblem_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.emblem_date !== null" v-html="FormatDate(form.emblem_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.emblem_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.emblem_date !== null">
                                            {{emblem_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.emblem_end_date_plan !== null" v-html="FormatDate(form.emblem_end_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.emblem_end_date !== null" v-html="FormatDate(form.emblem_end_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.emblem_end_date !== null && form.emblem_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.emblem_end_date == null && form.emblem_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.emblem_end_date == null && form.emblem_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_emblem}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        <span v-if="form.emblem_progress !== null ">
                                        {{form.emblem_progress}}
                                        </span>

                                        <span v-if="form.emblem_progress == null ">
                                        0
                                        </span>

                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_emblem - form.emblem_progress}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.emblem_progress !== null ">
                                        {{(((form.total_qty_emblem - form.emblem_progress) / form.total_qty_emblem) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.emblem_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.emblem_ket !== null ">
                                        {{form.emblem_ket}}
                                        </span>
                                    </td>
                                </tr>

                                <tr id="process7-table" class="hidden table-add">
                                    <td :class='checkDateEndActual(form.washing_end_date_plan,form.washing_end_date)'>
                                        Washing
                                    </td>
                                    <td>
                                        <!-- Subcon -->
                                        {{form.washing_subcon}}
                                    </td>
                                    <td>
                                        <!-- start plan -->
                                        <span v-if="form.washing_date_plan !== null" v-html="FormatDate(form.washing_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- start actual -->
                                        <span v-if="form.washing_date !== null" v-html="FormatDate(form.washing_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- Plan(days) -->
                                        {{CalcTotalPlan(form.total_qty , form.washing_target)}}
                                    </td>
                                    <td>
                                        <!-- ACTUAL (DAYS) -->
                                        <span v-if="form.washing_date !== null">
                                            {{washing_actual}}
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE PLAN  -->
                                        <span v-if="form.washing_end_date_plan !== null" v-html="FormatDate(form.washing_end_date_plan)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- END DATE ACTUAL  -->
                                        <span v-if="form.washing_end_date !== null" v-html="FormatDate(form.washing_end_date)">
                                        </span>
                                    </td>
                                    <td>
                                        <!-- STATUS -->
                                        <span v-if="form.washing_end_date !== null && form.washing_date !== null">
                                            Done
                                        </span>
                                        <span v-if="form.washing_end_date == null && form.washing_date !== null">
                                            On Progress
                                        </span>
                                        <span v-if="form.washing_end_date == null && form.washing_date == null">
                                            Open
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        {{form.total_qty_washing}}
                                    </td>
                                    <td>
                                        <!-- QTY PLAN	 -->
                                        <span v-if="form.washing_progress !== null ">
                                        {{form.washing_progress}}
                                        </span>
                                        <span v-if="form.washing_progress == null ">
                                        0
                                        </span>
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE	 -->
                                        {{form.total_qty_washing - form.washing_progress}}
                                    </td>
                                    <td>
                                        <!-- QTY DIFFERENCE (%)	 -->
                                        <span v-if="form.washing_progress !== null ">
                                        {{(((form.total_qty_washing - form.washing_progress) / form.total_qty_washing) *100).toFixed(1)}}%
                                        </span>
                                        <span v-if="form.washing_progress == null ">
                                        0.0%
                                        </span>
                                    </td>
                                    <td>
                                        <!-- REASON -->
                                        <span v-if="form.washing_ket !== null ">
                                        {{form.washing_ket}}
                                        </span>
                                    </td>
                                </tr>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import JsonExcel from 'vue-json-excel'
    // import vue loading
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);
    import axios from 'axios'

    export default {
       name: 'PPMMonitoringDetail',

        data() {
            const now = new Date()

            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                formName: 'Monitoring PPM',
                errorMessage: null,
                max_date:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                today:today,
                oneDay:oneDay,
                calc_date:null,
                    form : {
                    data_io:null,
                    Employee:null,
                    status:null,
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    process_order:[],
                    manufacture_date:null,
                    description:null,
                    style:null,
                    texture:null,
                    material:null,
                    category:null,
                    sleeve:null,
                    qty_order:null,
                    total_qty:null,
                    total_qty_cutting:null,
                    total_qty_sewing:null,
                    total_qty_finishing:null,
                    total_qty_packing:null,
                    total_qty_printing:null,
                    total_qty_embroidery:null,
                    total_qty_emblem:null,
                    total_qty_washing:null,
                    due_date:null,
                    package_size:null,
                    basic:null,
                    addtional:null,
                    marker:today,
                    acc:today,
                    fabric:today,
                    fabric_inspection:today,
                    ppm:today,
                    myfile:null,
                    cutting_id :null,
                    cutting_subcon :null,
                    cutting_target :null,
                    cutting_target_dummy :null,
                    cutting_date:null,
                    cutting_date_plan:null,
                    cutting_end_date:null,
                    cutting_end_date_plan:null,
                    cutting_end_days:null,
                    embroidery_id:null,
                    embroidery_subcon :null,
                    embroidery_target :null,
                    embroidery_target_dummy :null,
                    embroidery_date :null,
                    embroidery_date_plan:null,
                    embroidery_end_date:null,
                    embroidery_end_date_plan:null,
                    embroidery_end_days:null,
                    printing_id :null,
                    printing_subcon :null,
                    printing_target :null,
                    printing_target_dummy :null,
                    printing_date :null,
                    printing_date_plan:null,
                    printing_end_date:null,
                    printing_end_date_plan:null,
                    printing_end_days:null,
                    emblem_id :null,
                    emblem_subcon :null,
                    emblem_target :null,
                    emblem_target_dummy :null,
                    emblem_date :null,
                    emblem_date_plan:null,
                    emblem_end_date:null,
                    emblem_end_date_plan:null,
                    emblem_end_days:null,
                    sewing_id :null,
                    sewing_subcon :null,
                    sewing_target :null,
                    sewing_target_dummy :null,
                    sewing_date:null,
                    sewing_date_plan:null,
                    sewing_end_date:null,
                    sewing_end_date_plan:null,
                    sewing_end_days:null,
                    washing_id :null,
                    washing_subcon :null,
                    washing_target :null,
                    washing_target_dummy :null,
                    washing_date :null,
                    washing_date_plan:null,
                    washing_end_date:null,
                    washing_end_date_plan:null,
                    washing_end_days:null,
                    finishing_id :null,
                    finishing_subcon :null,
                    finishing_target :null,
                    finishing_target_dummy :null,
                    finishing_date :null,
                    finishing_date_plan:null,
                    finishing_end_date:null,
                    finishing_end_date_plan:null,
                    finishing_end_days:null,
                    packing_id :null,
                    packing_subcon :null,
                    packing_target :null,
                    packing_target_dummy :null,
                    packing_date :null,
                    packing_date_plan:null,
                    packing_end_date:null,
                    packing_end_date_plan:null,
                    packing_end_days:null,
                    cutting_progress:null,
                    sewing_progress:null,
                    finishing_progress:null,
                    packing_progress:null,
                    printing_progress:null,
                    embroidery_progress:null,
                    emblem_progress:null,
                    washing_progress:null,
                    cutting_ket:null,
                    sewing_ket:null,
                    finishing_ket:null,
                    packing_ket:null,
                    printing_ket:null,
                    embroidery_ket:null,
                    emblem_ket:null,
                    washing_ket:null,
                    qc_final_ket:null,
                    receive_ket:null,
                    status_receive:null,
                    qc_inline:null,
                    end_plan_qc_inline:null,
                    plan_qc_inline:null,
                    qc_final:null,
                    start_qc_final:null,
                    plan_qc_final:null,
                    end_plan_qc_final:null,
                    qty_shortship:null,
                    plan_delivery:null,
                    end_plan_delivery:null,
                    qty_delivery:null,
                    date_delivery:null,
                    end_date_delivery:null,
                    plan_days_delivery:null,

                    },
                cutting_actual:null,
                sewing_actual:null,
                finishing_actual:null,
                packing_actual:null,
                printing_actual:null,
                embroidery_actual:null,
                emblem_actual:null,
                washing_actual:null,
                qc_final_actual:null,
                delivery_actual:null,
                selectedDept:null,
                document_io:[],
                form_api:[],
                read_id: null,
                cat_id_cut:null,
                dis_id_cut:null,
                cat_id_sew:null,
                dis_id_sew:null,
                cat_id_embr:null,
                dis_id_embr:null,
                cat_id_pri:null,
                dis_id_pri:null,
                cat_id_embl:null,
                dis_id_embl:null,
                cat_id_wash:null,
                dis_id_wash:null,
                cat_id_fin:null,
                dis_id_fin:null,
                cat_id_pack:null,
                dis_id_pack:null,
                isActive:true,
                readmore:null,
                process_order:[],
                shift_cutting:null,
                shift_sewing:null,
                shift_finishing:null,
                shift_packing:null,
                shift_printing:null,
                shift_embroidery:null,
                shift_emblem:null,
                shift_washing:null,


            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stIoPPM','stIoPPMCount','stMstSub','stIoSubconDaily']),
            ...mapState('master_calender', ['stMstCldrCutting','stMstCldrSewing','stMstCldrFinishing','stMstCldrPacking','stMstCldrPrinting','stMstCldrEmbroidery','stMstCldrEmblem','stMstCldrWashing']),


        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getInputIOPPM','getSubconDailyData','getDataFormMasterSubcon']),
            ...mapActions('master_calender', ['getSubconCalender','GetDateCalculation','clearState']),
            FormatDate(value) {
               if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Setdata_IO() {
                let data_IO      = this.$route.query.io
                let userData     = this.userData
                this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardMonitoring'})
                }
                if (this.form.data_io != null) {
                    this.getSubconDailyData(this.form.data_io);
                    this.getInputIOPPM(this.form.data_io).then(this.GetdataForm()).catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardMonitoring"})
                        }
                    })
                }
            },
            checkDateEndActual(plan, actual){
                if (plan != null) {
                    if (actual == null){
                        actual = this.today
                    }
                    if (plan <= actual) {
                        return 'red-bcolor';
                    } else {
                        return 'green-bcolor';
                    }
                }
            },
            CalcTotalPlan(totalQty,target) {
                let calc = (((totalQty/target) - Math.floor(totalQty/target))*10)
                if (calc >= 3) {
                    calc = Math.ceil(totalQty/target)
                } else {
                    calc = Math.floor(totalQty/target)
                    if (calc == 0) {
                    calc = 1
                    }
                }
                return calc
            },
            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stIoPPMCount == 0 || this.stIoPPMCount == null) {
                    this.DelayGetData()
                    return
                }
                let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                color: '#f89830',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
                });
                setTimeout(() => {
                // Set Data v-model
                let formdata = this.stIoPPM
                this.form.status = formdata[0].status
                this.form.artikel = formdata[0].artikel
                this.form.sample = formdata[0].sample
                this.form.brand = formdata[0].brand
                this.form.subbrand = formdata[0].sub_brand
                this.form.manufacture_date = formdata[0].manufacture_date
                this.form.description = formdata[0].description
                this.form.style = formdata[0].style
                this.form.texture = formdata[0].texture
                this.form.material = formdata[0].material
                this.form.category = formdata[0].category
                this.form.sleeve = formdata[0].sleeve
                this.document_io = formdata[0].document_io
                this.form.qty_order = formdata[0].qty_order
                this.form.cutting_date = formdata[0].cutting_date
                this.form.total_qty = formdata[0].total_qty
                this.form.due_date = formdata[0].due_date
                this.form.package_size = formdata[0].package_size
                this.form.addtional = formdata[0].addtional
                if (formdata[0].due_date !== null) {
                    this.form.due_date = this.FormatDate(formdata[0].due_date)
                }
                if (formdata[0].marker !== null) {
                    this.form.marker = this.FormatDate(formdata[0].marker)
                }
                if (formdata[0].acc !== null) {
                    this.form.acc = this.FormatDate(formdata[0].acc)
                }
                if (formdata[0].fabric !== null) {
                    this.form.fabric = this.FormatDate(formdata[0].fabric)
                }
                if (formdata[0].fabric_inspection !== null) {
                    this.form.fabric_inspection = this.FormatDate(formdata[0].fabric_inspection)
                }
                if (formdata[0].ppm !== null) {
                    this.form.ppm = this.FormatDate(formdata[0].ppm)
                }
                this.form.cutting_id = formdata[0].cutting_id
                this.form.cutting_subcon = formdata[0].cutting_subcon
                this.form.cutting_target = formdata[0].cutting_target
                this.form.cutting_target_dummy = formdata[0].cutting_target
                this.form.cutting_progress = formdata[0].cutting_progress
                this.form.cutting_end_date = formdata[0].cutting_end_date
                this.form.cutting_date_plan = formdata[0].cutting_date_plan
                this.form.cutting_end_date_plan = formdata[0].cutting_end_date_plan
                this.form.cutting_end_days = formdata[0].cutting_end_days

                this.form.embroidery_id = formdata[0].embroidery_id
                this.form.embroidery_subcon = formdata[0].embroidery_subcon
                this.form.embroidery_target = formdata[0].embroidery_target
                this.form.embroidery_target_dummy = formdata[0].embroidery_target
                this.form.embroidery_date = formdata[0].embroidery_date
                this.form.embroidery_end_date = formdata[0].embroidery_end_date
                this.form.embroidery_date_plan = formdata[0].embroidery_date_plan
                this.form.embroidery_end_date_plan = formdata[0].embroidery_end_date_plan
                this.form.embroidery_progress = formdata[0].embroidery_progress
                this.form.embroidery_end_days = formdata[0].embro_end_days

                this.form.printing_id = formdata[0].printing_id
                this.form.printing_subcon = formdata[0].printing_subcon
                this.form.printing_target = formdata[0].printing_target
                this.form.printing_target_dummy = formdata[0].printing_target
                this.form.printing_date = formdata[0].printing_date
                this.form.printing_end_date = formdata[0].printing_end_date
                this.form.printing_progress = formdata[0].printing_progress
                this.form.printing_date_plan = formdata[0].printing_date_plan
                this.form.printing_end_date_plan = formdata[0].printing_end_date_plan
                this.form.printing_end_days = formdata[0].print_end_days

                this.form.emblem_id = formdata[0].emblem_id
                this.form.emblem_subcon = formdata[0].emblem_subcon
                this.form.emblem_target = formdata[0].emblem_target
                this.form.emblem_target_dummy = formdata[0].emblem_target
                this.form.emblem_date = formdata[0].emblem_date
                this.form.emblem_end_date = formdata[0].emblem_end_date
                this.form.emblem_progress = formdata[0].emblem_progress
                this.form.emblem_date_plan = formdata[0].emblem_date_plan
                this.form.emblem_end_date_plan = formdata[0].emblem_end_date_plan
                this.form.emblem_end_days = formdata[0].emblem_end_days

                this.form.sewing_id = formdata[0].sewing_id
                this.form.sewing_subcon = formdata[0].sewing_subcon
                this.form.sewing_target = formdata[0].sewing_target
                this.form.sewing_target_dummy = formdata[0].sewing_target
                this.form.sewing_date = formdata[0].sewing_date
                this.form.sewing_end_date = formdata[0].sewing_end_date
                this.form.sewing_progress = formdata[0].sewing_progress
                this.form.sewing_date_plan = formdata[0].sewing_date_plan
                this.form.sewing_end_date_plan = formdata[0].sewing_end_date_plan
                this.form.sewing_end_days = formdata[0].sewing_end_days

                this.form.washing_id = formdata[0].washing_id
                this.form.washing_subcon = formdata[0].washing_subcon
                this.form.washing_target = formdata[0].washing_target
                this.form.washing_target_dummy = formdata[0].washing_target
                this.form.washing_date = formdata[0].washing_date
                this.form.washing_end_date = formdata[0].washing_end_date
                this.form.washing_progress = formdata[0].washing_progress
                this.form.washing_date_plan = formdata[0].washing_date_plan
                this.form.washing_end_date_plan = formdata[0].washing_end_date_plan
                this.form.washing_end_days = formdata[0].washing_end_days

                this.form.packing_id = formdata[0].packing_id
                this.form.packing_subcon = formdata[0].packing_subcon
                this.form.packing_target = formdata[0].packing_target
                this.form.packing_target_dummy = formdata[0].packing_target
                this.form.packing_date = formdata[0].packing_date
                this.form.packing_end_date = formdata[0].packing_end_date
                this.form.packing_progress = formdata[0].packing_progress
                this.form.packing_date_plan = formdata[0].packing_date_plan
                this.form.packing_end_date_plan = formdata[0].packing_end_date_plan
                this.form.packing_end_days = formdata[0].packing_end_days

                this.form.finishing_id = formdata[0].finishing_id
                this.form.finishing_subcon = formdata[0].finishing_subcon
                this.form.finishing_target = formdata[0].finishing_target
                this.form.finishing_target_dummy = formdata[0].finishing_target
                this.form.finishing_date = formdata[0].finishing_date
                this.form.finishing_end_date = formdata[0].finishing_end_date
                this.form.finishing_progress = formdata[0].finishing_progress
                this.form.finishing_date_plan = formdata[0].finishing_date_plan
                this.form.finishing_end_date_plan = formdata[0].finishing_end_date_plan
                this.form.finishing_end_days = formdata[0].finishing_end_days


                this.form.qc_inline = formdata[0].qc_inline;
                this.form.plan_qc_inline = formdata[0].plan_qc_inline;
                this.form.end_plan_qc_inline = formdata[0].end_plan_qc_inline;
                this.form.qc_final =  formdata[0].qc_final;
                this.form.start_qc_final =  formdata[0].start_qc_final;
                if (formdata[0].start_qc_final == null) {
                    this.form.start_qc_final = formdata[0].qc_final;
                }
                this.form.plan_qc_final =  formdata[0].plan_qc_final;
                this.form.end_plan_qc_final =  formdata[0].end_plan_qc_final;
                this.form.qc_final_ket =  formdata[0].qc_final_ket;
                this.form.qty_shortship =  formdata[0].qty_shortship;
                this.form.plan_delivery =  formdata[0].plan_delivery;
                this.form.end_plan_delivery =  formdata[0].end_plan_delivery;
                this.form.date_delivery =  formdata[0].date_delivery;
                this.form.end_date_delivery =  formdata[0].end_date_delivery;
                this.form.status_receive =  formdata[0].status_receive;
                this.form.receive_ket =  formdata[0].receive_ket;
                if (formdata[0].end_date_delivery == null ) {
                    this.form.end_date_delivery = formdata[0].date_delivery;
                }
                // console.log('end rece:'+this.form.end_date_delivery)
                this.form.qty_delivery =  formdata[0].receive;
                if (this.form.packing_id == null || this.form.packing_id == undefined || this.form.packing_id == '') {
                    this.form.plan_days_delivery = null
                } else {
                    for(let b = 0; b < this.stMstSub.length; b++) {
                    if(this.stMstSub[b].id == this.form.packing_id) {
                        this.form.plan_days_delivery = this.stMstSub[b].delivery
                    }
                }

                }
                // console.log('form.qty_shortship: '+this.form.qty_shortship)
                this.process_order = formdata[0].order_process;
                this.form.cutting_date = formdata[0].cutting_date;

                let formDataSubcon = this.stIoSubconDaily
                if (this.stIoSubconDaily !== undefined) {
                    let sub = 0;

                // CHECK KETERANGAN DI SETIAP PROCESS
                    for (sub=0;sub<this.stIoSubconDaily.length;sub++) {
                        // KETERANGAN CUTTING
                        if (this.form.cutting_id !== null) {
                            if (formDataSubcon[sub].order_process == 'a') {
                                if (formDataSubcon[sub].status !== null) {
                                    this.form.cutting_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                        // KETERANGAN SEWING
                        if (this.form.sewing_id !== null) {
                            if (formDataSubcon[sub].order_process == 'b') {
                                if (formDataSubcon[sub].ket !== null) {
                                    this.form.sewing_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                        // KETERANGAN FINISHING
                        if (this.form.finishing_id !== null) {
                            if (formDataSubcon[sub].order_process == 'c') {
                                if (formDataSubcon[sub].ket !== null) {
                                    this.form.finishing_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                        // KETERANGAN PACKING
                        if (this.form.packing_id !== null) {
                            if (formDataSubcon[sub].order_process == 'd') {
                                if (formDataSubcon[sub].ket !== null) {
                                    this.form.packing_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                        // KETERANGAN PRINTING
                        if (this.form.printing_id !== null) {
                            if (formDataSubcon[sub].order_process == 'e') {
                                if (formDataSubcon[sub].ket !== null) {
                                    this.form.printing_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                        // KETERANGAN EMBROIDERY
                        if (this.form.embroidery_id !== null) {
                            if (formDataSubcon[sub].order_process == 'f') {
                                if (formDataSubcon[sub].ket !== null) {
                                    this.form.embroidery_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                        // KETERANGAN EMBLEM
                        if (this.form.emblem_id !== null) {
                            if (formDataSubcon[sub].order_process == 'g') {
                                if (formDataSubcon[sub].ket !== null) {
                                    this.form.emblem_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                        // KETERANGAN WASHING
                        if (this.form.washing_id !== null) {
                            if (formDataSubcon[sub].order_process == 'h') {
                                if (formDataSubcon[sub].ket !== null) {
                                    this.form.washing_ket = formDataSubcon[sub].ket
                                }
                            }
                        }
                    }
                }
                // this.form.cutting_date_plan = this.form.cutting_date_plan.toISOString().slice(0,10)
                this.form.total_qty_cutting = formdata[0].total_qty;
                //console.log(this.process_order);

                setTimeout(() => {
                    this.dataOrderProcess();
                    loader.hide()
                },2000);
                },1000)
            },
            CalcActualDays(end, start, shift, param1){
                console.log('param1:  '+param1)
                console.log('shift:  '+shift)
                console.log('start:  '+start)
                console.log('end:  '+end)
            this.GetDateCalculation({
                is_iterate: true,
                shift_id:shift,
                params:[{
                    calc_type: "duration",
                    start_date: start,
                    end_date: end,
            }]
            })
            .then(rsp => {
                if (param1 == 'cutting') {
                    this.cutting_actual = rsp[0].duration
                }
                if (param1 == 'sewing') {
                    this.sewing_actual = rsp[0].duration
                }
                if (param1 == 'finishing') {
                    this.finishing_actual = rsp[0].duration
                }
                if (param1 == 'packing') {
                    this.packing_actual = rsp[0].duration
                }
                if (param1 == 'printing') {
                    this.printing_actual = rsp[0].duration
                }
                if (param1 == 'embroidery') {
                    this.embroidery_actual = rsp[0].duration
                }
                if (param1 == 'emblem') {
                    this.emblem_actual = rsp[0].duration
                }
                if (param1 == 'washing') {
                    this.washing_actual = rsp[0].duration
                }
                if (param1 == 'qc_final') {
                    this.qc_final_actual = rsp[0].duration
                }
                if (param1 == 'delivery') {
                    this.delivery_actual = rsp[0].duration
                }
            })
            .catch(err => {
                if(err.response.status !== 401) {
                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            })
            },
            dataOrderProcess() {
                let b = 1;
                let a = 0;
                let r = 0;
                let e = 0;
                let d = 0;
                let f = 0;
                let rr = 0

                let qtytotal = [];
                if (this.process_order == '' || this.process_order == null) {
                    //console.log('process: '+this.process_order)
                } else {
                for (a=0;a<=7;a++) {
                if (this.process_order.slice(r, b) == 'a') {
                    this.form.process_order[a] = 'a';
                        this.AddProcess('0');

                        if (this.form.cutting_progress !== null) {
                            qtytotal[f] = this.form.cutting_progress;
                        } else {
                            qtytotal[f] = this.form.total_qty_cutting;
                        }
                        // DATE ACTUAL Cutting
                    if (this.form.cutting_end_date == null || this.form.cutting_end_days == null) {
                        if (this.form.cutting_date != null) {
                            let end = this.form.cutting_end_date
                            if (end == null) {
                                end = this.today
                            }
                            this.getSubconCalender({name:this.form.cutting_subcon})
                            .then(rsp => {
                                this.shift_cutting = rsp[0].shift_id
                                if (this.shift_cutting == undefined) {
                                    this.shift_cutting = 1
                                }
                                this.CalcActualDays(end , this.form.cutting_date, this.shift_cutting, 'cutting')
                            })
                            .catch(err => {
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardMonitoring"})
                                }
                            })
                        }
                    } else {
                        console.log('database cut actual')
                        this.cutting_actual = this.form.cutting_end_days
                    }
                }
                if (this.process_order.slice(r, b) == 'b') {
                    this.form.process_order[a] = 'b';
                        this.AddProcess('1');


                        if (this.form.sewing_progress !== null) {
                            // if (this.form.sewing_end_date !== null) {
                                qtytotal[f] = this.form.sewing_progress;
                            // }
                            this.form.total_qty_sewing = qtytotal[f-1];
                        } else {
                            this.form.total_qty_sewing = qtytotal[f-1];
                            qtytotal[f] = this.form.total_qty_sewing
                        }

                        // DATE ACTUAL Sewing
                    if (this.form.sewing_end_date == null || this.form.sewing_end_days == null) {
                        if (this.form.sewing_date != null) {
                            let end = this.form.sewing_end_date
                            if (end == null) {
                                end = this.today
                            }
                            this.getSubconCalender({name:this.form.sewing_subcon})
                            .then(rsp => {
                                this.shift_sewing = rsp[0].shift_id
                                if (this.shift_sewing == undefined) {
                                    this.shift_sewing = 1
                                }
                                this.CalcActualDays(end , this.form.sewing_date, this.shift_sewing, 'sewing')
                            })
                            .catch(err => {
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardMonitoring"})
                                }
                            })
                        }
                    } else {
                        console.log('database sew actual')
                        this.sewing_actual = this.form.sewing_end_days
                    }
                }
                if (this.process_order.slice(r, b) == 'c') {
                    this.form.process_order[a] = 'c';
                        this.AddProcess('2');


                        if (this.form.finishing_progress !== null) {
                            // if (this.form.finishing_end_date !== null) {
                                qtytotal[f] = this.form.finishing_progress;
                            // }
                            this.form.total_qty_finishing = qtytotal[f-1]
                        } else {
                            this.form.total_qty_finishing = qtytotal[f-1]
                            qtytotal[f] = this.form.total_qty_finishing
                        }

                    // DATE ACTUAL Finishing
                if (this.form.finishing_end_date == null || this.form.finishing_end_days == null) {
                    if (this.form.finishing_date != null) {
                            let end = this.form.finishing_end_date
                            if (end == null) {
                                end = this.today
                            }
                            this.getSubconCalender({name:this.form.finishing_subcon})
                            .then(rsp => {
                                this.shift_finishing = rsp[0].shift_id
                                if (this.shift_finishing == undefined) {
                                    this.shift_finishing = 1
                                }
                                this.CalcActualDays(end , this.form.finishing_date, this.shift_finishing, 'finishing')
                            })
                            .catch(err => {
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardMonitoring"})
                                }
                            })
                        }

                }
                } else {
                    console.log('database fin actual')
                    this.finishing_actual = this.form.finishing_end_days
                }
                if (this.process_order.slice(r, b) == 'd') {
                    this.form.process_order[a] = 'd';
                        this.AddProcess('3');


                        if (this.form.packing_progress !== null) {
                            // if (this.form.packing_end_date !== null) {
                                qtytotal[f] = this.form.packing_progress;
                            // }
                            this.form.total_qty_packing = qtytotal[f-1]
                        } else {
                            this.form.total_qty_packing = qtytotal[f-1]
                            qtytotal[f] = this.form.total_qty_packing
                        }

                        // DATE ACTUAL Packing
                    if (this.form.packing_date != null) {
                            let end = this.form.packing_end_date
                            if (end == null) {
                                end = this.today
                            }
                            this.getSubconCalender({name:this.form.packing_subcon})
                            .then(rsp => {
                                this.shift_packing = rsp[0].shift_id
                                if (this.shift_packing == undefined) {
                                    this.shift_packing = 1
                                }
                                    if (this.form.packing_end_days == null) {
                                        this.CalcActualDays(end, this.form.packing_date, this.shift_packing, 'packing')
                                    } else {
                                        this.packing_actual = this.form.packing_end_days
                                        console.log('database pack actual')
                                    }
                                    // DATE ACTUAL Qc_final
                                if (this.form.packing_end_date !== null ) {
                                    if (this.form.start_qc_final != null) {
                                        let start = this.form.start_qc_final
                                        let end = this.form.qc_final
                                        if (end == null) {
                                            end = this.today
                                        }
                                        this.CalcActualDays(end , start, this.shift_packing, 'qc_final')
                                    }
                                    // DATE ACTUAL Delivery
                                    if (this.form.date_delivery != null) {
                                        let start = this.form.date_delivery
                                        let end = this.form.end_date_delivery
                                        if (this.form.end_date_delivery == null || this.form.status_receive !== 'Done') {
                                            end = this.today
                                        }
                                        this.CalcActualDays(end , start, this.shift_packing, 'delivery')
                                    }
                                }
                            })
                            .catch(err => {
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardMonitoring"})
                                }
                            })
                    }
                }
                if (this.process_order.slice(r, b) == 'e') {
                    this.form.process_order[a] = 'e';
                        this.AddProcess('4');

                        if (this.form.printing_progress !== null) {
                            // if (this.form.printing_end_date !== null) {
                                qtytotal[f] = this.form.printing_progress;
                            // }
                            this.form.total_qty_printing = qtytotal[f-1]
                        } else {
                            this.form.total_qty_printing = qtytotal[f-1]
                            qtytotal[f] = this.form.total_qty_printing
                        }

                    // DATE ACTUAL Printing
                if (this.form.printing_end_date == null || this.form.printing_end_days == null) {
                    if (this.form.printing_date != null) {
                        let end = this.form.printing_end_date
                        if (end == null) {
                            end = this.today
                        }
                        this.getSubconCalender({name:this.form.printing_subcon})
                        .then(rsp => {
                            this.shift_printing = rsp[0].shift_id
                            if (this.shift_printing == undefined) {
                                this.shift_printing = 1
                                }
                            this.CalcActualDays(end , this.form.printing_date, this.shift_printing, 'printing')
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardMonitoring"})
                            }
                        })
                    }

                } else {
                    console.log('database print actual')
                    this.printing_actual = this.form.printing_end_days
                }
                }
                if (this.process_order.slice(r, b) == 'f') {
                    this.form.process_order[a] = 'f';
                        this.AddProcess('5');

                        if (this.form.embroidery_progress !== null) {
                            // if (this.form.embroidery_end_date !== null) {
                                qtytotal[f] = this.form.embroidery_progress;
                            // }
                            this.form.total_qty_embroidery = qtytotal[f-1]
                        } else {
                            this.form.total_qty_embroidery = qtytotal[f-1]
                            qtytotal[f] = this.form.total_qty_embroidery
                        }

                        // DATE ACTUAL Embroidery
                if (this.form.embroidery_end_date == null || this.form.embroidery_end_days == null) {
                    if (this.form.embroidery_date != null) {
                        let end = this.form.embroidery_end_date
                        if (end == null) {
                            end = this.today
                        }
                        this.getSubconCalender({name:this.form.embroidery_subcon})
                        .then(rsp => {
                            this.shift_embroidery = rsp[0].shift_id
                            if (this.shift_embroidery == undefined) {
                                this.shift_embroidery = 1
                                }
                            this.CalcActualDays(end , this.form.embroidery_date, this.shift_embroidery, 'embroidery')
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardMonitoring"})
                            }
                        })
                    }
                } else {
                    console.log('database embroidery actual')
                    this.embroidery_actual = this.form.embroidery_end_days
                }
                }
                if (this.process_order.slice(r, b) == 'g') {
                    this.form.process_order[a] = 'g';
                        this.AddProcess('6');

                        if (this.form.emblem_progress !== null) {
                            // if (this.form.emblem_end_date !== null) {
                                qtytotal[f] = this.form.emblem_progress;
                            // }
                            this.form.total_qty_emblem = qtytotal[f-1]
                        } else {
                            this.form.total_qty_emblem = qtytotal[f-1]
                            qtytotal[f] = this.form.total_qty_emblem
                        }

                        // DATE ACTUAL Emblem
                if (this.form.emblem_end_date == null || this.form.emblem_end_days == null) {
                    if (this.form.emblem_date != null) {
                        let end = this.form.emblem_end_date
                        if (end == null) {
                            end = this.today
                        }
                        this.getSubconCalender({name:this.form.emblem_subcon})
                        .then(rsp => {
                            this.shift_emblem = rsp[0].shift_id
                            if (this.shift_emblem == undefined) {
                                this.shift_emblem = 1
                                }
                            this.CalcActualDays(end , this.form.emblem_date, this.shift_emblem, 'emblem')
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardMonitoring"})
                            }
                        })
                    }

                } else {
                    console.log('database emblem actual')
                    this.emblem_actual = this.form.emblem_end_days
                }
                }
                if (this.process_order.slice(r, b) == 'h') {
                    this.form.process_order[a] = 'h';
                        this.AddProcess('7');

                        if (this.form.washing_progress !== null) {
                            // if (this.form.washing_end_date !== null) {
                                qtytotal[f] = this.form.washing_progress;
                            // }
                            this.form.total_qty_washing = qtytotal[f-1]
                        } else {
                            this.form.total_qty_washing = qtytotal[f-1]
                            qtytotal[f] = this.form.total_qty_washing
                        }

                        // DATE ACTUAL Washing
                    if (this.form.washing_end_date == null || this.form.washing_end_days == null) {
                        if (this.form.washing_date != null) {
                            let end = this.form.washing_end_date
                            if (end == null) {
                                end = this.today
                            }
                            this.getSubconCalender({name:this.form.washing_subcon})
                            .then(rsp => {
                                this.shift_washing = rsp[0].shift_id
                                if (this.shift_washing == undefined) {
                                    this.shift_washing = 1
                                }
                                this.CalcActualDays(end , this.form.washing_date, this.shift_washing, 'washing')
                            })
                            .catch(err => {
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardMonitoring"})
                                }
                            })
                        }
                    } else {
                        console.log('database washing actual')
                        this.washing_actual = this.form.washing_end_days
                    }


                }
                b += 2;
                r += 2;
                f++
                //console.log('r'+r);
                //console.log('b'+b);
                //console.log(this.form.process_order);
                }
                }
            },
            AddProcess(value) {
                // let order_process_qc = document.getElementById("process-inline-table");
                let order_process_final = document.getElementById("process-final-table");
                let order_process_delivery = document.getElementById("process-delivery-table");
                let order_process = document.getElementById("process"+value+"-table");
                let tab_button = document.getElementById("tab-process-table");
                tab_button.appendChild(order_process);
                order_process.classList.remove("hidden");
                // if (value == 1) {
                //     tab_button.appendChild(order_process_qc);
                //     order_process_qc.classList.remove("hidden");
                // }
                if (value == 3) {
                    tab_button.appendChild(order_process_final);
                    order_process_final.classList.remove("hidden");
                }
                if (value == 3) {
                    setTimeout(() => {
                    tab_button.appendChild(order_process_delivery);
                    order_process_delivery.classList.remove("hidden");
                    },1000)
                }
            },

            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
        },
        created() {
            this.Setdata_IO()
            this.getDataFormMasterSubcon()
        },

    }
</script>
<style src="./PPCInputMonitoring.scss" lang="scss" scoped />
