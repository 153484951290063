<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item class="fw-semi-bold">{{formName}}</b-breadcrumb-item>

        </b-breadcrumb>

        <b-container fluid>
            <b-row align-h="end" style="padding-bottom:10px;">
                <!-- <b-avatar variant="l" size="2.2rem" style="border:1px solid #CCC; cursor:pointer;">
                    <b-icon class="h5 mb-0" icon="arrow-clockwise" aria-hidden="true"></b-icon>
                </b-avatar> -->
            </b-row>
            <b-row align-h="start">
                <b-col sm="12">
                    <b-list-group v-if="dataList.length > 0">
                        <b-list-group-item
                        class="flex-column align-items-start"
                        style="cursor:pointer;"
                        v-for="row in dataList" :key="row.id"
                        @click="editData(row.id)"
                        >
                            <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                                <b>{{row.project_id}} - {{row.customer_name}}</b>
                            </h5>
                            </div>
                            <p class="mb-1">
                            Plan Date : {{FormatDate(row.plan_date)}}
                            </p>

                            <small>Plan Activity : {{row.activity_name}}</small>
                        </b-list-group-item>
                    </b-list-group>
                    <b-list-group v-if="dataList.length == 0 || dataList.length == undefined ">
                        <b-list-group-item
                        class="flex-column align-items-start"
                        >
                        <div class="d-flex w-100 justify-content-between">
                            <h5 class="mb-1">
                            </h5>
                        </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
            <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
        </b-container>
    </div>
</template>
<script>
import Loader from "@/components/Loader/Loader";
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    components: {
        Loader,
    },
    data(){
        return{
            isLoadingData:false,
            formName:'Actual Activity',
            monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            dataList:[],
            totalData:0,
            form:{
                role:null,
                start_limit:null,
                end_limit:null,
                user_nik:null,
                username:null,
            },
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['stActActv','stActActvCount']),
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['GetDashActualActv']),
        setData(){
            let roles = this.userData.roles
            if (roles.length > 0) {
                for (let data in roles) {
                if (roles[data].app == 'SFC') {
                    let lower = roles[data].role_list.map(string => {
                    return string.toLowerCase();
                    });
                    this.roles_list = lower
                }
                }
            }
            this.form.user_nik   = this.userData.nik
            this.form.username   = this.userData.fullname
            this.LoadData()
        },
        LoadData() {
            // start loading
            this.isLoadingData = true

            this.GetDashActualActv({
                sales:this.form.user_nik,
            })
            .then(rsp => {
                this.dataList = this.stActActv
                this.totalData = this.stActActvCount
                if(this.stActActvCount == 0) {
                    this.showNotification({type:'info', message:'Data Not Found'})
                }
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            }).finally(()=>{
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
            })
        },
        FormatDate(value) {
            if(value == null || value == undefined || value == '') {
                return ' '
            } else {
                let date = new Date(value)
                let nameDay = this.dayNames[date.getDay()];
                let day = date.getDate()
                if (String(day).length == 1) {
                day = "0"+String(day)
                }
                let year = date.getFullYear()
                let bulan = this.monthNames[date.getMonth()]
                date = nameDay+' '+day+' '+bulan+' '+year
                return date
            }
        },
        editData(data) {
            this.$router.push({ name: 'SFCActualActivityForm', params: { page:'edit', idData:data } })
        },
    },
    created(){
        this.setData()
    },
}
</script>
<style src="../SalesForce.scss" lang="scss" scoped />