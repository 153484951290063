<template>
    
    <div>
        <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item >
            <router-link tag="a" to="/app/appsplm-product-design2">Product Design</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>Edit</b-breadcrumb-item>
        </b-breadcrumb>
        <template>
            <b-card 
            header-tag="nav" 
            no-body
            footer-tag="footer"
            >
            <b-tabs 
                active-nav-item-class="font-weight-bold"
                active-tab-class="font-weight-bold"
                
                content-class="mt-3">
                <b-tab active title="Garment">
                    <template>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                            <b-form @submit.prevent="handleSubmit(onSubmit)">
                                <b-row >
                                    <b-col cols="auto" md="6">
                                        <!-- Duration -->
                                            <validation-provider
                                                name="Duration"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Duration <code>*</code>
                                                    </template>
                                                    <b-form-select value-field="value"
                                                        text-field="value"  id="inputDuration" v-model="form.duration" :options="durationOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputDuration">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Duration -->
                                        <!-- Brand -->
                                            <validation-provider
                                                name="brand"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Brand <code>*</code>
                                                    </template>
                                                    <b-form-input 
                                                        readonly  
                                                        value-field="value"
                                                        text-field="value"   
                                                        id="inputBrand" 
                                                        v-model="form.brand" 
                                                        
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-input>
                                                    <b-form-invalid-feedback id="inputBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Brand -->

                                        <!-- Collection Name -->
                                            <validation-provider
                                                name="CollectionName"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Collection Name<code>*</code>
                                                    </template>
                                                    <b-form-input id="inputCollectionName" v-model="form.collectionName" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="inputCollectionName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Collection Name -->

                                        <!-- Quartal -->
                                            <validation-provider
                                                name="Quartal"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Quartal<code>*</code>
                                                    </template>
                                                    <!-- <b-form-input id="inputQuartal" v-model="form.quartal"  :state="getValidationState(validationContext)"  trim></b-form-input> -->
                                                    <b-form-select   
                                                        id="inputQuartal" 
                                                        v-model="form.quartal" 
                                                        :options="quartalOpt"  
                                                        value-field="value"
                                                        text-field="text"
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputQuartal">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Quartal -->

                                        <!-- Year -->
                                            <validation-provider
                                                name="Year"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Year<code>*</code>
                                                    </template>
                                                    <b-form-select  
                                                        id="inputYear" 
                                                        v-model="form.year" 
                                                        :options="yearOpt"  
                                                        value-field="value"
                                                        text-field="text"
                                                        v-on:change="generateArticle()"
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputYear">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Year -->

                                        <!-- Prod. Month -->
                                            <validation-provider
                                                name="ProdMonth"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Prod. Month<code>*</code>
                                                    </template>
                                                    <b-form-select   
                                                        id="inputProdMonth" 
                                                        v-model="form.prodMonth" 
                                                        :options="monthOpt"  
                                                        value-field="value"
                                                        text-field="value"
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputProdMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Prod. Month -->

                                        <!-- Coll. Month -->
                                            <validation-provider
                                                name="CollMonth"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Coll. Month<code>*</code>
                                                    </template>
                                                    <b-form-select 
                                                        value-field="value"
                                                        v-on:change="generateArticle()"
                                                        text-field="text" id="inputCollMonth" v-model="form.collMonth" :options="monthOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputCollMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Coll. Month -->

                                        <!-- Sub Brand -->
                                            <validation-provider
                                                name="SubBrand"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Sub. Brand<code>*</code>
                                                    </template>
                                                    <b-form-select 
                                                        value-field="value"
                                                        text-field="text"   
                                                        id="inputSubBrand" 
                                                        v-model="form.subBrand" 
                                                        :options="setSubBrand" 
                                                        v-on:change="generateArticle()" 
                                                        :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputSubBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Sub Brand -->

                                        <!-- Category -->
                                            <validation-provider
                                                name="Category"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Category<code>*</code>
                                                    </template>
                                                    <b-form-select 
                                                        value-field="value"
                                                        text-field="text"   
                                                        id="inputCategory" 
                                                        v-model="form.category" 
                                                        :options="setCategory"  
                                                        v-on:change="generateArticle()"
                                                        :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputCategory">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Category -->
                                        <!-- Note -->
                                            <validation-provider
                                                name="Note"
                                                :rules="{ required: false }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Note<code>*</code>
                                                    </template>
                                                    <b-form-textarea 
                                                        
                                                        id="inputNote" 
                                                        v-model="form.note"
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-textarea>
                                                    <b-form-invalid-feedback id="inputNote">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Note -->
                                        <!-- Color -->
                                            <validation-provider
                                                name="Color"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Color<code>*</code>
                                                    </template>
                                                    <b-form-select 
                                                    value-field="value"
                                                        text-field="text"   
                                                        id="inputColor" 
                                                        v-model="form.color" 
                                                        :options="colorOpt"  
                                                        v-on:change="generateArticle()"
                                                        :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputColor">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Color -->
                                        <!-- Intensity -->
                                            <validation-provider
                                                name="Intensity"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Intensity<code>*</code>
                                                    </template>
                                                    <b-form-select 
                                                    value-field="value"
                                                        text-field="label"   
                                                        id="inputIntensity" 
                                                        v-model="form.intensity" 
                                                        :options="intensityOpt"  
                                                        v-on:change="generateArticle()"
                                                        :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputIntensity">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Intensity -->
                                        <!-- Class Level -->
                                        <!-- <validation-provider
                                                name="Note"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Intensity<code>*</code>
                                                    </template>
                                                <b-form-radio-group
                                                    v-model="isSelect"
                                                    :options="classOpt"
                                                    class="mb-3"
                                                    value-field="value"
                                                    text-field="text"
                                                    disabled-field="notEnabled"
                                                ></b-form-radio-group>
                                                    <b-form-invalid-feedback id="inputNote">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider> -->
                                        <!-- /Class Level -->
                                    </b-col>
                                    <b-col cols="auto" md="6">
                                            <!-- Article -->
                                            <validation-provider
                                                name="Article"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Article<code>*</code>
                                                    </template>
                                                    <b-form-input readonly id="inputArticle" v-model="form.article"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    <b-form-invalid-feedback id="inputArticle">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Article -->

                                        <!-- Repeat Sample -->
                                            <validation-provider
                                                name="RepeatSample"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Repeat Sample<code>*</code>
                                                    </template>
                                                    <b-form-radio-group
                                                        id="inputRepatSample"
                                                        v-model="form.repeatSample"
                                                        :change="setRepeat()"
                                                        name="repeat-sample"
                                                    >
                                                        <b-form-radio  value="yes">Yes</b-form-radio>
                                                        <b-form-radio  value="no">No</b-form-radio>
                                                    </b-form-radio-group>
                                                    <b-form-invalid-feedback id="inputRepatSample">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Repeat Sample -->

                                        <!-- No. Sample -->
                                            <validation-provider
                                                name="NoSample"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Sample Number<code>*</code>
                                                    </template>
                                                    <template v-if="readOnly === true">
                                                        <b-form-input id="inputNoSample" v-model="form.noSample"   :state="getValidationState(validationContext)"  trim></b-form-input>
                                                    </template>
                                                    <template v-else>
                                                        <v-select 
                                                            label="ref"
                                                            v-model="form.noSample"
                                                            :reduce="ref => ref.code" 
                                                            @input="setSelected" 
                                                            :options="refSampeOpt" 
                                                            :filter-by="myFilter"
                                                            placeholder="Type your sample number"
                                                            >
                                                            <template #option="{ ref, old }">
                                                                <p style="margin: 0">{{ ref }}</p>
                                                                <em class="text-muted">{{ old }}</em>
                                                            </template>
                                                        </v-select>
                                                    </template>
                                                    <b-form-invalid-feedback id="inputNoSample">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /No. Sample -->
                                        <!-- Old No. Sample -->
                                            <validation-provider
                                                name="OldSample"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Old Sample Number<code>*</code>
                                                    </template>
                                                    <b-form-input 
                                                        id="inputOldSample" 
                                                        v-model="form.oldSample"  
                                                        
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-input>
                                                    <b-form-invalid-feedback id="inputOldSample">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Old No. Sample -->

                                        <!-- Guidance -->
                                            <validation-provider
                                                name="Guidance"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Guidance<code>*</code>
                                                    </template>
                                                    <b-form-select
                                                        
                                                        value-field="value"
                                                        text-field="value" 
                                                        id="inputGuidance" 
                                                        v-model="form.guidance" 
                                                        :options="guidanceOpt"  
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputGuidance">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Guidance -->

                                        <!-- SpecSize -->
                                            <validation-provider
                                                name="SpecSize"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Size Spec<code>*</code>
                                                    </template>
                                                    <b-form-select
                                                        
                                                        value-field="value"
                                                        text-field="text" 
                                                        id="inputSpecSize" 
                                                        v-model="form.specSize" 
                                                        :options="setSizeSpec"  
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputSpecSize">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                            <div>
                                                <b-button block  variant="warning" v-on:click="goToList()">Cancel</b-button>
                                                <b-button block  variant="success" type="submit">Save</b-button>
                                                <!-- <b-button block  variant="danger" v-on:click="goToDrop()" v-if="isApprove">Drop</b-button> -->
                                            </div>
                                            <template>
                                            <b-card sub-title="Product History">
                                                <b-table
                                                    responsive
                                                    show-empty
                                                    small
                                                    fixer
                                                    :items="approveHistory"
                                                    :fields="fieldsApprove"
                                                >
                                                    <template #cell(date)='{item}'>
                                                        {{new Date(item.date).toLocaleString("id-ID")}}
                                                    </template>
                                                </b-table>
                                            </b-card>
                                            </template>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </template>
                </b-tab>
                <b-tab title="Fabric">
                    <product-design-form-fabric-edit v-bind="$props" />
                </b-tab>
                <b-tab title="Accessories">
                    <b-form-radio-group
                        v-model="isSelectAcc"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectAcc === 'Yes'">
                    <product-design-form-acc-edit v-bind="$props" />
                    </template>
                </b-tab>
                <b-tab title="Thread">
                    <b-form-radio-group
                        v-model="isSelectThread"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectThread === 'Yes'">
                    <product-design-form-thread-edit v-bind="$props" />
                    </template>
                </b-tab>
                <b-tab title="Artwork">
                    <b-form-radio-group
                        v-model="isSelectArtwork"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectArtwork === 'Yes'">
                    <product-design-form-artwork-edit v-bind="$props" />
                    </template>
                </b-tab>
                <b-tab title="Interlining">
                    <b-form-radio-group
                        v-model="isSelectInterlining"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectInterlining === 'Yes'">
                    <product-design-form-interlining-edit v-bind="$props" />
                    </template>
                </b-tab>
            </b-tabs>
            
            
        </b-card>
        </template>
    </div>
</template>
<script>

import { APIPATH_PLM } from "../../../../api/api-paths";
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../api/api-methods";

import { mapState, mapActions, mapGetters } from 'vuex';
import apiProduct from '@/store/product-form/master-param.js'
import 'vue-select/dist/vue-select.css';
import ProductDesignFormFabricEdit from './ProductDesignFormFabricEdit.vue';
import ProductDesignFormAccEdit from './ProductDesignFormAccEdit.vue';
import ProductDesignFormThreadEdit from './ProductDesignFormThreadEdit.vue';
import ProductDesignFormArtworkEdit from './ProductDesignFormArtworkEdit.vue';
import ProductDesignFormInterliningEdit from './ProductDesignFormInterliningEdit.vue';
import Vue from 'vue';


export default {
  components: {
    ProductDesignFormFabricEdit,
    ProductDesignFormAccEdit,
    ProductDesignFormThreadEdit,
    ProductDesignFormArtworkEdit,
    ProductDesignFormInterliningEdit
    },
    props:['productId'],
    data(){
        return{
            deleted_at:null,
            productDetail:null,
            readOnly:true,
            isApprove: false,
            form:{
                code:null,
                duration:null,
                brand:null,
                collectionName:null,
                year:null,
                quartal:null,
                prodMonth:null,
                collMonth:null,
                subBrand:null,
                category:null,
                color:null,
                intensity:null,
                article:null,
                design:null,
                imgDesign:[],
                status:'Waiting For Approval',
                noSample:null,
                guidance:null,
                specSize:null,
                technical:[],
                repeatSample:null,
                note:null,
                oldSample:null,
                sampleID:null,
                technicalDrawing:null,
                imgTechnicalDrawing:[],
                codeMainLabel:null,
                codeStreamerLabel:null,
                codeSizeLabel:null,
                codeHangtag:null,
                imgCodeMainLabel:[],
                imgCodeStreamerLabel:[],
                imgCodeSizeLabel:[],
                imgCodeHangtag:[],
                isProccess:null,
                codeFabric:null,
                runningNumber:null,

            },
            isValid:false,
            brandOpt:[],
            monthOpt:[],
            yearOpt:[],
            subBrandOpt:[],
            categoryOpt:[],
            modelOpt:[],
            noSampleOpt:[],
            refSampeOpt:[],
            guidanceOpt:[],
            specSizeOpt:[],
            quartalOpt:[
                {value:'1', text:'1'},
                {value:'2', text:'2'},
                {value:'3', text:'3'},
                {value:'4', text:'4'},
            ],
            durationOpt:[
                {value:'basic', text:'BASIC'},
                {value:'online', text:'ONLINE'},
                {value:'regular', text:'REGULAR'},
            ],
            intensityOpt:[
                {text:'DARK',value:'9',label:'DARK - 9'},
                {text:'LIGHT',value:'1',label:'LIGHT - 1'},
                {text:'MEDIUM',value:'5',label:'MEDIUM - 5'},
                ],
            colorOpt:[],
            myFilter: (option, label, search) => {
                let temp = search.toLowerCase();
                return option.ref.toLowerCase().indexOf(temp) > -1 || 
                option.old.toLowerCase().indexOf(temp) > -1
            },
            codeOpt:[],
            fields:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                'used_as'
            ],
            fieldsApprove:[
                {key:'date',label:'Date'},
                'status','comment',
                {key:'created_by', label:'Approve By'}
            ],
            approveHistory:[],
            isSelectFabric:'Yes',
            isSelectAcc:'TBD',
            isSelectThread:'TBD',
            isSelectArtwork:'TBD',
            isSelectInterlining:'TBD',
            processOpt:[
                { item: 'TBD', name: 'TBD' },
                { item: 'Yes', name: 'Yes' },
                { item: 'No', name: 'No'},
            ],
            code:{
                brandCode:null,
                yearCode:null,
                monthCode:null,
                subbrandCode:null,
                categoryCode:null,
                colorCode:null,
                intensityCode:null
            },

        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('productDesign',['PUT_PRODUCT_DETAIL','addProduct','deleteProduct','updateProduct','GET_PRODUCT_SAMPLE','getProduct','GET_PRODUCT_IMAGES','GET_PRODUCT_APPROVE']),
        ...mapActions('sizeSpec',['GET_SIZESPEC_DASHBOARD']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getApproveHistory(){
            this.GET_PRODUCT_APPROVE().then(response =>{
                let approve     = response.data.payload.filter(app => app.product === this.productId)
                this.approveHistory = approve
                
            })
        },
        getDataParam(){
        
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    
                    this.brandOpt = response.data.payload.filter(d=>d.category ==='Brand')
                    // this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')
                    // this.yearOpt = response.data.payload.filter(d=>d.category ==='Year')                    
                    // this.modelOpt = response.data.payload.filter(d=>d.category ==='Model')
                    // this.guidanceOpt = response.data.payload.filter(d=>d.category ==='Guidance')                
                    // this.subBrandOpt = response.data.payload.filter(d=>d.category ==='Sub Brand')
                    // this.categoryOpt = response.data.payload.filter(d=>d.category ==='Category')
                    // this.colorOpt = response.data.payload.filter(d=>d.category ==='Color')

                    this.monthOpt = []
                    this.yearOpt = []
                    this.modelOpt = []
                    this.guidanceOpt = []
                    this.subBrandOpt = []
                    this.categoryOpt = []
                    this.color = []

                    for (let i = 0; i < response.data.payload.length; i++) {
                        const param = {
                            id:response.data.payload[i].id,
                            category:response.data.payload[i].category,
                            name:response.data.payload[i].name,
                            value:response.data.payload[i].value,
                            description:response.data.payload[i].description,
                            parent:response.data.payload[i].parent,
                            text:response.data.payload[i].value+' - '+response.data.payload[i].name
                        }

                        if(response.data.payload[i].category === 'Month'){
                            this.monthOpt.push(param)
                        }if(response.data.payload[i].category === 'Year'){
                            this.yearOpt.push(param)
                        }if(response.data.payload[i].category === 'Model'){
                            this.modelOpt.push(param)
                        }if(response.data.payload[i].category === 'Guidance'){
                            this.guidanceOpt.push(param)
                        }if(response.data.payload[i].category === 'Sub Brand'){
                            this.subBrandOpt.push(param)
                        }if(response.data.payload[i].category === 'Category'){
                            this.categoryOpt.push(param)
                        }if(response.data.payload[i].category === 'Color'){
                            this.colorOpt.push(param)
                        }
                        
                    }
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
            
        },
        getSizeSpec(){
            this.specSizeOpt = []
            this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
                let sizeSpec = JSON.parse(JSON.stringify( rsp.data.payload))
                // console.log(sizeSpec);
                for (let i = 0; i < sizeSpec.length; i++) {
                    const objSize = {
                        value: sizeSpec[i].id,
                        text: sizeSpec[i].category +' - '+ sizeSpec[i].description,
                        brand: sizeSpec[i].brand,
                        category: sizeSpec[i].category
                    }
                    this.specSizeOpt.push(objSize)
                }
                // console.log('sizespec',JSON.parse(JSON.stringify(this.specSizeOpt)));
            })
        },
        goToList(){
            
            this.$router.push({name:'AppsProductDesign2Page'})
        },
        getProductData(){
            
            this.$set(this,'productDetail',this.GET_PRODUCT_DETAIL)
            console.log('productDetail',this.productDetail);
            this.form.brand = this.productDetail.brand,
            this.form.collectionName = this.productDetail.collection_name,
            this.form.year = this.productDetail.year,
            this.form.quartal = this.productDetail.quartal,
            this.form.prodMonth = this.productDetail.prod_month,
            this.form.collMonth = this.productDetail.coll_month,
            this.form.subBrand = this.productDetail.subbrand,
            this.form.category = this.productDetail.category,
            this.form.article = this.productDetail.article,
            // this.designImages = this.productDetail.design,
            // this.form.design = this.productDetail.design,
            this.form.repeatSample = this.productDetail.repeat_sample,
            this.form.noSample = this.productDetail.ref_sample,
            this.form.oldSample = this.productDetail.old_sample_no,
            this.form.guidance = this.productDetail.guidance,
            this.form.specSize = this.productDetail.size_spec,
            // this.technicalImages = this.productDetail.technical,
            this.form.status = this.productDetail.status,
            this.form.note = this.productDetail.note,
            this.form.color = this.productDetail.color,
            this.form.intensity = this.productDetail.itensity,
            this.form.duration = this.productDetail.duration,
            this.form.runningNumber = this.productDetail.running_number,
            this.isSelectFabric = this.productDetail.fabric_process,
            this.isSelectAcc = this.productDetail.accessories_process,
            this.isSelectThread = this.productDetail.thread_process,
            this.isSelectArtwork = this.productDetail.artwork_process,
            this.isSelectInterlining = this.productDetail.interlining_process

        },
        getProductSample(){
            this.GET_PRODUCT_SAMPLE().then(response =>{
                this.refSampeOpt = []
                
                let objSample   = response.data.payload
                for(var i=0;i<objSample.length;i++){
                    let arrSample = {
                        ref: objSample[i].ref_sample,
                        code: objSample[i].sample_id,
                        old: objSample[i].old_sample_no
                    }
                    this.refSampeOpt.push(arrSample)
                }
            })
        },
        setSelected(value){
            // console.log(value)
            if (value === null){
                this.form.guidance = null
                this.form.specSize = null
                this.form.oldSample = null
                this.form.sampleID = null
                
            }else{
                
                let dataProductSample = this.PRODUCT_SAMPLE.filter(ps => ps.sample_id === value)
                // console.log(dataProductSample);
                this.form.sampleID = dataProductSample[0].sample_id
                this.form.oldSample =dataProductSample[0].old_sample_no
                this.form.guidance =dataProductSample[0].guidance
                this.form.specSize =dataProductSample[0].size_spec
                
                
            }
        },
        setSample(value){
            let dataProductSample = this.PRODUCT_SAMPLE.filter(ps => ps.sample_id === value)


            this.form.oldSample =dataProductSample[0].old_sample_no
            

            this.setSelected(value)

        },
        setOldSample(value){
            let dataProductSample = this.PRODUCT_SAMPLE.filter(ps => ps.sample_id === value)
            


            this.form.noSample = dataProductSample[0].ref_sample
            this.setSelected(value)
        },
        setRepeat(){
            if(this.form.repeatSample === "yes"){
                this.readOnly = false
            }if (this.form.repeatSample === "no") {
                this.readOnly = true
                if((this.form.brand && this.form.year && this.form.collMonth) != null){
                    let brandCode    = this.brandOpt.filter(x=>x.value === this.form.brand)
                    let yearCode     = this.yearOpt.filter(x=>x.value === this.form.year)
                    let monthCode    = this.monthOpt.filter(x=>x.value === this.form.collMonth)
                    this.form.noSample = brandCode[0].name+'/'+yearCode[0].value+'/'+ monthCode[0].value.substring(0,3)
                    
                }
            }else{
                this.readOnly = false
            }
        },
        clearForm(){
            this.form.indexId=null
            this.form.code=null
            this.form.duration=null
            this.form.brand=null
            this.form.collectionName=null
            this.form.year=null
            this.form.quartal=null
            this.form.prodMonth=null
            this.form.collMonth=null
            this.form.subBrand=null
            this.form.category=null
            this.form.color=null
            this.form.intensity=null
            this.form.article=null
            this.form.design=null
            this.form.imgDesign=[]
            this.form.status=null
            this.form.noSample=null
            this.form.guidance=null
            this.form.specSize=null
            this.form.technical=[]
            this.form.repeatSample=null
            this.form.note=null
            this.form.date=null
            this.form.oldSample=null
            this.form.sampleID=null
            this.form.technicalDrawing=null
            this.form.imgTechnicalDrawing=[]
            this.form.codeMainLabel=null
            this.form.codeStreamerLabel=null
            this.form.codeSizeLabel=null
            this.form.codeHangtag=null
            this.form.imgCodeMainLabel=[]
            this.form.imgCodeStreamerLabel=[]
            this.form.imgCodeSizeLabel=[]
            this.form.imgCodeHangtag=[]
            this.form.isProccess=null

        },
        disableFieldApprove(){
            const cek = this.form.runningNumber;
            if(cek){
                this.isApprove = true;
            } 
        },
        goToDrop(){
            this.generateArticle();
            this.form.status = 'Drop';
            fnPOST(APIPATH_PLM.dropProdev, {
                product_id:this.productId,
                status:this.form.status,
                article:this.form.article,
                
            })
            .then((rsp) => {
            console.log(rsp);
                if (rsp.data.status == "200") {
                this.goToList()
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        generateArticle(){
            // if(!this.form.runningNumber){

                if(this.form.year && this.form.collMonth && this.form.subBrand && this.form.category && this.form.color && this.form.intensity){
                    this.form.article = null
                    let brandId      = this.brandOpt.filter(i=>i.value === this.form.brand)
                    let brandCode    = this.brandOpt.filter(x=>x.value === this.form.brand)
                    let yearCode     = this.yearOpt.filter(x=>x.value === this.form.year)
                    let monthCode    = this.monthOpt.filter(x=>x.value === this.form.collMonth)

                    let subbrandCode = this.subBrandOpt.filter(x=>x.value === this.form.subBrand && x.parent === brandId[0].id )
                    let categoryCode = this.categoryOpt.filter(x=>x.value === this.form.category && x.parent === brandId[0].id )

                    let intensityCode   = this.intensityOpt.filter(x=>x.value === this.form.intensity)
                    
                    let colorCode       = this.colorOpt.filter(x=>x.value === this.form.color)
                    var number = 'XX'
                    if(this.form.runningNumber){
                        number  = this.form.runningNumber.toString().padStart(2,0)
                    }
                    
                    
                    
                    let articleNumber   = brandCode[0].name+'-'+yearCode[0].name+monthCode[0].name+subbrandCode[0].name+categoryCode[0].name+number+'-'+colorCode[0].name+intensityCode[0].value
                    Vue.set(this.form,'article',articleNumber)
                    
                    Vue.set(this.code,'brandCode',brandCode[0].name)
                    Vue.set(this.code,'yearCode',yearCode[0].name)
                    Vue.set(this.code,'monthCode',monthCode[0].name)
                    Vue.set(this.code,'subbrandCode',subbrandCode[0].name)
                    Vue.set(this.code,'categoryCode',categoryCode[0].name)
                    Vue.set(this.code,'intensityCode',intensityCode[0].value)
                    Vue.set(this.code,'colorCode',colorCode[0].name)
                    
                    // console.log(brandCode,yearCode,monthCode,subbrandCode,categoryCode,intensityCode,colorCode,number);
                    // if(this.readOnly === false){
                    //     this.form.noSample = brandCode[0].name+'/'+yearCode[0].name+'/'+monthCode[0].name
                    // }
                }
            // }  

        },
        onSubmit(){
            this.generateArticle()
            let formProductDesign = {
                product_id:this.productId,
                status:this.form.status,
                duration:this.form.duration,
                brand:this.form.brand,
                collection_name:this.form.collectionName,
                year:this.form.year,
                quartal:this.form.quartal,
                prod_month:this.form.prodMonth,
                coll_month:this.form.collMonth,
                subbrand:this.form.subBrand,
                category:this.form.category,
                article:this.form.article,
                itensity:this.form.intensity,
                color:this.form.color,
                is_proccess:'Product Submitted',
                brand_code:this.code.brandCode,
                year_code:this.code.yearCode,
                month_code:this.code.monthCode,
                subbrand_code:this.code.subbrandCode,
                category_code:this.code.categoryCode,
                color_code:this.code.colorCode,
                intensity_code:this.code.intensityCode,
                // design:this.product.design,
                sample_id: this.form.sample_id,
                repeat_sample:this.form.repeatSample,
                ref_sample:this.form.noSample,
                old_sample_no:this.form.oldSample,
                guidance:this.form.guidance,
                size_spec:this.form.specSize,
                note:this.form.note,
                fabric_process:this.isSelectFabric,
                accessories_process:this.isSelectAcc,
                thread_process:this.isSelectThread,
                artwork_process:this.isSelectArtwork,
                interlining_process:this.isSelectInterlining,
                // technical_drawing:this.product.technical,
                // fabric:this.fabricList,
                // accessories:this.accList,
                // thread:this.threadList,
                // artwork:this.artworkList,
                // interlining:this.interliningList,
                // brand_code:this.product.brand_code,
                // year_code:this.product.year_code,
                // month_code:this.product.month_code,
                // subbrand_code:this.product.subbrand_code,
                // category_code:this.product.category_code,
                // color_code:this.product.color_code,
                // intensity_code:this.product.intensity_code,
                //test drop
                deleted_at : this.deleted_at,
            }

            this.PUT_PRODUCT_DETAIL(formProductDesign)
                .then(response => {
                    // console.log('edit sukses');
                    this.showNotification({type:'success', message:'Successfully'})
                    this.goToList()
                    console.log(response)
                })
                .catch(error =>{
                    // console.log('product dev',error);
                    return error
                })
        }
        
    },
    created(){
        this.getDataParam()
        this.getProductSample()
        this.getProductData()
        this.getSizeSpec()
        this.getApproveHistory()
        
    },
    mounted(){
        // this.generateArticle()
        this.disableFieldApprove()   
        
    },
    computed:{
        ...mapGetters('productDesign',['product','PRODUCT_SAMPLE','GET_PRODUCT_MATERIAL','GET_PRODUCT_DETAIL','PRODUCT_MATERIAL']),
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        setSubBrand(){
            let brandId = this.brandOpt.filter(i=>i.value == this.form.brand)
            return this.subBrandOpt.filter(x=>x.parent == brandId[0].id)
        },
        setCategory(){
            let brandId = this.brandOpt.filter(i=>i.value == this.form.brand)            
            return this.categoryOpt.filter(i=>i.parent == brandId[0].id)
        },
        setSizeSpec(){
            let brandName = this.brandOpt.filter(i =>i.value == this.form.brand )
            let categoryName = this.categoryOpt.filter(i => (i.value == this.form.category && i.parent == brandName[0].id))
            let sizeArr = JSON.parse(JSON.stringify(this.specSizeOpt))
            let sizeName = sizeArr.filter(i => (i.brand == brandName[0].value && i.category == categoryName[0].value))
            return sizeName
        },
    }
}
</script>