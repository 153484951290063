<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto" md="6">
                        
                        <!-- Code Thread -->
                        <validation-provider
                            name="codethread"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Code
                                </template>
                            
                                
                                <!-- <b-form-select id="inputCodeThread" v-model="form.codeThread"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                <v-select append-to-body
                                    :reduce="ref => ref.code" 
                                    v-model="form.codeThread"
                                    @input="setSelected" 
                                    :options="codeOpt" 
                                    placeholder="Type your code number"
                                    label="ref">
                                    <template #list-header>
                                        <b-input-group-append>
                                            <b-button block variant="light" @click="showMaster()">
                                                <!-- <b-icon icon="eye-fill" aria-label="Help"></b-icon> -->
                                                Lihat Semua
                                            </b-button>
                                        </b-input-group-append>
                                    </template>
                                    <template #option="{ code, description }">
                                        <p style="margin: 0">{{ code }}</p>
                                        <em class="text-muted">{{ description }}</em>
                                    </template>
                                </v-select>
                                
                                <b-form-invalid-feedback id="inputCodeThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Type Thread -->
                        <validation-provider
                            name="TypeThread"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4"  
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Type
                                </template>
                            
                                <b-form-input readonly id="inputTypeThread" v-model="form.typeThread"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputTypeThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Description -->
                        <validation-provider
                            name="DescriptionThread"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Description
                                </template>
                            
                                <b-form-input readonly id="DescriptionThread" v-model="form.descriptionThread"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="DescriptionThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Supplier -->
                        <!-- <validation-provider
                            name="supplier"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Supplier
                                </template>
                                <b-form-input id="inputSupplierThread" v-model="form.supplier"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputSupplierThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->

                        <!-- Detail Thread -->
                        <validation-provider
                            name="DetailThread"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Detail
                                </template>
                            
                                <b-form-textarea id="DetailThread" v-model="form.detailThread"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                <b-form-invalid-feedback id="DetailThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        
                        
                    </b-col>
                    <b-col cols="auto" md="6">
                        <!-- UsedThread -->
                        <!-- <validation-provider
                            name="UsedThread"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Used As
                                </template>
                                <b-form-select id="inputUsedThread" v-model="form.usedThread" :options="usedOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                <b-form-invalid-feedback id="inputUsedThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->

                        <!-- Images Thread -->
                        <!-- <validation-provider
                            name="imagesthread"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images
                                </template>
                                <b-form-file
                                    multiple
                                    ref="fileInput"
                                    id="inputImagesThread"
                                    v-model="form.imagesThread"
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose file here..."
                                    accept=".jpg, .png,"
                                    no-drop
                                    :file-name-formatter="formatNames"
                                    v-on:change="onFileChange"
                                ></b-form-file>
                                    <div v-for="img in imagesUrl" :key="img.name">
                                        <p class="small">
                                            <a :href="img.src">{{img.fileName}}</a>
                                            <b-badge href="#" v-on:click="removeImg(img.name,imagesUrl)" variant="light">
                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                            </b-badge>
                                        </p>
                                    </div>
                                <b-form-invalid-feedback id="inputImagesThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->
                        <b-form-group
                            label-cols          ="4" 
                            label-cols-lg       ="4" 
                            label-size          ="sm"
                        >
                            <template v-slot:label>
                                Images
                            </template>
                                <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesThread"></b-img>
                        </b-form-group>
                        <div>
                            <b-button block  variant="success" type="submit">Save</b-button>
                        </div>
                    </b-col>
                </b-row>
                    
            </b-form>
        </validation-observer>

        <div>
            <b-table
                responsive
                striped 
                hover
                :items="loadProductMaterial"
                :fields="fields"
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="data">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
        
        <b-modal
            hide-backdrop
            hide-footer
            v-model="modalMaster"
            size="xl"
            ref="modal"
            scrollable 
            title="Master Material">
            <master-thread/>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MasterThread from '../Master/MasterThread.vue';

export default {
    name:'FormThread',
    components: { MasterThread },
    data(){
        return{
            mainProps: {width: 150, class: 'm1' },
            formName: 'Thread',
            modulName: null,
            tabIndex:2,
            isValid:false,
            form:{
                indexId:null,
                threadId:null,
                codeThread:null,
                typeThread:null,
                imagesThread:[],
                usedThread:null,
                detailThread:null,
                descriptionThread:null,
                category:'thread'
            },
            codeOpt:[
                {code:'Code A', ref:'Code A'},
                {code:'Code B', ref:'Code B'},
                {code:'Code C', ref:'Code C'},
                {code:'Code D', ref:'Code D'},
            ],
            usedOpt:[
                {value:'main', text:'Main'},
                {value:'collar', text:'Collar'},
                {value:'pocket', text:'Pocket'},
            ],
            fields:[
                {key:'actions', label:''},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                // 'used_as'
            ],
            imagesUrl:[],
            threadOpt: [
                { item: 'TBD', name: 'TBD' },
                { item: 'Yes', name: 'Yes' },
                { item: 'No', name: 'No', notEnabled: true},
                
            ],
            isSelect:'TBD',
            modalMaster:false,
        }
    },
    methods:{
        ...mapActions('productDesign',['add','delete','update','get']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            if(this.form.indexId == null){
                this.add({
                    formname: this.formName,
                    category: this.form.category,
                    material_id: this.form.threadId,
                    code: this.form.codeThread,
                    type: this.form.typeThread,
                    images: this.imagesUrl,
                    used_as: this.form.usedThread,
                    detail: this.form.detailThread,
                    description: this.form.descriptionThread,
                    isProccess:this.isSelect
                })
               
            }else{
                this.update({
                    id: this.form.indexId,
                    material_id: this.form.threadId,
                    category: this.form.category,
                    formname: this.formName,
                    code: this.form.codeThread,
                    type: this.form.typeThread,
                    images: this.imagesUrl,
                    used_as: this.form.usedThread,
                    detail: this.form.detailThread,
                    description: this.form.descriptionThread,
                    isProccess:this.isSelect
                })
            }
            this.clearForm()
        
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeThread = null
            this.form.typeThread = null
            this.form.imagesThread = []
            this.imagesUrl = []
            this.form.usedThread = null
            this.form.detailThread = null
            this.form.descriptionThread = null
            this.$refs.observer.reset()
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
        return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        deleteData(id){
            
            this.delete({id:id,formname:this.formName})
        },
        editData(index){
            
            this.get({id:index,formname:this.formName})
            this.form.indexId           = index
            this.form.threadId          = this.threadDetail.id
            this.form.codeThread        = this.threadDetail.code
            this.form.typeThread        = this.threadDetail.type
            this.imagesUrl              = this.threadDetail.images
            this.form.usedThread        = this.threadDetail.used_as
            this.form.detailThread      = this.threadDetail.detail
            this.form.descriptionThread = this.threadDetail.description
            this.isSelect               = this.threadDetail.thread_process
        },
        
        showMaster(){
            this.modalMaster = true
        },
        setSelected(value){
            
            if(value != null){
                let dataThread = this.MASTER_MATERIAL.filter(x => x.code === value)
                this.form.threadId= dataThread[0].id
                this.form.codeThread= dataThread[0].code
                this.form.typeThread= dataThread[0].type
                this.form.descriptionThread = dataThread[0].description
                this.form.imagesThread = dataThread[0].img_src
            }else{
                console.log('belum ada data')
            }
        },
        getThreadMaster(){
            this.GET_MASTER_MATERIAL().then(response => {
                this.codeOpt = []
                    let objThread = response.data.payload.filter(x => x.category === 'thread')
                    for(var i=0;i<objThread.length;i++){
                        let arrThread = {
                            ref: objThread[i].code,
                            code: objThread[i].code,
                            description: objThread[i].description
                            
                        }
                        this.codeOpt.push(arrThread)
                    }
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
    },
    mounted(){
        if(this.form.indexId != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        
        this.getThreadMaster()
    },
    computed:{
    ...mapGetters('productDesign',['PRODUCT_MATERIAL']),
    ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        loadProductMaterial(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='thread')
        }
    }
}
</script>