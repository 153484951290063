<template>
    <div class="wrapper">
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item  >
                <router-link tag="a" to="/app/appsplm-supplier-selection" >{{formName}}</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid="sm">
            <b-card
                header="Product Development"
                
            >
                <b-row>
                    <b-col sm="auto" md="4">
                        
                        
                        <b-card>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    No. <code>*</code>
                                </template>
                
                                <label>{{product.code}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Duration <code>*</code>
                                </template>
                
                                <label>{{product.duration}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Brand <code>*</code>
                                </template>
                
                                <label>{{product.brand}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Collection Name<code>*</code>
                                </template>
                                <label> {{product.collection_name}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Quartal<code>*</code>
                                </template>
                                <label> {{product.quartal}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Year<code>*</code>
                                </template>
                                <label> {{product.year}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"  
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Prod. Month<code>*</code>
                                </template>
                                <label> {{product.prod_month}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Coll. Month<code>*</code>
                                </template>
                                <label> {{product.coll_month}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Sub. Brand<code>*</code>
                                </template>
                                <label> {{product.subbrand}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Category<code>*</code>
                                </template>
                                <label> {{product.category}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Color<code>*</code>
                                </template>
                                <label> {{product.color}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Itensity<code>*</code>
                                </template>
                                <label> {{product.itensity}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Article<code>*</code>
                                </template>
                                <label> {{product.article}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Repeat Sample<code>*</code>
                                </template>
                                <label> {{product.repeat_sample}}</label>
                            </b-form-group>
                            
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Ref. Sample<code>*</code>
                                </template>
                                <label> {{product.ref_sample}}</label>
                            </b-form-group>
                            
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Old Sample No.<code>*</code>
                                </template>
                                <label> {{product.old_sample_no}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Guidance<code>*</code>
                                </template>
                                <label> {{product.guidance}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Size Spec<code>*</code>
                                </template>
                                <label> {{product.size_spec}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Note<code>*</code>
                                </template>
                                <label> {{product.note}}</label>
                            </b-form-group>

                            <template v-for="images in productImg">
                                    <b-form-group
                                        :key="images.img_id"
                                        label-size ="sm">
                                        <template v-slot:label>
                                            {{images.img_label}}
                                        </template>
                                        <b-link :href="images.img_src" target="_blank">{{images.img_name}}</b-link>
                                    </b-form-group>
                            </template>
                            
                        </b-card>
                    </b-col>
                    
                    <b-col sm="auto" md="8">
                        <b-card sub-title="Fabric">
                            <div class="text-right" v-if="fabricData.length > 0">
                                <b-button title="Preview" size="xs" variant="warning" v-on:click="fabricPdf('#tableFabric','Fabric','preview')">Preview</b-button>
                                <b-button title="Download" size="xs" variant="danger" v-on:click="fabricPdf('#tableFabric','Fabric','download')">Download</b-button>
                            </div>
                            <b-table
                                id="tableFabric"
                                responsive
                                show-empty
                                small
                                fixed
                                :items="fabricData"
                                :fields="fieldsFabric"
                            >
                            <template #cell(img_src)="{item}"  >
                                <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name"></b-img>
                               <!-- <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link> -->
                            </template>
                            

                            </b-table>
                        </b-card>
                        <b-card sub-title="Accessories">
                            <div class="text-right" v-if="accData.length > 0">
                                <b-button title="Preview" size="xs" variant="warning" v-on:click="materialPdf('accData','Accessories','preview')">Preview</b-button>
                                <b-button title="Download" size="xs" variant="danger" v-on:click="materialPdf('accData','Accessories','download')">Download</b-button>
                            </div>
                            <b-table
                                id="tableAcc"
                                responsive
                                show-empty
                                small
                                fixed
                                :items="accData"
                                :fields="fieldsAcc"
                            >
                            <template #cell(img_src)="{item}"  >
                               <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name" ></b-img>
                               <!-- <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link> -->
                            </template>
                            

                            </b-table>
                        </b-card>
                        <b-card sub-title="Thread">
                            <div class="text-right" v-if="threadData.length > 0">
                                <b-button title="Preview" size="xs" variant="warning" v-on:click="materialPdf('threadData','Thread','preview')">Preview</b-button>
                                <b-button title="Download" size="xs" variant="danger" v-on:click="materialPdf('threadData','Thread','download')">Download</b-button>
                            </div>
                            <b-table
                                id="tableThread"
                                responsive
                                show-empty
                                small
                                fixed
                                :items="threadData"
                                :fields="fieldsThread"
                            >
                                <template #cell(img_src)="{item}"  >
                                    <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name" ></b-img>
                                    <!-- <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link> -->
                                </template>
                                

                            </b-table>
                        </b-card>
                        <b-card sub-title="Artwork">
                            <div class="text-right" v-if="artworkData.length > 0">
                                <b-button title="Preview" size="xs" variant="warning" v-on:click="materialPdf('artworkData','Artwork','preview')">Preview</b-button>
                                <b-button title="Download" size="xs" variant="danger" v-on:click="materialPdf('artworkData','Artwork','download')">Download</b-button>
                            </div>
                            <b-table
                                id="tableArtwork"
                                responsive
                                show-empty
                                small
                                fixed
                                :items="artworkData"
                                :fields="fieldsArtwork"
                            >
                                <template #cell(img_src)="{item}"  >
                                    <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name" ></b-img>
                                    <!-- <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link> -->
                                </template>
                                
                            </b-table>
                        </b-card>
                        <b-card sub-title="Interlining">
                            <div class="text-right" v-if="interliningData.length > 0">
                                <b-button title="Preview" size="xs" variant="warning" v-on:click="materialPdf('interliningData','Interlining','preview')">Preview</b-button>
                                <b-button title="Download" size="xs" variant="danger" v-on:click="materialPdf('interliningData','Interlining','download')">Download</b-button>
                            </div>
                            <b-table
                                id="tableInter"
                                responsive
                                show-empty
                                small
                                fixed
                                :items="interliningData"
                                :fields="fieldsInterlining"
                            >
                                <template #cell(img_src)="{item}"  >
                                    <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name" ></b-img>
                                    <!-- <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link> -->
                                </template>
                                

                            </b-table>
                        </b-card>
                    
                        <b-card>
                            <template v-slot:header >
                                <b-row>
                                    <b-col sm="auto" md="9">
                                        <div class="text-left">
                                            <h4>Supplier Selection</h4>
                                        </div>
                                    </b-col>
                                    <b-col sm="auto" md="3">
                                        <template v-if="roles">
                                        <div class="text-right">
                                            <b-button variant="success" size="sm" v-on:click="addSupplier()" >Add Supplier</b-button>
                                        </div>
                                        </template>
                                    </b-col>
                                    
                                </b-row>
                            </template>
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="supplierData"
                                :fields="fieldsSupplier"
                            >
                                <!-- <template v-slot:cell(matdev)="{item}">
                                    <div v-if="item.type_subcon === 'CMT'">
                                        <b-button title="Detail" size="xs" variant="info" v-on:click="addMatDev(item.product_id)" >Add</b-button>
                                    </div>
                                </template> -->
                            </b-table>
                            <div class="text-right">
                                <b-button title="Detail" size="xs" variant="success" v-on:click="addMatDev(productId)">Add Material Dev.</b-button>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-container>
        <b-modal
            hide-footer
            v-model="show"
            hide-backdrop
            v-on:ok="onSubmit()"
            ok-title="Save"
            size="lg"
            ref="modal"
            no-close-on-backdrop
            scrollable 
            title="Form Add Supplier"
        >
            <validation-observer ref="observer" v-slot="{handleSubmit}">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col cols="12">
                            <!-- Code Supplier -->
                            <validation-provider
                                    name="code-supplier"
                                    :rules="{ required: true }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Code Supplier <code>*</code>
                                        </template>
                                        <v-select
                                            label="supplier_name"
                                            v-model="form.codeSupplier"
                                            :reduce="supplier_name => supplier_name.supplier_code" 
                                            @input="setSupplier" 
                                            :options="codeSupplierOpt" 
                                            :filter-by="myFilter"
                                            placeholder="Type your sample number"
                                        >
                                            <template #option="{ supplier_code, supplier_name }">
                                                <p style="margin: 0">{{ supplier_code }}</p>
                                                <em >{{ supplier_name }}</em>
                                            </template>
                                        </v-select>
                                        <b-form-invalid-feedback id="input-code-supplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Code Supplier -->
                                <!-- <validation-provider
                                    name="code-supplier"
                                    :rules="{ required: true }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Code Supplier <code>*</code>
                                        </template>
                                        <b-form-select v-on:change="setSupplier()"  value-field="supplier_code" text-field="supplier_code" :options="codeSupplierOpt" id="input-code-supplier" v-model="form.codeSupplier"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                        <b-form-invalid-feedback id="input-code-supplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider> -->
                            <!-- Supplier Name -->
                                <validation-provider
                                    name="supplier-name"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Supplier Name <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-supplier-name" v-model="form.nameSupplier"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-supplier-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Supplier Email -->
                                <validation-provider
                                    name="supplier-email"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Supplier Email <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-supplier-email" v-model="form.emailSupplier"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-supplier-email">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Type -->
                                <validation-provider
                                    name="type"
                                    :rules="{ required: true }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Type <code>*</code>
                                        </template>
                                        <b-form-select value-field="value" text-field="value" :options="typeOpt"  id="input-type" v-model="form.typeSubcon"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                        <b-form-invalid-feedback id="input-type">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-row align-h="end">
                                    <b-col cols="3">
                                        <b-button block variant="success"  size="sm" type="submit">Save</b-button>
                                    </b-col>
                                    <b-col cols="3">
                                        <b-button block variant="warning" v-on:click="clearForm()"  size="sm">Cancel</b-button>
                                    </b-col>
                                </b-row>
                                
                                
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import apiSupplier from '@/store/product-form/product-supplier.js'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'vue-select/dist/vue-select.css';
export default {
    // props:['productId'],
    data(){
        return{
            productId : this.$route.query.productId,
            formName:'Supplier Selection Form',
            modulName:'Form',
            mainProps: {width: 100, class: 'm1' },
            isValid:false,
            show:false,
            form:{
                idSupplier:null,
                codeSupplier:null,
                nameSupplier:null,
                typeSubcon:null,
                user:null,

            },
            status:null,
            date:null,
        
            fieldsFabric:[
                'code','type','description',
                {key:'img_src',label:'images'},
                'used_as','detail',
                
            ],
            fieldsAcc:[
                'code','type','description',
                {key:'img_src',label:'images'},
                'used_as','detail',
                
            ],
            fieldsThread:[
                'code','description','used_as',
                {key:'img_src',label:'images'},
                'detail',
            ],
            fieldsArtwork:[
                'code','type','description',
                {key:'img_src',label:'images'},
                'used_as','detail',
            ],
            fieldsInterlining:[
                'code','type','description',
                {key:'img_src',label:'images'},
                'used_as','detail',
            ],
            fieldsApprove:[
                {key:'date',label:'Date'},
                'status','comment',
                {key:'created_by', label:'Approve By'}
            ],
            approveHistory:[],
            product:null,
            fabricData:[],
            accData:[],
            threadData:[],
            artworkData:[],
            interliningData:[],
            productImg:[],
            fieldsSupplier:[
                {key:'action',label:'#'},
                // {key:'matdev',label:'Material Dev'},
                'supplier_code','type_subcon','supplier_name','email_supplier',
                
            ],
            supplierData:[],
            codeSupplierOpt:[
                {value:'1', text:'Code 1'},
                {value:'2', text:'Code 2'},
                {value:'3', text:'Code 3'},
                {value:'4', text:'Code 4'},
            ],
            typeOpt:[
                {value:'FOB', text:'FOB'},
                {value:'CMT', text:'CMT'},
            ],
            myFilter: (option, label, search) => {
                let temp = search.toLowerCase();
                return option.supplier_code.toLowerCase().indexOf(temp) > -1 || 
                option.supplier_name.toLowerCase().indexOf(temp) > -1
            },
            // bad way role
            role:['Super User','MD'],
            rols:['Super User']
            
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_DETAIL','GET_PRODUCT_IMAGES','getProduct','getProductDetail','GET_PRODUCT_DASHBOARD','GET_PRODUCT_MATERIAL_DEV','POST_PRODUCT_APPROVE','GET_PRODUCT_APPROVE','POST_PRODUCT_PROCESS']),
        ...mapActions('session',['showNotification']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getAccountInfo() {
            let userData      = JSON.parse(localStorage.userData)
            this.$set(this.form, 'user', userData.fullname)

            // console.log('user data',this.form.user);
        },
        addSupplier(){
            this.show = true
        },
        onSubmit(){
            
            apiSupplier.dispatch('POST_SUPPLIER',{
                product_id: this.productId,
                supplier_id: this.form.idSupplier,
                supplier_name:this.form.nameSupplier,
                supplier_code:this.form.codeSupplier,
                type_subcon:this.form.typeSubcon,
                created_by:this.form.user
            }).then(response => {
                this.showNotification({type:'success', message:'Successfully'})
                this.saveProcess(this.productId)
                this.clearForm()
                this.getDataSupplier()
            }).catch(error => {
                return error.response.data.message
            })

        },
        autoComplete(){
            this.form.nameSupplier = 'PT.XYZ'
        },
        getDataProduct(){
            this.GET_PRODUCT_DETAIL({
                product_id:this.productId
            }).then(rsp =>{
                // let detailProduct = rsp.filter(x=>x.id === this.productId)
                let detailProduct = rsp.data.payload
                console.log(detailProduct);
                this.product = detailProduct
            })
            this.GET_PRODUCT_MATERIAL_DEV().then(response => {
                let productMaterial = response.data.payload.filter(pm => pm.product_id == this.productId && pm.product_material_status == 'Approved')
                // console.log('material data',productMaterial);
                this.fabricData         = productMaterial.filter(f=>f.category == 'fabric')
                this.accData            = productMaterial.filter(f=>f.category == 'accessories')
                this.threadData         = productMaterial.filter(f=>f.category == 'thread')
                this.artworkData        = productMaterial.filter(f=>f.category == 'artwork')
                this.interliningData    = productMaterial.filter(f=>f.category == 'interlining')
            })
        },
        getMasterSupplier(){
            apiSupplier.dispatch('GET_MASTER_SUPPLIER')
            .then(rsp=>{
                console.log(rsp);
                this.$set(this,'codeSupplierOpt',rsp)
            })
            .catch(err=>{
                return err
            })
        },
        getData(){
            this.productImg = []
            // this.getProduct()
                this.GET_PRODUCT_IMAGES({product_id:this.productId})
                .then(response =>{
                // let data = response.filter(x=>x.id === this.productId)
                let data = response.data.payload
                for (let i = 0; i < data.length; i++) {
                    // let img = {
                    //     img_id:data[i].design_id,
                    //     img_code:data[i].design_code,
                    //     img_name:data[i].design_name,
                    //     img_src:data[i].design_src,
                    //     img_label:data[i].design_label,

                    // }
                    let img = {
                        img_id:data[i].id,
                        img_code:data[i].img_code,
                        img_name:data[i].img_name,
                        img_src:data[i].img_src,
                        img_label:data[i].img_label,

                    }

                    this.productImg.push(img)
                    
                }
                
            })
            

        },
        getDataSupplier(){
            console.log('productID',this.productId);
            apiSupplier.dispatch('GET_SUPPLIER')
            .then(rsp =>{
                let dataSup = rsp.filter(x=>x.product_id == this.productId)
                console.log('rsp',rsp);
                this.supplierData = dataSup
            }).catch(err => {
                return err.response.data.message
            })
        },
        clearForm(){
            this.show = false
            this.form.nameSupplier = null
            this.form.codeSupplier = null
            this.form.typeSubcon = null
        },
        addMatDev(id){
            this.$router.push({
                name:'AppsMaterialDevPage',
                params:{productId:id}
            })
        },


        createPdf(tableId,tableName){
            let pdfName = tableName
            var doc = new jsPDF('l','pt','A4')

            doc.autoTable({
                html:tableId,
                bodyStyles:{
                    minCellHeight:50
                },
                columnStyles: { 3: { halign: 'center' } },
                theme:'grid',
                // bodyStyles: {minCellHeight: 15},
                // didDrawCell: function(data) {
                //     if (data.column.index === 3 && data.cell.section === 'body') {
                //         var td = data.cell.raw;
                //         var img = td.getElementsByTagName('img')[0];
                //         var dim = data.cell.height - data.cell.padding('vertical');
                //         var textPos = data.cell;
                //         console.log(data.cell);
                //         doc.addImage(img.src, textPos.x,  textPos.y, dim, dim);
                //     }
                // }
                
            })

            doc.output('dataurlnewwindow')
        },
        fabricPdf(tableId,tableName,button){
            let pdfName = tableName
            var doc = new jsPDF('p','pt','A4')

            let centerize = doc.internal.pageSize.getWidth()/2
            let tinggi = doc.internal.pageSize.getHeight()

            
            // var fabrics = this.fabricData.filter(x => x)
            // for (let i = 0; i < fabrics.length; i++) {
            //     var key = Object.keys(fabrics[i])
            //     var val = Object.values(fabrics[i])
            //     doc.text(key,10,40)
                
            //     // doc.text(val,100,40)
            //     if(fabrics.length > (i+1)){
            //         doc.addPage()
            //     }
                
            // }

            // const toDataURL = url => fetch(url)
            //     .then(response => response.blob())
            //     .then(blob => new Promise((resolve, reject) => {
            //         const reader = new FileReader()
            //         reader.onloadend = () => resolve(reader.result)
            //         reader.onerror = reject
            //         reader.readAsDataURL(blob)
            //     }))
              

            for (let i = 0; i < this.fabricData.length; i++) {
                
                doc.text(tableName,centerize,30,"center")
                
                doc.text('Code',10,50)
                doc.text('Brand',10,70)
                doc.text('Category',10,90)
                doc.text('Type',10,110)
                doc.text('Description',10,130)
                doc.text('Detail',10,150)
                doc.text('Used as',10,170)




                doc.text(': '+(this.fabricData[i].code||'-'),200,50)
                doc.text(': '+(this.fabricData[i].brand||'-'),200,70)
                doc.text(': '+(this.fabricData[i].category||'-'),200,90)
                doc.text(': '+(this.fabricData[i].type||'-'),200,110)
                doc.text(': '+(this.fabricData[i].description||'-'),200,130)
                doc.text(': '+(this.fabricData[i].detail||'-'),200,150)
                doc.text(': '+(this.fabricData[i].used_as||'-'),200,170)


                

                if(this.fabricData[i].category === 'fabric'){
                        doc.text('Yarn Size',10,190)
                        doc.text('Texture',10,210)
                        doc.text('Month',10,230)
                        doc.text('Year',10,250)
                        doc.text('Weight',10,270)
                        doc.text('Weaving Type',10,290)
                        doc.text('Composition',10,310)
                        doc.text('Construction',10,330)
                        doc.text('Code Handloom',10,350)

                        doc.text(': '+(this.fabricData[i].yarn_size||'-'),200,190)
                        doc.text(': '+(this.fabricData[i].texture||'-'),200,210)
                        doc.text(': '+(this.fabricData[i].month||'-'),200,230)
                        doc.text(': '+(this.fabricData[i].year||'-'),200,250)
                        doc.text(': '+(this.fabricData[i].weight||'-'),200,270)
                        doc.text(': '+(this.fabricData[i].weaving_type||'-'),200,290)
                        doc.text(': '+(this.fabricData[i].composition||'-'),200,310)
                        doc.text(': '+(this.fabricData[i].construction||'-'),200,330)
                        doc.text(': '+(this.fabricData[i].code_handloom||'-'),200,350)
                    }

                doc.addPage("a4","p")
                var widthFabric = doc.internal.pageSize.getWidth();
                var heightFabric = doc.internal.pageSize.getHeight();
                if(this.fabricData[i].img_src){
                    if(this.fabricData[i].img_src.includes('png')){
                        doc.addImage(this.fabricData[i].img_src,'png',0, 0,widthFabric,heightFabric)
                    }else{
                        doc.addImage(this.fabricData[i].img_src,'jpeg',0, 0,widthFabric,heightFabric)
                    }
                }
                if(this.fabricData.length > (i+1)){
                    doc.addPage()
                }

                
            }
            doc.setProperties({
                title: pdfName
            })
            if(button === 'preview'){
                window.open(doc.output('bloburl',pdfName))
            }else{
                doc.save(pdfName+'_'+this.product.article+'.pdf')
            }
            // doc.output('dataurlnewwindow')
            // doc.save(this.product.article+'-'+pdfName)
        },
        materialPdf(tableId,tableName,button){
            let dataId = tableId
            let pdfName = tableName
            var doc = new jsPDF('p','pt','A4')

            let centerize = doc.internal.pageSize.getWidth()/2
            let tinggi = doc.internal.pageSize.getHeight()

            let materialData = this[tableId]
            for (let i = 0; i < materialData.length; i++) {
                
                doc.text(tableName,centerize,30,"center")
                doc.text('Code',10,50)
                doc.text('Brand',10,70)
                doc.text('Category',10,90)
                doc.text('Type',10,110)
                doc.text('Description',10,130)
                doc.text('Detail',10,150)
                doc.text('Used as',10,170)
                doc.text('Color',10,190)

                
                doc.text(': '+(materialData[i].code||'-'),100,50)
                doc.text(': '+(materialData[i].brand||'-'),100,70)
                doc.text(': '+(materialData[i].category||'-'),100,90)
                doc.text(': '+(materialData[i].type||'-'),100,110)
                doc.text(': '+(materialData[i].description||'-'),100,130)
                doc.text(': '+(materialData[i].detail||'-'),100,150)
                doc.text(': '+(materialData[i].used_as||'-'),100,170)
                doc.text(': '+(materialData[i].color||'-'),100,190)
                
                
                doc.addPage("a4","p")
                var width = doc.internal.pageSize.getWidth();
                var height = doc.internal.pageSize.getHeight();
                if(materialData[i].img_src.includes('png')){
                    doc.addImage(materialData[i].img_src,'png',0, 0,width,height)
                }
                else{
                    doc.addImage(materialData[i].img_src,'jpeg',0, 0,width,height)
                }
                if(materialData.length > (i+1)){
                    doc.addPage()
                }
                
            }

            doc.setProperties({
                title: pdfName
            })
            if(button === 'preview'){
                window.open(doc.output('bloburl',pdfName))
            }else{
                doc.save(pdfName+'_'+this.product.article+'.pdf')
            }
            
            // doc.output('dataurlnewwindow')
            // doc.save('test.pdf')
            // doc.save(this.product.article+'-'+pdfName)
        },
        // toDataURL2(src, callback, outputFormat) {
        //     var img = new Image();
        //     img.crossOrigin = 'Anonymous';
        //     img.onload = function() {
        //         var canvas = document.createElement('CANVAS');
        //         var ctx = canvas.getContext('2d');
        //         var dataURL;
        //         canvas.height = this.naturalHeight;
        //         canvas.width = this.naturalWidth;
        //         ctx.drawImage(this, 0, 0);
        //         dataURL = canvas.toDataURL(outputFormat);
        //         callback(dataURL);
        //     };
        //     img.src = src;
        //     if (img.complete || img.complete === undefined) {
        //         img.src = "data:image/jpeg;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        //         img.src = src;
        //     }
        // },
        // toDataURL(url, callback) {
        //     var xhr = new XMLHttpRequest();
        //     xhr.onload = function() {
        //         var reader = new FileReader();
        //         reader.onloadend = function() {
        //         callback(reader.result);
        //         }
        //         reader.readAsDataURL(xhr.response);
        //     };
        //     xhr.open('GET', url);
        //     xhr.responseType = 'blob';
        //     xhr.send();
        // },
        saveProcess(prodId){
            let now = new Date()
            // let estDate = new Date()
            // let startDate = now.toLocaleDateString('id-ID',{ year: 'numeric', month: '2-digit', day: '2-digit' })
            
            let year = now.toLocaleString('id-ID', { year: 'numeric'})
            
            let month = now.toLocaleString('id-ID', { month: '2-digit'})
            
            let day = now.toLocaleString('id-ID', { day: '2-digit'})
            let startDate = year+'-'+month+'-'+day

            let estDate = new Date()
            
            


            // let startDate = estDate.getDate()

            if(this.product.duration === 'basic'){
                estDate.setDate(estDate.getDate()+7)
            }else if(this.product.duration === 'regular'){
                estDate.setDate(estDate.getDate()+49)
            }else{
                console.log('else');
                estDate.setDate(estDate.getDate()+27)
            }

            let year2 = estDate.toLocaleString('id-ID', { year: 'numeric'})
            
            let month2 = estDate.toLocaleString('id-ID', { month: '2-digit'})
            
            let day2 = estDate.toLocaleString('id-ID', { day: '2-digit'})
            let estimateDate = year2+'-'+month2+'-'+day2

            console.log('today',estimateDate);
            console.log('start',startDate);
            this.POST_PRODUCT_PROCESS({
                product_id:prodId,
                process:'Supplier Selection',
                task:this.userData.dept,
                start_date:startDate,
                estimate_date:estimateDate,
                status:'On Progress'
            }).then(rsp=>{
                console.log('Process Saved');
            }).catch(err=>{
                console.log(err);
            })
        },
        setSupplier(){
            let supplierId = this.codeSupplierOpt.filter(i=>i.supplier_code === this.form.codeSupplier)
            this.form.nameSupplier = supplierId[0].supplier_name
            this.form.idSupplier = supplierId[0].id
        }
        
    },
    computed:{
        ...mapGetters('productDesign',['product','MASTER_MATERIAL','PRODUCT_MATERIAL','PRODUCT_APPROVE']),
        ...mapGetters('session', ['userData','localDate', 'serverDate']),
        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        loadData(){
            return this.product.filter(x=>x.id === this.productId)
            // console.log('brand',brand);
            
        },
        
        
        
        
    },
    created(){
        this.getMasterSupplier()
        this.getData()
        this.getDataProduct()
        this.getDataSupplier()
        this.getAccountInfo()
    },
    mounted(){
    }
}
</script>
<style scoped>
</style>