import { getAPI } from './api-base'

const apiPath = {
    serverTime                   : '/core/api/server-time/',
    // cityList                     : '/core/api/city-list/',
    cityList                     : '/v1/api/city-list/',
}

export function fnApiFetchServerTime () {
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.serverTime)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

export function fnApiFetchCityList(param) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.cityList, param)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}