<template>
    <div class="table-basic inputAdjustment">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-approve-adjustment"> Dashboard Approve Adjustment</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Form Master Subcon" header-tag="header">
                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:500;'>Approve Adjustment</h3>
                                </div>
                            </div>
                        </template>
                        <b-row align-h="center">
                            <!-- INFO Request  -->
                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="IO / PO"
                                    label-for           ="inputIO">
                                    <b-form-input readonly id="inputIO" v-model="form.data_io"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="Type Request"
                                    label-for           ="inputRequest">
                                    <b-form-input readonly id="inputUser" v-model="request"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="User Request"
                                    label-for           ="inputUser">
                                    <b-form-input readonly id="inputUser" v-model="form.user_request"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col sm="auto" lg="3">
                                <b-form-group
                                    label               ="Date Request"
                                    label-for           ="inputDateRequest">
                                    <b-form-input readonly id="inputUser" v-model="form.date_request"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row align-h="center">
                            <b-col sm="auto" lg="12">
                                <b-form-group
                                    label               ="Keterangan Request"
                                    label-for           ="inputKeterangan">
                                    <b-form-input readonly id="inputKeterangan" v-model="form.keterangan_request"   trim></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card style='margin-top:20px;'>
                        <b-row align-h="start">
                            <b-col sm="auto" lg="8">
                            <!-- Approve  -->
                            <validation-provider
                                name="inputKeterangan"
                                vid="inputKeterangan">
                                <b-form-group
                                    label               ="Keterangan Approve or Reject"
                                    label-for           ="inputKeteranganApprove">
                                    <b-form-textarea
                                        id="textarea-state"
                                        v-model="form.approve"
                                        placeholder="Enter Reason Approve / Reject"
                                        no-resize
                                        rows="3"
                                    ></b-form-textarea>
                                    </b-form-group>
                            </validation-provider>
                            </b-col>
                        </b-row>
                        <b-row align-h="center">
                            <b-col sm="auto" lg="2">
                                <b-button block  @click="doApproval('Approve')" ref="submit" type="submit" variant="success">Approve</b-button>
                            </b-col>
                            <b-col sm="auto" lg="4">
                            </b-col>
                            <b-col sm="auto" lg="2">
                                <b-button block  @click="doApproval('Reject')" ref="submit" type="submit" variant="danger">Reject</b-button>
                            </b-col>
                            <b-col sm="auto" lg="4">
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import apiPPCM from '@/store/ppm-form/ppm.js';
    import { extend } from 'vee-validate';
    // import vue loading
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);

    export default {
       name: 'InputApprovalAdjustment',

        data() {
           const now = new Date()
            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

            // create hour:min:sec
            const time = now.toString().slice(16,24)
            // const opthour = { hour: '2-digit'};
            // const optmin = { minute: '2-digit'};
            // const optsec = { second: '2-digit'};
            // const hour = now.toLocaleString('id-ID', opthour ,{ hour12: false })
            // const min = now.toLocaleString('id-ID', optmin ,{ hour12: false })
            // const sec = now.toLocaleString('id-ID', optsec ,{ hour12: false })
            // const time = hour+':'+min+':'+sec

            return{
                opt_process: [
                ],
                formName: 'Input Approval Adjustment',
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                errorMessage: null,
                max_date:today,
                time:time,
                today:today,
                    form : {
                    code_request:null,
                    data_io:null,
                    user_request:null,
                    keterangan_request:null,
                    detail_request:null,
                    request:null,
                    date_request:null,

                    approve:null,
                    Employee:null,
                    date_approve:null,
                    status_approve:null,
                    keterangan_approve:null,
                    },
                request:null,
            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stAppAdjustment','stAppAdjustmentCount']),

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['GetDataAdjustment']),
            getValidationState({ dirty, validated, valid = null }) {
                return dirty || validated ? valid : null;
            },
            FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Setdata_IO() {
                let code_request      = this.$route.query.code_request
                let userData          = this.userData
                this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'code_request', code_request)
                if (code_request == null || code_request == '') {
                    this.$router.push({ name: 'DashboardApproveAdjustment'})
                }
                this.GetDataAdjustment(this.form.code_request)
                .then(rsp => {
                    this.GetdataForm()
                })
            },
            GetdataForm() {
                let formdata = this.stAppAdjustment
                this.form.data_io = formdata[0].io
                this.form.code_request = formdata[0].code_request
                this.form.user_request = formdata[0].user_request
                this.form.keterangan_request = formdata[0].keterangan_request
                this.form.detail_request = formdata[0].detail_request
                this.form.request = formdata[0].request
                this.form.date_request = formdata[0].date_request
                if (formdata[0].date_request != null) {
                     this.form.date_request = this.FormatDate(formdata[0].date_request)
                }
                if (formdata[0].request == 'reset_io') {
                    this.request = 'Reset IO'
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 1000);
                }
            },
            doApproval(choice) {
                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm '+choice,
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                    this.boxTwo = String(value)
                    if (this.boxTwo == 'true') {
                            this.form.status_approve = choice
                            this.Submit()
                    }
                })
                .catch(err => {
                    this.showNotification({type:'error', message:err})
                })

            },
            Submit() {
                this.form.date_approve = this.today+' '+this.time;
                this.Loading('start')
                apiPPCM.dispatch('PutCreateAdjustment', {
                        code_request:this.form.code_request,
                        io:this.form.data_io,
                        request:this.form.request,
                        detail_request:this.form.detail_request,
                        keterangan_request:this.form.keterangan_request,
                        user_approve:this.form.Employee,
                        status_approve:this.form.status_approve,
                        keterangan_approve:this.form.keterangan_approve,
                        date_approve:this.form.date_approve,
                    })
                    .then(response => {
                        this.Loading('stop')
                        this.$router.push({name:"DashboardApproveAdjustment"})
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                    })
                    .catch(err => {
                        this.Loading('stop')
                        console.log(err.response.data.message)
                        if (err.response === 400) {
                            console.log(err.response.data.message)
                            this.showNotification({type:'error', message:err.response.data.message})
                        }
                    })
                    .finally(()=>{
                        this.Loading('stop')
                        this.form.formData = []
                    })
            },
        },
        created() {
            this.Setdata_IO()
        },

    }
</script>
<style src="./InputAdjustment.scss" lang="scss" scoped />