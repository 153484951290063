<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
            <b-breadcrumb-item >{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container fluid>
            <b-card
                
            >
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h3>Product Design</h3>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <template v-if="roles">
                            <div class="text-right">
                                <b-button variant="success" size="sm" v-on:click="goToCreate()">Create</b-button>
                            </div>
                            </template>
                        </b-col>
                    </b-row>
                    
                </template>
                <ProductDesignList/>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import ProductDesignList from './ProductDesignList2'
import {mapActions,mapGetters} from 'vuex';
export default {
    components: { ProductDesignList },
    name:'ProductDesign2',
    data(){
        return {
            formName: 'Product Design2',
            modulName: 'List',
            form:null,
            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User']
            
        }
    },
    methods:{
        ...mapActions('productDesign',['RESET_STATE']),
        goToCreate(){
            this.RESET_STATE()
            
            this.$router.push({
                name:"AppsProductDesign2FormPage",
                params:{
                    modulCreate:'create'
                }
                
                })
        }
    },
    computed:{
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
            // return this.role.some(i=>this.rols.includes(i))
        },
    }

}
</script>