<template>
    <div>
        <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item >
            <router-link tag="a" to="/app/apps-product-design"> {{formName}}</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>

        <b-card header-tag="nav" no-body>
            <b-tabs 
                active-nav-item-class="font-weight-bold"
                active-tab-class="font-weight-bold"
                v-model="tabIndex" 
                content-class="mt-3">
                <b-tab active title="Form Product Design">
                    <FormInput/>
                </b-tab>
                <b-tab title="Accessories">
                    <FormAcc/>
                </b-tab>
                <b-tab title="Botton">
                    <FormBotton/>
                </b-tab>
                <b-tab title="Artwork">
                    <FormArtwork/>
                </b-tab>
                <template #tabs-end>
                    <b-nav-item href="#" role="presentation" v-on:click="showModal()"  >Submit</b-nav-item>
                </template>
            </b-tabs>
            
        </b-card>

        <b-modal
            v-model="show"
            hide-backdrop
            v-on:ok="submitForm()"
            ok-title="Submit"
            size="xl"
            ref="modal"
            scrollable 
            title="Product Design Detail">
            <SubmitView/>
        </b-modal>
        
        
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FormInput from './ProductDesignFormInput';
import FormAcc from './ProductDesignFormAcc';
import FormBotton from './ProductDesignFormBotton';
import FormArtwork from './ProductDesignFormArtwork';
import SubmitView from './ProductDesignSubmitView';
export default {
    components:{
        FormInput,
        FormAcc,
        FormBotton,
        FormArtwork,
        SubmitView,
    },
    name:'ProductDesignForm',
    
    data(){
        return{
            show: false,
            formName: 'Product Design',
            modulName: null,
            tabIndex:0,
            mounted:false,
            form:{
            },
        }
    },
    methods:{

        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                // this.form.imagesAcc.push(file[i])
                // this.imagesUrl = URL.createObjectURL(file[i])
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
            console.log(this.imagesUrl)
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        submitForm(){
            const data = {
                productDesign:this.product,
                productAcc:this.accList,
                productBotton:this.bottonList,
                productArtwork:this.artworkList
            }
            console.log(data)
            this.goToList()
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        showModal(){
            if (this.product != null){
                this.show = true
            }
        }
        
    },
    mounted(){
        if(this.key_id != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        

    },
    computed:{
    ...mapGetters('productDesign',['product','accList','bottonList','artworkList']),
        
    }
}
</script>