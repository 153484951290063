<template>
  <div class="tables-basic">
    <div>
      <router-link to="wbs">
        <div class="floating-container">
          <div class="floating-button">
            <br />
            <b>#Berani Lapor</b>
          </div>
        </div></router-link
      >
    </div>
    <b-breadcrumb>
      <b-breadcrumb-item
        ><span class="fontbbi space2 text-secondary"
          >YOU ARE HERE</span
        ></b-breadcrumb-item
      >
      <b-breadcrumb-item active
        ><span class="fontbbi space2">Reports</span></b-breadcrumb-item
      >
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold fontbbi space3">Reports</span>
    </h2>
    <b-row v-for="(mainData, group) in listRpt" :key="group">
      <b-col>
        <Widget v-bind:title="populateGroupTitle(group)" customHeader>
          <div class="table-resposive">
            <table class="table">
              <tbody>
                <tr align="left">
                  <td
                    align="center"
                    v-for="(row, index) in mainData"
                    :key="index"
                    class="div-menu"
                  >

                      <a
                      class="fontbbi space1 text-inverse"
                      v-if="row.enabled"
                      :href="replaceRptUrl(row.rpt_url)"
                      :target="chkUrlTarget(row.target)"
                    >
                        <div class="div-fill-all">
                          <img
                            class="img-rounded"
                            height="50"
                            :src="row.img_url"
                          />
                          <br />
                          {{ row.name }}
                        </div>
                      </a>

                    <!-- <a v-if="row.enabled" :click="setActiveReport(row.name)">
                    </a> -->
                    <div v-else>
                      <img
                        class="img-rounded img-grayed"
                        height="50"
                        :src="row.img_url"
                      />
                      <br />
                      {{ row.name }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Widget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import { fnPOST, fnPOSTCore } from "../../api/api-methods";
import { APIPATH_BBIAPPS } from "../../api/api-paths";

export default {
  name: "Reports",
  components: { Widget },
  data() {
    return {
      listRpt: null,
    };
  },
  computed: {
    ...mapState("report", ["stRptAccess"]),
    ...mapGetters("session", ["userData"]),
  },
  methods: {
    ...mapActions("report", [
      "clearState",
      "getReportAccess",
      "setActiveReport",
    ]),
    populateGroupTitle(title) {
      this.curGroup = title;
      return "<h5><span class='fw-semi-bold'>" + title + "</span></h5>";
    },
    replaceRptUrl(formUrl) {
      let url = formUrl
      let chkUrlString = formUrl.substring(0, 3)
      let curlUrl = new URL(window.location.href)
      if (chkUrlString == '/#/') {
        return curlUrl.origin + url;
      } else {
        return url
      }
    },
    chkUrlTarget(formUrl) {
      // console.log(formUrl)
      if (formUrl == "NEW_TAB" || formUrl == 'NEW_WINDOW') {
        return "_blank";
      } else {
        return "_self";
      }
    },
    processRptPayload(payload) {
      let items = payload;
      let lookup = {};
      let result = [];
      if (items) {
        for (let i = 0; i < items.length; i++) {
          let tempRptList = items[i];
          let name = tempRptList.group;
          if (!(name in lookup)) {
            lookup[name] = 1;
            result.push(name);
          }
        }
      }
      let newData = this.groupBy(
        payload,
        "group"
      );
      this.$set(this, "listRpt", newData);
      // console.log(newData)
    },
    groupBy(data, key) {
      const result = {};
      let jsonArr = data;
      let array = [];

      for (let i = 0; i < jsonArr.length; i++) {
        let tempRpt = jsonArr[i];

        array.push(tempRpt);
      }
      array.forEach((item) => {
        if (item["enabled"] == true) {
          if (!result[item[key]]) {
            result[item[key]] = [];
          }
          result[item[key]].push(item);
        }
      });
      return result;
    },
  },
  created() {
    let usrBU = this.userData.business_unit;
    let dept = this.userData.deptname;
    let subdept = this.userData.subdeptname;
    let role = this.userData.role;
    let roles_rpt = this.userData.roles_rpt;

    this.clearState();
    // if(usrBU == 'BBI1' || usrBU == 'BBI') {
      fnPOSTCore(APIPATH_BBIAPPS.rptRoleList, {
        roles_rpt: roles_rpt,
      }).then((rsp) => {
        this.processRptPayload(rsp.data.payload.rpt_list)
      });
    // }
    // else {
      // this.getReportAccess({
      //   businessunit: usrBU,
      //   role: role,
      //   dept: dept,
      //   subdept: subdept,
      // });
    //   fnPOSTCore(APIPATH_BBIAPPS.rptAccessSmg, {
    //     businessunit: usrBU,
    //     role: role,
    //     dept: dept,
    //     subdept: subdept,
    //     roles_rpt: roles_rpt,
    //   }).then((rsp) => {
    //     this.processRptPayload(rsp.data.payload.rpt_list)
    //   });
    // }
  },
};
</script>

<style src="./Reports.scss" lang="scss" scoped />
