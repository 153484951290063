<template>
  <div>
    <div>
      <router-link to="wbs">
        <div class="floating-container">
          <div class="floating-button">
            <br />
            <b>#Berani Lapor</b>
          </div>
        </div></router-link
      >
    </div>

    <b-breadcrumb>
      <b-breadcrumb-item
        ><span class="fontbbi space2 text-secondary"
          >YOU ARE HERE</span
        ></b-breadcrumb-item
      >
      <b-breadcrumb-item active
        ><span class="fontbbi space2">Dashboard</span></b-breadcrumb-item
      >
    </b-breadcrumb>
    <h1 class="page-title fontbbi space3">Dashboard</h1>
    <b-row>
      <b-col
        lg="3"
        sm="6"
        xs="12"
        v-for="(row, index) in notifications"
        :key="index"
      >
        <Widget
          v-bind:title="populateNotifWidget(index, row.category)"
          bodyClass="p-0 mt"
          customHeader
        >
          <h4 class="fw-semi-bold ml-lg mb-lg">{{ row.counter }}</h4>
        </Widget>
      </b-col>
    </b-row>

    <b-row class="row-main-wf">
      <b-col md="4" class="card-wf">
        <cards
          title="Inbox"
          :count="this.countWF['inbox']"
          :isLoading="this.isLoading"
          icon="flaticon-download"
          last_updated="PLM010102"
          url="workflow-inbox"
          iconColor="#ff9500"
        />
      </b-col>
      <b-col md="4" class="card-wf">
        <cards
          title="Outbox"
          :count="this.countWF['outbox']"
          :isLoading="this.isLoading"
          icon="flaticon-send"
          last_updated="PLM010101"
          url="workflow-outbox"
          iconColor="#337def"
        />
      </b-col>
      <b-col md="4" class="card-wf">
        <cards
          title="Closed"
          :count="this.countWF['closed']"
          :isLoading="this.isLoading"
          icon="flaticon-checked-1"
          url="workflow-close"
          iconColor="#6fc27c"
        />
      </b-col>
    </b-row>

    <!-- <div class="col-lg-4 col-sm-3 col-3">
        <div class="card">
          <div class="card-content">
            <div class="card-body">
              <div class="media d-flex">
                <div class="align-self-center">
                  <i class="fi flaticon-notepad-2" style="font-size: 50px;"></i>
                </div>
                <div class="media-body text-right">
                  <h3>New Post</h3>
                  <h4>278</h4>
                </div>
              </div>
              <hr/>
                <p class="h6"><i class="fa fa-refresh"></i> Last Updated:</p>
            </div>
          </div>
        </div>
      </div> -->

    <b-row>
      <b-col xs="12">
        <!-- Image 1 -->
        <div class="box">
          <div class="layer">
            <b-img
              :src="srcImg1"
              fluid
              alt="Responsive image"
              class="img-1"
              @click="openImage1()"
            ></b-img>
          </div>
          <div class="layer">
            <b-img
              :src="srcImg1"
              fluid
              alt="Responsive image"
              class="img-1"
              @click="openImage1()"
            ></b-img>
          </div>
          <div class="layer">
            <b-img
              :src="srcImg1"
              fluid
              alt="Responsive image"
              class="img-1"
              @click="openImage1()"
            ></b-img>
          </div>
        </div>
        <!-- Image 1 -->

        <!-- Image 2 -->
        <div class="box">
          <div class="layer">
            <b-img
              :src="srcImg2"
              fluid
              alt="Responsive image"
              @click="openImage2()"
            ></b-img>
          </div>
          <div class="layer">
            <b-img
              :src="srcImg2"
              fluid
              alt="Responsive image"
              @click="openImage2()"
            ></b-img>
          </div>
          <div class="layer">
            <b-img
              :src="srcImg2"
              fluid
              alt="Responsive image"
              @click="openImage2()"
            ></b-img>
          </div>
        </div>
        <!-- Image 2 -->
        <!-- <Widget
          title="<h5><span class='fw-semi-bold'>BBI News</span></h5>"
          bodyClass="widget-table-overflow"
          customHeader
        >
          <div class="table-responsive">
            <table class="table table-lg mb-0">
              <thead>
                <tr class="text-muted">
                  <th>DATE</th>
                  <th>CATEGORY</th>
                  <th>POSTED BY</th>
                  <th>INFORMATION</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="row in table"
                  :key="row.id"
                >
                  <td>{{row.posted_date}}</td>
                  <td>{{row.category}}</td>
                  <td>{{row.org_creator}}</td>
                  <td><a v-bind:href="populateInfoLink(row.title)">{{row.title}}</a></td>
                </tr>
              </tbody>
            </table>
          </div>
        </Widget> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Cards from "@/components/Cards/cards";
import Widget from "@/components/Widget/Widget";
import { APIPATH_BBIAPPS } from "@/api/api-paths";
import { APIPATH_WF } from "@/api/api-paths";
import { fnGET, fnPOST, fnPOSTWF } from "@/api/api-methods";
import { APIUrl } from "../../api/api-base";

export default {
  name: "Dashboard",
  components: { Widget, Cards },
  data() {
    return {
      table: null,
      notifications: null,
      accessTokenData: window.localStorage.getItem("access_token"),
      srcImg1: APIUrl + "/media/additional/kebijakan-mutu-k3-lingkungan.jpeg",
      srcImg2: APIUrl + "/media/additional/quality-health-safety-env-policy.jpeg",
      countWF:[],
      isLoading: false,
    };
  },
  methods: {
    populateNotifWidget(idx, title) {
      let customStyle = "";
      if (idx % 3 === 1) {
        customStyle = "bg-primary";
      } else if (idx % 3 === 2) {
        customStyle = "bg-warning";
      } else {
        customStyle = "bg-success";
      }
      return (
        "<h5 class='d-flex align-items-center pb-1'> <span class='circle " +
        customStyle +
        " mr-sm' style='font-size: 6px;'></span> <span class='fw-normal ml-xs'>" +
        title +
        "</span></h4>"
      );
    },
    // populateInfoLink(path) {
    //   let baseURL = "https://www.google.com/search?q=";
    //   return baseURL + path;
    // },
    openImage1() {
      window.open(this.srcImg1, "_blank");
    },
    openImage2() {
      window.open(this.srcImg2, "_blank");
    },
    getCountWF() {
      this.isLoading = true;
      fnPOSTWF(APIPATH_WF.countwf, {
        data_token: this.accessTokenData,
      })
        .then((response) => {
          if (response.data.status === 200) {
            this.countWF = response.data.payload;
          }
          // console.log("data_log", this.countWF['outbox']);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = true;
          console.log("error_log", error);
        });
    },
  },
  mounted: function () {
    fnGET(APIPATH_BBIAPPS.refreshTokenV2);
    this.getCountWF();
  },
};
</script>

<style src="./Dashboard.scss" lang="scss" scoped />
<style>
/* @import url("https://fonts.googleapis.com/css?family=Roboto");

    @-webkit-keyframes come-in {
        0% {
            -webkit-transform: translatey(100px);
            transform: translatey(100px);
            opacity: 0;
        }

        30% {
            -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
        }

        70% {
            -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
        }

        100% {
            -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
            opacity: 1;
        }
    }

    @keyframes come-in {
        0% {
            -webkit-transform: translatey(100px);
            transform: translatey(100px);
            opacity: 0;
        }

        30% {
            -webkit-transform: translateX(-50px) scale(0.4);
            transform: translateX(-50px) scale(0.4);
        }

        70% {
            -webkit-transform: translateX(0px) scale(1.2);
            transform: translateX(0px) scale(1.2);
        }

        100% {
            -webkit-transform: translatey(0px) scale(1);
            transform: translatey(0px) scale(1);
            opacity: 1;
        }
    } */

.floating-container {
  position: fixed;
  width: 100px;
  height: 100px;
  bottom: 0;
  right: 0;
  margin: 35px 25px;
  z-index: 1000;
}

.floating-container:hover {
  height: 300px;
}

.floating-container:hover .floating-button {
  box-shadow: 0 10px 25px rgba(240, 44, 44, 0.6);
  -webkit-transform: translatey(5px);
  transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.floating-container .floating-button {
  position: absolute;
  width: 110px;
  height: 110px;
  background: #b80e0e;
  bottom: 0;
  border-radius: 10px;
  left: 0;
  right: 0;
  margin: auto;
  font-weight: bolder;
  color: white;
  line-height: 28px;
  text-align: center;
  font-size: 22px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(240, 44, 44, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
</style>