<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-approval"> Dashboard Approval</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Form Master Subcon" header-tag="header">
                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Input Form Approval</h3>
                                </div>
                            </div>
                        </template>

                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <b-row align-h="center">
                        <!-- data IO -->
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="IO / PO"
                                                label-for           ="inputIO">
                                                <b-form-input readonly id="inputIO" v-model="form.data_io"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Employee"
                                                label-for           ="inputEmployee">
                                                <b-form-input readonly id="inputEmployee" v-model="form.Employee"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Status"
                                                label-for           ="inputStatus">
                                                <b-form-input readonly id="inputStatus" v-model="form.status"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                         <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Brand"
                                                label-for           ="inputBrand">
                                                <b-form-input readonly id="inputBrand" v-model="form.brand"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                </b-row>

                            <transition-group name="fade">

                                <b-row v-bind:key=1 v-if="!ReadMore()" align-h="center">
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Artikel"
                                                label-for           ="inputArtikel">
                                                <b-form-input readonly id="inputArtikel" v-model="form.artikel"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Sample"
                                                label-for           ="inputSample">
                                                <b-form-input readonly id="inputSample" v-model="form.sample"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                       <b-col sm="auto">
                                            <b-form-group
                                                label               ="Sub Brand"
                                                label-for           ="inputSubBrand">
                                                <b-form-input readonly id="inputSubBrand" v-model="form.subbrand"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <validation-provider
                                            name="manufacture_date"
                                        >
                                            <b-form-group>
                                                <label for="manufacture_date">Manufacture Date</label>
                                                <b-input-group class="sm-3" aria-describedby= "input-manufacture_date-feedback">
                                                    <b-form-input
                                                        id="manufacture_date"
                                                        v-model="manufacture_date"
                                                        type="text"
                                                        autocomplete="off"

                                                        readonly
                                                    ></b-form-input>
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>
                                        </b-col>
                                </b-row>
                                <b-row v-bind:key=2 v-if="!ReadMore()" align-h="center">

                                       <b-col sm="auto">
                                            <b-form-group
                                                label               ="Style"
                                                label-for           ="inputStyle">
                                                <b-form-input readonly id="inputStyle" v-model="form.style"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Texture"
                                                label-for           ="inputTexture">
                                                <b-form-input readonly id="inputTexture" v-model="form.texture"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Material"
                                                label-for           ="inputMaterial">
                                                <b-form-input readonly id="inputMaterial" v-model="form.material"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <validation-provider
                                            name="Due Date"
                                        >
                                            <b-form-group
                                                label               ="Due Date"
                                                label-for           ="inputDue_date">
                                                <b-form-input readonly id="inputDue_date" v-model="due_date"   trim></b-form-input>
                                            </b-form-group>
                                        </validation-provider>
                                        </b-col>
                                </b-row>
                                <b-row v-bind:key=3 v-if="!ReadMore()" align-h="center">
                                        <b-col sm="auto" lg="4">
                                            <b-form-group
                                                label               ="Description"
                                                label-for           ="inputDescription">
                                                <b-form-input readonly id="inputDescription" v-model="form.description"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                        <b-col sm="auto" lg="5">
                                            <b-form-group
                                                label               ="Qty Order"
                                                label-for           ="inputQty_Order">
                                                <b-form-input readonly id="inputQty_Order" v-model="form.qty_order"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Total Qty"
                                                label-for           ="inputTotal_Qty">
                                                <b-form-input readonly id="inputTotal_Qty" v-model="form.total_qty"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                </b-row>
                            <!-- END data IO -->
                            </transition-group>
                            <b-row align-h="center">
                                <!-- Button Show Data -->
                                <b-form-checkbox
                                    v-model= "readmore"
                                    name= "checkbox_readmore"
                                    ref='checkbox_readmore'
                                    value="yes"
                                    aria-describedby="input-checkbox_readmore-feedback"
                                    >Show Data
                                </b-form-checkbox>
                                <!-- END Button Show Data -->
                            </b-row>
                                <b-row align-h="center">
                                    <!-- Upload Document-IO -->
                                    <b-col sm="auto" lg="2">
                                    </b-col>
                                        <b-col style="padding-top:20px; padding-bottom:20px;" sm="auto" lg="4">
                                        <!-- <b-col v-if="url_image == null" sm="auto" lg="8"> -->
                                         <validation-provider
                                                name="ImageIO"
                                                vid="ImageIO"
                                                >
                                            <b-form-group

                                                label               ="Product Image : "
                                                label-for           ="InputImageIO">
                                                <div class="custom-file mb-3" style="display:inline;">
                                                    <!-- <label class="custom-file-label">{{image}}</label> -->
                                                    <img style="margin-left:-15px" v-if="image_io !== null" :src="url_data+'media/ppm/image/'+image_io">
                                                </div>
                                            </b-form-group>
                                            </validation-provider>
                                        </b-col>
                                    <b-col sm="auto" lg="2">
                                    </b-col>
                                        <b-col style="padding-top:20px; padding-bottom:20px;" sm="auto" lg="4">
                                        <!-- <b-col v-if="url_file == null" sm="auto" lg="8"> -->
                                        <validation-provider
                                                name="DocumentIO"
                                                vid="DocumentIO"
                                                >

                                            <b-form-group
                                                label               ="Document IO : "
                                                label-for           ="InputDocumentIO">
                                                <div  class="custom-file mb-3">
                                                    <!-- <label class="custom-file-label">{{file}}</label> -->
                                                     <a v-if="document_io !== null" :href="url_data+'media/ppm/document/'+document_io" target="_blank">{{document_io}}</a>
                                                </div>
                                            </b-form-group>
                                        </validation-provider>
                                        </b-col>



                                        <!-- <b-col v-if="url_file !== null" sm="auto" lg="6">
                                        <validation-provider
                                                name="DocumentIO"
                                                vid="DocumentIO"
                                                >
                                            <b-col sm="12" lg="12" style="margin:0 auto 0 auto; text-align:center;">
                                                <b-form-group
                                                label               ="Document IO :"
                                                label-for           ="InputDocumentIO">

                                                   <span>{{file}}</span><br />
                                                <button>
                                                <label for="document_io">Change File</label>
                                                </button>
                                                <input type="file" class="custom-file-input" id="document_io" ref="document_io" accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .pdf" v-on:change="onChangeFileUpload()"/>

                                            </b-form-group>
                                            </b-col>
                                        </validation-provider>
                                        </b-col> -->





                                        <!-- <b-col v-if="url_image !== null" sm="auto" lg="6">
                                        <validation-provider
                                                name="DocumentIO"
                                                vid="DocumentIO"
                                                >
                                            <b-col sm="12" lg="12" style="margin:0 auto 0 auto; text-align:center;">
                                                <b-form-group
                                                label               ="Image IO :"
                                                label-for           ="InputImageIO">

                                                   <img style="width:100px; height:auto;" :src="'media/'+url_image" /><br />
                                                <button>
                                                <label for="image_io">Change File</label>
                                                </button>
                                                <input type="file" class="custom-file-input" id="image_io" ref="image_io" accept="image/*" v-on:change="onChangeImageUpload()"/>

                                            </b-form-group>
                                            </b-col>
                                        </validation-provider>
                                        </b-col>    -->
                                    <!-- END Upload Document-IO -->

                                    <!-- Package Size -->
                                        <!-- <b-col sm="auto" lg="4">
                                            <b-form-group
                                                label               ="Package Size"
                                                label-for           ="inputPackage_Size">
                                                <b-form-select v-model="form.package_size" :options="options"></b-form-select>
                                                </b-form-group>
                                        </b-col> -->
                                    <!-- END Package Size -->
                                </b-row>

                                <b-row align-h="center">
                                    <b-col sm="auto" lg="4">
                                    <!-- Date Marker -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="marker"
                                                vid="marker"
                                                :rules="{required:true}"
                                                v-slot="validationContext">

                                                <b-form-group
                                                label               ="Marker"
                                                label-for           ="inputMarker">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.marker"
                                                        no-header
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        disabled
                                                        />
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-marker-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                </validation-provider>
                                      </b-col>
                                      <!-- END Date Marker -->

                                      <!-- Date ACC -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="acc"
                                                vid="acc"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="ACC"
                                                label-for           ="inputACC">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.acc"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        disabled
                                                        />
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-acc-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                </validation-provider>
                                      </b-col>
                                      <!-- END Date ACC -->
                                    </b-col>
                                    <b-col sm="auto" lg="4">

                                      <!-- Date Fabric -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="fabric"
                                                vid="fabric"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Fabric"
                                                label-for           ="inputFabric">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.fabric"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        disabled
                                                        />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-fabric-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                            </validation-provider>
                                      </b-col>
                                      <!-- END Date Fabric -->

                                      <!-- Date Fabric -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="fabric_inspection"
                                                vid="fabric_inspection"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Fabric_Inspection"
                                                label-for           ="inputFabric_Inspection">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.fabric_inspection"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        disabled
                                                        />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-fabric-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                            </validation-provider>
                                      </b-col>
                                      <!-- END Date Fabric -->
                                    </b-col>

                                      <b-col sm="auto" lg="4">

                                      <!-- Date PPM -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="ppm"
                                                vid="ppm"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="PPM"
                                                label-for           ="inputPPM">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.ppm"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        disabled
                                                        />
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-fabric-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                </validation-provider>
                                      </b-col>
                                      <!-- END Date PPM -->
                                      </b-col>
                                </b-row>

                                <b-row align-h="center">
                                    <!-- Radio Button Basic -->
                                    <!-- <b-col sm="auto">
                                            <validation-provider
                                                name="basic"
                                                vid="basic"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Basic"
                                                label-for           ="inputBasic">
                                                    <b-form-radio-group
                                                        class="padding"
                                                        v-model="form.basic"
                                                        name="basic"
                                                        ref="opt_basic"
                                                        aria-describedby="input-basic-feedback"
                                                        @change="CheckBasic">

                                                        <b-radio class='radio' value='yes'>Yes</b-radio>
                                                        <b-radio value='no'>No</b-radio>
                                                    </b-form-radio-group>
                                                    <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-basic-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                        </validation-provider>
                                        </b-col> -->
                                        <!-- END Radio Button Basic -->

                                        <!-- Radio Button Addtional -->
                                        <b-col sm="auto">
                                            <validation-provider
                                                name="addtional"
                                                vid="addtional"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Addtional"
                                                label-for           ="inputAddtional">
                                                    <b-form-radio-group
                                                        class="padding"
                                                        v-model="form.addtional"
                                                        name="addtional"
                                                        ref="opt_addtional"
                                                        disabled
                                                        aria-describedby="input-addtional-feedback"
                                                       >

                                                        <b-radio class='radio' value='yes'>Yes</b-radio>
                                                        <b-radio value='no'>No</b-radio>
                                                    </b-form-radio-group>
                                                    <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-addtional-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                        </validation-provider>
                                        </b-col>
                                        <!-- Radio Button Addtional -->
                                </b-row>

                                <b-row align-h="center">
                                    <b-col sm="auto" md="8">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                            <tr style="text-align:center;" class="text-muted">
                                                <th>Choose</th>
                                                <th>Process</th>
                                                <th>Subcon</th>
                                                <th>Target/Day</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        rules="required"
                                                        v-slot="validationContext"
                                                        name="checkbox_cutting">
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_cutting"
                                                                name= "checkbox_cutting"
                                                                ref='opt_checkbox_cutting'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_cutting-feedback"
                                                                :click="CheckboxCutting()">
                                                            </b-form-checkbox>
                                                            <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_cutting-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                        </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id="fieldset_horizontal"
                                                            label="Cutting"
                                                            label-for="input-Cutting"
                                                            >
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="select_cutting"
                                                        :rules="{required:!CheckboxCutting()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.cutting_id"  ref="opt_cutting" placeholder="Choose Department Code" style="top:1px;">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id"  :selected="form.cutting_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_cutting-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                        <validation-provider
                                                        name="target_cutting"
                                                        :rules="{required:!CheckboxCutting()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_cut >= 1" id="target_cutting" v-model="form.cutting_target"   trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_cutting-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>
                                                <tr style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        rules="required"
                                                        v-slot="validationContext"
                                                        name="checkbox_sewing">
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_sewing"
                                                                name= "checkbox_sewing"
                                                                ref='checkbox_sewing'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_sewing-feedback"
                                                                :click="CheckboxSewing()">
                                                            </b-form-checkbox>
                                                            <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-checkbox_sewing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                       </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id                  ="fieldset_horizontal"
                                                            label               ="Sewing"
                                                            label-for           ="InputSewing">
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="select_sewing"
                                                        :rules="{required:!CheckboxSewing()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.sewing_id" ref="opt_sewing"  placeholder="Choose Department Code">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.sewing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_sewing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="target_sewing"
                                                        :rules="{required:!CheckboxSewing()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_sew >= 1" id="target_sewing" v-model="form.sewing_target"   trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_sewing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>
                                                <tr style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        rules="required"
                                                        v-slot="validationContext"
                                                        name="checkbox_finishing">
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_finishing"
                                                                name= "checkbox_finishing"
                                                                ref='checkbox_finishing'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_finishing-feedback"
                                                                :click="CheckboxFinishing()">
                                                            </b-form-checkbox>
                                                            <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-checkbox_finishing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                       </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id                  ="fieldset_horizontal"
                                                            label               ="Finishing"
                                                            label-for           ="InputFinishing">
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="select_finishing"
                                                        :rules="{required:!CheckboxFinishing()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.finishing_id"  ref="opt_finishing" placeholder="Choose Department Code">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.finishing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_finishing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                        <validation-provider
                                                        name="target_finishing"
                                                        :rules="{required:!CheckboxFinishing()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_fin >= 1" id="target_finishing" v-model="form.finishing_target"   trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_finishing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>

                                                <tr style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        rules="required"
                                                        v-slot="validationContext"
                                                        name="checkbox_packing">
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_packing"
                                                                name= "checkbox_packing"
                                                                ref='checkbox_packing'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_packing-feedback"
                                                                :click="CheckboxPacking()">
                                                            </b-form-checkbox>
                                                            <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-checkbox_packing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                       </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id                  ="fieldset_horizontal"
                                                            label               ="Packing"
                                                            label-for           ="InputPacking">
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="select_packing"
                                                        :rules="{required:!CheckboxPacking()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.packing_id"  ref="opt_packing" placeholder="">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.packing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_packing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                        <validation-provider
                                                        name="target_packing"
                                                        :rules="{required:!CheckboxPacking()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_pack >= 1" id="target_packing" v-model="form.packing_target"   trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_packing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>
                                                <tr v-bind:class="[isActive ? 'fadein' : 'fadeout']" style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        name="checkbox_embroidery"
                                                        >
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_embroidery"
                                                                name= "checkbox_embroidery"
                                                                ref='opt_checkbox_embroidery'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_embroidery-feedback"
                                                                :click="CheckboxEmbroidery()">
                                                            </b-form-checkbox>
                                                        </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id                  ="fieldset_horizontal"
                                                            label               ="Embroidery"
                                                            label-for           ="InputEmbroidery">
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                        <validation-provider
                                                        name="select_embroidery"
                                                        :rules="{required:!CheckboxEmbroidery()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.embroidery_id"  ref="opt_embroidery" placeholder="Choose Department Code">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.embroidery_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_embroidery-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                        <validation-provider
                                                        name="target_embroidery"
                                                        :rules="{required:!CheckboxEmbroidery()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_embr >= 1" id="target_embroidery" v-model="form.embroidery_target"  trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_embroidery-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>

                                                <tr v-bind:class="[isActive ? 'fadein' : 'fadeout']" style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        name="checkbox_printing">
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_printing"
                                                                name= "checkbox_printing"
                                                                ref='checkbox_printing'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_printing-feedback"
                                                                :click="CheckboxPrinting()">
                                                            </b-form-checkbox>
                                                        </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id                  ="fieldset_horizontal"
                                                            label               ="Printing"
                                                            label-for           ="InputPrinting">
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="select_printing"
                                                        :rules="{required:!CheckboxPrinting()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.printing_id" ref="opt_printing"  placeholder="Choose Department Code">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.printing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_printing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="target_printing"
                                                        :rules="{required:!CheckboxPrinting()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_pri >= 1" id="target_printing" v-model="form.printing_target"   trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_printing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>

                                                <tr v-bind:class="[isActive ? 'fadein' : 'fadeout']" style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        name="checkbox_emblem">
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_emblem"
                                                                name= "checkbox_emblem"
                                                                ref='checkbox_emblem'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_emblem-feedback"
                                                                :click="CheckboxEmblem()">
                                                            </b-form-checkbox>
                                                       </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id                  ="fieldset_horizontal"
                                                            label               ="Emblem"
                                                            label-for           ="InputEmblem">
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="select_emblem"
                                                        :rules="{required:!CheckboxEmblem()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.emblem_id" ref="opt_emblem"  placeholder="Choose Department Code">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.emblem_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_emblem-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="target_emblem"
                                                        :rules="{required:!CheckboxEmblem()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_embl >= 1" id="target_emblem" v-model="form.emblem_target"   trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_emblem-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>
                                                <tr v-bind:class="[isActive ? 'fadein' : 'fadeout']" style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        name="checkbox_washing">
                                                            <b-form-checkbox
                                                                v-model= "form.checkbox_washing"
                                                                name= "checkbox_washing"
                                                                ref='checkbox_washing'
                                                                value="yes"
                                                                stacked
                                                                disabled
                                                                aria-describedby="input-checkbox_washing-feedback"
                                                                :click="CheckboxWashing()">
                                                            </b-form-checkbox>
                                                       </validation-provider>
                                                    </td>
                                                    <td>
                                                        <b-form-group
                                                            id                  ="fieldset_horizontal"
                                                            label               ="Washing"
                                                            label-for           ="InputWashing">
                                                        </b-form-group>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="select_washing("
                                                        :rules="{required:!CheckboxWashing()}"
                                                        v-slot="validationContext">
                                                        <b-form-select :disabled="true" class="form-custom" v-model="form.washing_id" ref="opt_washing"  placeholder="Choose Department Code">
                                                        <!-- option value none -->
                                                        <option :value="null">-- Choose Subcon --</option>
                                                        <!-- end option value none -->
                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.washing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-select_washing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                    <td>
                                                    <validation-provider
                                                        name="target_washing"
                                                        :rules="{required:!CheckboxWashing()}"
                                                        disabled
                                                        v-slot="validationContext">
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" :disabled="cat_id_wash >= 1" id="target_washing" v-model="form.washing_target"   trim></b-form-input>
                                                        <b-form-invalid-feedback :state="getValidationState(validationContext)"  id="input-target_washing-feedback">{{ validationContext.errors[0] }} </b-form-invalid-feedback>
                                                    </validation-provider>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    </b-col>
                                </b-row>
                                <b-row align-h="end" style='padding-top:20px !important;'>
                                    <b-col sm="2">
                                        <b-button block  :disabled="inputDisable" @click="doApproval()" ref="save" type="submit" variant="success">Approve</b-button>
                                    </b-col>
                                    <b-col sm="2">
                                        <b-button block  :disabled="inputDisable" @click="doReject()" ref="save" type="submit" variant="danger">Reject</b-button>
                                    </b-col>
                                </b-row>
                            </form>
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import apiapprovePPCM from '@/store/ppm-form/ppm.js';
    // import vue loading
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);
    import axios from 'axios'

    export default {
       name: 'InputApproval',

        data() {
            const now = new Date()

            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

            // create hour:min:sec
            const time = now.toString().slice(16,24)
            // const opthour = { hour: '2-digit'};
            // const optmin = { minute: '2-digit'};
            // const optsec = { second: '2-digit'};
            // const hour = now.toLocaleString('id-ID', opthour ,{ hour12: false })
            // const min = now.toLocaleString('id-ID', optmin ,{ hour12: false })
            // const sec = now.toLocaleString('id-ID', optsec ,{ hour12: false })
            // const time = hour+':'+min+':'+sec
            return{
                options: [
                { value: 'Size Huruf tanpa 2L', text: 'Size Huruf tanpa 2L' },
                { value: 'Size Huruf tanpa 1L', text: 'Size Huruf tanpa 1L' },
                { value: 'Size Huruf tanpa 3L', text: 'Size Huruf tanpa 3L' },
                { value: 'Size Huruf tanpa 4L', text: 'Size Huruf tanpa 4L' },
                ],
                formName: 'Input Form Approval',
                errorMessage: null,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                max_date:today,
                now:now,
                today:today,
                time:time,
                status:null,
                url_data:'',
                inputDisable:true,
                manufacture_date:null,
                due_date:null,
                    form : {
                    data_io:null,
                    approval:null,
                    Employee:null,
                    status:null,
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    manufacture_date:null,
                    description:null,
                    style:null,
                    texture:null,
                    material:null,
                    category:null,
                    sleeve:null,
                    qty_order:null,
                    total_qty:null,
                    due_date:null,
                    package_size:null,
                    basic:null,
                    addtional:null,
                    marker:today,
                    acc:today,
                    fabric:today,
                    fabric_inspection:today,
                    ppm:today,
                    myfile:null,
                    cutting_id :null,
                    cutting_subcon :null,
                    cutting_target :null,
                    cutting_target_dummy :null,
                    cutting_date:null,
                    embroidery_id:null,
                    embroidery_subcon :null,
                    embroidery_target :null,
                    embroidery_target_dummy :null,
                    printing_id :null,
                    printing_subcon :null,
                    printing_target :null,
                    printing_target_dummy :null,
                    emblem_id :null,
                    emblem_subcon :null,
                    emblem_target :null,
                    emblem_target_dummy :null,
                    sewing_id :null,
                    sewing_subcon :null,
                    sewing_target :null,
                    sewing_target_dummy :null,
                    washing_id :null,
                    washing_subcon :null,
                    washing_target :null,
                    washing_target_dummy :null,
                    finishing_id :null,
                    finishing_subcon :null,
                    finishing_target :null,
                    finishing_target_dummy :null,
                    packing_id :null,
                    packing_subcon :null,
                    packing_target :null,
                    packing_target_dummy :null,

                    },
                selectedDept:null,
                document_io:null,
                image_io:null,
                data_file:null,
                url_file:null,
                file:'Choose File',
                data_image:null,
                url_image:null,
                image:'Choose Image',
                form_api:[],
                read_id: null,
                cat_id_cut:null,
                dis_id_cut:null,
                cat_id_sew:null,
                dis_id_sew:null,
                cat_id_embr:null,
                dis_id_embr:null,
                cat_id_pri:null,
                dis_id_pri:null,
                cat_id_embl:null,
                dis_id_embl:null,
                cat_id_wash:null,
                dis_id_wash:null,
                cat_id_fin:null,
                dis_id_fin:null,
                cat_id_pack:null,
                dis_id_pack:null,
                isActive:true,
                readmore:null,


            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stIoPPM','stIoPPMCount','stMstSub']),


        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getInputIOPPM','getDataFormMasterSubcon']),
            FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 500);
                }
            },
            Setdata_IO() {
                let data_IO      = this.$route.query.io
                let userData     = this.userData
                this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                this.url_data = process.env.VUE_APP_BASE_URL
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardApproval'})
                }
                if (this.form.data_io != null) {
                    this.Loading('start')
                    this.getInputIOPPM(this.form.data_io).then(this.GetdataForm()).catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali / Contact Administrator'})
                        this.$router.push({name:"DashboardApproval"})
                        }
                        this.Loading('stop')
                    })
                }
            },
            BasicData(param) {
                let strbasic = param.substring(5,4);
                if (strbasic == 'X' || strbasic == 'x') {
                    this.form.basic == 'yes'
                    this.read_id = 1
                }
                else {
                    this.form.basic == 'no'
                    this.read_id = 0
                }
            },
            TotalQty(param) {
                let count = 0;
                let char = [];
                let ord_size = [];
                if (param == null || param == '' || param == undefined) {
                    // this.form.total_qty = 0
                } else {
                for (let size = 0;size<param.length; size++) {
                    if (param.charAt(size) == '(') {
                        char[count] = ((param.substring(size + 1,size+6)).split(")")[0]);
                    }
                    if (param.charAt(size) == ')') {
                        ord_size[count] = ((param.substring(size + 2,size+6)).split("|")[0]);
                        this.form.total_qty += parseInt(ord_size[count])
                        count++;
                    }
                }
                // console.log('param: '+char);
                // console.log('count: '+count);
                // console.log('ord_size: '+ord_size);
                }
            },
            ReadMore(){
                if(this.readmore == 'yes'){
                    return false;
                }

                return true
            },
            // CheckBasic(){
            //     if (this.form.basic != 'yes' || this.form.basic == null) {
            //         this.read_id = 1
            //         if (this.form.checkbox_cutting == 'yes') {
            //         this.form.cutting_target = this.form.cutting_target_dummy;
            //         }
            //         if (this.form.checkbox_sewing == 'yes') {
            //         this.form.sewing_target = this.form.sewing_target_dummy;
            //         }
            //         if (this.form.checkbox_embroidery == 'yes') {
            //         this.form.embroidery_target = this.form.embroidery_target_dummy;
            //         }
            //         if (this.form.checkbox_emblem == 'yes') {
            //         this.form.emblem_target = this.form.emblem_target_dummy;
            //         }
            //         if (this.form.checkbox_washing == 'yes') {
            //         this.form.washing_target = this.form.washing_target_dummy;
            //         }
            //         if (this.form.checkbox_finishing == 'yes') {
            //         this.form.finishing_target = this.form.finishing_target_dummy;
            //         }
            //         if (this.form.checkbox_printing == 'yes') {
            //         this.form.printing_target = this.form.printing_target_dummy;
            //         }
            //         if (this.form.checkbox_packing == 'yes') {
            //         this.form.packing_target = this.form.packing_target_dummy;
            //         }
            //     } else {
            //         this.read_id = 0
            //         this.form.cutting_target = null;
            //         this.form.sewing_target = null;
            //         this.form.embroidery_target = null;
            //         this.form.emblem_target = null;
            //         this.form.washing_target = null;
            //         this.form.finishing_target = null;
            //         this.form.printing_target = null;
            //         this.form.packing_target = null;
            //     }

            // },
            CheckAddtional() {
                if (this.form.addtional == 'no' || this.form.addtional == null) {
                    this.isActive = true
                } else {
                    this.isActive = false
                    this.form.checkbox_embroidery = null,
                    this.form.embroidery_id = null;
                    this.form.embroidery_subcon = null;
                    this.form.embroidery_target = null;
                    this.form.embroidery_target_dummy = null;

                    this.form.checkbox_washing= null;
                    this.form.washing_id= null;
                    this.form.washing_subcon = null;
                    this.form.washing_target = null;
                    this.form.washing_target_dummy = null;

                    this.form.checkbox_printing = null;
                    this.form.printing_id = null;
                    this.form.printing_subcon = null;
                    this.form.printing_target = null;
                    this.form.printing_target_dummy = null;

                    this.form.checkbox_emblem = null;
                    this.form.emblem_id = null;
                    this.form.emblem_subcon = null;
                    this.form.emblem_target = null;
                    this.form.emblem_target_dummy = null;
                }
            },
            CheckboxCutting() {
                if (this.form.checkbox_cutting != 'yes') {
                    this.form.checkbox_cutting = null;
                    this.form.cutting_id = null;
                    this.form.cutting_subcon = null;
                    this.form.cutting_target = null;
                    this.form.cutting_target_dummy = null;
                    this.dis_id_cut = 1
                    this.cat_id_cut = 1
                    return true
                } else {
                    this.dis_id_cut = 0
                    this.cat_id_cut = 0
                    return false
                }
            },
            CheckboxSewing() {
                if (this.form.checkbox_sewing != 'yes') {
                    this.form.checkbox_sewing = null;
                    this.form.sewing_id = null;
                    this.form.sewing_subcon = null;
                    this.form.sewing_target = null;
                    this.dis_id_sew = 1
                    this.cat_id_sew = 1
                    return true
                } else {
                    this.dis_id_sew = 0
                    this.cat_id_sew = 0
                    return false
                }
            },
            CheckboxEmbroidery() {
                if (this.form.checkbox_embroidery != 'yes') {
                    this.form.embroidery_id = null;
                    this.form.embroidery_subcon = null;
                    this.form.embroidery_target = null;
                    this.dis_id_embr = 1
                    this.cat_id_embr = 1
                    return true
                } else {
                    this.dis_id_embr = 0
                    this.cat_id_embr = 0
                    return false
                }
            },
            CheckboxPrinting() {
                if (this.form.checkbox_printing != 'yes') {
                    this.form.printing_id = null;
                    this.form.printing_subcon = null;
                    this.form.printing_target = null;
                    this.dis_id_pri = 1
                    this.cat_id_pri = 1
                    return true
                } else {
                    this.dis_id_pri = 0
                    this.cat_id_pri = 0
                    return false
                }
            },
            CheckboxFinishing() {
                if (this.form.checkbox_finishing != 'yes') {
                    this.form.checkbox_finishing = null;
                    this.form.finishing_id = null;
                    this.form.finishing_subcon = null;
                    this.form.finishing_target = null;
                    this.dis_id_fin = 1
                    this.cat_id_fin = 1
                    return true
                } else {
                    this.dis_id_fin = 0
                    this.cat_id_fin = 0
                    return false
                }
            },
            CheckboxEmblem() {
                if (this.form.checkbox_emblem != 'yes') {
                    this.form.emblem_id = null;
                    this.form.emblem_subcon = null;
                    this.form.emblem_target = null;
                    this.dis_id_embl = 1
                    this.cat_id_embl = 1
                    return true
                } else {
                    this.dis_id_embl = 0
                    this.cat_id_embl = 0
                    return false
                }
            },
            CheckboxWashing() {
                if (this.form.checkbox_washing != 'yes') {
                    this.form.washing_id = null;
                    this.form.washing_subcon = null;
                    this.form.washing_target = null;
                    this.dis_id_wash = 1
                    this.cat_id_wash = 1
                    return true
                } else {
                    this.dis_id_wash = 0
                    this.cat_id_wash = 0
                    return false
                }
            },
            CheckboxPacking() {
                if (this.form.checkbox_packing != 'yes') {
                    this.form.checkbox_packing = null;
                    this.form.packing_id = null;
                    this.form.packing_subcon = null;
                    this.form.packing_target = null;
                    this.form.packing_target_dummy = null;
                    this.dis_id_pack = 1
                    this.cat_id_pack = 1
                    return true
                } else {

                    this.dis_id_pack = 0
                    this.cat_id_pack = 0
                    return false
                }
            },
            cuttingdate() {
                if (this.form.marker >= this.form.acc && this.form.marker >= this.form.fabric &&
                    this.form.marker >= this.form.fabric_inspection && this.form.marker >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.marker);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.acc >= this.form.marker && this.form.acc >= this.form.fabric &&
                    this.form.acc >= this.form.fabric_inspection && this.form.acc >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.acc);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.fabric >= this.form.marker && this.form.fabric >= this.form.acc &&
                    this.form.fabric >= this.form.fabric_inspection && this.form.fabric >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.fabric);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.fabric_inspection >= this.form.marker && this.form.fabric_inspection >= this.form.acc &&
                    this.form.fabric_inspection >= this.form.fabric && this.form.fabric_inspection >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.fabric_inspection);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.ppm >= this.form.marker && this.form.ppm >= this.form.acc &&
                    this.form.ppm >= this.form.fabric && this.form.ppm >= this.form.fabric_inspection) {
                        let cuttingdate = new Date(this.form.ppm);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.marker == this.form.acc && this.form.marker == this.form.fabric &&
                    this.form.marker == this.form.fabric_inspection && this.form.marker == this.form.ppm) {
                        let cuttingdate = new Date(this.form.marker);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
            },

            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stIoPPMCount == 0 || this.stIoPPMCount == null) {
                    this.DelayGetData()
                    return
                }

                // Set Data v-model
                let formdata = this.stIoPPM
                this.form.status = formdata[0].status
                this.form.artikel = formdata[0].artikel
                this.BasicData(this.form.artikel)
                this.form.sample = formdata[0].sample
                this.form.brand = formdata[0].brand
                this.form.subbrand = formdata[0].sub_brand
                this.form.manufacture_date = formdata[0].manufacture_date
                if (formdata[0].manufacture_date !== null) {
                    this.manufacture_date = this.FormatDate(formdata[0].manufacture_date)
                }
                this.form.description = formdata[0].description
                this.form.style = formdata[0].style
                this.form.texture = formdata[0].texture
                this.form.material = formdata[0].material
                this.form.category = formdata[0].category
                this.form.sleeve = formdata[0].sleeve
                this.document_io = formdata[0].document_io
                this.file = formdata[0].document_io
                if (this.file == null) {
                    this.file = 'Choose File'
                }

                this.image_io = formdata[0].image_io
                this.image = formdata[0].image_io
                if (this.image == null) {
                    this.image = 'Choose Image'
                }
                this.form.qty_order = formdata[0].qty_order
                this.form.cutting_date = formdata[0].cutting_date
                this.TotalQty(this.form.qty_order)
                this.form.due_date = formdata[0].due_date
                if (formdata[0].due_date !== null) {
                    this.due_date = this.FormatDate(formdata[0].due_date)
                }
                this.form.package_size = formdata[0].package_size
                this.form.addtional = formdata[0].addtional
                this.form.marker = formdata[0].marker
                this.form.acc = formdata[0].acc
                this.form.fabric = formdata[0].fabric
                this.form.fabric_inspection = formdata[0].fabric_inspection
                this.form.ppm = formdata[0].ppm
                this.form.cutting_id = formdata[0].cutting_id
                this.form.cutting_subcon = formdata[0].cutting_subcon
                this.form.cutting_target = formdata[0].cutting_target
                this.form.cutting_target_dummy = formdata[0].cutting_target
                if (this.form.cutting_id == null || this.form.cutting_id == 0) {
                    this.form.checkbox_cutting = null
                    this.dis_id_cut = 1
                } else {
                    this.form.checkbox_cutting = 'yes'
                    this.dis_id_cut = 0
                }
                this.form.embroidery_id = formdata[0].embroidery_id
                this.form.embroidery_subcon = formdata[0].embroidery_subcon
                this.form.embroidery_target = formdata[0].embroidery_target
                this.form.embroidery_target_dummy = formdata[0].embroidery_target
                if (this.form.embroidery_id == null || this.form.embroidery_id == 0) {
                    this.form.checkbox_embroidery = null
                    this.dis_id_embr = 1
                } else {
                    this.form.checkbox_embroidery = 'yes'
                    this.dis_id_embr = 0
                }
                this.form.printing_id = formdata[0].printing_id
                this.form.printing_subcon = formdata[0].printing_subcon
                this.form.printing_target = formdata[0].printing_target
                this.form.printing_target_dummy = formdata[0].printing_target
                if (this.form.printing_id == null || this.form.printing_id == 0) {
                    this.form.checkbox_printing = null
                    this.dis_id_pri = 1
                } else {
                    this.form.checkbox_printing = 'yes'
                    this.dis_id_pri = 0
                }
                this.form.emblem_id = formdata[0].emblem_id
                this.form.emblem_subcon = formdata[0].emblem_subcon
                this.form.emblem_target = formdata[0].emblem_target
                this.form.emblem_target_dummy = formdata[0].emblem_target
                if (this.form.emblem_id == null || this.form.emblem_id == 0) {
                    this.form.checkbox_emblem = null
                    this.dis_id_embl = 1
                } else {
                    this.form.checkbox_emblem = 'yes'
                    this.dis_id_embl = 0
                }
                this.form.sewing_id = formdata[0].sewing_id
                this.form.sewing_subcon = formdata[0].sewing_subcon
                this.form.sewing_target = formdata[0].sewing_target
                this.form.sewing_target_dummy = formdata[0].sewing_target
                if (this.form.sewing_id == null || this.form.sewing_id == 0) {
                    this.form.checkbox_sewing = null
                    this.dis_id_sew = 1
                } else {
                    this.form.checkbox_sewing = 'yes'
                    this.dis_id_sew = 0
                }
                this.form.washing_id = formdata[0].washing_id
                this.form.washing_subcon = formdata[0].washing_subcon
                this.form.washing_target = formdata[0].washing_target
                this.form.washing_target_dummy = formdata[0].washing_target
                if (this.form.washing_id == null || this.form.washing_id == 0) {
                    this.form.checkbox_washing = null
                    this.dis_id_wash = 1
                } else {
                     this.form.checkbox_washing = 'yes'
                     this.dis_id_wash = 0
                }
                this.form.packing_id = formdata[0].packing_id
                this.form.packing_subcon = formdata[0].packing_subcon
                this.form.packing_target = formdata[0].packing_target
                this.form.packing_target_dummy = formdata[0].packing_target
                if (this.form.packing_id == null || this.form.packing_id == 0) {
                    this.form.checkbox_packing = null
                    this.dis_id_cut = 1
                } else {
                    this.form.checkbox_packing = 'yes'
                    this.dis_id_cut = 0
                }
                this.form.finishing_id = formdata[0].finishing_id
                this.form.finishing_subcon = formdata[0].finishing_subcon
                this.form.finishing_target = formdata[0].finishing_target
                this.form.finishing_target_dummy = formdata[0].finishing_target
                if (this.form.finishing_id == null || this.form.finishing_id == 0) {
                    this.form.checkbox_finishing = null
                     this.dis_id_fin = 1
                } else {
                    this.form.checkbox_finishing = 'yes'
                    this.dis_id_fin = 0
                }
                if (this.form.basic == 'yes') {
                    this.read_id = 1
                } else {
                    this.form.basic = 'no'
                    this.read_id = 0
                }
                if (this.form.addtional == 'yes') {
                    this.isActive = true
                } else {
                    this.form.addtional = 'no'
                    this.isActive = false
                    this.form.checkbox_embroidery = null;
                    this.form.embroidery_id = null;
                    this.form.embroidery_subcon = null;
                    this.form.embroidery_target = null;
                    this.form.embroidery_target_dummy = null;

                    this.form.checkbox_washing = null;
                    this.form.washing_id= null;
                    this.form.washing_subcon = null;
                    this.form.washing_target = null;
                    this.form.washing_target_dummy = null;

                    this.form.checkbox_printing = null;
                    this.form.printing_id = null;
                    this.form.printing_subcon = null;
                    this.form.printing_target = null;
                    this.form.printing_target_dummy = null;

                    this.form.checkbox_emblem = null;
                    this.form.emblem_id = null;
                    this.form.emblem_subcon = null;
                    this.form.emblem_target = null;
                    this.form.emblem_target_dummy = null;
                }
                this.inputDisable= false;
                this.Loading('stop')
            },
            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
            doReject() {
                // let file_upload = this.document_io
                // if (this.document_io.name) {
                //     file_upload = this.document_io.name
                // }
                this.form.approval = "Reject"
                this.status = "New(Reject)"
                // console.log(this.form.cutting_date);

                let verifyData = this.createFormData()
                this.form_api.push(verifyData)

            },
            doApproval() {
                // let file_upload = this.document_io
                // if (this.document_io.name) {
                //     file_upload = this.document_io.name
                // }
                this.form.approval = "Approve"
                this.status = "ONGOING(Cutting)"
                // console.log(this.form.cutting_date);

                let verifyData = this.createFormData()
                this.form_api.push(verifyData)

            },
            createFormData() {
            let data = {
                io:this.form.data_io,
                //document_io NULL
                approve:this.form.approval,
                status:this.status,

            }
            // console.log('data: '+data)
            return data;
            },
            onSubmit() {

                // console.log('form_api: '+this.form_api)
                if(this.form_api.length == 0) {
                    return
                }
            this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                    this.boxTwo = String(value)
                    if (this.boxTwo == 'true') {
                        let loader = this.$loading.show({
                            // Optional parameters
                            container: this.fullPage ? null : this.$refs.formContainer,
                            canCancel: false,
                            color: '#f89830',
                            loader: 'spinner',
                            width: 64,
                            height: 64,
                            backgroundColor: '#ffffff',
                            opacity: 0.5,
                            zIndex: 999,
                            });
                            // show report after timeout min 1 second

                            // apiUpload.dispatch('UploadFile', {
                            //     myfile: this.document_io
                            // })
                            apiapprovePPCM.dispatch('postApprovePPCMDetail', {
                                io:this.form.data_io,
                                user:this.form.Employee,
                                status:this.form.approval,
                                date_time:this.today+' '+this.time,

                            })
                            .then(response => {
                            // console.log(response)
                            })
                            .catch(err => {
                            if (err.response === 400) {
                                console.log(err.response.data.message)
                                this.showNotification({type:'error', message:err.response.data.message})
                            }
                            })

                        setTimeout(() => {
                            apiapprovePPCM.dispatch('putApprovePPCM', {
                                data:this.form_api
                            })
                            .then(response => {
                            loader.hide()
                            // console.log(response)
                            this.$router.push({name:"DashboardApproval"})
                            this.showNotification({type:'success', message:'Successfully Submitted'})
                            })
                            .catch(err => {
                            loader.hide()
                            if (err.response === 400) {
                                console.log(err.response.data.message)
                                this.showNotification({type:'error', message:err.response.data.message})
                            }
                            })
                            .finally(()=>{
                            loader.hide()
                            this.form_api = []
                            })
                        },3000)
                    }
                    if (this.boxTwo == 'false' || this.boxTwo == 'null') {
                        this.form_api = []
                    }
                })
                .catch(err => {
                    // An error occurred
                })

            },

        },
        created() {
            this.getDataFormMasterSubcon()
            this.Setdata_IO()
        },

    }
</script>
<style src="../InputPPM/InputPPM.scss" lang="scss" scoped />
