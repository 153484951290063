
import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchListProductDesign,
    fnApiFetchListProductDashboard,
    fnApiPostProductDetail,
    fnApiPutProductDetail,
    fnApiFetchProductImages,
    fnApiFetchSaveProductDesignForm,
    fnApiFetchSaveProductDesignDetail,
    fnApiFetchMaterialDevDashboard,
    fnApiPostMaterialDevDetail,
    fnApiFetchMaterialDevDetail,
    fnApiPostMaterialDevActivity,
    fnApiFetchMaterialDevActivity,
    fnApiFetchListProductSample,
    fnApiFetchSaveProductMaterial,
    fnApiFetchSaveProductImages,
    fnApiPutProductImages,
    fnApiDeleteProductImages,
    fnApiFetchProductMaterial,
    fnApiPutProductMaterial,
    fnApiPutProductMaterialApprove,
    fnApiFetchProductApprove,
    fnApiPostProductApprove,
    fnApiProductMaterialApprove,
    fnApiMaterialHistory,
    fnApiPostProductMaterialApprove,
    fnApiFetchProductProcess,
    fnApiPostProductProcess,
    fnApiPutProductProcess,
}from '../../api/api-aplications-product'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        stProductSample:[],
        stProduct:null,
        stProductList:null,
        stProductDetail:null,
        stProductMaterial:[],
        stProductMaterialDev:[],
        stAccList:[],
        stAccDetail:null,
        stThreadList:[],
        stThreadDetail:null,
        stArtworkList:[],
        stArtworkDetail:null,
        stFabricList:[],
        stFabricDetail:null,
        stInterliningList:[],
        stInterliningDetail:null,
        stProductDev:null,
        stActivity:[],
        stMasterDev:[],
        stProductApprove:[],
        ST_PRODUCT_DETAIL:null,
        ST_PRODUCT_MATERIAL:[],
        ST_MATERIAL_HISTORY:[],


    }
}

const state = getDefaultState()

const getters = {
    GET_MATERIAL_HISTORY(state){
        return state.ST_MATERIAL_HISTORY
    },
    GET_PRODUCT_DETAIL(state){
        return state.ST_PRODUCT_DETAIL
    },
    GET_PRODUCT_MATERIAL(state){
        return state.ST_PRODUCT_MATERIAL
    },
    productList(state){
        return state.stProductList
    },
    productDetail(state){
        return state.stProductDetail
    },
    product(state){
        return state.stProduct
    },
    accList(state){
        return state.stAccList
    },
    accDetail(state){
        return state.stAccDetail
    },
    threadList(state){
        return state.stThreadList
    },
    threadDetail(state){
        return state.stThreadDetail
    },
    artworkList(state){
        return state.stArtworkList
    },
    artworkDetail(state){
        return state.stArtworkDetail
    },
    fabricList(state){
        return state.stFabricList
    },
    fabricDetail(state){
        return state.stFabricDetail
    },
    interliningList(state){
        return state.stInterliningList
    },
    interliningDetail(state){
        return state.stInterliningDetail
    },
    postProductDev(state){
        return state.stProductDev
    },
    MATERIAL_DEV(state){
        state.stMasterDev
    },
    activityList(state){
        // console.log('getter',state.stActivity);
        return state.stActivity
    },
    PRODUCT_MATERIAL(state){
        // console.log(state.stProductMaterial);
        return state.stProductMaterial
    },
    PRODUCT_SAMPLE(state){
        return state.stProductSample
    },
    PRODUCT_MATERIAL_DEV(state){
        return state.stProductMaterialDev
    },
    PRODUCT_APPROVE(state){
        return state.stProductApprove
    },
    
}

const mutations = {
    GET_MATERIAL_HISTORY(state,item){
        state.ST_MATERIAL_HISTORY = item
    },
    SET_PRODUCT_DETAIL(state,item){
        state.ST_PRODUCT_DETAIL = null
        state.ST_PRODUCT_DETAIL = item
    },
    SET_PRODUCT_MATERIAL(state,item){
        // console.log(item);
        state.stProductMaterial = []
        state.stProductMaterial = item
    },
    getProduct(state,item){
        state.stProductList = item
    },
    getProductDetail(state,id){
        state.stProductDetail = state.stProductList.filter(obj => obj.id === id)
    },
    addProduct(state,item){
        state.stProduct = item
    },
    updateProduct(state,item){
        Object.assign(state.stProduct, item)
    },
    deleteProduct(state,id){
        state.stProduct.splice(id,1)
    },
    get(state,index){
        // state["st"+index.formname+"Detail"] = state["st"+index.formname+"List"][index.id]
        state.stProductMaterial = index
    },
    add(state,item){
        // console.log('mutations', item);
        // state["st"+item.formname+"List"].push(item)
        state.stProductMaterial.push(item)
    },
    update(state,item){
        // Object.assign(state["st"+item.formname+"List"][item.id],item)
        Object.assign(state.stProductMaterial,item)
    },
    delete(state,id){
        // console.log(id);
        // state["st"+id.formname+"List"].splice(id.id,1)
        state.stProductMaterial.splice(id.id,1)
    },
    postProductDev(state,item){
        state.stProductDev = item
    },
    addActivity(state,item){
        state.stActivity.push(item)
    },
    GET_PRODUCT_SAMPLE(state,item){
        state.stProductSample = item
    },
    PRODUCT_APPROVE(state,item){
        state.stProductApprove = item
    },
    RESET_STATE(state){
        Object.assign(state, getDefaultState())
    }

}

const actions = {
    RESET_STATE(context){
        context.commit('RESET_STATE')
    },
    SET_PRODUCT_MATERIAL({commit},id){
        // console.log(id);
        return new Promise((resolve, reject)=>{
            fnApiFetchProductMaterial()
            .then(response => {
                resolve(response)
                commit('SET_PRODUCT_MATERIAL', response.data.payload.filter(i=>i.product_id === id))
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    SET_PRODUCT_DETAIL({commit}, productDetail){
        return new Promise((resolve, reject)=>{
            fnApiPostProductDetail(productDetail)
            .then(response => {
                resolve(response)
                commit('SET_PRODUCT_DETAIL',response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    }, 
    GET_PRODUCT_DETAIL(context, productDetail){
        return new Promise((resolve, reject)=>{
            fnApiPostProductDetail(productDetail)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    PUT_PRODUCT_DETAIL({commit},productId){
        return new Promise((resolve,reject)=>{
            fnApiPutProductDetail(productId)
            .then(rsp=>{
                resolve(rsp)
            })
            .catch(err =>{
                reject(err)
            })
        })
    },

    PUT_PRODUCT_MATERIAL({commit},productMaterial){
        return new Promise((resolve,reject)=>{
            fnApiPutProductMaterial(productMaterial)
            .then(rsp=>{resolve(rsp)})
            .catch(err=>{reject(err)})
        })
    },

    GET_PRODUCT_IMAGES(context,productId){
        return new Promise((resolve,reject)=>{
            fnApiFetchProductImages(productId)
            .then(rsp=>{
                resolve(rsp)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },

    GET_PRODUCT_PROCESS(context, productArticle){
        return new Promise((resolve, reject)=>{
            fnApiFetchProductProcess(productArticle)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },  

    POST_PRODUCT_PROCESS(context, productArticle){
        return new Promise((resolve, reject)=>{
            fnApiPostProductProcess(productArticle)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },  
    
    PUT_PRODUCT_PROCESS(context, productArticle){
        return new Promise((resolve, reject)=>{
            fnApiPutProductProcess(productArticle)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },  

    POST_PRODUCT_APPROVE(context, formProductApprove){
        return new Promise((resolve, reject)=>{
            fnApiPostProductApprove(formProductApprove)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_PRODUCT_DEV(context, formProductDesign){
        return new Promise((resolve, reject) =>{
            fnApiFetchSaveProductDesignForm(formProductDesign)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_PRODUCT_IMAGES(context,imagesProductDesign){
        return new Promise((resolve, reject)=>{
            fnApiFetchSaveProductImages(imagesProductDesign)
            .then(response =>{
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    PUT_PRODUCT_IMAGES(context,imagesProductDesign){
        return new Promise((resolve, reject)=>{
            fnApiPutProductImages(imagesProductDesign)
            .then(response =>{
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_PRODUCT_MATERIAL(context, productMaterial){
        return new Promise((resolve,reject)=>{
            fnApiFetchSaveProductMaterial(productMaterial)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    APPROVE_PRODUCT_MATERIAL(context,approveProdMat){
        return new Promise((resolve, reject)=>{
            fnApiPutProductMaterialApprove(approveProdMat)
            .then(rsp => {
                resolve(rsp)
            })
            .catch(err =>{
                reject(err)
            })
        })
    },
    GET_PRODUCT_APPROVE({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchProductApprove()
            .then(response => {
                resolve(response)
                commit('PRODUCT_APPROVE',response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    GET_PRODUCT_SAMPLE({commit}){
        return new Promise((resolve,reject)=>{
            fnApiFetchListProductSample()
            .then(response => {
                
                resolve(response)
                commit('GET_PRODUCT_SAMPLE',response.data.payload)
            })
            .catch(error => {
                // console.log('action error',error);
                reject(error)
            })
        })
    },
    GET_PRODUCT_MATERIAL_DEV({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchProductMaterial()
            .then(response => {
                resolve(response)
                // commit('SET_PRODUCT_MATERIAL', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    getProduct({commit}){
        return new Promise((resolve,reject)=>{
            fnApiFetchListProductDesign()
            .then(response => {
                resolve(response.data.payload)
                commit('getProduct', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    GET_PRODUCT_DASHBOARD({commit}){
        return new Promise((resolve,reject)=>{
            fnApiFetchListProductDashboard()
            .then(response => {
                resolve(response.data.payload)
                // commit('getProduct', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },

    getProductDetail({commit},id){
        commit("getProductDetail",id)
    },
    addProduct({commit},item){
        // console.log('add product', item);
        commit("addProduct",item)
    },
    updateProduct({commit},item){
        commit("updateProduct",item)
    },
    deletProduct({commit},id){
        commit("deleteProduct",id)
    },
    addActivity({commit},item){
        commit("addActivity",item)
    },
    get({commit},index){
        commit("get",index)
    },
    add({commit},item){
        // console.log('actions',item);
        commit("add",item)
    },
    update({commit},item){
        commit("update",item)
    },
    delete({commit},id){
        commit("delete",id)
    },
    postProductDev(context, formProductDesign){
        return new Promise((resolve, reject) =>{
            fnApiFetchSaveProductDesignForm(formProductDesign)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    postProductDevDetail(context, formProductDesignDetail){
        return new Promise((resolve,reject)=>{
            fnApiFetchSaveProductDesignDetail(formProductDesignDetail)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },
    GET_MATERIAL_APPROVE(context,approveMaterial){
        return new Promise((resolve,reject)=>{
            fnApiProductMaterialApprove(approveMaterial)
            .then(rsp=>{
                resolve(rsp)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    GET_MATERIAL_HISTORY({commit},approveMaterial){
        return new Promise((resolve,reject)=>{
            fnApiMaterialHistory(approveMaterial)
            .then(rsp=>{
                resolve(rsp)
                
                commit('GET_MATERIAL_HISTORY', rsp.data.payload)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    POST_MATERIAL_APPROVE(context,approveMaterial){
        return new Promise((resolve,reject)=>{
            fnApiPostProductMaterialApprove(approveMaterial)
            .then(rsp=>{resolve(rsp)})
            .catch(err=>{reject(err)})
        })
    },
    DELETE_PRODUCT_IMAGES(context,productImages){
        return new Promise((resolve,reject)=>{
            fnApiDeleteProductImages(productImages)
            .then(rsp=>{resolve(rsp)})
            .catch(err=>{reject(err)})
        })
    }
    

    
}


export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

