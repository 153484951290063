<template>
    <div>
        <b-container fluid style="height: 90vh;">
            <b-row align-h="center" align-v="center" style="height:100%;">
            <b-card border-variant="light" title="Update Now?" body-class="text-center">
                <b-card-text>
                     Version {{this.version}} is available on the server
                </b-card-text>

                <b-button @click="downloadFile()" variant="primary">Download</b-button>
            </b-card>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    data(){
        return{
            formName:'App Release',
            url:null,
            filename:null,
            version:null,
            getUrl:null,
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['versionApp']),
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['getVersionApp']),
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                console.log('true')
            } else {
                console.log('false')
            }
        },
        setData(){
            this.isMobile()
            let host = new URL(window.location.href);
            let hostname = host.hostname
            if (hostname == "localhost") {
                hostname = "testapps.bbi-apparel.com"
            }
            this.url = "https://"+hostname+":8000/media/additional/"
            this.getVersionApp().then(rsp=> {
                this.filename = rsp.payload[0].filename
                this.version = rsp.payload[0].version
                this.getUrl = this.url+this.filename
            })
        },
        downloadFile() {
            window.open(this.getUrl, '_self')
        },
    },
    created(){
        this.setData()
    },
}
</script>
<style src="../SalesForce.scss" lang="scss" scoped />