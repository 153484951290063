import { getAPI } from './api-base'
import { resolve, reject } from 'core-js/fn/promise'

const apiPath = {
 // *API PPCM
 formmastersubcon            : '/v1/api/bbi-ppcm/master-subcon/',
 inputorderppm               : '/v1/api/bbi-ppcm/input-order-ppm',
 subcondaily                 : '/v1/api/bbi-ppcm/get-subcon-daily',
 subcondailyv2               : '/v1/api/bbi-ppcm/get-subcon-dailyv2',
 dashboardmonitoring         : '/v1/api/bbi-ppcm/get-monitoring-detail',
 allIOPPM                    : '/v1/api/bbi-ppcm/input-order-ppm',
 allIOPPMV2                  : '/v1/api/bbi-ppcm/dashboard-ppm',
 allDataIOPPM                : '/v1/api/bbi-ppcm/data-dashboard-ppm',
 ppcminspection              : '/v1/api/bbi-ppcm/ppcm-inspection',
 PpcmInspectionV2            : '/v1/api/bbi-ppcm/ppcm-inspection-v2',
 countDataInspection         : '/v1/api/bbi-ppcm/count_ppcm-inspection',
 getppcminspection           : '/v1/api/bbi-ppcm/get-ppcm-inspection',
 GetDeliveryPPCM             : '/v1/api/bbi-ppcm/get-ppcm-delivery',
 GetDataFromDelvr            : '/v1/api/bbi-ppcm/get-ppcm-delivery_detail',
 GetReceivePPCM              : '/v1/api/bbi-ppcm/get-ppcm-receive',
 GetDataFromReceive          : '/v1/api/bbi-ppcm/get-ppcm-receive_detail',
 GetDataImageUpload          : '/v1/api/bbi-ppcm/getuploadimageppcm',
 GetDataFileUpload           : '/v1/api/bbi-ppcm/getuploadfileppcm',
 GetDataApprove              : '/v1/api/bbi-ppcm/approve-ppm',

//  Adjustment
 DashboardInputAdjustment     : '/v1/api/bbi-ppcm/dashboardinputadjustment',
 DashboardAdjustment          : '/v1/api/bbi-ppcm/getdashboardadjustment',

 ApproveAdjustment            : '/v1/api/bbi-ppcm/get-data-adjustment',

 // Master Reason
 GetMasterReason              : '/v1/api/bbi-ppcm/get-data-process-reason',

 GetCountMonitoringDetail     : '/v1/api/bbi-ppcm/get_count_monitoring_detail',
}
// PPCM
export function fnApiFetchFormMasterSubcon(filters){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.formmastersubcon, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

export function fnApiInputOrderPPM(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.inputorderppm, {io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiSubconDailyData(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.subcondaily, {io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiSubconDailyDataV2(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.subcondailyv2, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiMonitoringDetailData(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.dashboardmonitoring, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiAllInputOrderPPM(){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.allIOPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiAllInputOrderPPMV2(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.allIOPPMV2, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiAllDataInputOrderPPM(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.allDataIOPPM, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiGetPPCMInspection(){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.ppcminspection)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataPPCMInspectionV2(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.PpcmInspectionV2, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiGetCountDataPPCMInspection(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.countDataInspection, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiGetDataPPCMInspection(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.getppcminspection, {io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataPPCMDelivery(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.GetDeliveryPPCM, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataFormDelivery(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDeliveryPPCM, {io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataLogDelivery(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataFromDelvr, {io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}


export function fnApiGetDataPPCMReceive(formPPM){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.GetReceivePPCM, formPPM)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataFormReceive(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetReceivePPCM, {io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataLogReceive(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataFromReceive, {io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataImageUpload(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataImageUpload, {ppm_io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiGetDataFileUpload(filter){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetDataFileUpload, {ppm_io:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}
export function fnApiApproveData(filter){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.GetDataApprove)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })

    })

}

// Adjustment
// Data Create Adjustment
export function fnApiGetAllDashboardInputAdjustment(filter){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.DashboardInputAdjustment)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiGetFilterCreateDashboardAdjustment(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.DashboardInputAdjustment, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// Data Adjustment
export function fnApiGetAllDashboardAdjustment(){
    return new Promise((resolve, reject) => {
        getAPI.get(apiPath.DashboardAdjustment)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiGetFilterDashboardAdjustment(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.DashboardAdjustment, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

export function fnApiGetDataAdjustment(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.ApproveAdjustment, {code_request:filters})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

export function fnApiGetDataReason(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetMasterReason, {filter:filters})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

// get count monitoring detail
export function fnApiGetCountMonitoringDetail(filters){
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.GetCountMonitoringDetail, filters)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}
