<template>
  <div class="tables-basic dahsbordAdjustment">
    <b-breadcrumb>
      <h2 class="page-title"> <span style="font-size:16px;" class="fw-semi-bold fontbbi ">Dashboard Adjustment</span></h2>
    </b-breadcrumb>
   <!-- User Interface controls -->

      <b-col sm="12">
        <b-row>
          <b-col sm="4">
            <b-form-group
            label="Filter: "
            label-for="filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm">
            <b-input-group size="sm">
              <b-form-select
              id="filter"
              v-model="filter"
              size="sm"
              :options="optionsFilter"
              class="w-75"
              ></b-form-select>
            </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="4">
            <b-form-group
            label="Search: "
            label-for="search"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            >
            <b-input-group size="sm">
              <b-form-input style="height: calc(1.5em + 0.75rem + 2px);"
                placeholder="Enter your search keyword"
                v-model="search">
              </b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col sm="auto">
              <b-button variant="primary"
                style="border-radius:8px;"
                @click="searchFilter">
                <b-icon icon="search"></b-icon>
              Search
              </b-button>
          </b-col>
          <b-col sm="auto">
              <b-button variant="danger"
                style="border-radius:8px;"
                @click="ClearFilter">
                <b-icon icon="arrow-repeat" font-scale="1.4"></b-icon>
              Clear
              </b-button>
          </b-col>
        </b-row>

    <!-- Main table element -->
    <b-table
      :items="dataTable"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      label-sort-asc
      :sort-direction="sortDirection"
      stacked="md"
      @filtered="onFiltered"
      class='my-table'
    >
      <!-- IO -->
      <template #cell(io)="row">
        <span @click="IOPPCM(row.value)" style="color:#5497f3; text-decoration: underline; cursor: pointer;">{{ row.value }} </span>
      </template>

      <!-- Due Date -->
      <template #cell(due_date)="row">
        <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
      </template>

      <!-- Status -->
      <template #cell(status)="row">
        <span v-if="row.value !== null" v-html="statusHidden(row.value)"></span>
      </template>

    </b-table>
  <b-row>
    <b-col sm="4">
      Showing {{showPerPage}} of {{totalData}} entries
    </b-col>
    <b-col sm="4">

    </b-col>
    <b-col sm="4" align-self="end" class="my-1">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalData"
        :per-page="perPage"
        align="fill"
        size="sm"
      >
      <template #first-text><span class="text-danger">First</span></template>
      <template #prev-text><span class="">Prev</span></template>
      <template #next-text><span class="">Next</span></template>
      <template #last-text><span class="text-danger">Last</span></template>
      <template #page="{ page, active }">
        <b v-if="active">{{Pagination(page)}} {{ page }}</b>
        <i v-else>{{ page }}</i>
      </template>
    </b-pagination>
    </b-col>
  </b-row>
  </b-col>
</div>

</template>

<script>
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex';
import Widget from '@/components/Widget/Widget';
// import vue loading
import Loading from 'vue-loading-overlay';
// Import vue loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

export default {
    name: 'DashboardAdjustment',
    components: { Widget },
    data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return {
      today:today,
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      oneDay:oneDay,
      loader:null,
      filter: 'all',
      search: null,
      showPerPage:0,
      dataTable:[],
      totalData:0,
      optionsFilter: [
        { value: 'all', text: 'All' },
        { value: 'io', text: 'IO' },
        { value: 'brand', text: 'Brand' },
        { value: 'artikel', text: 'Artikel' },
        { value: 'status', text: 'Status' },
      ],
      fields: [
        { key: 'io', label: 'IO', sortable: true, class: 'header-table' },
        { key: 'due_date', label: 'due date', sortable: true, sortDirection: 'desc', class: 'header-table' },
        { key: 'artikel', label: 'artikel', class: 'header-table' },
        { key: 'brand', label: 'brand', class: 'header-table' },
        { key: 'status', label: 'status', sortable: true, sortDirection: 'desc', class: 'header-table' },
      ],
      currentPage: 1,
      PageNow:1,
      perPage: 5,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      form : {
            fullname:null,
            data:null,
            role:null,
            filter:'all',
            search:null,
            start_limit:0,
            // end_limit:1,
        },
      }
  },
  computed: {
      ...mapState('application', ['stAppAccess']),
      ...mapGetters('session', ['userData']),
      ...mapState('ppm', ['stCreateAdjustment','stCreateAdjustmentCount']),

    },
    methods: {
      ...mapActions('session', ['showNotification']),
      ...mapActions('application', ['clearState', 'getApplicationAccess']),
      ...mapActions('ppm', ['clearStatePpm','getFilterCreateAdjustment','getAllCreateAdjustment']),

    Setdata_IO() {
        let userData     = this.userData
        this.$set(this.form, 'fullname', userData.fullname)
        this.$set(this.form, 'role', userData.role)
        if (userData.roles) {
          for (let a=0; a<userData.roles.length; a++) {
            if (userData.roles[a].app == 'PPCM') {
              this.form.role = userData.roles[a].role_list
            }
          }
        }
        // this.form.role = this.form.role.toLowerCase();
        let role = this.form.role
        this.url_data = process.env.VUE_APP_BASE_URL
        this.Loading('start')
        this.getFilterCreateAdjustment({
          role:this.form.role,
          filter:'all',
          search:null,
          start_limit:this.form.start_limit,

          })
          .then(rsp => {
            this.dataTable = this.stCreateAdjustment
            this.totalData = this.stCreateAdjustmentCount
            this.showPerPage = this.perPage
            if (this.totalData < this.perPage) {
              this.showPerPage = this.totalData
            }
            this.Loading('stop')
          })
          .catch(err => {
              if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
              }
              this.Loading('stop')
          })
          if (this.form.role.includes('subcon')) {
            this.$router.push({name:"ApplicationPage"})
            this.showNotification({type:'error', message:"You don't have authorization to view this page"})
          }
      },
    Pagination(value) {
      if (value !== this.PageNow) {
        if (value > this.PageNow) {
          this.PageNow = value
          this.showPerPage = this.perPage * this.PageNow
          if (this.showPerPage >= this.totalData) {
            this.showPerPage = this.totalData
          }
        } else {
          this.PageNow = value
          this.showPerPage = this.showPerPage - (this.showPerPage - (this.perPage * this.PageNow))
          if (this.showPerPage <= this.perPage) {
            this.showPerPage = this.perPage
          }
        }
      }
    },
    IOPPCM(data) {
      // this.$router.push({ name: 'InputAdjustment', params: { data_io: data } })
      let routeData = this.$router.resolve({ name: 'InputAdjustment', query: { io: data }})
      // console.log(routeData.href)
      window.open(routeData.href, '_self')
    },
    nextPage:function() {
      if (this.totalData > this.dataTable.length) {
      this.Loading('start')
      this.form.start_limit = this.perPage
      // this.form.end_limit = this.perPage * this.PageNow
      this.getFilterCreateAdjustment({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        // end_limit:this.form.end_limit,
      })
      .then(rsp => {
        this.Loading('stop')
        this.dataTable = this.dataTable.concat(this.stCreateAdjustment) // merge two arrays
          let foo = new Map();
          for(const tag of this.dataTable) {
            foo.set(tag.id, tag);
          }
          this.dataTable = [...foo.values()]
      })

    }
    },
      FormatDate(value) {
        if(value == null || value == undefined || value == '') {
            return ' '
        } else {
        let date = new Date(value)
        let tgl = date.toISOString().slice(8,10)
        let bulan = this.monthNames[date.getMonth()]
        let tahun = date.toISOString().slice(0,4)
        date = tgl+' '+bulan+' '+tahun
        return date
        }
      },
      Loading(value) {
        if (value == 'start') {
        this.loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            color: '#f89830',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
            });
        }
        if (value == 'stop') {
          // Set delay 1 second
          setTimeout(() => {
            this.loader.hide()
          }, 1000);
        }
      },
      statusHidden(value) {
        let conOng = value.includes("ONGOING")
        let conPen = value.includes("Approval")
        let status = value
        if (conOng) {
          status = ((value.substring(8,20)).split(")")[0]);
        }
        if (conPen) {
          status = ((value.substring(8,20)).split(")")[0]);
        }
        return status
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalData = filteredItems.length
        this.currentPage = 1
      },
      ClearFilter() {
        this.currentPage = 1;
        this.PageNow = 1;
        this.filter = 'all'
        this.search = null
        this.form.filter = this.filter
        this.form.search = this.search
        this.form.start_limit = 0
        this.LoadData()
      },
      searchFilter() {
        console.log('search: '+this.search)
        if (this.filter !== 'all') {
          if (this.search == null || this.search == '') {
            this.showNotification({type:'error', message:"Field Search Tidak Boleh Kosong"})
            return
          }
          if (this.search.substring(0, 1) == ' ' ) {
            this.showNotification({type:'error', message:"Field Search Terinput Belum Benar"})
            return
          }
        } else {
          this.search = null;
        }
        this.currentPage = 1;
        this.PageNow = 1;
        this.form.filter = this.filter
        this.form.search = this.search
        this.form.start_limit = 0
        // this.form.end_limit = this.perPage
        this.LoadData()
      },

      LoadData() {
        this.Loading('start')
        this.getFilterCreateAdjustment({
          role:this.form.role,
          filter:this.form.filter,
          search:this.form.search,
          start_limit:0,
        })
        .then(rsp => {
          this.Loading('stop')
          this.dataTable = this.stCreateAdjustment
          this.totalData = this.stCreateAdjustmentCount
          this.showPerPage = this.perPage
          if (this.perPage >= this.stCreateAdjustmentCount) {
            this.showPerPage = this.stCreateAdjustmentCount
          }
        })
        .catch(err => {
          this.Loading('stop')
          if(err.response.status !== 401) {
            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
          }
        })
      },

    },
    mounted() {
      this.Setdata_IO()
      this.clearStatePpm()
    },
  }
</script>
<style src="./DashboardAdjustment.scss" lang="scss" scoped />
