<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/applications">
            Applications</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>{{ formName }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ modulName }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-container fluid>
        <b-card>
          <template>
            <b-card sub-title="Filter">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label="Category"
                    label-for="deptpermspc-input"
                    ref="opt_deptpermspc"
                    label-cols="3"
                    label-size="sm"
                  >
                    <multiselect
                      v-model="filters.categorymult"
                      :max-height="300"
                      :show-labels="false"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search Category"
                      label="name"
                      track-by="name"
                      :multiple="true"
                      :taggable="true"
                      :options="categoryOptMult"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group
                    label="Business Unit"
                    label-for="deptpermspc-input"
                    ref="opt_deptpermspc"
                    label-cols="3"
                    label-size="sm"
                  >
                    <multiselect
                      v-model="filters.business_unit"
                      :show-labels="false"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search Business Unit"
                      label="code"
                      :reduce="(i) => i.code"
                      :options="buOpt"
                    ></multiselect>
                  </b-form-group>
                  <!-- <b-form-group
                    label="Business Unit"
                    label-for="bu-select"
                    label-cols="3"
                    label-size="sm"
                    v-slot="{ ariaDescribedby }"
                  >
                    <v-select
                      id="bu-select"
                      v-model="filters.business_unit"
                      :options="buOpt"
                      placeholder="Search or add a tag"
                      size="sm"
                      label="code"
                      :reduce="(i) => i.code"
                      :aria-describedby="ariaDescribedby"
                    >
                    </v-select>
                  </b-form-group> -->
                  <b-form-group
                    label="Location"
                    label-for="location-select"
                    ref="opt_deptpermspc"
                    label-cols="3"
                    label-size="sm"
                  >
                    <multiselect
                      v-model="filters.location"
                      id="location-select"
                      :show-labels="false"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search Location"
                      label="location_name"
                      :reduce="(i) => i.location_name"
                      track-by="location_name"
                      :options="locationOpt"
                    ></multiselect>
                  </b-form-group>
                  <!-- <b-form-group
                    label="Location"
                    label-for="location-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="location-select"
                      v-model="filters.location"
                      :options="locationOpt"
                      size="sm"
                      label="location_name"
                      :reduce="(i) => i.location_name"
                    >
                    </v-select>
                  </b-form-group> -->
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Type"
                    label-for="deptpermspc-input"
                    ref="opt_deptpermspc"
                    label-cols="3"
                    label-size="sm"
                  >
                    <multiselect
                      v-model="filters.typemult"
                      tag-placeholder="Add this as new tag"
                      placeholder="Search Type"
                      label="type_doc"
                      track-by="type_doc"
                      :max-height="150"
                      :multiple="true"
                      :taggable="true"
                      :options="typeOptMult"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group
                    label="License Type"
                    label-for="license-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <multiselect
                      v-model="filters.permit_type"
                      placeholder="Search License Type"
                      :show-labels="false"
                      label="permit_type"
                      id="license-select"
                      :reduce="(i) => i.permit_type"
                      :options="licenseOpt"
                    ></multiselect>
                  </b-form-group>
                  <!-- <b-form-group
                    label="License Type"
                    label-for="license-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="license-select"
                      v-model="filters.permit_type"
                      :options="licenseOpt"
                      size="sm"
                      label="permit_type"
                      :reduce="(i) => i.permit_type"
                    >
                    </v-select>
                  </b-form-group> -->
                  <b-form-group
                    label="Year"
                    label-for="enddate-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <multiselect
                      v-model="filters.end_year"
                      :show-labels="false"
                      :max-height="200"
                      placeholder="Search License Type"
                      label="end_date"
                      id="enddate-select"
                      :reduce="(i) => i.end_date"
                      :options="setYear"
                    ></multiselect>
                  </b-form-group>
                  <!-- <b-form-group
                    label="Year"
                    label-for="enddate-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="enddate-select"
                      v-model="filters.end_year"
                      :options="setYear"
                      size="sm"
                      label="end_date"
                      :reduce="(i) => i.end_date"
                    >
                    </v-select>
                  </b-form-group> -->
                </b-col>
                <b-col cols="4">
                  <b-form-group label-size="sm">
                    <template v-slot:label> Search </template>
                    <b-input-group>
                      <b-form-input
                        id="inputSearch"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        trim
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Search</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <!-- <b-form-checkbox
                    v-model="filters.active"
                    name="check-button"
                    switch
                  >
                    <template v-if="filters.active === true"> Active </template>
                    <template v-else> Inactive </template>
                  </b-form-checkbox> -->
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:header>
            <b-row>
              <b-col sm="auto" md="9">
                <div class="text-left">
                  <h4>Legal Document</h4>
                </div>
              </b-col>
              <b-col sm="auto" md="3">
                <div class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    v-on:click="show = !show"
                    >Create</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
          <b-table
            responsive
            stickyColumn
            striped
            hover
            show-empty
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterDocument"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="includeFiled"
            head-variant="dark"
            ref="tableDocument"
          >
            <template #cell(is_active)="{ item }">
              <template v-if="item.is_active === false"> Inactive </template>
              <template v-else> Active </template>
            </template>

            <template v-slot:cell(start_date)="{ item }">
              <template v-if="item.start_date != null">
                {{
                  new Date(item.start_date).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </template>
            </template>
            <template v-slot:cell(end_date)="{ item }">
              <template v-if="item.end_date != null">
                {{
                  new Date(item.end_date).toLocaleDateString("id-ID", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </template>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <b-button
                title="Hapus"
                size="xs"
                variant="danger"
                v-on:click="deleteData(item.id)"
              >
                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
              </b-button>
              <b-button
                title="Ubah"
                size="xs"
                variant="warning"
                v-on:click="editData(item.id, item)"
              >
                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
              </b-button>
              <b-button
                title="View File"
                size="xs"
                variant="dark"
                v-on:click="viewPdf(item.id, item.file_src)"
              >
                <b-icon icon="paperclip" aria-label="Help"></b-icon>
              </b-button>
              <b-button
                title="View Data"
                size="xs"
                variant="info"
                v-on:click="detailData(item)"
              >
                <b-icon icon="eye-fill" aria-label="Help"></b-icon>
              </b-button>
            </template>
          </b-table>
          <template>
            <div class="mt-3">
              Showing {{ pageItems }} of {{ items.length }} entries
              <b-pagination
                v-model="paging.currentPage"
                :total-rows="rows"
                :per-page="paging.perPage"
                align="right"
              >
              </b-pagination>
            </div>
          </template>
        </b-card>
      </b-container>
      <template #overlay>
        <div ref="loadings" class="text-center">
          <b-icon
            variant="bbi_yellow"
            icon="arrow-clockwise"
            font-scale="3"
            animation="spin"
          ></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
    <template>
      <b-modal
        v-model="show"
        header-bg-variant="secondary"
        footer-bg-variant="secondary"
        hide-backdrop
        size="lg"
        ref="modal"
        no-close-on-backdrop
        title="Form Document Legal"
        hide-footer
        scrollable
        body-bg-variant="light"
        hide-header-close
      >
        <form-document
          :editItems="editItem"
          :itemId="editId"
          v-on:cancel="cancel()"
        />
      </b-modal>
    </template>
    <template>
      <b-modal
        v-model="showEdit"
        header-bg-variant="secondary"
        footer-bg-variant="secondary"
        hide-backdrop
        size="lg"
        ref="modal"
        no-close-on-backdrop
        title="Form Document Legal"
        hide-footer
        scrollable
        body-bg-variant="light"
      >
        <form-edit-document
          :editItems="editItem"
          :itemId="editId"
          v-on:cancel="cancel()"
        />
      </b-modal>
    </template>
    <template>
      <b-modal
        v-model="showDetail"
        header-bg-variant="secondary"
        footer-bg-variant="secondary"
        hide-backdrop
        size="lg"
        ref="modal"
        title="Document Legal"
        hide-footer
        scrollable
        body-bg-variant="light"
        cancel-title="Close"
      >
        <detail-document :detailItems="detailItem" :detailFile="detailFile" />
      </b-modal>
    </template>
  </div>
</template>

<script>
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../../api/api-methods";
import { APIPATH_DMS, APIPATH_BBIAPPS } from "../../../../../api/api-paths";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import FormDocument from "./FormDocument.vue";
import FormEditDocument from "./FormEditDocument.vue";
import DetailDocument from "./DetailDocument.vue";
// import vue multiselect
import Multiselect from "vue-multiselect";
export default {
  components: { FormDocument, FormEditDocument, DetailDocument, Multiselect },
  data() {
    return {
      formName: "Document Legal",
      modulName: "Dashboard",
      valuemulti: null,
      optionsmulti: ["list", "of", "options"],
      items: [],
      fields: [
        { key: "actions", label: "#" },
        { key: "business_unit", label: "B. Unit", sortable: true },
        // { key: "location", label: "Location", sortable: true },
        { key: "category_name", label: "Category", sortable: true },
        { key: "permit_type", label: "License Type", sortable: true },
        { key: "type_dms", label: "Type", sortable: true },
        { key: "permit_no", label: "License No.", sortable: true },
        {
          key: "extend_no",
          label: "Extend No.",
          formatter: this.setExtendNo,
        },
        { key: "start_date", label: "Start", sortable: true },
        { key: "end_date", label: "End", sortable: true },
        { key: "reminder", label: "Reminder (Month)", sortable: true },
        { key: "is_active", label: "Status" },
        { key: "created_by", label: "Created By" },
        {
          key: "created_at",
          label: "Created At",
          formatter: this.setDateFormat,
        },
        { key: "modified_by", label: "Updated By" },
        {
          key: "modified_at",
          label: "Updated At",
          formatter: this.setDateFormat,
        },
      ],
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      isLoading: false,
      show: false,
      editItem: null,
      editId: null,
      showEdit: false,
      deleted_by: null,
      filter: null,
      filters: {
        business_unit: [],
        category: "",
        categorymult: "",
        typemult: "",
        location: "",
        permit_type: "",
        end_year: "",
        active: true,
      },
      buOpt: [],
      categoryOptMult: [],
      typeOptMult: [],
      locationOpt: [],
      licenseOpt: [],
      endDateOpt: [],
      includeFiled: [
        "business_unit",
        "location",
        "category_name",
        "permit_type",
        "type_dms",
        "end_year",
        "permit_no",
      ],
      showDetail: false,
      detailItem: null,
      detailFile: null,
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),

    rows() {
      if (this.items) {
        return this.filterDocument.length;
      }
    },
    filterDocument() {
      var bu = this.filters.business_unit;
      var location = this.filters.location;
      // var category = this.filters.category;
      var categorymult = this.filters.categorymult;
      var typemult = this.filters.typemult;
      var permit = this.filters.permit_type;
      var end = this.filters.end_year;
      var status = this.filters.active;
      var categoryOptMultSelected = [];
      var typeOptMultSelected = [];
      for (let catmult = 0; catmult < categorymult.length; catmult++) {
        categoryOptMultSelected.push(categorymult[catmult]["name"]);
      }
      for (let tmul = 0; tmul < typemult.length; tmul++) {
        typeOptMultSelected.push(typemult[tmul]["type_doc"]);
      }

      if (bu == "" || bu == null) {
        bu == null;
      } else {
        bu = bu.code;
      }
      if (location == "" || location == null) {
        location == null;
      } else {
        location = location.location_name;
      }
      if (permit == "" || permit == null) {
        permit == null;
      } else {
        permit = permit.permit_type;
      }
      if (end == "" || end == null) {
        end == null;
      } else {
        end = end.end_date;
      }

      return this.items.filter(function (item) {
        var filtereds = true;
        if (bu && bu.length > 0) {
          filtereds = item.business_unit === bu;
        }
        if (filtereds) {
          if (categoryOptMultSelected.length > 0) {
            var addOrParam = "";
            for (let sel = 0; sel < categoryOptMultSelected.length; sel++) {
              if (sel == 0) {
                addOrParam =
                  item.category_name === categoryOptMultSelected[sel];
              } else {
                addOrParam +=
                  "" || item.category_name === categoryOptMultSelected[sel];
              }
              filtereds = addOrParam;
            }
          }
        }
        if (filtereds) {
          if (typeOptMultSelected.length > 0) {
            var addOrParamType = "";
            for (let sel = 0; sel < typeOptMultSelected.length; sel++) {
              if (sel == 0) {
                addOrParamType = item.type_dms === typeOptMultSelected[sel];
              } else {
                addOrParamType +=
                  "" || item.type_dms === typeOptMultSelected[sel];
              }
              filtereds = addOrParamType;
            }
          }
        }
        if (filtereds) {
          if (location && location.length > 0) {
            filtereds = item.location === location;
          }
        }
        if (filtereds) {
          if (permit && permit.length > 0) {
            filtereds = item.permit_type === permit;
          }
        }
        if (filtereds) {
          if (end) {
            // if (item.end_year.indexOf(end) > -1) {
            filtereds = item.end_year === end;
            // }
          }
        }
        if (filtereds) {
          if (status === false) {
            filtereds = item.is_active === false;
          } else {
            filtereds = item.is_active === true;
          }
        }
        // console.log(filtereds);
        return filtereds;
      });
    },
    setYear() {
      return _.uniqBy(this.endDateOpt, function (u) {
        if (parseInt(u.end_date)) {
          return parseInt(u.end_date);
        }
      });
    },
    pageItems() {
      var totalPage = Math.ceil(this.rows / this.paging.perPage);
      if (this.paging.currentPage === totalPage) {
        return (
          (this.rows % this.paging.perPage) +
          this.paging.perPage * totalPage -
          this.paging.perPage
        );
      } else {
        return this.paging.perPage * this.paging.currentPage;
      }
    },

    // setLocation(){
    //     var location = this.locationOpt.filter(i=>i.business_unit === this.filters.business_unit)
    //     return location
    // }
  },
  methods: {
    toggleUnSelectMarket({ value, id }) {
      this.toggleUnSelectLojas(value, id);
    },
    setDateFormat(value) {
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      var formats = new Date(value).toLocaleDateString("id-ID", options);
      if (value) {
        return formats;
      }
    },
    getDocumentLegal() {
      Vue.set(this, "isLoading", true);
      fnGET(APIPATH_DMS.getDocumentLegal)
        .then((rsp) => {
          // console.log("rsp", rsp.data.payload);
          Vue.set(this, "items", rsp.data.payload);

          var year = rsp.data.payload;
          var yearArr = [];
          for (let i = 0; i < year.length; i++) {
            let years = {
              end_date: year[i].end_year,
            };
            if (years.end_date) {
              yearArr.push(years);
            }
          }
          Vue.set(this, "endDateOpt", _.uniqBy(yearArr));
        })
        .catch((err) => {
          return err;
        })
        .then(() => {
          Vue.set(this, "isLoading", false);
        });
    },
    editData(id, item) {
      Vue.set(this, "editItem", item);
      Vue.set(this, "editId", id);

      Vue.set(this, "show", true);
    },
    detailData(item) {
      console.log("item", item);
      fnPOST(APIPATH_DMS.getDocumentFile, { document_id: item.id })
        .then((rsp) => {
          let data = rsp.data.payload
          console.log(rsp.data.payload);
          if (rsp.data.payload) {
            for (let a=0; a<data.length; a++) {
              data[a].file_src = data[a].file_src.replace(":8005",":8000")
            }
          }
          Vue.set(this, "detailFile", data);
        })
        .catch((err) => {
          return err;
        })
        .then(() => {
          Vue.set(this, "isLoading", false);
        });
      Vue.set(this, "detailItem", item);

      Vue.set(this, "showDetail", true);
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            Vue.set(this, "isLoading", true);
            fnPUT(APIPATH_DMS.deleteDocumentLegal, {
              document_id: id,
              deleted_by: this.deleted_by,
            })
              .then((rsp) => {
                this.cancel();
                this.showNotification({
                  type: "success",
                  message: "Successfully deleted",
                });
              })
              .catch((err) => {
                return err;
              })
              .then(Vue.set(this, "isLoading", false));
          }
        });
    },
    viewPdf(id, file) {
      // let files = []
      fnPOST(APIPATH_DMS.getDocumentFile, { document_id: id })
        .then((rsp) => {
          // files = rsp.data.payload
          let data = rsp.data.payload
          for (let i = 0; i < rsp.data.payload.length; i++) {
            if (data) {
              data[i].file_src = data[i].file_src.replace(":8005",":8000")
            }
            window.open(data[i].file_src, "_blank");
          }
        })
        .catch((err) => {
          return err;
        });

      // window.open(file,'_blank')
    },
    cancel() {
      Vue.set(this, "show", false);
      Vue.set(this, "showEdit", false);
      // Vue.set(this,'showDetail',false)
      Vue.set(this, "editItem", null);
      Vue.set(this, "editId", null);
      this.getDocumentLegal();
    },
    getUserData() {
      Vue.set(this, "deleted_by", this.userData.fullname);
    },
    getBU() {
      fnGET(APIPATH_BBIAPPS.listBU)
        .then((rsp) => {
          Vue.set(this, "buOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getLocation() {
      fnGET(APIPATH_DMS.getLocation)
        .then((rsp) => {
          var locations = rsp.data.payload.filter((i) => i.is_active === true);
          Vue.set(this, "locationOpt", locations);
        })
        .catch((err) => {
          return err;
        });
    },
    getPermitType() {
      fnGET(APIPATH_DMS.getPermit)
        .then((rsp) => {
          var permits = rsp.data.payload.filter((i) => i.is_active === true);
          Vue.set(this, "licenseOpt", permits);
        })
        .catch((err) => {
          return err;
        });
    },
    setExtendNo(value, key, item) {
      var extNo = this.items.find((i) => i.id == value);

      if (extNo) {
        return extNo.permit_no;
      }
    },
    getCategoryMult() {
      fnGET(APIPATH_DMS.getCategory)
        .then((rsp) => {
          Vue.set(this, "categoryOptMult", rsp.data.payload);
          // for (let index = 0; index < rsp.data.payload.length; index++) {
          //   Vue.set(this, "categoryOptMult", rsp.data.payload[index]["code"]);
          // }
        })
        .catch((err) => {
          return err;
        });
    },
    getTypeMult() {
      fnGET(APIPATH_BBIAPPS.listType)
        .then((rsp) => {
          Vue.set(this, "typeOptMult", rsp.data);
          // for (let index = 0; index < rsp.data.payload.length; index++) {
          //   Vue.set(this, "categoryOptMult", rsp.data.payload[index]["code"]);
          // }
        })
        .catch((err) => {
          return err;
        });
    },
  },
  created() {
    this.getDocumentLegal();
    this.getUserData();
    this.getCategoryMult();
    this.getTypeMult();
    this.getBU();
    this.getLocation();
    this.getPermitType();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__element {
  display: block;
  font-size: 12px;
}

/* .multiselect.invalid .multiselect__tags,
.multiselect.invalid .multiselect__tags span,
.multiselect.invalid .multiselect__tags input {
  background: red !important;
} */
.multiselect__placeholder {
  display: inline-block !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
}

.multiselect.invalid .multiselect__tags {
  border: 1px solid #f86c6b !important;
}

.multiselect__option--highlight {
  background: #428bca !important;
}

.multiselect__option--highlight:after {
  background: #428bca !important;
}

.multiselect__tags {
  padding: 5px !important;
  min-height: 10px;
}

.multiselect__placeholder {
  margin-left: 10px;
  margin-top: 2px;
  font-size: 13px;
}
.multiselect__single {
  font-size: 13px;
}
.multiselect__input {
  font-size: 13px;
}
.multiselect {
  font: 13px;
  font-size: 13px;
}
.multiselect__tag {
  background: #f0f0f0 !important;
  border: 1px solid rgba(60, 60, 60, 0.26) !important;
  color: black !important;
  margin-bottom: 0px !important;
  margin-right: 5px !important;
}

.multiselect__tag-icon:after {
  color: rgba(60, 60, 60, 0.5) !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: #f0f0f0 !important;
}

.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: red !important;
}

.form-control {
  border: 1px solid #e8e8e8;
  color: #adadad !important;
}

::placeholder {
  color: #adadad !important;
}

.table th,
.table td {
  font-size: 13px;
}
</style>