<template>
    <div>
            <b-card title="Product Design">
                <b-row>
                    <b-col cols="12" sm="auto">
                        <b-form-group
                            
                            label-cols-sm   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Brand <code>*</code>
                            </template>
            
                             <label> {{product.brand}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Collection Name<code>*</code>
                            </template>
                             <label> {{product.collectionName}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Period<code>*</code>
                            </template>
                             <label> {{product.period}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Quartal<code>*</code>
                            </template>
                             <label> {{product.quartal}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"  
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Prod. Month<code>*</code>
                            </template>
                             <label> {{product.prodMonth}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Coll. Month<code>*</code>
                            </template>
                             <label> {{product.collMonth}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Theme<code>*</code>
                            </template>
                             <label> {{product.theme}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Sub. Brand<code>*</code>
                            </template>
                             <label> {{product.subBrand}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"  
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Code<code>*</code>
                            </template>
                             <label> {{product.code}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"  
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Combo
                            </template>
                             <label> {{product.combo}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Detail Combo
                            </template>
                             <label> {{product.detailCombo}}</label>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" sm="auto">
                        <b-form-group
                            
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Article<code>*</code>
                            </template>
                             <label> {{product.article}}</label>
                        </b-form-group>
                        
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Model<code>*</code>
                            </template>
                             <label> {{product.model}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                No. Sample<code>*</code>
                            </template>
                             <label> {{product.noSample}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Style<code>*</code>
                            </template>
                             <label> {{product.style}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Fitting<code>*</code>
                            </template>
                             <label> {{product.fitting}}</label>
                        </b-form-group>
                        
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Category<code>*</code>
                            </template>
                             <label> {{product.category}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Thread Body
                            </template>
                             <label> {{product.threadBody}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Thread Combo
                            </template>
                             <label> {{product.threadCombo}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                            Thread Botton
                        </template>
                             <label> {{product.threadBotton}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Thread Bottonhole
                            </template>
                             <label> {{product.threadBottonhole}}</label>
                        </b-form-group>
                        
                    </b-col>
                    <b-col cols="auto">
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Design<code>*</code>
                            </template>
                            <div v-for="img in product.design" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Main Fabric
                            </template>
                            <div v-for="img in product.mainFabric" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Label
                            </template>
                            <div v-for="img in product.label" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        
                    </b-col>
                </b-row>
            </b-card>
            <b-card title="Accessories">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="accList"
                    :fields="fieldsAcc"
                >
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>

                </b-table>
            </b-card>
            <b-card title="Botton">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="bottonList"
                    :fields="fieldsBotton"
                >
                    <template #cell(images)="{item}"   >
                        <p class="small" v-for="img in item.images" :key="img.src">
                            <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                        </p>
                    </template>

                </b-table>
            </b-card>
            <b-card title="Artwork">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="artworkList"
                    :fields="fieldsArtwork"
                >
                    <template #cell(images)="{item}"   >
                        <p class="small" v-for="img in item.images" :key="img.src">
                            <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                        </p>
                    </template>

                </b-table>
            </b-card>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
            productDesign:[],
            fieldsAcc:[
                'code','type','supplier','detail','images'
            ],
            fieldsBotton:[
                'code','supplier','color','size','images'
            ],
            fieldsArtwork:[
                'code','type','supplier','detail','images'
            ],

        }
    },
    methods:{
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        }
    },
    computed:{
        ...mapGetters('productDesign',['product','accList','bottonList','artworkList'])
    }
    
}
</script>