<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col cols="6">
            <!-- Business Unit -->
            <validation-provider
              name="business-unit"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Business Units </template>
                <b-form-select
                  value-field="code"
                  text-field="name"
                  id="inputBusinessUnit"
                  v-model="form.business_unit"
                  :options="businessUnitOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputBusinessUnit">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Business Unit -->
            <!-- Permit Type  -->
            <validation-provider
              name="permit_type"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> License Type </template>
                <b-form-select
                  value-field="id"
                  text-field="permit_type"
                  @change="setReminder()"
                  id="inputPermitType"
                  v-model="form.permit_type"
                  :options="permitTypeOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputPermitType">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Permit Type  -->
            <!-- PermitNumber  -->
            <validation-provider
              name="PermitNumber"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Permit Number </template>
                <b-form-input
                  id="inputPermitNumber"
                  v-model="form.permit_no"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="inputDurations">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /PermitNumber  -->
            <!-- Institution  -->
            <validation-provider
              name="institution"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Institution </template>
                <b-form-select
                  value-field="id"
                  text-field="name"
                  id="inputInstitution"
                  v-model="form.institution"
                  :options="institutionOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputInstitution">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Institution  -->
            <!-- Location Type -->
            <validation-provider
              name="Location Type"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Location Type </template>
                <b-form-select
                  value-field="id"
                  text-field="location_name"
                  id="inputLocationType"
                  v-model="form.location"
                  :options="setLocation"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputLocationType">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Location Type -->
            <!-- Reminder  -->
            <validation-provider
              name="reminder"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Reminder </template>
                <b-form-select
                  readonly
                  text-field="reminder"
                  value-field="id"
                  id="inputReminder"
                  v-model="form.reminder"
                  :options="setRemind"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputReminder">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Reminder  -->
            <!-- Start Date  -->
            <validation-provider
              name="StartDate"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Start Date </template>
                <b-form-datepicker
                  id="inputStartDate"
                  v-model="form.start_date"
                  today-button
                  reset-button
                  close-button
                  locale="id"
                  :state="getValidationState(validationContext)"
                ></b-form-datepicker>
                <b-form-invalid-feedback id="inputStartDate">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Start Date  -->
            <!-- End Date  -->
            <validation-provider
              name="EndDate"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> End Date </template>
                <b-form-datepicker
                  id="inputEndDate"
                  v-model="form.end_date"
                  today-button
                  reset-button
                  close-button
                  locale="id"
                  :state="getValidationState(validationContext)"
                  @context="countDuration"
                ></b-form-datepicker>
                <b-form-invalid-feedback id="inputEndDate">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /End Date  -->
            <!-- Durations  -->
            <validation-provider
              name="Durations"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Durations </template>
                <b-form-input
                  readonly
                  id="inputDurations"
                  v-model="form.durations"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="inputDurations">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Durations  -->
            <!-- Remind Date  -->
            <validation-provider
              name="RemindDate"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Remind Date </template>
                <b-form-input
                  readonly
                  id="inputRemindDate"
                  v-model="form.remind_date"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="inputRemindDate">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Remind Date  -->
          </b-col>
          <b-col cols="6">
            <!-- Extend  -->
            <validation-provider
              name="Extend"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Extend </template>
                <b-form-radio-group
                  id="inputExtend"
                  v-model="form.extend"
                  :options="extendOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-radio-group>
                <b-form-invalid-feedback id="inputDurations">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Extend  -->
            <!-- Extend Number  -->
            <validation-provider
              name="extendNumber"
              :rules="{ required_if: form.extend == 'Yes' }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Extend Number </template>
                <b-form-select
                  :disabled="form.extend !== 'Yes'"
                  value-field="id"
                  text-field="permit_no"
                  id="inputExtendNumber"
                  v-model="form.extend_no"
                  :options="extendNumberOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputExtendNumber">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Extend Number  -->
            <!-- Level Institution  -->
            <validation-provider
              name="LevelInstitution"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Level Institution </template>
                <b-form-select
                  text-field="level_name"
                  value-field="id"
                  id="inputLevelInstitution"
                  v-model="form.level"
                  :options="levelOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputLevelInstitution">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Level Institution  -->
            <!-- Province  -->
            <validation-provider
              name="Province"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Provinsi </template>
                <b-form-select
                  value-field="name"
                  text-field="name"
                  id="inputProvince"
                  @change="setCity()"
                  v-model="form.province"
                  :options="provinceOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputProvince">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Province  -->
            <!-- City  -->
            <validation-provider
              name="City"
              :rules="{ required: false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Kota </template>
                <b-form-select
                  value-field="name"
                  text-field="name"
                  id="inputCity"
                  @change="setDistrict()"
                  v-model="form.city"
                  :options="cityOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputCity">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /City  -->
            <!-- District  -->
            <validation-provider
              name="District"
              :rules="{ required: false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Kecamatan </template>
                <b-form-select
                  value-field="name"
                  text-field="name"
                  id="inputDistrict"
                  @change="setWard()"
                  v-model="form.district"
                  :options="districtOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputDistrict">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /District  -->
            <!-- Ward  -->
            <validation-provider
              name="Ward"
              :rules="{ required: false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Kelurahan </template>
                <b-form-select
                  value-field="name"
                  text-field="name"
                  id="inputWard"
                  v-model="form.ward"
                  :options="wardOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputWard">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Ward  -->
            <!-- Storage Location -->
            <validation-provider
              name="StorageLocation"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Storage Location </template>
                <b-form-select
                  value-field="code"
                  text-field="name"
                  id="inputStorageLocation"
                  v-model="form.file_location"
                  :options="businessUnitOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-select>
                <b-form-invalid-feedback id="inputStorageLocation">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Storage Location -->
            <!-- PIC -->
            <validation-provider
              name="PIC"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> PIC </template>
                <v-select
                  :reduce="(dept_code) => dept_code.dept_code"
                  v-model="form.pic_dept"
                  :options="picOpt"
                  placeholder="Type your code number"
                  label="dept_code"
                >
                </v-select>
                <b-form-invalid-feedback id="inputPIC">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /PIC -->
            <!-- DocumentFile -->
            <validation-provider
              name="DocumentFile"
              :rules="{ required: false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Document File </template>
                <b-form-file
                  ref="fileDocument"
                  id="DocumentFile"
                  name="document-legal"
                  v-model="form.document_file"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose file here..."
                  no-drop
                  v-on:change="onFileUpload"
                ></b-form-file>
                <div v-for="file in form.file_src" :key="file.src">
                  <p class="small">
                    <a :href="file.src" target="_blank">{{ file.name }}</a>
                    <b-badge
                      href="#"
                      v-on:click="removeFileUpload(file.name)"
                      variant="light"
                    >
                      <b-icon
                        icon="x-circle-fill"
                        font-scale="1"
                        variant="danger"
                      ></b-icon>
                    </b-badge>
                  </p>
                </div>
                <b-form-invalid-feedback id="DocumentFile">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /DocumentFile -->
            <b-button type="submit" variant="success">Save</b-button>
            <b-button @click="cancel()" variant="warning">Cancel</b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-button @click="$emit('testAlert')">
          Save
      </b-button> -->
  </div>
</template>

<script>
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../../api/api-methods";
import {
  APIPATH_DMS,
  APIPATH_GENERAL,
  APIPATH_BBIAPPS,
} from "../../../../../api/api-paths";
import Vue from "vue";
import "vue-select/dist/vue-select.css";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["editItems"],
  data() {
    return {
      isLoading: false,
      form: {
        business_unit: null,
        permit_type: null,
        institution: null,
        start_date: null,
        end_date: null,
        durations: null,
        remind_date: null,
        permit_no: null,
        extend: null,
        extend_no: null,
        level: null,
        province: null,
        city: null,
        district: null,
        ward: null,
        location: null,
        created_by: null,
        modified_by: null,
        deleted_by: null,
        document_file: [],
        reminder: null,
        file_id: null,
        file_src: [],
        file_name: null,
        file_label: null,
        file_location: null,
        document_id: null,
      },
      businessUnitOpt: [],
      permitTypeOpt: [],
      institutionOpt: [],
      extendOpt: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      extendNumberOpt: [],
      levelOpt: [],
      provinceOpt: [],
      cityOpt: [],
      districtOpt: [],
      wardOpt: [],
      locationOpt: [],
      reminderOpt: [],
      picOpt: [],
    };
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getUserData() {
      Vue.set(this.form, "created_by", this.userData.fullname);
    },
    getPermitType() {
      Vue.set(this, "isLoading", true);
      fnGET(APIPATH_DMS.getPermit)
        .then((rsp) => {
          Vue.set(this, "permitTypeOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
      Vue.set(this, "isLoading", false);
    },
    getDept() {
      fnGET(APIPATH_BBIAPPS.listDepartment)
        .then((rsp) => {
          Vue.set(this, "picOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getLevel() {
      fnGET(APIPATH_DMS.getLevel)
        .then((rsp) => {
          Vue.set(this, "levelOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getInstitution() {
      fnGET(APIPATH_DMS.getInstitution)
        .then((rsp) => {
          Vue.set(this, "institutionOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getBU() {
      fnGET(APIPATH_BBIAPPS.listBU)
        .then((rsp) => {
          Vue.set(this, "businessUnitOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getLocation() {
      fnGET(APIPATH_DMS.getLocation)
        .then((rsp) => {
          Vue.set(this, "locationOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getReminder() {
      fnGET(APIPATH_DMS.getReminder)
        .then((rsp) => {
          Vue.set(this, "reminderOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getProvinciList() {
      fnPOST(APIPATH_GENERAL.cityList, { category: "provinsi" })
        .then((rsp) => {
          Vue.set(this, "provinceOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    setCity() {
      if (this.form.province) {
        fnPOST(APIPATH_GENERAL.cityList, {
          category: "kota",
          provinsi: this.form.province,
        })
          .then((rsp) => {
            Vue.set(this, "cityOpt", rsp.data.payload);
          })
          .catch((err) => {
            return err;
          });
      }
    },
    setDistrict() {
      if (this.form.city) {
        fnPOST(APIPATH_GENERAL.cityList, {
          category: "kecamatan",
          kota: this.form.city,
        })
          .then((rsp) => {
            Vue.set(this, "districtOpt", rsp.data.payload);
          })
          .catch((err) => {
            return err;
          });
      }
    },
    setWard() {
      if (this.form.district) {
        fnPOST(APIPATH_GENERAL.cityList, {
          category: "kelurahan",
          kecamatan: this.form.district,
        })
          .then((rsp) => {
            Vue.set(this, "wardOpt", rsp.data.payload);
          })
          .catch((err) => {
            return err;
          });
      }
    },
    setReminder() {
      if (this.form.permit_type) {
        var reminds = this.permitTypeOpt.filter(
          (i) => i.id === this.form.permit_type
        );
        Vue.set(this.form, "reminder", reminds[0].reminder);
      }
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;

      months -= d1.getMonth() + 1;
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    dayDiff(d1, d2) {
      var minutes = 1000 * 60;
      var hours = minutes * 60;
      var days = hours * 24;
      var months = days * 30;

      var diff_date = Math.round((d2 - d1) / days);

      // var diff_month = Math.floor((d2 - d1)/months)

      return diff_date;
    },
    setRemindDate(months) {
      var reminderMonth = this.reminderOpt.find(
        (i) => i.id === this.form.reminder
      );
      var d2 = new Date(this.form.end_date);
      var days = reminderMonth.reminder * 30;
      var last = new Date(d2.getTime() - days * 24 * 60 * 60 * 1000);
      var day = last.getDate();
      var month = last.getMonth() + 1;
      var year = last.getFullYear();

      Vue.set(this.form, "remind_date", year + "-" + month + "-" + day);
    },
    countDuration() {
      if (this.form.start_date && this.form.end_date && this.form.reminder) {
        var d1 = new Date(this.form.start_date);
        var d2 = new Date(this.form.end_date);

        var month = Math.floor(this.dayDiff(d1, d2) / 30);

        this.setRemindDate(month);
        return (this.form.durations = month);
      }
    },
    getDocumentNumber() {
      fnGET(APIPATH_DMS.getDocumentLegal)
        .then((rsp) => {
          Vue.set(this, "extendNumberOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    onSubmit() {
      Vue.set(this, "isLoading", true);
      const fd = new FormData();
      fd.append("document_id", this.form.document_id);
      fd.append("file_id", this.form.file_id);
      fd.append("file_src", this.form.file_src[0].file_src);
      fd.append("file_name", this.form.file_src[0].name);
      fd.append("file_label", this.form.file_src[0].file_label);
      fd.append("business_unit", this.form.business_unit);
      fd.append("permit_type_id", this.form.permit_type);
      fd.append("institution_id", this.form.institution);
      fd.append("start_date", this.form.start_date);
      fd.append("end_date", this.form.end_date);
      fd.append("duration", this.form.durations);
      fd.append("remind_date", this.form.remind_date);
      fd.append("permit_no", this.form.permit_no);
      fd.append("extend", this.form.extend);
      fd.append("extend_no", this.form.extend_no);
      fd.append("level_id", this.form.level);
      fd.append("province", this.form.province);
      fd.append("city", this.form.city);
      fd.append("district", this.form.district);
      fd.append("ward", this.form.ward);
      fd.append("location_id", this.form.location);
      fd.append("reminder_id", this.form.reminder);
      fd.append("pic_dept", this.form.pic_dept);
      fd.append("modified_by", this.form.created_by);
      fd.append("file_location", this.form.file_location);

      fnPUT(APIPATH_DMS.putDocumentLegal, fd)
        .then((rsp) => {
          this.cancel();
          this.showNotification({
            type: "success",
            message: "Successfully Submitted",
          });
        })
        .catch((err) => {
          return err;
        })
        .then(Vue.set(this, "isLoading", false));
    },
    cancel() {
      this.form.business_unit = null;
      this.form.permit_type = null;
      this.form.institution = null;
      this.form.start_date = null;
      this.form.end_date = null;
      this.form.durations = null;
      this.form.remind_date = null;
      this.form.permit_no = null;
      this.form.extend = null;
      this.form.extend_no = null;
      this.form.level = null;
      this.form.province = null;
      this.form.city = null;
      this.form.district = null;
      this.form.ward = null;
      this.form.location = null;
      this.form.created_by = null;
      this.form.document_file = [];
      this.form.reminder = null;
      this.form.pic_dept = null;
      this.form.file_location = null;
      this.form.file_src = [];
      this.form.file_name = null;
      this.form.file_label = null;

      this.$emit("cancel");
    },
    onFileUpload(e) {
      var file = e.target.files;
      var pathName = e.target.name;
      this.form.file_src = [];

      for (var i = 0; i < file.length; i++) {
        let files = {
          src: URL.createObjectURL(file[i]),
          name: file[i].name,
          file_src: file[i],
          file_label: pathName,
        };
        this.form.file_src.push(files);
      }
    },
    formatNamesUploadFiles(e) {
      var idField = e.target.id;
      return this.form.file_src.length === 0
        ? this.$refs.fileDocument.reset()
        : `${this.form.file_src.length} files selected`;
    },
    removeFileUpload: function (filename) {
      this.form.file_src.splice(filename, 1);
      return this.$refs.fileDocument.reset();
    },
    setEdit() {
      if (this.editItems) {
        console.log("editItems", this.editItems.file_src);
        this.form.document_id = this.editItems.id;
        this.form.business_unit = this.editItems.business_unit;
        this.form.permit_type = this.editItems.permit_type_id;
        this.form.institution = this.editItems.institution_id;
        this.form.start_date = this.editItems.start_date;
        this.form.end_date = this.editItems.end_date;
        this.form.durations = this.editItems.durations;
        this.form.remind_date = this.editItems.remind_date;
        this.form.permit_no = this.editItems.permit_no;
        this.form.extend = this.editItems.extend;
        this.form.extend_no = this.editItems.extend_no;
        this.form.level = this.editItems.level_id;
        this.form.province = this.editItems.province;
        this.form.city = this.editItems.city;
        this.form.district = this.editItems.district;
        this.form.ward = this.editItems.ward;
        this.form.location = this.editItems.location_id;
        (this.form.document_file = []),
          (this.form.reminder = this.editItems.reminder_id);
        this.form.pic_dept = this.editItems.pic_dept;
        this.form.file_location = this.editItems.file_location;
        this.form.file_id = this.editItems.file_id;
        var file = {
          src: this.editItems.file_src,
          file_src: this.editItems.file_src,
          file_name: this.editItems.file_name,
          name: this.editItems.file_name,
          file_label: this.editItems.file_label,
        };

        this.form.file_src.push(file);
        this.form.file_name = null;
        this.form.file_label = null;
        if (this.form.province) {
          this.setCity();
        }
        if (this.form.city) {
          this.setDistrict();
        }
        if (this.form.district) {
          this.setWard();
        }
      }
    },
  },
  created() {
    this.getUserData();
    this.getBU();
    this.getPermitType();
    this.getInstitution();
    this.getLevel();
    this.getProvinciList();
    this.getDocumentNumber();
    this.getLocation();
    this.getReminder();
    this.getDept();
    this.setEdit();
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    setRemind() {
      if (this.form.permit_type && this.form.location) {
        var reminder = this.reminderOpt.filter(
          (i) =>
            i.permit === this.form.permit_type &&
            i.location === this.form.location
        );
        if (reminder.length > 0) {
          return reminder;
        } else {
          return [{ reminder: "Not Found...", disabled: true }];
        }
      }
    },
    setLocation() {
      if (this.form.business_unit) {
        var location = this.locationOpt.filter(
          (i) => i.business_unit === this.form.business_unit
        );
        if (location.length > 0) {
          return location;
        } else {
          return [{ location_name: "Not Found...", disabled: true }];
        }
      }
    },
  },
};
</script>

<style>
</style>