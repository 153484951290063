<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-actual-activity"
          >Actual Activity</router-link
        >
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>

    <b-list-group flush>
      <b-list-group-item>
        <b-row align-h="start">
          <b-col sm="12">
            <b-form-group
              label="Project ID"
              label-for="Project ID"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <b-form-input
                id="Project ID"
                :disabled="true"
                v-model="ProjectID"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col sm="12">
            <b-form-group
              label="Customer Name"
              label-for="CustomerName"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <b-form-input
                id="CustomerName"
                :disabled="true"
                v-model="form.customer_name"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="start">
          <b-col sm="12">
            <b-form-group
              label="Actual Date"
              label-for="ActualDate"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <v-date-picker
                class="inline-block h-full"
                v-model="form.actual_date"
                color="indigo"
                mode="dateTime"
                :timezone="timezone"
                :min-date="NowDate"
                :max-date="new Date()"
                :masks="masks"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex items-center" v-on="inputEvents">
                    <button
                      class="p-1 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                    >
                      <b-icon
                        scale="1"
                        style="color: black"
                        icon="calendar3"
                      ></b-icon>
                    </button>
                    <input
                      style="width: 77%"
                      :value="inputValue"
                      class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                      readonly
                    />
                  </div>
                </template>
              </v-date-picker>
              <!-- <VueDatePicker
                                style="border: 1px solid #c1ccd3;border-radius: 0.3rem; margin-bottom:5px;"
                                class="vd__div"
                                :required="true"
                                v-model="form.actual_date"
                                no-header
                                format="ddd D MMM YYYY"
                                placeholder="Choose date"
                                :min-date="NowDate"
                                />
                                <b-time
                                    v-model="form.actual_time"
                                    locale="en"
                                    :hour12="false"
                                    :isRTL="true"
                                    :hide-header="true"
                                    @context="onContext"
                                >
                                </b-time> -->
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="start">
          <b-col sm="12">
            <b-form-group
              label="Actual"
              label-for="Actual"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <div style="display: flex">
                <multiselect
                  v-model="SelectActivity"
                  :disabled="DisableActivity"
                  tag-placeholder="Add this as new tag"
                  placeholder="Search or add Activity"
                  label="name"
                  @input="InputActivity"
                  :options="optionsActivity"
                  track-by="name"
                  openDirection="bottom"
                  :multiple="true"
                  :taggable="true"
                >
                </multiselect>
                <b-button
                  v-if="ButtonChange"
                  @click="changeActivity"
                  variant="light"
                  style="background: #fff0; display: flex"
                >
                  <b-icon
                    scale="0.7"
                    style="color: black"
                    icon="pencil-fill"
                  ></b-icon>
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="start" v-if="hiddenOptions">
          <b-col sm="12">
            <b-form-group
              label="Options"
              label-for="Options"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <v-select
                append-to-body
                :clearable="false"
                :reduce="(data) => data.name"
                v-model="form.sub_activity_name"
                @input="InputSubActivity"
                :options="optionsSubActivity"
                placeholder="Select Your Options"
                label="name"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="start" v-if="hiddenPOID">
          <b-col sm="12">
            <b-form-group
              label="PO ID"
              label-for="PO ID"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <b-form-input
                id="PO ID"
                v-model="form.po_id"
                trim
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="start" v-if="hiddenReason">
          <b-col sm="12">
            <b-form-group
              label="Reason"
              label-for="Reason"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <v-select
                append-to-body
                :clearable="false"
                :reduce="(data) => data.reason"
                v-model="form.reason"
                :options="optionsReason"
                placeholder="Select Your Reason"
                label="reason"
              >
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row align-h="start">
          <b-col sm="12">
            <b-form-group
              label="Remark"
              label-for="Remark"
              label-cols-sm="4"
              label-align-sm="left"
              label-size="sm"
            >
              <b-form-textarea
                id="textarea-no-resize"
                placeholder="Enter Your Remarks..."
                rows="3"
                v-model="form.remark"
                no-resize
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <br />
      </b-list-group-item>

      <!-- Sales Projection -->

      <b-list-group-item>
        <accordion>
          <accordion-item>
            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
            <template slot="accordion-trigger">
              <h4>
                Sales Projection
                <b-icon
                  scale="2"
                  style="
                    color: black;
                    position: relative;
                    padding: 5px;
                    top: 2px;
                  "
                  icon="chevron-down"
                ></b-icon>
                <span
                  id="warning-sales_projection"
                  style="margin-left: 10px"
                  class="display-hidden text-small text-danger"
                  >Required
                </span>
              </h4>
            </template>
            <!-- This slot will handle all the content that is passed to the accordion -->
            <template slot="accordion-content">
              <br />
              <b-row align-h="start">
                <!-- EstShipmentMonth -->
                <b-col sm="12">
                  <b-form-group
                    label="Est Shipment Month"
                    label-for="EstShipmentMonth"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <VueDatePicker
                      v-model="form.estShipMonth"
                      class="vd__div"
                      no-header
                      placeholder="Choose Month"
                      :min-date="NowDate"
                      type="month"
                    >
                      <template #input-icon>
                        <b-icon
                          scale="1"
                          style="color: black"
                          icon="calendar3"
                        ></b-icon>
                      </template>
                    </VueDatePicker>
                    <span
                    id="warning-shipment"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Project Status -->
              <b-row align-h="start">
                <b-col sm="12">
                  <b-form-group
                    label="Project Status"
                    label-for="ProjectStatus"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <v-select
                      append-to-body
                      :clearable="false"
                      :reduce="(data) => data.value"
                      v-model="form.statusCustomer"
                      :options="optionsStatusCustomer"
                      placeholder="Select Project Status"
                      label="value"
                    >
                    </v-select>
                    <span
                    id="warning-projectStatus"
                    class="display-hidden text-small text-danger"
                    >
                    Required
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Item Products  -->
              <b-row align-h="start">
                <b-col sm="12">
                  <b-form-group
                    label="Products"
                    label-for="Products"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <b-row style="margin: 0px; margin-top: 5px; font-size:12px;">
                      <div style="display:contents; ">
                      <span style='width:25%; padding-bottom:10px;'> Name </span>
                      <span style='width:15%; padding-bottom:10px;'> Qty </span>
                      <span style='width:25%; padding-bottom:10px;'> Est Price </span>
                      <span style='width:25%; padding-bottom:10px;'> Est Cogs </span>
                      <span style='width:10%; text-align:end; padding-bottom:10px;' @click="AddItem()">
                        <!-- <span style="position: absolute; right: 0px;">Add</span> -->
                        <b-icon
                          scale="1.5"
                          style="
                            color: green;
                            position: relative;
                          "
                          icon="plus-square"
                        ></b-icon>
                      </span>
                    </div>
                    <br />
                    <div v-for="(row, index) in dataItemProducts" :key="row.id" style="display:contents; margin: 0px; font-size:11px;">
                      <span v-if="row.type != 'delete'" style='width:25%; padding-top: 5px; '> {{ row.category }}-{{ row.item }}</span>
                      <span v-if="row.type != 'delete'" style='width:15%; padding-top: 5px; '> {{ row.qty }} </span>
                      <span v-if="row.type != 'delete'" style='width:25%; padding-top: 5px; '> {{ (parseFloat(row.est_item_price)).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }} </span>
                      <span v-if="row.type != 'delete'" style='width:25%; padding-top: 5px; '> {{ (parseFloat(row.est_cogs)).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }} </span>
                      <span v-if="row.type != 'delete'" style='width:10%; text-align:end; padding-top: 5px; '>
                        <!-- <span style="position: absolute; right: 0px;">Add</span> -->
                        <b-icon
                          @click="editItem(row, index)"
                          scale="1.3"
                          style="
                            color: goldenrod;
                            position: relative;
                            right:20px;
                          "
                          icon="pencil-fill"
                        ></b-icon>
                        <b-icon
                          @click="removeItem(index)"
                          scale="1.3"
                          style="
                            color: red;
                            position: relative;
                          "
                          icon="trash-fill"
                        ></b-icon>
                      </span>
                    </div>
                    </b-row>
                    <span
                    id="warning-products"
                    class="display-hidden text-small text-danger"
                    >
                    Required
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- GP Value -->
              <b-row align-h="start">
                <b-col sm="12">
                  <b-form-group
                    label="GP Value"
                    label-for="GPValue"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <b-form-input
                    v-model="GpValue"
                    type="text"
                    :readonly="true"
                    trim
                  ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- GP Percent -->
              <b-row align-h="start">
                <b-col sm="12">
                  <b-form-group
                    label="GP %"
                    label-for="GpPercent"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <b-form-input
                    v-model="GpPercent"
                    type="text"
                    :readonly="true"
                    trim
                  ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>


            </template>
          </accordion-item>
        </accordion>
      </b-list-group-item>
    </b-list-group>
    <br />
    <b-row align-h="end">
      <b-button
        style="margin-right: 20px"
        variant="success"
        @click="Confirm('Submit')"
      >
        Submit</b-button
      >
      <b-button variant="warning" @click="cancel()"> Cancel</b-button>
    </b-row>

    <template>
      <b-modal
        v-model="showModal"
        ok-variant="success"
        cancel-variant="warning"
        header-bg-variant="secondary"
        hide-footer
        hide-header-close
        hide-backdrop
        size="md"
        no-close-on-backdrop
        title="Add Product ? "
      >
        <div id="modal">
          <!-- Products -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Product *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <v-select
                    :clearable="false"
                    v-model="itemProducts"
                    @input="InputProduct"
                    :options="optionsProductCategory"
                    placeholder="Select Product"
                    label="value"
                  >
                    <template v-slot:option="option">
                      {{ option.category }} - {{ option.value }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.category }} - {{ option.value }}
                    </template>
                  </v-select>
                  <span
                    id="warning-item"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Qty Product -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Qty *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="form.itemQty"
                    type="number"
                    placeholder="Input Qty"
                    trim
                  ></b-form-input>
                  <span
                    id="warning-itemQty"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Est Price -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Est Price *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="itemPrice"
                    @change="ChangePrice"
                    placeholder="Input Price"
                    type="text"
                    @keypress="isNumber($event)"
                    trim
                  ></b-form-input>
                  <span
                    id="warning-itemPrice"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Est Cogs -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Est Cogs *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="itemCogs"
                    @change="ChangeCogs"
                    placeholder="Input Cogs"
                    type="text"
                    @keypress="isNumber($event)"
                    trim
                  ></b-form-input>
                  <span
                    id="warning-itemCogs"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row align-h="end">
              <b-col sm="3">
                <b-button
                  block
                  size="md"
                  type="button"
                  variant="success"
                  @click="checkProduct()"
                >
                  Save
                </b-button>
              </b-col>
              <b-col sm="3">
                <b-button
                  block
                  size="md"
                  @click="cancelProduct()"
                  type="button"
                  variant="warning"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
        </div>
      </b-modal>
    </template>

    <Loader v-if="isLoadingData" fullPage useOverlay loader="spinner"></Loader>
  </div>
</template>
<script>
import Loader from "@/components/Loader/Loader";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import vue multiselect
import Multiselect from "vue-multiselect";
import apiActualActivity from "@/store/salesforce-form/actual-activity.js";
import { fnPOST } from "../../../../api/api-methods";
import { APIPATH_SALES, APIPATH_SALESFORCE } from "../../../../api/api-paths";

import Accordion from "@/components/Accordion/accordion";
import AccordionItem from "@/components/Accordion/accordion-item";

export default {
  components: {
    Multiselect,
    vSelect,
    Loader,
    Accordion,
    AccordionItem,
  },
  data() {
    const now = new Date();
    const optyear = { year: "numeric" };
    const today = now.toISOString().slice(0, 10);
    const year = now.toLocaleString("id-ID", optyear);
    return {
      isLoadingData: false,
      masks: {
        inputDateTime: "WWW DD-MMM-YYYY h:mm A",
      },
      formName: "Actual Activity Form",
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      dayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      NowDate: today,
      today: today,
      NowYear: year,
      idData: null,
      context: null,
      totalData: 0,
      timezone: "",
      DisableActivity: true,
      hiddenReason: false,
      hiddenPOID: false,
      hiddenOptions: false,
      ButtonChange: true,
      dataActivity: [],
      optionsActivity: [],
      optionsReason: [],
      optionsSubActivity: [],
      optionsStatusCustomer: [],
      optionsProductCategory: [],
      SelectActivity: [],
      ProjectID: null,
      dataItemProducts: [],
      itemProducts: null,
      itemPrice: null,
      itemCogs: null,
      GpValue: 0,
      GpPercent: 0,
      form: {
        estShipMonth: null,
        statusCustomer: null,
        itemProducts: null,
        categoryProducts: null,
        itemPrice: null,
        itemCogs: null,
        GpValue: 0,
        GpPercent: 0,

        idSalesProjection:null,

        role: null,
        start_limit: null,
        end_limit: null,
        user_nik: null,
        username: null,

        version_app: null,

        customer_id: null,
        weekly_planning: null,
        weekly_planning_detail: null,
        customer_mapping: null,
        customer_name: null,
        po_id: null,
        actual_date: now,
        actual_time: null,
        expired_date: null,
        processed_date: today,
        activity_name: null,
        activity_id: null,
        sub_activity_name: null,
        sub_activity_id: null,
        reason: null,
        remark: null,
        coordinates: null,
      },
      showModal: false,
      dataProducts: [],
      editProduct:false,
      index:null,
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState("sales_force", [
      "stDtActActv",
      "stDtActActvCount",
      "stMstSubAct",
      "stMstSubActCount",
      "stRsn",
      "stRsnCount",
    ]),
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions("sales_force", [
      "GetDataActualActv",
      "getReasonActivity",
      "GetListSubActivity",
    ]),
    setData() {
      let roles = this.userData.roles;
      if (roles.length > 0) {
        for (let data in roles) {
          if (roles[data].app == "SFC") {
            let lower = roles[data].role_list.map((string) => {
              return string.toLowerCase();
            });
            this.roles_list = lower;
          }
        }
      }
      this.idData = this.$route.params.idData;
      this.form.user_nik = this.userData.nik;
      this.form.username = this.userData.fullname;

      let MinDate = new Date(this.NowDate);
      let result = MinDate.setDate(MinDate.getDate() - 1);
      this.NowDate = new Date(result).toISOString().slice(0, 10);

      let expired_date = new Date(this.today);
      let resultExpired = expired_date.setMonth(expired_date.getMonth() + 1);
      this.form.expired_date = new Date(resultExpired)
        .toISOString()
        .slice(0, 10);
      if (this.idData == null || this.idData == undefined) {
        this.showNotification({
          type: "error",
          message:
            "Problem occurred in the system. Please try again. If the problem persists, contact your system Administrator",
        });
        this.$router.push({ name: "SFCActualActivity" });
      }
      this.LoadData();
    },

    isNumber: function (evt) {
      if (!/^[0-9]+$/.test(evt.key) || evt.key === ".")
        return evt.preventDefault();
      if (evt.keyCode === 46) {
        evt.preventDefault();
      }
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    removeItem(index) {
      let Boxtrue = ''
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to remove ? ", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: 'danger',
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          Boxtrue = value;
          if (Boxtrue == true) {
            if (this.dataItemProducts[index].type == 'create') {
              this.dataItemProducts.splice(index, 1)
            } else {
              this.dataItemProducts[index].type = 'delete'
            }
            this.calculateGP()
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    editItem(value, index) {
      this.showModal = true;
      this.itemProducts = value.category+'-'+value.item
      this.form.itemProducts = value.item;
      this.form.categoryProducts = value.category;
      this.form.itemQty = value.qty;
      this.itemPrice = value.est_item_price
      this.form.itemPrice = value.est_item_price;
      this.itemCogs = value.est_cogs;
      this.form.itemCogs = value.est_cogs;
      this.editProduct = true
      this.index = index
    },
    AddItem() {
      this.showModal = true;
      this.itemProducts = null;
      this.form.itemProducts = null;
      this.form.categoryProducts = null;
      this.form.itemQty = null;
      this.itemPrice = null;
      this.form.itemPrice = null;
      this.itemCogs = null;
      this.form.itemCogs = null;
      this.editProduct = false
      this.index = null
    },
    cancelProduct() {
      this.showModal = false;
      this.itemProducts = null;
      this.form.itemProducts = null;
      this.form.categoryProducts = null;
      this.form.itemQty = null;
      this.itemPrice = null;
      this.form.itemPrice = null;
      this.itemCogs = null;
      this.form.itemCogs = null;
      this.editProduct = false
      this.index = null
    },
    checkProduct() {
        if (this.form.itemProducts == null || this.form.itemProducts == "" ||this.form.itemProducts.substring(0, 1) == " ") {
          document.getElementById("warning-item").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-item").classList.add("display-hidden");
            }, 5000);
          return false
        }
        if (this.form.itemQty == null || this.form.itemQty == "" || this.form.itemQty == isNaN )  {
          document.getElementById("warning-itemQty").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-itemQty").classList.add("display-hidden");
            }, 5000);
          return false
        }
        if (this.form.itemPrice == null || this.form.itemPrice == "" || this.form.itemPrice == isNaN )  {
          document.getElementById("warning-itemPrice").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-itemPrice").classList.add("display-hidden");
            }, 5000);
          return false
        }
        if (this.form.itemCogs == null || this.form.itemCogs == "" || this.form.itemCogs == isNaN )  {
          document.getElementById("warning-itemCogs").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-itemCogs").classList.add("display-hidden");
            }, 5000);
          return false
        }
        // console.log(this.form.itemCogs)
        // console.log(this.form.itemPrice)
        if (this.form.itemCogs >= this.form.itemPrice) {
          this.showNotification({ type: "info", message: 'Est Cogs more than Est Price' });
          return false
        }
        if (this.editProduct) {
          this.editDataProduct(this.index)
        } else {
          this.submitProduct()
        }

    },
    submitProduct() {
      let data = {
        item:this.form.itemProducts,
        category:this.form.categoryProducts,
        qty:this.form.itemQty,
        est_item_price:this.form.itemPrice,
        est_cogs:this.form.itemCogs,
        type:'create'
      }
      this.dataItemProducts.push(data)
      // console.log(this.dataItemProducts)
      this.cancelProduct()
      this.calculateGP()
    },
    editDataProduct(index) {
      // console.log(index)
      // console.log(this.dataItemProducts)
      // console.log(this.form.itemProducts)
      this.dataItemProducts[index].item = this.form.itemProducts
      this.dataItemProducts[index].category = this.form.categoryProducts
      this.dataItemProducts[index].qty = this.form.itemQty
      this.dataItemProducts[index].est_item_price = this.form.itemPrice
      this.dataItemProducts[index].est_cogs = this.form.itemCogs
      if (this.dataItemProducts[index].type != 'create'){
        this.dataItemProducts[index].type = 'update'
      }

      // console.log(this.dataItemProducts)
      this.cancelProduct()
      this.calculateGP()
    },
    InputProduct() {
      // console.log(this.itemProducts)
      if (this.itemProducts) {
        this.form.itemProducts = this.itemProducts.value;
        this.form.categoryProducts = this.itemProducts.category;
        // console.log(this.form.itemProducts)
        // console.log(this.form.categoryProducts)
      }
    },

    ChangePrice() {
      if (this.itemPrice) {
        this.form.itemPrice = parseFloat(this.itemPrice)
        this.itemPrice = parseFloat(this.itemPrice)
          // .toString()
          // .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      // console.log(this.form.itemPrice)
      // console.log(this.itemPrice)
    },
    ChangeCogs() {
      if (this.itemCogs) {
        this.form.itemCogs = parseFloat(this.itemCogs)
        this.itemCogs = parseFloat(this.itemCogs)
          // .toString()
          // .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      // console.log(this.form.itemCogs)
      // console.log(this.itemCogs)
    },
    calculateGP() {
      let gp_value = 0
      let gp_percent = 0
      let total_price = 0
      let total_cogs = 0
      if (this.dataItemProducts) {
        for (let a=0; a<this.dataItemProducts.length; a++) {
          // console.log(this.dataItemProducts[a].qty)
          // console.log(this.dataItemProducts[a].est_item_price)
          // console.log(this.dataItemProducts[a].est_cogs)
          if (this.dataItemProducts[a].type != 'delete'){
            gp_value += this.dataItemProducts[a].qty * (this.dataItemProducts[a].est_item_price - this.dataItemProducts[a].est_cogs)
            total_price += this.dataItemProducts[a].est_item_price * this.dataItemProducts[a].qty
            total_cogs += this.dataItemProducts[a].est_cogs * this.dataItemProducts[a].qty
            gp_percent = (gp_value / total_price) * 100
          }
        }
      }
      this.form.GpValue = gp_value
      this.form.GpPercent = gp_percent.toFixed(2)
      this.GpValue = (gp_value.toFixed(2)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      this.GpPercent = (gp_percent.toFixed(2)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

    },

    LoadData() {
      this.dataActivity = [];

      // start loading
      this.isLoadingData = true;

      this.GetDataActualActv({
        id: this.idData,
      })
        .then((rsp) => {
          // console.log(rsp)
          this.dataActivity = this.stDtActActv;
          if (
            this.stDtActActvCount == 0 ||
            this.stDtActActvCount == undefined
          ) {
            this.dataActivity = rsp.data.payload[0];
          }
          this.ProjectID = rsp.data.payload[0].project_id;
          this.getReasonActivity({
            filter: "filter",
            id: this.dataActivity[0].customer,
            project_id: this.ProjectID,
          }).then((rsp) => {
            this.optionsActivity = rsp.payload;
            this.SetDataForm();
          });
        })
        .catch((err) => {
          this.showNotification({ type: "error", message: err });
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });

      fnPOST(APIPATH_SALESFORCE.masterStatusCustomer, {})
        .then((rsp) => {
          if (rsp.data.payload) {
            this.optionsStatusCustomer = rsp.data.payload;
          }
        })
        .catch((err) => {
          this.showNotification({ type: "error", message: err });
        });

      fnPOST(APIPATH_SALESFORCE.masterProductCategory, {})
        .then((rsp) => {
          if (rsp.data.payload) {
            this.optionsProductCategory = rsp.data.payload;
          }
        })
        .catch((err) => {
          this.showNotification({ type: "error", message: err });
        });
    },
    changeActivity() {
      this.DisableActivity = false;
      this.ButtonChange = false;
    },
    SetDataForm() {
      let dataForm = this.dataActivity[0];
      this.form.customer_name = dataForm.customer_name;
      this.form.customer_id = dataForm.customer;
      if (dataForm.allowed_phase) {
        if (dataForm.allowed_phase == 'Initial' || dataForm.allowed_phase == 'Progress') {
          this.hiddenPOID = false
        } else {
          this.hiddenPOID = true
        }
      }
      this.form.po_id = dataForm.po_id;
      this.form.activity_name = dataForm.activity_name;
      for (let a = 0; a <= this.optionsActivity.length - 1; a++) {
        if (this.form.activity_name == this.optionsActivity[a].name) {
          this.SelectActivity = this.optionsActivity[a];
        }
      }
      // this.form.sub_activity_name = dataForm.sub_activity_name
      // this.form.remark = dataForm.remark
      // this.form.reason = dataForm.reason
      this.form.customer_mapping = dataForm.customer_mapping;
      this.form.weekly_planning_detail = dataForm.weekly_planning_detail;
      this.form.weekly_planning = dataForm.weekly_planning;
      this.LoadActivity();
      this.loadSalesProjection()
    },
    onContext(ctx) {
      this.context = ctx;
    },
    InputSubActivity() {
      this.hiddenReason = false;
      this.optionsReason = [];
      this.form.reason = null;
      this.getReasonActivity({
        activity: this.form.sub_activity_name,
      })
        .then((res) => {
          if (res != "No Data") {
            this.optionsReason = res.payload;
            if (this.optionsReason.length > 0) {
              this.hiddenReason = true;
            } else {
              this.hiddenReason = false;
            }
          }
        })
        .catch((err) => {
          this.showNotification({ type: "error", message: err });
        });
    },
    InputActivity(value) {
      this.hiddenOptions = false;
      this.hiddenReason = false;
      let inputValue = [];
      value.forEach((value) => {
        inputValue.push(value.name);
      });
      this.form.activity_name = inputValue;
      this.form.reason = null;
      this.form.sub_activity_name = null;
      this.optionsSubActivity = [];
      this.optionsReason = [];
      this.LoadActivity();
    },
    loadSalesProjection() {
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.getSalesProjection, {
        project_id:this.ProjectID,
        customer_id:this.form.customer_id,
      })
      .then((rsp) => {
        this.isLoadingData = false;
        if (rsp.data.payload) {
          let data = rsp.data.payload
          if (data.length > 0) {
            this.form.idSalesProjection = data[0].id
            this.form.estShipMonth      = data[0].est_shipment_month.substring(0, 7);
            // console.log(this.form.estShipMonth)
            this.form.statusCustomer    = data[0].status_customer
            this.form.GpValue           = data[0].gp_value
            this.form.GpPercent         = data[0].gp_percent
            let gpValue                 = parseFloat(data[0].gp_value)
            let gpPercent               = parseFloat(data[0].gp_percent)
            this.GpValue                = (gpValue.toFixed(2)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            this.GpPercent              = (gpPercent.toFixed(2)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            this.loadSalesProjectionDetail()
          }
        }
      })
      .catch((err) => {
        this.isLoadingData = false;
        this.showNotification({ type: "error", message: err });
      });
    },
    loadSalesProjectionDetail() {
      fnPOST(APIPATH_SALESFORCE.getSalesProjectionDetail, {
        sales_projection:this.form.idSalesProjection
      })
      .then((rsp) => {
        this.isLoadingData = false;
        // console.log(rsp)
        let dataDetail = rsp.data.payload
        this.dataItemProducts = rsp.data.payload
      })
      .catch((err) => {
        this.isLoadingData = false;
        this.showNotification({ type: "error", message: err });
      });

    },
    LoadActivity() {
      this.GetListSubActivity({
        activity: this.form.activity_name,
      })
        .then((rsp) => {
          this.optionsSubActivity = rsp.data.payload;
          if (this.optionsSubActivity.length > 0) {
            this.hiddenOptions = true;
          }
        })
        .catch((err) => {
          this.showNotification({ type: "error", message: err });
        });

      this.getReasonActivity({
        activity: this.form.activity_name,
      })
        .then((res) => {
          if (res != "No Data") {
            this.optionsReason = res;
            if (this.optionsReason.length > 0) {
              this.hiddenReason = true;
            }
          }
        })
        .catch((err) => {
          this.showNotification({ type: "error", message: err });
        });
    },
    FormatDate(value) {
      if (value == null || value == undefined || value == "") {
        return " ";
      } else {
        let date = new Date(value);
        let nameDay = this.dayNames[date.getDay()];
        let day = date.getDate();
        if (String(day).length == 1) {
          day = "0" + String(day);
        }
        let year = date.getFullYear();
        let bulan = this.monthNames[date.getMonth()];
        date = nameDay + " " + day + " " + bulan + " " + year;
        return date;
      }
    },
    cancel() {
      this.$router.push({ name: "SFCActualActivity" });
    },
    Confirm(choice) {
      let varOk = "danger";
      if (choice !== "delete") {
        varOk = "success";
      }
      let Boxtrue = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to " + choice + " ? ", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: varOk,
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          Boxtrue = value;
          if (Boxtrue == true) {
            this.onSubmit();
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    onSubmit() {
      if (this.form.estShipMonth == null || this.form.estShipMonth == '' || this.form.estShipMonth == undefined) {
        document.getElementById("warning-shipment").classList.remove("display-hidden");
        document.getElementById("warning-sales_projection").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("warning-shipment").classList.add("display-hidden");
            document.getElementById("warning-sales_projection").classList.add("display-hidden");
          }, 5000);
        return false
      }
      if (this.form.statusCustomer == null || this.form.statusCustomer == '' || this.form.statusCustomer == undefined) {
        document.getElementById("warning-projectStatus").classList.remove("display-hidden");
        document.getElementById("warning-sales_projection").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("warning-projectStatus").classList.add("display-hidden");
            document.getElementById("warning-sales_projection").classList.add("display-hidden");
          }, 5000);
        return false
      }
      if (this.dataItemProducts == null || this.dataItemProducts == '' || this.dataItemProducts.length == undefined) {
        document.getElementById("warning-products").classList.remove("display-hidden");
        document.getElementById("warning-sales_projection").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("warning-products").classList.add("display-hidden");
            document.getElementById("warning-sales_projection").classList.add("display-hidden");
          }, 5000);
        return false
      }
      if (this.form.activity_name !== null) {
        if (this.form.activity_name.length == 1) {
          // this.form.activity_name = this.form.activity_name[0].replace(/[^a-zA-Z0-9,()/& ]/g, "");
          this.form.activity_name = this.form.activity_name[0].replace(
            /[{}]/g,
            ""
          );
        }
      }
      if (this.form.sub_activity_name !== null) {
        if (
          this.form.sub_activity_name == "" ||
          this.form.sub_activity_name.substring(0, 1) == " "
        ) {
          this.form.sub_activity_name == null;
        }
      }
      if (this.form.actual_date == null) {
        this.showNotification({
          type: "error",
          message: "Please Fill Out Date Field",
        });
        return false;
      }
      if (this.form.activity_name == null) {
        this.showNotification({
          type: "error",
          message: "Please Fill Out Actual Field",
        });
        return false;
      }
      if (this.hiddenOptions == true) {
        if (this.form.sub_activity_name == 'Batal' && (this.form.remark == null || this.form.remark == '' || this.form.remark.substring(0, 1) == " ") ) {
          // console.log('test')
          this.showNotification({
            type: "error",
            message: "Please Fill Out Remark Field",
          });
          return false;
        }
        if (this.form.sub_activity_name == null) {
          this.showNotification({
            type: "error",
            message: "Please Fill Out Options Field",
          });
          return false;
        }
      }
      if (this.hiddenReason == true) {
        if (this.form.reason == null) {
          this.showNotification({
            type: "error",
            message: "Please Fill Out Reason Field",
          });
          return false;
        }
      }

      // start loading
      this.isLoadingData = true;

      apiActualActivity
        .dispatch("SaveDataActualActivity", {
          id: this.idData,
          weekly_planning: this.form.weekly_planning,
          weekly_planning_detail: this.form.weekly_planning_detail,
          customer: this.form.customer_id,
          customer_mapping: this.form.customer_mapping,
          date: this.form.actual_date,
          po_id: this.form.po_id,
          remark: this.form.remark,
          expired_date: this.form.expired_date,
          processed_date: this.form.processed_date,
          activity_name: this.form.activity_name,
          activity_id: this.form.activity_id,
          sub_activity_name: this.form.sub_activity_name,
          sub_activity_id: this.form.sub_activity_id,
          reason: this.form.reason,
          coordinates: this.form.coordinates,
          estShipMonth:this.form.estShipMonth,
          projectStatus:this.form.statusCustomer,
          dataItemProducts:this.dataItemProducts,
          gpValue:this.form.GpValue,
          gpPercent:this.form.GpPercent,
          created_by:this.userData.nik,
          created_by_name:this.userData.fullname,
        })
        .then(() => {
          this.showNotification({
            type: "success",
            message: "Successfully Submitted",
          });
          this.$router.push({ name: "SFCActualActivity" });
        })
        .catch(() => {
          this.showNotification({
            type: "error",
            message:
              "Problem occurred in the system. Please try again. If the problem persists, contact your system Administrator",
          });
          return false;
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
<style>
.multiselect__option {
  font-size: 11px !important;
  white-space: initial !important;
  line-height: 1.5 !important;
}
.multiselect__option--selected:after {
  background: #f3f3f3;
}
.multiselect__tag {
  white-space: initial !important;
  line-height: 1.5 !important;
  font-size: 11px !important;
}
.accordion__item {
  padding: 0px !important;
  padding-bottom: 10px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
