<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/applications">
            Applications</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>{{ formName }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ modulName }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-container fluid>
        <b-card>
          <template>
            <b-card sub-title="Filter">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label="Business Unit"
                    label-for="bu-select"
                    label-cols="3"
                    label-size="sm"
                    v-slot="{ ariaDescribedby }"
                  >
                    <v-select
                      id="bu-select"
                      v-model="filters.business_unit"
                      :options="businessUnitOpt"
                      size="sm"
                      label="code"
                      :reduce="(i) => i.code"
                      :aria-describedby="ariaDescribedby"
                    >
                    </v-select>
                  </b-form-group>
                  <b-form-group
                    label="Location"
                    label-for="location-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="location-select"
                      v-model="filters.location"
                      :options="locationOpt"
                      size="sm"
                      label="location_name"
                      :reduce="(i) => i.location_name"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="License Type"
                    label-for="license-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="license-select"
                      v-model="filters.permit_type"
                      :options="licenseTypeOpt"
                      size="sm"
                      label="permit_type"
                      :reduce="(i) => i.permit_type"
                    >
                    </v-select>
                  </b-form-group>
                  <b-form-checkbox
                    v-model="filters.active"
                    name="check-button"
                    switch
                  >
                    <template v-if="filters.active === true"> Active </template>
                    <template v-else> Inactive </template>
                  </b-form-checkbox>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-size="sm">
                    <template v-slot:label> Search </template>
                    <b-input-group size="sm">
                      <b-form-input
                        id="inputSearch"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        trim
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Search</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:header>
            <b-row>
              <b-col sm="auto" md="9">
                <div class="text-left">
                  <h4>Reminder</h4>
                </div>
              </b-col>
              <b-col sm="auto" md="3">
                <div class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    v-on:click="show = !show"
                    >Create</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
          <b-table
            head-variant="dark"
            responsive
            stickyColumn
            striped
            hover
            show-empty
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterReminder"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="includeFiled"
          >
            <template #cell(is_status)="{ item }">
              <div v-if="item.is_status === true">Active</div>
              <div v-if="item.is_status === false">Inactive</div>
            </template>

            <template v-slot:cell(actions)="{ item }">
              <b-button
                title="Hapus"
                size="xs"
                variant="danger"
                v-on:click="deleteData(item.id)"
              >
                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
              </b-button>
              <b-button
                title="Ubah"
                size="xs"
                variant="warning"
                v-on:click="editData(item.id)"
              >
                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
              </b-button>
            </template>
          </b-table>
          <div class="mt-3">
            Showing {{ pageItems }} of {{ items.length }} entries
            <b-pagination
              v-model="paging.currentPage"
              :total-rows="rows"
              :per-page="paging.perPage"
              align="right"
            >
            </b-pagination>
          </div>
        </b-card>
        <template>
          <!-- <b-modal
            v-model="show"
            @ok="onSubmit()"
            @hidden="cancel()"
            ok-title="Save"
            cancel-title="Cancel"
            ok-variant="success"
            cancel-variant="warning"
            header-bg-variant="secondary"
            footer-bg-variant="secondary"
            hide-backdrop
            size="lg"
            ref="modal"
            no-close-on-backdrop
            title="Form Reminder"
          > -->
          <b-modal
            v-model="show"
            ref="my-modal"
            header-bg-variant="secondary"
            footer-bg-variant="secondary"
            hide-backdrop
            size="lg"
            title="Form Reminder"
          >
            <div id="modal">
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                  <b-row>
                    <b-col cols="12">
                      <!-- Business Unit -->
                      <validation-provider
                        name="BusinessUnit"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label-size="sm"
                        >
                          <template v-slot:label>
                            Business Unit<code>*</code>
                          </template>
                          <v-select
                            :reduce="(i) => i.code"
                            label="name"
                            :options="businessUnitOpt"
                            id="inputBusinessUnit"
                            v-model="form.business_unit"
                            trim
                          >
                            <template #search="{ attributes, events }">
                              <input
                                class="vs__search"
                                :required="!form.business_unit"
                                v-bind="attributes"
                                v-on="events"
                              />
                            </template>
                          </v-select>
                          <b-form-invalid-feedback id="inputBusinessUnit">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- /Business Unit -->
                      <!-- License Type -->
                      <validation-provider
                        name="LicenseType"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label-size="sm"
                        >
                          <template v-slot:label>
                            License Type<code>*</code>
                          </template>
                          <v-select
                            :reduce="(i) => i.id"
                            label="permit_type"
                            :options="licenseTypeOpt"
                            id="inputLicenseType"
                            v-model="form.permit_id"
                            trim
                          >
                            <template #search="{ attributes, events }">
                              <input
                                class="vs__search"
                                :required="!form.permit_id"
                                v-bind="attributes"
                                v-on="events"
                              />
                            </template>
                          </v-select>
                          <b-form-invalid-feedback id="inputLicenseType">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- /License Type -->
                      <!-- Location -->
                      <validation-provider
                        name="Location"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label-size="sm"
                        >
                          <template v-slot:label>
                            Location<code>*</code>
                          </template>
                          <v-select
                            :reduce="(i) => i.id"
                            label="location_name"
                            :options="setLocation"
                            id="inputLocation"
                            v-model="form.location_id"
                            trim
                          >
                            <template #search="{ attributes, events }">
                              <input
                                class="vs__search"
                                :required="!form.location_id"
                                v-bind="attributes"
                                v-on="events"
                              />
                            </template>
                          </v-select>
                          <b-form-invalid-feedback id="inputLocation">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- /Location -->

                      <!-- Reminder -->
                      <validation-provider
                        name="Reminder"
                        :rules="{ required: true, numeric: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label-size="sm"
                        >
                          <template v-slot:label>
                            Reminder (Months)<code>*</code>
                          </template>
                          <b-form-input
                            id="inputReminder"
                            v-model="form.reminder"
                            :state="getValidationState(validationContext)"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback id="inputReminder">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- /Reminder -->
                      <!-- Status -->
                      <template>
                        <validation-provider
                          name="Status"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Status<code>*</code>
                            </template>
                            <b-form-checkbox
                              switch
                              id="inputStatus"
                              v-model="form.is_status"
                              :state="getValidationState(validationContext)"
                              trim
                            >
                              <template v-if="form.is_status === true">
                                Active
                              </template>
                              <template v-else> Inactive </template>
                            </b-form-checkbox>
                            <b-form-invalid-feedback id="inputStatus">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </template>
                      <!-- /Status -->
                    </b-col>
                  </b-row>
                  <!-- penambahan terbaru -->
                  <b-row class="float-right">
                    <b-button class="mr-3" variant="success" @click="onSubmit()"
                      >Save</b-button
                    >
                    <b-button class="mr-3" variant="warning" @click="cancel()"
                      >Cancel</b-button
                    >
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <template #modal-footer>
              <div class="h-300 bg-secondary"></div>
            </template>
          </b-modal>
        </template>
      </b-container>
      <template #overlay>
        <div ref="loadings" class="text-center">
          <b-icon
            variant="bbi_yellow"
            icon="arrow-clockwise"
            font-scale="3"
            animation="spin"
          ></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template> 

<script>
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../../api/api-methods";
import { APIPATH_DMS, APIPATH_BBIAPPS } from "../../../../../api/api-paths";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import "vue-select/dist/vue-select.css";
export default {
  data() {
    return {
      formName: "Reminder",
      modulName: "Dashboard",
      items: [],
      fields: [
        { key: "actions", label: "#" },
        { key: "business_unit", sortable: true },
        { key: "permit_type", label: "License Type", sortable: true },
        { key: "location_name", label: "Location", sortable: true },
        { key: "reminder", label: "Reminder (Months)" },
        { key: "is_status", label: "Status", sortable: true },
        "created_by",
        {
          key: "created_at",
          label: "Created At",
          formatter: this.setDateFormat,
        },
        { key: "modified_by", label: "Updated By" },
        {
          key: "modified_at",
          label: "Updated At",
          formatter: this.setDateFormat,
        },
      ],
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      isLoading: true,
      form: {
        reminder_id: null,
        permit_id: null,
        location_id: null,
        business_unit: null,
        reminder: null,
        is_status: true,
        created_by: null,
        modified_by: null,
        deleted_by: null,
      },
      show: false,
      businessUnitOpt: [],
      licenseTypeOpt: [],
      locationOpt: [],
      filters: {
        business_unit: null,
        location: null,
        permit_type: null,
        active: true,
      },
      filter: null,
      includeFiled: [
        "business_unit",
        "location_name",
        "permit_type",
        "is_status",
      ],
      statusOpt: [
        { value: true, label: "Active" },
        { value: false, label: "Inactive" },
      ],
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    rows() {
      if (this.items) {
        return this.filterReminder.length;
      }
    },
    filterReminder() {
      var bu = this.filters.business_unit;
      var location = this.filters.location;
      var permit = this.filters.permit_type;
      var status = this.filters.active;

      return this.items.filter(function (item) {
        var filtereds = true;
        if (bu && bu.length > 0) {
          filtereds = item.business_unit === bu;
        }
        if (filtereds) {
          if (location && location.length > 0) {
            filtereds = item.location_name === location;
          }
        }
        if (filtereds) {
          if (permit && permit.length > 0) {
            filtereds = item.permit_type === permit;
          }
        }
        if (filtereds) {
          if (status === false) {
            filtereds = item.is_status === false;
          } else {
            filtereds = item.is_status === true;
          }
        }
        return filtereds;
      });
    },
    setLocation() {
      if (this.form.business_unit) {
        var location = this.locationOpt.filter(
          (i) => i.business_unit === this.form.business_unit
        );
        if (location.length > 0) {
          return location;
        } else {
          return [{ location_name: "Not Found...", disabled: true }];
        }
      }
    },
    pageItems() {
      var totalPage = Math.ceil(this.rows / this.paging.perPage);
      if (this.paging.currentPage === totalPage) {
        return (
          (this.rows % this.paging.perPage) +
          this.paging.perPage * totalPage -
          this.paging.perPage
        );
      } else {
        return this.paging.perPage * this.paging.currentPage;
      }
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    setDateFormat(value) {
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      var formats = new Date(value).toLocaleDateString("id-ID", options);
      if (value) {
        return formats;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getReminder() {
      Vue.set(this, "isLoading", true);
      fnGET(APIPATH_DMS.getReminder)
        .then((rsp) => {
          Vue.set(this, "items", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        })
        .then(() => {
          Vue.set(this, "isLoading", false);
        });
    },
    onSubmit() {
      Vue.set(this, "isLoading", true);
      if (this.form.reminder_id) {
        fnPUT(APIPATH_DMS.putReminder, this.form)
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Successfully Updated",
            });
            this.getReminder();
          })
          .catch((err) => {
            this.showNotification({
              type: "danger",
              message: "Failed Submitted, Something wrong",
            });
            return err;
          })
          .then(() => {
            Vue.set(this, "isLoading", false);
            this.cancel();
          });
      } else {
        fnPOST(APIPATH_DMS.postReminder, this.form)
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });

            this.getReminder();
          })
          .catch((err) => {
            this.showNotification({
              type: "danger",
              message: "Failed Submitted, Something wrong",
            });

            return err;
          })
          .then(() => {
            Vue.set(this, "isLoading", false);
            this.cancel();
          });
      }
    },
    editData(id) {
      var reminderObj = this.items.find((i) => i.id === id);
      Vue.set(this, "show", true);
      Vue.set(this.form, "reminder_id", id);
      Vue.set(this.form, "reminder", reminderObj.reminder);
      Vue.set(this.form, "business_unit", reminderObj.business_unit);
      Vue.set(this.form, "permit_id", reminderObj.permit);
      Vue.set(this.form, "location_id", reminderObj.location);
      Vue.set(this.form, "location_name", reminderObj.location_name);
      Vue.set(this.form, "is_status", reminderObj.is_status);
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            Vue.set(this, "isLoading", true);
            fnPUT(APIPATH_DMS.deleteReminder, {
              reminder_id: id,
              deleted_by: this.form.deleted_by,
            })
              .then((rsp) => {
                this.showNotification({
                  type: "success",
                  message: "Successfully Deleted",
                });

                this.getReminder();
              })
              .catch((err) => {
                this.showNotification({
                  type: "danger",
                  message: "Failed Deleted, Something wrong",
                });

                return err;
              })
              .then(() => {
                Vue.set(this, "isLoading", false);
                this.cancel();
              });
          }
        });
    },
    setUserData() {
      Vue.set(this.form, "created_by", this.userData.fullname);
      Vue.set(this.form, "modified_by", this.userData.fullname);
      Vue.set(this.form, "deleted_by", this.userData.fullname);
    },
    cancel() {
      Vue.set(this.form, "reminder_id", null);
      Vue.set(this.form, "reminder", null);
      Vue.set(this.form, "business_unit", null);
      Vue.set(this.form, "permit_id", null);
      Vue.set(this.form, "location_id", null);
      Vue.set(this.form, "is_status", null);
      this.$refs["my-modal"].hide();
    },
    getBU() {
      fnGET(APIPATH_BBIAPPS.listBU)
        .then((rsp) => {
          Vue.set(this, "businessUnitOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getPermit() {
      fnGET(APIPATH_DMS.getPermit)
        .then((rsp) => {
          var permits = rsp.data.payload.filter((i) => i.expired === "Yes");
          Vue.set(this, "licenseTypeOpt", permits);
        })
        .catch((err) => {
          return err;
        });
    },
    getLocation() {
      fnGET(APIPATH_DMS.getLocation)
        .then((rsp) => {
          Vue.set(this, "locationOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
  },
  created() {
    this.getReminder();
    this.setUserData();
    this.getBU();
    this.getPermit();
    this.getLocation();
  },
};
</script>

<style>
</style>