<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto">
                        
                        <!-- Brand -->
                            <validation-provider
                                name="brand"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Brand <code>*</code>
                                    </template>
                                    <b-form-select id="inputBrand" v-model="form.brand" :options="brandOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Brand -->

                        <!-- Collection Name -->
                            <validation-provider
                                name="CollectionName"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Collection Name<code>*</code>
                                    </template>
                                    <b-form-input id="inputCollectionName" v-model="form.collectionName" :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputCollectionName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Collection Name -->

                        <!-- Period -->
                            <validation-provider
                                name="Period"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Period<code>*</code>
                                    </template>
                                    <b-form-input type="date" id="inputPeriod" v-model="form.period"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputPeriod">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Period -->

                        <!-- Quartal -->
                            <validation-provider
                                name="Quartal"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Quartal<code>*</code>
                                    </template>
                                    <b-form-input id="inputQuartal" v-model="form.quartal"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputQuartal">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Quartal -->

                        <!-- Prod. Month -->
                            <validation-provider
                                name="ProdMonth"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Prod. Month<code>*</code>
                                    </template>
                                    <b-form-select   
                                        id="inputProdMonth" 
                                        v-model="form.prodMonth" 
                                        :options="monthOpt"  
                                        value-field="value"
                                        text-field="value"
                                        :state="getValidationState(validationContext)"  
                                        trim></b-form-select>
                                    <b-form-invalid-feedback id="inputProdMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Prod. Month -->

                        <!-- Coll. Month -->
                            <validation-provider
                                name="CollMonth"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Coll. Month<code>*</code>
                                    </template>
                                    <b-form-select 
                                        value-field="value"
                                        text-field="value" id="inputCollMonth" v-model="form.collMonth" :options="monthOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputCollMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Coll. Month -->

                        <!-- Theme -->
                            <validation-provider
                                name="Theme"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Theme<code>*</code>
                                    </template>
                                    <b-form-input id="inputTheme" v-model="form.theme"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputTheme">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Theme -->

                        <!-- Sub Brand -->
                            <validation-provider
                                name="SubBrand"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Sub. Brand<code>*</code>
                                    </template>
                                    <b-form-select id="inputSubBrand" v-model="form.subBrand" :options="subBrandOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputSubBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Sub Brand -->
                        <!-- Code -->
                            <validation-provider
                                name="Code"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code<code>*</code>
                                    </template>
                                    <b-form-input id="inputCode" v-model="form.code"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Code -->

                        <!-- Combo -->
                            <validation-provider
                                name="Combo"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Combo
                                    </template>
                                    <b-form-input id="inputCombo" v-model="form.combo"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputCombo">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Combo -->

                        <!-- Detail Combo -->
                            <validation-provider
                                name="DetailCombo"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Detail Combo
                                    </template>
                                    <b-form-input id="inputDetailCombo" v-model="form.detailCombo" :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputDetailCombo">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Detail Combo -->

                        
                
                    </b-col>
                    <b-col cols="auto">
                            <!-- Article -->
                            <validation-provider
                                name="Article"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Article<code>*</code>
                                    </template>
                                    <b-form-input id="inputArticle" v-model="form.article"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputArticle">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Article -->

                        <!-- Design -->
                            <validation-provider
                                name="Design"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Design<code>*</code>
                                    </template>
                                    <b-form-file
                                        multiple
                                        ref="filedesignImages"
                                        id="inputDesign"
                                        v-model="form.design"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        :file-name-formatter="formatNamesDesignImages"
                                        v-on:change="onDesignFileChange"
                                    ></b-form-file>
                                        <div v-for="img in designImages" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeImgDesign(img.fileName,designImages)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="inputDesign">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Design -->

                        <!-- Model -->
                            <validation-provider
                                name="Model"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Model<code>*</code>
                                    </template>
                                    <b-form-select 
                                        value-field="value"
                                        text-field="value" id="inputModel" v-model="form.model" :options="modelOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputModel">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Model -->

                        <!-- No. Sample -->
                            <validation-provider
                                name="NoSample"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        No. Sample<code>*</code>
                                    </template>
                                    <b-form-select id="inputNoSample" v-model="form.noSample" :options="noSampleOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputNoSample">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /No. Sample -->

                        <!-- Style -->
                            <validation-provider
                                name="Style"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Style<code>*</code>
                                    </template>
                                    <b-form-select
                                        value-field="value"
                                        text-field="value" id="inputStyle" v-model="form.style" :options="styleOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputStyle">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Style -->

                        <!-- Fitting -->
                            <validation-provider
                                name="Fitting"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Fitting<code>*</code>
                                    </template>
                                    <b-form-select
                                        value-field="value"
                                        text-field="value" id="inputFitting" v-model="form.fitting" :options="fittingOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputFitting">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Fitting -->

                        <!-- Main Fabric -->
                            <validation-provider
                                name="MainFabric"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Main Fabric
                                    </template>
                                    <b-form-file
                                        multiple
                                        ref="filemainFabricImages"
                                        id="inputMainFabric"
                                        v-model="form.mainFabric"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        no-drop
                                        accept=".jpg, .png,"
                                        :file-name-formatter="formatNamesMainFabric"
                                        v-on:change="onMainFabricFileChange"
                                    ></b-form-file>
                                        <div v-for="img in mainFabricImages" :key="img.src">
                                            <p class="small">
                                                <a :href="img.src">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeImgMainFabric(img.fileName,mainFabricImages)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    
                                    <b-form-invalid-feedback id="inputMainFabric">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Main Fabric -->

                        <!-- Category -->
                            <validation-provider
                                name="Category"
                                :rules="{ required: true }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Category<code>*</code>
                                    </template>
                                    <b-form-select id="inputCategory" v-model="form.category" :options="categoryOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputCategory">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Category -->
                        
                        <!-- Thread Body -->
                            <validation-provider
                                name="ThreadBody"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Thread Body
                                    </template>
                                    <b-form-input id="inputThreadBody" v-model="form.threadBody"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputThreadBody">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Thread Body -->

                        <!-- Thread Combo -->
                            <validation-provider
                                name="ThreadCombo"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Thread Combo
                                    </template>
                                    <b-form-input id="inputThreadCombo" v-model="form.threadCombo"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputThreadCombo">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Thread Combo -->

                        <!-- Thread Botton -->
                            <validation-provider
                            name="ThreadBotton"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="3" 
                                label-cols-lg       ="3" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Thread Botton
                                </template>
                                <b-form-input id="inputThreadBotton" v-model="form.threadBotton"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputThreadBotton">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        
                            </validation-provider>
                        <!-- /Thread Botton -->

                        <!-- Thread Bottonhole -->
                            <validation-provider
                                name="ThreadBottonhole"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Thread Bottonhole
                                    </template>
                                    <b-form-input id="inputThreadBottonhole" v-model="form.threadBottonhole"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputThreadBottonhole">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Thread Bottonhole -->

                        <!-- Label -->
                            <validation-provider
                                name="Label"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="3" 
                                    label-cols-lg       ="3" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Label
                                    </template>
                                    <b-form-file
                                        multiple
                                        ref="filelabelImages"
                                        id="inputLabel"
                                        v-model="form.label"
                                        :state="getValidationState(validationContext)"
                                        placeholder="Choose file here..."
                                        accept=".jpg, .png,"
                                        no-drop
                                        :file-name-formatter="formatNamesLabelImages"
                                        v-on:change="onLabelFileChange"
                                    ></b-form-file>
                                        <div v-for="img in labelImages" :key="img.src">
                                            <p class="small">
                                                    <a :href="img.src">{{img.fileName}}</a>
                                                <b-badge href="#" v-on:click="removeImgLabel(img.fileName,labelImages)" variant="light">
                                                    <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                </b-badge>
                                            </p>
                                        </div>
                                    <b-form-invalid-feedback id="inputLabel">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        <!-- /Label -->
                    
                    </b-col>
                </b-row>
                <div>
                    <b-button block  variant="success" type="submit">Save</b-button>
                    <b-button block  variant="light" v-on:click="goToList()" >Cancel</b-button>
                </div> 
                    
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import apiProduct from '@/store/product-form/master-param.js'
export default {
    name:'Form Product Design 2',
    data(){
        return{
            formName: 'Product',
            modulName: null,
            tabIndex:0,
            designImages:[],
            mainFabricImages:[],
            labelImages:[],
            form:{
                indexId:null,
                brand:null,
                collectionName:null,
                period:null,
                quartal:null,
                prodMonth:null,
                collMonth:null,
                theme:null,
                subBrand:null,
                category:null,
                article:null,
                design:[],
                model:null,
                noSample:null,
                style:null,
                fitting:null,
                mainFabric:[],
                code:null,
                combo:null,
                detailCombo:null,
                threadBody:null,
                threadCombo:null,
                threadBotton:null,
                threadBottonhole:null,
                label:[],

            },
            codeOpt:[
                {value:'1', text:'Code A'},
                {value:'2', text:'Code B'},
                {value:'3', text:'Code C'},
                {value:'4', text:'Code D'},
            ],
            brandOpt:[
                {value:'1', text:'Brand A'},
                {value:'2', text:'Brand B'},
                {value:'3', text:'Brand C'},
                {value:'4', text:'Brand D'},
            ],
            monthOpt:[
                {value:'1', text:'Januari'},
                {value:'2', text:'Febuari'},
                {value:'3', text:'Maret'},
                {value:'4', text:'April'},
            ],
            subBrandOpt:[
                {value:'1', text:'Sub Brand A'},
                {value:'2', text:'Sub Brand B'},
                {value:'3', text:'Sub Brand C'},
                {value:'4', text:'Sub Brand D'},
            ],
            categoryOpt:[
                {value:'1', text:'Category A'},
                {value:'2', text:'Category B'},
                {value:'3', text:'Category C'},
                {value:'4', text:'Category D'},
            ],
            modelOpt:[
                {value:'1', text:'Model A'},
                {value:'2', text:'Model B'},
                {value:'3', text:'Model C'},
                {value:'4', text:'Model D'},
            ],
            noSampleOpt:[
                {value:'1', text:'No Sample A'},
                {value:'2', text:'No Sample B'},
                {value:'3', text:'No Sample C'},
                {value:'4', text:'No Sample D'},
            ],
            styleOpt:[
                {value:'1', text:'Style A'},
                {value:'2', text:'Style B'},
                {value:'3', text:'Style C'},
                {value:'4', text:'Style D'},
            ],
            fittingOpt:[
                {value:'1', text:'Fitting A'},
                {value:'2', text:'Fitting B'},
                {value:'3', text:'Fitting C'},
                {value:'4', text:'Fitting D'},
            ]
        }
    },
    methods:{
        ...mapActions('productDesign',['addProduct','deleteProduct','updateProduct']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getData(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')
                    this.modelOpt = response.data.payload.filter(d=>d.category ==='Model')
                    this.styleOpt = response.data.payload.filter(d=>d.category ==='Style')
                    this.fittingOpt = response.data.payload.filter(d=>d.category ==='Fitting')
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        onSubmit(){
            this.addProduct({
                brand:this.form.brand,
                collectionName:this.form.collectionName,
                period:this.form.period,
                quartal:this.form.quartal,
                prodMonth:this.form.prodMonth,
                collMonth:this.form.collMonth,
                theme:this.form.theme,
                subBrand:this.form.subBrand,
                category:this.form.category,
                article:this.form.article,
                design:this.designImages,
                model:this.form.model,
                noSample:this.form.noSample,
                style:this.form.style,
                fitting:this.form.fitting,
                mainFabric:this.mainFabricImages,
                code:this.form.code,
                combo:this.form.combo,
                detailCombo:this.form.detailCombo,
                threadBody:this.form.threadBody,
                threadCombo:this.form.threadCombo,
                threadBotton:this.form.threadBotton,
                threadBottonhole:this.form.threadBottonhole,
                label:this.labelImages,
            })
            
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        onDesignFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.designImages.push(img)
            }
        },
        onMainFabricFileChange(e){
             var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.mainFabricImages.push(img)
            }
        },
        onLabelFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.labelImages.push(img)
            }
        },
        removeImgDesign:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNamesDesignImages()
        },
        formatNamesDesignImages() {
            return this.designImages.length === 0 ? this.$refs.filedesignImages.reset() : `${this.designImages.length} files selected`
        },
        removeImgMainFabric:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNamesMainFabric()
        },
        formatNamesMainFabric() {
            return this.mainFabricImages.length === 0 ? this.$refs.filemainFabricImages.reset() : `${this.mainFabricImages.length} files selected`
        },
        removeImgLabel:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNamesLabelImages()
        },
        formatNamesLabelImages() {
            return this.labelImages.length === 0 ? this.$refs.filelabelImages.reset() : `${this.labelImages.length} files selected`
        },
        getProduct(){
            this.form.brand = this.product.brand,
            this.form.collectionName = this.product.collectionName,
            this.form.period = this.product.period,
            this.form.quartal = this.product.quartal,
            this.form.prodMonth = this.product.prodMonth,
            this.form.collMonth = this.product.collMonth,
            this.form.theme = this.product.theme,
            this.form.subBrand = this.product.subBrand,
            this.form.category = this.product.category,
            this.form.article = this.product.article,
            this.designImages = this.product.designImages,
            this.form.model = this.product.model,
            this.form.noSample = this.product.noSample,
            this.form.style = this.product.style,
            this.form.fitting = this.product.fitting,
            this.mainFabricImages = this.product.mainFabricImages,
            this.form.code = this.product.code,
            this.form.combo = this.product.combo,
            this.form.detailCombo = this.product.detailCombo,
            this.form.threadBody = this.product.threadBody,
            this.form.threadCombo = this.product.threadCombo,
            this.form.threadBotton = this.product.threadBotton,
            this.form.threadBottonhole = this.product.threadBottonhole,
            this.labelImages = this.product.labelImages
        },
    },
    mounted(){
        if(this.form.indexId != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        if(this.product != null){
            this.getProduct()
        }
        this.getData()
    },
    computed:{
    ...mapGetters('productDesign',['product']),
        
    }
}
</script>