<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>{{formName}}</b-breadcrumb-item>
            
        </b-breadcrumb>
        <b-container fluid>
            <b-card>
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Size Spec</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <template v-if="roles">
                            <div class="text-right">
                                <b-button variant="success" size="sm" v-on:click="show = !show">Create Size Spec</b-button>
                            </div>
                            </template>
                        </b-col>
                        
                    </b-row>
                </template>
                <b-card sub-title="Filter">
            <b-row>
                 <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                        label="Brand"
                        label-for="brand-select"
                        label-cols="3" 
                        label-size="sm"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-select
                            id="brand-select"
                            v-model="filters.brand"
                            :options="brandOptFilter"
                            :aria-describedby="ariaDescribedby"
                            size="sm"
                        >
                        <template #first>
                            <option value="">All</option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                 <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                        label="Category"
                        label-for="category-select"
                        label-cols="3" 
                        label-size="sm"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-select
                            id="category-select"
                            v-model="filters.category"
                            :options="categoryOptFilter"
                            :aria-describedby="ariaDescribedby"
                            size="sm"
                        >
                        <template #first>
                            <option value="">All</option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="auto" md="4" lg="4">
                        <b-form-group
                           label="Description"
                           label-for="search-description"
                           label-cols="3" 
                           label-size="sm"
                           v-slot="{ ariaDescribedby }"
                        >
                            <b-input-group size="sm">
                                <b-form-input 
                                    id="inputDescription" 
                                    v-model="filter"
                                    type="search Description"
                                    placeholder="Type to Search" 
                                    trim
                                ></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                        </b-input-group>
                            </b-form-group>
                    </b-col>
            </b-row>
        </b-card>
                <b-table
                    striped 
                    hover
                    show-empty
                    responsive
                    :filter="filter"
                    :filter-included-fields="includeFiled"
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :fields="fields"
                    :items="filterProduct"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <b-button title="Detail" size="xs" variant="dark" v-on:click="viewData(item.id,item.size,item.size_detail)">
                            
                            <b-icon icon="eye-fill" aria-label="Help"></b-icon> Detail
                        </b-button>
                        <template v-if="roles">
                        <b-button v-if="!item.size_detail" title="Ubah" size="xs" variant="warning" v-on:click="editData(item.id,item.brand,item.category,item.description,item.size)">
                            <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                        </b-button>
                        </template>
                    </template>
                </b-table>
                <div class="mt-3">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="center"
                    >

                    </b-pagination>
                </div>
                <b-modal
                    hide-footer
                    v-model="show"
                    hide-backdrop
                    
                    v-on:cancel="hideModal()"
                    v-on:close="hideModal()"
                    ok-title="Save"
                    size="lg"
                    ref="modal"
                    no-close-on-backdrop
                    scrollable 
                    title="Create Size Form"
                >
                    <div id="modal">
                        <validation-observer ref="observer" v-slot="{handleSubmit}">
                            <b-form @submit.prevent="handleSubmit(onSubmit)" >
                                <b-row>
                                    <b-col sm="auto" md="12">
                                        <!-- Brand -->
                                            <validation-provider
                                                name="brand"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Brand
                                                    </template>
                                                    <b-form-select value-field="value"
                                                                        text-field="value"  id="inputBrand" v-model="form.brand" :options="brandOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Brand -->
                                        <!-- Category -->
                                            <validation-provider
                                                name="category"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Category
                                                    </template>
                                                    <b-form-select value-field="value"
                                                                        text-field="value"  id="inputCategory" v-model="form.category" :options="setCategory" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputCategory">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Category -->
                                        <!-- Description -->
                                            <validation-provider
                                                name="Description"
                                                :rules="{ required: false }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Description<code>*</code>
                                                    </template>
                                                    <b-form-textarea 
                                                        
                                                        id="inputDescription" 
                                                        v-model="form.description" 
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-textarea>
                                                    <b-form-invalid-feedback id="inputDescription">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Description -->
                                        <!-- Size -->
                                            <validation-provider
                                                name="size"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Size
                                                    </template>
                                                    <b-row>
                                                        <b-col sm="12">
                                                            <b-form-checkbox-group 
                                                            
                                                            button-variant="light"
                                                            value-field="value"
                                                            text-field="value"  
                                                            id="inputSize" 
                                                            v-model="form.size" 
                                                            :options="sizeOpt" 
                                                            :state="getValidationState(validationContext)"  
                                                            trim></b-form-checkbox-group>
                                                        </b-col>
                                                    </b-row>
                                                    <b-form-invalid-feedback id="inputSize">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Size -->
                                        <b-button variant="warning" v-on:click="hideModal()" block size="sm">Cancel</b-button>
                                        <b-button variant="success" block size="sm" type="submit">Save</b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </div>
                </b-modal>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import apiProduct from '@/store/product-form/master-param.js'
export default {
    data(){
        return{
             filter:null,
           search:null,
           filters:{
                brand:'',
                category:'',
            },
            brandOptFilter:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'Anthm',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
                {value:'Van Hausen',text:'Van Hausen'},
            ],
            categoryOptFilter:[
               {value:'BAG',text:'BAG'},
               {value:'BELT',text:'BELT'},
               {value:'BERMUDA',text:'BERMUDA'},
               {value:'BLAZER',text:'BLAZER'},
               {value:'Basic',text:'Basic'},
               {value:'CAP',text:'CAP'},
               {value:'CHINOS',text:'CHINOS'},
               {value:'DENIM',text:'DENIM'},
               {value:'JACKET',text:'JACKET'},
               {value:'JAS',text:'JAS'},
               {value:'JOGGER',text:'JOGGER'},
               {value:'PANTS',text:'PANTS'},
               {value:'PANTS REGULAR',text:'PANTS REGULAR'},
               {value:'PANTS SLIM',text:'PANTS SLIM'},
               {value:'POLO SHIRT LS',text:'POLO SHIRT LS'},
               {value:'SHIRT',text:'SHIRT'},
               {value:'SHIRT MILAN SS',text:'SHIRT MILAN SS'},
               {value:'SHIRT MODERN LS',text:'SHIRT MODERN LS'},
               {value:'SHIRT MODERN SS',text:'SHIRT MODERN SS'},
               {value:'SHIRT REGULAR LS',text:'SHIRT REGULAR LS'},
               {value:'SHIRT REGULAR SS',text:'SHIRT REGULAR SS'},
               {value:'SHIRT SLIM LS',text:'SHIRT SLIM LS'},
               {value:'SHIRT SLIM SS',text:'SHIRT SLIM SS'},
               {value:'SHOES',text:'SHOES'},
               {value:'SUIT',text:'SUIT'},
               {value:'SWEATER',text:'SWEATER'},
               {value:'SWEATSHIRT',text:'SWEATSHIRT'},
               {value:'TIE',text:'TIE'},
               {value:'TSHIRT',text:'TSHIRT'},
               {value:'WALLET',text:'WALLET'},
               {value:'XXXYYYZZZ',text:'XXXYYYZZZ'},
            ],
            includeFiled:['description'],
            formName:'Size Spec',
            paging:{
                perPage:10,
                currentPage:1,
            },
            fields:[
                {key:'actions',label:'#'},
                'brand','category','description'
            ],
            show:false,
            form:{
                sizeId:null,
                brand:null,
                category:null,
                description:null,
                size:[],
            },
            brandOpt:[],
            categoryOpt:[],
            sizeOpt:[],

            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User']
        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('sizeSpec',['GET_SIZESPEC_DASHBOARD','POST_SIZESPEC_HEADER','PUT_SIZESPEC_HEADER','POST_SIZESPEC_DETAIL','SET_SIZESPEC_HEADER','RESET_STATE']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getDataSizeSpec(){
            this.GET_SIZESPEC_DASHBOARD()
            .then(response => {
                console.log(response);
            })
        },
        createData(){
            this.show = true
        },
        editData(id,brand,category,description,size){
            this.form.sizeId = id
            this.form.brand = brand
            this.form.category = category
            this.form.description = description
            // this.form.size = size
            let arrSize = JSON.parse(size)
            for (let i = 0; i < arrSize.length; i++) {
                const objSize = arrSize[i]

                this.form.size.push(objSize)
                
            }
            this.show = true

            

        },
        hideModal(){
            this.show = false
            this.form.brand = null
            this.form.category = null
            this.form.description = null
            this.form.size = []
            this.form.sizeId = null
        },
        onSubmit(){
            if(!this.form.sizeId){
                // console.log('post ok');
                this.POST_SIZESPEC_HEADER({
                    brand       :this.form.brand,
                    category    :this.form.category,
                    description :this.form.description,
                    size        :JSON.stringify(this.form.size)
                }).then(response => {
                    
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                        this.hideModal()
                        this.getDataSizeSpec()
                    
                }).catch(error => {
                    return error
                })
            }else{
                // console.log('put ok');
                this.PUT_SIZESPEC_HEADER({
                    size_id     :this.form.sizeId,
                    brand       :this.form.brand,
                    category    :this.form.category,
                    description :this.form.description,
                    size        :JSON.stringify(this.form.size)
                }).then(response => {
                    
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                        this.hideModal()
                        this.getDataSizeSpec()
                    
                }).catch(error => {
                    return error
                })
            }
        },
        getMasterParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                console.log(response);
                    this.brandOpt = response.data.payload.filter(d=>d.category ==='Brand')
                    this.categoryOpt = response.data.payload.filter(c=>c.category ==='Category')
                    this.sizeOpt = response.data.payload.filter(s=>s.category ==='Size')
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        viewData(id,size,detail){
            // console.log('sz',JSON.stringify(detail));
            // this.RESET_STATE()
            this.SET_SIZESPEC_HEADER({id,size,detail})
                
                this.$router.push({
                    name:'AppsSizeSpecDetailPage',
                    params:{
                        size_id:id,
                    }
                })
            
        }
    },
    created(){
        this.getDataSizeSpec()
        this.getMasterParam()
    },
    computed:{
        ...mapGetters('sizeSpec',['SIZESPEC_DASHBOARD']),
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        loadSizeSpecDashboard(){
            console.log(this.SIZESPEC_DASHBOARD);
            return this.SIZESPEC_DASHBOARD
        },
         filterProduct(){
           let brands = this.filters.brand
           let categories = this.filters.category
           return this.loadSizeSpecDashboard.filter(function(item){
               let filtereds = true
                    if(brands && brands.length > 0){
                        filtereds = item.brand == brands
                    }
                    if(filtereds){
                    if(categories && categories.length > 0){
                        filtereds = item.category == categories
                    }
                    }
             return filtereds
           }) 

        },
        setCategory(){
            let brandId = this.brandOpt.filter(i=>i.value == this.form.brand)

            return this.categoryOpt.filter(i=>i.parent == brandId[0].id)

        },
        rows(){
            if(this.loadSizeSpecDashboard != null){
                return this.loadSizeSpecDashboard.length
            }
        }
    }

}
</script>