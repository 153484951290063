<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto">
                        
                        <!-- Code Acc -->
                        <validation-provider
                            name="codeAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Code Accessories
                                </template>
                            
                                <b-form-select id="inputCodeAcc" v-model="form.codeAcc"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                <b-form-invalid-feedback id="inputCodeAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Type Acc -->
                        <validation-provider
                            name="TypeAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4"  
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Type Accessories
                                </template>
                            
                                <b-form-input id="inputTypeAcc" v-model="form.typeAcc"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputTypeAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Supplier -->
                        <validation-provider
                            name="supplier"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Supplier
                                </template>
                                <b-form-input id="inputSupplierAcc" v-model="form.supplier"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputSupplierAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Detail Acc -->
                        <validation-provider
                            name="DetailAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Detail Accessories
                                </template>
                            
                                <b-form-textarea id="DetailCodeAcc" v-model="form.detailAcc"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                <b-form-invalid-feedback id="DetailCodeAcc">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        
                        
                    </b-col>
                    <b-col cols="auto">
                        

                        <!-- Images Acc -->
                        <validation-provider
                            name="imagesAcc"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images Accessories
                                </template>
                                <b-form-file
                                    multiple
                                    ref="fileInput"
                                    id="inputImagesAcc"
                                    v-model="form.imagesAcc"
                                    no-drop
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose file here..."
                                    accept=".jpg, .png,"
                                    :file-name-formatter="formatNames"
                                    v-on:change="onFileChange"
                                ></b-form-file>
                                    <div v-for="img in imagesUrl" :key="img.name">
                                        <p class="small">
                                            <a :href="img.src">{{img.fileName}}</a>
                                            <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                            </b-badge>
                                        </p>
                                    </div>
                                <b-form-invalid-feedback id="inputImagesBotton">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <div>
                            <b-button block  variant="success" type="submit">Save</b-button>
                        </div>
                    </b-col>
                    
                    
                    
                </b-row>
                    
            </b-form>
        </validation-observer>
        
        <div>
            <b-table
                responsive
                striped 
                hover
                :items="dataAcc"
                :fields="fields"
            >
                
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="data">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                        
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    name:'FormAcc',
    
    
    data(){
        return{
            formName: 'Acc',
            modulName: null,
            tabIndex:1,
            isValid:false,
            fields:[
                {key:'index', label:'#'},
                'code','type','supplier','detail',
                {key:'images', label:'Images'},
                {key:'actions', label:''},
            ],
            dataAcc: [],
            imagesUrl:[],
            form:{
                indexId:null,
                codeAcc:null,
                typeAcc:null,
                supplier:null,
                imagesAcc:[],
                detailAcc:null,
            },
            codeOpt:[
                {value:'Code A', text:'Code A'},
                {value:'Code B', text:'Code B'},
                {value:'Code C', text:'Code C'},
                {value:'Code D', text:'Code D'},
            ]
        }
    },
    methods:{
        ...mapActions('productDesign',['add','delete','update','get']),
        
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            if(this.form.indexId == null){
                this.add({
                    formname: this.formName,
                    code:this.form.codeAcc,
                    type:this.form.typeAcc,
                    supplier:this.form.supplier,
                    detail:this.form.detailAcc,
                    images:this.imagesUrl,
                })
            }else{
                this.update({
                    formname: this.formName,
                    id:this.form.indexId,
                    code:this.form.codeAcc,
                    type:this.form.typeAcc,
                    supplier:this.form.supplier,
                    detail:this.form.detailAcc,
                    images:this.imagesUrl,
                })
            }
            
            this.clearForm()
        
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeAcc = ''
            this.form.typeAcc = ''
            this.form.supplier = ''
            this.form.imagesAcc = []
            this.imagesUrl = []
            this.form.detailAcc = ''
            this.$refs.observer.reset()
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                // this.form.imagesAcc.push(file[i])
                // this.imagesUrl = URL.createObjectURL(file[i])
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
            return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        deleteData(id){
            
            this.delete({id:id,formname:this.formName})
        },
        editData(index){
            
            this.get({id:index,formname:this.formName})
            this.form.indexId   = index
            this.form.codeAcc   = this.accDetail.code
            this.form.typeAcc   = this.accDetail.type
            this.form.supplier  = this.accDetail.supplier
            this.form.detailAcc = this.accDetail.detail
            this.imagesUrl      = this.accDetail.images
        },
        getAccList(){
            this.dataAcc = this.accList
        }
    },

    mounted(){
        if(this.form.indexId != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        this.getAccList()

    },
    computed:{
    ...mapGetters('productDesign',['accList','accDetail']),
        
    }
}
</script>