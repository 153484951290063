import { getAPI, getAPIReport } from "./api-base";
import { resolve, reject } from "core-js/fn/promise";

import axios from "axios";
import { axiosBase } from "./api-base";

//NEW PATH GET LIST DEPT
let APIDomain = new URL(window.location.href);
let APIPort = "";
if (APIDomain.hostname == "localhost") {
  APIPort = "8000";
} else {
  APIPort = "8005";
}
const APIUrl = "https://" + APIDomain.hostname + ":" + APIPort;

const ListDept = axios.create({
  namespaced: true,
  baseURL: APIUrl,
});
//END NEW PATH GET LIST DEPT

const apiPath = {
  rptAccess: "/v1/api/rpt-list/",
  rptAccessSmg: "/v1/api/rpt-list-smg/",
  rptDataDailyHealth: "/v1/api/bbi-reports/daily-health-report/",
  rptDataDailyHealthV2: "/v1/api/bbi-reports/daily-health-report-v2/",
  rptDataDailyHealthV3: "/v1/api/bbi-reports/daily-health-report-v3/",
  rptDataDailyHealthV4: "/v1/api/bbi-reports/daily-health-report-v4/",
  rptDataDailyHealthV5: "/v1/api/bbi-reports/daily-health-report-v5/",
  rptDataDailyHealthVerification:
    "/v1/api/bbi-reports/daily-health-report-v2-verification/",
  rptDataHealthDeclaration: "/v1/api/bbi-reports/health-declaration-report/",
  rptDataHealthDeclarationV2:
    "/v1/api/bbi-reports/health-declaration-report-v2/",
  rptDataRapidTest: "/v1/api/bbi-reports/rapid-test-report/",
  rptDataHealthHistory: "/v1/api/bbi-reports/health-history-report/",
  rptDataHealthHistoryV2: "/v1/api/bbi-reports/health-history-report-v2/",
  listDepartment: "/api/active-dept/",
  listDepartmentNew: "/api/active-div-group/",
  getOrganization: "/api/active-org/",
  listDepartmentNewSmg: "/api/active-div-group-smg/",
  listAltDepartment: "/api/active-alt-dept/",
  listDepartmentSmg: "/v1/api/dept-smg/",
  listValidDepartmentSmg: "/api/valid-dept-smg/",
  pivotRptDataSalesReport: "/v1/api/bbi-reports/sales/daily-sales-report/",
  pivotRptProductivityReport: "/v1/api/bbi-reports/sales/daily-sales-report/",
  pivotRptBrandReport: "/v1/api/bbi-reports/sales/daily-sales-report/",
};

export function fnApiFetchRptAccess(params) {
  if (params == null || params.businessunit == "BBI1") {
    return new Promise((resolve, reject) => {
      getAPI
        .get(apiPath.rptAccess)
        .then((rsp) => {
          resolve(rsp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      getAPI
        .post(apiPath.rptAccessSmg, params)
        .then((rsp) => {
          resolve(rsp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

// BBI (HR) REPORTS
export function fnApiFetchRptDataDailyHealth(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataDailyHealth, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataDailyHealthV2(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataDailyHealthV2, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataDailyHealthV3(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataDailyHealthV3, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataDailyHealthV4(filters) {
  return new Promise((resolve, reject) => {
    getAPIReport
      .post(apiPath.rptDataDailyHealthV4, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function fnApiFetchRptDataDailyHealthV5(filters) {
  return new Promise((resolve, reject) => {
    getAPIReport
      .post(apiPath.rptDataDailyHealthV5, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataDailyHealthVerification(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataDailyHealthVerification, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataRapidTest(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataRapidTest, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataHealthDeclaration(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataHealthDeclaration, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataHealthDeclarationV2(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataHealthDeclarationV2, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataHealthHistory(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataHealthHistory, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchRptDataHealthHistoryV2(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.rptDataHealthHistoryV2, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// PIVOT TABLE REPORTS
export function fnApiFetchPivotRptDailySales(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.pivotRptDataSalesReport, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchPivotRptProductivity(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.pivotRptProductivityReport, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchPivotRptBrand(filters) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.pivotRptBrandReport, filters)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

// FILTERS
export function fnApiFetchListDepartment(businessunit) {
  if (businessunit == null || businessunit == "BBI1") {
    return new Promise((resolve, reject) => {
      ListDept.get(apiPath.listDepartment)
        .then((rsp) => {
          resolve(rsp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } else {
    // return new Promise((resolve, reject) => {
    //     getAPI.get(apiPath.listDepartmentSmg)
    //     .then(rsp => { resolve(rsp) })
    //     .catch(err =>{ reject(err) })
    // })
    return new Promise((resolve, reject) => {
      ListDept.post(apiPath.listValidDepartmentSmg, {
        businessunit: businessunit,
      })
        .then((rsp) => {
          resolve(rsp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export function fnApiFetchOrganization(params) {
  // ListDept.post(apiPath.getOrganization, {
  //   params,
  // })
  //   .then((rsp) => {
  //     console.log(rsp);
  //     resolve(rsp);
  //   })
  //   .catch((err) => {
  //     console.log(rsp);
  //     reject(err);
  //   });
  return new Promise((resolve, reject) => {
    ListDept.post(apiPath.getOrganization, {
      params,
    })
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchListDepartmentNew(businessunit) {
  if (businessunit == null || businessunit == "BBI1") {
    return new Promise((resolve, reject) => {
      ListDept.get(apiPath.listDepartmentNew)
        .then((rsp) => {
          resolve(rsp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      ListDept.get(apiPath.listDepartmentNewSmg, {
        businessunit: businessunit,
      })
        .then((rsp) => {
          resolve(rsp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export function fnApiFetchListDepartmentSemarang() {
  return new Promise((resolve, reject) => {
    ListDept.get(apiPath.listDepartment)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchListAltDepartment() {
  return new Promise((resolve, reject) => {
    ListDept.get(apiPath.listAltDepartment)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
