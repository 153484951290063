<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold">{{ formTitle }}</span>
      <span class="fw-normal fs-larger"> {{ rptVersion }} </span>
    </h2>
    <b-row>
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <b-row class="form">
            <!-- Filter Type Doc -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
                <label>Type Document*</label>
                <v-select
                  label="type_label"
                  :reduce="(data) => data.type_doc"
                  :options="this.optionsType"
                  v-model="selectedType"
                  class="form-custom custom-select"
                  ref="opt_type"
                ></v-select>
              </div>
            </b-col>
            <!-- end Filter Type Doc -->

            <!-- Filter No Document -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
                <label>No./Title Document**</label>
                <input
                  class="form-control mb-3 bg-transparent"
                  type="text"
                  name="titdoc"
                  id="titdoc"
                  v-model="inputTitleDoc"
                />
              </div>
            </b-col>
            <!-- end Filter No Document -->
          </b-row>
          <div>
            <b-row>
              <b-col cols="9" class="text-danger text-sm-left">
                <span class="mr-3">*Required Fields</span>
                <span>**Optional Fields</span></b-col
              >
            </b-row>
          </div>
          <br />
          <div>
            <b-row>
              <b-col cols="8">
                <b-button
                  class="btn-spacing"
                  type="button"
                  variant="primary"
                  @click="searchData()"
                  ><b-icon icon="search"></b-icon> Search</b-button
                >
                <b-button
                  class="btn-spacing-left text-white"
                  type="button"
                  variant="info"
                  v-on:click="resetGetData()"
                  ><b-icon icon="arrow-repeat"></b-icon> Reset</b-button
                >
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br />
    <b-col cols="12" class="text-right">
      <b-button
        class="btn-spacing"
        type="button"
        variant="success"
        @click="showModalAdd"
        :hidden="!permButton()"
        ><b-icon icon="plus-square"></b-icon> | Add New Data</b-button
      >
    </b-col>

    <!--Modals -->
    <b-modal
      hide-footer
      v-model="show"
      hide-backdrop
      v-on:ok="onSubmit()"
      ok-title="Save"
      size="lg"
      ref="modal"
      no-close-on-backdrop
      header-bg-variant="success"
      header-text-variant="white"
      body-bg-variant="white"
      scrollable
    >
      <template #modal-header>
        <h5>
          {{ indexId == null ? "Add New Data" : "Edit Document " + noDoc }}
        </h5>
        <b-button size="sm" variant="danger" @click="hideModalAdd()">
          X
        </b-button>
      </template>

      <div id="modal">
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(saveDMSDocument)">
            <b-row class="row-modal">
              <b-col sm="auto" md="12">
                <!-- Type Document Add -->
                <validation-provider
                  name="type-doc"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    label="Type Document"
                    label-for="type-doc-input"
                    :invalid-feedback="alertTypeDoc"
                  >
                    <b-form-select
                      v-model="selectedType2"
                      :options="optionsType"
                      value-field="type_doc"
                      text-field="type_label"
                      ref="opt_type"
                      class="bg-transparent"
                      @input="assignNoDoc($event)"
                      id="inputtype-doc"
                      :state="getValidationState(validationContext)"
                      trim
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >-- Please select type document
                          --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback
                      id="inputtype-doc"
                    ></b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Type Document Add -->

                <!-- Start Date Apply -->
                <validation-provider
                  name="start_date_apply"
                  vid="start_date_apply"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    label="Start Date Apply"
                    label-for="start-date-input"
                  >
                    <b-form class="datepicker">
                      <VueDatePicker
                        v-model="selectedDate"
                        class="vd__div"
                        no-header
                        format="DD MMM YYYY"
                        min-date="1900-01-01"
                        placeholder="Choose start date apply"
                        ref="opt_start_date_apply"
                        id="inputdate-doc"
                      />
                    </b-form>
                    <b-form-invalid-feedback
                      id="inputdate-doc"
                      :state="getValidationState(validationContext)"
                      >{{ alertDateDoc }}</b-form-invalid-feedback
                    >
                  </b-form-group>
                </validation-provider>
                <!-- Start Date Apply -->

                <!-- Title Document -->
                <validation-provider
                  name="title-doc"
                  vid="title-doc"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    label="Document Title"
                    label-for="title-input"
                    ref="opt_title"
                    aria-describedby="input-title-doc-feedback"
                  >
                    <b-form-input
                      v-model="titleDoc"
                      class="bg-transparent"
                      placeholder="Enter title document"
                      ref="opt_title"
                      trim
                      id="inputtitle-doc"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-title-doc-feedback">{{
                      alertTitDoc
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Title Document -->

                <!-- Desc Document -->
                <b-form-group label="Description" label-for="desc-input">
                  <b-form-textarea
                    id="desc-input"
                    v-model="descDoc"
                    placeholder="Desciption document..."
                    rows="3"
                    max-rows="6"
                    class="bg-transparent"
                    ref="opt_desc"
                  ></b-form-textarea>
                </b-form-group>
                <!-- Desc Document -->

                <!-- No Document -->
                <validation-provider
                  name="no-doc"
                  vid="no-doc"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group label="Document No." label-for="nodoc-input">
                    <b-input-group :prepend="typenoDoc">
                      <b-form-input
                        id="nodoc-input"
                        v-model="noDoc"
                        class="bg-transparent"
                        placeholder="Enter no. document"
                        ref="opt_nodoc"
                        aria-describedby="nodoc-input-feedback"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-no-doc-feedback">{{
                        alertNoDoc
                      }}</b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
                <!-- No Document -->

                <!-- Upload Document -->
                <validation-provider
                  name="file-doc"
                  vid="file-doc"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    label="Upload"
                    label-for="upload-input"
                    :invalid-feedback="alertFileUpload"
                  >
                    <b-form-file
                      v-model="fileDMS"
                      name="fileDocumentUpload"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file pdf here..."
                      :accept="allow_file"
                      @input="onChangeFileUpload"
                      ref="opt_filedoc"
                      :state="getValidationState(validationContext)"
                      :file-name-formatter="formatNames"
                      no-drop
                      id="input-file-doc-feedback"
                    ></b-form-file>
                    <div v-for="doc in docsURL" :key="doc.name">
                      <p class="small">
                        <a :href="doc.src">{{ doc.fileName }}</a>
                        <b-badge
                          href="#"
                          v-on:click="removeDoc(doc.fileName, docsURL)"
                          variant="light"
                        >
                          <b-icon
                            icon="x-circle-fill"
                            font-scale="1"
                            variant="danger"
                          ></b-icon>
                        </b-badge>
                      </p>
                    </div>
                    <b-form-invalid-feedback id="input-file-doc-feedback">
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <!-- Upload Document -->

                <!-- BU Access Document -->
                <validation-provider
                  name="buacc-doc"
                  vid="buacc-doc"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    label="Business Unit Access"
                    label-for="buacc-input"
                    invalid-feedback="Business Unit Access is required"
                    :rules="{ required: true }"
                  >
                    <b-form-radio-group
                      v-model="BUAccess"
                      ref="opt_buacc"
                      :options="optionsBUAccess"
                      value-field="value"
                      text-field="name"
                      :state="getValidationState(validationContext)"
                      @change="chkBuAcces($event)"
                    >
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                        >Please select one options</b-form-invalid-feedback
                      >
                    </b-form-radio-group>
                  </b-form-group>
                </validation-provider>
                <!-- BU Access Document -->

                <!-- BU Access Document Specific -->
                <transition-group name="fade">
                  <b-col cols="11.5" offset="1" key="1" v-if="!checkBUAccess()">
                    <validation-provider
                      name="buaccspc-doc"
                      vid="buaccspc-doc"
                      :rules="{ required: !checkBUAccess() }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Business Unit Access Specific"
                        label-for="buaccspc-input"
                        ref="opt_buaccspc"
                      >
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          >Please select business unit</b-form-invalid-feedback
                        >
                        <b-form-checkbox-group
                          class="padding-top"
                          v-for="(buName, index) in BuList"
                          :key="index"
                          :state="getValidationState(validationContext)"
                        >
                          <input
                            ref="chkboxBuAccSpc"
                            type="checkbox"
                            :value="buName.code"
                            v-model="BuAccSpc"
                            @change="chkBuAccesSpcs($event)"
                          />
                          {{ buName.name }}
                        </b-form-checkbox-group>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </transition-group>
                <!-- BU Access Document Speific -->

                <!-- Dept Permission Document -->
                <validation-provider
                  name="deptperm-doc"
                  vid="deptperm-doc"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    label="Department Permission"
                    label-for="deptperm-input"
                    invalid-feedback="Dept Permission is required"
                    :rules="{ required: true }"
                  >
                    <b-form-radio-group
                      v-model="DeptPerm"
                      ref="opt_deptperm"
                      :options="optionsDeptPerm"
                      value-field="value"
                      text-field="name"
                      :state="getValidationState(validationContext)"
                    >
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                        >Please select one options</b-form-invalid-feedback
                      >
                    </b-form-radio-group>
                  </b-form-group>
                </validation-provider>
                <!-- Dept Permission Document -->

                <!-- Dept Permission Document Specific -->
                <transition-group name="fade">
                  <b-col cols="11.5" offset="1" key="1" v-if="!checkDeptPerm()">
                    <validation-provider
                      name="deptpermspc-doc"
                      vid="deptpermspc-doc"
                      :rules="{ required: !checkDeptPerm() }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Department Permission Specific"
                        label-for="deptpermspc-input"
                        ref="opt_deptpermspc"
                      >
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          >Please select department</b-form-invalid-feedback
                        >
                        <multiselect
                          v-model="DeptPermsSpc"
                          tag-placeholder="Add this as a new tag"
                          placeholder="Search or add a tag"
                          label="formattedValue"
                          track-by="formattedValue"
                          :options="DeptList"
                          :multiple="true"
                          :taggable="true"
                          :state="getValidationState(validationContext)"
                          :option-label="false"
                        >
                          <!-- <template slot="option" slot-scope="{ option }">
                            ({{ option.company }}) - {{ option.groupdept }}
                          </template> -->
                        </multiselect>
                        <!-- <b-form-checkbox-group
                          class="padding-top"
                          v-for="(deptName, index) in DeptList"
                          :key="index"
                          :state="getValidationState(validationContext)"
                        >
                          <input
                            ref="chkboxDeptPermsSpc"
                            type="checkbox"
                            :value="deptName.dept_name"
                            v-model="DeptPermsSpc"
                          />
                          {{
                            deptName.business_unit + "-" + deptName.dept_name
                          }}
                        </b-form-checkbox-group> -->
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </transition-group>
                <!-- Dept Permission Document Speific -->

                <!-- Reason Edit Document -->
                <b-form-group
                  label="Reason Edit Document"
                  label-for="desc-input"
                  v-if="indexId != null"
                >
                  <b-form-textarea
                    id="reasonEdit-input"
                    v-model="reasonEditDoc"
                    placeholder="Reason edit document..."
                    rows="3"
                    max-rows="6"
                    class="bg-transparent"
                    ref="opt_reasonEdit"
                  ></b-form-textarea>
                </b-form-group>
                <!-- Reason Edit Document -->

                <hr />
                <b-col cols="12" class="text-right bg-white">
                  <b-button
                    class="mr-2"
                    variant="warning"
                    @click="hideModalAdd()"
                    :disabled="isLoadingData"
                    ><b-icon icon="x-square"></b-icon> Cancel</b-button
                  >
                  <b-button
                    variant="success"
                    type="submit"
                    @click="FocusWarning()"
                    :disabled="isLoadingData"
                    ><b-icon icon="box-arrow-in-right"></b-icon> Save</b-button
                  >
                </b-col>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
    <!-- End Add Modals -->

    <hr />
    <b-row>
      <b-col xs="12">
        <div>
          <span>*Click Column Header to Sort The Data</span>
          <b-table
            striped
            hover
            responsive
            id="my-table"
            :items="resultDMS"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isBusy"
            head-variant="dark"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>

            <template #cell(decs_doc)="{ item }">
              <span>
                {{
                  item.decs_doc.length > 100
                    ? item.decs_doc.slice(0, 100) + "..."
                    : item.decs_doc
                }}
              </span>
              <span v-if="readMore[item.id]">
                {{ item.decs_doc }}
              </span>
              <b-button
                class=""
                v-if="!readMore[item.id] && item.decs_doc.length > 100"
                @click="showMore(item)"
                variant="link"
              >
                read more...
              </b-button>
              <b-button
                class=""
                v-if="readMore[item.id]"
                @click="showLess(item)"
                variant="link"
              >
                read less...
              </b-button>
            </template>

            <template #cell(actions)="{ item }">
              <b-button
                size="sm"
                class="mr-1"
                variant="primary"
                @click="seeDocument(item)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
              <b-button
                size="sm"
                class="mr-1"
                variant="warning"
                :hidden="!permButton()"
                @click="editDocument(item)"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <!-- <b-button
                size="sm"
                class="mr-1"
                variant="danger"
                :hidden="
                  !getDMSRole.includes('Superadmin')
                    ? true
                    : getDMSRole.includes('Superadmin') &&
                      item.status_active == true
                    ? true
                    : false
                "
                @click="deleteDocument(item)"
              > -->
              <b-button
                size="sm"
                class="mr-1"
                variant="danger"
                :hidden="true"
                @click="deleteDocument(item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
              <hr :style="hideCheckBox" />
              <b-form-checkbox
                v-model="item.status_active"
                :style="hideCheckBox"
                name="check-button"
                switch
                @change="checkStatusDoc($event, item.no_document)"
              >
                <span
                  class="text-info font-weight-bold"
                  style="font-size: 12px"
                  >{{
                    item.status_active == true ? "Active" : "Inactive"
                  }}</span
                >
              </b-form-checkbox>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            prev-text="⏪"
            next-text="⏩"
            first-number
            last-number
            pills
            align="right"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// Import api-method fbPOST
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../api/api-methods";
// Import api-path HR Daily Health V2 dan Site List
import { APIPATH_DMS, APIPATH_BBIAPPS } from "../../../../api/api-paths";

import {
  fnApiFetchListDepartment,
  fnApiFetchListDepartmentNew,
  fnApiFetchOrganization,
} from "@/api/api-reports.js";
// import { fnApiFetchListDepartment } from "@/api/api-reports.js";
// import vue loading
import Loading from "vue-loading-overlay";
// import vue sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { APIUrl } from "../../../../api/api-base";
import { setFocus, showToastOK } from "@/core/pluginHelper";

// import vue multiselect
import Multiselect from "vue-multiselect";

import Vue from "vue";
import {
  BootstrapVue,
  IconsPlugin,
  BPagination,
  BootstrapVueIcons,
  BIconNodePlusFill,
} from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
Vue.use(Loading);
Vue.use(VueSweetalert2);

export default {
  name: "DMSDocument",
  components: {
    Multiselect,
    BPagination,
  },
  data() {
    return {
      formName: "Dashboard Document Management System",
      formTitle: "Document Management System",
      rptVersion: "v1",
      errorMessage: null,
      show: false,

      // Add Document Form
      selectedType2: null,
      alertTypeDoc: "Type Document is required",
      selectedDate: null,
      alertDateDoc: "Date Document is required",
      titleDoc: "",
      alertTitDoc: "Title Document is required",
      descDoc: null,
      noDoc: "",
      typenoDoc: "/",
      alertNoDoc: "No. Document is required",
      fileDMS: null,
      filedoc_name: null,
      alertFileUpload: "Upload Document is required",
      chkFileUpload: false,
      BUAccess: null,
      BuList: [],
      BuAccSpc: [],
      DeptPerm: null,
      DeptList: [],
      DeptPermsSpc: [],
      publicDoc: null,

      docsURL: [],

      optionsType: [],
      selectedType: null,
      inputTitleDoc: null,
      getStatusDoc: null,
      statusActiveDoc: null,

      // Get Data Document
      resultDMS: [],
      getDMSData: [],
      getDMSRole: [],
      // getBBI1Dept: null,
      // getBBISDept: null,
      // getAOI1Dept: null,
      // getAOI2Dept: null,
      deptGroupList: null,

      hideCheckBox: null,

      fields: [
        { key: "no_document", label: "No Document", sortable: true },
        { key: "title_doc", label: "Title Document", sortable: true },
        { key: "decs_doc", label: "Description", sortable: true },
        { key: "start_date_apply", label: "Apply Date", sortable: true },
        { key: "actions", label: "Action" },
      ],
      optionsBUAccess: [
        { value: "all", name: "All Business Unit" },
        { value: "specific", name: "Specific Business Unit" },
      ],
      optionsDeptPerm: [
        { value: "all", name: "All Department" },
        { value: "specific", name: "Specific Department" },
      ],

      optionsDeptPermSpc: [
        { text: "SCC", value: "scc" },
        { text: "ICT", value: "ict" },
      ],

      perPage: 10,
      currentPage: 1,
      isBusy: false,

      messages: null,
      isLoadingData: false,

      // Edit Form
      indexId: null,
      reasonEditDoc: null,
      tanggal_harian: null,

      // Dept List,
      // deptListBBIP: [],
      // deptListBBIS: [],
      // deptListAOI1: [],
      // deptListAOI2: [],
      // deptListAOI4: [],
      depListAll: [],

      allow_file: null,

      mimeTypeList: [],
      extTypeDoc: [],
      mimeTypeDoc: [],

      readMore: {},
    };
  },
  computed: {
    ...mapGetters("session", ["userData", "serverDate"]),
    rows() {
      return this.resultDMS.length;
    },
    chkBUAcc() {
      return Boolean(this.BUAccess);
    },
    chkDeptPerm() {
      return Boolean(this.DeptPerm);
    },
    titleState() {
      return this.titleDoc.length > 0 ? true : false;
    },
    dateState() {
      return this.selectedDate != null ? true : false;
    },
    chkTypeDoc() {
      return this.selectedType2 != null ? true : false;
    },
    nodocState() {
      return this.noDoc.length > 0 ? true : false;
    },
    stateBuAccSpc() {
      return this.BuAccSpc.length > 0 ? true : false;
    },
    stateDeptPermsSpc() {
      return this.DeptPermsSpc.length > 0 ? true : false;
    },
    readMoreText() {
      return this.showFullText ? "less" : "more";
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    customLabel(option) {
      return `${option.groupdept} - ${option.company}`;
    },

    assignNoDoc(e) {
      if (e != "" || e != null) {
        this.typenoDoc = e + "-";
        if (e == null) {
          e = "";
          this.typenoDoc = e + "-";
        }
      } else {
        this.typenoDoc = "-";
        this.selectedType2 = "";
      }
    },
    showMore(item) {
      this.$set(this.readMore, item.id, true);
    },
    showLess(item) {
      this.$set(this.readMore, item.id, false);
    },
    onChangeFileUpload(e) {
      // const FileUpload = this.$refs.opt_filedoc.files[0];
      // var file = e.target.files;

      var getMimeType = e.type;
      var getSizeFile = e.size;

      if (e) {
        if (!this.mimeTypeDoc.includes(getMimeType)) {
          this.alertFileUpload =
            "File must be " + this.extTypeDoc.toString() + " File";
          this.chkFileUpload = false;
          return this.alertFileUpload;
        } else {
          if (getSizeFile > 10240 * 1024) {
            this.alertFileUpload = "File too big (> 10MB)";
            this.chkFileUpload = false;
            return this.alertFileUpload;
          } else {
            this.chkFileUpload = true;
          }
        }
      }

      // for (let i = 0; i < file.length; i++) {
      let newNodoc = this.noDoc.replace(/[/]/g, "-");
      let newtypeDoc = this.typenoDoc.replace(/[/]/g, "-");
      let fileName = e.name;

      fileName =
        newtypeDoc +
        newNodoc +
        "." +
        fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

      let docs = {
        src: URL.createObjectURL(e),
        fileName: fileName,
        document: e,
      };

      this.docsURL.push(docs);
      // }

      if (this.docsURL.length > 1) {
        for (let i = 0; i < this.docsURL.length; i++) {
          this.removeDoc(this.docsURL[i].fileName, this.docsURL);
        }
      }
    },
    removeDoc(filename, modelname) {
      modelname.splice(filename, 1);
      return this.formatNames();
    },
    formatNames() {
      return this.docsURL.length === 0
        ? this.$refs.opt_filedoc.reset()
        : `${this.docsURL.length} files selected`;
    },
    checkStatusDoc(e, noDoc) {
      this.getStatusDoc = null;
      if (e != null || e != "") {
        if (e == true) {
          e = "True";
        } else {
          e = "False";
        }
        this.getStatusDoc = e;

        this.isBusy = !this.isBusy;
        fnPOST(APIPATH_DMS.editStatusDMSv1, {
          no_document: noDoc,
          status_active: this.getStatusDoc,
        })
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Successfully Status Edited",
            });

            this.isBusy = !this.isBusy;
            this.getDMSDocument();
          })
          .catch((err) => {
            if (err.response.status === 400) {
              console.log(err.response.data.message);

              this.showNotification({
                type: "error",
                message: err.response.data.message,
              });

              this.isBusy = !this.isBusy;
              this.getDMSDocument();
            }
          });
      }
    },
    chkBuAcces(e) {
      //cek dropdown BU cekk kalau pilih all
      this.DeptList = [];
      if (this.BUAccess === "all") {
        for (let i = 0; i < this.deptGroupList.length; i++) {
          const element = this.deptGroupList[i];
          this.DeptList.push(element);
        }

        // for (let i = 0; i < this.deptListBBIS.length; i++) {
        //   const element = this.deptListBBIS[i];
        //   this.DeptList.push(element);
        // }
        // for (let i = 0; i < this.deptListAOI1.length; i++) {
        //   const element = this.deptListAOI1[i];
        //   this.DeptList.push(element);
        // }
        // for (let i = 0; i < this.deptListAOI2.length; i++) {
        //   const element = this.deptListAOI2[i];
        //   this.DeptList.push(element);
        // }
        // //tandai
        // for (let i = 0; i < this.deptListAOI4.length; i++) {
        //   const element = this.deptListAOI4[i];
        //   this.DeptList.push(element);
        // }
      }
    },
    chkBuAccesSpcs(e) {
      //cek dropdown BU cekk kalau pilih selected
      // ganti dengan BBI atau AOI saja
      this.DeptList = [];
      if (this.BuAccSpc.includes("BBI") || this.BuAccSpc.includes("BBI1")) {
        for (let i = 0; i < this.deptGroupList.length; i++) {
          const element = this.deptGroupList[i];
          // Check if the company is "BBI"
          if (element.company === "BBI") {
            this.DeptList.push(element);
          }
        }
      }
      if (
        (this.BuAccSpc.includes("BBI") || this.BuAccSpc.includes("BBI1")) &&
        this.BuAccSpc.length > 1
      ) {
        for (let i = 0; i < this.deptGroupList.length; i++) {
          const element = this.deptGroupList[i];
          // Check if the company is "BBI"
          this.DeptList.push(element);
        }
      }

      if (!this.BuAccSpc.includes("BBI1")) {
        for (let i = 0; i < this.deptGroupList.length; i++) {
          const element = this.deptGroupList[i];
          // Check if the company is "BBI"
          if (element.company === "AOI") {
            this.DeptList.push(element);
          }
        }
      }
      // if (this.BuAccSpc.includes("AOI3")) {
      //   for (let i = 0; i < this.deptListBBIS.length; i++) {
      //     const element = this.deptListBBIS[i];
      //     this.DeptList.push(element);
      //   }
      // }
      // if (this.BuAccSpc.includes("AOI1")) {
      //   for (let i = 0; i < this.deptListAOI1.length; i++) {
      //     const element = this.deptListAOI1[i];
      //     this.DeptList.push(element);
      //   }
      // }
      // if (this.BuAccSpc.includes("AOI2")) {
      //   for (let i = 0; i < this.deptListAOI2.length; i++) {
      //     const element = this.deptListAOI2[i];
      //     this.DeptList.push(element);
      //   }
      // }
      // //tandai
      // if (this.BuAccSpc.includes("AOI4")) {
      //   for (let i = 0; i < this.deptListAOI4.length; i++) {
      //     const element = this.deptListAOI4[i];
      //     this.DeptList.push(element);
      //   }
      // }
    },

    deleteDocument(item) {
      Vue.swal
        .fire({
          title: "Are you sure?",
          text: "You want to permanently delete this document?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.isBusy = !this.isBusy;
            fnPOST(APIPATH_DMS.deleteDMSDocV1, {
              no_document: item.no_document,
            })
              .then((rsp) => {
                this.showNotification({
                  type: "success",
                  message: "Successfully Deleted Inactive Document",
                });

                this.isBusy = !this.isBusy;
                this.getDMSDocument();
              })
              .catch((err) => {
                if (err.response.status === 400) {
                  console.log(err.response.data.message);

                  this.showNotification({
                    type: "error",
                    message: err.response.data.message,
                  });

                  this.isBusy = !this.isBusy;
                  this.getDMSDocument();
                }
              });
          }
        });
    },

    getMimeTypeDoc() {
      fnGET(APIPATH_BBIAPPS.mimeTypeDocDMS).then((rsp) => {
        this.$set(this, "mimeTypeList", rsp.data);
        for (let i = 0; i < this.mimeTypeList.length; i++) {
          let temp = this.mimeTypeList[i];
          let getExt = "." + temp.mime_type;
          this.extTypeDoc.push(getExt);
          this.mimeTypeDoc.push(temp.mime_doc);
        }
        this.allow_file = this.extTypeDoc.toString();
      });
    },

    getDMSDocument() {
      let userData = this.userData;
      this.isBusy = !this.isBusy;

      let apps = null;
      for (let i = 0; i < userData.roles.length; i++) {
        const appName = userData.roles[i];

        if (appName.app === "DMS") {
          apps = appName.app;
          // console.log("test", appName.role_list);
          this.$set(this, "getDMSRole", appName.role_list);
          // const priorityRole = ["Superadmin", "Auditor", "Staff"];
          // const roleList = appName.role_list;

          // let foundRole = null;

          // for (const role of priorityRole) {
          //   if (roleList.includes(role)) {
          //     foundRole = role;
          //     break;
          //   }
          // }
          // console.log(foundRole); // cek role superadmin

          // for (let i = 0; i < appName.role_list.length; i++) {
          //   const roleList = appName.role_list[i];
          //   if (roleList === "Superadmin") {
          //     this.$set(this, "getDMSRole", roleList);
          //     console.log(roleList); // cek role superadmin
          //     break;
          //   }
          //   if (roleList === "Auditor") {
          //     this.$set(this, "getDMSRole", roleList);
          //     console.log(roleList); // cek role superadmin
          //     break;
          //   }
          //   if (roleList != "Staff" || roleList != "Auditor") {
          //     this.$set(this, "getDMSRole", roleList); // cek role superadmin
          //     break;
          //   }
          // }
        }
        if (appName.app === "Default") {
          apps = appName.app;
          // console.log("test", appName.role_list);
          this.$set(this, "getDMSRole", appName.role_list);
        }
      }

      if (apps === null) {
        this.getDMSRole = null;
        // for (let i = 0; i < userData.roles.length; i++) {
        //   const appName = userData.roles[i];
        //   if (appName.app === "Default") {
        //     apps = appName.app;
        //     for (let i = 0; i < appName.role_list.length; i++) {
        //       const roleList = appName.role_list[i];
        //       this.$set(this, "getDMSRole", roleList);
        //     }
        //   }
        // }
      }
      // fnApiFetchListDepartmentNew("BBI1").then((rsp) => {
      //   this.deptListBBIP = rsp.data.payload.slice(1);
      // });

      setTimeout(() => {
        fnApiFetchOrganization({
          bunit: userData.business_unit,
          dept: userData.dept,
        }).then((rsp) => {
          let groupdept = rsp.data.payload[0].groupdept;
          // console.log("rsp", rsp.data.payload[0].groupdept);
          // this.deptListBBIP = rsp.data.payload.slice(1);
          // tambahkan role
          fnPOST(APIPATH_DMS.getdataDMSV1, {
            nik: userData.nik,
            name: userData.fullname,
            bunit: userData.business_unit,
            dept: userData.dept,
            groupdept: groupdept.slice(0, 3),
            role: this.getDMSRole,
          }).then((rsp) => {
            this.isBusy = !this.isBusy;
            this.$set(this, "getDMSData", rsp.data.payload);
            // console.log(rsp.data.payload);
            this.$set(this, "resultDMS", this.getDMSData.dataDMS);

            if (!this.getDMSRole.includes("Superadmin")) {
              let style = {
                opacity: 0,
                position: `absolute`,
                left: 9999 + `px`,
              };
              this.$set(this, "hideCheckBox", style);
            }
          });
        });
      }, 1200);
    },

    searchData() {
      let storage = JSON.parse(localStorage.getItem("userData"));
      if (this.selectedType == null) {
        this.errorMessage = "Please select Type Document filter";
        setFocus(this.$refs.opt_type);
      } else {
        this.isBusy = !this.isBusy;
        setTimeout(() => {
          fnPOST(APIPATH_DMS.filterDMSV1, {
            nik: storage.nik,
            type_doc: this.selectedType,
            title_doc: this.inputTitleDoc,
            dept: storage.dept,
            bu: storage.business_unit,
            role: this.getDMSRole,
          })
            .then((rsp) => {
              this.isBusy = !this.isBusy;
              this.resultDMS = [];
              this.selectedType = null;
              this.inputTitleDoc = null;
              this.$set(this, "resultDMS", rsp.data.payload);
              showToastOK({ type: "success", message: "Data Loaded" });
            })
            .catch((err) => {
              if (err.response.status !== 401) {
                showToastOK({
                  type: "error",
                  message: "Failed loading data",
                });
              }
            });
        }, 1500);
      }
    },

    resetGetData() {
      this.resultDMS = [];
      this.getDMSDocument();
    },

    permButton() {
      // console.log(this.getDMSRole);
      if (this.getDMSRole.includes("Superadmin")) {
        return true;
      }
      return false;
    },

    permButtonDelete() {
      if (this.getDMSRoleincludes("Superadmin")) {
        for (let i = 0; i < this.resultDMS.length; i++) {
          let statusDoc = this.resultDMS[i];
          if (statusDoc.status_active != "true") {
            return true;
          }
        }
      }
      return false;
    },

    seeDocument(item) {
      fnPOST(APIPATH_DMS.getDMSFileV1, {
        no_document: item.no_document,
      }).then((rsp) => {
        function base64toBlob(base64Data) {
          const sliceSize = 1024;
          const byteCharacters = atob(base64Data);
          const bytesLength = byteCharacters.length;
          const slicesCount = Math.ceil(bytesLength / sliceSize);
          const byteArrays = new Array(slicesCount);

          for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
              bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
          }

          return new Blob(byteArrays, { type: rsp.data.payload.mimetype });
        }
        if (rsp.data.payload.filename != "No file exist") {
          var blob = base64toBlob(rsp.data.payload.upload_doc);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              blob,
              "FileDMS." + rsp.data.payload.filetype
            );
          } else {
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl);
          }
        } else {
          Vue.swal.fire({
            icon: "info",
            title: "The document file cannot be found.",
            // html: this.messages,
          });
        }
      });
    },

    editDocument(item) {
      this.indexId = item.id;
      this.docsURL = [];
      this.show = true;
      this.optionsType.shift();

      let masterData = this.resultDMS.filter((data) => data.id === item.id);
      // console.log(masterData);
      let masterDataDMS = masterData[0];

      this.indexId = masterDataDMS.id;
      this.selectedType2 = masterDataDMS.type_doc;
      this.selectedDate = masterDataDMS.start_date_apply;
      this.titleDoc = masterDataDMS.title_doc;
      this.descDoc = masterDataDMS.decs_doc;

      let nodocs = masterDataDMS.no_document.slice(
        ((masterDataDMS.no_document.indexOf("-") - 1) >>> 0) + 2
      );

      this.typenoDoc = this.selectedType2 + "-";
      this.noDoc = nodocs;

      let fileName = masterDataDMS.upload_doc.slice(
        ((masterDataDMS.upload_doc.lastIndexOf("/") - 1) >>> 0) + 2
      );

      fnPOST(APIPATH_DMS.getDMSFileV1, {
        no_document: item.no_document,
      }).then((rsp) => {
        if (rsp.data.payload.filename != "No file exist") {
          let fileUrl =
            "data:" +
            rsp.data.payload.mimetype +
            ";base64," +
            rsp.data.payload.upload_doc;
          fetch(fileUrl)
            .then((result) => result.blob())
            .then((blob) => {
              let file = new File([blob], fileName, {
                type: rsp.data.payload.mimetype,
              });
              let docs = {
                src: fileUrl,
                fileName: fileName,
                document: file,
              };
              this.docsURL.push(docs);
              this.fileDMS = docs;
            });
        } else {
          let docs = {
            src: "",
            fileName: "No file exist",
            document: null,
          };
          this.docsURL.push(docs);
          this.fileDMS = docs;
        }
      });

      if (masterDataDMS.bu_access == "all") {
        this.BUAccess = masterDataDMS.bu_access;
      } else {
        this.BUAccess = "specific";
      }
      // console.log(this.BUAccess);
      let BuAccSpc = masterDataDMS.bu_access.split(",");
      if (BuAccSpc.includes("BBI") && !BuAccSpc.includes("BBI1")) {
        BuAccSpc.push("BBI1");
      }
      this.BuAccSpc = BuAccSpc;

      if (masterDataDMS.dept_access == "all") {
        this.DeptPerm = masterDataDMS.dept_access;
      } else {
        this.DeptPerm = "specific";
        let DeptPermsSpc = masterDataDMS.dept_access.split(",");
        // console.log(DeptPermsSpc);
        if (this.BuAccSpc.includes("BBI") || this.BuAccSpc.includes("BBI1")) {
          for (let i = 0; i < this.deptGroupList.length; i++) {
            const element = this.deptGroupList[i];
            // Check if the company is "BBI"
            if (element.company === "BBI") {
              this.DeptList.push(element);
            }
          }
        }
        if (
          (this.BuAccSpc.includes("BBI") || this.BuAccSpc.includes("BBI1")) &&
          this.BuAccSpc.length > 1
        ) {
          for (let i = 0; i < this.deptGroupList.length; i++) {
            const element = this.deptGroupList[i];
            // Check if the company is "BBI"
            this.DeptList.push(element);
          }
        }

        if (!this.BuAccSpc.includes("BBI1")) {
          for (let i = 0; i < this.deptGroupList.length; i++) {
            const element = this.deptGroupList[i];
            // Check if the company is "BBI"
            if (element.company === "AOI") {
              this.DeptList.push(element);
            }
          }
        }
        if (this.BUAccess === "all") {
          for (let i = 0; i < this.deptGroupList.length; i++) {
            const element = this.deptGroupList[i];
            this.DeptList.push(element);
          }
        }

        for (let i in DeptPermsSpc) {
          this.DeptPermsSpc.push({
            // dept_code: DeptPermsSpc[i],
            // dept_name: DeptPermsSpc[i],
            formattedValue: DeptPermsSpc[i],
          });
        }
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    FocusWarning() {
      if (this.selectedType2 == null || this.selectedType2 == "") {
        this.$refs.opt_type.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_type.$el.classList.value =
          this.$refs.opt_type.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_type.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.selectedDate == null || this.selectedDate == "") {
        this.$refs.opt_start_date_apply.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_start_date_apply.$el.classList.value =
          this.$refs.opt_start_date_apply.$el.classList.value +
          " animation-shake";
        setTimeout(() => {
          this.$refs.opt_start_date_apply.$el.classList.remove(
            "animation-shake"
          );
        }, 1500);
        return false;
      }
      if (this.titleDoc == null || this.titleDoc == "") {
        this.$refs.opt_title.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_title.$el.classList.value =
          this.$refs.opt_title.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_title.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.noDoc == null || this.noDoc == "") {
        this.$refs.opt_nodoc.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_nodoc.$el.classList.value =
          this.$refs.opt_nodoc.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_nodoc.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.docsURL.length == 0) {
        this.$refs.opt_filedoc.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_filedoc.$el.classList.value =
          this.$refs.opt_filedoc.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_filedoc.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.BUAccess == null || this.BUAccess == "") {
        this.$refs.opt_buacc.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_buacc.$el.classList.value =
          this.$refs.opt_buacc.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_buacc.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.BUAccess == "specific") {
        if (this.BuAccSpc == null || this.BuAccSpc == "") {
          this.$refs.opt_buaccspc.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_buaccspc.$el.classList.value =
            this.$refs.opt_buaccspc.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_buaccspc.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
      }
      if (this.DeptPerm == null || this.DeptPerm == "") {
        this.$refs.opt_deptperm.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_deptperm.$el.classList.value =
          this.$refs.opt_deptperm.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_deptperm.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.DeptPerm == "specific") {
        if (this.DeptPermsSpc == null || this.DeptPermsSpc == "") {
          this.$refs.opt_deptpermspc.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_deptpermspc.$el.classList.value =
            this.$refs.opt_deptpermspc.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_deptpermspc.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
      }
    },

    showModalAdd() {
      this.show = true;
      this.optionsType.shift();
    },
    hideModalAdd() {
      this.show = false;
      this.indexId = null;
      this.selectedType2 = null;
      this.selectedDate = null;
      this.titleDoc = "";
      this.descDoc = null;
      this.noDoc = "";
      this.typenoDoc = "-";
      this.fileDMS = null;
      this.BUAccess = null;
      this.BuAccSpc = [];
      this.DeptPerm = null;
      this.DeptPermsSpc = [];
      this.filedoc_name = null;
      this.publicDoc = null;
      this.docsURL = [];
      this.DeptList = [];
      this.$refs.observer.reset();
      this.optionsType.unshift({
        code_type: "TD0000",
        id: 0,
        type_doc: "ALL",
        type_label: "ALL",
      });
    },
    checkBUAccess() {
      if (this.BUAccess == "specific") {
        return false;
      }
      this.BuAccSpc = [];
      return true;
    },
    checkDeptPerm() {
      if (this.DeptPerm == "specific") {
        return false;
      }
      this.DeptPermsSpc = [];
      return true;
    },
    saveDMSDocument() {
      this.noDoc = this.typenoDoc + this.noDoc;
      if (this.descDoc == null || this.descDoc == "") {
        this.descDoc = "-";
      }

      var deptPermSpc = this.DeptPermsSpc.map(function (dept) {
        return dept["formattedValue"];
      });

      let objTempDMS1 = this.BUAccess;
      let objTempDMS2 = this.DeptPerm;
      if (objTempDMS1 == "all" && objTempDMS2 == "all") {
        this.publicDoc = "True";
      }
      if (objTempDMS1 == "specific") {
        objTempDMS1 = this.BuAccSpc.toString();
        this.publicDoc = "False";
      }
      if (objTempDMS2 == "specific") {
        objTempDMS2 = deptPermSpc.toString();
        this.publicDoc = "False";
      }

      if (this.docsURL.length === 0) {
        this.alertFileUpload = "Upload Document is required";
        return this.alertFileUpload;
      }
      let formDataDMS = new FormData();
      formDataDMS.append("nik", this.userData.nik);
      formDataDMS.append("nama_karyawan", this.userData.fullname);
      formDataDMS.append("lokasi", this.userData.business_unit);
      formDataDMS.append("no_document", this.noDoc);
      formDataDMS.append("title_doc", this.titleDoc);
      formDataDMS.append("type_doc", this.selectedType2);
      formDataDMS.append("decs_doc", this.descDoc);
      formDataDMS.append("start_date_apply", this.selectedDate);
      formDataDMS.append("is_public_doc", this.publicDoc);

      formDataDMS.append("bu_access", objTempDMS1);

      formDataDMS.append("dept_access", objTempDMS2);
      formDataDMS.append("upload_doc", this.docsURL[0].document);
      formDataDMS.append("status_active", "True");

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: "#f89830",
        loader: "dots",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
      });

      if (this.indexId == null) {
        this.messages = "Data document berhasil disimpan.<br>";

        setTimeout(() => {
          this.isBusy = !this.isBusy;

          this.isLoadingData = true;
          fnPOST(APIPATH_DMS.dmsDocumentV1, formDataDMS)
            .then((response) => {
              loader.hide();
              let postSubmitInfo = `Nama : ${response.data.payload.nama} <br>NIK : ${response.data.payload.nik} <br>Tgl Tambah Data Baru : ${response.data.payload.timestamp} WIB<br>`;
              this.messages += postSubmitInfo;

              Vue.swal.fire({
                icon: "info",
                title: "Data Document Berhasil Disimpan",
                html: this.messages,
              });

              this.showNotification({
                type: "success",
                message: "Successfully Saved",
              });

              this.isBusy = !this.isBusy;
              this.getDMSDocument();
            })
            .catch((err) => {
              loader.hide();
              if (err.response.status === 400) {
                console.log(err.response.data.message);
                Vue.swal.fire({
                  icon: "danger",
                  title: "Data Document Gagal Disimpan",
                  html: err.response.data.message,
                });
                this.showNotification({
                  type: "error",
                  message: err.response.data.message,
                });

                this.isBusy = !this.isBusy;
                this.getDMSDocument();
              }
            })
            .finally(() => {
              this.isLoadingData = false;
            });
        }, 1200);

        return this.hideModalAdd();
      } else {
        var today = new Date();
        var month = ("0" + (today.getMonth() + 1)).slice(-2);
        var date = ("0" + today.getDate()).slice(-2);
        var fullDate = today.getFullYear() + "-" + month + "-" + date;
        var time =
          today.getHours() +
          ":" +
          today.getMinutes() +
          ":" +
          today.getSeconds();
        var dateTime = fullDate + " " + time;

        formDataDMS.append("id", this.indexId);
        formDataDMS.append("modify_reason", this.reasonEditDoc);
        formDataDMS.append("modified_at", dateTime);
        formDataDMS.append(
          "modified_by",
          this.userData.nik + "-" + this.userData.fullname
        );

        this.messages = "Data document berhasil diubah.<br>";

        setTimeout(() => {
          this.isBusy = !this.isBusy;
          this.isLoadingData = true;
          fnPUT(APIPATH_DMS.dmsDocumentV1, formDataDMS)
            .then((response) => {
              loader.hide();
              let postSubmitInfo = `Nama : ${response.data.payload.nama} <br>NIK : ${response.data.payload.nik} <br>Tgl Ubah Data : ${response.data.payload.timestamp} WIB<br>`;
              this.messages += postSubmitInfo;

              Vue.swal.fire({
                icon: "info",
                title: "Data Document Berhasil Diubah",
                html: this.messages,
              });

              this.showNotification({
                type: "success",
                message: "Successfully Edited",
              });

              this.isBusy = !this.isBusy;
              this.getDMSDocument();
            })
            .catch((err) => {
              loader.hide();
              if (err.response.status === 400) {
                console.log(err.response.data.message);
                Vue.swal.fire({
                  icon: "danger",
                  title: "Data Document Gagal Diubah",
                  html: err.response.data.message,
                });
                this.showNotification({
                  type: "error",
                  message: err.response.data.message,
                });

                this.isBusy = !this.isBusy;
                this.getDMSDocument();
              }
            })
            .finally(() => {
              this.isLoadingData = false;
            });
        }, 1200);

        return this.hideModalAdd();
      }
    },
  },
  created() {
    fnGET(APIPATH_BBIAPPS.typeDocDMS).then((rsp) => {
      this.$set(this, "optionsType", rsp.data);
      this.optionsType.unshift({
        code_type: "TD0000",
        id: 0,
        type_doc: "ALL",
        type_label: "ALL",
      });
    });
    fnGET(APIPATH_BBIAPPS.listBU).then((rsp) => {
      this.$set(this, "BuList", rsp.data.payload);
      //console.log(rsp.data.payload);
    });
    fnGET(APIPATH_DMS.getDeptGroup).then((rsp) => {
      // Assuming rsp.data.payload is an array of objects with properties company and groupdept
      this.$set(
        this,
        "deptGroupList",
        rsp.data.payload.map((item) => {
          return {
            ...item,
            formattedValue: `(${item.company}) ${item.groupdept}`,
          };
        })
      );
    });
    this.getDMSDocument();
    this.$set(this, "tanggal_harian", this.serverDate);

    this.getMimeTypeDoc();

    // Get Dept BBIP
    // fnApiFetchListDepartment("BBI1").then((rsp) => {
    //   this.deptListBBIP = rsp.data.payload.slice(1);
    // });

    // fnApiFetchListDepartmentNew("BBI1").then((rsp) => {
    //   this.deptListBBIP = rsp.data.payload.slice(1);
    // });
    // // Get Dept BBIS
    // fnApiFetchListDepartmentNew("AOI3").then((rsp) => {
    //   this.deptListBBIS = rsp.data.payload.slice(1);
    // });

    // // Get Dept AOI1
    // fnApiFetchListDepartmentNew("AOI1").then((rsp) => {
    //   this.deptListAOI1 = rsp.data.payload.slice(1);
    // });

    // // Get Dept AOI2
    // fnApiFetchListDepartmentNew("AOI2").then((rsp) => {
    //   this.deptListAOI2 = rsp.data.payload.slice(1);
    // });
    // fnApiFetchListDepartmentNew("AOI4").then((rsp) => {
    //   this.deptListAOI4 = rsp.data.payload.slice(1);
    // });
  },
};
</script>

<style src="./DMSDocument.scss" lang="scss" scoped />
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
