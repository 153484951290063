<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid>
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Project List" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style="display: flex">
                <h3>Project List</h3>
                <!-- <router-link tag="b" to="/app/sfc-list-review-actual-activity" style="position: absolute; right: 15px;">
                  <b-button size="md" type='button' variant="info">
                    <b-icon icon="arrow-left"></b-icon>
                    Back
                  </b-button>
                </router-link> -->
              </div>
            </template>

            <br />
            <b-row>
              <b-col sm="5">
                <b-form-group
                  label="Sales Person"
                  label-for="SalesPerson"
                  label-cols-sm="3"
                  label-align-sm="left"
                  label-size="sm"
                  class="mb-0"
                >
                  <v-select
                    v-model="sales"
                    :clearable="true"
                    :reduce="(data) => data.sales_nik"
                    :required="true"
                    label="sales_name"
                    placeholder="Choose Sales"
                    :options="options.sales"
                    ref="OptSales"
                  >
                  <template v-slot:option="option">
                    {{ option.sales_nik }} - {{option.sales_name}}
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    {{ option.sales_nik }} - {{option.sales_name}}
                  </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row align-h="end">
              <b-col lg="3">
                  <b-form-group
                  label="Search"
                  label-for="Search-input"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                  >
                  <b-input-group size="sm">
                      <b-form-input
                      id="Search-input"
                      v-model="search"
                      type="search"
                      placeholder="Type to Search"
                      ></b-form-input>

                      <b-input-group-append>
                      <b-button :disabled="!search" @click="search = ''">Clear</b-button>
                      </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
              </b-col>
            </b-row>

            <br />

            <b-table
              :no-border-collapse="true"
              striped
              stacked="md"
              class="header-background max_width_fit"
              hover
              small
              outlined
              :per-page="paging.perPage"
              :current-page="paging.currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
              responsive
              sort-icon-left
              :fields="fields"
              @filtered="onFiltered"
              :filter="search"
              :items="FilterItems"
            >
              <!-- table empty -->
              <template #empty="scope">
                <p class="text-center">{{ scope.emptyText }}</p>
              </template>

              <!-- last Activity -->
              <template #cell(last_activity)="row">
                <span v-html="NullTable(row.value)"></span>
              </template>

              <template #cell(action)="row">
                <b-button
                  title="View"
                  size="xs"
                  variant="outline-info"
                  v-on:click="
                    viewData(row.item.project_id, row.item.customer_name)
                  "
                  style="margin-right: 5px"
                >
                  <b-icon icon="eye-fill" aria-label="View"></b-icon>
                </b-button>
                <b-button
                  v-if="row.item.currentphase == 'Ready To Close'"
                  title="Closing"
                  size="xs"
                  variant="outline-primary"
                  v-on:click="closeData(row)"
                >
                  <b-icon
                    icon="file-earmark-check-fill"
                    aria-label="Closing"
                  ></b-icon>
                </b-button>
                <b-button
                  v-if="row.item.currentphase == 'Initial' || row.item.currentphase == 'Progress'"
                  title="Abort"
                  size="xs"
                  variant="outline-danger"
                  v-on:click="abortData(row)"
                >
                  <b-icon
                    icon="trash-fill"
                    aria-label="Abort"
                  ></b-icon>
                </b-button>
              </template>
            </b-table>
            <b-row>
              <b-col sm="4">
                Showing {{ showPerPage }} of {{ totalData }} entries
              </b-col>
              <b-col sm="4">
                <b-form-group
                  label="Per Page"
                  label-for="PerPage"
                  label-cols-sm="6"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-input
                    style="max-width: 80px"
                    id="PerPage"
                    v-model="paging.perPage"
                    @input="Pagging()"
                    type="number"
                    max="999"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4" align-self="end" class="my-1">
                <b-pagination
                  v-model="paging.currentPage"
                  :total-rows="rows"
                  :per-page="paging.perPage"
                  align="right"
                  size="sm"
                >
                  <template #first-text><span class="">First</span></template>
                  <template #prev-text><span class="">Prev</span></template>
                  <template #next-text><span class="">Next</span></template>
                  <template #last-text><span class="">Last</span></template>
                  <template #page="{ page, active }">
                    <b v-if="active">{{ Pagination(page) }} {{ page }}</b>
                    <i v-else>{{ page }}</i>
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        ok-variant="success"
        cancel-variant="warning"
        header-bg-variant="secondary"
        hide-footer
        hide-header-close
        hide-backdrop
        size="md"
        no-close-on-backdrop
        title="Status Reason"
        v-model="reasonAbort"
      >
        <form>
          <b-form-group
            label="Reason"
            label-for="PO ID"
          >
            <b-form-input
              id="Reason"
              v-model="status_reason"
              required
            ></b-form-input>
          </b-form-group>
          <b-row align-h="end">
              <b-col sm="3">
                <b-button
                  block
                  size="md"
                  type="button"
                  variant="success"
                  @click="saveReason()"
                >
                  Submit
                </b-button>
              </b-col>
              <b-col sm="3">
                <b-button
                  block
                  size="md"
                  @click="cancelAbort()"
                  type="button"
                  variant="warning"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
        </form>
      </b-modal>
      <Loader
        v-if="isLoadingData"
        fullPage
        useOverlay
        loader="spinner"
      ></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import Vue from "vue";
import { fnPOST } from "../../../../api/api-methods";
import { APIPATH_SALESFORCE } from "../../../../api/api-paths";

export default {
  components: {
    ModelListSelect,
    Loader,
  },
  data() {
    const now = new Date();
    const today = now.toISOString().slice(0, 10);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: "numeric" };
    const year = now.toLocaleString("en-US", optyear);
    const optmonth = { month: "numeric" };
    const SMonth = { month: "short" };
    const month = now.toLocaleString("en-US", optmonth);
    const Strmonth = now.toLocaleString("en-US", SMonth);
    const optdays = { day: "numeric" };
    const days = now.toLocaleString("en-US", optdays);
    return {
      isLoadingData: false,
      formName: "Project List",
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      dayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      today: today,
      isChecked: false,
      disabledForm: false,
      buttonSubmit: null,
      today_days: days,
      today_month: month,
      reasonAbort:false,
      StrMonth: Strmonth,
      previousSelected: [],
      today_year: year,
      fields: [
        {
          key: "action",
          label: "Action",
          sortable: false,
        },
        {
          key: "project_id",
          label: "Project ID",
          class: "min-10vw",
          sortable: true,
        },
        {
          key: "sales_nik",
          label: "sales nik",
          sortable: true,
        },
        {
          key: "sales_name",
          label: "Sales Person",
          class: "min-10vw",
          sortable: false,
        },
        { key: "customer", class: "display-hidden" },
        {
          key: "customer_name",
          label: "Customer Name",
          class: "min-11vw",
          sortable: true,
        },
        {
          key: "currentphase",
          label: "Current Status",
          class: "min-11vw",
          sortable: true,
        },
        {
          key: "last_activity",
          label: "last activity Name",
          class: "min-10vw",
          sortable: false,
        },
        // {
        //   key: "status_tender",
        //   label: "status tender",
        //   sortable: false,
        // },
        // {key:'sub_activity_name', label:'Sub Activity', class:"min-10vw", sortable:false },
        // {key:'reason', label:'Reason', class:"min-10vw", sortable:false },
      ],
      dataTable: [],
      sortBy: "",
      sortDesc: false,
      showPerPage: 0,
      totalData: 0,
      PageNow: 1,
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      form: {
        employee_nik: null,
        employee_name: null,
        end_date: null,
        start_date: null,
      },
      options: {
        sales: [],
        customer: [],
      },
      search:null,
      sales:null,
      status_reason:null,
      rowData:null,
    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    rows() {
      if (this.totalData != null) {
        return this.totalData;
      }
    },
    FilterItems(){
            let sales_nik = this.sales
            return this.dataTable.filter(function(item){
                let filtereds = true
                if(sales_nik && sales_nik.length > 0){
                    filtereds = item.sales_nik == sales_nik
                }
                return filtereds
            })

        },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),


    setData() {
      this.form.employee_nik = this.userData.nik;
      this.form.employee_name = this.userData.fullname;
      let statusRoute = this.$route.params.data;
      let idData = this.$route.params.idData;
      if (statusRoute == undefined || statusRoute == "") {
        statusRoute = null;
      }
      this.LoadData();
      this.getSalesPerson();
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalData = filteredItems.length
        this.paging.currentPage = 1
        this.showPerPage = filteredItems.length
        if (filteredItems.length >= this.paging.perPage) {
            this.showPerPage = this.paging.perPage
        }

    },
    Pagging() {
      if (this.showPerPage > this.paging.perPage) {
        this.showPerPage = this.paging.perPage;
      }
      if (this.showPerPage < this.paging.perPage) {
        this.showPerPage = this.paging.perPage;
      }
      if (this.showPerPage >= this.totalData) {
        this.showPerPage = this.totalData;
      }
    },
    Pagination(value) {
      if (value !== this.PageNow) {
        if (value > this.PageNow) {
          this.PageNow = value;
          // this.nextPage()
          this.showPerPage = this.paging.perPage * this.PageNow;
          if (this.showPerPage >= this.totalData) {
            this.showPerPage = this.totalData;
          }
        } else {
          this.PageNow = value;
          this.showPerPage =
            this.showPerPage -
            (this.showPerPage - this.paging.perPage * this.PageNow);
          if (this.showPerPage <= this.paging.perPage) {
            this.showPerPage = this.paging.perPage;
          }
        }
      }
    },
    getSalesPerson() {
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.getSalesPerson, {})
      .then((rsp) => {
        this.options.sales = rsp.data.payload
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          this.showNotification({
            type: "error",
            message: "Failed, Please Try Again",
          });
        }
      })
      .finally(() => {
        // stop loading
        setTimeout(() => {
          this.isLoadingData = false;
        }, 500);
      });
    },
    LoadData() {
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.getListProject, {})
        .then((rsp) => {
          if (rsp.data.payload == null || rsp.data.payload == undefined) {
            this.dataTable = [];
            this.totalData = 0;
          } else {
            this.dataTable = rsp.data.payload;
            this.totalData = rsp.data.payload.length;
          }
          this.showPerPage = this.paging.perPage;
          if (this.totalData < this.paging.perPage) {
            this.showPerPage = this.totalData;
          }
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({
              type: "error",
              message: "Gagal Load Data, Silahkan Coba Kembali",
            });
          }
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    FormatDate(value) {
      if (value == null || value == undefined || value == "") {
        return " ";
      } else {
        let date = new Date(value);
        let nameDay = this.dayNames[date.getDay()];
        let day = date.getDate();
        if (String(day).length == 1) {
          day = "0" + String(day);
        }
        let year = date.getFullYear();
        let bulan = this.monthNames[date.getMonth()];
        date = nameDay + " " + day + " " + bulan + " " + year;
        return date;
      }
    },
    NullTable(value) {
      let result = value;
      if (value == null || value == undefined || value == "") {
        result = "-";
      }
      if (value !== null) {
        result = value.replace(/[^a-zA-Z0-9,()/& ]/g, "");
      }
      return result;
    },
    viewData(data, customer) {
      this.$router.push({
        name: "SFCListActualProject",
        params: { customer: customer, idData: data },
      });
    },
    closeData(row) {
      let varOk = "warning";
      let Boxtrue = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to close this project ?", {
          title: "Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: varOk,
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          Boxtrue = value;
          if (Boxtrue == true) {
            this.processClose(row);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    abortData(row) {
      this.reasonAbort = true
      this.rowData = row
    },
    cancelAbort() {
      this.reasonAbort = false
      this.rowData = null
      this.status_reason = null
    },
    saveReason() {
      if (this.status_reason == null || this.status_reason == '' || this.status_reason == ' ') {
        alert('reason harus diisi')
        return false
      }
      let projectID = this.rowData.item.project_id;
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.abortProject, {
        project_id: projectID,
        status_reason: this.status_reason,
        user_nik: this.form.employee_nik,
        user_name: this.form.employee_name,
      })
        .then((rsp) => {
          this.showNotification({
            type: "success",
            message: "Successfully Submitted",
          });
          this.LoadData();
          this.cancelAbort()
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({
              type: "error",
              message: "Failed, Please Try Again",
            });
          }
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    processClose(row) {
      let projectID = row.item.project_id;
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.closeProject, {
        project_id: projectID,
        user_nik: this.form.employee_nik,
        user_name: this.form.employee_name,
      })
        .then((rsp) => {
          this.showNotification({
            type: "success",
            message: "Successfully Submitted",
          });
          this.LoadData();
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({
              type: "error",
              message: "Failed, Please Try Again",
            });
          }
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
