import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiPostSalesGroup,
}from '../../api/api-applications-salesforce.js'

Vue.use(Vuex)

const actions = {
    PostSalesGroup(context, formSalesGroup){
        return new Promise((resolve, reject) => {
            fnApiPostSalesGroup(formSalesGroup)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
}

export default new Vuex.Store({
    namespaced: true,
    actions
})