<template>
    <div>
            <b-card title="Product Design" >
                
                <b-row ref="pdfProduct">
                    <b-col cols="12" sm="auto" md="3">
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Duration<code>*</code>
                            </template>
                             <label> {{product.duration}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Brand<code>*</code>
                            </template>
                             <label> {{product.brand}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Collection Name<code>*</code>
                            </template>
                             <label> {{product.collectionName}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Year<code>*</code>
                            </template>
                             <label> {{product.year}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Quartal<code>*</code>
                            </template>
                             <label> {{product.quartal}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"  
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Prod. Month<code>*</code>
                            </template>
                             <label> {{product.prodMonth}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Coll. Month<code>*</code>
                            </template>
                             <label> {{product.collMonth}}</label>
                        </b-form-group>
                        
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Sub. Brand<code>*</code>
                            </template>
                             <label> {{product.subBrand}}</label>
                        </b-form-group>
                        
                    </b-col>
                    <b-col cols="12" sm="auto" md="3">
                        <b-form-group
                            
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Article<code>*</code>
                            </template>
                             <label> {{product.article}}</label>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Repeat Sample<code>*</code>
                            </template>
                             <label> {{product.repeatSample}}</label>
                        </b-form-group>
                        
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Ref. Sample<code>*</code>
                            </template>
                             <label> {{product.noSample}}</label>
                        </b-form-group>
                        
                        <b-form-group
                            label-cols-lg   ="6" 
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Old Sample No.<code>*</code>
                            </template>
                             <label> {{product.noSample}}</label>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Guidance<code>*</code>
                            </template>
                             <label> {{product.guidance}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Size Spec<code>*</code>
                            </template>
                             <label> {{product.specSize}}</label>
                        </b-form-group>
                        
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Category<code>*</code>
                            </template>
                             <label> {{product.category}}</label>
                        </b-form-group>
                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Intensity<code>*</code>
                            </template>
                             <label> {{product.itensity}}</label>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Color<code>*</code>
                            </template>
                             <label> {{product.color}}</label>
                        </b-form-group>

                        <b-form-group
                            label-cols-lg   ="6"
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Note<code>*</code>
                            </template>
                             <label> {{product.note}}</label>
                        </b-form-group>
                        
                    </b-col>
                    <b-col cols="12" sm="auto" md="auto">
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Design<code>*</code>
                            </template>
                            <div v-for="img in product.design" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Technical Drawing
                            </template>
                            <div v-for="img in product.technical" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Code Main Label
                            </template>
                            <div v-for="img in product.mainLabel" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Code Streamer Label
                            </template>
                            <div v-for="img in product.streamerLabel" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Code Size Label
                            </template>
                            <div v-for="img in product.sizeLabel" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        <b-form-group
                            
                            label-size      ="sm"
                        >
                            <template v-slot:label>
                                Code Hang Tag
                            </template>
                            <div v-for="img in product.hangTag" :key="img.src">
                                <p class="small">
                                    <a :href="img.src">{{img.fileName.substring(0,26)}}</a>
                                </p>
                            </div>
                        </b-form-group>
                        
                    </b-col>
                </b-row>
            </b-card>
            <b-card title="Fabric" id="pdfFabric">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="loadFabric"
                    :fields="fieldsFabric"
                >
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>

                </b-table>
            </b-card>
            <b-card title="Accessories" id="pdfAcc">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="loadAcc"
                    :fields="fieldsAcc"
                >
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>

                </b-table>
            </b-card>
            <b-card title="Thread" id="pdfThread">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="loadThread"
                    :fields="fieldsThread"
                >
                    <template #cell(images)="{item}"   >
                        <p class="small" v-for="img in item.images" :key="img.src">
                            <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                        </p>
                    </template>

                </b-table>
            </b-card>
            <b-card title="Artwork" id="Artwork">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="loadArtwork"
                    :fields="fieldsArtwork"
                >
                    <template #cell(images)="{item}"   >
                        <p class="small" v-for="img in item.images" :key="img.src">
                            <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                        </p>
                    </template>

                </b-table>
            </b-card>
            <b-card title="Interlining" id="Interlining">
                <b-table
                    responsive
                    show-empty
                    small
                    fixed
                    :items="loadInterlining"
                    :fields="fieldsInterlining"
                >
                    <template #cell(images)="{item}"   >
                        <p class="small" v-for="img in item.images" :key="img.src">
                            <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                        </p>
                    </template>

                </b-table>
            </b-card>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
            
            productDesign:[],
            fieldsFabric:[
                'code','reference','type','description','supplier','detail','images','used_as'
            ],
            fieldsAcc:[
                'code','reference','type','description','supplier','detail','images','used_as'
            ],
            fieldsThread:[
                'code','reference','type','description','supplier','detail','images','used_as'
            ],
            fieldsArtwork:[
                'code','reference','type','description','supplier','detail','images','used_as'
            ],
            fieldsInterlining:[
                'code','reference','type','description','supplier','detail','images','used_as'
            ]

        }
    },
    methods:{
        goToList(){
            this.$router.push({name:'AppsProductDesign2Page'})
        },
    },
    computed:{
        ...mapGetters('productDesign',['PRODUCT_MATERIAL','product','fabricList','accList','threadList','artworkList','interliningList']),
        loadFabric(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='fabric')
        },
        loadAcc(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='accessories')
        },
        loadThread(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='thread')
        },
        loadArtwork(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='artwork')
        },
        loadInterlining(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='interlining')
        }
    },
    
}
</script>