<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ stActiveReport }}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold">{{ stActiveReport }}</span>
      <span class="fw-normal fs-larger"> {{ rptVersion }} </span>
    </h2>
    <b-row>
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <b-row class="form">
            <!-- Filter BU Code -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
                <label>Business Unit*</label>
                <b-form-select
                  class="form-custom"
                  v-model="selectedBU"
                  placeholder="Choose Business Unit"
                  @change="fetchDept()"
                >
                  <option
                    v-for="(row, index) in optionsBU"
                    :key="index"
                    :value="row.code"
                    :selected="selectedBU"
                  >
                    {{ row.name }}
                  </option>
                </b-form-select>
                <div class="border-bottom-select"></div>
              </div>
            </b-col>
            <!-- end Filter BU Code -->

            <!-- Filter Dept Code -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
                <label>Department*</label>
                <b-form-select
                  class="form-custom"
                  v-model="selectedDept"
                  ref="opt_dept"
                  placeholder="Choose Department Code"
                >
                  <!-- option choose dept value none -->
                  <option :value="null">-- Choose Department Code --</option>
                  <!-- end option choose dept value none -->
                  <option
                    v-for="(row, index) in stDeptList"
                    :key="index"
                    :value="row.dept_code"
                    :selected="selectedDept"
                  >
                    {{ row.dept_code }}
                  </option>
                </b-form-select>
                <div class="border-bottom-select"></div>
              </div>
            </b-col>
            <!-- end Filter Dept Code -->

            <!-- Filter Cat Code -->
            <b-col xs="12" md="3" class="form-col-last">
              <div class="form-div">
                <label>Category*</label>
                  <b-form-radio-group
                    id="radio-category"
                    v-model="selectedCat"
                    style="text-align:center; padding-top: 5px;"
                    class="form-custom custom-select md"
                    :options="optionsCategory"
                    @input="CategoryEvent"
                    ref="opt_cat"
                  ></b-form-radio-group>
                <!-- <label>Category*</label>
                <v-select
                  label="category_name"
                  :reduce="(data) => data.category_name"
                  :options="this.optionsCat"
                  v-model="selectedCat"
                  class="form-custom custom-select"
                  ref="opt_cat"
                ></v-select> -->

            <!-- end Filter Cat Code -->

            <!-- Filter Cat Vaksin -->


                <v-select
                  label="category_name"
                  :reduce="(data) => data.category_name"
                  :options="this.optionsCat"
                  v-model="selectedCatVaksin"
                  :disabled="DisableCatVaksin"
                  class="form-custom custom-select"
                  ref="opt_cat"
                ></v-select>
              </div>
            </b-col>
            <!-- end Filter Cat Vaksin -->
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button
                  class="btn-spacing"
                  type="button"
                  variant="primary"
                  :disabled="isLoadingData"
                  v-on:click="populateReport()"
                  >Load Report</b-button
                >
                <download-excel
                  :class="exportButtonClass()"
                  :data="resultReport2"
                  :fields="excel_fields"
                  :title="excel_title"
                  :name="excel_name"
                >
                  Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button
                  class="btn-spacing-left"
                  type="button"
                  variant="secondary"
                  v-on:click="goToReport()"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </div>
          <br />
          <div>
            <b-row>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Processed at</b-col>
                  <b-col xs="7" md="8" lg="10">: {{ processedDateTime }}</b-col>
                </b-row>
                <b-row>
                  <b-col xs="5" md="4" lg="2">Total Data</b-col>
                  <b-col xs="7" md="8" lg="10">: {{ rows }}</b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br />
    <hr />
    <b-row>
      <b-col xs="12">
        <div>
          <span>*Click at Header Column to Sort The Data</span>
          <b-table
            striped
            hover
            small
            responsive
            id="my-table"
            :items="resultReport2"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isBusy"
            head-variant="dark"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong> Loading...</strong>
              </div>
            </template>

            <!-- Vaksin Ke 1 -->
            <template #cell(tgl_vaksin_1)="row">
                <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
            </template>

            <!-- Vaksin Ke 2 -->
            <template #cell(tgl_vaksin_2)="row">
                <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
            </template>

            <!-- Vaksin Ke 3 -->
            <template #cell(tgl_vaksin_3)="row">
                <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
            </template>

          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            prev-text="⏪"
            next-text="⏩"
            first-number
            last-number
            pills
            align="right"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Import api-method fbPOST
import { fnGET, fnPOST } from "../../../../api/api-methods";
// Import api-path HR Daily Health V2 dan Site List
import { APIPATH_HR, APIPATH_BBIAPPS } from "../../../../api/api-paths";
import { mapState, mapActions, mapGetters } from "vuex";
import { fnApiFetchListDepartment } from "@/api/api-reports.js";

import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { BootstrapVue, IconsPlugin, BPagination } from "bootstrap-vue";
import { showToastOK } from "@/core/pluginHelper";
import loginStore from "@/store/login.js";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component("b-pagination", BPagination);
Vue.component("download-excel", JsonExcel);

export default {
  name: "VaccineHistoryReport2",
  data() {
    return {
      rptVersion: "v2",
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      errorMessage: null,
      selectedBU: null,
      optionsBU: [],
      DisableCatVaksin:true,
      selectedDept: null,
      optionsDept: [],
      selectedCat: 'Semua',
      selectedCatVaksin: null,
      optionsCat: [],
      optionsCategory: ['Semua','Sudah','Belum'],
      resultReport2: [],
      fields: [
        { key: "nik", label: "NIK", sortable: true },
        { key: "nama_karyawan", label: "NAMA KARYAWAN", sortable: true },
        { key: "business_unit", label: "BUSINESS UNIT", sortable: true },
        { key: "department", label: "DEPARTMENT", sortable: true },
        { key: "tgl_vaksin_1", label: "VAKSIN KE-1", sortable: true },
        { key: "tgl_vaksin_2", label: "VAKSIN KE-2", sortable: true },
        { key: "tgl_vaksin_3", label: "VAKSIN KE-3", sortable: true },
        { key: "jenis_vaksin", label: "VAKSIN", sortable: true },
        {
          key: "sertifikat_vaksin",
          label: "ID SERTIFIKAT VAKSIN",
          sortable: true,
        },
      ],
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      excel_fields: {
        NIK: "nik",
        "NAMA KARYAWAN": "nama_karyawan",
        "BUSINESS UNIT": "business_unit",
        DEPARTMENT: "department",
        "VAKSIN KE-1": "tgl_vaksin_1",
        "VAKSIN KE-2": "tgl_vaksin_2",
        "VAKSIN KE-3": "tgl_vaksin_3",
        VAKSIN: "jenis_vaksin",
        "ID SERTIFIKAT VAKSIN": "sertifikat_vaksin",
      },
      processedDateTime: "",
      excel_title: "",
      excel_name: "",
      isLoadingData: false,
    };
  },
  computed: {
    ...mapState("report", ["stActiveReport", "stDeptList"]),
    ...mapGetters("session", ["userData", "localDate"]),
    rows() {
      return this.resultReport2.length;
    },
  },
  methods: {
    ...mapActions("report", [
      "getCurrentDatetime",
      "generateTitleXLS",
      "getListDepartment",
      "initializeReport",
    ]),

    exportButtonClass() {
      let classNames = "btn btn-info";
      if (this.resultReport2.length == 0) {
        classNames += " hide";
      }
      if (this.isLoadingData) {
        classNames += " btn-disabled";
      }
      return classNames;
    },
    FormatDate(value) {
        if(value == null || value == undefined || value == '-') {
            return value
        } else {
            let date = new Date(value)
            let tgl = date.toISOString().slice(8,10)
            let bulan = this.monthNames[date.getMonth()]
            let tahun = date.toISOString().slice(0,4)
            date = tgl+' '+bulan+' '+tahun
        return date
        }
    },
    CategoryEvent(value) {
        if (value == "Semua") {
            this.DisableCatVaksin = true
            this.selectedCatVaksin = null
        }
        else {
            this.DisableCatVaksin = false
        }
    },
    fetchDept() {
      console.log(this.selectedBU);
      this.initializeReport();
      this.selectedDept = 'ALL';
      this.getListDepartment(this.selectedBU);
    },
    replaceNullValue(obj) {
      const swapValue = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (!obj[key]) {
            obj[key] = "-";
          }
        });
      };
      return swapValue;
    },
    initExcelTitle() {
      this.generateTitleXLS({
        rptName: this.stActiveReport,
        rptVersion: this.rptVersion,
        processedDateTime: this.processedDateTime,
        processedBy: this.userData.username + " - " + this.userData.fullname,
      }).then((rsp) => {
        this.excel_name = rsp[0];
        this.excel_title = rsp[1];
      });
    },
    swapValue(obj) {
      for (let i = 0; i < obj.length; i++) {
        let element = obj[i];
        if (element.jenis_vaksin == null) {
          element.jenis_vaksin = "-";
        }
        if (element.sertifikat_vaksin == null) {
          element.sertifikat_vaksin = "-";
        }
        if (element.tgl_vaksin_1 == null) {
          element.tgl_vaksin_1 = "-";
        }
        if (element.tgl_vaksin_2 == null) {
          element.tgl_vaksin_2 = "-";
        }
        if (element.tgl_vaksin_3 == null) {
          element.tgl_vaksin_3 = "-";
        }
        if (element.business_unit == "BBI1") {
          element.business_unit = "BBI Pulogadung";
        }
        if (element.business_unit == "AOI1") {
          element.business_unit = "Apparel One Indonesia - 1";
        }
        if (element.business_unit == "AOI2") {
          element.business_unit = "Apparel One Indonesia - 2";
        }
        if (element.business_unit == "AOI3") {
          element.business_unit = "Apparel One Indonesia - 3";
        }
      }
    },

    populateReport() {
        if (this.selectedDept == null) {
            showToastOK({ type: "error", message: "Please select Department filter" });
            return false
        }
        if (this.selectedCat !== 'Semua') {
            if (this.selectedCatVaksin == null) {
                showToastOK({ type: "error", message: "Please select Vaccine filter" });
                return false
            }
        }
      this.resetReportSummary();
      this.isBusy = !this.isBusy;
      this.isLoadingData = true;
      fnPOST(APIPATH_HR.rptVaccineHistory2V2, {
        businessunit: this.selectedBU,
        dept: this.selectedDept,
        category: this.selectedCat,
        category_vaksin: this.selectedCatVaksin,
      }).then((rsp) => {
        this.isBusy = !this.isBusy;
        this.swapValue(rsp.data.payload);
        this.$set(this, "resultReport2", rsp.data.payload);

        console.log(this.resultReport2);

        this.getCurrentDatetime()
          .then((rsp) => {
            this.processedDateTime = rsp;
            showToastOK({ type: "success", message: "Report Loaded" });

            this.initExcelTitle();
          })
          .catch((ex) => {
            showToastOK({ type: "error", message: "Failed loading report" });
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      });
    },

    goToReport() {
      this.$router.push({ name: "ReportPage" });
    },
    resetReportSummary() {
      this.errorMessage = null;
      this.processedDateTime = "";
    },
  },
  created() {
    this.initializeReport();
    loginStore.dispatch("getListBU").then(() => {
      this.optionsBU = loginStore.getters.BUList;
      this.selectedBU = this.userData.business_unit;
      this.getListDepartment(this.selectedBU);
    });
    // fnGET(APIPATH_BBIAPPS.listBU).then((rsp) => {
    //   this.$set(this, "optionsBU", rsp.data.payload);
    //   this.optionsBU.unshift({
    //     code: "ALL",
    //     name: "ALL",
    //   });
    // });
    fnGET(APIPATH_BBIAPPS.categoryEmpVaksinV2).then((rsp) => {
      this.$set(this, "optionsCat", rsp.data);
    });
  },
};
</script>

<style src="./VaccineHistoryReport.scss" lang="scss" scoped />

<link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css"/>
