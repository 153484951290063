<template>
    <div>
        <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item >
            <router-link tag="a" to="/app/appsplm-product-design2"> {{formName}}</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-overlay :show="busy" rounded="sm" >
            <template #overlay>
                <div class="text-center">
                    <b-icon icon="arrow-clockwise" font-scale="4" variant="primary" animation="spin"></b-icon>
                    <p id="cancel-label">Please wait...</p>
                    
                </div>
            </template>
        <b-card 
            header-tag="nav" 
            no-body
            footer-tag="footer"
            >
            <b-tabs 
                active-nav-item-class="font-weight-bold"
                active-tab-class="font-weight-bold"
                v-model="tabIndex" 
                content-class="mt-3">
                <b-tab active title="Garment">
                    <FormInput :productMethods="isSubmit" v-bind="$props" />
                </b-tab>
                <b-tab title="Fabric">

                    <FormFabric/>
                </b-tab>
                <b-tab title="Accessories">
                    <b-form-radio-group
                        v-model="isSelectAcc"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectAcc === 'Yes'">
                        <FormAcc  />
                    </template>
                </b-tab>
                <b-tab title="Thread">
                    <b-form-radio-group
                        v-model="isSelectThread"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectThread === 'Yes'">
                    <FormThread />
                    </template>
                </b-tab>
                <b-tab title="Artwork">
                    <b-form-radio-group
                        v-model="isSelectArtwork"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectArtwork === 'Yes'">
                    <FormArtwork />
                    </template>
                </b-tab>
                <b-tab title="Interlining">
                    <b-form-radio-group
                        v-model="isSelectInterlining"
                        :options="processOpt"
                        class="mb-3"
                        value-field="item"
                        text-field="name"
                    ></b-form-radio-group>
                    <template v-if="isSelectInterlining === 'Yes'">
                    <FormInterlining />
                    </template>
                </b-tab>
                <!-- <template #tabs-end>
                    <b-nav-item href="#" role="presentation" v-on:click="showModal()"  >Submit</b-nav-item>
                </template> -->
            </b-tabs>
            <template #footer>
                <b-row>
                    <b-col  sm="12" md="4">
                        <div>
                            <b-button variant="warning" block>Cancel</b-button>
                        </div>
                    </b-col>
                    <b-col  sm="12" md="4">
                        <div>
                            <b-button variant="info" v-on:click="showModal('Draft')" block>Draft</b-button>
                        </div>
                    </b-col>
                    <b-col  sm="12" md="4">
                        <div>
                            <b-button variant="success" v-on:click="showModal('Waiting For Approval')" block>Submit</b-button>
                        </div>
                    </b-col>
                </b-row>
                
                
            </template>
            
        </b-card>

        <b-modal
            v-model="show"
            hide-backdrop
            v-on:ok="submitForm()"
            ok-title="Submit"
            size="xl"
            ref="modal"
            scrollable 
            title="Product Design Detail">
            <SubmitView/>
        </b-modal>
        
        </b-overlay>
    </div>
</template>

<script>
import {mapActions, mapGetters } from 'vuex';
import FormInput from './ProductDesignFormInput2';
import FormAcc from './ProductDesignFormAcc2';
import FormThread from './ProductDesignFormThread2';
import FormArtwork from './ProductDesignFormArtwork2';
import FormFabric from './ProductDesignFormFabric2';
import FormInterlining from './ProductDesignFormInterlining2';
import SubmitView from './ProductDesignSubmitView2';
import Vue from 'vue';
export default {
    components:{
        FormInput,
        FormAcc,
        FormThread,
        FormArtwork,
        FormFabric,
        FormInterlining,
        SubmitView,
    },
    name:'ProductDesignForm2',
    props:['modulCreate','productId'],
    data(){
        const now = new Date()
        
        return{
            
            cbAcc:'not_accepted',
            isSubmit:0,
            show: false,
            formName: 'Product Design2',
            modulName: null,
            tabIndex:0,
            mounted:false,
            form:{
                isProccess:null,
                status:null,
            },
            isSelectFabric:'Yes',
            isSelectAcc:'TBD',
            isSelectThread:'TBD',
            isSelectArtwork:'TBD',
            isSelectInterlining:'TBD',
            processOpt:[
                { item: 'TBD', name: 'TBD' },
                { item: 'Yes', name: 'Yes' },
                { item: 'No', name: 'No'},
            ],
            busy:false,

        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('productDesign',['POST_PRODUCT_DEV','POST_PRODUCT_IMAGES','POST_PRODUCT_MATERIAL','addProduct','postProductDev','postProductDevDetail','POST_PRODUCT_PROCESS','PUT_PRODUCT_DETAIL']),
        
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                // this.form.imagesAcc.push(file[i])
                // this.imagesUrl = URL.createObjectURL(file[i])
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
            console.log(this.imagesUrl)
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        submitForm(){
            Vue.set(this,'busy',true)
            let formProductDesign = {
                product_id:this.product.product_id,
                nama_karyawan:this.userData.fullname,
                nik:this.userData.nik,
                lokasi:this.userData.business_unit,
                department:this.userData.dept,
                status:this.form.status,
                date:this.product.date,
                duration:this.product.duration,
                brand:this.product.brand,
                collection_name:this.product.collectionName,
                year:this.product.year,
                quartal:this.product.quartal,
                prod_month:this.product.prodMonth,
                coll_month:this.product.collMonth,
                subbrand:this.product.subBrand,
                category:this.product.category,
                article:this.product.article,
                itensity:this.product.itensity,
                color:this.product.color,
                is_proccess:this.form.isProccess,

                // design:this.product.design,
                sample_id: this.product.sample_id,
                repeat_sample:this.product.repeatSample,
                ref_sample:this.product.noSample,
                old_sample_no:this.product.oldSample,
                guidance:this.product.guidance,
                size_spec:this.product.specSize,
                note:this.product.note,
                // technical_drawing:this.product.technical,
                // fabric:this.fabricList,
                // accessories:this.accList,
                // thread:this.threadList,
                // artwork:this.artworkList,
                // interlining:this.interliningList,
                brand_code:this.product.brand_code,
                year_code:this.product.year_code,
                month_code:this.product.month_code,
                subbrand_code:this.product.subbrand_code,
                category_code:this.product.category_code,
                color_code:this.product.color_code,
                intensity_code:this.product.intensity_code,
                fabric_process:this.isSelectFabric,
                accessories_process:this.isSelectAcc,
                thread_process:this.isSelectThread,
                artwork_process:this.isSelectArtwork,
                interlining_process:this.isSelectInterlining,
            }

            if(this.product.product_id){
                this.PUT_PRODUCT_DETAIL(formProductDesign)
                .then(response => {
                    Vue.set(this,'busy',false)
                    console.log('edit sukses');
                    this.goToList()
                })
                .catch(error =>{
                    Vue.set(this,'busy',false)
                    console.log('product dev',error);
                })
            }else{
                var fabricProcess = this.PRODUCT_MATERIAL.filter
                this.POST_PRODUCT_DEV(formProductDesign)
                    .then(response => {
                        console.log(response.data.payload.product_id);
                        let prodId = response.data.payload.product_id
                        this.saveProcess(prodId)
                        this.submitDetail(response.data.payload.product_id)
                        this.submitProductMaterial(response.data.payload.product_id)
                        
                        this.uploadTechnicalDrawing(prodId)
                        this.uploadMainLabel(prodId)
                        this.uploadStreamerLabel(prodId)
                        this.uploadSizeLabel(prodId)
                        this.uploadHangtag(prodId)
                        Vue.set(this,'busy',false)
                    })
                    .catch(error =>{
                        console.log('product dev',error);
                        Vue.set(this,'busy',false)
                    })

            }
            
        },
        submitProductMaterial(productID){
            let productMaterial ={
                product_id: productID,
                material:this.PRODUCT_MATERIAL
            }

            this.POST_PRODUCT_MATERIAL(productMaterial)
                .then(response => {
                    console.log('success submit product material');
                })
                .catch(error => {
                    console.log('material save',error);
                })



        },
        submitDetail(productID){
            console.log('design',this.product.design);
            const fd = new FormData()
            fd.append('product_id', productID)
            fd.append('design',this.product.design[0].images)
            fd.append('img_label',this.product.design[0].img_label)
            // fd.append('technical_drawing',this.product.technical[0].images)
            

            this.POST_PRODUCT_IMAGES(fd)
            .then(response =>{
                
                console.log('gambar berhasil disimpan');
                // this.showNotification({type:'success', message:'Successfully Submitted'})

                this.goToList()
            })
            .catch(err=>{
                if (err.response.status === 400){
                    return err.response.data.message
                }
            })
        },
        uploadTechnicalDrawing(productID){
            
            console.log(this.product.technical)
            this.product.technical.forEach(element => {
                let fd = new FormData()
                fd.append('product_id', productID)
                fd.append('design',element.images)
                fd.append('img_label',element.img_label)

            // for (let i = 0; i < this.product.technical.length; i++) {
                //     console.log(this.product.technical[i]);
                
            //     fd.append('product_id', productID)
            //     fd.append('design',this.product.technical[i].images)
            //     fd.append('img_label',this.product.technical[i].img_label)
            //     // fd.append('technical_drawing',this.product.technical[0].images)
                
            
            //         }   
                console.log('fd',fd);
                this.POST_PRODUCT_IMAGES(fd)
                .then(response =>{
                    
                    console.log('gambar berhasil disimpan');
                    // this.showNotification({type:'success', message:'Successfully Submitted'})

                    
                })
                .catch(err=>{
                    if (err.response.status === 400){
                        return err.response.data.message
                    }
                })
                        });
        },
        uploadMainLabel(productID){
            const fd = new FormData()
            fd.append('product_id', productID)
            fd.append('design',this.product.mainLabel[0].images)
            fd.append('img_label',this.product.mainLabel[0].img_label)
            // fd.append('technical_drawing',this.product.technical[0].images)
            

            this.POST_PRODUCT_IMAGES(fd)
            .then(response =>{
                
                console.log('gambar berhasil disimpan');
                this.showNotification({type:'success', message:'Successfully Submitted'})

                
            })
            .catch(err=>{
                if (err.response.status === 400){
                    return err.response.data.message
                }
            })
        },
        uploadStreamerLabel(productID){
            const fd = new FormData()
            fd.append('product_id', productID)
            fd.append('design',this.product.streamerLabel[0].images)
            fd.append('img_label',this.product.streamerLabel[0].img_label)
            // fd.append('technical_drawing',this.product.technical[0].images)
            

            this.POST_PRODUCT_IMAGES(fd)
            .then(response =>{
                
                console.log('gambar berhasil disimpan');
                // this.showNotification({type:'success', message:'Successfully Submitted'})

                
            })
            .catch(err=>{
                if (err.response.status === 400){
                    return err.response.data.message
                }
            })
        },
        uploadSizeLabel(productID){
            const fd = new FormData()
            fd.append('product_id', productID)
            fd.append('design',this.product.sizeLabel[0].images)
            fd.append('img_label',this.product.sizeLabel[0].img_label)
            // fd.append('technical_drawing',this.product.technical[0].images)
            

            this.POST_PRODUCT_IMAGES(fd)
            .then(response =>{
                
                console.log('gambar berhasil disimpan');
                
            })
            .catch(err=>{
                if (err.response.status === 400){
                    return err.response.data.message
                }
            })
        },
        uploadHangtag(productID){
            const fd = new FormData()
            fd.append('product_id', productID)
            fd.append('design',this.product.hangTag[0].images)
            fd.append('img_label',this.product.hangTag[0].img_label)
            // fd.append('technical_drawing',this.product.technical[0].images)
            

            this.POST_PRODUCT_IMAGES(fd)
            .then(response =>{
                
                console.log('gambar berhasil disimpan');
                
            })
            .catch(err=>{
                if (err.response.status === 400){
                    return err.response.data.message
                }
            })
        },
        goToList(){
            this.showNotification({type:'success', message:'Successfully'})
            this.$router.push({name:'AppsProductDesign2Page'})
        },
        showModal(value){
            console.log('product', this.product);
            this.isSubmit = !this.isSubmit
            this.show = true
            this.form.status = value
            
        },
        saveProcess(prodId){
            let now = new Date()
            // let estDate = new Date()
            // let startDate = now.toLocaleDateString('id-ID',{ year: 'numeric', month: '2-digit', day: '2-digit' })
            
            let year = now.toLocaleString('id-ID', { year: 'numeric'})
            
            let month = now.toLocaleString('id-ID', { month: '2-digit'})
            
            let day = now.toLocaleString('id-ID', { day: '2-digit'})
            let startDate = year+'-'+month+'-'+day

            let estDate = new Date()
            
            


            // let startDate = estDate.getDate()

            if(this.product.duration === 'basic'){
                estDate.setDate(estDate.getDate()+7)
            }else if(this.product.duration === 'regular'){
                estDate.setDate(estDate.getDate()+49)
            }else{
                console.log('else');
                estDate.setDate(estDate.getDate()+27)
            }

            let year2 = estDate.toLocaleString('id-ID', { year: 'numeric'})
            
            let month2 = estDate.toLocaleString('id-ID', { month: '2-digit'})
            
            let day2 = estDate.toLocaleString('id-ID', { day: '2-digit'})
            let estimateDate = year2+'-'+month2+'-'+day2

            console.log('today',estimateDate);
            console.log('start',startDate);
            this.POST_PRODUCT_PROCESS({
                product_id:prodId,
                process:'Product Submitted',
                task:this.userData.dept,
                start_date:startDate,
                estimate_date:estimateDate,
                status:'On Progress'
            }).then(rsp=>{
                console.log('Process Saved');
            }).catch(err=>{
                console.log(err);
            })
        }
        
        
    },
    mounted(){
        if(this.key_id != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        

    },
    computed:{
    ...mapGetters('productDesign',['product','fabricList','accList','threadList','artworkList','interliningList','PRODUCT_MATERIAL']),
    ...mapGetters('session', ['userData','localDate', 'serverDate']),
    
        
    }
}
</script>