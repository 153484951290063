import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiPostCustomerDistribution,
    fnApiEditCustomerDistribution,
}from '../../api/api-applications-salesforce.js'

Vue.use(Vuex)

const actions = {
    PostCustomerDepHead(context, formCustomerDistribution){
        return new Promise((resolve, reject) => {
            fnApiPostCustomerDistribution(formCustomerDistribution)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    EditCustomerDepHead(context, formCustomerDistribution){
        return new Promise((resolve, reject) => {
            fnApiEditCustomerDistribution(formCustomerDistribution)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
}

export default new Vuex.Store({
    namespaced: true,
    actions
})