<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ stActiveReport }}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold">{{ stActiveReport }}</span>
      <span class="fw-normal fs-larger"> {{ rptVersion }} </span>
    </h2>
    <b-row>
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <b-row class="form">
            <!-- datepicker From -->
            <b-col xs="12" md="2" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">From*</label>
                <b-form class="datepicker">
                  <VueDatePicker
                    v-model="selectedDate"
                    class="vd__div"
                    no-header
                    format="DD MMM YYYY"
                    min-date="1900-01-01"
                    :max-date="selectedDateTo"
                    placeholder="Choose report date start"
                  />
                </b-form>
              </div>
            </b-col>
            <!-- end datepicker From -->

            <!-- datepicker To -->
            <b-col xs="12" md="2" class="form-col">
              <div class="form-div">
                <label for="example-datepicker">To*</label>
                <VueDatePicker
                  class="vd__div"
                  v-model="selectedDateTo"
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  :max-date="max_date"
                  placeholder="Choose report date start"
                />
              </div>
            </b-col>
            <!-- end datepicker To -->

             <!-- Filter Business Unit -->
            <b-col xs="12" md="2" class="form-col-last">
              <div class="form-div">
                <label>Business Unit*</label>
                <v-select
                  label="code"
                  :reduce="(data) => data.code"
                  :options="this.optionsBu"
                  v-model="selectedBu"
                  class="form-custom custom-select"
                  ref="opt_dept"
                ></v-select>
              </div>
            </b-col>
            <!-- end Filter Business Unit -->

            <!-- Filter Dept Code -->
            <b-col xs="12" md="2" class="form-col-last">
              <div class="form-div">
                <label>Department*</label>
                <v-select
                  label="dept_code"
                  :reduce="(data) => data.dept_code"
                  :options="this.optionsDept"
                  v-model="selectedDept"
                  class="form-custom custom-select"
                  ref="opt_dept"
                ></v-select>
              </div>
            </b-col>
            <!-- end Filter Dept Code -->

            <!-- Filter Alt Dept Code -->
            <b-col xs="12" md="2" class="form-col-last">
              <div class="form-div">
                <label>Alt Department*</label>
                <v-select
                  label="dept_code"
                  :reduce="(data) => data.dept_code"
                  :options="this.optionsAltDept"
                  v-model="selectedAltDept"
                  class="form-custom custom-select"
                  ref="opt_dept"
                ></v-select>
              </div>
            </b-col>
            <!-- end Filter Alt Dept Code -->

            <!-- Filter NIK -->
            <b-col xs="12" md="2" class="form-col-last">
              <div class="form-div">
                <label>Employee Id**</label>
                <input
                  class="form-control mb-3 bg-transparent"
                  type="text"
                  name="userid"
                  id="userid"
                  v-model="inputUserId"
                />
              </div>
            </b-col>
            <!-- end Filter NIK -->
          </b-row>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button
                  class="btn-spacing"
                  type="button"
                  variant="primary"
                  :disabled="isLoadingData"
                  v-on:click="populateReport()"
                  >Load Report</b-button
                >
                <download-excel
                  :class="exportButtonClass()"
                  :data="rptDatav4"
                  :fields="excel_fields"
                  :title="excel_title"
                  :name="excel_name"
                >
                  Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button
                  class="btn-spacing-left"
                  type="button"
                  variant="secondary"
                  v-on:click="goToReport()"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </div>
          <div>
            <b-row>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Processed at</b-col>
                  <b-col xs="7" md="8" lg="10">: {{ processedDateTime }}</b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col xs="5" md="4" lg="2">Total Data</b-col>
                  <b-col xs="7" md="8" lg="10">: {{ rptDataCountv4 }}</b-col>
                </b-row>
              </b-col>
              <b-col sm="12">
                <b-row>
                  <b-col xs="4" md="3" lg="2">Resiko</b-col>
                  <b-col xs="8" md="9" lg="10"
                    >: <span>Tinggi {{ sumHighRisk }} </span> |
                    <span>Rendah {{ sumMedRisk }} </span>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col xs="12">
        <div class="table-responsive">
          <table class="table table-lg mb-0">
            <thead>
              <tr style="text-align: center">
                <th>TANGGAL SIMPAN</th>
                <th>TANGGAL</th>
                <th>NAMA</th>
                <th>NIK</th>
                <th>DEPARTMENT</th>
                <th>KONDISI KESEHATAN</th>
                <th>PROFIL RESIKO</th>
                <th>BEPERGIAN</th>
                <th>TEMPAT KERJA</th>
                <th>LOKASI KERJA</th>
                <th>KELUAR KOTA</th>
                <th>TUJUAN KELUAR KOTA</th>
                <th>KELUAR NEGERI</th>
                <th>TUJUAN KELUAR NEGERI</th>
                <th>FASILITAS UMUM</th>
                <th>NAMA FASILITAS UMUM</th>
                <th>RIWAYAT KONTAK 5 HARI</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="text-align: center"
                v-for="(row, index) in stRptDatav4"
                :key="row.id"
                :class="setBackground(index)"
              >
                <!-- <td>{{convertToReadableDateTime(row.tanggal_simpan)}}</td> -->
                <td
                  :class="
                    chkDateNotMatch(
                      row.tanggal_simpan_char,
                      row.tanggal_harian_char
                    )
                  "
                >
                  {{ row.tanggal_simpan_char ? row.tanggal_simpan_char : '-'  }}
                </td>
                <td>{{ row.tanggal_harian_char ? row.tanggal_harian_char : '-' }}</td>
                <td>{{ row.nama_karyawan ? row.nama_karyawan : '-' }}</td>
                <td>{{ row.nik ? row.nik : '-' }}</td>
                <td>{{ row.department ? row.department : '-' }}</td>
                <td
                  :class="
                    chkSickCounter(row.count_lama_sakit, row.kondisi_kesehatan)
                  "
                >
                  {{ row.kondisi_kesehatan ? row.kondisi_kesehatan : '-' }}
                </td>
                <!-- <td :class="chkNilaiResiko(row.profil_resiko)">
                  {{ row.nilai_resiko }}
                </td> -->
                <td :class="chkProfilResiko(row.profil_resiko)">
                  {{ row.profil_resiko ? row.profil_resiko : '-' }}
                </td>
  
                <td>{{ row.bepergian ? row.bepergian : '-' }}</td>
                <!-- <td>{{row.tempat_tujuan}}</td> -->
                <td>{{ row.tempat_kerja ? row.tempat_kerja : "-" }}</td>
                <td>{{ row.lokasi_kerja ? row.lokasi_kerja : '-' }}</td>
                <td>{{ row.keluar_kota ? row.keluar_kota : '-' }}</td>
                <td>{{ row.tujuan_keluar_kota ?  row.tujuan_keluar_kota : '-' }}</td>
                <td>{{ row.keluar_negeri ? row.keluar_negeri : '-' }}</td>
                <td>{{ row.tujuan_keluar_negeri ? row.tujuan_keluar_negeri : '-'}}</td>
                <td>{{ row.fasilitas_umum ? row.fasilitas_umum : '-' }}</td>
                <td>{{ row.nama_fasilitas_umum ? row.nama_fasilitas_umum : '-'}}</td>
                <td>{{ row.riwayat_kontak_covid ? row.riwayat_kontak_covid : '-'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <Loader v-if="isLoadingData" fullPage useOverlay></Loader>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import vSelect from "vue-select";
import loginStore from "@/store/login.js";
import "vue-select/dist/vue-select.css";

import Loader from "@/components/Loader/Loader";
import { setFocus, showToastOK } from "@/core/pluginHelper";
import { fnApiFetchListDepartment, fnApiFetchListAltDepartment } from "@/api/api-reports.js";

Vue.component("download-excel", JsonExcel);

export default {
  name: "DailyHealthReportV4",
  components: {
    vSelect,
    Loader,
  },
  data() {
    const now = new Date();
    const today = now.toISOString().slice(0, 10);
    return {
      rptVersion: "v4.1",
      max_date: today,
      processedDateTime: "",
      selectedDate: null,
      selectedDateTo: null,
      selectedDept: null,
      selectedBu: null,
      selectedAltDept:null,
      inputUserId: "",
      errorMessage: null,
      isLoadingData: false,
      countDateNotMatch: 0,
      countHighRisk: 0,
      countMedRisk: 0,
      countLowRisk: 0,
      excel_title: "",
      excel_name: "",
      excel_fields: {
        "TANGGAL SIMPAN": "tanggal_simpan_char",
        TANGGAL: "tanggal_harian",
        NAMA: "nama_karyawan",
        NIK: "nik",
        DEPARTMENT: "department",
        "KONDISI KESEHATAN": "kondisi_kesehatan",
        "PROFIL RESIKO": "profil_resiko",
        BEPERGIAN: "bepergian",
        "TEMPAT KERJA": "tempat_kerja",
        "LOKASI KERJA": "lokasi_kerja",
        "KELUAR KOTA": "keluar_kota",
        "TUJUAN KELUAR KOTA": "tujuan_keluar_kota",
        "KELUAR NEGERI": "keluar_negeri",
        "TUJUAN KELUAR NEGERI": "tujuan_keluar_negeri",
        "RIWAYAT KONTAK 5 HARI": "riwayat_kontak_covid",
        "FASILITAS UMUM":"fasilitas_umum",
        "NAMA FASILITAS UMUM": "nama_fasilitas_umum"
      },
      optionsDept: [],
      optionsAltDept: [],
      optionsBu:[],
      dataRpt:[]
    };
  },
  computed: {
    ...mapState("report", [
      "stDeptList",
      "stRptDatav4",
      "stRptDataCountv4",
      "stActiveReport",
    ]),
    ...mapGetters("report", ["rptDataCountv4", "rptDatav4", "hasDatav4"]),
    ...mapGetters("session", ["userData", "localDate"]),
    sumHighRisk() {
      let percentage = 0;
      if (this.countHighRisk > 0) {
        percentage =
          (parseFloat(this.countHighRisk) * 100.0) / this.rptDataCountv4;
      }
      return this.countHighRisk + " ( " + percentage.toFixed(2) + "%)";
    },
    sumMedRisk() {
      let percentage = 0;
      if (this.countMedRisk > 0) {
        percentage =
          (parseFloat(this.countMedRisk) * 100.0) / this.rptDataCountv4;
      }
      return this.countMedRisk + " ( " + percentage.toFixed(2) + "%)";
    },
    sumLowRisk() {
      let percentage = 0;
      if (this.countLowRisk > 0) {
        percentage =
          (parseFloat(this.countLowRisk) * 100.0) / this.rptDataCountv4;
      }
      return this.countLowRisk + " ( " + percentage.toFixed(2) + "%)";
    },
    sumDateNotMatch() {
      let percentage = 0;
      if (this.countDateNotMatch > 0) {
        percentage =
          (parseFloat(this.countDateNotMatch) * 100.0) / this.rptDataCountv4;
      }
      return this.countDateNotMatch + " ( " + percentage.toFixed(2) + "%)";
    },
  },
  methods: {
    ...mapActions("report", [
      "initializeReport",
      "getListDepartment",
      "getDataDailyHealthV2",
      "getDataDailyHealthV4",
      "getCurrentDatetime",
      "generateTitleXLS",
    ]),
    populateReport() {
      this.resetReportSummary();
      if (this.selectedDate === null || this.selectedBu === null || this.selectedDept === null || this.selectedAltDept === null) {
        this.errorMessage = "Please select Date and Department filter";
        setFocus(this.$refs.opt_dept);
      } else {
        this.isLoadingData = true;
        if (this.inputUserId == null || this.inputUserId == "") {
          this.inputUserId = "ALL";
        }
        this.getDataDailyHealthV4({
          nik: this.inputUserId,
          date: this.selectedDate,
          dateto: this.selectedDateTo,
          dept: this.selectedDept,
          altdept: this.selectedAltDept,
          //businessunit: this.userData.business_unit,
          businessunit: this.selectedBu,
        })
          .then((rsp) => {
            this.postProcessReport(rsp);
            // console.log('data rptv4', this.stRptDatav4);
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              showToastOK({
                type: "error",
                message: "Failed loading report",
              });
            }
          })
          .finally(() => {
            this.isLoadingData = false;
          });
      }
    },
    postProcessReport(apiArg) {
      let cntDateNotMatch =
        document.getElementsByClassName("bg-datenotmatch").length;
      let cntHighRisk = document.getElementsByClassName(
        "bg-riskprofile-high"
      ).length;
      let cntMediumRisk =
        document.getElementsByClassName("bg-riskprofile-med").length;
      let cntLowRisk =
        document.getElementsByClassName("bg-riskprofile-low").length;
      this.countDateNotMatch = cntDateNotMatch;
      this.countHighRisk = cntHighRisk;
      this.countMedRisk = cntMediumRisk;
      this.countLowRisk = cntLowRisk;

      console.log('data resiko', this.countHighRisk)

      // Deploy toast dialog
      this.getCurrentDatetime()
        .then((rsp) => {
          this.processedDateTime = rsp;
          showToastOK({ type: "success", message: "Report Loaded" });

          this.initExcelTitle();
        })
        .catch((ex) => {
          showToastOK({ type: "error", message: "Failed loading report" });
        });
    },
    goToReport() {
      this.$router.push({ name: "ReportPage" });
    },
    chkSickCounter(param, chkCovid) {
      if (chkCovid.match("batuk, radang, pilek, demam, pegal, hilang, rasa/bau")) {
        return "bg-sick-red";
      } else if (param > 0 && param < 2) {
        return "bg-sick-yellow";
      }
      return "";
    },
    chkProfilResiko(val) {
      if (val.toLowerCase().trim() == "resiko tinggi") {
        return "bg-riskprofile-high bg-sick-red";
      } else if (val.toLowerCase().trim() == "resiko rendah") {
        return "bg-riskprofile-med bg-sick-yellow";
      } else if (val.toLowerCase().trim() == "tidak beresiko") {
        return "bg-riskprofile-low";
      }
      return "";
    },
    chkNilaiResiko(val) {
      if (val.toLowerCase().trim() == "resiko tinggi") {
        return "bg-sick-red";
      } else if (val.toLowerCase().trim() == "resiko sedang") {
        return "bg-sick-yellow";
      }
      return "";
    },
    chkDateNotMatch(param1, param2) {
      let varSubmitDate = param1.toLowerCase().trim().slice(0, 16);
      let varFormDate = param2.toLowerCase().trim().slice(0, 16);
      if (varSubmitDate !== varFormDate) {
        return "bg-datenotmatch";
      }

      return "";
    },
    resetReportSummary() {
      this.errorMessage = null;
      this.processedDateTime = "";
    },
    exportButtonClass() {
      let classNames = "btn btn-info";
      if (!this.hasDatav4) {
        classNames += " hide";
      }
      if (this.isLoadingData) {
        classNames += " btn-disabled";
      }
      return classNames;
    },
    initExcelTitle() {
      this.generateTitleXLS({
        rptName: this.stActiveReport,
        rptVersion: this.rptVersion,
        processedDateTime: this.processedDateTime,
        processedBy: this.userData.username + " - " + this.userData.fullname,
      }).then((rsp) => {
        this.excel_name = rsp[0];
        this.excel_title = rsp[1];
      });
    },
    setBackground(idx) {
      if (parseInt(idx) % 2 == 0) {
        return "bg-greyed";
      }
      return "";
    },
    getListBU(){
      loginStore.dispatch("getListBU").then(() => {
      this.optionsBu = loginStore.getters.BUList;
      let data = {
        code: "ALL",
        name: "all"
      }
      this.optionsBu.push(data);
      this.optionsBu.reverse();
      // console.log('bu', this.optionsBu)
    });
    }
  },
  created() {
    this.initializeReport();
    // this.getListDepartment(this.userData.business_unit)
    fnApiFetchListDepartment(this.userData.business_unit).then((rsp) => {
      this.optionsDept = rsp.data.payload;
    //   console.log('dept', this.optionsDept)
    });

    fnApiFetchListAltDepartment().then((rsp) => {
      this.optionsAltDept = rsp.data.payload
    });

    this.selectedDate = this.localDate;
    this.selectedDateTo = this.localDate;
    this.getListBU();
  },
};
</script>


<style src="./DailyHealthReport.scss" lang="scss" scoped />

<link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css"/>
