<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <h2 class="page-title"> <span style="font-size:16px;" class="fw-semi-bold fontbbi ">Dashboard PPM</span></h2>
    </b-breadcrumb>
   <b-row>
      <b-col sm="12">
        <b-row>
          <b-col sm="auto">
            <b-form-group
            label="Filter: "
            label-for="filter"
            label-cols-sm="4"
            label-align-sm="left">
              <b-form-select id="filter" v-model="filter" :options="optionsFilter" ></b-form-select>
            </b-form-group>
          </b-col>

          <b-col sm="auto">
            <b-form-group
            label="Search: "
            label-for="filter"
            label-cols-sm="4"
            label-align-sm="left">
              <b-form-input style="height: calc(1.5em + 0.75rem + 2px); margin-left:-20px"
                placeholder="Enter your search keyword"
                v-model="search">
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col sm="auto">
              <b-button variant="primary"
                style="height: calc(1.5em + 0.75rem + 2px); margin-left:-40px"
                @click="searchFilter">
              Search
              </b-button>
          </b-col>
        </b-row>
          <div class="table-responsive">
          <table class="table table-lg mb-0">
              <thead>
                <tr style="text-align:center; border-top:1px solid #CCC;" class="text-muted">
                    <th style="border:1px solid #CCC; font-weight: 700;">IO</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Sub Con</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Due Date</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Article</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Brand</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Sample</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Status</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Total Plan</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Total Actual</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Qty Plan</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Qty Final</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">PIC IO</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Cut</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Sew</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Finish</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Pack</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Embro</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Print</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Emblem</th>
                    <th style="border:1px solid #CCC; font-weight: 700;">Wash</th>
                </tr>
              </thead>
              <tbody>
                <tr
                v-for="row in stAllPPMV"
                :key="row.id"
                style="text-align:center;"
                >
                  <td style="border-right:1px solid #CCC; border-left:1px solid #CCC; border-bottom:1px solid #CCC;" v-if ="row.status !== 'Ongoing(CloseIO)'" :class="chkDateOnSchedule(row.due_date, today, row.status , row.end_date_receive)">
                    <span @click="IOPPCM(row.io,row.status)" style="color:#5497f3; text-decoration: underline; cursor: pointer;" class="fw-semi-bold fontbbi">{{row.io}}</span>

                    <!-- <router-link v-if="row.status == 'ONGOING(CloseIO)' || row.status == 'COMPLETED'" :to="{name:'CloseIO', params:{data_io: row.io}}">{{row.io}}</router-link>
                    <router-link v-else :to="{name:'PPCMInputPPM', params:{data_io: row.io}}">{{row.io}}</router-link> -->
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span v-if="row.cutting_subcon !== null" class="fw-semi-bold fontbbi">{{row.cutting_subcon}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; min-width:90px; border-bottom:1px solid #CCC;">
                    <span v-if="row.due_date !== null" v-html="FormatDate(row.due_date)" class="fw-semi-bold fontbbi">{{row.due_date}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.artikel}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.brand}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.sample}}</span>
                  </td>
                  <td :class="chkStatus(row.status)" style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi" v-html="statusHidden(row.status)"></span>
                  </td>
                  <!-- TOTAL PLAN DAYS -->
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi" v-if="row.total_qty !== null && row.cutting_target !== null" v-html="TotalPlanDays(row.total_qty,row.cutting_target,row.sewing_target,row.finishing_target,row.packing_target,row.printing_target,row.embroidery_target,row.emblem_target,row.washing_target,row.delivery_days)" ></span>
                  </td>
                  <!-- TOTAL ACTUAL DAYS -->
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                      <span class="fw-semi-bold fontbbi" v-if="row.cutting_date !== null">{{row.total_actual_days}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi">{{row.total_qty}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi" v-if="row.packing_progress !== null">{{row.packing_progress}} ({{((row.packing_progress / row.total_qty) * 100).toFixed(1)}}%)</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <img v-if="row.image_io !== null" :src="url_data+'media/ppm/image/'+row.image_io">
                  </td>
                  <!-- Calc Percentage Progress -->
                  {{TotalQtyPercent(row.id,
                    row.cutting_progress, row.sewing_progress, row.finishing_progress,
                    row.packing_progress, row.printing_progress, row.embroidery_progress,
                    row.emblem_progress, row.washing_progress,

                    row.total_qty,
                    row.order_process)}}
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.cutting_progress !== null">{{cutting_percent[row.id]}}%</span>
                  </td>
                   <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.sewing_progress !== null">{{sewing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.finishing_progress !== null">{{finishing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.packing_progress !== null">{{packing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.embroidery_progress !== null">{{embroidery_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.printing_progress !== null">{{printing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.emblem_progress !== null">{{emblem_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.washing_progress !== null">{{washing_percent[row.id]}}%</span>
                  </td>

                </tr>
              </tbody>
            </table>
            <br />
            <p>
  <button @click="prevPage" :disabled="cantGoBack">Previous</button> <span style="padding-left: 5px;"></span>
  <span style="margin-left:10px; padding-right:10px;"> Page : {{currentPage}} </span>
  <button @click="nextPage" :disabled="cantNext" >Next</button>
  </p>
          </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex';
import Widget from '@/components/Widget/Widget';
// import vue loading
import Loading from 'vue-loading-overlay';
// Import vue loading stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
// Init plugin
Vue.use(Loading);

export default {
        name: 'DashboardPPM',
        components: { Widget },

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                errorMessage: null,
                today:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                currentSort:'status',
                currentSortDir:'desc',
                oneDay:oneDay,
                pageSize:10,
                totalData:0,
                currentPage:1,
                filter: 'all',
                search: null,
                actual_days:[],
                actual_cutting:[],
                actual_sewing:[],
                actual_finishing:[],
                actual_packing:[],
                actual_printing:[],
                actual_embroidery:[],
                actual_emblem:[],
                actual_washing:[],
                totalQty:[],
                progress_cutting:[],
                progress_sewing:[],
                progress_finishing:[],
                progress_packing:[],
                progress_printing:[],
                progress_embroidery:[],
                progress_emblem:[],
                progress_washing:[],
                order_process:[],
                cutting_percent:[],
                sewing_percent:[],
                finishing_percent:[],
                packing_percent:[],
                printing_percent:[],
                embroidery_percent:[],
                emblem_percent:[],
                washing_percent:[],
                sewing_total:[],
                finishing_total:[],
                packing_total:[],
                printing_total:[],
                embroidery_total:[],
                emblem_total:[],
                washing_total:[],
                url_data:'',
                optionsFilter: [
                  { value: 'all', text: 'All' },
                  { value: 'io', text: 'IO' },
                  { value: 'brand', text: 'Brand' },
                  { value: 'artikel', text: 'Artikel' },
                  { value: 'status', text: 'Status' },
                  { value: 'subcon', text: 'Subcon' },
                ],
                form : {
                    fullname:null,
                    role:null,
                    roles:[],
                    filter:'all',
                    search:null,
                    start_limit:0,
                    end_limit:10,
                },

            }
        },
  computed: {
    ...mapState('application', ['stAppAccess']),
    ...mapGetters('session', ['userData']),
    ...mapState('ppm', ['stAllPPMV','stAllPPMCount','stAllDataPPMCount']),
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantNext() {
      if (this.totalData == 0) {
        return this.currentPage === 1;
      }
        return this.currentPage === Math.ceil(this.totalData / this.pageSize)
    },
  },
  methods: {
    ...mapActions('session', ['showNotification']),
    ...mapActions('application', ['clearState', 'getApplicationAccess']),
    ...mapActions('ppm', ['clearStatePpm','getALLIOPPMV2','getALLIOPPM','getALLDataIOPPM']),
    ...mapActions('master_calender', ['GetDateCalculation','getSubconCalender']),
    statusHidden(value) {
      let conOng = value.includes("ONGOING")
      let conPen = value.includes("Approval")
      let status = value
      if (conOng) {
        status = ((value.substring(8,20)).split(")")[0]);
      }
      if (conPen) {
        status = ((value.substring(8,20)).split(")")[0]);
      }
      return status

    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
          return ' '
      } else {
      let date = new Date(value)
      let tgl = date.toISOString().slice(8,10)
      let bulan = this.monthNames[date.getMonth()]
      let tahun = date.toISOString().slice(0,4)
      date = tgl+' '+bulan+' '+tahun
      return date
      }
    },
    Loading(value) {
        if (value == 'start') {
        this.loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            color: '#f89830',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
            });
        }
        if (value == 'stop') {
          // Set delay 1 second
          setTimeout(() => {
            this.loader.hide()
          }, 500);
        }
    },
    getAppRoles(appUserData) {
      // console.log(appUserData)
      // get roles
      let list_roles = []
        let arr_len = appUserData.roles[0].role_list.length
        for (let ary=0; ary<=(arr_len-1); ary++) {
          // check if data role_list not null and lowerCase
          let roles = appUserData.roles[0].role_list[ary]
          if (appUserData.roles[0].role_list[ary] !== null || appUserData.roles[0].role_list[ary] !== undefined) {
            roles = appUserData.roles[0].role_list[ary].toLowerCase();
          }
          list_roles = list_roles.concat(roles)
        }
        // condition if there 2 roles
        if (appUserData.roles.length > 1) {
          // reset roles
          list_roles = [];
          let len = appUserData.roles.length
          for (let arr=0; arr<=(len-1); arr++) {
            let arr_len = appUserData.roles[arr].role_list.length
            for (let ary=0; ary<=(arr_len-1); ary++) {
              let roles = appUserData.roles[arr].role_list[ary]
              if (appUserData.roles[arr].role_list[ary] !== null || appUserData.roles[arr].role_list[ary] !== undefined) {
                roles = appUserData.roles[arr].role_list[ary].toLowerCase();
              }
              list_roles = list_roles.concat(roles)
            }
          }
        }
      return list_roles;
    },
    Setdata_IO() {
        let userData     = this.userData
        this.form.roles = this.getAppRoles(this.userData)
        let role = ''
        // // console.log(this.form.roles)
        this.$set(this.form, 'fullname', userData.fullname)
        if (userData.roles) {
          for (let a=0; a<userData.roles.length; a++) {
            if (userData.roles[a].app == 'PPCM') {
              role = userData.roles[a].role_list
              this.form.role = userData.roles[a].role_list
            }
          }
        }
        this.url_data = process.env.VUE_APP_BASE_URL
        this.Loading('start')
        this.getALLIOPPMV2({
        role:this.form.role,
        filter:'all',
        search:null,
        start_limit:0,
        end_limit:10,
      })
          .then(rsp => {
          this.getALLIOPPM().then(rsp => {
            this.totalData = rsp
            if(this.stAllPPMCount == 0) {
              this.showNotification({type:'info', message:'Data Not Found'})
            }
          })
          .catch(err => {
            if(err.response.status !== 401) {
            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
            }
        })
      }).catch(err => {
          this.Loading('stop')
          if(err.response.status !== 401) {
          this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
          }
      })
      .finally(()=>{
        this.Loading('stop')
      })
       if (this.form.role.includes('subcon')) {
        this.$router.push({name:"ApplicationPage"})
        this.showNotification({type:'error', message:"you don't have authorization to view this page"})
       }


    },
    calcProcessPercent(index, process, progress) {
        if (process == 'b') {
          this.sewing_total[index] = progress
        }
        if (process == 'c') {
          this.finishing_total[index] = progress
        }
        if (process == 'd') {
          this.packing_total[index] = progress
        }
        if (process == 'e') {
          this.printing_total[index] = progress
        }
        if (process == 'f') {
          this.embroidery_total[index] = progress
        }
        if (process == 'g') {
          this.emblem_total[index] = progress
        }
        if (process == 'h') {
          this.washing_total[index] = progress
        }
      },
      TotalQtyPercent(index, cut,sew,fin,pack,print,embro,emblem,wash, total, order_process){
      if (order_process == null || order_process == '' || order_process == undefined) {
        // nothing
      } else {
        let len = Math.abs(order_process.length / 2)
        let param = order_process
        let d = 0
        this.totalQty[index] = total
        for (let a=0;a<=len;a++) {
          if (param[d] == 'a') {
            this.calcProcessPercent(index, param[d+2], cut)
            this.progress_cutting[index] = cut
            this.cutting_percent[index] = (((this.progress_cutting[index] / this.totalQty[index]) * 100).toFixed(1))
          }
          if (param[d] == 'b') {
            this.calcProcessPercent(index, param[d+2], sew)
            this.progress_sewing[index] = sew
            this.sewing_percent[index] = (((this.progress_sewing[index] / this.sewing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'c') {
            this.calcProcessPercent(index, param[d+2], fin)
            this.progress_finishing[index] = fin
            this.finishing_percent[index] = (((this.progress_finishing[index] / this.finishing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'd') {
            this.progress_packing[index] = pack
            this.packing_percent[index] = (((this.progress_packing[index] / this.packing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'e') {
            this.calcProcessPercent(index, param[d+2], print)
            this.progress_printing[index] = print
            this.printing_percent[index] = (((this.progress_printing[index] / this.printing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'f') {
            this.calcProcessPercent(index, param[d+2], embro)
            this.progress_embroidery[index] = embro
            this.embroidery_percent[index] = (((this.progress_embroidery[index] / this.embroidery_total[index]) * 100).toFixed(1))
            // console.log('this.progress_embroidery[index]:  '+this.progress_embroidery[index])
            // console.log('this.embroidery_total[index]:  '+this.embroidery_total[index])
            // console.log('this.embroidery_percent[index]:  '+this.embroidery_percent[index])
          }
          if (param[d] == 'g') {
            this.calcProcessPercent(index, param[d+2], emblem)
            this.progress_emblem[index] = emblem
            this.emblem_percent[index] = (((this.progress_emblem[index] / this.emblem_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'h') {
            this.calcProcessPercent(index, param[d+2], wash)
            this.progress_washing[index] = wash
            this.washing_percent[index] = (((this.progress_washing[index] / this.washing_total[index]) * 100).toFixed(1))
          }
          d = d + 2
        }
      }

    },
    CalcTotalPlan(totalQty,target) {
      let calc = (((totalQty/target) - Math.floor(totalQty/target))*10)
      if (calc >= 3) {
        calc = Math.ceil(totalQty/target)
      } else {
        calc = Math.floor(totalQty/target)
        if (calc == 0) {
          calc = 1
        }
      }
      return calc
    },
    TotalPlanDays(totalqty,cutting,sewing,finishing,packing,printing,embroidery,emblem,washing,delivery) {
      let Plancutting= 0
      let Plansewing = 0
      let Planfinishing = 0
      let Planpacking = 0
      let Planprinting = 0
      let Planembroidery = 0
      let Planemblem = 0
      let Planwashing = 0
      let PlanQc_Final = 2
      let PlanDelivery = 0

      if (cutting !== null) {
        Plancutting = this.CalcTotalPlan(totalqty,cutting)
      }
      if (sewing !== null) {
        Plansewing = this.CalcTotalPlan(totalqty,sewing)
      }
      if (finishing !== null) {
        Planfinishing = this.CalcTotalPlan(totalqty,finishing)
      }
      if (packing !== null) {
        Planpacking = this.CalcTotalPlan(totalqty,packing)
      }
      if (printing !== null) {
        Planprinting = this.CalcTotalPlan(totalqty,printing)
      }
      if (embroidery !== null) {
        Planembroidery = this.CalcTotalPlan(totalqty,embroidery)
      }
      if (emblem !== null) {
        Planemblem = this.CalcTotalPlan(totalqty,emblem)
      }
      if (washing !== null) {
        Planwashing = this.CalcTotalPlan(totalqty,washing)
      }
      if (delivery !== null) {
        PlanDelivery = delivery
      }
      let TotalPlan = Plancutting + Plansewing + Planfinishing + Planpacking + Planprinting + Planembroidery + Planemblem + Planwashing + PlanQc_Final + PlanDelivery
      return TotalPlan
    },
    chkDateOnSchedule(param1, param2, param3, param4){
        let duedate   = (param1);
        let today     = (param2);
        let status    = param3.toLowerCase();
        let endDate    = (param4);
        // console.log(duedate);
        // console.log(today);
        // console.log(status);

        if (status == 'new' || status == 'new(pending)' || status == 'pending(approval)') {
          return 'new'
        }
        if (endDate == null || endDate == '' || endDate == undefined) {
          if (duedate < today) {
            return 'red'
          }
          if (duedate >= today) {
            return 'green'
          }
        } else {
          if (duedate < endDate) {
            return 'red'
          }
          if (duedate >= endDate) {
            return 'green'
          }
        }

      },
    chkStatus(param1){
        let status   = param1;
        if (status == 'New(Reject)') {
          return 'status_red span'
        }
    },
    searchFilter() {
      console.log('search: '+this.search)
      if (this.filter !== 'all') {
        if (this.search == null || this.search == '') {
          this.showNotification({type:'error', message:"Field Search Tidak Boleh Kosong"})
          return
        }
        if (this.search.substring(0, 1) == ' ' ) {
          this.showNotification({type:'error', message:"Field Search Terinput Belum Benar"})
          return
        }
      } else {
        this.search = null;
      }
      this.currentPage = 1;
      this.form.filter = this.filter
      this.form.search = this.search
      this.form.start_limit = 0
      this.form.end_limit = 10
      this.Loading('start')
      this.getALLDataIOPPM({
        filter:this.form.filter,
        search:this.form.search,
      }).then(rsp => {
        this.totalData = rsp
      })
      this.getALLIOPPMV2({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        end_limit:this.form.end_limit,
      })
      .finally(()=>{
        this.Loading('stop')
      })



    },
    nextPage:function() {
      this.clearStatePpm()
      this.form.start_limit = this.form.start_limit+10
      this.form.end_limit = this.form.end_limit+10
      this.getALLIOPPMV2({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        end_limit:this.form.end_limit,
      })

        this.currentPage++;

    },
    prevPage:function() {
      this.clearStatePpm()
      this.form.start_limit = this.form.start_limit-10
      this.form.end_limit = this.form.end_limit-10
      this.getALLIOPPMV2({
        role:this.form.role,
        filter:this.form.filter,
        search:this.form.search,
        start_limit:this.form.start_limit,
        end_limit:this.form.end_limit,
      })

        this.currentPage--;

    },
    IOPPCM(data,status) {
      // if (status == 'ONGOING(CloseIO)' || status == 'COMPLETED') {
      //   this.$router.push({ name: 'CloseIO', params: { data_io: data } })
      // } else {
      //   this.$router.push({ name: 'PPCMInputPPM', params: { data_io: data } })
      // }
      let routeData
      if (status == 'ONGOING(CloseIO)' || status == 'COMPLETED') {
        routeData = this.$router.resolve({ name: 'CloseIO', query: { io: data }})
      } else {
        routeData = this.$router.resolve({ name: 'PPCMInputPPM', query: { io: data } })
      }
      // console.log(routeData.href)
      // window.open(routeData.href, '_blank')
      window.open(routeData.href, '_self')
    },

  },
  mounted() {
    this.Setdata_IO()
    this.clearStatePpm()
  },
  created() {
  }
};
</script>
<style src="./DashboardPPCM.scss" lang="scss" scoped />
