<template>
    <div class="wrapper">
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item >
               <router-link tag="a" to="/app/appsplm-approve-design-list"> {{formName}}</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid="sm">
            <b-card
                header="Approval Design Form"
                
            >
                <b-row>
                    <b-col sm="auto" md="auto">
                        <!-- card group design, main fabric, label -->
                        <b-card-group class="mb-2 h-50">
                                <template v-for="img in productImg" >
                                <b-card :sub-title="img.img_label" :key="img.img_id" v-if="img.img_label">
                                    <b-carousel
                                        id="carousel-button"
                                        style="text-shadow: 0px 0px 2px #000"
                                        fade
                                        controls
                                        :interval="0"
                                    >   
                                        <div>
                                            <b-carousel-slide>
                                                <template #img>
                                                    <b-img
                                                        fluid
                                                        :src="img.img_src"
                                                    >
                                                    </b-img>
                                                </template>
                                            </b-carousel-slide> 
                                        </div>
                                    </b-carousel>
                                </b-card>
                                </template>
                                
                        </b-card-group>
                    </b-col>
                    <b-col sm="auto" md="4">
                        
                        
                        <b-card v-if="product">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    No. <code>*</code>
                                </template>
                
                                <label>{{product.code}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Duration <code>*</code>
                                </template>
                
                                <label>{{product.duration}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Brand <code>*</code>
                                </template>
                
                                <label>{{product.brand}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Collection Name<code>*</code>
                                </template>
                                <label> {{product.collection_name}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Quartal<code>*</code>
                                </template>
                                <label> {{product.quartal}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Year<code>*</code>
                                </template>
                                <label> {{product.year}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"  
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Prod. Month<code>*</code>
                                </template>
                                <label> {{product.prod_month}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Coll. Month<code>*</code>
                                </template>
                                <label> {{product.coll_month}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Sub. Brand<code>*</code>
                                </template>
                                <label> {{product.subbrand}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Category<code>*</code>
                                </template>
                                <label> {{product.category}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Color<code>*</code>
                                </template>
                                <label> {{product.color}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Itensity<code>*</code>
                                </template>
                                <label> {{product.itensity}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Article<code>*</code>
                                </template>
                                <label> {{product.article}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Repeat Sample<code>*</code>
                                </template>
                                <label> {{product.repeat_sample}}</label>
                            </b-form-group>
                            
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Ref. Sample<code>*</code>
                                </template>
                                <label> {{product.ref_sample}}</label>
                            </b-form-group>
                            
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Old Sample No.<code>*</code>
                                </template>
                                <label> {{product.old_sample_no}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Guidance<code>*</code>
                                </template>
                                <label> {{product.guidance}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Size Spec<code>*</code>
                                </template>
                                <label> {{sizeSpec}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Note<code>*</code>
                                </template>
                                <label> {{product.note}}</label>
                            </b-form-group>
                            <template v-for="images in productImg">
                                    <b-form-group
                                        :key="images.img_id"
                                        label-size ="sm">
                                        <template v-slot:label>
                                            {{images.img_label}}
                                        </template>
                                        <b-link :href="images.img_src" target="_blank">{{images.img_name}}</b-link>
                                    </b-form-group>
                            </template>
                            <template v-if="roles">
                            <div v-if="seen">
                                <b-button block variant="success" size="sm" v-on:click="approved()">Approve</b-button>
                                <b-button block variant="danger" size="sm" v-on:click="rejected()">Reject</b-button>
                            </div>
                            <div class="spacing-top" v-if="product.status != 'Droped'">
                                <b-button block variant="warning" size="sm" v-on:click="showModalDrop()">Drop</b-button>
                            </div>
                            </template>
                            <div v-if="!seen" >
                                <b-button disabled size="sm" block >{{product.status}}</b-button>
                            </div>
                        </b-card>
                    </b-col>
                    
                    <b-col sm="auto" md="8">
                        <b-card sub-title="Fabric">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="fabricData"
                                :fields="fieldsFabric"
                            >
                            <template #empty="scope">
                                <div class="text-center">
                                    <b-badge variant="danger">
                                        {{product.fabric_process}}
                                    </b-badge>
                                </div>
                            </template>
                            <template #cell(img_src)="{item}"  >
                               <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                            </template>
                            <template #cell(actions)="{item}">
                                <template v-if="roles">
                                <div v-if="item.product_material_status === 'Approved'">
                                    {{item.product_material_status}}
                                </div>
                                <div v-else-if="item.product_material_status === 'Rejected'">
                                    {{item.product_material_status}}
                                </div>
                                <div v-else>
                                    <b-button title="Aprrove" size="xs" variant="success" v-on:click="approveMaterial(item.product_material_id)">Approve</b-button>
                                    <b-button title="Aprrove" size="xs" variant="danger" v-on:click="rejectMaterial(item.product_material_id)">Reject</b-button>
                                </div>
                                </template>
                                <div>
                                    <b-button title="Detail" size="xs" variant="info" v-on:click="detailFabric(item.material_id)">Detail</b-button>
                                </div>
                            </template>
                            

                            </b-table>
                        </b-card>
                        <b-card sub-title="Accessories">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="accData"
                                :fields="fieldsAcc"
                            >
                            <template #empty="scope">
                                <div class="text-center">
                                    <b-badge variant="danger">
                                        {{product.accessories_process}}
                                    </b-badge>
                                </div>
                            </template>
                            <template #cell(img_src)="{item}"  >
                               <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                            </template>
                            <template #cell(actions)="{item}">
                                <template v-if="roles">
                                <div v-if="item.product_material_status === 'Approved'">
                                    {{item.product_material_status}}
                                </div>
                                <div v-else-if="item.product_material_status === 'Rejected'">
                                    {{item.product_material_status}}
                                </div>
                                <div v-else>
                                    <b-button title="Aprrove" size="xs" variant="success" v-on:click="approveMaterial(item.product_material_id)">Approve</b-button>
                                    <b-button title="Aprrove" size="xs" variant="danger" v-on:click="rejectMaterial(item.product_material_id)">Reject</b-button>
                                </div>
                                </template>
                                <div>
                                    <b-button title="Detail" size="xs" variant="info" v-on:click="detail(item.material_id)">Detail</b-button>
                                </div>
                            </template>
                            </b-table>
                        </b-card>
                        <b-card sub-title="Thread">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="threadData"
                                :fields="fieldsThread"
                            >
                                <template #empty="scope">
                                    <div class="text-center">
                                        <b-badge variant="danger">
                                            {{product.thread_process}}
                                        </b-badge>
                                    </div>
                                </template>
                                <template #cell(img_src)="{item}"  >
                                    <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                                </template>
                                <template #cell(actions)="{item}">
                                <template v-if="roles">
                                <div v-if="item.product_material_status === 'Approved'">
                                    {{item.product_material_status}}
                                </div>
                                <div v-else-if="item.product_material_status === 'Rejected'">
                                    {{item.product_material_status}}
                                </div>
                                <div v-else>
                                    <b-button title="Aprrove" size="xs" variant="success" v-on:click="approveMaterial(item.product_material_id)">Approve</b-button>
                                    <b-button title="Aprrove" size="xs" variant="danger" v-on:click="rejectMaterial(item.product_material_id)">Reject</b-button>
                                </div>
                                </template>
                                <div>
                                    <b-button title="Detail" size="xs" variant="info" v-on:click="detail(item.material_id)">Detail</b-button>
                                </div>
                            </template>

                            </b-table>
                        </b-card>
                        <b-card sub-title="Artwork">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="artworkData"
                                :fields="fieldsArtwork"
                            >
                                <template #empty="scope">
                                    <div class="text-center">
                                        <b-badge variant="danger">
                                            {{product.artwork_process}}
                                        </b-badge>
                                    </div>
                                </template>
                                <template #cell(img_src)="{item}"  >
                                    <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                                </template>
                                <template #cell(actions)="{item}">
                                    <template v-if="roles">
                                    <div v-if="item.product_material_status === 'Approved'">
                                        {{item.product_material_status}}
                                    </div>
                                    <div v-else-if="item.product_material_status === 'Rejected'">
                                        {{item.product_material_status}}
                                    </div>
                                    <div v-else>
                                        <b-button title="Aprrove" size="xs" variant="success" v-on:click="approveMaterial(item.product_material_id)">Approve</b-button>
                                        <b-button title="Aprrove" size="xs" variant="danger" v-on:click="rejectMaterial(item.product_material_id)">Reject</b-button>
                                    </div>
                                    </template>
                                    <div>
                                        <b-button title="Detail" size="xs" variant="info" v-on:click="detail(item.material_id)">Detail</b-button>
                                    </div>
                                </template>
                            </b-table>
                        </b-card>
                        <b-card sub-title="Interlining">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="interliningData"
                                :fields="fieldsInterlining"
                            >
                                <template #empty="scope">
                                    <div class="text-center">
                                        <b-badge variant="danger">
                                            {{product.interlining_process}}
                                        </b-badge>
                                    </div>
                                </template>
                                <template #cell(img_src)="{item}"  >
                                    <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                                </template>
                                <template #cell(actions)="{item}">
                                    <template v-if="roles">
                                    <div v-if="item.product_material_status === 'Approved'">
                                        {{item.product_material_status}}
                                    </div>
                                    <div v-else-if="item.product_material_status === 'Rejected'">
                                        {{item.product_material_status}}
                                    </div>
                                    <div v-else>
                                        <b-button title="Aprrove" size="xs" variant="success" v-on:click="approveMaterial(item.product_material_id)">Approve</b-button>
                                        <b-button title="Aprrove" size="xs" variant="danger" v-on:click="rejectMaterial(item.product_material_id)">Reject</b-button>
                                    </div>
                                    </template>
                                    <div>
                                        <b-button title="Detail" size="xs" variant="info" v-on:click="detail(item.material_id)">Detail</b-button>
                                    </div>
                                </template>

                            </b-table>
                        </b-card>
                        <b-card sub-title="Material History">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixer
                                :items="materialHistory"
                                :fields="fieldsApprove"
                            >
                                <template #cell(date)='{item}'>
                                    {{new Date(item.date).toLocaleString("id-ID")}}
                                </template>
                            </b-table>
                        </b-card>
                        <b-card sub-title="Product History">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixer
                                :items="approveHistory"
                                :fields="fieldsApprove"
                            >
                                <template #cell(date)='{item}'>
                                    {{new Date(item.date).toLocaleString("id-ID")}}
                                </template>
                            </b-table>
                            <b-button title="Submit" size="sm" variant="success" v-on:click="submit()" >Submit</b-button>
                            <b-button title="Cancel" size="sm" variant="warning" v-on:click="submit()" >Cancel</b-button>
                        </b-card>
                    </b-col>
                </b-row>
                <template>
                    <b-modal
                        hide-footer
                        v-model="show"
                        hide-backdrop
                        
                        v-on:cancel="hideModal()"
                        v-on:close="hideModal()"
                        ok-title="Save"
                        size="md"
                        ref="modal"
                        no-close-on-backdrop
                        scrollable 
                        title="Comment"
                    >
                        <div id="modal">
                            <validation-observer ref="observer" v-slot="{handleSubmit}">
                                <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                                    <validation-provider
                                        name="Comment"
                                        
                                        :rules="{ required: isValid }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols-lg       ="4" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Comment
                                            </template>
                                        
                                            <b-form-textarea id="Comment" v-model="form.comment"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                            <b-form-invalid-feedback id="Comment">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <b-button title="Reject" block size="sm" variant="danger" type="submit">Reject</b-button>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-modal>
                </template>
                <template>
                    <b-modal
                        hide-footer
                        v-model="showDropModal"
                        hide-backdrop
                        
                        v-on:cancel="hideModal()"
                        v-on:close="hideModal()"
                        ok-title="Save"
                        size="md"
                        ref="modal"
                        no-close-on-backdrop
                        scrollable 
                        title="Comment"
                    >
                        <div id="modal">
                            <validation-observer ref="observer" v-slot="{handleSubmit}">
                                <b-form @submit.stop.prevent="handleSubmit(goToDrop)">
                                    <validation-provider
                                        name="Comment"
                                        
                                        :rules="{ required: isValid }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols-lg       ="4" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Comment
                                            </template>
                                        
                                            <b-form-textarea id="Comment" v-model="form.comment"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                            <b-form-invalid-feedback id="Comment">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <b-button title="Drop" block size="sm" variant="danger" type="submit">Drop</b-button>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-modal>
                </template>
                <template>
                    <b-modal
                        hide-footer
                        v-model="showMaterial"
                        hide-backdrop
                        
                        v-on:cancel="hideModalMaterial()"
                        v-on:close="hideModalMaterial()"
                        ok-title="Save"
                        size="md"
                        ref="modal"
                        no-close-on-backdrop
                        scrollable 
                        title="Comment Material"
                    >
                        <div id="modal">
                            <validation-observer ref="observer" v-slot="{handleSubmit}">
                                <b-form @submit.stop.prevent="handleSubmit(onSubmitMaterial)">
                                    <validation-provider
                                        name="comment-material"
                                        
                                        :rules="{ required: isValid }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols-lg       ="4" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Comment
                                            </template>
                                        
                                            <b-form-textarea id="comment-material" v-model="form.commentMaterial"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                            <b-form-invalid-feedback id="comment-material">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <b-button title="Reject" block size="sm" variant="danger" type="submit">Reject</b-button>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-modal>
                </template>
                <template>
                    <b-modal
                        v-model="showMasterFabric"
                    >
                        <b-card title="Material Detail">
                            <b-table
                                :items="masterMat"
                                :fields="masterFabricField"
                                responsive
                                stacked
                            >

                            </b-table>
                            <!-- <b-row>
                                <b-col sm="auto" md="6">
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Brand
                                            </template>
                                            <label> {{masterMat.brand}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Yarn Size
                                            </template>
                                            <label> {{masterMat.yarn_size}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Texture
                                            </template>
                                            <label> {{masterMat.texture}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Month
                                            </template>
                                            <label> {{masterMat.month}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Year
                                            </template>
                                            <label> {{masterMat.year}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Code
                                            </template>
                                            <label> {{masterMat.code}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Type
                                            </template>
                                            <label> {{masterMat.type}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Width
                                            </template>
                                            <label> {{masterMat.width}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Chemical Finishing
                                            </template>
                                            <label> {{masterMat.chemical_finishing}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Description
                                            </template>
                                            <label> {{masterMat.description}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Weight
                                            </template>
                                            <label> {{masterMat.weight}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Weaving Type
                                            </template>
                                            <label> {{masterMat.weaving_type}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Composition
                                            </template>
                                            <label> {{masterMat.composition}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Construction
                                            </template>
                                            <label> {{masterMat.construction}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="6" 
                                            label-cols-lg       ="6" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Code Handloom
                                            </template>
                                            <label> {{masterMat.code_handloom}}</label>
                                    </b-form-group>
                                    <b-form-group
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Color
                                            </template>
                                            <label> {{masterMat.color}}</label>
                                    </b-form-group>
                                </b-col>
                            </b-row> -->
                        </b-card>
                    </b-modal>
                </template>
                <template>
                    <b-modal
                        v-model="showMaster"
                    >
                        <b-card title="Material Detail">
                            <b-table
                                :items="masterMat"
                                :fields="masterField"
                                responsive
                                stacked
                            >

                            </b-table>
                        </b-card>
                    </b-modal>
                </template>
            </b-card>
        </b-container>
    </div>
</template>
<script>

import { APIPATH_PLM } from "../../../../api/api-paths";
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../api/api-methods";

import { mapGetters,mapActions } from 'vuex';
import  {APIUrl} from '../../../../api/api-base.js'


export default {
    // props:['productId'],
    data(){
        return{
            productId : this.$route.query.productId,
            formName:'Approval Design',
            modulName:'Form',
            isValid:false,
            seen:true,
            productImg:[],
            masterMat:[],
            showMaster:false,
            showMasterFabric:false,
            form:{
                comment:null,
                user:null,
                commentMaterial:null,
            },
            status:null,
            statusMaterial:'Rejected',
            date:null,
            masterFabricField:[
                'code','brand','category',
                'type','status','description',
                'yarn_size','texture','month','year',
                'weight','weaving_type','composition',
                'construction','code_handloom','color'
                
            ],
            masterField:[
                'code','brand','category',
                'type','status','description','color'
                
            ],
            fieldsFabric:[
                'actions','code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsAcc:[
                'actions','code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsThread:[
                'actions','code','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsArtwork:[
                'actions','code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsInterlining:[
                'actions','code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsApprove:[
                {key:'date',label:'Date'},
                'status','comment',
                {key:'created_by', label:'Approve By'}
            ],
            approveHistory:[],
            product:null,
            fabricData:[],
            accData:[],
            threadData:[],
            artworkData:[],
            interliningData:[],
            show:false,
            showDropModal:false,
            showMaterial:false,
            materialHistory:[],
            materialId:null,
            process:null,

            //bad way role
            role:['Super User','Designer'],
            rols:['Super User'],
            sizeSpec:null,
            
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_IMAGES','GET_PRODUCT_DETAIL','getProduct','getProductDetail','GET_PRODUCT_MATERIAL_DEV','POST_PRODUCT_APPROVE','GET_PRODUCT_APPROVE','APPROVE_PRODUCT_MATERIAL','GET_PRODUCT_DASHBOARD','POST_PRODUCT_PROCESS','GET_MATERIAL_APPROVE','POST_MATERIAL_APPROVE']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        ...mapActions('session',['showNotification']),
        ...mapActions('sizeSpec',['GET_SIZESPEC_DASHBOARD']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        // formatDate(value){
        //     // {{new Date(item.date).toLocaleString("id-ID")}}
        //     const formDate = new Date(value).toLocaleString("id-ID")
        //     console.log('date',formDate);
        //     return formDate
        // },
        
        submit(){
            this.$router.push({
                name:'AppsApproveDesignListPage'
            })
        },
        approved(){
            this.show = false
            this.status = 'Approved'
            this.process = 'Product Approved'
            this.onSubmit()
        },
        showModalDrop(){
            this.showDropModal = true
            this.status = 'Drop';
            this.process = 'Product Droped'
        },
        goToDrop(){
            this.showDropModal = false
            fnPOST(APIPATH_PLM.dropProdev, {
                product_id:this.productId,
                status:'Drop',
                article:this.product.article,   
            })
            .then((rsp) => {
                if (rsp.data.status == "200") {
                this.onSubmit()
                }
            })
            .catch((err) => {
                console.log(err);
            });
        },
        rejected(){
            this.show = true
            this.status = 'Rejected'
            this.process = 'Product Rejected'
        },
        rejectMaterial(id){
            this.showMaterial = true
            this.statusMaterial = 'Rejected'
            this.$set(this,'materialId',id)
        },
        approveMaterial(id){
            this.showMaterial = false
            this.statusMaterial = 'Approved'
            this.$set(this,'materialId',id)
            this.onSubmitMaterial()
        },
        onSubmit(){
            let today = new Date().toLocaleString()
            this.POST_PRODUCT_APPROVE({
                product: this.productId,
                status: this.status,
                comment: this.form.comment,
                date: today,
                created_by: this.form.user
            }).then(response => {
                this.showNotification({type:'success', message:'Successfully'})
                this.getApproveHistory()
                this.saveProcess(this.productId)
                this.clearForm()
                // this.backToList()
                this.getData()
                this.show = false
                this.showDropModal = false
            }).catch(error => {
                return error.response.data.message
            })
            // this.submit()
        },

        onSubmitMaterial(){
            // let today = new Date().toLocaleString()
            this.POST_MATERIAL_APPROVE({
                product: this.productId,
                product_material:this.materialId,
                status: this.statusMaterial,
                comment: this.form.commentMaterial,
                created_by: this.form.user
            }).then(response => {
                this.approvedProductMaterial(this.materialId,this.statusMaterial)
                this.showMaterial = false
                this.showNotification({type:'success', message:'Successfully'})
                this.getMaterialHistory()
                this.getProductMaterial()
                this.clearFormMaterial()
            }).catch(error => {
                return error.response.data.message
            })
        },
        hideModal(){
            this.show = false
            this.showModalDrop = false
            this.clearForm()
        },
        hideModalMaterial(){
            this.showMaterial = false
            this.clearFormMaterial()
        },
        clearForm(){
            this.form.comment = null
            this.status = null
            this.seen = true
        },
        clearFormMaterial(){
            this.form.commentMaterial = null,
            this.statusMaterial = null
        },
        display(){
            console.log('product id',this.productId);
            if(this.product.comment !=  null){
                this.form.comment = this.product.comment
            }
        },
        isRejected(){
            console.log('is product',this.product);
            // if(this.product.status === 'Waiting For Approval'){
            //     this.seen = true
            // }
            // if(this.product.status === 'Rejected'){
            //     this.seen = false
            // }else{
            //     this.seen = false
            // }
        },
        backToList(){
            this.showNotification({type:'success', message:'Successfully'})
            this.$router.push({name:'AppsApproveDesignListPage'})
        },
        getData(){
            this.GET_PRODUCT_DETAIL({
                product_id:this.productId
            }).then(response =>{
                // let data = response.filter(x=>x.id === this.productId)
                let data = response.data.payload
                console.log('data',data);
                this.product = data

                if( data.status === 'Waiting For Approval'){
                    this.seen = true
                }else{
                    this.seen = false
                }
                
            })
            
            this.getApproveHistory()

        },
        getDataImg(){
            this.productImg = []
            // this.getProduct()
            this.GET_PRODUCT_IMAGES({product_id:this.productId})
            .then(response =>{
                // let data = response.filter(x=>x.id === this.productId)
                let data = response.data.payload
                for (let i = 0; i < data.length; i++) {
                    let img = {
                        img_id:data[i].id,
                        img_code:data[i].img_code,
                        img_name:data[i].img_name,
                        img_src:data[i].img_src,
                        img_label:data[i].img_label,

                    }

                    this.productImg.push(img)
                    
                }
                
            })
                
        },
        getProductMaterial(){
            this.GET_PRODUCT_MATERIAL_DEV().then(response => {
                let productMaterial = response.data.payload.filter(pm => pm.product_id == this.productId)
                console.log(productMaterial);
                this.fabricData         = productMaterial.filter(f=>f.category === 'fabric' && f.img_label === 'images')
                this.accData            = productMaterial.filter(f=>f.category === 'accessories')
                this.threadData         = productMaterial.filter(f=>f.category === 'thread')
                this.artworkData        = productMaterial.filter(f=>f.category === 'artwork')
                this.interliningData    = productMaterial.filter(f=>f.category === 'interlining')
            })
        },
        getApproveHistory(){
            this.GET_PRODUCT_APPROVE().then(response =>{
                let approve     = response.data.payload.filter(app => app.product == this.productId)
                this.approveHistory = approve
                
            })
        },
        getMaterialHistory(){
            this.GET_MATERIAL_APPROVE({product:this.productId})
            .then(rsp=>{
                console.log(rsp);
                this.$set(this,'materialHistory',rsp.data.payload)
            }).catch(err=>{
                return err
            })
        },
        getAccountInfo() {
            let userData      = JSON.parse(localStorage.userData)
            this.$set(this.form, 'user', userData.fullname)
            // console.log(this.form.user);
        },
        // approveMaterial(id,status){
        //     this.POST_MATERIAL_APPROVE({
        //         product:this.productId,
        //         product_material:id,
        //         status:status,
        //         comment:this.form.comment,
        //         date:today,
        //         created_by:this.form.user
        //     }).then(rsp=>{
                
        //         this.showNotification({type:'success', message:'Successfully'})
        //         this.getMaterialHistory(this.productId)
        //     }).catch(err=>{
        //         return err
        //     })
        // },
        approvedProductMaterial(id,status){
            // console.log(id,status,isProccess);
            this.APPROVE_PRODUCT_MATERIAL({
                product_material:id,
                status:status,
                is_proccess:status
            }).then(rsp =>{
                console.log('approve',rsp);
                this.getProductMaterial()
            })
            // this.backToList()
            
            // this.forceRefresh()
        },
        forceRefresh(){
            // console.log('param',id);
            this.$router.push({
                name:'AppsApproveDesignFormPage',
                params:{productId:this.productId}
                
            })
        
        },
        saveProcess(prodId){
            let now = new Date()
            // let estDate = new Date()
            // let startDate = now.toLocaleDateString('id-ID',{ year: 'numeric', month: '2-digit', day: '2-digit' })
            
            let year = now.toLocaleString('id-ID', { year: 'numeric'})
            
            let month = now.toLocaleString('id-ID', { month: '2-digit'})
            
            let day = now.toLocaleString('id-ID', { day: '2-digit'})
            let startDate = year+'-'+month+'-'+day

            let estDate = new Date()
            
            


            // let startDate = estDate.getDate()

            if(this.product.duration === 'basic'){
                estDate.setDate(estDate.getDate()+7)
            }else if(this.product.duration === 'regular'){
                estDate.setDate(estDate.getDate()+49)
            }else{
                console.log('else');
                estDate.setDate(estDate.getDate()+27)
            }

            let year2 = estDate.toLocaleString('id-ID', { year: 'numeric'})
            
            let month2 = estDate.toLocaleString('id-ID', { month: '2-digit'})
            
            let day2 = estDate.toLocaleString('id-ID', { day: '2-digit'})
            let estimateDate = year2+'-'+month2+'-'+day2

            console.log('today',estimateDate);
            console.log('start',startDate);
            this.POST_PRODUCT_PROCESS({
                product_id:prodId,
                process:this.process,
                task:this.userData.dept,
                start_date:startDate,
                estimate_date:estimateDate,
                status:'On Progress'
            }).then(rsp=>{
                console.log('Process Saved');
            }).catch(err=>{
                console.log(err);
            })
        },
        detail(id){
            this.GET_MASTER_MATERIAL().then(rsp=>{
                let masterMaterial = rsp.data.payload.filter(i=>i.id === id)
                this.$set(this,'masterMat',masterMaterial)
                this.showMaster = true
                
            })
        },
        detailFabric(id){
            this.showMasterFabric = false
            this.GET_MASTER_MATERIAL().then(rsp=>{
                let masterMaterial = rsp.data.payload.filter(i=>i.id === id)
                this.$set(this,'masterMat',masterMaterial)
                this.showMasterFabric = true
                
            })
        },
        getSizeSpec(){
            
            this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
                let sizeSpec = JSON.parse(JSON.stringify( rsp.data.payload))
                let sizeDesc = sizeSpec.filter(i=>i.id === this.product.size_spec)
                this.sizeSpec = sizeDesc[0].category+' - '+sizeDesc[0].description 
                // console.log(sizeSpec.filter(i=>i.id === this.product.size_spec));
                

                
            })
        },

    },
    computed:{
        ...mapGetters('productDesign',['product','MASTER_MATERIAL','PRODUCT_MATERIAL','PRODUCT_APPROVE']),
        ...mapGetters('session', ['userData','localDate', 'serverDate']),
        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
            // return this.role.some(i=>this.rols.includes(i))
        },
        loadData(){
            return this.product.filter(x=>x.id == this.productId)
            // console.log('brand',brand);
            
        },
        
        
        
    },
    created(){
        this.getAccountInfo()
        this.getData()
        this.getDataImg()
        this.getProductMaterial()
        this.getMaterialHistory()
        this.display()
        this.isRejected()
    },
    mounted(){
        this.getSizeSpec()
    }
}
</script>
<style scoped>
.spacing-top{
    margin-top: 9px;
    margin-bottom: 9px;
}
</style>