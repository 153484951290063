<template>
    <div>
        <template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                <b-form @submit.prevent="handleSubmit(submitProductMaterial)">
                    <b-row >
                        <b-col cols="auto" md="6">
                            
                            <!-- Code Artwork -->
                            <validation-provider
                                name="codeArtwork"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code
                                    </template>
                                    
                                        <!-- <b-form-select id="inputCodeArtwork" v-model="form.codeArtwork"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                        <v-select append-to-body
                                            :reduce="ref => ref.code" 
                                            v-model="form.codeArtwork"
                                            @input="setSelected" 
                                            :options="codeOpt" 
                                            placeholder="Type your code number"
                                            label="ref">
                                            <template #list-header>
                                                <b-input-group-append>
                                                    <b-button block variant="light" @click="showMaster()">
                                                        <!-- <b-icon icon="eye-fill" aria-label="Help"></b-icon> -->
                                                        Lihat Semua
                                                    </b-button>
                                                </b-input-group-append>
                                            </template>
                                            <template #option="{ code, description }">
                                                <p style="margin: 0">{{ code }}</p>
                                                <em class="text-muted">{{ description }}</em>
                                            </template>
                                        </v-select>
                                        
                                    
                                    <b-form-invalid-feedback id="inputCodeArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Type Artwork -->
                            <validation-provider
                                name="TypeArtwork"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4"  
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Type
                                    </template>
                                
                                    <b-form-input readonly id="inputTypeArtwork" v-model="form.typeArtwork"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputTypeArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Description -->
                            <validation-provider
                                name="DescriptionArtwork"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Description
                                    </template>
                                
                                    <b-form-input readonly id="DescriptionArtwork" v-model="form.descriptionArtwork"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="DescriptionArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Detail Artwork -->
                            <validation-provider
                                name="DetailArtwork"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Detail
                                    </template>
                                
                                    <b-form-textarea id="DetailCodeArtwork" v-model="form.detailArtwork"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                    <b-form-invalid-feedback id="DetailCodeArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <!-- UsedArtwork
                            <validation-provider
                                name="UsedArtwork"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Used As
                                    </template>
                                    <b-form-select id="inputUsedArtwork" v-model="form.usedArtwork"  :options="usedOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputUsedArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider> -->
                            <b-form-group
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images
                                </template>
                                    <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesArtwork" ></b-img>

                            </b-form-group>
                            
                            <div>
                                <b-button block  variant="warning" v-on:click="clearForm()">Cancel</b-button>
                                <b-button block  variant="success" type="submit">Save</b-button>
                            </div>
                        </b-col>
                        <b-col cols="auto" md="6">
                            
                            <b-card sub-title="Material History">
                                <b-table
                                    responsive
                                    show-empty
                                    small
                                    fixer
                                    :items="loadMaterialHistory"
                                    :fields="fieldsApprove"
                                >
                                    <template #cell(date)='{item}'>
                                        {{new Date(item.date).toLocaleString("id-ID")}}
                                    </template>
                                </b-table>
                            </b-card>
                        </b-col>
                    </b-row>
                        
                </b-form>
            </validation-observer>
        </template>
        <template>
            <b-table
                responsive
                striped 
                hover
                :items="loadProductMaterial"
                :fields="fields"
                show-empty
            >
                
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="{item}">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="updateMaterial(item.product_material_id)">
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.product_material_id)">
                        
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                        
                    </b-button>
                    <b-button title="View" size="xs" variant="dark" v-on:click="modalArtworkView = true">
                        
                        <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </template>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';
export default {
    props:['productId'],
    data(){
        return{
            mainProps: {width: 150, class: 'm1' },
            formName: 'Artwork',
            modulName: null,
            tabIndex:1,
            isValid:false,
            dataArtwork:[],
            
            fields:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                'used_as'
            ],
            imagesUrl:[],
            form:{
                productMaterialId:null,
                codeArtwork:null,
                typeArtwork:null,
                imagesArtwork:[],
                detailArtwork:null,
                usedArtwork:null,
                descriptionArtwork:null,
                artworkId:null,
                category:'artwork',
                statusArtwork:null,
                
            },
            codeOpt:[
                {code:'Code A', ref:'Code A'},
                {code:'Code B', ref:'Code B'},
                {code:'Code C', ref:'Code C'},
                {code:'Code D', ref:'Code D'},
                
            ],
            usedOpt:[
                {value:'Main', text:'Main'},
                {value:'Combo 1', text:'Combo 1'},
                {value:'Combo 2', text:'Combo 2'},
                {value:'Combo 3', text:'Combo 3'},
            ],
            artworkOpt: [
                { item: 'tbd', name: 'TBD', notEnabled: true },
                { item: 'yes', name: 'Yes' },
                { item: 'no', name: 'No', notEnabled: true},
                
            ],
            isSelect:'yes',
            modalMaster:false,
            modalArtworkView:false,
            fieldsApprove:[
                {key:'date',label:'Date'},
                'status','comment','code'
                // {key:'created_by', label:'Approve By'}
            ],
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_MATERIAL_DEV','POST_PRODUCT_MATERIAL','PUT_PRODUCT_MATERIAL']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getProductMaterial(){
            // console.log('id',this.productId);
            this.GET_PRODUCT_MATERIAL_DEV({
                product:this.productId
            }).then(rsp=>{
                // console.log('data',rsp.data.payload.filter(i=>i.category ==='artwork' ))
                let data = rsp.data.payload.filter(i=>i.category ==='artwork' && i.product_id === this.productId)

                this.dataArtwork = data
            }).catch(err=>{
                return err
            })
        },
        getArtworkMaster(){
            this.GET_MASTER_MATERIAL().then(response => {
                this.codeOpt = []
                    let objArtwork = response.data.payload.filter(x=>x.category === 'artwork')
                    for(var i=0;i<objArtwork.length;i++){
                        
                            let arrArtwork = {
                                ref: objArtwork[i].code,
                                code: objArtwork[i].code,
                                description:objArtwork[i].description,
                            }
                            this.codeOpt.push(arrArtwork)
                    }
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeArtwork = null
            this.form.typeArtwork = null
            this.form.imagesArtwork = []
            this.imagesUrl = []
            this.form.detailArtwork = null
            this.form.usedArtwork = null
            this.form.artworkId = null
            this.form.descriptionArtwork = null
            this.form.productMaterialId     = null
            this.form.statusArtwork = null
            this.$refs.observer.reset()
        },
        setSelected(value){
            // console.log(value);
            if(value != null){
                let dataArtwork = this.MASTER_MATERIAL.filter(x => x.code === value)
                // console.log(dataArtwork);
                this.form.artworkId= dataArtwork[0].id
                this.form.codeArtwork= dataArtwork[0].code
                this.form.typeArtwork= dataArtwork[0].type
                this.form.descriptionArtwork = dataArtwork[0].description
                this.form.imagesArtwork = dataArtwork[0].img_src
            }else{
                console.log('belum ada data')
            }
        },
        submitProductMaterial(){
                let productMaterial = []
                let artwork = {
                    category: this.form.category,
                    material_id: this.form.artworkId,
                    code: this.form.codeArtwork,
                    type: this.form.typeArtwork,
                    used_as: this.form.usedArtwork,
                    detail: this.form.detailArtwork,
                    description: this.form.descriptionArtwork,
                    status:'new',
                    is_proccess:'active'
                }    
                productMaterial.push(artwork)

            if(this.form.productMaterialId){
                this.PUT_PRODUCT_MATERIAL({
                    product_material_id:this.form.productMaterialId,
                    detail:this.form.detailArtwork,
                    used_as:this.form.usedArtwork,
                    process:this.form.statusArtwork
                }).then(rsp=>{
                    console.log('berhasil dihapus');
                    this.getProductMaterial()
                    this.clearForm()
                })
            }else{
                this.POST_PRODUCT_MATERIAL({
                product_id:this.productId,
                material:productMaterial

            })
                .then(response => {
                    console.log('success submit product material');
                    this.getProductMaterial()
                    this.clearForm()
                })
                .catch(error => {
                    console.log('material save',error);
                })
            }  
            
        },
        editData(id){
            let data = this.dataArtwork.filter(i=>i.product_material_id === id)
            // console.log('edit data',data);

            this.form.productMaterialId     = id
            this.form.artworkId              = data[0].id
            this.form.codeArtwork            = data[0].code
            this.form.typeArtwork            = data[0].type
            this.form.detailArtwork          = data[0].detail
            this.form.usedArtwork            = data[0].used_as
            this.form.descriptionArtwork     = data[0].description
            this.form.statusArtwork          = data[0].product_material_proccess
        },
        updateMaterial(id){
            this.PUT_PRODUCT_MATERIAL({
                product_material_id:id,
                detail:this.form.detailArtwork,
                used_as:this.form.usedArtwork,
                process:'drop'
            }).then(rsp=>{
                console.log('berhasil dihapus');
                this.getProductMaterial()
                this.clearForm()
            })
        }

    },
    created(){
        this.getProductMaterial()
        this.getArtworkMaster()
    },
    computed:{
        
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        ...mapGetters('productDesign',['GET_MATERIAL_HISTORY']),
        loadMaterialHistory(){
            
            return this.GET_MATERIAL_HISTORY.filter(i=>i.category === 'artwork')
        },
        loadProductMaterial(){
           
            return this.dataArtwork
        }
    }
}
</script>