<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto" md="6" >
                        
                        <!-- Code Artwork -->
                        <validation-provider
                            name="codeArtwork"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Code
                                </template>
                                    <!-- <b-form-select id="inputCodeArtwork" v-model="form.codeArtwork"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                    <v-select append-to-body
                                        v-model="form.codeArtwork"
                                        :reduce="ref => ref.code" 
                                        @input="setSelected" 
                                        :options="codeOpt" 
                                        placeholder="Type your code number"
                                        label="ref">
                                        <template #list-header>
                                            <b-input-group-append>
                                                <b-button block variant="light" @click="showMaster()">
                                                    <!-- <b-icon icon="eye-fill" aria-label="Help"></b-icon> -->
                                                    Lihat Semua
                                                </b-button>
                                            </b-input-group-append>
                                        </template>
                                        <template #option="{ code, description }">
                                            <p style="margin: 0">{{ code }}</p>
                                            <em class="text-muted">{{ description }}</em>
                                        </template>
                                    </v-select>
                                <b-form-invalid-feedback id="inputCodeArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        

                        <!-- Type Artwork -->
                        <validation-provider
                            name="TypeArtwork"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Type
                                </template>
                            
                                <b-form-input readonly id="inputTypeArtwork" v-model="form.typeArtwork"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputTypeArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        
                        <!-- Description -->
                        <validation-provider
                            name="DescriptionArtwork"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Description
                                </template>
                            
                                <b-form-input readonly id="DescriptionArtwork" v-model="form.descriptionArtwork"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="DescriptionArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                       

                        <!-- Detail Artwork -->
                        <validation-provider
                            name="DetailArtwork"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Detail
                                </template>
                            
                                <b-form-textarea id="DetailCodeArtwork" v-model="form.detailArtwork"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                <b-form-invalid-feedback id="DetailCodeArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        
                        
                    </b-col>
                    <b-col cols="auto" md="6">
                        <!-- UsedArtwork -->
                        <!-- <validation-provider
                            name="UsedArtwork"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Used As
                                </template>
                                <b-form-select id="inputUsedAs" v-model="form.usedArtwork" :options="usedOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                <b-form-invalid-feedback id="inputUsedAs">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->

                        <!-- Images Artwork -->
                        <!-- <validation-provider
                            name="imagesArtwork"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images
                                </template>
                                <b-form-file
                                    multiple
                                    ref="fileInput"
                                    id="inputImagesArtwork"
                                    v-model="form.imagesArtwork"
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose  file here..."
                                    accept=".jpg, .png,"
                                    no-drop
                                    :file-name-formatter="formatNames"
                                    v-on:change="onFileChange"
                                ></b-form-file>
                                    <div v-for="img in imagesUrl" :key="img.name">
                                        <p class="small">
                                            <a :href="img.src" target="_blank">{{img.fileName}}</a>
                                            <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                            </b-badge>
                                        </p>
                                    </div>
                                <b-form-invalid-feedback id="inputImagesArtwork">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->
                        <b-form-group
                            label-cols          ="4" 
                            label-cols-lg       ="4" 
                            label-size          ="sm"
                        >
                            <template v-slot:label>
                                Images
                            </template>
                            <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesArtwork"></b-img>

                        </b-form-group>
                        <div>
                            <b-button block  variant="success" size="sm" type="submit">Save</b-button>
                        </div>
                    </b-col>
                    
                    
                    
                </b-row>
                    
            </b-form>
        </validation-observer>

        <div>
            <b-table
                striped 
                responsive
                hover
                :items="loadProductMaterial"
                :fields="fields"
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="data">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
        
        <b-modal
            hide-backdrop
            hide-footer
            v-model="modalMaster"
            size="xl"
            ref="modal"
            scrollable 
            title="Master Material">
            <master-artwork/>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MasterArtwork from '../Master/MasterArtwork.vue';
export default {
    components: {  MasterArtwork },
    name:'FormArtwork',
    data(){
        return{
            mainProps: {width: 150, class: 'm1' },
            formName: 'Artwork',
            modulName: null,
            tabIndex:3,
            isValid:false,
            fields:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                // 'used_as'
            ],
            imagesUrl:[],
            form:{
                indexId:null,
                artworkId:null,
                codeArtwork:null,
                typeArtwork:null,
                imagesArtwork:[],
                detailArtwork:null,
                usedArtwork:null,
                descriptionArtwork:null,
                category:'artwork'
            },
            codeOpt:[
                {code:'Code A', ref:'Code A'},
                {code:'Code B', ref:'Code B'},
                {code:'Code C', ref:'Code C'},
                {code:'Code D', ref:'Code D'},
            ],
            usedOpt:[
                {value:'main', text:'Main'},
                {value:'collar', text:'Collar'},
                {value:'pocket', text:'Pocket'},
            ],
            artworkOpt: [
                { item: 'TBD', name: 'TBD' },
                { item: 'Yes', name: 'Yes' },
                { item: 'No', name: 'No'},
                
            ],
            isSelect:'TBD',
            modalMaster:false,
        }
    },
    methods:{
        ...mapActions('productDesign',['add','delete','update','get']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            if(this.form.indexId == null){
                this.add({
                    formname: this.formName,
                    category: this.form.category,
                    material_id: this.form.artworkId,
                    code:this.form.codeArtwork,
                    type:this.form.typeArtwork,
                    detail:this.form.detailArtwork,
                    images:this.imagesUrl,
                    used_as:this.form.usedArtwork,
                    description: this.form.descriptionArtwork,
                    isProccess:this.isSelect,
                })
            }else{
                this.update({
                    formname: this.formName,
                    id:this.form.indexId,
                    artwork_id: this.form.artworkId,
                    code:this.form.codeArtwork,
                    type:this.form.typeArtwork,
                    detail:this.form.detailArtwork,
                    images:this.imagesUrl,
                    used_as:this.form.usedArtwork,
                    description: this.form.descriptionArtwork,
                    isProccess:this.isSelect,
                })
            }
            
            this.clearForm()
        
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeArtwork = null
            this.form.typeArtwork = null
            this.form.imagesArtwork = []
            this.imagesUrl = []
            this.form.detailArtwork = null
            this.form.usedArtwork = null
            this.form.artworkId = null
            this.form.descriptionArtwork = null
            this.$refs.observer.reset()
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
        return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        deleteData(id){
            this.delete({id:id,formname:this.formName})
        },
        editData(index){
            this.get({id:index,formname:this.formName})
            this.form.indexId       = index
            this.form.artworkId     = this.artworkDetail.artwork_id
            this.form.codeArtwork   = this.artworkDetail.code
            this.form.typeArtwork   = this.artworkDetail.type
            this.form.detailArtwork = this.artworkDetail.detail
            this.imagesUrl          = this.artworkDetail.images
            this.form.usedArtwork   = this.artworkDetail.used_as
            this.form.descriptionArtwork   = this.artworkDetail.description
            this.isSelect           = this.artworkDetail.artwork_process
        },
        showMaster(){
            this.modalMaster = true
        },
        setSelected(value){
            if(value != null){
                let dataArtwork = this.MASTER_MATERIAL.filter(x => x.code === value)
                this.form.artworkId= dataArtwork[0].id
                this.form.codeArtwork= dataArtwork[0].code
                this.form.typeArtwork= dataArtwork[0].type
                this.form.descriptionArtwork = dataArtwork[0].description
                this.form.imagesArtwork = dataArtwork[0].img_src
            }
        },
        getArtworkMaster(){
            this.GET_MASTER_MATERIAL().then(response => {
                this.codeOpt = []
                    let objArtwork = response.data.payload.filter(x=>x.category ==='artwork')
                    for(var i=0;i<objArtwork.length;i++){
                        let arrArtwork = {
                            ref: objArtwork[i].code,
                            code: objArtwork[i].code,
                            description: objArtwork[i].description
                            
                        }
                        this.codeOpt.push(arrArtwork)
                    }
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
    },
    mounted(){
        if(this.key_id != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        
        
        this.getArtworkMaster()
    },
    computed:{
        ...mapGetters('productDesign',['PRODUCT_MATERIAL']),
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        loadProductMaterial(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='artwork')
        }
        
    }
}
</script>