<template>
    <div>
        <b-container fluid>
            <b-card
                header="Master Parameter List"
                header-tag="header"
            >
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto">
                            <div class="text-left">
                                <h3>Master Paramater</h3>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3" >
                            <template v-if="roles">
                            <div class="text-left">
                                
                                <b-button title="Tambah" size="sm" variant="success" v-on:click="show = !show">
                                    
                                    <b-icon icon="plus-circle-fill" aria-label="Help"></b-icon>
                                    Create
                                    
                                </b-button>
                            </div>
                            </template>
                        </b-col>
                    </b-row>
                    
                </template>

                <template>
                    <b-table 
                        id="table-supplier"
                        responsive 
                        stickyColumn 
                        striped 
                        hover 
                        show-empty
                        :items="masterSupplierData" 
                        :fields="masterSupplierField">

                        <template v-slot:cell(actions)="{item}">
                            <template v-if="roles">
                            <b-button title="Hapus" size="xs" variant="danger" v-on:click="onDelete(item.id,item.supplier_name,item.supplier_code)">
                                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                            </b-button>
                            <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.id,item.supplier_name,item.supplier_code,item.supplier_type)">
                                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                            </b-button>
                            </template>
                        </template>
                    </b-table>
                    <b-modal
                        hide-footer
                        v-model="show"
                        size="auto"
                        title="Create Supplier"
                        id="modal-create"
                    >
                        <div>
                            <validation-observer ref="observer" v-slot="{handleSubmit}">
                                <b-form @submit.prevent="handleSubmit(onSubmit)" >
                                    <validation-provider
                                        name="SupplierCode"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Supplier Code
                                            </template>
                                            <b-form-input id="inputSupplierCode" v-model="form.supplierCode"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputSupplierCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        name="SupplierName"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Supplier Name
                                            </template>
                                            <b-form-input id="inputSupplierName" v-model="form.supplierName"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputSupplierName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        name="SupplierType"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Supplier Type
                                            </template>
                                            <b-form-select value-field="value" text-field="value" id="inputSupplierType" v-model="form.supplierType" :options="supplierTypeOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputSupplierType">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <b-button variant="warning" v-on:click="cancel()" block size="sm">Cancel</b-button>
                                    <b-button variant="success" type="submit" block size="sm">Save</b-button>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-modal>
                    <b-modal
                        hide-footer
                        v-model="showEdit"
                        size="auto"
                        title="Edit Supplier"
                        id="modal-edit"
                    >
                        <div>
                            <validation-observer ref="observer" v-slot="{handleSubmit}">
                                <b-form @submit.prevent="handleSubmit(onEdit)" >
                                    <validation-provider
                                        name="SupplierCode"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Supplier Code
                                            </template>
                                            <b-form-input id="inputSupplierCode" v-model="form.supplierCode"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputSupplierCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        name="SupplierName"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Supplier Name
                                            </template>
                                            <b-form-input id="inputSupplierName" v-model="form.supplierName"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputSupplierName">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <validation-provider
                                        name="SupplierType"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Supplier Type
                                            </template>
                                            <b-form-select value-field="value" text-field="value" id="inputSupplierType" v-model="form.supplierType" :options="supplierTypeOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputSupplierType">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                    <b-button variant="warning" v-on:click="cancel()" block size="sm">Cancel</b-button>
                                    <b-button variant="success" type="submit" block size="sm">Save</b-button>
                                </b-form>
                            </validation-observer>
                        </div>
                    </b-modal>
                </template>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import {mapActions,mapGetters} from 'vuex';
import apiSupplier from '@/store/product-form/product-supplier.js'
import apiParam from '@/store/product-form/master-param.js'
export default {
    data(){
        return{
            masterSupplierField:['actions','supplier_code','supplier_name','supplier_type'],
            masterSupplierData:[],
            form:{
                supplierCode:null,
                supplierName:null,
                supplierId:null,
                supplierType:null,
            },
            show:false,
            showEdit:false,
            supplierTypeOpt:[],
            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User']
        }
    },
    methods:{
        
        ...mapActions('session',['showNotification']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getMasterParam(){
            apiParam.dispatch('getListMasterParam')
            .then(response => {
                    // console.log(response);
                    this.supplierTypeOpt = response.data.payload.filter(d=>d.category ==='Supplier Type')
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })

        },
        getMasterSupplier(){
            apiSupplier.dispatch('GET_MASTER_SUPPLIER')
            .then(rsp=>{
                console.log(rsp);
                this.$set(this,'masterSupplierData',rsp)
            })
            .catch(err=>{
                return err
            })
        },
        onSubmit(){
            apiSupplier.dispatch('POST_MASTER_SUPPLIER',{
                supplier_name:this.form.supplierName,
                supplier_code:this.form.supplierCode,
                supplier_type:this.form.supplierType
            }).then(rsp=>{
                this.showNotification({type:'success', message:'Successfully Submitted'})
                this.getMasterSupplier()
                this.cancel()
            }).catch(err=>{
                return err
            })
        },
        cancel(){
            this.show = false
            this.showEdit = false
            this.form.supplierCode = null
            this.form.supplierName = null
            this.form.supplierId = null
            this.form.supplierType = null
        },
        editData(id,name,code,type){
            this.showEdit = true
            this.form.supplierCode = code
            this.form.supplierName = name
            this.form.supplierId = id
            this.form.supplierType = type
        },
        onEdit(){
            apiSupplier.dispatch('PUT_MASTER_SUPPLIER',{
                supplier_id: this.form.supplierId,
                supplier_name: this.form.supplierName,
                supplier_code: this.form.supplierCode,
                supplier_type: this.form.supplierType,
            }).then(rsp=>{
                this.showNotification({type:'success', message:'Successfully Edit'})
                this.getMasterSupplier()
                this.cancel()
            }).catch(err=>{
                return err
            })
        },
        onDelete(id,name,code){
            apiSupplier.dispatch('DELETE_MASTER_SUPPLIER',{
                supplier_id: id,
                supplier_name: name,
                supplier_code: code
            }).then(rsp=>{
                this.showNotification({type:'success', message:'Successfully Delete'})
                this.getMasterSupplier()
                this.cancel()
            }).catch(err=>{
                return err
            })
        }

    },
    created(){
        this.getMasterParam()
        this.getMasterSupplier()
    },
    computed:{
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
    }
}
</script>