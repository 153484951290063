<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-approval-activity-monthly">Approval Monthly Activity</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Approval Monthly Activity Plan" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3>Approval Monthly Activity Plan </h3>
                <router-link tag="b" to="/app/sfc-approval-activity-monthly" style="position: absolute; right: 15px;">
                  <b-button size="md" type='button' variant="info">
                    <b-icon icon="arrow-left"></b-icon>
                    Back
                  </b-button>
                </router-link>
              </div>

            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="NIK"
                      label-for="Employee_ID"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="Employee_ID"
                        :disabled="true"
                        v-model="form.employee_nik"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Sales Person"
                      label-for="EmployeeName"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="EmployeeName"
                        :disabled="true"
                        v-model="form.employee_name"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Plan ID"
                      label-for="ID_Transaction"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="ID_Transaction"
                        :disabled="true"
                        v-model="form.transaction_id"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-col sm='4'>
                  <b-form-group
                    label="Period"
                    label-for="Period"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <VueDatePicker
                      class="vd__div"
                      v-model="form.period"
                      no-header
                      :disabled="true"
                      type="month"
                      format="MMM YYYY"
                      :min-date="minMonthDate"
                      placeholder="Choose date"
                      />
                    </b-form-group>
                </b-col>
              </b-list-group-item>
            </b-list-group>
            <b-table
              :no-border-collapse="true"
              striped
              stacked="md"
              class="header-background max_width_fit"
              hover
              small
              :tbody-tr-class="rowClass"
              show-empty
              responsive
              :fields="fields"
              :items="dataTable"
            >
              <!-- table empty -->
              <template #empty="scope">
                  <p class="text-center">{{ scope.emptyText }}</p>
              </template>

              <!-- Est Employee -->
              <template #cell(est_employee)="row">
                  <span v-if="row.value !== null" v-html="FormatCommaNumber(row.value)"></span>
              </template>

              <!-- TENDER -->
              <template #cell(project_id)="row">
                  <span v-if="row.value !== null" v-html="NullTable(row.value)"></span>
              </template>

              <!-- TENDER -->
              <template #cell(status_tender)="row">
                  <span v-if="row.value !== null" v-html="CheckTender(row.value)"></span>
              </template>

              <!-- Status Approval -->
              <template #cell(approval_status)="row">
                  <span v-if="row.value !== null" v-html="FormatStatus(row.value)"></span>
              </template>

              <!-- <template v-slot:cell(actions)="{item}"> -->
              <template #cell(action)="row">
                <b-form-group>
                  <b-form-radio-group
                    v-model="selectedApprove[row.index]"
                    :options="optionsAction"
                    @change="onChange(row.item.id , row.item.customer, row.index, row.item.status_tender, row.item.project_id)"
                    value-field="value"
                    :disabled="row.item.approval_status == 'approved'"
                    :checked="isChecked"
                    text-field="name"
                  ></b-form-radio-group>
                  <span :id="'Check-'+row.index" class="display-hidden text-very-small text-danger">"Wajib di Isi"</span>
                </b-form-group>
              </template>
            </b-table>
          </b-card>

          <!-- Reason Not Ok -->
          <template>
            <b-modal
                v-model="showModal"
                ok-variant="success"
                cancel-variant="warning"
                header-bg-variant="secondary"
                hide-footer
                hide-header-close
                hide-backdrop
                size="md"
                no-close-on-backdrop
                title="Are you Sure Reject ?"

            >
                <div id="modal">
                  <validation-observer ref="observer" v-slot="{handleSubmit}">
                    <b-form @submit.prevent="handleSubmit(submitReject)" >
                      <b-row>
                        <b-col cols="12">
                            <b-form-group
                              label               ="Reason *"
                              label-class         = 'text-primary'
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-textarea
                              id="textarea-no-resize"
                              placeholder="Enter Your Reason"
                              v-model="ReasonReject"
                              rows="3"
                              ref="reasonModal"
                              no-resize
                            ></b-form-textarea>
                            <span id="ReasonWarrning" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                            </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row align-h="end">
                        <b-col sm='4'>
                          <b-button block v-if="buttonSubmit" size="md" @click="submitReject()" type='submit' variant="success">
                            Submit
                          </b-button>
                        </b-col>
                        <b-col sm='4'>
                          <b-button block v-if="buttonSubmit" size="md" @click="cancel()" type='button' variant="warning">
                            Cancel
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
            </b-modal>
          </template>

          <!-- Reason Rejected -->
          <template>
            <b-modal
                v-model="showModalReject"
                ok-variant="success"
                cancel-variant="warning"
                header-bg-variant="secondary"
                hide-footer
                hide-header-close
                hide-backdrop
                size="md"
                no-close-on-backdrop
                title="Are you Sure Reject ?"

            >
                <div id="modal">
                  <validation-observer ref="observer">
                    <b-form>
                      <b-row>
                        <b-col cols="12">
                            <b-form-group
                              label               ="Reason *"
                              label-class         = 'text-primary'
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-textarea
                              id="textarea-no-resize"
                              placeholder="Enter Your Reason"
                              v-model="Remark"
                              rows="3"
                              ref="reasonModal"
                              no-resize
                            ></b-form-textarea>
                            <span id="RemarkWarrning" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                            </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row align-h="end">
                        <b-col sm='4'>
                          <b-button block v-if="buttonSubmit" size="md" @click="ConfirmReject('rejected')" type='button' variant="success">
                            Submit
                          </b-button>
                        </b-col>
                        <b-col sm='4'>
                          <b-button block v-if="buttonSubmit" size="md" @click="cancelRejected()" type='button' variant="warning">
                            Cancel
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
            </b-modal>
          </template>

          <br />
          <b-row align-h="center">
              <b-col md='3'>
                <b-button block v-if="buttonSubmit" size="md" @click="Confirm('approved')" type='submit' variant="success">
                  Approve
                </b-button>
              </b-col>
              <b-col md='3'>
                <b-button block v-if="buttonSubmit" size="md" @click="RemarkReject()" type='submit' variant="danger">
                  Reject
                </b-button>
              </b-col>
          </b-row>

        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import apiMonthlyApproval from '@/store/salesforce-form/monthly-approval.js';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";

export default {
  components: {
    ModelListSelect,
    Loader,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'short'};
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "Approval Monthly Activity Plan",
      minMonthDate:null,
      showModal:false,
      showModalReject:false,
      today:today,
      isChecked:false,
      headerTitle:null,
      Remark:null,
      disabledForm:false,
      buttonSubmit:null,
      showHidden:true,
      today_days:days,
      today_month:month,
      StrMonth:Strmonth,
      selectedApprove:[],
      previousSelected:[],
      today_year:year,
      optionsAction:[
        { name: 'OK', value: 'ok' },
        { name: 'NOT OK', value: 'not_ok' },
      ],
      fields:[
          {key:'action', label:'Action', class:"display-hidden", sortable:false },
          {key:'approval_status', label:'Approval Status', class:"display-hidden", sortable:false },
          {key:'customer' ,class: 'display-hidden'},
          {key:'project_id', label:'project id', sortable:false },
          {key:'customer_name', label:'customer name', sortable:false },
          {key:'status_tender', label:'Tender', sortable:false },
          {key:'sector', label:'sector', sortable:false},
          {key:'sub_sector', label:'sub sector', sortable:false},
          {key:'est_employee', class:'', label:'Est Employee', tdClass: 'align_right', sortable:false},
          {key:'status', label:'Status', sortable:false },
          {key:'remark', label:'Reason', class:"display-hidden", sortable:false },
      ],
      dataTable:[],
      ReasonReject:null,
      ModalID:null,
      projectID:null,
      idDataModal:null,
      status_tender:null,
      MonthlyPlanning:null,
      dataTableApproval:[],
      form :{
        employee_nik:null,
        employee_name:null,
        period:null,
        transaction_id:null,
      },

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState('sales_force', ['stMnthApp','stMnthAppCount','stDtMnthApp','stDtMnthAppCount']),


  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions('general', ['fetchCityList']),
    ...mapActions('sales_force', ['getDashboardMonthlyApproval','getDataMonthlyApproval']),

    setData() {
      this.form.employee_nik    = this.userData.nik
      this.form.employee_name   = this.userData.fullname
      let statusRoute           = this.$route.params.page

      let StrMonth = this.today_month
      if (this.today_month.length == 1) {
        StrMonth = "0"+this.today_month
      }
      let Stryear = this.today_year.substring(2,4)
      this.projectID = 'PROJECT/'+Stryear+"/"+StrMonth

      if (this.$route.params.page == 'view' || this.$route.params.page == 'edit') {
        if (this.$route.params.page == 'edit') {
          this.buttonSubmit = true
          this.fields[0].class = ''
          this.fields[8].class = ''
        }
        if (this.$route.params.page == 'view') {
          this.buttonSubmit = false
          this.fields[1].class = ''
          this.fields[9].class = ''
        }
      } else {
        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
        this.$router.push({name:"SFCApprovalMonthly"})
        return
      }
      let idData                = this.$route.params.idData
      let yearMonth             = this.today_year+"-"+this.today_month
      this.minMonthDate         = this.today_year+"-"+this.today_month+"-"+"01"
      if (statusRoute == undefined || statusRoute =='' ) {
        statusRoute = null
      }

      // start loading
      this.isLoadingData = true

      this.getDashboardMonthlyApproval({
      id:idData,
      })
      .then(rsp => {
          if(this.stMnthAppCount == 0) {
              this.showNotification({type:'info', message:'Data Not Found'})
          }
          this.getDataMonthlyApproval({
            idData:idData,
            })
            .then(rsp => {
                // stop Loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);

                if(this.stDtMnthAppCount == 0) {
                    this.showNotification({type:'info', message:'Data Detail Not Found'})
                }
                this.setDataForm()
            })
            .catch(err => {
                // stop Loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);

                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                    this.$router.push({name:"SFCApprovalMonthly"})
                }
            })
      })
      .catch(err => {
            // stop Loading
            setTimeout(() => {
                this.isLoadingData = false
            }, 500);
          if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
              this.$router.push({name:"SFCApprovalMonthly"})
          }
      })
    },
    setDataForm() {
      let FormData = this.stMnthApp
      let FormDataDetail = this.stDtMnthApp
      this.form.period = FormData[0].period_date
      if (this.form.period == null || this.form.period == undefined) {
        // console.log(this.form.period)
      } else {
        let period_project = new Date(this.form.period)
        let month = period_project.getMonth() + 1
        let strmonth = period_project.getMonth() + 1
        let year = period_project.getFullYear()
        let Stryear = (String(year)).substring(2,4)
        if (String(month).length == 1) {
          strmonth = "0"+month
        }
        this.projectID = 'PROJECT/'+Stryear+"/"+strmonth
      }
      console.log(this.projectID)
      this.form.employee_nik = FormData[0].created_by
      this.form.transaction_id = FormData[0].transaction_id
      this.form.employee_name = FormData[0].sales_name
      this.MonthlyPlanning = FormData[0].monthly_planning
      this.dataTable = FormDataDetail
      if (FormDataDetail.length > 0) {
        this.dataTableApproval = FormDataDetail
      }
    },
    NullTable(value) {
      let result = value
      if (value == null || value == undefined || value == '') {
        result = '-'
      }
      return result
    },
    CheckTender(value) {
      let result = '-'
      if (value == 'close_tender') {
        result = 'Close Tender'
      }
      if (value == 'open_tender') {
        result = 'Open Tender'
      }
      return result;
    },
    FormatCommaNumber (param) {
        let format = param
        if (param == null || param == undefined || param == '') {
            format = null
        }
        else {
            // format = param.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            format = param.toString()
        }
        return format
    },
    rowClass(item, type) {
      let trClass= null
      if (item !== null) {
        if (item.approval_status == 'rejected' && this.$route.params.page == 'edit') {
          trClass = 'display-hidden'
        }
        // if (item.approval_status == 'rejected' && this.$route.params.page == 'view') {
        //   trClass = 'row-danger'
        // }
      }
      return trClass

    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    FormatStatus(value) {
        if (value.length > 0) {
            value = value.toLowerCase()
            if (value.includes('waiting')) {
                value = "Waiting Approval"
            }
            if (value.length > 1) {
                value = value.charAt(0).toUpperCase() + value.slice(1);
            }
        }
        return value
    },
    cancelRejected() {
      this.showModalReject = false
      this.Remark = null
    },
    cancel() {
      this.selectedApprove[this.ModalID] = null
      this.showModal = false
      this.isChecked = true
      this.ReasonReject = null
    },
    onChange (item_id, customer, index, tender, project_id) {
      // console.log(project_id)
      this.isChecked = false
      this.ModalID = index
      this.idDataModal = item_id
      this.status_tender = tender
      this.idCustomerModal = customer
      if (this.selectedApprove[index] == 'not_ok') {
        this.ReasonReject = null
        this.showModal = true
      } else {
        this.dataTableApproval[this.ModalID] = ({
          remark:null,
          project_id:project_id,
          approval_status:'approved',
          approval_id:this.idDataModal,
          customer:this.idCustomerModal,
          status_tender:this.status_tender,
        })
      }
    },
    submitReject() {
      if (this.ReasonReject == null || this.ReasonReject == undefined || this.ReasonReject.substring(0, 1) == ' ' || this.ReasonReject.substring(0, 1) == '') {
        document.getElementById("ReasonWarrning").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("ReasonWarrning").classList.add("display-hidden");
          }, 5000);
          return false
      }
      this.dataTableApproval[this.ModalID] = ({
        remark:this.ReasonReject,
        approval_status:'rejected',
        approval_id:this.idDataModal,
        customer:this.idCustomerModal,
        status_tender:this.status_tender,
      })
      this.showModal = false
    },
    ConfirmReject() {
      if (this.Remark == null || this.Remark == undefined || this.Remark.substring(0, 1) == ' ' || this.Remark.substring(0, 1) == '') {
        document.getElementById("RemarkWarrning").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("RemarkWarrning").classList.add("display-hidden");
          }, 5000);
          return false
      }
      this.Confirm('rejected')
    },
    CheckFormChoice() {
      let process = true
      let len = this.dataTable.length
      for (let a=0; a<=len-1; a++) {
        if (this.dataTable[a].approval_status == 'waiting_approval') {
          if (this.selectedApprove[a] == null || this.selectedApprove[a] == undefined) {
            document.getElementById("Check-"+a).classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("Check-"+a).classList.add("display-hidden");
            }, 5000);
            process = false
          }
        }
      }
      return process
    },
    RemarkReject() {
      this.Remark = null
      this.showModalReject = true

    },
    Confirm(choice, index) {
      let varOk = 'danger'
      if(choice !== 'delete') {
        varOk = 'success'
      }
      let Boxtrue = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to '+choice+' ? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: varOk,
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          Boxtrue = value
          if (Boxtrue == true) {
            if (this.CheckFormChoice()) {
              this.SaveApproval(choice)
            }
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    SaveApproval(choice) {
      // start loading
      this.isLoadingData = true

      apiMonthlyApproval.dispatch('MonthlyApproval',{
        monthly_approval:this.$route.params.idData,
        monthly_planning:this.MonthlyPlanning,
        approval_status:choice,
        project_id:this.projectID,
        remark:this.Remark,
        sales_nik:this.form.employee_nik,
        sales_name:this.form.employee_name,
        data:this.dataTableApproval,
      })
      .then(response => {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCApprovalMonthly"})
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=> {
        // stop Loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
  },

  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />