<template>
    <div>
        <b-card>
            <b-row>
                <b-col cols="12" sm="auto" md="3">
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Brand<code>*</code>
                        </template>
                            <label> {{productDetail[0].brand}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Collection Name<code>*</code>
                        </template>
                            <label> {{productDetail[0].collection_name}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Year<code>*</code>
                        </template>
                            <label> {{productDetail[0].year}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Quartal<code>*</code>
                        </template>
                            <label> {{productDetail[0].quartal}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="6"  
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Prod. Month<code>*</code>
                        </template>
                            <label> {{productDetail[0].prod_month}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Coll. Month<code>*</code>
                        </template>
                            <label> {{productDetail[0].coll_month}}</label>
                    </b-form-group>
                    
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Sub. Brand<code>*</code>
                        </template>
                            <label> {{productDetail[0].subbrand}}</label>
                    </b-form-group>
                    
                </b-col>
                <b-col cols="12" sm="auto" md="3">
                    <b-form-group
                        
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Article<code>*</code>
                        </template>
                            <label> {{productDetail[0].article}}</label>
                    </b-form-group>

                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Repeat Sample<code>*</code>
                        </template>
                            <label> {{productDetail[0].repeat_sample}}</label>
                    </b-form-group>
                    
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Ref. Sample<code>*</code>
                        </template>
                            <label> {{productDetail[0].ref_sample}}</label>
                    </b-form-group>
                    
                    <b-form-group
                        label-cols-lg   ="6" 
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Old Sample No.<code>*</code>
                        </template>
                            <label> {{productDetail[0].old_sample_no}}</label>
                    </b-form-group>

                    <b-form-group
                        label-cols-lg   ="6"
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Guidance<code>*</code>
                        </template>
                            <label> {{productDetail[0].guidance}}</label>
                    </b-form-group>
                    <b-form-group
                        label-cols-lg   ="6"
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Size Spec<code>*</code>
                        </template>
                            <label> {{productDetail[0].size_spec}}</label>
                    </b-form-group>
                    
                    <b-form-group
                        label-cols-lg   ="6"
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Category<code>*</code>
                        </template>
                            <label> {{productDetail[0].category}}</label>
                    </b-form-group>

                    <b-form-group
                        label-cols-lg   ="6"
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Note<code>*</code>
                        </template>
                            <label> {{productDetail[0].note}}</label>
                    </b-form-group>
                    
                </b-col>
                <b-col cols="12" sm="auto" md="auto">
                    <b-form-group
                        
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Design<code>*</code>
                        </template>
                        <div>
                            <b-img v-bind="mainProps" thumbnail fluid :src="productDetail[0].design_img_url" :alt="productDetail[0].design_img_name.substring(0,26)"></b-img>
                            <!-- <p class="small">
                                <a :href="productDetail[0].design_img_url">{{productDetail[0].design_img_name.substring(0,26)}}</a>
                            </p> -->
                        </div>
                    </b-form-group>
                    
                    <b-form-group
                        
                        label-size      ="sm"
                    >
                        <template v-slot:label>
                            Technical Drawing
                        </template>
                        <div>
                            <b-img v-bind="mainProps" thumbnail fluid :src="productDetail[0].technical_img_url" :alt="productDetail[0].technical_img_name.substring(0,26)"></b-img>
                            <!-- <p class="small">
                                <a :href="productDetail[0].technical_img_url">{{productDetail[0].technical_img_name.substring(0,26)}}</a>
                            </p> -->
                        </div>
                    </b-form-group>
                    
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
export default {
    data(){
        return{
            mainProps: {width: 75, height: 75, class: 'm1' }
        }
    },
    computed:{
        ...mapGetters('productDesign',['productDetail']),
        
    }
}
</script>