import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchHealthPemantauanAccess
} from '../../api/api-applications-health.js'

Vue.use(Vuex)


const actions = {
    postPemantauan(context, formPemantauan) {
      return new Promise((resolve, reject) => {
        fnApiFetchHealthPemantauanAccess(formPemantauan)
            .then(response => {
                
                resolve(response)
            })
            .catch(error => {
                
                reject(error)
            })
      })
    }
  }

export default new Vuex.Store({
    namespaced: true,
    actions,
})
