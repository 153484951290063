export const APIPATH_GENERAL = {
  serverTime: "/v1/api/server-time/",
  cityList: "/v1/api/city-list/",
  chkVersionApp: "/v1/api/check-version-app/",
  errorLog : "/api/error-log/",
};

export const APIPATH_BBIAPPS = {
  appAccess: "/v1/api/app-list/",
  appAccessSmg: "/api/app-list-smg/",
  rptAccess: "/v1/api/rpt-list/",
  rptAccessSmg: "/api/rpt-list-smg/",
  auth: "/core/api/token/",
  chPass: "/core/api/account/change-password/",
  refreshToken: "/v1/api/refresh-token/",
  refreshTokenV2: "/api/refresh-token/",
  listBU: "/core/api/active-bu/",
  listDepartment: "/v1/api/active-dept/",
  listOrgPath: "/core/api/active-org-path/",
  listDepartmentSmg: "/api/dept-smg/",
  listValidDepartmentSmg: "/api/valid-dept-smg/",
  listSiteName: "/v1/api/site-name/",
  backdoorReset: "/core/api/account/backdoor-reset/",
  // TBD - Rearrange later
  listPenerimaVaksin: "v1/api/penerima-vaksin/",
  listJenisVaksin: "v1/api/jenis-vaksin/",
  userPersonalData: "/v1/api/bbi-forms/get-user-personal-data",
  categoryEmpVaksin: "/v1/api/category-report-vaksin/",
  categoryEmpVaksinV2: "/v1/api/category-report-vaksin-v2/",
  typeDocDMS: "/v1/api/type-doc-dms/",
  mimeTypeDocDMS: "/v1/api/mimetype-doc-dms/",

  // appAccessRoleList
  appRoleList: "/core/api/bbi-appacc-list/",
  // rptAccessRoleList
  rptRoleList: "/core/api/bbi-rptacc-list/",

  listType: "/v1/api/type-legal-dms/",

  userAll: "/v1/api/get-user-all/",
};

export const APIPATH_HR = {
  // APPS - FORMS
  healthDaily: "v1/api/bbi-forms/save-report-harian",
  healthDailyV2: "v1/api/bbi-forms/save-report-harian-v2",
  healthDailyV3: "v1/api/bbi-forms/save-report-harian-v3",
  // healthDailyV4: "v1/api/bbi-forms/save-report-harian-v8",
  healthDailyV4: "v1/api/bbi-forms/save-report-harian-v4",
  paramHealthDailyV4: "v1/api/bbi-forms/param-daily-health-v4",

  healthDailyVerif: "v1/api/bbi-forms/save-verification-report-harian-v2",
  healthDeclaration: "v1/api/bbi-forms/save-deklarasi-kesehatan",
  healthPemantauan: "v1/api/bbi-forms/save-rapid-test",
  healthSelfDeclaration: "v1/api/bbi-forms/save-deklarasi-kesehatan-v2",
  healthHistory: "v1/api/bbi-forms/save-health-history",
  healthHistoryV2: "v1/api/bbi-forms/save-health-history-v2",
  vaccineHistory: "v1/api/bbi-forms/save-riwayat-vaksin",
  getVaccineHistory: "v1/api/bbi-forms/get-riwayat-vaksin",
  dateVocid19V3: "v1/api/bbi-forms/get-datacovid-harian-v3",
  getpersonaldata: "v1/api/bbi-forms/get-user-personal-data",
  // REPORTS
  rptDataDailyHealth: "/v1/api/bbi-reports/daily-health-report/",
  rptDataDailyHealthV2: "/v1/api/bbi-reports/daily-health-report-v2/",
  rptDataDailyHealthV3: "/v1/api/bbi-reports/daily-health-report-v3/",
  rptDataDailyHealthV4: "/v1/api/bbi-reports/daily-health-report-v4/",
  rptDataDailyHealthV3Location:
    "/v1/api/bbi-reports/daily-health-report-v3-location/",
  rptDataDailyHealthVerification:
    "/v1/api/bbi-reports/daily-health-report-v2-verification/",
  rptDataHealthDeclaration: "/v1/api/bbi-reports/health-declaration-report/",
  rptDataHealthDeclarationV2:
    "/v1/api/bbi-reports/health-declaration-report-v2/",
  rptDataRapidTest: "/v1/api/bbi-reports/rapid-test-report/",
  rptDataHealthHistory: "/v1/api/bbi-reports/health-history-report/",
  rptDataHealthHistoryV2: "/v1/api/bbi-reports/health-history-report-v2/",
  rptVaccineHistory1: "/v1/api/bbi-reports/vaccine-history-report-1/",
  rptVaccineHistory2: "/v1/api/bbi-reports/vaccine-history-report-2/",
  rptVaccineHistory2V2: "/v1/api/bbi-reports/vaccine-history-report-2-v2/",
};

export const APIPATH_SALES = {
  pivotRptDataSalesReport: "/v1/api/bbi-reports/sales/daily-sales-report/",
  pivotRptProductivityReport: "/v1/api/bbi-reports/sales/daily-sales-report/",
  pivotRptBrandReport: "/v1/api/bbi-reports/sales/daily-sales-report/",
};

export const APIPATH_DMS = {
  dmsDocumentV1: "/v1/api/bbi-dms/save-dms-document",
  getdataDMSV1: "/v1/api/bbi-dms/get-dms-document",
  filterDMSV1: "/v1/api/bbi-dms/filter-dms-document",
  editStatusDMSv1: "/v1/api/bbi-dms/edit-status-dms",
  deleteDMSDocV1: "/v1/api/bbi-dms/delete-dms-document",
  getDMSFileV1: "/v1/api/bbi-dms/get-file-dms",
  getDeptList: "/v1/api/bbi-dms/get-dept-list",
  getDeptGroup: "/v1/api/bbi-dms/get-dept-group",

  // master permit legal
  getPermit: "/v1/api/bbi-dms/legal/get-permit-type",
  postPermit: "/v1/api/bbi-dms/legal/post-permit-type",
  putPermit: "/v1/api/bbi-dms/legal/put-permit-type",
  deletePermit: "/v1/api/bbi-dms/legal/delete-permit-type",

  // master institution legal
  getInstitution: "/v1/api/bbi-dms/legal/get-institution",
  postInstitution: "/v1/api/bbi-dms/legal/post-institution",
  putInstitution: "/v1/api/bbi-dms/legal/put-institution",
  deleteInstitution: "/v1/api/bbi-dms/legal/delete-institution",

  // master level legal
  getLevel: "/v1/api/bbi-dms/legal/get-level",
  postLevel: "/v1/api/bbi-dms/legal/post-level",
  putLevel: "/v1/api/bbi-dms/legal/put-level",
  deleteLevel: "/v1/api/bbi-dms/legal/delete-level",

  // master department legal
  getDept: "/v1/api/bbi-dms/legal/get-department",
  postDept: "/v1/api/bbi-dms/legal/post-department",
  putDept: "/v1/api/bbi-dms/legal/put-department",
  deleteDept: "/v1/api/bbi-dms/legal/delete-department",
  getDataPicDept: "/v1/api/bbi-dms/legal/get-data-pic-dept",

  // master level legal
  getCategory: "/v1/api/bbi-dms/legal/get-category",
  postCategory: "/v1/api/bbi-dms/legal/post-category",
  putCategory: "/v1/api/bbi-dms/legal/put-category",
  deleteCategory: "/v1/api/bbi-dms/legal/delete-category",

  // master reminder legal
  getReminder: "/v1/api/bbi-dms/legal/get-reminder",
  postReminder: "/v1/api/bbi-dms/legal/post-reminder",
  putReminder: "/v1/api/bbi-dms/legal/put-reminder",
  deleteReminder: "/v1/api/bbi-dms/legal/delete-reminder",

  // master location legal
  getLocation: "/v1/api/bbi-dms/legal/get-location",
  postLocation: "/v1/api/bbi-dms/legal/post-location",
  putLocation: "/v1/api/bbi-dms/legal/put-location",
  deleteLocation: "/v1/api/bbi-dms/legal/delete-location",

  // document legal
  getDocumentLegal: "/v1/api/bbi-dms/legal/get-document-legal",
  postDocumentLegal: "/v1/api/bbi-dms/legal/post-document-legal",
  putDocumentLegal: "/v1/api/bbi-dms/legal/put-document-legal",
  deleteDocumentLegal: "/v1/api/bbi-dms/legal/delete-document-legal",
  getDocumentLegalDetail: "/v1/api/bbi-dms/legal/detail-document-legal",
  getDocumentFile: "/v1/api/bbi-dms/legal/get-document-file",

  // document reminder
  getDocumentReminder: "/v1/api/bbi-dms/legal/get-document-reminder",

  // Department Mapping
  DepartmentMapping: "/v1/api/bbi-dms/legal/department-mapping",
  DepartmentPIC: "/v1/api/bbi-dms/legal/department-pic",
};

export const APIPATH_PNL = {
  pnlRptDetail: "/v1/api/bbi-reports/pnl/detail/",
  pnlRptSummary: "/v1/api/bbi-reports/pnl/summary/",
  pnlRptConfig: "/v1/api/bbi-reports/pnl/report-config/",
};

export const APIPATH_WBS = {
  datawbs: "/api/viewset/data-wbs/",

  uploadfilewbs: "/api/bbi-wbs/uploadfilewbs",
  uploadfilewbsmultiple: "/api/bbi-wbs/uploadfilewbsmultiple",
  getcontactwbs: "/api/bbi-wbs/contact",

  postdataemail: "/api/bbi-notification/post-data-email",
  sentemail: "/api/bbi-notification/send-email",

  sentconfirmemail: "api/bbi-notification/send-confirm-email",
  generateotp: "/api/bbi-notification/generate-otp",
  confirmotp: "/api/bbi-notification/confirm-otp",

  getusingemailotp: "/api/bbi-notification/getusingemailotp",
};

//PLM Prodev
export const APIPATH_PLM = {
  getMasterMaterialImages: "/v1/api/bbi-plm/get-master-material-images",
  dropProdev: "/v1/api/bbi-plm/drop-product-material",
  getGarmentDashboard: "/v1/api/bbi-plm/garment-dev-detail",
  postGarmentDevDetail: "/v1/api/bbi-plm/post-garment-dev-detail",
  getGarmentDevDetail: "/v1/api/bbi-plm/garmentdev-detail",
  postGarmentDevActivity: "/v1/api/bbi-plm/post-garment-dev-activity",
  postGarmentDevActivityFile: "/v1/api/bbi-plm/post-garment-activity-file",
  getGarmentDevActivity: "/v1/api/bbi-plm/activity-dev-garment",
  getGarmentChatActivityAll: "/v1/api/bbi-plm/activity-chat-garment-all",
  postGarmentChatActivity: "v1/api/bbi-plm/activity-chat-garment",
  getMaterialChatActivityAll: "/v1/api/bbi-plm/activity-chat-material-all",
  postMaterialChatActivity: "v1/api/bbi-plm/activity-chat-material",
};

//Workflow
export const APIPATH_WF = {
  inboxwf: "/workflow/api/inbox-wf/",
  outboxwf: "/workflow/api/outbox-wf/",
  closedwf: "/workflow/api/closed-wf/",
  countwf: "/workflow/api/count-wf/",
};

export const APIPATH_SALESFORCE = {
  reviewSalesRecap: "/v1/api/bbi-sales-force/review-sales-recap/",
  getAllCustomer: "/v1/api/bbi-sales-force/get-all-customer/",
  getListProject: "/v1/api/bbi-sales-force/check-list-project/",
  getListActualProject: "/v1/api/bbi-sales-force/check-list-actual-project/",
  closeProject: "/v1/api/bbi-sales-force/close-project/",
  abortProject: "/v1/api/bbi-sales-force/abort-project/",

  getSalesGroup: "/v1/api/bbi-sales-force/sales-group/",
  getSalesPerson: "/v1/api/bbi-sales-force/list-sales-detail/",

  // Sales Projection
  masterProductCategory: "/v1/api/bbi-sales-force/get-master-product-category/",
  masterStatusCustomer: "/v1/api/bbi-sales-force/get-master-status-customer/",

  getSalesProjection: "/v1/api/bbi-sales-force/get-data-sales-projection/",
  getSalesProjectionDetail:
    "/v1/api/bbi-sales-force/get-data-sales-projection-detail/",

  getReportSalesProjection:
    "/v1/api/bbi-sales-force/get-report-sales-projection/",

  saveSalesProjection: "/v1/api/bbi-sales-force/save-sales-projection/",

  getCustomerGroup: "/v1/api/bbi-sales-force/get-customer-group/",
  listCustomerApproval: "/v1/api/bbi-sales-force/list-master-customer-approval/",
  editMasterCustomer: "/v1/api/bbi-sales-force/edit-master-customer/",
  addGroupCustomer: "/v1/api/bbi-sales-force/add-group-customer/"
};
