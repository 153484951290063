import { reject, resolve } from 'core-js/fn/promise'
import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchMaterialMaster,
    fnApiFetchMaterialMasterFabric,
    fnApiPostMaterialMaster,
    fnApiPostMaterialImages,
    fnApiFetchMasterFabric,
    fnApiPostMasterFabric,
    fnApiFetchMasterAcc,
    fnApiPostMasterAcc,
    fnApiFetchMasterThread,
    fnApiPostMasterThread,
    fnApiFetchMasterArtwork,
    fnApiPostMasterArtwork,
    fnApiFetchMasterInterlining,
    fnApiPostMasterInterlining,
    fnApiPutMaterialMaster,
    fnApiPutMaterialImages,
    fnApiDeleteMaterialMaster,
}from '../../api/api-aplications-product.js'



Vue.use(Vuex)

const getDefaultState = () => {
    return {
        stMaterialMaster:[],
        stMasterFabric:[],
        stMasterAcc:[],
        stMasterThread:[],
        stMasterArtwork:[],
        stMasterInterlining:[],
    }
}

const state = getDefaultState()

const getters = {
    MASTER_MATERIAL(state){
        return state.stMaterialMaster
    },
    masterFabric(state){
        return state.stMasterFabric
    },
    masterAcc(state){
        return state.stMasterAcc
    },
    masterThread(state){
        return state.stMasterThread
    },
    masterArtwork(state){
        return state.stMasterArtwork
    },
    masterInterlining(state){
        return state.stMasterInterlining
    }
}

const mutations = {
    editMaster(state,item){
        state["stMaster"+item.formname] = state["stMaster"+item.formname][item.id]
    },
    addMaster(state,item){
        state["stMaster"+item.formname].push(item)
    },
    updateMaster(state,item){
        Object.assign(state["stMaster"+item.formname][item.id],item)
    },
    deleteMaster(state,item){
        state["stMaster"+item.formname].splice(item.id,1)
    },
    getMasterFabric(state,item){
        state.stMasterFabric = item
    },
    getMasterAcc(state,item){
        state.stMasterAcc = item
    },
    getMasterThread(state,item){
        state.stMasterThread = item
    },
    getMasterArtwork(state,item){
        state.stMasterArtwork = item
    },
    getMasterInterlining(state,item){
        state.stMasterInterlining = item
    },
    GET_MASTER_MATERIAL(state,item){
        state.stMaterialMaster = item
    }
}

const actions = {
    GET_MASTER_MATERIAL({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMaterialMaster()
            .then(response => {
                resolve(response)
                commit('GET_MASTER_MATERIAL', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_MASTER_MATERIAL(context, formMasterMaterial){
        return new Promise((resolve,reject)=>{
            fnApiPostMaterialMaster(formMasterMaterial)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    GET_MASTER_MATERIAL_FABRIC(context, formMasterMaterial){
        return new Promise((resolve,reject)=>{
            fnApiFetchMaterialMasterFabric(formMasterMaterial)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    PUT_MASTER_MATERIAL(context, formMasterMaterial){
        console.log('vuex',formMasterMaterial);
        return new Promise((resolve,reject)=>{
            fnApiPutMaterialMaster(formMasterMaterial)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    DELETE_MASTER_MATERIAL(context,formMasterMaterial){
        return new Promise((resolve,reject)=>{
            fnApiDeleteMaterialMaster(formMasterMaterial)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_MATERIAL_IMAGES(context,formMaterialImages){
        return new Promise((resolve,reject)=>{
            console.log('action',formMaterialImages);
            fnApiPostMaterialImages(formMaterialImages)
            .then(response => {
                resolve(response)
            })
            .then(error => {
                reject(error)
            })
        })
    },

    PUT_MATERIAL_IMAGES(context,formMaterialImages){
        return new Promise((resolve,reject)=>{
            // console.log('action',formMaterialImages);
            fnApiPutMaterialImages(formMaterialImages)
            .then(response => {
                resolve(response)
            })
            .then(error => {
                reject(error)
            })
        })
    },

    getMasterFabric({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMasterFabric()
            .then(response => {
                resolve(response)
                commit('getMasterFabric', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    postMasterFabric(context, formMasterFabric){
        console.log('post fabric',formMasterFabric)
        return new Promise((resolve, reject) => {
            fnApiPostMasterFabric(formMasterFabric)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    getMasterAcc({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMasterAcc()
            .then(response => {
                
                resolve(response)
                commit('getMasterAcc', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    postMasterAcc(context, formMasterAcc){
        console.log(formMasterAcc)
        return new Promise((resolve, reject) => {
            fnApiPostMasterAcc(formMasterAcc)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    getMasterArtwork({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMasterArtwork()
            .then(response => {
                
                resolve(response)
                commit('getMasterArtwork', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    postMasterArtwork(context, formMasterArtwork){
        console.log(formMasterArtwork)
        return new Promise((resolve, reject) => {
            fnApiPostMasterArtwork(formMasterArtwork)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    getMasterThread({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMasterThread()
            .then(response => {
                resolve(response)
                commit('getMasterThread', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    postMasterThread(context, formMasterThread){
        console.log(formMasterThread)
        return new Promise((resolve, reject) => {
            fnApiPostMasterThread(formMasterThread)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    getMasterInterlining({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMasterInterlining()
            .then(response => {
                resolve(response)
                commit('getMasterInterlining', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    postMasterInterlining(context, formMasterInterlining){
        console.log(formMasterInterlining)
        return new Promise((resolve, reject) => {
            fnApiPostMasterInterlining(formMasterInterlining)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    addMaster({commit},item){
        commit("addMaster",item)
    },
    editMaster({commit},item){
        commit("editMaster",item)
    },
    updateMaster({commit},item){
        commit("updateMaster",item)
    },
    deleteMaster({commit},item){
        commit("deleteMaster",item)
    },
}

export default{
    namespaced:true,
    state,
    getters,
    mutations,
    actions
}