import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchPostMonthlyPlanning,
    fnApiFetchEditMonthlyPlanning,
}from '../../api/api-applications-salesforce.js'

Vue.use(Vuex)

const actions = {
    PostMonthlyPlanning(context, formMonthlyPlanning){
        return new Promise((resolve, reject) => {
            fnApiFetchPostMonthlyPlanning(formMonthlyPlanning)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    EditMonthlyPlanning(context, formMonthlyPlanning){
        return new Promise((resolve, reject) => {
            fnApiFetchEditMonthlyPlanning(formMonthlyPlanning)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
}

export default new Vuex.Store({
    namespaced: true,
    actions
})