<template>
  <router-view />
</template>

<script>
import { checkCookie } from "@/store/cookies.js"
export default {
  name: 'App',
  created() {
    checkCookie('userData')
    const currentPath = this.$router.history.current.path;
    // console.log(this.$router.history.current.path)

    // if (window.localStorage.getItem('authenticated') === 'false') {
    //   this.$router.push('/login');
    // }

    if (currentPath === '/' || currentPath === '/app') {
      this.$router.push('/app/dashboard');
    }
  },
  mounted: function () {

  },
  methods: {
  }
};
</script>

<style src="./styles/theme.scss" lang="scss" />
