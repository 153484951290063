
import { reject, resolve } from 'core-js/fn/promise'
import { getAPI,apiMultimedia, apiPlm } from './api-base'

const apiPath = {
    masterParamList     : '/api/bbi-plm/master-param',
    masterParam         : '/api/bbi-plm/save-master-param',
    masterParamUpdate   : '/api/bbi-plm/update-master-param',
    masterParamDelete   : '/api/bbi-plm/delete-master-param',
    productDesignList   : '/api/bbi-plm/product-dev',
    productDesignDash   : '/api/bbi-plm/product-dashboard',
    productDesignDetail : '/api/bbi-plm/product-detail',
    productUpdateDetail : '/api/bbi-plm/update-product-detail',
    productImagesDetail : '/api/bbi-plm/images-product-detail',
    productDesignSave   : '/api/bbi-plm/save-product-dev',
    productImagesSave   : '/api/bbi-plm/save-product-images',
    productImagesUpdate : '/api/bbi-plm/update-product-images',
    productImagesDelete : '/api/bbi-plm/delete-product-images',
    productMaterial     : '/api/bbi-plm/product-material',
    productMaterialSave : '/api/bbi-plm/save-product-material',
    productMaterialApprove  : '/api/bbi-plm/approve-product-material',
    productMaterialUpdate : '/api/bbi-plm/status-product-material',
    productMaterialHistory: '/api/bbi-plm/material-history',
    productDesignDetailSave   : '/api/bbi-plm/save-product-design-detail',
    productSample       : '/api/bbi-plm/product-sample',
    masterMaterial      : '/api/bbi-plm/master-material',
    masterMaterialFabric      : '/api/bbi-plm/master-material-fabric',
    saveMasterMaterial  : '/api/bbi-plm/post-master-material',
    saveMaterialImages  : '/api/bbi-plm/post-master-material-images',
    updateMaterialImages    : '/api/bbi-plm/put-master-material-images',
    masterFabric            : '/api/bbi-plm/master-fabric',
    saveMasterFabric        : '/api/bbi-plm/post-master-fabric',
    masterAcc               : '/api/bbi-plm/master-accessories',
    saveMasterAcc           : '/api/bbi-plm/post-master-accessories',
    masterThread            : '/api/bbi-plm/master-thread',
    saveMasterThread        : '/api/bbi-plm/post-master-thread',
    masterArtwork           : '/api/bbi-plm/master-artwork',
    saveMasterArtwork       : '/api/bbi-plm/post-master-artwork',
    masterInterlining       : '/api/bbi-plm/master-interlining',
    saveMasterInterlining   : '/api/bbi-plm/post-master-interlining',

    // material development
    materialDevDashboard    : '/api/bbi-plm/material-dev-dashboard',
    materialDevDetail       : '/api/bbi-plm/material-dev-detail',
    saveMaterialDevDetail   : '/api/bbi-plm/post-material-dev-detail',
    materialDevActivity     : '/api/bbi-plm/material-dev-activity',
    saveMaterialDevActivity : '/api/bbi-plm/post-material-dev-activity',
    saveActivityFiles       : '/api/bbi-plm/post-material-activity-file',



    updateMasterMaterial    : '/api/bbi-plm/put-master-material',
    deleteMasterMaterial    : '/api/bbi-plm/delete-master-material',
    saveProductApprove      : '/api/bbi-plm/save-product-approve',
    productApprove          : '/api/bbi-plm/product-approve',
    materialApprove         : '/api/bbi-plm/material-approve',
    saveMaterialApprove     : '/api/bbi-plm/save-material-approve',

    // size spec
    sizeSpec                : '/api/bbi-plm/size-spec',
    saveSizeSpecHeader      : '/api/bbi-plm/post-size-spec-header',
    updateSizeSpecHeader    : '/api/bbi-plm/put-size-spec-header',
    saveSizeSpecDetail      : '/api/bbi-plm/post-size-spec-detail',

    // supplier selection
    productSupplier         : '/api/bbi-plm/product-supplier',
    saveProductSupplier     : '/api/bbi-plm/post-product-supplier',

    // product costing
    productCosting          : '/api/bbi-plm/product-costing',
    saveProductCosting      : '/api/bbi-plm/post-product-costing',

    // product process
    productProcess          : '/api/bbi-plm/product-process',
    saveProductProcess      : '/api/bbi-plm/post-product-process',
    updateProductProcess    : '/api/bbi-plm/put-product-process',

    // master supplier
    masterSupplier          : '/api/bbi-plm/master-supplier',
    postMasterSupplier      : '/api/bbi-plm/post-master-supplier',
    putMasterSupplier       : '/api/bbi-plm/put-master-supplier',
    deleteMasterSupplier    : '/api/bbi-plm/delete-master-supplier',



}

// -- master supplier
export function fnApiFetchMasterSupplier(){
    return new Promise((resolve,reject)=>{
        apiPlm.get(apiPath.masterSupplier)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}

export function fnApiPostMasterSupplier(masterSupplier){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.postMasterSupplier,masterSupplier)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}

export function fnApiPutMasterSupplier(masterSupplier){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.putMasterSupplier,masterSupplier)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}

export function fnApiDeleteMasterSupplier(masterSupplier){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.deleteMasterSupplier,masterSupplier)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}



// -- product detail
export function fnApiPutProductMaterial(productMaterial){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.productMaterialUpdate,productMaterial)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}


export function fnApiPutProductImages(productImages){
    return new Promise((resolve,reject)=>{
        apiMultimedia.post(apiPath.productImagesUpdate,productImages)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiDeleteProductImages(productImages){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.productImagesDelete,productImages)
        .then(rsp =>{
            {resolve(rsp)}
        }).catch(err=>{
            {reject(err)}
        })
    })
}

export function fnApiPostProductDetail(productDetail){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.productDesignDetail,productDetail)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

export function fnApiPutProductDetail(productDetail){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.productUpdateDetail,productDetail)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}

export function fnApiFetchProductImages(productId){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.productImagesDetail,productId)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}



// -- product process
export function fnApiFetchProductProcess(productArticle){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.productProcess,productArticle)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

export function fnApiPostProductProcess(productArticle){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.saveProductProcess,productArticle)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

export function fnApiPutProductProcess(productArticle){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.productProcess,productArticle)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}



// -- approval product material
export function fnApiPutProductMaterialApprove(approveProdMat){
    return new Promise((resolve, reject)=>{
        apiPlm.put(apiPath.productMaterialApprove,approveProdMat)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

export function fnApiProductMaterialApprove(approveMaterial){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.materialApprove,approveMaterial)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}

export function fnApiMaterialHistory(approveMaterial){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.productMaterialHistory,approveMaterial)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}

export function fnApiPostProductMaterialApprove(approveMaterial){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.saveMaterialApprove,approveMaterial)
        .then(rsp=>{resolve(rsp)})
        .catch(err=>{reject(err)})
    })
}

// -- material development
export function fnApiFetchMaterialDevDashboard(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.materialDevDashboard)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}
export function fnApiFetchMaterialDevDetail(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.materialDevDetail)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
export function fnApiPostMaterialDevDetail(MaterialDevDetail){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveMaterialDevDetail,MaterialDevDetail)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}
export function fnApiFetchMaterialDevActivity(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.materialDevActivity)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}
export function fnApiPostMaterialDevActivity(MaterialDevActivity){
    return new Promise((resolve,reject)=>{
        apiPlm.post(apiPath.saveMaterialDevActivity,MaterialDevActivity)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPostMaterialActivityFiles(formActivityFiles){
    return new Promise((resolve,reject)=>{
        apiMultimedia.post(apiPath.saveActivityFiles,formActivityFiles)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}
// material development --

export function fnApiFetchProductApprove(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.productApprove)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPostProductApprove(formProductApprove){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveProductApprove,formProductApprove)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchProductMaterial(){
    return new Promise((resolve, reject)=>{
        apiMultimedia.get(apiPath.productMaterial)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}



export function fnApiFetchMaterialMaster(){
    return new Promise((resolve, reject)=>{
        apiMultimedia.get(apiPath.masterMaterial)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPutMaterialMaster(formMaterialMaster){
    return new Promise((resolve,reject)=>{
        apiPlm.put(apiPath.updateMasterMaterial,formMaterialMaster)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchMaterialMasterFabric(formMaterialMaster){
    return new Promise((resolve,reject)=>{
        // apiMultimedia.post(apiPath.masterMaterialFabric,formMaterialMaster)
        apiPlm.post(apiPath.masterMaterialFabric,formMaterialMaster)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiDeleteMaterialMaster(formMaterialMaster){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.deleteMasterMaterial,formMaterialMaster)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPostMaterialMaster(formMaterialMaster){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveMasterMaterial,formMaterialMaster)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPostMaterialImages(formMaterialImages){
    return new Promise((resolve,reject)=>{
        apiMultimedia.post(apiPath.saveMaterialImages,formMaterialImages)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPutMaterialImages(formMaterialImages){
    return new Promise((resolve,reject)=>{
        apiMultimedia.post(apiPath.updateMaterialImages,formMaterialImages)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchMasterFabric(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.masterFabric)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
export function fnApiPostMasterFabric(formMasterFabric){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveMasterFabric,formMasterFabric)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}


export function fnApiFetchMasterAcc(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.masterAcc)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
export function fnApiPostMasterAcc(formMasterAcc){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveMasterAcc,formMasterAcc)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchMasterThread(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.masterThread)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
export function fnApiPostMasterThread(formMasterThread){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveMasterThread,formMasterThread)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchMasterArtwork(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.masterArtwork)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
export function fnApiPostMasterArtwork(formMasterArtwork){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveMasterArtwork,formMasterArtwork)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchMasterInterlining(){
    return new Promise((resolve, reject)=>{
        apiPlm.get(apiPath.masterInterlining)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
export function fnApiPostMasterInterlining(formMasterInterlining){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.saveMasterInterlining,formMasterInterlining)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchMasterParam(formMasterParam){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.masterParam,formMasterParam)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchListMasterParam(){
    return new Promise((resolve, reject) => {
        apiPlm.get(apiPath.masterParamList)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

export function fnApiFetchEditMasterParam(formMasterParam){
    return new Promise((resolve, reject) => {
        apiPlm.put(apiPath.masterParamUpdate, formMasterParam)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchDeleteMasterParam(formMasterParam){
    return new Promise((resolve, reject) => {
        apiPlm.post(apiPath.masterParamDelete,formMasterParam)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchListProductDashboard(){
    return new Promise((resolve, reject) => {
        apiPlm.get(apiPath.productDesignDash)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

export function fnApiFetchListProductDesign(){
    return new Promise((resolve, reject) => {
        apiPlm.get(apiPath.productDesignList)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

export function fnApiFetchSaveProductDesignForm(formProductDesign) {
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.productDesignSave,formProductDesign)
        .then(response => {
            console.log(response);
            resolve(response)
        })
        .catch(err => {
            console.log(err);
            reject(err)
        })
    })
}

export function fnApiFetchSaveProductMaterial(productMaterial){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.productMaterialSave, productMaterial)
        .then(response => {
            resolve(response)
        })
        .catch(err => {
            reject(err)
        })
    })
}

export function fnApiFetchSaveProductDesignDetail(formProductDesignDetail){
    console.log(formProductDesignDetail)
    return new Promise((resolve, reject)=>{
        apiMultimedia.post(apiPath.productDesignDetailSave,formProductDesignDetail)
        .then(response => {resolve(response)})
        .catch(err => {reject(err)})
    })
}
export function fnApiFetchSaveProductImages(imagesProductDesign){
    return new Promise((resolve, reject)=>{
        apiMultimedia.post(apiPath.productImagesSave,imagesProductDesign)
        .then(response =>{resolve(response)})
        .catch(err => {reject(err)})
    })
}

export function fnApiFetchListProductSample(){
    return new Promise((resolve, reject) => {
        apiPlm.get(apiPath.productSample)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- size spec

export function fnApiSizeSpec(){
    return new Promise((resolve,reject)=>{
        apiPlm.get(apiPath.sizeSpec)
        .then(rsp => { resolve(rsp)})
        .catch(err => { reject(err)})
    })
}

export function fnApiPostSizeSpecHeader(sizeSpecHeader){
    return new Promise((resolve, reject)=> {
        apiPlm.post(apiPath.saveSizeSpecHeader,sizeSpecHeader)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPutSizeSpecHeader(sizeSpecHeader){
    return new Promise((resolve, reject)=> {
        apiPlm.put(apiPath.updateSizeSpecHeader,sizeSpecHeader)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

export function fnApiPostSizeSpecDetail(sizeSpecDetail){
    return new Promise((resolve, reject)=> {
        apiPlm.post(apiPath.saveSizeSpecDetail,sizeSpecDetail)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

// -- /size spec

// supplier selection
export function fnApiSupplier(){
    return new Promise((resolve,reject)=>{
        apiPlm.get(apiPath.productSupplier)
        .then(rsp => { resolve(rsp)})
        .catch(err => { reject(err)})
    })
}

export function fnApiPostSupplier(supplierSelection){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.saveProductSupplier,supplierSelection)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}

// product costing
export function fnApiCosting(){
    return new Promise((resolve,reject)=>{
        apiPlm.get(apiPath.productCosting)
        .then(rsp => { resolve(rsp)})
        .catch(err => { reject(err)})
    })
}

export function fnApiPostCosting(productCosting){
    return new Promise((resolve, reject)=>{
        apiPlm.post(apiPath.saveProductCosting,productCosting)
        .then(rsp => {resolve(rsp)})
        .catch(err => {reject(err)})
    })
}
