<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Form Master Subcon" header-tag="header">
                        <template v-slot:header>
                            <div class="text-left">
                                <h3 style='font-weight:bold;'>Form Master Subcon</h3>
                            </div>
                        </template>

                        <validation-observer ref="observer" >
                            <form>
                                <b-row align-h="center">
                                    <b-col sm="auto">
                                        <div class="overflow-auto">
                                            <b-col sm="4">
                                              <!-- Filter Field -->
                                            <b-input-group size="sm">
                                              <b-form-input
                                                v-model="filter"
                                                type="search"
                                                id="filterInput"
                                                placeholder="Type to Search"
                                              ></b-form-input>
                                              <b-input-group-append>
                                                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                              </b-input-group-append>
                                            </b-input-group>
                                            <!-- END Filter Field -->
                                            </b-col>

                                            <!-- Table Form Master Subcon -->
                                            <b-col sm="12" style="padding-top:10px;">
                                              <b-table striped hover style="font-size:11px; text-align:center;"
                                                id="my-table"
                                                :items="stMstSub"
                                                :fields="fields"
                                                stacked="md"
                                                :per-page="perPage"
                                                :current-page="currentPage"
                                                :sort-by.sync="sortBy"
                                                :sort-desc.sync="sortDesc"
                                                :filter="filter"
                                                :filter-included-fields="filterOn"
                                                @filtered="onFiltered"
                                                :busy="isBusy"

                                                 responsive="sm"
                                                >
                                                <!-- Loading  -->
                                                <template #table-busy>
                                                  <div class="text-center text-primary my-2">
                                                    <b-spinner class="align-middle"></b-spinner>
                                                    <strong>Loading...</strong>
                                                  </div>
                                                </template>
                                                <!-- END Loading  -->

                                              <!-- Cell subcon -->
                                              <template #cell(nama_subcon)="row" >
                                                {{row.value}}
                                                <b-form-input class="none" :id="'subcon'+row.item.id" :ref="'subcon'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>
                                              <!-- End Cell subcon -->

                                              <!-- Cell kode -->
                                              <template #cell(kode)="row" >
                                                {{row.value}}
                                                <b-form-input class="none" :id="'kode'+row.item.id" :ref="'kode'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>
                                              <!-- End Cell kode -->

                                              <!-- Cell cutting target(day) -->
                                              <template #cell(cutting)="row">
                                                <b-form-input :id="'cutting'+row.item.id" type="number" v-model="cutting[row.item.id]" :ref="'cutting'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>
                                              <!-- END Cell cutting target(day) -->

                                              <!-- Cell sewing target(day) -->
                                              <template #cell(sewing)="row">
                                                <b-form-input :id="'sewing'+row.item.id" type="number" v-model="sewing[row.item.id]" :ref="'sewing'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>
                                              <!-- End Cell sewing target(day) -->

                                              <!-- Cell finishing target(day) -->
                                              <template #cell(finishing)="row">
                                                <b-form-input  :id="'finishing'+row.item.id" type="number" v-model="finishing[row.item.id]" :ref="'finishing'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>

                                              <!-- Cell embroidery target(day) -->
                                              <template #cell(embroidery)="row">
                                                <b-form-input  :id="'embroidery'+row.item.id" type="number" v-model="embroidery[row.item.id]" :ref="'embroidery'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>

                                              <!-- Cell printing target(day) -->
                                              <template #cell(printing)="row">
                                                <b-form-input :id="'printing'+row.item.id" type="number" v-model="printing[row.item.id]" :ref="'printing'+row.item.id" :value="row.value"  :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>

                                              <!-- Cell emblem target(day) -->
                                              <template #cell(emblem)="row">
                                                <b-form-input :id="'emblem'+row.item.id" type="number" v-model="emblem[row.item.id]" :ref="'emblem'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>

                                              <!-- Cell washing target(day) -->
                                              <template #cell(washing)="row">
                                                <b-form-input :id="'washing'+row.item.id" type="number" v-model="washing[row.item.id]" :ref="'washing'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>

                                              <!-- Cell packing target(day) -->
                                              <template #cell(packing)="row">
                                                <b-form-input :id="'packing'+row.item.id" type="number" v-model="packing[row.item.id]" :ref="'packing'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>

                                              <!-- Cell delivery(day) -->
                                              <template #cell(delivery)="row">
                                                <b-form-input :id="'delivery'+row.item.id" type="number" v-model="delivery[row.item.id]" :ref="'delivery'+row.item.id" :value="row.value" :readonly="cat_id >= 1"      trim></b-form-input>
                                              </template>

                                              </b-table>
                                            </b-col>
                                              <b-col sm="2" md="3" class="my-0">
                                                  <b-pagination
                                                    v-model="currentPage"
                                                    :total-rows="totalRows"
                                                    :per-page="perPage"
                                                    align="fill"
                                                    size="sm"
                                                    class="mt-2"
                                                    pills
                                                  ></b-pagination>
                                                </b-col>
                                              </div>
                                            </b-col>
                                   </b-row>

                                <!-- Button Confirm -->
                                 <b-row style="padding-top:10px;" align-h="end">
                                    <b-col sm="2" style='text-align:right;'>
                                        <b-button block  v-if="save" @click="Confirm()" ref="save" type="button" variant="primary">Save</b-button>
                                    </b-col>
                                </b-row>
                                <!-- END Button Confirm -->
                            </form>
                            <!-- Button EDIT Value-->
                            <b-row align-h="end">
                            <b-col sm="2" style='text-align:right;'>
                            <b-button block v-if="edit" class="edit"  @click="Edit()" type="button" variant="success">Edit</b-button>
                            </b-col>
                            </b-row>
                             <!-- END Button EDIT Value-->
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import apiDailyV2 from '@/store/ppm-form/ppm.js';
    import JsonExcel from 'vue-json-excel'
    // import vue loading
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);

Vue.component('download-excel', JsonExcel)

    export default {
        name: 'MasterSubcon',

        data() {
            const now = new Date()

            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

            // create hour:min:sec
            const time = now.toString().slice(16,24)
            // const opthour = { hour: '2-digit'};
            // const optmin = { minute: '2-digit'};
            // const optsec = { second: '2-digit'};
            // const hour = now.toLocaleString('id-ID', opthour ,{ hour12: false })
            // const min = now.toLocaleString('id-ID', optmin ,{ hour12: false })
            // const sec = now.toLocaleString('id-ID', optsec ,{ hour12: false })
            // const time = hour+':'+min+':'+sec
            return{
                formName: 'PPCM Master Subcon',
                today:today,
                time:time,
                fields: [{
                    key: 'id',
                    label: 'No'
                  },
                  {
                    key: 'nama_subcon',
                    label: 'Nama Subcon'
                  },
                  {
                    key: 'kode',
                    label: 'ID Subcon'
                  },
                  {
                    key: 'email',
                    label: 'Email'
                  },
                  {
                    key: 'alamat',
                    label: 'Alamat'
                  },
                  {
                    key: 'cutting',
                    label: 'Cut'
                  },
                  {
                    key: 'Sewing',
                    label: 'Sew'
                  },
                  {
                    key: 'finishing',
                    label: 'Finish'
                  },
                  {
                    key: 'packing',
                    label: 'Pack'
                  },
                  {
                    key: 'embroidery',
                    label: 'Embro'
                  },
                  {
                    key: 'printing',
                    label: 'Print'
                  },
                  {
                    key: 'emblem',
                    label: 'Emblem'
                  },
                  {
                    key: 'washing',
                    label: 'Wash'
                  },
                  {
                    key: 'delivery',
                    label: 'Delivery'
                  },

                ],
                errorMessage: null,
                cat_id:1,
                perPage: 10,
                totalRows:'',
                currentPage: 1,
                filter: null,
                isBusy: true,
                sortBy: 'id',
                sortDesc: false,
                filterOn: [],
                edit:true,
                save:false,
                form:[],
                formdetail:[],
                formdata:[],
                kode:[],
                subcon:[],
                cutting:[],
                sewing:[],
                finishing:[],
                embroidery:[],
                printing:[],
                emblem:[],
                washing:[],
                packing:[],
                delivery:[],
                totalpagefilter:null,
                boxTwo:'',
                form_io : {
                    fullname:null,
                    role:null,
                },

            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
             ...mapState('ppm', ['stMstSub', 'stMstSubCount']),

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getDataFormMasterSubcon','clearStatePpm']),

      // function Filter Field
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      Setdata_IO() {
        let userData     = this.userData
        this.$set(this.form_io, 'fullname', userData.fullname)
        // this.$set(this.form_io, 'role', userData.role)

        // Authorization
        // this.form_io.role = this.form_io.role.toLowerCase();
      //  if (this.form_io.role !== 'subcon') {
      //    // console.log(this.form_io.role)
      //  } else {
      //   this.$router.push({name:"ApplicationPage"})
      //   this.showNotification({type:'error', message:"you don't have authorization to view this page"})
      //  }
      },
        totalPage() {
         if (this.stMstSub !== undefined) {
         this.totalRows = this.stMstSub.length
         }

      },

      // data = 0 then push to application page
      checkData() {
        setTimeout(() => {
        if (this.stMstSubCount == 0) {
          this.$router.push({name:"ApplicationPage"})
        }
        },1000)
      },

      // get data from database
      FillValueModel() {
        let array_id = null
        let id = null
        // console.log(this.stMstSubCount)
        for (let i = 0; i < this.stMstSubCount; i++) {
          if (this.stMstSub !== undefined) {
              array_id = this.stMstSub[i].id;
              // console.log(array_id)
              for(let b = 0; b < this.stMstSubCount; b++) {
                    if(this.stMstSub[b].id == array_id) {
                        id = array_id
                        // console.log(id)
                        this.subcon[id] = this.stMstSub[b].nama_subcon;
                        this.cutting[id] = this.stMstSub[b].cutting;
                        this.sewing[id] = this.stMstSub[b].sewing;

                        this.finishing[id] = this.stMstSub[b].finishing;
                        this.embroidery[id] = this.stMstSub[b].embroidery;
                        this.printing[id] = this.stMstSub[b].printing;
                        this.emblem[id] = this.stMstSub[b].emblem;
                        this.washing[id] = this.stMstSub[b].washing;
                        this.packing[id] = this.stMstSub[b].packing;
                        this.delivery[id] = this.stMstSub[b].delivery;
                    }
                }

          } else {
            this.$router.push({name:"ApplicationPage"})
            return
          }
        }
        },
      getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
      },

      // function button Edit
      Edit() {
          this.cat_id=0;
          this.edit = false;
          this.save = true;
      },

      // function button Confirm
      Confirm() {
        this.boxTwo = ''
              this.$bvModal.msgBoxConfirm('Are you sure?', {
              title: 'Please Confirm',
              size: 'md',
              buttonSize: 'md',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true,
              })
              .then(value => {
                  this.boxTwo = String(value)
                  if (this.boxTwo == 'true') {
                      // console.log('box: '+this.boxTwo)
                      this.doApproval()
                  }
              })
              .catch(err => {
                  // An error occurred
              })
      } ,
        doApproval() {
            for (let r = 0; r < this.stMstSubCount; r++) {
              let array_id = this.stMstSub[r].id;
              console.log(array_id)
              if (this.$refs['kode'+array_id]) {
                console.log('push')
            let verifyData = this.createFormData({array_id})
            this.form.push(verifyData)
            let verifyDataDetail = this.createFormDataDetail({array_id})
            this.formdetail.push(verifyDataDetail)
              }
            }
            this.onSubmit()
      },
      createFormData({array_id}) {
        let data = {
            id:array_id,
            kode:this.$refs['kode'+array_id].value,
            cutting:this.$refs['cutting'+array_id].value,
            sewing:this.$refs['sewing'+array_id].value,
            finishing:this.$refs['finishing'+array_id].value,
            embroidery:this.$refs['embroidery'+array_id].value,
            printing:this.$refs['printing'+array_id].value,
            emblem:this.$refs['emblem'+array_id].value,
            washing:this.$refs['washing'+array_id].value,
            packing:this.$refs['packing'+array_id].value,
            delivery:this.$refs['delivery'+array_id].value,
          }
        return data;
      },
      createFormDataDetail({array_id}) {

        let data = {
            id:array_id,
            kode:this.$refs['kode'+array_id].value,
            subcon:this.$refs['subcon'+array_id].value,
            cutting:this.$refs['cutting'+array_id].value,
            sewing:this.$refs['sewing'+array_id].value,
            finishing:this.$refs['finishing'+array_id].value,
            embroidery:this.$refs['embroidery'+array_id].value,
            printing:this.$refs['printing'+array_id].value,
            emblem:this.$refs['emblem'+array_id].value,
            washing:this.$refs['washing'+array_id].value,
            packing:this.$refs['packing'+array_id].value,
            delivery:this.$refs['delivery'+array_id].value,
            user:this.form_io.fullname,
            date_time:this.today+' '+this.time
          }
        return data;
      },
        onSubmit() {
        // do Nothing if no data is submitted
        if(this.form.length == 0) {
          return
        }
        let loader = this.$loading.show({
              // Optional parameters
              container: this.fullPage ? null : this.$refs.formContainer,
              canCancel: false,
              color: '#f89830',
              loader: 'spinner',
              width: 64,
              height: 64,
              backgroundColor: '#ffffff',
              opacity: 0.5,
              zIndex: 999,
            });
            // show report after timeout min 1 second
          setTimeout(() => {
            apiDailyV2.dispatch('postFormMasterSubcon', {
          data:this.formdetail
        })
        .then(response => {
          //console.log(response)
        })
        .catch(err => {
          if (err.response === 400) {
            console.log(err.response.data.message)
            this.showNotification({type:'error', message:err.response.data.message})
          }
        })
        apiDailyV2.dispatch('putFormMasterSubcon', {
          data:this.form
        })
        .then(response => {
          loader.hide()
          //console.log(response)
          this.$router.push({name:"ApplicationPage"})
          this.showNotification({type:'success', message:'Successfully Submitted'})
        })
        .catch(err => {
          loader.hide()
          if (err.response === 400) {
            console.log(err.response.data.message)
            this.showNotification({type:'error', message:err.response.data.message})
          }
        })
        .finally(()=>{
          loader.hide()
          this.form = []
          this.formdetail = []
        })
        },3000)
      },


    },
        created() {
          this.Setdata_IO()
          this.getDataFormMasterSubcon().then(rsp => {
            this.FillValueModel();
            this.totalPage();
            this.isBusy = !this.isBusy;
            this.checkData();
          })
          .catch(err => {
              if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
              this.$router.push({name:"ApplicationPage"})
              }
          })
          this.clearStatePpm()
        },
    }
</script>
<style src="./MasterSubcon.scss" lang="scss" scoped />
