import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchCityList,
} from '../api/api-general.js'
import { APIPATH_GENERAL } from '../api/api-paths.js'
import { fnPOSTCore } from '../api/api-methods';


Vue.use(Vuex)


const actions = {
    fetchCityList(context, param) {
        // console.log(formSelfDeclaration)
      return new Promise((resolve, reject) => {
        fnApiFetchCityList(param)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
      })
    },
  }

export default new Vuex.Store({
    namespaced: true,
    actions,
})

export const saveErrorLog = function(name, error) {
  fnPOSTCore(APIPATH_GENERAL.errorLog, {
        app_name:name,
        error_log:error.response
    })

}
export const ConfirmBox = function(msg, title, type) {
    let color = ''
    if (type == 'Delete') {
      color = 'danger'
    } else {
      color = 'success'
    }
    this.$bvModal
          .msgBoxConfirm(msg, {
            title: title,
            size: "sm",
            buttonSize: "sm",
            okVariant: color,
            okTitle: type,
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }).then((value) => {
            return value
          })
  }