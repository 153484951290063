import { getAPI } from './api-base'

const apiPath = {
    masterCustomer              : 'v1/api/bbi-sales-force/master-customer/',
    EditmasterCustomer          : 'v1/api/bbi-sales-force/edit-master-customer/',
    monthlyPlanning             : 'v1/api/bbi-sales-force/monthly-planning/',
    EditMonthlyPlanning         : 'v1/api/bbi-sales-force/edit-monthly-planning/',

    EditMonthlyApproval         : 'v1/api/bbi-sales-force/approval-monthly/',

    WeeklyPlanning              : 'v1/api/bbi-sales-force/weekly-planning/',
    EditWeeklyPlanning          : 'v1/api/bbi-sales-force/edit-weekly-planning/',

    WeeklyApproval              : 'v1/api/bbi-sales-force/approval-weekly/',

    SaveCustomerDistribution    : 'v1/api/bbi-sales-force/save-customer-distribution/',
    EditCustomerDistribution    : 'v1/api/bbi-sales-force/edit-customer-distribution/',

    SalesGroup                  : 'v1/api/bbi-sales-force/post-sales-group/',

    ActualActivity              : 'v1/api/bbi-sales-force/update-actual-activity/',
}

// -- Post Master Customer
export function fnApiFetchMasterCustomer(MasterCustomer){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.masterCustomer,MasterCustomer)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Edit Master Customer
export function fnApiFetchEditMasterCustomer(MasterCustomer){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.EditmasterCustomer,MasterCustomer)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}


// -- Post Monthly Planning
export function fnApiFetchPostMonthlyPlanning(MonthlyPlanning){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.monthlyPlanning,MonthlyPlanning)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Edit Monthly Planning
export function fnApiFetchEditMonthlyPlanning(MonthlyPlanning){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.EditMonthlyPlanning, MonthlyPlanning)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Edit Monthly Approval
export function fnApiFetchMonthlyApproval(MonthlyApproval){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.EditMonthlyApproval, MonthlyApproval)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Weekly Planning
export function fnApiFetchWeeklyPlanning(FormWeeklyPlanning){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.WeeklyPlanning, FormWeeklyPlanning)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}
// -- Edit Weekly Planning
export function fnApiFetchEditWeeklyPlanning(FormWeeklyPlanning){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.EditWeeklyPlanning, FormWeeklyPlanning)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Weekly Approval
export function fnApiFetchWeeklyApproval(FormWeeklyApproval){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.WeeklyApproval, FormWeeklyApproval)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Save Customer Distribution
export function fnApiPostCustomerDistribution(formCustomerDistribution){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.SaveCustomerDistribution, formCustomerDistribution)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Edit Customer Distribution
export function fnApiEditCustomerDistribution(formCustomerDistribution){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.EditCustomerDistribution, formCustomerDistribution)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Sales Group
export function fnApiPostSalesGroup(formSalesGroup){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.SalesGroup, formSalesGroup)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}

// -- Actual Activity
export function fnApiFetchActualActivity(formActualActivity){
    return new Promise((resolve, reject)=>{
        getAPI.post(apiPath.ActualActivity, formActualActivity)
        .then(rsp => { resolve(rsp) })
        .catch(err => { reject(err) })
    })
}