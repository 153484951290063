<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-monthly-activity-plan">Monthly Activity Plan</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Monthly Activity Plan Form" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3>Monthly Activity Plan Form</h3>
                <b-button v-if='disabledForm == true' @click="BackHistoryButton()" size="md" type='button' variant="info" style="position: absolute; right: 15px;">
                  <b-icon icon="arrow-left"></b-icon>
                  Back
                </b-button>
              </div>

            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Plan ID"
                      label-for="ID_Transaction"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="ID_Transaction"
                        :disabled="true"
                        v-model="form.id_transaction"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="NIK"
                      label-for="Employee_ID"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="Employee_ID"
                        :disabled="true"
                        v-model="form.employee_nik"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Sales Person"
                      label-for="EmployeeName"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="EmployeeName"
                        :disabled="true"
                        v-model="form.employee_name"
                        trim
                      ></b-form-input>
                      </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-row>
                <b-col sm='4'>
                  <b-form-group
                    label="Period"
                    label-for="Period"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                    <VueDatePicker
                      class="vd__div"
                      v-model="form.period"
                      no-header
                      @input="ChangeTransaction"
                      :disabled="disablePeriod"
                      type="month"
                      format="MMM YYYY"
                      :min-date="minMonthDate"
                      :max-date="maxMonthDate"
                      placeholder="Choose date"
                      />
                    </b-form-group>
                </b-col>
                <b-col sm="8">
                  <div class="text-right">
                    <b-button title="Remove" v-if="buttonSubmit == null" v-on:click="deleteAll()" size="sm" variant="outline-danger" style='margin-right:5px;'>
                        <b-icon icon="x-square" aria-label="Remove"></b-icon>
                        Remove All
                    </b-button>
                  </div>
                </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
            <b-table
              :no-border-collapse="true"
              striped
              stacked="md"
              class="header-background max_width_fit"
              hover
              small
              show-empty
              responsive
              :fields="fields"
              :items="dataTable"
            >
              <!-- table empty -->
              <template #empty="scope">
                  <p class="text-center display-hidden">{{ scope.emptyText }}</p>
                  <div style='text-align:center; !important; '>
                  <b-button title="Add" size="xs" v-if="!disabledForm" variant="outline-success" v-on:click="show = !show" style='margin-right:5px;'>
                      <b-icon icon="person-plus-fill" aria-label="Add"></b-icon>

                  </b-button>
                  </div>
              </template>

              <!-- Est Employee -->
              <template #cell(est_employee)="row">
                  <span v-if="row.value !== null" v-html="FormatCommaNumber(row.value)"></span>
              </template>

              <!-- project_id -->
              <template #cell(project_id)="row">
                  <span v-html="NullTable(row.value)"></span>
              </template>

              <!-- TENDER -->
              <template #cell(status_tender)="row">
                  <span v-if="row.value !== null" v-html="CheckTender(row.value)"></span>
              </template>

              <template #table-caption>
                <b-button v-if="dataTable.length > 0 && !disabledForm" title="Add" size="xs" variant="outline-success" v-on:click="show = !show" style='margin-right:5px;'>
                    <b-icon icon="person-plus-fill" aria-label="Add"></b-icon>

                </b-button>
              </template>
              <!-- <template v-slot:cell(actions)="{item}"> -->
              <template #cell(action)="row">
                    <b-button title="View" size="xs" v-if="disabledForm" variant="outline-info" v-on:click="ViewCustomer(row)" style='margin-right:5px;'>
                        <b-icon icon="eye-fill" aria-label="View"></b-icon>

                    </b-button>

                    <!-- <b-button :disabled="disablebtnSubmit(form.selectedtypeTable)" v-if="row.item.type == '' || row.item.type == null" title="Submit" size="sm" variant="outline-primary" @click="submitAddTable(row)" style='margin-right:5px;'>
                        <b-icon icon="check-square-fill" aria-label="Submit"></b-icon>
                    </b-button> -->

                    <b-button title="Delete" size="xs" v-if="!disabledForm" :disabled="row.item.action == undefined" type="button" variant="outline-danger" v-on:click="Confirm('delete',row)">
                        <b-icon icon="trash-fill" aria-label="Delete"></b-icon>

                    </b-button>
              </template>
            </b-table>
          </b-card>

          <template>
            <b-modal
                v-model="show"
                ok-variant="success"
                cancel-variant="warning"
                header-bg-variant="secondary"
                hide-footer
                hide-backdrop
                hide-header-close
                size="md"
                ref="modal"
                no-close-on-backdrop
                title="Form Monthly Activity Plan"

            >
                <div id="modal">
                  <validation-observer ref="observer" v-slot="{handleSubmit}">
                    <b-form @submit.prevent="handleSubmit(submitAddTable)" >
                      <b-row>
                        <b-col cols="12">
                          <validation-provider
                            name="CustomerName"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                              label               ="Customer *"
                              label-class         ="text-primary"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                              <v-select
                                v-model="form.selectedCustomer"
                                :clearable="false"
                                :required="true"
                                label="name"
                                @input="onChange($event)"
                                :options="options.customer"
                                >
                                <template v-slot:option="option">
                                  <slot name="option-data" v-bind="option">
                                    {{option.name}} - [{{ option.group_name }}]
                                  </slot>
                                </template>
                                <template v-if="form.customer_id !== null" v-slot:selected-option="option">
                                  <slot name="option-data" v-bind="option">
                                    <span>{{option.name}} - [{{ option.group_name }}]</span>
                                  </slot>
                                </template>
                              </v-select>

                              <b-form-invalid-feedback id="Customer">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12">
                          <validation-provider
                            name="Tender"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                              label               ="Tender *"
                              label-class         ="text-primary"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                              <b-form-radio-group
                                v-model="form.tender"
                                style='top:5px; position: relative;'
                                :required="true"
                                class="text-dark"
                                name="tender"
                                ref="opt_tender"
                              >
                                <b-radio class="radio" value="open_tender">Yes</b-radio>
                                <b-radio value="close_tender">No</b-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback id="Tender">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <!-- <b-col cols="12">
                            <b-form-group
                              label               ="Customer Name"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-input
                              id="Industrial"
                              v-model="form.customer_name"
                              :disabled="true"
                              trim
                            ></b-form-input>
                            </b-form-group>
                        </b-col> -->

                        <b-col cols="12">
                            <b-form-group
                              label               ="Sector"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-input
                              id="Industrial"
                              v-model="form.industrial"
                              :disabled="true"
                              trim
                            ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group
                              label               ="Sub Sector"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-input
                              id="Sector"
                              v-model="form.sector"
                              :disabled="true"
                              trim
                            ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group
                              label               ="Est Employee"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-input
                              id="EstEmployee"
                              v-model="form.est_employee"
                              :disabled="true"
                              trim
                            ></b-form-input>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group
                              label               ="Status"
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <b-form-input
                              id="Status"
                              v-model="form.status"
                              :disabled="true"
                              trim
                            ></b-form-input>
                            </b-form-group>
                        </b-col>

                      </b-row>
                      <b-row align-h="end">
                        <b-col sm='3'>
                          <b-button block size="md" type='submit' variant="success">
                            Save
                          </b-button>
                        </b-col>
                        <b-col sm='3'>
                          <b-button block size="md" @click="cancel()" type='button' variant="warning">
                            Cancel
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
            </b-modal>
          </template>

          <br />
          <b-row align-h="center">
              <b-col md='6'>
                <b-button v-if='buttonSubmit == null ' block size="md" @click="Confirm('save')" type='submit' variant="primary">
                  Submit
                </b-button>
                <b-button v-if='buttonSubmit == "edit" ' @click="Confirm('edit')" block size="md" type='submit' variant="success">
                  Confirm
                </b-button>
              </b-col>
          </b-row>

        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import apiMonthlyPlanning from '@/store/salesforce-form/monthly-planning.js';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";

export default {
  components: {
    ModelListSelect,
    Loader,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'numeric'};
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "Monthly Activity Plan Form",
      minMonthDate:null,
      maxMonthDate:null,
      show:false,
      today:today,
      BackHistory:null,
      disablePeriod:false,
      headerTitle:null,
      disabledForm:false,
      buttonSubmit:null,
      showHidden:true,
      today_days:days,
      today_month:month,
      StrMonth:Strmonth,
      today_year:year,
      fields:[
          {key:'action', label:'Action', sortable:false },
          {key:'customer_id' ,class: 'display-hidden'},
          {key:'project_id', label:'project id', sortable:true },
          {key:'customer_name', label:'customer name', sortable:false },
          {key:'status_tender', label:'Tender', sortable:false },
          {key:'sector', label:'sector', sortable:false},
          {key:'sub_sector', label:'sub sector', sortable:false},
          {key:'est_employee', class:'', label:'Est Employee', tdClass: 'align_right', sortable:false},
          {key:'status', label:'Status', sortable:false },
      ],
      dataTable:[],
      dataTableEdit:[],
      data:[],
      groupNik:[],
      nikLeader:null,
      options: {
        customer:[],
      },
      form :{
        leader_nik:null,
        leader_name:null,
        tender:null,
        id_transaction:null,
        employee_nik:null,
        employee_name:null,
        period:null,
        selectedCustomer:null,
        customer_id:null,
        customer_name:null,
        industrial:null,
        transaction_id:null,
        est_employee:null,
        status:null,
        sector:null,
      },

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState('sales_force', ['stListCstm','stListCstmCount','stMnthPln','stMnthPlnCount','stMnthPlnDetail','stMnthPlnDetailCount']),


  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions('general', ['fetchCityList']),
    ...mapActions('sales_force', ['getDataListCustomer','getDataMonthlyPlanning','getDataMonthlyPlanningDetail','getListSalesDetail']),

    setData() {
      this.getListSalesDetail({
        sales:this.userData.nik
      }).then(rsp=>{
        if (rsp == 'No Data') {
          this.showNotification({type:'info', message:'User Sales belum terdaftar di group'})
          this.$router.push({name:"SFCMonthlyActivityPlan"})
        } else {
          this.nikLeader = rsp.payload[0].leader_nik
        }
      })
      this.form.employee_nik    = this.userData.nik
      this.form.employee_name   = this.userData.fullname
      let statusRoute           = this.$route.params.page
      let idData                = this.$route.params.idData
      let yearMonth             = this.today_year+"-"+this.today_month
      this.minMonthDate         = this.today_year+"-"+this.today_month+"-"+"01"

      if (this.today_month == '12') {
        let month                 = "0"
        let year                  = new Date(this.today_year, month, 1);
        this.maxMonthDate         = year.setFullYear(year.getFullYear()+1);
        this.maxMonthDate         = new Date(this.maxMonthDate).toISOString().slice(0,10)
      } else {
        let date                  = new Date();
        let year                  = new Date(date.getFullYear(), date.getMonth(), 1);
        this.maxMonthDate         = year.setMonth(year.getMonth()+1);
        this.maxMonthDate         = new Date(this.maxMonthDate)
      }
      if (statusRoute == undefined || statusRoute =='' ) {
        statusRoute = null
      }
      this.buttonSubmit = statusRoute

      if (statusRoute == 'edit' || statusRoute== 'view') {
        this.disablePeriod = true
        // start loading
        this.isLoadingData = true

        this.getDataMonthlyPlanning({
        id:idData,
        })
        .then(rsp => {
            if(this.stMnthPlnCount == 0) {
                this.showNotification({type:'info', message:'Data Not Found'})
            }
            this.getDataMonthlyPlanningDetail({
              monthly_planning:idData,
              })
              .then(rsp => {
                  if(this.stMnthPlnDetailCount == 0) {
                      this.showNotification({type:'info', message:'Data Detail Not Found'})
                  }
                  // stop Loading
                  setTimeout(() => {
                    this.isLoadingData = false
                  }, 500);
                  this.setDataForm()
              })
              .catch(err => {
                // stop Loading
                  setTimeout(() => {
                    this.isLoadingData = false
                  }, 500);
                  if(err.response.status !== 401) {
                      this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                      this.$router.push({name:"SFCMonthlyActivityPlan"})
                  }
              })
        })
        .catch(err => {
          // stop Loading
          setTimeout(() => {
            this.isLoadingData = false
          }, 500);
            if(err.response.status !== 401) {
                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                this.$router.push({name:"SFCMonthlyActivityPlan"})
            }
        })

      } else {
        this.headerTitle = 'New'
        this.$set(this.form, 'period', yearMonth)
        let StrMonth = this.StrMonth
        if (this.StrMonth.length == 1) {
          StrMonth = "0"+this.StrMonth
        }
        let Stryear = this.today_year.substring(2,4)
        this.form.id_transaction = 'MAP/'+Stryear+"/"+StrMonth+"/XXXX"
        this.form.transaction_id = 'MAP/'+Stryear+"/"+StrMonth
        this.form.selectedCustomer = {id:'', name:'', inds:'', sector:'', estEm:'', status:''}
        this.LoadListCustomer();

      }
    },
    LoadListCustomer(){
      let group_nik = null
      let statusGroup = null
      if (this.buttonSubmit == 'edit') {
        group_nik = this.groupNik
        if (this.groupNik !== this.nikLeader) {
          statusGroup = 'group_nik'
        }
      }
      if (this.buttonSubmit !== 'edit') {
        let period = this.form.period
        this.getDataListCustomer({
          sales_nik:this.form.employee_nik,
          period_date:period,
        }).then(rsp => {
          if (rsp != 'No Data') {
            for (let a=0; a<=rsp.length-1;a++) {
              let group_nik = rsp[a].group_nik
              if (rsp[a].group_nik == undefined || rsp[a].group_nik == '' || rsp[a].group_nik == null){
                group_nik = this.nikLeader
              }
              if (!this.groupNik.includes(group_nik)) {
                this.groupNik.push(group_nik)
              }
              this.dataTable.push({
                  customer:rsp[a].customer,
                  customer_name: rsp[a].customer_name,
                  project_id:rsp[a].project_id,
                  sector: rsp[a].sector,
                  sub_sector:rsp[a].sub_sector,
                  est_employee: rsp[a].est_employee,
                  group_nik: group_nik,
                  status: rsp[a].status,
                  status_tender:rsp[a].status_tender,
                  action:'create'
              })
            }
          }
        })
      }

      // start loading
      this.isLoadingData = true
      this.getDataListCustomer({
        sales_nik:this.form.employee_nik,
        period_date:null,
        group_nik:group_nik,
        status:statusGroup,

      }).then(rsp => {
        if (this.stListCstmCount < 1 ) {
          this.showNotification({type:'info', message:'Data Not Found'})
        }
        for (let r=0; r<=this.stListCstmCount-1; r++) {
          let sales_group_name = this.stListCstm[r].sales_group_name
          let group_nik = this.stListCstm[r].group_nik
          if (this.stListCstm[r].sales_group_name == null || this.stListCstm[r].sales_group_name == undefined) {
            sales_group_name = 'Free Group'
          }
          if (this.stListCstm[r].group_nik == null || this.stListCstm[r].group_nik == undefined || this.stListCstm[r].group_nik == null) {
            group_nik = this.nikLeader
          }
          this.options.customer.push(
              { id:this.stListCstm[r].customer, name: this.stListCstm[r].customer_name, inds:this.stListCstm[r].sub_sector, sector:this.stListCstm[r].sector, estEm:this.stListCstm[r].est_employee, status:this.stListCstm[r].status, group_name:sales_group_name, group_nik:group_nik,  disabled: false},
          );
        }
      })
      .catch(err => {
        if(err.response.status !== 401) {
          this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
        }
      }).finally(()=> {
        // stop Loading
        setTimeout(() => {
          this.isLoadingData = false
        }, 500);

      })
    },
    CheckTender(value) {
      let result = '-'
      if (value == 'close_tender') {
        result = 'Close Tender'
      }
      if (value == 'open_tender') {
        result = 'Open Tender'
      }
      return result;
    },
    NullTable(value) {
      let result = value
      if (value == null || value == undefined || value == '') {
        result = '-'
      }
      return result
    },
    FormatCommaNumber (param) {
        let format = param
        if (param == null || param == undefined || param == '') {
            format = null
        }
        else {
            // format = param.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            format = param.toString()
        }
        return format
    },
    BackHistoryButton(){
      let pageView = "SFCMonthlyActivityPlan"
      let data = null
      if (this.BackHistory == "WeeklyPlanning" ) {
          pageView = "SFCWeeklyActivityPlan"
      }
      // this.$router.push({name:"SFCMonthlyActivityPlan"})
      this.$router.push({ name: pageView, params: { page:'view', idData:data,  } })
    },
    setDataForm() {
      let FormData = this.stMnthPln
      let FormDataDetail = this.stMnthPlnDetail
      this.form.period = FormData[0].period_date
      this.groupNik = FormData[0].group_nik
      this.form.employee_nik = FormData[0].sales_nik
      this.form.employee_name = FormData[0].sales_name
      this.form.id_transaction = FormData[0].transaction_id
      this.form.transaction_id = FormData[0].transaction_id
      this.dataTable = FormDataDetail
      if (this.$route.params.page == 'view') {
        if (this.$route.params.BackHistory == 'WeeklyPlanning') {
          this.BackHistory = 'WeeklyPlanning'
        }
        this.headerTitle = 'View'
        this.buttonSubmit = 'view'
        this.disabledForm = true
        // this.showHidden = false;
      }

      if (this.$route.params.page == 'edit') {
        this.headerTitle = 'Edit'
        this.buttonSubmit = 'edit';
        this.LoadListCustomer()
      }
    },
    onChange (item) {
      this.form.customer_id = item.id
      this.form.customer_name = item.name
      this.form.industrial = item.inds
      this.form.sector = item.sector
      this.form.est_employee = item.estEm
      this.form.status = item.status
      this.form.leader_nik = item.group_nik
      this.form.leader_name = item.group_name
    },
    ChangeTransaction() {
      if (this.form.period == null || this.form.period == undefined) {
        this.showNotification({type:'error', message:'Problem occurred in the system. Please try again. If the problem persists, contact your system Administrator'})
      } else {
        let period = this.form.period
        this.dataTable = []

        this.getDataListCustomer({
          sales_nik:this.form.employee_nik,
          period_date:period,
        }).then(rsp => {
          if (rsp != 'No Data') {
            let plus_data = null
              for (let a=0; a<=rsp.length-1;a++) {
                let group_nik = rsp[a].group_nik
                if (rsp[a].group_nik == undefined || rsp[a].group_nik == '' || rsp[a].group_nik == null){
                  group_nik = this.nikLeader
                }
                if (!this.groupNik.includes(group_nik)) {
                  this.groupNik.push(group_nik)
                }
                this.dataTable.push({
                    customer:rsp[a].customer,
                    customer_name: rsp[a].customer_name,
                    project_id: rsp[a].project_id,
                    sub_sector: rsp[a].sub_sector,
                    sector:rsp[a].sector,
                    est_employee: rsp[a].est_employee,
                    status: rsp[a].status,
                    group_nik: group_nik,
                    status_tender: rsp[a].status_tender,
                    action:'create'
                })
                console.log(this.dataTable)
              }

          }
        })
        let dateNew   = new Date(this.form.period)
        let month     = dateNew.toLocaleString('default', { month: 'numeric' })
        let year      = dateNew.toLocaleString('default', { year: 'numeric' })

        let StrMonth = month
        if (month.length == 1) {
          StrMonth = "0"+month
        }
        let Stryear = year.substring(2,4)

        this.form.transaction_id = 'MAP/'+Stryear+"/"+StrMonth
        this.form.id_transaction = 'MAP/'+Stryear+"/"+StrMonth+"/XXXX"
      }

    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    ViewCustomer(value) {
      let data = value.item.customer
      this.$router.push({ name: 'SFCMasterCustomerForm', params: { BackHistory:'MonthlyPlanning' ,page:'view', idData:data, IDHistory:this.$route.params.idData } })
    },
    deleteData(value) {
      this.options.customer.unshift(
          { id:value.item.customer, name: value.item.customer_name, inds:value.item.sub_sector, sector:value.item.sector, estEm:value.item.est_employee, status:value.item.status, group_name:value.item.group_name, group_nik:value.item.group_nik,}
      );
      if (this.buttonSubmit == 'edit') {
        this.dataTableEdit.push({
            customer:value.item.customer,
            customer_name: value.item.customer_name,
            sub_sector: value.item.sub_sector,
            sector:value.item.sector,
            est_employee: value.item.est_employee,
            status: value.item.status,
            group_nik: value.item.group_nik,
            status_tender:value.item.status_tender,
            group_name:value.item.group_name,
            action:'delete'
          })
      }
      this.groupNik.splice(value.item.group_nik)
      this.dataTable.splice(value.index, 1)

    },
    cancel() {
      this.form.selectedCustomer = {id:'', name:'', inds:'', sector:'', estEm:'', status:''}
      this.form.customer_id = null
      this.form.customer_name = null
      this.form.industrial = null
      this.form.sector = null
      this.form.est_employee = null
      this.form.status = null
      this.show = !this.show
    },
    submitAddTable() {
      if (this.form.customer_name == null || this.form.customer_name == undefined) {
        this.showNotification({type:'info', message:'Data gagal tersimpan, Customer wajib di pilih'})
        return
      }
      if (this.form.tender == null || this.form.tender == undefined) {
        this.showNotification({type:'info', message:'Data gagal tersimpan, Tender wajib di pilih'})
        return
      }
      if (this.form.leader_nik == null || this.form.leader_nik == undefined) {
        this.form.leader_nik = this.nikLeader
      }
      if (!this.groupNik.includes(this.form.leader_nik)) {
        this.groupNik.push(this.form.leader_nik)
      }
      this.dataTable.push({
        customer:this.form.customer_id,
        customer_name: this.form.customer_name,
        sector: this.form.industrial,
        project_id: null,
        sub_sector:this.form.sector,
        est_employee: this.form.est_employee,
        status: this.form.status,
        group_nik:this.form.leader_nik,
        group_name:this.form.leader_name,
        status_tender:this.form.tender,
        action:'create'
      });
      let arrayIndex = 0
      for(let l=0; l<=this.options.customer.length-1; l++) {
        if (this.options.customer[l].id == this.form.customer_id) {
          arrayIndex = l
        }
      }
      this.options.customer.splice(arrayIndex, 1)
      // this.$set(this.options.customer, arrayIndex, { id:this.form.customer_id, name: this.form.customer_name, inds:this.form.industrial, estEm:this.form.est_employee, status:this.form.status, disabled: true},);
      this.form.selectedCustomer = {id:'', name:'', inds:'', sector:'', estEm:'', status:'', group_name:'' , group_nik:''}
      this.form.customer_id = null
      this.form.customer_name = null
      this.form.industrial = null
      this.form.sector = null
      this.form.est_employee = null
      this.form.leader_nik = null
      this.form.tender = null
      this.form.status = null
      this.show = !this.show

    },
    deleteAll() {
      this.dataTable = []
    },
    Confirm(choice, index) {
      let varOk = 'danger'
      if(choice !== 'delete') {
        varOk = 'success'
      }
      let Boxtrue = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to '+choice+' ? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: varOk,
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          Boxtrue = value
          if (Boxtrue == true) {
            if (choice == 'delete') {
              this.deleteData(index)
            }
            if (choice == 'edit') {
              this.dataTableEdit = [...new Set([...this.dataTable, ...this.dataTableEdit])]

              this.EditMonthlyPlanning()
            }
            if (choice == 'save') {
              this.SaveMonthlyPlanning()
            }
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    SaveMonthlyPlanning() {
      if (this.dataTable.length < 1) {
        this.showNotification({type:'info', message:'Data masih kosong, harap untuk di isi'})
        return
      }
      this.isLoadingData = true
      apiMonthlyPlanning.dispatch('PostMonthlyPlanning',{
        group_nik:this.groupNik,
        sales_nik:this.form.employee_nik,
        transaction_id:this.form.transaction_id,
        sales_name:this.form.employee_name,
        period_date:this.form.period,
        approval_status:'waiting_approval',
        planning_detail:this.dataTable,

      })
      .then(response => {
        if (response.data.message == "Warning") {
          this.showNotification({type:'error', message:response.data.payload})
        } else {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCMonthlyActivityPlan"})
        }
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=> {
        setTimeout(() => {
          this.isLoadingData = false
        }, 500);
      })
    },
    EditMonthlyPlanning() {
      if (this.dataTableEdit.length < 1) {
        this.showNotification({type:'info', message:'Data masih kosong, harap untuk di isi'})
        return
      }
      this.isLoadingData = true
      let idData = this.$route.params.idData
      apiMonthlyPlanning.dispatch('EditMonthlyPlanning',{
        group_nik:this.groupNik,
        sales_nik:this.form.employee_nik,
        transaction_id:this.form.transaction_id,
        sales_name:this.form.employee_name,
        period_date:this.form.period,
        approval_status:'waiting_approval',
        planning_detail:this.dataTableEdit,
        id :idData,
      })
      .then(response => {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCMonthlyActivityPlan"})
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=> {
        setTimeout(() => {
          this.isLoadingData = false
        }, 500);
      })
    },
  },

  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />