const setCookie = function(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = '';
  if (exdays == 0) {
      expires= "expires=Thu, 15 Mar 1990 12:00:00 UTC;"
  } else {
      expires = "expires="+d.toUTCString();
  }
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = function (cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
        let result = (c.substring(name.length, c.length))
        // console.log(result)
      return result;
    }
  }
  return null;
}

const checkCookie = function() {
  let user = getCookie("userData");
  let token = getCookie("access_token");
  let authenticated = getCookie("authenticated");
  let status = ''
    if (user) {
        localStorage.setItem("userData", user);
        localStorage.setItem("access_token",token )
        localStorage.setItem("authenticated", authenticated)
        status = "Login"
      } else {
        localStorage.removeItem("access_token")
        localStorage.removeItem("userData")
        localStorage.removeItem("authenticated")
        status = "Logout"
      }

  return status

}
const deleteCookie = function () {
  document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "authenticated=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  // setCookie('userData', localStorage.getItem('userData', 0))
  // setCookie("access_token", localStorage.getItem('access_token', 0));
  // setCookie("authenticated", localStorage.getItem('authenticated', 0));
}

export {
  checkCookie,
  getCookie,
  setCookie,
  deleteCookie,
}