<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto">
                        
                        <!-- Code Botton -->
                        <validation-provider
                            name="codebotton"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Code Botton
                                </template>
                            
                                <b-form-select id="inputCodeBotton" v-model="form.codeBotton"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                <b-form-invalid-feedback id="inputCodeBotton">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Supplier -->
                        <validation-provider
                            name="supplier"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Supplier
                                </template>
                                <b-form-input id="inputSupplierB" v-model="form.supplier"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputSupplierB">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Color Botton -->
                        <validation-provider
                            name="color"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Color Botton
                                </template>
                                <b-form-input id="inputColor" v-model="form.color"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputColor">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Size Botton -->
                        <validation-provider
                            name="size"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Size Button
                                </template>
                                <b-form-input id="inputSize" v-model="form.size"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputSize">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        
                    </b-col>
                    <b-col cols="auto">
                        

                        <!-- Images Botton -->
                        <validation-provider
                            name="imagesbotton"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images Botton
                                </template>
                                <b-form-file
                                    multiple
                                    ref="fileInput"
                                    id="inputImagesBotton"
                                    v-model="form.imagesBotton"
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose file here..."
                                    accept=".jpg, .png,"
                                    no-drop
                                    :file-name-formatter="formatNames"
                                    v-on:change="onFileChange"
                                ></b-form-file>
                                    <div v-for="img in imagesUrl" :key="img.name">
                                        <p class="small">
                                            <a :href="img.src">{{img.fileName}}</a>
                                            <b-badge href="#" v-on:click="removeImg(img.name,imagesUrl)" variant="light">
                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                            </b-badge>
                                        </p>
                                    </div>
                                <b-form-invalid-feedback id="inputImagesBotton">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <div>
                            <b-button block  variant="success" type="submit">Save</b-button>
                        </div>
                    </b-col>
                    
                    
                    
                </b-row>
                    
            </b-form>
        </validation-observer>

        <div>
            <b-table
                responsive
                striped 
                hover
                :items="dataBotton"
                :fields="fields"
            >
                <template #cell(index)="data">
                    {{ data.index + 1 }}
                </template>
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="data">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                        {{data.index}}
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    name:'FormBotton',
    
    data(){
        return{
            
            formName: 'Botton',
            modulName: null,
            tabIndex:2,
            isValid:false,
            form:{
                indexId:null,
                codeBotton:null,
                supplier:null,
                color:null,
                size:null,
                imagesBotton:[],
            },
            codeOpt:[
                {value:null, text:''},
                {value:'1', text:'Code A'},
                {value:'2', text:'Code B'},
                {value:'3', text:'Code C'},
                {value:'4', text:'Code D'},
            ],
            fields:[
                {key:'index', label:'#'},
                'code','supplier','color','size',
                {key:'images', label:'Images'},
                {key:'actions', label:''},
            ],
            dataBotton: [
                
            ],
            imagesUrl:[],
        }
    },
    methods:{
        ...mapActions('productDesign',['add','delete','update','get']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            if(this.form.indexId == null){
                this.add({
                    formname: this.formName,
                    code: this.form.codeBotton,
                    supplier: this.form.supplier,
                    color: this.form.color,
                    size: this.form.size,
                    images: this.imagesUrl,
                })
               
            }else{
                this.update({
                    id: this.form.indexId,
                    formname: this.formName,
                    code: this.form.codeBotton,
                    supplier: this.form.supplier,
                    color: this.form.color,
                    size: this.form.size,
                    images: this.imagesUrl,
                })
            }
            this.clearForm()
        
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeBotton = null
            this.form.color = null,
            this.form.size = null,
            this.form.supplier = null
            this.form.imagesBotton = []
            this.imagesUrl = []
            this.$refs.observer.reset()
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                // this.form.imagesAcc.push(file[i])
                // this.imagesUrl = URL.createObjectURL(file[i])
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
        return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        deleteData(id){
            
            this.delete({id:id,formname:this.formName})
        },
        editData(index){
            
            this.get({id:index,formname:this.formName})
            this.form.indexId   = index
            this.form.codeBotton   = this.bottonDetail.code
            this.form.size      = this.bottonDetail.size
            this.form.color     = this.bottonDetail.color
            this.form.supplier  = this.bottonDetail.supplier
            this.imagesUrl      = this.bottonDetail.images
        },
        getBottonList(){
            this.dataBotton = this.bottonList
        }
    },
    mounted(){
        if(this.form.indexId != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        this.getBottonList()
    },
    computed:{
    ...mapGetters('productDesign',['bottonList','bottonDetail']),
        
    }
}
</script>