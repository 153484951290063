<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-ppcm"> Dashboard PPM</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="Form Master Subcon" header-tag="header">
                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Input Order PPM</h3>
                                </div>
                            </div>
                        </template>

                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.prevent="handleSubmit(onSubmit)">
                                <b-row align-h="center">
                        <!-- data IO -->
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="IO / PO"
                                                label-for           ="inputIO">
                                                <b-form-input readonly id="inputIO" v-model="form.data_io"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Employee"
                                                label-for           ="inputEmployee">
                                                <b-form-input readonly id="inputEmployee" v-model="form.Employee"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                        <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Status"
                                                label-for           ="inputStatus">
                                                <b-form-input readonly id="inputStatus" v-model="form.status"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                         <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Brand"
                                                label-for           ="inputBrand">
                                                <b-form-input readonly id="inputBrand" v-model="form.brand"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>
                                </b-row>

                            <transition-group name="fade">

                                <b-row v-bind:key=1 v-if="!ReadMore()" align-h="center">
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Artikel"
                                                label-for           ="inputArtikel">
                                                <b-form-input readonly id="inputArtikel" v-model="form.artikel"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Sample"
                                                label-for           ="inputSample">
                                                <b-form-input readonly id="inputSample" v-model="form.sample"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                       <b-col sm="auto">
                                            <b-form-group
                                                label               ="Sub Brand"
                                                label-for           ="inputSubBrand">
                                                <b-form-input readonly id="inputSubBrand" v-model="form.subbrand"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <validation-provider
                                            name="manufacture_date"
                                        >
                                            <b-form-group>
                                                <label for="manufacture_date">Manufacture Date</label>
                                                <b-input-group class="sm-3" aria-describedby= "input-manufacture_date-feedback">
                                                    <b-form-input
                                                        id="manufacture_date"
                                                        v-model="manufacture_date"
                                                        type="text"
                                                        autocomplete="off"

                                                        readonly
                                                    ></b-form-input>
                                                </b-input-group>
                                            </b-form-group>
                                        </validation-provider>
                                        </b-col>
                                </b-row>
                                <b-row v-bind:key=2 v-if="!ReadMore()" align-h="center">

                                       <b-col sm="auto">
                                            <b-form-group
                                                label               ="Style"
                                                label-for           ="inputStyle">
                                                <b-form-input readonly id="inputStyle" v-model="form.style"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Texture"
                                                label-for           ="inputTexture">
                                                <b-form-input readonly id="inputTexture" v-model="form.texture"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <b-form-group
                                                label               ="Material"
                                                label-for           ="inputMaterial">
                                                <b-form-input readonly id="inputMaterial" v-model="form.material"   trim></b-form-input>
                                            </b-form-group>
                                        </b-col>
                                        <b-col sm="auto">
                                            <validation-provider
                                            name="Due Date"
                                        >
                                            <b-form-group
                                                label               ="Due Date"
                                                label-for           ="inputDue_date">
                                                <b-form-input readonly id="inputDue_date" v-model="due_date"   trim></b-form-input>
                                            </b-form-group>
                                        </validation-provider>
                                        </b-col>
                                </b-row>
                                <b-row v-bind:key=3 v-if="!ReadMore()" align-h="start">
                                        <b-col sm="auto" lg="6">
                                            <b-form-group
                                                label               ="Description"
                                                label-for           ="inputDescription">
                                                <b-form-input readonly id="inputDescription" v-model="form.category"   trim></b-form-input>
                                            </b-form-group>
                                         </b-col>

                                </b-row>
                            <!-- END data IO -->
                            </transition-group>
                            <b-row align-h="center">
                                <!-- Button Show Data -->
                                <b-form-checkbox
                                    v-model= "readmore"
                                    name= "checkbox_readmore"
                                    ref='checkbox_readmore'
                                    value="yes"
                                    aria-describedby="input-checkbox_readmore-feedback"
                                    >Show Data
                                </b-form-checkbox>
                                <!-- END Button Show Data -->
                            </b-row>
                            <b-row>
                            <b-col sm="auto" md="6" lg="6">
                                <validation-provider
                                    name="inputQty_Order"
                                    vid="inputQty_Order"
                                    :rules="{required:true}"
                                    v-slot="validationContext">

                                    <b-form-group
                                            label               ="Qty Order"
                                            label-for           ="inputQty_Order">
                                            <b-form-input id="inputQty_Order" v-model="form.qty_order"   trim></b-form-input>
                                        <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-quantity_order-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                        <span id="" style="color:red; font-size:11px">Example : (S):200|(M):400|(L):200 atau (42):300|(41):100|(40):200</span>
                                        <br />
                                    </validation-provider>
                            </b-col>
                            <b-col sm="auto" lg="3">
                                            <b-form-group
                                                label               ="Total Qty"
                                                label-for           ="inputTotal_Qty">
                                                <b-form-input readonly id="inputTotal_Qty" v-model="form.total_qty"   trim></b-form-input>
                                            </b-form-group>
                             </b-col>
                                </b-row>
                                <b-row style="padding-top:15px">
                                    <!-- Upload Document-IO -->
                                        <b-col sm="auto" lg="8">
                                        <!-- <b-col v-if="url_file == null" sm="auto" lg="8"> -->
                                        <validation-provider
                                                name="DocumentIO"
                                                vid="DocumentIO"
                                                >
                                            <b-form-group
                                                label               ="Document IO"
                                                label-for           ="InputDocumentIO">
                                                <div  class="custom-file mb-3">
                                                    <input type="file" class="custom-file-input" id="document_io" ref="document_io" accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .pdf" v-on:change="onChangeFileUpload()"/>
                                                    <label class="custom-file-label" for="document_io">{{file}}</label>
                                                </div>
                                            </b-form-group>
                                        </validation-provider>
                                        </b-col>

                                        <b-col sm="auto" lg="8">
                                        <!-- <b-col v-if="url_image == null" sm="auto" lg="8"> -->
                                         <validation-provider
                                                name="ImageIO"
                                                vid="ImageIO"
                                                >
                                            <b-form-group
                                                label               ="Product Image : "
                                                label-for           ="InputImageIO">
                                                <div class="custom-file mb-3">
                                                <input type="file" class="custom-file-input" id="image_io" ref="image_io" accept="image/*" v-on:change="onChangeImageUpload()"/>
                                                <label class="custom-file-label" for="image_io">{{image}}</label>
                                                </div>
                                            </b-form-group>
                                            </validation-provider>
                                        </b-col>

                                        <!-- <b-col v-if="url_image !== null" sm="auto" lg="6">
                                        <validation-provider
                                                name="DocumentIO"
                                                vid="DocumentIO"
                                                >
                                            <b-col sm="12" lg="12" style="margin:0 auto 0 auto; text-align:center;">
                                                <b-form-group
                                                label               ="Image IO :"
                                                label-for           ="InputImageIO">

                                                   <img style="width:100px; height:auto;" :src="'media/'+url_image" /><br />
                                                <button>
                                                <label for="image_io">Change File</label>
                                                </button>
                                                <input type="file" class="custom-file-input" id="image_io" ref="image_io" accept="image/*" v-on:change="onChangeImageUpload()"/>

                                            </b-form-group>
                                            </b-col>
                                        </validation-provider>
                                        </b-col>    -->
                                    <!-- END Upload Document-IO -->

                                    <!-- Package Size -->
                                        <!-- <b-col sm="auto" lg="4">
                                            <b-form-group
                                                label               ="Package Size"
                                                label-for           ="inputPackage_Size">
                                                <b-form-select v-model="form.package_size" :options="options"></b-form-select>
                                                </b-form-group>
                                        </b-col> -->
                                    <!-- END Package Size -->
                                </b-row>

                                <b-row align-h="center">
                                    <b-col sm="auto" lg="4">
                                    <!-- Date Marker -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="marker"
                                                vid="marker"
                                                :rules="{required:false}"
                                                v-slot="validationContext">

                                                <b-form-group
                                                label               ="Marker"
                                                label-for           ="inputMarker">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.marker"
                                                        no-header
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        />
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-marker-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                </validation-provider>
                                      </b-col>
                                      <!-- END Date Marker -->

                                      <!-- Date ACC -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="acc"
                                                vid="acc"
                                                :rules="{required:false}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="ACC"
                                                label-for           ="inputACC">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.acc"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        />
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-acc-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                </validation-provider>
                                      </b-col>
                                      <!-- END Date ACC -->
                                    </b-col>
                                    <b-col sm="auto" lg="4">

                                      <!-- Date Fabric -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="fabric"
                                                vid="fabric"
                                                :rules="{required:false}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Fabric"
                                                label-for           ="inputFabric">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.fabric"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-fabric-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                            </validation-provider>
                                      </b-col>
                                      <!-- END Date Fabric -->

                                      <!-- Date Fabric -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="fabric_inspection"
                                                vid="fabric_inspection"
                                                :rules="{required:false}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Fabric_Inspection"
                                                label-for           ="inputFabric_Inspection">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.fabric_inspection"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-fabric-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                            </validation-provider>
                                      </b-col>
                                      <!-- END Date Fabric -->
                                    </b-col>

                                      <b-col sm="auto" lg="4">

                                      <!-- Date PPM -->
                                      <b-col sm="auto">
                                            <validation-provider
                                                name="ppm"
                                                vid="ppm"
                                                :rules="{required:false}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="PPM"
                                                label-for           ="inputPPM">
                                                    <VueDatePicker
                                                        class="vd__div"
                                                        v-model="form.ppm"
                                                        no-header
                                                        @onChange="cuttingdate"
                                                        format="DD MMM YYYY"
                                                        min-date="1900-01-01"
                                                        :max-date="max_date"
                                                        placeholder="Choose date"
                                                        />
                                                <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-fabric-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                                </validation-provider>
                                      </b-col>
                                      <!-- END Date PPM -->
                                      </b-col>
                                </b-row>

                                <b-row align-h="center">
                                    <!-- Radio Button Basic -->
                                    <!-- <b-col sm="auto">
                                            <validation-provider
                                                name="basic"
                                                vid="basic"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Basic"
                                                label-for           ="inputBasic">
                                                    <b-form-radio-group
                                                        class="padding"
                                                        v-model="form.basic"
                                                        name="basic"
                                                        ref="opt_basic"
                                                        aria-describedby="input-basic-feedback"
                                                        @change="CheckBasic">

                                                        <b-radio class='radio' value='yes'>Yes</b-radio>
                                                        <b-radio value='no'>No</b-radio>
                                                    </b-form-radio-group>
                                                    <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-basic-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                        </validation-provider>
                                        </b-col> -->
                                        <!-- END Radio Button Basic -->

                                        <!-- Radio Button Addtional -->
                                        <b-col sm="auto">
                                            <validation-provider
                                                name="addtional"
                                                vid="addtional"
                                                :rules="{required:true}"
                                                v-slot="validationContext">
                                                <b-form-group
                                                label               ="Addtional"
                                                label-for           ="inputAddtional">
                                                    <b-form-radio-group
                                                        class="padding"
                                                        v-model="form.addtional"
                                                        name="addtional"
                                                        ref="opt_addtional"
                                                        aria-describedby="input-addtional-feedback"
                                                        @change="CheckAddtional">

                                                        <b-radio class='radio' value='yes'>Yes</b-radio>
                                                        <b-radio value='no'>No</b-radio>
                                                    </b-form-radio-group>
                                                    <b-form-invalid-feedback :state="getValidationState(validationContext)" id="input-addtional-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                        </validation-provider>
                                        </b-col>
                                        <!-- Radio Button Addtional -->
                                </b-row>

                                <b-row align-h="center">
                                    <b-col sm="auto" md="9">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                            <tr style="text-align:center;" class="text-muted">
                                                <th>Process</th>
                                                <th>Subcon</th>
                                                <th>Target/Day</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody id="table-process">
                                                <tr id="select_process0" style="text-align:center;">
                                                    <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder0" v-model="form.process_order[0]" :disabled="arraydisabled[0]" @change="OrderProcess(0)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">

                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy0" v-model="form.process_order_dummy[0]"   trim></b-form-input>

                                                        </validation-provider>
                                                    </td>
                                                </tr>


                                                <tr id="select_process1" class="hidden" style="text-align:center;">
                                                <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder1" v-model="form.process_order[1]" :disabled="arraydisabled[1]" @change="OrderProcess(1)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">

                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy1" v-model="form.process_order_dummy[1]"   trim></b-form-input>

                                                        </validation-provider>
                                                </td>
                                                </tr>

                                                <tr id="select_process2" class="hidden" style="text-align:center;">
                                                <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder2" v-model="form.process_order[2]" :disabled="arraydisabled[2]" @change="OrderProcess(2)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">
                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy2" v-model="form.process_order_dummy[2]"   trim></b-form-input>

                                                        </validation-provider>
                                                </td>
                                                </tr>


                                                <tr id="select_process3" class="hidden" style="text-align:center;">
                                                <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder3" v-model="form.process_order[3]" :disabled="arraydisabled[3]" @change="OrderProcess(3)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">
                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy3" v-model="form.process_order_dummy[3]"   trim></b-form-input>

                                                        </validation-provider>
                                                </td>
                                                </tr>


                                                <tr id="select_process4" class="hidden" style="text-align:center;">
                                                <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder4" v-model="form.process_order[4]" :disabled="arraydisabled[4]" @change="OrderProcess(4)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">
                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy4" v-model="form.process_order_dummy[4]"   trim></b-form-input>

                                                        </validation-provider>
                                                </td>
                                                </tr>


                                                <tr id="select_process5" class="hidden" style="text-align:center;">
                                                <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder5" v-model="form.process_order[5]" :disabled="arraydisabled[5]" @change="OrderProcess(5)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">
                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy5" v-model="form.process_order_dummy[5]"   trim></b-form-input>

                                                        </validation-provider>
                                                </td>
                                                </tr>


                                                <tr id="select_process6" class="hidden" style="text-align:center;">
                                                <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder6" v-model="form.process_order[6]" :disabled="arraydisabled[6]" @change="OrderProcess(6)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">
                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy6" v-model="form.process_order_dummy[6]"   trim></b-form-input>

                                                        </validation-provider>
                                                </td>
                                                </tr>


                                                <tr id="select_process7" class="hidden" style="text-align:center;">
                                                <td>
                                                        <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" id="NameProcessorder7" v-model="form.process_order[7]" :disabled="arraydisabled[7]" @change="OrderProcess(7)" :options="optionsProcess" placeholder="Choose Process" style="top:1px;">
                                                        </b-form-select>
                                                        <b-form-input class="select-custom hidden" type="text" readonly id="NameProcessorderDummy7" v-model="form.process_order_dummy[7]"   trim></b-form-input>

                                                        </validation-provider>
                                                </td>
                                                </tr>
                                                <td id="delete0" class="hidden">
                                                    <span id="del0" class="delete" @click="DeleteTable(0)">Delete</span>
                                                </td>
                                                <td id="delete1" class="hidden">
                                                    <span id="del1" class="delete" @click="DeleteTable(1)">Delete</span>
                                                </td>
                                                <td id="delete2" class="hidden">
                                                    <span id="del2" class="delete" @click="DeleteTable(2)">Delete</span>
                                                </td>
                                                <td id="delete3" class="hidden">
                                                    <span id="del3" class="delete" @click="DeleteTable(3)">Delete</span>
                                                </td>
                                                <td id="delete4" class="hidden">
                                                    <span id="del4" class="delete" @click="DeleteTable(4)">Delete</span>
                                                </td>
                                                <td id="delete5" class="hidden">
                                                    <span id="del5" class="delete" @click="DeleteTable(5)">Delete</span>
                                                </td>
                                                <td id="delete6" class="hidden">
                                                    <span id="del6" class="delete" @click="DeleteTable(6)">Delete</span>
                                                </td>
                                                <td id="delete7" class="hidden">
                                                    <span id="del7" class="delete" @click="DeleteTable(7)">Delete</span>
                                                </td>
                                                    <td id="select_cutting" class="hidden select-form">
                                                    <validation-provider
                                                        name="select_cutting"
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.cutting_id"
                                                            class="form-custom"
                                                            ref="opt_cutting"
                                                            :clearable=false
                                                            @input="targetPerDayCutting"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.cutting_id" @change="targetPerDayCutting()" ref="opt_cutting" placeholder="Choose Department Code" style="top:1px;">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id"  :selected="form.cutting_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                        </validation-provider>
                                                    </td>
                                                    <td id="target_cutting" class="hidden">
                                                       <validation-provider
                                                        name="target_cutting"
                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" id="target_cutting" v-model="form.cutting_target"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>



                                                    <td id="select_embroidery" class="hidden select-form">
                                                        <validation-provider
                                                        name="select_embroidery"
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.embroidery_id"
                                                            class="form-custom"
                                                            ref="opt_embroidery"
                                                            :clearable=false
                                                            @input="targetPerDayEmbroidery"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.embroidery_id" @change="targetPerDayEmbroidery()" ref="opt_embroidery" placeholder="Choose Department Code">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.embroidery_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                        </validation-provider>
                                                    </td>
                                                    <td id="target_embroidery" class="hidden">
                                                        <validation-provider
                                                        name="target_embroidery"
                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1"  id="target_embroidery" v-model="form.embroidery_target"  trim></b-form-input>
                                                        </validation-provider>
                                                    </td>

                                                    <td id="select_printing" class="hidden select-form">
                                                    <validation-provider
                                                        name="select_printing"
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.printing_id"
                                                            class="form-custom"
                                                            ref="opt_printing"
                                                            :clearable=false
                                                            @input="targetPerDayPrinting"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.printing_id" ref="opt_printing" @change="targetPerDayPrinting()" placeholder="Choose Department Code">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.printing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                        </validation-provider>
                                                    </td>
                                                    <td id="target_printing" class="hidden">
                                                    <validation-provider
                                                        name="target_printing"
                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" id="target_printing" v-model="form.printing_target"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>

                                                    <td id="select_emblem" class="hidden select-form">
                                                    <validation-provider
                                                        name="select_emblem"
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.emblem_id"
                                                            class="form-custom"
                                                            ref="opt_emblem"
                                                            :clearable=false
                                                            @input="targetPerDayEmblem"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.emblem_id" ref="opt_emblem" @change="targetPerDayEmblem()" placeholder="Choose Department Code">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.emblem_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                        </validation-provider>
                                                    </td>
                                                    <td id="target_emblem" class="hidden">
                                                    <validation-provider
                                                        name="target_emblem"
                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" id="target_emblem" v-model="form.emblem_target"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>


                                                    <td id="select_sewing" class="hidden select-form">
                                                    <validation-provider
                                                        name="select_sewing"
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.sewing_id"
                                                            class="form-custom"
                                                            ref="opt_emblem"
                                                            :clearable=false
                                                            @input="targetPerDaySewing"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.sewing_id" ref="opt_sewing" @change="targetPerDaySewing()" placeholder="Choose Department Code">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.sewing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                       </validation-provider>
                                                    </td>
                                                    <td id="target_sewing" class="hidden">
                                                    <validation-provider
                                                        name="target_sewing"

                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" id="target_sewing" v-model="form.sewing_target"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>

                                                    <td id="select_washing" class="hidden select-form">
                                                    <validation-provider
                                                        name="select_washing("
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.washing_id"
                                                            class="form-custom"
                                                            ref="opt_washing"
                                                            :clearable=false
                                                            @input="targetPerDayWashing"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.washing_id" ref="opt_washing" @change="targetPerDayWashing()" placeholder="Choose Department Code">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.washing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                        </validation-provider>
                                                    </td>
                                                    <td id="target_washing" class="hidden">
                                                    <validation-provider
                                                        name="target_washing"

                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" id="target_washing" v-model="form.washing_target"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>



                                                    <td id="select_finishing" class="hidden select-form">
                                                    <validation-provider
                                                        name="select_finishing"
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.finishing_id"
                                                            class="form-custom"
                                                            ref="opt_finishing"
                                                            :clearable=false
                                                            @input="targetPerDayFinishing"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.finishing_id" @change="targetPerDayFinishing()" ref="opt_finishing" placeholder="Choose Department Code">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.finishing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                        </validation-provider>
                                                    </td>
                                                    <td id="target_finishing" class="hidden">
                                                        <validation-provider
                                                        name="target_finishing"

                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" id="target_finishing" v-model="form.finishing_target"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>



                                                    <td id="select_packing" class="hidden select-form">
                                                    <validation-provider
                                                        name="select_packing"
                                                        >
                                                        <v-select
                                                            label="nama_subcon"
                                                            :reduce="(data) => data.id"
                                                            :options="OptionsSubcon"
                                                            v-model="form.packing_id"
                                                            class="form-custom"
                                                            ref="opt_packing"
                                                            :clearable=false
                                                            @input="targetPerDayPacking"
                                                        ></v-select>
                                                        <!-- <b-form-select class="form-custom" v-model="form.packing_id" @change="targetPerDayPacking()" ref="opt_packing" placeholder="">

                                                        <option :value="null">-- Choose Subcon --</option>

                                                        <option v-for="(row,index) in stMstSub" :key="index" :value="row.id" :selected="form.packing_id">
                                                            {{row.nama_subcon}}
                                                        </option>
                                                        </b-form-select> -->
                                                        </validation-provider>
                                                    </td>
                                                    <td id="target_packing" class="hidden">
                                                        <validation-provider
                                                        name="target_packing"

                                                        >
                                                        <b-form-input class="select-custom" type="number" :readonly="read_id >= 1" id="target_packing" v-model="form.packing_target"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>


                                            </tbody>
                                        </table>
                                        <span id="warning" class="hidden" style="color:red; font-size:12px">Urutan Process belum benar, mohon diurutkan kembali Processnya</span>
                                        <br />
                                        <span id="warning-1" class="hidden" style="color:green; font-size:10px"></span>
                                        <br />
                                        <span id="warning-2" class="hidden" style="color:green; font-size:10px"></span>
                                            <b-row align-h="end">
                                                <b-col sm="2">
                                                    <b-button block  @click="AddProcess()" :disabled="button_add" ref="save" type="button" variant="primary">Add</b-button>
                                                </b-col>
                                            </b-row>
                                    </div>
                                    </b-col>
                                </b-row>
                                <b-row align-h="center">
                                    <b-col sm="4">
                                        <b-button block  :disabled="button_save" @click="doApproval()" ref="save" type="submit" variant="success">Save</b-button>
                                    </b-col>
                                </b-row>
                            </form>
                            <!-- {{form.process_order}}
                            {{order_process}}<br />
                            {{form.cutting_id}} -->
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import apiPPCM from '@/store/ppm-form/ppm.js';
    import apiUpload from '@/store/ppm-form/ppm.js';
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";
    import JsonExcel from 'vue-json-excel'
    // import vue loading
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);
    import axios from 'axios'

    export default {
       name: 'InputFormPPM',
       components: {
        vSelect,
       },

        data() {
           const now = new Date()

            // create year-month-day
            const optyear = { year: 'numeric'};
            const year = now.toLocaleString('id-ID', optyear)
            const optmonth = { month: '2-digit'};
            const month = now.toLocaleString('id-ID', optmonth)
            const optday = { day: '2-digit'};
            const day = now.toLocaleString('id-ID', optday)
            const today = year+'-'+month+'-'+day

            // create hour:min:sec
            const time = now.toString().slice(16,24)
            // const opthour = { hour: '2-digit'};
            // const optmin = { minute: '2-digit'};
            // const optsec = { second: '2-digit'};
            // const hour = now.toLocaleString('id-ID', opthour ,{ hour12: false })
            // const min = now.toLocaleString('id-ID', optmin ,{ hour12: false })
            // const sec = now.toLocaleString('id-ID', optsec ,{ hour12: false })
            // const time = hour+':'+min+':'+sec

            return{
                opt_process: [
                ],
                formName: 'InputPPM',
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                errorMessage: null,
                max_date:today,
                OptionsSubcon:[],
                time:time,
                today:today,
                manufacture_date:null,
                due_date:null,
                    form : {
                    data_io:null,
                    Employee:null,
                    status:null,
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    manufacture_date:null,
                    description:null,
                    style:null,
                    texture:null,
                    material:null,
                    category:null,
                    sleeve:null,
                    qty_order:null,
                    total_qty:null,
                    due_date:null,
                    package_size:null,
                    basic:null,
                    addtional:null,
                    marker:today,
                    acc:today,
                    fabric:today,
                    fabric_inspection:today,
                    ppm:today,
                    myfile:null,
                    cutting_id :null,
                    cutting_subcon :null,
                    cutting_target :null,
                    cutting_target_dummy :null,
                    cutting_date:null,
                    cutting_date_plan:null,
                    cutting_end_date:null,
                    cutting_end_date_plan:null,
                    embroidery_id:null,
                    embroidery_subcon :null,
                    embroidery_target :null,
                    embroidery_target_dummy :null,
                    embroidery_date :null,
                    embroidery_date_plan:null,
                    embroidery_end_date:null,
                    embroidery_end_date_plan:null,
                    printing_id :null,
                    printing_subcon :null,
                    printing_target :null,
                    printing_target_dummy :null,
                    printing_date :null,
                    printing_date_plan:null,
                    printing_end_date:null,
                    printing_end_date_plan:null,
                    emblem_id :null,
                    emblem_subcon :null,
                    emblem_target :null,
                    emblem_target_dummy :null,
                    emblem_date :null,
                    emblem_date_plan:null,
                    emblem_end_date:null,
                    emblem_end_date_plan:null,
                    sewing_id :null,
                    sewing_subcon :null,
                    sewing_target :null,
                    sewing_target_dummy :null,
                    sewing_date:null,
                    sewing_date_plan:null,
                    sewing_end_date:null,
                    sewing_end_date_plan:null,
                    washing_id :null,
                    washing_subcon :null,
                    washing_target :null,
                    washing_target_dummy :null,
                    washing_date :null,
                    washing_date_plan:null,
                    washing_end_date:null,
                    washing_end_date_plan:null,
                    finishing_id :null,
                    finishing_subcon :null,
                    finishing_target :null,
                    finishing_target_dummy :null,
                    finishing_date :null,
                    finishing_date_plan:null,
                    finishing_end_date:null,
                    finishing_end_date_plan:null,
                    packing_id :null,
                    packing_subcon :null,
                    packing_target :null,
                    packing_target_dummy :null,
                    packing_date :null,
                    packing_date_plan:null,
                    packing_end_date:null,
                    packing_end_date_plan:null,
                    calc_date:null,
                    process_order:[],
                    process_order_dummy:[],
                    plan_date_delivery:null,
                    plan_end_date_delivery:null,
                    plan_date_qc_final:null,
                    plan_end_date_qc_final:null,

                    },
                confirm_calc_date:0,
                selectedDept:null,
                form_api:[],
                read_id: null,
                cat_id_cut:null,
                dis_id_cut:null,
                cat_id_sew:null,
                dis_id_sew:null,
                cat_id_embr:null,
                dis_id_embr:null,
                cat_id_pri:null,
                dis_id_pri:null,
                cat_id_embl:null,
                dis_id_embl:null,
                cat_id_wash:null,
                dis_id_wash:null,
                cat_id_fin:null,
                dis_id_fin:null,
                cat_id_pack:null,
                dis_id_pack:null,
                isActive:true,
                readmore:null,
                append_process:0,
                order_process:[],
                arraydisabled:[],
                button_add:false,
                button_save:true,
                process_order:null,
                arr_process:0,
                err_process:0,
                qty_process:10,
                document_io:null,
                image_io:null,
                data_file:null,
                url_file:null,
                file:'Choose File',
                data_image:null,
                url_image:null,
                image:'Choose Image',
                boxTwo:'',
                data_subcon:null,
                dataDeliver:null,
                dataReceive:null,
                data_deliv:null,
                data_rec:null,
                data_ins:null,
                datainst:null,
                shift_cutting:[],
                shift_sewing:[],
                shift_finishing:[],
                shift_packing:[],
                shift_printing:[],
                shift_embroidery:[],
                shift_emblem:[],
                shift_washing:[],


            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stIoPPM','stIoPPMCount','stMstSub','stFileUpload','stFileUploadCount','stImageUpload','stImageUploadCount','stIoSubconDaily','stIoSubconDailyCount','stPPCMDelvrData','stPPCMDelvrDataCount','stPPCMReceiveData','stPPCMReceiveDataCount','stPPCMInsData','stPPCMInsDataCount']),
            ...mapState('master_calender', ['stMstCldrCutting','stMstCldrSewing','stMstCldrFinishing','stMstCldrPacking','stMstCldrPrinting','stMstCldrEmbroidery','stMstCldrEmblem','stMstCldrWashing']),
            optionsProcess() {
                let filteredProcess = []
                this.opt_process.forEach(element => {
                    //masukin value2 yang masih boleh dipilih ke variabel filteredProcess
                    if(!this.form.process_order.includes(element)) {
                        filteredProcess.push(element)
                        // console.log(filteredProcess)
                    }
                });
                return filteredProcess
            },

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getInputIOPPM','getDataFormMasterSubcon','DataImageUpload','DataFileUpload','getSubconDailyData','getPPCMReceiveFormData','getPPCMDeliveryFormData','getPPCMInspectionFormData']),
            ...mapActions('master_calender', ['GetDateCalculation','getSubconCalender','getMasterCalenderCutting','getMasterCalenderSewing','getMasterCalenderFinishing','getMasterCalenderPacking','getMasterCalenderPrinting','getMasterCalenderEmbroidery','getMasterCalenderEmblem','getMasterCalenderWashing','clearState']),
            FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 500);
                }
            },
            Setdata_IO() {
                // let data_IO      = this.$route.params.data_io
                let data_IO      = this.$route.query.io
                let userData     = this.userData
                this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardPPCM'})
                }
                if (this.form.data_io != null) {
                    this.DataImageUpload(this.form.data_io).then().catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardPPCM"})
                        }
                    })
                    this.DataFileUpload(this.form.data_io).then().catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardPPCM"})
                        }
                    })
                    this.getSubconDailyData(this.form.data_io).then().catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardPPCM"})
                        }
                    })
                    this.getPPCMReceiveFormData(this.form.data_io).then().catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardPPCM"})
                        }
                    })
                    this.getPPCMInspectionFormData(this.form.data_io).then().catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardPPCM"})
                        }
                    })
                    this.getPPCMDeliveryFormData(this.form.data_io).then(rsp => {
                        this.Loading('start')
                        this.getInputIOPPM(this.form.data_io)
                        .then(rsp => {
                            this.GetdataForm()
                        }).catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                        .finally(()=>{
                            this.Loading('stop')
                        })
                    }).catch(err => {
                        if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardPPCM"})
                        }
                    })
                }
            },

            onChangeFileUpload(){
                this.document_io = this.$refs.document_io.files[0];
                // this.document_io.name = String(this.form.data_io)
                // console.log(this.document_io)
                this.file = 'Doc_'+this.form.data_io+'_'+this.form.artikel+'.'+this.document_io.name.slice((this.document_io.name.lastIndexOf(".") - 1 >>> 0) + 2);
                // console.log(this.file)
                this.data_file = this.file

            },
            onChangeImageUpload(){
                this.image_io = this.$refs.image_io.files[0];
                // console.log(this.document_io)
                this.image = 'Img_'+this.form.data_io+'_'+this.form.artikel+'.'+this.image_io.name.slice((this.image_io.name.lastIndexOf(".") - 1 >>> 0) + 2);
                this.data_image = this.image

            },
            AddProcess() {
                if (this.append_process >= 0) {
                document.getElementById("del"+(this.append_process)).classList.add("hidden");
                }
                this.append_process += 1;
                //console.log("append process : "+this.append_process);
                let table_process = document.getElementById("select_process"+this.append_process);
                document.getElementById("table-process").appendChild(table_process);
                table_process.classList.remove("hidden");

                // console.log(table_process);
                if (this.form.addtional == 'yes') {
                    if (this.append_process >= 7) {
                        this.button_add = true;
                    }
                }
                if (this.form.addtional == 'no') {
                    if (this.append_process >= 3) {
                        this.button_add = true;
                    }
                }
            },
            DeleteTable(value) {
                this.arraydisabled[value] = false;
                //console.log('delete_table: '+value);
                let select_cutting = document.getElementById("select_cutting");
                let target_cutting = document.getElementById("target_cutting");
                let select_sewing = document.getElementById("select_sewing");
                let target_sewing = document.getElementById("target_sewing");
                let select_finishing = document.getElementById("select_finishing");
                let target_finishing = document.getElementById("target_finishing");
                let select_packing = document.getElementById("select_packing");
                let target_packing = document.getElementById("target_packing");
                let select_print = document.getElementById("select_printing");
                let target_print = document.getElementById("target_printing");
                let select_embroidery = document.getElementById("select_embroidery");
                let target_embroidery = document.getElementById("target_embroidery");
                let select_emblem = document.getElementById("select_emblem");
                let target_emblem = document.getElementById("target_emblem");
                let select_washing = document.getElementById("select_washing");
                let target_washing = document.getElementById("target_washing");
                let id_process = document.getElementById("select_process"+value);
                id_process.classList.add("hidden");
                let delete_process = document.getElementById("delete"+value);
                delete_process.classList.add("hidden");
                if (this.append_process >= 1) {
                document.getElementById("del"+(value-1)).classList.remove("hidden");
                }
                this.append_process -= 1;
                if (this.append_process == 0) {
                    this.arr_process = 0;
                    this.err_process = 0;
                }
                if (this.form.process_order[value] == 'a') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_cutting.classList.add("hidden");
                    target_cutting.classList.add("hidden");
                    this.$set(this.opt_process, 0, { value: 'a', text: 'Cutting', disabled: false});
                    this.form.process_order.splice(this.form.process_order.indexOf('a'), 1);
                    this.process_order = this.form.process_order;

                    this.form.cutting_id = null
                    this.form.cutting_subcon = null
                    this.form.cutting_target = null
                }
                if (this.form.process_order[value] == 'b') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_sewing.classList.add("hidden");
                    target_sewing.classList.add("hidden");
                    this.$set(this.opt_process, 1, { value: 'b', text: 'Sewing', disabled: false });
                    if (this.arr_process > 0) {
                    this.arr_process = this.arr_process - 1;
                    }
                    this.form.process_order.splice(this.form.process_order.indexOf('b'), 1);
                    this.process_order = this.form.process_order;

                    this.form.sewing_id = null
                    this.form.sewing_subcon = null
                    this.form.sewing_target = null
                }
                if (this.form.process_order[value] == 'c') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_finishing.classList.add("hidden");
                    target_finishing.classList.add("hidden");
                    this.$set(this.opt_process, 2, { value: 'c', text: 'Finishing', disabled: false });
                    if (this.arr_process > 0) {
                    this.arr_process = this.arr_process - 1;
                    }
                    this.form.process_order.splice(this.form.process_order.indexOf('c'), 1);
                    this.process_order = this.form.process_order;

                    this.form.finishing_id = null
                    this.form.finishing_subcon = null
                    this.form.finishing_target = null
                }
                if (this.form.process_order[value] == 'd') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_packing.classList.add("hidden");
                    target_packing.classList.add("hidden");
                    this.$set(this.opt_process, 3, { value: 'd', text: 'Packing', disabled: false });
                    if (this.arr_process > 0) {
                    this.arr_process = this.arr_process - 10;
                    }
                    if (this.err_process > 0) {
                    this.err_process = this.err_process - 10;
                    }
                    this.form.process_order.splice(this.form.process_order.indexOf('d'), 1);
                    this.process_order = this.form.process_order;

                    this.form.packing_id = null
                    this.form.packing_subcon = null
                    this.form.packing_target = null
                }
                if (this.form.process_order[value] == 'e') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_print.classList.add("hidden");
                    target_print.classList.add("hidden");
                    this.$set(this.opt_process, 4, { value: 'e', text: 'Print', disabled: false });
                    if (this.arr_process > 9) {
                        this.arr_process -= 10;
                    }
                    this.form.process_order.splice(this.form.process_order.indexOf('e'), 1);
                    this.process_order = this.form.process_order;

                    this.form.printing_id = null
                    this.form.printing_subcon = null
                    this.form.printing_target = null

                }
                if (this.form.process_order[value] == 'f') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_embroidery.classList.add("hidden");
                    target_embroidery.classList.add("hidden");
                    this.$set(this.opt_process, 5, { value: 'f', text: 'Embroidery', disabled: false });
                    if (this.arr_process > 9) {
                        this.arr_process -= 10;
                    }
                    this.form.process_order.splice(this.form.process_order.indexOf('f'), 1);
                    this.process_order = this.form.process_order;

                    this.form.embroidery_id = null
                    this.form.embroidery_subcon = null
                    this.form.embroidery_target = null

                }
                if (this.form.process_order[value] == 'g') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_emblem.classList.add("hidden");
                    target_emblem.classList.add("hidden");
                    this.$set(this.opt_process, 6, { value: 'g', text: 'Emblem', disabled: false });
                    if (this.arr_process > 9) {
                        this.arr_process -= 10;
                    }
                    this.form.process_order.splice(this.form.process_order.indexOf('g'), 1);
                    this.process_order = this.form.process_order;

                    this.form.emblem_id = null
                    this.form.emblem_subcon = null
                    this.form.emblem_target = null

                }
                if (this.form.process_order[value] == 'h') {
                    this.form.process_order_dummy[value] = null;
                    document.getElementById("NameProcessorder"+value).classList.remove("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.add("hidden");
                    select_washing.classList.add("hidden");
                    target_washing.classList.add("hidden");
                    this.$set(this.opt_process, 7, { value: 'h', text: 'Washing', disabled: false });
                    if (this.arr_process > 9) {
                        this.arr_process -= 10;
                    }
                    this.form.process_order.splice(this.form.process_order.indexOf('h'), 1);
                    this.process_order = this.form.process_order;

                    this.form.washing_id = null
                    this.form.washing_subcon = null
                    this.form.washing_target = null

                }
                this.form.process_order[value] = null;
                this.order_process[value] = null;
                //console.log('form process'+this.form.process_order[value]);
                //console.log('order process'+this.order_process[value]);
                if (this.form.addtional == 'yes') {
                    if (this.append_process <= 6) {
                        this.button_add = false;
                    }
                }
                if (this.form.addtional == 'no') {
                    if (this.append_process <= 2) {
                        this.button_add = false;
                    }
                }
            },
            OrderProcess: function(value) {
                console.log(this.form.process_order)
                this.order_process[value] = this.form.process_order[value];
                let select_cutting = document.getElementById("select_cutting");
                let target_cutting = document.getElementById("target_cutting");
                let select_sewing = document.getElementById("select_sewing");
                let target_sewing = document.getElementById("target_sewing");
                let select_finishing = document.getElementById("select_finishing");
                let target_finishing = document.getElementById("target_finishing");
                let select_packing = document.getElementById("select_packing");
                let target_packing = document.getElementById("target_packing");
                let select_print = document.getElementById("select_printing");
                let target_print = document.getElementById("target_printing");
                let select_embroidery = document.getElementById("select_embroidery");
                let target_embroidery = document.getElementById("target_embroidery");
                let select_emblem = document.getElementById("select_emblem");
                let target_emblem = document.getElementById("target_emblem");
                let select_washing = document.getElementById("select_washing");
                let target_washing = document.getElementById("target_washing");
                if (this.form.process_order[value] == 'a') {
                    this.form.process_order_dummy[value] = 'Cutting';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_cutting);
                    document.getElementById("select_process"+value).appendChild(target_cutting);
                    select_cutting.classList.remove("hidden");
                    target_cutting.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(0);
                    if (this.arr_process == 0) {
                    this.err_process = 1;
                    this.arr_process = 0;
                    } else {
                        this.err_process = 0;
                        this.arr_process += 0;
                    }
                }
                if (this.form.process_order[value] == 'b') {
                    this.form.process_order_dummy[value] = 'Sewing';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_sewing);
                    document.getElementById("select_process"+value).appendChild(target_sewing);
                    select_sewing.classList.remove("hidden");
                    target_sewing.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(1);
                    if (this.arr_process == 0) {
                        if (this.err_process == 1) {
                            this.err_process = 2;
                            this.arr_process += 1;
                        } else if (this.err_process == 0) {
                            this.err_process = 2;
                            this.arr_process += 1;
                        } else {
                            this.err_process = 0;
                            this.arr_process += 1;
                        }
                    } else if (this.err_process == 3) {
                        this.err_process = 0;
                        this.arr_process += 1;
                    } else {
                        this.err_process = 2;
                        this.arr_process += 1;
                    }

                    if (this.form.cutting_subcon == null || this.form.cutting_subcon == '') {
                        // console.log('');
                    }
                    else {
                    if (this.form.basic == 'yes') {
                        for(let b = 0; b < this.stMstSub.length; b++) {
                            if(this.stMstSub[b].id == this.form.sewing_id) {
                                this.form.sewing_target = this.stMstSub[b].sewing;
                            }
                        }
                    }
                    // console.log(this.form.sewing_subcon);
                    }
                }
                if (this.form.process_order[value] == 'c') {
                    this.form.process_order_dummy[value] = 'Finishing';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_finishing);
                    document.getElementById("select_process"+value).appendChild(target_finishing);
                    select_finishing.classList.remove("hidden");
                    target_finishing.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(2);
                    if (this.arr_process == 0) {
                        if (this.err_process == 1) {
                            this.err_process = 3;
                            this.arr_process += 1;
                        } else if (this.err_process == 0) {
                            this.err_process = 3;
                            this.arr_process += 1;
                        } else {
                            this.err_process = 0;
                            this.arr_process += 1;
                        }
                    } else if (this.err_process == 0) {
                        this.err_process = 0;
                        this.arr_process += 1;
                    } else {
                        this.err_process = 3;
                        this.arr_process += 1;
                    }

                    if (this.form.cutting_subcon == null || this.form.cutting_subcon == '') {
                        //console.log('');
                    }
                    else {
                    if (this.form.basic == 'yes') {
                        for(let b = 0; b < this.stMstSub.length; b++) {
                            if(this.stMstSub[b].id == this.form.finishing_id) {
                                this.form.finishing_target = this.stMstSub[b].finishing;
                            }
                        }

                    }
                    // console.log(this.form.finishing_subcon);
                    }
                }
                if (this.form.process_order[value] == 'd') {
                    this.form.process_order_dummy[value] = 'Packing';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_packing);
                    document.getElementById("select_process"+value).appendChild(target_packing);
                    select_packing.classList.remove("hidden");
                    target_packing.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(3);
                    this.err_process += 10;
                    this.arr_process += 10;

                    if (this.form.cutting_subcon == null || this.form.cutting_subcon == '') {
                        //console.log('');
                    }
                    else {
                    if (this.form.basic == 'yes') {
                        for(let b = 0; b < this.stMstSub.length; b++) {
                            if(this.stMstSub[b].id == this.form.packing) {
                                this.form.packing_target = this.stMstSub[b].packing;
                            }
                        }

                    }

                    }
                }
                if (this.form.process_order[value] == 'e') {
                    this.form.process_order_dummy[value] = 'Print';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_print);
                    document.getElementById("select_process"+value).appendChild(target_print);
                    select_print.classList.remove("hidden");
                    target_print.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(4);
                    if (this.arr_process > 9) {
                        this.arr_process += 10;
                    }
                }
                if (this.form.process_order[value] == 'f') {
                    this.form.process_order_dummy[value] = 'Embroidery';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_embroidery);
                    document.getElementById("select_process"+value).appendChild(target_embroidery);
                    select_embroidery.classList.remove("hidden");
                    target_embroidery.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(5);
                    if (this.arr_process > 9) {
                        this.arr_process += 10;
                    }

                }
                if (this.form.process_order[value] == 'g') {
                    this.form.process_order_dummy[value] = 'Emblem';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_emblem);
                    document.getElementById("select_process"+value).appendChild(target_emblem);
                    select_emblem.classList.remove("hidden");
                    target_emblem.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(6);
                    if (this.arr_process > 9) {
                        this.arr_process += 10;
                    }

                }
                if (this.form.process_order[value] == 'h') {
                    this.form.process_order_dummy[value] = 'Washing';
                    document.getElementById("NameProcessorder"+value).classList.add("hidden");
                    document.getElementById("NameProcessorderDummy"+value).classList.remove("hidden");
                    let select_process = document.getElementById("select_process"+value);
                    document.getElementById("select_process"+value).appendChild(select_washing);
                    document.getElementById("select_process"+value).appendChild(target_washing);
                    select_washing.classList.remove("hidden");
                    target_washing.classList.remove("hidden");
                    let delete_process = document.getElementById("delete"+value);
                    document.getElementById("select_process"+value).appendChild(delete_process);
                    delete_process.classList.remove("hidden");
                    this.disabledArray(7);
                     if (this.arr_process > 9) {
                        this.arr_process += 10;
                    }

                }
                this.arraydisabled[value] = true;
                //console.log('order_process: '+value);
            },
            disabledArray(value){
                //console.log('removing form element', value);
                if (value == 0) {
                this.$set(this.opt_process, value, { value: 'a', text: '', disabled: true });
                }
                if (value == 1) {
                this.$set(this.opt_process, value, { value: 'b', text: '', disabled: true });
                }
                if (value == 2) {
                this.$set(this.opt_process, value, { value: 'c', text: '', disabled: true });
                }
                if (value == 3) {
                this.$set(this.opt_process, value, { value: 'd', text: '', disabled: true });
                }
                if (value == 4) {
                this.$set(this.opt_process, value, { value: 'e', text: '', disabled: true });
                }
                if (value == 5) {
                this.$set(this.opt_process, value, { value: 'f', text: '', disabled: true });
                }
                if (value == 6) {
                this.$set(this.opt_process, value, { value: 'g', text: '', disabled: true });
                }
                if (value == 7) {
                this.$set(this.opt_process, value, { value: 'h', text: '', disabled: true });
                }
            },
            BasicData(param) {
                let strbasic = param.substring(5,4);
                if (strbasic == 'X' || strbasic == 'x') {
                    this.form.basic = 'yes';
                    this.read_id = 1;
                }
                else {
                    this.form.basic = 'no';
                    this.read_id = 0;
                }
            },
            TotalQty(param) {
                let count = 0;
                let char = [];
                let ord_size = [];
                if (param == null || param == '' || param == undefined) {
                    // this.form.total_qty = 0
                } else {
                for (let size = 0;size<param.length; size++) {
                    if (param.charAt(size) == '(') {
                        char[count] = ((param.substring(size + 1,size+6)).split(")")[0]);
                    }
                    if (param.charAt(size) == ')') {
                        ord_size[count] = ((param.substring(size + 2,size+6)).split("|")[0]);
                        this.form.total_qty += parseInt(ord_size[count])
                        count++;
                    }
                }
                // console.log('param: '+char);
                // console.log('count: '+count);
                // console.log('ord_size: '+ord_size);
                }
            },
            ReadMore(){
                if(this.readmore == 'yes'){
                    return false;
                }
                return true
            },

            CheckAddtional() {
                console.log('addtional: '+this.form.addtional)
                if (this.form.addtional == 'yes') {
                    this.isActive = true;
                    this.opt_process.push(
                        { value: 'e', text: 'Print', disabled: false },
                        { value: 'f', text: 'Embroidery', disabled: false },
                        { value: 'g', text: 'Emblem', disabled: false },
                        { value: 'h', text: 'Washing', disabled: false },
                    );
                    if (this.append_process <= 6) {
                        this.button_add = false;
                    }
                    if (this.append_process >= 7) {
                        this.button_add = true;
                    }
                } else {
                    if (this.append_process <= 2) {
                        this.button_add = false;
                    }
                    if (this.append_process >= 3) {
                        this.button_add = true;
                    }
                    if (this.append_process > 6) {
                    this.DeleteTable(7);

                    }
                    if (this.append_process > 5) {
                    this.DeleteTable(6);

                    }
                    if (this.append_process > 4) {
                    this.DeleteTable(5);

                    }
                    if (this.append_process > 3) {
                    this.DeleteTable(4);

                    }
                    if (this.append_process > 2) {
                    this.DeleteTable(3);
                    }
                    if (this.append_process > 1) {
                    this.DeleteTable(2);
                    }
                    if (this.append_process > 0) {
                    this.DeleteTable(1);
                    }
                    if (this.append_process >= 0) {
                    this.DeleteTable(0);
                    }
                    this.isActive = false;

                    this.form.cutting_id = null;
                    this.form.cutting_subcon = null;
                    this.form.cutting_target = null;
                    this.form.cutting_target_dummy = null;

                    this.form.sewing_id = null;
                    this.form.sewing_subcon = null;
                    this.form.sewing_target = null;
                    this.form.sewing_target_dummy = null;

                    this.form.finishing_id = null;
                    this.form.finishing_subcon = null;
                    this.form.finishing_target = null;
                    this.form.finishing_target_dummy = null;

                    this.form.packing_id = null;
                    this.form.packing_subcon = null;
                    this.form.packing_target = null;
                    this.form.packing_target_dummy = null;

                    // this.form.checkbox_embroidery = null,
                    this.form.embroidery_id = null;
                    this.form.embroidery_subcon = null;
                    this.form.embroidery_target = null;
                    this.form.embroidery_target_dummy = null;

                    // this.form.checkbox_washing= null;
                    this.form.washing_id= null;
                    this.form.washing_subcon = null;
                    this.form.washing_target = null;
                    this.form.washing_target_dummy = null;

                    // this.form.checkbox_printing = null;
                    this.form.printing_id = null;
                    this.form.printing_subcon = null;
                    this.form.printing_target = null;
                    this.form.printing_target_dummy = null;

                    // this.form.checkbox_emblem = null;
                    this.form.emblem_id = null;
                    this.form.emblem_subcon = null;
                    this.form.emblem_target = null;
                    this.form.emblem_target_dummy = null;
                    this.opt_process = [
                        { value: 'a', text: 'Cutting' ,disabled: false },
                        { value: 'b', text: 'Sewing' ,disabled: false },
                        { value: 'c', text: 'Finishing',disabled: false },
                        { value: 'd', text: 'Packing',disabled: false },
                    ];
                }
            },
            cuttingdate() {
                if (this.form.marker >= this.form.acc && this.form.marker >= this.form.fabric &&
                    this.form.marker >= this.form.fabric_inspection && this.form.marker >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.marker);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.acc >= this.form.marker && this.form.acc >= this.form.fabric &&
                    this.form.acc >= this.form.fabric_inspection && this.form.acc >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.acc);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.fabric >= this.form.marker && this.form.fabric >= this.form.acc &&
                    this.form.fabric >= this.form.fabric_inspection && this.form.fabric >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.fabric);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.fabric_inspection >= this.form.marker && this.form.fabric_inspection >= this.form.acc &&
                    this.form.fabric_inspection >= this.form.fabric && this.form.fabric_inspection >= this.form.ppm) {
                        let cuttingdate = new Date(this.form.fabric_inspection);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.ppm >= this.form.marker && this.form.ppm >= this.form.acc &&
                    this.form.ppm >= this.form.fabric && this.form.ppm >= this.form.fabric_inspection) {
                        let cuttingdate = new Date(this.form.ppm);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
                if (this.form.marker == this.form.acc && this.form.marker == this.form.fabric &&
                    this.form.marker == this.form.fabric_inspection && this.form.marker == this.form.ppm) {
                        let cuttingdate = new Date(this.form.marker);
                        this.form.cutting_date = cuttingdate.setDate(cuttingdate.getDate() + 1);
                        this.form.cutting_date = new Date(this.form.cutting_date);
                        this.form.cutting_date = this.form.cutting_date.toISOString().slice(0,10)
                }
            },
            targetPerDayCutting() {
                if (this.read_id == 1) {
                    for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.cutting_id) {
                            this.form.cutting_target = this.stMstSub[a].cutting;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.cutting_id) {
                            this.form.cutting_subcon = this.stMstSub[b].nama_subcon;
                            this.form.cutting_target_dummy = this.stMstSub[b].cutting;
                        }
                    }
            },
            targetPerDayEmbroidery() {
                if (this.read_id == 1) {
                    for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.embroidery_id) {
                            this.form.embroidery_target = this.stMstSub[a].embroidery;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.embroidery_id) {
                            this.form.embroidery_subcon = this.stMstSub[b].nama_subcon;
                            this.form.embroidery_target_dummy = this.stMstSub[b].embroidery;
                        }
                    }

            },
            targetPerDaySewing() {
                if (this.read_id == 1) {
                    for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.sewing_id) {
                            this.form.sewing_target = this.stMstSub[a].sewing;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.sewing_id) {
                            this.form.sewing_subcon = this.stMstSub[b].nama_subcon;
                            this.form.sewing_target_dummy = this.stMstSub[b].sewing;
                        }
                    }
            },
            targetPerDayPrinting() {
                if (this.read_id == 1) {
                    for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.printing_id) {
                            this.form.printing_target = this.stMstSub[a].printing;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.printing_id) {
                            this.form.printing_subcon = this.stMstSub[b].nama_subcon;
                            this.form.printing_target_dummy = this.stMstSub[b].printing;
                        }
                    }
            },
            targetPerDayWashing() {
                if (this.read_id == 1) {
                    for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.washing_id) {
                            this.form.washing_target = this.stMstSub[a].washing;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.washing_id) {
                            this.form.washing_subcon = this.stMstSub[b].nama_subcon;
                            this.form.washing_target_dummy = this.stMstSub[b].washing;
                        }
                    }
            },
            targetPerDayEmblem() {
                if (this.read_id == 1) {
                    for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.emblem_id) {
                            this.form.emblem_target = this.stMstSub[a].emblem;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.emblem_id) {
                            this.form.emblem_subcon = this.stMstSub[b].nama_subcon;
                            this.form.emblem_target_dummy = this.stMstSub[b].emblem;
                        }
                    }
            },
            targetPerDayFinishing() {
                if (this.read_id == 1) {
                    for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.finishing_id) {
                            this.form.finishing_target = this.stMstSub[a].finishing;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.finishing_id) {
                            this.form.finishing_subcon = this.stMstSub[b].nama_subcon;
                            this.form.finishing_target_dummy = this.stMstSub[b].finishing;
                        }
                    }
            },
            targetPerDayPacking() {
                if (this.read_id == 1) {
                     for(let a = 0; a < this.stMstSub.length; a++) {
                        if(this.stMstSub[a].id == this.form.packing_id) {
                            this.form.packing_target = this.stMstSub[a].packing;
                        }
                    }
                }
                    for(let b = 0; b < this.stMstSub.length; b++) {
                        if(this.stMstSub[b].id == this.form.packing_id) {
                            this.form.packing_subcon = this.stMstSub[b].nama_subcon;
                            this.form.packing_target_dummy = this.stMstSub[b].packing;
                        }
                    }
            },
            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stIoPPMCount == 0 || this.stIoPPMCount == null) {
                    this.DelayGetData()
                    return
                }
                // console.log(this.time)
                // Set Data v-model
                let formdata = this.stIoPPM
                this.form.status = formdata[0].status
                this.form.artikel = formdata[0].artikel
                this.BasicData(this.form.artikel)
                this.form.sample = formdata[0].sample
                this.form.brand = formdata[0].brand
                this.form.subbrand = formdata[0].sub_brand
                this.form.manufacture_date = formdata[0].manufacture_date
                if (formdata[0].manufacture_date !== null) {
                    this.manufacture_date = this.FormatDate(formdata[0].manufacture_date)
                }
                this.form.description = formdata[0].description
                this.form.style = formdata[0].style
                this.form.texture = formdata[0].texture
                this.form.material = formdata[0].material
                this.form.category = formdata[0].category
                this.form.sleeve = formdata[0].sleeve
                this.document_io = formdata[0].document_io
                this.file = formdata[0].document_io
                if (this.file == null) {
                    this.file = 'Choose File'
                }

                this.image_io = formdata[0].image_io
                this.image = formdata[0].image_io
                if (this.image == null) {
                    this.image = 'Choose Image'
                }
                // console.log('image_io:'+this.image_io)
                this.form.qty_order = formdata[0].qty_order
                this.form.cutting_date = formdata[0].cutting_date
                this.TotalQty(this.form.qty_order)
                this.form.due_date = formdata[0].due_date
                if (formdata[0].due_date !== null) {
                    this.due_date = this.FormatDate(formdata[0].due_date)
                }
                this.form.package_size = formdata[0].package_size
                this.form.addtional = formdata[0].addtional
                if (formdata[0].addtional == null) {
                    this.form.addtional = 'no'
                }
                console.log('addtional: '+this.form.addtional)
                this.form.marker = formdata[0].marker
                this.form.acc = formdata[0].acc
                this.form.fabric = formdata[0].fabric
                this.form.fabric_inspection = formdata[0].fabric_inspection
                this.form.ppm = formdata[0].ppm
                this.form.cutting_id = formdata[0].cutting_id
                this.form.cutting_subcon = formdata[0].cutting_subcon
                this.form.cutting_target = formdata[0].cutting_target
                this.form.cutting_target_dummy = formdata[0].cutting_target
                if (this.form.cutting_id == null || this.form.cutting_id == 0) {
                    this.form.checkbox_cutting = null
                    this.dis_id_cut = 1
                } else {
                    this.form.checkbox_cutting = 'yes'
                    this.dis_id_cut = 0
                }
                this.form.embroidery_id = formdata[0].embroidery_id
                this.form.embroidery_subcon = formdata[0].embroidery_subcon
                this.form.embroidery_target = formdata[0].embroidery_target
                this.form.embroidery_target_dummy = formdata[0].embroidery_target
                if (this.form.embroidery_id == null || this.form.embroidery_id == 0) {
                    this.form.checkbox_embroidery = null
                    this.dis_id_embr = 1
                } else {
                    this.form.checkbox_embroidery = 'yes'
                    this.dis_id_embr = 0
                }
                this.form.printing_id = formdata[0].printing_id
                this.form.printing_subcon = formdata[0].printing_subcon
                this.form.printing_target = formdata[0].printing_target
                this.form.printing_target_dummy = formdata[0].printing_target
                if (this.form.printing_id == null || this.form.printing_id == 0) {
                    this.form.checkbox_printing = null
                    this.dis_id_pri = 1
                } else {
                    this.form.checkbox_printing = 'yes'
                    this.dis_id_pri = 0
                }
                this.form.emblem_id = formdata[0].emblem_id
                this.form.emblem_subcon = formdata[0].emblem_subcon
                this.form.emblem_target = formdata[0].emblem_target
                this.form.emblem_target_dummy = formdata[0].emblem_target
                if (this.form.emblem_id == null || this.form.emblem_id == 0) {
                    this.form.checkbox_emblem = null
                    this.dis_id_embl = 1
                } else {
                    this.form.checkbox_emblem = 'yes'
                    this.dis_id_embl = 0
                }
                this.form.sewing_id = formdata[0].sewing_id
                this.form.sewing_subcon = formdata[0].sewing_subcon
                this.form.sewing_target = formdata[0].sewing_target
                this.form.sewing_target_dummy = formdata[0].sewing_target
                if (this.form.sewing_id == null || this.form.sewing_id == 0) {
                    this.form.checkbox_sewing = null
                    this.dis_id_sew = 1
                } else {
                    this.form.checkbox_sewing = 'yes'
                    this.dis_id_sew = 0
                }
                this.form.washing_id = formdata[0].washing_id
                this.form.washing_subcon = formdata[0].washing_subcon
                this.form.washing_target = formdata[0].washing_target
                this.form.washing_target_dummy = formdata[0].washing_target
                if (this.form.washing_id == null || this.form.washing_id == 0) {
                    this.form.checkbox_washing = null
                    this.dis_id_wash = 1
                } else {
                     this.form.checkbox_washing = 'yes'
                     this.dis_id_wash = 0
                }
                this.form.packing_id = formdata[0].packing_id
                this.form.packing_subcon = formdata[0].packing_subcon
                this.form.packing_target = formdata[0].packing_target
                this.form.packing_target_dummy = formdata[0].packing_target
                if (this.form.packing_id == null || this.form.packing_id == 0) {
                    this.form.checkbox_packing = null
                    this.dis_id_cut = 1
                } else {
                    this.form.checkbox_packing = 'yes'
                    this.dis_id_cut = 0
                }
                this.form.finishing_id = formdata[0].finishing_id
                this.form.finishing_subcon = formdata[0].finishing_subcon
                this.form.finishing_target = formdata[0].finishing_target
                this.form.finishing_target_dummy = formdata[0].finishing_target
                if (this.form.finishing_id == null || this.form.finishing_id == 0) {
                    this.form.checkbox_finishing = null
                     this.dis_id_fin = 1
                } else {
                    this.form.checkbox_finishing = 'yes'
                    this.dis_id_fin = 0
                }
                if (this.form.addtional == 'yes') {
                    this.isActive = true
                    this.opt_process = [
                        { value: 'a', text: 'Cutting' ,disabled: false },
                        { value: 'b', text: 'Sewing' ,disabled: false },
                        { value: 'c', text: 'Finishing',disabled: false },
                        { value: 'd', text: 'Packing',disabled: false },
                        { value: 'e', text: 'Print',disabled: false },
                        { value: 'f', text: 'Embroidery',disabled: false },
                        { value: 'g', text: 'Emblem',disabled: false },
                        { value: 'h', text: 'Washing',disabled: false },
                    ];
                    // console.log(this.opt_process);
                } else {
                    this.form.addtional = 'no'
                    this.isActive = false
                    this.opt_process = [
                        { value: 'a', text: 'Cutting' ,disabled: false },
                        { value: 'b', text: 'Sewing' ,disabled: false },
                        { value: 'c', text: 'Finishing',disabled: false },
                        { value: 'd', text: 'Packing',disabled: false },
                    ];
                    //console.log(this.opt_process);
                    this.form.checkbox_embroidery = null;
                    this.form.embroidery_id = null;
                    this.form.embroidery_subcon = null;
                    this.form.embroidery_target = null;
                    this.form.embroidery_target_dummy = null;

                    this.form.checkbox_washing = null;
                    this.form.washing_id= null;
                    this.form.washing_subcon = null;
                    this.form.washing_target = null;
                    this.form.washing_target_dummy = null;

                    this.form.checkbox_printing = null;
                    this.form.printing_id = null;
                    this.form.printing_subcon = null;
                    this.form.printing_target = null;
                    this.form.printing_target_dummy = null;

                    this.form.checkbox_emblem = null;
                    this.form.emblem_id = null;
                    this.form.emblem_subcon = null;
                    this.form.emblem_target = null;
                    this.form.emblem_target_dummy = null;

                }
                this.button_save = false;
                if (this.form.status.includes('ONGOING')) {
                    this.button_save= true;
                }
                if (this.form.status.includes('COMPLETED')) {
                    this.button_save= true;
                }
                this.process_order = formdata[0].order_process;
                //console.log(this.process_order);
                this.dataOrderProcess();
                let fileupload = this.stFileUpload
                // console.log(this.stFileUpload)
                if (this.stFileUploadCount !== 0) {
                    this.url_file = fileupload[0].document
                }
                let imageupload = this.stImageUpload
                // console.log(this.stImageUpload)
                if (this.stImageUploadCount !== 0) {
                    this.url_image = imageupload[0].image
                }
                let datasubcondaily = this.stIoSubconDaily
                if (this.stIoSubconDailyCount !== 0) {
                    this.data_subcon = datasubcondaily[0].code_process
                    console.log(this.data_subcon)
                }
                let dataDeliver = this.stPPCMDelvrData
                if (this.stPPCMDelvrDataCount !== 0) {
                    this.dataDeliver = dataDeliver[0].io
                    console.log(this.dataDeliver)
                }
                console.log(this.stPPCMReceiveData)
                let dataReceive = this.stPPCMReceiveData
                if (this.stPPCMReceiveDataCount !== 0) {
                    this.dataReceive = dataReceive[0].io
                }
                console.log(this.stPPCMInsDataCount)
                let datainst = this.stPPCMInsData
                if (this.stPPCMInsDataCount !== 0 ) {
                    this.datainst = datainst[0].io
                }


            },

            dataOrderProcess() {
                let b = 1;
                let a = 0;
                let r = 0;
                if (this.process_order == '' || this.process_order == null) {
                    //console.log('process: '+this.process_order)
                } else {
                for (a=0;a<=7;a++) {
                if (this.process_order.slice(r, b) == 'a') {
                    this.form.process_order[a] = 'a';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                if (this.process_order.slice(r, b) == 'b') {
                    this.form.process_order[a] = 'b';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                if (this.process_order.slice(r, b) == 'c') {
                    this.form.process_order[a] = 'c';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                if (this.process_order.slice(r, b) == 'd') {
                    this.form.process_order[a] = 'd';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                if (this.process_order.slice(r, b) == 'e') {
                    this.form.process_order[a] = 'e';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                if (this.process_order.slice(r, b) == 'f') {
                    this.form.process_order[a] = 'f';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                if (this.process_order.slice(r, b) == 'g') {
                    this.form.process_order[a] = 'g';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                if (this.process_order.slice(r, b) == 'h') {
                    this.form.process_order[a] = 'h';
                    this.OrderProcess(a);
                    if (a > 0 ) {
                        this.AddProcess();
                    }
                }
                b += 2;
                r += 2;
                //console.log('r'+r);
                //console.log('b'+b);
            }
            }
        },
            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
            CalcTotalPlan(totalQty,target) {
                let calc = (((totalQty/target) - Math.floor(totalQty/target))*10)
                if (calc >= 3) {
                    calc = Math.ceil(totalQty/target)
                } else {
                    calc = Math.floor(totalQty/target)
                    if (calc == 0) {
                    calc = 1
                    }
                }
                return calc
            },
            CalcDatePlan() {
                let days_plan = null
                let date = null
                let b = 1;
                let a = 0;
                let r = 0;
                let e = 0;
                let d = 0;
                    // console.log('length: '+this.calender_cutting.length)
                if (this.process_order == '' || this.process_order == null) {
                    // console.log('NOt process: '+this.process_order)
                } else {

                // console.log('length 2 2: '+this.calender_cutting.length)
                for (a=0;a<=7;a++) {
                if (this.confirm_calc_date == a) {
                    if (this.process_order.slice(r, b) == 'a') {
                        // days_plan = get duration
                        // finishingdate = get last end date
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.cutting_target);
                        date = this.form.cutting_date
                        // get shift_cutting
                        this.getSubconCalender({name:this.form.cutting_subcon})
                        .then(rsp => {
                            this.shift_cutting = rsp[0].shift_id
                            console.log('this.shift_cutting :  ' + this.shift_cutting)
                            if (this.shift_cutting == undefined ) {
                                this.shift_cutting = 1
                            }
                            console.log('this.shift_cutting :  ' + this.shift_cutting)
                            // calc start and end date plan
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_cutting,
                                params:[{
                                    // calc cutting Start Date Plan
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    // calc cutting End Date Plan
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                // confirm_calc_date = confirmation onSubmit()
                                this.confirm_calc_date += 1
                                this.form.cutting_date_plan = rsp[0].next_date
                                this.form.cutting_end_date_plan = rsp[1].next_date
                                // calc_date = save end date plan for next process
                                this.calc_date = this.form.cutting_end_date_plan
                                this.CalcDatePlan()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }

                    if (this.process_order.slice(r, b) == 'b') {
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.sewing_target);
                        date = this.calc_date
                        this.getSubconCalender({name:this.form.sewing_subcon})
                        .then(rsp => {
                            this.shift_sewing = rsp[0].shift_id
                            if (this.shift_sewing == undefined ) {
                                this.shift_sewing = 1
                            }
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_sewing,
                                params:[{
                                    // calc sewing Start Date Plan
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    // calc sewing End Date Plan
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                this.confirm_calc_date += 1
                                this.form.sewing_date_plan = rsp[0].next_date
                                this.form.sewing_end_date_plan = rsp[1].next_date
                                this.calc_date = this.form.sewing_end_date_plan
                                this.CalcDatePlan()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }

                    if (this.process_order.slice(r, b) == 'c') {
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.finishing_target);
                        date = this.calc_date
                        this.getSubconCalender({name:this.form.finishing_subcon})
                        .then(rsp => {
                            this.shift_finishing = rsp[0].shift_id
                            if (this.shift_finishing == undefined ) {
                                this.shift_finishing = 1
                            }
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_finishing,
                                params:[{
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                this.confirm_calc_date += 1
                                this.form.finishing_date_plan = rsp[0].next_date
                                this.form.finishing_end_date_plan = rsp[1].next_date
                                this.calc_date = this.form.finishing_end_date_plan
                                this.CalcDatePlan()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }


                    if (this.process_order.slice(r, b) == 'd') {
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.packing_target);
                        date = this.calc_date
                        this.getSubconCalender({name:this.form.packing_subcon})
                        .then(rsp => {
                            this.shift_packing = rsp[0].shift_id
                            if (this.shift_packing == undefined ) {
                                this.shift_packing = 1
                            }
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_packing,
                                params:[{
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                this.confirm_calc_date += 1
                                this.form.packing_date_plan = rsp[0].next_date
                                this.form.packing_end_date_plan = rsp[1].next_date
                                this.calc_date = this.form.packing_end_date_plan
                                this.DatePlanQcFinal()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }

                    if (this.process_order.slice(r, b) == 'e') {
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.printing_target);
                        date = this.calc_date
                        this.getSubconCalender({name:this.form.printing_subcon})
                        .then(rsp => {
                            this.shift_printing = rsp[0].shift_id
                            if (this.shift_printing == undefined ) {
                                this.shift_printing = 1
                            }
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_printing,
                                params:[{
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                this.confirm_calc_date += 1
                                this.form.printing_date_plan = rsp[0].next_date
                                this.form.printing_end_date_plan = rsp[1].next_date
                                this.calc_date = this.form.printing_end_date_plan
                                this.CalcDatePlan()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }

                    if (this.process_order.slice(r, b) == 'f') {
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.embroidery_target);
                        date = this.calc_date
                        this.getSubconCalender({name:this.form.embroidery_subcon})
                        .then(rsp => {
                            this.shift_embroidery = rsp[0].shift_id
                            if (this.shift_embroidery == undefined ) {
                                this.shift_embroidery = 1
                            }
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_embroidery,
                                params:[{
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                this.confirm_calc_date += 1
                                this.form.embroidery_date_plan = rsp[0].next_date
                                this.form.embroidery_end_date_plan = rsp[1].next_date
                                this.calc_date = this.form.embroidery_end_date_plan
                                this.CalcDatePlan()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }

                    if (this.process_order.slice(r, b) == 'g') {
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.emblem_target);
                        date = this.calc_date
                        this.getSubconCalender({name:this.form.emblem_subcon})
                        .then(rsp => {
                            this.shift_emblem = rsp[0].shift_id
                            if (this.shift_emblem == undefined ) {
                                this.shift_emblem = 1
                            }
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_emblem,
                                params:[{
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                this.confirm_calc_date += 1
                                this.form.emblem_date_plan = rsp[0].next_date
                                this.form.emblem_end_date_plan = rsp[1].next_date
                                this.calc_date = this.form.emblem_end_date_plan
                                this.CalcDatePlan()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }

                    if (this.process_order.slice(r, b) == 'h') {
                        days_plan = this.CalcTotalPlan(this.form.total_qty,this.form.washing_target);
                        date = this.calc_date
                        this.getSubconCalender({name:this.form.washing_subcon})
                        .then(rsp => {
                            this.shift_washing = rsp[0].shift_id
                            if (this.shift_washing == undefined ) {
                                this.shift_washing = 1
                            }
                            this.GetDateCalculation({
                                is_iterate: true,
                                shift_id:this.shift_washing,
                                params:[{
                                    calc_type: "end_date",
                                    start_date: date,
                                    duration: 1,
                                },
                                {
                                    calc_type: "end_date",
                                    duration: days_plan,
                                },
                                ]
                            })
                            .then(rsp => {
                                this.confirm_calc_date += 1
                                this.form.washing_date_plan = rsp[0].next_date
                                this.form.washing_end_date_plan = rsp[1].next_date
                                this.calc_date = this.form.washing_end_date_plan
                                this.CalcDatePlan()
                            })
                            .catch(err => {
                                console.log(err)
                                if(err.response.status !== 401) {
                                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                                this.$router.push({name:"DashboardPPCM"})
                                }
                            })
                        })
                        .catch(err => {
                            if(err.response.status !== 401) {
                            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                            this.$router.push({name:"DashboardPPCM"})
                            }
                        })
                    }

                }
                b += 2;
                r += 2;
                }
                }

            },


            doApproval() {
                let param = this.form.qty_order
                let count = 0;
                let char = [];
                let ord_size = [];
                if (param == null || param == '' || param == undefined) {
                    this.showNotification({type:'error', message:'Quantity Order Harap Di Isi'})
                    return
                } else {
                    this.form.total_qty = 0
                for (let size = 0;size<param.length; size++) {
                    if (param.charAt(size) == '(') {
                        char[count] = ((param.substring(size + 1,size+6)).split(")")[0]);
                    }
                    if (param.charAt(size) == ')') {
                        ord_size[count] = ((param.substring(size + 2,size+6)).split("|")[0]);
                        this.form.total_qty += parseInt(ord_size[count])
                        count++;
                    }
                }
                if (this.form.total_qty == 0 || this.form.total_qty == null || this.form.total_qty == undefined || isNaN(this.form.total_qty) ) {
                    this.showNotification({type:'error', message:'Quantity Order Belum Di Isi Dengan Benar'})
                    return
                }
                }



                if (this.form.cutting_id == null || (this.form.cutting_target == null || this.form.cutting_target == '')) {
                    this.showNotification({type:'error', message:'Cutting Target Perlu Di Isi'})
                    return
                }
                if (this.form.sewing_id !== null && (this.form.sewing_target == null || this.form.sewing_target == '')) {
                    this.showNotification({type:'error', message:'Sewing Target Perlu Di Isi'})
                    return
                }
                if (this.form.finishing_id !== null && (this.form.finishing_target == null || this.form.finishing_target == '')) {
                    this.showNotification({type:'error', message:'Finishing Target Perlu Di Isi'})
                    return
                }
                if (this.form.packing_id !== null && (this.form.packing_target == null || this.form.packing_target == '')) {
                    this.showNotification({type:'error', message:'Packing Target Perlu Di Isi'})
                    return
                }
                if (this.form.printing_id !== null && (this.form.printing_target == null || this.form.printing_target == '')) {
                    this.showNotification({type:'error', message:'Printing Target Perlu Di Isi'})
                    return
                }
                if (this.form.embroidery_id !== null && (this.form.embroidery_target == null || this.form.embroidery_target == '')) {
                    this.showNotification({type:'error', message:'Embroidery Target Perlu Di Isi'})
                    return
                }
                if (this.form.emblem_id !== null && (this.form.emblem_target == null || this.form.emblem_target == '')) {
                    this.showNotification({type:'error', message:'Emblem Target Perlu Di Isi'})
                    return
                }
                if (this.form.washing_id !== null && (this.form.washing_target == null || this.form.washing_target == '')) {
                    this.showNotification({type:'error', message:'Washing Target Perlu Di Isi'})
                    return
                }
                this.data_deliv = this.dataDeliver
                this.data_rec = this.dataReceive
                this.data_ins = this.datainst
                this.process_order = this.order_process.toString();
                if (this.form.marker == null || this.form.acc == null || this.form.fabric == null || this.form.fabric_inspection == null || this.form.ppm == null) {
                    this.form.status = 'New(Pending)'
                    this.confirm_calc_date = ((Math.ceil(this.process_order.length/2))+2)
                    this.CheckFormProcess()
                } else {
                    this.confirm_calc_date = 0
                    this.form.status = 'Pending(Approval)'

                if (this.form.marker >= this.form.acc && this.form.marker >= this.form.fabric &&
                    this.form.marker >= this.form.fabric_inspection && this.form.marker >= this.form.ppm) {
                        this.form.cutting_date = this.form.marker
                }
                if (this.form.acc >= this.form.marker && this.form.acc >= this.form.fabric &&
                    this.form.acc >= this.form.fabric_inspection && this.form.acc >= this.form.ppm) {
                        this.form.cutting_date = this.form.acc
                }
                if (this.form.fabric >= this.form.marker && this.form.fabric >= this.form.acc &&
                    this.form.fabric >= this.form.fabric_inspection && this.form.fabric >= this.form.ppm) {
                        this.form.cutting_date = this.form.fabric
                }
                if (this.form.fabric_inspection >= this.form.marker && this.form.fabric_inspection >= this.form.acc &&
                    this.form.fabric_inspection >= this.form.fabric && this.form.fabric_inspection >= this.form.ppm) {
                        this.form.cutting_date = this.form.fabric_inspection
                }
                if (this.form.ppm >= this.form.marker && this.form.ppm >= this.form.acc &&
                    this.form.ppm >= this.form.fabric && this.form.ppm >= this.form.fabric_inspection) {
                        this.form.cutting_date = this.form.ppm
                }
                if (this.form.marker == this.form.acc && this.form.marker == this.form.fabric &&
                    this.form.marker == this.form.fabric_inspection && this.form.marker == this.form.ppm) {
                        this.form.cutting_date = this.form.marker
                }
                this.CalcDatePlan()

                }

            },
            DatePlanQcFinal() {
                let array_id = null
                for(let b = 0; b < this.stMstSub.length; b++) {
                    if(this.stMstSub[b].id == this.form.packing_id) {
                        array_id = b
                    }
                }
                if (array_id == null) {
                    return
                }

                let days_plan = this.stMstSub[array_id].delivery
                if (!this.stMstSub[array_id].delivery >= 0) {
                    days_plan = 2
                }
                let qc_final = this.calc_date
                this.GetDateCalculation({
                    is_iterate: true,
                    shift_id:this.shift_packing,
                    params:[{
                        calc_type: "end_date",
                        start_date: qc_final,
                        duration: 1,
                    },
                    {
                        calc_type: "end_date",
                        duration: days_plan,
                    },
                    ]
                })
                .then(rsp => {
                    this.confirm_calc_date += 1
                    this.form.plan_date_qc_final = rsp[0].next_date
                    this.form.plan_end_date_qc_final = rsp[1].next_date
                    this.calc_date = this.form.plan_end_date_qc_final
                    this.DatePlanDelivery();
                })
                .catch(err => {
                    if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                    this.$router.push({name:"DashboardPPCM"})
                    }
                })

            },
            DatePlanDelivery() {
                let array_id = null
                for(let b = 0; b < this.stMstSub.length; b++) {
                    if(this.stMstSub[b].id == this.form.packing_id) {
                        array_id = b
                    }
                }
                if (array_id == null) {
                    return
                }
                let days_plan = this.stMstSub[array_id].delivery
                if (!this.stMstSub[array_id].delivery >= 0) {
                    days_plan = 2
                }
                let deliverdate = this.calc_date
                this.GetDateCalculation({
                    is_iterate: true,
                    shift_id:this.shift_packing,
                    params:[{
                        calc_type: "end_date",
                        start_date: deliverdate,
                        duration: 1,
                    },
                    {
                        calc_type: "end_date",
                        duration: days_plan,
                    },
                    ]
                })
                .then(rsp => {
                    this.confirm_calc_date += 1
                    this.form.plan_date_delivery = rsp[0].next_date
                    this.form.plan_end_date_delivery = rsp[1].next_date
                    this.CheckFormProcess()
                })
                .catch(err => {
                    if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                    this.$router.push({name:"DashboardPPCM"})
                    }
                })
            },

            CheckFormProcess() {
            let a = this.append_process;
            let x = 1;
            if (this.err_process > this.arr_process) {

                if (this.qty_process <= this.arr_process) {

                    let verifyData = this.createFormData()
                    this.form_api.push(verifyData)
                } else {
                    document.getElementById("warning").classList.remove("hidden");
                    if (this.form.addtional == 'yes') {
                        document.getElementById("warning-2").classList.remove("hidden");
                    } else {
                        document.getElementById("warning-1").classList.remove("hidden");
                    }

                    for (let r=a;r>=0;r--) {
                        x++;
                        setTimeout(() => {
                        this.DeleteTable(r);
                        },1000  * ((x * 0.5)));
                    }
                }
            } else {
                document.getElementById("warning").classList.remove("hidden");
                if (this.form.addtional == 'yes') {
                    document.getElementById("warning-2").classList.remove("hidden");
                } else {
                    document.getElementById("warning-1").classList.remove("hidden");
                }

                for (let r=a;r>=0;r--) {
                    x++;
                    setTimeout(() => {
                        this.DeleteTable(r);
                    },1000  * ((x * 0.5)));
                    }
            }
            },


            createFormData() {
                // subcon daily
                let row_subcon_daily    = null
                let status_subcon_daily = 'Open'
                let kode_subcon_daily   = this.form.data_io+''+Math.floor(Math.random() * 1000000)
                if (this.data_subcon == null) {
                    row_subcon_daily = 'add'
                } else {
                    row_subcon_daily = 'update'
                    kode_subcon_daily = this.data_subcon
                }

                // delivery
                let row_delivery = null
                if (this.dataDeliver == null) {
                    row_delivery = 'add'
                } else {
                    row_delivery = 'update'
                }

                // receive
                let row_receive = null
                if (this.dataReceive == null) {
                    row_receive = 'add'
                } else {
                    row_receive = 'update'
                }

                // inspection
                let row_inspection = null
                if (this.datainst == null) {
                    row_inspection = 'add'
                } else {
                    row_inspection = 'update'
                }
                let document_io = this.file
                let image_io = this.image
                if (this.document_io == null || this.document_io == '' || this.document_io ==' ' || this.document_io == undefined) {
                    document_io = null
                }
                if (this.image_io == null || this.image_io == '' || this.image_io ==' ' || this.image_io == undefined) {
                    image_io = null
                }
                if (this.form.marker == null || this.form.acc == null || this.form.fabric == null || this.form.fabric_inspection == null || this.form.ppm == null) {
                    this.form.status = 'New(Pending)'
                } else {
                    this.form.status = 'Pending(Approval)'
                }
                this.process_order = this.order_process.toString();
            let data = {
                io:this.form.data_io,
                date_now:this.today+'_'+this.time,
                //document_io NULL
                document_io:document_io,
                image_io:image_io,
                employee:this.form.Employee,
                status:this.form.status,
                qty_order:this.form.qty_order,
                total_qty:this.form.total_qty,
                basic:this.form.basic,
                addtional:this.form.addtional,
                marker:this.form.marker,
                acc:this.form.acc,
                fabric:this.form.fabric,
                fabric_inspection:this.form.fabric_inspection,
                ppm:this.form.ppm,
                cutting_id:this.form.cutting_id,
                cutting_subcon:this.form.cutting_subcon,
                cutting_target:this.form.cutting_target,
                // cutting_date:this.form.cutting_date_plan,
                cutting_date_plan:this.form.cutting_date_plan,
                cutting_end_date_plan:this.form.cutting_end_date_plan,
                embroidery_id:this.form.embroidery_id,
                embroidery_subcon:this.form.embroidery_subcon,
                embroidery_target:this.form.embroidery_target,
                embroidery_date_plan:this.form.embroidery_date_plan,
                embroidery_end_date_plan:this.form.embroidery_end_date_plan,
                printing_id:this.form.printing_id,
                printing_subcon:this.form.printing_subcon,
                printing_target:this.form.printing_target,
                printing_date_plan:this.form.printing_date_plan,
                printing_end_date_plan:this.form.printing_end_date_plan,
                emblem_id:this.form.emblem_id,
                emblem_subcon:this.form.emblem_subcon,
                emblem_target:this.form.emblem_target,
                emblem_date_plan:this.form.emblem_date_plan,
                emblem_end_date_plan:this.form.emblem_end_date_plan,
                sewing_id:this.form.sewing_id,
                sewing_subcon:this.form.sewing_subcon,
                sewing_target:this.form.sewing_target,
                sewing_date_plan:this.form.sewing_date_plan,
                sewing_end_date_plan:this.form.sewing_end_date_plan,
                washing_id:this.form.washing_id,
                washing_subcon:this.form.washing_subcon,
                washing_target:this.form.washing_target,
                washing_date_plan:this.form.washing_date_plan,
                washing_end_date_plan:this.form.washing_end_date_plan,
                finishing_id:this.form.finishing_id,
                finishing_subcon:this.form.finishing_subcon,
                finishing_target:this.form.finishing_target,
                finishing_date_plan:this.form.finishing_date_plan,
                finishing_end_date_plan:this.form.finishing_end_date_plan,
                packing_id:this.form.packing_id,
                packing_subcon:this.form.packing_subcon,
                packing_target:this.form.packing_target,
                packing_date_plan:this.form.packing_date_plan,
                packing_end_date_plan:this.form.packing_end_date_plan,
                order_process:this.process_order,
                approve:null,
                // subcon daily
                row_subdaily:row_subcon_daily,
                status_subdaily:status_subcon_daily,
                kode_subdaily:kode_subcon_daily,
                order_process_subdaily:this.process_order[0],
                next_process_subdaily:this.process_order[2],
                // delivery
                row_delivery:row_delivery,
                plan_date_delivery:this.form.plan_date_delivery,
                end_plan_date_delivery:this.form.plan_end_date_delivery,
                // receive
                row_receive:row_receive,
                // inspection
                row_inspection:row_inspection,
                plan_visit_inline:this.form.sewing_date_plan,
                end_plan_visit_inline:this.form.sewing_end_date_plan,
                plan_visit_final:this.form.plan_date_qc_final,
                end_plan_visit_final:this.form.plan_end_date_qc_final,

            }


            return data;
            },
            onSubmit() {
                let timer = 2000 * ((Math.ceil(this.process_order.length/2))+2)
                // console.log(timer)
            this.button_save = true
            let loader = this.$loading.show({
                // Optional parameters
                container: this.fullPage ? null : this.$refs.formContainer,
                canCancel: false,
                color: '#f89830',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
                },);
            setTimeout(() => {
                loader.hide()
                this.button_save = false
                if(this.form_api.length == 0) {
                    // Stop
                    this.showNotification({type:'info', message:"Data gagal di proses, harap di klik tombol Save kembali / Contact Administrator"})
                    return
                }
                if(this.confirm_calc_date != ((Math.ceil(this.process_order.length/2))+2)) {
                    // console.log('this.confirm_calc_date  '+this.confirm_calc_date)
                    // console.log('(Math.ceil(this.process_order.length/2))+2)  '+((Math.ceil(this.process_order.length/2))+2))
                    // Stop
                    this.showNotification({type:'info', message:"Data gagal di proses, harap di klik tombol Save kembali / Contact Administrator"})
                    return
                }

                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value => {
                    this.boxTwo = String(value)
                    if (this.boxTwo == 'true') {
                        // console.log('box: '+this.boxTwo)


                    let loader = this.$loading.show({
                        // Optional parameters
                        container: this.fullPage ? null : this.$refs.formContainer,
                        canCancel: false,
                        color: '#f89830',
                        loader: 'spinner',
                        width: 64,
                        height: 64,
                        backgroundColor: '#ffffff',
                        opacity: 0.5,
                        zIndex: 999,
                        });
                        // show report after timeout min 1 second
                        // console.log(this.document_io)
                        if (this.data_file !== null) {
                            if (this.document_io !== null) {
                                let data = new FormData(); // creates a new FormData object
                                data.append('document', this.document_io); // add your file to form data
                                data.append('ppm_io', this.form.data_io); // add your file to form data
                                data.append('description', this.file); // add your file to form data
                            if (this.stFileUploadCount == 0) {
                                apiUpload.dispatch('UploadFile', data )
                                .then(response => {
                                // console.log(response)
                                })
                                .catch(err => {
                                if (err.response === 400) {
                                    console.log(err)
                                    this.showNotification({type:'error', message:err.response.data.message})
                                }
                                // Stop
                                return
                                })
                            }
                            if (this.stFileUploadCount !== 0) {
                                apiUpload.dispatch('UpdateUploadFile', data )
                                .then(response => {
                                // console.log(response)
                                })
                                .catch(err => {
                                if (err.response === 400) {
                                    console.log(err)
                                    this.showNotification({type:'error', message:err.response.data.message})
                                }
                                // Stop
                                return
                                })
                            }
                        }
                        }

                            // console.log(this.image_io)
                        if (this.data_image !== null) {
                            if (this.image_io !== null) {
                            let image = new FormData(); // creates a new FormData object
                            image.append('image', this.image_io); // add your file to form data
                            image.append('ppm_io', this.form.data_io);
                            image.append('description', this.image);
                            if (this.stImageUploadCount == 0) {
                                apiUpload.dispatch('UploadImage', image )
                                .then(response => {
                                // console.log(response)
                                })
                                .catch(err => {
                                    if (err.response === 400) {
                                        console.log(err)
                                        this.showNotification({type:'error', message:err.response.data.message})
                                    }
                                })
                            }
                            if (this.stImageUploadCount !== 0) {
                                apiUpload.dispatch('UpdateUploadImage', image )
                                .then(response => {
                                // console.log(response)
                                })
                                .catch(err => {
                                    if (err.response === 400) {
                                        console.log(err)
                                        this.showNotification({type:'error', message:err.response.data.message})
                                    }
                                })
                            }
                        }
                        }

                    setTimeout(() => {
                        // apiPPCM.dispatch('putInputOrderPPM', {
                        apiPPCM.dispatch('ApiInput_Order_PPM_V2', {
                            data:this.form_api
                        })
                        .then(response => {
                        loader.hide()
                        // console.log(response)
                        this.$router.push({name:"DashboardPPCM"})
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                        })
                        .catch(err => {
                        loader.hide()
                        if (err.response === 400) {
                            console.log(err.response.data.message)
                            this.showNotification({type:'error', message:err.response.data.message})
                        }
                        })
                        .finally(()=>{
                        loader.hide()
                        this.form_api = []
                        })
                    },3000)


                    }
                    if (this.boxTwo == 'false' || this.boxTwo == 'null' || this.boxTwo == null) {
                        this.form_api = []
                    }
                })
                .catch(err => {
                    // An error occurred
                })

                // console.log(this.boxTwo)
                // console.log(this.form_api)
            },timer )

            },

        },
        created() {
            this.getDataFormMasterSubcon().then(rsp=> {
                this.OptionsSubcon = this.stMstSub
                this.Setdata_IO()
            }).catch(err => {
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                    this.$router.push({name:"DashboardPPCM"})
                }
            })
        },

    }
</script>
<style>
.custom-select>option:disabled {
  display:none !important;
}
</style>
<style src="./InputPPM.scss" lang="scss" scoped />
