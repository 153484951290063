import { getAPI } from "./api-base";

const apiPath = {
  healthDaily: "v1/api/bbi-forms/save-report-harian",
  healthDailyV2: "v1/api/bbi-forms/save-report-harian-v2",
  healthDailyVerif: "v1/api/bbi-forms/save-verification-report-harian-v2",
  healthDeclaration: "v1/api/bbi-forms/save-deklarasi-kesehatan",
  healthPemantauan: "v1/api/bbi-forms/save-rapid-test",
  healthSelfDeclaration: "v1/api/bbi-forms/save-deklarasi-kesehatan-v2",
  healthHistory: "v1/api/bbi-forms/save-health-history",
  healthHistoryV2: "v1/api/bbi-forms/save-health-history-v2",
};

export function fnApiFetchHealthDailyAccess(formDaily) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.healthDaily, formDaily)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchHealthDailyAccessV2(formDaily) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.healthDailyV2, formDaily)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchHealthDailyVerifAccess(formDaily) {
  return new Promise((resolve, reject) => {
    getAPI
      .put(apiPath.healthDailyVerif, formDaily)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchHealthDeclarationAccess(frmData) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.healthDeclaration, frmData)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchHealthPemantauanAccess(frmData) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.healthPemantauan, frmData)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnApiFetchHealthSelfDeclarationAccess(frmData) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.healthSelfDeclaration, frmData)
      .then((rsp) => {
        resolve(rsp);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function fnApiFetchHealthHistory(formData) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.healthHistory, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function fnApiFetchHealthHistoryV2(formData) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath.healthHistoryV2, formData)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
