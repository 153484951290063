<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <h2 class="page-title"> <span style="font-size:16px;" class="fw-semi-bold fontbbi ">Dashboard Approval</span></h2>
    </b-breadcrumb>
   <b-row>
      <b-col xs="12">
        Search <input type="text"
         placeholder=""
         v-model="filter">
          <div style="padding-top:20px;" class="table-responsive">
          <table class="table table-lg mb-0">
              <thead>
                <tr style="text-align:center;" class="text-muted">
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('io')">No IO</th>
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('due_date')">Brand</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Article</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Coll Month</th>
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('status')">Status</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                v-for="row in filteredRows"
                :key="row.id"
                style="text-align:center;"
                >
                  <td style="border-right:1px solid #CCC; border-left:1px solid #CCC; border-bottom:1px solid #CCC;" >
                    <span v-html="highlightMatches(row.io)" class="fw-semi-bold fontbbi ">{{row.io}}</span>
                  </td>

                   <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span v-html="highlightMatches(row.brand)" class="fw-semi-bold fontbbi "></span>
                  </td>

                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{row.artikel}}</span>
                  </td>

                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi ">{{monthNames[(new Date(row.due_date).getMonth())]}}</span>
                  </td>

                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span v-html="dataApprove(row.approve)" class="fw-semi-bold fontbbi "></span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <b-button variant="success" style="border-radius:10px;" @click="IOApprov(row.io)">
                      <b-icon icon="eye" font-scale="1.4"></b-icon>
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <p>
  <button @click="prevPage" :disabled="cantGoBack">Previous</button> <span style="padding-left: 5px;"></span>
  <button @click="nextPage" :disabled="cantGoNext()">Next</button>
  </p>
          </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Widget from '@/components/Widget/Widget';
// import appsStore from '@/store/apiApps.js';

export default {
        name: 'DashboardSubcon',
        components: { Widget },

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
              monthNames : ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
],
                errorMessage: null,
                today:today,
                now:now,
                currentSort:'status',
                currentSort1:'due_date',
                currentSortDir:'desc',
                oneDay:oneDay,
                pageSize:10,
                currentPage:1,
                filter: '',
                totalData:0,
                filterOn: [],
                DateToday:today,
                 form : {
                    fullname:null,
                    role:null,
                },

            }
        },
  computed: {
    ...mapState('application', ['stAppAccess']),
    ...mapGetters('session', ['userData']),
    ...mapState('ppm', ['stAllAppr']),
    cantGoBack() {
      return this.currentPage === 1;
    },
    filteredRows() {
      if (this.stAllAppr !== null) {
    return this.stAllAppr.filter(row => {
      const io = row.io.toString().toLowerCase();
      const due_date = row.due_date.toString().toLowerCase();
      const artikel = row.artikel.toString().toLowerCase();
      const status = row.status.toLowerCase();
      const searchTerm = this.filter.toLowerCase();
      if (row.cutting_subcon !== null) {
        const cutting_subcon = row.cutting_subcon.toLowerCase();
        return cutting_subcon.includes(searchTerm) ||
        io.includes(searchTerm) ||
        artikel.includes(searchTerm) ||
        status.includes(searchTerm) ||
        due_date.includes(searchTerm);
      } else {
        const cutting_subcon = row.cutting_subcon;
      }
      return io.includes(searchTerm) ||
        status.includes(searchTerm) ||
        artikel.includes(searchTerm) ||
        due_date.includes(searchTerm);
    }).sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort1] < b[this.currentSort1]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        if(a[this.currentSort1] > b[this.currentSort1]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
      }
  },
  },
  methods: {
    ...mapActions('session', ['showNotification']),
    ...mapActions('application', ['clearState', 'getApplicationAccess']),
    ...mapActions('ppm', ['clearStatePpm','getApproveData']),
    highlightMatches(text) {
    const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
    if (!matchExists) return text;
    const re = new RegExp(this.filter, 'ig');
    return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);

    },
    cantGoNext() {
      if ((this.currentPage*this.pageSize) > this.totalData) {
        return true
      } else {
        return false
      }

    },
    Setdata_IO() {
        let userData     = this.userData
        this.$set(this.form, 'fullname', userData.fullname)
        // this.$set(this.form, 'role', userData.roles)
        if (userData.roles) {
          for (let a=0; a<userData.roles.length; a++) {
            if (userData.roles[a].app == 'PPCM') {
              this.form.role = userData.roles[a].role_list
            }
          }
        }
      //  console.log(this.now)
    },
    dataApprove(value) {
      if (value == null || value == '') {
        return 'Need To Approve';
      } else  {
        return value;
      }
    },
    Loading(value) {
        if (value == 'start') {
        this.loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            color: '#f89830',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
            });
        }
        if (value == 'stop') {
          // Set delay 1 second
          setTimeout(() => {
            this.loader.hide()
          }, 500);
        }
    },
    OnSchedule(value) {
        let schedule = value;
        if (schedule >= this.DateToday) {
            return 'Yes';
        }
        if (schedule < this.DateToday) {
            return 'Overdue';
        }
    },
    chkDateOnSchedule(param1, param2, param3){
        let duedate   = new Date(param1);
        let today     = new Date(param2);
        let status    = param3.toLowerCase();
        // console.log(duedate);
        // console.log(today);
        // console.log(status);

        if (status == 'new' || status == 'pending') {
          return 'new'
        }
         if (duedate < today) {
          return 'red'
        }
        if (duedate >= today) {
          return 'green'
        }
      },
    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      if(s === this.currentSort1) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.totalData) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        totalPage() {
         this.totalRows = this.totalData
        },
    IOApprov(data) {
      // this.$router.push({ name: 'PPCInputApproval', params: { data_io: data } })
      let routeData = this.$router.resolve({ name: 'PPCInputApproval', query: { io: data }})
      // console.log(routeData.href)
      // window.open(routeData.href, '_blank')
      window.open(routeData.href, '_self')
    },
    chkUrlTarget(formUrl){
      let chkUrlString = formUrl.substring(0, 3)

      if(chkUrlString === '/#/') {
        return "_self"
      }
      else {
        return "_blank"
      }
    },
  },
  mounted() {
    this.Loading('start')
    this.clearStatePpm()
    this.getApproveData().then(rsp => {
      if (rsp != 'No Data') {
        this.totalData = rsp.length
      }
      this.Setdata_IO()
    }).catch(err =>{
      if(err.response.status !== 401) {
        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali / Contact Administrator'})
        this.$router.push({name:"Application"})
      }
    })
    .finally(()=>{
    this.Loading('stop')
    })


  },
};
</script>
<style src="../InputPPM/DashboardPPCM.scss" lang="scss" scoped />
