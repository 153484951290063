<template>
  <div>
    <div v-if="!useOverlay" class="data-loader">
      <i
        class="la la-spinner la-spin"
        v-bind:style="{ fontSize: size + 'px' }"
      ></i>
    </div>
    <div v-if="useOverlay" class="vld-parent">
      <loading
        :active.sync="isLoading"
        :can-cancel="canCancel"
        :on-cancel="whenCancelled"
        :is-full-page="fullPage"
        :height="height"
        :width="width"
        :color="color"
        :background-color="bgColor"
        :loader="loader"
        v-on:showLoading="handleShow"
        v-on:hideLoading="handleHide"
      >
        <h3 v-if="useSlot">Loading ...</h3>
      </loading>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "Loader",
  components: { Loading },
  props: {
    // General Props
    useOverlay: { type: Boolean, default: false },
    // Default Loader Props
    size: { type: Number, default: 21 },
    // loading-overlay props
    fullPage: { type: Boolean, default: false },
    canCancel: { type: Boolean, default: false },
    useSlot: { type: Boolean, default: false },
    color: { type: String, default: "#f89830" },
    loader: { type: String, default: "dots" },
    width: { type: Number, default: 64 },
    height: { type: Number, default: 64 },
    bgColor: { type: String, default: "#A9A9A9" },
    opacity: { type: Number, default: 0.5 },
    zIndex: { type: Number, default: 9999 },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    handleShow() {
      this.isLoading = true;
    },
    handleHide() {
      this.isLoading = false;
    },
    whenCancelled(e) {
      // to be implemented
    },
  },
};
</script>

<style src="./Loader.scss" lang="scss"/>
