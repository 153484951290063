<template>
  <div>
    <b-row>
      <b-col cols="6">
        <b-form-group label-cols="6" label-cols-lg="4" label="Type">
          <label>: {{ detailItems.type_dms }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Business Unit">
          <label>: {{ detailItems.business_unit }}</label>
        </b-form-group>
        <!-- <b-form-group label-cols="6" label-cols-lg="4" label="Location">
          <label>: {{ detailItems.location }}</label>
        </b-form-group> -->
        <b-form-group label-cols="6" label-cols-lg="4" label="License Type">
          <label>: {{ detailItems.permit_type }}</label>
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="License No.">
          <label>: {{ detailItems.permit_no }}</label>
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="Extend">
          <label>: {{ detailItems.extend }}</label>
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="Extend No.">
          <label>: {{ detailItems.extend_no }}</label>
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="Start Date">
          <label
            >:
            {{
              new Date(detailItems.start_date).toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}</label
          >
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="End Date">
          <label
            >:
            {{
              new Date(detailItems.end_date).toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}</label
          >
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="Duration">
          <label>: {{ detailItems.durations }} Month</label>
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="Remind Date">
          <label
            >:
            {{
              new Date(detailItems.remind_date).toLocaleDateString("id-ID", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}</label
          >
        </b-form-group>

        <b-form-group label-cols="6" label-cols-lg="4" label="Reminder">
          <label>: {{ detailItems.reminder }} Month</label>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label-cols="6" label-cols-lg="4" label="Institution">
          <label>: {{ detailItems.institution }}</label>
        </b-form-group>
        <b-form-group
          label-cols="6"
          label-cols-lg="4"
          label="Institution Level"
        >
          <label>: {{ detailItems.level_name }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Provinsi">
          <label>: {{ detailItems.province }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Kota">
          <label>: {{ detailItems.city }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Kecamatan">
          <label>: {{ detailItems.district }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Keluaran">
          <label>: {{ detailItems.ward }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Storage Location">
          <label>: {{ detailItems.file_location }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Dept. PIC">
          <label>: {{ detailItems.dept_name }}</label>
        </b-form-group>
        <b-form-group label-cols="6" label-cols-lg="4" label="Document File">
          <div v-for="files in detailFile" :key="files.file_src">
            <a :href="files.file_src" target="_blank">{{ files.file_name }}</a>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["detailItems", "detailFile"],
  data() {
    return {};
  },
};
</script>
