<template>
    <div class="wrapper">
        <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
        </b-breadcrumb-item>
        <b-breadcrumb-item tag="a" to="/app/appsplm-approve-design-list">{{formName}}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid="sm">
            <b-card
                header="Approval Design List"
                header-tag="header"
            >
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                <b-card sub-title="Filter">
                    <b-row>
                        <b-col sm="auto" md="4" lg="4">
                                <b-form-group
                                    label="Brand"
                                    label-for="brand-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    v-slot="{ ariaDescribedby }"
                                    >
                                    <b-form-select
                                        id="brand-select"
                                        v-model="filters.brand"
                                        :options="brandOpt"
                                        :aria-describedby="ariaDescribedby"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group
                                    label="Coll. Month"
                                    label-for="coll-month-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="coll-month-select"
                                        v-model="filters.month"
                                        :options="monthOpt"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>

                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            
                            <b-form-group
                                    label="Status"
                                    label-for="status-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="status-select"
                                        v-model="filters.status"
                                        :options="statusOpt"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                            <b-form-group
                                    
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Search
                                    </template>
                                    <b-input-group size="sm">
                                        <b-form-input 
                                            id="inputSearch" 
                                            v-model="filter"
                                            type="search"
                                            placeholder="Type to Search" 
                                            trim
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Search</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
                <b-table
                    responsive
                    stickyColumn
                    striped
                    hover
                    show-empty
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :items="filterProduct"
                    :fields="fields"
                    :filter="filter"
                    :filter-included-fields="includeFiled"
                    
                >
                    <!-- <template v-slot:cell(actions)="{item}">
                        <b-button title="View" size="xs" v-on:click="detailApprove(item.id)">
                            <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                        </b-button>
                    </template> -->
                    <template #cell(#)="data">
                        {{ data.index + 1 }}
                    </template>
                    <template #cell(status)="{item}" >
                        {{item.status}}
                    </template>
                    <template v-if="roles" #cell(actions)="{item}">
                        <b-button title="Aprrove" size="xs" variant="info" v-on:click="goApprove(item.id)">Detail</b-button>
                    </template>
                    <template #cell(process)="{item}">
                        <div v-if="item.fabric_process == 'TBD' || item.accessories_process == 'TBD' || item.thread_process == 'TBD' || item.artwork_process == 'TBD' || item.interlining_process == 'TBD'">
                            TBD
                        </div>
                    </template>
                </b-table>
                <div class="mt-3">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="center"
                    >

                    </b-pagination>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import {mapGetters,mapActions} from 'vuex';
import SubmitView from '../ProductDesign/ProductDesignSubmitView';
export default {
    components:{
        SubmitView
    },
    data(){
        return{
            show:false,
            formName:'Approval Design',
            modulName:'List',
            isLoadingData:false,
            errorMessage:null,
            fields:[
                '#',
                {key:'actions', label:'#'},
                'code','brand','article','coll_month','date',
                {key:'status', label:'Status'},
                {key:'process',label:'Prosess'},
            ],
            items:[],
            paging:{
                perPage:10,
                currentPage:1,
            },
            isLoading:false,
            search:null,
            filter:null,
            brandOpt:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'ANTHM',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
            ],
            filters:{
                brand:'',
                month:'',
                year:'',
                status:'',
            },
            monthOpt:[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            statusOpt:[
                
                {value:'Waiting For Approval',text:'Waiting For Approval'},
                {value:'On Development',text:'On Development'},
                {value:'Finish',text:'Finish'},
            ],
            includeFiled:['code','brand','article','coll_month','date','status'],
            //bad way role
            role:['Super User','Designer SPV','MD'],
            rols:['Super User']
        }
    },
    computed:{
        ...mapGetters('productDesign',['product']),
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
            // return this.role.some(i=>this.rols.includes(i))
        },
        rows() {
            console.log(this.filterProduct.length);
            return this.filterProduct.length
        },
        filterProduct(){

            
           
                console.log(
                    
                );
                let brands = this.filters.brand
                let months = this.filters.month
                let statuses = this.filters.status
                return this.items.filter(function(item){
                        let filtereds = true
                        if(brands && brands.length > 0){
                            filtereds = item.brand == brands
                        }
                        if(filtereds){
                            if(months && months.length > 0){
                                filtereds = item.coll_month == months
                            }
                        }
                        if(filtereds){
                            if(statuses && statuses.length > 0){
                                filtereds = item.status == statuses
                            }
                        }
                        return filtereds
                    })
            
        }
    },
    methods:{
        ...mapActions('session',['showNotifications']),
        ...mapActions('productDesign',['getProduct','getProductDetail','GET_PRODUCT_DASHBOARD']),
        goApprove(id){
            console.log('param',id);
            let routeData = this.$router.resolve({name: 'AppsApproveDesignFormPage', query: {productId:id}});
            window.open(routeData.href, '_blank');
            // this.$router.push({
            //     name:'AppsApproveDesignFormPage',
            //     params:{productId:id}
                
            // })
        },
        getData(){
            // this.getProduct().then(response => {
            //     // console.log(response);
            //     // console.log('product list',this.productList);
                
            //         // this.dataProductDesign.push(this.productList)
            //         this.items = response
                
            // })
            this.isLoading = true
            this.GET_PRODUCT_DASHBOARD().then(rsp => {
                this.items = rsp.filter(i=>i.status !== 'Draft')
                this.isLoading = false
                // console.log(this.items);
            }).catch(err =>{
                this.isLoading = false
            })
        },
    },
    mounted(){
        this.getData()
    }
}
</script>