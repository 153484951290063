import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiCosting,
    fnApiPostCosting,
}from '../../api/api-aplications-product'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        ST_PRODUCT_COSTING:null,

    }
}

const state = getDefaultState()

const getters = {
    PRODUCT_COSTING(state){
        return state.ST_PRODUCT_COSTING
    }
}

const mutations = {
    GET_PRODUCT_COSTING(state,item){
        state.ST_PRODUCT_COSTING = item
    }
}

const actions = {
    GET_PRODUCT_COSTING({commit}){
        return new Promise((resolve,reject)=>{
            fnApiCosting()
            .then(rsp => {
                resolve(rsp.data.payload)
                // commit('GET_PRODUCT_COSTING',rsp.data.payload)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    POST_PRODUCT_COSTING(context, productCosting){
        return new Promise((resolve,reject)=>{
            fnApiPostCosting(productCosting)
            .then(rsp => {
                resolve(rsp)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
}

export default new Vuex.Store({
    namespaced: true,
    actions
})