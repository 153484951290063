<template>
  <nav
    :class="{sidebar: true, sidebarStatic, sidebarOpened, sidebarColorOrange, sidebarColorWhite}"
    @mouseenter="sidebarMouseEnter"
    @mouseleave="sidebarMouseLeave"
  >
    <header class="logo">
      <router-link to="/app/dashboard" class="fontbbi"><img class='logo_orange' style='height:50px;' src='../../assets/logo-bbi.png' /><img class='logo_white' style='height:50px;' src='../../assets/logo-bbi-white.png' /></router-link>
    </header>
    <ul class="nav">
      <NavLink
        header="Dashboard"
        link="/app/dashboard"
        iconName="flaticon-home"
        index="dashboard"
        isHeader
      /> 
      <NavLink
        header="Applications"
        link="/app/applications"
        iconName="flaticon-app"
        index="applications"
        isHeader
      />
      <NavLink
        header="Reports"
        link="/app/reports"
        iconName="flaticon-note"
        index="tables"
        isHeader
      />
      <NavLink 
        :activeItem="activeItem"
        header="Workflow"
        link="/app/workflow"
        iconName="flaticon-edit-1"
        index="workflow"
        :childrenLinks="[
          { header: 'Inbox', link: '/app/workflow-inbox' },
          { header: 'Outbox', link: '/app/workflow-outbox' },
          { header: 'Close', link: '/app/workflow-close' },
        ]"
      />
      <!-- <NavLink
        header="Typography"
        link="/app/typography"
        iconName="flaticon-list"
        index="typography"
        isHeader
      />
      <NavLink
        header="Tables Basic"
        link="/app/tables"
        iconName="flaticon-equal-1"
        index="tables"
        isHeader
      />
      <NavLink
        header="Notifications"
        link="/app/notifications"
        iconName="flaticon-star"
        index="notifications"
        isHeader
      />
      <NavLink 
        :activeItem="activeItem"
        header="Components"
        link="/app/components"
        iconName="flaticon-network"
        index="components"
        :childrenLinks="[
          { header: 'Charts', link: '/app/components/charts' },
          { header: 'Icons', link: '/app/components/icons' },
          { header: 'Maps', link: '/app/components/maps' },
        ]"
      /> -->
    </ul>
    <!-- <p>
    <h5 class="navTitle">
      LABELS
      <a class="actionLink">
        <i class="la la-plus float-right" />
      </a>
    </h5>
    <ul class="sidebarLabels">
      <li>
        <a href="#">
          <i class="fa fa-circle text-warning mr-2" />
          <span class="labelName">My Recent</span>
        </a>
      </li>
      <li>
        <a href="#">
          <i class="fa fa-circle text-gray mr-2" />
          <span class="labelName">Starred</span>
        </a>
      </li>
      <li>
        <a href="#">
          <i class="fa fa-circle text-danger mr-2" />
          <span class="labelName">Background</span>
        </a>
      </li>
    </ul> -->
    <!-- <h5 class="navTitle">
      TARGETS
    </h5>
    <div class="sidebarAlerts">
      <b-alert
        v-for="alert in alerts"
        :key="alert.id"
        class="sidebarAlert" variant="transparent"
        show dismissible
      >
        <span>{{alert.title}}</span><br />
        <b-progress class="sidebarProgress progress-xs mt-1"
          :variant="alert.color" :value="alert.value" :max="100" />
        <small>{{alert.footer}}</small>
      </b-alert>
    </div> -->
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import isScreen from '@/core/screenHelper';
import NavLink from './NavLink/NavLink';

export default {
  name: 'Sidebar',
  components: { NavLink },
  data() {
    return {
      alerts: [
        {
          id: 0,
          title: 'Sales Report',
          value: 15,
          footer: 'Calculating x-axis bias... 65%',
          color: 'info',
        },
        {
          id: 1,
          title: 'Personal Responsibility',
          value: 20,
          footer: 'Provide required notes',
          color: 'danger',
        },
      ],
    };
  },
  methods: {
    ...mapActions('layout', ['changeSidebarActive', 'switchSidebar']),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split('/');
      paths.pop();
      this.changeSidebarActive(paths.join('/'));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen('lg') || isScreen('xl'))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },
  },
  created() {
    this.setActiveByRoute();
  },
  computed: {
    ...mapGetters('layout', ['colorOrange', 'colorWhite']),
      sidebarColorOrange(){
        return this.colorOrange
      },
      sidebarColorWhite(){
        return this.colorWhite
      },
    ...mapState('layout', {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement,
    }),
  },
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped/>
