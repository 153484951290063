import { render, staticRenderFns } from "./HealthDeclarationReportV2AllBU.vue?vue&type=template&id=354bbf36&scoped=true&"
import script from "./HealthDeclarationReportV2AllBU.vue?vue&type=script&lang=js&"
export * from "./HealthDeclarationReportV2AllBU.vue?vue&type=script&lang=js&"
import style0 from "./HealthDeclarationReport.scss?vue&type=style&index=0&id=354bbf36&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354bbf36",
  null
  
)

export default component.exports