<template>
  <div class="tables-basic">
    <!-- <b-breadcrumb>
      <b-breadcrumb-item><span class='fontbbi space2 text-secondary'>YOU ARE HERE</span></b-breadcrumb-item>
      <b-breadcrumb-item active><span class='fontbbi space2'>Dashboard Subcon</span></b-breadcrumb-item>
    </b-breadcrumb> -->
    <h2 class="page-title"> <span style="font-size:16px;" class="fw-semi-bold fontbbi space3">Dashboard Subcon</span></h2>
   <b-row>
      <b-col xs="12">
        Search <input type="text"
         placeholder=""
         v-model="filter">
          <div style="padding-top:20px;" class="table-responsive">
          <table class="table table-lg mb-0">
              <thead>
                <tr style="text-align:center;" class="text-muted">
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('io')">IO/PO</th>
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('due_date')">Due Date</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Article</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Sample</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Qty Plan</th>
                    <th style="cursor:pointer; border:1px solid #CCC; font-weight: 800;" @click="sort('status')">Status</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Cut</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Sew</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Finish</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Pack</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Embro</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Print</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Emblem</th>
                    <th style="border:1px solid #CCC; font-weight: 800;">Wash</th>
                </tr>
              </thead>
              <tbody>
                <tr
                v-for="row in filteredRows"
                :key="row.id"
                style="text-align:center;"
                >
                  <td style="border-right:1px solid #CCC; border-left:1px solid #CCC;" :class="chkDateOnSchedule(row.due_date, today, row.status , row.end_date_receive)">
                    <span v-html="highlightMatches(row.io)" @click="IOPPCM(row.io)" style="color:#5497f3; text-decoration: underline; cursor: pointer;" class="fw-semi-bold fontbbi space3">{{row.io}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span v-if="row.due_date !== null" v-html="highlightMatches(row.due_date) && FormatDate(row.due_date)" class="fw-semi-bold fontbbi space3"></span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi space3">{{row.artikel}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi space3">{{row.sample}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi space3">{{row.total_qty}}</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span v-html="highlightMatches(row.status) && statusHidden(row.status)" class="fw-semi-bold fontbbi space3"></span>
                  </td>
                  <!-- Calc Percentage Progress -->
                  {{TotalQtyPercent(row.id,
                    row.cutting_progress, row.sewing_progress, row.finishing_progress,
                    row.packing_progress, row.printing_progress, row.embroidery_progress,
                    row.emblem_progress, row.washing_progress,

                    row.total_qty,
                    row.order_process)}}
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.cutting_progress !== null">{{cutting_percent[row.id]}}%</span>
                  </td>
                   <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.sewing_progress !== null">{{sewing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.finishing_progress !== null">{{finishing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.packing_progress !== null">{{packing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.embroidery_progress !== null">{{embroidery_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.printing_progress !== null">{{printing_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.emblem_progress !== null">{{emblem_percent[row.id]}}%</span>
                  </td>
                  <td style="border-right:1px solid #CCC; border-bottom:1px solid #CCC;">
                    <span class="fw-semi-bold fontbbi " v-if="row.washing_progress !== null">{{washing_percent[row.id]}}%</span>
                  </td>


                </tr>
              </tbody>
            </table>
            <br />
            <p>
  <button @click="prevPage" :disabled="cantGoBack">Previous</button> <span style="padding-left: 5px;"></span>
  <button @click="nextPage" :disabled="cantNext">Next</button>
  </p>
          </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Widget from '@/components/Widget/Widget';
// import appsStore from '@/store/apiApps.js';

export default {
        name: 'DashboardSubcon',
        components: { Widget },

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            return{
                errorMessage: null,
                today:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                currentSort:'status',
                currentSort1:'due_date',
                currentSortDir:'desc',
                oneDay:oneDay,
                pageSize:5,
                currentPage:1,
                actual_cutting:[],
                actual_sewing:[],
                actual_finishing:[],
                actual_packing:[],
                actual_printing:[],
                actual_embroidery:[],
                actual_emblem:[],
                actual_washing:[],
                totalQty:[],
                progress_cutting:[],
                progress_sewing:[],
                progress_finishing:[],
                progress_packing:[],
                progress_printing:[],
                progress_embroidery:[],
                progress_emblem:[],
                progress_washing:[],
                order_process:[],
                cutting_percent:[],
                sewing_percent:[],
                finishing_percent:[],
                packing_percent:[],
                printing_percent:[],
                embroidery_percent:[],
                emblem_percent:[],
                washing_percent:[],
                sewing_total:[],
                finishing_total:[],
                packing_total:[],
                printing_total:[],
                embroidery_total:[],
                emblem_total:[],
                washing_total:[],
                filter: '',
                filterOn: [],
                DateToday:today,
                totalData:0,
                form : {
                    fullname:null,
                    role:null,
                },

            }
        },
  computed: {
    ...mapState('application', ['stAppAccess']),
    ...mapGetters('session', ['userData']),
    ...mapState('ppm', ['stAllPPMV2']),
    cantGoBack() {
      return this.currentPage === 1;
    },
    cantNext() {
      if (this.totalData == 0) {
        return this.currentPage === 1;
      }
        return this.currentPage === Math.ceil(this.totalData / this.pageSize)
    },
    filteredRows() {
      if (this.stAllPPMV2 !== null) {
    return this.stAllPPMV2.filter(row => {
      const io = row.io.toString().toLowerCase();
      const due_date = row.due_date.toString().toLowerCase();
      const status = row.status.toLowerCase();
      const artikel = row.artikel.toLowerCase();
      const searchTerm = this.filter.toLowerCase();
      if (row.cutting_subcon !== null) {
        const cutting_subcon = row.cutting_subcon.toLowerCase();
        return cutting_subcon.includes(searchTerm) ||
        io.includes(searchTerm) ||
        status.includes(searchTerm) ||
        artikel.includes(searchTerm) ||
        due_date.includes(searchTerm);
      } else {
        const cutting_subcon = row.cutting_subcon;
      }
      return io.includes(searchTerm) ||
        status.includes(searchTerm) ||
        artikel.includes(searchTerm) ||
        due_date.includes(searchTerm);
    }).sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort1] < b[this.currentSort1]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        if(a[this.currentSort1] > b[this.currentSort1]) return 1 * modifier;

        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
      }
  },
  },
  methods: {
    ...mapActions('application', ['getApplicationAccess']),
    ...mapActions('ppm', ['clearStatePpm','getALLIOPPM','getSubconDailyDataV2']),
    highlightMatches(text) {
    const matchExists = text.toLowerCase().includes(this.filter.toLowerCase());
    if (!matchExists) return text;
    const re = new RegExp(this.filter, 'ig');
    return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);

    },
    statusHidden(value) {
      let status = ((value.substring(8,20)).split(")")[0]);
      return status

    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
          return ' '
      } else {
      let date = new Date(value)
      let tgl = date.toISOString().slice(8,10)
      let bulan = this.monthNames[date.getMonth()]
      let tahun = date.toISOString().slice(0,4)
      date = tgl+' '+bulan+' '+tahun
      return date
      }
    },
    Loading(value) {
        if (value == 'start') {
        this.loader = this.$loading.show({
            // Optional parameters
            container: this.fullPage ? null : this.$refs.formContainer,
            canCancel: true,
            color: '#f89830',
            loader: 'spinner',
            width: 64,
            height: 64,
            backgroundColor: '#ffffff',
            opacity: 0.5,
            zIndex: 999,
            });
        }
        if (value == 'stop') {
          // Set delay 1 second
          setTimeout(() => {
            this.loader.hide()
          }, 500);
        }
    },
    Setdata_IO() {
        let userData     = this.userData
        this.$set(this.form, 'fullname', userData.fullname)
        if (userData.roles) {
          for (let a=0; a<userData.roles.length; a++) {
            if (userData.roles[a].app == 'PPCM') {
              this.form.role = userData.roles[a].role_list
            }
          }
        }
        // this.form.role = this.form.role.toLowerCase();
        this.Loading('start')
        this.getSubconDailyDataV2({role:this.form.role,subcon:this.form.fullname})
        .then(rsp => {
          if (rsp != 'No Data') {
            this.totalData = rsp.length
          }

        })
        .finally(()=>{
          this.Loading('stop')
        })

    },
    calcProcessPercent(index, process, progress) {
        if (process == 'b') {
          this.sewing_total[index] = progress
        }
        if (process == 'c') {
          this.finishing_total[index] = progress
        }
        if (process == 'd') {
          this.packing_total[index] = progress
        }
        if (process == 'e') {
          this.printing_total[index] = progress
        }
        if (process == 'f') {
          this.embroidery_total[index] = progress
        }
        if (process == 'g') {
          this.emblem_total[index] = progress
        }
        if (process == 'h') {
          this.washing_total[index] = progress
        }
      },
      TotalQtyPercent(index, cut,sew,fin,pack,print,embro,emblem,wash, total, order_process){
      if (order_process == null || order_process == '' || order_process == undefined) {
        // nothing
      } else {
        let len = Math.abs(order_process.length / 2)
        let param = order_process
        let d = 0
        this.totalQty[index] = total
        for (let a=0;a<=len;a++) {
          // console.log('index: '+index)
          // console.log('param: '+param)
          // console.log('progress: '+progress)
          // console.log('total: '+total)
          // console.log('process : '+a)
          // console.log('order_process: '+order_process)
          if (param[d] == 'a') {
            this.calcProcessPercent(index, param[d+2], cut)
            this.progress_cutting[index] = cut
            this.cutting_percent[index] = (((this.progress_cutting[index] / this.totalQty[index]) * 100).toFixed(1))
            // console.log('this.progress_cutting[index]:  '+this.progress_cutting[index])
            // console.log('this.totalQty[index]:  '+this.totalQty[index])
            // console.log('this.cutting_percent[index]:  '+this.cutting_percent[index])
          }
          if (param[d] == 'b') {
            this.calcProcessPercent(index, param[d+2], sew)
            this.progress_sewing[index] = sew
            this.sewing_percent[index] = (((this.progress_sewing[index] / this.sewing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'c') {
            this.calcProcessPercent(index, param[d+2], fin)
            this.progress_finishing[index] = fin
            this.finishing_percent[index] = (((this.progress_finishing[index] / this.finishing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'd') {
            this.progress_packing[index] = pack
            this.packing_percent[index] = (((this.progress_packing[index] / this.packing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'e') {
            this.calcProcessPercent(index, param[d+2], print)
            this.progress_printing[index] = print
            this.printing_percent[index] = (((this.progress_printing[index] / this.printing_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'f') {
            this.calcProcessPercent(index, param[d+2], embro)
            this.progress_embroidery[index] = embro
            this.embroidery_percent[index] = (((this.progress_embroidery[index] / this.embroidery_total[index]) * 100).toFixed(1))
            // console.log('this.progress_embroidery[index]:  '+this.progress_embroidery[index])
            // console.log('this.embroidery_total[index]:  '+this.embroidery_total[index])
            // console.log('this.embroidery_percent[index]:  '+this.embroidery_percent[index])
          }
          if (param[d] == 'g') {
            this.calcProcessPercent(index, param[d+2], emblem)
            this.progress_emblem[index] = emblem
            this.emblem_percent[index] = (((this.progress_emblem[index] / this.emblem_total[index]) * 100).toFixed(1))
          }
          if (param[d] == 'h') {
            this.calcProcessPercent(index, param[d+2], wash)
            this.progress_washing[index] = wash
            this.washing_percent[index] = (((this.progress_washing[index] / this.washing_total[index]) * 100).toFixed(1))
          }
          d = d + 2
        }
      }

    },
    chkDateOnSchedule(param1, param2, param3, param4){
        let duedate   = (param1);
        let today     = (param2);
        let status    = param3.toLowerCase();
        let endDate    = (param4);
        // console.log(duedate);
        // console.log(today);
        // console.log(status);

        if (status == 'new' || status == 'new(pending)' || status == 'pending(approval)') {
          return 'new'
        }
        if (endDate == null || endDate == '' || endDate == undefined) {
          if (duedate < today) {
            return 'red'
          }
          if (duedate >= today) {
            return 'green'
          }
        } else {
          if (duedate < endDate) {
            return 'red'
          }
          if (duedate >= endDate) {
            return 'green'
          }
        }

      },
    sort:function(s) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      if(s === this.currentSort1) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;

    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.stAllPPMV2.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
        totalPage() {
         this.totalRows = this.stAllPPMV2.length
        },

    populateGroupTitle(title) {
      this.curGroup = title
      return "<h5><span class='fw-semi-bold'>"+ title +"</span></h5>"
    },
    IOPPCM(data) {
      // this.$router.push({ name: 'PPCInputSubcon', params: { data_io: data } })
      let routeData = this.$router.resolve({ name: 'PPCInputSubcon', query: { io: data }})
      // console.log(routeData.href)
      window.open(routeData.href, '_self')
    },
    chkUrlTarget(formUrl){
      let chkUrlString = formUrl.substring(0, 3)

      if(chkUrlString === '/#/') {
        return "_self"
      }
      else {
        return "_blank"
      }
    },
  },
  mounted() {
    this.Setdata_IO()
    // this.getALLIOPPM()
    this.clearStatePpm();


  },
};
</script>
<style src="../InputPPM/DashboardPPCM.scss" lang="scss" scoped />
