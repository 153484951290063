<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item class="fw-semi-bold">{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Sales Group Form" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3> Sales Group Form</h3>
                <b-button v-if='disabledForm == true' @click="BackHistoryButton()" size="md" type='button' variant="info" style="position: absolute; right: 15px;">
                  <b-icon icon="arrow-left"></b-icon>
                  Back
                </b-button>
              </div>

            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="NIK"
                      label-for="Nik"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                    <b-form-input
                      id="Nik"
                      :disabled="true"
                      v-model="form.employee_nik"
                      trim
                    ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Sales"
                      label-for="Sales Group"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                    <b-form-input
                      id="Person"
                      :disabled="true"
                      v-model="form.employee_name"
                      trim
                    ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-col sm='6'>
                  <b-form-group
                    label="Name Sales Group"
                    label-for="Sales Group"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                  <b-form-input
                    id="sales_group"
                    v-model="form.sales_group_name"
                    trim
                  ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col sm='12'>
                  <b-form-group
                    label="Sales Person"
                    label-for="Sales Group"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                  <multiselect
                    v-model="form.groupSales"
                    tag-placeholder="Add this as new tag"
                    placeholder="Search or add Customer"
                    label="name"
                    @input="ChangeSales"
                    :options="options.sales"
                    :custom-label="CustomLabel"
                    :disabled="disabledForm"
                    track-by="name"
                    :multiple="true"
                    :taggable="true"
                  >
                  </multiselect>
                  </b-form-group>
                </b-col>
              </b-list-group-item>
            </b-list-group>

          </b-card>

          <br />
          <b-row align-h="center">
              <b-col md='6'>
                <b-button v-if='buttonSubmit == null ' block size="md" @click="Confirm('save')" type='submit' variant="primary">
                  Submit
                </b-button>
                <b-button v-if='buttonSubmit == "edit" ' @click="Confirm('edit')" block size="md" type='submit' variant="success">
                  Confirm
                </b-button>
              </b-col>
          </b-row>

        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import apiSalesGroup from '@/store/salesforce-form/sales-group.js';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";
// import vue multiselect
import Multiselect from "vue-multiselect";

export default {
  components: {
    ModelListSelect,
    Multiselect,
    Loader,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'numeric'};
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "Sales Group Form",
      today:today,
      BackHistory:null,
      disabledForm:false,
      buttonSubmit:null,
      today_days:days,
      today_month:month,
      today_year:year,
      roles_list:[],
      options: {
        sales:[],
      },
      form :{
        employee_nik:null,
        employee_name:null,
        sales_group:null,
        groupID:null,
        groupSales:null,
        sales_group_name:null,
      },

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState('sales_force', ['stSlsGrp','stSlsGrpCount','stLstUsr','stLstUsrCount']),


  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions('sales_force', ['getDataSalesGroup','getListUserBBI']),

    setData() {
      let roles = this.userData.roles
      if (roles.length > 0) {
        for (let data in roles) {
          if (roles[data].app == 'SFC') {
            let lower = roles[data].role_list.map(string => {
              return string.toLowerCase();
            });
            this.roles_list = lower
          }
        }
      }
      if (this.roles_list.includes('dept-head') || this.roles_list.includes('superuser')) {
        // console.log()
      } else {
        this.showNotification({type:'error', message:"You don't have permissions to view this page"})
        this.$router.push({name:"ApplicationPage"})
      }
      this.form.employee_nik    = this.userData.nik
      this.form.employee_name   = this.userData.fullname
      this.form.sales_group_name= this.userData.fullname+' Group'

      // let idData                = this.$route.params.idData
       this.options.sales = []
      this.getListUserBBI().then(rsp=> {
        if (rsp != 'No Data') {
          this.options.sales = rsp.payload
        }
        this.LoadListSalesGroup()

      })

    },
    CustomLabel({nik, name}) {
      return `${name} — [${nik}]`
    },
    LoadListSalesGroup(){
      // start loading
      this.isLoadingData = true
      this.getDataSalesGroup({
        sales:this.form.employee_nik,
        })
        .then(rsp => {
          if (rsp != 'No Data') {
            this.setDataForm()
          }
        })
        .catch(err => {
            if(err.response.status !== 401) {
                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
            }
        }).finally(()=>{
          // stop Loading
          setTimeout(() => {
              this.isLoadingData = false
          }, 500);
        })
    },
    BackHistoryButton(){
      let pageView = "ApplicationPage"
      let data = null
      if (this.BackHistory == "ApplicationPage" ) {
          pageView = "ApplicationPage"
      }
      // this.$router.push({name:"SFCCustomerDistribution"})
      this.$router.push({ name: pageView, params: { page:'view', idData:data,  } })
    },
    setDataForm() {
      let FormData = this.stSlsGrp
      this.form.sales_group = FormData[0].sales_group_name
      this.buttonSubmit = 'edit'
      this.form.groupID = FormData[0].sales_group
      this.form.sales_group = []
      this.form.groupSales = []
        for (let a=0; a<=FormData.length-1;a++) {
          this.options.sales.push({
            nik:FormData[a].sales_nik,
            name:FormData[a].sales_name,
          })

          this.form.sales_group.push({
            sales_nik:FormData[a].sales_nik,
            sales_name:FormData[a].sales_name,
          })
          this.form.groupSales.push({
            nik:FormData[a].sales_nik,
            name:FormData[a].sales_name,
          })
        }

    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    ChangeSales(value) {
      this.form.sales_group = []
      if (value.length > 0) {
        for (let a=0;a<=value.length-1;a++) {
          this.form.sales_group.push({
            sales_nik:value[a].nik,
            sales_name:value[a].name
          })
        }
      }
    },
    Confirm(choice, index) {
      let varOk = 'danger'
      if(choice !== 'delete') {
        varOk = 'success'
      }
      let Boxtrue = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to '+choice+' ? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: varOk,
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          Boxtrue = value
          if (Boxtrue == true) {
            if (choice == 'edit') {
              this.PostSalesGroup('edit')
            }
            if (choice == 'save') {
              this.PostSalesGroup('save')
            }
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    PostSalesGroup(value) {
      // start loading
      this.isLoadingData = true
      apiSalesGroup.dispatch('PostSalesGroup',{
        status:value,
        id:this.form.groupID,
        leader_nik:this.form.employee_nik,
        sales_group_name:this.form.sales_group_name,
        sales_group:this.form.sales_group,

      })
      .then(response => {
        if (response.data.message == "Warning") {
          this.showNotification({type:'error', message:response.data.payload})
        } else {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"ApplicationPage"})
        }
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=>{
        // stop Loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
  },

  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>