<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-receive"> Dashboard Receive</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">

                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Input Receive Form</h3>
                                </div>
                            </div>
                        </template>


                                <b-row align-h="center">
                        <!-- data IO -->
                                <center>
                                <table class="table table-lg">
                                    <tbody>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Date</span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{formatToday}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Due Date </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.due_date}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">IO </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.data_io}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Subcon </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_subcon}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Article </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.artikel}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Sample </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.sample}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Plan </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.qty_plan}}</span>
                                            </td>
                                        </tr>

                                            <tr id="table-info">
                                                <td v-if="form.tipe == 'Partial'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Partial</span>
                                                </td>
                                                <td v-if="form.tipe == 'Partial'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: Yes</span>
                                                </td>

                                                <td v-if="form.tipe == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Full</span>
                                                </td>
                                                <td v-if="form.tipe == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: Yes</span>
                                                </td>
                                            </tr>

                                            <tr  id="table-info">
                                                <td v-if="form.tipe == 'Partial' " style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Partial</span>
                                                </td>
                                                <td v-if="form.tipe == 'Partial' " style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: {{form.visit_delivery}}</span>
                                                </td>

                                                <td v-if="form.tipe == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Full</span>
                                                </td>
                                                <td v-if="form.tipe == 'Full'" style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">: {{form.visit_delivery}}</span>
                                                </td>
                                            </tr>

                                            <tr  id="table-info">
                                                <td style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Reason </span>
                                                </td>
                                                <td style="border:0px" id='tablebasicTD'>
                                                    <b-form-select class="form-custom" v-model="form.keterangan_receive" :options="opt_reason" placeholder="Choose Type" style="top: 1px; max-width: 200px;">
                                                    </b-form-select>
                                                </td>
                                            </tr>
                                            <tr  id="table-info">
                                                <td style="border:0px">
                                                    <span class="fw-semi-bold fontbbi">Description </span>
                                                </td>
                                                <td style="border:0px">
                                                    <b-form-textarea
                                                    id="textarea-state"
                                                    v-model="form.keterangan_receive_detail"
                                                    placeholder="Enter Detail Reason"
                                                    no-resize
                                                    rows="3"
                                                ></b-form-textarea>
                                                </td>
                                            </tr>

                                        <tr id="table-info">
                                            <td id="table-td">

                                            </td>
                                            <td id="table-td">

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                </center>
                                </b-row>
                                <b-row>
                                <b-col xs="12">
                                    <div class="table-responsive">
                                        <table class="table table-lg mb-0">
                                            <thead>
                                                <tr style="text-align:center; " class=text-table-th>
                                                    <th>
                                                        UOM
                                                    </th>
                                                    <th>
                                                        Qty Delivery
                                                    </th>
                                                    <th>
                                                        Receive
                                                    </th>
                                                    <th>
                                                        Remaining
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="text-align:center; ">
                                                    <td style="background-color:#ffc247">Pcs</td>
                                                    <td style="background-color:#CCC">{{form.qty_pcs}}</td>
                                                    <td style="text-align:center;">
                                                        <validation-provider
                                                        name="qty_pcs"
                                                        >
                                                        <b-form-input class="select-custom" :disabled="alldisabled" type="number" id="qty_pcs" v-model="qty_pcs_actual"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>
                                                    <td style="background-color:#CCC">{{qty_remaining_pcs}}</td>
                                                </tr>

                                                <tr style="text-align:center; ">
                                                    <td style="background-color:#ffc247">Box</td>
                                                    <td style="background-color:#CCC">{{form.qty_box}}</td>
                                                    <td style="text-align:center;">
                                                        <validation-provider
                                                        name="qty_box"
                                                        >
                                                        <b-form-input class="select-custom" :disabled="alldisabled" type="number" id="qty_box" v-model="qty_box_actual"   trim></b-form-input>
                                                        </validation-provider>
                                                    </td>
                                                    <td style="background-color:#CCC">{{qty_remaining_box}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>

                            <br />

                            <b-row>
                                <b-col xs="12">
                                    <div class="table-responsive">
                                        <table class="table table-lg mb-0">
                                            <thead>
                                                <tr v-if="stPPCMLogReceiveDtCount !== 0" style="text-align:center; " class=text-table-th>
                                                    <th>
                                                        Date
                                                    </th>
                                                    <th>
                                                        Partial
                                                    </th>
                                                    <th>
                                                        Qty (Pcs)
                                                    </th>
                                                    <th>
                                                        Qty (Box)
                                                    </th>
                                                    <th>
                                                        Difference (Pcs)
                                                    </th>
                                                    <th>
                                                        Difference (Box)
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style="text-align:center;"
                                                    v-for="row in stPPCMLogReceiveDt"
                                                    :key="row.id"
                                                    >
                                                    <td v-if="row.qty_pcs_receive !== 0" v-html="FormatDate(row.date)">>
                                                        {{row.date}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.visit}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_pcs_receive}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_box_receive}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_difference_pcs}}
                                                    </td>
                                                    <td v-if="row.qty_pcs_receive !== 0">
                                                        {{row.qty_difference_box}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </b-col>
                            </b-row>
                            <br />
                            <br />
                               <b-row  align-h="center">
                                    <b-col sm="3">
                                        <b-button block :disabled="alldisabled" @click="SubmitCompleted()" style='text-align:center;' ref="completed" type="button" variant="warning">Completed</b-button>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block :disabled="alldisabled" @click="Submit()" ref="save" type="button" variant="success">Save</b-button>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block :disabled="alldisabled" @click="Cancel()" ref="cancel" type="button" variant="danger">Cancel</b-button>
                                    </b-col>
                                </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import PostLogReceive from '@/store/ppm-form/ppm.js';
    import UpdateReceive from '@/store/ppm-form/ppm.js';
    import UpdateInputPPM from '@/store/ppm-form/ppm.js';
    import PostOrderReason from '@/store/ppm-form/ppm.js';
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);

    export default {
       name: 'PPCMInputReceive',

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const time = now.toString().slice(16,24)
            return{
                formName: 'Form Receive PPCM',
                errorMessage: null,
                max_date:today,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                today:today,
                time:time,
                formatToday:null,
                oneDay:oneDay,
                data_reason:'receive',
                opt_reason: [
                    { value: null, text: '--- Please Choice ---' , disabled: true },
                ],
                keterangan_receive:null,
                keterangan_receive_detail:null,
                keterangan_receive_last:null,
                qty_pcs_actual:0,
                qty_box_actual:0,
                total_actual_days:null,
                shift:1,
                alldisabled:false,
                    form : {
                    data_io:null,
                    Employee:null,
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    manufacture_date:null,
                    total_qty:null,
                    due_date:null,
                    cutting_subcon :null,
                    cutting_date:null,
                    cutting_progress:null,
                    qty_packing:null,
                    qty_shortship:null,
                    qty_pcs:0,
                    qty_box:0,
                    qty_pcs_receive:null,
                    qty_box_receive:null,
                    tipe:null,
                    visit_delivery:null,
                    visit_receive:null,
                    status:null,
                    datenow:today,
                    remaining_pcs:0,
                    remaining_box:0,
                    start_date_receive:null,
                    keterangan_receive:null,
                    keterangan_receive_detail:null,
                    },
                    boxTwo:'',



            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stPPCMReceiveData','stPPCMReceiveDataCount','stPPCMLogReceiveDt','stPPCMLogReceiveDtCount','stIoPPM','StDataReason','StDataReasonCount']),

             qty_remaining_pcs() {
                 let remaining = 0
                 remaining = this.form.qty_pcs - this.qty_pcs_actual
                //  console.log(remaining)
                return remaining;

             },
             qty_remaining_box() {
                 let remaining_box = 0
                 remaining_box = this.form.qty_box - this.qty_box_actual
                //  console.log(remaining)
                return remaining_box;

             },

        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getPPCMReceiveFormData','GetDataPPCMReceiveyLog','getInputIOPPM','getDataReason']),
            ...mapActions('master_calender', ['GetDateCalculation']),
           FormatDate(value) {
                if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 1000);
                }
            },
           Setdata_IO() {
                let data_IO      = this.$route.query.io
                // let userData     = this.userData
                // this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardReceive'})
                }
                if (this.form.data_io != null) {
                    this.GetDataPPCMReceiveyLog(this.form.data_io);
                    this.getPPCMReceiveFormData(this.form.data_io);
                    this.getInputIOPPM(this.form.data_io).then(this.GetdataForm()).catch(err => {
                    if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardReceive"})
                    }
                })
                .finally(()=>{
                    this.Loading('stop')
                })
                }
            },

            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stPPCMReceiveDataCount == 0 || this.stPPCMReceiveDataCount == null || this.stIoPPM == null) {
                    this.DelayGetData()
                    return
                }
                // Set Data v-model
                let formdata = this.stPPCMReceiveData
                this.form.artikel = formdata[0].artikel
                this.form.sample = formdata[0].sample
                this.form.Employee = formdata[0].user
                this.form.brand = formdata[0].brand
                this.form.start_date_receive = formdata[0].datenow
                if (formdata[0].date !== null) {
                    this.form.cutting_date = formdata[0].date
                }
                console.log(this.form.start_date_receive)
                this.formatToday = this.FormatDate(this.today)
                if (formdata[0].due_date !== null) {
                    this.form.due_date = this.FormatDate(formdata[0].due_date)
                }
                this.form.cutting_subcon = formdata[0].subcon
                this.form.qty_plan = formdata[0].qty_plan
                this.form.qty_pcs = formdata[0].qty_pcs;
                this.form.qty_box = formdata[0].qty_box;
                this.form.qty_pcs_receive = formdata[0].qty_pcs_receive;
                this.form.qty_box_receive = formdata[0].qty_box_receive;

                if (this.form.qty_pcs_receive == null || this.form.qty_pcs_receive == 0) {
                    this.form.qty_pcs = parseInt(this.form.qty_pcs)
                } else {
                    this.form.qty_pcs = parseInt(this.form.qty_pcs) - parseInt(this.form.qty_pcs_receive)
                }
                if (this.form.qty_box_receive == null || this.form.qty_box_receive == 0) {
                    this.form.qty_box = parseInt(this.form.qty_box)
                } else {
                    this.form.qty_box = parseInt(this.form.qty_box) - parseInt(this.form.qty_box_receive)
                }
                this.form.tipe = formdata[0].tipe;
                this.form.visit_delivery = formdata[0].visit_delivery;
                this.form.visit_receive = formdata[0].visit_receive;
                this.form.status = formdata[0].status;

                this.keterangan_receive_last = formdata[0].ket;

                if (this.form.status == 'Done') {
                    this.alldisabled = true;
                }

                let dataPPM = this.stIoPPM
                this.statusPPM = this.stIoPPM[0].status
                // console.log(this.statusPPM)

            },
            Cancel() {
                this.qty_pcs_actual = 0
                this.qty_box_actual = 0
            },

            SubmitCompleted() {
                 if (this.statusPPM !== 'ONGOING(Receive)') {
                    this.showNotification({type:'error', message:'Pastikan Untuk Men-Completed Process Sebelumnya'})
                    return
                }
                this.GetDateCalculation({
                    is_iterate: true,
                    shift_id:this.shift,
                    params:[{
                        // calc cutting Start Date Plan
                        calc_type: "duration",
                        start_date: this.form.cutting_date,
                        end_date: this.today,
                    },
                    ]
                })
                .then(rsp => {
                    this.total_actual_days = rsp[0].duration
                    this.boxTwo = ''
                    this.$bvModal.msgBoxConfirm('Are you want to complete the process?', {
                    title: 'Please Confirm',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                    })
                    .then(value => {
                        this.boxTwo = String(value)
                        if (this.boxTwo == 'true') {
                            console.log('box: '+this.boxTwo)
                            this.Completed()
                        }
                    })
                    .catch(err => {
                        // An error occurred
                    })
                })
                .catch(err => {
                    if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                    }
                })
            },

            Completed() {
                let order_reason = null
                this.keterangan_receive = this.keterangan_receive_last
                if (this.form.keterangan_receive != null) {
                    this.keterangan_receive = this.form.keterangan_receive
                    order_reason = this.form.keterangan_receive
                }
                if (this.form.keterangan_receive_detail != null) {
                    this.keterangan_receive_detail = this.form.keterangan_receive_detail
                }

                let qty_pcs = 0
                let qty_box = 0
                let qty_pcs_receive = 0
                let qty_box_receive = 0
                let tipe = null
                let visit = this.form.visit_receive
                if (visit == null) {
                    visit = 0
                }
                let status = null
                let date = this.today

                qty_pcs = parseInt(this.form.qty_pcs) - parseInt(this.qty_pcs_actual)
                qty_box = parseInt(this.form.qty_box) - parseInt(this.qty_box_actual)
                qty_pcs_receive = this.qty_pcs_actual
                qty_box_receive = this.qty_box_actual
                tipe = 'Partial'
                status = 'Done'

                if (qty_pcs < 0 || isNaN(qty_pcs)) {
                    this.showNotification({type:'error', message:'Qty_Pcs Error'})
                    return
                }
                if (qty_box < 0 || isNaN(qty_box)) {
                    this.showNotification({type:'error', message:'Qty_Box Error'})
                    return
                }

                if (qty_pcs == 0 && visit == 0) {
                    tipe = 'Full'
                }

                visit = parseInt(visit) + 1;

                this.Loading('start')

                // show report after timeout min 1 second

                // PostLogReceive.dispatch('PostReceive', {
                // io:this.form.data_io,
                // user:this.form.Employee,
                // qty_pcs:qty_pcs,
                // qty_box:qty_box,
                // qty_pcs_receive:qty_pcs_receive,
                // qty_box_receive:qty_box_receive,
                // tipe:tipe,
                // visit:visit,
                // status:status,
                // date:date,
                // })
                // .then(response => {
                //
                // console.log(response)
                //
                // })
                // .catch(err => {
                //
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })


                let qty_pcs_receiveput = this.form.qty_pcs_receive
                let qty_box_receiveput = this.form.qty_box_receive

                if (qty_pcs_receiveput == null) {
                    qty_pcs_receiveput = 0
                }

                if (qty_box_receiveput == null) {
                    qty_box_receiveput = 0
                }

                let qty_pcs_receiveupdate = parseInt(qty_pcs_receiveput) + parseInt(this.qty_pcs_actual)
                let qty_box_receiveupdate = parseInt(qty_box_receiveput) + parseInt(this.qty_box_actual)
                let tipeupdate = tipe
                let visitupdate = visit
                let statusupdate = status
                let dateupdate_comp = this.today

                UpdateReceive.dispatch('ApiUpdate_Receive_V2', {
                    condition_process:'completed',
                    io:this.form.data_io,
                    status:statusupdate,
                    ket:this.keterangan_receive,
                    last_date:dateupdate_comp,
                    status_rec:'ONGOING(CloseIO)',
                    total_actual_days:this.total_actual_days,
                    reason_detail:this.keterangan_receive_detail,
                    date_time:this.today+'_'+this.time,
                })
                .then(response => {
                    this.$router.push({name:"DashboardReceive"})
                    this.showNotification({type:'success', message:'Successfully Completed'})
                })
                .catch(err => {
                    if (err.response === 400) {
                        console.log(err.response.data.message)
                        this.showNotification({type:'error', message:err.response.data.message})
                    }
                })
                .finally(()=>{
                    this.Loading('stop')
                })

                // setTimeout(() => {
                // UpdateReceive.dispatch('UpdateFormReceive', {
                // io:this.form.data_io,
                // status:statusupdate,
                // ket:this.keterangan_receive,
                // last_date:dateupdate_comp,
                // })
                // .then(response => {
                //     loader.hide()
                // console.log(response)
                // this.$router.push({name:"DashboardReceive"})
                // this.showNotification({type:'success', message:'Successfully Completed'})
                // })
                // .catch(err => {
                //     loader.hide()
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })
                // .finally(()=>{
                // loader.hide()
                // })
                // },3000)

                // UpdateInputPPM.dispatch('PutInputPPM', {
                // io:this.form.data_io,
                // status:'ONGOING(CloseIO)',
                // total_actual_days:this.total_actual_days,
                // })
                // .then(response => {
                // // console.log(response)
                // })
                // .catch(err => {
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })
                // PostOrderReason.dispatch('PostOrderReason', {
                //     io:this.form.data_io,
                //     process:'receive',
                //     reason_category:order_reason,
                //     reason_detail:this.keterangan_receive_detail,
                //     date_time:this.today+'_'+this.time,
                // })
                // .then(response => {
                // //console.log(response)
                // })
                // .catch(err => {
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })


            },
            Submit() {
                let order_reason = null
                this.keterangan_receive = this.keterangan_receive_last
                if (this.form.keterangan_receive != null) {
                    this.keterangan_receive = this.form.keterangan_receive
                    order_reason = this.form.keterangan_receive
                }
                if (this.form.keterangan_receive_detail != null) {
                    this.keterangan_receive_detail = this.form.keterangan_receive_detail
                }

                if (this.qty_pcs_actual == null || this.qty_pcs_actual == 0 || this.qty_pcs_actual == '' || this.qty_pcs_actual == undefined) {
                    this.showNotification({type:'error', message:'Error Qty_Pcs'})
                    return
                }
                if (this.qty_box_actual == null || this.qty_box_actual == 0 || this.qty_box_actual == '' || this.qty_box_actual == undefined) {
                    this.showNotification({type:'error', message:'Error Qty_Box'})
                    return
                }
                let qty_pcs = 0
                let qty_box = 0
                let qty_pcs_receive = 0
                let qty_box_receive = 0
                let tipe = null
                let visit = this.form.visit_receive
                if (visit == null) {
                    visit = 0
                }
                let status = null
                let date = this.today
                if (this.form.start_date_receive == null) {
                    this.form.start_date_receive = this.today
                }
                qty_pcs = parseInt(this.form.qty_pcs) - parseInt(this.qty_pcs_actual)
                qty_box = parseInt(this.form.qty_box) - parseInt(this.qty_box_actual)
                qty_pcs_receive = this.qty_pcs_actual
                qty_box_receive = this.qty_box_actual
                tipe = 'Partial'
                status = 'Ongoing'
                let qty_difference_pcs = qty_pcs
                let qty_difference_box = qty_box

                if (qty_pcs < 0 || isNaN(qty_pcs)) {
                    this.showNotification({type:'error', message:'Qty_Pcs Error'})
                    return
                }
                if (qty_box < 0 || isNaN(qty_box)) {
                    this.showNotification({type:'error', message:'Qty_Box Error'})
                    return
                }

                if (qty_pcs == 0 && visit == 0) {
                    tipe = 'Full'
                }

                visit = parseInt(visit) + 1;

                let qty_pcs_receiveput = this.form.qty_pcs_receive
                let qty_box_receiveput = this.form.qty_box_receive

                if (qty_pcs_receiveput == null) {
                    qty_pcs_receiveput = 0
                }

                if (qty_box_receiveput == null) {
                    qty_box_receiveput = 0
                }

                let qty_pcs_receiveupdate = parseInt(qty_pcs_receiveput) + parseInt(this.qty_pcs_actual)
                let qty_box_receiveupdate = parseInt(qty_box_receiveput) + parseInt(this.qty_box_actual)
                let tipeupdate = tipe
                let visitupdate = visit
                let statusupdate = status
                let dateupdate = this.today

                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value1 => {
                    this.boxTwo = String(value1)
                    if (this.boxTwo == 'true') {

                        this.Loading('start')

                        UpdateReceive.dispatch('ApiUpdate_Receive_V2', {
                            condition_process:'update',
                            io:this.form.data_io,
                            user:this.form.Employee,
                            qty_pcs:qty_pcs,
                            qty_box:qty_box,
                            qty_pcs_receive:qty_pcs_receiveupdate,
                            qty_box_receive:qty_box_receiveupdate,
                            qty_pcs_receivelog:qty_pcs_receive,
                            qty_box_receivelog:qty_box_receive,
                            qty_difference_pcs:qty_difference_pcs,
                            qty_difference_box:qty_difference_box,
                            tipe:tipe,
                            visit:visit,
                            status:status,
                            date:this.form.start_date_receive,
                            last_date:date,
                            date_now:this.today,
                            ket:this.keterangan_receive,
                            ket_detail:this.keterangan_receive_detail,
                            date_time:this.today+'_'+this.time,
                        })
                        .then(response => {
                            // console.log(response)
                            this.$router.push({name:"DashboardReceive"})
                            this.showNotification({type:'success', message:'Successfully Save'})
                        })
                        .catch(err => {
                            if (err.response === 400) {
                                console.log(err.response.data.message)
                                this.showNotification({type:'error', message:err.response.data.message})
                            }
                        })
                        .finally(()=>{
                            this.Loading('stop')
                        })




                // show report after timeout min 1 second
            //   setTimeout(() => {
            //     PostLogReceive.dispatch('PostReceive', {
            //     io:this.form.data_io,
            //     user:this.form.Employee,
            //     qty_pcs:qty_pcs,
            //     qty_box:qty_box,
            //     qty_pcs_receive:qty_pcs_receive,
            //     qty_box_receive:qty_box_receive,
            //     qty_difference_pcs:qty_difference_pcs,
            //     qty_difference_box:qty_difference_box,
            //     tipe:tipe,
            //     visit:visit,
            //     status:status,
            //     date:this.form.start_date_receive,
            //     last_date:date,
            //     date_now:this.today,
            //     })
            //     .then(response => {
            //     loader.hide()
            //     console.log(response)
            //     this.$router.push({name:"DashboardReceive"})
            //     this.showNotification({type:'success', message:'Successfully Save'})
            //     })
            //     .catch(err => {
            //     loader.hide()
            //     if (err.response === 400) {
            //         console.log(err.response.data.message)
            //         this.showNotification({type:'error', message:err.response.data.message})
            //     }
            //     })
            //     .finally(()=>{
            //     loader.hide()
            //     })
            //     },1000)

            //     UpdateReceive.dispatch('UpdateFormReceive', {
            //     io:this.form.data_io,
            //     qty_pcs_receive:qty_pcs_receiveupdate,
            //     qty_box_receive:qty_box_receiveupdate,
            //     qty_difference_pcs:qty_difference_pcs,
            //     qty_difference_box:qty_difference_box,
            //     tipe:tipeupdate,
            //     visit:visitupdate,
            //     status:statusupdate,
            //     date:this.form.start_date_receive,
            //     last_date:dateupdate,
            //     ket:this.keterangan_receive,
            //     })
            //     .then(response => {
            //     console.log(response)
            //     })
            //     .catch(err => {
            //     if (err.response === 400) {
            //         console.log(err.response.data.message)
            //         this.showNotification({type:'error', message:err.response.data.message})
            //     }
            //     })

                }
                })
                .catch(err => {
                    // An error occurred
                })


                // PostOrderReason.dispatch('PostOrderReason', {
                // io:this.form.data_io,
                // process:'receive',
                // reason_category:order_reason,
                // reason_detail:this.keterangan_receive_detail,
                // date_time:this.today+'_'+this.time,
                // })
                // .then(response => {
                // //console.log(response)
                // })
                // .catch(err => {
                // if (err.response === 400) {
                //     console.log(err.response.data.message)
                //     this.showNotification({type:'error', message:err.response.data.message})
                // }
                // })


            },

            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
        },
        created() {
            this.Loading('start')
            this.getDataReason(this.data_reason).then(rsp=>{
                for (let r=0; r<=this.StDataReasonCount-1; r++) {
                    this.opt_reason.push(
                        { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                    );
                }
                this.Setdata_IO()
            }).catch(err=>{
                if (err.response !== 401) {
                    this.showNotification({type:'error', message:'Data Gagal Di Load, Harap Di Ulang Kembali'})
                }
                this.Loading('stop')
            })
        },

    }
</script>
<style scoped>
#tablebasicTD::after {
  position: relative;
  content: '\f063';
  font-family: 'FontAwesome';
  font-size: 0.8em;
  color: #AAA;
  right: 10px;
  top: calc(60% - 0.5em);
}
#tablebasicTD:hover::after {
  color: rgb(140, 147, 163);
  transition: color 0.5s;
}
</style>
<style src="../Inspection/PPCMInputInspection.scss" lang="scss" scoped />
