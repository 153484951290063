<template>
  <div class="wrapper">
    <b-overlay :show="isLoading" rounded="sm" @shown="onShown">
      <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/applications">
            Applications</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>{{ formName }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ modulName }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-container fluid>
        <b-card>
          <template>
            <b-card sub-title="Filter">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label="Category"
                    label-for="category-select"
                    label-cols="3"
                    label-size="sm"
                    v-slot="{ ariaDescribedby }"
                  >
                    <v-select
                      id="category-select"
                      v-model="filters.category"
                      :options="categoryOpt"
                      size="sm"
                      label="name"
                      :reduce="(i) => i.name"
                      :aria-describedby="ariaDescribedby"
                    >
                    </v-select>
                  </b-form-group>
                  <b-form-group
                    label="Have Expired"
                    label-for="expired-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="expired-select"
                      v-model="filters.expired"
                      :options="expiredOpt"
                      size="sm"
                      label="label"
                      :reduce="(i) => i.value"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="License Type"
                    label-for="license-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="license-select"
                      v-model="filters.permit_type"
                      :options="items"
                      size="sm"
                      label="permit_type"
                      :reduce="(i) => i.permit_type"
                    >
                    </v-select>
                  </b-form-group>
                  <!-- <b-form-checkbox
                    v-model="filters.is_active"
                    name="check-button"
                    switch
                  >
                    <template v-if="filters.is_active === true">
                      Active
                    </template>
                    <template v-else> Inactive </template>
                  </b-form-checkbox> -->
                </b-col>
                <b-col cols="4">
                  <b-form-group label-size="sm">
                    <template v-slot:label> Search </template>
                    <b-input-group size="sm">
                      <b-form-input
                        id="inputSearch"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        trim
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Search</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:header>
            <b-row>
              <b-col sm="auto" md="9">
                <div class="text-left">
                  <h4>{{ formName }}</h4>
                </div>
              </b-col>
              <b-col sm="auto" md="3">
                <div class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    v-on:click="show = !show"
                    >Create</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
          <b-table
            responsive
            head-variant="dark"
            stickyColumn
            striped
            hover
            show-empty
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterReminder"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="includeFiled"
          >
            <template #cell(is_active)="{ item }">
              <div v-if="item.is_active !== true">Inactive</div>
              <div v-if="item.is_active === true">Active</div>
            </template>

            <template v-slot:cell(actions)="{ item }">
              <b-button
                title="Hapus"
                size="xs"
                variant="danger"
                v-on:click="deleteData(item.id)"
              >
                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
              </b-button>
              <b-button
                title="Ubah"
                size="xs"
                variant="warning"
                v-on:click="
                  editData(
                    item.id,
                    item.permit_type,
                    item.expired,
                    item.category,
                    item.institution,
                    item.levels,
                    item.end_date,
                    item.duration,
                    item.reminders
                  )
                "
              >
                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
              </b-button>
            </template>
          </b-table>
          <div class="mt-3">
            Showing {{ pageItems }} of {{ items.length }} entries
            <b-pagination
              v-model="paging.currentPage"
              :total-rows="rows"
              :per-page="paging.perPage"
              align="right"
            >
            </b-pagination>
          </div>
          <template>
            <!-- <b-modal
              v-model="show"
              @ok="onSubmit()"
              @hidden="cancel()"
              ok-title="Save"
              cancel-title="Cancel"
              ok-variant="success"
              cancel-variant="warning"
              header-bg-variant="secondary"
              footer-bg-variant="secondary"
              hide-backdrop
              size="lg"
              ref="modal"
              no-close-on-backdrop
              title="Form License Type"
            > -->
            <b-modal
              v-model="show"
              ref="my-modal"
              header-bg-variant="secondary"
              footer-bg-variant="secondary"
              hide-backdrop
              size="lg"
              title="Form License Type"
            >
              <div id="modal">
                <!-- <form-permit-type ref="permitForm"  /> -->
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                      <b-col cols="12">
                        <!-- Category -->
                        <validation-provider
                          name="Category"
                          :rules="{ required: false }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Category<code>*</code>
                            </template>
                            <b-form-select
                              :options="categoryOpt"
                              value-field="id"
                              text-field="name"
                              id="inputCategory"
                              v-model="form.category_id"
                              :state="getValidationState(validationContext)"
                              trim
                            >
                            </b-form-select>
                            <b-form-invalid-feedback id="inputCategory">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Category -->

                        <!-- Instituion -->
                        <validation-provider
                          name="Institution"
                          :rules="{ required: false }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Institution<code>*</code>
                            </template>
                            <b-form-select
                              :options="institutionOpt"
                              value-field="id"
                              text-field="name"
                              id="inputInstitution"
                              v-model="form.institution_id"
                              :state="getValidationState(validationContext)"
                              trim
                            >
                            </b-form-select>
                            <b-form-invalid-feedback id="inputInstitution">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Instituion -->

                        <!-- Instituion -->
                        <validation-provider
                          name="Level"
                          :rules="{ required: false }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Level<code>*</code>
                            </template>
                            <b-form-select
                              :options="levelOpt"
                              value-field="id"
                              text-field="level_name"
                              id="inputlevel"
                              v-model="form.level_id"
                              :state="getValidationState(validationContext)"
                              trim
                            >
                            </b-form-select>
                            <b-form-invalid-feedback id="inputlevel">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Instituion -->

                        <!-- PermitType -->
                        <validation-provider
                          name="PermitType"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              License Type<code>*</code>
                            </template>
                            <b-form-input
                              id="inputPermitType"
                              v-model="form.permit_type"
                              :state="getValidationState(validationContext)"
                              trim
                            ></b-form-input>
                            <b-form-invalid-feedback id="inputPermitType">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /PermitType -->

                        <!-- Expired -->
                        <validation-provider
                          name="Expired"
                          vid="Expired"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Have expired date<code>*</code>
                            </template>
                            <b-form-radio-group
                              id="inputExpired"
                              v-model="form.expired"
                              :options="expiredOpt"
                              value-field="value"
                              text-field="label"
                              :state="getValidationState(validationContext)"
                              @change="setDateExpired"
                              trim
                            ></b-form-radio-group>
                            <b-form-invalid-feedback id="inputExpired">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Expired -->

                        <!-- Duration -->
                        <validation-provider
                          name="Duration"
                          v-if="expState == false"
                          :rules="{
                            required: true,
                            min_value: 0,
                          }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Duration (months)<code>*</code>
                            </template>
                            <b-form-input
                              id="inputDuration"
                              type="number"
                              :min="0"
                              :maxlength="2"
                              v-model="form.duration"
                              :state="getValidationState(validationContext)"
                              trim
                            ></b-form-input>
                            <b-form-invalid-feedback id="inputDuration">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Duration -->

                        <!-- Reminder -->
                        <validation-provider
                          name="Reminder"
                          v-if="expState == false"
                          :rules="{
                            required: true,
                            min_value: 0,
                          }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label>
                              Reminder (months)<code>*</code>
                            </template>
                            <b-form-input
                              id="inputReminder"
                              type="number"
                              :min="0"
                              :maxlength="2"
                              v-model="form.reminders"
                              :state="getValidationState(validationContext)"
                              trim
                            ></b-form-input>
                            <b-form-invalid-feedback id="inputReminder">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- /Reminder -->

                        <!-- End Date  -->
                        <!-- <validation-provider
                          name="EndDate"
                          v-if="expState == false"
                          :rules="{ required_if: expState == false }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label-cols="3"
                            label-cols-lg="3"
                            label-size="sm"
                          >
                            <template v-slot:label> End Date </template>
                            <b-form-datepicker
                              id="inputEndDate"
                              v-model="form.end_date"
                              today-button
                              reset-button
                              close-button
                              locale="id"
                              :disabled="expState"
                              :state="getValidationState(validationContext)"
                              :min="form.start_date"
                              @input="resetDate"
                            ></b-form-datepicker>
                            <b-form-invalid-feedback id="inputEndDate">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider> -->
                        <!-- /End Date  -->
                      </b-col>
                    </b-row>
                    <!-- penambahan terbaru -->
                    <b-row class="float-right">
                      <b-button class="mr-3" variant="success" type="submit"
                        >Save</b-button
                      >
                      <b-button class="mr-3" variant="warning" @click="cancel()"
                        >Cancel</b-button
                      >
                    </b-row>
                  </b-form>
                </validation-observer>
              </div>
              <template #modal-footer>
                <div class="h-300 bg-secondary"></div>
              </template>
            </b-modal>
          </template>
        </b-card>
      </b-container>
      <template #overlay>
        <div ref="loadings" class="text-center">
          <b-icon
            icon="arrow-clockwise"
            font-scale="3"
            animation="spin"
          ></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../../api/api-methods";
import { APIPATH_DMS } from "../../../../../api/api-paths";
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
const now = new Date();
export default {
  data() {
    return {
      formName: "License Type",
      modulName: "Dashboard",
      items: [],
      fields: [
        { key: "actions", label: "#" },
        { key: "permit_type", label: "License Type" },
        { key: "name", label: "Category" },
        { key: "expired", label: "Have Expired Date" },
        { key: "is_active", label: "Status" },
        "created_by",
        {
          key: "created_at",
          label: "Created At",
          formatter: this.setDateFormat,
        },
        { key: "modified_by", label: "Update By" },
        {
          key: "modified_at",
          label: "Update At",
          formatter: this.setDateFormat,
        },
      ],
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      isLoading: false,
      show: false,
      form: {
        permit_id: null,
        start_date: null,
        permit_type: null,
        duration: null,
        reminders: null,
        end_date: null,
        expired: null,
        is_active: true,
        category_id: null,
        institution_id: null,
        level_id: null,
        created_by: null,
        modified_by: null,
        deleted_by: null,
      },
      expiredOpt: [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
      ],
      categoryOpt: [],
      institutionOpt: [],
      levelOpt: [],
      locationOpt: [],
      reminderOpt: [],
      max: now,
      expState: true,
      filters: {
        expired: "",
        category: "",
        permit_type: "",
        is_active: true,
      },
      filter: null,
      includeFiled: ["expired", "name", "permit_type", "is_active"],
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    rows() {
      if (this.items) {
        return this.filterReminder.length;
      }
    },
    filterReminder() {
      var category = this.filters.category;
      var expired = this.filters.expired;
      var permit = this.filters.permit_type;
      var status = this.filters.is_active;

      return this.items.filter(function (item) {
        var filtereds = true;
        if (category && category.length > 0) {
          filtereds = item.name === category;
        }
        if (filtereds) {
          if (expired && expired.length > 0) {
            filtereds = item.expired === expired;
          }
        }
        if (filtereds) {
          if (permit && permit.length > 0) {
            filtereds = item.permit_type === permit;
          }
        }
        if (filtereds) {
          if (status === false) {
            filtereds = item.is_active === false;
          } else {
            filtereds = item.is_active === true;
          }
        }
        return filtereds;
      });
    },
    pageItems() {
      var totalPage = Math.ceil(this.rows / this.paging.perPage);
      if (this.paging.currentPage === totalPage) {
        return (
          (this.rows % this.paging.perPage) +
          this.paging.perPage * totalPage -
          this.paging.perPage
        );
      } else {
        return this.paging.perPage * this.paging.currentPage;
      }
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    setDateFormat(value) {
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      var formats = new Date(value).toLocaleDateString("id-ID", options);
      if (value) {
        return formats;
      }
    },
    setDateExpired() {
      if (this.form.expired === "Yes") {
        Vue.set(this, "expState", false);
      } else {
        Vue.set(this, "expState", true);
        Vue.set(this.form, "end_date", null);
      }
    },
    // resetDate() {
    //   console.log("reset called");

    //   // if(!this.editItems || this.form.end_date !== this.editItems.end_date){
    //   Vue.set(this.form, "durations", null);
    //   Vue.set(this.form, "remind_date", null);
    //   Vue.set(this.form, "reminder", null);
    //   // }
    // },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getCategory() {
      fnGET(APIPATH_DMS.getCategory)
        .then((rsp) => {
          Vue.set(this, "categoryOpt", rsp.data.payload);
          console.log(this.categoryOpt);
        })
        .catch((err) => {
          return err;
        });
    },
    getInstitution() {
      fnGET(APIPATH_DMS.getInstitution)
        .then((rsp) => {
          Vue.set(this, "institutionOpt", rsp.data.payload);
          console.log(this.institutionOpt);
        })
        .catch((err) => {
          return err;
        });
    },
    getPertmitType() {
      Vue.set(this, "isLoading", true);
      fnGET(APIPATH_DMS.getPermit)
        .then((rsp) => {
          Vue.set(this, "items", rsp.data.payload);
          console.log(this.items);
          Vue.set(this, "isLoading", false);
        })
        .catch((err) => {
          Vue.set(this, "isLoading", false);
          return err;
        });
    },
    cancel() {
      this.form.permit_type = null;
      this.form.expired = null;
      this.form.category_id = null;
      this.form.institution_id = null;
      this.form.level_id = null;
      this.form.permit_id = null;
      this.form.end_date = null;
      this.form.duration = null;
      this.form.reminders = null;
      // this.form.created_by = null
      this.$refs["my-modal"].hide();
    },
    onSubmit() {
      if (this.form.permit_type == null || this.form.permit_type == "") {
        return false;
      }
      // if (this.form.pic_dept == null || this.form.pic_dept == "") {
      //   return false;
      // }
      Vue.set(this, "isLoading", true);
      // var unique = this.validationUnique()

      // if (unique) {
      if (this.form.permit_id) {
        fnPUT(APIPATH_DMS.putPermit, this.form)
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Successfully Modified",
            });
            Vue.set(this, "isLoading", false);
            this.getPertmitType();
            this.cancel();
          })
          .catch((err) => {
            this.showNotification({
              type: "danger",
              message: "Failed Submitted, Something wrong",
            });
            Vue.set(this, "isLoading", false);
            return err;
          });
      } else {
        fnPOST(APIPATH_DMS.postPermit, this.form)
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });
            Vue.set(this, "isLoading", false);
            this.getPertmitType();
            this.cancel();
          })
          .catch((err) => {
            this.showNotification({
              type: "danger",
              message: "Failed Submitted, Something wrong",
            });
            Vue.set(this, "isLoading", false);
            return err;
          });
      }
      // }else{
      //     Vue.set(this,'isLoading',false)
      //     alert('Duplicate!!!, Data already created')

      // }
    },
    getLocation() {
      fnGET(APIPATH_DMS.getLocation)
        .then((rsp) => {
          var locations = rsp.data.payload.filter((i) => i.is_active === true);
          Vue.set(this, "locationOpt", locations);
        })
        .catch((err) => {
          return err;
        });
    },
    getReminder() {
      fnGET(APIPATH_DMS.getReminder)
        .then((rsp) => {
          if (rsp.data.payload.length > 0) {
            Vue.set(this, "reminderOpt", rsp.data.payload);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    getLevel() {
      fnGET(APIPATH_DMS.getLevel)
        .then((rsp) => {
          Vue.set(this, "levelOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    editData(
      id,
      permitType,
      expired,
      category,
      instituion,
      level,
      end_date,
      duration,
      reminders
    ) {
      Vue.set(this, "show", true);
      Vue.set(this.form, "permit_id", id);
      Vue.set(this.form, "permit_type", permitType);
      Vue.set(this.form, "expired", expired);
      Vue.set(this.form, "category_id", category);
      Vue.set(this.form, "institution_id", instituion);
      Vue.set(this.form, "level_id", level);
      Vue.set(this.form, "end_date", end_date);
      Vue.set(this.form, "duration", duration);
      Vue.set(this.form, "reminders", reminders);
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            Vue.set(this, "isLoading", true);
            fnPUT(APIPATH_DMS.deletePermit, { permit_id: id })
              .then((rsp) => {
                this.showNotification({
                  type: "success",
                  message: "Successfully Deleted",
                });
                Vue.set(this, "isLoading", false);
                this.getPertmitType();
              })
              .catch((err) => {
                this.showNotification({
                  type: "danger",
                  message: "Failed Submitted, Something wrong",
                });
                Vue.set(this, "isLoading", false);
                return err;
              });
          }
        });
    },
    setUserData() {
      Vue.set(this.form, "created_by", this.userData.fullname);
      Vue.set(this.form, "modified_by", this.userData.fullname);
      Vue.set(this.form, "deleted_by", this.userData.fullname);
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
      this.$refs.loadings.focus();
    },
    validationUnique() {
      var permitType = this.form.permit_type.toLowerCase().trim();

      var permititems = this.items.filter((i) => i.id !== this.form.permit_id);

      return permititems.find((i) => i.permit_type === permitType);
      // return permititems.filter((item)=>{

      //     return item.permit_type.toLowerCase().match(permitType)
      // })

      // return this.items.find((item)=>{

      //     return item.permit_type.toLowerCase().match(permitType)
      // })
    },
  },
  created() {
    this.getPertmitType();
    this.setUserData();
    this.getCategory();
    this.getLocation();
    this.getReminder();
    this.getLevel();
    this.getInstitution();
  },
};
</script>