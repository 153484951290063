<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/appsplm-material-development"> Material Development</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
            
        </b-breadcrumb>
        <b-container fluid>
            <b-card>
                 <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Material Development</h4>
                                <b-button title="Back" size="sm" variant="warning" v-on:click="backTo()">
                                    <b-icon font-scale="1" icon="arrow-left" variant="dark"></b-icon>
                                    Back 
                                </b-button>
                            </div>
                            
                        </b-col>
                        <b-col sm="auto" md="3">
                            <template v-if="roles">
                            <div class="text-right">
                                <b-button variant="success" size="sm" v-on:click="createData()">Add Supplier</b-button>
                            </div>
                            </template>
                        </b-col>
                        
                    </b-row>
                </template>
                <b-table
                    striped 
                    hover
                    show-empty
                    responsive
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :fields="fields"
                    :items="getMaterialDevDetail"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <!-- <b-button title="Detail" size="xs" variant="dark" v-on:click="viewData(item.code,item.category)">
                            
                            <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                        </b-button> -->
                        <b-button title="Ubah" size="xs" variant="info" v-on:click="editData(item.id,item.supplier_name)">
                            <b-icon icon="plus" aria-label="Help"></b-icon>
                            Add Activity
                        </b-button>
                    </template>
                    <template v-slot:cell(date)="{item}">
                        <template v-if="item.date != null">
                            {{new Date(item.date).toLocaleString("id-ID")}}
                        </template>
                    </template>
                </b-table>
                <div class="mt-3">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="center"
                    >

                    </b-pagination>
                </div>
            </b-card>
        </b-container>
        <b-modal
            hide-footer
            v-model="show"
            hide-backdrop
            v-on:ok="onSubmit()"
            ok-title="Save"
            size="lg"
            ref="modal"
            no-close-on-backdrop
            scrollable 
            title="Form Add Supplier"
        >
            <validation-observer ref="observer" v-slot="{handleSubmit}">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col cols="auto" md="6">
                            <!-- Category -->
                                <validation-provider
                                    name="category-material"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Category Material <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-category-material" v-model="form.category"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-category-material">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Used  -->
                                <validation-provider
                                    name="used"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Used As <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-used" v-model="form.used"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-used">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Code -->
                                <validation-provider
                                    name="code"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Code <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-code" v-model="form.code" :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-code">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Type -->
                                <validation-provider
                                    name="type"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Type <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-type" v-model="form.type" :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-type">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            <!-- Description -->
                                <validation-provider
                                    name="description"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Description <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-description" v-model="form.description" :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-description">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            <!-- Reference -->
                                <validation-provider
                                    name="reference"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Reference <code>*</code>
                                        </template>
                                        <b-form-input id="input-reference" v-model="form.reference"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-reference">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Supplier  -->
                                <validation-provider
                                    name="supplier"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Supplier <code>*</code>
                                        </template>
                                        <!-- <b-form-select value-field="supplier_name"
                                                    text-field="supplier_name" id="inputSupplier" v-model="form.supplier" :options="supplierOpt"  :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                        <v-select
                                            label="supplier_name"
                                            v-model="form.supplier"
                                            :reduce="supplier_name => supplier_name.supplier_name" 
                                             
                                            :options="supplierOpt" 
                                            :filter-by="myFilter"
                                            placeholder="Type here"
                                        >
                                            <template #option="{ supplier_code, supplier_name }">
                                                <p style="margin: 0">{{ supplier_code }}</p>
                                                <em >{{ supplier_name }}</em>
                                            </template>
                                        </v-select>
                                        <b-form-invalid-feedback id="input-supplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-button  variant="warning" v-on:click="cancel()" block size="sm">Cancel</b-button>
                        <b-button variant="success" block size="sm" type="submit">Save</b-button>
                        </b-col>
                        <b-col cols="auto" md="6">
                            <!-- Images -->
                                    <b-form-group
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Images <code>*</code>
                                        </template>
                                        <!-- <b-form-file disabled id="input-images" v-model="form.images" :state="getValidationState(validationContext)"  trim></b-form-file> -->
                                        <!-- <b-form-invalid-feedback id="input-images">{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                        <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesUrl" :alt="form.imagesName"></b-img>

                                    </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import apiSupplier from '@/store/product-form/product-supplier.js'
import 'vue-select/dist/vue-select.css';
export default {
    props:['material_id'],
    data(){
        return{
            formName:'Detail',
            mainProps: {width: 300, height: 300, class: 'm1' },
            show:false,
            form:{
                category:null,
                used:null,
                type:null,
                description:null,
                code:null,
                supplier_id:null,
                supplier_code:null,
                supplier:null,
                reference:null,
                imagesUrl:null,
                imagesName:null,
                status:"On Development"
            },
            productId:null,
            paging:{
                perPage:10,
                currentPage:1,
            },
            fields:[
                {key:'actions',label:'#'},
                'code',
                {key:'supplier_name',label:'SUPPLIER'},
                {key:'activity',label:'last activity'},
                {key:'date',label:'last update'},
                'status'
            ],
            categoryOpt:[
                {value:'fabric', text:'fabric'},
                {value:'accessories', text:'accessories'},
                {value:'thread', text:'thread'},
                {value:'artwork', text:'artwork'},
                {value:'interlining', text:'interlining'},
            ],
            supplierOpt:[
                {value:'PT. XYZ', text:'PT. XYZ',id:'2',code:'XYZ'},
                {value:'PT. ABC', text:'PT. ABC',id:'1',code:'ABC'},
                {value:'PT. JKL', text:'PT. JKL',id:'4',code:'JLK'},
                {value:'PT. OPQ', text:'PT. OPQ',id:'3',code:'OPQ'},
            ],
            refOpt:[
                {value:'REF 1', text:'REF 1'},
                {value:'REF 2', text:'REF 2'},
                {value:'REF 3', text:'REF 3'},
                {value:'REF 4', text:'REF 4'},
            ],
            isLoading : false,
            myFilter: (option, label, search) => {
                let temp = search.toLowerCase();
                return option.supplier_code.toLowerCase().indexOf(temp) > -1 || 
                option.supplier_name.toLowerCase().indexOf(temp) > -1
            },
            role:['Super User','MD'],
            rols:['Super User']
        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('materialDev',['GET_MATERIALDEV_DASHBOARD','GET_MATERIALDEV_DETAIL','POST_MATERIALDEV_DETAIL']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        createData(){
            // console.log('id', this.material_id);
            // this.$router.push({
            //     name:'AppsMaterialDevFormPage',
            //     params:{
            //         material_id:this.material_id,
            //     }
            // })
            this.show = true
        },
        editData(mdev_id,supplier_name){
            console.log('id', this.material_id);
             let routeData = this.$router.resolve({name: 'AppsMaterialDevFormPage', query: {material_id:this.material_id, mdev_id:mdev_id,supplier_name:supplier_name}});
            window.open(routeData.href, '_blank');
            // this.$router.push({
            //     name:'AppsMaterialDevFormPage',
            //     params:{
            //         material_id:this.material_id,
            //         mdev_id:mdev_id,
            //         supplier_name:supplier_name
            //     }
            // })
        },
        getData(){
            this.isLoading = true
            this.GET_MATERIALDEV_DASHBOARD().then(response => {
                console.log('rsp get',response.data.payload);
                let dataMaterial = response.data.payload.filter(x=>x.product_master_id == this.material_id)
                
                this.form.category = dataMaterial[0].category
                this.form.type= dataMaterial[0].type
                this.form.description= dataMaterial[0].description
                this.form.code= dataMaterial[0].code
                this.form.reference= dataMaterial[0].reference
                this.form.imagesUrl= dataMaterial[0].img_src
                this.form.imagesName= dataMaterial[0].img_name
                this.form.used= dataMaterial[0].used_as
                this.productId = dataMaterial[0].product_id

                this.isLoading = false
            }).catch(err=>{
                this.isLoading = false
            })
        },
        onSubmit(){
            this.isLoading = true
            // console.log('suplier',this.form.supplier);
            let supplierData = this.supplierOpt.filter(x => x.supplier_name === this.form.supplier)
            console.log('suplierdata',supplierData);
            this.POST_MATERIALDEV_DETAIL({
                product_material:this.material_id,
                supplier_id:supplierData[0].id,
                supplier_name:this.form.supplier,
                supplier_code:supplierData[0].supplier_code,
                status:this.form.status

            }).then(response => {
                this.GET_MATERIALDEV_DETAIL()
                this.showNotification({type:'success', message:'Successfully'})
                this.isLoading = false
            }).catch(error => {
                this.isLoading = false
                return error
            })

            this.clearForm()
        },
        clearForm(){
            this.show = false
            this.form.supplier = null
            // this.form.status = null
        },
        backTo(){
            console.log('prod_id',this.productId);
            this.$router.push({
                name:'AppsMaterialDevPage',
                params:{productId:this.productId}
            })
        },
        getMasterSupplier(){
            apiSupplier.dispatch('GET_MASTER_SUPPLIER')
            .then(rsp=>{
                console.log(rsp);
                this.$set(this,'supplierOpt',rsp)
            })
            .catch(err=>{
                return err
            })
        },
    },
    created(){
        this.getMasterSupplier()
        this.getData()
    },
    mounted(){
        this.GET_MATERIALDEV_DETAIL()
    },
    computed:{
        ...mapGetters('materialDev',['MATERIALDEV_DETAIL']),
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        getMaterialDevDetail(){
            // this.isLoading = true
            // console.log('material dev detail',this.MATERIALDEV_DETAIL);
            const materialDevDetail = this.MATERIALDEV_DETAIL.filter(x => x.product_material_id == this.material_id)
            console.log('matdevdet',materialDevDetail);
            // this.isLoading = false
            return materialDevDetail
        },
        rows(){
            if(this.getMaterialDevDetail > 0){
                return this.getMaterialDevDetail.length
            }
        }
    }
}
</script>