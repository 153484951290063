<template>
    <div>
        
        <b-card sub-title="Filter">
            <b-row>
                <b-col sm="auto" md="4" lg="4">
                        <b-form-group
                            label="Brand"
                            label-for="brand-select"
                            label-cols          ="3" 
                            label-size          ="sm"
                            v-slot="{ ariaDescribedby }"
                            >
                            <b-form-select
                                id="brand-select"
                                v-model="filters.brand"
                                :options="brandOpt"
                                :aria-describedby="ariaDescribedby"
                                size="sm"
                            >
                                <template #first>
                                    <option value="">All</option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group
                            label="Coll. Month"
                            label-for="coll-month-select"
                            label-cols          ="3" 
                            label-size          ="sm"
                            >
                            <b-form-select
                                id="coll-month-select"
                                v-model="filters.month"
                                :options="monthOpt"
                                size="sm"
                                value-field="value"
                                text-field="value"
                            >
                                <template #first>
                                    <option value="">All</option>
                                </template>
                            </b-form-select>
                        </b-form-group>

                </b-col>
                <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                            label="Year"
                            label-for="year-select"
                            label-cols          ="3" 
                            label-size          ="sm"
                            >
                            <b-form-select
                                id="year-select"
                                v-model="filters.year"
                                :options="yearOpt"
                                size="sm"
                                value-field="value"
                                text-field="value"
                            >
                                <template #first>
                                    <option value="">All</option>
                                </template>
                            </b-form-select>
                    </b-form-group>
                    <b-form-group
                            label="Status"
                            label-for="status-select"
                            label-cols          ="3" 
                            label-size          ="sm"
                            >
                            <b-form-select
                                id="status-select"
                                v-model="filters.status"
                                :options="statusOpt"
                                size="sm"
                            >
                                <template #first>
                                    <option value="">All</option>
                                </template>
                            </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                            
                            label-size          ="sm"
                        >
                            <template v-slot:label>
                                Search
                            </template>
                            <b-input-group size="sm">
                                <b-form-input 
                                    id="inputSearch" 
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search" 
                                    trim
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>

        <template v-if="showPdf">

            <b-table
                id="productDev"
                striped 
                hover
                show-empty
                small
                responsive
                
                :per-page="paging.perPage"
                :current-page="paging.currentPage"
                :items="filterProduct"
                :fields="fields"
                :tbody-tr-class="rowClass"
            >  

            </b-table>
        </template>
        <div class="d-flex justify-content-center mb-3">
            <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
        </div>
        <b-table
            striped 
            hover
            show-empty
            small
            responsive
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterProduct"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="includeFiled"
            
        >   
            <template #empty="scope">
                <p class="text-center">{{ scope.emptyText }}</p>
            </template>
            <template v-slot:cell(actions)="{item}">
                <!-- <b-button title="Detail" size="xs" variant="dark" v-on:click="viewData(item.id)">
                    <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                </b-button> -->
                <div>
                    <b-button-group size="sm">
                        <b-dropdown  right text="Action" variant="outline-success">
                            <template v-if="roles">
                            <b-dropdown-item title="Ubah" size="xs" variant="warning" v-on:click="editData(item.id)" :disabled="item.status == 'Droped' ? true : false">
                                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                Edit
                            </b-dropdown-item>
                            <b-dropdown-item title="Edit Images" size="xs" variant="dark" v-on:click="editImages(item.id)" :disabled="item.status == 'Droped' ? true : false">
                                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                                Edit Image
                            </b-dropdown-item>
                            </template>
                            <b-dropdown-item title="Download PDF" size="xs" variant="dark" v-on:click="techPack(item.id,item.size_spec)">
                                <b-icon icon="cloud-download" aria-label="Help"></b-icon>
                                Download Pdf
                            </b-dropdown-item>
                            
                        </b-dropdown>
                    </b-button-group>
                </div>
                <!-- <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.id)">
                    <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    Edit
                </b-button>
                <b-button title="Download PDF" size="xs" variant="dark" v-on:click="techPack(item.id,item.size_spec)">
                    <b-icon icon="cloud-download" aria-label="Help"></b-icon>
                    
                </b-button>
                <b-button title="Edit Images" size="xs" variant="dark" v-on:click="editImages(item.id)">
                    <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    Image
                </b-button> -->
                
            </template>
            <!-- <template v-slot:cell(image)>
                <b-button title="Detail" size="xs" variant="dark" v-on:click="showImage = true">
                    <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                </b-button>
            </template> -->
            <template v-slot:cell(article)="{item}">
                <!-- <b-button title="Detail" size="xs"  v-on:click="goToArticle(data.value)">
                    {{data.value}}
                </b-button> -->
                <b-link v-on:click="goToArticle(item.id)">{{item.article}}</b-link>
            </template>
        </b-table>
        <div class="mt-3">
            <b-pagination 
                v-model="paging.currentPage" 
                :total-rows="rows" 
                :per-page="paging.perPage"
                align="center"></b-pagination>
        </div>

        <b-modal
            hide-backdrop
            hide-footer
            v-model="show"
            size="xl"
            ref="modal"
            scrollable 
            title="Product Design Detail">
            <!-- <productview></productview> -->
            <template>
               <product-design-view-detail></product-design-view-detail>
            </template>
        </b-modal>
        <b-modal
            hide-backdrop
            hide-footer
            v-model="showImage"
            size="xl"
            ref="modalImage"
            scrollable 
            title="Images Product Design"
        >
            <b-card title="Product Design">
                <b-card-group deck >
                    <template v-for="designImg in dataProductDesign" >
                        <b-card :key="designImg.design_src"
                            style="max-width: 180px;"
                            img-height="120px"  
                            :img-src="designImg.design_src" 
                            img-alt="Image" 
                            img-top>
                        <b-card-text>
                            <small class="text-muted">{{designImg.design_name}}</small>
                        </b-card-text>
                        </b-card>
                    </template>
                </b-card-group>
            </b-card>
            <b-card title="Product Technical Drawing">
                <b-card-group deck >
                    <template v-for="labelImg in dataProductDesign" >
                        <b-card :key="labelImg.technical_src"
                            style="max-width: 180px;"
                            img-height="120px"  
                            :img-src="labelImg.technical_src" 
                            img-alt="Image" 
                            img-top>
                        <b-card-text>
                            <small class="text-muted">{{labelImg.technical_name}}</small>
                        </b-card-text>
                        </b-card>
                    </template>
                </b-card-group>
            </b-card>
            <b-card title="Fabric">
                <b-card-group deck >
                    <template v-for="fabricImg in fabricList" >
                        <template v-for="img in fabricImg.images" >
                        <b-card :key="img.src"
                            style="max-width: 180px;"
                            img-height="120px"  
                            :img-src="img.src" 
                            img-alt="Image" 
                            img-top>
                        <b-card-text>
                            <small class="text-muted">{{img.fileName}}</small>
                        </b-card-text>
                        </b-card>
                        </template>
                    </template>
                </b-card-group>
            </b-card>
            <b-card title="Accessories">
                <b-card-group deck >
                    <template v-for="accImg in accList" >
                        <template v-for="img in accImg.images" >
                        <b-card :key="img.src"
                            style="max-width: 180px;"
                            img-height="120px"  
                            :img-src="img.src" 
                            img-alt="Image" 
                            img-top>
                        <b-card-text>
                            <small class="text-muted">{{img.fileName}}</small>
                        </b-card-text>
                        </b-card>
                        </template>
                    </template>
                </b-card-group>
            </b-card>
            <b-card title="Thread">
                <b-card-group deck >
                    <template v-for="threadImg in threadList" >
                        <template v-for="img in threadImg.images" >
                        <b-card :key="img.src"
                            style="max-width: 180px;"
                            img-height="120px"  
                            :img-src="img.src" 
                            img-alt="Image" 
                            img-top>
                        <b-card-text>
                            <small class="text-muted">{{img.fileName}}</small>
                        </b-card-text>
                        </b-card>
                        </template>
                    </template>
                </b-card-group>
            </b-card>
            <b-card title="Artwork">
                <b-card-group deck >
                    <template v-for="artworkImg in artworkList" >
                        <template v-for="img in artworkImg.images" >
                        <b-card :key="img.src"
                            style="max-width: 180px;"
                            img-height="120px"  
                            :img-src="img.src" 
                            img-alt="Image" 
                            img-top>
                        <b-card-text>
                            <small class="text-muted">{{img.fileName}}</small>
                        </b-card-text>
                        </b-card>
                        </template>
                    </template>
                </b-card-group>
            </b-card>
            <b-card title="Interlining">
                <b-card-group deck >
                    <template v-for="interliningImg in interliningList" >
                        <template v-for="img in interliningImg.images" >
                        <b-card :key="img.src"
                            style="max-width: 180px;"
                            img-height="120px"  
                            :img-src="img.src" 
                            img-alt="Image" 
                            img-top>
                        <b-card-text>
                            <small class="text-muted">{{img.fileName}}</small>
                        </b-card-text>
                        </b-card>
                        </template>
                    </template>
                </b-card-group>
            </b-card>

        </b-modal>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import productview from './ProductDesignSubmitView2';
import apiProduct from '@/store/product-form/master-param.js'
import ProductDesignViewDetail from './ProductDesignViewDetail.vue';
export default {
    name: 'ProductDesignList2',
    components:{
        productview,
        ProductDesignViewDetail
    },
    data(){
        return {
            username:null,
            roleUser:null,
            brandallperuser:[],
            converDataBrand:[],
            filterArr:[],
            arrHead:[],
            arrBody:[],
            filterTest:'Arnold Palmer',
            showPdf:false,
            showImage:false,
            brandFilter:null,
            collMonthFilter:null,
            yearFilter:null,
            statusFilter:null,
            includeFiled:['brand','coll_month','article','code','year','process','duration','status'],
            filters:{
                brand:'',
                month:'',
                year:'',
                status:'',
            },
            brandOpt:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'ANTHM',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
            ],
            monthOpt:[
            ],
            yearOpt:[

            ],
            statusOpt:[
                {value:'Draft',text:'Draft'},
                {value:'Waiting For Approval',text:'Waiting For Approval'},
                {value:'On Development',text:'On Development'},
                {value:'Finish',text:'Finish'},
                {value:'Droped',text:'Droped'},
            ],
            show:false,
            fields:[
                {key:'actions', label:'#'},
                'code',
                {key:'brand',label:'Brand',sortable: true,},
                'article',
                {key:'coll_month',label:'Coll. Month',sortable: true,},
                {key:'year',label:'Year',sortable: true,},
                {key:'is_process',label:'Process'},
                'duration',
                {key:'status',label:'Status',sortable: true,},
            ],
            dataProductDesign: [],
            search:null,
            filter:null,
            paging: {
                perPage: 10,
                currentPage: 1,
            },
            prodMaterial:[],
            sizeSpec:[],
            isLoading:false,
            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User']
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_PROCESS','getProduct','getProductDetail','GET_PRODUCT_DASHBOARD','GET_PRODUCT_MATERIAL_DEV','SET_PRODUCT_DETAIL','SET_PRODUCT_MATERIAL','GET_MATERIAL_HISTORY']),
        ...mapActions('sizeSpec',['GET_SIZESPEC_DASHBOARD']),
        getProcess(item){
            // console.log('val',item);

            // this.GET_PRODUCT_PROCESS({product_id:id})
            // .then(rsp =>{
            //     let arrProcess = rsp.data.payload
            //     console.log(arrProcess[arrProcess.length - 1].process);
            //     // return arrProcess[arrProcess.length - 1].process
            // }).catch(err =>{
            //     return err
            // })
        },
        // getData(id){
        //     this.GET_PRODUCT_PROCESS({product_id:id})
        //     .then(rsp =>{
        //         console.log(rsp.data.payload);
        //         this.$set(this,'dataArticle',rsp.data.payload)
        //     }).catch(err =>{
        //         return err
        //     })
        // },
        createPDF(id,sizeId){
            this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
                // console.log(rsp.data.payload);
                const sizeDetail = rsp.data.payload.filter(x => x.id == sizeId)
                let arrSizeDetail = JSON.parse(sizeDetail[0].size_detail)
                let arrSizeHead = Object.keys(arrSizeDetail)
                Object.assign(this.arrHead,arrSizeHead)
                // for (let x = 0; x < arrSizeHead.length; x++) {
                    
                //     arrHead.push(arrSizeHead[x])
                // }
                for (let i = 0; i < arrSizeDetail.length; i++) {
                    this.arrBody.push(arrSizeDetail[i])
                }
                // console.log('rsp size',Object.keys(arrSizeDetail));
                // console.log('arr head', arrHead);
                

                
            })
            let prodDev = this.dataProductDesign.filter(i=>i.id == id)
            // console.log(prodDev);
            this.GET_PRODUCT_MATERIAL_DEV().then(response => {
               let matProdDev = response.data.payload.filter(pm => pm.product_id === id)
               for (let i = 0; i < matProdDev.length; i++) {
                   let objMat = {
                       code:matProdDev[i].code,
                       type:matProdDev[i].type,
                       description:matProdDev[i].description,
                       img:matProdDev[i].img_src,
                       detail:matProdDev[i].detail,
                       used:matProdDev[i].used_as
                   }
                    // console.log(objMat);
                   this.prodMaterial.push(objMat)
                   
                   
               }

                // this.prodMaterial = matProdDev
            })

            

            // console.log(materialList);
            let pdfName = 'Technical Package'
            var doc = new jsPDF('l','pt','A4')

            const columns =[
                    {title:"Code",dataKey:"code"},
                    {title:"Type",dataKey:"type"},
                    {title:"Description",dataKey:"description"},
                    {title:"Images",dataKey:"img"},
                    {title:"Detail",dataKey:"detail"},
                    {title:"Used As",dataKey:"used"},
                ]
            
            
            
            let centerize = doc.internal.pageSize.getWidth()/2
            let rights = doc.internal.pageSize.getWidth()
            let tinggi = doc.internal.pageSize.getHeight()
            let lebar  = doc.internal.pageSize.getWidth()
            //line
            doc.setLineWidth(1.5)
            doc.rect(10,10,rights-20,tinggi-20)

            doc.setFontSize(14)
            doc.text(prodDev[0].article,centerize,30,{align:'center'})
            doc.text(prodDev[0].brand,rights-Object.keys(prodDev[0].brand).length-50,30,{align:'right'})
            doc.setFontSize(8)
            doc.text('Garment/Apparel',centerize,40,"center")

            doc.setLineWidth(0.5)
            doc.line(10,45,rights-10,45)

            //label left
            doc.setFontSize(10)
            doc.text('Brand',15,55)
            doc.text(':',90,55)
            doc.text(prodDev[0].brand,100,55)

            doc.text('Quartal',15,75)
            doc.text(':',90,75)
            doc.text(prodDev[0].quartal,100,75)

            doc.text('Prod. Month',15,95)
            doc.text(':',90,95)
            doc.text(prodDev[0].prod_month,100,95)

            doc.text('Coll. Month',15,115)
            doc.text(':',90,115)
            doc.text(prodDev[0].coll_month,100,115)

            //label center
            doc.text('Theme',centerize-200,55)
            doc.text(':',centerize-150,55)
            doc.text(prodDev[0].collection_name,centerize-140,55)

            doc.text('Subbrand',centerize-200,55+20)
            doc.text(':',centerize-150,55+20)
            doc.text(prodDev[0].subbrand,centerize-140,55+20)

            doc.text('Category',centerize-200,55+20+20)
            doc.text(':',centerize-150,55+20+20)
            doc.text(prodDev[0].category,centerize-140,55+20+20)

            

            doc.text('Article',centerize-200,55+20+20+20)
            doc.text(':',centerize-150,55+20+20+20)
            doc.text(prodDev[0].article,centerize-140,55+20+20+20)

            
            //label right
            doc.text('Designer',centerize+30,55)
            doc.text(':',centerize+130,55)
            doc.text('',centerize+140,55)

            doc.text('Date Created',centerize+30,75)
            doc.text(':',centerize+130,75)
            doc.text('',centerize+140,75)

            doc.text('Date Submitted',centerize+30,95)
            doc.text(':',centerize+130,95)
            doc.text('',centerize+140,95)

            doc.text('Date Modified',centerize+30,115)
            doc.text(':',centerize+130,115)
            doc.text('',centerize+140,115)

            doc.setLineWidth(0.5)
            doc.line(10,125,rights-10,125)

            doc.text('No. Sample',15,140)
            doc.text(prodDev[0].ref_sample,120,140)
            doc.line(10,145,rights-10,145)

            

            doc.line(100,165,100,125)

            

            doc.text('FOB/CMT/CM',centerize-195,140)
            doc.text('FOB',centerize-105,140)

            doc.text('Supplier',centerize-195,160)
            doc.text('PT. XYZ',centerize-105,160)
            doc.line(centerize-200,165,centerize-200,125)
            doc.line(centerize-110,165,centerize-110,125)

            doc.text('Size Spec',15,160)
            doc.text(prodDev[0].size_spec,120,160)
            doc.line(10,165,rights-10,165)

            doc.line(10,185,rights-10,185)

            doc.line(centerize+50,165,centerize+50,125)
            doc.text('Approval Date',centerize+55,140)
            doc.text('2021 Aug 13',centerize+155,140)

            doc.text('Note',centerize+55,160)
            doc.text('oke',centerize+155,160)

            doc.line(centerize+150,165,centerize+150,125)
            
            doc.text('Technical Drawing',centerize-300,180)
            doc.addImage('https://picsum.photos/id/250/200/300',"JPEG",15,200)
            doc.line(lebar/3,165,lebar/3,tinggi-10)

            doc.text('Design',centerize,180,"center")
            doc.addImage('https://picsum.photos/id/1002/200/300',"JPEG",lebar/3+15,200)
            doc.line((lebar/3)*2,165,(lebar/3)*2,tinggi-10)


            doc.text('Technical Notes',centerize+300,180,"center")

            //page 2
            doc.addPage("a4","l")
            doc.setLineWidth(1.5)
            doc.rect(10,10,rights-20,tinggi-20)

            doc.setFontSize(14)
            doc.text(prodDev[0].article,centerize,30,{align:'center'})
            doc.text(prodDev[0].brand,rights-Object.keys(prodDev[0].brand).length-50,30,{align:'right'})
            doc.setFontSize(8)
            doc.text('Fabric,Accessories,Artwork,Interlining,Thread',centerize,40,"center")

            doc.setLineWidth(0.5)
            doc.line(10,45,rights-10,45)

            //label left
            doc.setFontSize(10)
            doc.text('Brand',15,55)
            doc.text(':',90,55)
            doc.text(prodDev[0].brand,100,55)

            doc.text('Quartal',15,75)
            doc.text(':',90,75)
            doc.text(prodDev[0].quartal,100,75)

            doc.text('Prod. Month',15,95)
            doc.text(':',90,95)
            doc.text(prodDev[0].prod_month,100,95)

            doc.text('Coll. Month',15,115)
            doc.text(':',90,115)
            doc.text(prodDev[0].coll_month,100,115)

            //label center
            doc.text('Theme',centerize-200,55)
            doc.text(':',centerize-150,55)
            doc.text(prodDev[0].collection_name,centerize-140,55)

            doc.text('Subbrand',centerize-200,55+20)
            doc.text(':',centerize-150,55+20)
            doc.text(prodDev[0].subbrand,centerize-140,55+20)

            doc.text('Category',centerize-200,55+20+20)
            doc.text(':',centerize-150,55+20+20)
            doc.text(prodDev[0].category,centerize-140,55+20+20)

            

            doc.text('Article',centerize-200,55+20+20+20)
            doc.text(':',centerize-150,55+20+20+20)
            doc.text(prodDev[0].article,centerize-140,55+20+20+20)

            
            //label right
            doc.text('Designer',centerize+30,55)
            doc.text(':',centerize+130,55)
            doc.text('',centerize+140,55)

            doc.text('Date Created',centerize+30,75)
            doc.text(':',centerize+130,75)
            doc.text('',centerize+140,75)

            doc.text('Date Submitted',centerize+30,95)
            doc.text(':',centerize+130,95)
            doc.text('',centerize+140,95)

            doc.text('Date Modified',centerize+30,115)
            doc.text(':',centerize+130,115)
            doc.text('',centerize+140,115)

            doc.setLineWidth(0.5)
            doc.line(10,125,rights-10,125)

            doc.autoTable({
                columns,
                body:this.prodMaterial,
                startY:125,
                margin:10,
                theme:'plain'   
                
            })


            //page 3
            doc.addPage("a4","l")
            doc.setLineWidth(1.5)
            doc.rect(10,10,rights-20,tinggi-20)

            doc.setFontSize(14)
            doc.text(prodDev[0].article,centerize,30,{align:'center'})
            doc.text(prodDev[0].brand,rights-Object.keys(prodDev[0].brand).length-50,30,{align:'right'})
            doc.setFontSize(8)
            doc.text('Garment/Apparel Sample Review',centerize,40,"center")

            doc.setLineWidth(0.5)
            doc.line(10,45,rights-10,45)

            //label left
            doc.setFontSize(10)
            doc.text('Brand',15,55)
            doc.text(':',90,55)
            doc.text(prodDev[0].brand,100,55)

            doc.text('Quartal',15,75)
            doc.text(':',90,75)
            doc.text(prodDev[0].quartal,100,75)

            doc.text('Prod. Month',15,95)
            doc.text(':',90,95)
            doc.text(prodDev[0].prod_month,100,95)

            doc.text('Coll. Month',15,115)
            doc.text(':',90,115)
            doc.text(prodDev[0].coll_month,100,115)

            //label center
            doc.text('Theme',centerize-200,55)
            doc.text(':',centerize-150,55)
            doc.text(prodDev[0].collection_name,centerize-140,55)

            doc.text('Subbrand',centerize-200,55+20)
            doc.text(':',centerize-150,55+20)
            doc.text(prodDev[0].subbrand,centerize-140,55+20)

            doc.text('Category',centerize-200,55+20+20)
            doc.text(':',centerize-150,55+20+20)
            doc.text(prodDev[0].category,centerize-140,55+20+20)

            

            doc.text('Article',centerize-200,55+20+20+20)
            doc.text(':',centerize-150,55+20+20+20)
            doc.text(prodDev[0].article,centerize-140,55+20+20+20)

            
            //label right
            doc.text('Designer',centerize+30,55)
            doc.text(':',centerize+130,55)
            doc.text('',centerize+140,55)

            doc.text('Date Created',centerize+30,75)
            doc.text(':',centerize+130,75)
            doc.text('',centerize+140,75)

            doc.text('Date Submitted',centerize+30,95)
            doc.text(':',centerize+130,95)
            doc.text('',centerize+140,95)

            doc.text('Date Modified',centerize+30,115)
            doc.text(':',centerize+130,115)
            doc.text('',centerize+140,115)

            doc.setLineWidth(0.5)
            doc.line(10,125,rights-10,125)

            doc.line(10,145,rights-10,145)
            doc.text('Size Specification',centerize,140)

            // let arrHead = []
            // let head = ["1","2","3","4"]
            // let arrBody = []

            
            

            let heads = JSON.parse(JSON.stringify(this.arrHead))
            // console.log('arrhead',this.arrHead);
            
            // doc.autoTable({
            //     style:{cellWidth:'auto'},
            //     head:[
            //         this.arrHead
            //     ],
            //     startY: 160,
            // })
            for (let i = 0; i < this.arrHead.length; i++) {
                doc.text(this.arrHead[i],centerize,140)
                
            }
            
            doc.output('dataurlnewwindow')
            

        },

        createPDF2(id){
            // let pdfName = 'Product Design'
            

            var doc = new jsPDF('p','pt','A4')

            doc.setProperties({
                title:"Product Design"
            })
            
            const columns =[
                    {title:"Code",dataKey:"code"},
                    {title:"Reference",dataKey:"reference"},
                    {title:"Type",dataKey:"type"},
                    {title:"Description",dataKey:"description"},
                    {title:"Supplier",dataKey:"supplier"},
                    {title:"Detail",dataKey:"detail"},
                    {title:"Used As",dataKey:"used_as"},
                ]
            const fabricList = []
            for(var i=0; i<this.fabricList.length;i++){                
                fabricList.push(this.fabricList[i])                
            }
            const accList = []
            for(var j=0; j<this.accList.length;j++){                
                accList.push(this.accList[j])
            }
            const threadList = []
            for(var k=0; k<this.threadList.length;k++){
                threadList.push(this.threadList[k])
            }
            const artworkList = []
            for(var l=0; l<this.artworkList.length;l++){
                artworkList.push(this.artworkList[l])
            }
            const interliningList = []
            for(var m=0; m<this.interliningList.length;m++){
                interliningList.push(this.interliningList[m])
            }
            
            

            doc.setFontSize(16)
            doc.text('Product Design',570/2,30,"center")
            doc.setFontSize(12)
            doc.text('Brand',10,50)
            doc.text(': '+this.product.brand,200,50)
            doc.text('Collection Name',10,50+20)
            doc.text(': '+this.product.collectionName,200,50+20)
            doc.text('Year',10,50+40)
            doc.text(': '+this.product.year,200,50+40)
            doc.text('Quartal',10,50+60)
            doc.text(': '+this.product.quartal,200,50+60)
            doc.text('Prod. Month',10,50+80)
            doc.text(': '+this.product.prodMonth,200,50+80)
            doc.text('Coll. Month',10,50+100)
            doc.text(': '+this.product.collMonth,200,50+100)
            doc.text('Sub. Brand',10,50+120)
            doc.text(': '+this.product.subBrand,200,50+120)
            doc.text('Category',10,50+140)
            doc.text(': '+this.product.category,200,50+140)
            doc.text('Article',10,50+160)
            doc.text(': '+this.product.article,200,50+160)
            doc.text('Repeat Sample',10,50+180)
            doc.text(': '+this.product.repeatSample,200,50+180)
            doc.text('Ref. Sample',10,50+200)
            doc.text(': '+this.product.noSample,200,50+200)
            doc.text('Old Sample No.',10,50+220)
            doc.text(': '+this.product.noSample,200,50+220)
            doc.text('Guidance',10,50+240)
            doc.text(': '+this.product.style,200,50+240)
            doc.text('Size Spec',10,50+260)
            doc.text(': '+this.product.fitting,200,50+260)
            doc.text('Design :',200+100,50)
            for(var ij=0;ij<=2;ij++){
                doc.addImage(this.product.design[ij].src,'JPEG',300+(ij*120),50+10,90,120)
                // console.log(this.product.design[ij].src)
                // doc.text(this.product.design[ij].fileName,300+(ij*80),50+90)
            }
            doc.text('Technical Drawing :',200+100,200)
            for(var ik=0;ik<2;ik++){
                doc.addImage(this.product.technical[ik].src,'JPEG',300+(ik*120),210,90,120)
                // doc.text(this.product.design[ij].fileName,300+(ij*80),50+90)
            }
            doc.text('Status',10,50+280)
            doc.text(': '+this.product.status,200,50+280)
            doc.text('Note',10,50+300)
            doc.text(': '+this.product.note,200,50+300)
            doc.addPage("A4","p")
            doc.setFontSize(16)
            doc.text('Fabric List',570/2,30,"center")
            doc.setFontSize(12)
            doc.autoTable({
                columns,
                body:fabricList    
            })
            doc.addPage("A4","p")
            doc.setFontSize(16)
            doc.text('Accessories List',570/2,30,"center")
            doc.setFontSize(12)
            doc.autoTable({
                columns,
                body:accList    
            })
            doc.addPage("A4","p")
            doc.setFontSize(16)
            doc.text('Thread List',570/2,30,"center")
            doc.setFontSize(12)
            doc.autoTable({
                columns,
                body:threadList    
            })
            doc.addPage("A4","p")
            doc.setFontSize(16)
            doc.text('Artwork List',570/2,30,"center")
            doc.setFontSize(12)
            doc.autoTable({
                columns,
                body:artworkList    
            })
            doc.addPage("A4","p")
            doc.setFontSize(16)
            doc.text('Interlining List',570/2,30,"center")
            doc.setFontSize(12)
            doc.autoTable({
                columns,
                body:interliningList    
            })
            // doc.save(pdfName+'.pdf')
            doc.output('dataurlnewwindow')
        },
        getDataUri(url, cb){
                var image = new Image();
                image.setAttribute('crossOrigin', 'anonymous')
                image.onload = function () {
                    var canvas = document.createElement('canvas');
                    canvas.width = this.naturalWidth;
                    canvas.height = this.naturalHeight;

                    //next three lines for white background in case png has a transparent background
                    var ctx = canvas.getContext('2d');
                    ctx.fillStyle = '#fff';  /// set white fill style
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    canvas.getContext('2d').drawImage(this, 0, 0);

                    cb(canvas.toDataURL('image/jpeg'));
                    
                };
                image.src = url;   
        },
        editData(id){
            // console.log(id);
            
            this.SET_PRODUCT_DETAIL({product_id:id})
            .then(rsp=>{
                this.$router.push({
                    name:'AppsProductDesignFormEdit',
                    params:{
                        productId:id
                    }
                })
                this.SET_PRODUCT_MATERIAL(id)
                .then(rsp=>{
                    this.GET_MATERIAL_HISTORY({product:id})
                }).catch(err=>{
                    return err
                })
            }).catch(err=>{
                return err
            })
            
        },
        getData(){
            this.isLoading = true
            // this.getProduct().then(response => {
            //     // console.log('product list',this.productList);
            //     if(this.productList != null){
            //         // this.dataProductDesign.push(this.productList)
            //         this.dataProductDesign = response
            //         console.log(this.dataProductDesign);
            //     }
            // })
            this.GET_PRODUCT_DASHBOARD()
                .then(response => {
                    if(this.roleUser === 'PLM - Designer'){
                        const finalData = JSON.parse(JSON.stringify(this.brandallperuser));
                        for (let i = 0; i < finalData.length; i++) {
                            const brand = finalData[i]
                            const allbrand = response.filter(app => app.brand == brand)
                            this.converDataBrand.push(allbrand)
                        }
                        this.dataProductDesign = this.converDataBrand.flat()
                        this.isLoading = false
                        console.log('data Designer', this.converDataBrand)
                    }else{
                        this.dataProductDesign = response
                        this.isLoading = false
                        console.log('data super user', this.dataProductDesign)
                    }
                }).catch(err => {
                    this.isLoading = false
                })

            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')
                    this.yearOpt = response.data.payload.filter(d=>d.category ==='Year')
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        goToArticle(articleId){
            // console.log(articleId);
            this.$router.push({
                name:'AppsProductDesign2ViewArticlePage',
                params:{
                    productId:articleId
                }

            })
        },
        viewData(id){
            this.getProductDetail(id)
            this.show = true
        },
        techPack(id,sizeId){
            this.$router.push({
                name:'AppsTechPack',
                params:{
                    productId:id,
                    sizeId:sizeId
                }
            })
        },
        editImages(id){
            this.$router.push({
                name:'AppsProductDesignFormEditImages',
                params:{
                    productId:id
                }
            })
        },
        rowClass(item,type){
            if (!item || type !== 'row') return
            if (item.status === 'Draft') return 'table-success'
        },
        getDataParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => { 
                     let parameter = response.data.payload
                     let parameter_brand = parameter.filter(app =>app.value === 'Designer' && app.description == this.username)
                     for (let i = 0; i < parameter_brand.length; i++) {
                            const brand = parameter_brand[i].name
                            this.brandallperuser.push(brand)
                     }
                     console.log('data brand per user', this.parameter_brand)
            })  
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
            
        },
        SetUserData(){
            let userData  = JSON.parse(localStorage.userData)
            this.roleUser = userData.role
            this.username = userData.username
            console.log('data role', this.roleUser)
        }
        
    },
    mounted(){
       this.getDataParam()
       this.getData()
    },
    created(){
        // this.getProduct()
        // this.getSizeSepc()
        this.SetUserData()
    },
    computed:{
        ...mapGetters('productDesign',['product','productList','productDetail','fabricList','accList','threadList','artworkList','interliningList']),
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        rows() {
            // console.log(this.filterProduct.length);
            return this.filterProduct.length
        },
        filterProduct(){      
                let brands = this.filters.brand
                let years  = this.filters.year
                let months = this.filters.month
                let statuses = this.filters.status
                return this.dataProductDesign.filter(function(item){
                        let filtereds = true
                        if(brands && brands.length > 0){
                            filtereds = item.brand == brands
                        }
                        if(filtereds){
                            if(years && years.length > 0){
                                filtereds = item.year == years
                            }
                        }
                        if(filtereds){
                            if(months && months.length > 0){
                                filtereds = item.coll_month == months
                            }
                        }
                        if(filtereds){
                            if(statuses && statuses.length > 0){
                                filtereds = item.status == statuses
                            }
                        }
                            return filtereds
                    })
            
        }
        // filterBy(row,filter){
        //     if(row.brand === this.brandFilter){

        //     }
        // }
        // sortOptions() {
        // // Create an options list from our fields
        // return this.fields
        //   .filter(f => f.sortable)
        //   .map(f => {
        //     return { text: f.label, value: f.key }
        //   })
        // },
        // onFiltered(filteredItems) {
        // // Trigger pagination to update the number of buttons/pages due to filtering
        // this.totalRows = filteredItems.length
        // this.currentPage = 1
        // }
    }
}
</script>