<template>
    <div>
        <b-overlay :show="busy" rounded="sm" >
            <template #overlay>
                <div class="text-center">
                    <b-icon icon="arrow-clockwise" font-scale="4" variant="primary" animation="spin"></b-icon>
                    <p id="cancel-label">Please wait...</p>
                    
                </div>
            </template>
        <template v-if="roles">
        <b-button variant="success" v-on:click="show = !show">Create</b-button>
        </template>
        <b-card sub-title="Filter" class="spacing-top">
            <b-row>
                 <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                        label="Brand"
                        label-for="brand-select"
                        label-cols="3" 
                        label-size="sm"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-select
                            id="brand-select"
                            v-model="filters.brand"
                            :options="brandOptFilter"
                            :aria-describedby="ariaDescribedby"
                            size="sm"
                        >
                        <template #first>
                            <option value="">All</option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                 <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                        label="Type"
                        label-for="type-select"
                        label-cols="3" 
                        label-size="sm"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-select
                            id="type-select"
                            v-model="filters.type"
                            :options="brandOptType"
                            :aria-describedby="ariaDescribedby"
                            size="sm"
                        >
                        <template #first>
                            <option value="">All</option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="auto" md="4" lg="4">
                        <b-form-group
                           label="Description"
                           label-for="search-description"
                           label-cols="3" 
                           label-size="sm"
                           v-slot="{ ariaDescribedby }"
                        >
                            <b-input-group size="sm">
                                <b-form-input 
                                    id="inputDescription" 
                                    v-model="filter"
                                    type="search Description"
                                    placeholder="Type to Search" 
                                    trim
                                ></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                        </b-input-group>
                            </b-form-group>
                    </b-col>
            </b-row>
        </b-card>
        <b-table
            striped 
            hover
            show-empty
            responsive
            :filter="filter"
            :filter-included-fields="includeFiled"
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterProduct"
            :fields="fieldsFabric"
            
        >
            <template #cell(actions)="{item}">
                <template v-if="roles">
                <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(item.id)">
                    
                    <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                </b-button>
                <template v-if="item.status != 'Finish'">
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.id)">
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
                </template>
                <template>
                    <b-button title="Show" size="xs" variant="info" v-on:click="showData(item.id)">
                        <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </template>
            <template #cell(img_src)="{item}"   >
                <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name"></b-img>
            </template>

        </b-table>
        <div class="mt-3">
            <b-pagination
                v-model="paging.currentPage"
                :total-rows="rows"
                :per-page="paging.perPage"
                align="center"
            >

            </b-pagination>
        </div>
        <b-modal
            hide-footer
            v-model="show"
            hide-backdrop
            v-on:ok="onSubmit()"
            v-on:close="clearForm()"
            ok-title="Save"
            size="lg"
            ref="modal"
            no-close-on-backdrop
            scrollable 
            title="Form Master Fabric">
            
            <div id="modal">
                <validation-observer ref="observer" v-slot="{handleSubmit}">
                    <b-form @submit.prevent="handleSubmit(onSubmit)">
                        <b-row>
                            <b-col sm="auto" md="6">
                                <!-- Brand -->
                                    <validation-provider
                                        name="brand"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Brand
                                            </template>
                                            <b-form-select :disabled="isShow" value-field="value"
                                                                text-field="value" v-on:change="generateCode()" id="inputBrand" v-model="form.brand" :options="brandOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Brand -->

                                <!-- YarnSize -->
                                    <validation-provider
                                        name="yarnSize"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p class="text-primary"> Yarn Size</p>
                                            </template>
                                            <b-form-select :disabled="isShow"
                                            v-on:change="generateCode()"
                                            value-field="value"
                                            text-field="value" id="inputYarnSize" v-model="form.yarnSize" :options="yarnOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputYarnSize">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /YarnSize -->

                                <!-- Texture -->
                                    <validation-provider
                                        name="Texture"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p class="text-primary">Texture</p>
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()" value-field="value"
                                        text-field="value" id="inputTexture" v-model="form.texture" :options="textureOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputTexture">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Texture -->

                                <!-- Month -->
                                    <validation-provider
                                        name="Month"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Month<code>*</code>
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()" value-field="value"
                                        text-field="value" id="inputMonth" v-model="form.month" :options="monthOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputMonth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Month -->

                                <!-- Year -->
                                    <validation-provider
                                        name="Year"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Year
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()" value-field="value"
                                        text-field="value" id="inputYear" v-model="form.year" :options="yearOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputYear">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Year -->

                                <!-- Code -->
                                    <validation-provider
                                        name="Code"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Code
                                            </template>
                                            <b-form-input  readonly id="inputCode" v-model="form.code"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Code -->

                                <!-- Type -->
                                    <validation-provider
                                        name="Type"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Type<code>*</code>
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()"
                                            value-field="value" text-field="value" id="inputType" v-model="form.type" :options="typeOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputType">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>

                                    <validation-provider
                                        name="TypeAtt"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Attribute<code>*</code>
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()"
                                            value-field="value" text-field="value" id="inputTypeAtt" v-model="form.typeAtt" :options="setTypeAtt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputTypeAtt">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>

                                <!-- /Type -->
                                
                                <!-- Width -->
                                    <validation-provider
                                        name="Width"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p class="text-primary">Width</p>
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()" value-field="value"
                                        text-field="value" id="inputWidth" v-model="form.width" :options="widthOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputWidth">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Width -->

                                <!-- ChemicalFinishing -->
                                    <validation-provider
                                        name="ChemicalFinishing"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p class="text-primary"> Finishing </p>
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()" value-field="value"
                                        text-field="value" id="inputChemicalFinishing" v-model="form.chemicalFinishing" :options="chemicalFinishingOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputChemicalFinishing">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /ChemicalFinishing -->

                                <!-- RunningNumber -->
                                    <validation-provider
                                        name="RunningNumber"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Running Number
                                            </template>
                                            <b-form-input  readonly  id="inputRunningNumber" v-model="form.runningNumber" :options="runningNumberOpt"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputRunningNumber">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /RunningNumber -->
                                <!-- Images -->
                                    <validation-provider
                                        name="Images"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Images
                                            </template>
                                            <b-form-file
                                                :disabled="isShow"
                                                multiple
                                                ref="fileImagesFabric"
                                                id="ImagesFabric"
                                                name="images-fabric"
                                                v-model="form.imagesFabric"
                                                no-drop
                                                :state="getValidationState(validationContext)"
                                                placeholder="Choose file here..."
                                                accept=".jpg, .png,"
                                                :file-name-formatter="formatNames"
                                                v-on:change="onFileChange"
                                            ></b-form-file>
                                                <div v-for="img in imagesUrl" :key="img.name">
                                                    <p class="small">
                                                        <a :href="img.src" target="_blank" >{{img.fileName}}</a>
                                                        <template v-if="isShow != true">
                                                            <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                            </b-badge>
                                                        </template>
                                                    </p>
                                                </div>
                                            <b-form-invalid-feedback id="ImagesFabric">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Images -->
                                <!-- Images -->
                                    <validation-provider
                                        name="AddFile"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Additional File
                                            </template>
                                            <b-form-file
                                                :disabled="isShow"
                                                multiple
                                                ref="fileAdditional"
                                                id="AddFile"
                                                name="additional-file"
                                                v-model="form.addFile"
                                                no-drop
                                                :state="getValidationState(validationContext)"
                                                placeholder="Choose file here..."
                                                
                                                v-on:change="onFileUpload"
                                            ></b-form-file>
                                                <div v-for="img in form.imgAddFile" :key="img.name">
                                                    <p class="small">
                                                        <a :href="img.src" target="_blank" >{{img.fileName}}</a>
                                                        
                                                            <b-badge href="#" v-on:click="removeFileUpload(img.fileName,form.imgAddFile,'fileAdditional')" variant="light">
                                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                            </b-badge>
                                                    </p>
                                                </div>
                                            <b-form-invalid-feedback id="AddFile">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Images -->
                        </b-col>
                            <b-col sm="auto" md="6">

                                <!-- Description -->
                                    <validation-provider
                                        name="Description"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Description
                                            </template>
                                            <b-form-input :disabled="isShow" id="inputDescription" v-model="form.description"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputDescription">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Description -->

                                <!-- Weight -->
                                    <validation-provider
                                        name="Weight"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Weight
                                            </template>
                                            <b-form-select :disabled="isShow" id="inputWeight" v-model="form.weight" :options="weightOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputWeight">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Weight -->

                                <!-- Weaving Type -->
                                    <validation-provider
                                        name="WeavingType"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p>Weaving Type</p>
                                            </template>
                                            <b-form-select value-field="value"
                                        text-field="value" :disabled="isShow" id="inputWeavingType" v-model="form.weavingType" :options="weavingOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputWeavingType">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Weaving Type -->

                                <!-- Composition -->
                                    <validation-provider
                                        name="Composition"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            
                                                <template v-slot:label>
                                                    <p class="text-primary">Composition</p>
                                                </template>
                                            <b-input-group>
                                                <b-form-select :disabled="isShow" v-on:change="generateCode()"  value-field="value"
                                        text-field="value" id="inputComposition" v-model="form.composition" :options="compositionOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                
                                                <template #append>
                                                    <b-form-select :disabled="isShow" id="inputCompositionValue" v-model="form.compositionValue" :options="compositionValueOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                </template>
                                            </b-input-group>
                                                <b-form-invalid-feedback id="inputComposition">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                <b-form-invalid-feedback id="inputCompositionValue">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Composition -->

                                <!-- Atribute1 -->
                                    <validation-provider
                                        name="Atribute1"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            
                                                <template v-slot:label>
                                                    <p class="text-primary">Atribute 1</p>
                                                </template>
                                            <b-input-group>
                                                <b-form-select :disabled="isShow" value-field="value"
                                        text-field="value" id="inputAtribute1" v-model="form.atribute1" :options="atribute1Opt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                
                                                <template #append>
                                                    <b-form-select :disabled="isShow" id="inputAtribute1Value" v-model="form.atribute1Value" :options="atribute1ValueOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                </template>
                                            </b-input-group>
                                                <b-form-invalid-feedback id="inputAtribute1">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                <b-form-invalid-feedback id="inputAtribute1Value">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Atribute1 -->
                                
                                <!-- Atribute2 -->
                                    <validation-provider
                                        name="Atribute2"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            
                                                <template v-slot:label>
                                                    <p class="text-primary">Atribute 2</p>
                                                </template>
                                            <b-input-group>
                                                <b-form-select :disabled="isShow" value-field="value"
                                        text-field="value" id="inputAtribute2" v-model="form.atribute2" :options="atribute2Opt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                
                                                <template #append>
                                                    <b-form-select :disabled="isShow" id="inputAtribute2Value" v-model="form.atribute2Value" :options="atribute2ValueOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                </template>
                                            </b-input-group>
                                                <b-form-invalid-feedback id="inputAtribute2">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                <b-form-invalid-feedback id="inputAtribute2Value">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Atribute2 -->
                                    
                                <!-- Construction -->
                                    <validation-provider
                                        name="Construction"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p class="text-primary">Construction</p>
                                            </template>
                                            <b-form-select :disabled="isShow" v-on:change="generateCode()" value-field="value"
                                        text-field="value" id="inputConstruction" v-model="form.construction" :options="constructionOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputConstruction">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Construction -->

                                <!-- Code Handloom -->
                                    <validation-provider
                                        name="CodeHandloom"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p class="text-primary">Code Handloom</p>
                                            </template>
                                            <b-form-input :disabled="isShow" id="inputCodeHandloom" v-model="form.codeHandloom"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputCodeHandloom">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Code Handloom -->

                                <!-- Supplier -->
                                    <!-- <validation-provider
                                        name="Supplier"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                <p class="text-primary">Supplier</p>
                                            </template>
                                            <b-form-select :disabled="isShow" id="inputSupplier" v-model="form.supplier" :options="supplierOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputSupplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider> -->
                                <!-- /Supplier -->

                                <!-- Color -->
                                    <validation-provider
                                        name="Color"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                               <p class="text"> Color <code>*</code></p>
                                            </template>
                                            <b-form-select :disabled="isShow" value-field="value" text-field="value" id="inputColor" v-model="form.color" :options="colorOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputColor">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Color -->
                                <b-button :disabled="isShow" variant="warning" v-on:click="cancel()" block size="sm">Cancel</b-button>
                                <b-button :disabled="isShow" variant="success" block size="sm" type="submit">Save</b-button>
                                
                            </b-col>
                        </b-row>

                    </b-form>
                </validation-observer>
            </div>
        </b-modal>
        </b-overlay>
    </div>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import apiProduct from '@/store/product-form/master-param.js'
import { APIPATH_PLM } from "../../../../api/api-paths";
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../api/api-methods";
export default {
    data(){
        const now = new Date()
        const today = now.toISOString().slice(0,10)
        return{
           dataFilterFabric:[],
           filter:null,
           search:null,
           filters:{
                brand:'',
                type:'',
            },
            brandOptFilter:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'Anthm',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
                {value:'Van Hausen',text:'Van Hausen'},
            ],
            brandOptType:[
                {value:'WOVEN',text:'WOVEN'},
                {value:'KNIT',text:'KNIT'},
                {value:'FLATKNIT',text:'FLATKNIT'},
            ],
            includeFiled:['description'],
            mainProps: {width: 50, height: 50, class: 'm1' },
            isShow:false,
            show:false,
            formName:'Fabric',
            isValid:false,
            imagesUrl:[],
            imgID:null,
            form:{
                category:'fabric',
                indexId:null,
                brand:null,
                yarnSize:null,
                texture:null,
                month:null,
                code:null,
                type:null,
                typeAtt:null,
                width:null,
                chemicalFinishing:null,
                runningNumber:'XX',
                description:null,
                composition:null,
                compositionValue:null,
                construction:null,
                year:null,
                images:[],
                supplier:null,
                date:today,
                weavingType: null,
                weight:null,
                createdBy:null,
                codeTemp:null,
                status:'new',
                color:null,
                atribute1:null,
                atribute1Value:null,
                atribute2:null,
                atribute2Value:null,
                codeHandloom:null,
                addFile:null,
                imgAddFile:[],
                imagesFabric:null,

            },
            dataFabric:[],
            // fieldsFabric:[
            //     {key:'actions', label:'#'},
            //     'code','runningNumber','brand','yarnSize','texture',
            //     'month','type','width','chemicalFinishing','description',
            //     'composition','construction','year','images'
            // ],
            fieldsFabric:[
                {key:'actions', label:'#'},
                'code',
                'type','status','description',
                {key:'img_src',label:'Images'},
            ],
            brandOpt:[],
            yarnOpt:[],
            textureOpt:[],
            monthOpt:[],
            typeOpt:[],
            typeAttOpt:[],
            widthOpt:[],
            chemicalFinishingOpt:[],
            runningNumberOpt:[],
            compositionOpt:[],
            compositionValueOpt:[
                {value:'100',text:'100'},
                {value:'97',text:'97'},
                {value:'80',text:'80'},
                {value:'60',text:'60'},
                {value:'55',text:'55'},
                {value:'45',text:'45'},
                {value:'40',text:'40'},
                {value:'20',text:'20'},
                {value:'3',text:'3'},
            ],
            constructionOpt:[],
            atribute1Opt:[],
            atribute1ValueOpt:[
                {value:'100',text:'100'},
                {value:'97',text:'97'},
                {value:'80',text:'80'},
                {value:'60',text:'60'},
                {value:'55',text:'55'},
                {value:'45',text:'45'},
                {value:'40',text:'40'},
                {value:'20',text:'20'},
                {value:'3',text:'3'},
            ],
            atribute2Opt:[],
            atribute2ValueOpt:[
                {value:'100',text:'100'},
                {value:'97',text:'97'},
                {value:'80',text:'80'},
                {value:'60',text:'60'},
                {value:'55',text:'55'},
                {value:'45',text:'45'},
                {value:'40',text:'40'},
                {value:'20',text:'20'},
                {value:'3',text:'3'},
            ],         
            yearOpt:[],
            supplierOpt:[
                {value:'Dwi Jaya',text:'Dwi Jaya'},
                {value:'Tri Jaya',text:'Tri Jaya'}
            ],
            weavingOpt:[
                {value:'Weaving A',text:'Weaving A'},
                {value:'Weaving B',text:'Weaving B'},
                {value:'Weaving C',text:'Weaving C'},
            ],
            weightOpt:[
                {value:'Light',text:'Light'},
                {value:'Medium',text:'Medium'},
                {value:'Heavy',text:'Heavy'},
            ],
            colorOpt:[
                {value:'Light Blue',text:'Light Blue'},
                {value:'Dark Grey',text:'Dark Grey'},
                {value:'Pantone',text:'Pantone'},
            ],
            paging:{
                perPage:10,
                currentPage:1,
            },

            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User'],
            isChange:false,
            masterFabric:null,
            busy:false,

        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL','PUT_MASTER_MATERIAL','POST_MASTER_MATERIAL','DELETE_MASTER_MATERIAL','POST_MATERIAL_IMAGES','PUT_MATERIAL_IMAGES','addMaster','editMaster','updateMaster','deleteMaster','GET_MASTER_MATERIAL_FABRIC']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onFileUpload(e){
            var file = e.target.files
            var idField = e.target.id
            var pathName = e.target.name
            this.form['img'+idField] = []
            
            for (var i=0; i < file.length; i++){
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                    images: file[i],
                    img_name:file[i].name,
                    img_code:pathName
                }
                this.form['img'+idField].push(img)
                console.log('dataimg', this.form.imgAddFile)
            }
            this.isChange = true
            
        },
         removeFileUpload:function(filename,modelname,refname){
            
            modelname.splice(filename,1);
            return this.$refs[refname].reset()
        },
        generateCode(){
            if((this.form.brand && this.form.type && this.form.typeAtt  && this.form.year && this.form.month) != null){
                let brand = this.brandOpt.filter(x=>x.value === this.form.brand)
                let type = this.typeOpt.filter(x=>x.value === this.form.type)
                let typeAtt = this.typeAttOpt.filter(x=>x.value === this.form.typeAtt)
                // let texture = this.textureOpt.filter(x=>x.value === this.form.texture)
                // let finishing = this.chemicalFinishingOpt.filter(x=>x.value === this.form.chemicalFinishing)
                let year = this.yearOpt.filter(x=>x.value === this.form.year)
                let month = this.monthOpt.filter(x=>x.value === this.form.month)
                
                
                // let yarn = this.yarnOpt.filter(x=>x.value === this.form.yarnSize)
                // let composition = this.compositionOpt.filter(x=>x.value === this.form.composition)
                // let width = this.widthOpt.filter(x=>x.value === this.form.width)
                // let construction = this.constructionOpt.filter(x=>x.value === this.form.construction)

                this.form.code = brand[0].name+'-'+type[0].name+typeAtt[0].name+'-'+year[0].name+month[0].name

                
            }

        },
        getMasterParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    this.brandOpt = response.data.payload.filter(d=>d.category ==='Brand')
                    this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')
                    this.yearOpt = response.data.payload.filter(d=>d.category ==='Year')
                    this.yarnOpt = response.data.payload.filter(d=>d.category ==='Yarn Size')
                    this.textureOpt = response.data.payload.filter(d=>d.category ==='Texture')
                    this.typeOpt = response.data.payload.filter(d=>d.category ==='Type of Fabric')
                    for (let i = 0; i < this.typeOpt.length; i++) {
                        // console.log(JSON.stringify(this.typeOpt[i].description));
                        
                    }
                    this.typeAttOpt = response.data.payload.filter(d=>d.category ==='Type Attribute')
                    this.widthOpt = response.data.payload.filter(d=>d.category ==='Width')
                    this.chemicalFinishingOpt = response.data.payload.filter(d=>d.category ==='Finishing')
                    this.compositionOpt = response.data.payload.filter(d=>d.category ==='Composition')
                    // let compVal = response.data.payload.filter(d=>d.category ==='Composition')
                    this.atribute1Opt = response.data.payload.filter(d=>d.category ==='Composition')
                    this.atribute2Opt = response.data.payload.filter(d=>d.category ==='Composition')
                    this.constructionOpt = response.data.payload.filter(d=>d.category ==='Construction')
                    this.colorOpt = response.data.payload.filter(d=>d.category ==='Color')
                    this.weavingOpt = response.data.payload.filter(d=>d.category ==='Weaving Type')
                    
                    
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })

        },
        onSubmit(){
            Vue.set(this,'busy',true)
            this.generateCode()
            if(this.form.indexId != null){
                this.PUT_MASTER_MATERIAL({
                    material_id: this.form.indexId,
                    category:this.form.category,
                    formname:this.formName,
                    code:this.form.code,
                    brand:this.form.brand,
                    type:this.form.type,
                    composition:this.form.composition,
                    composition_value:this.form.compositionValue,
                    yarn_size:this.form.yarnSize,
                    width:this.form.width,
                    construction:this.form.construction,
                    texture:this.form.texture,
                    chemical_finishing:this.form.chemicalFinishing,
                    year:this.form.year,
                    month:this.form.month,
                    running_number:this.form.runningNumber,
                    weaving_type: this.form.weavingType,
                    description:this.form.description,
                    weight: this.form.weight,
                    code_temp: this.form.code + this.form.brand,
                    supplier:this.form.supplier,
                    date:this.form.date,
                    createdBy:this.form.createdBy,
                    status:this.form.status,
                    color:this.form.color,
                    atribute1:this.form.atribute1,
                    atribute1_value:this.form.atribute1Value,
                    atribute2:this.form.atribute2,
                    atribute2_value:this.form.atribute2Value,
                    code_handloom:this.form.codeHandloom,
                    type_attribute:this.form.typeAtt
                })
                .then(response =>{
                    if(response.data.status === 200){
                        if(this.isChange === true){
                        this.updateImages(response.data.payload.material_id)
                        }
                        if(this.isChange === true){
                            this.submitFile(response.data.payload.material_id)
                        }
                        this.clearForm()
                        Vue.set(this,'busy',false)
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                        this.getFabricMaster()
                    }
                }).catch(error => {
                    Vue.set(this,'busy',false)
                    return error.response.data.message
                })
            }else{
                this.POST_MASTER_MATERIAL({
                    material_id: this.form.indexId,
                    category:this.form.category,
                    formname:this.formName,
                    code:this.form.code,
                    brand:this.form.brand,
                    type:this.form.type,
                    composition:this.form.composition,
                    composition_value:this.form.compositionValue,
                    yarn_size:this.form.yarnSize,
                    width:this.form.width,
                    construction:this.form.construction,
                    texture:this.form.texture,
                    chemical_finishing:this.form.chemicalFinishing,
                    year:this.form.year,
                    month:this.form.month,
                    running_number:this.form.runningNumber,
                    weaving_type: this.form.weavingType,
                    description:this.form.description,
                    weight: this.form.weight,
                    code_temp: this.form.code + this.form.brand,
                    supplier:this.form.supplier,
                    date:this.form.date,
                    createdBy:this.form.createdBy,
                    status:this.form.status,
                    color:this.form.color,
                    atribute1:this.form.atribute1,
                    atribute1_value:this.form.atribute1Value,
                    atribute2:this.form.atribute2,
                    atribute2_value:this.form.atribute2Value,
                    code_handloom:this.form.codeHandloom,
                    type_attribute:this.form.typeAtt
                })
                .then(response =>{
                    if(response.data.status === 200){
                        this.submitImages(response.data.payload.material_id)
                        this.submitFile(response.data.payload.material_id)
                        Vue.set(this,'busy',false)
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                    }
                }).catch(error => {
                    Vue.set(this,'busy',false)
                    return error.response.data.message
                })
            }
            

            
        },
        getMasterMaterialFile(masterID){
            fnPOST(APIPATH_PLM.getMasterMaterialImages,{material_id:masterID})
            .then((rsp) => {
                const dataMasterMaterialImage = rsp.data.payload
                const datafilter = dataMasterMaterialImage.filter(i => i.img_label == 'additional')
                for (var i=0; i < datafilter.length; i++){
                let file = {
                    src: datafilter[i].img_src,
                    fileName:datafilter[i].img_name,
                }
                this.form.imgAddFile.push(file)
                }     
                console.log('data file', this.form.imgAddFile[0])
            })
            .catch((err) => {
                console.log(err);
            });
        },
        submitImages(materialID){
            
            const fd = new FormData()
            fd.append('material_id', materialID)
            fd.append('material',this.imagesUrl[0].images)
            fd.append('img_label','images')

            this.POST_MATERIAL_IMAGES(fd)
            .then(response => {
                if(response.data.status === 200){
                    console.log('gambar berhasil disimpan');
                     
                    this.getFabricMaster()
                     
                }
            }).catch(err => {
                return err.response.data.message
            })

            // return this.clearForm()


        },
        submitFile(materialID){

            const fd = new FormData()
            for( var i = 0; i < this.form.imgAddFile.length; i++ ){
                fd.append('material_id', materialID)
                fd.append('material',this.form.imgAddFile[i].images)
                fd.append('img_label','additional')
            }
             console.log('data file process', this.form.imgAddFile)
            this.POST_MATERIAL_IMAGES(fd)
            .then(response => {
                if(response.data.status === 200){
                    console.log('file berhasil disimpan');
                     
                    this.getFabricMaster()
                     
                }
            }).catch(err => {
                return err.response.data.message
            })

            return this.clearForm()


        },
        updateImages(materialID){
            
            const fd = new FormData()
            fd.append('material_id', materialID)
            fd.append('img_id',this.imgID)
            fd.append('material',this.imagesUrl[0].images)
            fd.append('img_label','images')

            this.PUT_MATERIAL_IMAGES(fd)
            .then(response => {
                if(response.data.status === 200){
                    console.log('gambar berhasil disimpan');
                    this.getFabricMaster()
                }
            }).catch(err => {
                return err.response.data.message
            })

            // return this.clearForm()


        },
        uploadFileAdd(materialID){
            const fd = new FormData()
            fd.append('material_id', materialID)
            fd.append('img_id',this.imgID)
            fd.append('material',this.form.imgAddFile[0].images)
            fd.append('img_code',this.form.imgAddFile[0].img_code)
            fd.append('img_label','additional')

            this.PUT_MATERIAL_IMAGES(fd)
            .then(response => {
                if(response.data.status === 200){
                    console.log('gambar berhasil disimpan');
                    this.getFabricMaster()
                }
            }).catch(err => {
                return err.response.data.message
            })

            // return this.clearForm()
        },
        clearForm(){
            this.form.indexId=null,
            this.form.brand=null,
            this.form.yarnSize=null,
            this.form.texture=null,
            this.form.month=null,
            this.form.code=null,
            this.form.type=null,
            this.form.width=null,
            this.form.chemicalFinishing=null,
            this.form.runningNumber=null,
            this.form.description=null,
            this.form.composition=null,
            this.form.construction=null,
            this.form.year=null,
            this.form.images=[],
            this.form.supplier=null,
            this.form.date=null,
            this.form.createdBy=null,
            this.imagesUrl = [],
            this.form.compositionValue = null,
            this.form.atribute1 = null,            
            this.form.atribute1_value = null,
            this.form.atribute2 = null,
            this.form.atribute2_value = null,
            this.form.codeHandloom = null,
            this.form.addFile = null,
            this.form.weight = null,
            this.form.weavingType = null,
            this.form.imgAddFile = [],
            this.$refs.observer.reset(),
            this.show = false,
            this.isChange = false

        },
        editData(masterID){
            // console.log('data id', masterID)
            this.GET_MASTER_MATERIAL_FABRIC({material_id:masterID})
            .then(rsp=>{
                // Vue.set(this,'masterFabric',rsp.data.payload)
            
            
            this.form.indexId = masterID
            // let masterData = this.MASTER_MATERIAL.filter(x => x.id === masterID)
            let masterData = rsp.data.payload
            console.log('masterFabric',masterData);
            this.form.brand= masterData.brand
            this.form.yarnSize= masterData.yarn_size
            this.form.texture= masterData.texture
            this.form.month= masterData.month
            this.form.code= masterData.code
            this.form.type= masterData.type
            this.form.width= masterData.width
            this.form.chemicalFinishing= masterData.chemical_finishing
            this.form.runningNumber= masterData.running_number
            this.form.description= masterData.description
            this.form.composition= masterData.composition
            this.form.construction= masterData.construction
            this.form.year= masterData.year
            // this.imagesUrl=masterData[0].img_src
            this.form.supplier= masterData.supplier
            this.form.compositionValue = masterData.composition_value
            this.form.atribute1 = masterData.atribute1            
            this.form.atribute1_value = masterData.atribute1_value
            this.form.atribute2 = masterData.atribute2
            this.form.atribute2_value = masterData.atribute2_value
            this.form.codeHandloom = masterData.code_handloom
            this.form.weight = masterData.weight
            this.form.weavingType = masterData.weaving_type
            this.form.color = masterData.color
            let img = {
                src: masterData.img_src,
                fileName: masterData.img_name
            }
            // let file = {
            //     src: masterData.additional_src,
            //     fileName:masterData.additional_name
            // }
            this.imagesUrl.push(img)
            this.imgID = masterData.img_id
            this.form.imagesFabric = img

            // this.form.imgAddFile.push(file)
            this.form.addFile = img
            

            var codeAtt = masterData.code
            var attributeId = codeAtt.charAt(3)
            let typeId = this.typeOpt.filter(i=>i.value == this.form.type)

            var typeAtt = this.typeAttOpt.filter(i=>i.name === attributeId && i.parent === typeId[0].id)

            Vue.set(this.form,'typeAtt',typeAtt[0].value)

            this.show = true
            this.getMasterMaterialFile(masterID)
            }).catch(err=>{
                return err
            })
            
        },
        showData(masterID){
            this.isShow = true
            this.editData(masterID)
        },
        createFabric(){
            this.form.indexId = null
            this.show = true
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                    images:file[i],
                    img_name:file[i].name,
                    img_code:file[i].name+'-'+[i]
                }
                this.imagesUrl.push(img)
            }
            this.isChange = true

            
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
            return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        getFabricMaster(){
            Vue.set(this,'busy',true)
            this.GET_MASTER_MATERIAL().then(response => {
                Vue.set(this,'busy',false)
                if(response.data.status === 200){
                    // this.showNotification({type:'success', message:'Successfully Loading Data'})
                    this.dataFilterFabric = this.MASTER_MATERIAL.filter(mm => mm.category === 'fabric')
                }
                // this.dataFabric = response.data.payload.filter(x => x.category === 'fabric')
                
            }).catch(err=>{
                Vue.set(this,'busy',false)
                return err
            })
        },
        cancel(){
            this.form.indexId  = null,
            this.show = !this.show
            return this.clearForm()
        },
        deleteData(masterID){
            Vue.set(this,'busy',true)
            this.DELETE_MASTER_MATERIAL({material_id:masterID})
            .then(response =>{
                Vue.set(this,'busy',false)
                if(response.data.status === 200){
                    this.getFabricMaster()
                }
                
            }).catch(error => {
                Vue.set(this,'busy',false)
                return error.response.data.message
            })
        },    
    },
    mounted(){
        this.getFabricMaster()
        this.getMasterParam()
    },
    computed:{
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        // loadMasterMaterial(){
        //     return this.MASTER_MATERIAL.filter(mm => mm.category === 'fabric')
        // },
        filterProduct(){
           let brands = this.filters.brand
           let types = this.filters.type
           return this.dataFilterFabric.filter(function(item){
                let filtereds = true
                        if(brands && brands.length > 0){
                            filtereds = item.brand == brands
                        }
                        if(filtereds){
                            if(types && types.length > 0){
                                filtereds = item.type == types
                            }
                        }
             return filtereds
           }) 

        },
        rows(){
            if(this.dataFilterFabric != null){
                return this.dataFilterFabric.length
            }
        },
        setTypeAtt(){
            let typeId = this.typeOpt.filter(i=>i.value == this.form.type)
            return this.typeAttOpt.filter(i=>i.parent == typeId[0].id)
        }
    }
}
</script>