<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-weekly-activity-plan">Weekly Activity Plan</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Weekly Activity Plan Form" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3> Weekly Activity Plan Form</h3>
                <router-link tag="b" to="/app/sfc-weekly-activity-plan" style="position: absolute; right: 15px;">
                  <b-button size="md" type='button' variant="info">
                    <b-icon icon="arrow-left"></b-icon>
                    Back
                  </b-button>
                </router-link>
              </div>

            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col sm="5">
                    <b-col sm='12'>
                      <b-form-group
                        label="Plan ID"
                        label-for="IdTransaction"
                        label-cols-sm="5"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <b-form-input
                          id="IdTransaction"
                          :disabled="true"
                          v-model="form.id_transaction"
                          trim
                        ></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm='12'>
                      <b-form-group
                        label="Period"
                        label-for="Period"
                        label-cols-sm="5"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <VueDatePicker
                          class="vd__div"
                          v-model="form.period"
                          no-header
                          :disabled="DisableStartDate() || buttonSubmit == 'view'"
                          @input="ChangeTransaction()"
                          type="month"
                          format="MMM YYYY"
                          :min-date="minMonthPeriod"
                          :max-date="maxMonthPeriod"
                          placeholder="Choose date"
                          />
                        </b-form-group>
                    </b-col>



                  </b-col>
                  <b-col sm="5">
                    <b-col sm='12'>
                      <b-form-group
                        label="Start Date"
                        label-for="StartDate"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <VueDatePicker
                          class="vd__div"
                          v-model="form.start_date"
                          :disabled="DisableStartDate()"
                          @input="ChangeMinEndDate()"
                          no-header
                          ref="startDate"
                          format="ddd DD MMM YYYY"
                          :min-date="minMonthDate"
                          :max-date="maxMonthDate"
                          placeholder="Choose start date"
                          />
                          <span id="startDateWarning" class="display-hidden text-small text-danger">"Wajib di Isi</span>
                        </b-form-group>
                    </b-col>

                    <b-col sm='12'>
                      <b-form-group
                        label="End Date"
                        label-for="EndDate"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <VueDatePicker
                          class="vd__div"
                          v-model="form.end_date"
                          :disabled="DisableEndDate()"
                          format="ddd DD MMM YYYY"
                          ref="endDate"
                          no-header
                          :min-date="minEndDate"
                          :max-date="maxMonthDate"
                          placeholder="Choose end date"
                          />
                          <span id="endDateWarning" class="display-hidden text-small text-danger">Wajib di isi</span>
                        </b-form-group>
                    </b-col>
                  </b-col>
                  <!-- <b-col sm="4">
                    <b-table
                      :no-border-collapse="true"
                      striped
                      sticky-header="200px"
                      head-variant="light"
                      class="header-background max_width_fit "
                      hover
                      small
                      outlined
                      sort-icon-left
                      show-empty
                      responsive
                      :fields="fields_customer"
                      :items="dataTableCustomer"
                    >

                      <template #cell(action)="row">
                        <b-button title="ViewDetail" size="xs" variant="outline-success" v-on:click="ViewDetailCustomer(row.item.id)" style='margin-right:5px;'>
                            <b-icon icon="binoculars-fill" aria-label="ViewDetail"></b-icon>

                        </b-button>
                        <b-button title="View" size="xs" variant="outline-info" v-on:click="ViewCustomer(row.item.id)" style='margin-right:5px;'>
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>

                        </b-button>
                      </template>
                    </b-table>
                  </b-col> -->
                </b-row>
                <b-row>
                  <b-col sm="12">
                  <div class="text-right">
                    <b-button title="Remove" v-if="buttonSubmit == null" v-on:click="deleteAll()" size="sm" variant="outline-danger" style='margin-right:5px;'>
                        <b-icon icon="x-square" aria-label="Remove"></b-icon>
                        Remove All
                    </b-button>
                  </div>
                </b-col>
                </b-row>

              </b-list-group-item>
            </b-list-group>
            <b-table
              :no-border-collapse="true"
              striped
              stacked="md"
              class="header-background max_width_fit "
              hover
              small
              sort-icon-left
              show-empty
              responsive
              :fields="fields"
              :items="dataTable"
            >
              <template #empty="scope">
                  <p class="text-center display-hidden">{{ scope.emptyText }}</p>
                  <div style='text-align:center !important;'>
                    <b-button title="Add" size="sm" v-if="!disabledForm" variant="outline-success" v-on:click="AddWeekActivity()" style='margin-right:5px;'>
                      <b-icon icon="journal-plus" aria-label="Add"></b-icon>
                    </b-button>
                  </div>
              </template>

              <template #table-caption>
                <b-button v-if="dataTable.length > 0 && !disabledForm" title="Add" size="sm" variant="outline-success" v-on:click="AddWeekActivity()" style='margin-right:5px;'>
                    <b-icon icon="journal-plus" aria-label="Add"></b-icon>
                </b-button>
              </template>

              <!-- Period Date -->
              <template #cell(plan_date)="row">
                  <span v-if="row.value !== null" v-html="FormatDate(row.value)"></span>
              </template>

              <!-- Activity -->
              <template #cell(activity_name)="row">
                  <span v-if="row.value !== null" v-html="ActivityReplace(row.value)"></span>
              </template>

              <!-- <template v-slot:cell(actions)="{item}"> -->
              <template #cell(action)="row">
                <b-button title="Edit" v-if="!disabledForm " :disabled="buttonSubmit == 'view' || row.item.action == undefined" size="xs" variant="outline-warning" v-on:click="EditModal(row.index,row.item)" style='margin-right:5px;'>
                    <b-icon icon="pencil-fill" aria-label="Edit"></b-icon>

                </b-button>
                <b-button title="Delete" size="xs" :disabled="buttonSubmit == 'view' || row.item.action == undefined" type="button" variant="outline-danger" v-on:click="Confirm('delete',row)">
                        <b-icon icon="trash-fill" aria-label="Delete"></b-icon>

                    </b-button>
              </template>
            </b-table>
          </b-card>

          <template>
            <b-modal
                v-model="show"
                ok-variant="success"
                cancel-variant="warning"
                header-bg-variant="secondary"
                hide-footer
                hide-backdrop
                hide-header-close
                size="lg"
                ref="modal"
                no-close-on-backdrop
                title="Form Weekly Activity"

            >
                <div id="modal">
                  <validation-observer ref="observer" v-slot="{handleSubmit}">
                    <b-form @submit.prevent="handleSubmit()" >
                      <b-row>
                        <b-col cols="12">
                          <validation-provider
                            name="Customer"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                              label               ="Customer *"
                              label-class         = 'text-primary'
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                              <v-select
                                v-model="form.selectedCustomer"
                                :clearable="false"
                                :required="true"
                                label="name"
                                @input="onChange($event)"
                                placeholder="Choose Customer"
                                :options="options.customer"
                                ref="CustomerTable"
                                >
                                <template v-slot:option="option">
                                  {{ option.project_id }} - {{ option.name }}
                                </template>
                              </v-select>
                              <b-form-invalid-feedback id="Customer">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            <span id="Warrning-Customer" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                            </b-form-group>
                          </validation-provider>
                        </b-col>

                        <b-col cols="12">
                            <b-form-group
                              label               ="Plan Date *"
                              label-class         = 'text-primary'
                              label-cols          ="3"
                              label-cols-md       ="3"
                              label-size          ="sm"
                            >
                            <VueDatePicker
                              class="vd__div text-dark"
                              v-model="form.plan_date"
                              no-header
                              format="ddd DD MMM YYYY"
                              placeholder="Choose Date"
                              :min-date="form.start_date"
                              :max-date="form.end_date"
                              />
                              <span id="Warrning-Date" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                            </b-form-group>
                        </b-col>

                        <b-col cols="12" v-if="options.activity.length > 0">
                          <b-form-group
                            label               ="Plan *"
                            label-class         = 'text-primary'
                            label-cols          ="3"
                            label-cols-md       ="3"
                            label-size          ="sm"
                          >
                            <v-select
                              v-model="form.plan_activity"
                              :clearable="false"
                              :required="true"
                              class="activityField"
                              label="name"
                              @input="ChangeReasonActivity($event)"
                              placeholder="Choose Activity"
                              :options="options.activity"
                              >
                            </v-select>
                            <span id="Warrning-Plan" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                          id="reasonActivity"
                          class="display-hidden"
                        >
                          <b-form-group
                            label               ="Reason"
                            label-cols          ="3"
                            label-cols-md       ="3"
                            label-size          ="sm"
                          >
                            <v-select
                              v-model="form.reasonActivity"
                              :clearable="false"
                              :required="true"
                              label="name"
                              :reduce="(data) => data.name"
                              placeholder="Choose Reason"
                              :options="options.reason"
                              >
                            </v-select>
                            <span id="Warrning-Reason" class="display-hidden text-small text-danger">"Wajib di Isi"</span>
                          </b-form-group>
                        </b-col>

                        <b-col cols="12">
                          <b-form-group
                            label               ="Remark"
                            label-cols          ="3"
                            label-cols-md       ="3"
                            label-size          ="sm"
                          >
                            <b-form-textarea
                              id="Remark"
                              placeholder="Write Your Remark"
                              v-model="form.remark"
                              rows="3"
                              no-resize
                            ></b-form-textarea>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <br />

                        <!-- Sales Projection -->
                      <b-row>
                      <b-col sm="12">
                        <accordion>
                          <accordion-item>
                            <!-- This slot will handle the title/header of the accordion and is the part you click on -->
                            <template slot="accordion-trigger">
                              <h4>
                                Sales Projection
                                <b-icon
                                  scale="2"
                                  style="
                                    color: black;
                                    position: relative;
                                    padding: 5px;
                                    top: 2px;
                                  "
                                  icon="chevron-down"
                                ></b-icon>
                                <span
                                  id="warning-sales_projection"
                                  style="margin-left: 10px"
                                  class="display-hidden text-small text-danger"
                                  >Required
                                </span>
                              </h4>
                            </template>
                            <!-- This slot will handle all the content that is passed to the accordion -->
                            <template slot="accordion-content">
                              <br />
                              <b-row align-h="start">
                                <!-- EstShipmentMonth -->
                                <b-col sm="12">
                                  <b-form-group
                                    label="Est Shipment Month *"
                                    label-class= 'text-primary'
                                    label-for="EstShipmentMonth"
                                    label-cols-sm="3"
                                    label-align-sm="left"
                                    label-size="sm"
                                  >
                                    <VueDatePicker
                                      v-model="form.estShipMonth"
                                      class="vd__div"
                                      no-header
                                      :disabled="formWeekly"
                                      placeholder="Choose Month"
                                      :min-date="NowDate"
                                      type="month"
                                    >
                                      <template #input-icon>
                                        <b-icon
                                          scale="1"
                                          style="color: black"
                                          icon="calendar3"
                                        ></b-icon>
                                      </template>
                                    </VueDatePicker>
                                    <span
                                    id="warning-shipment"
                                    class="display-hidden text-small text-danger"
                                  >
                                    Required
                                    </span>
                                  </b-form-group>
                                </b-col>
                              </b-row>

                              <!-- Project Status -->
                              <b-row align-h="start">
                                <b-col sm="12">
                                  <b-form-group
                                    label="Project Status *"
                                    label-class= 'text-primary'
                                    label-for="ProjectStatus"
                                    label-cols-sm="3"
                                    label-align-sm="left"
                                    label-size="sm"
                                  >
                                    <v-select
                                      :disabled="formWeekly"
                                      :clearable="false"
                                      :reduce="(data) => data.value"
                                      v-model="form.statusCustomer"
                                      :options="optionsStatusCustomer"
                                      placeholder="Select Project Status"
                                      label="value"
                                    >
                                    </v-select>
                                    <span
                                    id="warning-projectStatus"
                                    class="display-hidden text-small text-danger"
                                    >
                                    Required
                                    </span>
                                  </b-form-group>
                                </b-col>
                              </b-row>

                              <!-- Item Products  -->
                              <b-row align-h="start">
                                <b-col sm="12">
                                  <b-form-group
                                    label="Products *"
                                    label-class= 'text-primary'
                                    label-for="Products"
                                    label-cols-sm="3"
                                    label-align-sm="left"
                                    label-size="sm"
                                  >
                                    <b-row style="margin: 0px; margin-top: 5px; font-size:12px;">
                                      <div style="display:contents; ">
                                      <span style='width:25%; padding-bottom:10px;'> Name </span>
                                      <span style='width:15%; padding-bottom:10px;'> Qty </span>
                                      <span style='width:25%; padding-bottom:10px;'> Est Price </span>
                                      <span style='width:25%; padding-bottom:10px;'> Est Cogs </span>
                                      <span v-if="!formWeekly" style='width:10%; text-align:end; padding-bottom:10px;' @click="AddItem()">
                                        <!-- <span style="position: absolute; right: 0px;">Add</span> -->
                                        <b-icon
                                          scale="1.5"
                                          style="
                                            color: green;
                                            position: relative;
                                          "
                                          icon="plus-square"
                                        ></b-icon>
                                      </span>
                                    </div>
                                    <br />
                                    <div v-for="(row, index) in dataItemProducts" :key="row.id" style="display:contents; margin: 0px; font-size:11px;">
                                      <span v-if="row.type != 'delete'" style='width:25%; padding-top: 5px; '> {{ row.category }}-{{ row.item }}</span>
                                      <span v-if="row.type != 'delete'" style='width:15%; padding-top: 5px; '> {{ row.qty }} </span>
                                      <span v-if="row.type != 'delete'" style='width:25%; padding-top: 5px; '> {{ (parseFloat(row.est_item_price)).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }} </span>
                                      <span v-if="row.type != 'delete'" style='width:25%; padding-top: 5px; '> {{ (parseFloat(row.est_cogs)).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,") }} </span>
                                      <span v-if="row.type != 'delete'" style='width:10%; text-align:end; padding-top: 5px; '>
                                        <!-- <span style="position: absolute; right: 0px;">Add</span> -->
                                        <b-icon
                                          v-if="!formWeekly"
                                          @click="editItem(row, index)"
                                          scale="1.3"
                                          style="
                                            color: goldenrod;
                                            position: relative;
                                            right:20px;
                                          "
                                          icon="pencil-fill"
                                        ></b-icon>
                                        <b-icon
                                          v-if="!formWeekly"
                                          @click="removeItem(index)"
                                          scale="1.3"
                                          style="
                                            color: red;
                                            position: relative;
                                          "
                                          icon="trash-fill"
                                        ></b-icon>
                                      </span>
                                    </div>
                                    </b-row>
                                    <span
                                    id="warning-products"
                                    class="display-hidden text-small text-danger"
                                    >
                                    Required
                                    </span>
                                  </b-form-group>
                                </b-col>
                              </b-row>

                              <!-- GP Value -->
                              <b-row align-h="start">
                                <b-col sm="12">
                                  <b-form-group
                                    label="GP Value"
                                    label-for="GPValue"
                                    label-cols-sm="3"
                                    label-align-sm="left"
                                    label-size="sm"
                                  >
                                    <b-form-input
                                    v-model="GpValue"
                                    type="text"
                                    :readonly="true"
                                    trim
                                  ></b-form-input>
                                  </b-form-group>
                                </b-col>
                              </b-row>

                              <!-- GP Percent -->
                              <b-row align-h="start">
                                <b-col sm="12">
                                  <b-form-group
                                    label="GP %"
                                    label-for="GpPercent"
                                    label-cols-sm="3"
                                    label-align-sm="left"
                                    label-size="sm"
                                  >
                                    <b-form-input
                                    v-model="GpPercent"
                                    type="text"
                                    :readonly="true"
                                    trim
                                  ></b-form-input>
                                  </b-form-group>
                                </b-col>
                              </b-row>


                            </template>
                          </accordion-item>
                        </accordion>
                      </b-col>
                      </b-row>
                      <b-row align-h="end">
                        <b-col sm='3'>
                          <b-button block size="md" @click="checkValidateTable()" type='submit' variant="success">
                            Save
                          </b-button>
                        </b-col>
                        <b-col sm='3'>
                          <b-button block size="md" @click="cancel()" type='button' variant="warning">
                            Cancel
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </validation-observer>
                </div>
            </b-modal>
          </template>

          <template>
      <b-modal
        v-model="showModal"
        ok-variant="success"
        cancel-variant="warning"
        header-bg-variant="secondary"
        hide-footer
        hide-header-close
        hide-backdrop
        size="md"
        no-close-on-backdrop
        title="Add Product ? "
      >
        <div id="modal">
          <!-- Products -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Product *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <v-select
                    :clearable="false"
                    v-model="itemProducts"
                    @input="InputProduct"
                    :options="optionsProductCategory"
                    placeholder="Select Product"
                    label="value"
                  >
                    <template v-slot:option="option">
                      {{ option.category }} - {{ option.value }}
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.category }} - {{ option.value }}
                    </template>
                  </v-select>
                  <span
                    id="warning-item"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Qty Product -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Qty *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="form.itemQty"
                    type="number"
                    placeholder="Input Qty"
                    trim
                  ></b-form-input>
                  <span
                    id="warning-itemQty"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Est Price -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Est Price *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="itemPrice"
                    @change="ChangePrice"
                    placeholder="Input Price"
                    type="text"
                    @keypress="isNumber($event)"
                    trim
                  ></b-form-input>
                  <span
                    id="warning-itemPrice"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Est Cogs -->
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Est Cogs *"
                  label-class="text-primary"
                  label-cols="3"
                  label-cols-md="3"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="itemCogs"
                    @change="ChangeCogs"
                    placeholder="Input Cogs"
                    type="text"
                    @keypress="isNumber($event)"
                    trim
                  ></b-form-input>
                  <span
                    id="warning-itemCogs"
                    class="display-hidden text-small text-danger"
                  >
                    Required
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row align-h="end">
              <b-col sm="3">
                <b-button
                  block
                  size="md"
                  type="button"
                  variant="success"
                  @click="checkProduct()"
                >
                  Save
                </b-button>
              </b-col>
              <b-col sm="3">
                <b-button
                  block
                  size="md"
                  @click="cancelProduct()"
                  type="button"
                  variant="warning"
                >
                  Cancel
                </b-button>
              </b-col>
            </b-row>
        </div>
      </b-modal>
    </template>

          <br />
          <b-row align-h="center">
              <b-col md='6'>
                <b-button block size="md" v-if="buttonSubmit == null" @click="Confirm('save')" type='submit' variant="primary">
                  Submit
                </b-button>
                <b-button block size="md" v-if="buttonSubmit == 'edit'" @click="Confirm('edit')" type='submit' variant="success">
                  Submit
                </b-button>
              </b-col>
          </b-row>

        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import apiWeeklyPlanning from '@/store/salesforce-form/weekly-planning.js';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";
import { now } from 'moment';

import Accordion from "@/components/Accordion/accordion";
import AccordionItem from "@/components/Accordion/accordion-item";
import { fnPOST } from "../../../../api/api-methods";
import { APIPATH_SALES, APIPATH_SALESFORCE } from "../../../../api/api-paths";

export default {
  components: {
    ModelListSelect,
    Loader,
    Accordion,
    AccordionItem,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'numeric'};
    const NameMonth = { month: 'long'};
    const namemonth = now.toLocaleString('en-US', NameMonth)
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "Weekly Activity Plan Form",
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      minMonthDate:null,
      minEndDate:null,
      maxMonthDate:null,
      minMonthPeriod:null,
      maxMonthPeriod:null,
      NowDate: today,
      optionsStatusCustomer: [],
      optionsProductCategory: [],
      SelectActivity: [],
      ProjectID: null,
      dataItemProducts: [],
      itemProducts: null,
      itemPrice: null,
      itemCogs: null,
      GpValue: 0,
      GpPercent: 0,
      formWeekly:true,
      showModal:false,
      show:false,
      today:today,
      startDate:false,
      endDate:false,
      headerTitle:null,
      disabledForm:false,
      buttonSubmit:null,
      showHidden:true,
      today_days:days,
      today_month:month,
      NameMonth:namemonth,
      StrMonth:Strmonth,
      today_year:year,
      fields:[
          {key:'action', label:'Action', sortable:false },
          {key:'customer', label:'Customer ID', class:"display-hidden", sortable:false},
          {key:'group_nik', label:'Leader nik', class:"display-hidden", sortable:false},
          {key:'project_id', label:'project id', sortable:true},
          {key:'customer_name', label:'Customer Name', sortable:true},
          {key:'plan_date', label:'Plan Date', sortable:false},
          {key:'activity_name', label:'Plan', sortable:false},
          {key:'remark', label:'Remark', sortable:false},
          {key:'reason', label:'Reason', class:"", sortable:false},
      ],
      fields_customer:[
          {key:'action', label:'Action', sortable:false },
          {key:'id', label:'ID', class:"display-hidden" ,sortable:false },
          {key:'customer_name', label:'Customer Name', sortable:false},

      ],
      indexTabel:null,
      reasonActive:false,
      dataTable:[],
      dataTableEdit:[],
      groupNik:[],
      nikLeader:null,
      dataTableCustomer:[],
      dataTableCustomerEdit:[],
      options: {
        customer:[],
        activity:[],
        reason:[],
      },
      selectedCustomer:null,
      form :{
        id_transaction:null,
        employee_nik:null,
        employee_name:null,
        period:null,
        group_nik:null,
        selectedCustomer:null,
        customer_name:null,
        selectedCustomerId:null,
        selectedCustomerProject:null,
        industrial:null,
        transaction_id:null,
        est_employee:null,
        status:null,
        start_date:null,
        end_date:null,
        plan_date:null,
        plan_activity:null,
        remark:null,
        reasonActivity:null,

        estShipMonth: null,
        statusCustomer: null,
        itemProducts: null,
        categoryProducts: null,
        itemPrice: null,
        itemCogs: null,
        GpValue: 0,
        GpPercent: 0,

        idSalesProjection:null,
        customer_id:null,

      },

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState('sales_force', ['stDtWeekPln','stDtWeekPlnCount','stListCstmWeek','stListCstmWeekCount','stLstMnthPln','stLstMnthPlnCount','stActv','stActvCount','stRsn','stRsnCount','stDtWeekDtlPln','stDtWeekDtlPlnCount']),

  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions('general', ['fetchCityList']),
    ...mapActions('sales_force', ['getDataWeeklyPlanning','getDataListCustomerWeekly','getLastMonthlyPlanning','getActivityPlan','getReasonActivity','getDataWeeklyPlanningDetail','getListSalesDetail']),

    deleteAll() {
      this.dataTable = []
    },
    setData() {
      fnPOST(APIPATH_SALESFORCE.masterStatusCustomer, {})
      .then((rsp) => {
        if (rsp.data.payload) {
          this.optionsStatusCustomer = rsp.data.payload;
        }
      })
      .catch((err) => {
        this.showNotification({ type: "error", message: err });
      });

      fnPOST(APIPATH_SALESFORCE.masterProductCategory, {})
      .then((rsp) => {
        if (rsp.data.payload) {
          this.optionsProductCategory = rsp.data.payload;
        }
      })
      .catch((err) => {
        this.showNotification({ type: "error", message: err });
      });


      this.getListSalesDetail({
        sales:this.userData.nik
      }).then(rsp=>{
        if (rsp == 'No Data') {
          this.showNotification({type:'info', message:'User Sales belum terdaftar di group'})
          this.$router.push({name:"SFCWeeklyActivityPlan"})
        } else {
          this.nikLeader = rsp.payload[0].leader_nik
        }
      })
      this.form.employee_nik    = this.userData.nik
      this.form.employee_name   = this.userData.fullname
      let statusRoute           = this.$route.params.page
      let idData                = this.$route.params.idData
      let lastDate              = new Date(this.today_year, this.today_month, 0);
      lastDate                  = String(lastDate.getDate())
      let yearMonth             = this.today_year+"-"+this.today_month
      this.minMonthDate         = this.today_year+"-"+this.today_month+"-"+"01"
      this.maxMonthDate         = this.today_year+"-"+this.today_month+"-"+lastDate
      if (statusRoute == undefined || statusRoute =='' ) {
        statusRoute = null
      }
      if (this.$route.params.page == 'view') {
          this.headerTitle = 'View'
          this.buttonSubmit = 'view'
          this.disabledForm = true
          // this.showHidden = false;
      }
      if (this.$route.params.page == 'edit') {
          this.buttonSubmit = 'edit'
      }

      if (statusRoute == 'edit' || statusRoute== 'view') {

        // start loading
        this.isLoadingData = true

        this.getDataWeeklyPlanning({
          idData:idData,
          })
          .then(rsp => {
              if(this.stDtWeekPlnCount == 0) {
                  this.showNotification({type:'info', message:'Data Weekly Not Found'})
              }
              this.getDataWeeklyPlanningDetail({
                idData:idData,
              })
              .then(rsp => {
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
                if(this.stDtWeekPlnCount == 0) {
                  this.showNotification({type:'info', message:'Data Weekly Not Found'})
                }
                this.setDataForm()
              })
              .catch(err => {
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data Detail, Silahkan Coba Kembali'})
                    this.$router.push({name:"SFCWeeklyActivityPlan"})
                }
              })

          })
          .catch(err => {
            // stop loading
            setTimeout(() => {
              this.isLoadingData = false
            }, 500);
              if(err.response.status !== 401) {
                  this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                  this.$router.push({name:"SFCWeeklyActivityPlan"})
              }
          })

      } else {
        this.headerTitle = 'New'
        this.$set(this.form, 'period', yearMonth)
        this.getLastMonthlyPlanning({
        sales:this.form.employee_nik,
        })
        .then(rsp => {
          if(this.stLstMnthPlnCount == 0) {
              // this.showNotification({type:'info', message:'Data Not Found'})
          }
          this.checkPeriod()
        })
        .catch(err => {
          if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data Monthly, Silahkan Coba Kembali'})
              this.$router.push({name:"SFCWeeklyActivityPlan"})
          }
        })


        let StrMonth = this.StrMonth
        if (this.StrMonth.length == 1) {
          StrMonth = "0"+this.StrMonth
        }
        let Stryear = this.today_year.substring(2,4)
        this.form.id_transaction = 'WAP/'+Stryear+"/"+StrMonth+"/XXXX"
        this.form.transaction_id = 'WAP/'+Stryear+"/"+StrMonth
      }
    },
    removeItem(index) {
      let Boxtrue = ''
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to remove ? ", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: 'danger',
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          Boxtrue = value;
          if (Boxtrue == true) {
            if (this.dataItemProducts[index].type == 'create') {
              this.dataItemProducts.splice(index, 1)
            } else {
              this.dataItemProducts[index].type = 'delete'
            }
            this.calculateGP()
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    isNumber: function (evt) {
      if (!/^[0-9]+$/.test(evt.key) || evt.key === ".")
        return evt.preventDefault();
      if (evt.keyCode === 46) {
        evt.preventDefault();
      }
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    loadSalesProjection() {
      this.form.idSalesProjection = null
      this.form.estShipMonth = null
      this.form.statusCustomer = null
      this.form.GpValue = null
      this.form.GpPercent = null
      this.GpValue = null
      this.GpPercent = null

      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.getSalesProjection, {
        project_id:this.ProjectID,
        customer_id:this.form.customer_id,
      })
      .then((rsp) => {
        this.isLoadingData = false;
        if (rsp.data.payload) {
          let data = rsp.data.payload
          if (data.length > 0) {
            this.formWeekly             = true

            this.form.idSalesProjection = data[0].id
            this.form.estShipMonth      = data[0].est_shipment_month.substring(0, 7);
            // console.log(this.form.estShipMonth)
            this.form.statusCustomer    = data[0].status_customer
            this.form.GpValue           = data[0].gp_value
            this.form.GpPercent         = data[0].gp_percent
            let gpValue                 = parseFloat(data[0].gp_value)
            let gpPercent               = parseFloat(data[0].gp_percent)
            this.GpValue                = (gpValue.toFixed(2)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            this.GpPercent              = (gpPercent.toFixed(1)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            this.loadSalesProjectionDetail()
          }
        }
      })
      .catch((err) => {
        this.isLoadingData = false;
        this.showNotification({ type: "error", message: err });
      });
    },
    loadSalesProjectionDetail() {
      this.dataItemProducts = []
      fnPOST(APIPATH_SALESFORCE.getSalesProjectionDetail, {
        sales_projection:this.form.idSalesProjection
      })
      .then((rsp) => {
        this.isLoadingData = false;
        // console.log(rsp)
        // let dataDetail = rsp.data.payload
        if (rsp.data.payload) {
          this.dataItemProducts = rsp.data.payload
        }
      })
      .catch((err) => {
        this.isLoadingData = false;
        this.showNotification({ type: "error", message: err });
      });

    },
    editItem(value, index) {
      this.showModal = true;
      this.itemProducts = value.category+'-'+value.item
      this.form.itemProducts = value.item;
      this.form.categoryProducts = value.category;
      this.form.itemQty = value.qty;
      this.itemPrice = value.est_item_price
      this.form.itemPrice = value.est_item_price;
      this.itemCogs = value.est_cogs;
      this.form.itemCogs = value.est_cogs;
      this.editProduct = true
      this.index = index
    },
    AddItem() {
      this.showModal = true;
      this.itemProducts = null;
      this.form.itemProducts = null;
      this.form.categoryProducts = null;
      this.form.itemQty = null;
      this.itemPrice = null;
      this.form.itemPrice = null;
      this.itemCogs = null;
      this.form.itemCogs = null;
      this.editProduct = false
      this.index = null
    },
    cancelProduct() {
      this.showModal = false;
      this.itemProducts = null;
      this.form.itemProducts = null;
      this.form.categoryProducts = null;
      this.form.itemQty = null;
      this.itemPrice = null;
      this.form.itemPrice = null;
      this.itemCogs = null;
      this.form.itemCogs = null;
      this.editProduct = false
      this.index = null
    },
    checkProduct() {
        if (this.form.itemProducts == null || this.form.itemProducts == "" ||this.form.itemProducts.substring(0, 1) == " ") {
          document.getElementById("warning-item").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-item").classList.add("display-hidden");
            }, 5000);
          return false
        }
        if (this.form.itemQty == null || this.form.itemQty == "" || this.form.itemQty == isNaN )  {
          document.getElementById("warning-itemQty").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-itemQty").classList.add("display-hidden");
            }, 5000);
          return false
        }
        if (this.form.itemPrice == null || this.form.itemPrice == "" || this.form.itemPrice == isNaN )  {
          document.getElementById("warning-itemPrice").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-itemPrice").classList.add("display-hidden");
            }, 5000);
          return false
        }
        if (this.form.itemCogs == null || this.form.itemCogs == "" || this.form.itemCogs == isNaN )  {
          document.getElementById("warning-itemCogs").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("warning-itemCogs").classList.add("display-hidden");
            }, 5000);
          return false
        }
        // console.log(this.form.itemCogs)
        // console.log(this.form.itemPrice)
        if (this.form.itemCogs >= this.form.itemPrice) {
          this.showNotification({ type: "info", message: 'Est Cogs more than Est Price' });
          return false
        }
        if (this.editProduct) {
          this.editDataProduct(this.index)
        } else {
          this.submitProduct()
        }

    },
    submitProduct() {
      let data = {
        item:this.form.itemProducts,
        category:this.form.categoryProducts,
        qty:this.form.itemQty,
        est_item_price:this.form.itemPrice,
        est_cogs:this.form.itemCogs,
        type:'create'
      }
      this.dataItemProducts.push(data)
      // console.log(this.dataItemProducts)
      this.cancelProduct()
      this.calculateGP()
    },

    InputProduct() {
      // console.log(this.itemProducts)
      if (this.itemProducts) {
        this.form.itemProducts = this.itemProducts.value;
        this.form.categoryProducts = this.itemProducts.category;
        // console.log(this.form.itemProducts)
        // console.log(this.form.categoryProducts)
      }
    },
    editDataProduct(index) {
      // console.log(index)
      // console.log(this.dataItemProducts)
      // console.log(this.form.itemProducts)
      this.dataItemProducts[index].item = this.form.itemProducts
      this.dataItemProducts[index].category = this.form.categoryProducts
      this.dataItemProducts[index].qty = this.form.itemQty
      this.dataItemProducts[index].est_item_price = this.form.itemPrice
      this.dataItemProducts[index].est_cogs = this.form.itemCogs
      if (this.dataItemProducts[index].type != 'create'){
        this.dataItemProducts[index].type = 'update'
      }

      // console.log(this.dataItemProducts)
      this.cancelProduct()
      this.calculateGP()
    },
    ChangePrice() {
      if (this.itemPrice) {
        this.form.itemPrice = parseFloat(this.itemPrice)
        this.itemPrice = parseFloat(this.itemPrice)
          // .toString()
          // .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      // console.log(this.form.itemPrice)
      // console.log(this.itemPrice)
    },
    ChangeCogs() {
      if (this.itemCogs) {
        this.form.itemCogs = parseFloat(this.itemCogs)
        this.itemCogs = parseFloat(this.itemCogs)
          // .toString()
          // .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      // console.log(this.form.itemCogs)
      // console.log(this.itemCogs)
    },
    calculateGP() {
      let gp_value = 0
      let gp_percent = 0
      let total_price = 0
      let total_cogs = 0
      if (this.dataItemProducts) {
        for (let a=0; a<this.dataItemProducts.length; a++) {
          // console.log(this.dataItemProducts[a].qty)
          // console.log(this.dataItemProducts[a].est_item_price)
          // console.log(this.dataItemProducts[a].est_cogs)
          if (this.dataItemProducts[a].type != 'delete'){
            gp_value += this.dataItemProducts[a].qty * (this.dataItemProducts[a].est_item_price - this.dataItemProducts[a].est_cogs)
            total_price += this.dataItemProducts[a].est_item_price * this.dataItemProducts[a].qty
            total_cogs += this.dataItemProducts[a].est_cogs * this.dataItemProducts[a].qty
            gp_percent = (gp_value / total_price) * 100
          }
        }
      }
      this.form.GpValue = gp_value
      this.form.GpPercent = gp_percent.toFixed(2)
      this.GpValue = (gp_value.toFixed(2)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      this.GpPercent = (gp_percent.toFixed(2)).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");

    },
    LoadListActivity(customer, project_id) {
      this.options.activity = []
      this.getReasonActivity({
        filter:'filter',
        id:customer,
        project_id:project_id,
      })
      .then(rsp => {
        if(rsp == 'No Data') {
            this.showNotification({type:'info', message:'Activity data not found'})
        }
        for (let r=0; r<=rsp.payload.length-1; r++) {
          this.options.activity.push(
              { id:rsp.payload[r].id, name: rsp.payload[r].name, need_reason:rsp.payload.need_reason, disabled: false},
          );
        }
      })
    },
    LoadListData(){
      let group_nik = null
      let statusGroup = 'list_customer'
      if (this.buttonSubmit == 'edit') {
        group_nik = this.groupNik
        if (this.groupNik !== this.nikLeader) {
          statusGroup = 'group_nik'
        } else {
          statusGroup = 'dept_group'
        }
      }
      let period = this.form.period.substring(0,7)
      if (this.$route.params.page == 'edit' || this.$route.params.page == 'view') {
        // console.log(this.$route.params.page)
      } else {
        // console.log(this.$route.params.page)
       this.getDataListCustomerWeekly({
        sales_nik:this.form.employee_nik,
        period_date:period,
      }).then(rsp => {
        this.dataTable = []
        if (rsp != 'No Data') {
          for (let a=0; a<=rsp.length-1;a++){
            let group_nik = rsp[a].group_nik
            if (rsp[a].group_nik == undefined || rsp[a].group_nik == '' || rsp[a].group_nik == null){
                group_nik = this.nikLeader
            }
            if (!this.groupNik.includes(group_nik)) {
              this.groupNik.push(group_nik)
            }
            this.dataTable.push({
              project_id:rsp[a].project_id,
              customer:rsp[a].customer,
              customer_name: rsp[a].customer_name,
              activity_name: rsp[a].last_activity,
              plan_date: null,
              group_nik: rsp[a].group_nik,
              remark: null,
              reason:null,
              action:'create'
            })
          }
        }
      })
      }

      this.dataTableCustomer = []
      this.options.customer = []

      // start loading
      this.isLoadingData = true

      this.getDataListCustomerWeekly({
        sales_nik:this.form.employee_nik,
        period_date:period,
        status:statusGroup,
        group_nik:group_nik,
      }).then(rsp => {
        this.dataTableCustomer = []
        this.options.customer = []
        if (rsp != 'No Data') {
          for (let r=0; r<=rsp.length-1; r++) {
            this.dataTableCustomer.push(
                { project_id:rsp[r].project_id ,id:rsp[r].customer, customer_name: rsp[r].customer_name, inds:rsp[r].industrial, estEm:rsp[r].est_employee, status:rsp[r].status, group_nik:rsp[r].group_nik, disabled: false},
            );
            this.options.customer.push(
                { project_id:rsp[r].project_id ,cst_id:rsp[r].customer ,id:r, name: rsp[r].customer_name, inds:rsp[r].industrial, estEm:rsp[r].est_employee, status:rsp[r].status, group_nik:rsp[r].group_nik, disabled: false},
            );
          }
        }
      })
      .catch(err => {
        if(err.response.status !== 401) {
          this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
        }
      }).finally(()=>{
        // stop loading
        setTimeout(() => {
          this.isLoadingData = false
        }, 500);
      })
    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
        return ' '
      } else {
        let date = new Date(value)
        let nameDay = this.dayNames[date.getDay()];
        let day = date.getDate()
        if (String(day).length == 1) {
          day = "0"+String(day)
        }
        let year = date.getFullYear()
        let bulan = this.monthNames[date.getMonth()]
        date = nameDay+' '+day+' '+bulan+' '+year
        return date
      }
    },
    checkPeriod() {
      let FormLast = this.stLstMnthPln
      this.form.period = this.today
      this.minMonthPeriod = this.today

      this.form.period = FormLast[0].period_date
      this.NameMonth = FormLast[0].period_date
      this.maxMonthPeriod = FormLast[0].period_date

      this.form.period = this.form.period.slice(0,7)
      this.NameMonth = this.NameMonth.slice(0,7)
      this.ChangeTransaction()
    },
    ChangeReasonActivity(item) {
      this.form.plan_activity = item.name
      this.options.reason = []
      this.getReasonActivity({
        id:item.id,
      })
      .then(rsp => {
        for (let r=0; r<=this.stRsnCount-1; r++) {
          this.options.reason.push(
              { id:this.stRsn[r].id, name: this.stRsn[r].reason, disabled: false},
          );
        }
        if (this.stRsnCount > 0 && item.need_reason == true) {
          this.reasonActive = true
          document.getElementById("reasonActivity").classList.remove(
              "display-hidden"
          );
        }
        if (this.stRsnCount == 0 || item.need_reason == false) {
          this.reasonActive = false
          this.form.reasonActivity = null
          document.getElementById("reasonActivity").classList.add(
              "display-hidden"
          );
        }
      })
    },
    setDataForm() {
      let FormData = this.stDtWeekPln
      let FormDataDetail = this.stDtWeekDtlPln
      this.form.period = FormData[0].period_date
      this.form.employee_nik = FormData[0].sales_nik
      this.form.employee_name = FormData[0].sales_name
      this.form.customer_name = FormData[0].customer_name
      this.form.start_date = FormData[0].start_date
      this.form.end_date = FormData[0].end_date
      this.form.transaction_id = FormData[0].transaction_id
      this.form.id_transaction = FormData[0].transaction_id
      this.groupNik = FormData[0].group_nik
      this.dataTable = FormDataDetail
      if (this.$route.params.page == 'edit' || this.$route.params.page == 'view') {
        this.LoadListData()
      }
    },
    ActivityReplace(value) {
      let string = value
      if (value !== null) {
        string = value.replace(/[^a-zA-Z0-9,()/& ]/g, "");
      }
      return string
    },
    ChangeMinEndDate() {
      this.form.end_date = null
      let someDate = new Date(this.form.start_date)
      let numberOfDaysToAdd = 1;
      let result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
      result = new Date(result)
      let day = result.getDate()
      if (String(day).length == 1) {
        day = "0"+String(day)
      }
      let month = result.getMonth()+1
      if (String(month).length == 1) {
        month = "0"+String(month)
      }
      let year = result.getFullYear()
      let mindate = String(year)+"-"+String(month)+"-"+String(day)

      this.minEndDate = mindate
      this.form.end_date = mindate
    },
    AddWeekActivity() {
      if (this.form.start_date == null) {
        document.getElementById("startDateWarning").classList.remove("display-hidden");
        this.$refs.startDate.$el.classList.value =
            this.$refs.startDate.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.startDate.$el.classList.remove(
              "animation-shake"
            );
            document.getElementById("startDateWarning").classList.add("display-hidden");
          }, 5000);
          return false
      }
      if (this.form.end_date == null) {
        document.getElementById("endDateWarning").classList.remove("display-hidden");
        this.$refs.endDate.$el.classList.value =
            this.$refs.endDate.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.endDate.$el.classList.remove(
              "animation-shake"
            );
            document.getElementById("endDateWarning").classList.add("display-hidden");
          }, 5000);
          return false
      }
      this.show = true
      this.form.plan_date = this.form.start_date
      this.form.estShipMonth = null
      this.form.statusCustomer = null
      this.dataItemProducts = []
      this.formWeekly = true
      this.ProjectID = true
      this.form.customer_id = null
      this.form.GpValue = null
      this.form.GpPercent = null
      this.GpValue = null
      this.GpPercent = null
    },
    DisableStartDate() {
      let finish = false
      let len = 0
      if (this.dataTable == null || this.dataTable == undefined) {
        finish = false
      } else {
        len   = this.dataTable.length
      }
      if (len > 0 ) {
        for (let a=0; a<=len-1;a++) {
          if (this.dataTable[a].plan_date !== null ) {
            finish = true
          }
        }
      }
      return finish
    },
    DisableEndDate() {
      let finish = false
      let len = 0
      if (this.dataTable == null || this.dataTable == undefined) {
        finish = false
      } else {
        len = this.dataTable.length
      }
      if (len > 0) {
        if (this.form.start_date == null) {
          finish = false
        } else {
          for (let a=0; a<=len-1;a++) {
            if (this.dataTable[a].plan_date !== null ) {
              finish = true
            }
          }
        }
      }
      return finish
    },
    ViewCustomer(value) {
      let data = value
      this.$router.push({ name: 'SFCMasterCustomerForm', params: { BackHistory:'WeeklyPlanningForm' ,page:'view', idData:data, IDHistory:this.$route.params.idData } })
    },
    ViewDetailCustomer(value) {
      this.showNotification({type:'info', message:'Under Construction, Content Will Be Available Soon'})
    },
    ChangeTransaction() {
      if (this.form.period == null || this.form.period == undefined) {
        this.showNotification({type:'error', message:'Problem occurred in the system. Please try again. If the problem persists, contact your system Administrator'})
      } else {

        this.form.start_date = new Date(this.form.period);
        this.form.end_date   = new Date(this.form.period);
        this.form.end_date   = this.form.end_date.setDate(this.form.end_date.getDate() + 1);
        this.form.end_date   = new Date(this.form.end_date);

        let dateNew   = new Date(this.form.period)
        let month     = dateNew.toLocaleString('default', { month: 'numeric' })
        let year      = dateNew.toLocaleString('default', { year: 'numeric' })

        let StrMonth = month
        if (month.length == 1) {
          StrMonth = "0"+month
        }
        let Stryear = year.substring(2,4)

        this.form.transaction_id = 'WAP/'+Stryear+"/"+StrMonth
        this.form.id_transaction = 'WAP/'+Stryear+"/"+StrMonth+"/XXXX"
        this.minMonthDate         = year+"-"+month+"-"+"01"
        let lastDate              = new Date(year, month, 0);
        lastDate                  = String(lastDate.getDate())
        this.maxMonthDate         = year+"-"+month+"-"+lastDate
        let thisMonth = new Date().getMonth()
        let nowMonth = new Date(this.form.period).getMonth()
        this.minEndDate = this.form.end_date
        if (thisMonth == nowMonth) {
          let mindate = new Date(this.today)
          this.minMonthDate = new Date(this.today)
          this.minEndDate = mindate.setDate(mindate.getDate() + 1);
          this.form.start_date = new Date(this.today)
          this.form.end_date   = new Date(this.minEndDate)
        }
        this.form.start_date = this.form.start_date.toISOString().slice(0,10)
        this.form.end_date = this.form.end_date.toISOString().slice(0,10)
      }
      this.LoadListData()

    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onChange (item) {
      this.form.selectedCustomerId = item.cst_id
      this.form.selectedCustomer = item.project_id+' - '+item.name
      this.selectedCustomer = item.name
      this.form.selectedCustomerProject = item.project_id
      this.form.group_nik = item.group_nik
      this.LoadListActivity(item.cst_id, item.project_id)
      this.formWeekly = false
      this.form.customer_id = item.cst_id
      this.ProjectID = item.project_id
      this.loadSalesProjection();
    },
    deleteData(value) {
      console.log(value)
      console.log(this.buttonSubmit)
      console.log(this.groupNik)
      console.log(this.dataTable)
      console.log(this.dataTableEdit)
      if (this.buttonSubmit == 'edit') {
        this.dataTableEdit.push({
            customer:value.item.customer,
            customer_name: value.item.customer_name,
            project_id:value.item.project_id,
            industrial: value.item.industrial,
            est_employee: value.item.est_employee,
            status: value.item.status,
            group_nik:value.item.group_nik,
            action:'delete'
          })
      }
      if (this.buttonSubmit != 'edit') {
        this.groupNik.splice(value.item.group_nik)
      }
      this.dataTable.splice(value.index, 1)

    },
    cancel() {
      this.indexTabel = null
      this.form.selectedCustomer = null
      this.selectedCustomer = null
      this.form.selectedCustomerId = null
      this.form.selectedCustomerProject = null
      this.form.plan_date = null
      this.form.group_nik = null
      this.form.plan_activity = null
      this.form.remark = null
      this.form.reasonActivity = null
      document.getElementById("reasonActivity").classList.add(
          "display-hidden"
      );
      this.show = false
      this.showModal = false
      this.formWeekly = true
      // this.ProjectID = null
      // this.cusotmer_id = null
    },
    checkValidateTable() {
      let lenTable = this.dataTable.length
      if (this.form.selectedCustomer == null ) {
        document.getElementById("Warrning-Customer").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("Warrning-Customer").classList.add("display-hidden");
          }, 5000);
          return false
      }
      if (this.form.plan_activity == null ) {
        document.getElementById("Warrning-Plan").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("Warrning-Plan").classList.add("display-hidden");
          }, 5000);
          return false
      }
      if (this.form.plan_date == null ) {
        document.getElementById("Warrning-Date").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("Warrning-Date").classList.add("display-hidden");
          }, 5000);
          return false
      }
      if (this.reasonActive == true ) {
        if (this.form.reasonActivity == null) {
          document.getElementById("Warrning-Reason").classList.remove("display-hidden");
            setTimeout(() => {
              document.getElementById("Warrning-Reason").classList.add("display-hidden");
            }, 5000);
            return false
          }
      }
      for (let a=0; a<=lenTable-1; a++) {
        if (this.dataTable[a].customer == this.form.selectedCustomerId) {
          if (this.dataTable[a].plan_date == this.form.plan_date) {
            if (this.dataTable[a].activity_name == this.form.plan_activity) {
              if (this.dataTable[a].project_id == this.form.selectedCustomerProject) {
                this.showNotification({type:'error', message:`Gagal menyimpan data <br/> "${this.form.plan_activity}" ke Customer "${this.selectedCustomer}" pada tanggal "${this.form.plan_date}" sudah ada. Silahkan cek kembali inputan anda`})
                return false
              }
            }
          }
        }
      }

      if (this.form.estShipMonth == null || this.form.estShipMonth == '' || this.form.estShipMonth == undefined) {
        document.getElementById("warning-shipment").classList.remove("display-hidden");
        document.getElementById("warning-sales_projection").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("warning-shipment").classList.add("display-hidden");
            document.getElementById("warning-sales_projection").classList.add("display-hidden");
          }, 5000);
        return false
      }
      if (this.form.statusCustomer == null || this.form.statusCustomer == '' || this.form.statusCustomer == undefined) {
        document.getElementById("warning-projectStatus").classList.remove("display-hidden");
        document.getElementById("warning-sales_projection").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("warning-projectStatus").classList.add("display-hidden");
            document.getElementById("warning-sales_projection").classList.add("display-hidden");
          }, 5000);
        return false
      }
      if (this.dataItemProducts == [] || this.dataItemProducts == '' || this.dataItemProducts.length == undefined) {
        document.getElementById("warning-products").classList.remove("display-hidden");
        document.getElementById("warning-sales_projection").classList.remove("display-hidden");
          setTimeout(() => {
            document.getElementById("warning-products").classList.add("display-hidden");
            document.getElementById("warning-sales_projection").classList.add("display-hidden");
          }, 5000);
        return false
      }
      this.submitAddTable()
    },
    EditModal(index,value){
      this.form.selectedCustomerId = value.customer
      this.form.selectedCustomer = value.project_id+' - '+value.customer_name
      this.selectedCustomer = value.customer_name
      this.selectedCustomerProject = value.project_id
      this.form.plan_activity = value.activity_name
      this.form.plan_date = value.plan_date
      this.form.remark = value.remark
      this.form.reasonActivity = value.reason
      this.form.group_nik = value.group_nik
      this.indexTabel = index
      this.show = true
      this.LoadListActivity(value.customer, value.project_id)
      this.ProjectID = value.project_id
      this.form.customer_id = value.customer
      this.loadSalesProjection()
      this.formWeekly = false
    },
    submitAddTable() {
      if (this.form.plan_activity.includes("[") && this.form.plan_activity.includes("]")) {
        this.showNotification({type:'info', message:'"Plan Activity" tidak boleh lebih dari 1 plan activity'})
        return false
      }

      if (this.indexTabel !== null) {
        this.dataTable[this.indexTabel].customer = this.form.selectedCustomerId
        this.dataTable[this.indexTabel].customer_name = this.selectedCustomer
        this.dataTable[this.indexTabel].project_id = this.selectedCustomerProject
        this.dataTable[this.indexTabel].activity_name = this.form.plan_activity
        this.dataTable[this.indexTabel].plan_date = this.form.plan_date
        this.dataTable[this.indexTabel].remark = this.form.remark
        this.dataTable[this.indexTabel].reason = this.form.reasonActivity
      }
      let group_nik = this.form.group_nik
      if (this.form.group_nik == undefined || this.form.group_nik == '' || this.form.group_nik == null){
          group_nik = this.nikLeader
      }
      if (!this.groupNik.includes(group_nik)) {
        this.groupNik.push(group_nik)
      }
      if (this.indexTabel == null) {
        this.dataTable.push({
          customer:this.form.selectedCustomerId,
          customer_name: this.selectedCustomer,
          project_id:this.form.selectedCustomerProject,
          activity_name : this.form.plan_activity,
          plan_date: this.form.plan_date,
          remark: this.form.remark,
          reason: this.form.reasonActivity,
          group_nik:group_nik,
          action:'create'
        });
      }
      this.DisableStartDate()
      this.DisableEndDate()

      this.saveSalesProjection()

      console.log(this.dataTable)
      console.log(this.dataTableEdit)
    },
    CheckDataTable(value) {
      if (this.dataTable.length > 0) {
        let len = this.dataTable.length
        for (let a=0;a<=len-1;a++) {
          if (this.dataTable[a].plan_date == null || this.dataTable[a].plan_date == undefined || this.dataTable[a].plan_date == '') {
            this.showNotification({type:'info', message:'"Plan Date" Table masih ada yang kosong, harap di isi terlebih dahulu'})
            return false
          }
        }
      }
      if (value == 'edit') {
          this.EditWeeklyPlanning()
      }
      if (value == 'save') {
        this.SaveWeeklyPlanning()
      }
    },
    Confirm(choice, index) {
      let varOk = 'danger'
      if(choice !== 'delete') {
        varOk = 'success'
      }
      let Boxtrue = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to '+choice+' ? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: varOk,
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          Boxtrue = value
          if (Boxtrue == true) {
            if (choice == 'delete') {
              this.deleteData(index)
            }
            if (choice == 'edit') {
              this.dataTableEdit = [...new Set([...this.dataTable, ...this.dataTableEdit])]
              if (this.dataTableEdit.length == 0) {
                this.showNotification({type:'info', message:'"Data Table kosong, harap di isi terlebih dahulu'})
                return
              }
              this.CheckDataTable(choice)

            }
            if (choice == 'save') {
               if (this.dataTable.length < 1) {
                  this.showNotification({type:'info', message:'"Date Table" masih ada yang kosong, harap di isi terlebih dahulu'})
                  return false
               }
              this.CheckDataTable(choice)
            }
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    saveSalesProjection() {
      // start loading
      this.isLoadingData = true
      fnPOST(APIPATH_SALESFORCE.saveSalesProjection, {
          estShipMonth:this.form.estShipMonth,
          projectStatus:this.form.statusCustomer,
          dataItemProducts:this.dataItemProducts,
          gpValue:this.form.GpValue,
          gpPercent:this.form.GpPercent,
          customer: this.form.customer_id,
          project_id: this.ProjectID,
          created_by: this.userData.nik,
          created_by_name: this.userData.fullname,

      })
      .then(response => {
        // console.log(response.data)
        this.showNotification({type:'success', message:'Successfully Submitted'})
        this.cancel()
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
          this.isLoadingData = false
        }, 500);
      })
    },
    SaveWeeklyPlanning() {
      if (this.dataTable.length < 1) {
        this.showNotification({type:'info', message:'Data masih kosong, harap untuk di isi'})
        return
      }
      // start loading
      this.isLoadingData = true
      apiWeeklyPlanning.dispatch('PostWeeklyPlanning',{
          sales_nik:this.form.employee_nik,
          transaction_id:this.form.transaction_id,
          sales_name:this.form.employee_name,
          group_nik:this.groupNik,
          period_date:this.form.period,
          start_date:this.form.start_date,
          end_date:this.form.end_date,
          approval_status:'waiting_approval',
          planning_detail:this.dataTable,
      })
      .then(response => {
        // console.log(response.data)
        if (response.data.message == "Warning") {
          this.showNotification({type:'error', message:response.data.payload})
        } else {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCWeeklyActivityPlan"})
        }
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
          this.isLoadingData = false
        }, 500);
      })
    },
    EditWeeklyPlanning() {
      // start loading
      this.isLoadingData = true
      let idData = this.$route.params.idData
      apiWeeklyPlanning.dispatch('EditWeeklyPlanning',{
          sales_nik:this.form.employee_nik,
          group_nik:this.groupNik,
          transaction_id:this.form.transaction_id,
          sales_name:this.form.employee_name,
          period_date:this.form.period,
          approval_status:'waiting_approval',
          planning_detail:this.dataTableEdit,
          id :idData,
      })
      .then(response => {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCWeeklyActivityPlan"})
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=>{
        // stop loading
        setTimeout(() => {
          this.isLoadingData = false
        }, 500);
      })
    },
  },

  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
<style>
.accordion__item {
  padding: 0px !important;
  padding-bottom: 10px !important;
}
</style>