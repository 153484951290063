import { resolve } from 'core-js/fn/promise'
import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiSupplier,
    fnApiPostSupplier,
    fnApiFetchMasterSupplier,
    fnApiPostMasterSupplier,
    fnApiPutMasterSupplier,
    fnApiDeleteMasterSupplier,
}from '../../api/api-aplications-product'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        ST_SUPPLIER_LIST:[],

    }
}

const state = getDefaultState()

const getters = {
    SUPPLIER_DASHBOARD(state){
        return state.ST_SUPPLIER_LIST
    }
}

const mutations = {
    GET_SUPPLIER(state,item){
        state.ST_SUPPLIER_LIST = item
    }
}

const actions = {
    GET_MASTER_SUPPLIER(context){
        return new Promise((resolve,reject)=>{
            fnApiFetchMasterSupplier()
            .then(rsp =>{
                resolve(rsp.data.payload)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    POST_MASTER_SUPPLIER(context,masterSupplier){
        return new Promise((resolve,reject)=>{
            fnApiPostMasterSupplier(masterSupplier)
            .then(rsp=>{
                resolve(rsp.data.payload)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    PUT_MASTER_SUPPLIER(context,masterSupplier){
        return new Promise((resolve,reject)=>{
            fnApiPutMasterSupplier(masterSupplier)
            .then(rsp=>{
                resolve(rsp.data.payload)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },
    DELETE_MASTER_SUPPLIER(context,masterSupplier){
        return new Promise((resolve,reject)=>{
            fnApiDeleteMasterSupplier(masterSupplier)
            .then(rsp=>{
                resolve(rsp.data.payload)
            })
            .catch(err=>{
                reject(err)
            })
        })
    },

    GET_SUPPLIER({commit}){
        return new Promise((resolve,reject)=>{
            fnApiSupplier()
            .then(rsp => {
                resolve(rsp.data.payload)
                // commit('GET_SUPPLIER',rsp.data.payload)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    POST_SUPPLIER(context, supplierSelection){
        return new Promise((resolve,reject)=>{
            fnApiPostSupplier(supplierSelection)
            .then(rsp => {
                resolve(rsp)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
}
export default new Vuex.Store({
    namespaced: true,
    actions
})