<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link tag="a" to="/app/appsplm-size-spec"> Size Spec</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>{{formName}}</b-breadcrumb-item>

            
        </b-breadcrumb>
        <b-container fluid>
            <b-card>
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Size Spec</h4>
                                <p class="text-muted" >(All measurement in inches)</p>
                                <b-button variant="dark" size="sm" v-on:click="refreshData()">Refresh</b-button>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <template v-if="roles">
                            <div class="text-right">
                                <b-button variant="success" size="sm" v-on:click="createData()">Add Size</b-button>
                            </div>
                            </template>
                            
                        </b-col>
                        
                    </b-row>
                </template>
                <b-table
                    striped 
                    hover
                    show-empty
                    responsive
                    :fields="fields"
                    :items="dataSize"
                >
                    <template v-slot:cell(action)="data">
                        <template  v-if="roles">
                        <b-button title="Detail" size="xs" variant="danger" v-on:click="deleteDetail(data.index)">
                            <b-icon icon="trash" aria-label="Help"></b-icon>
                            Delete
                        </b-button>
                        </template>
                    </template>
                </b-table>
                <b-modal
                    hide-footer
                    v-model="show"
                    hide-backdrop
                    
                    v-on:cancel="hideModal()"
                    v-on:close="hideModal()"
                    ok-title="Save"
                    size="lg"
                    ref="modal"
                    no-close-on-backdrop
                    scrollable 
                    title="Create Size Form"
                >
                    <div id="modal">
                        <validation-observer ref="observer" v-slot="{handleSubmit}">
                            <b-form @submit.prevent="handleSubmit(onSubmit)" >
                                
                                        <!-- Description -->
                                            <validation-provider
                                                name="description"
                                                :rules="{ required: true }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Description
                                                    </template>
                                                    <b-form-select value-field="value"
                                                                        text-field="value" @change="setTol()"   id="inputDescription" v-model="form.description" :options="descriptionOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                                    <b-form-invalid-feedback id="inputDescription">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Description -->
                                        <!-- TolMin -->
                                            <validation-provider
                                                name="TolMin"
                                                :rules="{ required: false }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Tol(-)<code>*</code>
                                                    </template>
                                                    <!-- <b-form-select value-field="value"
                                                                        text-field="value"  id="inputTolMin" v-model="form.tolMin" :options="setTolMin" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                                                        
                                                    <b-form-input readonly id="inputTolMin" v-model="form.tolMin" :state="getValidationState(validationContext)" trim></b-form-input>
                                                    <b-form-invalid-feedback id="inputTolMin">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /TolMin -->
                                        <!-- TolPlus -->
                                            <validation-provider
                                                name="TolPlus"
                                                :rules="{ required: false }"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        Tol(+)<code>*</code>
                                                    </template>
                                                    <!-- <b-form-select 
                                                        
                                                        id="inputTolPlus" 
                                                        v-model="form.tolPlus" 
                                                        value-field="value"
                                                        text-field="value"
                                                        :options="setTolPlus"
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-select> -->
                                                    <b-form-input readonly id="inputTolPlus" v-model="form.tolPlus" :state="getValidationState(validationContext)" trim></b-form-input>
                                                    <b-form-invalid-feedback id="inputTolPlus">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /TolPlus -->
                                        <!-- Description -->
                                        <template v-for="(n,index) in form.modelName" >
                                            <validation-provider
                                                :key="index"
                                                :name="form.modelName[n]"
                                                :rules="{ required: false, max: 7,regex:/^([0-9/ ])*$/,}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label-cols          ="3" 
                                                    label-cols-lg       ="3" 
                                                    label-size          ="sm"
                                                >
                                                    <template v-slot:label>
                                                        {{n}}
                                                    </template>
                                                    <b-form-input 
                                                        
                                                        :id="form.modelName[n]" 
                                                        v-model="modelValue[n]"
                                                        :state="getValidationState(validationContext)"  
                                                        trim></b-form-input>
                                                    <b-form-invalid-feedback id="inputDescription">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /Description -->
                                        </template>
                                        <b-button variant="warning" v-on:click="hideModal()" block size="sm">Cancel</b-button>
                                        <b-button variant="success" block size="sm" type="submit">Save</b-button>
                                    
                            </b-form>
                        </validation-observer>
                    </div>
                </b-modal>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import Vue from 'vue';
import {mapActions, mapGetters, mapState} from 'vuex'
import apiProduct from '@/store/product-form/master-param.js'
export default {
    props:['size_id'],
    data(){
        return{
            formName:'Size Detail',
            sizeDetail:[],
            fields:[
                {key:'action',label:'#'},
                {key:'description',label:'Description'},
                {key:'tol_min',label:'Tol(-)'},
                {key:'tol_plus',label:'Tol(+)'},
            ],
            form:{
                fieldName:[],
                description:null,
                tolMin:null,
                tolPlus:null,
                modelName:[],
            },
                modelValue:{},
            show:false,
            descriptionOpt:[],
            dataSize:[],
            tolOpt:[],
            tolMinOpt:[],
            tolPlusOpt:[],
            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User']
        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('sizeSpec',['GET_SIZESPEC_DASHBOARD','POST_SIZESPEC_HEADER','POST_SIZESPEC_DETAIL','SAVE_SIZESPEC_DETAIL_DASHBOARD','SET_SIZESPEC_HEADER','DELETE_SIZESPEC_DETAIL']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getMasterParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    // console.log(response);
                    this.tolOpt = response.data.payload.filter(d=>d.category ==='Size Tolerance')
                    this.tolMinOpt = response.data.payload.filter(i=>i.category ==='Tol Min')
                    
                    this.tolPlusOpt = response.data.payload.filter(i=>i.category ==='Tol Plus')
                    this.descriptionOpt = response.data.payload.filter(d=>d.category ==='Size Description')

                    
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })

        },
        getData(){
            this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
                let sizeSpecDashb = rsp.data.payload.filter(i => i.id === this.size_id)
                let id = sizeSpecDashb[0].id
                let size = sizeSpecDashb[0].size
                let detail = sizeSpecDashb[0].size_detail
                // console.log('get dash',detail);

                this.SET_SIZESPEC_HEADER({id,size,detail})
                
                let sizeDetailDash = JSON.parse(this.SIZESPEC_HEADER.detail)
                for (let x = 0; x < sizeDetailDash.length; x++) {
                    
                    this.sizeDetail.push(sizeDetailDash[x])
                    
                }
                // console.log('dash',this.sizeDetail);


            }).catch(err =>{
                return err
            })
        },
        getDataSizeSpec(){
            
            this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
                // console.log(this.size_id);
                const sizeDetail = rsp.data.payload.filter(x => x.id === this.size_id)
                let arrSizeDetail = JSON.parse(sizeDetail[0].size_detail)
                this.$nextTick(function (){

                    this.$set(this,'dataSize',arrSizeDetail)
                    console.log('rsp size',this.dataSize);
                })
                // console.log('rsp size',JSON.parse(sizeDetail[0].size_detail));
            })
                
        },
        setFieldHeader(){
            console.log('id',this.size_id);
            // this.SIZESPEC_HEADER
            // console.log('setfileheader',JSON.parse(this.SIZESPEC_HEADER.detail));
            // console.log('field',JSON.parse(this.SIZESPEC_HEADER.size));
            let arrSize = JSON.parse(this.SIZESPEC_HEADER.size)
            let arrTest = []
            for (let x = 0; x < arrSize.length; x++) {
                let objSize = {
                    key: arrSize[x],
                    label: arrSize[x]
                }
                this.fields.push(objSize)

                this.form.fieldName.push(arrSize[x])


                // this.form.modelName.push('size_'+arrSize[x])
                // arrTest.push('size_'+arrSize[x])
                arrTest.push(arrSize[x])
                
            
            }
            this.$set(this.form, 'modelName', arrTest)
            
            // console.log(this.form.modelName);
        },
        createData(){
            this.clearForm()
            this.show = true
        },
        hideModal(){
            this.show = false
            this.form.fieldName = null
        },
        setObjt(index,n){
            this.modelValue.push(n)
            // console.log(this.modelValue);
        },
        onSubmit(){
            
            let objSizeValue = this.modelValue
            let objSizeDetail = {
                description:this.form.description,
                tol_min:this.form.tolMin,
                tol_plus:this.form.tolPlus,
                
            }
            Object.assign(objSizeDetail,objSizeValue)
            
            
            console.log(this.dataSize);
            if(this.dataSize){
                console.log('ini if 0');
                this.dataSize.push(objSizeDetail)
            }else{
                console.log('else');
                let arrSize = []
                arrSize.push(objSizeDetail)
                this.$set(this,'dataSize',arrSize)
                // this.dataSize.push(arrSize)
            }

            
            
            // this.SAVE_SIZESPEC_DETAIL_DASHBOARD(objSizeDetail)

            // console.log('size detail',this.SIZESPEC_DETAIL_DASHBOARD);
            this.POST_SIZESPEC_DETAIL({
                size_spec:this.SIZESPEC_HEADER.id,
                size_detail:JSON.stringify(this.dataSize)
            }).then(rsp => {
                // console.log('resp then',rsp.data.payload);
                this.getDataSizeSpec()
            }).catch(err =>{
                console.log(err);
            })
            
            
            // this.POST_SIZESPEC_DETAIL({
            //     size_spec:this.SIZESPEC_HEADER.id,
            //     // size_detail:JSON.stringify(objSizeDetail)
            //     size_detail:arrSize
            // }).then(rsp => {
            //     this.showNotification({type:'success', message:'Successfully'})
            // }).catch(error => {
            //     return error
            // })
            // console.log(this.SIZESPEC_HEADER)
            this.show = false
            // this.$nextTick(function(){

            //     this.getDataSizeSpec()
            // })
            
            
        },
        clearForm(){
            this.show = false
            this.form.description = null
            this.form.tolMin = null
            this.form.tolPlus = null
            this.modelValue = {}
        },
        deleteDetail(id){
            
            this.dataSize.splice(id)
            console.log('index',this.dataSize);

            this.POST_SIZESPEC_DETAIL({
                size_spec:this.SIZESPEC_HEADER.id,
                size_detail:JSON.stringify(this.dataSize)
            }).then(rsp => {
                // console.log('resp then',rsp.data.payload);
                this.getDataSizeSpec()
            }).catch(err =>{
                console.log(err);
            })
            
            // this.DELETE_SIZESPEC_DETAIL(id)
        },
        refreshData(){
            this.getDataSizeSpec()
        },
        setTol(){
            this.form.tolMin = null
            this.form.tolPlus = null
            var descId = this.descriptionOpt.filter(i=>i.value === this.form.description)
            var tolMin = this.tolMinOpt.filter(i=>i.parent === descId[0].id)
            if(tolMin.length >= 1){
            this.form.tolMin = tolMin[0].value
            }else{
                this.form.tolMin = null
            }
            var tolPlus = this.tolPlusOpt.filter(i=>i.parent === descId[0].id)
            if (tolPlus.length >= 1) {
                this.form.tolPlus = tolPlus[0].value
            }else{
                this.form.tolPlus = null
            }
            
        }
    },
    beforeCreate(){
        
        
    },
    created(){
        this.getMasterParam()
        this.getDataSizeSpec()
        
        this.setFieldHeader()
        
    },
    mounted(){
        // this.getData()
    },
    computed:{
        ...mapGetters('sizeSpec',['SIZESPEC_DASHBOARD','SIZESPEC_HEADER','SIZESPEC_DETAIL_DASHBOARD']),
        ...mapState('sizeSpec',['ST_SIZESPEC_HEADER']),
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        // setTolMin(){
        //     return this.tolMinOpt.filter(i=>i.parent == this.form.description)
        // },
        // setTolPlus(){
        //     var descriptionId = this.tolPlusOpt.filter(i)
        //     return this.tolPlusOpt.filter(i=>i.parent === this.form.description)
        // }
        // loadSizeDetail(){
        //     let arrSizeDash = this.SIZESPEC_DASHBOARD.filter(x=>x.id === this.SIZESPEC_HEADER.id)
        //     let details = arrSizeDash[0].size_detail
        //     console.log('comp json',JSON.parse(details));
            
        //    return JSON.parse(details)
        // }
        

        // loadSizeDetail(){
            
        //     this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
        //         // console.log(this.size_id);
        //         const sizeDetail = rsp.data.payload.filter(x => x.id === this.size_id)
        //         let arrSizeDetail = JSON.parse(sizeDetail[0].size_detail)
        //         // this.$set(this,'dataSize',arrSizeDetail)
        //         // console.log('rsp size',JSON.parse(sizeDetail[0].size_detail));
        //         // console.log('rsp size',this.dataSize);
        //         return arrSizeDetail
        //     })
        // },
    }
}
</script>