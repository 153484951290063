<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid>
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Approval Plan Activity" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3> Review Actual Activity</h3>
                <router-link tag="b" to="/app/applications" style="position: absolute; right: 15px;">
                  <b-button size="md" type='button' variant="warning">
                    <b-icon icon="arrow-left"></b-icon>
                    Back
                  </b-button>
                </router-link>
              </div>

            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row align-v="center">
                  <b-col sm='4'>
                      <b-form-group
                        label="Sales Person"
                        label-for="Employee_ID"
                        label-cols-sm="3"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <v-select
                            v-model="selectedNikSales"
                            :clearable="true"
                            :reduce="(data) => data.nik"
                            @input="ChangeNikSales()"
                            :required="true"
                            class="vd__div"
                            prepend-icon="edit"
                            label="name"
                            placeholder="Choose Sales Name"
                            :options="options.sales"
                            ref="OptSales"
                            >
                            <template v-slot:option="option">
                              {{ option.nik }} - {{option.name}}
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              {{ option.nik }} - {{option.name}}
                            </template>
                        </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm='4' offset-sm="1">
                  <b-row>
                    <b-col sm='12'>
                      <b-form-group
                        label="Start Date"
                        label-for="StartDate"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <VueDatePicker
                          class="vd__div"
                          clearable
                          @input="ChangeStartDate()"
                          v-model="form.start_date"
                          no-header
                          ref="startDate"
                          format="ddd DD MMM YYYY"
                          placeholder="Choose Start Date"
                        />
                        <span id="StartDateWarning" class="display-hidden text-small text-danger">Wajib di isi</span>
                    </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>

                    <b-col sm='12'>
                      <b-form-group
                        label="End Date"
                        label-for="EndDate"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                      <VueDatePicker
                        class="vd__div"
                        clearable
                        @input="ChangeEndDate()"
                        :disabled="!form.start_date"
                        v-model="form.end_date"
                        format="ddd DD MMM YYYY"
                        ref="endDate"
                        :min-date="form.start_date"
                        no-header
                        placeholder="Choose End Date"
                      />
                        <span id="endDateWarning" class="display-hidden text-small text-danger">Wajib di isi</span>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                </b-row>
                <b-row align-h="">
                  <br/>
                   Total Customer hands-on : {{customer_hands_on}}
                  <br/>
                  Total Customer on progress : {{customer_on_progress}}
                  <br/>
                  Total Customer Done : {{customer_done}}
                </b-row>


              </b-list-group-item>
            </b-list-group>


          </b-card>
        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";
import { fnPOST } from '../../../../api/api-methods';
import { APIPATH_SALESFORCE } from '../../../../api/api-paths';

export default {
  components: {
    ModelListSelect,
    Loader,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'short'};
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "Review Actual Activity",
      monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      dayNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      today:today,
      today_days:days,
      today_month:month,
      StrMonth:Strmonth,
      selectedNikSales:null,
      today_year:year,
      form :{
        employee_nik:null,
        employee_name:null,
        end_date:null,
        start_date:null,
      },
      options:{
        sales:[],
      },
      customer_hands_on:0,
      customer_on_progress:0,
      customer_done:0,

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),

  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions('sales_force', ['getListUserBBI']),

    setData() {
      this.form.employee_nik    = this.$route.params.idData
      let statusRoute           = this.$route.params.data
      this.form.employee_name   = this.$route.params.data
      let idData                = this.$route.params.idData
      if (statusRoute == undefined || statusRoute =='' ) {
        statusRoute = null
      }
      this.getListUserBBI({
        role:'Sreport',
      }).then(rsp=> {
        this.options.sales = rsp.payload
      })

    },
    ChangeStartDate() {
      if (this.form.start_date == null) {
        this.form.end_date = null
      }
      if (this.form.end_date !== null) {
        if (this.form.start_date >= this.form.end_date) {
          this.form.end_date = null
        } else {
          if (this.selectedNikSales !== null) {
            this.LoadData()
          }
        }
      }
    },
    ChangeEndDate() {
      if (this.form.end_date !== null && this.selectedNikSales !== null) {
        if (this.form.end_date !== undefined) {
          this.LoadData()
        }
      }
    },
    ChangeNikSales() {
      // console.log(this.selectedNikSales)
      // console.log(this.form.end_date)
      // console.log(this.form.start_date)
      if (this.selectedNikSales == undefined) {
        this.selectedNikSales = null
      }
      if (this.selectedNikSales !== null && this.form.end_date !== null && this.form.start_date !== null) {
        this.LoadData()
      }

    },
    LoadData() {
      this.isLoadingData = true
      fnPOST(APIPATH_SALESFORCE.reviewSalesRecap, {
        sales_nik:this.selectedNikSales,
        sales:this.selectedNikSales,
        start_date:this.form.start_date,
        end_date:this.form.end_date,
      })
      .then(rsp => {
        console.log(rsp)
        if (rsp.data.payload) {
          this.customer_done = rsp.data.payload.done
          this.customer_on_progress = rsp.data.payload.on_progress
          this.customer_hands_on = rsp.data.payload.hands_on
        }
        console.log(rsp.data.payload)
      })
      .catch(err => {
        if(err.response.status !== 401) {
            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
        }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
    FormatDate(value) {
      if(value == null || value == undefined || value == '') {
        return ' '
      } else {
        let date = new Date(value)
        let nameDay = this.dayNames[date.getDay()];
        let day = date.getDate()
        if (String(day).length == 1) {
          day = "0"+String(day)
        }
        let year = date.getFullYear()
        let bulan = this.monthNames[date.getMonth()]
        date = nameDay+' '+day+' '+bulan+' '+year
        return date
      }
    },
  },
  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />