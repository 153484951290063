<template>


  <b-navbar class="header d-print-none">
    <b-nav>
      <b-nav-item>
        <a class="d-md-down-none px-2" href="#" @click="toggleSidebarMethod" id="barsTooltip">
          <i class='la la-bars la-lg' />
        </a>
        <b-tooltip target="barsTooltip" placement="bottom">
          Turn on/off <br> sidebar <br> collapsing
        </b-tooltip>
        <a class="fs-lg d-lg-none" href="#" @click="switchSidebarMethod">
          <span class="rounded rounded-lg bg-gray text-white d-md-none">
            <i class="la la-bars la-lg" />
          </span>
          <i class="la la-bars la-lg d-sm-down-none ml-4" />
        </a>
      </b-nav-item>
    </b-nav>
    <!-- <a  class="navbar-brand d-md-none">
      <i class="fa fa-circle text-gray mr-n-sm" />
      <i class="fa fa-circle text-warning" />
      &nbsp;
      BBI
      &nbsp;
      <i class="fa fa-circle text-warning mr-n-sm" />
      <i class="fa fa-circle text-gray" />
    </a> -->
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown right>
        <template v-slot:button-content>
          <span class="avatar thumb-sm float-left mr-2">
            <img class="rounded-circle" src="../../assets/people/male-ava.png" alt="..." />
          </span>
          <span class="small"><span class="fw-semi-bold fontbbi">{{stUserData.fullname}}</span></span>
          <i class="la la-cog px-2" />
        </template>
          <b-dropdown-item @click="navToAccount"><i class="la la-user" /> My Account</b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-group id="dropdown-themes-selection" header="Choose Theme">
            <b-dropdown-item @click="SetThemeWhite"><i class="la la-eyedropper" /> BBI White</b-dropdown-item>
            <b-dropdown-item @click="SetThemeOrange"><i class="la la-eyedropper theme-icon-orange" /> BBI Orange</b-dropdown-item>
          </b-dropdown-group>
          <b-dropdown-divider />
          <b-dropdown-item @click="logout"><i class="la la-sign-out" /> Log Out</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

  </b-navbar>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Notifications from '@/components/Notifications/Notifications';
import loginStore from '@/store/login'


export default {
  name: 'Header',
  components: { Notifications },
  data() {
    return {
      userData : null,
    };
  },
  computed: {
    ...mapState('layout', ['sidebarClose', 'sidebarStatic', 'chatNotificationIcon', 'chatNotificationPopover']),
    ...mapState('session', ['stToken', 'stUserData', 'stError', 'stInfo', 'stSuccess']),
  },
  methods: {
    ...mapActions('layout', ['toggleSidebar', 'toggleChat', 'switchSidebar', 'changeSidebarActive', 'initApp', 'SetThemeOrange', 'SetThemeWhite']),
    switchSidebarMethod() {
      if (!this.sidebarClose) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      } else {
        this.switchSidebar(false);
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    toggleSidebarMethod() {
      if (this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      } else {
        this.toggleSidebar();
        const paths = this.$route.fullPath.split('/');
        paths.pop();
        this.changeSidebarActive(paths.join('/'));
      }
    },
    logout() {
      localStorage.removeItem("redirect_url");
      loginStore.dispatch('logoutUser')
    },
    navToAccount() {
      this.$router.push({
        name: 'AccountPage'
      })
    },
  },
  created() {
    if (window.innerWidth > 576) {
      this.initApp();
    }
  },
  mounted() {
  },
};
</script>

<style src="./Header.scss" lang="scss" scoped />
