import Vue from "vue";
import Vuex from "vuex";

import layout from './layout';
import session from './session';
import report from './report';
import application from './application';
import errMessage from './error-message.js';
import productDesign from './product-form/product-design';
import masterPLM from './product-form/master-plm';
import materialDev from './product-form/material-dev';
import sizeSpec from './product-form/size-spec';
import ppm from "./ppm";
import master_calender from "./calender";
import sales_force from "./salesforce-form/sfc-fetchdata";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    layout,
    session,
    application,
    report,
    errMessage,
    productDesign,
    masterPLM,
    materialDev,
    master_calender,
    ppm,
    sizeSpec,
    sales_force,
  },
});
