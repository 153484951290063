<template>
    <div>
        <b-card>
            <template v-slot:header >
                <b-row>
                    <b-col sm="auto" md="9">
                        <div class="text-left">
                            <h4>Technical Package</h4>
                        </div>
                    </b-col>
                    <b-col sm="auto" md="3">
                        <div class="text-right">
                            <b-button variant="info"  size="sm" v-on:click="downloadPdf('preview')">
                                <b-icon icon="file-earmark-play-fill" aria-hidden="true"></b-icon> Preview
                                </b-button>
                            <b-button variant="success" size="sm" v-on:click="downloadPdf('download')">
                                <b-icon icon="download" aria-hidden="true"></b-icon> Download
                                </b-button>
                        </div>
                    </b-col>
                    
                </b-row>
            </template>
            <!-- Garment -->
            <b-card>
                <template>
                    <b-row >
                        <b-col sm="auto" md="4" lg="4">

                        </b-col>
                        <b-col class="text-center" sm="auto" md="4" lg="4">
                            <h4 class="text-black">{{detailProduct.article}}</h4>
                            <p class="text-muted">Garment/Apparel</p>
                        </b-col>
                        <b-col class="text-right" sm="auto" md="4" lg="4">
                            <h5 class="text-black">{{detailProduct.brand}}</h5>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Brand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.brand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Quartal<code>*</code>
                                </template>
                
                                <label>{{detailProduct.quartal}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Prod. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.prod_month}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Coll. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.coll_month}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Theme<code>*</code>
                                </template>
                
                                <label>{{detailProduct.theme}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Subbrand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.subbrand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Category<code>*</code>
                                </template>
                
                                <label>{{detailProduct.category}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Article<code>*</code>
                                </template>
                
                                <label>{{detailProduct.article}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Designer<code>*</code>
                                </template>
                
                                <label>{{detailProduct.creator_name}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Created<code>*</code>
                                </template>
                
                                <label>{{new Date(this.detailProduct.created_at).toLocaleString("id-ID")}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Submitted<code>*</code>
                                </template>
                
                                <label>{{detailProduct.date}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Modified<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <template >
                                    <b-form-group
                                        
                                        label-size ="sm">
                                        
                                        <b-img   v-bind="mainProps2" thumbnail fluid :src="designData[0].img_src" ></b-img>
                                    </b-form-group>
                                </template>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    No. Sample<code>*</code>
                                </template>
                
                                <label>{{detailProduct.ref_sample}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Size Spec.<code>*</code>
                                </template>
                
                                <label>{{detailProduct.size_spec}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    FOB/CMT/CM<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Supplier<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Approval Date<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Note<code>*</code>
                                </template>
                
                                <label>{{detailProduct.note}}</label>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                        
                        <b-col sm="auto" md="12" lg="12">
                            <b-card title="Technical Drawing">
                                <b-table
                                    id="table-tech"
                                    :items="techData"
                                    :fields="techField"
                                    stacked
                                >
                                    <template #cell(img_src)="{item}"   >
                                        <b-img v-bind="mainProps2" thumbnail fluid :src="item.img_src"></b-img>
                                    </template>
                                </b-table>
                                <!-- <template v-for="images in productImg">
                                    <b-form-group
                                        :key="images.img_id"
                                        label-size ="sm">
                                        <b-link :href="images.img_src" target="_blank">{{images.img_name}}</b-link>
                                        <b-img v-if="images.img_label === 'technical-drawing'"  v-bind="mainProps2" thumbnail fluid :src="images.img_src" ></b-img>
                                    </b-form-group>
                                </template> -->
                            </b-card>
                        </b-col>
                        <!-- <b-col sm="auto" md="4" lg="4">
                            <b-card title="Technical Notes">
                                
                            </b-card>
                        </b-col> -->
                    </b-row>
                </template>
            </b-card>
            <!-- Material -->
            <b-card>
                <template>
                    <b-row >
                        <b-col sm="auto" md="4" lg="4">

                        </b-col>
                        <b-col class="text-center" sm="auto" md="4" lg="4">
                            <h4 class="text-black">{{detailProduct.article}}</h4>
                            <p class="text-muted">Fabric,Accessories,Artwork,Interlining,Thread</p>
                        </b-col>
                        <b-col class="text-right" sm="auto" md="4" lg="4">
                            <h5 class="text-black">{{detailProduct.brand}}</h5>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Brand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.brand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Quartal<code>*</code>
                                </template>
                
                                <label>{{detailProduct.quartal}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Prod. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.prod_month}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Coll. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.coll_month}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Theme<code>*</code>
                                </template>
                
                                <label>{{detailProduct.theme}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Subbrand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.subbrand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Category<code>*</code>
                                </template>
                
                                <label>{{detailProduct.category}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Article<code>*</code>
                                </template>
                
                                <label>{{detailProduct.article}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Designer<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Created<code>*</code>
                                </template>
                
                                <label>{{detailProduct.created_at}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Submitted<code>*</code>
                                </template>
                
                                <label>{{detailProduct.date}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Modified<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-img v-bind="mainProps" thumbnail fluid :src="detailProduct.img_src" ></b-img>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-table
                        id="table-material"
                        :items="materialProduct"
                        :fields="materialFields"
                    >
                        <template #cell(img_src)="{item}"   >
                            <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name"></b-img>
                            
                            <!-- <b-img v-bind="mainProps" thumbnail fluid :src=""></b-img> -->
                        </template>
                    </b-table>
                </template>
            </b-card>
            <!-- Size Spec -->
            <b-card>
                <template>
                    <b-row >
                        <b-col sm="auto" md="4" lg="4">

                        </b-col>
                        <b-col class="text-center" sm="auto" md="4" lg="4">
                            <h4 class="text-black">{{detailProduct.article}}</h4>
                            <p class="text-muted">Garment/Apparel Sample Review</p>
                        </b-col>
                        <b-col class="text-right" sm="auto" md="4" lg="4">
                            <h5 class="text-black">{{detailProduct.brand}}</h5>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Brand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.brand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Quartal<code>*</code>
                                </template>
                
                                <label>{{detailProduct.quartal}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Prod. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.prod_month}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Coll. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.coll_month}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Theme<code>*</code>
                                </template>
                
                                <label>{{detailProduct.theme}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Subbrand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.subbrand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Category<code>*</code>
                                </template>
                
                                <label>{{detailProduct.category}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Article<code>*</code>
                                </template>
                
                                <label>{{detailProduct.article}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Designer<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Created<code>*</code>
                                </template>
                
                                <label>{{detailProduct.created_at}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Submitted<code>*</code>
                                </template>
                
                                <label>{{detailProduct.date}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Modified<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-img v-bind="mainProps" thumbnail fluid :src="detailProduct.img_src" ></b-img>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <div class="text-center">
                        Size Specification
                    </div>
                    <b-table
                        id="table-sizespec"
                        :items="dataSize"
                        :fields="fields"
                    >
                        <template #cell(index)="data">
                            {{data.index + 1}}
                        </template>
                    </b-table>
                </template>
                <template>
                    <div class="text-center">
                        Proto Size
                    </div>
                    <b-table
                        id="table-protosize"
                        :items="dataSize"
                        :fields="fieldsProto"
                        responsive
                    >
                        <template #cell(index)="data">
                            {{data.index + 1}}
                        </template>
                    </b-table>
                </template>
            </b-card>
            <b-card>
                <template>
                    <b-row >
                        <b-col sm="auto" md="4" lg="4">

                        </b-col>
                        <b-col class="text-center" sm="auto" md="4" lg="4">
                            <h4 class="text-black">{{detailProduct.article}}</h4>
                            <p class="text-muted">Garment/Apparel Sample Review</p>
                        </b-col>
                        <b-col class="text-right" sm="auto" md="4" lg="4">
                            <h5 class="text-black">{{detailProduct.brand}}</h5>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-row>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Brand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.brand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Quartal<code>*</code>
                                </template>
                
                                <label>{{detailProduct.quartal}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Prod. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.prod_month}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Coll. Month<code>*</code>
                                </template>
                
                                <label>{{detailProduct.coll_month}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Theme<code>*</code>
                                </template>
                
                                <label>{{detailProduct.theme}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Subbrand<code>*</code>
                                </template>
                
                                <label>{{detailProduct.subbrand}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Category<code>*</code>
                                </template>
                
                                <label>{{detailProduct.category}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Article<code>*</code>
                                </template>
                
                                <label>{{detailProduct.article}}</label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Designer<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Created<code>*</code>
                                </template>
                
                                <label>{{detailProduct.created_at}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Submitted<code>*</code>
                                </template>
                
                                <label>{{detailProduct.date}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Date Modified<code>*</code>
                                </template>
                
                                <label></label>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="3" lg="3">
                            <b-img v-bind="mainProps" thumbnail fluid :src="detailProduct.img_src" ></b-img>
                        </b-col>
                    </b-row>
                </template>
                <template>
                    <b-table
                        id="table-comment"
                        :items="loadActivity"
                        :fields="designerField"
                    >
                        <template #cell(comment)="data">
                            <!-- <p> {{data.value}} </p> -->
                            <p v-for="item in data.value" :key="item">
                                {{item}}
                            </p>
                        </template>
                        <template #cell(date)='{item}'>
                            {{new Date(item.date).toLocaleString("id-ID")}}
                        </template>
                    </b-table>
                </template>
            </b-card>

        </b-card>
    </div>
</template>
<script>
import apiProduct from '@/store/product-form/master-param.js'
import apiSupplier from '@/store/product-form/product-supplier.js'
import { mapActions } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
    props:['productId','sizeId'],
    data(){
        
        return{
            
            mainProps: {width: 100, class: 'm1' },
            mainProps2: {width: 200, class: 'm1' },
            detailProduct:null,
            materialProduct:[],
            materialFields:[
                'code','type','description',
                {
                    key:'img_src',
                    label:'Images'
                },
                'detail','used_as'
            ],
            sizeSpec:[],
            sizespecFields:[],
            description:null,
            tolMin:null,
            tolPlus:null,
            fieldName:[],
            modelName:[],
            modelValue:{},
            fields:[
                {key:'index',label:'No'},
                {key:'description',label:'Description'},
                {key:'tol_min',label:'Tol(-)'},
                {key:'tol_plus',label:'Tol(+)'},
            ],
            fieldsProto:[
                {key:'index',label:'No'},
                {key:'description',label:'Description'},
                {key:'tol_min',label:'Tol(-)'},
                {key:'tol_plus',label:'Tol(+)'},
            ],
            dataSize:[],
            productImg:[],
            hangtagData:[],
            designField:[{
                    key:'img_src',
                    label:'Design'
                }],
            techField:[{
                    key:'img_src',
                    label:'Technical Drawing'
                }],
            designData:[],
            techData:[],
            imgBase64:[],
            approveHistory:[],
            designerField:[
                'code',
                'category',
                'supplier_name',
                'date',
                'user',
                'remark',
                {key:'comment',label:'COMMENT',formatter:this.formatComment},
            ],
            loadActivity:[],
            brandOpt:[],
            brandDetail:null,
            dataBrandManager:[],
            dataDate:[],
            created_by_data_sort:[],
            dataDateSupplier:[],
            created_by_supplier:[],
            codeStreamerData:[],
            codeSizeLabelData:[],
            codeMainLabelData:[],

        }
    },
    methods:{
        ...mapActions('productDesign',['getProduct','GET_PRODUCT_DETAIL','GET_PRODUCT_MATERIAL_DEV','GET_PRODUCT_APPROVE']),
        ...mapActions('sizeSpec',['GET_SIZESPEC_DASHBOARD']),
        ...mapActions('materialDev',['GET_MATERIALDEV_ACTIVITY']),
        getMaterial(){
            this.GET_PRODUCT_MATERIAL_DEV()
                .then(response => {
                    let materials = response.data.payload.filter(pm => pm.product_id == this.productId)
                    // console.log('prod mat',materials);
                    // for (let i = 0; i < materials.length; i++) {
                    //     const img = {
                    //         code:materials[i].code,
                    //         type:materials[i].type,
                    //         description:materials[i].description,
                    //         img_src:materials[i].img_src,
                    //         img_name:materials[i].img_name,
                    //         detail:materials[i].detail,
                    //         used_as:materials[i].used_as
                    //     }

                    //     this.materialProduct.push(img)
                        
                        
                    // }
                    this.materialProduct = materials
                    // console.log('data material', this.materialProduct)
                    // this.$set(this,'materialProduct',materials)
                }).catch(err =>{
                    console.log(err);
                })
        },
          getDataParam(){
        
            apiProduct.dispatch('getListMasterParam')
            .then(response => {  
                    // this.brandOpt = response.data.payload.filter(d=>d.category ==='Brand')
                     let parameter = response.data.payload
                     let parameter_brand = parameter.filter(app =>app.value === this.brandDetail && app.category === 'Brand')
                     let brand_manager = parameter.filter(app => app.category === 'Brand Manager' && app.parent === parameter_brand[0].id)

                     this.dataBrandManager = brand_manager
                    //  console.log('rsp brand', this.dataBrandManager)
                
                     
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
            
        },
         getDataSupplier(){
            apiSupplier.dispatch('GET_SUPPLIER')
            .then(rsp =>{
                let dataSup = rsp.filter(x=>x.product_id == this.productId)
                // console.log('supplier',dataSup);
                for (let i = 0; i < dataSup.length; i++) {
                    const dates_supplier = dataSup[i].created_date
                        this.dataDateSupplier.push(dates_supplier)
                }
                this.dataDateSupplier.sort();


                for (let i = 0; i < dataSup.length; i++) {
                    const created_supplier = dataSup[i].created_by
                        this.created_by_supplier.push(created_supplier)
                }
                this.created_by_supplier.sort();
                
            }).catch(err => {
                return err.response.data.message
            })
        },
        getProductDetail(){
            this.GET_PRODUCT_DETAIL({
                product_id:this.productId
            }).then(rsp=>{
                console.log('prod det',rsp.data.payload);
                let products = rsp.data.payload
                // this.$set(this,'detailProduct',products)
                // Object.assign(this.detailProduct,products)
                this.detailProduct = products
                this.brandDetail = this.detailProduct.brand
                // console.log('detail product',this.detailProduct);
            }).catch(err=>{
                console.log(err);
            })

            // console.log(this.detailProduct);
        },
        getSize(){
            // console.log('size id',this.sizeId);
            this.GET_SIZESPEC_DASHBOARD().then(rsp =>{
                // console.log('size spec',rsp.data.payload.filter(i => i.id == this.sizeId))
                let sizeSpecDashb = rsp.data.payload.filter(i => i.id == this.sizeId)
                let id = sizeSpecDashb[0].id
                let size = sizeSpecDashb[0].size
                let detail = sizeSpecDashb[0].size_detail
                // console.log('get dash',detail);

                let arrSize = JSON.parse(size)

                let arrTest = []
                for (let x = 0; x < arrSize.length; x++) {
                    let objSize = {
                        key: arrSize[x],
                        label: arrSize[x]
                    }
                    
                    this.fields.push(objSize)

                    this.fieldName.push(arrSize[x])


                    // this.form.modelName.push('size_'+arrSize[x])
                    // arrTest.push('size_'+arrSize[x])
                    arrTest.push(arrSize[x])

                    let objProto = {
                        key:arrSize[0],
                        label:arrSize[0]
                    }

                    this.fieldsProto.push(objProto)
                    
                  
                }
                 this.pushArrProto()
                this.$set(this, 'modelName', arrTest)
                
                let arrSizeDetail = JSON.parse(detail)
                this.$set(this,'dataSize',arrSizeDetail)
                // console.log('dash',this.sizeDetail);


            }).catch(err =>{
                return err
            })
        },
        pushArrProto(){
             let arrProto = [
                    {label:'1st Sample'},
                    {label:'+/-'},
                    {label:'2st Sample'},
                    {label:'+/-'},
                    {label:'3st Sample'},
                    {label:'+/-'},
                    {label:'4st Sample'},
                    {label:'+/-'}
                ]
                // console.log('arr proto',arrProto.length);
                for (let i = 0; i < arrProto.length; i++) {
                    let objProtoSize = {
                        key:arrProto[i].label+i,
                        label:arrProto[i].label
                    }
                    this.fieldsProto.push(objProtoSize)
                }
                // console.log('field proto', this.fieldsProto);
        },
        // convertBase64(url){
        //     let self = this
        //     var img64 = null
        //     var image = new Image()
        //     image.setAttribute('crossOrigin','anonymous')

        //     image.onload = function(){
        //         var canvas = document.createElement('canvas')
        //         canvas.width = image.width
        //         canvas.height = image.height

        //         canvas.getContext('2d').drawImage(this,0,0)

        //         canvas.toBlob(
        //             function(source){
        //                 var newImg = document.createElement("img")
        //                 url = URL.createObjectURL(source)

        //                 newImg.onload = function(){
        //                     URL.revokeObjectURL(url)
        //                 }

        //                 newImg.src = url
        //             },
        //             "image/jpeg",
        //             1
        //         )
        //         let dataUrl = canvas.toDataURL("image/jpeg")

        //         console.log('cek data url ',url);

                
        //             self.assignImageBase64(dataUrl);
        //             // console.log(dataUrl);

        //             // Object.assign(img64,dataUrl)
        //             // callback.call(this,dataUrl)

                    
                
        //     };
        //         image.src = url;

        //         // console.log(image.src);
                
        // },

        // assignImageBase64(img) {
        //     this.designData[0].img_src = img
        // },
        getDataImg(){
            // this.productImg = []
            this.designData = []
            this.techData = []
            this.getProduct().then(response =>{
                let data = response.filter(x=>x.id === this.productId)
                console.log('rsp',data);
                for (let i = 0; i < data.length; i++) {
                    let img = {
                        img_id:data[i].design_id,
                        img_code:data[i].design_code,
                        img_name:data[i].design_name,
                        img_src:data[i].design_src,
                        // img_src: new blob(data[i].design_src,{type:"image/jpeg"}),
                        // img_64:this.convertBase64(data[i].design_src),
                        img_label:data[i].design_label,

                    }

                    if (data[i].design_label === 'design') {
                        this.designData.push(img)
                        // console.log('ini design',this.designData);
                    }else if (data[i].design_label === 'technical-drawing') {
                        this.techData.push(img)
                        // console.log('ini tech',this.techData);
                    }else if (data[i].design_label === 'code-hang-tag') {
                        this.hangtagData.push(img)
                        // console.log('ini hang tag',this.hangtagData);
                    }else if (data[i].design_label === 'code-streamer-label') {
                        this.codeStreamerData.push(img)
                        // console.log('ini code size label label',this.code-size-label);
                    }else if (data[i].design_label === 'code-size-label') {
                        this.codeSizeLabelData.push(img)
                        // console.log('ini code main label',this.code-main-label);
                    }else if (data[i].design_label === 'code-main-label') {
                        this.codeMainLabelData.push(img)
                        // console.log('ini code main label',this.code-main-label);
                    }

                    // this.productImg.push(img)
                    
                }
                
            })
            // console.log('images',this.productImg);
                
        },

        getApproveHistory(){
            this.GET_PRODUCT_APPROVE().then(response =>{
                let approve     = response.data.payload.filter(app => app.product === this.productId && app.status === 'Approved')
                this.approveHistory = approve
                console.log('approve',approve);
                
                
                for (let i = 0; i < approve.length; i++) {
                    const dates = approve[i].date
                        this.dataDate.push(dates)
                }
                this.dataDate.sort();
                // console.log('custome', this.dataDate[0])


                for (let i = 0; i < approve.length; i++) {
                    const created_by_sort = approve[i].created_by
                        this.created_by_data_sort.push(created_by_sort)
                }
                this.created_by_data_sort.sort();
                console.log('custome', this.created_by_data_sort[0])
                
                
            })
        },

        getActivity(){
            this.GET_MATERIALDEV_ACTIVITY().then(response => {
                let dataActivity = response.data.payload
                let objActivity = dataActivity.filter(i=>i.product_id == this.productId && i.activity === 'Designer Comment' )
                // console.log('activity',dataActivity);
                this.loadActivity = objActivity
            })
        },
        formatComment(value){
            
            if (value != null) {
                const commentVal = JSON.parse(value).commentValue
                const entries = Object.entries(commentVal)
                const data = entries.filter(([key,val])=> val !== '' && val !== null)
                // const filt = commentVal.filter(([key,val])=> val !== null)
                const comment = []
                for (let i = 0; i < data.length; i++) {
                    
                    const filt = data[i].join(' : ')
                        comment.push(filt)
                }

                
                return comment
            }
            
            // return JSON.parse(value)
        },
        
        downloadPdf(button){
            let pdfName = 'Technical Package'
            var doc = new jsPDF('l','pt','A4')

            const columns =[
                    {title:"Code",dataKey:"code"},
                    {title:"Type",dataKey:"type"},
                    {title:"Description",dataKey:"description"},
                    {title:"Images",dataKey:"img"},
                    {title:"Detail",dataKey:"detail"},
                    {title:"Used As",dataKey:"used"},
                ]
            
            
            
            let centerize = doc.internal.pageSize.getWidth()/2
            let rights = doc.internal.pageSize.getWidth()
            let tinggi = doc.internal.pageSize.getHeight()
            let lebar  = doc.internal.pageSize.getWidth()
            
            


            //line
            doc.setLineWidth(1.5)
            doc.rect(10,10,rights-20,tinggi-20)

            doc.setFontSize(14)
            doc.text(this.detailProduct.article,centerize,30,{align:'center'})
            doc.text(this.detailProduct.brand,rights-Object.keys(this.detailProduct.brand).length-50,30,{align:'right'})
            doc.setFontSize(8)
            doc.text('Garment/Apparel',centerize,40,"center")

            doc.setLineWidth(0.5)
            doc.line(10,45,rights-10,45)

            //label left
            doc.setFontSize(10)
            doc.text('Brand',15,55)
            doc.text(':',90,55)
            doc.text(this.detailProduct.brand||'-',100,55)

            doc.text('Quartal',15,75)
            doc.text(':',90,75)
            doc.text(this.detailProduct.quartal||'-',100,75)

            doc.text('Prod. Month',15,95)
            doc.text(':',90,95)
            doc.text(this.detailProduct.prod_month||'-',100,95)

            doc.text('Coll. Month',15,115)
            doc.text(':',90,115)
            doc.text(this.detailProduct.coll_month||'-',100,115)

            //label center
            doc.text('Theme',centerize-200,55)
            doc.text(':',centerize-150,55)
            doc.text(this.detailProduct.collection_name||'-',centerize-140,55)

            doc.text('Subbrand',centerize-200,55+20)
            doc.text(':',centerize-150,55+20)
            doc.text(this.detailProduct.subbrand||'-',centerize-140,55+20)

            doc.text('Category',centerize-200,55+20+20)
            doc.text(':',centerize-150,55+20+20)
            doc.text(this.detailProduct.category||'-',centerize-140,55+20+20)

            

            doc.text('Article',centerize-200,55+20+20+20)
            doc.text(':',centerize-150,55+20+20+20)
            doc.text(this.detailProduct.article||'-',centerize-140,55+20+20+20)
            
            
                // console.log('pdf design',this.designData[0].img_src);
                doc.addImage(this.designData[0].img_src,'JPEG',centerize+250, 50,70,70)
            
            
            //label right
            doc.text('Designer',centerize+30,55)
            doc.text(':',centerize+130,55)
            // doc.text(this.approveHistory[0].created_by||'-',centerize+140,55)
            doc.text(this.detailProduct.creator_name||'-',centerize+140,55)

            doc.text('Date Created',centerize+30,75)
            doc.text(':',centerize+130,75)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,75)

            doc.text('Date Submitted',centerize+30,95)
            doc.text(':',centerize+130,95)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,95)

            doc.text('Date Modified',centerize+30,115)
            doc.text(':',centerize+130,115)
            doc.text('',centerize+140,115)

            doc.setLineWidth(0.5)
            doc.line(10,125,rights-10,125)

            doc.text('No. Sample',15,140)
            doc.text(this.detailProduct.ref_sample||'-',120,140)
            doc.line(10,145,rights-10,145)
            
            doc.line(100,165,100,125)

            doc.text('FOB/CMT/CM',centerize-195,140)
            doc.text('FOB',centerize-105,140)

            doc.text('Supplier',centerize-195,160)
            doc.text('-',centerize-105,160)
            doc.line(centerize-200,165,centerize-200,125)
            doc.line(centerize-110,165,centerize-110,125)

            doc.text('Size Spec',15,160)
            doc.text(this.detailProduct.size_description||'-',120,160)
            doc.line(10,165,rights-10,165)

            doc.line(10,185,rights-10,185)

            doc.line(centerize+50,165,centerize+50,125)
            doc.text('Approval Date',centerize+55,140)
            doc.text(new Date(this.approveHistory[0].date).toLocaleString("id-ID")||'-',centerize+155,140)

            doc.text('Note',centerize+55,160)
            doc.text('oke',centerize+155,160)
 
            doc.text('COGM',15,180)
            doc.line(10,207,rights-10,207)
            doc.line(160,165,160,250)

            doc.text('Approved by Head Designer',15,200)
            doc.line(10,228,rights-10,228)

            doc.text('Received by Merchandiser',15,220)
            doc.text('Approve by MD Dept Head',15,240)
            doc.line(10,250,rights-10,250)

            doc.text(new Date(this.dataDate[0]).toLocaleString("id-ID")||'-',165,200)
            doc.text(this.created_by_data_sort[0]||'-',255,200)
            doc.line(330,165,330,250)
            doc.text(new Date(this.dataDateSupplier[0]).toLocaleString("id-ID")||'-',165,220) 
            doc.text(this.created_by_supplier[0]||'-',255,220)  

            doc.text('Yenny Salim',253,240)


            doc.text('Qty:',335,180)
            doc.line(550,165,550,250)
            doc.text('Acknowledge by Brand Manager',335,200)
            doc.text('Approved by Mkt Sub Div Head / Div Head',335,220)
            doc.text('Approved by Prod Dev Div Head',335,240)


            doc.text('PO Number:',555,180)
            if(this.dataBrandManager.length > 0){
            doc.text(this.dataBrandManager[0].description||'-',700,200)
            }
            doc.text('Katharina / Tommy Indra',700,220)
            doc.text('Lendy Widjanarko',700,240)
  

            doc.line(centerize+150,165,centerize+150,125)
            doc.text('Design',20,263)
            doc.line(10,270,rights-10,270)
            
            
                // console.log('pdf tech',this.techData[0].img_src);
                doc.addImage(this.designData[0].img_src,'JPEG',20, 290,210,290)
            

            // var startingPage = doc.internal.getCurrentPageInfo().pageNumber;
            

            // doc.setPage(startingPage)

            doc.text('Technical Notes',centerize+300,263,"center")

            // technical drawing page
            doc.addPage("a4","p")
            var widthTech = doc.internal.pageSize.getWidth();
            var heightTech = doc.internal.pageSize.getHeight();

            for (let i = 0; i < this.techData.length; i++) {
                
                
                doc.addImage(this.techData[i].img_src,'JPEG',0, 0,widthTech,heightTech)
                
                var startingPageTech = doc.internal.getCurrentPageInfo().pageNumber;

                doc.setPage(startingPageTech)

                if(this.techData.length > (i+1)){
                    doc.addPage("a4","p")
                }

                
            }

            //page 2
            for (let i = 0; i < this.materialProduct.length; i++) {
                
                
            
                
                    doc.addPage("a4","l")
                

                doc.setLineWidth(1.5)
                doc.rect(10,10,rights-20,tinggi-20)

                doc.setFontSize(14)
                doc.text(this.detailProduct.article,centerize,30,{align:'center'})
                doc.text(this.detailProduct.brand,rights-Object.keys(this.detailProduct.brand).length-50,30,{align:'right'})
                doc.setFontSize(8)
                doc.text('Fabric,Accessories,Artwork,Interlining,Thread',centerize,40,"center")

                doc.setLineWidth(0.5)
                doc.line(10,45,rights-10,45)

                //label left
                doc.setFontSize(10)
                doc.text('Brand',15,55)
                doc.text(':',90,55)
                doc.text(this.detailProduct.brand||'-',100,55)

                doc.text('Quartal',15,75)
                doc.text(':',90,75)
                doc.text(this.detailProduct.quartal||'-',100,75)

                doc.text('Prod. Month',15,95)
                doc.text(':',90,95)
                doc.text(this.detailProduct.prod_month||'-',100,95)

                doc.text('Coll. Month',15,115)
                doc.text(':',90,115)
                doc.text(this.detailProduct.coll_month||'-',100,115)

                //label center
                doc.text('Theme',centerize-200,55)
                doc.text(':',centerize-150,55)
                doc.text(this.detailProduct.collection_name||'-',centerize-140,55)

                doc.text('Subbrand',centerize-200,55+20)
                doc.text(':',centerize-150,55+20)
                doc.text(this.detailProduct.subbrand||'-',centerize-140,55+20)

                doc.text('Category',centerize-200,55+20+20)
                doc.text(':',centerize-150,55+20+20)
                doc.text(this.detailProduct.category||'-',centerize-140,55+20+20)

                

                doc.text('Article',centerize-200,55+20+20+20)
                doc.text(':',centerize-150,55+20+20+20)
                doc.text(this.detailProduct.article||'-',centerize-140,55+20+20+20)

                //label right
                if(this.designData.length > 0){
                    doc.addImage(this.designData[0].img_src,'jpeg',centerize+250, 50,70,70)
                }
                
                doc.text('Designer',centerize+30,55)
                doc.text(':',centerize+130,55)
                doc.text(this.detailProduct.creator_name||'-',centerize+140,55)

                doc.text('Date Created',centerize+30,75)
                doc.text(':',centerize+130,75)
                doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,75)

                doc.text('Date Submitted',centerize+30,95)
                doc.text(':',centerize+130,95)
                doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,95)

                doc.text('Date Modified',centerize+30,115)
                doc.text(':',centerize+130,115)
                doc.text('',centerize+140,115)

                doc.setLineWidth(0.5)
                doc.line(10,125,rights-10,125)

                    doc.text('Code',15,130+20)
                    doc.text('Brand',15,150+20)
                    doc.text('Category',15,170+20)
                    doc.text('Type',15,190+20)
                    doc.text('Description',15,210+20)
                    doc.text('Detail',15,230+20)
                    doc.text('Used as',15,250+20)
                    doc.text('Color',15,270+20)

                    

                    doc.text(': '+(this.materialProduct[i].code||'-'),100,130+20)
                    doc.text(': '+(this.materialProduct[i].brand||'-'),100,150+20)
                    doc.text(': '+(this.materialProduct[i].category||'-'),100,170+20)
                    doc.text(': '+(this.materialProduct[i].type||'-'),100,190+20)
                    doc.text(': '+(this.materialProduct[i].description||'-'),100,210+20)
                    doc.text(': '+(this.materialProduct[i].detail||'-'),100,230+20)
                    doc.text(': '+(this.materialProduct[i].used_as||'-'),100,250+20)
                    doc.text(': '+(this.materialProduct[i].color||'-'),100,270+20)
                    
                    
                    // if(this.materialProduct[i].img_src){
                    //     doc.addImage(this.materialProduct[i].img_src,'JPEG',centerize+30, 150,248,350.8)
                    // }

                    if(this.materialProduct[i].category === 'fabric'){
                        doc.text('Yarn Size',15,290+20)
                        doc.text('Texture',15,310+20)
                        doc.text('Month',15,330+20)
                        doc.text('Year',15,350+20)
                        doc.text('Weight',15,370+20)
                        doc.text('Weaving Type',15,390+20)
                        doc.text('Composition',15,410+20)
                        doc.text('Construction',15,430+20)
                        doc.text('Code Handloom',15,450+20)

                        doc.text(': '+(this.materialProduct[i].yarn_size||'-'),100,290+20)
                        doc.text(': '+(this.materialProduct[i].texture||'-'),100,310+20)
                        doc.text(': '+(this.materialProduct[i].month||'-'),100,330+20)
                        doc.text(': '+(this.materialProduct[i].year||'-'),100,350+20)
                        doc.text(': '+(this.materialProduct[i].weight||'-'),100,370+20)
                        doc.text(': '+(this.materialProduct[i].weaving_type||'-'),100,390+20)
                        doc.text(': '+(this.materialProduct[i].composition||'-'),100,410+20)
                        doc.text(': '+(this.materialProduct[i].construction||'-'),100,430+20)
                        doc.text(': '+(this.materialProduct[i].code_handloom||'-'),100,450+20)
                    }

                    doc.addPage("a4","p")
                    var widthMaterial = doc.internal.pageSize.getWidth();
                    var heightMaterial = doc.internal.pageSize.getHeight();
                    if(this.materialProduct.length > 0){
                    if(this.materialProduct[i].img_src){
                        doc.addImage(this.materialProduct[i].img_src,'JPEG',0, 0,widthMaterial,heightMaterial)
                        
                    }
                    }
            }

            
            // doc.addPage("a4","p")
            // doc.addImage(this.materialProduct[0].img_src,'JPEG',0, 0,widthTech,heightTech)

            // doc.addImage(this.hangtagData[0].img_src,'JPEG',0, 0,widthMaterial,heightMaterial)
            // console.log('hangtag', this.hangtagData)

            // add image code main label, code streamer label, code size label, code hang tag
            if(this.codeMainLabelData.length > 0){
            doc.addPage("a4","p")
            doc.addImage(this.codeMainLabelData[0].img_src,'JPEG',0, 0,widthTech,heightTech)
            }
            if(this.codeStreamerData.length > 0){
            doc.addPage("a4","p")
            doc.addImage(this.codeStreamerData[0].img_src,'JPEG',0, 0,widthTech,heightTech)
            }
            if(this.codeSizeLabelData.length > 0){
            doc.addPage("a4","p")
            doc.addImage(this.codeSizeLabelData[0].img_src,'JPEG',0, 0,widthTech,heightTech)
            }
            if(this.hangtagData.length > 0){
            doc.addPage("a4","p")
            doc.addImage(this.hangtagData[0].img_src,'JPEG',0, 0,widthTech,heightTech)
            }
            

            doc.autoTable({ 
                startY:150,
                theme:'grid'
            
            })

            doc.addPage("a4","l")
            doc.setLineWidth(1.5)
            doc.rect(10,10,rights-20,tinggi-20)

            doc.setFontSize(14)
            doc.text(this.detailProduct.article,centerize,30,{align:'center'})
            doc.text(this.detailProduct.brand,rights-Object.keys(this.detailProduct.brand).length-50,30,{align:'right'})
            doc.setFontSize(8)
            doc.text('Garment/Apparel Sample Review',centerize,40,"center")

            doc.setLineWidth(0.5)
            doc.line(10,45,rights-10,45)

            //label left
            doc.setFontSize(10)
            doc.text('Brand',15,55)
            doc.text(':',90,55)
            doc.text(this.detailProduct.brand,100,55)

            doc.text('Quartal',15,75)
            doc.text(':',90,75)
            doc.text(this.detailProduct.quartal,100,75)

            doc.text('Prod. Month',15,95)
            doc.text(':',90,95)
            doc.text(this.detailProduct.prod_month,100,95)

            doc.text('Coll. Month',15,115)
            doc.text(':',90,115)
            doc.text(this.detailProduct.coll_month,100,115)

            //label center
            doc.text('Theme',centerize-200,55)
            doc.text(':',centerize-150,55)
            doc.text(this.detailProduct.collection_name,centerize-140,55)

            doc.text('Subbrand',centerize-200,55+20)
            doc.text(':',centerize-150,55+20)
            doc.text(this.detailProduct.subbrand,centerize-140,55+20)

            doc.text('Category',centerize-200,55+20+20)
            doc.text(':',centerize-150,55+20+20)
            doc.text(this.detailProduct.category,centerize-140,55+20+20)

            

            doc.text('Article',centerize-200,55+20+20+20)
            doc.text(':',centerize-150,55+20+20+20)
            doc.text(this.detailProduct.article,centerize-140,55+20+20+20)

            
            //label right
            doc.text('Designer',centerize+30,55)
            doc.text(':',centerize+130,55)
            doc.text(this.detailProduct.creator_name||'-',centerize+140,55)

            doc.text('Date Created',centerize+30,75)
            doc.text(':',centerize+130,75)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,75)

            doc.text('Date Submitted',centerize+30,95)
            doc.text(':',centerize+130,95)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,95)

            doc.text('Date Modified',centerize+30,115)
            doc.text(':',centerize+130,115)
            doc.text('',centerize+140,115)

            doc.setLineWidth(0.5)
            doc.line(10,125,rights-10,125)

            doc.line(10,145,rights-10,145)
            doc.text('Size Specification',centerize,140)

            doc.autoTable({ 
                html: '#table-sizespec',
                startY:150,
                theme:'grid'
            
            })

            //proto size
            doc.addPage("a4","l")
            doc.setLineWidth(1.5)
            doc.rect(10,10,rights-20,tinggi-20)

            doc.setFontSize(14)
            doc.text(this.detailProduct.article,centerize,30,{align:'center'})
            doc.text(this.detailProduct.brand,rights-Object.keys(this.detailProduct.brand).length-50,30,{align:'right'})
            doc.setFontSize(8)
            doc.text('Garment/Apparel Sample Review',centerize,40,"center")

            doc.setLineWidth(0.5)
            doc.line(10,45,rights-10,45)

            //label left
            doc.setFontSize(10)
            doc.text('Brand',15,55)
            doc.text(':',90,55)
            doc.text(this.detailProduct.brand,100,55)

            doc.text('Quartal',15,75)
            doc.text(':',90,75)
            doc.text(this.detailProduct.quartal,100,75)

            doc.text('Prod. Month',15,95)
            doc.text(':',90,95)
            doc.text(this.detailProduct.prod_month,100,95)

            doc.text('Coll. Month',15,115)
            doc.text(':',90,115)
            doc.text(this.detailProduct.coll_month,100,115)

            //label center
            doc.text('Theme',centerize-200,55)
            doc.text(':',centerize-150,55)
            doc.text(this.detailProduct.collection_name,centerize-140,55)

            doc.text('Subbrand',centerize-200,55+20)
            doc.text(':',centerize-150,55+20)
            doc.text(this.detailProduct.subbrand,centerize-140,55+20)

            doc.text('Category',centerize-200,55+20+20)
            doc.text(':',centerize-150,55+20+20)
            doc.text(this.detailProduct.category,centerize-140,55+20+20)

            

            doc.text('Article',centerize-200,55+20+20+20)
            doc.text(':',centerize-150,55+20+20+20)
            doc.text(this.detailProduct.article,centerize-140,55+20+20+20)

            
            //label right
            doc.text('Designer',centerize+30,55)
            doc.text(':',centerize+130,55)
            doc.text(this.detailProduct.creator_name||'-',centerize+140,55)

            doc.text('Date Created',centerize+30,75)
            doc.text(':',centerize+130,75)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,75)

            doc.text('Date Submitted',centerize+30,95)
            doc.text(':',centerize+130,95)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,95)

            doc.text('Date Modified',centerize+30,115)
            doc.text(':',centerize+130,115)
            doc.text('',centerize+140,115)

            doc.setLineWidth(0.5)
            doc.line(10,125,rights-10,125)

            doc.line(10,145,rights-10,145)
            doc.text('Size Specification',centerize,140)

            doc.autoTable({ 
                html: '#table-protosize',
                startY:150,
                theme:'grid'
            
            })



            // sample review
            doc.addPage("a4","l")
            doc.setLineWidth(1.5)
            doc.rect(10,10,rights-20,tinggi-20)

            doc.setFontSize(14)
            doc.text(this.detailProduct.article,centerize,30,{align:'center'})
            doc.text(this.detailProduct.brand,rights-Object.keys(this.detailProduct.brand).length-50,30,{align:'right'})
            doc.setFontSize(8)
            doc.text('Garment/Apparel Sample Review',centerize,40,"center")

            doc.setLineWidth(0.5)
            doc.line(10,45,rights-10,45)

            //label left
            doc.setFontSize(10)
            doc.text('Brand',15,55)
            doc.text(':',90,55)
            doc.text(this.detailProduct.brand,100,55)

            doc.text('Quartal',15,75)
            doc.text(':',90,75)
            doc.text(this.detailProduct.quartal,100,75)

            doc.text('Prod. Month',15,95)
            doc.text(':',90,95)
            doc.text(this.detailProduct.prod_month,100,95)

            doc.text('Coll. Month',15,115)
            doc.text(':',90,115)
            doc.text(this.detailProduct.coll_month,100,115)

            //label center
            doc.text('Theme',centerize-200,55)
            doc.text(':',centerize-150,55)
            doc.text(this.detailProduct.collection_name,centerize-140,55)

            doc.text('Subbrand',centerize-200,55+20)
            doc.text(':',centerize-150,55+20)
            doc.text(this.detailProduct.subbrand,centerize-140,55+20)

            doc.text('Category',centerize-200,55+20+20)
            doc.text(':',centerize-150,55+20+20)
            doc.text(this.detailProduct.category,centerize-140,55+20+20)

            

            doc.text('Article',centerize-200,55+20+20+20)
            doc.text(':',centerize-150,55+20+20+20)
            doc.text(this.detailProduct.article,centerize-140,55+20+20+20)

            
            //label right
            //label right
            doc.text('Designer',centerize+30,55)
            doc.text(':',centerize+130,55)
            doc.text(this.detailProduct.creator_name||'-',centerize+140,55)

            doc.text('Date Created',centerize+30,75)
            doc.text(':',centerize+130,75)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,75)

            doc.text('Date Submitted',centerize+30,95)
            doc.text(':',centerize+130,95)
            doc.text(new Date(this.detailProduct.created_at).toLocaleString("id-ID")||'-',centerize+140,95)

            doc.text('Date Modified',centerize+30,115)
            doc.text(':',centerize+130,115)
            doc.text('',centerize+140,115)

            doc.setLineWidth(0.5)
            doc.line(10,125,rights-10,125)

            doc.line(10,145,rights-10,145)
            doc.text('Sample Comment',centerize,140)

            doc.autoTable({ 
                html: '#table-comment',
                startY:150,
                theme:'grid'
            
            })
            
            if(button === 'preview'){
                window.open(doc.output('bloburl',this.detailProduct.article))
            }else{
                doc.save(this.detailProduct.article+'.pdf')
            }


        },
        // loadDesignData(){
        //     let designImages = this.productImg
        //     console.log('design img c',designImages.filter(p => p.img_label =='design'))
        //     this.designData = designImages
        // }
    },
    
    created(){
        this.getProductDetail()
        this.getMaterial()
        this.getDataImg()
        this.getProduct()
        this.getApproveHistory()
        this.getActivity()
        this.getDataParam()
        this.getDataSupplier()
        // this.loadDesignData()
        
    },
    mounted(){
        this.getSize()
        
    },
    computed:{
        // loadDesign(){
        //     let designImages = this.productImg.filter(i=>i.img_label =='design')
        //     console.log('design img c',designImages);
        //     return designImages
        // },
        // loadTech(){
        //     let techImages = this.productImg.filter(i => i.img_label =='technical-drawing')
        //     return techImages
        // }
    }

}
</script>