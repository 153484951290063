<template>
  <div class="table-basic">
    <b-container fluid="sm">
      <b-row align-h="center" style="margin-top: 100px">
        <b-col cols="12" sm="12" lg="8">
          <b-card
            style="max-width: 100%; padding-top: 30px; padding-bottom: 30px"
          >
            <b-card-title class="title-card mb-2 text-center"
              >WHISTLEBLOWING SYSTEM</b-card-title
            >
            <b-card-text class="body-card-text">
              <p>
                Pelaporan terkait Board of Director (BOD) dapat langsung menghubungi: <br/>
                Reports related to the Board of Directors (BOD) can be directly contacted:
              </p>
              <br />
              <p><label><img src="https://www.bbi-apparel.com/uploads/wbs/wbs-mail.jpg" width="30"></label>: {{ email }}</p>
              <p><label><img src="https://www.bbi-apparel.com/uploads/wbs/wbs-wa.jpg" width="30"></label>: {{ phoneNumber }}</p>
            </b-card-text>
            <div class="text-center">
              <router-link to="/app/dashboard" class="router-link"
                ><b-icon icon="arrow-left"></b-icon>Back
              </router-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { fnGETWBS, fnPOSTWBS } from "../../api/api-methods";
import { APIPATH_WBS } from "../../api/api-paths";
export default {
  name: "ContactComplaint",
  data() {
    return {
      phoneNumber: null,
      email: null,
    };
  },
  created() {
    this.getDataContact();
  },
  methods: {
    getDataContact() {
      fnGETWBS(APIPATH_WBS.getcontactwbs)
        .then((rsp) => {
          const data = rsp.data.payload;
          this.phoneNumber = data[0].p_charfield1;
          this.email = data[0].p_charfield2;
          console.log("test", this.phoneNumber);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.title-card {
  font-size: 2.500em;
  font-weight: bold;
  color: #595959;
  font-weight: 600;
}
@media only screen and (max-width: 480px) {
.title-card {
  font-size: 2.00em;
}
.body-card-text label p {
  font-weight: 700;
  font-size: 15px;
}
}
.body-card-text {
  text-align: left;
  padding-top: 30px;
  font-weight: 700;
}
.body-card-text p {
  margin: 0;
}
.body-card-text label {
  width: 35px;
  font-weight: 700;
}
.router-link {
  color: #00008b;
  font-size: 17px;
  font-weight: 700;
}
</style>