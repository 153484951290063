import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchHealthHistory,
    fnApiFetchHealthHistoryV2,
    fnApiFetchCityList,
} from '../../api/api-applications-health.js'

Vue.use(Vuex)


const actions = {
    postHealthHistory(context, formData) {
        // console.log(formSelfDeclaration)
      return new Promise((resolve, reject) => {
        fnApiFetchHealthHistory(formData)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
      })
    },
    postHealthHistoryV2(context, formData) {
        // console.log(formSelfDeclaration)
      return new Promise((resolve, reject) => {
        fnApiFetchHealthHistoryV2(formData)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
      })
    },
  }

export default new Vuex.Store({
    namespaced: true,
    actions,
})
