import {
  fnApiFetchRptAccess,
  fnApiFetchRptDataDailyHealth,
  fnApiFetchRptDataRapidTest,
  fnApiFetchRptDataHealthDeclaration,
  fnApiFetchRptDataHealthDeclarationV2,
  fnApiFetchRptDataHealthHistory,
  fnApiFetchRptDataHealthHistoryV2,
  fnApiFetchListDepartment,
  fnApiFetchListAltDepartment,
  fnApiFetchRptDataDailyHealthV2,
  fnApiFetchRptDataDailyHealthV3,
  fnApiFetchRptDataDailyHealthV4,
  fnApiFetchRptDataDailyHealthV5,
  fnApiFetchRptDataDailyHealthVerification,
  fnApiFetchPivotRptDailySales,
  fnApiFetchPivotRptProductivity,
  fnApiFetchPivotRptBrand,
} from "../api/api-reports";
// import { resolve } from 'core-js/fn/promise';
import download from "downloadjs";
import { forEach, filter } from "core-js/fn/array";
import { resolve, reject } from "core-js/fn/promise";

function groupBy(data, key) {
  const result = {};
  let jsonArr = data;
  let array = [];
  // (manually) convert JSON Array to Array Object
  for (var i in jsonArr) {
    array.push(jsonArr[i]);
  }
  array.forEach((item) => {
    if (item["enabled"] == true) {
      if (!result[item[key]]) {
        result[item[key]] = [];
      }
      result[item[key]].push(item);
    }
  });
  return result;
}

function base64ToBlob(data, mime) {
  let base64 = window.btoa(window.unescape(encodeURIComponent(data)));
  let bstr = atob(base64);
  let n = bstr.length;
  let u8arr = new Uint8ClampedArray(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const getDefaultState = () => {
  return {
    stActiveReport: localStorage.getItem("activeReport") || null,
    stRptAccess: null,
    stDeptList: null,
    stRptData: null,
    stRptDatav4: null,
    stRptDataCountv4: 0,
    stRptDataCount: 0,
  };
};
const state = getDefaultState();
const getters = {
  rptAccess(state) {
    return state.stRptAccess;
  },
  rptData(state) {
    return state.stRptData;
  },
  rptDatav4(state) {
    return state.stRptDatav4;
  },
  rptDataCountv4(state) {
    return state.stRptDataCountv4;
  },
  rptDataCount(state) {
    return state.stRptDataCount;
  },
  activeReport(state) {
    return state.stActiveReport;
  },
  hasData(state) {
    if (state.stRptDataCount > 0) return true;
    return false;
  },
  hasDatav4(state) {
    if (state.stRptDataCountv4 > 0) return true;
    return false;
  },
  DeptList(state) {
    return state.stDeptList;
  },
};
const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  updateRptAccess(state, data) {
    state.stRptAccess = data;
  },
  updateRptData(state, data) {
    state.stRptData = data;
    state.stRptDataCount = data.length;
  },
  updateRptDataV4(state, data) {
    state.stRptDatav4 = data;
    state.stRptDataCountv4 = data.length;
  },
  updateDeptList(state, data) {
    // state.stDeptList = JSON.parse(JSON.stringify(data))
    state.stDeptList = data;
  },
  updateActiveReport(state, rptName) {
    state.stActiveReport = rptName;
    localStorage.setItem("activeReport", rptName);
  },
  initReportState(state) {
    // Mutation used when opening report page for the first time & still maintaining it current state
    // Although it only retain Active Report Name
    // If you want to clear all state, use action "clearState" instead
    state.stDeptList = null;
    state.stRptData = null;
    state.stRptDataCount = 0;
  },
};
const actions = {
  setActiveReport({ commit }, rptName) {
    commit("updateActiveReport", rptName);
  },
  getReportAccess({ commit }, params = null) {
    fnApiFetchRptAccess(params)
      .then((rsp) => {
        let data = JSON.stringify(rsp.data.payload.rpt_list);
        let lookup = {};
        let result = [];
        if (data) {
          // let items = JSON.parse(data);
          let items = rsp.data.payload.rpt_list;
          for (let i = 0; i < items.length; i++) {
            let item = items[i];
            let name = item.group;
            if (!(name in lookup)) {
              lookup[name] = 1;
              result.push(name);
            }
          }
        }
        let newData = groupBy(rsp.data.payload.rpt_list, "group");

        commit("updateRptAccess", newData);
      })
      .catch((err) => {
        Promise.reject(err);
      });
  },
  getListDepartment({ commit }, businessunit = null) {
    fnApiFetchListDepartment(businessunit).then((rsp) => {
      commit("updateDeptList", rsp.data.payload);
    });
  },
  getListDepartmentSemarang({ commit }) {
    fnApiFetchListDepartment().then((rsp) => {
      commit("updateDeptList", rsp.data.payload);
    });
  },
  getDataDailyHealth({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataDailyHealth(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataDailyHealthV2({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataDailyHealthV2(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataDailyHealthV3({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataDailyHealthV3(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataDailyHealthV4({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataDailyHealthV4(filters)
        .then((rsp) => {
          commit("updateRptDataV4", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataDailyHealthV5({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataDailyHealthV5(filters)
        .then((rsp) => {
          commit("updateRptDataV4", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataDailyHealthVerification({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataDailyHealthVerification(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataHealthDeclaration({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataHealthDeclaration(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataHealthDeclarationV2({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataHealthDeclarationV2(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataRapidTest({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataRapidTest(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataHealthHistory({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchRptDataHealthHistory(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getDataHealthHistoryV2({commit}, filters) {
      return new Promise((resolve, reject) => {
          fnApiFetchRptDataHealthHistoryV2(filters).then( rsp => {
              commit('updateRptData', rsp.data.payload)
              if(rsp.data.payload.length == 0) {
                  resolve('No Data')
              }
              resolve(rsp)
          }).catch(err => {
              reject(err)
          })
      })
  },
  getPivotDataDailySales({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchPivotRptDailySales(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPivotDataProductivity({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchPivotRptProductivity(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPivotDataBrand({ commit }, filters) {
    return new Promise((resolve, reject) => {
      fnApiFetchPivotRptBrand(filters)
        .then((rsp) => {
          commit("updateRptData", rsp.data.payload);
          if (rsp.data.payload.length == 0) {
            resolve("No Data");
          }
          resolve("Success");
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  clearState({ commit }) {
    commit("resetState");
    localStorage.removeItem("activeReport");
  },
  initializeReport({ commit }) {
    commit("initReportState");
  },
  generateTitleXLS({ commit }, params) {
    let excelFullName = params.rptName + " " + params.rptVersion;
    let excelFileName =
      params.rptName + " " + params.processedDateTime + ".xls";

    excelFullName += "<br />";
    excelFullName +=
      " (Processed by: " +
      params.processedBy +
      " at " +
      params.processedDateTime +
      ")";
    if(params.additional) {
      excelFullName += "<br />" + params.additional
    }

    return [excelFileName, excelFullName];
  },
  getCurrentDatetime() {
    const today = new Date();
    const year = today.getFullYear();
    const month = ("0" + (today.getMonth() + 1)).slice(-2);
    const date = ("0" + today.getDate()).slice(-2);
    const fullDate = year + "-" + month + "-" + date;

    let hours = ("0" + today.getHours()).slice(-2);
    let minutes = ("0" + today.getMinutes()).slice(-2);
    let seconds = ("0" + today.getSeconds()).slice(-2);
    const time = hours + ":" + minutes + ":" + seconds;
    const dateTime = fullDate + " " + time;

    return dateTime;
  },
  // param : tblElement, [fileName], [sheetName], [strHeader], [strFooter]
  // exportHTMLToXls({tblElement, fileName='export_data.xls', sheetName='Sheet1', strHeader=null, strFooter=null}={}) {
  exportHTMLToXls(
    { commit },
    {
      tblElement,
      fileName = "export_data.xls",
      sheetName = "Sheet1",
      strHeader = null,
      strFooter = null,
    }
  ) {
    let format = function(s, c) {
      return s.replace(/{(\w+)}/g, function(m, p) {
        return c[p];
      });
    };
    let mime = "application/vnd.ms-excel";
    let template =
      '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>{header}{content}{footer}</body></html>';
    let xlsContent = "<table>" + tblElement.innerHTML + "</table>";
    let colSize = tblElement.rows[0].cells.length;
    let ctx = {
      worksheet: sheetName,
      content: xlsContent,
      header: "",
      footer: "",
    };

    // Generate Excel Header
    if (strHeader != null) {
      let xlsHeader =
        '<table><tr><th colspan="' +
        colSize +
        '">' +
        strHeader +
        "</th></tr></table>";
      ctx.header = xlsHeader;
    }
    // Generate Excel Footer
    if (strFooter != null) {
      let xlsFooter =
        '<table><tr><td colspan="' +
        colSize +
        '">' +
        strFooter +
        "</td></tr></table>";
      ctx.footer = xlsFooter;
    }
    let data = format(template, ctx);
    let blob = base64ToBlob(data, mime);

    download(blob, fileName, mime);
    // window.location.href = uri + base64(format(this.template, ctx))
  },
  manipulatePivotUI({ commit }, pivotElement) {
    console.log(pivotElement);
    let dictReplace = [
      // { key:"Count", value:"Count" },
      { key: "Count Unique Values", value: "Count Uniques" },
      { key: "List Unique Values", value: "List Uniques" },
      // { key:"Sum", value:"Sum" },
      // { key:"Integer Sum", value:"Integer Sum" },
      // { key:"Average", value:"Average" },
      // { key:"Median", value:"Median" },
      { key: "Sample Variance", value: "Sample Variance" },
      { key: "Sample Standard Deviation", value: "Sample Std. Deviation" },
      // { key:"Minimum", value:"Minimum" },
      // { key:"Maximum", value:"Maximum" },
      // { key:"First", value:"First" },
      // { key:"Last", value:"Last" },
      // { key:"Sum over Sum", value:"Sum over Sum" },
      { key: "Sum as Fraction of Total", value: "Sum per Total" },
      { key: "Sum as Fraction of Rows", value: "Sum per Rows" },
      { key: "Sum as Fraction of Columns", value: "Sum per Columns" },
      { key: "Count as Fraction of Total", value: "Count per Total" },
      { key: "Count as Fraction of Rows", value: "Count per Rows" },
      { key: "Count as Fraction of Columns", value: "Count per Columns" },
    ];

    dictReplace.forEach((element) => {
      let qrySel = 'option[value="' + element.key + '"]';
      let test1 = pivotElement.querySelector(qrySel);
      test1.innerHTML = element.value;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
