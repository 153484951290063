/* eslint-disable no-console */
import axios from "axios";
import store from "../store/login";
import router from "../Routes.js";

/* COMMENTED DUE TO BBI SEMARANG REQUEST. Implement Dynamic Backend */
// const APIUrl = process.env.VUE_APP_BASE_URL
let APIDomain = new URL(window.location.href);
// let APIDomain = new URL("http://192.168.20.16/");
// let APIPort = "8000";
let APIPort = "8005";
let APIPortCore = "8005";
let WBSPort = "8100";

let WFPort = "";
let ReportPort = "";
let ImageUrl = '8000';

let protocol = "https://"

if(APIDomain.hostname == "localhost"){
  WFPort = "8006"
  APIPortCore = '8005'
  ReportPort = "8008"
  protocol = "http://"
}else{
  WFPort = "8005"
  ReportPort = "8005"
}
let APIUrlReport = protocol + APIDomain.hostname + ":" + ReportPort;
let APIUrlWF = protocol + APIDomain.hostname + ":" + WFPort;
let APIUrl = protocol + APIDomain.hostname + ":" + APIPort;
let APIUrlCore = protocol + APIDomain.hostname + ":" + APIPortCore;
let APIUrlWBS = protocol + APIDomain.hostname + ":" + WBSPort;
let Api_Multimedia = protocol + APIDomain.hostname + ":" + ImageUrl;

/// API handler for unauthorized request
const axiosBase = axios.create({
  namespaced: true,
  baseURL: APIUrl,
  // headers: { 'Content-Type': 'application/json' }
});

const axiosBaseWf = axios.create({
  namespaced: true,
  baseURL: APIUrlWF,
  // headers: { 'Content-Type': 'application/json' }
});

/// API handler for report
const getAPIReport = axios.create({
  namespaced: true,
  baseURL: APIUrlReport,
});

/// API handler for authorized request
const getAPI = axios.create({
  namespaced: true,
  baseURL: APIUrl,
});
const apiMultimedia = axios.create({
  namespaced: true,
  baseURL: Api_Multimedia,
});
const apiPlm = axios.create({
  namespaced: true,
  baseURL: Api_Multimedia,
});
/// API handler for unauthorized request
const axiosBaseWbs = axios.create({
  namespaced: true,
  baseURL: APIUrlWBS,
  // headers: { 'Content-Type': 'application/json' }
});
/// API handler for unauthorized request
const getAPICoreUnauth = axios.create({
  namespaced: true,
  baseURL: APIUrlCore,
  // headers: { 'Content-Type': 'application/json' }
});
/// API handler for authorized request
const getAPICore = axios.create({
  namespaced: true,
  baseURL: APIUrlCore,
});

axiosBase.interceptors.response.use(undefined, function(err) {
  // if error response status is 401, it means the request was invalid due to expired access token
  if (!err.response) {
    alert(
      "Cannot connect to backend service, please contact IT Administrator."
    );

    store.dispatch("logoutUser");
  }
  return Promise.reject(err);
});

getAPIReport.interceptors.request.use(
  (config) => {
    // const token = store.getters.accessToken
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

getAPI.interceptors.request.use(
  (config) => {
    // const token = store.getters.accessToken
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosBaseWf.interceptors.request.use(
  (config) => {
    // const token = store.getters.accessToken
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

getAPI.interceptors.response.use(undefined, function(err) {
  // if error response status is 401, it means the request was invalid due to expired access token
  if (!err.response) {
    console.log("network error");
    alert(
      "### Auto Log Out ###\nCannot connect to backend service, please contact IT Administrator."
    );

    store.dispatch("logoutUser");
  } else if (err.config && err.response && err.response.status === 401) {
    // Logout and redirect back to login page
    alert("### Auto Log Out ###\nYour session is over. Please log in..");

    store.dispatch("logoutUser");
  } else if (err.config && err.response && err.response.status === 500) {
    alert("Failed processing your request, please contact IT Administrator");
    console.log(err);
  }

  return Promise.reject(err);
});

getAPI.interceptors.response.use(function(resp){
  localStorage.setItem("redirect_url",window.location.href)
  return Promise.resolve(resp)
}, function(err) {
  if (!err.response) {
    console.log("network error");
    alert(
      "### Auto Log Out ###\nCannot connect to backend service, please contact IT Administrator."
    );

    store.dispatch("logoutUser");
  } else if (err.config && err.response && err.response.status === 401) {
    // Logout and redirect back to login page
    alert("### Auto Log Out ###\nYour session is over. Please log in..");

    store.dispatch("logoutUser");
  } else if (err.config && err.response && err.response.status === 500) {
    alert("Failed processing your request, please contact IT Administrator");
    console.log(err);
  }

  return Promise.reject(err);
});
//
getAPICoreUnauth.interceptors.response.use(undefined, function(err) {
  // if error response status is 401, it means the request was invalid due to expired access token
  if (!err.response) {
    alert(
      "Cannot connect to backend service, please contact IT Administrator."
    );

    store.dispatch("logoutUser");
  }
  return Promise.reject(err);
});

getAPICore.interceptors.request.use(
  (config) => {
    // const token = store.getters.accessToken
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

getAPICore.interceptors.response.use(undefined, function(err) {
  // if error response status is 401, it means the request was invalid due to expired access token
  if (!err.response) {
    console.log("network error");
    alert(
      "### Auto Log Out ###\nCannot connect to backend service, please contact IT Administrator."
    );

    store.dispatch("logoutUser");
  } else if (err.config && err.response && err.response.status === 401) {
    // Logout and redirect back to login page
    alert("### Auto Log Out ###\nYour session is over. Please log in..");

    store.dispatch("logoutUser");
  } else if (err.config && err.response && err.response.status === 500) {
    alert("Failed processing your request, please contact IT Administrator");
    console.log(err);
  }

  return Promise.reject(err);
});

apiMultimedia.interceptors.request.use((config) => {
  // const token = store.getters.accessToken
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

apiPlm.interceptors.request.use((config) => {
  // const token = store.getters.accessToken
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  return config;
});


const userAPI = axios.create({
  namespaced: true,
  baseURL: APIUrl,
});

userAPI.interceptors.request.use(
  (config) => {
    // const token = store.getters.accessToken
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosBase, axiosBaseWf, getAPI, getAPIReport, apiMultimedia, APIUrl, axiosBaseWbs, getAPICore, getAPICoreUnauth,apiPlm, userAPI };
