<template>
    <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/appsppcm-dashboard-inspection"> Dashboard Inspection</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
    </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card header="" header-tag="header">
                        <template v-slot:header>
                            <div class="text-left">
                                <div class="container">
                                    <h3 style='font-weight:bold;'>Input Inspection Form</h3>
                                </div>
                            </div>
                        </template>

                            <b-row>
                                <b-col sm="auto" lg="4">
                                    <b-form-group
                                        label               ="IO / PO"
                                        label-for           ="inputIO">
                                        <b-form-input readonly id="inputIO" v-model="form.data_io"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="auto" lg="4">
                                    <b-form-group
                                        label               ="Subcon"
                                        label-for           ="inputSubcon">
                                        <b-form-input readonly id="inputSubcon" v-model="form.cutting_subcon"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="auto" lg="4">
                                    <b-form-group
                                        label               ="User"
                                        label-for           ="inputUser">
                                        <b-form-input readonly id="inputUser" v-model="form.employee"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="auto" lg="3">
                                    <b-form-group
                                        label               ="Qty Cutting"
                                        label-for           ="inputcutting_progress">
                                        <b-form-input readonly id="inputcutting_progress" v-model="form.cutting_progress"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="auto" lg="3">
                                    <b-form-group
                                        label               ="Qty Plan"
                                        label-for           ="inputqty_plan">
                                        <b-form-input readonly id="inputqty_plan" v-model="form.qty_plan"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="auto" lg="3">
                                    <b-form-group
                                        label               ="Due Data"
                                        label-for           ="dueData">
                                        <b-form-input readonly id="dueData" v-model="due_date"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col sm="auto" lg="3">
                                    <b-form-group
                                        label               ="Cutting Date"
                                        label-for           ="cuttingDate">
                                        <b-form-input readonly id="cuttingDate" v-model="cutting_date"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-card>
                            <b-row align-h="center" style='margin-top:15px !important;'>
                                <b-col align-h="center" sm="auto" md='3' lg="3">
                                    <b-form-group
                                    label               ="Type"
                                    id                  ='form_select_instpection'
                                    label-for           ="InputdType">
                                    <validation-provider
                                        name="select-process"
                                        >
                                        <b-form-select class="form-custom" v-model="form.type" @change="OrderProcess()" :options="opt_type" placeholder="Choose Type" style="top: 1px; max-width: 200px;">
                                        </b-form-select>
                                    </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row align-h="center" style='margin-top:15px !important;'>
                                <b-col align-h="center" sm="auto" md='3' lg="3">
                                    <b-form-group
                                        label               ="Visit Inline"
                                        label-for           ="inputVisitInline"
                                        class               =""
                                        id                  ="visit-inline">
                                        <b-form-input class="select-custom" readonly id="inputVisitInline" v-model="form.visit_inline"  trim></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        label               ="Visit Final "
                                        label-for           ="inputVisitFinal"
                                        class               ="hidden"
                                        id                  ="visit-final">
                                        <b-form-input class="select-custom" readonly id="inputVisitInline" v-model="form.visit_final"  trim></b-form-input>
                                    </b-form-group>
                                </b-col>

                                <b-col align-h="center" sm="auto" md='3' lg="3">
                                    <b-form-group
                                        label               ="Qty shortship "
                                        label-for           ="inputVisitInline"
                                        class               ="hidden"
                                        id                  ="visit-final-qty">
                                        <b-form-input  type="number" class="select-custom" placeholder="Enter your Qty" v-model="form.qty_shortship"   trim></b-form-input>
                                    </b-form-group>
                                </b-col>
                                <b-col align-h="center" sm="auto" md='3' lg="3">

                                    <b-form-group
                                    label               ="Date Final : "
                                    id                  ="date-final"
                                    class               ="hidden"
                                    label-for           ="Inputdatefinal">
                                        <VueDatePicker
                                            class="vd__div"
                                            v-model="DateFinal"
                                            no-header
                                            format="DD MMM YYYY"
                                            min-date="1900-01-01"
                                            :max-date="max_date"
                                            placeholder="Choose date"
                                            />
                                    </b-form-group>

                                    <b-form-group
                                    label               ="Date Inline :"
                                    id                  ="date-inline"
                                    class               =""
                                    label-for           ="Inputdateinline">
                                        <VueDatePicker
                                            class="vd__div"
                                            v-model="DateInline"
                                            no-header
                                            format="DD MMM YYYY"
                                            min-date="1900-01-01"
                                            :max-date="max_date"
                                            placeholder="Choose date"
                                            />
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <b-row id="keterangan-final" align-v='center' align-h="center" style='margin-top:15px !important;'>
                                <b-col sm="auto" md='3' lg="3">
                                        <b-form-group
                                        label               ="Reason "
                                        label-for           ="inputReason"
                                        class               ="hidden"
                                        id                  ="keterangan-final-2">
                                        <b-form-select class="form-custom" v-model="form.keterangan_final" :options="opt_reason" placeholder="Choose Type" style="top: 1px; max-width: 200px;">
                                        </b-form-select>
                                        </b-form-group>
                                </b-col>
                                <b-col sm="auto" md='6' lg="6">
                                        <b-form-group
                                        label               ="Description"
                                        label-for           ="inputReason"
                                        class               ="hidden"
                                        id                  ="keterangandetail-final-2">
                                        <b-form-textarea
                                            id="textarea-state"
                                            v-model="form.keterangan_final_detail"
                                            placeholder="Enter Detail Reason"
                                            no-resize
                                            rows="3"
                                        ></b-form-textarea>
                                        </b-form-group>
                                </b-col>
                            </b-row>

                               <!-- <b-row align-h="center">
                                <table class="table table-lg">
                                    <tbody>s
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Date Cutting</span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi" v-if="form.cutting_date !== null" v-html="FormatDate(form.cutting_date)"></span>
                                            </td>
                                        </tr>

                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Due Date </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi" v-if="form.due_date !== null" v-html="FormatDate(form.due_date)"></span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">IO </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.data_io}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Subcon </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_subcon}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Article </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.artikel}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Sample </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.sample}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Plan </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.qty_plan}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty Cutting </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.cutting_progress}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Type </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">:
                                                    <validation-provider
                                                        name="select-process"
                                                        >
                                                        <b-form-select class="form-custom" v-model="form.type" @change="OrderProcess()" :options="opt_type" placeholder="Choose Type" style="top: 1px; max-width: 200px;">

                                                    </b-form-select>
                                                    </validation-provider>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Visit </span>
                                            </td>
                                            <td style="border:0px">
                                                <span id="visit-inline" class="fw-semi-bold fontbbi">: {{form.visit_inline}}</span>

                                                <span id="visit-final" class="fw-semi-bold fontbbi hidden">: {{form.visit_final}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Employee </span>
                                            </td>
                                            <td style="border:0px">
                                                <span class="fw-semi-bold fontbbi">: {{form.employee}}</span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td id="visit-final-qty" class="hidden" style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Qty shortship </span>
                                            </td>
                                            <td id="visit-final-qty-2" class="hidden" style="border:0px">
                                                <span class="fw-semi-bold fontbbi">
                                                <b-form-input  type="number" class="select-custom" placeholder="Enter your Qty" v-model="form.qty_shortship"   trim></b-form-input>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr id="table-info">
                                            <td id="table-td">

                                            </td>
                                            <td id="table-td">

                                            </td>
                                        </tr>


                                        <tr id="table-info" style="border:0px !important;">
                                            <td id="table-td" style="border:0px !important;">

                                            </td>
                                            <td id="table-td" style="border:0px !important;">

                                            </td>
                                        </tr>
                                        <tr style='margin-top:10px !important;' id="table-info" >
                                            <td id="keterangan-final" class="hidden" style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Options Reason</span>
                                            </td>
                                            <td id="keterangan-final-2" class="hidden" style="border:0px">
                                                <span class="fw-semi-bold fontbbi">
                                                <b-form-select class="form-custom" v-model="form.keterangan_final" :options="opt_reason" placeholder="Choose Type" style="top: 1px; max-width: 200px;">
                                                </b-form-select>
                                                </span>
                                            </td>
                                        </tr>
                                        <tr id="table-info" style='border-bottom:1px solid #AAA !important;'>
                                            <td id="keterangandetail-final" class="hidden" style="border:0px">
                                                <span class="fw-semi-bold fontbbi">Detail Reason</span>
                                            </td>
                                            <td id="keterangandetail-final-2" class="hidden" style="border:0px">
                                                <span class="fw-semi-bold fontbbi">
                                                <b-form-textarea
                                                id="textarea-state"
                                                v-model="form.keterangan_final_detail"
                                                placeholder="Enter Detail Reason"
                                                no-resize
                                                rows="3"
                                                ></b-form-textarea>
                                                </span>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                                </b-row> -->
                            </b-card>
                                <!-- <b-row align-h="center" style='margin-top:10px !important;'>

                                </b-row> -->
                                <br />
                                <br />
                               <b-row  align-h="center">
                                    <b-col sm="3">
                                        <b-button block  @click="Submit(1)" ref="save" type="button" variant="success">Pass</b-button>
                                    </b-col>
                                    <b-col sm="3">
                                        <b-button block  @click="Submit(2)" ref="save" type="button" variant="danger">Reject</b-button>
                                    </b-col>
                                </b-row>
                        </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

    import { mapState, mapActions, mapGetters } from 'vuex';
    import Vue from 'vue'
    import { extend } from 'vee-validate';
    import PostLogInspection from '@/store/ppm-form/ppm.js';
    import PostOrderReason from '@/store/ppm-form/ppm.js';
    import UpdateInspection from '@/store/ppm-form/ppm.js';
    import Loading from 'vue-loading-overlay';
    // Import vue loading stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    // Init plugin
    Vue.use(Loading);

    export default {
       name: 'PPCMInputInspection',

        data() {
            const now = new Date()
            const today = now.toISOString().slice(0,10)
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const time = now.toString().slice(16,24)
            return{
                formName: 'Form Inspection',
                data_reason:'inspection',
                errorMessage: null,
                max_date:today,
                time:time,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                today:today,
                opt_type: [
                    { value: 'inline', text: 'Inline' ,disabled: false },
                    { value: 'final', text: 'Final' ,disabled: false },
                ],
                opt_reason: [
                    { value: null, text: '--- Please Choice ---' ,disabled: true },
                ],
                keterangan_final:null,
                keterangan_final_detail:null,
                keterangan_final_last:null,
                oneDay:oneDay,
                calc_date:null,
                DateFinal:today,
                cutting_date:null,
                due_date:null,
                DateInline:today,
                boxTwo:'',
                    form : {
                    data_io:null,
                    Employee:null,
                    status:null,
                    type:'inline',
                    artikel:null,
                    sample:null,
                    brand:null,
                    subbrand:null,
                    manufacture_date:null,
                    total_qty:null,
                    due_date:null,
                    cutting_subcon :null,
                    cutting_progress:null,
                    visit_inline:null,
                    last_visit_inline:null,
                    start_visit_inline:null,
                    status_inline:null,
                    visit_final:null,
                    last_visit_final:null,
                    start_visit_final:null,
                    status_final:null,
                    qty_shortship:0,
                    keterangan_final:null,
                    keterangan_final_detail:null,
                    },
            }
        },
        computed: {
            ...mapGetters('session', ['userData','localDate', 'serverDate']),
            ...mapState('ppm', ['stPPCMInsData','stPPCMInsDataCount','StDataReason','StDataReasonCount']),


        },
        methods: {
            ...mapActions('session', ['showNotification']),
            ...mapActions('ppm', ['getPPCMInspectionFormData','getDataReason']),
           FormatDate(value) {
               if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = tgl+' '+bulan+' '+tahun
                return date
                }
            },
            Loading(value) {
                if (value == 'start') {
                this.loader = this.$loading.show({
                    // Optional parameters
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    color: '#f89830',
                    loader: 'spinner',
                    width: 64,
                    height: 64,
                    backgroundColor: '#ffffff',
                    opacity: 0.5,
                    zIndex: 999,
                    });
                }
                if (value == 'stop') {
                // Set delay 1 second
                setTimeout(() => {
                    this.loader.hide()
                }, 1000);
                }
            },
           Setdata_IO() {
                let data_IO      = this.$route.query.io
                // let userData     = this.userData
                // this.$set(this.form, 'Employee', userData.fullname)
                this.$set(this.form, 'data_io', data_IO)
                if (data_IO == null || data_IO == '') {
                    this.$router.push({ name: 'DashboardInspection'})
                }
                if (this.form.data_io != null) {
                    this.Loading('start')
                    this.getPPCMInspectionFormData(this.form.data_io).then(this.GetdataForm()).catch(err => {
                    if(err.response.status !== 401) {
                        this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                        this.$router.push({name:"DashboardInspection"})
                    }
                    })
                    .finally(()=>{
                        this.Loading('stop')
                    })
                }
            },
            OrderProcess() {
                let inline = document.getElementById("visit-inline");
                let final = document.getElementById("visit-final");
                let final_qty = document.getElementById("visit-final-qty");
                // let final_qty2 = document.getElementById("visit-final-qty-2");
                let date_final = document.getElementById("date-final");
                let date_inline = document.getElementById("date-inline");
                let ket_final = document.getElementById("keterangan-final");
                let ket_final_2 = document.getElementById("keterangan-final-2");
                // let ketdtl_final = document.getElementById("keterangandetail-final");
                let ketdtl_final_2 = document.getElementById("keterangandetail-final-2");
                if (this.form.type == 'inline') {
                     inline.classList.remove("hidden");
                     final.classList.add("hidden");
                     final_qty.classList.add("hidden");
                    //  final_qty2.classList.add("hidden");
                     date_final.classList.add("hidden");
                     date_inline.classList.remove("hidden");
                     ket_final.classList.add("hidden");
                     ket_final_2.classList.add("hidden");
                    //  ketdtl_final.classList.add("hidden");
                     ketdtl_final_2.classList.add("hidden");
                } else {
                    inline.classList.add("hidden");
                     final.classList.remove("hidden");
                     final_qty.classList.remove("hidden");
                    //  final_qty2.classList.remove("hidden");
                     date_final.classList.remove("hidden");
                     date_inline.classList.add("hidden");
                     ket_final.classList.remove("hidden");
                     ket_final_2.classList.remove("hidden");
                    //  ketdtl_final.classList.remove("hidden");
                     ketdtl_final_2.classList.remove("hidden");
                }
            },
            DelayGetData() {
                setTimeout(() => {
                    this.GetdataForm();
                },1000)
            },
            GetdataForm() {
                if (this.stPPCMInsDataCount == 0 || this.stPPCMInsDataCount == null) {
                    this.DelayGetData()
                    return
                }
                // Set Data v-model
                let formdata = this.stPPCMInsData
                this.form.artikel = formdata[0].artikel
                this.form.sample = formdata[0].sample
                this.form.employee = formdata[0].employee
                this.form.brand = formdata[0].brand
                this.form.cutting_date = formdata[0].date;
                if (formdata[0].date != null) {
                    this.cutting_date = this.FormatDate(formdata[0].date)
                }
                this.form.due_date = formdata[0].due_date
                if (formdata[0].due_date != null) {
                    this.due_date = this.FormatDate(formdata[0].due_date)
                }
                this.form.cutting_subcon = formdata[0].subcon
                this.form.cutting_progress = formdata[0].qty_plan_cutting
                this.form.cutting_ = formdata[0].qty_plan_cutting
                this.form.qty_plan = formdata[0].qty_plan

                this.form.visit_inline = formdata[0].visit_inline;
                if (this.form.visit_inline == null) {
                    this.form.visit_inline = 0
                }
                this.form.last_visit_inline = formdata[0].last_visit_inline;
                this.form.start_visit_inline = formdata[0].start_visit_inline;
                this.form.status_inline = formdata[0].status_inline;

                this.form.visit_final = formdata[0].visit_final;
                if (formdata[0].visit_final == null) {
                    this.form.visit_final = 0
                }
                this.form.last_visit_final = formdata[0].last_visit_final;
                this.form.start_visit_final = formdata[0].start_visit_final;
                this.form.status_final = formdata[0].status_final;
                this.form.qty_shortship = formdata[0].qty_shortship;
                this.keterangan_final_last = formdata[0].ket;

            },

            Submit(value) {
                this.boxTwo = ''
                this.$bvModal.msgBoxConfirm('Are you sure?', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true,
                })
                .then(value1 => {
                    this.boxTwo = String(value1)
                    if (this.boxTwo == 'true') {

                            let status = null
                            if (value == 1) {
                                status ='Pass'
                            }
                            if (value == 2) {
                                status ='Reject'
                            }
                            let visit_inline = this.form.visit_inline
                            let visit_final = this.form.visit_final
                            let last_visit_inline = this.form.last_visit_inline
                            let last_visit_final = this.form.last_visit_final
                            let status_inline = this.form.status_inline
                            let status_final = this.form.status_final
                            let qty_shortship = this.form.qty_shortship
                            let order_reason = null

                            if (this.form.type == 'inline') {
                                this.form.visit_inline = this.form.visit_inline + 1;
                                this.form.last_visit_inline = this.DateInline;
                                if (this.form.start_visit_inline == null) {
                                    this.form.start_visit_inline = this.DateInline
                                }
                                this.form.status_inline = status
                                this.form.visit_final = null;
                                this.form.last_visit_final = null;
                                this.form.status_final = null;
                                this.form.qty_shortship = null;
                                visit_inline = visit_inline + 1
                                last_visit_inline = this.DateInline;
                                status_inline = status
                                this.keterangan_final = this.keterangan_final_last

                            }
                            if (this.form.type == 'final') {
                                this.form.visit_inline = null
                                this.form.last_visit_inline = null
                                this.form.status_inline = null
                                this.form.visit_final = this.form.visit_final + 1;
                                this.form.last_visit_final = this.DateFinal;
                                if (this.form.start_visit_final == null) {
                                    this.form.start_visit_final = this.DateFinal;
                                }
                                this.form.status_final = status
                                this.form.qty_shortship = this.form.qty_shortship;
                                this.keterangan_final = this.keterangan_final_last
                                if (this.form.keterangan_final != null) {
                                    this.keterangan_final = this.form.keterangan_final
                                    order_reason = this.form.keterangan_final
                                }
                                if (this.form.keterangan_final_detail != null) {
                                    this.keterangan_final_detail = this.form.keterangan_final_detail
                                }
                                visit_final = visit_final + 1
                                last_visit_final = this.DateFinal;
                                status_final = status
                                qty_shortship = this.form.qty_shortship
                                if (qty_shortship == '' || qty_shortship == null || qty_shortship == undefined) {
                                    qty_shortship = 0
                                    this.form.qty_shortship = 0
                                }
                            }
                            let loader = this.$loading.show({
                            // Optional parameters
                            container: this.fullPage ? null : this.$refs.formContainer,
                            canCancel: false,
                            color: '#f89830',
                            loader: 'spinner',
                            width: 64,
                            height: 64,
                            backgroundColor: '#ffffff',
                            opacity: 0.5,
                            zIndex: 999,
                            });
                            // show report after timeout min 1 second
                            setTimeout(() => {
                                PostLogInspection.dispatch('PostInspection', {
                                io:this.form.data_io,
                                visit_inline:this.form.visit_inline,
                                last_visit_inline:this.form.last_visit_inline,
                                status_inline:this.form.status_inline,
                                visit_final:this.form.visit_final,
                                start_visit_final:this.form.start_visit_final,
                                last_visit_final:this.form.last_visit_final,
                                status_final:this.form.status_final,
                                qty_shortship:this.form.qty_shortship,
                                date_now:this.today,
                                ket:this.keterangan_final,
                                })
                                .then(response => {
                                loader.hide()
                                //console.log(response)
                                this.$router.push({name:"DashboardInspection"})
                                this.showNotification({type:'success', message:'Successfully Submitted'})
                                })
                                .catch(err => {
                                loader.hide()
                                if (err.response === 400) {
                                    console.log(err.response.data.message)
                                    this.showNotification({type:'error', message:err.response.data.message})
                                }
                                })
                                .finally(()=>{
                                loader.hide()
                                })
                            },3000)


                                UpdateInspection.dispatch('UpdateFormInspection', {
                                io:this.form.data_io,
                                visit_inline:visit_inline,
                                last_visit_inline:last_visit_inline,
                                start_visit_inline:this.form.start_visit_inline,
                                status_inline:status_inline,
                                visit_final:visit_final,
                                start_visit_final:this.form.start_visit_final,
                                last_visit_final:last_visit_final,
                                status_final:status_final,
                                qty_shortship:qty_shortship,
                                ket:this.keterangan_final,
                                })
                                .then(response => {
                                //console.log(response)
                                })
                                .catch(err => {
                                if (err.response === 400) {
                                    console.log(err.response.data.message)
                                    this.showNotification({type:'error', message:err.response.data.message})
                                }
                                })
                                if (this.form.type == 'final') {
                                    PostOrderReason.dispatch('PostOrderReason', {
                                    io:this.form.data_io,
                                    process:'inspection',
                                    reason_category:order_reason,
                                    reason_detail:this.keterangan_final_detail,
                                    date_time:this.today+'_'+this.time,
                                    })
                                    .then(response => {
                                    //console.log(response)
                                    })
                                    .catch(err => {
                                    if (err.response === 400) {
                                        console.log(err.response.data.message)
                                        this.showNotification({type:'error', message:err.response.data.message})
                                    }
                                    })
                                }





                }
                })
                .catch(err => {
                    // An error occurred
                })
            },

            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },
        },
        created() {
            this.getDataReason(this.data_reason).then(rsp=>{
                for (let r=0; r<=this.StDataReasonCount-1; r++) {
                    this.opt_reason.push(
                        { value: this.StDataReason[r].value, text: this.StDataReason[r].value, disabled: false },
                    );
                }
                this.Setdata_IO()
            }).catch(err=>{
                if (err.response !== 401) {
                    this.showNotification({type:'error', message:'Data Gagal Di Load, Harap Di Ulang Kembali'})
                }
            })

        },

    }
</script>
<style scoped>
    .select-custom {
        font-weight: 500;
    }
</style>
<style src="./PPCMInputInspection.scss" lang="scss" scoped />
