<template>
    <div>
        <validation-observer  ref="observer" v-slot="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                        <validation-provider
                            name="date"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols="4"
                                label-cols-lg="4"
                                label-size="sm"
                            >
                                <template v-slot:label>
                                    Date
                                </template>
                                <b-form-input readonly id="inputDate" v-model="form.date" :state="getValidationState(validationContext)" trim></b-form-input>
                                <b-form-invalid-feedback id="inputDate">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            name="createdBy"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols="4"
                                label-cols-lg="4"
                                label-size="sm"
                            >
                                <template v-slot:label>
                                    Created By
                                </template>
                                <b-form-input readonly id="inputCreatedBy" v-model="form.createdBy" :state="getValidationState(validationContext)" trim></b-form-input>
                                <b-form-invalid-feedback id="inputCreatedBy">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            name="fob"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols="4"
                                label-cols-lg="4"
                                label-size="sm"
                            >
                                <template v-slot:label>
                                    FOB/CM/CMT<code>*</code>
                                </template>
                                <b-form-select id="inputFOB" v-model="form.fob" :options="fobOpt" :state="getValidationState(validationContext)" trim></b-form-select>
                                <b-form-invalid-feedback id="inputFOB">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            name="outsource"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols="4"
                                label-cols-lg="4"
                                label-size="sm"
                            >
                                <template v-slot:label>
                                    Name Outsource<code>*</code>
                                </template>
                                <b-form-select id="inputOutsource" v-model="form.outsource" :options="outsourceOpt" :state="getValidationState(validationContext)" trim></b-form-select>
                                <b-form-invalid-feedback id="inputOutsource">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            name="sample"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols="4"
                                label-cols-lg="4"
                                label-size="sm"
                            >
                                <template v-slot:label>
                                    With Sample<code>*</code>
                                </template>
                                <b-form-select id="inputSample" v-model="form.sample" :options="sampleOpt" :state="getValidationState(validationContext)" trim></b-form-select>
                                <b-form-invalid-feedback id="inputSample">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            name="sampleRoom"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols="4"
                                label-cols-lg="4"
                                label-size="sm"
                            >
                                <template v-slot:label>
                                    Sample Room<code>*</code>
                                </template>
                                <b-form-select id="inputSampleRoom" v-model="form.sampleRoom" :options="sampleRoomOpt" :state="getValidationState(validationContext)" trim></b-form-select>
                                <b-form-invalid-feedback id="inputSampleRoom">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <validation-provider
                            name="sampleName"
                            :rules="{ required: isValid }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                label-cols="4"
                                label-cols-lg="4"
                                label-size="sm"
                            >
                                <template v-slot:label>
                                    Sample Name<code>*</code>
                                </template>
                                <b-form-input id="inputSampleName" v-model="form.sampleName" :state="getValidationState(validationContext)" trim></b-form-input>
                                <b-form-invalid-feedback id="inputSampleName">{{validationContext.errors[0]}}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
            </b-form>
        </validation-observer>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
export default {
    name:'FormMD',
    data(){
        return{
            formName:'Form MD',
            modulName:null,
            isValid:true,
            form:{
                date: new Date().toLocaleString(),
                createdBy:null,
                nik:null,
                fob:null,
                outsource:null,
                sample:null,
                sampleRoom:null,
                sampleName:null
            },
            fobOpt:[
                {value:'FOB', text:'FOB'},
                {value:'CM', text:'CM'},
                {value:'CMT', text:'CMT'},
            ],
            outsourceOpt:[
                {value:'PT. A', text:'PT. A'},
                {value:'PT. B', text:'PT. B'},
                {value:'PT. C', text:'PT. C'},
                {value:'PT. D', text:'PT. D'},
            ],
            sampleOpt:[
                {value:'Sample A', text:'Sample A'},
                {value:'Sample B', text:'Sample B'},
                {value:'Sample C', text:'Sample C'},
                {value:'Sample D', text:'Sample D'},
            ],
            sampleRoomOpt:[
                {value:'Sample Room A', text:'Sample Room A'},
                {value:'Sample Room B', text:'Sample Room B'},
                {value:'Sample Room C', text:'Sample Room C'},
                {value:'Sample Room D', text:'Sample Room D'},
            ]

        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getAccountInfo() {
            let userData      = JSON.parse(localStorage.userData)
            this.$set(this.form, 'createdBy', userData.fullname)
            this.$set(this.form, 'nik', userData.nik)
            
        },
        onSubmit(){
            console.log('oke')
        }
    },
    mounted(){
        this.getAccountInfo()
    }
}
</script>