import axios from "axios";
import { axiosBase } from "./api-base";

const apiPath = {
    auth : '/core/api/token/',
    chPass : '/core/api/account/change-password/',
    chkSession : '/core/api/check/',
    listBU : '/core/api/active-bu/',
}
let result = false

if (new URL(window.location).hostname == 'localhost') {
  result = true
}
let APIDomain = new URL(window.location.href);
let APIPort = "8005";
const APIUrl = "https://" + APIDomain.hostname + ":" + APIPort;

const axiosBase2 = axios.create({
    namespaced: true,
    baseURL: APIUrl,
    // headers: { 'Content-Type': 'application/json' }
  });

export function fnApiLogin (credentials) {
    return new Promise((resolve, reject) => {
        if (!result) {
            axiosBase.post(apiPath.auth, credentials)
            .then(rsp => { resolve(rsp) })
            .catch(err =>{ reject(err) })
        } else {
            axiosBase2.post(apiPath.auth, credentials)
            .then(rsp => { resolve(rsp) })
            .catch(err =>{ reject(err) })
        }
        })
}

export function fnApiGetListBU () {
    return new Promise((resolve, reject) => {
        if (!result) {
            axiosBase.get(apiPath.listBU)
            .then(rsp => { resolve(rsp) })
            .catch(err =>{ reject(err) })
        } else {
            axiosBase2.get(apiPath.listBU)
            .then(rsp => { resolve(rsp) })
            .catch(err =>{ reject(err) })
        }
    })
}
