<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{rptName}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">{{rptName}}</span>
    <span class="fw-normal fs-larger"> {{rptVersion}} </span></h2>
    <b-row>
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
        </div>
      </b-col>
    </b-row>
    <b-row> 
      <b-col xs="auto">
        <!-- >>> Report Type -->
        <b-form-group
          label="Based on*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select id="selReportType"  v-model="selectedDept">
            <option v-for="(row,index) in rptType" :key="index" :value="row" :selected="selectedRptType">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Report Type -->
        <!-- >>> Period -->
        <b-form-group 
          label="Period*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose Period">
            <option v-for="(row,index) in rptPeriod" :key="index" :value="row" :selected="selectedPeriod">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Period -->
      </b-col>
      <b-col xs="6">
        <!-- >>> Brand -->
        <b-form-group 
          label="Brand*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose Brand">
            <option v-for="(row,index) in rptBrand" :key="index" :value="row" :selected="selectedBrand">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Brand -->
        
        <!-- >>> Channel -->
        <b-form-group 
          label="Channel*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose Channel">
            <option v-for="(row,index) in rptType" :key="index" :value="row" :selected="selectedRptType">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Channel -->
        
        <!-- >>> Cust Group -->
        <b-form-group 
          label="Cust. Group*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose Customer Group">
            <option v-for="(row,index) in rptType" :key="index" :value="row" :selected="selectedCustGroup">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Cust Group -->
        
        <!-- >>> Counter -->
        <b-form-group 
          label="Counter*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose Counter">
            <option v-for="(row,index) in rptType" :key="index" :value="row" :selected="selectedCounter">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Counter --> 
        
        <!-- >>> Class -->
        <b-form-group 
          label="Class*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose Counter">
            <option v-for="(row,index) in rptType" :key="index" :value="row" :selected="selectedClass">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Class --> 
        
        <!-- >>> Region -->
        <b-form-group 
          label="Region*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose Region">
            <option v-for="(row,index) in rptType" :key="index" :value="row" :selected="selectedRegion">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< Region --> 
        
        <!-- >>> City -->
        <b-form-group 
          label="City*"
          label-cols="4"
          label-cols-lg="2"
        >
          <b-form-select v-model="selectedDept" placeholder="Choose City">
            <option v-for="(row,index) in rptType" :key="index" :value="row" :selected="selectedCity">
                {{row}}
            </option>
          </b-form-select>
        </b-form-group>
        <!-- <<< City --> 
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="8">
        <b-button class='btn-spacing' type="button" variant="primary" :disabled="isLoadingData" v-on:click="populateReport()">Load Report</b-button>
        <b-button type="button" :class="exportButtonClass()" v-on:click="exportToExcel()">Export to Excel</b-button>
        </b-col>
      <b-col xs='4' class="text-right">
        <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToReport()">Cancel</b-button>
      </b-col>

    </b-row>
    <br>
    <b-row>
      <b-col xs="12" class="overflow-auto">
        <div class='custom-pivot-class'>
            <vue-pivottable
                :data="pivotData"
                :aggregator-name="aggregatorName"
                :renderer-name="rendererName"
                :rows="rows"
                :cols="cols"
                :vals="vals"
                :row-total="true"
                :col-total="true"
            >
            </vue-pivottable>
        </div>
        <div>
          <iframe id="txtArea1" style="display:none"></iframe>
        </div>
      </b-col>
    </b-row>
    
  </div>
  
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import  Vue from 'vue';
import JsonExcel from 'vue-json-excel';

Vue.component('download-excel', JsonExcel)

export default {
    name: 'BrandReport',
    data() {
        return {
            rptVersion:'v1.0.0',
            rptName:'Brand  Report',
            rptType:['Brand', 'Productivity'],
            processedDateTime:"",
            selectedPeriod:null,
            selectedRptType:null,
            selectedBrand:null,
            selectedChannel:null,
            selectedCustGroup:null,
            selectedCounter:null,
            selectedClass:null,
            selectedRegion:null,
            selectedCity:null,
            errorMessage:null,
            isLoadingData:false,
            pivotData:[],
            objParameter:[],
            aggregatorName:'Sum',
            rendererName: 'Table',
            hasData:false,
            excel_titile:'',
            excel_name:'',
            rows:[],
            cols:[],
            vals:[],
            disabledFromDragDrop:[],
            hiddenFromDragDrop:[],
        }
    },
    computed: {
        ...mapState('report', ['stRptData', 'stRptDataCount']),
      ...mapGetters('report', ['rptDataCount', 'rptData', 'activeReport']),
      ...mapGetters('session', ['userData']),
    },
    methods: {
        ...mapActions('report', ['initializeReport', 'getPivotDataBrand', 'getCurrentDatetime', 'generateTitleXLS', 'exportHTMLToXls', 'manipulatePivotUI']),
      ...mapActions('session', ['showNotification']),
        populateReport() {
            this.resetReportSummary()
            if (this.selectedPeriod === null) {
                this.errorMessage = "Please Select Period"
            }
            else{
                this.isLoadingData = true
                this.showNotification({type:'info', message:'Loading report, Please wait...'})
                this.getPivotDataBrand({
                    period: this.selectedPeriod,
                    rpt_type: this.selectedRptType,
                    brand: this.selectedBrand,
                    channel: this.selectedChannel,
                    cust_group: this.selectedCustGroup,
                    counter: this.selectedCounter,
                    class: this.selectedClass,
                    region: this.selectedRegion,
                    city: this.selectedCity,
                    
                })
                .then(rsp => {
                    this.pivotData = this.rptData.pivotData
                    this.vals = this.rptData.vals
                    this.rows = this.rptData.rows
                    this.cols = this.rptData.cols
                    this.aggregatorName = this.rptData.aggregatorName
                    this.rendererName = this.rptData.rendererName
                    this.disabledFromDragDrop = this.rptData.disabledFromDragDrop
                    this.hiddenFromDragDrop = this.rptData.hiddenFromDragDrop
                    if(this.pivotData.length > 0) {
                        this.hasData = true
                    }
                    else{
                        this.hasData = false
                    }
                    this.postProcessReport(rsp)
                })
                .catch(err => {
                    if(err.response.status !== 401){
                        this.showNotification({type:'error', message:'Failed loading report'})
                        console.log(err)
                    }
                })
                .finally(() => {
                    this.isLoadingData = false
                })
            }
        },
        postProcessReport(apiArg) {
            this.getCurrentDatetime().then(rsp => {
                this.processedDateTime = rsp;
                this.showNotification({type:'success', message:'Report Loaded'})

                this.initExcelTitile()
            })
            .catch(ex => {
                this.showNotification({type:'error', message:'Failed loading report'})
                console.log(ex)
            })
        },
        goToReport(){
            this.$router.push({name:"ReportPage"})
        },
        initExcelTitile(){
            this.generateTitleXLS({
                rptName             : this.rptName,
                rptVersion          : this.rptVersion,
                processedDateTime   : this.processedDateTime,
                processedBy         : (this.userData.username + ' - ' + this.userData.fullname)
            }).then(rsp => {
                this.excel_name     = rsp[0]
                this.excel_titile   = rsp[1]
            })
        },
        exportToExcel(){
            let tbElement   = document.getElementsByClassName('pvtTable')[0]
            let fileName    = this.excel_name
            let strHeader   = this.excel_titile

            this.exportHTMLToXls({tbElement,fileName,strHeader})
        },
        resetReportSummary(){
            this.errorMessage = null
        },
        exportButtonClass(){
            let classNames = 'btn btn-info'
            if(!this.hasData){
                classNames += ' hide'
            }
            if(this.isLoadingData){
                classNames += ' btn-disabled'
            }
            return classNames
        },
    },
    created() {
        this.initializeReport()
        this.rptName = this.activeReport
    }    
}
</script>

<style src="./BrandProductivityReport.scss" lang="scss" scoped />