import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchMasterParam,
    fnApiFetchListMasterParam,
    fnApiFetchEditMasterParam,
    fnApiFetchDeleteMasterParam,
}from '../../api/api-aplications-product.js'

Vue.use(Vuex)


const actions = {
    postMasterParam(context, formMasterParam){
        console.log(formMasterParam)
        return new Promise((resolve, reject) => {
            fnApiFetchMasterParam(formMasterParam)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    getListMasterParam(){
        return new Promise((resolve, reject) => {
            fnApiFetchListMasterParam()
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    putMasterParam(context, formMasterParam){
        console.log(formMasterParam)
        return new Promise((resolve, reject) => {
            fnApiFetchEditMasterParam(formMasterParam)
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    },
    deleteMasterParam(context, formMasterParam){
        console.log(formMasterParam)
        return new Promise((resolve, reject) =>{
            fnApiFetchDeleteMasterParam(formMasterParam)
            .then(response => {
                resolve(response)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
}

export default new Vuex.Store({
    namespaced: true,
    actions
})