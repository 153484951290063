<template>
  <div>
    <b-overlay :show="isLoading" rounded="sm">
      <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/applications">
            Applications</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>{{ formName }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ modulName }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-container fluid>
        <b-card>
          <template>
            <b-card sub-title="Filter">
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label="Business Unit"
                    label-for="bu-select"
                    label-cols="3"
                    label-size="sm"
                    v-slot="{ ariaDescribedby }"
                  >
                    <v-select
                      id="bu-select"
                      v-model="filters.business_unit"
                      :options="buOpt"
                      size="sm"
                      label="code"
                      :reduce="(i) => i.code"
                      :aria-describedby="ariaDescribedby"
                    >
                    </v-select>
                  </b-form-group>
                  <b-form-group
                    label="Location"
                    label-for="location-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="location-select"
                      v-model="filters.location"
                      :options="locationOpt"
                      size="sm"
                      label="location_name"
                      :reduce="(i) => i.location_name"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="License Type"
                    label-for="license-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="license-select"
                      v-model="filters.permit_type"
                      :options="licenseOpt"
                      size="sm"
                      label="permit_type"
                      :reduce="(i) => i.permit_type"
                    >
                    </v-select>
                  </b-form-group>
                  <b-form-group
                    label="End Date"
                    label-for="enddate-select"
                    label-cols="3"
                    label-size="sm"
                  >
                    <v-select
                      id="enddate-select"
                      v-model="filters.end_year"
                      :options="setYear"
                      size="sm"
                      label="end_date"
                      :reduce="(i) => i.end_date"
                    >
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-size="sm">
                    <template v-slot:label> Search </template>
                    <b-input-group size="sm">
                      <b-form-input
                        id="inputSearch"
                        v-model="filter"
                        type="search"
                        placeholder="Type to Search"
                        trim
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button :disabled="!filter" @click="filter = ''"
                          >Search</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </template>
          <template v-slot:header>
            <b-row>
              <b-col sm="auto" md="9">
                <div class="text-left">
                  <h4>Reminder Legal Document</h4>
                </div>
              </b-col>
              <b-col sm="auto" md="3">
                <div class="text-right"></div>
              </b-col>
            </b-row>
          </template>
          <b-table
            responsive
            stickyColumn
            striped
            hover
            small
            show-empty
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterDocument"
            :fields="fields"
            :filter="filter"
            :filter-included-fields="includeFiled"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(remind_date)="{ item }">
              <span :class="classcountReminder(item.end_date)">{{ countReminder(item.end_date) }} Month</span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <b-button
                title="View"
                size="xs"
                variant="info"
                v-on:click="viewData(item)"
              >
                <b-icon icon="eye-fill" aria-label="Help"></b-icon>
              </b-button>
            </template>
          </b-table>
          <div class="mt-3">
            Showing {{ pageItems }} of {{ items.length }} entries
            <b-pagination
              v-model="paging.currentPage"
              :total-rows="rows"
              :per-page="paging.perPage"
              align="right"
            >
            </b-pagination>
          </div>
        </b-card>
        <template #overlay>
          <div ref="loadings" class="text-center">
            <b-icon
              icon="arrow-clockwise"
              font-scale="3"
              animation="spin"
            ></b-icon>
            <p id="cancel-label">Please wait...</p>
          </div>
        </template>
      </b-container>
    </b-overlay>
    <template>
      <b-modal
        v-model="showDetail"
        header-bg-variant="secondary"
        footer-bg-variant="secondary"
        hide-backdrop
        size="lg"
        ref="modal"
        title="Document Legal"
        hide-footer
        scrollable
        body-bg-variant="light"
        cancel-title="Close"
      >
        <detail-document :detailItems="detailItem" :detailFile="detailFile" />
      </b-modal>
    </template>
  </div>
</template>

<script>
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../../api/api-methods";
import { APIPATH_DMS, APIPATH_BBIAPPS } from "../../../../../api/api-paths";
import Vue from "vue";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import DetailDocument from "../DocumentLegal/DetailDocument.vue";
export default {
  components: { DetailDocument },
  data() {
    return {
      formName: "Document Legal",
      modulName: "Dashboard",
      items: [],
      fields: [
        { key: "actions", label: "action" },
        { key: "business_unit", label: "Business Unit", sortable: true },
        { key: "dept_name", label: "Dept", sortable: true},
        { key: "permit_type", label: "License Type", sortable: true },
        { key: "permit_no", label: "Permit No.", sortable: true },
        { key: "location", label: "Location", sortable: true },
        {
          key: "end_date",
          label: "End Date",
          formatter: this.setDateFormat,
          sortable: true,
        },
        { key: "remind_date", label: "Reminder", sortable: true },
      ],
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      isLoading: false,
      filter: null,
      filters: {
        business_unit: "",
        location: "",
        permit_type: "",
        end_year: "",
      },
      buOpt: [],
      locationOpt: [],
      licenseOpt: [],
      endDateOpt: [],
      includeFiled: [
        "business_unit",
        "location",
        "permit_type",
        "end_year",
        "permit_no",
      ],
      showDetail: false,
      detailItem: null,
      detailFile: null,
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    rows() {
      if (this.items) {
        return this.filterDocument.length;
      }
    },
    filterDocument() {
      var bu = this.filters.business_unit;
      var location = this.filters.location;
      var permit = this.filters.permit_type;
      var end = this.filters.end_year;

      return this.items.filter(function (item) {
        var filtereds = true;
        if (bu && bu.length > 0) {
          filtereds = item.business_unit === bu;
        }
        if (filtereds) {
          if (location && location.length > 0) {
            filtereds = item.location === location;
          }
        }
        if (filtereds) {
          if (permit && permit.length > 0) {
            filtereds = item.permit_type === permit;
          }
        }
        if (filtereds) {
          if (end && end.length > 0) {
            filtereds = item.end_year === end;
          }
        }
        return filtereds;
      });
    },
    setYear() {
      return _.uniqBy(this.endDateOpt, function (u) {
        return u.end_date;
      });
    },
    pageItems() {
      var totalPage = Math.ceil(this.rows / this.paging.perPage);
      if (this.paging.currentPage === totalPage) {
        return (
          (this.rows % this.paging.perPage) +
          this.paging.perPage * totalPage -
          this.paging.perPage
        );
      } else {
        return this.paging.perPage * this.paging.currentPage;
      }
    },
  },
  methods: {
    setDateFormat(value) {
      var options = { year: "numeric", month: "short", day: "numeric" };
      var formats = new Date(value).toLocaleDateString("id-ID", options);
      if (value) {
        return formats;
      }
    },
    getDocumentLegal() {
      Vue.set(this, "isLoading", true);
      fnGET(APIPATH_DMS.getDocumentReminder)
        .then((rsp) => {
          Vue.set(this, "items", rsp.data.payload);
          var year = rsp.data.payload;
          var yearArr = [];
          for (let i = 0; i < year.length; i++) {
            let years = {
              end_date: year[i].end_year,
            };
            yearArr.push(years);
          }
          Vue.set(this, "endDateOpt", _.uniqBy(yearArr));
          Vue.set(this, "isLoading", false);
        })
        .catch((err) => {
          Vue.set(this, "isLoading", false);
          return err;
        });
    },
    viewData(item) {
      fnPOST(APIPATH_DMS.getDocumentFile, { document_id: item.id })
        .then((rsp) => {
          let data = rsp.data.payload
          // console.log(rsp.data.payload);
          if (rsp.data.payload) {
            for (let a=0; a<data.length; a++) {
              data[a].file_src = data[a].file_src.replace(":8005",":8000")
            }
          }
          Vue.set(this, "detailFile", data);
        })
        .catch((err) => {
          return err;
        })
        .then(() => {
          Vue.set(this, "isLoading", false);
        });
      Vue.set(this, "detailItem", item);
      console.log(this.detailItem);
      Vue.set(this, "showDetail", true);
    },
    countReminder(end_date) {
      var minutes = 1000 * 60;
      var hours = minutes * 60;
      var days = hours * 24;
      var date = new Date();
      var today =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      var d1 = new Date(today);
      var d2 = new Date(end_date);

      var diff_date = Math.round((d2 - d1) / days);

      var diff_month = Math.floor(diff_date / 30);

      return Math.abs(diff_month) ;
    },
    classcountReminder(end_date) {
      let minutes = 1000 * 60;
      let hours = minutes * 60;
      let days = hours * 24;
      let date = new Date();
      let today =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      let d1 = new Date(today);
      let d2 = new Date(end_date);

      let diff_date = Math.round((d2 - d1) / days);

      let diff_month = Math.floor(diff_date / 30);
        if (diff_month < 0) {
          return 'red-text'
        }
        else if (diff_month == 0) {
          return 'warning-text'
        } else {
          return ''
        }
    },
    getBU() {
      fnGET(APIPATH_BBIAPPS.listBU)
        .then((rsp) => {
          Vue.set(this, "buOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getLocation() {
      fnGET(APIPATH_DMS.getLocation)
        .then((rsp) => {
          var locations = rsp.data.payload.filter((i) => i.is_active === true);
          Vue.set(this, "locationOpt", locations);
        })
        .catch((err) => {
          return err;
        });
    },
    getPermitType() {
      fnGET(APIPATH_DMS.getPermit)
        .then((rsp) => {
          var permits = rsp.data.payload.filter((i) => i.is_active === true);
          Vue.set(this, "licenseOpt", permits);
        })
        .catch((err) => {
          return err;
        });
    },
  },
  created() {
    this.getDocumentLegal();
    this.getPermitType();
    this.getLocation();
    this.getBU();
  },
};
</script>

<style>
</style>