<template>
    <div>
        <validation-observer ref="observer" v-slot="{ handleSubmit }" >
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row >
                    <b-col cols="auto" md="6">
                        
                        <!-- Code Interlining -->
                        <validation-provider
                            name="codeInterlining"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Code
                                </template>
                                    <!-- <b-form-select id="inputCodeInterlining" v-model="form.codeInterlining"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                    <v-select append-to-body
                                        :reduce="ref => ref.code" 
                                        v-model="form.codeInterlining"
                                        @input="setSelected" 
                                        :options="codeOpt" 
                                        placeholder="Type your code number"
                                        label="ref">
                                        <template #list-header>
                                            <b-input-group-append>
                                                <b-button block variant="light" @click="showMaster()">
                                                    <!-- <b-icon icon="eye-fill" aria-label="Help"></b-icon> -->
                                                    Lihat Semua
                                                </b-button>
                                            </b-input-group-append>
                                        </template>
                                        <template #option="{ code, description }">
                                            <p style="margin: 0">{{ code }}</p>
                                            <em class="text-muted">{{ description }}</em>
                                        </template>
                                    </v-select>
                                <b-form-invalid-feedback id="inputCodeInterlining">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Type Interlining -->
                        <validation-provider
                            name="TypeInterlining"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4"  
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Type
                                </template>
                            
                                <b-form-input readonly id="inputTypeInterlining" v-model="form.typeInterlining"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="inputTypeInterlining">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <!-- Description -->
                        <validation-provider
                            name="DescriptionInterlining"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Description
                                </template>
                            
                                <b-form-input readonly id="DescriptionInterlining" v-model="form.descriptionInterlining"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                <b-form-invalid-feedback id="DescriptionInterlining">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                       
                        <!-- Detail Interlining -->
                        <validation-provider
                            name="DetailInterlining"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Detail
                                </template>
                            
                                <b-form-textarea id="DetailInterlining" v-model="form.detailInterlining"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                <b-form-invalid-feedback id="DetailInterlining">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        
                        
                    </b-col>
                    <b-col cols="auto" md="6">
                        <!-- UsedAs -->
                        <!-- <validation-provider
                            name="UsedAs"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Used As
                                </template>
                                <b-form-select id="inputUsedInterlining" v-model="form.usedInterlining"  :options="usedOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                <b-form-invalid-feedback id="inputUsedInterlining">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->

                        <!-- Images Interlining -->
                        <!-- <validation-provider
                            name="imagesInterlining"
                            :rules="{ required: false }"
                            v-slot="validationContext"
                        >
                            <b-form-group 
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images
                                </template>
                                <b-form-file
                                    multiple
                                    ref="fileInput"
                                    id="inputImagesInterlining"
                                    v-model="form.imagesInterlining"
                                    no-drop
                                    :state="getValidationState(validationContext)"
                                    placeholder="Choose file here..."
                                    accept=".jpg, .png,"
                                    :file-name-formatter="formatNames"
                                    v-on:change="onFileChange"
                                ></b-form-file>
                                    <div v-for="img in imagesUrl" :key="img.name">
                                        <p class="small">
                                            <a :href="img.src">{{img.fileName}}</a>
                                            <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                            </b-badge>
                                        </p>
                                    </div>
                                <b-form-invalid-feedback id="inputImagesBotton">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider> -->
                        <b-form-group
                            label-cols          ="4" 
                            label-cols-lg       ="4" 
                            label-size          ="sm"
                        >
                            <template v-slot:label>
                                Images
                            </template>
                            <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesInterlining"></b-img>

                        </b-form-group>
                        <div>
                            <b-button block  variant="success" type="submit">Save</b-button>
                        </div>
                    </b-col>
                    
                    
                    
                </b-row>
                    
            </b-form>
        </validation-observer>
        
        <div>
            <b-table
                responsive
                striped 
                hover
                :items="loadProductMaterial"
                :fields="fields"
            >
                
                
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="data">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                        
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </div>
        
        <b-modal
            hide-backdrop
            hide-footer
            v-model="modalMaster"
            size="xl"
            ref="modal"
            scrollable 
            title="Master Material">
            <master-interlining/>
        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MasterInterlining from '../Master/MasterInterlining.vue';
export default {
  components: {MasterInterlining },
    name:'FormInterlining',
    data(){
        return{
            mainProps: {width: 150, class: 'm1' },
            formName: 'Interlining',
            modulName: null,
            tabIndex:1,
            isValid:false,
            fields:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                // 'used_as'
            ],
            imagesUrl:[],
            form:{
                indexId:null,
                interliningId:null,
                codeInterlining:null,
                typeInterlining:null,
                imagesInterlining:[],
                detailInterlining:null,
                usedInterlining:null,
                descriptionInterlining:null,
                category:'interlining'
            },
            codeOpt:[
                {code:'Code A', ref:'Code A'},
                {code:'Code B', ref:'Code B'},
                {code:'Code C', ref:'Code C'},
                {code:'Code D', ref:'Code D'},
            ],
            usedOpt:[
                {value:'main', text:'Main'},
                {value:'collar', text:'Collar'},
                {value:'pocket', text:'Pocket'},
            ],
            interliningOpt: [
                { item: 'TBD', name: 'TBD' },
                { item: 'Yes', name: 'Yes' },
                { item: 'No', name: 'No'},
                
            ],
            isSelect:'TBD',
            modalMaster:false,
        }
    },
    methods:{
        ...mapActions('productDesign',['add','delete','update','get']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            if(this.form.indexId == null){
                this.add({
                    formname: this.formName,
                    category: this.form.category,
                    material_id: this.form.interliningId,
                    code:this.form.codeInterlining,
                    type:this.form.typeInterlining,
                    detail:this.form.detailInterlining,
                    images:this.imagesUrl,
                    used_as:this.form.usedInterlining,
                    description:this.form.descriptionInterlining,
                    isProccess:this.isSelect
                })
            }else{
                this.update({
                    formname: this.formName,
                    id:this.form.indexId,
                    category: this.form.category,
                    material_id: this.form.interliningId,
                    code:this.form.codeInterlining,
                    type:this.form.typeInterlining,
                    detail:this.form.detailInterlining,
                    images:this.imagesUrl,
                    used_as:this.form.usedInterlining,
                    description:this.form.descriptionInterlining,
                    isProccess:this.isSelect
                })
            }
            
            this.clearForm()
        
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeInterlining = null
            this.form.typeInterlining = null
            this.form.imagesInterlining = []
            this.imagesUrl = []
            this.form.detailInterlining = null
            this.form.usedInterlining = null
            this.form.descriptionInterlining = null
            this.$refs.observer.reset()
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                }
                this.imagesUrl.push(img)
            }
        },
        goToList(){
            this.$router.push({name:'AppsProductDesignPage'})
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
            return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        deleteData(id){
            
            this.delete({id:id,formname:this.formName})
        },
        editData(index){
            
            this.get({id:index,formname:this.formName})
            this.form.indexId   = index
            this.form.interliningId = this.interliningDetail.id
            this.form.codeInterlining   = this.interliningDetail.code
            this.form.typeInterlining   = this.interliningDetail.type
            this.form.detailInterlining = this.interliningDetail.detail
            this.imagesUrl      = this.interliningDetail.images
            this.form.usedInterlining    = this.interliningDetail.used_as
            this.form.descriptionInterlining = this.interliningDetail.description
            this.isSelect = this.interliningDetail.interlining_process
        },
        showMaster(){
            this.modalMaster = true
        },
        setSelected(value){
           if(value != null){
                let dataInterlining = this.MASTER_MATERIAL.filter(x => x.code === value)
                this.form.interliningId= dataInterlining[0].id
                this.form.codeInterlining= dataInterlining[0].code
                this.form.typeInterlining= dataInterlining[0].type
                this.form.descriptionInterlining = dataInterlining[0].description
                this.form.imagesInterlining = dataInterlining[0].img_src
            }else{
                console.log('belum ada data')
            }
        },
        getInterliningMaster(){
            this.GET_MASTER_MATERIAL().then(response => {
                this.codeOpt = []
                    let objInterlining = response.data.payload.filter(x=>x.category ==='interlining')
                    for(var i=0;i<objInterlining.length;i++){
                        let arrInterlining = {
                            ref: objInterlining[i].code,
                            code: objInterlining[i].code,
                            description: objInterlining[i].description
                            
                        }
                        this.codeOpt.push(arrInterlining)
                    }
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
    },

    mounted(){
        if(this.form.indexId != null){
            this.modulName = 'Edit'
        }else{
            this.modulName = 'Create'
        }
        this.getInterliningMaster()

    },
    computed:{
    ...mapGetters('productDesign',['PRODUCT_MATERIAL']),
    ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        loadProductMaterial(){
            return this.PRODUCT_MATERIAL.filter(pm => pm.category ==='interlining')
        }
    }
}
</script>