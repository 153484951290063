<template>
    <div>
        <b-table
            striped 
            hover
            show-empty
            small
            responsive
            :items="dataProductDesign"
            :fields="fields"
        >   
            <template #empty="scope">
                <p class="text-center">{{ scope.emptyText }}</p>
            </template>
            <template v-slot:cell(actions)="{item}">
                <b-button title="Detail" size="xs" variant="dark" v-on:click="show = true">
                    <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                </b-button>
                <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.id)">
                    <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                </b-button>
            </template>
        </b-table>

        <b-modal
            hide-backdrop
            hide-footer
            v-model="show"
            size="xl"
            ref="modal"
            scrollable 
            title="Product Design Detail">
            <productview/>
        </b-modal>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import productview from './ProductDesignSubmitView';
export default {
    name: 'ProductDesignList',
    components:{
        productview
    },
    data(){
        return {
            show:false,
            fields:[
                {key:'actions', label:'#'},
                'brand','article','period','collectionName',
            ],
            dataProductDesign: [],
        }
    },
    methods:{
        editData(){
            this.$router.push({
                name:"AppsProductDesignFormPage"
            })
        },
        getData(){
            if(this.product != null){
                this.dataProductDesign.push(this.product)
            }
        }
    },
    mounted(){
        this.getData()
    },
    computed:{
        ...mapGetters('productDesign',['product'])
    }
}
</script>