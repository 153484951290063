import { axiosBase, getAPI, axiosBaseWf, getAPIReport, axiosBaseWbs, getAPICore, getAPICoreUnauth } from "./api-base";

export function fnGET(apiPath) {
  return new Promise((resolve, reject) => {
    getAPI
      .get(apiPath)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnPOST(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnPUT(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    getAPI
      .put(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnDELETE(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    getAPI
      .delete(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnRptPOST(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    getAPI
      .post(apiPath, postBody)
      .then((response) => {
        if (response.data.payload.length == 0) {
          resolve("No Data");
        }
        resolve("Success");
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnNoAuthGET({ apiPath, postBody }) {
  return new Promise((resolve, reject) => {
    axiosBase
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnNoAuthPOST({ apiPath, postBody }) {
  return new Promise((resolve, reject) => {
    axiosBase
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


export function fnPOSTWBS(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    axiosBaseWbs
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnGETWBS(apiPath) {
  return new Promise((resolve, reject) => {
    axiosBaseWbs
    .get(apiPath)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
}

export function fnGETCore(apiPath) {
  return new Promise((resolve, reject) => {
    getAPICore
      .get(apiPath)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnPOSTCore(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    getAPICore
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnGETCoreNoAuth(apiPath) {
  return new Promise((resolve, reject) => {
    getAPICoreUnauth
      .get(apiPath)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnPOSTCoreNoAuth(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    getAPICoreUnauth
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

//Methods WF
export function fnPOSTWF(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    axiosBaseWf
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnGETWF(apiPath) {
  return new Promise((resolve, reject) => {
    axiosBaseWf
    .get(apiPath)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
}

//Methods Report
export function fnPOSTRpt(apiPath, postBody) {
  return new Promise((resolve, reject) => {
    getAPIReport
      .post(apiPath, postBody)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function fnGETRpt(apiPath) {
  return new Promise((resolve, reject) => {
    getAPIReport
    .get(apiPath)
    .then((response) => {
      resolve(response);
    })
    .catch((err) => {
      reject(err);
    });
});
}