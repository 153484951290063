<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{stActiveReport}}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title"> <span class="fw-semi-bold">{{stActiveReport}}</span>
    <span class="fw-normal fs-larger"> {{rptVersion}} </span></h2>
    <b-row> 
      <b-col xs="12">
        <div>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{errorMessage}}
          </b-alert>
          
          <b-form-group label="Date From*">
            <b-form-datepicker placeholder="Choose report date start" v-model="selectedDate" class="mb-2"></b-form-datepicker>
          </b-form-group>
          <b-form-group label="Date To">
            <b-form-datepicker placeholder="Choose report date to" v-model="selectedDateTo" class="mb-2"></b-form-datepicker>
          </b-form-group>
          <b-form-group label="Department*">
            <b-form-select v-model="selectedDept" placeholder="Choose Department Code">
              <option v-for="(row,index) in stDeptList" :key="index" :value="row.dept_code" :selected="selectedDept">
                  {{row.dept_code}}
              </option>
            </b-form-select>
          </b-form-group>
          <div>
            <b-row>
              <b-col cols="8">
                <b-button class='btn-spacing' type="button" variant="primary" :disabled="isLoadingData" v-on:click="populateReport()">Load Report</b-button>
                <download-excel
                    :class   = "exportButtonClass()"
                    :data   = "rptData"
                    :fields = "excel_fields"
                    :title   = "excel_title"
                    :name    = "excel_name">
                    Export to Excel
                </download-excel>
              </b-col>
              <b-col cols="4" class="text-right">
                <b-button class='btn-spacing-left' type="button" variant="secondary" v-on:click="goToReport()">Cancel</b-button>
              </b-col>
            </b-row>
          </div>
          <!-- <div class='col-xs-3'>
            <b-button class='btn-spacing' type="button" variant="primary" v-on:click="populateReport()">Load Report</b-button>
            <router-link class='btn-spacing' tag="b-button" to="/app/reports"> Cancel</router-link>
          </div> -->
          <div>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Processed at</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{processedDateTime}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Total Data</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{rptDataCount}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Late/Early submission</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumDateNotMatch}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Total Sick</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumSick}}</b-col>
            </b-row>
            <b-row>
              <b-col cols="6" sm="4" md="3" lg="2">Total >37.3</b-col>
              <b-col cols="6" sm="4" md="3" lg="2">: {{sumHighTemp}}</b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <br>
    <b-row>
      <b-col xs="12">
        <div class="table-responsive">
          <table class="table table-lg mb-0">
            <thead>
                
              <tr class="text-muted">
                <th>TANGGAL SIMPAN</th>
                <th>TANGGAL</th>
                <th>NAMA</th>
                <th>NIK</th>
                <th>DEPARTMENT</th>
                <th>USIA</th>
                <th>SUHU</th>
                <th>KONDISI KESEHATAN</th>   
                <th>ALAMAT</th>   
                <th>NO. HP KERABAT</th>   
                <th>NAMA KERABAT</th>   
                <th>RIWAYAT SAKIT KERAS</th>   
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="row in stRptData"
                :key="row.id"
              >
                <!-- <td>{{convertToReadableDateTime(row.tanggal_simpan)}}</td> -->
                <td :class="chkDateNotMatch(row.tanggal_simpan_char, row.tanggal_harian_char)">{{row.tanggal_simpan_char}}</td>
                <td>{{row.tanggal_harian_char}}</td>
                <td>{{row.nama_karyawan}}</td>
                <td>{{row.nik}}</td>
                <td>{{row.department}}</td>
                <td>{{row.usia}}</td>
                <td :class="{'bg-temp':isHighTemp(row.suhu) }">{{row.suhu}}</td>
                <td :class="{'bg-sick':isSick(row.kondisi_kesehatan) }">{{row.kondisi_kesehatan}}</td>
                <td>{{row.alamat}}</td>
                <td>{{row.nomor_hp}}</td>
                <td>{{row.nama_keluarga}}</td>
                <td>{{row.sakit_keras}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'

Vue.component('download-excel', JsonExcel)

export default {
    name: 'HealthDeclarationReport',
    data() {
      return {
        rptVersion:'v1.0',
        processedDateTime:"",
        selectedDate: null,
        selectedDateTo: null,
        selectedDept: null,
        errorMessage: null,
        isLoadingData:false,
        countSick: 0,
        countHighTemp: 0,
        countDateNotMatch: 0,
        excel_title: '',
        excel_name: '',
        excel_fields: {  
          'TANGGAL SIMPAN'      : 'tanggal_simpan_char',
          'TANGGAL'             : 'tanggal_harian',
          'NAMA'                : 'nama_karyawan',
          'NIK'                 : 'nik',
          'DEPARTMENT'          : 'department',
          'USIA'                : 'usia',
          'SUHU'                : 'suhu',
          'KONDISI KESEHATAN'   : 'kondisi_kesehatan',
          'ALAMAT'              : 'alamat',
          'NO. HP KERABAT'      : 'nomor_hp',
          'NAMA KERABAT'        : 'nama_keluarga',
          'RIWAYAT SAKIT KERAS' : 'sakit_keras',
        },
      }
    },
    computed: {
      ...mapState('report', ['stDeptList', 'stRptData', 'stRptDataCount', 'stActiveReport']),
      ...mapGetters('report', ['rptDataCount', 'rptData', 'hasData']),
      ...mapGetters('session', ['userData']),
      sumSick() {
        let percentage    = 0
        if(this.countSick > 0) {
          percentage    = parseFloat(this.countSick) * 100.00 / this.rptDataCount
        }
        return this.countSick + " ( " + percentage.toFixed(2) + "%)"
      },
      sumHighTemp() {
        let percentage    = 0
        if(this.countHighTemp > 0) {
          percentage    = parseFloat(this.countHighTemp) * 100.00 / this.rptDataCount
        }
        return this.countHighTemp + " ( " + percentage.toFixed(2) + "%)"
      },
      sumDateNotMatch() {
        let percentage    = 0
        if(this.countDateNotMatch > 0) {
          percentage    = parseFloat(this.countDateNotMatch) * 100.00 / this.rptDataCount
        }
        return this.countDateNotMatch + " ( " + percentage.toFixed(2) + "%)"
      },
    },
    methods: {
      ...mapActions('report', ['initializeReport', 'getListDepartment', 'getDataRapidTest', 'getCurrentDatetime','generateTitleXLS']),
      ...mapActions('session', ['showNotification']),
      populateReport() {
        this.processedDateTime = ""
        this.errorMessage = null
        if (this.selectedDate === null || this.selectedDept === null)
        {
          this.errorMessage = "Please select Date and Department filter"
        }
        else {
          this.isLoadingData = true
          this.getDataRapidTest({
            date: this.selectedDate,
            dateto: this.selectedDateTo,
            dept: this.selectedDept,
            businessunit: this.userData.business_unit
          })
          .then(rsp => {
            this.postProcessReport(rsp) 
          })
          .catch(err => {
            if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Failed loading report'})
              // console.log(err)
            }
          })
          .finally(()=>{
            this.isLoadingData = false
          })
        }
      },
      goToReport(){
        this.$router.push({name:"ReportPage"})
      },
      isHighTemp(param){
        let temperature = parseFloat(param)
        if(temperature > 37.3) return true
        return false
      },
      isSick(param){
        // return !param.toLowerCase().includes("sehat")
        if(param.toLowerCase().trim() == "sehat")
          return false
        return true
      },
      chkDateNotMatch(param1, param2){
        let varSubmitDate   = param1.toLowerCase().trim().slice(0,16)
        let varFormDate     = param2.toLowerCase().trim().slice(0,16)
        if(varSubmitDate !== varFormDate) {
          return 'bg-datenotmatch' 
        }
        
        return ''
      },
      postProcessReport(apiArg) {
        // console.log(apiArg)
        let cntSick             = document.getElementsByClassName('bg-sick').length
        let cntHighTemp         = document.getElementsByClassName('bg-temp').length
        let cntDateNotMatch     = document.getElementsByClassName('bg-datenotmatch').length
        this.countSick          = cntSick
        this.countHighTemp      = cntHighTemp
        this.countDateNotMatch  = cntDateNotMatch
        
        // Deploy toast dialog
        this.getCurrentDatetime().then(rsp => {
          this.processedDateTime = rsp;
          this.showNotification({type:'success', message:'Report Loaded'})
          
          this.initExcelTitle()
        })
        .catch(() => {
          this.showNotification({type:'success', message:'Failed loading report'})
          // console.log(ex)
        })
      },
      exportButtonClass() {
        let classNames = 'btn btn-info'
        if(!this.hasData) {
          classNames += ' hide'
        }
        if(this.isLoadingData){
          classNames += ' btn-disabled'
        }
        return classNames
      },
      initExcelTitle() {
        this.generateTitleXLS({
            rptName           : this.stActiveReport, 
            rptVersion        : this.rptVersion, 
            processedDateTime : this.processedDateTime, 
            processedBy       : (this.userData.username + ' - ' + this.userData.fullname)
          }).then(rsp =>{
            this.excel_name   = rsp[0]
            this.excel_title  = rsp[1]
          })
      },
    },
    mounted() {
      this.initializeReport()
      this.getListDepartment(this.userData.business_unit)
    }
};
</script>


<style src="./RapidTestReport.scss" lang="scss" scoped />
