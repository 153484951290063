<template>
    <div class="master-parameter">
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
            <b-breadcrumb-item>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card
                        header="Master Parameter"
                        header-tag="header"
                    >
                        <template v-slot:header >
                            <div class="text-left">
                                <h3>Form Master Parameter</h3>
                            </div>
                        </template>

                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
                                <b-row>
                                    <b-col sm="auto">
                                        <!-- input code setting -->
                                        <validation-provider
                                            name="codeName"
                                            :rules="{required: true}"
                                            v-slot="validationContext"
                                        >
                                             <b-form-group
                                                label="Code Name"
                                                label-for="inputCodeName"
                                                aria-describedby="input-code-name-feedback"
                                            >
                                                <b-form-input 
                                                    id="inputCodename"
                                                    v-model="form.codeSetting" 
                                                    :state="getValidationState(validationContext)"
                                                    trim></b-form-input>
                                                <b-form-invalid-feedback id="input-code-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input code setting -->

                                        <!-- input category setting -->
                                        <validation-provider
                                            name="categoryName"
                                            :rules="{required: true}"
                                            v-slot="validationContext"
                                        >
                                             <b-form-group
                                                label="Category Name"
                                                label-for="inputCategoryName"
                                                aria-describedby="input-category-name-feedback"
                                            >
                                                <b-form-select
                                                    :options="setCategory" 
                                                    text-field="category"
                                                    value-field="category"
                                                    id="inputCategoryName"
                                                    v-model="form.typeSetting" 
                                                    :state="getValidationState(validationContext)"
                                                    trim></b-form-select>
                                                <b-form-invalid-feedback id="input-category-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input category setting -->

                                        <!-- input parent setting -->
                                        <validation-provider
                                            name="parentCategory"
                                            :rules="{required: false}"
                                            v-slot="validationContext"
                                        >
                                             <b-form-group
                                                label="Parent Category"
                                                label-for="inputParentCategory"
                                                aria-describedby="input-parent-category-feedback"
                                            >
                                                <b-form-select 
                                                    id="inputParentCategory"
                                                    :options="setParentCategory"
                                                    text-field="category"
                                                    value-field="category"
                                                    v-model="form.parentCategory" 
                                                    :state="getValidationState(validationContext)"
                                                    trim>
                                                        <template #first>
                                                            <b-form-select-option :value="null">Default</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                <b-form-invalid-feedback id="input-parent-category-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input parent setting -->

                                        <!-- input parent setting -->
                                        <validation-provider
                                            name="parentName"
                                            :rules="{required: false}"
                                            v-slot="validationContext"
                                        >
                                             <b-form-group
                                                label="Parent Name"
                                                label-for="inputParentName"
                                                aria-describedby="input-parent-name-feedback"
                                            >
                                                <b-form-select 
                                                    id="inputParentName"
                                                    :options="setParent"
                                                    text-field="value"
                                                    value-field="id"
                                                    v-model="form.parentSetting" 
                                                    :state="getValidationState(validationContext)"
                                                    trim>
                                                        <template #first>
                                                            <b-form-select-option :value="null">Default</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                <b-form-invalid-feedback id="input-parent-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input parent setting -->

                                        

                                        <!-- input value setting -->
                                        <validation-provider
                                            name="valueName"
                                            :rules="{required: true}"
                                            v-slot="validationContext"
                                        >
                                             <b-form-group
                                                label="Value Name"
                                                label-for="inputValueName"
                                                aria-describedby="input-value-name-feedback"
                                            >
                                                <b-form-input 
                                                    id="inputValueName"
                                                    v-model="form.valueSetting" 
                                                    :state="getValidationState(validationContext)"
                                                    trim></b-form-input>
                                                <b-form-invalid-feedback id="input-value-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input value setting -->

                                        <!-- input description setting -->
                                        <validation-provider
                                            name="descriptionName"
                                            :rules="{required: false}"
                                            v-slot="validationContext"
                                        >
                                             <b-form-group
                                                label="Description Name"
                                                label-for="inputDescriptionName"
                                                aria-describedby="input-description-name-feedback"
                                            >
                                                <b-form-input 
                                                    id="inputDescriptionName"
                                                    v-model.trim="form.description" 
                                                    :state="getValidationState(validationContext)"
                                                    ></b-form-input>
                                                <b-form-invalid-feedback id="input-description-name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                            </b-form-group>
                                        </validation-provider>
                                        <!-- /input description setting -->
                                        <b-button variant="success" type="submit">Simpan</b-button>
                                        <b-button variant="warning" v-on:click="goToList()">Kembali</b-button>
                                    </b-col>
                                </b-row>
                                
                            </b-form>
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import apiProduct from '@/store/product-form/master-param.js'
import { extend } from 'vee-validate';
import _ from 'lodash';
import Vue from 'vue';
export default {
    name: 'MasterParameter',
    props:['master_id','name','category','value','description','parent','parentCategory'],
    data(){
        return {
            formName:'Master Parameter',
            modulName:'Create',
            errorMessage:null,
            data:[],
            form: {
                masterID:null,
                nama_karyawan: null,
                nik: null,
                department: null,
                lokasi: null,
                codeSetting:null,
                typeSetting:null,
                valueSetting:null,
                description:null,
                parentSetting:null,
                parentCategory:null,
            },
            masterParam:[],
            categoryOpt:[],
            parentCategoryOpt:[],
            parentOpt:[],

        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        getAccountInfo() {
            let userData      = JSON.parse(localStorage.userData)
            this.$set(this.form, 'nama_karyawan', userData.fullname)
            this.$set(this.form, 'nik', userData.nik)
            this.$set(this.form, 'department', userData.dept)
            this.$set(this.form, 'lokasi', userData.business_unit)
            
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            if(this.form.masterID != null){
                this.editSubmit()
            } else{
                this.createSubmit()
            }
        },
        getParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(rsp=>{
                Vue.set(this,'masterParam',rsp.data.payload)
            }).catch(err =>{
                return err
            })
                
            
        },
        createSubmit(){
            
            apiProduct.dispatch('postMasterParam',{
                master_id: this.form.masterID,
                category: this.form.typeSetting,
                name: this.form.codeSetting,
                value: this.form.valueSetting,
                description: this.form.description,
                parent:this.form.parentSetting,
                
            })
            .then(response => {
                
                this.showNotification({type:'success', message:'Successfully Submitted'})
                this.$router.push({name:"AppsMasterParamListPage"})
            })
            .catch(err => {
                
                if (err.response.status === 400) {
                    
                    this.errorMessage = err.response.data.message
                }
            })
        },
        editSubmit(){
            apiProduct.dispatch('putMasterParam',{
                
                master_id: this.form.masterID,
                category: this.form.typeSetting,
                name: this.form.codeSetting,
                value: this.form.valueSetting,
                description: this.form.description,
                parent:this.form.parentSetting,
                    
                
            })
            .then(response => {
                
                this.showNotification({type:'success', message:'Successfully Submitted'})
                this.$router.push({name:"AppsMasterParamListPage"})
            })
            .catch(err => {
                
                if (err.response.status === 400) {
                    
                    this.errorMessage = err.response.data.message
                }
            })
        },
        getEditData(){
            
            if(this.master_id != null){
                this.modulName = 'Edit'
                
                this.form.masterID = this.master_id
                this.form.codeSetting = this.name
                this.form.typeSetting = this.category
                this.form.valueSetting = this.value
                this.form.description = this.description
                this.form.parentSetting = this.parent
                this.form.parentCategory = this.parentCategory
                
                
            }
        },
        goToList(){
            this.$router.push({name:'AppsMasterParamListPage'})
        }
    },
    created(){
        this.getAccountInfo()
        
        this.getParam()
    },
    mounted(){
        this.getEditData()
    },
    computed:{

        setCategory(){
            return _.uniqBy(this.masterParam,function(u){
                // console.log('cat',u.category);
                return u.category
            })
        },
        setParentCategory(){
            return _.uniqBy(this.masterParam,function(u){
                // console.log('cat',u.category);
                return u.category
            })
        },
        setParent(){
            
            return this.masterParam.filter(x=>x.category == this.form.parentCategory)
        },
    }
}
</script>