import { getAPI } from './api-base'
import { resolve, reject } from 'core-js/fn/promise'

const apiPath = {
    getMstrCldr                 : '/v1/api/calendar/get-master-calender',
    postMstrCldr                : '/v1/api/calendar/post-subcon-calender',
    getSubCldr                  : '/v1/api/calendar/get-subcon-calender',
    getDateCalc                 : '/v1/api/calendar/date-calculation/',
}

export function fnApiAllMasterCalender(filter){
    // console.log('filet'+filter)
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.getMstrCldr, {supplier_id:filter})
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

export function fnApiGetSubconCalender(filter){
    // console.log('filet'+filter)
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.getSubCldr, filter)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}

export function fnApiFetchFormCalender(formCldr) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.postMstrCldr, formCldr)
        .then(response => { resolve(response) })
        .catch(err =>{ reject(err) })
    })
}
export function fnApiGetDateCalculation(formCldr) {
    return new Promise((resolve, reject) => {
        getAPI.post(apiPath.getDateCalc, formCldr)
        .then(rsp => { resolve(rsp) })
        .catch(err =>{ reject(err) })
    })
}