import { render, staticRenderFns } from "./SalesRecapitulation.vue?vue&type=template&id=389f4e90&scoped=true&"
import script from "./SalesRecapitulation.vue?vue&type=script&lang=js&"
export * from "./SalesRecapitulation.vue?vue&type=script&lang=js&"
import style0 from "../SalesForce.scss?vue&type=style&index=0&id=389f4e90&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "389f4e90",
  null
  
)

export default component.exports