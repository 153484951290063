<template>
    <div>
        <template>
            <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                <b-form @submit.prevent="handleSubmit(submitProductMaterial)">
                    <b-row >
                        <b-col cols="auto" md="6">
                            
                            <!-- Code Thread -->
                            <validation-provider
                                name="codeThread"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Code
                                    </template>
                                    
                                        <!-- <b-form-select id="inputCodeThread" v-model="form.codeThread"  :options="codeOpt" :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                        <v-select append-to-body
                                            :reduce="ref => ref.code" 
                                            v-model="form.codeThread"
                                            @input="setSelected" 
                                            :options="codeOpt" 
                                            placeholder="Type your code number"
                                            label="ref">
                                            <template #list-header>
                                                <b-input-group-append>
                                                    <b-button block variant="light" @click="showMaster()">
                                                        <!-- <b-icon icon="eye-fill" aria-label="Help"></b-icon> -->
                                                        Lihat Semua
                                                    </b-button>
                                                </b-input-group-append>
                                            </template>
                                            <template #option="{ code, description }">
                                                <p style="margin: 0">{{ code }}</p>
                                                <em class="text-muted">{{ description }}</em>
                                            </template>
                                        </v-select>
                                        
                                    
                                    <b-form-invalid-feedback id="inputCodeThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Type Thread -->
                            <validation-provider
                                name="TypeThread"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4"  
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Type
                                    </template>
                                
                                    <b-form-input readonly id="inputTypeThread" v-model="form.typeThread"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="inputTypeThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Description -->
                            <validation-provider
                                name="DescriptionThread"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Description
                                    </template>
                                
                                    <b-form-input readonly id="DescriptionThread" v-model="form.descriptionThread"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                    <b-form-invalid-feedback id="DescriptionThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>

                            <!-- Detail Thread -->
                            <validation-provider
                                name="DetailThread"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Detail
                                    </template>
                                
                                    <b-form-textarea id="DetailCodeThread" v-model="form.detailThread"  :state="getValidationState(validationContext)"  trim></b-form-textarea>
                                    <b-form-invalid-feedback id="DetailCodeThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <!-- UsedThread
                            <validation-provider
                                name="UsedThread"
                                :rules="{ required: false }"
                                v-slot="validationContext"
                            >
                                <b-form-group 
                                    label-cols          ="4" 
                                    label-cols-lg       ="4" 
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Used As
                                    </template>
                                    <b-form-select id="inputUsedThread" v-model="form.usedThread"  :options="usedOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                    <b-form-invalid-feedback id="inputUsedThread">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider> -->
                            <b-form-group
                                label-cols          ="4" 
                                label-cols-lg       ="4" 
                                label-size          ="sm"
                            >
                                <template v-slot:label>
                                    Images
                                </template>
                                    <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesThread" ></b-img>

                            </b-form-group>
                            
                            <div>
                                <b-button block  variant="warning" v-on:click="clearForm()">Cancel</b-button>
                                <b-button block  variant="success" type="submit">Save</b-button>
                            </div>
                            
                        </b-col>
                        <b-col cols="auto" md="6">
                            
                            <b-card sub-title="Material History">
                                <b-table
                                    responsive
                                    show-empty
                                    small
                                    fixer
                                    :items="loadMaterialHistory"
                                    :fields="fieldsApprove"
                                >
                                    <template #cell(date)='{item}'>
                                        {{new Date(item.date).toLocaleString("id-ID")}}
                                    </template>
                                </b-table>
                            </b-card>
                        </b-col>
                    </b-row>
                        
                </b-form>
            </validation-observer>
        </template>
        <template>
            <b-table
                responsive
                striped 
                hover
                :items="loadProductMaterial"
                :fields="fields"
                show-empty
            >
                
                <template #cell(images)="{item}"   >
                    <p class="small" v-for="img in item.images" :key="img.src">
                        <a :href="img.src" target="_blank">{{img.fileName.substring(0,26)}}</a>
                    </p>
                </template>
                <template #cell(actions)="{item}">
                    <b-button title="Hapus" size="xs" variant="danger" v-on:click="updateMaterial(item.product_material_id)">
                        <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                    </b-button>
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.product_material_id)">
                        
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                        
                    </b-button>
                    <b-button title="View" size="xs" variant="dark" v-on:click="modalThreadView = true">
                        
                        <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </template>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';
export default {
    props:['productId'],
    data(){
        return{
            mainProps: {width: 150, class: 'm1' },
            formName: 'Thread',
            modulName: null,
            tabIndex:1,
            isValid:false,
            dataThread:[],
            
            fields:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'images', label:'Images'},
                'detail',
                'used_as'
            ],
            imagesUrl:[],
            form:{
                productMaterialId:null,
                codeThread:null,
                typeThread:null,
                imagesThread:[],
                detailThread:null,
                usedThread:null,
                descriptionThread:null,
                threadId:null,
                category:'thread',
                statusThread:null,
                
            },
            codeOpt:[
                {code:'Code A', ref:'Code A'},
                {code:'Code B', ref:'Code B'},
                {code:'Code C', ref:'Code C'},
                {code:'Code D', ref:'Code D'},
                
            ],
            usedOpt:[
                {value:'Main', text:'Main'},
                {value:'Combo 1', text:'Combo 1'},
                {value:'Combo 2', text:'Combo 2'},
                {value:'Combo 3', text:'Combo 3'},
            ],
            threadOpt: [
                { item: 'tbd', name: 'TBD', notEnabled: true },
                { item: 'yes', name: 'Yes' },
                { item: 'no', name: 'No', notEnabled: true},
                
            ],
            isSelect:'yes',
            modalMaster:false,
            modalThreadView:false,
            fieldsApprove:[
                {key:'date',label:'Date'},
                'status','comment','code'
                // {key:'created_by', label:'Approve By'}
            ],
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_MATERIAL_DEV','POST_PRODUCT_MATERIAL','PUT_PRODUCT_MATERIAL']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL']),
        
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        getProductMaterial(){
            // console.log('id',this.productId);
            this.GET_PRODUCT_MATERIAL_DEV({
                product:this.productId
            }).then(rsp=>{
                // console.log('data',rsp.data.payload.filter(i=>i.category ==='thread' ))
                let data = rsp.data.payload.filter(i=>i.category ==='thread' && i.product_id === this.productId)

                this.dataThread = data
            }).catch(err=>{
                return err
            })
        },
        getThreadMaster(){
            this.GET_MASTER_MATERIAL().then(response => {
                this.codeOpt = []
                    let objThread = response.data.payload.filter(x=>x.category === 'thread')
                    for(var i=0;i<objThread.length;i++){
                        
                            let arrThread = {
                                ref: objThread[i].code,
                                code: objThread[i].code,
                                description:objThread[i].description,
                            }
                            this.codeOpt.push(arrThread)
                    }
                    
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        clearForm(){
            this.form.indexId = null
            this.form.codeThread = null
            this.form.typeThread = null
            this.form.imagesThread = []
            this.imagesUrl = []
            this.form.detailThread = null
            this.form.usedThread = null
            this.form.threadId = null
            this.form.descriptionThread = null
            this.form.productMaterialId     = null
            this.form.statusThread = null
            this.$refs.observer.reset()
        },
        setSelected(value){
            // console.log(value);
            if(value != null){
                let dataThread = this.MASTER_MATERIAL.filter(x => x.code === value)
                // console.log(dataThread);
                this.form.threadId= dataThread[0].id
                this.form.codeThread= dataThread[0].code
                this.form.typeThread= dataThread[0].type
                this.form.descriptionThread = dataThread[0].description
                this.form.imagesThread = dataThread[0].img_src
            }else{
                console.log('belum ada data')
            }
        },
        submitProductMaterial(){
                let productMaterial = []
                let thread = {
                    category: this.form.category,
                    material_id: this.form.threadId,
                    code: this.form.codeThread,
                    type: this.form.typeThread,
                    used_as: this.form.usedThread,
                    detail: this.form.detailThread,
                    description: this.form.descriptionThread,
                    status:'new',
                    is_proccess:'active'
                }    
                productMaterial.push(thread)

            if(this.form.productMaterialId){
                this.PUT_PRODUCT_MATERIAL({
                    product_material_id:this.form.productMaterialId,
                    detail:this.form.detailThread,
                    used_as:this.form.usedThread,
                    process:this.form.statusThread
                }).then(rsp=>{
                    console.log('berhasil dihapus');
                    this.getProductMaterial()
                    this.clearForm()
                })
            }else{
                this.POST_PRODUCT_MATERIAL({
                product_id:this.productId,
                material:productMaterial

            })
                .then(response => {
                    console.log('success submit product material');
                    this.getProductMaterial()
                    this.clearForm()
                })
                .catch(error => {
                    console.log('material save',error);
                })
            }  
            
        },
        editData(id){
            let data = this.dataThread.filter(i=>i.product_material_id === id)
            // console.log('edit data',data);

            this.form.productMaterialId     = id
            this.form.threadId              = data[0].id
            this.form.codeThread            = data[0].code
            this.form.typeThread            = data[0].type
            this.form.detailThread          = data[0].detail
            this.form.usedThread            = data[0].used_as
            this.form.descriptionThread     = data[0].description
            this.form.statusThread          = data[0].product_material_proccess
        },
        updateMaterial(id){
            this.PUT_PRODUCT_MATERIAL({
                product_material_id:id,
                detail:this.form.detailThread,
                used_as:this.form.usedThread,
                process:'drop'
            }).then(rsp=>{
                console.log('berhasil dihapus');
                this.getProductMaterial()
                this.clearForm()
            })
        }

    },
    created(){
        this.getProductMaterial()
        this.getThreadMaster()
    },
    computed:{
        
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        ...mapGetters('productDesign',['GET_MATERIAL_HISTORY']),
        loadMaterialHistory(){
            
            return this.GET_MATERIAL_HISTORY.filter(i=>i.category === 'thread')
        },
        loadProductMaterial(){
           
            return this.dataThread
        }
    }
}
</script>