<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-master-customer"
          >Master Customer</router-link
        >
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Master Customer Form" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style="display: flex">
                <h3>Master Customer Form</h3>
                <b-button
                  v-if="disabledForm == true"
                  @click="BackHistoryButton()"
                  size="md"
                  type="button"
                  variant="info"
                  style="position: absolute; right: 15px"
                >
                  <b-icon icon="arrow-left"></b-icon>
                  Back
                </b-button>
              </div>
            </template>

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(onSubmit)">
                <b-row align-h="center">
                  <b-col sm="6">
                    <!-- input customer name -->
                    <validation-provider
                      name="customer_name"
                      vid="customer_name"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Customer Name *"
                        label-class="text-primary"
                        label-for="customerName"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <b-form-input
                          id="customerName"
                          :disabled="disabledForm"
                          v-model="form.customer_name"
                          placeholder="Enter Your Name"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-customerName-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input customer name -->

                    <!-- input GroupCustomer -->
                    <validation-provider
                      name="group_customer"
                      vid="group_customer"
                      :rules="{ required: false }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Customer Group *"
                        label-class="text-primary"
                        label-for="CustomerGroup"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <!-- <div style="display: flex; left: -5px; position: relative;"> -->
                        <multiselect
                          :disabled="Approval"
                          v-model="selectCustomerGroup"
                          tag-placeholder="Add Customer Group"
                          placeholder="Search or Add Customer Group"
                          label="name"
                          @input="InputCustomerGrpup"
                          :options="options.groupCustomer"
                          track-by="name"
                          :multiple="false"
                          :taggable="true"
                          @tag="addTag"
                        >
                        </multiselect>
                        <!-- </div> -->
                        <span
                          id="warning-groupCustomer"
                          class="display-hidden text-small text-danger"
                          >"Required"</span
                        >
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-customerGroup-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input customer name -->

                    <!-- input est_employee -->
                    <validation-provider
                      name="est_employee"
                      vid="est_employee"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Est Employee *"
                        label-class="text-primary"
                        label-for="est_employee"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <b-form-input
                          id="est_employee"
                          v-model="estEmployee"
                          autocomplete="off"
                          :disabled="disabledForm"
                          placeholder="Enter Your Est Employee"
                          type="text"
                          @keypress="isNumber($event)"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-est_employee-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input est_employee-->

                    <!-- input industrial -->
                    <validation-provider
                      name="industrial"
                      vid="industrial"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Sector *"
                        label-class="text-primary"
                        label-for="industrial"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <v-select
                          append-to-body
                          :reduce="(data) => data.name"
                          v-model="form.selectedIndustrial"
                          :disabled="disabledForm"
                          :clearable="false"
                          @input="ChangeSector()"
                          :options="options.industrial"
                          placeholder="Enter Your sector"
                          label="name"
                        >
                        </v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-industrial-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input industrial -->

                    <!-- input sector -->
                    <validation-provider
                      name="sector"
                      vid="sector"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Sub Sector *"
                        label-class="text-primary"
                        label-for="sector"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <v-select
                          append-to-body
                          :reduce="(data) => data.name"
                          v-model="form.selectedSector"
                          :clearable="false"
                          :disabled="disabledForm"
                          :options="options.sector"
                          placeholder="Enter Your Sub Sector"
                          label="name"
                        >
                        </v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-sector-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input sector -->

                    <!-- input type -->
                    <validation-provider
                      name="type"
                      vid="type"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Type *"
                        label-class="text-primary"
                        label-for="type"
                        label-cols-sm="4"
                        label-align-sm="left"
                        label-size="sm"
                      >
                        <v-select
                          append-to-body
                          :reduce="(data) => data.name"
                          v-model="form.selectedType"
                          :disabled="disabledForm"
                          :clearable="false"
                          :options="options.type"
                          placeholder="Enter Your Type Organization"
                          label="name"
                        >
                        </v-select>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-type-feedback"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input type -->

                    <!-- input tax -->
                    <b-form-group
                      label="Tax"
                      label-for="tax"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-radio-group
                        v-model="form.tax"
                        style="top: 5px; position: relative"
                        :disabled="disabledForm"
                        name="tax"
                        ref="opt_tax"
                      >
                        <b-radio class="radio" value="Non Vat">Non PKP</b-radio>
                        <b-radio value="Vat 10%">PKP</b-radio>
                      </b-form-radio-group>
                    </b-form-group>
                    <!-- /input tax -->

                    <!-- input tax -->
                    <b-form-group
                      v-if="buttonSubmit == 'edit' || buttonSubmit == 'view'"
                      label="Sales Person"
                      label-for="salesPerson"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-input
                        id="salesPerson"
                        v-model="form.salesPerson"
                        :disabled="true"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input tax -->
                  </b-col>

                  <b-col sm="6">
                    <!-- input additional information -->
                    <b-form-group
                      label="Additional Information"
                      label-for="additionalInformation"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-textarea
                        id="additionalInformation"
                        v-model="form.additionalInformation"
                        placeholder="Enter Your Information"
                        :disabled="disabledForm"
                        no-resize
                        rows="3"
                      ></b-form-textarea>
                    </b-form-group>
                    <!-- /input additional information -->

                    <!-- checkbox company portal -->
                    <b-form-group
                      label="Company Portal"
                      label-for="CompanyPortal"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-checkbox
                        id="CompanyPortal"
                        style="top: 5px"
                        v-model="CompanyPortal"
                        @change="CheckPortal"
                        name="CompanyPortal"
                        value="accepted"
                        unchecked-value="not_accepted"
                      >
                      </b-form-checkbox>
                    </b-form-group>

                    <b-col sm="12" id="id_companyPortal" class="display-hidden">
                      <!-- Portal Url -->
                      <validation-provider
                        name="portal_url"
                        vid="portal_url"
                        :rules="{ required: !checkCompanyPortal() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Portal Url *"
                          label-class="text-primary"
                          label-for="portal_url"
                          label-cols-sm="4"
                          label-align-sm="left"
                          label-size="sm"
                        >
                          <b-form-input
                            id="portal_url"
                            size="sm"
                            :disabled="disabledForm"
                            v-model="portalUrl"
                            placeholder="Enter Your Portal Url"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-portal_url-feedback"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <!-- Email Portal -->
                      <validation-provider
                        name="email"
                        vid="email"
                        :rules="{ required: !checkCompanyPortal() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Registration Email *"
                          label-class="text-primary"
                          label-for="email"
                          label-cols-sm="4"
                          label-align-sm="left"
                          label-size="sm"
                        >
                          <b-form-input
                            id="email"
                            size="sm"
                            :disabled="disabledForm"
                            v-model="registrationEmail"
                            placeholder="Enter Your Email"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-email-feedback"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <!-- Username Portal -->
                      <validation-provider
                        name="username"
                        vid="username"
                        :rules="{ required: !checkCompanyPortal() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Username *"
                          label-class="text-primary"
                          label-for="username"
                          label-cols-sm="4"
                          label-align-sm="left"
                          label-size="sm"
                        >
                          <b-form-input
                            id="username"
                            size="sm"
                            :disabled="disabledForm"
                            v-model="usernamePortal"
                            placeholder="Enter Your Username"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-username-feedback"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <!-- Password Portal -->
                      <validation-provider
                        name="password"
                        vid="password"
                        :rules="{ required: !checkCompanyPortal() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Password *"
                          label-class="text-primary"
                          label-for="password"
                          label-cols-sm="4"
                          label-align-sm="left"
                          label-size="sm"
                        >
                          <b-form-input
                            id="password"
                            size="sm"
                            :disabled="disabledForm"
                            v-model="passwordPortal"
                            placeholder="Enter Your Password"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-password-feedback"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-col>
                </b-row>

                <!-- Table Customer Location -->
                <b-row>
                  <b-col sm="6">
                    <b-card no-body>
                      <template #header>
                        <span>Customer Location</span>
                      </template>
                      <b-table
                        :no-border-collapse="true"
                        striped
                        style="margin-bottom: 0px"
                        stacked="md"
                        class="header-background header-center td-center max_width_fit"
                        hover
                        small
                        show-empty
                        responsive
                        :fields="fieldsLocation"
                        :items="dataLocationTable"
                      >
                        <template #empty="scope">
                          <p class="text-center display-hidden">
                            {{ scope.emptyText }}
                          </p>
                          <div style="text-align: center !important">
                            <b-button
                              title="Add"
                              size="xs"
                              v-if="!disabledForm"
                              variant="outline-success"
                              v-on:click="show = !show"
                              style="margin-right: 5px"
                            >
                              <b-icon
                                icon="person-plus-fill"
                                aria-label="Add"
                              ></b-icon>
                            </b-button>
                          </div>
                        </template>

                        <!-- template type Location -->
                        <template #cell(location_display)="row">
                          <span
                            v-if="row.value !== []"
                            v-html="toString(row.value)"
                          ></span>
                        </template>

                        <!-- template  location name -->
                        <template #cell(location_name)="row">
                          <span v-if="row.value !== ''">{{ row.value }}</span>
                        </template>

                        <!-- template  address -->
                        <template #cell(address)="row">
                          <span v-if="row.value !== ''">{{ row.value }}</span>
                        </template>

                        <template #table-caption>
                          <b-button
                            v-if="dataLocationTable.length > 0 && !disabledForm"
                            title="Add"
                            size="xs"
                            variant="outline-success"
                            v-on:click="show = !show"
                            style="margin-right: 5px"
                          >
                            <b-icon
                              icon="person-plus-fill"
                              aria-label="Add"
                            ></b-icon>
                          </b-button>
                        </template>

                        <!-- <template v-slot:cell(actions)="{item}"> -->
                        <template #cell(action)="row">
                          <b-button
                            title="View"
                            size="xs"
                            variant="outline-info"
                            v-on:click="ViewModal(row, 'Location')"
                            style="margin-right: 5px"
                          >
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>
                          </b-button>

                          <!-- <b-button :disabled="disablebtnSubmit(form.selectedtypeTable)" v-if="row.item.type == '' || row.item.type == null" title="Submit" size="sm" variant="outline-primary" @click="submitAddLocationTable(row)" style='margin-right:5px;'>
                              <b-icon icon="check-square-fill" aria-label="Submit"></b-icon>
                          </b-button> -->

                          <b-button
                            title="Delete"
                            size="xs"
                            v-if="!disabledForm"
                            type="button"
                            variant="outline-danger"
                            v-on:click="Confirm('delete', row, 'Location')"
                          >
                            <b-icon
                              icon="trash-fill"
                              aria-label="Delete"
                            ></b-icon>
                          </b-button>
                        </template>
                      </b-table>
                    </b-card>
                  </b-col>

                  <!-- Table Customer Contact -->
                  <b-col sm="6">
                    <b-card no-body>
                      <template #header>
                        <span>Customer Contact</span>
                      </template>
                      <b-table
                        :no-border-collapse="true"
                        striped
                        style="margin-bottom: 0px"
                        stacked="md"
                        class="header-background header-center td-center"
                        hover
                        small
                        show-empty
                        responsive
                        :fields="fieldsContact"
                        :items="dataContactTable"
                      >
                        <template #empty="scope">
                          <p class="text-center display-hidden">
                            {{ scope.emptyText }}
                          </p>
                          <div style="text-align: center !important">
                            <b-button
                              title="Add"
                              size="xs"
                              v-if="!disabledForm"
                              :disabled="disabledForm"
                              variant="outline-success"
                              v-on:click="showContact = !showContact"
                              style="margin-right: 5px"
                            >
                              <b-icon
                                icon="person-plus-fill"
                                aria-label="Add"
                              ></b-icon>
                            </b-button>
                          </div>
                        </template>

                        <template #table-caption>
                          <b-button
                            v-if="dataContactTable.length > 0 && !disabledForm"
                            title="Add"
                            size="xs"
                            :disabled="disabledForm"
                            variant="outline-success"
                            v-on:click="showContact = !showContact"
                            style="margin-right: 5px"
                          >
                            <b-icon
                              icon="person-plus-fill"
                              aria-label="Add"
                            ></b-icon>
                          </b-button>
                        </template>

                        <!-- <template v-slot:cell(actions)="{item}"> -->
                        <template #cell(action)="row">
                          <b-button
                            title="View"
                            size="xs"
                            variant="outline-info"
                            v-on:click="ViewModal(row, 'contact')"
                            style="margin-right: 5px"
                          >
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>
                          </b-button>

                          <!-- <b-button :disabled="disablebtnSubmit(form.selectedtypeTable)" v-if="row.item.type == '' || row.item.type == null" title="Submit" size="sm" variant="outline-primary" @click="submitAddLocationTable(row)" style='margin-right:5px;'>
                              <b-icon icon="check-square-fill" aria-label="Submit"></b-icon>
                          </b-button> -->

                          <b-button
                            title="Delete"
                            size="xs"
                            v-if="!disabledForm"
                            type="button"
                            variant="outline-danger"
                            v-on:click="Confirm('delete', row, 'contact')"
                          >
                            <b-icon
                              icon="trash-fill"
                              aria-label="Delete"
                            ></b-icon>
                          </b-button>
                        </template>
                      </b-table>
                    </b-card>
                  </b-col>
                  <!-- / Table Customer Contact -->
                </b-row>
                <br />
                <b-row align-h="center">
                  <b-col md="6">
                    <b-button
                      v-if="buttonSubmit == null"
                      block
                      size="md"
                      type="submit"
                      variant="primary"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-if="buttonSubmit == 'edit'"
                      block
                      size="md"
                      type="submit"
                      variant="success"
                    >
                      Confirm
                    </b-button>
                    <b-button
                      v-if="buttonSubmit == 'approve'"
                      block
                      size="md"
                      type="submit"
                      variant="primary"
                    >
                      Approve
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>

          <!-- Modal Location -->
          <template>
            <b-modal
              v-model="show"
              hide-footer
              header-bg-variant="secondary"
              hide-backdrop
              hide-header-close
              size="md"
              ref="modal"
              no-close-on-backdrop
              title="Form Customer Location"
            >
              <div id="modal">
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                  <b-form
                    @submit.prevent="handleSubmit(submitAddLocationTable)"
                  >
                    <b-row>
                      <!-- ## Location Type ## -->
                      <b-col cols="12">
                        <validation-provider
                          name="LocationType"
                          :rules="{ required: true }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label="Location"
                            label-cols="3"
                            label-cols-md="3"
                            label-size="sm"
                          >
                            <!-- <v-select
                                          :reduce="data => data.value"
                                          v-model="form.selectedtypeTable"
                                          :clearable="false"
                                          placeholder="Enter Location Type"
                                          :required="true"
                                          :options="optionTypeTable"
                                          label="name">
                                        </v-select> -->
                            <b-form-checkbox
                              id="shipping"
                              v-model="form.TypeShipping"
                              name="shipping"
                              :value="true"
                              style="display: inline-block; margin-left: 10px"
                            >
                              Shipping
                            </b-form-checkbox>

                            <b-form-checkbox
                              id="billing"
                              v-model="form.TypeBilling"
                              name="billing"
                              :value="true"
                              style="display: inline-block; margin-left: 10px"
                            >
                              Billing
                            </b-form-checkbox>

                            <b-form-checkbox
                              id="legal"
                              v-model="form.TypeLegal"
                              name="legal"
                              :value="true"
                              style="display: inline-block; margin-left: 10px"
                            >
                              Legal
                            </b-form-checkbox>
                            <b-form-invalid-feedback id="typeAddress">{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- ## Location Name ## -->
                      <b-col cols="12">
                        <b-form-group
                          label="Location Name"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <b-form-input
                            id="LocationName"
                            v-model="form.LocationNameTable"
                            placeholder="Enter Your Location Name"
                            trim
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <!-- ## NPWP Num ## -->
                      <b-col
                        cols="12"
                        v-if="
                          form.TypeBilling == true || form.TypeLegal == true
                        "
                      >
                        <b-form-group
                          label="NPWP Number"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <b-form-input
                            id="NPWPNoTable"
                            v-model="form.npwpnoTable"
                            placeholder="Enter Your NPWP Number"
                            type="text"
                            :maxlength="15"
                            @keypress="isNumber($event)"
                            trim
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <!-- ## NPWP NAME ## -->
                      <b-col
                        cols="12"
                        v-if="
                          form.TypeBilling == true || form.TypeLegal == true
                        "
                      >
                        <b-form-group
                          label="NPWP Name"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <b-form-input
                            id="NPWPNameTable"
                            v-model="form.npwpnameTable"
                            placeholder="Enter Your NPWP Name"
                            trim
                          ></b-form-input>
                        </b-form-group>
                      </b-col>

                      <!-- ## TAX ## -->
                      <b-col cols="12" v-if="form.TypeShipping == true">
                        <b-form-group
                          label="Tax"
                          label-for="tax"
                          label-cols-sm="3"
                          label-align-sm="left"
                          label-size="sm"
                        >
                          <b-form-radio-group
                            v-model="form.taxTable"
                            name="tax"
                            ref="opt_tax"
                          >
                            <b-radio class="radio" value="non_vat"
                              >Non VAT</b-radio
                            >
                            <b-radio value="ppn10%">PPN 10%</b-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </b-col>

                      <!-- ## Term Of Payment ## -->
                      <b-col cols="12" v-if="form.TypeBilling == true">
                        <b-form-group
                          label="Term of Payment"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <v-select
                            :reduce="(data) => data.code"
                            v-model="form.termOfPaymentTable"
                            placeholder="Enter Your Term Of Payment"
                            :clearable="false"
                            :options="options.termOfPayment"
                            label="code"
                          >
                          </v-select>
                          <!-- <b-form-input
                                      id="TermofPayment"
                                      v-model="form.termOfPaymentTable"
                                      placeholder="Enter Your Term Of Payment"
                                      type="number"
                                      trim
                                      ></b-form-input> -->
                        </b-form-group>
                      </b-col>

                      <!-- ## Address ## -->
                      <b-col cols="12">
                        <b-form-group
                          label="Address"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <b-form-textarea
                            id="AddressTable"
                            v-model="form.addressTable"
                            placeholder="Enter Your Address"
                            no-resize
                            rows="2"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>

                      <!-- ## Province ## -->
                      <b-col cols="12">
                        <b-form-group
                          label="Province"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <v-select
                            :reduce="(data) => data.name"
                            v-model="form.selectedProvinceTable"
                            placeholder="Enter Your Province"
                            :clearable="false"
                            :options="options.provinceTable"
                            label="name"
                          >
                          </v-select>
                        </b-form-group>
                      </b-col>

                      <!-- ## City ## -->
                      <b-col cols="12">
                        <b-form-group
                          label="City"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <v-select
                            :reduce="(data) => data.name"
                            v-model="form.selectedCityTable"
                            placeholder="Enter Your City"
                            :clearable="false"
                            :options="options.cityTable"
                            label="name"
                          >
                          </v-select>
                        </b-form-group>
                      </b-col>

                      <!-- ## District ## -->
                      <b-col cols="12">
                        <b-form-group
                          label="District/ Kecamatan"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <v-select
                            :reduce="(data) => data.name"
                            v-model="form.selectedKecamatanTable"
                            placeholder="Enter Your District"
                            :clearable="false"
                            :options="options.kecamatanTable"
                            label="name"
                          >
                          </v-select>
                        </b-form-group>
                      </b-col>

                      <!-- ## Ward ## -->
                      <b-col cols="12">
                        <b-form-group
                          label="Ward/ Kelurahan"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <v-select
                            v-model="form.selectedKelurahanKodePosTable"
                            :clearable="false"
                            placeholder="Enter Your Ward"
                            :options="options.kelurahanTable"
                            label="name"
                          >
                            <template
                              slot="selected-option"
                              slot-scope="option"
                            >
                              <span>{{ option.name }} - {{ option.code }}</span>
                            </template>
                            <template slot="option" slot-scope="option">
                              <span>
                                {{ option.name }} - {{ option.code }}
                              </span>
                            </template>
                          </v-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row align-h="end">
                      <b-col sm="3">
                        <b-button
                          block
                          size="md"
                          type="submit"
                          variant="success"
                        >
                          Save
                        </b-button>
                      </b-col>
                      <b-col sm="3">
                        <b-button
                          block
                          size="md"
                          @click="cancel('Location')"
                          type="button"
                          variant="warning"
                        >
                          Cancel
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
              </div>
            </b-modal>
          </template>

          <!-- Modal Contact -->
          <template>
            <b-modal
              v-model="showContact"
              hide-footer
              header-bg-variant="secondary"
              hide-backdrop
              hide-header-close
              size="md"
              ref="modal"
              no-close-on-backdrop
              title="Form Customer Contact"
            >
              <div id="modal">
                <validation-observer ref="observer">
                  <b-row>
                    <!-- ## Name ## -->
                    <b-col cols="12">
                      <validation-provider
                        name="NameContact"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Name"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <b-form-input
                            id="NameContact"
                            v-model="contact.Name"
                            placeholder="Enter Your Name"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback id="NameContact">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- ## Position ## -->
                    <b-col cols="12">
                      <validation-provider
                        name="PositionContact"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Position"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <!-- <b-form-input
                                        id="PositionContact"
                                        v-model="contact.Position"
                                        placeholder="Enter Your Position"
                                        trim
                                      ></b-form-input> -->
                          <v-select
                            :reduce="(data) => data.value"
                            v-model="contact.Position"
                            placeholder="Enter Your Position"
                            :clearable="false"
                            :options="options.Position"
                            label="value"
                          >
                          </v-select>
                          <b-form-invalid-feedback id="PositionContact">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- ## Department ## -->
                    <b-col cols="12">
                      <validation-provider
                        name="DepartmentContact"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Department"
                          label-cols="3"
                          label-cols-md="3"
                          label-size="sm"
                        >
                          <v-select
                            :reduce="(data) => data.value"
                            v-model="contact.Department"
                            placeholder="Enter Your Department"
                            :clearable="false"
                            :options="options.Department"
                            label="value"
                          >
                          </v-select>
                          <!-- <b-form-input
                                        id="DepartmentContact"
                                        v-model="contact.Department"
                                        placeholder="Enter Your Department"
                                        trim
                                      ></b-form-input> -->
                          <b-form-invalid-feedback id="DepartmentContact">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- ## Email ## -->
                    <b-col cols="12">
                      <b-form-group
                        label="Email"
                        label-cols="3"
                        label-cols-md="3"
                        label-size="sm"
                      >
                        <b-form-input
                          id="EmailContact"
                          v-model="contact.Email"
                          placeholder="Enter Your Email"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <!-- ## Mobile Number ## -->
                    <b-col cols="12">
                      <b-form-group
                        label="Mobile Number"
                        label-cols="3"
                        label-cols-md="3"
                        label-size="sm"
                      >
                        <b-form-input
                          id="MobileNumber"
                          v-model="contact.MobileNumber"
                          placeholder="Enter Your Mobile Number"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <!-- ## Phone Number ## -->
                    <b-col cols="12">
                      <b-form-group
                        label="Phone Number"
                        label-cols="3"
                        label-cols-md="3"
                        label-size="sm"
                      >
                        <b-form-input
                          id="CodePhoneNumber"
                          style="width: 25%; margin-right: 5%; float: left"
                          :maxlength="4"
                          v-model="contact.CodePhoneNumber"
                          @keypress="isNumber($event)"
                          placeholder="E.g:021"
                          trim
                        ></b-form-input>
                        <b-form-input
                          id="PhoneNumber"
                          style="width: 70%; float: left"
                          v-model="contact.PhoneNumber"
                          @keypress="isNumber($event)"
                          placeholder="Enter Your Phone Number"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <!-- ## Ext Number ## -->
                    <b-col cols="12">
                      <b-form-group
                        label="Ext"
                        label-cols="3"
                        label-cols-md="3"
                        label-size="sm"
                      >
                        <b-form-input
                          id="ExtPhoneNumber"
                          v-model="contact.ExtPhoneNumber"
                          placeholder="Enter Your Ext"
                          trim
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row align-h="end">
                    <b-col sm="3">
                      <b-button
                        block
                        size="md"
                        @click="submitAddContactTable()"
                        type="submit"
                        variant="success"
                      >
                        Save
                      </b-button>
                    </b-col>
                    <b-col sm="3">
                      <b-button
                        block
                        size="md"
                        @click="cancel('contact')"
                        type="button"
                        variant="warning"
                      >
                        Cancel
                      </b-button>
                    </b-col>
                  </b-row>
                </validation-observer>
              </div>
            </b-modal>
          </template>

          <!-- Modal View -->
          <template>
            <b-modal
              v-model="showView"
              hide-footer
              header-bg-variant="secondary"
              hide-backdrop
              size="md"
              ref="modal"
              no-close-on-backdrop
              title="Form Customer Location"
            >
              <div id="modal">
                <b-row v-if="view.value == 'Location'">
                  <!-- ## Location Type ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Location"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <!-- <v-select
                                          :reduce="data => data.value"
                                          v-model="view.location"
                                          :disabled="DisabledModal"
                                          :clearable="false"
                                          placeholder="Enter Location Type"
                                          :required="true"
                                          :options="optionTypeTable"
                                          label="name">
                                        </v-select> -->
                      <b-form-checkbox
                        id="shipping"
                        v-model="view.TypeShipping"
                        name="shipping"
                        :disabled="DisabledModal"
                        :value="true"
                        style="display: inline-block; margin-left: 10px"
                      >
                        Shipping
                      </b-form-checkbox>

                      <b-form-checkbox
                        id="billing"
                        v-model="view.TypeBilling"
                        name="billing"
                        :disabled="DisabledModal"
                        :value="true"
                        style="display: inline-block; margin-left: 10px"
                      >
                        Billing
                      </b-form-checkbox>

                      <b-form-checkbox
                        id="legal"
                        v-model="view.TypeLegal"
                        name="legal"
                        :disabled="DisabledModal"
                        :value="true"
                        style="display: inline-block; margin-left: 10px"
                      >
                        Legal
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- ## Location Name ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Location Name"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="LocationName"
                        v-model="view.location_name"
                        placeholder="Enter Your Location Name"
                        :disabled="DisabledModal"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <!-- ## NPWP Num ## -->
                  <b-col
                    cols="12"
                    v-if="
                      view.TypeBilling == true ||
                      view.TypeLegal == true ||
                      titleHeader == 'View'
                    "
                  >
                    <b-form-group
                      label="NPWP Number"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="NPWPNoTable"
                        v-model="view.npwp_no"
                        placeholder="Enter Your NPWP Number"
                        :disabled="DisabledModal"
                        type="text"
                        trim
                      ></b-form-input>
                      <!-- <b-form-input
                                      id="NPWPNoTable"
                                      v-model="view.npwp_no"
                                      placeholder="Enter Your NPWP Number"
                                      :disabled="DisabledModal"
                                      type="number"
                                      trim
                                      ></b-form-input> -->
                    </b-form-group>
                  </b-col>

                  <!-- ## NPWP NAME ## -->
                  <b-col
                    cols="12"
                    v-if="
                      view.TypeBilling == true ||
                      view.TypeLegal == true ||
                      titleHeader == 'View'
                    "
                  >
                    <b-form-group
                      label="NPWP Name"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="NPWPNameTable"
                        v-model="view.npwp_name"
                        :disabled="DisabledModal"
                        placeholder="Enter Your NPWP Name"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <!-- ## TAX ## -->
                  <b-col
                    cols="12"
                    v-if="view.TypeShipping == true || titleHeader == 'View'"
                  >
                    <b-form-group
                      label="Tax"
                      label-for="tax"
                      label-cols-sm="3"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <b-form-radio-group
                        v-model="view.tax"
                        name="tax"
                        :disabled="DisabledModal"
                        ref="opt_tax"
                      >
                        <b-radio class="radio" value="non_vat">Non VAT</b-radio>
                        <b-radio value="ppn10%">PPN 10%</b-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>

                  <!-- ## Term Of Payment ## -->
                  <b-col
                    cols="12"
                    v-if="view.TypeBilling == true || titleHeader == 'View'"
                  >
                    <b-form-group
                      label="Term of Payment"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="TermofPayment"
                        v-model="view.top"
                        placeholder="Enter Your Term Of Payment"
                        type="text"
                        :disabled="DisabledModal"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <!-- ## Address ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Address"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-textarea
                        id="AddressTable"
                        v-model="view.address"
                        placeholder="Enter Your Address"
                        :disabled="DisabledModal"
                        no-resize
                        rows="2"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>

                  <!-- ## Province ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Province"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <v-select
                        :reduce="(data) => data.name"
                        v-model="view.province"
                        :disabled="DisabledModal"
                        placeholder="Enter Your Province"
                        :clearable="false"
                        :options="options.provinceTable"
                        label="name"
                      >
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- ## City ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="City"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <v-select
                        :reduce="(data) => data.name"
                        v-model="view.city"
                        :disabled="DisabledModal"
                        placeholder="Enter Your City"
                        :clearable="false"
                        :options="options.cityTable"
                        label="name"
                      >
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- ## District ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="District/ Kecamatan"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <v-select
                        :reduce="(data) => data.name"
                        v-model="view.kecamatan"
                        :disabled="DisabledModal"
                        placeholder="Enter Your District"
                        :clearable="false"
                        :options="options.kecamatanTable"
                        label="name"
                      >
                      </v-select>
                    </b-form-group>
                  </b-col>

                  <!-- ## Ward ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Ward/ Kelurahan"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <v-select
                        v-model="view.kelurahan_codepos"
                        :disabled="DisabledModal"
                        :clearable="false"
                        placeholder="Enter Your Ward"
                        :options="options.kelurahanTable"
                        label="name"
                      >
                        <template slot="selected-option" slot-scope="option">
                          <span>{{ option.name }} - {{ option.code }}</span>
                        </template>
                        <template slot="option" slot-scope="option">
                          <span> {{ option.name }} - {{ option.code }} </span>
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="view.value == 'contact'">
                  <!-- ## Name ## -->
                  <b-col cols="12">
                    <validation-provider
                      name="NameContact"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Name"
                        label-cols="3"
                        label-cols-md="3"
                        label-size="sm"
                      >
                        <b-form-input
                          id="NameContact"
                          v-model="view.contact_name"
                          :disabled="DisabledModal"
                          placeholder="Enter Your Name"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="NameContact">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ## Position ## -->
                  <b-col cols="12">
                    <validation-provider
                      name="PositionContact"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Position"
                        label-cols="3"
                        label-cols-md="3"
                        label-size="sm"
                      >
                        <b-form-input
                          id="PositionContact"
                          v-model="view.contact_position"
                          :disabled="DisabledModal"
                          placeholder="Enter Your Position"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="PositionContact">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ## Department ## -->
                  <b-col cols="12">
                    <validation-provider
                      name="DepartmentContact"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Department"
                        label-cols="3"
                        label-cols-md="3"
                        label-size="sm"
                      >
                        <b-form-input
                          id="DepartmentContact"
                          v-model="view.contact_department"
                          :disabled="DisabledModal"
                          placeholder="Enter Your Department"
                          trim
                        ></b-form-input>
                        <b-form-invalid-feedback id="Department">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- ## Email ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Email"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="EmailContact"
                        v-model="view.contact_email"
                        :disabled="DisabledModal"
                        placeholder="Enter Your Email"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <!-- ## Mobile Number ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Mobile Number"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="MobileNumber"
                        v-model="view.contact_mobile_number"
                        :disabled="DisabledModal"
                        placeholder="Enter Your Mobile Number"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <!-- ## Phone Number ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Phone Number"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="PhoneNumber"
                        v-model="view.contact_code_phone_number"
                        style="width: 25%; margin-right: 5%; float: left"
                        :disabled="DisabledModal"
                        placeholder="Enter Your Code Phone Number"
                        trim
                      ></b-form-input>
                      <b-form-input
                        id="PhoneNumber"
                        v-model="view.contact_phone_number"
                        style="width: 70%; float: left"
                        :disabled="DisabledModal"
                        placeholder="Enter Your Phone Number"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </b-col>

                  <!-- ## Ext ## -->
                  <b-col cols="12">
                    <b-form-group
                      label="Ext"
                      label-cols="3"
                      label-cols-md="3"
                      label-size="sm"
                    >
                      <b-form-input
                        id="ExtPhoneNumber"
                        v-model="view.contact_ext_phone_number"
                        :disabled="DisabledModal"
                        placeholder="Enter Your Ext"
                        trim
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-modal>
          </template>
        </b-col>
      </b-row>
      <Loader
        v-if="isLoadingData"
        fullPage
        useOverlay
        loader="spinner"
      ></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
import apiMasterCustomer from "@/store/salesforce-form/master-customer.js";
import "vue-search-select/dist/VueSearchSelect.css";
import Vue from "vue";
import apiGeneral from "@/store/general.js";
import { saveErrorLog } from "@/store/general.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

// import vue multiselect
import Multiselect from "vue-multiselect";
import { fnPOST, fnPOSTCore } from "../../../../api/api-methods";
import { APIPATH_SALESFORCE } from "../../../../api/api-paths";

export default {
  components: {
    ModelListSelect,
    vSelect,
    Multiselect,
    Loader,
  },
  data() {
    return {
      isLoadingData: false,
      formName: "Master Customer Form",
      show: false,
      BackHistory: null,
      IDHistory: null,
      showContact: false,
      showView: false,
      titleHeader: null,
      disabledForm: false,
      DisabledModal: false,
      buttonSubmit: null,
      showHidden: true,
      fieldsLocation: [
        { key: "id", class: "display-hidden" },
        { key: "action", label: "Action", sortable: false },
        {
          key: "type_mapping",
          label: "Location",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "location_display",
          label: "Location",
          class: "minimal_width",
          sortable: false,
        },
        { key: "location_name", label: "Location Name", sortable: false },
        {
          key: "npwp_no",
          label: "NPWP No",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "npwp_name",
          label: "NPWP Name",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "address",
          label: "Address",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "province",
          label: "Province",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "city",
          label: "City",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "kecamatan",
          label: "Kecamatan",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "kelurahan",
          label: "Kelurahan",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "zip_code",
          label: "Zip Code",
          class: "display-hidden",
          sortable: false,
        },
        { key: "tax", label: "Tax", class: "display-hidden", sortable: false },
        {
          key: "term_of_payment",
          label: "Term Of Payment",
          class: "display-hidden",
          sortable: false,
        },
      ],
      fieldsContact: [
        { key: "id", class: "display-hidden" },
        { key: "action", label: "Action", sortable: false },
        { key: "name", label: "Name", sortable: false },
        { key: "position", label: "Position", sortable: false },
        { key: "department", label: "Department", sortable: false },
        {
          key: "email",
          label: "Email",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "mobile_number",
          label: "Mobile Number",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "phone_number",
          label: "Phone Number",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "ext_phone_number",
          label: "Ext",
          class: "display-hidden",
          sortable: false,
        },
      ],
      dataLocationTable: [],
      selectCustomerGroup: [],
      dataLocationTableEdit: [],
      dataContactTable: [],
      dataContactTableEdit: [],
      optionTypeTable: [
        { value: "shipping", name: "Shipping" },
        { value: "billing", name: "Billing" },
        { value: "legal", name: "Legal" },
      ],
      options: {
        groupCustomer: [
          // { id: 1, name: 'Sector 1', value:"Sector 1"},
          // { id: 2, name: 'Sector 2', value:"Sector 2"},
          // { id: 3, name: 'Sector 3', value:"Sector 3"},
          // { id: 4, name: 'Sector 4', value:"Sector 4"},
        ],
        type: [
          { id: 1, name: "Government" },
          { id: 2, name: "Non Government" },
        ],
        sector: [],
        industrial: [],
        Position: [],
        Department: [],
        // sector:[
        //   { id: 1, name: 'Sector 1', value:"Sector 1"},
        //   { id: 2, name: 'Sector 2', value:"Sector 2"},
        //   { id: 3, name: 'Sector 3', value:"Sector 3"},
        //   { id: 4, name: 'Sector 4', value:"Sector 4"},
        // ],
        // industrial:[
        //   { id: 1, name: 'Industry 1', value:"Industrial 1"},
        //   { id: 2, name: 'Industry 2', value:"Industrial 2"},
        //   { id: 3, name: 'Industry 3', value:"Industrial 3"},
        //   { id: 4, name: 'Industry 4', value:"Industrial 4"},
        // ],
        termOfPayment: [],
        // Position:[
        //   { id: 1, name: 'Position 1', value:"Position 1"},
        //   { id: 2, name: 'Position 2', value:"Position 2"},
        //   { id: 3, name: 'Position 3', value:"Position 3"},
        //   { id: 4, name: 'Position 4', value:"Position 4"},
        // ],
        // Department:[
        //   { id: 1, name: 'Department 1', value:"Department 1"},
        //   { id: 2, name: 'Department 2', value:"Department 2"},
        //   { id: 3, name: 'Department 3', value:"Department 3"},
        //   { id: 4, name: 'Department 4', value:"Department 4"},
        // ],

        provinceTable: [],
        cityTable: [],
        kecamatanTable: [],
        kelurahanTable: [],
      },
      estEmployee: null,
      CompanyPortal: null,
      portalUrl: null,
      usernamePortal: null,
      registrationEmail: null,
      passwordPortal: null,
      form: {
        salesPerson: null,
        user_nik: null,
        username: null,
        customer_name: null,
        selectedSector: null,
        selectedIndustrial: null,
        selectedType: null,
        additionalInformation: null,
        company_portal: [],
        estEmployee: null,
        tax: null,
        NPWPName: null,
        selectedtypeTable: [],
        LocationType: [],
        TypeShipping: false,
        TypeBilling: false,
        TypeLegal: false,
        LocationNameTable: null,
        addressTable: null,
        npwpnoTable: null,
        npwpnameTable: null,
        termOfPaymentTable: null,
        taxTable: null,
        selectedProvinceTable: null,
        selectedCityTable: null,
        selectedKecamatanTable: null,
        selectedKelurahanKodePosTable: { name: "", code: "" },
        selectedKelurahanTable: null,
        selectedZipCodeTable: null,
      },
      contact: {
        Name: null,
        Position: null,
        Department: null,
        Email: null,
        MobileNumber: null,
        Phone_Number: [],
        PhoneNumber: null,
        CodePhoneNumber: null,
        ExtPhoneNumber: null,
      },
      view: {
        value: null,
        location: null,
        location_display: null,
        location_name: null,
        address: null,
        npwp_no: null,
        npwp_name: null,
        tax: null,
        top: null,
        province: null,
        city: null,
        kecamatan: null,
        kelurahan_codepos: null,
        contact_name: null,
        contact_position: null,
        contact_department: null,
        contact_email: null,
        contact_mobile_number: null,
        contact_phone_number: null,
        contact_code_phone_number: null,
        contact_ext_phone_number: null,
        TypeShipping: false,
        TypeBilling: false,
        TypeLegal: false,
      },
      Approval : true,
      addCustomerGroup :false,
    };
  },
  watch: {
    "form.selectedProvinceTable": {
      handler: function (val, oldval) {
        if (val) {
          this.fetchCity("CityTable");
        }
      },
      deep: true,
    },
    "form.selectedCityTable": {
      handler: function (val, oldval) {
        if (val) {
          this.fetchCity("KecamatanTable");
        }
      },
      deep: true,
    },
    "form.selectedKecamatanTable": {
      handler: function (val, oldval) {
        if (val) {
          this.fetchCity("KelurahanTable");
        }
      },
      deep: true,
    },
    "form.npwpnoTable": function (string) {
      if (string !== null) {
        const mask = string
          .replace(/\D/g, "")
          .replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
            "$1.$2.$3.$4-$5.$6"
          );
        Vue.nextTick(() => (this.form.npwpnoTable = mask));
      }
    },

    estEmployee: function (newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      Vue.nextTick(() => (this.estEmployee = result));
    },
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState("sales_force", [
      "stDataCstm",
      "stDataCstmCount",
      "stDataCstmLocation",
      "stDataCstmLocationCount",
      "stDataCstmContact",
      "stDataCstmContactCount",
      "stTermP",
      "stTermPCount",
    ]),
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions("general", ["fetchCityList"]),
    ...mapActions("sales_force", [
      "getDataMasterCustomer",
      "getDataMasterCustomerLocation",
      "getDataMasterCustomerContact",
      "getGetTermOfPayment",
      "GetMasterSector",
      "GetMasterIndustry",
      "GetMasterParameter",
    ]),

    setData() {
      this.form.user_nik = this.userData.nik;
      this.form.username = this.userData.fullname;
      let statusRoute = this.$route.params.page;
      let idData = this.$route.params.idData;
      let BHistory = this.$route.params.BackHistory;
      let IDHistory = this.$route.params.IDHistory;
      this.contact.Phone_Number = [];
      // this.Approval = true;
      fnPOST(APIPATH_SALESFORCE.getCustomerGroup)
        .then((rsp) => {
          if (rsp.data.payload) {
            this.$set(this.options, "groupCustomer", rsp.data.payload);
          }
        })
        .catch((e) => {
          saveErrorLog("SFC", e);
        });

      this.getGetTermOfPayment()
        .then((rsp) => {
          this.options.termOfPayment = [];
          this.$set(this.options, "termOfPayment", this.stTermP);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({
              type: "error",
              message: "Gagal Load Data, Silahkan Coba Kembali",
            });
            this.$router.push({ name: "SFCMasterCustomer" });
          }
        });
      if (statusRoute == "view" || statusRoute == "edit" || statusRoute == 'approve') {
        if (statusRoute == "view") {
          if (BHistory == "MonthlyPlanning") {
            this.BackHistory = BHistory;
            this.IDHistory = IDHistory;
          }
          if (BHistory == "DashboardWeeklyPlanning") {
            this.BackHistory = BHistory;
          }
          if (BHistory == "WeeklyPlanningForm") {
            this.BackHistory = BHistory;
            this.IDHistory = IDHistory;
          }
          this.titleHeader = "View";
          this.buttonSubmit = "view";
          this.disabledForm = true;
          this.showHidden = false;
        }
        if (statusRoute == "edit") {
          this.titleHeader = "Edit";
          this.Approval = false
          this.buttonSubmit = "edit";
        }
        if (statusRoute == "approve") {
          this.titleHeader = "Approval";
          this.buttonSubmit = "approve";
        }
        // start loading
        this.isLoadingData = true;

        this.getDataMasterCustomer({
          idData: idData,
        })
          .then((rsp) => {
            if (this.stDataCstmCount == 0) {
              this.showNotification({
                type: "info",
                message: "Data Not Found",
              });
            }
            this.getDataMasterCustomerLocation({
              customer: idData,
            })
              .then((rsp) => {
                if (this.stDataCstmLocationCount == 0) {
                  this.showNotification({
                    type: "info",
                    message: "Data Location Not Found",
                  });
                }
                this.getDataMasterCustomerContact({
                  customer: idData,
                })
                  .then((rsp) => {
                    // stop loading
                    setTimeout(() => {
                      this.isLoadingData = false;
                    }, 500);

                    if (rsp == "No Data") {
                      this.showNotification({
                        type: "info",
                        message: "Data Contact Not Found",
                      });
                    }
                    this.setDataForm();
                  })
                  .catch((err) => {
                    // stop loading
                    setTimeout(() => {
                      this.isLoadingData = false;
                    }, 500);
                    if (err.response) {
                      if (err.response.status !== 401) {
                        this.showNotification({
                          type: "error",
                          message: "Gagal Load Data, Silahkan Coba Kembali",
                        });
                        this.$router.push({ name: "SFCMasterCustomer" });
                      }
                    }
                  });
              })
              .catch((err) => {
                // stop loading
                setTimeout(() => {
                  this.isLoadingData = false;
                }, 500);
                if (err.response.status !== 401) {
                  this.showNotification({
                    type: "error",
                    message: "Gagal Load Data, Silahkan Coba Kembali",
                  });
                  this.$router.push({ name: "SFCMasterCustomer" });
                }
              });
          })
          .catch((err) => {
            if (err.response.status !== 401) {
              this.showNotification({
                type: "error",
                message: "Gagal Load Data, Silahkan Coba Kembali",
              });
              this.$router.push({ name: "SFCMasterCustomer" });
            }
          });
      } else {
        this.titleHeader = "Create";
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addTag(value) {
      // console.log(this.options.groupCustomer)
      const tag = {
        name: value,
        is_active : true,
      };
      this.options.groupCustomer.push(tag);
      this.selectCustomerGroup = tag;
      this.addCustomerGroup = true

    },
    InputCustomerGrpup() {},
    LoadDataParameter() {
      this.options.industrial = [];
      this.options.Position = [];
      this.options.Department = [];
      // Get Position
      this.GetMasterParameter({
        category: "contact_position",
      }).then((rsp) => {
        if (rsp != "No Data") {
          this.$set(this.options, "Position", rsp.data.payload);
        }
      });
      // Get Department
      this.GetMasterParameter({
        category: "contact_department",
      }).then((rsp) => {
        if (rsp != "No Data") {
          this.$set(this.options, "Department", rsp.data.payload);
        }
      });
      // Get Industry
      this.GetMasterIndustry({
        category: "industry",
      }).then((rsp) => {
        if (rsp != "No Data") {
          this.$set(this.options, "industrial", rsp.data.payload);
        }
      });
    },
    ChangeSector() {
      this.options.sector = [];
      this.form.selectedSector = null;
      if (
        this.form.selectedIndustrial == null ||
        this.form.selectedIndustrial == undefined
      ) {
        // console.log('')
      } else {
        // Get Sector
        this.GetMasterSector({
          category: "sector",
          name: this.form.selectedIndustrial,
        }).then((rsp) => {
          if (rsp != "No Data") {
            this.options.sector = rsp.data.payload;
          }
        });
      }
    },
    toString(value) {
      let text = null;
      if (value.length != 0) {
        text = value.replace(/[^a-zA-Z ]/g, "");
      }
      return text;
    },
    CheckPortal(value) {
      this.CompanyPortal = value;
      if (this.CompanyPortal == "accepted") {
        document
          .getElementById("id_companyPortal")
          .classList.remove("display-hidden");
      } else {
        document
          .getElementById("id_companyPortal")
          .classList.add("display-hidden");
      }
    },
    checkCompanyPortal() {
      let req = true;
      if (this.CompanyPortal == "accepted") {
        req = false;
      }
      return req;
    },
    BackHistoryButton() {
      let pageView = "SFCMasterCustomer";
      let data = null;
      if (this.BackHistory == "MonthlyPlanning") {
        pageView = "SFCMonthlyActivityPlanForm";
        data = this.IDHistory;
      }
      if (this.BackHistory == "DashboardWeeklyPlanning") {
        pageView = "SFCWeeklyActivityPlan";
      }
      if (this.BackHistory == "WeeklyPlanningForm") {
        pageView = "SFCWeeklyActivityPlanForm";
        data = this.IDHistory;
      }
      // this.$router.push({name:"SFCMonthlyActivityPlan"})
      this.$router.push({
        name: pageView,
        params: { page: "view", idData: data },
      });
    },
    ResetView() {
      this.view.location = null;
      this.view.location_display = null;
      this.view.TypeShipping = false;
      this.view.TypeBilling = false;
      this.view.TypeLegal = false;
      this.view.location_name = null;
      this.view.address = null;
      this.view.npwp_no = null;
      this.view.npwp_name = null;
      this.view.top = null;
      this.view.tax = null;
      this.view.province = null;
      this.view.city = null;
      this.view.kecamatan = null;
      this.view.kelurahan_codepos = { name: "", code: "" };

      this.view.contact_name = null;
      this.view.contact_position = null;
      this.view.contact_department = null;
      this.view.contact_email = null;
      this.view.contact_mobile_number = null;
      this.view.contact_phone_number = null;
      this.view.contact_code_phone_number = null;
      this.view.contact_ext_phone_number = null;
    },
    ViewModal(data, value) {
      this.ResetView();
      if (value == "Location") {
        console.log(data.item);
        this.showView = !this.showView;
        this.view.value = value;
        this.view.location = data.item.location;
        this.view.location_display = data.item.location_display;
        if (this.view.location_display !== null) {
          this.view.location_display = this.view.location_display.toLowerCase();
          if (this.view.location_display.includes("shipping")) {
            this.view.TypeShipping = true;
          }
          if (this.view.location_display.includes("billing")) {
            this.view.TypeBilling = true;
          }
          if (this.view.location_display.includes("legal")) {
            this.view.TypeLegal = true;
          }
        }
        this.view.location_name = data.item.location_name;
        this.view.address = data.item.address;
        this.view.npwp_no = data.item.npwp_no;
        if (data.item.npwp_no !== null) {
          this.view.npwp_no = data.item.npwp_no
            .toString()
            .replace(
              /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
              "$1.$2.$3.$4-$5.$6"
            );
        }
        this.view.npwp_name = data.item.npwp_name;
        this.view.top = data.item.term_of_payment;
        this.view.tax = data.item.tax;
        this.view.province = data.item.province;
        this.view.city = data.item.city;
        this.view.kecamatan = data.item.kecamatan;
        this.view.kelurahan_codepos = {
          name: data.item.kelurahan,
          code: data.item.zip_code,
        };
        this.DisabledModal = true;
      }
      if (value == "contact") {
        console.log(data.item);
        this.showView = !this.showView;
        this.view.value = value;
        this.view.contact_name = data.item.name;
        this.view.contact_position = data.item.position;
        this.view.contact_department = data.item.department;
        this.view.contact_email = data.item.email;
        this.view.contact_mobile_number = data.item.mobile_number;
        if (data.item.phone_number[0] !== undefined) {
          this.view.contact_phone_number = data.item.phone_number[0].phone;
          this.view.contact_code_phone_number = data.item.phone_number[0].code;
        }
        this.view.contact_ext_phone_number = data.item.ext_phone_number;
      }
      this.DisabledModal = true;
    },
    setDataForm() {
      let FormData = this.stDataCstm;
      if (FormData[0].status == 'waiting_approval') {
        if (this.userData.roles) {
          let role = this.userData.roles;
          for (let a = 0; a < role.length; a++) {
            if (role[a].app == "SFC") {
              if (
                role[a].role_list == "Div-Head" ||
                role[a].role_list == "Dept-Head" ||
                role[a].role_list == "Superuser"
              ) {
                this.Approval = false;
              }
            }
          }
        }
      }

      let FormDataLocation = this.stDataCstmLocation;
      let FormDataContact = this.stDataCstmContact;
      this.form.customer_name = FormData[0].customer_name;
      this.form.selectedSector = FormData[0].sub_sector;
      this.form.additionalInformation = FormData[0].additional_information;
      if (FormData[0].company_portal !== null) {
        if (FormData[0].company_portal[0]) {
        this.CheckPortal("accepted");
          this.portalUrl = FormData[0].company_portal[0].portal_url;
          this.registrationEmail =
            FormData[0].company_portal[0].registration_email;
          this.usernamePortal = FormData[0].company_portal[0].username;
          this.passwordPortal = FormData[0].company_portal[0].password;
        }
      }
      this.form.salesPerson =
        FormData[0].last_sales_name + " - " + FormData[0].last_activity;
      this.form.selectedIndustrial = FormData[0].sector;
      this.form.selectedType = FormData[0].type_government;
      this.form.estEmployee = FormData[0].est_employee;
      if (FormData[0].est_employee !== null) {
        this.estEmployee = FormData[0].est_employee
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      this.form.tax = FormData[0].tax;
      this.dataLocationTable = FormDataLocation;
      this.dataContactTable = FormDataContact;
      let len = this.dataLocationTable.length;
      console.log(FormData[0].sector)
      if (FormData[0].sector) {
        // Get sector
        this.GetMasterSector({
          category: "sector",
          name: FormData[0].sector,
        }).then((rsp) => {
          if (rsp != "No Data") {
            this.$set(this.options, "sector", rsp.data.payload);
          }
        });
      }
      // console.log(FormData[0].group_customer);
      if (FormData[0].group_customer) {
        if (this.options.groupCustomer.length > 0) {
          // console.log('asda')
          for (let a=0; a<this.options.groupCustomer.length; a++) {
            if (this.options.groupCustomer[a].name == FormData[0].group_customer) {
              this.selectCustomerGroup = this.options.groupCustomer[a]

            }
          }
        }
      }


      for (let a = 0; a <= len - 1; a++) {
        let json = [];
        let str = this.dataLocationTable[a].type_mapping;
        let idJson = str.substring(
          str.indexOf("id':") + 5,
          str.lastIndexOf(", 'n")
        );
        idJson = parseInt(idJson);
        if (str.includes("shipping")) {
          json.push({
            id: idJson,
            name: "shipping",
            action: null,
          });
        }
        if (str.includes("billing")) {
          json.push({
            id: idJson,
            name: "billing",
            action: null,
          });
        }
        if (str.includes("legal")) {
          json.push({
            id: idJson,
            name: "legal",
            action: null,
          });
        }
        this.dataLocationTable[a].type_mapping = json;
        console.log("test1");
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    checkFormInput(value) {
      if (value !== null) {
        if (value == "" || value == undefined) {
          value = null;
        }
      }
      return value;
    },
    deleteData(value, table) {
      if (table == "Location") {
        if (this.buttonSubmit == "edit") {
          this.dataLocationTableEdit.push({
            id: value.item.id,
            type_mapping: value.item.type_mapping,
            location_display: value.item.location_display,
            location_name: value.item.location_name,
            npwp_no: value.item.npwp_no,
            npwp_name: value.item.npwp_name,
            address: value.item.address,
            province: value.item.province,
            city: value.item.city,
            kecamatan: value.item.kecamatan,
            kelurahan: value.item.kelurahan,
            zip_code: value.item.zip_code,
            tax: value.item.tax,
            term_of_payment: value.item.term_of_payment,
            action: "delete",
          });
        }
        this.dataLocationTable.splice(value.index, 1);
      }

      if (table == "contact") {
        if (this.buttonSubmit == "edit") {
          this.dataContactTableEdit.push({
            id: value.item.id,
            name: value.item.name,
            position: value.item.position,
            email: value.item.email,
            mobile_number: value.item.mobile_number,
            phone_number: value.item.phone_number,
            ext_phone_number: value.item.ext_phone_number,
            action: "delete",
          });
        }
        this.dataContactTable.splice(value.index, 1);
      }
    },
    cancel(value) {
      if (value == "Location") {
        this.form.TypeShipping = false;
        this.form.TypeBilling = false;
        this.form.TypeLegal = false;
        this.form.selectedtypeTable = [];
        this.form.LocationType = [];
        this.form.LocationNameTable = null;
        this.form.addressTable = null;
        this.form.npwpnoTable = null;
        this.form.npwpnameTable = null;
        this.form.termOfPaymentTable = null;
        this.form.taxTable = null;
        this.form.selectedProvinceTable = null;
        this.form.selectedCityTable = null;
        this.form.selectedKecamatanTable = null;
        this.form.selectedKelurahanKodePosTable = { name: "", code: "" };
        this.form.selectedKelurahanTable = null;
        this.form.selectedZipCodeTable = null;
        this.show = !this.show;
      }
      if (value == "contact") {
        this.contact.Name = null;
        this.contact.Position = null;
        this.contact.Department = null;
        this.contact.Email = null;
        this.contact.MobileNumber = null;
        this.contact.PhoneNumber = null;
        this.contact.CodePhoneNumber = null;
        this.contact.Phone_Number = [];
        this.contact.ExtPhoneNumber = null;
        this.showContact = !this.showContact;
      }
    },
    submitAddLocationTable() {
      if (
        this.form.npwpnoTable == null ||
        this.form.npwpnoTable == undefined ||
        this.form.npwpnoTable.substring(0, 1) == " "
      ) {
        this.form.npwpnoTable = null;
      } else {
        this.form.npwpnoTable = this.form.npwpnoTable.replace(/[^\w\s]/gi, "");
      }
      if (this.form.TypeShipping == true) {
        this.form.selectedtypeTable.push({
          id: null,
          name: "shipping",
          action: "create",
        });
        this.form.LocationType = this.form.LocationType + "Shipping ";
      }
      if (this.form.TypeBilling == true) {
        this.form.selectedtypeTable.push({
          id: null,
          name: "billing",
          action: "create",
        });
        this.form.LocationType = this.form.LocationType + "Billing ";
      }
      if (this.form.TypeLegal == true) {
        this.form.selectedtypeTable.push({
          id: null,
          name: "legal",
          action: "create",
        });
        this.form.LocationType = this.form.LocationType + "Legal ";
      }
      if (this.form.selectedtypeTable.length == 0) {
        this.showNotification({
          type: "info",
          message:
            "Data Location gagal tersimpan, Location wajib salah satu dipilih",
        });
        return;
      }
      this.$set(
        this.form,
        "selectedKelurahanTable",
        this.form.selectedKelurahanKodePosTable.name
      );
      this.$set(
        this.form,
        "selectedZipCodeTable",
        this.form.selectedKelurahanKodePosTable.code
      );
      this.form.selectedtypeTable = this.checkFormInput(
        this.form.selectedtypeTable
      );
      this.form.LocationNameTable = this.checkFormInput(
        this.form.LocationNameTable
      );
      this.form.npwpnoTable = this.checkFormInput(this.form.npwpnoTable);
      this.form.npwpnameTable = this.checkFormInput(this.form.npwpnameTable);
      this.form.addressTable = this.checkFormInput(this.form.addressTable);
      this.form.selectedProvinceTable = this.checkFormInput(
        this.form.selectedProvinceTable
      );
      this.form.selectedCityTable = this.checkFormInput(
        this.form.selectedCityTable
      );
      this.form.selectedKecamatanTable = this.checkFormInput(
        this.form.selectedKecamatanTable
      );
      this.form.selectedKelurahanTable = this.checkFormInput(
        this.form.selectedKelurahanTable
      );
      this.form.selectedZipCodeTable = this.checkFormInput(
        this.form.selectedZipCodeTable
      );
      this.form.termOfPaymentTable = this.checkFormInput(
        this.form.termOfPaymentTable
      );
      this.form.taxTable = this.checkFormInput(this.form.taxTable);
      if (this.form.TypeLegal == false && this.form.TypeBilling == false) {
        this.form.npwpnoTable = null;
        this.form.npwpnameTable = null;
      }

      this.dataLocationTable.push({
        id: null,
        type_mapping: this.form.selectedtypeTable,
        location_display: this.form.LocationType,
        location_name: this.form.LocationNameTable,
        npwp_no: this.form.npwpnoTable,
        npwp_name: this.form.npwpnameTable,
        address: this.form.addressTable,
        province: this.form.selectedProvinceTable,
        city: this.form.selectedCityTable,
        kecamatan: this.form.selectedKecamatanTable,
        kelurahan: this.form.selectedKelurahanTable,
        zip_code: this.form.selectedZipCodeTable,
        term_of_payment: this.form.termOfPaymentTable,
        tax: this.form.taxTable,
        action: "create",
      });

      this.form.selectedtypeTable = [];
      this.form.LocationType = [];
      this.form.TypeShipping = false;
      this.form.TypeBilling = false;
      this.form.TypeLegal = false;
      this.form.LocationNameTable = null;
      this.form.npwpnoTable = null;
      this.form.npwpnameTable = null;
      this.form.addressTable = null;
      this.form.termOfPaymentTable = null;
      this.form.taxTable = null;
      this.form.selectedProvinceTable = null;
      this.form.selectedCityTable = null;
      this.form.selectedKecamatanTable = null;
      this.form.selectedKelurahanTable = null;
      this.form.selectedKelurahanKodePosTable = { name: null, code: null };
      this.show = !this.show;
    },

    submitAddContactTable() {
      if (this.contact.Name == null) {
        this.showNotification({
          type: "info",
          message: "Data Contact gagal tersimpan, Name wajib di isi",
        });
        return;
      }
      this.contact.Name = this.checkFormInput(this.contact.Name);
      this.contact.Position = this.checkFormInput(this.contact.Position);
      this.contact.Department = this.checkFormInput(this.contact.Department);
      this.contact.Email = this.checkFormInput(this.contact.Email);
      this.contact.MobileNumber = this.checkFormInput(
        this.contact.MobileNumber
      );
      this.contact.PhoneNumber = this.checkFormInput(this.contact.PhoneNumber);
      if (
        this.contact.CodePhoneNumber == null ||
        this.contact.CodePhoneNumber == undefined ||
        this.contact.CodePhoneNumber.substring(0, 1) == " "
      ) {
        this.contact.CodePhoneNumber = null;
      }
      this.contact.Phone_Number.push({
        code: this.contact.CodePhoneNumber,
        phone: this.contact.PhoneNumber,
      });
      this.contact.ExtPhoneNumber = this.checkFormInput(
        this.contact.ExtPhoneNumber
      );

      this.dataContactTable.push({
        id: null,
        name: this.contact.Name,
        position: this.contact.Position,
        department: this.contact.Department,
        email: this.contact.Email,
        mobile_number: this.contact.MobileNumber,
        phone_number: this.contact.Phone_Number,
        ext_phone_number: this.contact.ExtPhoneNumber,
        action: "create",
      });

      this.contact.Name = null;
      this.contact.Position = null;
      this.contact.Department = null;
      this.contact.Email = null;
      this.contact.MobileNumber = null;
      this.contact.PhoneNumber = null;
      this.contact.CodePhoneNumber = null;
      this.contact.Phone_Number = [];
      this.contact.ExtPhoneNumber = null;

      this.showContact = !this.showContact;
    },
    onSubmit() {
      this.form.company_portal = [];
      if (this.CompanyPortal == "accepted") {
        this.form.company_portal.push({
          portal_url: this.portalUrl,
          registration_email: this.registrationEmail,
          username: this.usernamePortal,
          password: this.passwordPortal,
        });
      } else {
        this.form.company_portal = null;
      }
      this.form.additionalInformation = this.checkFormInput(
        this.form.additionalInformation
      );
      if (this.buttonSubmit == "edit" || this.buttonSubmit == "approve") {
        this.dataLocationTableEdit = [
          ...new Set([
            ...this.dataLocationTable,
            ...this.dataLocationTableEdit,
          ]),
        ];
        this.dataContactTableEdit = [
          ...new Set([...this.dataContactTable, ...this.dataContactTableEdit]),
        ];
        if (this.buttonSubmit == "approve") {
          // console.log(this.selectCustomerGroup)
          if (this.selectCustomerGroup) {
            this.Confirm("approve");
          } else {
            document.getElementById("warning-groupCustomer").classList.remove("display-hidden");
              setTimeout(() => {
                document.getElementById("warning-groupCustomer").classList.add("display-hidden");
              }, 5000);
            return false
          }
        } else {
          if (this.selectCustomerGroup) {
            this.Confirm("edit");
          } else {
            document.getElementById("warning-groupCustomer").classList.remove("display-hidden");
              setTimeout(() => {
                document.getElementById("warning-groupCustomer").classList.add("display-hidden");
              }, 5000);
            return false
          }

        }
      } else {
          this.Confirm("save");

      }
    },
    Confirm(choice, index, table) {
      if (choice !== "delete") {
        this.form.estEmployee = parseFloat(this.estEmployee.replace(/,/g, ""));
      }
      let varOk = "danger";
      if (choice !== "delete") {
        varOk = "success";
      }
      let Boxtrue = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to " + choice + " ? ", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: varOk,
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          Boxtrue = value;
          if (Boxtrue == true) {
            if (choice == "delete") {
              this.deleteData(index, table);
            } else {
                if (this.addCustomerGroup) {
                fnPOST(APIPATH_SALESFORCE.addGroupCustomer, {
                  name:this.selectCustomerGroup.name.toUpperCase()
                }).catch((e) => {
                  saveErrorLog('SFC', e)
                })
              }
            }
            if (choice == "edit") {
              this.EditCustomer();
            }
            if (choice == "save") {
              this.SaveCustomer();
            }
            if (choice == "approve") {
              this.ApproveCustomer();
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    SaveCustomer() {
      // start loading
      this.isLoadingData = true;
      apiMasterCustomer
        .dispatch("postMasterCustomer", {
          customer_name: this.form.customer_name,
          sub_sector: this.form.selectedSector,
          est_employee: this.form.estEmployee,
          sector: this.form.selectedIndustrial,
          type_government: this.form.selectedType,
          tax: this.form.tax,
          additional_information: this.form.additionalInformation,
          company_portal: this.form.company_portal,
          created_by: this.form.user_nik,
          creator_name: this.form.username,
          location: this.dataLocationTable,
          contact: this.dataContactTable,
        })
        .then((response) => {
          this.showNotification({
            type: "success",
            message: "Successfully Submitted",
          });
          this.$router.push({ name: "SFCMasterCustomer" });
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            if (err.response.status === 400) {
              this.errorMessage = err.response.data.message;
            }
          }
        })
        .finally(() => {
          // start loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    EditCustomer() {
      // console.log('asdad')
      // console.log(this.selectCustomerGroup.name)
      let customerGroup = this.selectCustomerGroup.name.toUpperCase()

      // start loading
      this.isLoadingData = true;
      apiMasterCustomer
        .dispatch("EditMasterCustomer", {
          idData: this.$route.params.idData,
          customer_name: this.form.customer_name,
          sub_sector: this.form.selectedSector,
          est_employee: this.form.estEmployee,
          sector: this.form.selectedIndustrial,
          type_government: this.form.selectedType,
          tax: this.form.tax,
          company_portal: this.form.company_portal,
          additional_information: this.form.additionalInformation,
          modified_by: this.form.user_nik,
          location: this.dataLocationTableEdit,
          contact: this.dataContactTableEdit,
          group_customer:customerGroup,
        })
        .then((response) => {
          this.showNotification({
            type: "success",
            message: "Successfully Submitted",
          });
          this.$router.push({ name: "SFCMasterCustomer" });
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            if (err.response.status === 400) {
              this.errorMessage = err.response.data.message;
            }
          }
        })
        .finally(() => {
          // start loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    ApproveCustomer() {
      let customerGroup = this.selectCustomerGroup.name.toUpperCase()
      // start loading
      this.isLoadingData = true;
      fnPOST(APIPATH_SALESFORCE.editMasterCustomer ,{
          idData: this.$route.params.idData,
          customer_name: this.form.customer_name,
          sub_sector: this.form.selectedSector,
          est_employee: this.form.estEmployee,
          sector: this.form.selectedIndustrial,
          type_government: this.form.selectedType,
          tax: this.form.tax,
          company_portal: this.form.company_portal,
          additional_information: this.form.additionalInformation,
          modified_by: this.form.user_nik,
          location: this.dataLocationTableEdit,
          contact: this.dataContactTableEdit,
          group_customer:customerGroup,
        })
        .then((response) => {
          this.showNotification({
            type: "success",
            message: "Successfully Submitted",
          });
          this.$router.push({ name: "SFCMasterCustomerApproval" });
        })
        .catch((err) => {
          saveErrorLog("SFC", err);
          console.log(err);
          if (err.response) {
            if (err.response.status === 400) {
              this.errorMessage = err.response.data.message;
            }
          }
        })
        .finally(() => {
          // start loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    generateCustomText(e) {
      if (e.code) {
        return `${e.name} - ${e.code}`;
      }
      return `${e.name}`;
    },
    fetchCity(category) {
      let paramFilter = {};
      let optionCategory = null;
      switch (category) {
        case "ProvinceTable":
          this.$set(this.form, "selectedCityTable", null);
          this.$set(this.form, "selectedKecamatanTable", null);
          this.$set(this.form, "selectedKelurahanTable", null);
          this.$set(this.form, "selectedZipCodeTable", null);
          this.$set(this.options, "cityTable", []);
          this.$set(this.options, "kecamatanTable", []);
          this.$set(this.options, "kelurahanTable", []);
          this.form.selectedKelurahanKodePosTable = { name: "", code: "" };
          paramFilter = { category: "provinsi" };
          optionCategory = "provinceTable";
          break;
        case "CityTable":
          this.$set(this.form, "selectedKecamatanTable", "");
          this.$set(this.form, "selectedKelurahanTable", "");
          this.$set(this.form, "selectedZipCodeTable", "");
          this.$set(this.options, "kecamatanTable", []);
          this.$set(this.options, "kelurahanTable", []);
          this.form.selectedKelurahanKodePosTable = { name: "", code: "" };
          paramFilter = {
            category: "kota",
            provinsi: this.form.selectedProvinceTable,
          };
          optionCategory = "cityTable";
          break;
        case "KecamatanTable":
          this.$set(this.form, "selectedKelurahanTable", "");
          this.$set(this.form, "selectedZipCodeTable", "");
          this.$set(this.options, "kelurahanTable", []);
          this.form.selectedKelurahanKodePosTable = { name: "", code: "" };
          paramFilter = {
            category: "kecamatan",
            provinsi: this.form.selectedProvinceTable,
            kota: this.form.selectedCityTable,
          };
          optionCategory = "kecamatanTable";
          break;
        case "KelurahanTable":
          paramFilter = {
            category: "kelurahan",
            provinsi: this.form.selectedProvinceTable,
            kota: this.form.selectedCityTable,
            kecamatan: this.form.selectedKecamatanTable,
          };
          optionCategory = "kelurahanTable";
          break;
      }
      apiGeneral
        .dispatch("fetchCityList", paramFilter)
        .then((rsp) => {
          this.$set(this.options, optionCategory, rsp.data.payload);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({
              type: "error",
              message: "Failed Fetching City Data",
            });
          }
        });
    },
  },
  created() {
    this.LoadDataParameter();
    this.fetchCity("ProvinceTable");
    this.setData();
    // console.log(this.userData.roles)
  },
};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
//
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
