import { localize } from "vee-validate";

const errMessage = localize({
  en: {
    messages: {
      required: "Isian wajib, silahkan diisi.",
      required_if: "Isian wajib, silahkan diisi.",
      numeric:"Hanya boleh angka"
    },
    fields: {
      tanggal: {
        required: "Tanggal tidak sesuai",
      },
      suhu: {
        // required: 'Suhu tidak boleh kosong',
        regex: "Suhu hanya boleh angka",
        min_value: "Suhu hanya boleh 30.0 - 50.0",
        max_value: "Suhu hanya boleh 30.0 - 50.0",
        suhuRule: "Suhu hanya boleh 0 atau 30-41.99",
      },
      nomorhp: {
        regex: "Hanya boleh diisi dengan angka",
        rule: "Hanya boleh diisi dengan angka",
      },
      idsertifkatvaksin: {
        minId: "Nomor id sertifikat vaksin minimal 24 digit",
      },
      fileDocumentUpload: {
        minSizeFile: "File too big (> 10MB)",
      },
      kondisi_kesehatan: {
        // required: 'Kondisi kesehatan harus dipilih salah satu'
      },
      kondisi_lainnya: {
        // required: 'Jika memilih kondisi lainnya, harap diisi '
      },
      alamat: {
        // required: 'Alamat lengkap tidak boleh kosong'
      },
      bepergian: {
        // required: 'Bepergian harus dipilih'
      },
      tempat_tujuan: {
        // required: 'Jika kemarin bepergian, tempat tujuan tidak boleh kosong'
      },
      keluar_rumah: {
        // required: 'Keluar Rumah harus dipilih'
      },
      sering_keluar: {
        // required: 'Seberapa sering keluar harus dipilih'
      },
      penerapan_covid: {
        // required: 'Upaya Pencegahan covid-19 harus dipilih'
      },
      transportasi: {
        // required: 'Pernah menggunakan Transportasi Umum harus dipilih'
      },
      nama_transportasi: {
        // required: 'Jika Pernah, transportasi yang digunakan harus disebutkan'
      },
      keluar_kota: {
        // required: 'Pernah Keluar Kota / International harus dipilih'
      },
      tujuan_keluar_kota: {
        // required: 'Jika Pernah, tempat tujuan dan waktunya harus disebutkan'
      },
      kegiatan: {
        // required: 'Pernah mengikuti kegiatan harus dipilih'
      },
      nama_kegiatan: {
        // required: 'Jika Pernah, nama kegiatan dan tanggal kegiatan harus disebutkan'
      },
      riwayat_covid: {
        // required: 'Riwayat kontak erat dengan covid-19 harus dipilih'
      },
      sakit_14hari: {
        // required: 'Pernah mengalami sakit dalam 14 hari harus dipilih'
      },
      nama_sakit_14hari: {
        // required: 'Jika Pernah, sakit apa dan berapa lama harus disebutkan'
      },
      jawaban_deklarasi: {
        // required: 'Deklarasi jawaban harus dipilih'
      },
      checkbox_jawaban: {
        // required: 'Deklarasi jawaban harus dipilih'
      },
      usia: {
        // required: 'Usia tidak boleh kosong',
        numeric: "Usia hanya boleh angka",
      },
      hp: {
        // required: 'Nomor Telp tidak boleh kosong',
        numeric: "Nomor Telp hanya boleh angka",
        min: "Nomor Telp minimal 3 digit",
        max: "Nomor Telp maksimal 13 digit",
      },
      keluarga: {
        // required: 'Nama Keluarga tidak boleh kosong',
      },
      batuk_pilek_nyeri: {
        // required: 'Batuk / pilek / nyeri tenggorokan harus dipilih'
      },
      lemas_pegal_tidak_bertenaga: {
        // required: 'badan lemas / pegal / tidak bertenaga harus dipilih'
      },
      demam: {
        // required: 'Sedang demam dengan suhu melebihi 37.3 harus dipilih'
      },
      sesak_nafas: {
        // required: 'Sedang sesak nafas ringan / berat harus dipilih'
      },
      no_ktp: {
        // required: 'Nomor KTP harus tidak boleh kosong'
      },
    },
  },
});

export default { errMessage };
