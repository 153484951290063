<template>
    <div class="health-declaration">
        <b-container fluid="sm">
            <b-row align-h="center">
                <b-col cols="12" sm="auto">
                    <b-card
                        header="Form Deklarasi"
                        header-tag="header"

                    >
                    <template v-slot:header >
                        <div class="text-center">
                            <div class="banner-container">
                                <div class="bbi-banner"></div>
                            </div>
                            <!-- <img src="../../assets/bbi-logo.png" width="40%" alt="Logo BBI"> -->
                            <h3>Form Self Declaration</h3>
                        </div>
                    </template>
                        <validation-observer ref="observer" v-slot="{ handleSubmit }">
                            <form @submit.stop.prevent="handleSubmit(onSubmit)" >
                                <b-row align-h="center">
                                    <b-col  sm="auto" >

                                        <!-- input nama karyawan -->
                                            <b-form-group 
                                                label               ="Nama Karyawan"
                                                label-for           ="inputNama"
                                                
                                            >
                                                <b-form-input readonly id="inputName" v-model="form.nama_karyawan"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nama karyawan -->

                                        <!-- input department -->
                                            <b-form-group 
                                                label               ="Department"
                                                label-for           ="inputDept"
                                            >
                                                <b-form-input readonly id="inputDept" v-model="form.department"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input department -->


                                    </b-col>
                                    <b-col sm="auto" >

                                        <!-- input nik -->
                                            <b-form-group 
                                                label               ="Nomor Induk Karyawan"
                                                label-for           ="inputNik"
                                            >
                                                <b-form-input readonly id="inputNik" v-model="form.nik"   trim></b-form-input>
                                            </b-form-group>
                                        <!-- /input nik -->

                                        <!-- input no ktp -->
                                            <template v-if="form.department === 'EXT'" >
                                                <validation-provider
                                                    name="no_ktp"
                                                    :rules="{ required: checkNoKTP()}"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-group 
                                                        label               ="Nomor KTP"
                                                        label-for           ="inputNoKTP"
                                                        aria-describedby    ="input-no-ktp-feedback"
                                                    >
                                                        <b-form-input id="inputNoKTP" v-model="form.no_ktp" :state="getValidationState(validationContext)"  trim></b-form-input>
                                                        <b-form-invalid-feedback id="input-no-ktp-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            </template>
                                        <!-- /input no ktp -->

                                        <!-- input tanggal -->
                                            <validation-provider
                                                name="tanggal"
                                                :rules="{ required: true}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group>
                                                    <label for="tanggal">Tanggal Hari Ini</label>
                                                    <b-input-group class="sm-3" aria-describedby= "input-tanggal-feedback">
                                                        <b-form-input
                                                            id="tanggal"
                                                            v-model="form.tanggal_harian"
                                                            type="text"
                                                            autocomplete="off"
                                                            :state="getValidationState(validationContext)"
                                                            readonly
                                                        ></b-form-input>
                                                        <b-input-group-append>
                                                            <b-form-datepicker
                                                                v-model="form.tanggal_harian"
                                                                button-only
                                                                right
                                                                aria-controls="tanggal"
                                                                :max="max"
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                :state="getValidationState(validationContext)"
                                                            ></b-form-datepicker>
                                                        </b-input-group-append>
                                                        <b-form-invalid-feedback id="input-tanggal-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                    
                                                </b-form-group> 
                                            </validation-provider>
                                        <!-- /input tanggal -->


                                    </b-col>
                                </b-row>
                                <b-row >
                                    <b-col sm="6" offset-sm="3">

                                        <!-- input batuk pilek nyeri tenggorokan -->
                                            <validation-provider
                                                name="batuk_pilek_nyeri"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah saat ini sedang batuk / pilek / nyeri tenggorokan ?" label-class="text-body">
                                                            <b-form-radio-group

                                                                v-model= "form.batuk_pilek_nyeri"
                                                                :options= "options_all"
                                                                name= "batuk_pilek_nyeri"
                                                                stacked
                                                                
                                                                aria-describedby    ="input-batuk-pilek-nyeri-feedback"
                                                                :click="countBatukPilekNyeri()"
                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-batuk-pilek-nyeri-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input batuk pilek nyeri tenggorokan -->

                                        <!-- input lemes pegel tidak bertenaga -->
                                            <validation-provider
                                                name="lemas_pegal_tidak_bertenaga"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah saat ini sedang merasakan badan terasa lemas / pegal-pegal / tidak bertenaga ?" label-class="text-body">
                                                            <b-form-radio-group

                                                                v-model= "form.lemas_pegal_tidak_bertenaga"
                                                                :options= "options_all"
                                                                name= "lemas_pegal_tidak_bertenaga"
                                                                stacked
                                                                
                                                                aria-describedby    ="input-lemas-pegal-tidak-bertenaga-feedback"
                                                                :click="countLemasPegalTidakBertenaga()"
                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-lemas-pegal-tidak-bertenaga-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input lemes pegel tidak bertenaga -->

                                        <!-- input demam suhu > 37.3 -->
                                            <validation-provider
                                                name="demam"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah saat ini sedang demam dengan suhu badan melebihi 37.3'C ?" label-class="text-body">
                                                            <b-form-radio-group

                                                                v-model= "form.demam"
                                                                :options= "options_all"
                                                                name= "demam"
                                                                stacked
                                                                
                                                                aria-describedby    ="input-demam-feedback"
                                                                :click="countDemam()"
                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-demam-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input demam suhu > 37.3 -->

                                        <!-- input sesak nafas -->
                                            <validation-provider
                                                name="sesak_nafas"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Apakah saat ini sedang sesak nafas ringan hingga berat ?" label-class="text-body">
                                                            <b-form-radio-group

                                                                v-model= "form.sesak_nafas"
                                                                :options= "options_all"
                                                                name= "sesak_nafas"
                                                                stacked
                                                                
                                                                aria-describedby    ="input-sesak-nafas-feedback"
                                                                :click="countSesakNafas()"
                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-sesak-nafas-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input sesak nafas -->

                                        <!-- input riwayat_covid -->
                                            <validation-provider
                                                name="riwayat_covid"
                                                vid="riwayat_covid"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label="Apakah memiliki kontak erat dengan 
                                                            keluarga / kerabat / orang dalam satu tempat tinggal
                                                             yang mejadi Suspek ataupun ter-konfirmasi positif Covid-19?
                                                             (Kontak erat berjabat tangan, berbicara, berada dalam satu ruangan
                                                             dengan jarak <1m/ satu tempat tinggal/ rumah) ?" label-class="text-body">
                                                            <b-form-radio-group

                                                                v-model= "form.riwayat_covid"
                                                                :options= "options_all"
                                                                name= "riwayat_covid"
                                                                stacked
                                                                
                                                                aria-describedby    ="input-riwayat-covid-feedback"
                                                                :click="countRiwayatCovid()"
                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-riwayat-covid-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input riwayat_covid -->

                                        <!-- input keluar kota -->
                                            <validation-provider
                                                name="keluar_kota"
                                                vid="keluar_kota"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group 
                                                    label="Apakah dalam 7 hari terakhir 
                                                            memiliki riwayat perjalanan ke luar kota / luar negeri / wilayah terjangkit virus corona ?" label-class="text-body">
                                                            <b-form-radio-group

                                                                v-model= "form.keluar_kota"
                                                                :options= "options_all"
                                                                name= "keluar_kota"
                                                                stacked
                                                                
                                                                aria-describedby    ="input-keluar-kota-feedback"
                                                                :click="countKeluarKota()"
                                                            >
                                                            </b-form-radio-group>
                                                            
                                                    <b-form-invalid-feedback id="input-keluar-kota-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input keluar kota -->
                                    </b-col>
                                </b-row>
                                <b-row class="justify-content-sm-center" >
                                    <b-col col sm="8">
                                        <!-- input checkbox jawaban -->
                                            <validation-provider
                                                name="jawaban_deklarasi"
                                                :rules="{ required: {allowFalse: false}}"
                                                v-slot="validationContext"
                                            >
                                                <b-form-group label="Saya bertanggung jawab terhadap setiap jawaban yang saya berikan pada form ini untuk dapat dipergunakan sesuai dengan kebutuhannya." label-class="text-body">
                                                    <b-form-checkbox

                                                        v-model= "form.jawaban_deklarasi"
                                                        name= "jawaban_deklarasi"
                                                        value="ya"
                                                        stacked
                                                        
                                                        aria-describedby    ="input-jawaban-deklarasi-feedback"
                                                    >
                                                    Ya
                                                    </b-form-checkbox>
                                                    <b-form-invalid-feedback id="input-jawaban-deklarasi-feedback" :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                </b-form-group>
                                            </validation-provider>
                                        <!-- /input checkbox jawaban -->
                                        
                                        <b-button block type="submit" variant="primary">Submit</b-button>
                                    </b-col>
                                </b-row>
                            </form>
                        </validation-observer>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import apiDeclaration from '@/store/health-form/health-self-declaration.js';
    

    export default {
        name: 'HealthSelfDeclaration',
        data() {
            // const now = new Date()
            // const today = now.toISOString().slice(0,10)
            
            return {
                errorMessage: null,
                messages: "Successfully Submitted",
                types: "success",
                form: {
                    nama_karyawan                   : null,
                    nik                             : null,
                    department                      : null,
                    lokasi                          : null,
                    no_ktp                          : null,
                    tanggal_harian                  : null,
                    batuk_pilek_nyeri               : null,
                    lemas_pegal_tidak_bertenaga     : null,
                    demam                           : null,
                    sesak_nafas                     : null,
                    riwayat_covid                   : null,     
                    keluar_kota                     : null,
                    jawaban_deklarasi               : null, 
                    // posting_date                    : now,

                    batuk_pilek_nyeri_count             : 0,
                    lemas_pegal_tidak_bertenaga_count   : 0,
                    demam_count                         : 0,
                    sesak_nafas_count                   : 0,
                    riwayat_covid_count                 : 0,
                    keluar_kota_count                   : 0,
                    total_count                         : 0,
                    profil_risk                         : null     
                    
                },
                
                max: null,
                posts:{},
                
                

                options_all: [
                    { text: 'Ya', value: 'ya' },
                    { text: 'Tidak', value: 'tidak' },
                ],

            }

        },
        computed: {
            ...mapGetters('session', ['userData','localDate']),
        },
        
        methods: {
             ...mapActions('session', ['showNotification']),
            getAccountInfo() {
                let userData      = this.userData
                this.$set(this.form, 'nama_karyawan', userData.fullname)
                this.$set(this.form, 'nik', userData.nik)
                if(userData.business_unit != 'BBI1') {
                    this.$set(this.form, 'department', userData.subdept)
                }
                else {
                    this.$set(this.form, 'department', userData.dept)
                }
                this.$set(this.form, 'lokasi', userData.business_unit)
                
            },
            getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
            },

            checkNoKTP(){
                if(this.form.department == 'EXT'){
                    return true
                }
                return false
            },

            countBatukPilekNyeri(){
                if(this.form.batuk_pilek_nyeri == 'ya'){
                    this.form.batuk_pilek_nyeri_count = 1
                    
                }else{
                    this.form.batuk_pilek_nyeri_count = 0
                    
                }

                
            },

            countLemasPegalTidakBertenaga(){
                if(this.form.lemas_pegal_tidak_bertenaga == 'ya'){
                    this.form.lemas_pegal_tidak_bertenaga_count = 1
                }else{
                    this.form.lemas_pegal_tidak_bertenaga_count = 0
                }
                
            },

            countDemam(){
                if(this.form.demam == 'ya'){
                    this.form.demam_count = 2
                }else{
                    this.form.demam_count = 0
                }
                
            },

            countSesakNafas(){
                if(this.form.sesak_nafas == 'ya'){
                    this.form.sesak_nafas_count = 4
                }else{
                    this.form.sesak_nafas_count = 0
                }
                
            },

            countRiwayatCovid(){
                if(this.form.riwayat_covid == 'ya'){
                    this.form.riwayat_covid_count = 4
                }else{
                    this.form.riwayat_covid_count = 0
                }
                
            },

            countKeluarKota(){
                if(this.form.keluar_kota == 'ya'){
                    this.form.keluar_kota_count = 2
                }else{
                    this.form.keluar_kota_count = 0
                }
                
            },

            // countTotal(){
            //     this.form.total_count = this.form.batuk_pilek_nyeri_count + 
            //                             this.form.lemas_pegal_tidak_bertenaga_count +
            //                             this.form.demam_count + 
            //                             this.form.sesak_nafas_count +
            //                             this.form.riwayat_covid_count + 
            //                             this.form.keluar_kota_count
            //     console.log(this.form.total_count)
            // },

            onSubmit() {
                this.form.total_count = this.form.batuk_pilek_nyeri_count + 
                                        this.form.lemas_pegal_tidak_bertenaga_count +
                                        this.form.demam_count + 
                                        this.form.sesak_nafas_count +
                                        this.form.riwayat_covid_count + 
                                        this.form.keluar_kota_count
                if(this.form.total_count == 0){
                    this.form.profil_risk = "sehat"
                    this.messages = "Deklarasi Kesehatan berhasil disimpan. \nAnda memenuhi syarat untuk besok bekerja. \nSelamat bekerja dan patuhi protokol pencegahan Covid-19"
                    this.types = "success"
                }else if(this.form.total_count == 1 ){
                    this.form.profil_risk = "Resiko Kecil"
                    this.messages = "Deklarasi Kesehatan berhasil disimpan. \nAnda memenuhi syarat untuk besok bekerja. \nSelamat bekerja dan patuhi protokol pencegahan Covid-19"
                    this.types = "success"
                }else if(this.form.total_count > 1 && this.form.total_count < 4){
                    this.form.profil_risk = "Resiko Sedang"
                    this.messages = "Deklarasi Kesehatan berhasil disimpan. \nProfil resiko anda SEDANG. \nAnda tidak memenuhi syarat untuk besok bekerja di kantor, segera laporkan kondisi anda kepada atasan dan Personalia."
                    this.types = "error"
                }else if(this.form.total_count >= 4 ){
                    this.form.profil_risk = "Resiko Tinggi"
                    this.messages = "Deklarasi Kesehatan berhasil disimpan. \nProfil resiko anda TINGGI. \nAnda tidak memenuhi syarat untuk besok bekerja di kantor, segera laporkan kondisi anda kepada atasan dan Personalia."
                    this.types = "error"
                }
                apiDeclaration.dispatch('postSelfDeclaration', {
                    nama_karyawan                   : this.form.nama_karyawan,
                    nik                             : this.form.nik,
                    department                      : this.form.department,
                    lokasi                          : this.form.lokasi,
                    no_ktp                          : this.form.no_ktp,
                    tanggal_harian                  : this.form.tanggal_harian,
                    batuk_pilek_nyeri               : this.form.batuk_pilek_nyeri,
                    lemas_pegal_tidak_bertenaga     : this.form.lemas_pegal_tidak_bertenaga,
                    demam                           : this.form.demam,
                    sesak_nafas                     : this.form.sesak_nafas,
                    riwayat_covid                   : this.form.riwayat_covid,     
                    keluar_kota                     : this.form.keluar_kota,
                    jawaban_deklarasi               : this.form.jawaban_deklarasi, 
                    // posting_date                    : this.form.posting_date,
                    nilai_resiko                    : this.form.total_count,
                    profil_resiko                   : this.form.profil_risk
                })
                    .then(response => {
                        // console.log(response)
                        // this.showNotification({type:this.types, message:this.messages})
                        alert(this.messages)
                        this.$router.push({name:"DashboardPage"})
                    })
                    .catch(err => {
                        // if (err.response.status === 400) {
                           
                        //     this.errorMessage = err.response.data.message;
                        // }else if (err.response.status === 500){
                        //     this.errorMessage = err.response.data.message;
                        // }
                        if(err.response.data.status == '400' && err.response.data.message.includes("Submit failed.")) {
                            this.errorMessage = 'Data gagal disimpan. \nDeklarasi Kesehatan untuk NIK '+this.form.nik+' tanggal ' +this.form.tanggal_harian + ' sudah pernah tersimpan.'
                        }
                        else {
                            this.errorMessage = err.response.data.message;
                        }
                        alert(this.errorMessage)
                    })
            }
        },
        created: function(){
            this.getAccountInfo()
            this.$set(this.form, 'tanggal_harian', this.localDate)
            this.max = this.localDate
        },
    }
</script>
<style src="./HealthSelfDeclaration.scss" lang="scss" scoped />