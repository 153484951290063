<template>
  <div id="modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col cols="6">
            <!-- Type DMS -->
            <validation-provider
              name="type-dms"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Type<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  id="inputBusinessUnit"
                  v-model="form.type_dms"
                  :reduce="(i) => i.type_doc"
                  :options="typeOpt"
                  label="type_doc"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.type_dms"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputBusinessUnit">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Type DMS -->
            <!-- Business Unit -->
            <validation-provider
              name="business-unit"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Business Unit<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  id="inputBusinessUnit"
                  v-model="form.business_unit"
                  :options="businessUnitOpt"
                  @input="setReminder()"
                  label="name"
                  :reduce="(name) => name.code"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.business_unit"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputBusinessUnit">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Business Unit -->
            <!-- Location Type -->
            <!-- <validation-provider
              name="Location Type"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Location </template>
                <v-select
                  class="style-chooser"
                  id="inputLocationType"
                  v-model="form.location"
                  :options="setLocation"
                  label="location_name"
                  :reduce="(location_name) => location_name.id"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.location"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputLocationType">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider> -->
            <!-- /Location Type -->
            <!-- Permit Type  -->
            <validation-provider
              name="permit_type"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> License Type<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  :reduce="(permit_type) => permit_type.id"
                  label="permit_type"
                  @input="setReminder()"
                  id="inputPermitType"
                  v-model="form.permit_type"
                  :options="permitTypeOpt"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.permit_type"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputPermitType">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Permit Type  -->
            <!-- PermitNumber  -->
            <validation-provider
              name="PermitNumber"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> License Number<code>*</code> </template>
                <b-form-input
                  id="inputPermitNumber"
                  v-model="form.permit_no"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="inputDurations">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /PermitNumber  -->
            <!-- Extend  -->
            <validation-provider
              name="Extend"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Extend<code>*</code> </template>
                <b-form-radio-group
                  @change="setPopulate"
                  id="inputExtend"
                  v-model="form.extend"
                  :options="extendOpt"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-radio-group>
                <b-form-invalid-feedback id="inputDurations">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Extend  -->
            <!-- Extend Number  -->
            <validation-provider
              name="extendNumber"
              :rules="{ required_if: form.extend == 'Yes' }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Extend Number<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  :disabled="form.extend !== 'Yes'"
                  :reduce="(permit_no) => permit_no.id"
                  label="permit_no"
                  id="inputExtendNumber"
                  v-model="form.extend_no"
                  :options="setExtendNo"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.extend_no"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputExtendNumber">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Extend Number  -->

            <!-- Start Date  -->
            <validation-provider
              name="StartDate"
              :rules="{ required_if: expState == false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Start Date<code>*</code> </template>
                <b-form-datepicker
                  id="inputStartDate"
                  v-model="form.start_date"
                  today-button
                  reset-button
                  close-button
                  locale="id"
                  @input="countDuration"
                  :state="getValidationState(validationContext)"
                ></b-form-datepicker>
                <b-form-invalid-feedback id="inputStartDate">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Start Date  -->
            <!-- End Date  -->
            <validation-provider
              name="EndDate"
              :rules="{ required_if: expState == false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> End Date<code>*</code> </template>
                <b-form-datepicker
                  id="inputEndDate"
                  v-model="form.end_date"
                  today-button
                  reset-button
                  close-button
                  locale="id"
                  :readonly="expState"
                  :state="getValidationState(validationContext)"
                  :min="form.start_date"
                  @input="resetDate"
                ></b-form-datepicker>
                <b-form-invalid-feedback id="inputEndDate">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /End Date  -->
            <!-- Durations  -->
            <validation-provider
              name="Durations"
              :rules="{ required_if: expState == false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Durations<code>*</code> </template>
                <b-form-input
                  readonly
                  id="inputDurations"
                  v-model="form.durations"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="inputDurations">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Durations  -->

            <!-- Reminder  -->
            <validation-provider
              name="reminder"
              :rules="{ required_if: expState == false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label>
                  Reminder (Month)<code>*</code>
                </template>
                <v-select
                  readonly
                  class="style-chooser"
                  @input="countDuration"
                  label="reminder"
                  :reduce="(i) => i.id"
                  id="inputReminder"
                  v-model="form.reminder"
                  :options="setRemind"
                  :disabled="expState"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.reminder"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputReminder">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Reminder  -->
            <!-- Remind Date  -->
            <validation-provider
              name="RemindDate"
              :rules="{ required_if: expState == false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Remind Date<code>*</code> </template>
                <b-form-input
                  readonly
                  id="inputRemindDate"
                  v-model="formatRemindDate"
                  :state="getValidationState(validationContext)"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="inputRemindDate">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Remind Date  -->
          </b-col>
          <b-col cols="6">
            <!-- Institution  -->
            <validation-provider
              name="institution"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Institution<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  :reduce="(i) => i.id"
                  label="name"
                  disabled
                  id="inputInstitution"
                  v-model="form.institution"
                  :options="institutionOpt"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.institution"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputInstitution">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Institution  -->
            <!-- Level Institution  -->
            <validation-provider
              name="LevelInstitution"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label>
                  Institution Level<code>*</code>
                </template>
                <v-select
                  class="style-chooser"
                  label="level_name"
                  disabled
                  :reduce="(i) => i.id"
                  id="inputLevelInstitution"
                  v-model="form.level"
                  :options="levelOpt"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.level"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputLevelInstitution">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Level Institution  -->
            <!-- Province  -->
            <validation-provider
              name="Province"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Provinsi<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  :reduce="(i) => i.name"
                  label="name"
                  id="inputProvince"
                  @input="setCity()"
                  v-model="form.province"
                  :options="provinceOpt"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.province"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputProvince">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Province  -->
            <!-- City  -->
            <validation-provider
              name="City"
              :rules="{ required: false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Kota<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  :reduce="(i) => i.name"
                  label="name"
                  id="inputCity"
                  @input="setDistrict()"
                  v-model="form.city"
                  :options="cityOpt"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.city"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputCity">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /City  -->
            <!-- District  -->
            <validation-provider
              name="District"
              :rules="{ required: false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Kecamatan </template>
                <v-select
                  class="style-chooser"
                  :reduce="(i) => i.name"
                  label="name"
                  id="inputDistrict"
                  @input="setWard()"
                  v-model="form.district"
                  :options="districtOpt"
                >
                </v-select>
                <b-form-invalid-feedback id="inputDistrict">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /District  -->
            <!-- Ward  -->
            <validation-provider
              name="Ward"
              :rules="{ required: false }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Kelurahan </template>
                <v-select
                  class="style-chooser"
                  :reduce="(i) => i.name"
                  label="name"
                  id="inputWard"
                  v-model="form.ward"
                  :options="wardOpt"
                >
                </v-select>
                <b-form-invalid-feedback id="inputWard">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Ward  -->
            <!-- Storage Location -->
            <validation-provider
              name="StorageLocation"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label>
                  Storage Location<code>*</code>
                </template>
                <v-select
                  class="style-chooser"
                  :reduce="(i) => i.code"
                  label="name"
                  id="inputStorageLocation"
                  v-model="form.file_location"
                  :options="businessUnitOpt"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.file_location"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputStorageLocation">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /Storage Location -->
            <!-- PIC -->
            <validation-provider
              name="PIC"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Dept PIC<code>*</code> </template>
                <v-select
                  class="style-chooser"
                  :reduce="(i) => i.department"
                  disabled
                  label="department"
                  v-model="form.pic_dept"
                  :options="picOpt"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.pic_dept"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <b-form-invalid-feedback id="inputPIC">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /PIC -->
            <!-- DocumentFile -->
            <validation-provider
              name="DocumentFile"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group label-cols="3" label-cols-lg="3" label-size="sm">
                <template v-slot:label> Document Files<code>*</code> </template>
                <b-form-file
                  ref="fileDocument"
                  id="DocumentFile"
                  name="document-legal"
                  v-model="form.document_file"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose file here..."
                  no-drop
                  multiple
                  v-on:change="onFileUpload"
                ></b-form-file>
                <div v-for="file in form.file_src" :key="file.src">
                  <p class="small">
                    <a :href="file.src" target="_blank">{{ file.name }}</a>
                    <b-badge
                      href="#"
                      v-on:click="removeFileUpload(file.file_id, file.name)"
                      variant="light"
                    >
                      <b-icon
                        icon="x-circle-fill"
                        font-scale="1"
                        variant="danger"
                      ></b-icon>
                    </b-badge>
                  </p>
                </div>
                <b-form-invalid-feedback id="DocumentFile">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- /DocumentFile -->
            <b-row>
              <b-col cols="6">
                <b-button block type="submit" variant="success">Save</b-button>
              </b-col>
              <b-col cols="6">
                <b-button block @click="cancel()" variant="warning"
                  >Cancel</b-button
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-button @click="$emit('testAlert')">
          Save
      </b-button> -->
  </div>
</template>

<script>
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../../api/api-methods";
import {
  APIPATH_DMS,
  APIPATH_GENERAL,
  APIPATH_BBIAPPS,
} from "../../../../../api/api-paths";
import Vue from "vue";
import "vue-select/dist/vue-select.css";
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["editItems"],

  data() {
    return {
      isLoading: false,
      reminds: null,
      form: {
        type_dms: null,
        business_unit: null,
        permit_type: null,
        institution: null,
        start_date: null,
        end_date: null,
        durations: null,
        remind_date: null,
        permit_no: null,
        extend: null,
        extend_no: null,
        level: null,
        province: null,
        city: null,
        district: null,
        ward: null,
        location: null,
        created_by: null,
        modified_by: null,
        deleted_by: null,
        document_file: [],
        reminder: null,
        file_src: [],
        file_name: null,
        file_label: null,
        file_location: null,
        document_id: null,
        pic_dept: null,
      },
      businessUnitOpt: [],
      typeOpt: [],
      permitTypeOpt: [],
      institutionOpt: [],
      extendOpt: [
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      extendNumberOpt: [],
      levelOpt: [],
      provinceOpt: [],
      cityOpt: [],
      districtOpt: [],
      wardOpt: [],
      locationOpt: [],
      reminderOpt: [],
      picOpt: [],
      expState: true,
      edit: {
        document_legal_id: null,
        document_item: null,
        file_id: [],
      },
    };
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getUserData() {
      Vue.set(this.form, "created_by", this.userData.fullname);
      Vue.set(this.form, "modified_by", this.userData.fullname);
    },
    getPermitType() {
      fnGET(APIPATH_DMS.getPermit)
        .then((rsp) => {
          var permits = rsp.data.payload.filter((i) => i.is_active === true);
          Vue.set(this, "permitTypeOpt", permits);
        })
        .catch((err) => {
          return err;
        });
    },
    getDept() {
      fnGET(APIPATH_DMS.getDept)
        .then((rsp) => {
          Vue.set(this, "picOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getLevel() {
      fnGET(APIPATH_DMS.getLevel)
        .then((rsp) => {
          Vue.set(this, "levelOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getInstitution() {
      fnGET(APIPATH_DMS.getInstitution)
        .then((rsp) => {
          Vue.set(this, "institutionOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getBU() {
      fnGET(APIPATH_BBIAPPS.listBU)
        .then((rsp) => {
          Vue.set(this, "businessUnitOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    getType() {
      fnGET(APIPATH_BBIAPPS.listType)
        .then((rsp) => {
          Vue.set(this, "typeOpt", rsp.data);
          // for (let index = 0; index < rsp.data.payload.length; index++) {
          //   Vue.set(this, "categoryOptMult", rsp.data.payload[index]["code"]);
          // }
        })
        .catch((err) => {
          return err;
        });
    },
    getLocation() {
      fnGET(APIPATH_DMS.getLocation)
        .then((rsp) => {
          var locations = rsp.data.payload.filter((i) => i.is_active === true);
          Vue.set(this, "locationOpt", locations);
        })
        .catch((err) => {
          return err;
        });
    },
    getReminder() {
      fnGET(APIPATH_DMS.getReminder)
        .then((rsp) => {
          if (rsp.data.payload.length > 0) {
            Vue.set(this, "reminderOpt", rsp.data.payload);
            console.log(this.reminderOpt);
          }
        })
        .catch((err) => {
          return err;
        });
    },
    getProvinciList() {
      fnPOST(APIPATH_GENERAL.cityList, { category: "provinsi" })
        .then((rsp) => {
          Vue.set(this, "provinceOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    setCity() {
      if (this.form.province) {
        fnPOST(APIPATH_GENERAL.cityList, {
          category: "kota",
          provinsi: this.form.province,
        })
          .then((rsp) => {
            Vue.set(this, "cityOpt", rsp.data.payload);
          })
          .catch((err) => {
            return err;
          });
      }
    },
    setDistrict() {
      if (this.form.city) {
        fnPOST(APIPATH_GENERAL.cityList, {
          category: "kecamatan",
          kota: this.form.city,
        })
          .then((rsp) => {
            Vue.set(this, "districtOpt", rsp.data.payload);
          })
          .catch((err) => {
            return err;
          });
      }
    },
    setWard() {
      if (this.form.district) {
        fnPOST(APIPATH_GENERAL.cityList, {
          category: "kelurahan",
          kecamatan: this.form.district,
        })
          .then((rsp) => {
            Vue.set(this, "wardOpt", rsp.data.payload);
          })
          .catch((err) => {
            return err;
          });
      }
    },
    setReminder() {
      if (this.form.permit_type && this.form.business_unit) {
        // Vue.set(this, "isLoading", true);
        // fnPOST(APIPATH_DMS.getDataPicDept,{
        //   permit_type:this.form.permit_type,
        //   bu:this.form.business_unit,
        // }).then((rsp) => {
        //   Vue.set(this, "isLoading", false);
        // }).catch((e)=> {
        //   Vue.set(this, "isLoading", false);
        //   this.showNotification({
        //       type: "error",
        //       message: e,
        //     });
        // })
        // var reminds = this.permitTypeOpt.filter(i=>i.id === this.form.permit_type)
        var reminds = this.permitTypeOpt.filter(
          (i) => i.id === this.form.permit_type
        );
        console.log(reminds[0]);
        Vue.set(this.form, "institution", reminds[0].institution);
        Vue.set(this.form, "end_date", reminds[0].end_date);
        // Vue.set(this.form, "extend", reminds[0].expired);
        // Vue.set(this.form, "extend", reminds[0].expired);
        Vue.set(this.form, "level", reminds[0].levels);
        Vue.set(this.form, "pic_dept", reminds[0].department);
        Vue.set(this.form, "reminder", reminds[0].reminders);
        Vue.set(this.form, "durations", reminds[0].duration);
        var remindMonth = this.reminderOpt.find(
          (i) =>
            i.permit === this.form.permit_type &&
            i.location === this.form.location
        );
        console.log("remindMonth", this.reminderOpt);

        if (remindMonth.expired === "Yes") {
          Vue.set(this.form, "reminder", remindMonth.id);

          Vue.set(this, "expState", false);
        } else {
          Vue.set(this.form, "reminder", null);
          Vue.set(this.form, "durations", null);
          Vue.set(this.form, "remind_date", null);
          Vue.set(this.form, "end_date", null);
          Vue.set(this, "expState", true);
        }
      } else {
        Vue.set(this, "expState", true);
        Vue.set(this.form, "reminder", null);
        Vue.set(this.form, "durations", null);
        Vue.set(this.form, "remind_date", null);
        Vue.set(this.form, "start_date", null);
        Vue.set(this.form, "end_date", null);
      }
    },
    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;

      months -= d1.getMonth() + 1;
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    dayDiff(d1, d2) {
      var minutes = 1000 * 60;
      var hours = minutes * 60;
      var days = hours * 24;
      var months = days * 30;

      var diff_date = Math.round((d2 - d1) / days);

      // var diff_month = Math.floor((d2 - d1)/months)

      return diff_date;
    },
    setRemindDate(months) {
      var reminderMonth = this.reminderOpt.find(
        (i) => i.id === this.form.reminder
      );
      console.log("reminderOpt", this.reminderOpt);
      var d2 = new Date(this.form.end_date);
      var days = reminderMonth.reminder * 30;
      var last = new Date(d2.getTime() - days * 24 * 60 * 60 * 1000);
      var day = last.getDate();
      var month = last.getMonth() + 1;
      var year = last.getFullYear();

      Vue.set(this.form, "remind_date", year + "-" + month + "-" + day);
    },
    countDuration() {
      if (this.form.start_date && this.form.reminder) {
        var d1 = new Date(this.form.start_date);
        var d2 = new Date(this.form.start_date);
        console.log(d1);
        var newEndDate = new Date(
          d1.setMonth(d1.getMonth() + this.form.durations)
        );
        var newReminder = new Date(
          d2.setMonth(d2.getMonth() + this.form.durations - this.form.reminder)
        );

        console.log(newEndDate);
        console.log(newReminder);

        let dayEndData = newEndDate.getDate();
        let monthEndData = newEndDate.getMonth() + 1;
        let YearEndData = newEndDate.getFullYear();
        let dayReminder = newReminder.getDate();
        let monthReminder = newReminder.getMonth() + 1;
        let YearReminder = newReminder.getFullYear();
        console.log(YearEndData + "-" + monthEndData + "-" + dayEndData);
        console.log(YearReminder + "-" + monthReminder + "-" + dayReminder);
        newEndDate = YearEndData + "-" + monthEndData + "-" + dayEndData;
        newReminder = YearReminder + "-" + monthReminder + "-" + dayReminder;
        Vue.set(this.form, "end_date", newEndDate);
        console.log(newReminder);
        Vue.set(this.form, "remind_date", newReminder);
      }
      // if (this.form.start_date && this.form.end_date && this.form.reminder) {
      //   var d1 = new Date(this.form.start_date);
      //   var d2 = new Date(this.form.end_date);

      //   var month = Math.floor(this.dayDiff(d1, d2) / 30);

      //   this.setRemindDate(month);
      //   return (this.form.durations = month);
      // }
    },
    getDocumentNumber() {
      fnGET(APIPATH_DMS.getDocumentLegal)
        .then((rsp) => {
          Vue.set(this, "extendNumberOpt", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    onSubmit() {
      Vue.set(this, "isLoading", true);
      const fd = new FormData();
      if (this.edit.document_legal_id) {
        fd.append("document_legal_id", this.edit.document_legal_id);
        fd.append("modified_by", this.form.modified_by);
        if (this.edit.file_id) {
          this.edit.file_id.forEach((file) => {
            console.log("file", file);
            fd.append("file_id", file);
          });
        }

        this.form.file_src.forEach((files) => {
          console.log("file_id", files.file_id);
          if (!files.file_id) {
            console.log("masook");
            fd.append("file_src", files.file_src);
            fd.append("file_name", files.name);
            fd.append("file_label", files.file_label);
          }
        });
      } else {
        this.form.file_src.forEach((files) => {
          fd.append("file_src", files.file_src);
          fd.append("file_name", files.name);
          fd.append("file_label", files.file_label);
        });
      }
      // fd.append('file_src',this.form.file_src[0].file_src)

      fd.append("business_unit", this.form.business_unit);
      fd.append("type_dms", this.form.type_dms);
      fd.append("permit_type_id", this.form.permit_type);
      fd.append("institution_id", this.form.institution);
      fd.append("start_date", this.form.start_date);

      if (this.form.reminder !== null) {
        fd.append("end_date", this.form.end_date);
        fd.append("duration", this.form.durations);
        fd.append("remind_date", this.form.remind_date);
        fd.append("reminder_id", this.form.reminder);
      }
      fd.append("permit_no", this.form.permit_no);
      fd.append("extend", this.form.extend);
      if (this.form.extend_no) {
        fd.append("extend_no", this.form.extend_no);
      }
      fd.append("level_id", this.form.level);
      fd.append("province", this.form.province);
      fd.append("city", this.form.city);
      if (this.form.district) {
        fd.append("district", this.form.district);
      }
      if (this.form.ward) {
        fd.append("ward", this.form.ward);
      }
      fd.append("location_id", this.form.location);
      fd.append("pic_dept", this.form.pic_dept);
      fd.append("created_by", this.form.created_by);
      fd.append("file_location", this.form.file_location);

      if (this.edit.document_legal_id) {
        fnPUT(APIPATH_DMS.putDocumentLegal, fd)
          .then((rsp) => {
            this.cancel();
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });
          })
          .catch((err) => {
            return err;
          })
          .then(Vue.set(this, "isLoading", false));
      } else {
        fnPOST(APIPATH_DMS.postDocumentLegal, fd)
          .then((rsp) => {
            this.cancel();
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });
          })
          .catch((err) => {
            return err;
          })
          .then(Vue.set(this, "isLoading", false));
      }
    },
    cancel() {
      this.edit.document_legal_id = null;
      this.form.business_unit = null;
      this.form.type_dms = null;
      this.form.permit_type = null;
      this.form.institution = null;
      this.form.start_date = null;
      this.form.end_date = null;
      this.form.durations = null;
      this.form.remind_date = null;
      this.form.permit_no = null;
      this.form.extend = null;
      this.form.extend_no = null;
      this.form.level = null;
      this.form.province = null;
      this.form.city = null;
      this.form.district = null;
      this.form.ward = null;
      this.form.location = null;
      this.form.created_by = null;
      this.form.document_file = [];
      this.form.reminder = null;
      this.form.pic_dept = null;
      this.form.file_location = null;
      this.form.file_src = [];
      this.form.file_name = null;
      this.form.file_label = null;
      this.edit.file_id = [];

      this.$emit("cancel");
    },
    onFileUpload(e) {
      var file = e.target.files;
      var pathName = e.target.name;
      // this.form.file_src = []

      console.log("file", file);

      for (var i = 0; i < file.length; i++) {
        let files = {
          src: URL.createObjectURL(file[i]),
          name: file[i].name,
          file_src: file[i],
          file_label: pathName,
        };
        this.form.file_src.push(files);
      }
    },
    formatNamesUploadFiles(e) {
      var idField = e.target.id;
      return this.form.file_src.length === 0
        ? this.$refs.fileDocument.reset()
        : `${this.form.file_src.length} files selected`;
    },
    removeFileUpload: function (fileid, filename) {
      this.form.file_src.splice(filename, 1);
      console.log("hapus", fileid);
      if (fileid) {
        this.edit.file_id.push(fileid);
      }
      return this.$refs.fileDocument.reset();
    },
    setAutoPopulate() {
      if (this.form.extend_no) {
        var item = this.extendNumberOpt.find(
          (i) => i.id === this.form.extend_no
        );
        console.log("item", item);
        Vue.set(this.form, "institution", item.institution_id);
        Vue.set(this.form, "level", item.level_id);
        Vue.set(this.form, "province", item.province);
        Vue.set(this.form, "city", item.city);
        Vue.set(this.form, "district", item.district);
        Vue.set(this.form, "ward", item.ward);
        Vue.set(this.form, "file_location", item.file_location);
        Vue.set(this.form, "pic_dept", item.pic_dept_id);
      } else {
        Vue.set(this.form, "institution", null);
        Vue.set(this.form, "level", null);
        Vue.set(this.form, "province", null);
        Vue.set(this.form, "city", null);
        Vue.set(this.form, "district", null);
        Vue.set(this.form, "ward", null);
        Vue.set(this.form, "file_location", null);
        Vue.set(this.form, "pic_dept", null);
      }
    },
    setPopulate() {
      // Vue.set(this.form, "extend_no", null);
      // Vue.set(this.form, "institution", null);
      // Vue.set(this.form, "level", null);
      // Vue.set(this.form, "province", null);
      // Vue.set(this.form, "city", null);
      // Vue.set(this.form, "district", null);
      // Vue.set(this.form, "ward", null);
      // Vue.set(this.form, "file_location", null);
      // Vue.set(this.form, "pic_dept", null);
    },
    resetDate() {
      console.log("reset called");

      // if(!this.editItems || this.form.end_date !== this.editItems.end_date){
      // Vue.set(this.form, "durations", null);
      // Vue.set(this.form, "remind_date", null);
      // Vue.set(this.form, "reminder", null);
      // }
    },
    setEdit() {
      this.edit.document_legal_id = this.editItems.id;
      this.form.business_unit = this.editItems.business_unit;
      this.form.type_dms = this.editItems.type_dms;
      this.form.permit_type = this.editItems.permit_type_id;
      this.form.institution = this.editItems.institution_id;
      this.form.start_date = this.editItems.start_date;
      this.form.end_date = this.editItems.end_date;
      this.form.location = this.editItems.location_id;
      this.form.permit_no = this.editItems.permit_no;
      this.form.extend = this.editItems.extend;
      this.form.extend_no = this.editItems.extend_no;
      this.form.level = this.editItems.level_id;
      this.form.province = this.editItems.province;
      this.form.city = this.editItems.city;
      this.form.district = this.editItems.district;
      this.form.ward = this.editItems.ward;
      this.form.durations = this.editItems.durations;
      this.form.remind_date = this.editItems.remind_date;
      this.form.reminder = this.editItems.reminder_id;
      console.log(this.form.durations);
      console.log("masuk sini");

      // if (this.form.end_date) {
      //   if (this.editItems.reminder_id) {
      //     if (this.editItems.expired === "Yes") {
      //       this.form.reminder = this.editItems.reminder_id;
      //       this.form.durations = this.editItems.durations;
      //       this.form.remind_date = this.editItems.remind_date;
      //       this.expState = false;
      //     }
      //   }
      // }
      (this.form.document_file = []),
        (this.form.pic_dept = this.editItems.dept_name);
      this.form.file_location = this.editItems.file_location;
      // this.form.file_id = this.editItems.file_id

      fnPOST(APIPATH_DMS.getDocumentFile, { document_id: this.editItems.id })
        .then((rsp) => {
          console.log("files", rsp.data.payload);
          for (let i = 0; i < rsp.data.payload.length; i++) {
            var file = {
              src: rsp.data.payload[i].file_src.replace(":8005",":8000"),
              file_src: rsp.data.payload[i].file_src.replace(":8005",":8000"),
              file_name: rsp.data.payload[i].file_name,
              name: rsp.data.payload[i].file_name,
              file_label: rsp.data.payload[i].file_label,
              file_id: rsp.data.payload[i].id,
            };

            this.form.file_src.push(file);
            this.form.document_file.push(file);
          }
        })
        .catch((err) => {
          return err;
        });
      // var file = {

      //     src: this.editItems.file_src,
      //     file_src: this.editItems.file_src,
      //     file_name: this.editItems.file_name,
      //     name:this.editItems.file_name,
      //     file_label: this.editItems.file_label
      // }

      // this.form.file_src.push(file)
      this.form.file_name = null;
      this.form.file_label = null;
      // this.form.document_file.push(file)
      if (this.form.province) {
        this.setCity();
      }
      if (this.form.city) {
        this.setDistrict();
      }
      if (this.form.district) {
        this.setWard();
      }
    },
  },
  created() {
    this.getUserData();
    this.getBU();
    this.getType();
    this.getPermitType();
    this.getInstitution();
    this.getLevel();
    this.getProvinciList();
    this.getDocumentNumber();
    this.getLocation();
    this.getReminder();
    this.getDept();
  },
  mounted() {
    if (this.editItems) {
      this.setEdit();
    }
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    setRemind() {
      if (this.form.permit_type && this.form.location) {
        var reminder = this.reminderOpt.filter(
          (i) =>
            i.permit === this.form.permit_type &&
            i.location === this.form.location
        );
        if (reminder.length > 0) {
          return reminder;
        }
      }
    },
    setLocation() {
      if (this.form.business_unit) {
        var location = this.locationOpt.filter(
          (i) => i.business_unit === this.form.business_unit
        );
        if (location.length > 0) {
          return location;
        }
      }
    },
    setExtendNo() {
      if (this.form.extend === "Yes") {
        if (
          this.form.business_unit &&
          // this.form.location &&
          this.form.permit_type
        ) {
          var extendNo = this.extendNumberOpt.filter(
            (i) =>
              i.business_unit === this.form.business_unit &&
              // i.location_id === this.form.location &&
              i.permit_type_id === this.form.permit_type
          );
          console.log(extendNo);
          return extendNo;
        }
      }
    },
    formatRemindDate() {
      var options = { year: "numeric", month: "short", day: "numeric" };
      if (this.form.remind_date) {
        return new Date(this.form.remind_date).toLocaleDateString(
          "id-ID",
          options
        );
      }
    },
  },
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #ffffff;
  border: 1;
  color: #030303;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #3b3a3a;
}
</style>