import {
    // *SFC
    fnApiGetTermOfPayment,

    fnApiGetDahsboardMasterCustomer,
    fnApiGetDataMasterCustomer,
    fnApiGetDataMasterCustomerLocation,
    fnApiGetDataMasterCustomerContact,

    fnApiGetListCustomer,
    fnApiGetListCustomerWeekly,

    fnApiGetDahsboardMonthly,
    fnApiGetDataMonthlyPlanning,
    fnApiGetDataMonthlyPlanningDetail,

    fnApiGetDashboardMonthlyApproval,
    fnApiGetDataMonthlyApproval,

    fnApiGetDashboardWeeklyPlanning,
    fnApiGetDataWeeklyPlanning,
    fnApiGetDataWeeklyDetailPlanning,
    fnApiGetLastMonthlyPlanning,

    fnApiGetActivity,
    fnApiGetReason,

    fnApiGetDashboardWeeklyApproval,
    fnApiGetDataWeeklyApproval,

    fnApiGetDashboardCustomerDistribution,
    fnApiGetDataCustomerDistribution,
    fnApiGetDataCustomerDistributionDetail,
    fnApiGetDataCustomerDistributionList,
    fnApiGetDataSalesGroup,

    fnApiGetReviewActualActivity,
    fnApiGetReviewCustomer,
    fnApiGetListSalesDetail,

    fnApiGetListUserBBI,

    fnApiFetchDashboardActualActivity,
    fnApiFetchDataActualActivity,

    fnApiFetchMasterSubActivity,

    fnApiFetchGetMasterSector,
    fnApiFetchGetMasterIndustry,
    fnApiFetchGetMasterParameter,

    fnApiGetVersionApp,

} from '../../api/api-salesforce.js';
const getDefaultState = () => {
    return {
        // *SFC
        stTermP:null,
        stTermPCount:0,

        stCstm:null,
        stCstmCount:0,
        stDataCstm:null,
        stDataCstmCount:0,
        stDataCstmLocation:null,
        stDataCstmLocationCount:0,
        stDataCstmContact:null,
        stDataCstmContactCount:0,

        stListCstm:null,
        stListCstmCount:0,
        stListCstmWeek:null,
        stListCstmWeekCount:0,

        stMnthAct:null,
        stMnthActCount:0,
        stMnthPln:null,
        stMnthPlnCount:0,
        stMnthPlnDetail:null,
        stMnthPlnDetailCount:0,

        stMnthApp:null,
        stMnthAppCount:0,
        stDtMnthApp:null,
        stDtMnthAppCount:null,

        stWeekPln:null,
        stWeekPlnCount:0,
        stDtWeekPln:null,
        stDtWeekPlnCount:0,
        stDtWeekDtlPln:null,
        stDtWeekPlnDtlCount:0,
        stLstMnthPln:null,
        stLstMnthPlnCount:0,

        stActv:null,
        stActvCount:0,
        stRsn:null,
        stRsnCount:0,

        stWeekApp:null,
        stWeekAppCount:0,
        stDtWeekApp:null,
        stDtWeekAppCount:0,

        stDtCstmDist:null,
        stDtCstmDistCount:0,
        stDtCstmDistDetail:null,
        stDtCstmDistDetailCount:0,
        stDtCstmDistList:null,
        stDtCstmDistListCount:0,

        stSlsGrp:null,
        stSlsGrpCount:0,

        stLrac:null,
        stLracCount:0,
        stDtLrac:null,
        stDtLracCount:0,

        stLrc:null,
        stLrcCount:0,
        stDtLrc:null,
        stDtLrcCount:0,

        stLstSls:null,
        stLstSlsCount:0,

        stLstUsr:null,
        stLstUsrCount:0,

        stActActv:null,
        stActActvCount:0,

        stDtActActv:null,
        stDtActActvCount:0,

        stMstSubAct:null,
        stMstSubActCount:0,

        stMstSct:null,
        stMstSctCount:0,

        stMstIndst:null,
        stMstIndstCount:0,

        stMstPrmt:null,
        stMstPrmtCount:0,

        versionApp:null,
        versionAppCount:0,
    }
}
const state = getDefaultState()
const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
    // *SFC

    // Get Term Of Payment
    StGetTermOfPayment(state, data) {
        state.stTermP = data
        state.stTermPCount = data.length
    },
    // Dashboard MasterCustomer
    StGetDashboardMasterCustomer(state, data) {
        state.stCstm = data
        state.stCstmCount = data.length
    },
    // Data MasterCustomer
    StGetDataMasterCustomer(state, data) {
        state.stDataCstm = data
        state.stDataCstmCount = data.length
    },
    // Data MasterCustomer Location
    StGetDataCustomerLocation(state, data) {
        state.stDataCstmLocation = data
        state.stDataCstmLocationCount = data.length
    },
    // Data MasterCustomer Contact
    StGetDataCustomerContact(state, data) {
        state.stDataCstmContact = data
        state.stDataCstmContactCount = data.length
    },
    // Data List Customer
    StGetListCustomer(state, data) {
        state.stListCstm = data
        state.stListCstmCount = data.length
    },
    // Data List Customer Weekly
    StGetListCustomerWeekly(state, data) {
        state.stListCstmWeek = data
        state.stListCstmWeekCount = data.length
    },
    // Dashboard Monthly Planning
    StGetDashboardMonthly(state, data) {
        state.stMnthAct = data
        state.stMnthActCount = data.length
    },
    // Data Monthly Planning
    StGetDataMonthlyPlanning(state, data) {
        state.stMnthPln = data
        state.stMnthPlnCount = data.length
    },
    // Data Monthly Planning Detail
    StGetDataMonthlyPlanningDetail(state, data) {
        state.stMnthPlnDetail = data
        state.stMnthPlnDetailCount = data.length
    },

    // Dashboard Monthly Approval
    StGetDashboardMonthlyApproval(state, data) {
        state.stMnthApp = data
        state.stMnthAppCount = data.length
    },
    // Data Monthly Approval
    StGetDataMonthlyApproval(state, data) {
        state.stDtMnthApp = data
        state.stDtMnthAppCount = data.length
    },

    // Dashboard Weekly Planning
    StGetDashboardWeeklyPlanning(state, data) {
        state.stWeekPln = data
        state.stWeekPlnCount = data.length
    },
    // Data Weekly Planning
    StGetDataWeeklyPlanning(state, data) {
        state.stDtWeekPln = data
        state.stDtWeekPlnCount = data.length
    },
    // Data Weekly Planning Detail
    StGetDataWeeklyPlanningDetail(state, data) {
        state.stDtWeekDtlPln = data
        state.stDtWeekPlnDtlCount = data.length
    },
    // Data Last Monthly Planning
    StGetLastMonthlyPlanning(state, data) {
        state.stLstMnthPln = data
        state.stLstMnthPlnCount = data.length
    },

        // ACTIVITY AND REASON
    // Get Activity
    StGetActivity(state, data) {
        state.stActv = data
        state.stActvCount = data.length
    },
    // Get Reason
    StGetReason(state, data) {
        state.stRsn = data
        state.stRsnCount = data.length
    },

    // Dashboard Weekly Approval
    StGetDashboardWeeklyApproval(state, data) {
        state.stWeekApp = data
        state.stWeekAppCount = data.length
    },
    // Data Weekly Approval
    StGetDataWeeklyApproval(state, data) {
        state.stDtWeekApp = data
        state.stDtWeekAppCount = data.length
    },

    // Dashboard Customer Distribution
    StGetDashboardCustomerDistribution(state, data) {
        state.stCstmDist = data
        state.stCstmDistCount = data.length
    },
    // Data Customer Distribution
    StGetDataCustomerDistribution(state, data) {
        state.stDtCstmDist = data
        state.stDtCstmDistCount = data.length
    },
    // Data Customer Distribution Detail
    StGetDataCustomerDistributionDetail(state, data) {
        state.stDtCstmDistDetail = data
        state.stDtCstmDistDetailCount = data.length
    },
    // Data Customer Distribution List
    StGetDataCustomerDistributionList(state, data) {
        state.stDtCstmDistList = data
        state.stDtCstmDistListCount = data.length
    },
    // Data Sales Group
    StGetDataSalesGroup(state, data) {
        state.stSlsGrp = data
        state.stSlsGrpCount = data.length
    },


    // Get List Review Actual Activity
    StGetReviewActualActivity(state, data) {
        state.stLrac = data
        state.stLracCount = data.length
    },
    // Get Data Review Actual Activity
    StGetDataReviewActualActivity(state, data) {
        state.stDtLrac = data
        state.stDtLracCount = data.length
    },

    // Get List Review Customer
    StGetReviewCustomer(state, data) {
        state.stLrc = data
        state.stLrcCount = data.length
    },
    // Get Data Review Customer
    StGetDataReviewCustomer(state, data) {
        state.stDtLrc = data
        state.stDtLrcCount = data.length
    },

    // Get List Sales Detail
    StGetListSalesDetail(state, data) {
        state.stLstSls = data
        state.stLstSlsCount = data.length
    },

    // Get List UserBBI
    StGetListUserBBI(state, data) {
        state.stLstUsr = data
        state.stLstUsrCount = data.length
    },

    // Get Dashboard ActualActivity
    StActualActivity(state, data) {
        state.stActActv = data
        state.stActActvCount = data.length
    },
    // Get Data ActualActivity
    StDataActualActivity(state, data) {
        state.stDtActActv = data
        state.stDtActActvCount = data.length
    },

    // Get Data Sub Activity
    StMasterSubActivity(state, data) {
        state.stMstSubAct = data
        state.stMstSubActCount = data.length
    },
    // Get Data Sector
    StGetMasterSector(state, data) {
        state.stMstSct = data
        state.stMstSctCount = data.length
    },
    // Get Data Industry
    StGetMasterIndustry(state, data) {
        state.stMstIndst = data
        state.stMstIndstCount = data.length
    },
    // Get Data Parameter
    StGetMasterParameter(state, data) {
        state.stMstPrmt = data
        state.stMstPrmtCount = data.length
    },

    // Get Version APP
    StGetVersionApp(state, data) {
        state.versionApp = data
        state.versionAppCount = data.length
    },


}

const actions = {
        // *SFC

        // Dashboard MasterCustomer
        getGetTermOfPayment({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetTermOfPayment(filter).then( rsp => {
                commit('StGetTermOfPayment', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Dashboard MasterCustomer
        getDashboardMasterCustomer({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDahsboardMasterCustomer(filter).then( rsp => {
                commit('StGetDashboardMasterCustomer', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data MasterCustomer
        getDataMasterCustomer({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataMasterCustomer(filter).then( rsp => {
                commit('StGetDataMasterCustomer', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data MasterCustomer Location
        getDataMasterCustomerLocation({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataMasterCustomerLocation(filter).then( rsp => {
                commit('StGetDataCustomerLocation', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data MasterCustomer Contact
        getDataMasterCustomerContact({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataMasterCustomerContact(filter).then( rsp => {
                commit('StGetDataCustomerContact', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get List Customer
        getDataListCustomer({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetListCustomer(filter).then( rsp => {
                commit('StGetListCustomer', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data.payload)
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get List Customer Weekly
        getDataListCustomerWeekly({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetListCustomerWeekly(filter).then( rsp => {
                commit('StGetListCustomerWeekly', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data.payload)
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Dashboard Monthly Planning
        getDashboardMonthlyPlanning({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDahsboardMonthly(filter).then( rsp => {
                commit('StGetDashboardMonthly', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Data Monthly Planning
        getDataMonthlyPlanning({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataMonthlyPlanning(filter).then( rsp => {
                commit('StGetDataMonthlyPlanning', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Data Monthly Planning Detail
        getDataMonthlyPlanningDetail({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataMonthlyPlanningDetail(filter).then( rsp => {
                commit('StGetDataMonthlyPlanningDetail', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Dashboard Monthly Approval
        getDashboardMonthlyApproval({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDashboardMonthlyApproval(filter).then( rsp => {
                commit('StGetDashboardMonthlyApproval', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data Monthly Approval
        getDataMonthlyApproval({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataMonthlyApproval(filter).then( rsp => {
                commit('StGetDataMonthlyApproval', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },


        // Get Dashboard Weekly Planning
        getDashboardWeeklyPlanning({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDashboardWeeklyPlanning(filter).then( rsp => {
                commit('StGetDashboardWeeklyPlanning', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data Weekly Planning
        getDataWeeklyPlanning({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataWeeklyPlanning(filter).then( rsp => {
                commit('StGetDataWeeklyPlanning', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data Weekly Planning Detail
        getDataWeeklyPlanningDetail({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataWeeklyDetailPlanning(filter).then( rsp => {
                commit('StGetDataWeeklyPlanningDetail', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data Last Monthly Planning
        getLastMonthlyPlanning({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetLastMonthlyPlanning(filter).then( rsp => {
                commit('StGetLastMonthlyPlanning', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },


        // Get Activity
        getActivityPlan({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetActivity(filter).then( rsp => {
                commit('StGetActivity', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Reason
        getReasonActivity({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetReason(filter).then( rsp => {
                commit('StGetReason', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Dashboard Weekly Approval
        getDashboardWeeklyApproval({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDashboardWeeklyApproval(filter).then( rsp => {
                commit('StGetDashboardWeeklyApproval', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Data Weekly Approval
        getDataWeeklyApproval({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataWeeklyApproval(filter).then( rsp => {
                commit('StGetDataWeeklyApproval', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },


        // Get Dashboard Customer Distribution
        getDashboardCustomerDistribution({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDashboardCustomerDistribution(filter).then( rsp => {
                commit('StGetDashboardCustomerDistribution', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Data Customer Distribution
        getDataCustomerDistribution({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataCustomerDistribution(filter).then( rsp => {
                commit('StGetDataCustomerDistribution', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Data Customer Distribution Detail
        getDataCustomerDistributionDetail({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataCustomerDistributionDetail(filter).then( rsp => {
                commit('StGetDataCustomerDistributionDetail', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Data Customer Distribution List
        getDataCustomerDistributionList({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataCustomerDistributionList(filter).then( rsp => {
                commit('StGetDataCustomerDistributionList', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Data Customer Distribution
        getDataSalesGroup({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataSalesGroup(filter).then( rsp => {
                commit('StGetDataSalesGroup', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get List Review Actual Activity
        getListReviewActualActivity({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetReviewActualActivity(filter).then( rsp => {
                commit('StGetReviewActualActivity', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Data Review Actual Activity
        getDataReviewActualActivity({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetReviewActualActivity(filter).then( rsp => {
                commit('StGetDataReviewActualActivity', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get List Review Customer
        getListReviewCustomer({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetReviewCustomer(filter).then( rsp => {
                commit('StGetReviewCustomer', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Data Review Customer
        getDataReviewCustomer({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetReviewCustomer(filter).then( rsp => {
                commit('StGetDataReviewCustomer', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get List Sales Detail
        getListSalesDetail({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetListSalesDetail(filter).then( rsp => {
                commit('StGetListSalesDetail', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get List UserBBI
        getListUserBBI({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetListUserBBI(filter).then( rsp => {
                commit('StGetListUserBBI', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get List UserBBI
        getVersionApp({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetVersionApp(filter).then( rsp => {
                commit('StGetVersionApp', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data)
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get Dahsboard Actual Activity
        GetDashActualActv(context, filter){
            return new Promise((resolve, reject) => {
                fnApiFetchDashboardActualActivity(filter)
                .then( rsp => {
                    context.commit("StActualActivity", rsp.data.payload);
                    resolve(rsp);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
        // Get Data Actual Activity
        GetDataActualActv(context, filter){
            return new Promise((resolve, reject) => {
                fnApiFetchDataActualActivity(filter)
                .then( rsp => {
                    context.commit("StDataActualActivity", rsp.data.payload);
                    resolve(rsp);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
        // Get Master Sub Activity
        GetListSubActivity(context, filter){
            return new Promise((resolve, reject) => {
                fnApiFetchMasterSubActivity(filter)
                .then( rsp => {
                    context.commit("StMasterSubActivity", rsp.data.payload);
                    resolve(rsp);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },

        // Get Master Sector
        GetMasterSector(context, filter){
            return new Promise((resolve, reject) => {
                fnApiFetchGetMasterSector(filter)
                .then( rsp => {
                    context.commit("StGetMasterSector", rsp.data.payload);
                    resolve(rsp);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },
        // Get Master Industry
        GetMasterIndustry(context, filter){
            return new Promise((resolve, reject) => {
                fnApiFetchGetMasterIndustry(filter)
                .then( rsp => {
                    context.commit("StGetMasterIndustry", rsp.data.payload);
                    resolve(rsp);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },

        // Get Master Parameter
        GetMasterParameter(context, filter){
            return new Promise((resolve, reject) => {
                fnApiFetchGetMasterParameter(filter)
                .then( rsp => {
                    context.commit("StGetMasterParameter", rsp.data.payload);
                    resolve(rsp);
                })
                .catch((err) => {
                    reject(err);
                });
            });
        },



        clearStatePpm({commit}) {
            commit('resetState')
        },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}