<template>
    <div>
        <b-container fluid style="height: 90vh;">
            <b-row align-h="center" align-v="center" style="height:100%;">
            <b-card border-variant="light" :title="dataApp" body-class="text-center">
                <b-card-text >
                    {{textDownload}}
                </b-card-text>
                <b-button v-if="version != null" @click="downloadFile()" variant="primary">Download</b-button>
            </b-card>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import { fnPOST } from "../../../../api/api-methods";
import { APIPATH_GENERAL } from "../../../../api/api-paths";
export default {
    data(){
        return{
            formName:'App Release',
            url:null,
            filename:null,
            version:null,
            getUrl:null,
            dataApp:null,
            textDownload:null,
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['versionApp']),
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['getVersionApp']),
        // isMobile() {
        //     if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //         console.log('true')
        //     } else {
        //         console.log('false')
        //     }
        // },
        setData(){
            // this.isMobile()
            if (this.$route.query.app == null || this.$route.query.app == undefined) {
                this.$router.push({ name: 'LoginPage'})
            } else {
                this.dataApp      = this.$route.query.app
                if (this.$route.query.app == 'SFC') {
                    this.dataApp = 'Sales Force Mobile'
                }
                else if (this.$route.query.app == 'SFA') {
                    this.dataApp = 'SFA Mobile'
                }
                else {
                    this.dataApp = 'App Mobile'
                }
                let host = new URL(window.location.href);
                let hostname = host.hostname
                if (hostname == "localhost") {
                    hostname = "testapps.bbi-apparel.com"
                }
                this.url = "https://"+hostname+":8000/media/mobile-apps/"

                fnPOST(APIPATH_GENERAL.chkVersionApp, {
                    category: this.$route.query.app,
                }).then(rsp=> {
                    if (rsp.data.payload.length > 0){
                        this.filename = rsp.data.payload[0].filename
                        this.version = rsp.data.payload[0].version
                        if (rsp.data.payload[0].url == null || rsp.data.payload[0].url == undefined) {
                            this.getUrl = this.url+this.filename
                        } else {
                            this.getUrl = rsp.data.payload[0].url
                        }

                        this.textDownload = 'Version '+this.version+' is available on the server'
                    } else {
                        this.textDownload = 'This app is not available on our server'
                    }
                })

            }
        },
        downloadFile() {
            let Boxtrue = ''
            this.$bvModal.msgBoxConfirm('Please confirm that you want to download ? ', {
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                Boxtrue = value
                if (Boxtrue == true) {
                    window.open(this.getUrl, '_self')
                }
            })
            .catch(err => {
                    // An error occurred
            })

        },
    },
    created(){
        this.setData()
    },
}
</script>