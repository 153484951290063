/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import Vue from "vue";
import Vuex from "vuex";
import Toasted from "vue-toasted";
import router from "../Routes";

import { setCookie, deleteCookie } from "./cookies.js"

import { fnApiLogin, fnApiGetListBU } from "../api/api-auth.js";
// import { reject } from 'core-js/fn/promise';

Vue.use(Vuex);
Vue.use(Toasted, { duration: 5000 });

const getDefaultState = () => {
  return {
    accessToken: localStorage.getItem("access_token") || null,
    userData: localStorage.getItem("userData") || null,
    businessUnit: localStorage.getItem("businessUnit") || null,
    firstLogin: localStorage.getItem("isFirstLogin") || null,
    stBUList: null,
    // coordinates state
    coordinates: localStorage.getItem("coordinates") || null,
  };
};
// initial state
const state = getDefaultState();

const getters = {
  loggedIn(state) {
    return state.accessToken != null;
  },
  accessToken(state) {
    return state.accessToken;
  },
  firstLogin(state) {
    return state.firstLogin;
  },
  userData(state) {
    return state.userData;
  },
  BUList(state) {
    return state.stBUList;
  },
  businessUnit(state) {
    return state.businessUnit;
  },
};

const actions = {
  logoutUser(context) {
    deleteCookie()
    if (context.getters.loggedIn) {
        context.commit('postLogoutUpdate')
    }
    router.push({
      path: '/login',
      name: 'Login'
    })
  },
  loginUser(context, credentials) {
    return new Promise((resolve, reject) => {
      fnApiLogin(credentials)
        .then((response) => {
          context.commit("postLoginUpdate", {
            access: response.data.payload.token,
          });
          context.commit("updateUserData", { data: response.data.payload });
          resolve();
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            reject(error.response.data.message);
          }

          reject(error);
        });
    });
  },
  getListBU(context) {
    return new Promise((resolve, reject) => {
      fnApiGetListBU()
        .then((response) => {
          context.commit("updateBUList", response.data.payload);
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // action get coordinates
  getCoordinates(context, coordinates) {
    context.commit("postCoordinates", coordinates);
  },
};

const mutations = {
  // postLoginUpdate (state, { access, refresh })
  postLoginUpdate(state, { access }) {
    state.accessToken = access;

    localStorage.setItem("authenticated", true);
    localStorage.setItem("access_token", access);
  },

  // add coordinates to local storage
  postCoordinates(state, { coordinates }) {
    state.coordinates = coordinates;

    localStorage.setItem("coordinates", coordinates);
  },

  updateAccess(state, { access }) {
    state.accessToken = access;
  },
  postLogoutUpdate(state) {
    let userTheme = localStorage.getItem("bbiapps_theme");
    localStorage.clear();
    // localStorage.removeItem('access_token')
    // localStorage.removeItem('userData')
    // localStorage.removeItem('isFirstLogin');
    localStorage.setItem("authenticated", false);
    localStorage.setItem("bbiapps_theme", userTheme);

    // Reset state to initial state
    Object.assign(state, getDefaultState());
  },
  updateUserData(state, { data }) {
    state.userData = data.user_data;
    state.businessUnit = data.user_data.business_unit;
    state.firstLogin = data.user_data.is_first_login;

    localStorage.setItem("userData", JSON.stringify(data.user_data));
    localStorage.setItem("businessUnit", data.user_data.business_unit);
    localStorage.setItem("isFirstLogin", data.user_data.is_first_login);

    setCookie('userData', JSON.stringify(data.user_data), 365)
    setCookie('authenticated', localStorage.getItem('authenticated'), 365)
    setCookie('access_token', localStorage.getItem('access_token'), 365)

  },
  updateBUList(state, data) {
    state.stBUList = data;
  },
  // resetUserData (state) {
  //   state.userData = ''
  //   localStorage.removeItem('access_token')
  // }
};

export default new Vuex.Store({
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
});
