<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>{{formName}}</b-breadcrumb-item>
            
        </b-breadcrumb>
        
        <b-container fluid>
            
            <b-card>
                 <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Material Development</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <b-button title="Back" size="sm" variant="warning" v-on:click="backTo()">
                                    <b-icon font-scale="2" icon="arrow-left" variant="dark"></b-icon>
                                    Back 
                                </b-button>
                            </div>
                        </b-col>
                        
                    </b-row>
                </template>
                <!-- <b-card sub-title="Filter">
                    <b-row>
                        <b-col sm="auto" md="4" lg="4">
                                <b-form-group
                                    label="Brand"
                                    label-for="brand-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="brand-select"
                                        v-model="brandFilter"
                                        :options="brandOpt"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="all">All</option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group
                                    label="Material Code"
                                    label-for="coll-month-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="coll-month-select"
                                        v-model="collMonthFilter"
                                        :options="monthOpt"
                                        size="sm"
                                        value-field="value"
                                        text-field="value"
                                    >
                                        <template #first>
                                            <option value="all">All</option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>

                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                    label="Article"
                                    label-for="year-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="year-select"
                                        v-model="yearFilter"
                                        :options="yearOpt"
                                        size="sm"
                                        value-field="value"
                                        text-field="value"
                                    >
                                        <template #first>
                                            <option value="all">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                            <b-form-group
                                    label="Date"
                                    label-for="status-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="status-select"
                                        v-model="statusFilter"
                                        :options="statusOpt"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="all">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                    
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Search
                                    </template>
                                    <b-input-group size="sm">
                                        <b-form-input 
                                            id="inputSearch" 
                                            v-model="filter"
                                            type="search"
                                            placeholder="Type to Search" 
                                            trim
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Search</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card> -->
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                <b-card sub-title="Filter">
                    <b-row>
                        <b-col sm="auto" md="4" lg="4">
                                
                            <b-form-group
                                   
                                    
                                    label-size          ="sm"
                                    >
                                    <template v-slot:label>
                                        Category
                                    </template>
                                    <b-form-select
                                        id="category-select"
                                        v-model="filters.category"
                                        :options="categoryOpt"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                            <b-form-group
                                   
                                    
                                    label-size          ="sm"
                                    >
                                    <template v-slot:label>
                                        Brand
                                    </template>
                                    <b-form-select
                                        id="brand-select"
                                        v-model="filters.brand"
                                        :options="brandOpt"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                    
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Search
                                    </template>
                                    <b-input-group size="sm">
                                        <b-form-input 
                                            id="inputSearch" 
                                            v-model="filter"
                                            type="search"
                                            placeholder="Type to Search" 
                                            trim
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Search</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                            </b-form-group>
                            <b-form-group
                                   
                                    
                                    label-size          ="sm"
                                    >
                                    <template v-slot:label>
                                        Coll. Month
                                    </template>
                                    <b-form-select
                                        id="coll-month-select"
                                        v-model="filters.month"
                                        :options="monthOpt"
                                        value-field="value"
                                        text-field="value"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
                <b-table
                    striped 
                    hover
                    show-empty
                    responsive
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    :fields="fields"
                    :items="filterProduct"
                    :filter="filter"
                    :filter-included-fields="includeFiled"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <b-button title="Detail" size="xs" variant="success" v-on:click="viewData(item.product_master_id)">
                            
                            <b-icon icon="plus" aria-label="Help"></b-icon>
                            Add Supplier
                        </b-button>
                        <!-- <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.code,item.category,item.id)">
                            <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                        </b-button> -->
                    </template>
                </b-table>
                <div class="mt-3">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="center"
                    >

                    </b-pagination>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import apiProduct from '@/store/product-form/master-param.js'
import {mapActions, mapGetters} from 'vuex'
export default {
    props:['productId'],
    data(){
        return{
            formName:'Material Development',
            sortBy: 'is_status',
            sortDesc: false,
            dataMaterialDev:[],
            username:null,
            roleUser:null,
            brandallperuser:[],
            converDataBrand:[],
            fields:[
                {key:'actions', label:'#'},
                // {key:'article',sortable:true},
                {key:'brand',sortable:true},
                {key:'coll_month',sortable:true},
                {key:'category',sortable:true},
                'article',
                {key:'code'},
                {key:'type',sortable:true},
                {key:'description'},
                {key:'detail'},
                // {key:'images'},
                {key:'used_as',sortable:true},
                // {key:'supplier',sortable:true},
                // {key:'last_activity'},
                // {key:'last_update'},
                {key:'is_status',label:'status',sortable:true},
            ],
            paging:{
                perPage:10,
                currentPage:1,
            },
            dataMatDev:[],
            isLoading:false,
            search:null,
            filter:null,
            brandOpt:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'ANTHM',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
            ],
            filters:{
                brand:'',
                month:'',
                year:'',
                category:'',
            },
            monthOpt:[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            categoryOpt:[
                
                {value:'fabric',text:'Fabric'},
                {value:'accessories',text:'Accessories'},
                {value:'thread',text:'Thread'},
                {value:'artwork',text:'Artwork'},
                {value:'interlining',text:'Interlining'},
            ],
            includeFiled:['category','code','article','type','description','detail','used_as','supplier','last_activity'],
            yearOpt:[

            ],
        }
    },
    methods:{
        ...mapGetters('session', ['userData']),
        ...mapActions('materialDev',['GET_MATERIALDEV_DASHBOARD']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL','POST_MASTER_MATERIAL','POST_MATERIAL_IMAGES','addMaster','editMaster','updateMaster','deleteMaster']),
        goToCreate(){
            this.$router.push({name:'AppsMaterialDevFormPage'})
        },
        getDataMaterial(){
            this.isLoading = true
            
            this.GET_MATERIALDEV_DASHBOARD().then(response => {
                let dataMat = []
                if (this.productId) {
                    dataMat = response.data.payload.filter(i=>i.product_id == this.productId)
                }else if(this.roleUser == 'PLM - MD'){
                    const finalData = JSON.parse(JSON.stringify(this.brandallperuser));
                    for (let i = 0; i < finalData.length; i++) {
                            const brand = finalData[i]
                            const allbrand = response.data.payload.filter(i=>i.brand == brand)
                            this.converDataBrand.push(allbrand)
                     }
                     dataMat = this.converDataBrand.flat()
                     console.log('data MD', dataMat)
                }
                else{
                    dataMat = response.data.payload
                    console.log('data Super User', dataMat.sort())
                }
                this.dataMatDev = dataMat
                this.isLoading = false
                
            }).catch(err =>{
                this.isLoading = false
            })

            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')
                    
                    this.yearOpt = response.data.payload.filter(d=>d.category ==='Year')
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
            
            
        },
        editData(code,category,id){
            
            this.$router.push({
                name:'AppsMaterialDevFormPage',
                params:{
                    material_id:id,
                    code_name: code,
                    c_name: category
                }
            })
        },
        viewData(id){
            
            this.$router.push({
                name:'AppsMaterialDevDetailPage',
                params:{
                    material_id:id,
                }
            })
        },
        backTo(){
            this.$router.push({
                name:'AppsSupplierSelectionFormPage',
                params:{productId:this.productId}
            })
        },
        getDataParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => { 
                     let userData  = JSON.parse(localStorage.userData)
                     this.roleUser = userData.role
                     this.username = userData.username
                     let parameter = response.data.payload
                     let parameter_brand = parameter.filter(app =>app.value === 'MD' && app.description ==  this.username)
                     for (let i = 0; i < parameter_brand.length; i++) {
                            const brand = parameter_brand[i].name
                            this.brandallperuser.push(brand)
                     }
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
            
        }
    },
    created(){
        this.getDataParam()
        this.getDataMaterial()
    },
    computed:{
        ...mapGetters('materialDev',['MATERIALDEV_DASHBOARD']),
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        rows() {
            
            return this.filterProduct.length
        },
        filterProduct(){

                let categories = this.filters.category
                let brand = this.filters.brand
                let month = this.filters.month
                return this.dataMatDev.filter(function(item){
                        let filtereds = true
                        if(categories && categories.length > 0){
                                filtereds = item.category == categories
                        }
                        if(filtereds){
                            if (brand && brand.length > 0) {
                                filtereds = item.brand == brand
                            }
                        }
                        if (filtereds) {
                            if(month && month.length > 0){
                                filtereds = item.coll_month == month
                            }
                        }
                        
                        return filtereds
                    })
            
        }
    }
}
</script>