<template>
  <div class="tables-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/reports"> Reports</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ stActiveReport }}</b-breadcrumb-item>
    </b-breadcrumb>
    <h2 class="page-title">
      <span class="fw-semi-bold">{{ stActiveReport }}</span>
      <span class="fw-normal fs-larger"> {{ rptVersion }} </span>
    </h2>
    <b-row>
      <b-col xs="12">
        <div class="table-responsive bg-white">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col" rowspan="2" class="text-center align-middle">
                  Bisnis Unit
                </th>
                <th scope="col" rowspan="2" class="text-center align-middle">
                  Total Karyawan
                </th>
                <th scope="col" colspan="2" class="text-center">Vaksin Ke-1</th>
                <th scope="col" colspan="2" class="text-center">Vaksin Ke-2</th>
                <th scope="col" colspan="2" class="text-center">Vaksin Ke-3</th>
                <th scope="col" colspan="2" class="text-center">
                  Belum Vaksin
                </th>
              </tr>
              <tr>
                <th class="text-center align-middle">Partisipan</th>
                <th class="text-center align-middle">%</th>
                <th class="text-center align-middle">Partisipan</th>
                <th class="text-center align-middle">%</th>
                <th class="text-center align-middle">Partisipan</th>
                <th class="text-center align-middle">%</th>
                <th class="text-center align-middle">Partisipan</th>
                <th class="text-center align-middle">%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span v-if="this.rptData.bbip !== null">
                    {{ this.rptData.bbip }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.total_employee_BBIP !== null" >
                  {{ this.rptData.total_employee_BBIP }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.total_vaksin_1_BBIP !== null" >
                  {{ this.rptData.total_vaksin_1_BBIP }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.vaccine_1_percentage_BBIP !== undefined">
                  {{ this.rptData.vaccine_1_percentage_BBIP + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.total_vaksin_2_BBIP !== null">
                  {{ this.rptData.total_vaksin_2_BBIP }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.vaccine_2_percentage_BBIP !== undefined">
                  {{ this.rptData.vaccine_2_percentage_BBIP + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.total_vaksin_3_BBIP !== null">
                  {{ this.rptData.total_vaksin_3_BBIP }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.vaccine_3_percentage_BBIP !== undefined">
                  {{ this.rptData.vaccine_3_percentage_BBIP + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.total_vaksin_not_BBIP !== null">
                  {{ this.rptData.total_vaksin_not_BBIP }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if="this.rptData.vaccine_not_percentage_BBIP !== undefined">
                  {{ this.rptData.vaccine_not_percentage_BBIP + " %" }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span v-if='this.rptData.aoi1 !== null'>
                    {{ this.rptData.aoi1 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_employee_AOI1 !== null'>
                    {{ this.rptData.total_employee_AOI1 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_1_AOI1 !== null'>
                    {{ this.rptData.total_vaksin_1_AOI1 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.vaccine_1_percentage_AOI1 !== undefined'>
                    {{ this.rptData.vaccine_1_percentage_AOI1 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_2_AOI1 !== null'>
                    {{ this.rptData.total_vaksin_2_AOI1 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.vaccine_2_percentage_AOI1 !== undefined'>
                    {{ this.rptData.vaccine_2_percentage_AOI1 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_3_AOI1 !== null'>
                    {{ this.rptData.total_vaksin_3_AOI1 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.vaccine_3_percentage_AOI1 !== undefined'>
                    {{ this.rptData.vaccine_3_percentage_AOI1 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_not_AOI1 !== null'>
                    {{ this.rptData.total_vaksin_not_AOI1 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.vaccine_not_percentage_AOI1 !== undefined'>
                    {{ this.rptData.vaccine_not_percentage_AOI1 + " %" }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span v-if='this.rptData.aoi2 !== null'>
                    {{ this.rptData.aoi2 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_employee_AOI2 !== null'>
                    {{ this.rptData.total_employee_AOI2 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_1_AOI2 !== null'>
                    {{ this.rptData.total_vaksin_1_AOI2 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.vaccine_1_percentage_AOI2 !== undefined'>
                    {{ this.rptData.vaccine_1_percentage_AOI2 + " %"}}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_2_AOI2 !== null'>
                    {{ this.rptData.total_vaksin_2_AOI2 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_2_AOI2 !== undefined'>
                    {{ this.rptData.vaccine_2_percentage_AOI2 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_3_AOI2 !== null'>
                    {{ this.rptData.total_vaksin_3_AOI2 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_3_AOI2 !== undefined'>
                    {{ this.rptData.vaccine_3_percentage_AOI2 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_not_AOI2 !== null'>
                    {{ this.rptData.total_vaksin_not_AOI2 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_not_AOI2 !== undefined'>
                    {{ this.rptData.vaccine_not_percentage_AOI2 + " %" }}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span v-if='this.rptData.aoi3 !== null'>
                    {{ this.rptData.aoi3 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_employee_AOI3 !== null'>
                    {{ this.rptData.total_employee_AOI3 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_1_AOI3 !== null'>
                    {{ this.rptData.total_vaksin_1_AOI3 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_1_AOI3 !== undefined'>
                    {{ this.rptData.vaccine_1_percentage_AOI3 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_2_AOI3 !== null'>
                    {{ this.rptData.total_vaksin_2_AOI3 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.vaccine_2_percentage_AOI3 !== undefined'>
                    {{ this.rptData.vaccine_2_percentage_AOI3 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_3_AOI3 !== null'>
                    {{ this.rptData.total_vaksin_3_AOI3 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.vaccine_3_percentage_AOI3 !== undefined'>
                    {{ this.rptData.vaccine_3_percentage_AOI3 + " %" }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_not_AOI3 !== null'>
                    {{ this.rptData.total_vaksin_not_AOI3 }}
                  </span>
                </td>
                <td class="text-right">
                  <span v-if='this.rptData.total_vaksin_not_AOI3 !== undefined'>
                    {{ this.rptData.vaccine_not_percentage_AOI3 + " %" }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
    <Loader v-if="isLoadingData" fullPage useOverlay></Loader>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { fnGET, fnPOST } from "../../../../api/api-methods";
import { APIPATH_HR, APIPATH_BBIAPPS } from "../../../../api/api-paths";
import { fnApiFetchListDepartment } from "@/api/api-reports.js";
import Loader from "@/components/Loader/Loader";
import { setFocus, showToastOK } from "@/core/pluginHelper";

export default {
  name: "DailyHealthReportV3",
  components: {
    Loader,
  },
  data() {
    return {
      rptVersion: "v1",
      isLoadingData: false,
      errorMessage: null,

      //   For Input
      BUList: [],
      selectedBU: "",
      optionsDept: [],
      selectedDept: "",
      optionCatList: [],
      selectedCat: "",
      rptData: [],
    };
  },
  computed: {
    ...mapState("report", ["stActiveReport"]),
    ...mapGetters("session", ["userData", "localDate"]),
  },
  methods: {
    goToReport() {
      this.$router.push({ name: "ReportPage" });
    },
    populateReport() {
      this.isLoadingData = true
      fnPOST(APIPATH_HR.rptVaccineHistory1, {
        businessunitBBIP: "BBI1",
        businessunitAOI1: "AOI1",
        businessunitAOI2: "AOI2",
        businessunitAOI3: "AOI3",
      })
        .then((rsp) => {
          this.$set(this, "rptData", rsp.data.payload);
          console.log(this.rptData);
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            showToastOK({
              type: "error",
              message: "Failed loading report",
            });
          }
        })
        .finally(() => {
          this.isLoadingData = false;
        });
    },
  },
  created() {
    this.populateReport();
  },
};
</script>

<style src="./VaccineHistoryReport.scss" lang="scss" scoped />

<link type="text/css" rel="stylesheet" href="https://unpkg.com/bootstrap-vue@latest/dist/bootstrap-vue.min.css"/>
