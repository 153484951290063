/* eslint-disable no-console */
<template>
  <div class="auth-page">
    <b-container>
      <h5 class="auth-logo">
        <div class="banner-container">
          <div class="bbi-banner"></div>
        </div>
        <i class="fa fa-circle text-gray"></i>
        APPS
        <i class="fa fa-circle text-bbi_yellow"></i>
      </h5>
      <h3 class="text-danger custom-title" v-if="!!title2">
        <i class="fa fa-exclamation-circle custom-icon"></i>
        <b>{{ title2 }}</b>
        <i class="fa fa-exclamation-circle custom-icon"></i>
      </h3>
      <div class="custom-alert" v-if="!!envInfo">
        {{ envInfo }}
      </div>
      <br />
      <b-row>
        <!-- BANNER VERTICAL 2 -->
        <!-- <b-col class="d-none d-lg-block">
          <div>
            <img
              style="max-height: 450px; max-width: 200px; float: right"
              src="../../assets/banner-vertical2.png"
            />
          </div>
        </b-col> -->
        <!-- BANNER VERTICAL 2 END -->
        <b-col>
          <Widget class="widget-auth mx-auto" customHeader>
            <p class="widget-auth-info fontbbi space1">
              Use your NIK to sign in.
            </p>
            <form class="mt" @submit.prevent="loginUser">
              <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
                {{ errorMessage }}
              </b-alert>
              <div class="form-group">
                <b-form-select
                  v-model="selectedBU"
                  placeholder="Choose Business Unit"
                >
                  <option
                    v-for="(row, index) in BUList"
                    :key="index"
                    :value="row.code"
                    :selected="selectedBU"
                  >
                    {{ row.code }} - {{ row.name }}
                  </option>
                </b-form-select>
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  ref="userid"
                  name="userid"
                  placeholder="Employee ID"
                />
              </div>
              <div class="form-group">
                <b-input-group>
                  <input
                    class="form-control"
                    ref="password"
                    required
                    :type="passwordFieldType"
                    name="password"
                    placeholder="Password"
                  />
                  <b-input-group-append
                    is-text
                    v-on:click="showPassword"
                    style="cursor: pointer"
                  >
                    <b-icon :icon="isIcon"></b-icon>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <b-button
                type="submit"
                size="sm"
                class="auth-btn mb-3 fontbbi space2"
                :disabled="disableSubmit"
                variant="inverse"
                >Login</b-button
              >
            </form>
            <!-- BANNER HORIZONTAL -->
            <!-- <br />
            <div>
              <img
                style="max-height: 150px; max-width: 300px"
                src="../../assets/banner-horizontal.png"
              />
            </div> -->
            <!-- BANNER HORIZONTAL END -->
            
            <!-- <router-link class="d-block text-center" to="forgotpassword"
          >Forgot Password?</router-link
        > -->
          </Widget></b-col
        >
        <!-- BANNER VERTICAL -->
        <!-- <b-col class="d-none d-lg-block">
          <div>
            <img
              style="max-height: 450px; max-width: 200px; float: left"
              src="../../assets/banner-vertical.png"
            /></div
        >
        </b-col> -->
        <!-- BANNER VERTICAL END -->
      </b-row>
      <br />
    </b-container>
  </div>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import loginStore from "@/store/login.js";

export default {
  name: "LoginPage",
  components: { Widget },
  data() {
    return {
      isHidden: true,
      isIcon: "eye-slash",
      passwordFieldType: "password",
      errorMessage: null,
      username: "",
      password: "",
      selectedBU: "",
      BUList: null,
      wrongCred: false, // activates appropriate message if set to true
      envInfo: null,
      title2: null,
      disableSubmit: false,
      coordinates: null,
      getGeoEnabled: null,
    };
  },
  methods: {
    showPassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.isIcon = this.isIcon === "eye-slash" ? "eye" : "eye-slash";
    },
    loginUser() {
      // call loginUSer action
      const userid = this.$refs.userid.value;
      const password = this.$refs.password.value;
      // const bunit = this.$refs.bunit.value;
      const bunit = this.selectedBU;

      this.getGeoEnabled = "false";
      this.disableSubmit = true;
      // get env geolocation, auto "false" if env param not found
      let paramExist3 = !!process.env.VUE_APP_GEOLOCATION_ENABLED;
      if (paramExist3) {
        this.getGeoEnabled = process.env.VUE_APP_GEOLOCATION_ENABLED;
      }
      if (this.getGeoEnabled == "true") {
        // get geolacation
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            // if browser location is allowed, send coordinates to backend and local storage
            (position) => {
              if (paramExist3) {
                this.getGeoEnabled = process.env.VUE_APP_GEOLOCATION_ENABLED;
                if (this.getGeoEnabled == "true") {
                  this.coordinates =
                    position.coords.latitude + ", " + position.coords.longitude;
                }
              }
            },
            // if broser locations block, send alert message and redirect to login page
            (error) => {
              console.log(error.message);
              alert("Please allow your browser location!");
              loginStore.dispatch("logoutUser");
              this.$router.push({
                name: "Login",
              });
            }
          );
        } else {
          alert("Geolocation is not supported by this browser.");
        }
      } else {
        this.coordinates = 0 + ", " + 0;
      }

      loginStore.dispatch("getCoordinates", {
        coordinates: this.coordinates,
      });

      if (userid.length !== 0 && password.length !== 0) {
        loginStore
          .dispatch("loginUser", {
            username: userid,
            password: password,
            businessunit: bunit,
          })
          .then(() => {
            this.wrongCred = false;
            if (loginStore.getters.firstLogin) {
              this.$router.push("/app/account");
              this.$router.push({ name: "AccountPage" });
            } else {
              if (this.$route.query.wbs) {
                this.$router.push("/wbs");
              } else {
                let redirect_url = localStorage.getItem("redirect_url");
                if (redirect_url && redirect_url !== String(null)) {
                  window.location.replace(redirect_url);
                } else {
                  this.$router.push("/app/dashboard");
                }
              }
            }
          })
          .catch((err) => {
            this.errorMessage = err;
            this.wrongCred = true; // if the credentials were wrong set wrongCred to true

            this.$refs.password.value = "";
          })
          .finally(() => {
            this.disableSubmit = false;
          });
      }
    },
    initLogin() {
      let paramExist = !!process.env.VUE_APP_LOGIN_ENV_INFO;
      let paramExist2 = !!process.env.VUE_APP_LOGIN_TITLE_2;

      if (paramExist) {
        this.envInfo = process.env.VUE_APP_LOGIN_ENV_INFO;
      }

      if (paramExist2) {
        this.title2 = process.env.VUE_APP_LOGIN_TITLE_2;
      }
    },
  },
  created() {
    if (window.localStorage.getItem("authenticated") === "true") {
      this.$router.push("/app/dashboard");
    }
    loginStore.dispatch("getListBU").then(() => {
      this.BUList = loginStore.getters.BUList;

      this.selectedBU = loginStore.getters.BUList[0].code;
    });
    this.initLogin();
  },
};
</script>
<style>
</style>