<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
            <b-breadcrumb-item >{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container fluid>
            <b-card>
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h3>MD List</h3>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <b-button variant="success" size="sm" v-on:click="show = !show">Create</b-button>
                            </div>
                        </b-col>
                    </b-row>
                    
                </template>
                <b-table
                    striped
                    responsive
                    hover
                    :items="dataMD"
                    :fields="fieldsMD"
                    show-empty
                >
                    <template #cell(actions)="data">
                        <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(data.index)">
                            <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                        </b-button>
                        <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(data.index)">
                            <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                        </b-button>
                    </template>
                    <template #cell(index)="data">
                        {{ data.index + 1 }}
                    </template>
                </b-table>
            
            </b-card>
            <b-modal
                v-model="show"
                hide-backdrop
                v-on:ok="submitForm()"
                ok-title="Save"
                size="sm"
                ref="modal"
                scrollable 
                title="Form MD">
                <FormMD/>
            </b-modal>
        </b-container>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import FormMD from './MDForm';
export default {
    components:{
        FormMD
    },
    name:'MDList',
    data(){
        return{
            show:false,
            formName:'MD',
            modulName:'List',
            fieldsMD:[
                {key:'actions', label:'#'},
                {key:'outsource', label:'Name Subcon'},
                {key:'fob', label:'Type'},
                'alamat',
                {key:'sample', label:'With Sample'},
                {key:'sampleRoom', label:'Type Sample'},
                'nameOutsource',
            ],
            dataMD:[],

        }
    }
    
}
</script>