<template>
   <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>{{formName}}</b-breadcrumb-item>
            
        </b-breadcrumb>

          <b-container fluid>
            
            <b-card>
                 <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Garment Development</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <b-button title="Back" size="sm" variant="warning" v-on:click="backTo()">
                                    <b-icon font-scale="2" icon="arrow-left" variant="dark"></b-icon>
                                    Back 
                                </b-button>
                            </div>
                        </b-col>
                        
                    </b-row>
                </template>
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                  <b-card sub-title="Filter">
            <b-row>
                <b-col sm="auto" md="4" lg="4">
                        <b-form-group
                            label="Brand"
                            label-for="brand-select"
                            label-cols          ="3" 
                            label-size          ="sm"
                            v-slot="{ ariaDescribedby }"
                            >
                            <b-form-select
                                id="brand-select"
                                v-model="filters.brand"
                                :options="brandOpt"
                                :aria-describedby="ariaDescribedby"
                                size="sm"
                            >
                                <template #first>
                                    <option value="">All</option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group
                            label="Coll. Month"
                            label-for="coll-month-select"
                            label-cols          ="3" 
                            label-size          ="sm"
                            >
                            <b-form-select
                                id="coll-month-select"
                                v-model="filters.month"
                                :options="monthOpt"
                                size="sm"
                                value-field="value"
                                text-field="value"
                            >
                                <template #first>
                                    <option value="">All</option>
                                </template>
                            </b-form-select>
                        </b-form-group>

                </b-col>
                <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                            label="Search"
                            label-for="search"
                            label-cols="3" 
                            label-size="sm"
                            >
                            <template v-slot:label>
                                Search
                            </template>
                            <b-input-group size="sm">
                                <b-form-input 
                                    id="inputSearch" 
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search" 
                                    trim
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-button :disabled="!filter" @click="filter = ''">Search</b-button>
                                </b-input-group-append>
                            </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
                <b-table
                   striped 
                   hover
                   show-empty
                   responsive
                   :per-page="paging.perPage"
                   :current-page="paging.currentPage"
                   :items="filterProduct"
                   :fields="fields"
                   :filter="filter"
                   :filter-included-fields="includeFiled"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <b-button title="Detail" size="xs" variant="success" v-on:click="viewData(item.id)">
                            <b-icon icon="plus" aria-label="Help"></b-icon>
                            Add Supplier
                        </b-button>
                        <!-- <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.code,item.category,item.id)">
                            <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                        </b-button> -->
                    </template>
                </b-table>
                <div class="mt-3">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="center"
                    >

                    </b-pagination>
                </div>
            </b-card>
        </b-container>

    </div>
</template>

<script>
import { APIPATH_PLM } from "../../../../api/api-paths";
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../api/api-methods";
export default {
    data(){
        return{
            formName:'Garment Development',
            sortBy: 'age',
            sortDesc: false,
            dataMaterialDev:[],
            fields:[
                {key:'actions', label:'#'},
                {key:'brand',sortable:true},
                {key:'coll_month',sortable:true},
                {key:'article',sortable:true},
                {key:'code',sortable:true},
                'year',
                'duration',
                'status'
                ],
            paging:{
                perPage:10,
                currentPage:1,
            },
            dataGardDev:[],
            isLoading:false,
            search:null,
            filter:null,
            brandOpt:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'ANTHM',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
            ],
            filters:{
                brand:'',
                month:'',
                category:'',
            },
            monthOpt:[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            includeFiled:['code','article'],
            yearOpt:[

            ],
        }
    },
    methods:{
        getDataGarmentDash(){
         fnGET(APIPATH_PLM.getGarmentDashboard).then(
           (rsp) => {
             const filterData = rsp.data.payload.filter(x => x.img_label === "design")
             this.dataGardDev = filterData
           }
         );
        },
        viewData(id){
        this.$router.push({
            name:'AppsGarmentDevDetailPage',
            params:{
            garment_id:id,
            }
        })
        },
        backTo(){
        this.$router.push({
            name:'ApplicationPage',
            params:{productId:this.productId}
        })
    },
    },
    created(){
        this.getDataGarmentDash()
    },
    computed:{
        rows() {    
            return this.filterProduct.length
        },
        filterProduct(){

                let brand = this.filters.brand
                let month = this.filters.month
                return this.dataGardDev.filter(function(item){
                        let filtereds = true
                        if(filtereds){
                            if (brand && brand.length > 0) {
                                filtereds = item.brand == brand
                            }
                        }
                        if (filtereds) {
                            if(month && month.length > 0){
                                filtereds = item.coll_month == month
                            }
                        }
                        
                        return filtereds
                    })
            
        }
    }
    
}
</script>
