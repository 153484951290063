<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item class="fw-semi-bold">{{formName}}</b-breadcrumb-item>

        </b-breadcrumb>

        <b-container fluid>

            <b-card>
                 <template v-slot:header >
                    <b-row align-v="center">
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Dept Head Assigment</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <b-button title="Add" v-if="roles_list.includes('superuser') || roles_list.includes('div-head')" v-on:click="AddNew()" size="sm" variant="outline-success" style='margin-right:5px;'>
                                    <b-icon icon="journal-plus" aria-label="Add"></b-icon>
                                    Add New
                                </b-button>
                                <!-- <b-button title="Add" size="sm" variant="success" v-on:click="AddNew()">
                                    <span>Add New</span>
                                </b-button> -->
                            </div>
                        </b-col>

                    </b-row>
                </template>
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                <b-table
                    :no-border-collapse="true"
                    striped
                    class="header-background "
                    hover
                    outlined
                    show-empty
                    responsive
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    :fields="fields"
                    :items="dataTable"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>

                    <!-- Start Date -->
                    <template #cell(start_date)="row">
                        <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
                    </template>

                    <!-- End Date -->
                    <template #cell(end_date)="row">
                        <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
                    </template>



                    <!-- <template v-slot:cell(actions)="{item}"> -->
                    <template #cell(action)="row">
                        <b-button title="View" size="xs" variant="outline-info" v-on:click="viewData(row.item.id)" style='margin-right:5px;'>
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>


                        </b-button>
                        <b-button v-if="roles_list.includes('superuser') || roles_list.includes('div-head')"
                            title="Edit" size="xs" variant="outline-warning" v-on:click="editData(row.item.id)">
                            <b-icon icon="pencil-fill" aria-label="Edit"></b-icon>


                        </b-button>
                    </template>
                </b-table>
                <div class="my-1">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="right"
                        size="sm"
                    >
                    <template #first-text><span class="">First</span></template>
                    <template #prev-text><span class="">Prev</span></template>
                    <template #next-text><span class="">Next</span></template>
                    <template #last-text><span class="">Last</span></template>
                    <template #page="{ page, active }">
                        <b v-if="active">{{ page }}</b>
                        <!-- <b v-if="active">{{Pagination(page)}} {{ page }}</b> -->
                        <i v-else>{{ page }}</i>
                    </template>

                    </b-pagination>
                </div>
            </b-card>
            <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
        </b-container>
    </div>
</template>
<script>
import Loader from "@/components/Loader/Loader";
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    components: {
        Loader,
    },
    data(){
        return{
            isLoadingData:false,
            formName:'Dept Head Assigment',
            monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            sortBy: 'period_date',
            sortDesc: true,
            roles_list:[],
            dataTable:[],
            fields:[
                {key:'action', label:'Action', sortable:false},
                {key:'name', label:'Name', class:"display-hidden", sortable:false},
                {key:'sales_group', label:'Sales Group ID', class:"display-hidden", sortable:true},
                {key:'sales_group_name', label:'Sales Group', sortable:true},
                {key:'start_date', label:'Start Date', sortable:true},
                {key:'end_date', label:'End Date', sortable:true},

            ],
            paging:{
                perPage:10,
                currentPage:1,
            },
            isLoading:false,
            form_filter:null,
            form:{
                role:null,
                start_limit:null,
                end_limit:null,
                user_nik:null,
                username:null,
            },
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['stCstmDist','stCstmDistCount']),
        rows(){
            if(this.dataTable != null){
                return this.dataTable.length
            }
        }
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['getDashboardCustomerDistribution']),
        setData(){
            let roles = this.userData.roles
            if (roles.length > 0) {
                for (let data in roles) {
                if (roles[data].app == 'SFC') {
                    let lower = roles[data].role_list.map(string => {
                    return string.toLowerCase();
                    });
                    this.roles_list = lower
                }
                }
            }
            if (this.roles_list.includes('superuser') || this.roles_list.includes('dept-head') || this.roles_list.includes('div-head')) {
                this.form.user_nik   = this.userData.nik
                this.form.username   = this.userData.fullname
                this.LoadData()
            } else {
                this.showNotification({type:'error', message:"You don't have permissions to view this page"})
                this.$router.push({name:"ApplicationPage"})
            }

        },
        LoadData() {
            // start loading
            this.isLoadingData = true

            this.getDashboardCustomerDistribution({
                role:this.form.role,
                start_limit:this.form.start_limit,
                end_limit:this.form.end_limit,
            })
            .then(rsp => {
                this.dataTable = this.stCstmDist
                if(this.stCstmDistCount == 0) {
                    this.showNotification({type:'info', message:'Data Not Found'})
                }
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            }).finally(()=> {
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
            })
        },
        FormatDate(value) {
            if(value == null || value == undefined || value == '') {
                return ' '
            } else {
            let date = new Date(value)
            let tgl = date.toISOString().slice(8,10)
            let bulan = this.monthNames[date.getMonth()]
            let tahun = date.toISOString().slice(0,4)
            date = tgl+' '+bulan+' '+tahun
            return date
            }
        },
        AddNew() {
            this.$router.push({ name: 'SFCCustomerDistributionForm'})
        },
        viewData(data) {
            this.$router.push({ name: 'SFCCustomerDistributionForm', params: { page:'view', idData:data } })
        },
        editData(data) {
            this.$router.push({ name: 'SFCCustomerDistributionForm', params: { page:'edit', idData:data } })
        },
    },
    created(){
        this.setData()
    },
}
</script>
<style src="../SalesForce.scss" lang="scss" scoped />