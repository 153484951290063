<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/sfc-customer-distribution">Dept Head Assigment</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="lg">
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Dept Head Assigment Form" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style='display: flex;'>
                <h3> Dept Head Assigment Form</h3>
                <b-button v-if='disabledForm == true' @click="BackHistoryButton()" size="md" type='button' variant="info" style="position: absolute; right: 15px;">
                  <b-icon icon="arrow-left"></b-icon>
                  Back
                </b-button>
              </div>

            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="Start Date"
                      label-for="Start Date"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <VueDatePicker
                        class="vd__div"
                        v-model="form.start_date"
                        no-header
                        :disabled="disablePeriod"
                        format="DD MMM YYYY"
                        placeholder="Choose date"
                        />
                      </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm='4'>
                    <b-form-group
                      label="End Date"
                      label-for="End Date"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <VueDatePicker
                        class="vd__div"
                        v-model="form.end_date"
                        no-header
                        :disabled="disablePeriod"
                        format="DD MMM YYYY"
                        placeholder="Choose date"
                        />
                      </b-form-group>
                  </b-col>
                  <b-col sm='4'>
                    <b-form-group
                      label="Sales Group"
                      label-for="Sales Group"
                      label-cols-sm="4"
                      label-align-sm="left"
                      label-size="sm"
                    >
                    <v-select
                    v-model="form.selected_sales_group"
                    :reduce="(data) => data"
                    :clearable="false"
                    class="vd__div"
                    placeholder="Choose Sales Group"
                    :required="true"
                    :disabled="disabledForm"
                    @input="ChangeSalesGroup($event)"
                    label="name"
                    :options="options.sales_group"
                    >
                    </v-select>
                      </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                <b-col sm='12'>
                  <b-form-group
                    label="Customer Distribution"
                    label-for="Sales Group"
                    label-cols-sm="4"
                    label-align-sm="left"
                    label-size="sm"
                  >
                  <multiselect
                    v-model="form.customerDistribution"
                    tag-placeholder="Add this as new tag"
                    placeholder="Search or add Customer"
                    label="customer_name"
                    @input="ChangeDistribution"
                    :options="options.customer"
                    :custom-label="CustomLabel"
                    :disabled="disabledForm"
                    track-by="customer_name"
                    :multiple="true"
                    :taggable="true"
                  >
                  </multiselect>
                  </b-form-group>
                </b-col>
              </b-list-group-item>
            </b-list-group>

          </b-card>

          <br />
          <b-row align-h="center">
              <b-col md='6'>
                <b-button v-if='buttonSubmit == null ' block size="md" @click="Confirm('save')" type='submit' variant="primary">
                  Submit
                </b-button>
                <b-button v-if='buttonSubmit == "edit" ' @click="Confirm('edit')" block size="md" type='submit' variant="success">
                  Confirm
                </b-button>
              </b-col>
          </b-row>

        </b-col>
      </b-row>
      <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect  } from 'vue-search-select'
import apiCustomerDistribution from '@/store/salesforce-form/customer-distribution.js';
import 'vue-search-select/dist/VueSearchSelect.css';
import Vue from "vue";
// import vue multiselect
import Multiselect from "vue-multiselect";

export default {
  components: {
    ModelListSelect,
    Multiselect,
    Loader,
  },
  data() {
    const now = new Date()
    const today = now.toISOString().slice(0,10)
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: 'numeric'};
    const year = now.toLocaleString('en-US', optyear)
    const optmonth = { month: 'numeric'};
    const SMonth = { month: 'numeric'};
    const month = now.toLocaleString('en-US', optmonth)
    const Strmonth = now.toLocaleString('en-US', SMonth)
    const optdays = { day: 'numeric'};
    const days = now.toLocaleString('en-US', optdays)
    return {
      isLoadingData:false,
      formName: "Dept Head Assigment Form",
      minMonthDate:null,
      maxMonthDate:null,
      show:false,
      today:today,
      BackHistory:null,
      disablePeriod:false,
      headerTitle:null,
      disabledForm:false,
      buttonSubmit:null,
      showHidden:true,
      today_days:days,
      today_month:month,
      today_year:year,
      options: {
        customer:[],
        sales_group:[],
      },
      form :{
        period:null,
        name:null,
        customerDistribution:null,
        customer_distribution:null,
        employee_nik:null,
        employee_name:null,
        start_date:null,
        end_date:null,
        selected_sales_group:null,
        sales_group:null,
        sales_group_name:null,
        customer_id:null,
        customer_name:null,
      },

    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState('sales_force', ['stDtCstmDist','stDtCstmDistCount','stDtCstmDistDetail','stDtCstmDistDetailCount','stSlsGrp','stSlsGrpCount','stDtCstmDistList','stDtCstmDistListCount']),


  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions('general', ['fetchCityList']),
    ...mapActions('sales_force', ['getDataCustomerDistribution','getDataSalesGroup','getDataCustomerDistributionDetail','getDataCustomerDistributionList']),

    setData() {
      this.form.employee_nik    = this.userData.nik
      this.form.employee_name   = this.userData.fullname
      let statusRoute           = this.$route.params.page
      // let idData                = this.$route.params.idData

      let start                 = this.today
      let end                   = this.today_year+"-12-31"
      this.form.period          = start
      if (statusRoute == undefined || statusRoute =='' ) {
        statusRoute = null
      }
      this.buttonSubmit = statusRoute

      if (statusRoute == 'edit' || statusRoute== 'view') {
        this.disablePeriod = true
        if (statusRoute == 'view') {
          if (this.$route.params.BackHistory == 'CustomerDistribution') {
            this.BackHistory = 'CustomerDistribution'
          }
          this.headerTitle = 'View'
          this.buttonSubmit = 'view'
          this.disabledForm = true
          // this.showHidden = false;
        }
        if (statusRoute == 'edit') {
          this.disablePeriod = false
          this.headerTitle = 'Edit'
          this.buttonSubmit = 'edit';
          this.LoadListSalesGroup()
          this.LoadCustomerList()
        }
        this.LoadData()

      } else {
        this.headerTitle = 'New'
        this.$set(this.form, 'start_date', start)
        this.$set(this.form, 'end_date', end)
        this.LoadListSalesGroup()
        this.LoadCustomerList()
      }
    },
    CustomLabel({customer_name, sector, est_employee}) {
      let comma = this.FormatCommaNumber(est_employee)
      return `${customer_name} — [${sector} — ${comma}]`
    },
    LoadListSalesGroup(){
      this.options.sales_group = []
      this.getDataSalesGroup({
        })
        .then(rsp => {
          this.$set(this.options, 'sales_group', rsp.payload)
        })
        .catch(err => {
            if(err.response.status !== 401) {
                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
            }
        })
    },
    LoadData(){
      // start loading
      this.isLoadingData = true

      this.getDataCustomerDistribution({
        id:this.$route.params.idData,
        })
        .then(rsp => {
            if(this.stDtCstmDistCount == 0) {
                this.showNotification({type:'info', message:'Data Not Found'})
            }
            this.getDataCustomerDistributionDetail({
              id:this.$route.params.idData,
            })
            .then(rsp => {
              // stop loading
              setTimeout(() => {
                  this.isLoadingData = false
              }, 500);

              if(this.stDtCstmDistDetailCount == 0) {
                  this.showNotification({type:'info', message:'Data Not Found'})
              }
              this.setDataForm()
            })
            .catch(err => {
              // stop loading
              setTimeout(() => {
                  this.isLoadingData = false
              }, 500);
              if(err.response.status !== 401) {
                  this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                  this.$router.push({name:"SFCCustomerDistribution"})
                  }
              })
        })
        .catch(err => {
            // stop loading
            setTimeout(() => {
                this.isLoadingData = false
            }, 500);
            if(err.response.status !== 401) {
                this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                this.$router.push({name:"SFCCustomerDistribution"})
            }
        })
    },
    LoadCustomerList() {
      // start loading
      this.isLoadingData = true

      this.options.customer = []
      this.getDataCustomerDistributionList({
        period_date:this.form.period
      })
      .then(rsp => {
        this.$set(this.options, 'customer', rsp.payload)
      })
      .catch(err => {
          if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
          }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })

    },
    BackHistoryButton(){
      let pageView = "SFCCustomerDistribution"
      let data = null
      if (this.BackHistory == "CustomerDistribution" ) {
          pageView = "SFCCustomerDistribution"
      }
      // this.$router.push({name:"SFCCustomerDistribution"})
      this.$router.push({ name: pageView, params: { page:'view', idData:data,  } })
    },
    ChangeSalesGroup(value){
      this.form.sales_group = value.id;
      this.form.sales_group_name = value.name;
    },
    FormatCommaNumber (param) {
        let format = param
        if (param == null || param == undefined || param == '') {
            format = null
        }
        else {
            // format = param.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            format = param.toString()
        }
        return format
    },
    setDataForm() {
      let FormData = this.stDtCstmDist
      let FormDataDetail = this.stDtCstmDistDetail
      this.form.start_date = FormData[0].start_date
      this.form.end_date = FormData[0].end_date
      this.form.sales_group_name = FormData[0].sales_group_name
      this.form.selected_sales_group = FormData[0].sales_group_name
      this.form.sales_group = FormData[0].sales_group
      this.form.name = FormData[0].name
      this.form.customerDistribution = FormDataDetail
      if (this.buttonSubmit == 'edit') {
        if (FormDataDetail !== null) {
        for (let a=0; a<=FormDataDetail.length-1;a++) {
          this.options.customer.push({
            id:FormDataDetail[a].customer,
            customer_name:FormDataDetail[a].customer_name,
            sector:FormDataDetail[a].sector,
            est_employee:FormDataDetail[a].est_employee,
          })
          }
        }
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    ChangeDistribution(value) {
      console.log(value)
      let inputValue = []
      value.forEach((value) => {
        inputValue.push(value.id)
      })
      this.form.customer_distribution = ({customer:inputValue})
    },
    Confirm(choice, index) {
      let varOk = 'danger'
      if(choice !== 'delete') {
        varOk = 'success'
      }
      let Boxtrue = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to '+choice+' ? ', {
        title: 'Please Confirm',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: varOk,
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          Boxtrue = value
          if (Boxtrue == true) {
            if (choice == 'edit') {
              this.EditCustomerDepHead()
            }
            if (choice == 'save') {
              this.SaveCustomerDepHead()
            }
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    SaveCustomerDepHead() {
      // start loading
      this.isLoadingData = true
      apiCustomerDistribution.dispatch('PostCustomerDepHead',{
          name:null,
          sales_group:this.form.sales_group,
          start_date:this.form.start_date,
          end_date:this.form.end_date,
          data_detail:this.form.customerDistribution,

      })
      .then(response => {
        if (response.data.message == "Warning") {
          this.showNotification({type:'error', message:response.data.payload})
        } else {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCCustomerDistribution"})
        }
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=>{
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
    EditCustomerDepHead() {
      // start loading
      this.isLoadingData = true
      apiCustomerDistribution.dispatch('EditCustomerDepHead',{
          name:null,
          sales_group:this.form.sales_group,
          start_date:this.form.start_date,
          end_date:this.form.end_date,
          data_detail:this.form.customerDistribution,
          id :this.$route.params.idData,
      })
      .then(response => {
          this.showNotification({type:'success', message:'Successfully Submitted'})
          this.$router.push({name:"SFCCustomerDistribution"})
      })
      .catch(err => {
          console.log(err);
          if (err.response.status === 400) {
              this.errorMessage = err.response.data.message
          }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
  },

  created() {
    this.setData()
  },

};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>