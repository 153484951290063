<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid="sm">
      <b-row align-h="center">
        <b-col cols="12" sm="auto">
          <b-card header="Form Harian Baru" header-tag="header">
            <template v-slot:header>
              <div class="text-center">
                <div class="banner-container">
                  <div class="bbi-banner"></div>
                </div>
                <h3>Form Laporan Harian</h3>
                <p>
                  Harap diisi dengan JUJUR dan sesuai kondisi yg ada demi
                  kebaikan bersama
                </p>
              </div>
            </template>

            <validation-observer ref="observer" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <b-row align-h="center">
                  <b-col sm="auto">
                    <!-- input tanggal -->
                    <validation-provider name="tanggal">
                      <b-form-group>
                        <label for="tanggal">Tanggal Laporan Hari Ini</label>
                        <b-input-group
                          class="sm-3"
                          aria-describedby="input-tanggal-feedback"
                        >
                          <b-form-input
                            id="tanggal"
                            v-model="form.tanggal_harian"
                            type="text"
                            autocomplete="off"
                            readonly
                          ></b-form-input>
                          <b-input-group-append>
                            <!-- <b-form-datepicker
                                                            v-model="form.tanggal_harian"
                                                            button-only
                                                            right
                                                            :readonly="true"
                                                            aria-controls="tanggal"
                                                            :max="max"
                                                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                            :state="getValidationState(validationContext)"
                                                        ></b-form-datepicker> -->
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                    <!-- /input tanggal -->

                    <!-- input nama karyawan -->
                    <b-form-group label="Nama Karyawan" label-for="inputNama">
                      <b-form-input
                        readonly
                        id="inputName"
                        v-model="form.nama_karyawan"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input nama karyawan -->
                  </b-col>
                  <b-col sm="auto">
                    <!-- input nik -->
                    <b-form-group
                      label="Nomor Induk Karyawan"
                      label-for="inputNik"
                    >
                      <b-form-input
                        readonly
                        id="inputNik"
                        v-model="form.nik"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input nik -->

                    <!-- input department -->
                    <b-form-group label="Department" label-for="inputDept">
                      <b-form-input
                        readonly
                        id="inputDept"
                        v-model="form.department"
                        trim
                      ></b-form-input>
                    </b-form-group>
                    <!-- /input department -->
                  </b-col>
                </b-row>
                <b-row align-h="center">
                  <b-col sm="6">
                    <!-- options kondisi kesehatan -->
                    <validation-provider
                      name="kondisi_kesehatan"
                      vid="kondisi_kesehatan"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group label="Kondisi Kesehatan ?">
                        <b-form-radio-group
                          v-model="form.kondisi_kesehatan"
                          name="kondisi_kesehatan"
                          ref="opt_kesehatan"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-kondisi_kesehatan-feedback"
                        >
                          <b-radio class="radio" value="sehat">Sehat</b-radio>
                          <b-radio value="sakit">Sakit</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-kondisi_kesehatan-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- end options kondisi kesehatan -->

                    <!-- input kondisi kesehatan -->
                    <transition-group name="fade">
                      <b-col
                        cols="11.5"
                        offset="1"
                        key="1"
                        v-if="!checkKondisiKesehatan()"
                      >
                        <validation-provider
                          name="keterangan_kondisi_kesehatan"
                          :rules="{ required: !checkKondisiKesehatan() }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label="Sakit yang dialami"
                            ref="opt_ket_kesehatan"
                          >
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-keterangan_kondisi_kesehatan-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="Demam dengan suhu tubuh > 37,2"
                                v-model="kondisi"
                              />
                              Demam dengan suhu tubuh > 37,2
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="Batuk, Pilek, Sakit Tenggorokan"
                                v-model="kondisi"
                              />
                              Batuk, Pilek, Sakit Tenggorokan
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="Hilangnya kemampuan mengecap rasa atau mencium bau"
                                v-model="kondisi"
                              />
                              Hilangnya kemampuan mengecap rasa atau mencium bau
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="Lemas / Pegal-Pegal / Tidak Bertenaga"
                                v-model="kondisi"
                              />
                              Lemas / Pegal-Pegal / Tidak Bertenaga
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="Sesak nafas ringan - berat"
                                v-model="kondisi"
                              />
                              Sesak nafas ringan - berat
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisi"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="Positif Covid-19"
                                v-model="kondisi"
                              />
                              Positif Covid-19
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiLainnya"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="lainnya"
                                v-model="kondisi"
                              />
                              Lainnya
                            </b-form-checkbox-group>
                          </b-form-group>
                        </validation-provider>
                        <!-- /input kondisi kesehatan -->

                        <!-- input kondisi lainnya -->
                        <validation-provider
                          key="1"
                          v-if="!checkLainnya()"
                          name="kondisi_lainnya"
                          :rules="{ required: !checkLainnya() }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            cols="6"
                            ref="opt_ket_kesehatan_lainnya"
                            label-for="inputKondisiLainnya"
                            aria-describedby="input-kondisi-lainnya-feedback"
                          >
                            <b-form-input
                              class="form-kondisi-lainnya"
                              name="kondisi_lainnya"
                              size="sm"
                              :disabled="checkLainnya()"
                              :state="getValidationState(validationContext)"
                              id="inputKondisiLainnya"
                              v-model="form.kondisiLainnya"
                              ref="chkboxKondisi"
                              trim
                            >
                            </b-form-input>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-kondisi-lainnya-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>

                        <!-- input jika positif covid 19 -->
                        <b-col
                          cols="11.5"
                          offset="1"
                          v-if="!checkPositifCovid19()"
                        >
                          <!-- input penyakit bawaan -->
                          <validation-provider
                            name="penyakit_bawaan"
                            vid="penyakit_bawaan"
                            :rules="{ required: !checkPositifCovid19() }"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              label="Apakah anda memiliki penyakit bawaan?"
                              description="Diabetes | Darah Tinggi | Asma | Kolestrol | Kanker | Ginjal | Paru"
                            >
                              <b-form-radio-group
                                v-model="option_positif_covid19.penyakit_bawaan"
                                name="penyakit_bawaan"
                                ref="opt_penyakit_bawaan"
                                :disabled="checkPositifCovid19()"
                                aria-describedby="input-penyakit_bawaan-feedback"
                              >
                                <b-radio class="radio" value="ya">Ya</b-radio>
                                <b-radio value="tidak">Tidak</b-radio>
                              </b-form-radio-group>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-penyakit_bawaan-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input penyakit bawaan -->

                          <!-- input tanggal lahir -->
                          <validation-provider
                            name="tanggal_lahir"
                            vid="tanggal_lahir"
                            :rules="{ required: !checkPositifCovid19() }"
                            v-slot="validationContext"
                          >
                            <b-form-group label="Tanggal Lahir">
                              <b-form class="datepicker">
                                <VueDatePicker
                                  v-model="option_positif_covid19.tanggal_lahir"
                                  class="vd__div"
                                  no-header
                                  format="DD MMM YYYY"
                                  min-date="1900-01-01"
                                  :max-date="maxDate"
                                  placeholder="Choose date of birth"
                                  name="tanggal_lahir"
                                  ref="opt_tanggal_lahir"
                                  :disabled="checkPositifCovid19()"
                                  aria-describedby="input-tanggal_lahir-feedback"
                                />
                              </b-form>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-tanggal_lahir-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input tanggal lahir -->

                          <!-- input keluhan sebelum karantina -->
                          <validation-provider
                            key="1"
                            name="keluhan_karantina"
                            vid="keluhan_karantina"
                            :rules="{ required: !checkPositifCovid19() }"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              cols="6"
                              ref="opt_keluhan_karantina"
                              label="Keluhan Sebelum Karantina"
                              label-for="inputKeluhanKarantina"
                              aria-describedby="input-keluhan_karantina-feedback"
                            >
                              <b-form-input
                                class="form-keluhan_karantina"
                                name="keluhan_karantina"
                                size="sm"
                                :disabled="checkPositifCovid19()"
                                :state="getValidationState(validationContext)"
                                id="inputKeluhanKarantina"
                                v-model="
                                  option_positif_covid19.keluhan_karantina
                                "
                                trim
                              >
                              </b-form-input>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-keluhan_karantina-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input keluhan sebelum karantina -->

                          <!-- input nomor WA aktif -->
                          <validation-provider
                            key="2"
                            name="nomor_wa"
                            vid="nomor_wa"
                            :rules="{ required: !checkPositifCovid19() }"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              cols="6"
                              ref="opt_nomor_wa"
                              label="Nomor Whatsapp Aktif"
                              label-for="inputNomorWA"
                              aria-describedby="input-nomor_wa-feedback"
                              description="Isi Hanya Dengan Menggunakan Angka Saja"
                            >
                              <b-form-input
                                class="form-nomor_wa"
                                name="nomor_wa"
                                size="sm"
                                type="number"
                                :disabled="checkPositifCovid19()"
                                :state="getValidationState(validationContext)"
                                id="inputNomorWA"
                                v-model="option_positif_covid19.nomor_wa"
                                trim
                              >
                              </b-form-input>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-nomor_wa-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input nomor WA aktif -->

                          <!-- input nomor WA/HP Keluarga -->
                          <validation-provider
                            key="3"
                            name="nomor_wa_keluarga"
                            vid="nomor_wa_keluarga"
                            :rules="{ required: !checkPositifCovid19() }"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              cols="6"
                              ref="opt_nomor_wa_keluarga"
                              label="Nomor Whatsapp/Handphone Anggota Keluarga yang Dapat Dihubungi"
                              label-for="inputNomorWAKeluarga"
                              aria-describedby="input-nomor_wa_keluarga-feedback"
                              description="Isi Hanya Dengan Menggunakan Angka Saja"
                            >
                              <b-form-input
                                class="form-nomor_wa_keluarga"
                                name="nomor_wa_keluarga"
                                size="sm"
                                type="number"
                                :disabled="checkPositifCovid19()"
                                :state="getValidationState(validationContext)"
                                id="inputNomorWAKeluarga"
                                v-model="
                                  option_positif_covid19.nomor_wa_keluarga
                                "
                                trim
                              >
                              </b-form-input>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-nomor_wa_keluarga-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input nomor WA/HP Keluarga -->

                          <!-- input penyebab terpapar covid 19 -->
                          <validation-provider
                            key="4"
                            name="penyebab_positif_covid"
                            vid="penyebab_positif_covid"
                            :rules="{ required: !checkPositifCovid19() }"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              cols="6"
                              ref="opt_penyebab_positif_covid"
                              label="Penyebab Terpapar Covid 19"
                              label-for="inputPenyebabCovid"
                              aria-describedby="input-penyebab_positif_covid-feedback"
                            >
                              <b-form-input
                                class="form-penyebab_positif_covid"
                                name="penyebab_positif_covid"
                                size="sm"
                                :disabled="checkPositifCovid19()"
                                :state="getValidationState(validationContext)"
                                id="inputPenyebabCovid"
                                v-model="
                                  option_positif_covid19.penyebab_positif_covid
                                "
                                trim
                              >
                              </b-form-input>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-penyebab_positif_covid-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input penyebab terpapar covid 19 -->

                          <!-- input isolasi hari ke berapa -->
                          <validation-provider
                            key="5"
                            name="isolasi_hari_ke"
                            vid="isolasi_hari_ke"
                            :rules="{ required: !checkPositifCovid19(), regex: /^\d*?\d*$/ }"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              cols="6"
                              ref="opt_isolasi_hari_ke"
                              label="Isolasi Hari Ke-"
                              label-for="inputIsolasiHariKe"
                              aria-describedby="input-isolasi_hari_ke-feedback"
                            >
                              <b-form-input
                                class="form-isolasi_hari_ke"
                                name="isolasi_hari_ke"
                                size="sm"
                                type="number"
                                :disabled="checkPositifCovid19()"
                                :state="getValidationState(validationContext)"
                                id="inputIsolasiHariKe"
                                v-model="option_positif_covid19.isolasi_hari_ke"
                                trim
                              >
                              </b-form-input>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-isolasi_hari_ke-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input isolasi hari ke berapa -->

                          <!-- input option positif lainnya -->
                          <validation-provider
                            key="6"
                            name="opt_positif_lainnya"
                            vid="opt_positif_lainnya"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              cols="6"
                              ref="opt_opt_positif_lainnya"
                              label="Lainnya"
                              label-for="inputOptPositifLainnya"
                              aria-describedby="input-opt_positif_lainnya-feedback"
                              description="Jika Tidak Ada Kosongkan"
                            >
                              <b-form-input
                                class="form-opt_positif_lainnya"
                                name="opt_positif_lainnya"
                                size="sm"
                                :disabled="checkPositifCovid19()"
                                :state="getValidationState(validationContext)"
                                id="inputOptPositifLainnya"
                                v-model="
                                  option_positif_covid19.opt_positif_lainnya
                                "
                                trim
                              >
                              </b-form-input>
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                                id="input-opt_positif_lainnya-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                          <!-- /input option positif lainnya -->
                        </b-col>
                        <!-- /input jika positif covid 19 -->
                      </b-col>
                    </transition-group>
                    <!-- /input kondisi lainnya -->

                    <!-- input keluarga sakit -->
                    <validation-provider
                      name="keluarga_sakit"
                      vid="keluarga_sakit"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Apakah ada keluarga atau orang yang tinggal dalam satu rumah atau satu tempat tinggal dalam kondisi sakit ?"
                      >
                        <b-form-radio-group
                          v-model="keluarga_sakit"
                          name="keluarga_sakit"
                          ref="opt_keluarga_sakit"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-keluarga_sakit-feedback"
                          :click="countKeluargaSakit()"
                        >
                          <b-radio class="radio" value="ya">Ya</b-radio>
                          <b-radio value="tidak">Tidak</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-keluarga_sakit-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input keluarga sakit -->

                    <transition-group name="fade">
                      <b-col
                        cols="11.5"
                        offset="1"
                        key="1"
                        v-if="!checkKeluargaSakit()"
                      >
                        <!-- input hubungan keluarga sakit -->
                        <validation-provider
                          name="kekerabatan_keluarga_sakit"
                          :rules="{ required: !checkKeluargaSakit() }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label="Hubungan kekerabatannya dengan anda ?"
                            ref="opt_kerabat_keluarga"
                          >
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxHubunganKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="keluarga inti"
                                v-model="kondisi_hubungan_keluarga_sakit"
                              />
                              Keluarga Inti (Suami, Istri / Anak)
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxHubunganKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="keluarga serumah"
                                v-model="kondisi_hubungan_keluarga_sakit"
                              />
                              Keluarga Serumah (Ayah, Ibu, Kakak, Adik Kandung,
                              Dsb)
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxHubunganKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="teman / rekan "
                                v-model="kondisi_hubungan_keluarga_sakit"
                              />
                              Teman / Rekan
                            </b-form-checkbox-group>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-kondisi-keluarga-sakit-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                        <!-- /input hubungan keluarga sakit -->
                        <!-- input kondisi keluarga sakit -->
                        <validation-provider
                          name="kondisi_keluarga_sakit"
                          :rules="{ required: !checkKeluargaSakit() }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label="Sakit yang dialami"
                            ref="opt_kondisi_keluarga"
                          >
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="demam dengan suhu tubuh > 37,2"
                                v-model="kondisi_keluarga"
                              />
                              Demam dengan suhu tubuh > 37,2
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="batuk, pilek, sakit tenggorokan"
                                v-model="kondisi_keluarga"
                              />
                              Batuk, Pilek, Sakit Tenggorokan
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="Hilangnya kemampuan mengecap rasa atau mencium bau"
                                v-model="kondisi_keluarga"
                              />
                              Hilangnya kemampuan mengecap rasa atau mencium bau
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="Lemas / Pegal-Pegal / Tidak Bertenaga"
                                v-model="kondisi_keluarga"
                              />
                              Lemas / Pegal-Pegal / Tidak Bertenaga
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="Sesak nafas ringan - berat"
                                v-model="kondisi_keluarga"
                              />
                              Sesak nafas ringan - berat
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                @change="check($event)"
                                value="Keluarga Positif Covid-19"
                                v-model="kondisi_keluarga"
                              />
                              Positif Covid-19
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKondisiKeluarga"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="lainnya"
                                v-model="kondisi_keluarga"
                              />
                              Lainnya
                            </b-form-checkbox-group>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-kondisi-keluarga-sakit-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                        <!-- /input kondisi keluarga sakit -->

                        <!-- input kondisi lainnya keluarga sakit -->
                        <validation-provider
                          key="1"
                          v-if="!checkKondisiKeluargaLainnya()"
                          name="kondisi_keluarga_sakit_lainnya"
                          :rules="{ required: !checkKondisiKeluargaLainnya() }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            cols="6"
                            ref="opt_kondisi_keluarga_lainnya"
                            label-for="inputKondisiKeluargaLainnya"
                            aria-describedby="input-keluarga-sakit-lainnya-feedback"
                          >
                            <b-form-input
                              class="form-keluarga-sakit-lainnya"
                              name="kondisi_keluarga_sakit_lainnya"
                              size="sm"
                              ref="opt_kondisi_keluarga_lainnya_input"
                              :disabled="checkKondisiKeluargaLainnya()"
                              :state="getValidationState(validationContext)"
                              id="inputKondisiKeluargaLainnya"
                              v-model="form.kondisi_keluarga_sakit_lainnya"
                              trim
                            >
                            </b-form-input>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-keluarga-sakit-lainnya-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                        <!-- /input kondisi lainnya keluarga sakit -->
                      </b-col>
                    </transition-group>

                    <!-- input bepergian -->
                    <validation-provider
                      name="bepergian"
                      vid="pergi"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group label="Apakah Anda Kemarin Bepergian">
                        <b-form-radio-group
                          v-model="pergi"
                          name="bepergian"
                          ref="opt_bepergian"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-bepergian-feedback"
                        >
                          <b-radio class="radio" value="ya">Ya</b-radio>
                          <b-radio value="tidak">Tidak</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-bepergian-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input bepergian -->

                    <b-col cols="11.5" offset="1" v-if="!checkTidakBepergian()">
                      <!-- input hari libur/off -->
                      <validation-provider
                        name="hari_libur_off"
                        vid="hari_libur_off"
                        :rules="{ required: !checkTidakBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Hari Libur/Off">
                          <b-form-radio-group
                            v-model="option_tidak_pergi.hari_libur_off"
                            name="hari_libur_off"
                            ref="opt_hari_libur_off"
                            :disabled="checkTidakBepergian()"
                            aria-describedby="input-hari_libur_off-feedback"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-hari_libur_off-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input hari libur/off -->

                      <!-- input isolasi mandiri -->
                      <validation-provider
                        name="isolasi_mandiri"
                        vid="isolasi_mandiri"
                        :rules="{ required: !checkTidakBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Isolasi Mandiri (Kontak Erat)">
                          <b-form-radio-group
                            v-model="option_tidak_pergi.isolasi_mandiri"
                            name="isolasi_mandiri"
                            ref="opt_isolasi_mandiri"
                            :disabled="checkTidakBepergian()"
                            aria-describedby="input-isolasi_mandiri-feedback"
                            :click="countIsoman()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-isolasi_mandiri-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input isolasi mandiri -->

                      <!-- input wfh -->
                      <validation-provider
                        name="work_from_home"
                        vid="work_from_home"
                        :rules="{ required: !checkTidakBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="WFH (Work From Home)">
                          <b-form-radio-group
                            v-model="option_tidak_pergi.work_from_home"
                            name="work_from_home"
                            ref="opt_work_from_home"
                            :disabled="checkTidakBepergian()"
                            aria-describedby="input-work_from_home-feedback"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-work_from_home-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input wfh -->

                      <!-- input cuti/ijin -->
                      <validation-provider
                        name="cuti_izin"
                        vid="cuti_izin"
                        :rules="{ required: !checkTidakBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Cuti/Izin">
                          <b-form-radio-group
                            v-model="option_tidak_pergi.cuti_izin"
                            name="cuti_izin"
                            ref="opt_cuti_izin"
                            :disabled="checkTidakBepergian()"
                            aria-describedby="input-cuti_izin-feedback"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-cuti_izin-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input cuti/ijin -->

                      <!-- input alasan lainnya -->
                      <validation-provider
                        name="tidak_pergi_lainnya"
                        :rules="{ required: !checkTidakPergiLainnya() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Lainnya"
                          ref="opt_tidak_pergi_lainnya"
                          cols="6"
                          aria-describedby="input-tidak_pergi_lainnya-feedback"
                          v-if="!checkTidakPergiLainnya()"
                        >
                          <b-form-input
                            class="tidak_pergi_lainnya"
                            name="tidak_pergi_lainnya"
                            size="sm"
                            :state="getValidationState(validationContext)"
                            id="input_tidak_pergi_lainnya"
                            v-model="option_tidak_pergi.tidak_pergi_lainnya"
                            ref="chkboxtidak_pergi_lainnya"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-tidak_pergi_lainnya-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input alasan lainnya -->
                    </b-col>

                    <b-col cols="11.5" offset="1" v-if="!checkBepergian()">
                      <!-- input tempat kerja -->
                      <validation-provider
                        name="tempat_kerja"
                        vid="tempat_kerja"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Tempat Kerja">
                          <b-form-radio-group
                            v-model="tempat_kerja"
                            name="tempat_kerja"
                            ref="opt_tempat_kerja"
                            :disabled="checkBepergian()"
                            aria-describedby="input-tempat_kerja-feedback"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-tempat_kerja-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input tempat kerja -->

                      <!-- input lokasi kerja -->
                      <validation-provider
                        name="lokasi_kerja"
                        :rules="{ required: !checkTempatKerja() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Lokasi Kerja"
                          ref="opt_lokasi_kerja"
                          v-if="!checkTempatKerja()"
                        >
                          <b-form-checkbox-group
                            class="padding-top"
                            v-for="(siteName, index) in siteList"
                            :key="index"
                          >
                            <input
                              ref="chkboxLokasiKerja"
                              :state="getValidationState(validationContext)"
                              type="checkbox"
                              :value="siteName.site_name"
                              v-model="lokasiKerja"
                            />
                            {{ siteName.site_name }}
                          </b-form-checkbox-group>
                        </b-form-group>
                      </validation-provider>

                      <!-- input transportasi -->
                      <validation-provider
                        name="transportasi"
                        vid="transportasi"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Menggunakan Transportasi Umum">
                          <b-form-radio-group
                            v-model="transportasi"
                            name="transportasi"
                            ref="opt_transportasi"
                            :disabled="checkBepergian()"
                            aria-describedby="input-transportasi-feedback"
                            :click="countTransportasi()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-transportasi-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input transportasi -->

                      <!-- input layanan kesehatan -->
                      <validation-provider
                        name="layanan_kesehatan"
                        vid="layanan_kesehatan"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Layanan Kesehatan (Klinik / Puskesmas / RS)"
                        >
                          <b-form-radio-group
                            v-model="layanan_kesehatan"
                            name="layanan_kesehatan"
                            ref="opt_layanan_kesehatan"
                            :disabled="checkBepergian()"
                            aria-describedby="input-layanan_kesehatan-feedback"
                            :click="countLayananKes()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-layanan_kesehatan-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input layanan kesehatan -->

                      <!-- input nama layanan kesehatan -->
                      <validation-provider
                        name="nama_layanan_kesehatan"
                        :rules="{ required: !checkLayananKes() }"
                        v-if="!checkLayananKes()"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          cols="6"
                          ref="opt_layanan_kesehatan_lainnnya"
                          label="Nama Layanan"
                          label-for="inputNamaLayanan"
                          aria-describedby="input-nama_layanan_kesehatan-feedback"
                        >
                          <b-form-input
                            name="nama_layanan_kesehatan"
                            size="sm"
                            ref="opt_layanan_kesehatan_lainnnya_input"
                            :disabled="checkLayananKes()"
                            :state="getValidationState(validationContext)"
                            id="inputTempatTujuan"
                            v-model="form.nama_layanan_kesehatan"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-kondisi-lainnya-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input nama layanan kesehatan -->

                      <!-- input berbelanja -->
                      <validation-provider
                        name="berbelanja"
                        vid="berbelanja"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Berbelanja Ke Pasar">
                          <b-form-radio-group
                            v-model="berbelanja"
                            name="berbelanja"
                            ref="opt_berbelanja"
                            :disabled="checkBepergian()"
                            aria-describedby="input-berbelanja-feedback"
                            :click="countBerbelanja()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>

                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-berbelanja-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input berbelanja -->

                      <!-- input lokasi berbelanja -->
                      <validation-provider
                        name="lokasi_berbelanja"
                        :rules="{ required: !checkBelanja() }"
                        v-if="!checkBelanja()"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          cols="6"
                          ref="opt_lokasi_berbelanja"
                          label="Lokasi Berbelanja"
                          label-for="inputLokasiBerbelanja"
                          aria-describedby="input-lokasi_berbelanja-feedback"
                        >
                          <b-form-input
                            name="lokasi_berbelanja"
                            size="sm"
                            ref="opt_lokasi_berbelanja_input"
                            :disabled="checkBelanja()"
                            :state="getValidationState(validationContext)"
                            id="inputLokasiBerbelanja"
                            v-model="form.lokasi_berbelanja"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-kondisi-lainnya-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input lokasi berbelanja -->

                      <!-- input warung tradisional -->
                      <validation-provider
                        name="warung_tradisional"
                        vid="warung_tradisional"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Swalayan/Mall dengan durasi lebih dari 30 menit"
                        >
                          <b-form-radio-group
                            v-model="warung_tradisional"
                            name="warung_tradisional"
                            ref="opt_warung_tradisional"
                            :disabled="checkBepergian()"
                            aria-describedby="input-warung_tradisional-feedback"
                            :click="countWarungTradisional()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-warung_tradisional-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input warung tradisional -->

                      <!-- input kegiatan keagamaan -->
                      <validation-provider
                        name="kegiatan_keagamaan"
                        vid="kegiatan_keagamaan"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Kegiatan Keagamaan / Pengajian / Ibadah / Perkumpulan Doa"
                        >
                          <b-form-radio-group
                            v-model="kegiatan_keagamaan"
                            name="kegiatan_keagamaan"
                            ref="opt_kegiatan_keagamaan"
                            :disabled="checkBepergian()"
                            aria-describedby="input-kegiatan_keagamaan-feedback"
                            :click="countKegiatanKeagamaan()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>

                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-kegiatan_keagamaan-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input kegiatan keagamaan -->

                      <!-- input pesta -->
                      <validation-provider
                        name="pesta"
                        vid="pesta"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Pesta / Halal Bihalal / Pernikahan / Arisan / Melayat / Menengok Orang Sakit"
                        >
                          <b-form-radio-group
                            v-model="pesta"
                            name="pesta"
                            ref="opt_pesta"
                            :disabled="checkBepergian()"
                            aria-describedby="input-pesta-feedback"
                            :click="countPesta()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-pesta-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input pesta -->

                      <!-- input club olahraga -->
                      <validation-provider
                        name="club_olahraga"
                        vid="club_olahraga"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Club olahraga atau aktivitas hobby yang melibatkan kumpulan orang "
                        >
                          <b-form-radio-group
                            v-model="club_olahraga"
                            name="club_olahraga"
                            ref="opt_club_olahraga"
                            :disabled="checkBepergian()"
                            aria-describedby="input-club_olahraga-feedback"
                            :click="countClubOlahraga()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-club_olahraga-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input club olahraga -->

                      <!-- input makan direstoran -->
                      <validation-provider
                        name="makan_direstoran"
                        vid="makan_direstoran"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Makan Di Restoran / Warung Makan (Tidak Di Bawa Pulang)"
                        >
                          <b-form-radio-group
                            v-model="makan_direstoran"
                            name="makan_direstoran"
                            ref="opt_makan_direstoran"
                            :disabled="checkBepergian()"
                            aria-describedby="input-makan_direstoran-feedback"
                            :click="countMakanDirestoran()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-makan_direstoran-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input makan direstoran -->

                      <!-- input perusahaan lain -->
                      <validation-provider
                        name="perusahaan_lain"
                        vid="perusahaan_lain"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Mengunjungi Perusahaan Lain Diluar BBI Group Untuk Keperluan Rapat Lebih Dari 30 Menit"
                        >
                          <b-form-radio-group
                            v-model="perusahaan_lain"
                            name="perusahaan_lain"
                            ref="opt_perusahaan_lain"
                            :disabled="checkBepergian()"
                            aria-describedby="input-perusahaan_lain-feedback"
                            :click="countPerusahaanLain()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>

                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-perusahaan_lain-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input perusahaan lain -->

                      <!-- input tempat hiburan -->
                      <validation-provider
                        name="tempat_hiburan"
                        vid="tempat_hiburan"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group label="Tempat Rekreasi / Tempat Hiburan">
                          <b-form-radio-group
                            v-model="tempat_hiburan"
                            name="tempat_hiburan"
                            ref="opt_tempat_hiburan"
                            :disabled="checkBepergian()"
                            aria-describedby="input-tempat_hiburan-feedback"
                            :click="countTempatHiburan()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-tempat_hiburan-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input tempat hiburan -->

                      <!-- input perjalanan luar kota -->
                      <validation-provider
                        name="perjalanan_luar_kota"
                        vid="perjalanan_luar_kota"
                        :rules="{ required: !checkBepergian() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Apakah kemarin anda melakukan perjalanan ke luar kota untuk kepentingan pribadi ?"
                        >
                          <b-form-radio-group
                            v-model="perjalanan_luar_kota"
                            ref="opt_perjalanan_luar_kota"
                            name="perjalanan_luar_kota"
                            :disabled="checkBepergian()"
                            aria-describedby="input-perjalanan_luar_kota-feedback"
                            :click="countKeluarKota()"
                          >
                            <b-radio class="radio" value="ya">Ya</b-radio>
                            <b-radio value="tidak">Tidak</b-radio>
                          </b-form-radio-group>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-perjalanan_luar_kota-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input perjalanan luar kota -->

                      <!-- input keperluan tujuan -->
                      <validation-provider
                        name="keperluan_tujuan"
                        :rules="{ required: !checkKeperluan() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          cols="6"
                          ref="opt_keperluan_tujuan"
                          v-if="!checkKeperluan()"
                          label="Keperluan Dan Tujuan"
                          label-for="inputkeperluan_tujuan"
                          aria-describedby="input-keperluan_tujuan-feedback"
                        >
                          <b-form-input
                            name="keperluan_tujuan"
                            size="sm"
                            ref="opt_keperluan_tujuan_input"
                            :disabled="checkKeperluan()"
                            :state="getValidationState(validationContext)"
                            id="inputTempatTujuan"
                            v-model="form.keperluan_tujuan"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-kondisi-lainnya-feedback"
                            >{{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- /input keperluan tujuan -->

                      <!-- input bepergian lainnya -->
                      <validation-provider
                        name="bepergian_lainnya"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Tujuan / Tempat Lainnya"
                          ref="opt_bepergian_lainnya"
                        >
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-bepergian_lainnya-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                          <b-form-checkbox-group class="padding-top">
                            <input
                              ref="chkboxBepergianLainnya"
                              :state="getValidationState(validationContext)"
                              :disabled="checkBepergian()"
                              type="checkbox"
                              value="lainnya"
                              v-model="bepergianLainnya"
                              id="input_bepergian_lainnya"
                            />
                            Tujuan / Tempat Lainnya
                          </b-form-checkbox-group>
                        </b-form-group>
                      </validation-provider>
                      <!-- <validation-provider
                        name="bepergian_lainnya"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Lainnya"
                          ref="opt_bepergian_lainnya"
                          cols="6"
                          label-for="bepergian_lainnya"
                          aria-describedby="input-bepergian_lainnya-feedback"
                          description="Jika Tidak Ada Kosongkan"
                        >
                          <b-form-input
                            name="bepergian_lainnya"
                            size="sm"
                            ref="opt_bepergian_lainnya_input"
                            :disabled="checkBepergian()"
                            :state="getValidationState(validationContext)"
                            id="input_bepergian_lainnya"
                            v-model="bepergian_lainnya"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-bepergian_lainnya-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider> -->
                      <!-- /input bepergian lainnya -->

                      <!-- input keperluan bertamu lainnya -->
                      <validation-provider
                        key="1"
                        v-if="!checkBepergianLainnya()"
                        name="bepergian_lainnya_text"
                        :rules="{ required: !checkBepergianLainnya() }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          cols="6"
                          ref="opt_bepergian_lainnya_text"
                          label-for="inputbepergian_lainnya_text"
                          aria-describedby="input-bepergian_lainnya_text-feedback"
                        >
                          <b-form-input
                            class="form-bepergian_lainnya_text"
                            name="bepergian_lainnya_text"
                            size="sm"
                            :disabled="checkBepergianLainnya()"
                            :state="getValidationState(validationContext)"
                            id="inputbepergian_lainnya_text"
                            v-model="bepergian_lainnya"
                            ref="chkboxbepergian_lainnya_text"
                            trim
                          >
                          </b-form-input>
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                            id="input-kepBertamu-lainnya-feedback"
                            >{{
                              validationContext.errors[0]
                            }}</b-form-invalid-feedback
                          >
                        </b-form-group>
                      </validation-provider>
                      <!-- /input keperluan bertamu lainnya -->
                    </b-col>

                    <!-- input anggota keluarga bepergian -->
                    <validation-provider
                      name="keluarga_bepergian"
                      vid="keluarga_bepergian"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Apakah kemarin anggota keluarga dalam 1 rumah bepergian ?"
                      >
                        <b-form-radio-group
                          v-model="keluarga_bepergian"
                          name="keluarga_bepergian"
                          ref="opt_keluarga_bepergian"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-keluarga_bepergian-feedback"
                          :click="countKeluargaBepergian()"
                        >
                          <b-radio class="radio" value="ya">Ya</b-radio>
                          <b-radio value="tidak">Tidak</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-keluarga_bepergian-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /nput anggota keluarga bepergian -->

                    <!-- input tamu -->
                    <validation-provider
                      name="tamu"
                      vid="tamu"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Apakah kemarin ada tamu/ keluarga yang tidak tinggal  1 rumah  yang datang berkunjung lebih dari 30 menit ?"
                      >
                        <b-form-radio-group
                          v-model="tamu"
                          name="tamu"
                          ref="opt_tamu"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-tamu-feedback"
                        >
                          <b-radio class="radio" value="ya">Ya</b-radio>
                          <b-radio value="tidak">Tidak</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-tamu-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input tamu -->

                    <!-- input keperluan bertamu -->
                    <transition-group name="fade">
                      <b-col
                        cols="11.5"
                        offset="1"
                        key="1"
                        v-if="!checkKeperluanBertamu()"
                      >
                        <validation-provider
                          name="keperluan_bertamu"
                          :rules="{ required: !checkKeperluanBertamu() }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            label="Keperluan Bertamu"
                            ref="opt_kep_bertamu"
                          >
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-keperluan_bertamu-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKeperluanTamu"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="Acara Keagamaan/Pengajian/Ibadah/Perkumpulan Do'a/Serupa?"
                                v-model="keperluanBertamu"
                              />
                              Acara Keagamaan/Pengajian/Ibadah/Perkumpulan
                              Do'a/Serupa?
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKeperluanTamu"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="Acara Pesta/Halal Bihalal/Pernikahan/Arisan/Acara Kedukaan/Demo/Serupa?"
                                v-model="keperluanBertamu"
                              />
                              Acara Pesta/Halal Bihalal/Pernikahan/Arisan/Acara
                              Kedukaan/Demo/Serupa?
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKeperluanTamu"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="Kunjungan Biasa"
                                v-model="keperluanBertamu"
                              />
                              Kunjungan Biasa
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKeperluanTamu"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="Perbaikan (Renovasi/Perbaikan Rumah) atau Bersih-Bersih Rumah Menggunakan Jasa Pihak Luar (ART Harian/Cuci AC, Dll)"
                                v-model="keperluanBertamu"
                              />
                              Perbaikan (Renovasi/Perbaikan Rumah) atau
                              Bersih-Bersih Rumah Menggunakan Jasa Pihak Luar
                              (ART Harian/Cuci AC, Dll)
                            </b-form-checkbox-group>
                            <b-form-checkbox-group class="padding-top">
                              <input
                                ref="chkboxKeperluanTamuLainnya"
                                :state="getValidationState(validationContext)"
                                type="checkbox"
                                value="lainnya"
                                v-model="keperluanBertamu"
                              />
                              Lainnya
                            </b-form-checkbox-group>
                          </b-form-group>
                        </validation-provider>

                        <!-- input keperluan bertamu lainnya -->
                        <validation-provider
                          key="1"
                          v-if="!checkKeperluanBertamuLainnya()"
                          name="kondisi_lainnya"
                          :rules="{ required: !checkKeperluanBertamuLainnya() }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            cols="6"
                            ref="opt_kep_bertamu_lainnya"
                            label-for="inputKeperluanBertamuLainnya"
                            aria-describedby="input-kepBertamu-lainnya-feedback"
                          >
                            <b-form-input
                              class="form-keperluanBertamu-lainnya"
                              name="keperluanBertamu_lainnya"
                              size="sm"
                              :disabled="checkKeperluanBertamuLainnya()"
                              :state="getValidationState(validationContext)"
                              id="inputKepBertamuLainnya"
                              v-model="keperluanBertamuLainnya"
                              ref="chkboxKeperluanTamu"
                              trim
                            >
                            </b-form-input>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                              id="input-kepBertamu-lainnya-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                        <!-- /input keperluan bertamu lainnya -->
                      </b-col>
                    </transition-group>
                    <!-- /input keperluan bertamu -->

                    <!-- input covid_19 -->
                    <validation-provider
                      name="covid_19"
                      vid="covid_19"
                      :rules="{ required: true }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        description="Kontak erat: berjabat tangan, berbicara, berada dalam seruangan dengan jarak < 1 meter"
                        label="Apakah anda pernah melakukan kontak erat dengan keluarga / kerabat / orang yang memiliki Gejala/ Suspek Covid-19 atau terkonfirmasi Positif Covid-19 dalam 14 hari terakhir ?"
                      >
                        <b-form-radio-group
                          v-model="covid_19"
                          name="covid_19"
                          ref="opt_covid_19"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-covid_19-feedback"
                          :click="countCovid()"
                        >
                          <b-radio class="radio" value="ya">Ya</b-radio>
                          <b-radio value="tidak">Tidak</b-radio>
                        </b-form-radio-group>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-covid_19-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input covid_19 -->
                  </b-col>
                  <b-col cols="12" sm="7">
                    <!-- input checkbox jawaban -->
                    <validation-provider
                      name="checkbox_jawaban"
                      :rules="{ required: { allowFalse: false } }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        label="Saya bertanggung jawab terhadap setiap jawaban yang saya berikan pada form ini untuk dapat dipergunakan sesuai dengan kebutuhannya."
                      >
                        <b-form-checkbox
                          v-model="form.checkbox_jawaban"
                          name="checkbox_jawaban"
                          ref="opt_jawab"
                          value="ya"
                          stacked
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-checkbox-jawaban-feedback"
                        >
                          Ya
                        </b-form-checkbox>
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                          id="input-checkbox-jawaban-feedback"
                          >{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback
                        >
                      </b-form-group>
                    </validation-provider>
                    <!-- /input checkbox jawaban -->

                    <b-button
                      block
                      @click="FocusWarning()"
                      type="submit"
                      variant="primary"
                      >Submit</b-button
                    >
                  </b-col>
                </b-row>
              </form>
            </validation-observer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import apiDailyV2 from "@/store/health-form/health-daily.js";
import { extend } from "vee-validate";
import Vue from "vue";
import loginStore from "@/store/login.js";
// import vue loading
import Loading from "vue-loading-overlay";
// Import vue loading stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
// Import api-method fbPOST
import { fnGET, fnPOST } from "../../../../api/api-methods";
// Import api-path HR Daily Health V2 dan Site List
import { APIPATH_HR, APIPATH_BBIAPPS } from "../../../../api/api-paths";
import vSelect from "vue-select";
// import vue sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// Init plugin
Vue.use(Loading);
Vue.use(VueSweetalert2);

export default {
  name: "HealthDaily",
  components: {
    vSelect,
  },
  data() {
    const now = new Date();
    return {
      formName: "Form Kesehatan Harian",
      errorMessage: null,
      form: {
        nama_karyawan: null,
        nik: null,
        department: null,
        tanggal_harian: null,
        suhu: null,
        kondisiLainnya: null,
        kondisi_kesehatan: null,
        bepergian: null,
        tempat_tujuan: " ",
        checkbox_jawaban: null,
        lokasi: null,
        keperluan_tujuan: null,
        nama_tamu: null,
        kondisi_keluarga_sakit_lainnya: null,
        kondisi_keluarga_sakit: null,
        lokasi_berbelanja: null,
        nama_layanan_kesehatan: null,
      },
      kondisi_hubungan_keluarga_sakit: [],
      hubungan_keluarga_sakit: "",

      // Count Total
      total_count: 0,

      // Count Kesehatan
      suhu_count: 0,
      sesak_count: 0,
      batuk_count: 0,
      kondisi_keluarga_count: 0,
      hilang_rasa_count: 0,
      lemas_count: 0,

      // Count Bepergian
      transportasi_count: 0,
      layanan_kesehatan_count: 0,
      berbelanja_count: 0,
      warung_tradisional_count: 0,
      kegiatan_keagamaan_count: 0,
      pesta_count: 0,
      club_olahraga_count: 0,
      makan_direstoran_count: 0,
      lainnya_count: 0,
      perusahaan_lain_count: 0,
      tempat_hiburan_count: 0,
      luar_kota_count: 0,

      // Count tamu dan keluarga
      keluarga_sakit_count: 0,
      tamu_count: 0,

      // Count Terpapar 14 Covid
      covid_count: 0,

      profil_resiko: null,
      messages: null,
      isSehat: null,
      // max: now,
      kondisi: [],
      kondisi_keluarga: [],
      pergi: null,
      posts: {},
      suhuRule: null,
      transportasi: "",
      tempat_kerja: "",
      layanan_kesehatan: "",
      berbelanja: "",
      warung_tradisional: "",
      kegiatan_keagamaan: "",
      pesta: "",
      club_olahraga: "",
      makan_direstoran: "",
      perusahaan_lain: "",
      tempat_hiburan: "",
      keluarga_sakit: null,
      perjalanan_luar_kota: "",
      tamu: null,
      covid_19: null,

      options_kondisi: [
        {
          id: 1,
          text: "Batuk, Pilek, Sakit Tengorokan",
          value: "batuk, pilek, sakit tenggorokan",
        },
        {
          id: 2,
          text: "Hilangnya kemampuan mengecap rasa atau mencium bau",
          value: "Hilangnya kemampuan mengecap rasa atau mencium bau",
        },
        {
          id: 3,
          text: "Lemas / Pegal-Pegal / Tidak Bertenaga",
          value: "lemas / pegal-pegal / tidak bertenaga",
        },
        { id: 4, text: "Sesak Nafas", value: "sesak nafas" },
        { id: 5, text: "Lainnya", value: "lainnya" },
        { id: 6, text: "Sehat", value: "sehat" },
      ],

      options_bepergian: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],

      options_suhu: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_tempat_kerja: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_transportasi: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_layanan_kesehatan: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_berbelanja: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_warung_tradisional: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_kegiatan_keagamaan: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_pesta: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_club_olahraga: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_makan_direstoran: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_perusahaan_lain: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_tempat_hiburan: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_keluarga_sakit: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_tamu: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_perjalanan_luar_kota: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_covid_19: [
        { text: "Ya", value: "ya" },
        { text: "Tidak", value: "tidak" },
      ],
      options_kondisi_kesehatan: [
        { text: "Sehat", value: "sehat" },
        { text: "Sakit", value: "sakit" },
      ],

      selectedSite: null,
      siteList: [],
      lokasiKerja: [],
      keperluanBertamu: [],
      bepergianLainnya: [],
      keperluanBertamuLainnya: null,
      keperluan_bertamu: null,
      keperluan_bertamu_count: 0,
      bepergian_lainnya: null,
      keluarga_bepergian_count: 0,
      keluarga_bepergian: null,

      positif_covid: "",
      positif_covid_count: 0,

      positif_covid_keluarga: "",
      positif_covid_count_keluarga: 0,

      option_tidak_pergi: {
        hari_libur_off: "",
        isolasi_mandiri: "",
        work_from_home: "",
        cuti_izin: "",
        tidak_pergi_lainnya: null,
        // count tidak pergi
        hari_libur_off_count: 0,
        isolasi_mandiri_count: 0,
        work_from_home_count: 0,
        cuti_izin_count: 0,
      },

      option_positif_covid19: {
        penyakit_bawaan: "",
        penyakit_bawaan_count: 0,
        tanggal_lahir: null,
        keluhan_karantina: "",
        nomor_wa: "",
        nomor_wa_keluarga: "",
        penyebab_positif_covid: "",
        isolasi_hari_ke: 0,
        opt_positif_lainnya: "",
        date: new Date(),
        currentDate: new Date(),
        tgl_positif_covid: null,
      },

      data_riwayat_covid: [],
      data_riwayat_covid_keluarga_karyawan: [],
      user_personal_data: [],
    };
  },
  computed: {
    ...mapGetters("session", [
      "userData",
      "localDate",
      "serverDate",
      "businessUnit",
    ]),
    maxDate() {
      return new Date(
        this.option_positif_covid19.currentDate.getFullYear(),
        this.option_positif_covid19.currentDate.getMonth(),
        this.option_positif_covid19.currentDate.getDate()
      );
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),

    test() {
      console.log(this.option_positif_covid19.tanggal_lahir);
    },

    // Get account info
    getAccountInfo() {
      let userData = this.userData;
      this.$set(this.form, "nama_karyawan", userData.fullname);
      this.$set(this.form, "nik", userData.nik);
      if (userData.business_unit != "BBI1") {
        this.$set(this.form, "department", userData.subdept);
      } else {
        this.$set(this.form, "department", userData.dept);
      }
      this.$set(this.form, "lokasi", userData.business_unit);
    },

    // Get validation
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    // Get master site list
    getSiteList() {
      fnGET(APIPATH_BBIAPPS.listSiteName).then((rsp) => {
        this.$set(this, "siteList", rsp.data);
      });
    },

    // Get tanggal terpapar covid
    getDateCovid19() {
      let userData = this.userData;
      return fnPOST(APIPATH_HR.dateVocid19V3, {
        nik: userData.nik,
      }).then((rsp) => {
        this.$set(this, "data_riwayat_covid", rsp.data.payload);
      });
    },

    // Get User Personal Data
    getUserPersonalData() {
      let userData = this.userData;
      return fnPOST(APIPATH_BBIAPPS.userPersonalData, {
        nik: userData.nik,
      }).then((rsp) => {
        this.$set(this, "user_personal_data", rsp.data.payload);
      });
    },

    check: function (e) {
      if (e.target.value == "sehat") {
        if (e.target.checked) {
          this.kondisi = [];
          this.kondisi.push(e.target.value);
        }
      } else if (e.target.value != "sehat") {
        if (e.target.checked) {
          if (this.kondisi.includes("sehat")) {
            this.kondisi = this.kondisi.filter((a) => a !== "sehat");
          }
        }
      }
      if (e.target.value == "Positif Covid-19") {
        this.assignCovid19Value(e.target.checked);
      }
    },
    countSuhu() {
      if (this.form.suhu == "ya") {
        this.suhu_count = 25;
      } else {
        this.suhu_count = 0;
      }
    },

    // check value method
    checkLainnya() {
      if (this.kondisi.includes("lainnya")) {
        return false;
      }
      this.form.kondisiLainnya = "";
      return true;
    },
    checkKondisiKeluargaLainnya() {
      if (this.kondisi_keluarga.includes("lainnya")) {
        return false;
      }
      this.form.kondisi_keluarga_sakit_lainnya = "";
      return true;
    },
    checkBepergianLainnya() {
      if (this.bepergianLainnya.includes("lainnya")) {
        return false;
      }
      this.bepergian_lainnya = "";
      return true;
    },
    checkKeperluanBertamuLainnya() {
      if (this.keperluanBertamu.includes("lainnya")) {
        return false;
      }
      this.keperluanBertamuLainnya = "";
      return true;
    },
    checkBepergian() {
      if (this.pergi == "ya") {
        this.isolasi_mandiri_count = 0;
        return false;
      }
      this.tempat_kerja = "";
      this.transportasi = "";
      this.layanan_kesehatan = "";
      this.form.nama_layanan_kesehatan = "";
      this.berbelanja = "";
      this.lokasi_berbelanja = "";
      this.warung_tradisional = "";
      this.kegiatan_keagamaan = "";
      this.pesta = "";
      this.club_olahraga = "";
      this.makan_direstoran = "";
      this.perusahaan_lain = "";
      this.tempat_hiburan = "";
      this.form.tempat_tujuan = "";
      return true;
    },
    checkTidakBepergian() {
      if (this.pergi == "tidak") {
        this.transportasi_count = 0;
        this.layanan_kesehatan_count = 0;
        this.berbelanja_count = 0;
        this.warung_tradisional_count = 0;
        this.kegiatan_keagamaan_count = 0;
        this.pesta_count = 0;
        this.club_olahraga_count = 0;
        this.makan_direstoran_count = 0;
        this.lainnya_count = 0;
        this.perusahaan_lain_count = 0;
        this.tempat_hiburan_count = 0;
        this.luar_kota_count = 0;
        return false;
      }
      this.option_tidak_pergi.hari_libur_off = "";
      this.option_tidak_pergi.isolasi_mandiri = "";
      this.option_tidak_pergi.work_from_home = "";
      this.option_tidak_pergi.cuti_izin = "";
      this.option_tidak_pergi.tidak_pergi_lainnya = "";
      return true;
    },
    checkTidakPergiLainnya() {
      if (
        this.option_tidak_pergi.hari_libur_off == "tidak" &&
        this.option_tidak_pergi.isolasi_mandiri == "tidak" &&
        this.option_tidak_pergi.work_from_home == "tidak" &&
        this.option_tidak_pergi.cuti_izin == "tidak"
      ) {
        return false;
      }
      this.option_tidak_pergi.tidak_pergi_lainnya = "";
      return true;
    },
    assignCovid19Value(checked) {
      if (checked) {
        if (
          this.user_personal_data != [] ||
          this.user_personal_data != "" ||
          this.user_personal_data != null
        ) {
          let objUserPersonalData = this.user_personal_data;
          this.$set(
            this.option_positif_covid19,
            "nomor_wa",
            objUserPersonalData.nomor_wa
          );
          this.$set(
            this.option_positif_covid19,
            "nomor_wa_keluarga",
            objUserPersonalData.nomor_wa_keluarga
          );
          this.$set(
            this.option_positif_covid19,
            "tanggal_lahir",
            objUserPersonalData.tanggal_lahir
          );
        } else {
          this.option_positif_covid19.nomor_wa = "";
          this.option_positif_covid19.nomor_wa_keluarga = "";
          this.option_positif_covid19.tanggal_lahir = null;
        }
        if (
          this.data_riwayat_covid == [] ||
          this.data_riwayat_covid == "" ||
          this.data_riwayat_covid == null
        ) {
          this.option_positif_covid19.tgl_positif_covid = this.serverDate;
          this.option_positif_covid19.isolasi_hari_ke = 0;
        } else {
          for (let i = 0; i < this.data_riwayat_covid.length; i++) {
            var tempDateCovid = this.data_riwayat_covid[i];

            // Do something if is the last iteration of the array
            if (i + 1 == this.data_riwayat_covid.length) {
              if (
                tempDateCovid.tgl_positif_covid == null ||
                tempDateCovid.tgl_positif_covid == "" ||
                tempDateCovid.tgl_positif_covid == "-"
              ) {
                this.option_positif_covid19.tgl_positif_covid = this.serverDate;
                this.option_positif_covid19.isolasi_hari_ke = 0;
                // console.log(this.option_positif_covid19.tgl_positif_covid);
              } else {
                this.option_positif_covid19.tgl_positif_covid =
                  tempDateCovid.tgl_positif_covid;

                const _MS_PER_DAY = 1000 * 60 * 60 * 24;

                const DateToday = new Date(new Date(this.serverDate));
                const DateCovid = new Date(
                  new Date(this.option_positif_covid19.tgl_positif_covid)
                );

                const Difference_In_Time =
                  DateToday.getTime() - DateCovid.getTime();

                const Difference_In_Days = Difference_In_Time / _MS_PER_DAY;

                if (Difference_In_Days == 0) {
                  this.option_positif_covid19.isolasi_hari_ke =
                    Difference_In_Days;
                } else if (Difference_In_Days > 60) {
                  this.option_positif_covid19.isolasi_hari_ke = 0;
                  this.option_positif_covid19.tgl_positif_covid =
                    this.serverDate;
                } else {
                  this.option_positif_covid19.isolasi_hari_ke =
                    Difference_In_Days + 1;
                }
                // console.log(this.option_positif_covid19.tgl_positif_covid);
              }
            }
          }
        }
      } else {
        this.option_positif_covid19.nomor_wa = "";
        this.option_positif_covid19.nomor_wa_keluarga = "";
        this.option_positif_covid19.tanggal_lahir = null;
        this.option_positif_covid19.isolasi_hari_ke = 0;
      }
    },
    checkPositifCovid19() {
      if (this.kondisi.includes("Positif Covid-19")) {
        return false;
      }
      this.option_positif_covid19.penyakit_bawaan = "";
      this.option_positif_covid19.tanggal_lahir = null;
      this.option_positif_covid19.keluhan_karantina = "";
      this.option_positif_covid19.nomor_wa = "";
      this.option_positif_covid19.nomor_wa_keluarga = "";
      this.option_positif_covid19.penyebab_positif_covid = "";
      this.option_positif_covid19.isolasi_hari_ke = 0;
      this.option_positif_covid19.opt_positif_lainnya = "";
      return true;
    },
    checkTempatKerja() {
      if (this.tempat_kerja == "ya") {
        return false;
      }
      this.selectedSite = "";
      return true;
    },
    checkLayananKes() {
      if (this.layanan_kesehatan == "ya") {
        return false;
      }
      this.form.nama_layanan_kesehatan = "";
      return true;
    },
    checkBelanja() {
      if (this.berbelanja == "ya") {
        return false;
      }
      this.form.lokasi_berbelanja = "";
      return true;
    },
    checkKeluargaSakit() {
      if (this.keluarga_sakit == "ya") {
        return false;
      }
      this.kondisi_keluarga = [];
      this.kondisi_hubungan_keluarga_sakit = [];
      return true;
    },
    checkKondisiKesehatan() {
      if (this.form.kondisi_kesehatan == "sakit") {
        return false;
      }
      this.kondisi = [];
      return true;
    },
    checkKeperluanBertamu() {
      if (this.tamu == "ya") {
        return false;
      }
      this.keperluanBertamu = [];
      this.banyaknyaTamu = "";
      return true;
    },
    checkKeperluan() {
      if (this.perjalanan_luar_kota == "ya") {
        return false;
      }
      this.form.keperluan_tujuan = "";
      return true;
    },

    // count method
    countIsoman() {
      if (this.option_tidak_pergi.isolasi_mandiri == "ya") {
        this.option_tidak_pergi.isolasi_mandiri_count = 25;
      } else {
        this.option_tidak_pergi.isolasi_mandiri_count = 0;
      }
    },
    countTransportasi() {
      if (this.transportasi == "ya") {
        this.transportasi_count = 2;
      } else {
        this.transportasi_count = 0;
      }
    },
    countLayananKes() {
      if (this.layanan_kesehatan == "ya") {
        this.layanan_kesehatan_count = 4;
      } else {
        this.layanan_kesehatan_count = 0;
      }
    },
    countBerbelanja() {
      if (this.berbelanja == "ya") {
        this.berbelanja_count = 4;
      } else {
        this.berbelanja_count = 0;
      }
    },
    countWarungTradisional() {
      if (this.warung_tradisional == "ya") {
        this.warung_tradisional_count = 8;
      } else {
        this.warung_tradisional_count = 0;
      }
    },
    countKegiatanKeagamaan() {
      if (this.kegiatan_keagamaan == "ya") {
        this.kegiatan_keagamaan_count = 8;
      } else {
        this.kegiatan_keagamaan_count = 0;
      }
    },
    countPesta() {
      if (this.pesta == "ya") {
        this.pesta_count = 8;
      } else {
        this.pesta_count = 0;
      }
    },
    countClubOlahraga() {
      if (this.club_olahraga == "ya") {
        this.club_olahraga_count = 8;
      } else {
        this.club_olahraga_count = 0;
      }
    },
    countMakanDirestoran() {
      if (this.makan_direstoran == "ya") {
        this.makan_direstoran_count = 8;
      } else {
        this.makan_direstoran_count = 0;
      }
    },
    countPerusahaanLain() {
      if (this.perusahaan_lain == "ya") {
        this.perusahaan_lain_count = 8;
      } else {
        this.perusahaan_lain_count = 0;
      }
    },
    countTempatHiburan() {
      if (this.tempat_hiburan == "ya") {
        this.tempat_hiburan_count = 8;
      } else {
        this.tempat_hiburan_count = 0;
      }
    },
    countKeluargaBepergian() {
      if (this.keluarga_bepergian == "ya") {
        this.keluarga_bepergian_count = 4;
      } else {
        this.keluarga_bepergian_count = 0;
      }
    },
    countKeluargaSakit() {
      if (this.keluarga_sakit == "ya") {
        this.keluarga_sakit_count = 25;
      } else {
        this.keluarga_sakit_count = 0;
      }
    },
    countKeluarKota() {
      if (this.perjalanan_luar_kota == "ya") {
        this.luar_kota_count = 2;
      } else {
        this.luar_kota_count = 0;
      }
    },
    countCovid() {
      if (this.covid_19 == "ya") {
        this.covid_count = 25;
      } else {
        this.covid_count = 0;
      }
    },
    // animation shake and autoscroll
    FocusWarning() {
      if (
        this.form.kondisi_kesehatan == null ||
        this.form.kondisi_kesehatan == ""
      ) {
        this.$refs.opt_kesehatan.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_kesehatan.$el.classList.value =
          this.$refs.opt_kesehatan.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_kesehatan.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.form.kondisi_kesehatan == "sakit") {
        if (this.kondisi == null || this.kondisi == "" || this.kondisi == []) {
          this.$refs.opt_ket_kesehatan.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_ket_kesehatan.$el.classList.value =
            this.$refs.opt_ket_kesehatan.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_ket_kesehatan.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.kondisi.includes("lainnya") &&
          (this.form.kondisiLainnya == "" || this.form.kondisiLainnya == null)
        ) {
          this.$refs.opt_ket_kesehatan_lainnya.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_ket_kesehatan_lainnya.$el.classList.value =
            this.$refs.opt_ket_kesehatan_lainnya.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_ket_kesehatan_lainnya.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
      }
      if (this.kondisi.includes("Positif Covid-19")) {
        if (
          this.option_positif_covid19.penyakit_bawaan == null ||
          this.option_positif_covid19.penyakit_bawaan == ""
        ) {
          this.$refs.opt_penyakit_bawaan.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_penyakit_bawaan.$el.classList.value =
            this.$refs.opt_penyakit_bawaan.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_penyakit_bawaan.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_positif_covid19.tanggal_lahir == null ||
          this.option_positif_covid19.tanggal_lahir == ""
        ) {
          this.$refs.opt_tanggal_lahir.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_tanggal_lahir.$el.classList.value =
            this.$refs.opt_tanggal_lahir.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_tanggal_lahir.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_positif_covid19.keluhan_karantina == null ||
          this.option_positif_covid19.keluhan_karantina == ""
        ) {
          this.$refs.opt_keluhan_karantina.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_keluhan_karantina.$el.classList.value =
            this.$refs.opt_keluhan_karantina.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_keluhan_karantina.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_positif_covid19.nomor_wa == null ||
          this.option_positif_covid19.nomor_wa == ""
        ) {
          this.$refs.opt_nomor_wa.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_nomor_wa.$el.classList.value =
            this.$refs.opt_nomor_wa.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_nomor_wa.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (
          this.option_positif_covid19.nomor_wa_keluarga == null ||
          this.option_positif_covid19.nomor_wa_keluarga == ""
        ) {
          this.$refs.opt_nomor_wa_keluarga.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_nomor_wa_keluarga.$el.classList.value =
            this.$refs.opt_nomor_wa_keluarga.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_nomor_wa_keluarga.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_positif_covid19.penyebab_positif_covid == null ||
          this.option_positif_covid19.penyebab_positif_covid == ""
        ) {
          this.$refs.opt_penyebab_positif_covid.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_penyebab_positif_covid.$el.classList.value =
            this.$refs.opt_penyebab_positif_covid.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_penyebab_positif_covid.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_positif_covid19.isolasi_hari_ke == null ||
          this.option_positif_covid19.isolasi_hari_ke == 0
        ) {
          this.$refs.opt_isolasi_hari_ke.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_isolasi_hari_ke.$el.classList.value =
            this.$refs.opt_isolasi_hari_ke.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_isolasi_hari_ke.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
      }
      if (this.keluarga_sakit == null || this.keluarga_sakit == "") {
        this.$refs.opt_keluarga_sakit.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_keluarga_sakit.$el.classList.value =
          this.$refs.opt_keluarga_sakit.$el.classList.value +
          " animation-shake";
        setTimeout(() => {
          this.$refs.opt_keluarga_sakit.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.keluarga_sakit == "ya") {
        if (
          this.kondisi_hubungan_keluarga_sakit == "" ||
          this.kondisi_hubungan_keluarga_sakit == null
        ) {
          this.$refs.opt_kerabat_keluarga.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_kerabat_keluarga.$el.classList.value =
            this.$refs.opt_kerabat_keluarga.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_kerabat_keluarga.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.kondisi_keluarga == "" ||
          this.kondisi_keluarga == null ||
          this.kondisi_keluarga == []
        ) {
          this.$refs.opt_kondisi_keluarga.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_kondisi_keluarga.$el.classList.value =
            this.$refs.opt_kondisi_keluarga.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_kondisi_keluarga.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          (this.kondisi_keluarga.includes("lainnya") &&
            this.form.kondisi_keluarga_sakit_lainnya == "") ||
          this.form.kondisi_keluarga_sakit_lainnya == null
        ) {
          this.$refs.opt_kondisi_keluarga_lainnya.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_kondisi_keluarga_lainnya.$el.classList.value =
            this.$refs.opt_kondisi_keluarga_lainnya.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_kondisi_keluarga_lainnya_input.focus();
            this.$refs.opt_kondisi_keluarga_lainnya.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
      }
      if (this.pergi == null || this.pergi == "") {
        this.$refs.opt_bepergian.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_bepergian.$el.classList.value =
          this.$refs.opt_bepergian.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_bepergian.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.pergi == "tidak") {
        if (
          this.option_tidak_pergi.hari_libur_off == null ||
          this.option_tidak_pergi.hari_libur_off == ""
        ) {
          this.$refs.opt_hari_libur_off.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_hari_libur_off.$el.classList.value =
            this.$refs.opt_hari_libur_off.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_hari_libur_off.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_tidak_pergi.isolasi_mandiri == null ||
          this.option_tidak_pergi.isolasi_mandiri == ""
        ) {
          this.$refs.opt_isolasi_mandiri.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_isolasi_mandiri.$el.classList.value =
            this.$refs.opt_isolasi_mandiri.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_isolasi_mandiri.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_tidak_pergi.work_from_home == null ||
          this.option_tidak_pergi.work_from_home == ""
        ) {
          this.$refs.opt_work_from_home.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_work_from_home.$el.classList.value =
            this.$refs.opt_work_from_home.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_work_from_home.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.option_tidak_pergi.cuti_izin == null ||
          this.option_tidak_pergi.cuti_izin == ""
        ) {
          this.$refs.opt_cuti_izin.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_cuti_izin.$el.classList.value =
            this.$refs.opt_cuti_izin.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_cuti_izin.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (
          this.option_tidak_pergi.hari_libur_off == "tidak" &&
          this.option_tidak_pergi.isolasi_mandiri == "tidak" &&
          this.option_tidak_pergi.work_from_home == "tidak" &&
          this.option_tidak_pergi.cuti_izin == "tidak"
        ) {
          if (
            this.option_tidak_pergi.tidak_pergi_lainnya == null ||
            this.option_tidak_pergi.tidak_pergi_lainnya == ""
          ) {
            this.$refs.opt_tidak_pergi_lainnya.$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
            this.$refs.opt_tidak_pergi_lainnya.$el.classList.value =
              this.$refs.opt_tidak_pergi_lainnya.$el.classList.value +
              " animation-shake";
            setTimeout(() => {
              this.$refs.opt_tidak_pergi_lainnya.$el.classList.remove(
                "animation-shake"
              );
            }, 1500);
            return false;
          }
        }
      }
      if (this.pergi == "ya") {
        if (this.tempat_kerja == null || this.tempat_kerja == "") {
          this.$refs.opt_tempat_kerja.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_tempat_kerja.$el.classList.value =
            this.$refs.opt_tempat_kerja.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_tempat_kerja.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (
          (this.tempat_kerja == "ya" && this.lokasiKerja == null) ||
          this.lokasiKerja == "" ||
          this.lokasiKerja == []
        ) {
          this.$refs.opt_lokasi_kerja.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_lokasi_kerja.$el.classList.value =
            this.$refs.opt_lokasi_kerja.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_lokasi_kerja.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (this.transportasi == null || this.transportasi == "") {
          this.$refs.opt_transportasi.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_transportasi.$el.classList.value =
            this.$refs.opt_transportasi.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_transportasi.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (this.layanan_kesehatan == null || this.layanan_kesehatan == "") {
          this.$refs.opt_layanan_kesehatan.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_layanan_kesehatan.$el.classList.value =
            this.$refs.opt_layanan_kesehatan.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_layanan_kesehatan.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          (this.layanan_kesehatan == "ya" &&
            this.form.nama_layanan_kesehatan == "") ||
          this.form.nama_layanan_kesehatan == null
        ) {
          this.$refs.opt_layanan_kesehatan_lainnnya.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_layanan_kesehatan_lainnnya.$el.classList.value =
            this.$refs.opt_layanan_kesehatan_lainnnya.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_layanan_kesehatan_lainnnya_input.focus();
            this.$refs.opt_layanan_kesehatan_lainnnya.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (this.berbelanja == null || this.berbelanja == "") {
          this.$refs.opt_berbelanja.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_berbelanja.$el.classList.value =
            this.$refs.opt_berbelanja.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_berbelanja.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (
          (this.berbelanja == "ya" && this.form.lokasi_berbelanja == "") ||
          this.form.lokasi_berbelanja == null
        ) {
          this.$refs.opt_lokasi_berbelanja.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_lokasi_berbelanja.$el.classList.value =
            this.$refs.opt_lokasi_berbelanja.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_lokasi_berbelanja_input.focus();
            this.$refs.opt_lokasi_berbelanja.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (this.warung_tradisional == null || this.warung_tradisional == "") {
          this.$refs.opt_warung_tradisional.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_warung_tradisional.$el.classList.value =
            this.$refs.opt_warung_tradisional.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_warung_tradisional.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (this.kegiatan_keagamaan == null || this.kegiatan_keagamaan == "") {
          this.$refs.opt_kegiatan_keagamaan.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_kegiatan_keagamaan.$el.classList.value =
            this.$refs.opt_kegiatan_keagamaan.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_kegiatan_keagamaan.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (this.pesta == null || this.pesta == "") {
          this.$refs.opt_pesta.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_pesta.$el.classList.value =
            this.$refs.opt_pesta.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_pesta.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (this.club_olahraga == null || this.club_olahraga == "") {
          this.$refs.opt_club_olahraga.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_club_olahraga.$el.classList.value =
            this.$refs.opt_club_olahraga.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_club_olahraga.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (this.makan_direstoran == null || this.makan_direstoran == "") {
          this.$refs.opt_makan_direstoran.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_makan_direstoran.$el.classList.value =
            this.$refs.opt_makan_direstoran.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_makan_direstoran.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (this.perusahaan_lain == null || this.perusahaan_lain == "") {
          this.$refs.opt_perusahaan_lain.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_perusahaan_lain.$el.classList.value =
            this.$refs.opt_perusahaan_lain.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_perusahaan_lain.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (this.tempat_hiburan == null || this.tempat_hiburan == "") {
          this.$refs.opt_tempat_hiburan.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_tempat_hiburan.$el.classList.value =
            this.$refs.opt_tempat_hiburan.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_tempat_hiburan.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.perjalanan_luar_kota == null ||
          this.perjalanan_luar_kota == ""
        ) {
          this.$refs.opt_perjalanan_luar_kota.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_perjalanan_luar_kota.$el.classList.value =
            this.$refs.opt_perjalanan_luar_kota.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_perjalanan_luar_kota.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          (this.perjalanan_luar_kota == "ya" &&
            this.form.keperluan_tujuan == "") ||
          this.form.keperluan_tujuan == null
        ) {
          this.$refs.opt_keperluan_tujuan.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_keperluan_tujuan.$el.classList.value =
            this.$refs.opt_keperluan_tujuan.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_keperluan_tujuan_input.focus();
            this.$refs.opt_keperluan_tujuan.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
        if (
          this.bepergianLainnya.includes("lainnya") &&
          (this.bepergian_lainnya == "" || this.bepergian_lainnya == null)
        ) {
          this.$refs.opt_ket_kesehatan_lainnya.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_ket_kesehatan_lainnya.$el.classList.value =
            this.$refs.opt_ket_kesehatan_lainnya.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_ket_kesehatan_lainnya.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
      }
      if (this.keluarga_bepergian == null || this.keluarga_bepergian == "") {
        this.$refs.opt_bepergian_lainnya_text.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_bepergian_lainnya_text.$el.classList.value =
          this.$refs.opt_bepergian_lainnya_text.$el.classList.value +
          " animation-shake";
        setTimeout(() => {
          this.$refs.opt_bepergian_lainnya_text.$el.classList.remove(
            "animation-shake"
          );
        }, 1500);
        return false;
      }
      if (this.tamu == null || this.tamu == "") {
        this.$refs.opt_tamu.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_tamu.$el.classList.value =
          this.$refs.opt_tamu.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_tamu.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (this.tamu == "ya") {
        if (
          this.keperluanBertamu == null ||
          this.keperluanBertamu == "" ||
          this.keperluanBertamu == []
        ) {
          this.$refs.opt_kep_bertamu.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_kep_bertamu.$el.classList.value =
            this.$refs.opt_kep_bertamu.$el.classList.value + " animation-shake";
          setTimeout(() => {
            this.$refs.opt_kep_bertamu.$el.classList.remove("animation-shake");
          }, 1500);
          return false;
        }
        if (
          this.keperluanBertamu.includes("lainnya") &&
          (this.keperluanBertamuLainnya == "" ||
            this.keperluanBertamuLainnya == null)
        ) {
          this.$refs.opt_kep_bertamu_lainnya.$el.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          this.$refs.opt_kep_bertamu_lainnya.$el.classList.value =
            this.$refs.opt_kep_bertamu_lainnya.$el.classList.value +
            " animation-shake";
          setTimeout(() => {
            this.$refs.opt_kep_bertamu_lainnya.$el.classList.remove(
              "animation-shake"
            );
          }, 1500);
          return false;
        }
      }
      if (this.covid_19 == null || this.covid_19 == "") {
        this.$refs.opt_covid_19.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_covid_19.$el.classList.value =
          this.$refs.opt_covid_19.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_covid_19.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
      if (
        this.form.checkbox_jawaban == null ||
        this.form.checkbox_jawaban == ""
      ) {
        this.$refs.opt_jawab.$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        this.$refs.opt_jawab.$el.classList.value =
          this.$refs.opt_jawab.$el.classList.value + " animation-shake";
        setTimeout(() => {
          this.$refs.opt_jawab.$el.classList.remove("animation-shake");
        }, 1500);
        return false;
      }
    },
    // END animation shake and autoscroll

    onSubmit() {
      let objTemp = this.form.kondisi_kesehatan;
      if (this.form.kondisi_kesehatan == "sakit") {
        objTemp = this.kondisi.toString();
        if (this.$refs.chkboxKondisiLainnya.value != null) {
          objTemp = objTemp.replace("lainnya", this.form.kondisiLainnya);
        }

        if (objTemp.match("Demam dengan suhu tubuh > 37,2")) {
          this.suhu_count = 25;
          this.$set(this.form, "suhu", "ya");
        } else {
          this.suhu_count = 0;
          this.$set(this.form, "suhu", "tidak");
        }

        if (objTemp.match("Sesak nafas ringan - berat")) {
          this.sesak_count = 31;
        } else {
          this.sesak_count = 0;
        }

        if (objTemp.match("Lemas / Pegal-Pegal / Tidak Bertenaga")) {
          this.lemas_count = 2;
        } else {
          this.lemas_count = 0;
        }

        if (
          objTemp.match("Hilangnya kemampuan mengecap rasa atau mencium bau")
        ) {
          this.hilang_rasa_count = 31;
        } else {
          this.hilang_rasa_count = 0;
        }

        if (objTemp.match("Batuk, Pilek, Sakit Tenggorokan")) {
          this.batuk_count = 25;
        } else {
          this.batuk_count = 0;
        }

        if (objTemp.match("Positif Covid-19")) {
          this.positif_covid_count = 31;
          this.positif_covid = "ya";
          if (
            this.option_positif_covid19.tgl_positif_covid == null ||
            this.option_positif_covid19.tgl_positif_covid == ""
          ) {
            this.option_positif_covid19.tgl_positif_covid =
              this.form.tanggal_harian;
          }
        } else {
          this.positif_covid_count = 0;
          this.positif_covid = "";
          this.option_positif_covid19.tgl_positif_covid = null;
        }
      } else {
        objTemp = "sehat";
        this.$set(this.form, "suhu", "tidak");
      }
      if (this.keluarga_sakit == "ya") {
        let objTempKesehatanKeluarga = this.kondisi_keluarga.toString();
        if (this.$refs.chkboxKondisiKeluarga.value != null) {
          objTempKesehatanKeluarga = objTempKesehatanKeluarga.replace(
            "lainnya",
            this.form.kondisi_keluarga_sakit_lainnya
          );
        }
        this.form.kondisi_keluarga_sakit = objTempKesehatanKeluarga;
        let objTempHubunganKeluarga =
          this.kondisi_hubungan_keluarga_sakit.toString();
        this.hubungan_keluarga_sakit = objTempHubunganKeluarga;
        // count kondisi keluarga
        // if (
        //   this.form.kondisi_keluarga_sakit.match(
        //     "batuk, pilek, sakit tenggorokan"
        //   )
        // ) {
        //   this.kondisi_keluarga_count = this.kondisi_keluarga_count + 25;
        // }
        // if (
        //   this.form.kondisi_keluarga_sakit.match(
        //     "Hilangnya kemampuan mengecap rasa atau mencium bau"
        //   )
        // ) {
        //   this.kondisi_keluarga_count = this.kondisi_keluarga_count + 31;
        // }
        // if (
        //   this.form.kondisi_keluarga_sakit.match(
        //     "lemas / pegal-pegal / tidak bertenaga"
        //   )
        // ) {
        //   this.kondisi_keluarga_count = this.kondisi_keluarga_count + 2;
        // }
        // if (this.form.kondisi_keluarga_sakit.match("sesak nafas")) {
        //   this.kondisi_keluarga_count = this.kondisi_keluarga_count + 31;
        // }
        // if (
        //   this.form.kondisi_keluarga_sakit.match(
        //     "demam dengan suhu tubuh > 37,2"
        //   )
        // ) {
        //   this.kondisi_keluarga_count = this.kondisi_keluarga_count + 25;
        // }
        // end count kondisi keluarga
      } else {
        this.form.kondisi_keluarga_sakit = "-";
        this.hubungan_keluarga_sakit = "-";
        this.kondisi_keluarga_count = 0;
      }

      if (this.tempat_kerja == "ya") {
        let objLokasiKerja = this.lokasiKerja.toString();
        this.selectedSite = objLokasiKerja;
        if (this.selectedSite != null) {
          this.selectedSite = this.selectedSite;
        }
      } else {
        this.selectedSite = "-";
      }

      let objKeperluanBertamu = this.tamu;
      if (this.tamu == "ya") {
        objKeperluanBertamu = this.keperluanBertamu.toString();
        if (this.$refs.chkboxKeperluanTamuLainnya.value != null) {
          objKeperluanBertamu = objKeperluanBertamu.replace(
            "lainnya",
            this.keperluanBertamuLainnya
          );
        }
        this.keperluan_bertamu = objKeperluanBertamu;
        // count keperluan bertamu
        if (
          this.keperluan_bertamu.match(
            "Acara Keagamaan/Pengajian/Ibadah/Perkumpulan Do'a/Serupa?"
          )
        ) {
          this.keperluan_bertamu_count = this.keperluan_bertamu_count + 31;
        }
        if (
          this.keperluan_bertamu.match(
            "Acara Pesta/Halal Bihalal/Pernikahan/Arisan/Acara Kedukaan/Demo/Serupa?"
          )
        ) {
          this.keperluan_bertamu_count = this.keperluan_bertamu_count + 31;
        }
        if (this.keperluan_bertamu.match("Kunjungan Biasa")) {
          this.keperluan_bertamu_count = this.keperluan_bertamu_count + 4;
        }
        if (
          this.keperluan_bertamu.match(
            "Perbaikan (Renovasi/Perbaikan Rumah) atau Bersih-Bersih Rumah Menggunakan Jasa Pihak Luar (ART Harian/Cuci AC, Dll)"
          )
        ) {
          this.keperluan_bertamu_count = this.keperluan_bertamu_count + 4;
        }
      } else {
        this.keperluan_bertamu = "-";
        this.keperluan_bertamu_count = 0;
      }

      if (
        this.option_tidak_pergi.tidak_pergi_lainnya == null ||
        this.option_tidak_pergi.tidak_pergi_lainnya == ""
      ) {
        this.option_tidak_pergi.tidak_pergi_lainnya = "-";
      }

      if (this.bepergian_lainnya == null || this.bepergian_lainnya == "") {
        this.bepergian_lainnya = "-";
      }

      if (
        this.option_positif_covid19.opt_positif_lainnya == null ||
        this.option_positif_covid19.opt_positif_lainnya == ""
      ) {
        this.option_positif_covid19.opt_positif_lainnya = "-";
      }

      this.form.bepergian = this.pergi;

      this.total_count =
        this.keluarga_sakit_count +
        this.suhu_count +
        this.perusahaan_lain_count +
        this.makan_direstoran_count +
        this.berbelanja_count +
        this.warung_tradisional_count +
        this.kegiatan_keagamaan_count +
        this.club_olahraga_count +
        this.pesta_count +
        this.transportasi_count +
        this.layanan_kesehatan_count +
        this.tempat_hiburan_count +
        this.lainnya_count +
        this.keluarga_bepergian_count +
        this.luar_kota_count +
        this.covid_count +
        this.batuk_count +
        this.hilang_rasa_count +
        this.lemas_count +
        this.sesak_count +
        this.kondisi_keluarga_count +
        this.keperluan_bertamu_count +
        this.option_tidak_pergi.isolasi_mandiri_count +
        this.positif_covid_count;

      if (this.total_count <= 10) {
        this.profil_resiko = "Tidak Berisiko";
        this.messages =
          "<br>Profil resiko anda: Tidak beresiko.<br>Silahkan beraktivitas\ndan tetap lakukan protokol pencegahan Covid-19.\n#Disiplin5M";
      } else if (this.total_count >= 11 && this.total_count <= 20) {
        this.profil_resiko = "Resiko Rendah";
        this.messages =
          "<br>Profil resiko anda: Rendah.<br>Laporkan kondisi kesehatan ke atasan anda dan beraktivitas.\nTetap lakukan protokol pencegahan Covid-19.\n#Disiplin5M";
      } else if (this.total_count >= 21 && this.total_count <= 30) {
        this.profil_resiko = "Resiko Sedang";
        this.messages =
          "<br>Profil resiko anda: Sedang.<br>Sebelum beraktivitas, laporkan kondisi anda ke atasan dan atau Personalia.\nTetap lakukan protokol pencegahan Covid-19.\n#Disiplin5M";
      } else if (this.total_count >= 31) {
        this.profil_resiko = "Resiko Tinggi";
        this.messages =
          "<br>Profil resiko anda: Tinggi.<br>Laporkan kondisi anda ke atasan dan atau Personalia.\nJika anda sakit/memiliki gejala Covid-19, lakukan pemeriksaan ke Faskes/Klinik Terdekat\nTetap lakukan protokol pencegahan Covid-19.\n#Disiplin5M";
      }

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        color: "#f89830",
        loader: "dots",
        width: 64,
        height: 64,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
      });
      // show report after timeout min 1 second
      setTimeout(() => {
        // New Post Daily Health V3
        fnPOST(APIPATH_HR.healthDailyV3, {
          nama_karyawan: this.form.nama_karyawan,
          nik: this.form.nik,
          department: this.form.department,
          tanggal_harian: this.form.tanggal_harian,
          suhu: this.form.suhu,
          kondisi_kesehatan: objTemp,
          bepergian: this.replaceNullValue(this.form.bepergian),
          tempat_tujuan: this.replaceNullValue(this.form.tempat_tujuan),
          checkbox_jawaban: this.form.checkbox_jawaban,
          lokasi: this.form.lokasi,
          tempat_kerja: this.replaceNullValue(this.tempat_kerja),
          lokasi_kerja: this.replaceNullValue(this.selectedSite),
          transportasi: this.replaceNullValue(this.transportasi),
          layanan_kesehatan: this.replaceNullValue(this.layanan_kesehatan),
          nama_layanan_kesehatan: this.replaceNullValue(
            this.form.nama_layanan_kesehatan
          ),
          berbelanja: this.replaceNullValue(this.berbelanja),
          lokasi_berbelanja: this.replaceNullValue(this.form.lokasi_berbelanja),
          warung_tradisional: this.replaceNullValue(this.warung_tradisional),
          kegiatan_keagamaan: this.replaceNullValue(this.kegiatan_keagamaan),
          pesta: this.replaceNullValue(this.pesta),
          club_olahraga: this.replaceNullValue(this.club_olahraga),
          makan_direstoran: this.replaceNullValue(this.makan_direstoran),
          perusahaan_lain: this.replaceNullValue(this.perusahaan_lain),
          tempat_hiburan: this.replaceNullValue(this.tempat_hiburan),
          keluarga_sakit: this.replaceNullValue(this.keluarga_sakit),
          nama_keluarga_sakit: this.replaceNullValue(
            this.hubungan_keluarga_sakit
          ),
          kondisi_keluarga_sakit: this.replaceNullValue(
            this.form.kondisi_keluarga_sakit
          ),
          tamu: this.replaceNullValue(this.tamu),
          keperluan_bertamu: this.replaceNullValue(this.keperluan_bertamu),
          nama_tamu: this.replaceNullValue(this.form.nama_tamu),
          keluar_kota: this.replaceNullValue(this.perjalanan_luar_kota),
          tujuan_keluar_kota: this.replaceNullValue(this.form.keperluan_tujuan),
          riwayat_covid: this.replaceNullValue(this.covid_19),
          nilai_resiko: this.total_count,
          profil_resiko: this.profil_resiko,
          coordinates: localStorage.getItem("coordinates"),

          // add reason tidak bepergian
          hari_libur: this.replaceNullValue(
            this.option_tidak_pergi.hari_libur_off
          ),
          isolasi_mandiri: this.replaceNullValue(
            this.option_tidak_pergi.isolasi_mandiri
          ),
          positif_covid: this.replaceNullValue(this.positif_covid),
          positif_covid_keluarga_karyawan: this.replaceNullValue(
            this.positif_covid_keluarga
          ),
          work_from_home: this.replaceNullValue(
            this.option_tidak_pergi.work_from_home
          ),
          cuti_izin: this.replaceNullValue(this.option_tidak_pergi.cuti_izin),
          tidak_pergi_lainnya: this.replaceNullValue(
            this.option_tidak_pergi.tidak_pergi_lainnya
          ),

          // add bepergian lainnya
          bepergian_lainnya: this.replaceNullValue(this.bepergian_lainnya),

          // add option positif covid 19
          penyakit_bawaan: this.replaceNullValue(
            this.option_positif_covid19.penyakit_bawaan
          ),
          tanggal_lahir: this.option_positif_covid19.tanggal_lahir,
          keluhan_karantina: this.replaceNullValue(
            this.option_positif_covid19.keluhan_karantina
          ),
          nomor_wa: this.replaceNullValue(this.option_positif_covid19.nomor_wa),
          nomor_wa_keluarga: this.replaceNullValue(
            this.option_positif_covid19.nomor_wa_keluarga
          ),
          penyebab_positif_covid: this.replaceNullValue(
            this.option_positif_covid19.penyebab_positif_covid
          ),
          isolasi_hari_ke: this.option_positif_covid19.isolasi_hari_ke,
          opt_positif_lainnya: this.replaceNullValue(
            this.option_positif_covid19.opt_positif_lainnya
          ),
          tgl_positif_covid: this.option_positif_covid19.tgl_positif_covid,

          // add keluarga bepergian
          keluarga_bepergian: this.replaceNullValue(this.keluarga_bepergian)
        })
          .then((response) => {
            loader.hide();
            let postSubmitInfo = `Nama : ${response.data.payload.nama} <br>NIK : ${response.data.payload.nik} <br>Tgl Lapor : ${response.data.payload.timestamp} WIB<br>`;
            postSubmitInfo += this.messages;
            Vue.swal.fire({
              icon: "info",
              title: "Laporan Berhasil Disimpan",
              html: postSubmitInfo,
            });
            // alert(postSubmitInfo);
            this.$router.push({ name: "DashboardPage" });
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });
          })
          .catch((err) => {
            loader.hide();
            if (err.response.status === 400) {
              console.log(err.response.data.message);
              this.showNotification({
                type: "error",
                message: err.response.data.message,
              });
            }
          });
      }, 1000);
    },
    replaceNullValue(value) {
      if (!value) {
        return "-";
      }
      return value;
    },
  },
  mounted: function () {
    this.getAccountInfo();
    console.log('url', APIPATH_HR.healthDailyV3)
  },
  async created() {
    await this.getSiteList();
    this.getDateCovid19();
    this.getUserPersonalData();
    extend("suhuRule", {
      getMessage: (suhu) => suhu + "Hanya boleh 0 atau 30 s/d 41.99",
      validate: (value) => {
        if (value == 0 || (value >= 30 && value <= 41.99)) return true;
        return false;
      },
    });
    this.$set(this.form, "tanggal_harian", this.serverDate);
    this.max = this.serverDate;
  },
};
</script>
<style src="./DailyHealthV3.scss" lang="scss" scoped />
