<template>
  <div>
    <section>
      <div class="imgBx">
        <img src="../../assets/forgotpassword.jpg" alt="" />
      </div>
      <div class="contentBx">
        <div class="formBx">
          <h5 class="auth-logo">
            <div class="banner-container">
              <div class="bbi-banner"></div>
            </div>
            <i class="fa fa-circle text-gray"></i>
            APPS
            <i class="fa fa-circle text-bbi_yellow"></i>
          </h5>
          <h2>Reset Password</h2>
          <b-alert class="alert-sm" variant="danger" :show="!!errorMessage">
            {{ errorMessage }}
          </b-alert>
          <form @submit.prevent="submitResetPassword">
            <div class="inputBx">
              <span>Employee Id</span>
              <input
                type="text"
                name="userid"
                id="userid"
                v-model="inputUserID"
              />
            </div>
            <div class="inputBx">
              <span>Date of Birth</span>
              <b-form class="datepicker">
                <VueDatePicker
                  v-model="selectedDate"
                  class="vd__div"
                  no-header
                  format="DD MMM YYYY"
                  min-date="1900-01-01"
                  placeholder="Choose report date start"
                />
              </b-form>
            </div>
            <div class="inputBx">
              <vue-recaptcha
                @verify="onVerify"
                sitekey="6LecHWcbAAAAAHxgw3itq-256JzMQ1PWI8fF4n1l"
              ></vue-recaptcha>
            </div>
            <div class="inputBx">
              <input type="submit" value="Submit" />
            </div>
            <!-- Back to Login Page -->
            <div class="inputBx">
              <p>
                Already remember your password?
                <router-link to="login" class="links__router"
                  >Sign in here...</router-link
                >
              </p>
            </div>
          </form>
          <h3>BBI Social Media</h3>
          <ul class="sci">
            <li>
              <router-link to="instagram" class="links__router" target="_blank"
                ><i class="fa fa-instagram"></i
              ></router-link>
            </li>
            <li>
              <router-link to="linkedin" class="links__router" target="_blank"
                ><i class="fa fa-linkedin"></i
              ></router-link>
            </li>
            <li>
              <router-link to="facebook" class="links__router" target="_blank"
                ><i class="fa fa-facebook-f"></i
              ></router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// Import api path backdoor reset
import { APIPATH_BBIAPPS } from "../../api/api-paths";
// Import api method
import { fnPOST } from "../../api/api-methods";
// Import mapAction
import { mapActions } from "vuex";
// Import vue recaptcha
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "ForgotPasswordPage",
  components: {
    VueRecaptcha,
  },
  data() {
    const now = new Date();
    const today = now.toISOString().slice(0, 10);
    return {
      selectedDate: null,
      inputUserID: null,
      errorMessage: null,
      show: true,
      form: {
        robot: false,
      },
    };
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    // SubmitForm Forgot Password
    submitResetPassword() {
      this.errorMessage = null;
      // if input is null/empty and captcha is'nt check
      if (
        this.inputUserID == null ||
        this.inputUserID == "" ||
        this.selectedDate == null ||
        this.selectedDate == "" ||
        this.form.robot == false
      ) {
        this.errorMessage = "Please fill and checklist the captcha";
      }
      // send data forgot password to backend
      else {
        fnPOST(APIPATH_BBIAPPS.backdoorReset, {
          username: this.inputUserID,
        })
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Password updated",
            });
            this.$router.push({ name: "Login" });
          })
          .catch((err) => {
            if (err.response.status === 400) {
              this.errorMessage = err.response.data.message;
            }
          });
      }
    },
    onVerify(response) {
      if (response) this.form.robot = true;
    },
  },
};
</script>

<style src="./ForgotPassword.scss" lang="scss" scoped />