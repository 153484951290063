import {
    fnApiAllMasterCalender, 
    fnApiGetSubconCalender,       
    fnApiGetDateCalculation,       
} from '../api/api-calender';
// import { resolve } from 'core-js/fn/promise';

const getDefaultState = () => {
    return {        
        stMstCldrCutting: null,
        stMstCldrSewing: null,
        stMstCldrFinishing: null,
        stMstCldrPacking: null,
        stMstCldrPrinting: null,
        stMstCldrEmbroidery: null,
        stMstCldrEmblem: null,
        stMstCldrWashing: null,
        stSubCldr: null,
        stDateCalc: null,
        stDateCalcCount: 0,
    }
}
const state = getDefaultState()
const getters = {       
}
const mutations = {    
    updateMstCldrCutting(state, data) {
        state.stMstCldrCutting = data        
    },  
    updateMstCldrSewing(state, data) {
        state.stMstCldrSewing = data        
    },  
    updateMstCldrFinishing(state, data) {
        state.stMstCldrFinishing = data        
    },  
    updateMstCldrPacking(state, data) {
        state.stMstCldrPacking = data        
    },  
    updateMstCldrPrinting(state, data) {
        state.stMstCldrPrinting = data        
    },  
    updateMstCldrEmbroidery(state, data) {
        state.stMstCldrEmbroidery = data        
    },  
    updateMstCldrEmblem(state, data) {
        state.stMstCldrEmblem = data        
    },  
    updateMstCldrWashing(state, data) {
        state.stMstCldrWashing = data        
    },  
    updateDateCalc(state, data) {
        state.stDateCalc = data        
        state.stDateCalcCount = data.length       
    },  
    updateSubCldr(state, data) {
        state.stSubCldr = data        
    },
    resetState(state) {
        Object.assign(state, getDefaultState())
    },  

}
const actions = {
    getMasterCalenderCutting({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrCutting', rsp.data.payload)            
        })
    }, 
    getMasterCalenderSewing({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrSewing', rsp.data.payload)            
        })
    }, 
    getMasterCalenderFinishing({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrFinishing', rsp.data.payload)            
        })
    }, 
    getMasterCalenderPacking({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrPacking', rsp.data.payload)            
        })
    }, 
    getMasterCalenderPrinting({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrPrinting', rsp.data.payload)            
        })
    }, 
    getMasterCalenderEmbroidery({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrEmbroidery', rsp.data.payload)            
        })
    }, 
    getMasterCalenderEmblem({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrEmblem', rsp.data.payload)            
        })
    }, 
    getMasterCalenderWashing({commit}, filter) {
        fnApiAllMasterCalender(filter).then( rsp => {
            commit('updateMstCldrWashing', rsp.data.payload)            
        })
    },
    clearState({commit}) {        
        commit('resetState')
        localStorage.removeItem('activeReport')
    },
    getSubconCalender({commit}, filters) {
        return new Promise((resolve, reject) => {
            fnApiGetSubconCalender(filters).then( rsp => {
                commit('updateSubCldr', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data.payload)
            }).catch(err => {
                reject(err)
            })
        })
    },
    GetDateCalculation({commit}, filters) {
        return new Promise((resolve, reject) => {
            fnApiGetDateCalculation(filters).then( rsp => {
                commit('updateDateCalc', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data.payload)
            }).catch(err => {
                reject(err)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
    
}