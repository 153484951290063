<template>
    <b-overlay :show="busy" rounded="sm" >
        <template #overlay>
            <div class="text-center">
                <b-icon icon="arrow-clockwise" font-scale="4" variant="primary" animation="spin"></b-icon>
                <p id="cancel-label">Please wait...</p>
                
            </div>
        </template>
    <div>
        <template v-if="roles">
            <b-button variant="success" v-on:click="show = !show">Create</b-button>
        </template>
         <b-card sub-title="Filter" class="spacing-top">
            <b-row>
                 <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                        label="Brand"
                        label-for="brand-select"
                        label-cols="3" 
                        label-size="sm"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-select
                            id="brand-select"
                            v-model="filters.brand"
                            :options="brandOptFilter"
                            :aria-describedby="ariaDescribedby"
                            size="sm"
                        >
                        <template #first>
                            <option value="">All</option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                 <b-col sm="auto" md="4" lg="4">
                    <b-form-group
                        label="Type"
                        label-for="type-select"
                        label-cols="3" 
                        label-size="sm"
                        v-slot="{ ariaDescribedby }"
                        >
                        <b-form-select
                            id="type-select"
                            v-model="filters.type"
                            :options="brandOptType"
                            :aria-describedby="ariaDescribedby"
                            size="sm"
                        >
                        <template #first>
                            <option value="">All</option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col sm="auto" md="4" lg="4">
                        <b-form-group
                           label="Description"
                           label-for="search-description"
                           label-cols="3" 
                           label-size="sm"
                           v-slot="{ ariaDescribedby }"
                        >
                            <b-input-group size="sm">
                                <b-form-input 
                                    id="inputDescription" 
                                    v-model="filter"
                                    type="search Description"
                                    placeholder="Type to Search" 
                                    trim
                                ></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                        </b-input-group>
                            </b-form-group>
                    </b-col>
            </b-row>
        </b-card>
        <b-table
            striped 
            hover
            show-empty
            responsive
            :filter="filter"
            :filter-included-fields="includeFiled"
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="filterProduct"
            :fields="fieldsInterlining"
        >
            <template #cell(actions)="{item}">
                <template v-if="roles">
                <b-button title="Hapus" size="xs" variant="danger" v-on:click="deleteData(item.id)">
                    
                    <b-icon icon="trash-fill" aria-label="Help"></b-icon>
                </b-button>
                <template v-if="item.status != 'Finish'">
                    <b-button title="Ubah" size="xs" variant="warning" v-on:click="editData(item.id)">
                        <b-icon icon="pencil-square" aria-label="Help"></b-icon>
                    </b-button>
                </template>
                </template>
                <template>
                    <b-button title="Show" size="xs" variant="info" v-on:click="showData(item.id)">
                        <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                    </b-button>
                </template>
            </template>

            <template #cell(img_src)="{item}"   >
                <b-img v-bind="mainProps" thumbnail fluid :src="item.img_src" :alt="item.img_name"></b-img>
            </template>

        </b-table>
        <div class="mt-3">
            <b-pagination
                v-model="paging.currentPage"
                :total-rows="rows"
                :per-page="paging.perPage"
                align="center"
            >

            </b-pagination>
        </div>
        <b-modal
            hide-footer
            v-model="show"
            hide-backdrop
            v-on:ok="onSubmit()"
            ok-title="Save"
            size="auto"
            ref="modal"
            no-close-on-backdrop
            scrollable 
            title="Form Master Interlining">
            <div id="modal">
                <validation-observer ref="observer" v-slot="{handleSubmit}">
                    <b-form @submit.prevent="handleSubmit(onSubmit)">
                        
                                <!-- Brand -->
                                    <validation-provider
                                        name="brand"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Brand
                                            </template>
                                            <b-form-select value-field="value"
                                                                text-field="value" v-on:change="generateCode()" id="inputBrand" v-model="form.brand" :options="brandOpt" :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputBrand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Brand -->

                                <!-- Code -->
                                    <validation-provider
                                        name="Code"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Code
                                            </template>
                                            <b-form-input readonly id="inputCode" v-model="form.code"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputCode">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Code -->

                                <!-- Type -->
                                    <validation-provider
                                        name="Type"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Type<code>*</code>
                                            </template>
                                            <b-form-select value-field="value"
                                                                text-field="value" v-on:change="generateCode()" id="inputType" v-model="form.type" :options="typeOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputType">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Type -->
                                
                                <!-- Color -->
                                    <validation-provider
                                        name="Color"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Color
                                            </template>
                                            <b-form-select value-field="value"
                                                                text-field="value" v-on:change="generateCode()" id="inputColor" v-model="form.color" :options="colorOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputColor">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Color -->

                                <!-- Description -->
                                    <validation-provider
                                        name="Description"
                                        :rules="{ required: false }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Description
                                            </template>
                                            <b-form-input id="inputDescription" v-model="form.description"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                            <b-form-invalid-feedback id="inputDescription">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Description -->

                                <!-- Supplier -->
                                    <!-- <validation-provider
                                        name="Supplier"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Supplier
                                            </template>
                                            <b-form-select id="inputSupplier" v-model="form.supplier" :options="supplierOpt"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                            <b-form-invalid-feedback id="inputSupplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider> -->
                                <!-- /Supplier -->

                                <!-- Images -->
                                    <validation-provider
                                        name="Images"
                                        :rules="{ required: true }"
                                        v-slot="validationContext"
                                    >
                                        <b-form-group 
                                            label-cols          ="3" 
                                            label-cols-lg       ="3" 
                                            label-size          ="sm"
                                        >
                                            <template v-slot:label>
                                                Images
                                            </template>
                                            <b-form-file
                                                multiple
                                                ref="fileInput"
                                                id="inputImagesInterlining"
                                                v-model="form.images"
                                                no-drop
                                                :state="getValidationState(validationContext)"
                                                placeholder="Choose file here..."
                                                accept=".jpg, .png,"
                                                :file-name-formatter="formatNames"
                                                v-on:change="onFileChange"
                                            ></b-form-file>
                                                <div v-for="img in imagesUrl" :key="img.name">
                                                    <p class="small">
                                                        <a :href="img.src">{{img.fileName}}</a>
                                                        <b-badge href="#" v-on:click="removeImg(img.fileName,imagesUrl)" variant="light">
                                                            <b-icon icon="x-circle-fill" font-scale="1" variant="danger"></b-icon>
                                                        </b-badge>
                                                    </p>
                                                </div>
                                            <b-form-invalid-feedback id="inputImagesInterlining">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                <!-- /Images -->
                                <b-button variant="warning" v-on:click="show = !show" block size="sm">Cancel</b-button>
                                <b-button variant="success" type="submit" block size="sm">Save</b-button>
                                

                    </b-form>
                </validation-observer>
            </div>
        </b-modal>
    </div>
    </b-overlay>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import apiProduct from '@/store/product-form/master-param.js'
import Vue from 'vue';
export default {
    data(){
        return{
           filter:null,
           search:null,
           filters:{
                brand:'',
                type:'',
            },
            brandOptFilter:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'Anthm',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
                {value:'Van Hausen',text:'Van Hausen'},
            ],
            brandOptType:[
                {value:'White',text:'White'},
                {value:'Off White',text:'Off White'},
                {value:'Charcoal',text:'Charcoal'},
                {value:'Black',text:'Black'},
            ],
            includeFiled:['description'],
            mainProps: {width: 50, height: 50, class: 'm1' },
            show:false,
            formName:'Interlining',
            isValid:false,
            imagesUrl:[],
            imgID:null,
            busy:false,
            form:{
                indexId:null,
                brand:null,
                code:null,
                type:null,
                color:null,
                description:null,
                images:null,
                supplier:null,
                date:null,
                createdBy:null,
                category:'interlining',
                status:'new',
                imagesInterlining:[],
                
            },
            dataInterlining:[],
            fieldsInterlining:[
                {key:'actions', label:'#'},
                'code','type','description',
                {key:'img_src',label:'Images'},
            ],
            brandOpt:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'ANTHM',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
            ],
            typeOpt:[
                {value:'Type A',text:'Type A'},
                {value:'Type B',text:'Type B'},
                {value:'Type C',text:'Type C'},
            ],
            colorOpt:[
                {value:'Black Plain',text:'Black Plain'},
                {value:'Black Motif',text:'Black Motif'},
                {value:'Blue Plain',text:'Blue Plain'},
                {value:'Blue Motif',text:'Blue Motif'},
                {value:'Yellow Plain',text:'Yellow Plain'},
                {value:'Yellow Motif',text:'Yellow Motif'},
                {value:'Green Plain',text:'Green Plain'},
                {value:'Green Motif',text:'Green Motif'},
                {value:'Grey Plain',text:'Grey Plain'},
                {value:'Grey Motif',text:'Grey Motif'},
                {value:'Brown Plain',text:'Brown Plain'},
                {value:'Brown Motif',text:'Brown Motif'},
            ],
            supplierOpt:[
                {value:'Dwi Jaya',text:'Dwi Jaya'},
                {value:'Tri Jaya',text:'Tri Jaya'}
            ],
            paging:{
                perPage:10,
                currentPage:1,
            },
            //bad way role
            role:['Super User','Designer','Designer SPV'],
            rols:['Super User']

        }
    },
    methods:{
        ...mapActions('session',['showNotification']),
        ...mapActions('masterPLM',['GET_MASTER_MATERIAL','POST_MASTER_MATERIAL','PUT_MASTER_MATERIAL','DELETE_MASTER_MATERIAL','POST_MATERIAL_IMAGES','PUT_MATERIAL_IMAGES','addMaster','editMaster','updateMaster','deleteMaster']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit(){
            Vue.set(this,'busy',true)
            this.generateCode()
            if(this.form.indexId != null){
                this.submitEdit()
            }else{
                this.POST_MASTER_MATERIAL({
                    category:this.form.category,
                    formname:this.formName,
                    code:this.form.code,
                    brand:this.form.brand,
                    type:this.form.type,
                    description:this.form.description,
                    supplier:this.form.supplier,
                    date:this.form.date,
                    createdBy:this.form.createdBy,
                    status:this.form.status,
                    color:this.form.color,
                })
                .then(response =>{
                    if(response.data.status === 200){
                        this.submitImages(response.data.payload.material_id)
                        Vue.set(this,'busy',false)
                        this.showNotification({type:'success', message:'Successfully Submitted'})
                        
                    }
                }).catch(error => {
                    Vue.set(this,'busy',false)
                    return error.response.data.message
                })
            }
        },
        submitImages(materialID){
            
            const fd = new FormData()
            fd.append('material_id', materialID)
            fd.append('material',this.imagesUrl[0].images)
            fd.append('img_label','images')

            this.POST_MATERIAL_IMAGES(fd)
            .then(response => {
                if(response.data.status === 200){
                    console.log('gambar berhasil disimpan');
                    this.getDataInterlining()
                }
            }).catch(err => {
                return err.response.data.message
            })

            // return this.clearForm()
        },
        updateImages(materialID){
            
            const fd = new FormData()
            fd.append('material_id', materialID)
            fd.append('img_id',this.imgID)
            fd.append('material',this.imagesUrl[0].images)
            fd.append('img_label','images')

            this.PUT_MATERIAL_IMAGES(fd)
            .then(response => {
                if(response.data.status === 200){
                    console.log('gambar berhasil disimpan');
                    this.getDataInterlining()
                }
            }).catch(err => {
                return err.response.data.message
            })

            // return this.clearForm()


        },
        submitEdit(){
            this.PUT_MASTER_MATERIAL({
                    material_id: this.form.indexId,
                    category:this.form.category,
                    formname:this.formName,
                    code:this.form.code,
                    brand:this.form.brand,
                    type:this.form.type,
                    description:this.form.description,
                    supplier:this.form.supplier,
                    date:this.form.date,
                    createdBy:this.form.createdBy,
                    status:this.form.status,
                    color:this.form.color,
                    running_number: this.form.runningNumber,
                })
                .then(response =>{
                        // if(response.status === 200){
                            this.updateImages(response.data.payload.material_id)
                            Vue.set(this,'busy',false)
                            this.showNotification({type:'success', message:'Successfully Submitted'})
                            this.getDataAcc()
                        // }
                    
                }).catch(error => {
                    Vue.set(this,'busy',false)
                    return error
                })
        },
        editData(masterID){
            this.form.indexId = masterID
            this.imagesUrl = []
            this.show = true
            let masterData = this.MASTER_MATERIAL.filter(x => x.id === masterID)
            this.form.indexId = masterData[0].id
            this.form.brand= masterData[0].brand
            this.form.code= masterData[0].code
            this.form.type= masterData[0].type
            this.form.runningNumber= masterData[0].running_number
            this.form.description= masterData[0].description
            this.form.supplier= masterData[0].supplier
            this.form.color = masterData[0].color
            let img = {
                src: masterData[0].img_src,
                fileName: masterData[0].img_name
            }
            this.imagesUrl.push(img)
            this.form.images = img
            this.imgID = masterData[0].img_id
            this.form.imagesInterlining = masterData[0].img_src
            
        },
        clearForm(){
            
            this.form.indexId=null,
            this.imgID = null,
            this.form.brand=null,
            this.form.code=null,
            this.form.type=null,
            this.form.color=null,
            this.form.description=null,
            this.imagesUrl=[],
            this.form.supplier=null,
            this.form.date=null,
            this.form.createdBy=null,
            this.$refs.observer.reset(),
            this.show = false
        },
        createInterlining(){
            this.show = true
        },
        onFileChange(e){
            var file = e.target.files
            for (var i=0; i < file.length; i++){
                
                let img = {
                    src: URL.createObjectURL(file[i]),
                    fileName: file[i].name,
                    images:file[i],
                    img_name:file[i].name,
                    img_code:file[i].name+'-'+[i]
                }
                this.imagesUrl.push(img)
            }
        },
        removeImg:function(filename,modelname){
            modelname.splice(filename,1);
            return this.formatNames()
        },
        formatNames() {
            return this.imagesUrl.length === 0 ? this.$refs.fileInput.reset() : `${this.imagesUrl.length} files selected`
        },
        getDataInterlining(){
            this.clearForm()
            this.GET_MASTER_MATERIAL().then(response => {
                if(response.data.status === 200){
                    // this.showNotification({type:'success', message:'Successfully Loading Data'})
                }
            })
        },
        generateCode(){
            if ((this.form.brand && this.form.type && this.form.color) != null) {
                let brand = this.brandOpt.filter(x=>x.value === this.form.brand)
                let type = this.typeOpt.filter(x=>x.value === this.form.type)
                let color = this.colorOpt.filter(x=>x.value === this.form.color)
                
                this.form.code = brand[0].name +'-'+ type[0].name +'-'+ color[0].name
            }
        },
        getMasterParam(){
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    this.brandOpt = response.data.payload.filter(d=>d.category ==='Brand')
                    this.typeOpt = response.data.payload.filter(d=>d.category ==='Type of Interlining')
                    this.colorOpt = response.data.payload.filter(d=>d.category ==='Color')
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        deleteData(masterID){
            Vue.set(this,'busy',true)
            this.DELETE_MASTER_MATERIAL({material_id:masterID})
            .then(response =>{
                if(response.data.status === 200){
                    Vue.set(this,'busy',false)
                    this.getFabricMaster()
                }
            }).catch(error => {
                Vue.set(this,'busy',false)
                return error.response.data.message
            })
        }
        
    },
    mounted(){
        this.getMasterParam()
        this.getDataInterlining()
    },
    computed:{
        ...mapGetters('masterPLM',['MASTER_MATERIAL']),
        ...mapGetters('session',['userData']),

        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
        },
        filterProduct(){
           let brands = this.filters.brand
           let types = this.filters.type
           return this.loadMasterMaterial.filter(function(item){
                let filtereds = true
                        if(brands && brands.length > 0){
                            filtereds = item.brand == brands
                        }
                        if(filtereds){
                            if(types && types.length > 0){
                                filtereds = item.type == types
                            }
                        }
             return filtereds
           }) 

        },
        loadMasterMaterial(){
            
            return this.MASTER_MATERIAL.filter(mm => mm.category === 'interlining')
        },
        rows(){
            if(this.loadMasterMaterial != null){
                return this.loadMasterMaterial.length
            }
        }
    }
    
}
</script>