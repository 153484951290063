<template>
    <div>
         <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>{{formName}}</b-breadcrumb-item>
            
        </b-breadcrumb>
        <b-container fluid>
            <b-card>
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Costing</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                        </b-col>
                        
                    </b-row>
                </template>
                <b-card sub-title="Filter">
                    <b-row>
                        <b-col sm="auto" md="4" lg="4">
                                <b-form-group
                                    label="Brand"
                                    label-for="brand-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    v-slot="{ ariaDescribedby }"
                                    >
                                    <b-form-select
                                        id="brand-select"
                                        v-model="filters.brand"
                                        :options="brandOpt"
                                        :aria-describedby="ariaDescribedby"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>
                                <b-form-group
                                    label="Coll. Month"
                                    label-for="coll-month-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="coll-month-select"
                                        v-model="filters.month"
                                        :options="monthOpt"
                                        size="sm"
                                        value-field="value"
                                        text-field="value"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                                </b-form-group>

                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                    label="Year"
                                    label-for="year-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="year-select"
                                        v-model="filters.year"
                                        :options="yearOpt"
                                        size="sm"
                                        value-field="value"
                                        text-field="value"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                            <b-form-group
                                    label="Status"
                                    label-for="status-select"
                                    label-cols          ="3" 
                                    label-size          ="sm"
                                    >
                                    <b-form-select
                                        id="status-select"
                                        v-model="filters.status"
                                        :options="statusOpt"
                                        size="sm"
                                    >
                                        <template #first>
                                            <option value="">All</option>
                                        </template>
                                    </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col sm="auto" md="4" lg="4">
                            <b-form-group
                                    
                                    label-size          ="sm"
                                >
                                    <template v-slot:label>
                                        Search
                                    </template>
                                    <b-input-group size="sm">
                                        <b-form-input 
                                            id="inputSearch" 
                                            v-model="filter"
                                            type="search"
                                            placeholder="Type to Search" 
                                            trim
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Search</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card>
                <b-table
                    striped 
                    hover
                    show-empty
                    responsive
                    :fields="fields"
                    :items="filterProduct"
                    :filter="filter"
                    :filter-included-fields="includeFiled"
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <b-button title="Detail" size="xs" variant="success" v-on:click="addCosting(item.id)">
                            
                            <b-icon icon="plus" aria-label="Help"></b-icon>
                            Add
                        </b-button>
                        
                    </template>
                </b-table>
                <div class="mt-3">
                <b-pagination 
                        v-model="paging.currentPage" 
                        :total-rows="rows" 
                        :per-page="paging.perPage"
                        align="center"></b-pagination>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import apiProduct from '@/store/product-form/master-param.js'
import { mapGetters, mapActions } from 'vuex';
export default {
    data(){
        return{
            formName:'Supplier Selection',
            fields:[
                {key:'actions',label:'#'},
                'code','brand','article','type','coll_month','year','design',
                {key:'ref_sample',label:'No. Sample'},
                'size_description'
            ],
            dataSupplier:[],
            search:null,
            filter:null,
            brandOpt:[
                {value:'Valino Indo',text:'Valino Indo'},
                {value:'Arnold Palmer',text:'Arnold Palmer'},
                {value:'ANTHM',text:'ANTHM'},
                {value:'Kent Indo',text:'Kent Indo'},
                {value:'Valino Ladies',text:'Valino Ladies'},
            ],
            filters:{
                brand:'',
                month:'',
                year:'',
                status:'',
            },
            monthOpt:[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ],
            statusOpt:[
                
                {value:'Waiting For Approval',text:'Waiting For Approval'},
                {value:'On Development',text:'On Development'},
                {value:'Finish',text:'Finish'},
            ],
            includeFiled:['code','brand','article','type','coll_month','year','design','size_description','ref_sample','status'],
            yearOpt:[

            ],
            paging: {
                perPage: 10,
                currentPage: 1,
            },
        }
    },
    methods:{
        ...mapActions('productDesign',['getProduct','getProductDetail','GET_PRODUCT_DASHBOARD']),
        getData(){
            this.GET_PRODUCT_DASHBOARD().then(response => {
                console.log('product list',response);
                
                    this.dataSupplier = response.filter(i => i.status === 'On Development')
                 
            })
            apiProduct.dispatch('getListMasterParam')
            .then(response => {
                    this.monthOpt = response.data.payload.filter(d=>d.category ==='Month')
                    this.yearOpt = response.data.payload.filter(d=>d.category ==='Year')
            })
            .catch(error => {
                if (error.response.status === 400){
                     this.errorMessage = error.response.data.message
                }
            })
        },
        addCosting(id){
            this.$router.push({
                name:'AppsCostingFormPage',
                params:{productId:id}
            })
        }
    },
    created(){
        this.getData()
    },
    computed:{
        ...mapGetters('productDesign',['product','productList','productDetail','fabricList','accList','threadList','artworkList','interliningList']),
        ...mapGetters('session',['userData']),
        rows() {
            console.log(this.filterProduct.length);
            return this.filterProduct.length
        },
        filterProduct(){

            
           
                console.log(
                    
                );
                let brands = this.filters.brand
                let years  = this.filters.year
                let months = this.filters.month
                let statuses = this.filters.status
                return this.dataSupplier.filter(function(item){
                        let filtereds = true
                        if(brands && brands.length > 0){
                            filtereds = item.brand == brands
                        }
                        if(filtereds){
                            if(years && years.length > 0){
                                filtereds = item.year == years
                            }
                        }
                        if(filtereds){
                            if(months && months.length > 0){
                                filtereds = item.coll_month == months
                            }
                        }
                        if(filtereds){
                            if(statuses && statuses.length > 0){
                                filtereds = item.status == statuses
                            }
                        }
                        return filtereds
                    })
            
        }
    }
}
</script>