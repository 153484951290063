<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
            <b-breadcrumb-item >{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>
        <b-container fluid>
            <b-card
                
            >
                <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h3>Product Design</h3>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <b-button variant="success" size="sm" v-on:click="goToCreate()">Create</b-button>
                            </div>
                        </b-col>
                    </b-row>
                    
                </template>
                <ProductDesignList/>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import ProductDesignList from './ProductDesignList'
import {mapActions} from 'vuex';
export default {
    components: { ProductDesignList },
    name:'ProductDesign',
    data(){
        return {
            formName: 'Product Design',
            modulName: 'List',
            form:null,
        }
    },
    methods:{
        goToCreate(){
            this.$router.push({name:"AppsProductDesignFormPage"})
        }
    }
}
</script>