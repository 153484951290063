import {
    // *PPCM
    fnApiFetchFormMasterSubcon,
    fnApiInputOrderPPM,
    fnApiApproveData,
    fnApiSubconDailyData,
    fnApiSubconDailyDataV2,
    fnApiAllInputOrderPPM,
    fnApiAllInputOrderPPMV2,
    fnApiAllDataInputOrderPPM,
    fnApiGetPPCMInspection,
    fnApiGetDataPPCMInspection,
    fnApiGetDataPPCMInspectionV2,
    fnApiGetCountDataPPCMInspection,
    fnApiGetDataPPCMDelivery,
    fnApiGetDataFormDelivery,
    fnApiGetDataLogDelivery,
    fnApiMonitoringDetailData,

    fnApiGetDataPPCMReceive,
    fnApiGetDataFormReceive,
    fnApiGetDataLogReceive,
    fnApiGetDataFileUpload,
    fnApiGetDataImageUpload,

    // Adjustment
    fnApiGetAllDashboardInputAdjustment,
    fnApiGetAllDashboardAdjustment,
    fnApiGetFilterDashboardAdjustment,
    fnApiGetFilterCreateDashboardAdjustment,
    fnApiGetDataAdjustment,

    // Master Reason
    fnApiGetDataReason,

    // Count Monitoring Detail
    fnApiGetCountMonitoringDetail,

} from '../api/api-ppm.js';
const getDefaultState = () => {
    return {
        // PPCM
        stMstSub:null,
        stMstSubCount:0,
        stIoPPM:null,
        stIoPPMCount:0,
        stIoSubconDaily:null,
        stIoSubconDailyCount:0,
        stAllAppr:null,
        stAllPPM:null,
        stAllPPMCount:0,
        stAllDataPPMCount:0,
        stAllPPMV:null,
        stAllPPMV2:null,
        stAllPPMV2Count:0,
        stAllDtail:null,
        stAllDtailCount:0,
        stPPCMIns:null,
        stPPCMInsV2:null,
        stPPCMInsCount:0,
        stPPCMInsCountV2:0,
        stPPCMInsData:null,
        stPPCMInsDataCount:0,
        stPPCMDelData:null,
        stPPCMDelDataCount:0,
        stPPCMDelvrData:null,
        stPPCMDelvrDataCount:0,
        stPPCMLogDeliveryDt:null,
        stPPCMLogDeliveryDtCount:0,

        stPPCMRecData:null,
        stPPCMRecDataCount:0,
        stPPCMReceiveData:null,
        stPPCMReceiveDataCount:0,
        stPPCMLogReceiveDt:null,
        stPPCMLogReceiveDtCount:0,

        stFileUpload:null,
        stFileUploadCount:0,
        stImageUpload:null,
        stImageUploadCount:0,

        // Adjustment
        stAdjustment:null,
        stAdjustmentCount:0,
        stAllAdjustmentCount:0,
        stCreateAdjustment:null,
        stCreateAdjustmentCount:0,
        stAllCreateAdjustmentCount:0,

        stAppAdjustment:null,
        stAppAdjustmentCount:0,

        // Master Reason
        StDataReason:null,
        StDataReasonCount:0,

        // Count Monitoring Detail
        StMonitoringCount:0
    }
}
const state = getDefaultState()
const mutations = {
    resetState(state) {
        Object.assign(state, getDefaultState())
    },
        // PPCM
    updatestIoPPMList(state, data) {
        state.stIoPPM = data
        state.stIoPPMCount = data.length
    },
    updateRptMstSubData(state, data) {
        state.stMstSub = data
        state.stMstSubCount = data.length
    },
    updatestIoSubconDailyList(state, data) {
        state.stIoSubconDaily = data
        state.stIoSubconDailyCount = data.length
    },
    updateAllIOAppr(state, data) {
        state.stAllAppr = data
    },
    updateAllIOPPM(state, data) {
        state.stAllPPM = data
        state.stAllPPMCount = data.length
    },
    updateAllIOPPMV(state, data) {
        state.stAllPPMV = data
        state.stAllPPMVCount = data.length
    },
    updateAllIOPPMV2(state, data) {
        state.stAllPPMV2 = data
        state.stAllPPMV2Count = data.length
    },
    updateAllDataIOPPM(state, data) {
        state.stAllDataPPMCount = data
    },
    updateMntrDtail(state, data) {
        state.stAllDtail = data
        state.stAllDtailCount = data.length
    },
    updatePPCMInsData(state, data) {
        state.stPPCMIns = data
    },
    GetPPCMInsData(state, data) {
        state.stPPCMInsData = data
        state.stPPCMInsDataCount = data.length
    },
    GetPPCMInsCount(state, data) {
        state.stPPCMInsCountV2 = data
    },
    GetPPCMInsDataV2(state, data) {
        state.stPPCMInsV2 = data
    },
    GetDeliveryData(state, data) {
        state.stPPCMDelData = data
        state.stPPCMDelDataCount = data.length
    },
    GetDataFormDelvr(state, data) {
        state.stPPCMDelvrData = data
        state.stPPCMDelvrDataCount = data.length
    },
    GetLogDataDelivery(state, data) {
        state.stPPCMLogDeliveryDt = data
        state.stPPCMLogDeliveryDtCount = data.length
    },
    GetReceiveData(state, data) {
        state.stPPCMRecData = data
        state.stPPCMRecDataCount = data.length
    },
    GetDataFormReceive(state, data) {
        state.stPPCMReceiveData = data
        state.stPPCMReceiveDataCount = data.length
    },
    GetLogDataReceive(state, data) {
        state.stPPCMLogReceiveDt = data
        state.stPPCMLogReceiveDtCount = data.length
    },
    GetDataFileUpload(state, data) {
        state.stFileUpload = data
        state.stFileUploadCount = data.length
    },
    GetDataImageUpload(state, data) {
        state.stImageUpload = data
        state.stImageUploadCount = data.length
    },
// Adjustment
    GetAllDataDashboardAdjustment(state, data) {
        state.stAllAdjustmentCount = data.length
    },
    GetAllDataCreateDashboardAdjustment(state, data) {
        state.stAllCreateAdjustmentCount = data.length
    },
    GetFilterDataDashboardAdjustment(state, data) {
        state.stAdjustment = data
        state.stAdjustmentCount = data.length
    },
    GetFilterDataCreateDashboardAdjustment(state, data) {
        state.stCreateAdjustment = data
        state.stCreateAdjustmentCount = data.length
    },
    // Approve Adjustment
    StGetDataAdjustment(state, data) {
        state.stAppAdjustment = data
        state.stAppAdjustmentCount = data.length
    },
    // Get Data Master Reason
    StGetDataReason(state, data) {
        state.StDataReason = data
        state.StDataReasonCount = data.length
    },

    // Get Data Master Reason
    StGetMonitoringCount(state, data) {
        state.StMonitoringCount = data
    },
}

const actions = {
        // *PPCM
        getDataFormMasterSubcon({commit}, filters) {
            return new Promise((resolve, reject) => {
                fnApiFetchFormMasterSubcon(filters).then( rsp => {
                    commit('updateRptMstSubData', rsp.data.payload)
                    if(rsp.data.payload.length == 0) {
                        resolve('No Data')
                    }
                    resolve('Success')
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getInputIOPPM({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiInputOrderPPM(filter).then( rsp => {
                    commit('updatestIoPPMList', rsp.data.payload)
                    if(rsp.data.payload.length == 0) {
                        resolve('No Data')
                    }
                    resolve('Success')
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getSubconDailyData({commit}, filter) {
            fnApiSubconDailyData(filter).then( rsp => {
                commit('updatestIoSubconDailyList', rsp.data.payload)
            })
        },
        getPPCMInspectionData({commit}, filter) {
            // console.log(filter)
            return new Promise((resolve, reject) => {
                fnApiGetPPCMInspection(filter).then( rsp => {
                commit('updatePPCMInsData', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data.payload)
            }).catch(err => {
                reject(err)
                })
            })
        },
        getPPCMInspectionFormData({commit}, filter) {
            fnApiGetDataPPCMInspection(filter).then( rsp => {
                commit('GetPPCMInsData', rsp.data.payload)
            })
        },
        getDataPPCMInspectionV2({commit}, filter) {
            fnApiGetDataPPCMInspectionV2(filter).then( rsp => {
                commit('GetPPCMInsDataV2', rsp.data.payload)
            })
        },
        getDataCountPPCMInspectionV2({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetCountDataPPCMInspection(filter).then( rsp => {
                    commit('GetPPCMInsCount', rsp.data.payload)
                    if(rsp.data.payload.length == 0) {
                        resolve('No Data')
                    }
                    resolve(rsp.data.payload)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getAllDelivery({commit}, filter) {
            return new Promise((resolve, reject) => {
            fnApiGetDataPPCMDelivery(filter).then( rsp => {
                commit('GetDeliveryData', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                    resolve(rsp.data.payload)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getPPCMDeliveryFormData({commit}, filter) {
            fnApiGetDataFormDelivery(filter).then( rsp => {
                commit('GetDataFormDelvr', rsp.data.payload)
            })
        },
        GetDataPPCMDeliveryLog({commit}, filter) {
            fnApiGetDataLogDelivery(filter).then( rsp => {
                commit('GetLogDataDelivery', rsp.data.payload)
            })
        },

        getAllReceive({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataPPCMReceive(filter).then( rsp => {
                commit('GetReceiveData', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                    resolve(rsp.data.payload)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        getPPCMReceiveFormData({commit}, filter) {
            fnApiGetDataFormReceive(filter).then( rsp => {
                commit('GetDataFormReceive', rsp.data.payload)
            })
        },
        GetDataPPCMReceiveyLog({commit}, filter) {
            fnApiGetDataLogReceive(filter).then( rsp => {
                commit('GetLogDataReceive', rsp.data.payload)
            })
        },
        DataFileUpload({commit}, filter) {
            fnApiGetDataFileUpload(filter).then( rsp => {
                commit('GetDataFileUpload', rsp.data.payload)
            })
        },
        DataImageUpload({commit}, filter) {
            fnApiGetDataImageUpload(filter).then( rsp => {
                commit('GetDataImageUpload', rsp.data.payload)
            })
        },
        getALLIOPPM({commit}, filter) {
            return new Promise((resolve, reject) => {
            fnApiAllInputOrderPPM(filter).then( rsp => {
                commit('updateAllIOPPM', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data.payload)
            }).catch(err => {
                reject(err)
                })
            })
        },
        getALLIOPPMV2({commit}, filter) {
            // console.log(filter)
            return new Promise((resolve, reject) => {
            fnApiAllInputOrderPPMV2(filter).then( rsp => {
                commit('updateAllIOPPMV', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        getALLDataIOPPM({commit}, filter) {
            // console.log(filter)
            return new Promise((resolve, reject) => {
            fnApiAllDataInputOrderPPM(filter).then( rsp => {
                commit('updateAllDataIOPPM', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve(rsp.data.payload)
            }).catch(err => {
                reject(err)
                })
            })
        },
        getSubconDailyDataV2({commit}, filters) {
            // console.log('filter: '+filters)

            return new Promise((resolve, reject) => {
                fnApiSubconDailyDataV2(filters).then( rsp => {
                    commit('updateAllIOPPMV2', rsp.data.payload)
                    if(rsp.data.payload.length == 0) {
                        resolve('No Data')
                    }
                    resolve(rsp.data.payload)
                }).catch(err => {
                    reject(err)
                    })
                })
        },
        getApproveData({commit}, filters) {
            // console.log('filter: '+filters)

            return new Promise((resolve, reject) => {
                fnApiApproveData(filters).then( rsp => {
                    commit('updateAllIOAppr', rsp.data.payload)
                    if(rsp.data.payload.length == 0) {
                        resolve('No Data')
                    }
                    resolve(rsp.data.payload)
                }).catch(err => {
                    reject(err)
                    })
                })
        },
        getMonitoringDetailData({commit}, filters) {
            // console.log('filter: '+filters)

            return new Promise((resolve, reject) => {
                fnApiMonitoringDetailData(filters).then( rsp => {
                    commit('updateMntrDtail', rsp.data.payload)
                    if(rsp.data.payload.length == 0) {
                        resolve('No Data')
                    }
                    resolve('Success')
                }).catch(err => {
                    reject(err)
                    })
                })
        },

    // OPNAME
        // Get All Data Create Adjustment
        getAllCreateAdjustment({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetAllDashboardInputAdjustment(filter).then( rsp => {
                commit('GetAllDataCreateDashboardAdjustment', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Filter Data Create Adjustment
        getFilterCreateAdjustment({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetFilterCreateDashboardAdjustment(filter).then( rsp => {
                commit('GetFilterDataCreateDashboardAdjustment', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        // Get All Data Adjustment
        getAllAdjustment({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetAllDashboardAdjustment(filter).then( rsp => {
                commit('GetAllDataDashboardAdjustment', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        // Get Filter Data Adjustment
        getFilterAdjustment({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetFilterDashboardAdjustment(filter).then( rsp => {
                commit('GetFilterDataDashboardAdjustment', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },
        //Get Data Approve Adjusment
        GetDataAdjustment({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataAdjustment(filter).then( rsp => {
                commit('StGetDataAdjustment', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        //Get Data Master Reason
        getDataReason({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetDataReason(filter).then( rsp => {
                commit('StGetDataReason', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        //Get Count Dashboard Monitoring Detail
        getMonitoringDetailCount({commit}, filter) {
            return new Promise((resolve, reject) => {
                fnApiGetCountMonitoringDetail(filter).then( rsp => {
                commit('StGetMonitoringCount', rsp.data.payload)
                if(rsp.data.payload.length == 0) {
                    resolve('No Data')
                }
                resolve('Success')
            }).catch(err => {
                reject(err)
                })
            })
        },

        clearStatePpm({commit}) {
            commit('resetState')
            localStorage.removeItem('activeReport')
        },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}