<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item class="fw-semi-bold">{{formName}}</b-breadcrumb-item>

        </b-breadcrumb>

        <b-container fluid>

            <b-card>
                 <template v-slot:header >
                    <b-row align-v="center">
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Weekly Activity Plan</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <b-button title="Add" v-if="roles_list.includes('sales') || roles_list.includes('superuser')" v-on:click="AddNew()" size="sm" variant="outline-success" style='margin-right:5px;'>
                                    <b-icon icon="journal-plus" aria-label="Add"></b-icon>
                                    Add New
                                </b-button>
                            </div>
                        </b-col>


                    </b-row>
                </template>

                <b-list-group flush>
                    <b-list-group-item>
                        <b-row>
                            <b-col sm='4'>
                                <b-form-group
                                label="NIK"
                                label-for="Employee_ID"
                                label-cols-sm="4"
                                label-align-sm="left"
                                label-size="sm"
                                >
                                <b-form-input
                                    id="Employee_ID"
                                    :disabled="true"
                                    v-model="form.employee_nik"
                                    trim
                                ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col sm='4'>
                                <b-form-group
                                label="Sales Person"
                                label-for="EmployeeName"
                                label-cols-sm="4"
                                label-align-sm="left"
                                label-size="sm"
                                >
                                <b-form-input
                                    id="EmployeeName"
                                    :disabled="true"
                                    v-model="form.employee_name"
                                    trim
                                ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                <b-table
                    :no-border-collapse="true"
                    striped
                    class="header-background "
                    hover
                    outlined
                    show-empty
                    responsive
                    :tbody-tr-class="rowClass"
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    :fields="fields"
                    :items="dataTable"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>

                    <!-- Period Date -->
                    <template #cell(period_date)="row">
                        <span v-if="row.value !== null" v-html="FormatDate(row.value)">{{row.value}}</span>
                    </template>

                    <!-- Status Approval -->
                    <template #cell(approval_status)="row">
                        <span v-if="row.value !== null " v-html="FormatStatus(row.value)">{{row.value}}</span>
                    </template>

                    <!-- <template v-slot:cell(actions)="{item}"> -->
                    <template #cell(action)="row">
                        <b-button title="View" size="xs" variant="outline-info" v-on:click="viewData(row.item.id)" style='margin-right:5px;'>
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>

                        </b-button>
                        <b-button title="Edit" size="xs" v-if="row.item.approval_status == 'rejected'" variant="outline-warning" v-on:click="editData(row.item.id)">
                            <b-icon icon="pencil-fill" aria-label="Edit"></b-icon>

                        </b-button>
                    </template>
                </b-table>
                <div class="my-1">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="right"
                        size="sm"
                    >
                    <template #first-text><span class="">First</span></template>
                    <template #prev-text><span class="">Prev</span></template>
                    <template #next-text><span class="">Next</span></template>
                    <template #last-text><span class="">Last</span></template>
                    <template #page="{ page, active }">
                        <b v-if="active">{{ page }}</b>
                        <!-- <b v-if="active">{{Pagination(page)}} {{ page }}</b> -->
                        <i v-else>{{ page }}</i>
                    </template>

                    </b-pagination>
                </div>
            </b-card>
            <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
        </b-container>
    </div>
</template>
<script>
import Loader from "@/components/Loader/Loader";
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    components: {
        Loader,
    },
    data(){
        return{
            isLoadingData:false,
            formName:'Weekly Activity Plan',
            monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            sortBy: 'period_date',
            sortDesc: true,
            roles_list:[],
            dataTable:[],
            fields:[
                {key:'action', label:'Action', sortable:false},
                {key:'transaction_id', label:'Plan Id', sortable:true},
                {key:'period_date', label:'Period', sortable:true},
                {key:'sales_nik', label:'Sales Nik', sortable:false},
                {key:'sales_name', label:'Sales Name', sortable:false},
                {key:'approval_status', label:'Status', sortable:false},
                {key:'remark', label:'Reason', sortable:false},
            ],
            paging:{
                perPage:10,
                currentPage:1,
            },
            isLoading:false,
            form_filter:null,
            form:{
                role:null,
                start_limit:null,
                end_limit:null,
                employee_nik:null,
                employee_name:null,
            },
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['stWeekPln','stWeekPlnCount']),
        rows(){
            if(this.dataTable != null){
                return this.dataTable.length
            }
        }
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['getDashboardWeeklyPlanning']),
        setData(){
            let roles = this.userData.roles
            if (roles.length > 0) {
                for (let data in roles) {
                if (roles[data].app == 'SFC') {
                    let lower = roles[data].role_list.map(string => {
                    return string.toLowerCase();
                    });
                    this.roles_list = lower
                }
                }
            }
            this.form.employee_nik   = this.userData.nik
            this.form.employee_name  = this.userData.fullname
            this.LoadData()
        },
        LoadData() {
            // start loading
            this.isLoadingData = true
            this.getDashboardWeeklyPlanning({
                sales:this.form.employee_nik,
                role:this.roles_list,
                filter:this.form_filter,
                start_limit:this.form.start_limit,
                end_limit:this.form.end_limit,
            })
            .then(rsp => {
                if(this.stWeekPlnCount == 0) {
                    this.showNotification({type:'info', message:'Data Not Found'})
                }
                this.dataTable = this.stWeekPln
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            }).finally(()=>{
                // stop Loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
            })
        },
        rowClass(item, type) {
            let RowClass = null
            // if (item !== null) {
            //     if (item.approval_status !== 'approve') {
            //         RowClass = 'display-hidden'
            //     }
            // }
            return RowClass
        },
        FormatDate(value) {
            if(value == null || value == undefined || value == '') {
                    return ' '
                } else {
                let date = new Date(value)
                let tgl = date.toISOString().slice(8,10)
                let bulan = this.monthNames[date.getMonth()]
                let tahun = date.toISOString().slice(0,4)
                date = bulan+' '+tahun
                return date
            }
        },
        FormatStatus(value) {
            if (value.length > 0) {
                    value = value.toLowerCase()

                if (value.includes('waiting')) {
                    value = "Waiting Approval"
                }
                if (value.length > 1) {
                    value = value.charAt(0).toUpperCase() + value.slice(1);
                }
            }
            return value
        },
        AddNew() {
            this.$router.push({ name: 'SFCWeeklyActivityPlanForm'})
        },
        viewData(data) {
            this.$router.push({ name: 'SFCWeeklyActivityPlanForm', params: { BackHistory:'WeeklyPlanning', page:'view', idData:data } })
        },
        editData(data) {
            this.$router.push({ name: 'SFCWeeklyActivityPlanForm', params: { page:'edit', idData:data } })
        },
    },
    created(){
        this.setData()
    },
}
</script>
<style src="../SalesForce.scss" lang="scss" scoped />