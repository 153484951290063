import { resolve } from 'core-js/fn/promise'
import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchMaterialDevDashboard,
    fnApiPostMaterialDevDetail,
    fnApiFetchMaterialDevDetail,
    fnApiPostMaterialDevActivity,
    fnApiFetchMaterialDevActivity,
    fnApiPostMaterialActivityFiles
}from '../../api/api-aplications-product'

Vue.use(Vuex)

const getDefaultState = () => {
    return {
        ST_MATERIALDEV_DASHBOARD:[],
        ST_MATERIALDEV_DETAIL:[],
        ST_MATERIALDEV_ACTIVITY:[],
    }
}

const state = getDefaultState()

const getters = {
    MATERIALDEV_DASHBOARD(state){
        return state.ST_MATERIALDEV_DASHBOARD
    },
    MATERIALDEV_DETAIL(state){
        return state.ST_MATERIALDEV_DETAIL
    },
    MATERIALDEV_ACTIVITY(state){
        return state.ST_MATERIALDEV_ACTIVITY
    }
}

const mutations = {
    GET_MATERIALDEV_DASHBOARD(state,item){
        state.ST_MATERIALDEV_DASHBOARD = item
    },
    GET_MATERIALDEV_DETAIL(state,item){
        state.ST_MATERIALDEV_DETAIL = item
    },
    GET_MATERIALDEV_ACTIVITY(state,item){
        state.ST_MATERIALDEV_ACTIVITY = item
    }
}

const actions   = {
    GET_MATERIALDEV_DASHBOARD({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMaterialDevDashboard()
            .then(response => {
                resolve(response)
                commit('GET_MATERIALDEV_DASHBOARD', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    GET_MATERIALDEV_DETAIL({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMaterialDevDetail()
            .then(response => {
                console.log('actions mdev',response.data.payload);
                resolve(response)
                commit('GET_MATERIALDEV_DETAIL', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_MATERIALDEV_DETAIL(context, MaterialDevDetail){
        
        return new Promise((resolve,reject)=>{
            fnApiPostMaterialDevDetail(MaterialDevDetail)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },
    GET_MATERIALDEV_ACTIVITY({commit}){
        return new Promise((resolve, reject)=>{
            fnApiFetchMaterialDevActivity()
            .then(response => {
                resolve(response)
                commit('GET_MATERIALDEV_ACTIVITY', response.data.payload)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    POST_MATERIALDEV_ACTIVITY(context, MaterialDevActivity){
        
        return new Promise((resolve,reject)=>{
            fnApiPostMaterialDevActivity(MaterialDevActivity)
            .then(response => {
                resolve(response)
            })
            .catch(error =>{
                reject(error)
            })
        })
    },
    POST_ACTIVITY_FILES(context,MaterialActivityFile){
        return new Promise((resolve,reject) => {
            fnApiPostMaterialActivityFiles(MaterialActivityFile)
            .then(rsp=>{
                resolve(rsp)
            }).catch(err=>{
                return err
            })
        })
    }

}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}