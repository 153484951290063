import Vue from 'vue'
import Vuex from 'vuex'
import { getAPI } from '../api/api-base.js'


Vue.use(Vuex)

const getDefaultState = () => {
  return {
    userData: localStorage.getItem('userData') || null,
    firstLogin: localStorage.getItem('isFirstLogin') || null,
    businessUnit: localStorage.getItem('businessUnit') || null,
  }
}
const state = getDefaultState()
const getters = {
  firstLogin (state) {
    return state.firstLogin
  },
  userData(state){
    return JSON.parse(state.userData)
  }
}
const mutations = {
  updateUserData (state, { data }) {
    data.business_unit = state.businessUnit
    state.userData = data
    state.firstLogin = data.is_first_login

    localStorage.setItem('userData', JSON.stringify(data))
    localStorage.setItem('isFirstLogin', data.is_first_login);
  },
  resetState() {
    Object.assign(state, getDefaultState())
  },
}
const actions = {
  changePassword(context, formData) {
    return new Promise((resolve, reject) => {
      getAPI.post('/core/api/account/change-password/', {
        headers: {'Authorization': `Bearer ${this.accessTokenData}`},
        username: formData.username,
        old_password: formData.old_password,
        new_password: formData.new_password
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  updateAcctDetail(context) {
    return new Promise((resolve, reject) => {
      // send the username and password to the backend API:
      getAPI.get('/core/api/account/detail/', {
        headers: {'Authorization': `Bearer ${this.accessTokenData}`}
      })
      //if successful update local storage:
        .then(response => {
          context.commit('updateUserData', { data: response.data })
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  reloadState(context){
    context.commit('resetState')
  },
}

export default new Vuex.Store({
  namespaced: true,
  state,
  getters,
  mutations,
  actions
})
