<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
         <router-link tag="a" to="/app/dashboard">
          {{ formName }}
        </router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ modulName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid>
      <b-card>
        <template v-slot:header>
          <b-row>
            <b-col sm="auto" md="9">
              <div class="text-left">
                <h3>Workflow Close</h3>
              </div>
            </b-col>
            <b-col sm="auto" md="3">
              <div class="text-right">
                <b-button
                    title="Back"
                    style="margin-right: 10px"
                    size="sm"
                    variant="warning"
                    v-on:click="backToWf()"
                  >
                    <b-icon
                      font-scale="1"
                      icon="arrow-left"
                      variant="dark"
                    ></b-icon>
                    Back
                  </b-button>
              </div>
            </b-col>
          </b-row>
        </template>
        <b-card sub-title="Filter">
          <b-row>
            <b-col sm="auto" md="4" lg="4">
              <b-form-group
                label="Document Name"
                label-for="document-name-select"
                label-cols="3"
                label-size="sm"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-select
                  id="document-name-select"
                  v-model="filters.DocumentName"
                  :options="DocumentNameOpt"
                  :aria-describedby="ariaDescribedby"
                  size="sm"
                >
                  <template #first>
                    <option value="">All</option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                label="Search"
                label-for="search"
                label-cols="3"
                label-size="sm"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="inputSearch"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                    trim
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Search</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <div
          class="d-flex justify-content-center mb-3"
          style="position: relative; top: 100px; z-index: 10"
        >
          <b-spinner
            variant="primary"
            v-show="isLoading"
            label="Loading..."
          ></b-spinner>
        </div>
        <b-table
          striped
          hover
          show-empty
          small
          responsive
          :per-page="paging.perPage"
          :current-page="paging.currentPage"
          :items="filterProduct"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="includeFiled"
        >
          <template #cell(actions)="{ item }">
            <b-button
              style="margin-right: 10px"
              title="Approve"
              size="xs"
              variant="info"
              v-on:click="Detail(item.callback_url,item.id)"
            >
              Detail
            </b-button>
          </template>
        </b-table>
        <div class="mt-3">
          <b-pagination
            v-model="paging.currentPage"
            :total-rows="rows"
            :per-page="paging.perPage"
            align="center"
          ></b-pagination>
        </div>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import { APIPATH_WF } from "@/api/api-paths";
import { fnGET, fnPOST, fnPOSTWF, fnPUT, fnDELETE } from "@/api/api-methods";
export default {
  name: "WorkflowPageClose",
  data() {
    return {
      formName: "Workflow",
      modulName: "Close",
      token: null,
      dataWorkFlow: [],
      isLoading: false,
      includeFiled: ["doc_no", "doc_name", "apps_name", "next_processor_name", "update_date", "creator_name"],
      fields: [
        { key: "actions", label: "Action" },
        { key: "doc_no", label: "Doc No" },
        { key: "doc_name", label: "Doc name" },
        { key: "apps_name", label: "Apps Name" },
        { key: "update_date", label: "Pending Date", sortable: true, formatter: "formatDateAssigned" },
        { key: "creator_name", label: "Requester" },
        { key: "create_date", label: "Request Date",  sortable: true, formatter: "formatDateAssigned" },
        { key: "wf_state", label: "Status" },
        {
          key: "create_date",
          label: "Date",
          sortable: true,
          formatter: "formatDateAssigned",
        },
      ],
      filters: {
        DocumentName: "",
      },
      filter: null,
      search: null,
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      DocumentNameOpt: [],
    };
  },
  computed: {
    rows() {
      return this.filterProduct.length;
    },
    filterProduct() {
      let documentsName = this.filters.DocumentName;
      return this.dataWorkFlow.filter(function (item) {
        let filtereds = true;
        if (documentsName && documentsName.length > 0) {
          filtereds = item.doc_name == documentsName;
        }
        return filtereds;
      });
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;
      this.token = localStorage.getItem("access_token");
      fnPOSTWF(APIPATH_WF.closedwf, {
        data_token: this.token,
      })
        .then((response) => {
          console.log("data_log", response.data.payload);
            this.dataWorkFlow = response.data.payload;
            for (let i = 0; i < this.dataWorkFlow.length; i++) {
              var data = {
                value: this.dataWorkFlow[i].doc_name,
                text: this.dataWorkFlow[i].doc_name,
              };
            }
            this.DocumentNameOpt.push(data);
            this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = true;
          console.log("error_log", error);
        });
    },

    formatDateAssigned(value) {
      if(value){
        const d = new Date(value).toLocaleString("id-ID")
        return d;
      }
    },

    Detail(url, id) {
      const getPath = url+"?param="+btoa(id);
      // console.log("param",getPath);
      window.open(getPath);
    },

    backToWf() {
      this.$router.push({
        name: "DashboardPage",
      });
    },
  },
};
</script>

<style>
</style>