import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchHealthSelfDeclarationAccess
} from '../../api/api-applications-health.js'

Vue.use(Vuex)


const actions = {
    postSelfDeclaration(context, formSelfDeclaration) {
        // console.log(formSelfDeclaration)
      return new Promise((resolve, reject) => {
        fnApiFetchHealthSelfDeclarationAccess(formSelfDeclaration)
            .then(response => {
                
                resolve(response)
            })
            .catch(error => {
                
                reject(error)
            })
      })
    }
  }

export default new Vuex.Store({
    namespaced: true,
    actions,
})
