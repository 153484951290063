<template>
    <div class="wrapper">
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
                <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item >
                <router-link tag="a" to="/app/appsplm-costing">{{formName}}</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{modulName}}</b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid="sm">
            <b-card
                header="Approval Design Form"
                
            >
                <b-row>
                    <b-col sm="auto" md="5">
                        
                        
                        <b-card>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    No. <code>*</code>
                                </template>
                
                                <label>{{product.code}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Duration <code>*</code>
                                </template>
                
                                <label>{{product.duration}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Brand <code>*</code>
                                </template>
                
                                <label>{{product.brand}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Collection Name<code>*</code>
                                </template>
                                <label> {{product.collection_name}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Quartal<code>*</code>
                                </template>
                                <label> {{product.quartal}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Year<code>*</code>
                                </template>
                                <label> {{product.year}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"  
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Prod. Month<code>*</code>
                                </template>
                                <label> {{product.prod_month}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Coll. Month<code>*</code>
                                </template>
                                <label> {{product.coll_month}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Sub. Brand<code>*</code>
                                </template>
                                <label> {{product.subbrand}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Category<code>*</code>
                                </template>
                                <label> {{product.category}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Color<code>*</code>
                                </template>
                                <label> {{product.color}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Itensity<code>*</code>
                                </template>
                                <label> {{product.itensity}}</label>
                            </b-form-group>
                            <b-form-group
                                
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Article<code>*</code>
                                </template>
                                <label> {{product.article}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Repeat Sample<code>*</code>
                                </template>
                                <label> {{product.repeat_sample}}</label>
                            </b-form-group>
                            
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Ref. Sample<code>*</code>
                                </template>
                                <label> {{product.ref_sample}}</label>
                            </b-form-group>
                            
                            <b-form-group
                                label-cols-lg   ="4" 
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Old Sample No.<code>*</code>
                                </template>
                                <label> {{product.old_sample_no}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Guidance<code>*</code>
                                </template>
                                <label> {{product.guidance}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Size Spec<code>*</code>
                                </template>
                                <label> {{product.size_spec}}</label>
                            </b-form-group>
                            <b-form-group
                                label-cols-lg   ="4"
                                label-size      ="sm"
                            >
                                <template v-slot:label>
                                    Note<code>*</code>
                                </template>
                                <label> {{product.note}}</label>
                            </b-form-group>

                            <template v-for="images in productImg">
                                    <b-form-group
                                        :key="images.img_id"
                                        label-size ="sm">
                                        <template v-slot:label>
                                            {{images.img_label}}
                                        </template>
                                        <b-link :href="images.img_src" target="_blank">{{images.img_name}}</b-link>
                                    </b-form-group>
                            </template>
                            
                        </b-card>
                    </b-col>
                    
                    <b-col sm="auto" md="7">
                        <b-card sub-title="Fabric">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="fabricData"
                                :fields="fieldsFabric"
                            >
                            <template #cell(img_src)="{item}"  >
                               <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                            </template>
                            

                            </b-table>
                        </b-card>
                        <b-card sub-title="Accessories">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="accData"
                                :fields="fieldsAcc"
                            >
                            <template #cell(img_src)="{item}"  >
                               <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                            </template>
                            

                            </b-table>
                        </b-card>
                        <b-card sub-title="Thread">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="threadData"
                                :fields="fieldsThread"
                            >
                                <template #cell(img_src)="{item}"  >
                                <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                                </template>
                                

                            </b-table>
                        </b-card>
                        <b-card sub-title="Artwork">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="artworkData"
                                :fields="fieldsArtwork"
                            >
                                <template #cell(img_src)="{item}"  >
                                    <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                                </template>
                                
                            </b-table>
                        </b-card>
                        <b-card sub-title="Interlining">
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="interliningData"
                                :fields="fieldsInterlining"
                            >
                                <template #cell(img_src)="{item}"  >
                                <b-link :href="item.img_src" target="_blank">{{item.img_name}}</b-link>
                                </template>
                                

                            </b-table>
                        </b-card>
                    
                        <b-card>
                            <template v-slot:header >
                                <b-row>
                                    <b-col sm="auto" md="9">
                                        <div class="text-left">
                                            <h4>Supplier Selection</h4>
                                        </div>
                                    </b-col>
                                    <b-col sm="auto" md="3">
                                        
                                    </b-col>
                                    
                                </b-row>
                            </template>
                            <b-table
                                responsive
                                show-empty
                                small
                                fixed
                                :items="supplierData"
                                :fields="fieldsSupplier"
                            >
                                
                            </b-table>
                        </b-card>
                        <b-card>
                            <template v-slot:header >
                                <b-row>
                                    <b-col sm="auto" md="9">
                                        <div class="text-left">
                                            <h4>Costing</h4>
                                        </div>
                                    </b-col>
                                    <b-col sm="auto" md="3">
                                        
                                    </b-col>
                                    
                                </b-row>
                            </template>
                            <template v-if="showButton">
                                <b-row>
                                        <b-col cols="auto" sm="12">
                                            <!-- Code Supplier -->
                                                
                                                    <b-form-group
                                                        label-cols          ="4" 
                                                        label-cols-lg       ="4" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Code Supplier <code>*</code>
                                                        </template>
                                                        <label> {{form.nameSupplier}} </label>
                                                    </b-form-group>
                                                
                                            <!-- est-cost -->
                                                
                                                    <b-form-group
                                                        label-cols          ="4" 
                                                        label-cols-lg       ="4" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Est. Cost <code>*</code>
                                                        </template>
                                                        <label> {{Number(form.estCost).toLocaleString('id-ID',{style:'currency',currency:'IDR'})}}</label>
                                                    </b-form-group>
                                            <!-- sample-number -->
                                               
                                                    <b-form-group
                                                        label-cols          ="4" 
                                                        label-cols-lg       ="4" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Sample Number <code>*</code>
                                                        </template>
                                                        <label> {{product.ref_sample}}</label>
                                                    </b-form-group>
                                                <!-- remark -->
                                                
                                                    <b-form-group
                                                        label-cols          ="4" 
                                                        label-cols-lg       ="4" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Remark 
                                                        </template>
                                                        <label> {{form.sampleNumber}}</label>
                                                    </b-form-group>
                                                
                                        </b-col>
                                    </b-row>
                            </template>
                            <template v-if="!showButton">
                            <validation-observer ref="observer" v-slot="{handleSubmit}">
                                <b-form @submit.prevent="handleSubmit(onSubmit)">
                                    <b-row>
                                        <b-col cols="auto">
                                            <!-- Code Supplier -->
                                                <validation-provider
                                                    name="code-supplier"
                                                    :rules="{ required: true }"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-group
                                                        label-cols          ="3" 
                                                        label-cols-lg       ="3" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Code Supplier <code>*</code>
                                                        </template>
                                                        <b-form-select  value-field="value" text-field="text" :options="codeSupplierOpt" id="input-code-supplier" v-model="form.nameSupplier"  :state="getValidationState(validationContext)"  trim></b-form-select>
                                                        <b-form-invalid-feedback id="input-code-supplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            <!-- est-cost -->
                                                <validation-provider
                                                    name="est-cost"
                                                    :rules="{ required: true }"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-group
                                                        label-cols          ="3" 
                                                        label-cols-lg       ="3" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Est. Cost <code>*</code>
                                                        </template>
                                                        <b-form-input  id="input-est-cost" v-model="form.estCost"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                        <b-form-invalid-feedback id="input-est-cost">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                            <!-- sample-number -->
                                                <validation-provider
                                                    name="sample-number"
                                                    :rules="{ required: true }"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-group
                                                        label-cols          ="3" 
                                                        label-cols-lg       ="3" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Sample Number <code>*</code>
                                                        </template>
                                                        <b-form-input readonly  id="input-sample-number" :value="product.ref_sample"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                        <!-- <b-input-group class="mt-3">
                                                            <template #prepend>
                                                                <b-input-group-text>{{product.ref_sample}}</b-input-group-text>
                                                            </template>
                                                            <b-form-input  id="input-sample-number" v-model="form.sampleNumber"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                        </b-input-group> -->
                                                        <b-form-invalid-feedback id="input-sample-number">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                                <!-- remark -->
                                                <validation-provider
                                                    name="remark"
                                                    :rules="{ required: false }"
                                                    v-slot="validationContext"
                                                >
                                                    <b-form-group
                                                        label-cols          ="3" 
                                                        label-cols-lg       ="3" 
                                                        label-size          ="sm"
                                                    >
                                                        <template v-slot:label>
                                                            Remark
                                                        </template>
                                                        <b-form-input id="input-remark" v-model="form.sampleNumber"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                                        <b-form-invalid-feedback id="input-remark">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-form-group>
                                                </validation-provider>
                                                <template v-if="roles" >
                                                <b-button  variant="warning" v-on:click="clearForm()" block size="sm">Cancel</b-button>
                                                <b-button variant="success" block size="sm" type="submit">Save</b-button>
                                                </template>
                                                
                                        </b-col>
                                    </b-row>
                                </b-form>
                            </validation-observer>
                            </template>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import { mapGetters,mapActions } from 'vuex';
import apiSupplier from '@/store/product-form/product-supplier.js'
import apiCosting from  '@/store/product-form/product-costing.js'
export default {
    props:['productId'],
    data(){
        return{
            formName:'Supplier Selection Form',
            modulName:'Form',
            isValid:false,
            show:false,
            showButton:false,
            form:{
                cositngId:null,
                nameSupplier:null,
                estCost:null,
                sampleNumber:null,
                remark:null,

            },
            status:null,
            date:null,
        
            fieldsFabric:[
                'code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsAcc:[
                'code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsThread:[
                'code','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsArtwork:[
                'code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsInterlining:[
                'code','type','description','used_as','detail',
                {key:'img_src',label:'images'}
            ],
            fieldsApprove:[
                {key:'date',label:'Date'},
                'status','comment',
                {key:'created_by', label:'Approve By'}
            ],
            approveHistory:[],
            product:null,
            fabricData:[],
            accData:[],
            threadData:[],
            artworkData:[],
            interliningData:[],
            productImg:[],
            fieldsSupplier:[
                'supplier_code','type_subcon','supplier_name','email_supplier',
                
            ],
            supplierData:[],
            codeSupplierOpt:[],
            typeOpt:[
                {value:'FOB', text:'FOB'},
                {value:'CMT', text:'CMT'},
            ],
            //bad way role
            role:['Super User','MD'],
            rols:['Super User']
            
        }
    },
    methods:{
        ...mapActions('productDesign',['GET_PRODUCT_DETAIL','getProduct','getProductDetail','GET_PRODUCT_DASHBOARD','GET_PRODUCT_MATERIAL_DEV','POST_PRODUCT_APPROVE','GET_PRODUCT_APPROVE','POST_PRODUCT_PROCESS']),
        ...mapActions('session',['showNotification']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        addSupplier(){
            this.show = true
        },
        onSubmit(){
            
            apiCosting.dispatch('POST_PRODUCT_COSTING',{
                product_id: this.productId,
                supplier:this.form.nameSupplier,
                est_cost:this.form.estCost,
                sample_number:this.form.sampleNumber
            }).then(response => {
                this.showNotification({type:'success', message:'Successfully'})
                this.saveProcess(this.productId)
                this.clearForm()
                this.getCosting()
            }).catch(error => {
                return error.response.data.message
            })

        },
        autoComplete(){
            this.form.nameSupplier = 'PT.XYZ'
        },
        getCosting(){
            apiCosting.dispatch('GET_PRODUCT_COSTING').then(rsp=>{
                
                    let costFilter = rsp.filter(i => i.product_id == this.productId)
                    
                        console.log('cost Filter',costFilter.length);
                        
                        let supName = this.supplierData.filter(i => i.supplier_id = costFilter[0].supplier )
                        // this.$set(this.form,'nameSupplier',costFilter[0].supplier)
                        console.log('supName',supName);
                        
                    if(costFilter.length > 0){
                        this.showButton = true
                        this.$set(this.form,'nameSupplier',supName[0].supplier_name)
                        this.$set(this.form,'estCost',costFilter[0].est_cost)
                        this.$set(this.form,'sampleNumber',costFilter[0].sample_number)
                    }else{
                        this.showButton = false
                        
                    }
                    
                
            })
        },
        getDataProduct(){
            this.GET_PRODUCT_DETAIL({
                product_id:this.productId
            }).then(rsp =>{
                // let detailProduct = rsp.filter(x=>x.id === this.productId)
                let detailProduct = rsp.data.payload
                console.log(detailProduct);
                this.product = detailProduct
            })
            this.GET_PRODUCT_MATERIAL_DEV().then(response => {
                let productMaterial = response.data.payload.filter(pm => pm.product_id === this.productId && pm.product_material_status === 'Approved')
                this.fabricData         = productMaterial.filter(f=>f.category === 'fabric')
                this.accData            = productMaterial.filter(f=>f.category === 'accessories')
                this.threadData         = productMaterial.filter(f=>f.category === 'thread')
                this.artworkData        = productMaterial.filter(f=>f.category === 'artwork')
                this.interliningData    = productMaterial.filter(f=>f.category === 'interlining')
            })
        },
        getData(){
            this.productImg = []
            this.getProduct().then(response =>{
                let data = response.filter(x=>x.id === this.productId)
                for (let i = 0; i < data.length; i++) {
                    let img = {
                        img_id:data[i].design_id,
                        img_code:data[i].design_code,
                        img_name:data[i].design_name,
                        img_src:data[i].design_src,
                        img_label:data[i].design_label,

                    }

                    this.productImg.push(img)
                    
                }
                
            })
            

        },
        getDataSupplier(){
            apiSupplier.dispatch('GET_SUPPLIER')
            .then(rsp =>{
                console.log('rsp',rsp);
                this.supplierData = rsp.filter(x=>x.product_id == this.productId)

                let supplierFilter = rsp.filter(x=>x.product_id == this.productId)
                this.codeSupplierOpt = []
                for (let i = 0; i < supplierFilter.length; i++) {
                    const supplierOpt = {
                        text:supplierFilter[i].supplier_code+' - '+supplierFilter[i].supplier_name,
                        value:supplierFilter[i].supplier_id
                    }
                    this.codeSupplierOpt.push(supplierOpt)
                    
                }

                console.log(this.codeSupplierOpt);
            }).catch(err => {
                return err.response.data.message
            })
        },
        clearForm(){
            this.show = false
            this.form.nameSupplier = null
            this.form.codeSupplier = null
            this.form.typeSubcon = null
            this.form.estCost = null
            this.form.sampleNumber = null
        },
        saveProcess(prodId){
            let now = new Date()
            // let estDate = new Date()
            // let startDate = now.toLocaleDateString('id-ID',{ year: 'numeric', month: '2-digit', day: '2-digit' })
            
            let year = now.toLocaleString('id-ID', { year: 'numeric'})
            
            let month = now.toLocaleString('id-ID', { month: '2-digit'})
            
            let day = now.toLocaleString('id-ID', { day: '2-digit'})
            let startDate = year+'-'+month+'-'+day

            let estDate = new Date()
            
            


            // let startDate = estDate.getDate()

            if(this.product.duration === 'basic'){
                estDate.setDate(estDate.getDate()+7)
            }else if(this.product.duration === 'regular'){
                estDate.setDate(estDate.getDate()+49)
            }else{
                console.log('else');
                estDate.setDate(estDate.getDate()+27)
            }

            let year2 = estDate.toLocaleString('id-ID', { year: 'numeric'})
            
            let month2 = estDate.toLocaleString('id-ID', { month: '2-digit'})
            
            let day2 = estDate.toLocaleString('id-ID', { day: '2-digit'})
            let estimateDate = year2+'-'+month2+'-'+day2

            console.log('today',estimateDate);
            console.log('start',startDate);
            this.POST_PRODUCT_PROCESS({
                product_id:prodId,
                process:'Costing',
                task:this.userData.dept,
                start_date:startDate,
                estimate_date:estimateDate,
                status:'On Progress'
            }).then(rsp=>{
                console.log('Process Saved');
            }).catch(err=>{
                console.log(err);
            })
        },
        // numberFormat(value){
        //     // this.form.estCost = Number(value.replace(/\D/g,''))
        //     console.log('est cost',this.form.estCost);
        //     // return value == '0' ? '' : this.form.estCost.toLocaleString('id-ID')
        //     return value == '0' ? '' : Number(value.replace(/\D/g,''))
        // }
        
        
    },
    computed:{
        ...mapGetters('productDesign',['product','MASTER_MATERIAL','PRODUCT_MATERIAL','PRODUCT_APPROVE']),
        ...mapGetters('session', ['userData','localDate', 'serverDate']),
        roles(){
            // return this.role.indexOf(this.userData.role) > -1
            let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
            return this.role.some(i=>roleList[0].role_list.includes(i))
            // return this.role.some(i=>this.rols.includes(i))
        },
        
        
        
    },
    created(){
        // this.$set(this.form,'nameSupplier',null)
        // this.$set(this.form,'estCost',null)
        // this.$set(this.form,'sampleNumber',null)
        this.getDataProduct()
        this.getDataSupplier()
        this.getCosting()
        this.getData()
    },
    mounted(){
    }
}
</script>
<style scoped>
</style>