import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchActualActivity,
}from '../../api/api-applications-salesforce.js'

Vue.use(Vuex)

const actions = {
    SaveDataActualActivity(context, formActualActivity){
        return new Promise((resolve, reject) => {
            fnApiFetchActualActivity(formActualActivity)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
}

export default new Vuex.Store({
    namespaced: true,
    actions
})