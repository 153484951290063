<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item class="fw-semi-bold">{{formName}}</b-breadcrumb-item>

        </b-breadcrumb>

        <b-container fluid>

            <b-card>
                 <template v-slot:header >
                    <b-row align-v="center">
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>List Review Customer</h4>
                            </div>
                        </b-col>
                        <b-col sm="auto" md="3">
                            <div class="text-right">
                                <!-- <b-button title="Add" v-on:click="AddNewCustomer()" size="sm" variant="outline-success" style='margin-right:5px;' >
                                    <b-icon icon="journal-plus" aria-label="Add"></b-icon>
                                    Add New Customer
                                </b-button> -->
                                <!-- <b-button title="Add" size="sm" variant="success" v-on:click="AddNewCustomer()">
                                    <span>Add New Customer</span>
                                </b-button> -->
                            </div>
                        </b-col>

                    </b-row>
                </template>
                <b-row align-h="start">
                    <b-col sm="6">
                        <b-form-group
                            label="Sector"
                            label-for="Sector"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <v-select
                                v-model="form.industrial"
                                :clearable="true"
                                :reduce="(data) => data.name"
                                :required="true"
                                @input="ChangeSector()"
                                label="name"
                                placeholder="Choose Industry"
                                :options="options.industrial"
                                ref="OptIndustrial"
                                >
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group
                            label="Government"
                            label-for="Government"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <v-select
                                v-model="form.government"
                                :clearable="true"
                                :reduce="(data) => data.name"
                                :required="true"
                                label="name"
                                placeholder="Choose Government"
                                :options="options.government"
                                ref="OptGovernment"
                                >
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <br />
                <b-row align-h="start">
                    <b-col sm="6">
                        <b-form-group
                            label="Sub Sector"
                            label-for="Sub Sector"
                            label-cols-sm="3"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <v-select
                                v-model="form.sector"
                                :clearable="true"
                                :reduce="(data) => data.name"
                                :required="true"
                                label="name"
                                :disabled="disabledSector"
                                placeholder="Choose Sector"
                                :options="options.sector"
                                ref="OptSector"
                                >
                            </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group
                            label="Status"
                            label-for="status"
                            label-cols-sm="4"
                            label-align-sm="right"
                            label-size="sm"
                            class="mb-0"
                        >
                            <v-select
                                v-model="form.status"
                                :clearable="true"
                                :reduce="(data) => data.name"
                                :required="true"
                                label="name"
                                placeholder="Choose Status"
                                :options="options.status"
                                ref="OptStatus"
                                >
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
                <br />
                <b-row align-h="end">
                    <b-col lg="4">
                        <b-form-group
                        label="Search"
                        label-for="Search-input"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        class="mb-0"
                        >
                        <b-input-group size="sm">
                            <b-form-input
                            id="Search-input"
                            v-model="search"
                            type="search"
                            placeholder="Type to Search"
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!search" @click="search = ''">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner variant="primary" v-show="isLoading" label="Loading..."></b-spinner>
                </div>
                <b-table
                    :no-border-collapse="true"
                    striped
                    class="header-background"
                    hover
                    small
                    outlined
                    show-empty
                    responsive
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    sort-icon-left
                    @filtered="onFiltered"
                    :filter="search"
                    :fields="fields"
                    :items="FilterItems"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>

                    <!-- Last Date Activity -->
                    <template #cell(last_date_activity)="row">
                        <span v-if="row.value !== null" v-html="FormatDate(row.value)"></span>
                    </template>

                    <!-- Last Activity -->
                    <template #cell(last_activity)="row">
                        <span v-if="row.value !== null" v-html="CheckActivity(row.value)"></span>
                    </template>

                    <!-- Last Date SPK -->
                    <template #cell(last_spk)="row">
                        <span v-if="row.value !== null" v-html="FormatDate(row.value)"></span>
                    </template>

                    <template #cell(action)="row">
                        <!-- <b-button title="View" size="xs" variant="outline-info" v-on:click="viewData(row.item.id)" style='margin-right:5px;'>
                            <b-icon icon="eye-fill" aria-label="View"></b-icon>

                        </b-button> -->
                        <b-button title="Edit" size="xs" variant="outline-warning" v-on:click="editData(row.item.id)">
                            <b-icon icon="pencil-fill" aria-label="Edit"></b-icon>

                        </b-button>
                    </template>
                </b-table>
                <b-row>
                <b-col sm="4">
                Showing {{showPerPage}} of {{totalData}} entries
                </b-col>
                <b-col sm="4">
                    <b-form-group
                  label="Per Page"
                  label-for="PerPage"
                  label-cols-sm="6"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-input
                  style="max-width:80px;"
                    id="PerPage"
                    v-model="paging.perPage"
                    @input="Pagging()"
                    type="number"
                    max=999
                  ></b-form-input>
                </b-form-group>
                </b-col>
                <b-col sm="4" align-self="end" class="my-1">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="right"
                        size="sm"
                    >
                    <template #first-text><span class="">First</span></template>
                    <template #prev-text><span class="">Prev</span></template>
                    <template #next-text><span class="">Next</span></template>
                    <template #last-text><span class="">Last</span></template>
                    <template #page="{ page, active }">
                        <b v-if="active">{{Pagination(page)}} {{ page }}</b>
                        <i v-else>{{ page }}</i>
                    </template>

                    </b-pagination>
                </b-col>
                </b-row>
                <Loader v-if="isLoadingData" fullPage useOverlay loader='spinner'></Loader>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import Loader from "@/components/Loader/Loader";
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    components: {
        Loader,
    },
    data(){
        return{
            isLoadingData:false,
            formName:'List Review Customer',
            monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            sortBy: 'id',
            sortDesc: true,
            dataTable:[],
            fields:[
                {key:'action', label:'Action', sortable:false},
                {key:'customer_name', label:'Customer Name', sortable:true},
                {key:'last_date_activity', label:'last date activity', sortable:true},
                {key:'last_activity', label:'last activity', sortable:true},
                {key:'last_spk', label:'last SPK/PO', sortable:false},
                {key:'status', label:'Status', sortable:false},

            ],
            showPerPage:0,
            totalData:0,
            disabledSector:true,
            PageNow:1,
            paging:{
                perPage:10,
                currentPage:1,
            },
            isLoading:false,
            search:null,
            options:{
                // government:[],
                sector:[],
                industrial:[],
                // status:[],
                government:[
                    { id: 1, name: 'Government'},
                    { id: 2, name: 'Non Government'},
                ],
                // sector:[
                //     {name:'Sector 1'},
                //     {name:'Sector 2'},
                //     {name:'Sector 3'},
                //     {name:'Sector 4'},
                // ],
                // industrial:[
                //     {name:'Industry 1', value:"Industrial 1"},
                //     {name:'Industry 2', value:"Industrial 2"},
                //     {name:'Industry 3', value:"Industrial 3"},
                //     {name:'Industry 4', value:"Industrial 5"},
                // ],
                status:[
                    {name:'New'},
                    {name:'Existing'},
                ],
            },
            form:{
                role:null,
                start_limit:null,
                end_limit:null,
                user_nik:null,
                username:null,
                government:null,
                industrial:null,
                sector:null,
                status:null,
            },
        }
    },
    computed:{
        ...mapGetters('session', ['userData']),
        ...mapState('sales_force', ['stLrc','stLrcCount']),
        rows(){
            if(this.totalData != null){
                return this.totalData
            }
        },
        FilterItems(){
            let industrial = this.form.industrial
            let sector = this.form.sector
            let type_government = this.form.government
            let status = this.form.status
            return this.dataTable.filter(function(item){
                let filtereds = true
                if(type_government && type_government.length > 0){
                    filtereds = item.type_government == type_government
                }
                if(filtereds){
                    if(industrial && industrial.length > 0){
                        filtereds = item.industrial == industrial
                    }
                }
                if(filtereds){
                    if(status && status.length > 0){
                        filtereds = item.status == status
                    }
                }
                if(filtereds){
                    if(sector && sector.length > 0){
                        filtereds = item.sector == sector
                    }
                }
                return filtereds
            })

        },
    },
    methods:{
        ...mapActions('session', ['showNotification']),
        ...mapActions('sales_force', ['getListReviewCustomer','GetMasterSector','GetMasterIndustry']),

        LoadData() {
            // start loading
            this.isLoadingData = true
            this.GetMasterIndustry({
                category:'industry',
            }).then(rsp=> {
                if (rsp != 'No Data') {
                this.$set(this.options, 'industrial', rsp.data.payload)
                }
            })

            this.getListReviewCustomer({
                sales:this.userData.nik,
                role:this.form.role,
                industrial:this.form.industrial,
                sector:this.form.sector,
                government:this.form.government,
                status:this.form.status,
                start_limit:this.form.start_limit,
                end_limit:this.form.end_limit,
            })
            .then(rsp => {
                if (rsp.payload == null || rsp.payload == undefined || rsp.payload == []) {
                    this.dataTable = []
                    this.totalData = 0
                    this.showNotification({type:'info', message:'Data Not Found'})
                } else {
                    this.dataTable = rsp.payload
                    this.totalData = rsp.payload.length
                }
                this.showPerPage = this.paging.perPage
                if (this.totalData < this.paging.perPage) {
                    this.showPerPage = this.totalData
                }
            })
            .catch(err => {
                if(err.response.status !== 401) {
                    this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
                }
            }).finally(()=> {
                // stop loading
                setTimeout(() => {
                    this.isLoadingData = false
                }, 500);
            })
        },
        ChangeSector() {
            this.options.sector = []
            this.disabledSector = true
            this.form.sector = null
            if (this.form.industrial == null || this.form.industrial == undefined) {
                // console.log('')
            } else {
                // Get Sector
                this.GetMasterSector({
                    category:'sector',
                    name:this.form.industrial,
                }).then(rsp=> {
                    if (rsp != 'No Data') {
                        this.disabledSector = false
                        this.options.sector = rsp.data.payload
                    }
                })
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalData = filteredItems.length
            this.paging.currentPage = 1
            this.showPerPage = filteredItems.length
            if (filteredItems.length >= this.paging.perPage) {
                this.showPerPage = this.paging.perPage
            }

        },
        Pagging() {
            if ( this.showPerPage > this.paging.perPage) {
            this.showPerPage = this.paging.perPage
            }
            if ( this.showPerPage < this.paging.perPage) {
            this.showPerPage = this.paging.perPage
            }
            if ( this.showPerPage >= this.totalData) {
            this.showPerPage = this.totalData
            }
        },
        Pagination(value) {
            if (value !== this.PageNow) {
                if (value > this.PageNow) {
                    this.PageNow = value
                    // this.nextPage()
                    this.showPerPage = this.paging.perPage * this.PageNow
                if (this.showPerPage >= this.totalData) {
                    this.showPerPage = this.totalData
                }
                } else {
                    this.PageNow = value
                    this.showPerPage = this.showPerPage - (this.showPerPage - (this.paging.perPage * this.PageNow))
                if (this.showPerPage <= this.paging.perPage) {
                    this.showPerPage = this.paging.perPage
                }
                }
            }
        },
        CheckActivity(value) {
            if(value == null || value == undefined || value == '') {
                return '-'
            } else {
                let string = value
                if (value !== null) {
                    string = value.replace(/[^a-zA-Z0-9, ]/g, "");
                }
                return string
            }
        },
        FormatDate(value) {
            if(value == null || value == undefined || value == '') {
                return '-'
            } else {
                let date = new Date(value)
                let day = date.getDate()
                if (String(day).length == 1) {
                day = "0"+String(day)
                }
                let year = date.getFullYear()
                let bulan = this.monthNames[date.getMonth()]
                date = day+' '+bulan+' '+year
                return date
            }
        },
        FormatCommaNumber (param) {
            let format = param
            if (param == null || param == undefined || param == '') {
                format = null
            }
            else {
                // format = param.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                format = param.toString()
            }
            return format
        },
        editData(data) {
            // let routeData
            // routeData = this.$router.resolve({ name: 'SFCMasterCustomerForm', params: { page:'edit', idData:data }})
            this.$router.push({ name: 'SFCReviewCustomer', params: { page:'edit', idData:data } })
            // console.log(routeData.href)
            // window.open(routeData.href, '_blank')
            // window.open(routeData.href, '_self')
        },
    },
    created(){
        this.LoadData()
    },
}
</script>
<style src="../SalesForce.scss" lang="scss" scoped />