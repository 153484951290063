import Vue from 'vue'
import Vuex from 'vuex'
import {
    fnApiFetchWeeklyPlanning,
    fnApiFetchEditWeeklyPlanning,
}from '../../api/api-applications-salesforce.js'

Vue.use(Vuex)

const actions = {
    PostWeeklyPlanning(context, FormWeeklyPlanning){
        return new Promise((resolve, reject) => {
            fnApiFetchWeeklyPlanning(FormWeeklyPlanning)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
    EditWeeklyPlanning(context, FormWeeklyPlanning){
        return new Promise((resolve, reject) => {
            fnApiFetchEditWeeklyPlanning(FormWeeklyPlanning)
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                reject(error)
            })
        })
    },
}

export default new Vuex.Store({
    namespaced: true,
    actions
})