<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/applications"> Applications</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
            <router-link tag="a" to="/app/appsplm-garment-development"> Garment Development</router-link>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{formName}}</b-breadcrumb-item>
            
        </b-breadcrumb>
        <b-container fluid>
            <b-card>
                 <template v-slot:header >
                    <b-row>
                        <b-col sm="auto" md="9">
                            <div class="text-left">
                                <h4>Garment Development</h4>
                                <b-button title="Back" size="sm" variant="warning" v-on:click="backTo()">
                                    <b-icon font-scale="1" icon="arrow-left" variant="dark"></b-icon>
                                    Back 
                                </b-button>
                            </div>
                            
                        </b-col>
                        <b-col sm="auto" md="3">
                            <template v-if="roles">
                            <div class="text-right">
                                <b-button variant="success" size="sm" v-on:click="createData()">Add Supplier</b-button>
                            </div>
                            </template>
                        </b-col>
                        
                    </b-row>
                </template>
                <b-table
                    striped 
                    hover
                    show-empty
                    responsive
                    :per-page="paging.perPage"
                    :current-page="paging.currentPage"
                    :fields="fields"
                    :items="getGarmentlDevDetail"
                >
                    <template #empty="scope">
                        <p class="text-center">{{ scope.emptyText }}</p>
                    </template>
                    <template v-slot:cell(actions)="{item}">
                        <!-- <b-button title="Detail" size="xs" variant="dark" v-on:click="viewData(item.code,item.category)">
                            
                            <b-icon icon="eye-fill" aria-label="Help"></b-icon>
                        </b-button> -->
                        <b-button title="Ubah" size="xs" variant="info" v-on:click="editData(item.id,item.supplier_name)">
                            <b-icon icon="plus" aria-label="Help"></b-icon>
                            Add Activity
                        </b-button>
                    </template>
                    <template v-slot:cell(date)="{item}">
                        <template v-if="item.date != null">
                            {{new Date(item.date).toLocaleString("id-ID")}}
                        </template>
                    </template>
                </b-table>
                <div class="mt-3">
                    <b-pagination
                        v-model="paging.currentPage"
                        :total-rows="rows"
                        :per-page="paging.perPage"
                        align="center"
                    >

                    </b-pagination>
                </div>
            </b-card>
        </b-container>
        <b-modal
            hide-footer
            v-model="show"
            hide-backdrop
            v-on:ok="onSubmit()"
            ok-title="Save"
            size="lg"
            ref="modal"
            no-close-on-backdrop
            scrollable 
            title="Form Add Supplier"
        >
            <validation-observer ref="observer" v-slot="{handleSubmit}">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                    <b-row>
                        <b-col cols="auto" md="6">
                           
                            <!-- Used  -->
                                <!-- <validation-provider
                                    name="used"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Used As <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-used" v-model="form.used"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-used">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider> -->
                            <!-- Code -->
                                <validation-provider
                                    name="code"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Code Article<code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-code" v-model="form.code_article" :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-code">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            <!-- Description -->
                                <validation-provider
                                    name="description"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Brand <code>*</code>
                                        </template>
                                        <b-form-input readonly id="input-brand" v-model="form.brand" :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-brand">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>

                            <!-- Reference -->
                                <validation-provider
                                    name="reference"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Reference <code>*</code>
                                        </template>
                                        <b-form-input id="input-reference" v-model="form.reference"  :state="getValidationState(validationContext)"  trim></b-form-input>
                                        <b-form-invalid-feedback id="input-reference">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            <!-- Supplier  -->
                                <validation-provider
                                    name="supplier"
                                    :rules="{ required: false }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols          ="3" 
                                        label-cols-lg       ="3" 
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Supplier <code>*</code>
                                        </template>
                                        <!-- <b-form-select value-field="supplier_name"
                                                    text-field="supplier_name" id="inputSupplier" v-model="form.supplier" :options="supplierOpt"  :state="getValidationState(validationContext)"  trim></b-form-select> -->
                                        <v-select
                                            label="supplier_name"
                                            v-model="form.supplier"
                                            :reduce="supplier_name => supplier_name.supplier_name" 
                                             
                                            :options="supplierOpt" 
                                            :filter-by="myFilter"
                                            placeholder="Type here"
                                        >
                                            <template #option="{ supplier_code, supplier_name }">
                                                <p style="margin: 0">{{ supplier_code }}</p>
                                                <em >{{ supplier_name }}</em>
                                            </template>
                                        </v-select>
                                        <b-form-invalid-feedback id="input-supplier">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-button  variant="warning" v-on:click="cancel()" block size="sm">Cancel</b-button>
                        <b-button variant="success" block size="sm" type="submit">Save</b-button>
                        </b-col>
                        <b-col cols="auto" md="6">
                            <!-- Images -->
                                    <b-form-group
                                        label-size          ="sm"
                                    >
                                        <template v-slot:label>
                                            Images <code>*</code>
                                        </template>
                                        <!-- <b-form-file disabled id="input-images" v-model="form.images" :state="getValidationState(validationContext)"  trim></b-form-file> -->
                                        <!-- <b-form-invalid-feedback id="input-images">{{ validationContext.errors[0] }}</b-form-invalid-feedback> -->
                                        <b-img v-bind="mainProps" thumbnail fluid :src="form.imagesUrl" :alt="form.imagesName"></b-img>

                                    </b-form-group>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import apiSupplier from '@/store/product-form/product-supplier.js'
import 'vue-select/dist/vue-select.css';
import { APIPATH_PLM } from "../../../../api/api-paths";
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../api/api-methods";
export default {
    props:['garment_id'],
    data(){
        return{
            formName:'Detail',
            mainProps: {width: 300, height: 300, class: 'm1' },
            show:false,
            form:{
                code_article:null,
                supplier_id:null,
                supplier_code:null,
                supplier:null,
                reference:null,
                imagesUrl:null,
                imagesName:null,
                brand: null,
                status:"On Development"
            },
            dataSupplierByGarment:[],
            productId:null,
            paging:{
                perPage:10,
                currentPage:1,
            },
            fields:[
                {key:'actions',label:'#'},
                'code',
                {key:'supplier_name',label:'SUPPLIER'},
                {key:'activity',label:'last activity'},
                {key:'date',label:'last update'},
                'status'
            ],
            supplierOpt:[
                {value:'PT. XYZ', text:'PT. XYZ',id:'2',code:'XYZ'},
                {value:'PT. ABC', text:'PT. ABC',id:'1',code:'ABC'},
                {value:'PT. JKL', text:'PT. JKL',id:'4',code:'JLK'},
                {value:'PT. OPQ', text:'PT. OPQ',id:'3',code:'OPQ'},
            ],
            refOpt:[
                {value:'REF 1', text:'REF 1'},
                {value:'REF 2', text:'REF 2'},
                {value:'REF 3', text:'REF 3'},
                {value:'REF 4', text:'REF 4'},
            ],
            isLoading : false,
            myFilter: (option, label, search) => {
                let temp = search.toLowerCase();
                return option.supplier_code.toLowerCase().indexOf(temp) > -1 || 
                option.supplier_name.toLowerCase().indexOf(temp) > -1
            },
            role:['Super User','MD'],
            rols:['Super User']
        }
    },
     methods:{
        ...mapActions('session',['showNotification']),
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        createData(){
            this.show = true
        },
        cancel(){
           this.show = false
        },
        getData(){
          fnGET(APIPATH_PLM.getGarmentDashboard).then(
           (rsp) => {
             let dataGarment = rsp.data.payload.filter(x=>x.id == this.garment_id)
             this.form.code_article= dataGarment[0].article
             this.form.reference= dataGarment[0].reference
             this.productId = dataGarment[0].product_id
             this.form.imagesUrl= dataGarment[0].img_src
             this.form.imagesName= dataGarment[0].img_name
             this.form.brand = dataGarment[0].brand
            //  console.log('data', dataGarment)
           }
          );
        },
        getMasterSupplier(){
            apiSupplier.dispatch('GET_MASTER_SUPPLIER')
            .then(rsp=>{
                console.log(rsp);
                this.$set(this,'supplierOpt',rsp)
            })
            .catch(err=>{
                return err
            })
        },
        getGarmentlDevData(){
            fnGET(APIPATH_PLM.getGarmentDevDetail).then(
              (rsp) => {
                  let dataSupplierGarment = rsp.data.payload
                  this.dataSupplierByGarment = dataSupplierGarment
            }).catch(err=>{
                return err
            })
        },
        onSubmit(){
            let supplierData = this.supplierOpt.filter(x => x.supplier_name === this.form.supplier)
            fnPOST(APIPATH_PLM.postGarmentDevDetail,{
                product_garment:this.garment_id,
                supplier_id:supplierData[0].id,
                supplier_name:this.form.supplier,
                supplier_code:supplierData[0].supplier_code,
                status:this.form.status 
            }).then(
              (rsp) => {
                  if(rsp.data.status == 200){
                    this.show = false
                    this.getGarmentlDevData()
                    this.showNotification({type:'success', message:'Successfully'})
                    this.isLoading = false
                }}).catch((err) => {
                    console.log(err);
                });
          },
        editData(gdev_id,supplier_name){
            console.log('id', this.garment_id);
            this.$router.push({
                name:'AppsGarmentDevFormPage',
                params:{
                    garment_id:this.garment_id,
                    gdev_id:gdev_id,
                    supplier_name:supplier_name
                }
            })
        }
        },
        created(){
            this.getData()
            this.getMasterSupplier()
        },
        mounted(){
            this.getGarmentlDevData()
        },
        computed:{
            ...mapGetters('session',['userData']),

            roles(){
                // return this.role.indexOf(this.userData.role) > -1
                let roleList = this.userData.roles.filter(i => i.app ==='PLM')  
                return this.role.some(i=>roleList[0].role_list.includes(i))
            },
            getGarmentlDevDetail(){
                const garmentDevDetail = this.dataSupplierByGarment.filter(x=>x.product_garment_id == this.garment_id)
                return garmentDevDetail
            },
            rows(){
                if(this.getGarmentDevDetail > 0){
                return this.getGarmentDevDetail.length
                }
            }

       }

}
</script>

<style>

</style>