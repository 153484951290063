<template>
  <div class="table-basic">
    <b-breadcrumb>
      <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link tag="a" to="/app/applications"> Applications</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>{{ formName }}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container fluid>
      <b-row align-h="center">
        <b-col cols="12" sm="12">
          <b-card header="Approval Plan Activity" header-tag="header">
            <template v-slot:header>
              <div class="text-left" style="display: flex">
                <h3>Review Actual Activity</h3>
                <!-- <router-link tag="b" to="/app/sfc-list-review-actual-activity" style="position: absolute; right: 15px;">
                  <b-button size="md" type='button' variant="info">
                    <b-icon icon="arrow-left"></b-icon>
                    Back
                  </b-button>
                </router-link> -->
              </div>
            </template>
            <b-list-group flush>
              <b-list-group-item>
                <b-row align-v="center">
                  <b-col sm="6">
                    <b-form-group
                      label="Sales Person"
                      label-for="Employee_ID"
                      label-cols-sm="2"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <v-select
                        v-model="selectedNikSales"
                        :clearable="false"
                        @input="ChangeNikSales()"
                        :reduce="(data) => data.nik"
                        :required="true"
                        class="vd__div"
                        prepend-icon="edit"
                        label="name"
                        placeholder="Choose Sales Name"
                        :options="options.sales"
                        ref="OptSales"
                      >
                        <template v-slot:option="option">
                          {{ option.nik }} - {{ option.name }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.nik }} - {{ option.name }}
                        </template>
                      </v-select>
                      <!-- <b-form-input
                          id="Employee_ID"
                          :disabled="true"
                          v-model="form.employee_nik"
                          trim
                        ></b-form-input> -->
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" offset-sm="1">

                        <b-form-group
                          label="Start Date"
                          label-for="StartDate"
                          label-cols-sm="4"
                          label-align-sm="left"
                          label-size="sm"
                        >
                          <VueDatePicker
                            class="vd__div"
                            clearable
                            @input="ChangeStartDate()"
                            v-model="form.start_date"
                            no-header
                            ref="startDate"
                            format="ddd DD MMM YYYY"
                            placeholder="Choose Start Date"
                          />
                          <span
                            id="StartDateWarning"
                            class="display-hidden text-small text-danger"
                            >Wajib di isi</span
                          >
                        </b-form-group>
                      </b-col>
                </b-row>
                <b-row align-v="center">
                  <b-col sm="6">
                    <b-form-group
                      label="Customer"
                      label-for="Customer"
                      label-cols-sm="2"
                      label-align-sm="left"
                      label-size="sm"
                    >
                      <v-select
                        v-model="selectedCustomer"
                        :clearable="true"
                        @input="ChangeCustomer()"
                        :reduce="(data) => data.id"
                        :required="true"
                        class="vd__div"
                        label="customer_name"
                        placeholder="Choose Customer"
                        :options="options.customer"
                        ref="OptCustomer"
                      >
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4" offset-sm="1">
                        <b-form-group
                          label="End Date"
                          label-for="EndDate"
                          label-cols-sm="4"
                          label-align-sm="left"
                          label-size="sm"
                        >
                          <VueDatePicker
                            class="vd__div"
                            clearable
                            @input="ChangeEndDate()"
                            :disabled="!form.start_date"
                            v-model="form.end_date"
                            format="ddd DD MMM YYYY"
                            ref="endDate"
                            :min-date="form.start_date"
                            no-header
                            placeholder="Choose End Date"
                          />
                          <span
                            id="endDateWarning"
                            class="display-hidden text-small text-danger"
                            >Wajib di isi</span
                          >
                        </b-form-group>
                  </b-col>
                </b-row>
              </b-list-group-item>
              <b-list-group-item>
                   Total Customer hands-on : {{customer_hands_on}}
                  <br/>
                  Total Customer on progress : {{customer_on_progress}}
                  <br/>
                  Total Customer Done : {{customer_done}}
              </b-list-group-item>
            </b-list-group>

            <br />

            <b-table
              :no-border-collapse="true"
              striped
              stacked="md"
              class="header-background max_width_fit"
              hover
              small
              outlined
              :per-page="paging.perPage"
              :current-page="paging.currentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              show-empty
              responsive
              sort-icon-left
              :fields="fields"
              :items="dataTable"
            >
              <!-- table empty -->
              <template #empty="scope">
                <p class="text-center">{{ scope.emptyText }}</p>
              </template>

              <!-- Project Id and Cust Name -->
              <template #cell(project_id)="row">
                <span
                  v-if="row.value !== null && row.item.customer_name !== null"
                  >{{ row.value }} <br />
                  {{ row.item.customer_name }}</span
                >
              </template>

              <!-- Sales_name -->
              <template #cell(sales_name)="row">
                <span>{{ row.value }}</span>
              </template>

              <!-- Plan Date -->
              <template #cell(plan_date)="row">
                <span
                  v-if="row.value !== null"
                  v-html="FormatDate(row.value)"
                  ></span
                >
              </template>

              <!-- Actual Date -->
              <template #cell(date)="row">
                <span
                  v-if="row.value !== null"
                  v-html="FormatDate(row.value)"
                  ></span
                >
              </template>

              <!-- Sub Activity -->
              <!-- <template #cell(sub_activity_name)="row">
                  <span v-if="row.value !== null" v-html="ActivityReplace(row.value)">{{row.value}}</span>
              </template> -->

              <!-- Activity -->
              <template #cell(activity_name)="row">
                <span
                  v-if="row.item.date !== null"
                  v-html="
                    ActivityReplace(
                      row.value,
                      row.item.sub_activity_name,
                      row.item.reason
                    )
                  "
                  ></span
                >
              </template>
            </b-table>
            <b-row>
              <b-col sm="4">
                Showing {{ showPerPage }} of {{ totalData }} entries
              </b-col>
              <b-col sm="4" >
                <b-form-group
                  label="Per Page"
                  label-for="PerPage"
                  label-cols-sm="6"
                  label-align-sm="right"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-form-input
                  style="max-width:80px;"
                    id="PerPage"
                    v-model="paging.perPage"
                    @input="Pagging()"
                    type="number"
                    max=999
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4" align-self="end" class="my-1">
                <b-pagination
                  v-model="paging.currentPage"
                  :total-rows="rows"
                  :per-page="paging.perPage"
                  align="right"
                  size="sm"
                >
                  <template #first-text><span class="">First</span></template>
                  <template #prev-text><span class="">Prev</span></template>
                  <template #next-text><span class="">Next</span></template>
                  <template #last-text><span class="">Last</span></template>
                  <template #page="{ page, active }">
                    <b v-if="active">{{ Pagination(page) }} {{ page }}</b>
                    <i v-else>{{ page }}</i>
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <Loader
        v-if="isLoadingData"
        fullPage
        useOverlay
        loader="spinner"
      ></Loader>
    </b-container>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import { mapGetters, mapActions, mapState } from "vuex";
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import Vue from "vue";
import { fnPOST } from '../../../../api/api-methods';
import { APIPATH_SALESFORCE } from '../../../../api/api-paths';

export default {
  components: {
    ModelListSelect,
    Loader,
  },
  data() {
    const now = new Date();
    const today = now.toISOString().slice(0, 10);
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const optyear = { year: "numeric" };
    const year = now.toLocaleString("en-US", optyear);
    const optmonth = { month: "numeric" };
    const SMonth = { month: "short" };
    const month = now.toLocaleString("en-US", optmonth);
    const Strmonth = now.toLocaleString("en-US", SMonth);
    const optdays = { day: "numeric" };
    const days = now.toLocaleString("en-US", optdays);
    return {
      isLoadingData: false,
      formName: "Review Actual Activity",
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      dayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      today: today,
      isChecked: false,
      disabledForm: false,
      buttonSubmit: null,
      today_days: days,
      today_month: month,
      StrMonth: Strmonth,
      previousSelected: [],
      selectedNikSales: null,
      selectedCustomer: null,
      today_year: year,
      fields: [
        {
          key: "action",
          label: "Action",
          class: "display-hidden",
          sortable: false,
        },
        {
          key: "project_id",
          label: "Project ID",
          class: "min-11vw",
          sortable: true,
        },
        {
          key: "sales_name",
          label: "Sales",
          class: "min-10vw display-hidden",
          sortable: true,
        },
        { key: "customer", class: "display-hidden" },
        {
          key: "customer_name",
          label: "Customer Name",
          class: "display-hidden",
          sortable: true,
        },
        {
          key: "plan_date",
          label: "Plan Date",
          class: "min-10vw",
          sortable: true,
        },
        {
          key: "plan_activity",
          label: "Plan Activity",
          class: "min-10vw",
          sortable: true,
        },
        {
          key: "date",
          label: "Actual Date",
          class: "min-10vw",
          sortable: true,
        },
        {
          key: "activity_name",
          label: "Actual Activity",
          class: "min-10vw",
          sortable: true,
        },
        // {key:'sub_activity_name', label:'Sub Activity', class:"min-10vw", sortable:false },
        // {key:'reason', label:'Reason', class:"min-10vw", sortable:false },
        { key: "remark", label: "Remark", class: "min-10vw", sortable: false },
      ],
      dataTable: [],
      sortBy: "customer_name",
      sortDesc: true,
      showPerPage: 0,
      totalData: 0,
      PageNow: 1,
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      form: {
        employee_nik: null,
        employee_name: null,
        end_date: null,
        start_date: null,
      },
      options: {
        sales: [],
        customer:[],
      },
      customer_hands_on:0,
      customer_on_progress:0,
      customer_done:0,
    };
  },

  computed: {
    ...mapGetters("session", ["userData"]),
    ...mapState("sales_force", ["stDtLrac", "stDtLracCount"]),
    rows() {
      if (this.totalData != null) {
        return this.totalData;
      }
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    ...mapActions("sales_force", [
      "getDataReviewActualActivity",
      "getListUserBBI",
      "getDataCustomerDistributionList",
    ]),

    setData() {
      this.form.employee_nik = this.$route.params.idData;
      let statusRoute = this.$route.params.data;
      this.form.employee_name = this.$route.params.data;
      let idData = this.$route.params.idData;
      if (statusRoute == undefined || statusRoute == "") {
        statusRoute = null;
      }
      this.getListUserBBI({
        role: "Sreport",
      }).then((rsp) => {
        this.LoadCustomerList()
        this.options.sales = rsp.payload;
        this.options.sales.unshift({ name: "Sales Person", nik: "ALL" });
      });
      // if (this.$route.params.idData == null || this.$route.params.idData == undefined) {
      //   this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
      //   this.$router.push({name:"SFCListReviewActualActivity"})
      // }

      // --start loading
      // this.isLoadingData = true

      // this.getDataReviewActualActivity({
      // sales_nik:idData,
      // sales:idData,
      // })
      // .then(rsp => {
      //     if(this.stMnthAppCount == 0) {
      //         this.showNotification({type:'info', message:'Data Not Found'})
      //     }
      //     if (rsp.payload == null || rsp.payload == undefined) {
      //       this.dataTable = []
      //       this.totalData = 0
      //     } else {
      //       this.dataTable = rsp.payload
      //       this.totalData = rsp.payload.length
      //     }
      //       this.showPerPage = this.paging.perPage
      //       if (this.totalData < this.paging.perPage) {
      //           this.showPerPage = this.totalData
      //       }

      // })
      // .catch(err => {
      //     if(err.response.status !== 401) {
      //         this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
      //         this.$router.push({name:"SFCListReviewActualActivity"})
      //     }
      // }).finally(()=> {
      //   // stop loading
      //   setTimeout(() => {
      //       this.isLoadingData = false
      //   }, 500);
      // })
    },
    LoadCustomerList() {
      // start loading
      this.isLoadingData = true

      this.options.customer = []
      fnPOST(APIPATH_SALESFORCE.getAllCustomer, {

      })
      .then(rsp => {
        if (rsp.data.payload) {
          this.$set(this.options, 'customer', rsp.data.payload)
        }
      })
      .catch(err => {
          if(err.response.status !== 401) {
              this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
          }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })

    },
    LoadRecapData() {
      this.isLoadingData = true
      fnPOST(APIPATH_SALESFORCE.reviewSalesRecap, {
        customer:this.selectedCustomer,
        sales_nik:this.selectedNikSales,
        sales:this.selectedNikSales,
        start_date:this.form.start_date,
        end_date:this.form.end_date,
      })
      .then(rsp => {
        if (rsp.data.payload) {
          this.customer_done = rsp.data.payload.done
          this.customer_on_progress = rsp.data.payload.on_progress
          this.customer_hands_on = rsp.data.payload.hands_on
        }
        console.log(rsp.data.payload)
      })
      .catch(err => {
        if(err.response.status !== 401) {
            this.showNotification({type:'error', message:'Gagal Load Data, Silahkan Coba Kembali'})
        }
      }).finally(()=> {
        // stop loading
        setTimeout(() => {
            this.isLoadingData = false
        }, 500);
      })
    },
    Pagging() {
        if ( this.showPerPage > this.paging.perPage) {
           this.showPerPage = this.paging.perPage
        }
        if ( this.showPerPage < this.paging.perPage) {
           this.showPerPage = this.paging.perPage
        }
        if ( this.showPerPage >= this.totalData) {
           this.showPerPage = this.totalData
        }
    },
    Pagination(value) {

      if (value !== this.PageNow) {
        if (value > this.PageNow) {
          this.PageNow = value;
          // this.nextPage()
          this.showPerPage = this.paging.perPage * this.PageNow;
          if (this.showPerPage >= this.totalData) {
            this.showPerPage = this.totalData;
          }
        } else {
          this.PageNow = value;
          this.showPerPage =
            this.showPerPage -
            (this.showPerPage - this.paging.perPage * this.PageNow);
          if (this.showPerPage <= this.paging.perPage) {
            this.showPerPage = this.paging.perPage;
          }
        }
      }
    },
    ChangeStartDate() {
      if (this.form.start_date == null) {
        this.form.end_date = null;
      }
      if (this.form.end_date !== null) {
        if (this.form.start_date >= this.form.end_date) {
          this.form.end_date = null;
        } else {
          if (this.selectedNikSales !== null) {
            this.LoadData();
          }
        }
      }
    },
    ChangeEndDate() {
      if (this.form.end_date !== null && this.selectedNikSales !== null) {
        if (this.form.end_date !== undefined) {
          this.LoadData()
        }
      }
    },
    ChangeNikSales() {
      if (this.selectedNikSales == "ALL") {
        this.fields[2].class = "";
      } else {
        this.fields[2].class = "display-hidden";
      }
      if (this.selectedNikSales !== null && this.form.end_date !== null && this.form.start_date !== null) {
        this.LoadData()
      }
    },
    ChangeCustomer() {
      if (this.selectedCustomer == undefined || this.selectedCustomer == '') {
        this.selectedCustomer == null
      }
      if (
        this.selectedNikSales !== null &&
        (this.form.start_date !== null || this.form.end_date !== null)
      ) {
        this.LoadData();
      }

    },
    LoadData() {
      this.isLoadingData = true;
      this.getDataReviewActualActivity({
        customer:this.selectedCustomer,
        sales_nik: this.selectedNikSales,
        sales: this.selectedNikSales,
        start_date: this.form.start_date,
        end_date: this.form.end_date,
      })
        .then((rsp) => {
          this.LoadRecapData()
          if (this.stDtLracCount == 0) {
            this.showNotification({ type: "info", message: "Data Not Found" });
          }
          if (rsp.payload == null || rsp.payload == undefined) {
            this.dataTable = [];
            this.totalData = 0;
          } else {
            this.dataTable = rsp.payload;
            this.totalData = rsp.payload.length;
          }
          this.showPerPage = this.paging.perPage;
          if (this.totalData < this.paging.perPage) {
            this.showPerPage = this.totalData;
          }
        })
        .catch((err) => {
          if (err.response.status !== 401) {
            this.showNotification({
              type: "error",
              message: "Gagal Load Data, Silahkan Coba Kembali",
            });
          }
        })
        .finally(() => {
          // stop loading
          setTimeout(() => {
            this.isLoadingData = false;
          }, 500);
        });
    },
    FormatDate(value) {
      if (value == null || value == undefined || value == "") {
        return " ";
      } else {
        let date = new Date(value);
        let nameDay = this.dayNames[date.getDay()];
        let day = date.getDate();
        if (String(day).length == 1) {
          day = "0" + String(day);
        }
        let year = date.getFullYear();
        let bulan = this.monthNames[date.getMonth()];
        date = nameDay + " " + day + " " + bulan + " " + year;
        return date;
      }
    },
    ActivityReplace(activity, sub_activity, reason) {
      let string = activity;
      let subAct = "";
      let Res = "";
      let result = "";
      if (sub_activity !== null) {
        subAct = "<br/> - " + sub_activity;
      }

      if (reason !== null) {
        Res = "<br/> - " + reason;
      }

      if (activity !== null) {
        string = activity.replace(/[^a-zA-Z0-9,()/& ]/g, "");
      }

      result = string + subAct + Res;
      return result;
    },
  },
  created() {
    this.setData();
  },
};
</script>
<style src="../SalesForce.scss" lang="scss" scoped />
