<template>
  <div class="wrapper">
    <b-overlay :show="isLoading" rounded="sm" @shown="onShown">
      <b-breadcrumb>
        <b-breadcrumb-item>YOU ARE HERE</b-breadcrumb-item>
        <b-breadcrumb-item>
          <router-link tag="a" to="/app/applications">
            Applications</router-link
          >
        </b-breadcrumb-item>
        <b-breadcrumb-item>{{ formName }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ modulName }}</b-breadcrumb-item>
      </b-breadcrumb>
      <b-container fluid>
        <b-card>
          <template v-slot:header>
            <b-row>
              <b-col sm="auto" md="9">
                <div class="text-left">
                  <h4>Level</h4>
                </div>
              </b-col>
              <b-col sm="auto" md="3">
                <div class="text-right">
                  <b-button
                    variant="success"
                    size="sm"
                    v-on:click="show = !show"
                    >Create</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </template>
          <b-table
            responsive
            head-variant="dark"
            stickyColumn
            striped
            hover
            show-empty
            :per-page="paging.perPage"
            :current-page="paging.currentPage"
            :items="items"
            :fields="fields"
          >
            <template v-slot:cell(actions)="{ item }">
              <b-button
                title="Hapus"
                size="xs"
                variant="danger"
                v-on:click="deleteData(item.id)"
              >
                <b-icon icon="trash-fill" aria-label="Help"></b-icon>
              </b-button>
              <b-button
                title="Ubah"
                size="xs"
                variant="warning"
                v-on:click="editData(item.id, item.level_name)"
              >
                <b-icon icon="pencil-square" aria-label="Help"></b-icon>
              </b-button>
            </template>
          </b-table>
          <div class="mt-3">
            <b-pagination
              v-model="paging.currentPage"
              :total-rows="rows"
              :per-page="paging.perPage"
              align="center"
            >
            </b-pagination>
          </div>
        </b-card>
        <template>
          <!-- <b-modal
            v-model="show"
            @ok="onSubmit()"
            @hidden="cancel()"
            ok-title="Save"
            cancel-title="Cancel"
            ok-variant="success"
            cancel-variant="warning"
            header-bg-variant="secondary"
            footer-bg-variant="secondary"
            hide-backdrop
            size="lg"
            ref="modal"
            no-close-on-backdrop
            title="Form Level"
          > -->
          <b-modal
            v-model="show"
            ref="my-modal"
            header-bg-variant="secondary"
            footer-bg-variant="secondary"
            hide-backdrop
            size="lg"
            title="Form Level"
          >
            <div id="modal">
              <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.prevent="handleSubmit(onSubmit)">
                  <b-row>
                    <b-col cols="12">
                      <!-- Level -->
                      <validation-provider
                        name="Level"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols="3"
                          label-cols-lg="3"
                          label-size="sm"
                        >
                          <template v-slot:label>
                            Level Name<code>*</code>
                          </template>
                          <b-form-input
                            id="inputLevel"
                            v-model="form.level_name"
                            :state="getValidationState(validationContext)"
                            trim
                          ></b-form-input>
                          <b-form-invalid-feedback id="inputLevel">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <!-- /Level -->
                    </b-col>
                  </b-row>
                  <!-- penambahan terbaru -->
                  <b-row class="float-right">
                    <b-button class="mr-3" variant="success" @click="onSubmit()"
                      >Save</b-button
                    >
                    <b-button class="mr-3" variant="warning" @click="cancel()"
                      >Cancel</b-button
                    >
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <template #modal-footer>
              <div class="h-300 bg-secondary"></div>
            </template>
          </b-modal>
        </template>
      </b-container>
      <template #overlay>
        <div ref="loadings" class="text-center">
          <b-icon
            icon="arrow-clockwise"
            font-scale="3"
            animation="spin"
          ></b-icon>
          <p id="cancel-label">Please wait...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { fnGET, fnPOST, fnPUT, fnDELETE } from "../../../../../api/api-methods";
import { APIPATH_DMS } from "../../../../../api/api-paths";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
export default {
  data() {
    return {
      formName: "Level",
      modulName: "Dashboard",
      items: [],
      fields: [
        { key: "actions", label: "#" },
        { key: "level_name", label: "Level Name" },
        { key: "created_by", label: "Created By" },
        {
          key: "created_at",
          label: "Created At",
          formatter: this.setDateFormat,
        },
        { key: "modified_by", label: "Updated By" },
        {
          key: "modified_at",
          label: "Updated At",
          formatter: this.setDateFormat,
        },
      ],
      paging: {
        perPage: 10,
        currentPage: 1,
      },
      isLoading: false,
      form: {
        level_id: null,
        level_name: null,
        created_by: null,
        modified_by: null,
        deleted_by: null,
      },
      show: false,
    };
  },
  computed: {
    ...mapGetters("session", ["userData"]),
    rows() {
      if (this.items) {
        return this.items.length;
      }
    },
  },
  methods: {
    ...mapActions("session", ["showNotification"]),
    setDateFormat(value) {
      var options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      var formats = new Date(value).toLocaleDateString("id-ID", options);
      if (value) {
        return formats;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getLevel() {
      fnGET(APIPATH_DMS.getLevel)
        .then((rsp) => {
          Vue.set(this, "items", rsp.data.payload);
        })
        .catch((err) => {
          return err;
        });
    },
    onSubmit() {
      Vue.set(this, "isLoading", true);
      if (this.form.level_id) {
        fnPUT(APIPATH_DMS.putLevel, this.form)
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });
            Vue.set(this, "isLoading", false);
            this.getLevel();
          })
          .catch((err) => {
            this.showNotification({
              type: "danger",
              message: "Failed Submitted, Something wrong",
            });
            Vue.set(this, "isLoading", false);
            return err;
          });
        this.cancel();
      } else {
        // var unique = this.validationUnique()

        // if (unique.length == 0) {
        fnPOST(APIPATH_DMS.postLevel, this.form)
          .then((rsp) => {
            this.showNotification({
              type: "success",
              message: "Successfully Submitted",
            });
            Vue.set(this, "isLoading", false);
            this.getLevel();
          })
          .catch((err) => {
            this.showNotification({
              type: "danger",
              message: "Failed Submitted, Something wrong",
            });
            Vue.set(this, "isLoading", false);
            return err;
          });
        this.cancel();
        // }else{
        //         Vue.set(this,'isLoading',false)
        //         alert('Duplicate!!!, Data already created')

        //     }
      }
    },
    editData(id, name) {
      Vue.set(this, "show", true);
      Vue.set(this.form, "level_id", id);
      Vue.set(this.form, "level_name", name);
    },
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Delete",
          cancelTitle: "Cancel",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            Vue.set(this, "isLoading", true);
            fnPUT(APIPATH_DMS.deleteLevel, { level_id: id })
              .then((rsp) => {
                this.showNotification({
                  type: "success",
                  message: "Successfully Submitted",
                });
                Vue.set(this, "isLoading", false);
                this.getLevel();
              })
              .catch((err) => {
                this.showNotification({
                  type: "danger",
                  message: "Failed Submitted, Something wrong",
                });
                Vue.set(this, "isLoading", false);
                return err;
              });
          }
        });
    },
    setUserData() {
      Vue.set(this.form, "created_by", this.userData.fullname);
      Vue.set(this.form, "modified_by", this.userData.fullname);
      Vue.set(this.form, "deleted_by", this.userData.fullname);
    },
    onShown() {
      // Focus the cancel button when the overlay is showing
      this.$refs.loadings.focus();
    },
    cancel() {
      Vue.set(this.form, "level_id", null);
      Vue.set(this.form, "level_name", null);
      this.$refs["my-modal"].hide();
    },
    validationUnique() {
      var levels = this.form.level_name.toLowerCase().trim();

      return this.items.filter((item) => {
        return item.level_name.toLowerCase().match(levels);
      });
    },
  },
  created() {
    this.getLevel();
    this.setUserData();
  },
};
</script>

<style>
</style>